import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Cascader, Row, Button as AntButton, Badge, Radio, Checkbox, InputNumber } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Select } from "antd";
import { DatePicker } from "antd";
import { Input } from "antd";
import moment from "moment";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, UploadOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Divider } from "antd";
import { FcAbout } from "react-icons/fc";
import { Modal as AntModal } from "antd";
import { Upload } from "antd";
import { API_BASE_ROOT } from "../../ApiConnector";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class EldenStandard extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "EldenStandard",
      controllerName: "eldenStandard",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      productionMethod: [], // for cascader.
      purposeOfUse: [], // for cascader.
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefProductionMethod = React.createRef();
  formRefPurposeOfUse = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsestatus = await handleRequest("GET", "/api/" + this.state.controllerName + "/status");
    if (responsestatus.type === "ERROR") {
      error(responsestatus);
    }
    this.setState({
      statusSelectItems: Boolean(responsestatus.data) ? responsestatus.data : []
    });
    var responseitemGroupId = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemGroups");
    if (responseitemGroupId.type === "ERROR") {
      error(responseitemGroupId);
    }
    this.setState({
      itemGroupIdSelectItems: Boolean(responseitemGroupId.data) ? responseitemGroupId.data : []
    });
    var responseitemDescriptionId = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemDescriptions");
    if (responseitemDescriptionId.type === "ERROR") {
      error(responseitemDescriptionId);
    }
    this.setState({
      itemDescriptionIdSelectItems: Boolean(responseitemDescriptionId.data) ? responseitemDescriptionId.data : []
    });

    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/treeEldenStandardProductionMethod");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      this.setState({
        productionMethod: responseTree.data
      });
    }

    const responseTree2 = await handleRequest("GET", "/api/" + this.state.controllerName + "/treeEldenStandardPurposeOfUse");
    if (responseTree2.data.length !== 0) {
      generateList(responseTree2.data);
      this.setState({
        purposeOfUse: responseTree2.data
      });
    }

    var responseStandardGroups = await handleRequest("GET", "/api/equivalentStandard/standardGroups");
    if (responseStandardGroups.type === "ERROR") {
      error(responseStandardGroups);
    }
    this.setState({
      standardGroupEquivalentSelectItems: Boolean(responseStandardGroups.data) ? responseStandardGroups.data : []
    });

    var responseStandards = await handleRequest("GET", "/api/equivalentStandard/standards");
    if (responseStandards.type === "ERROR") {
      error(responseStandards);
    }
    this.setState({
      standardEquivalentSelectItems: Boolean(responseStandards.data) ? responseStandards.data : []
    });

    this.fillCode("standardGroup");
    this.fillCode("standard");
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let sketches = false;
    if (Boolean(row.eldenStandardSketchList) && row.eldenStandardSketchList.length > 0) {
      sketches = true;
    }

    let contentOfStandard = false;
    if (Boolean(row.eldenStandardItemList) && row.eldenStandardItemList.length > 0) {
      contentOfStandard = true;
    }

    let tables = false;
    if (Boolean(row.eldenStandardTableList) && row.eldenStandardTableList.length > 0) {
      tables = true;
    }

    this.formRef.current.setFieldsValue({
      ...row,
      sketches: sketches,
      contentOfStandard: contentOfStandard,
      tables: tables
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      sketchesVisible: sketches,
      contentOfStandardVisible: contentOfStandard,
      tablesVisible: tables
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      eldenStandardId: this.state.eldenStandardId
    };
    newItem.purposeOfUseId =
      Boolean(values.purposeOfUseId) && values.purposeOfUseId.length > 0 ? values.purposeOfUseId[values.purposeOfUseId.length - 1] : null;
    newItem.productionMethodId =
      Boolean(values.productionMethodId) && values.productionMethodId.length > 0
        ? values.productionMethodId[values.productionMethodId.length - 1]
        : null;
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  savePurposeOfUse = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };
    newItem.parentId = Boolean(values.parentId) && values.parentId.length > 0 ? values.parentId[values.parentId.length - 1] : null;
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveEldenStandardPurposeOfUse", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  saveProductionMethod = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };
    newItem.parentId = Boolean(values.parentId) && values.parentId.length > 0 ? values.parentId[values.parentId.length - 1] : null;
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveEldenStandardProductionMethod", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };
  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const columns = [
      {
        key: "key",
        title: <FormattedMessage id="General.TitleActions" defaultMessage="Actions" />,
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EldenStandard.standardGroupId" defaultMessage="Standard Group" />,
        key: "standardGroupId",
        render: record => {
          return record.standardGroupName;
        },
        ...getColumnFilter({
          inputName: "standardGroupName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenStandard.codeOfStandardId" defaultMessage="Code Of Standard" />,
        key: "codeOfStandardId",
        render: record => {
          return record.standardName;
        },
        ...getColumnFilter({
          inputName: "standardName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenStandard.definitionOfStandard" defaultMessage="Definition Of Standard" />,
        key: "definitionOfStandard",
        render: record => {
          return record.definitionOfStandard;
        },
        ...getColumnFilter({
          inputName: "definitionOfStandard",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenStandard.definitionOfStandard" defaultMessage="Part" />,
        key: "part",
        render: record => {
          return record.part;
        },
        ...getColumnFilter({
          inputName: "part",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenStandard.definitionOfStandard" defaultMessage="Definition Of Part" />,
        key: "definitionOfPart",
        render: record => {
          return record.definitionOfPart;
        },
        ...getColumnFilter({
          inputName: "definitionOfPart",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenStandard.issueDate" defaultMessage="Issue Date" />,
        key: "issueDate",
        render: record => {
          return Boolean(record.issueDate) ? moment(record.issueDate).format("DD-MM-YYYY") : "";
        },
        ...getColumnFilter({
          inputName: "issueDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenStandard.statusId" defaultMessage="Status" />,
        key: "status",
        render: record => {
          return record.status;
        },
        ...getColumnFilter({
          inputName: "status",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenStandard.Upload" defaultMessage="Upload" />,
        key: "fileUrl",
        render: record => {
          return (
            <AntButton
              style={{ border: "0px" }}
              onClick={() => this.setState({})}
              // type="primary"
              // shape="round"
              icon={<FcAbout />}
            ></AntButton>
          );
        }
      },
      {
        title: <FormattedMessage id="EldenStandard.plannedRevisionDate" defaultMessage="Planned Revision Date" />,
        key: "plannedRevisionDate",
        render: record => {
          return Boolean(record.plannedRevisionDate) ? moment(record.plannedRevisionDate).format("DD-MM-YYYY") : "";
        },
        ...getColumnFilter({
          inputName: "plannedRevisionDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },

      {
        title: <FormattedMessage id="EldenStandard.equivalentEldenStandard" defaultMessage="Equivalent Standard" />,
        key: "equivalentStandard",
        render: record => {
          return (
            <AntButton
              style={{ border: "0px" }}
              onClick={() => this.setState({ showEquivalentStandardsModal: true })}
              // type="primary"
              // shape="round"
              icon={<FcAbout />}
            ></AntButton>
          );
        }
      },
      {
        title: <FormattedMessage id="EldenStandard.itemGroupId" defaultMessage="Item Group" />,
        key: "itemGroupId",
        render: record => {
          return record.itemGroupName;
        },
        ...getColumnFilter({
          inputName: "itemGroupName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenStandard.type" defaultMessage="Type" />,
        key: "type",
        render: record => {
          return record.type;
        },
        ...getColumnFilter({
          inputName: "type",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenStandard.itemGroupId" defaultMessage="Item Group" />,
        key: "itemGroupId",
        render: record => {
          return record.itemGroupName;
        },
        ...getColumnFilter({
          inputName: "itemGroupName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },

      {
        title: <FormattedMessage id="EldenStandard.itemdescriptionId" defaultMessage="Item Description" />,
        key: "itemdescriptionId",
        render: record => {
          return record.itemDescriptionName;
        },
        ...getColumnFilter({
          inputName: "itemDescriptionName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenStandard.LastUpdate" defaultMessage="Last Update" />,
        key: "lastUpdated",
        render: record => {
          return Boolean(record.lastUpdated) ? moment(record.lastUpdated).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "lastUpdated",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      }
    ];

    const equivalentStandardsColumns = [
      {
        key: "key",
        title: <FormattedMessage id="General.TitleActions" defaultMessage="Actions" />,
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EldenStandard.standardGroupId" defaultMessage="Standard Group" />,
        key: "standardGroupId",
        render: record => {
          return record.standardGroupName;
        },
        ...getColumnFilter({
          inputName: "standardGroupName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenStandard.Standard" defaultMessage="Standard" />,
        key: "codeOfStandardId",
        render: record => {
          return record.standardName;
        },
        ...getColumnFilter({
          inputName: "standardName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}{" "}
        {this.state.exportClick ? (
          <ExcelFile filename="EldenStandard" hideElement={true}>
            {" "}
            <ExcelSheet name="EldenStandard" data={this.state.tableList}>
              {" "}
              <ExcelColumn label="Standard Group" value="standardGroupName" />
              <ExcelColumn label="Code Of Standard" value="standardName" />
              <ExcelColumn label="Definition Of Standard" value="definitionOfStandard" />
              <ExcelColumn label="Issue Date" value="issueDate" />
              <ExcelColumn label="Planned Revision Date" value="plannedRevisionDate" />
              <ExcelColumn label="Status" value="statusName" />
              <ExcelColumn label="Equivalent Standard" value="equivalentStandardName" />
              <ExcelColumn label="Item Group" value="itemGroupName" />
              <ExcelColumn label="Type" value="type" />
              <ExcelColumn label="Item Description" value="itemDescriptionName" />
              <ExcelColumn label="Last Update" value="lastUpdate" />
            </ExcelSheet>{" "}
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    return (
      <Badge.Ribbon
        text={<FormattedMessage id="stock.pageTitle" defaultMessage="EldenStandard" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              {" "}
              <Col md={21}></Col> <Col md={1}>{excelExport}</Col>{" "}
              <Col md={1}>
                {" "}
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>{" "}
              </Col>{" "}
              <Col md={1}>
                {" "}
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>{" "}
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>{" "}
              </Col>{" "}
            </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenStandard.standardGroupId" defaultMessage="Standard Group" />}
                  name="standardGroupId"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            href
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("standardGroup")}
                          >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["standardGroupSelectItems"]) &&
                      this.state["standardGroupSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenStandard.codeOfStandardId" defaultMessage="Code Of Standard" />}
                  name="standardId"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            href
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("standard")}
                          >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["standardSelectItems"]) &&
                      this.state["standardSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenStandard.definitionOfStandard" defaultMessage="Definition Of Standard" />}
                  name="definitionOfStandard"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenStandard.definitionOfStandard" defaultMessage="Part" />}
                  name="part"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenStandard.definitionOfStandard" defaultMessage="Definition Of Part" />}
                  name="definitionOfPart"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenStandard.issueDate" defaultMessage="Issue Date" />}
                  name="issueDate"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenStandard.statusId" defaultMessage="Status" />}
                  name="status"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["statusSelectItems"]) &&
                      this.state["statusSelectItems"].length > 0 &&
                      this.state["statusSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Photo" defaultMessage="Upload" />}
                  name="fileUrl"
                  rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please upload file" /> }]}
                >
                  <Upload {...props}>
                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> Upload
                    </AntButton>
                  </Upload>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenStandard.plannedRevisionDate" defaultMessage="Planned Revision Date" />}
                  name="plannedRevisionDate"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              }
              {
                <Form.List name="checklistImpactEnergy">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenStandard.StandardGroup"} defaultMessage={"Standard Group"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenStandard.Standard"} defaultMessage={"Standard"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "standardGroupId"]}>
                                      <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children">
                                        {Boolean(this.state["standardGroupEquivalentSelectItems"]) &&
                                          this.state["standardGroupEquivalentSelectItems"].length > 0 &&
                                          this.state["standardGroupEquivalentSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}{" "}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "standardId"]}>
                                      <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children">
                                        {Boolean(this.state["standardEquivalentSelectItems"]) &&
                                          this.state["standardEquivalentSelectItems"].length > 0 &&
                                          this.state["standardEquivalentSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}{" "}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"EldenStandard.AddEquivalentStandards "} defaultMessage={"Add Equivalent Standards "} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              }
              {
                <Form.Item {...layout} label={<FormattedMessage id="EldenStandard.PurposeOfUse" defaultMessage="Purpose Of Use" />}>
                  <Row gutter={1}>
                    <Col span={10}>
                      <Form.Item
                        name="purposeOfUseId"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Cascader changeOnSelect style={{ width: "100%" }} options={this.state.purposeOfUse} />
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={10}>
                      <AntButton
                        style={{ border: "0px" }}
                        // hidden={!this.state.hideInputs}
                        onClick={() => this.setState({ showPurposeOfUseModal: true })}
                        icon={<FcAbout />}
                      ></AntButton>
                    </Col>
                  </Row>
                </Form.Item>
              }
              {
                <Form.Item {...layout} label={<FormattedMessage id="EldenStandard.ProductionMethod" defaultMessage="Production Method" />}>
                  <Row gutter={1}>
                    <Col span={10}>
                      <Form.Item
                        name="productionMethodId"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Cascader changeOnSelect style={{ width: "100%" }} options={this.state.productionMethod} />
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={10}>
                      <AntButton
                        style={{ border: "0px" }}
                        // hidden={!this.state.hideInputs}
                        onClick={() => this.setState({ showProductionMethodModal: true })}
                        icon={<FcAbout />}
                      ></AntButton>
                    </Col>
                  </Row>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenStandard.Type" defaultMessage="Type" />}
                  name="type"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Radio.Group>
                    <Radio value={"EQUIPMENT"}>
                      <FormattedMessage id="EldenStandard.Equipment" defaultMessage="Equipment" />
                    </Radio>
                    <Radio value={"MATERIAL"}>
                      <FormattedMessage id="EldenStandard.Material" defaultMessage="Material" />
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              }
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenStandard.itemGroupId" defaultMessage="Item Group" />}
                name="itemGroupId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["itemGroupIdSelectItems"]) &&
                    this.state["itemGroupIdSelectItems"].length > 0 &&
                    this.state["itemGroupIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenStandard.itemDescriptionId" defaultMessage="Item Description" />}
                name="itemDescriptionId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["itemDescriptionIdSelectItems"]) &&
                    this.state["itemDescriptionIdSelectItems"].length > 0 &&
                    this.state["itemDescriptionIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>

              {/* CONTENT OF STANDARD */}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenStandard.ContentofStandard" defaultMessage="Content of Standard" />}
                  name="contentOfStandard"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={e => {
                      console.log("e", e);
                      if (e.target.checked) {
                      }
                      this.setState({
                        contentOfStandardVisible: e.target.checked
                      });
                    }}
                  />
                </Form.Item>
              }

              {this.state.contentOfStandardVisible && (
                <Form.List name="eldenStandardItemList">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenStandard.ItemNo"} defaultMessage={"Item No"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenStandard.Item"} defaultMessage={"Item"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "newLineNo"]}>
                                      <InputNumber
                                        style={{ width: "100%" }}
                                        min={0}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "newLineDefinition"]}>
                                      <Input parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"EldenStandard.AddNew "} defaultMessage={"Add New "} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              )}

              {/* TABLES */}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenStandard.Tables" defaultMessage="Tables" />}
                  name="tables"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={e => {
                      console.log("e", e);
                      if (e.target.checked) {
                      }
                      this.setState({
                        tablesVisible: e.target.checked
                      });
                    }}
                  />
                </Form.Item>
              }

              {this.state.tablesVisible && (
                <Form.List name="eldenStandardTableList">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenStandard.TableNo"} defaultMessage={"Table No"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenStandard.Definition"} defaultMessage={"Definition"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "newLineNo"]}>
                                      <InputNumber
                                        style={{ width: "100%" }}
                                        min={0}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "newLineDefinition"]}>
                                      <Input parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"EldenStandard.AddNew "} defaultMessage={"Add New "} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              )}

              {/* SKETCH */}

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenStandard.Sketches" defaultMessage="Sketches" />}
                  name="sketches"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={e => {
                      this.setState({
                        sketchesVisible: e.target.checked
                      });
                    }}
                  />
                </Form.Item>
              }

              {this.state.sketchesVisible && (
                <Form.List name="eldenStandardSketchList">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenStandard.SketchNo"} defaultMessage={"Sketch No"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"EldenStandard.Definition"} defaultMessage={"Definition"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "newLineNo"]}>
                                      <InputNumber
                                        style={{ width: "100%" }}
                                        min={0}
                                        parser={value => value.replace(",", ".")}
                                        formatter={value => value.replace(".", ",")}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "newLineDefinition"]}>
                                      <Input parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"EldenStandard.AddNew "} defaultMessage={"Add New "} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              )}

              <div hidden={this.state.hideSave}>
                {" "}
                {
                  <Row gutter={[16, 16]}>
                    {" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      {" "}
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        {" "}
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                  </Row>
                }{" "}
              </div>
              <div hidden={this.state.hideUpdate}>
                {" "}
                {
                  <Row gutter={[16, 16]}>
                    {" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      {" "}
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        {" "}
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      {" "}
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        {" "}
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                  </Row>
                }{" "}
              </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              {" "}
              <div className="alert-icon">
                {" "}
                <FilterOutlined />{" "}
              </div>{" "}
              <div className="alert-text">
                {" "}
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      {" "}
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>{" "}
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>{" "}
                      <span>
                        {" "}
                        <Tooltip title="Remove">
                          {" "}
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />{" "}
                        </Tooltip>{" "}
                      </span>{" "}
                    </div>
                  );
                })}{" "}
              </div>{" "}
              <div className="alert-close">
                {" "}
                <Tooltip title="Remove All">
                  {" "}
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />{" "}
                </Tooltip>{" "}
              </div>{" "}
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            {" "}
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />{" "}
          </div>

          <AntModal
            title={<FormattedMessage id="EldenStandard.Senario" defaultMessage="Usage" />}
            open={this.state.showPurposeOfUseModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showPurposeOfUseModal: false })}
            onOk={() => this.formRefPurposeOfUse.current.submit()}
            width={1300}
            centered
          >
            <Form initialValues={{ remember: false }} onFinish={this.savePurposeOfUse} onFinishFailed={onFinishFailed} ref={this.formRefPurposeOfUse}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenStandard.UsageGroup" defaultMessage="Usage Group" />}
                  // tooltip={<FormattedMessage id="ItemStock.parentId.w" defaultMessage="Parent stock of defined stock." />}
                  name="parentId"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Cascader changeOnSelect style={{ width: "100%" }} options={this.state.purposeOfUse} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenStandard.Usage" defaultMessage="Usage" />}
                  name="usageName"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input placeholder="Usage" style={{ width: "100%" }} />
                </Form.Item>
              }
            </Form>
          </AntModal>

          <AntModal
            title={<FormattedMessage id="EldenStandard.Production" defaultMessage="Production" />}
            open={this.state.showProductionMethodModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showProductionMethodModal: false })}
            onOk={() => this.formRefProductionMethod.current.submit()}
            width={1300}
            centered
          >
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveProductionMethod}
              onFinishFailed={onFinishFailed}
              ref={this.formRefProductionMethod}
            >
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenStandard.ProductionGroup" defaultMessage="Production Group" />}
                  // tooltip={<FormattedMessage id="ItemStock.parentId.w" defaultMessage="Parent stock of defined stock." />}
                  name="parentId"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Cascader changeOnSelect style={{ width: "100%" }} options={this.state.productionMethod} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenStandard.ProductionMethod" defaultMessage="Production Method" />}
                  name="productionMethod"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input placeholder="Production Method" style={{ width: "100%" }} />
                </Form.Item>
              }
            </Form>
          </AntModal>

          <AntModal
            title={<FormattedMessage id="EldenStandard.EquivalentStandards" defaultMessage="Equivalent Standards" />}
            open={this.state.showEquivalentStandardsModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showEquivalentStandardsModal: false })}
            //onOk={() => this.formRefProductionMethod.current.submit()}
            width={1300}
            centered
          >
            <Table
              //scroll={{ x: 400, scrollToFirstRowOnChange: true }}
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={equivalentStandardsColumns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </AntModal>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            {" "}
            <Modal.Header closeButton>
              {" "}
              <Modal.Title id="contained-modal-title-vcenter">
                {" "}
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />{" "}
              </Modal.Title>{" "}
            </Modal.Header>{" "}
            <Modal.Body>
              {" "}
              <p>
                {" "}
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />{" "}
              </p>{" "}
            </Modal.Body>{" "}
            <Modal.Footer>
              {" "}
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                {" "}
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />{" "}
              </Button>{" "}
              <Button variant="danger" onClick={this.delete}>
                {" "}
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />{" "}
              </Button>{" "}
            </Modal.Footer>{" "}
          </Modal>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EldenStandard);
