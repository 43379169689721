import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { Button, Modal } from 'react-bootstrap';
import { FilterOutlined } from '@ant-design/icons';
import { handleRequest } from '../../ApiConnector';
import { API_BASE_ROOT } from '../../ApiConnector';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import CustomTransmittalMenu from '../CustomMenu/CustomTransmittalMenu';
import SettingsIcon from '@material-ui/icons/Settings';
import CreateIcon from '@material-ui/icons/AddCircle';
import ExportIcon from '@material-ui/icons/CloudDownloadRounded';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { Form, message, Table, Input, Select, Col, Row, Button as AntButton, Divider, Upload, InputNumber } from 'antd';
import moment from 'moment';
import { PlusOutlined, UploadOutlined, MinusOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { TransmittalResponse } from './TransmittalResponse';
import { TransmittalSetting } from './TransmittalSetting';
import { TransmittalOfficial } from './TransmittalOfficial';
import { error, showError, showSuccess } from '../../MessageHelper';

const { Option } = Select;

export class TransmittalLog extends Component {

    constructor() {
        super();
        this.state = {
            hideResponse: true,
            modalDeleteShow: false,
            showShareModal: false,
            shareFileId: null,
            selectedMailAddress: [],
            transmittalSettings: null,
            exportList: [],
            exportClick: false,
            showCodeModal: false,
            showSettingsModal: false,
            revNo: 0,
            settingsData: [{
                key: 1,
                settingsType: "General",
                officialType: ""
            },
            {
                key: 2,
                settingsType: "To",
                officialType: "To"
            },
            {
                key: 3,
                settingsType: "Attn",
                officialType: "Attn"
            },
            {
                key: 4,
                settingsType: "Review Approve",
                officialType: "ReviewApprove"
            },
            {
                key: 5,
                settingsType: "Reviewed By",
                officialType: "ReviewedBy"
            }],
            hideUpdate: true,
            hideSave: false,
            hiddenInputs: true,
            filePaths: [],
            fileList: [],
            fileListCoverPage: [],
            filePathsCoverPage: [],
            showRevisedListModal: false,
            showLinkedListModal: false,
            revisedFileList: [],
            modalPdfViewer: false,
            pdfFile: "",
            codeName: "",
            drawingTypeSelectItems: [],
            disciplineSelectItems: [],
            departmentSelectItems: [],
            documentTypeSelectItems: [],
            actionTypeSelectItems: [],
            clientSelectItems: [],
            userSelectItems: [],
            locationSelectItems: [],
            materialEquipmentSelectItems: [],
            id: null,
            modalResponseShow: false,
            selectedRow: null,
            revising: false,
            disciplineId: null,
            departmentId: null,
            drawingTypeId: null,
            drawingSearch: null,
            linkDrawingList: [],
            linkedDrawingIds: [],
            dateOfSupersede: null,
            modalSupersedeAction: false,
            index: null,
            logDetailId: null,
            logFileName: null,
            logFilePath: null,
            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['2', '10', '25', '50', '100', '1000', '10000', '100000', '1000000'],
            },
            loading: false,
            sortField: "",
            sortOrder: "",
        };

    }

    formRef = React.createRef();

    componentDidMount = async () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
        let logDetailId = Boolean(this.props) && Boolean(this.props.logDetailId) ? this.props.logDetailId : null;
        let documentCode = Boolean(this.props) && Boolean(this.props.documentCode) ? this.props.documentCode : null;
        let revNo = Boolean(this.props) && Boolean(this.props.revNo) ? this.props.revNo : null;

        const response = await handleRequest("GET", "/api/users/me");
        if (Boolean(response.data)) {
            const user = response.data;
            let userSelectItems = [];
            userSelectItems.push(user);
            this.formRef.current.setFieldsValue({
                departmentId: user.departmentId,
                byUserId: user.id
            });

            this.setState({
                byUserId: user.id,
                departmentId: user.departmentId,
                userSelectItems: userSelectItems
            })
        }

        this.fillDisciplines();
        this.fillDepartments();
        this.fillDocumentTypes();
        this.fillClients();
        this.fillActionTypes();

        if (Boolean(logDetailId)) {
            this.setState({
                logDetailId: logDetailId,
                hiddenInputs: false
            });

            this.fillInputs(logDetailId);
        }

        const responseSettings = await handleRequest("GET", "/api/transmittalSettings/initialize");
        if (Boolean(responseSettings.data)) {
            const transmittalSettings = responseSettings.data;
            this.setState({
                transmittalSettings: transmittalSettings
            })

        } else this.setState({
            transmittalSettings: null
        })

    };

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hiddenInputs: true,
        });
        this.resetInputs();
    };

    resetInputs = () => {
        this.formRef.current.resetFields();
        this.setState({
            filePaths: [],
            fileList: [],
            hideResponse: true,
            filePathsCoverPage: [],
            fileListCoverPage: [],
            id: null,
            revNo: 0,
            logDetailId: null,
            revising: false,
            logFileName: null,
            logFilePath: null,
            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['2', '10', '25', '50', '100', '1000', '10000', '100000', '1000000'],
            },
        })
    }

    edit = (row) => {

        // document.getElementById("OrganizationalLevelCancelButton").click(); 
        let fileList = [];
        let fileListCoverPage = [];
        let pathList = Boolean(row.path) ? row.path.split(",") : [];
        let pathListCoverPage = Boolean(row.coverPageUrl) ? row.coverPageUrl.split(",") : [];
        if (Boolean(pathList) && pathList.length > 0) {

            pathList.forEach((element, index) => {
                let obj = {
                    uid: index,
                    name: element.split("download/")[1],
                    url: element,
                    thumbUrl: element,
                };

                fileList.push(obj);
            });
        }

        if (Boolean(pathListCoverPage) && pathListCoverPage.length > 0) {

            pathListCoverPage.forEach((element, index) => {
                let obj = {
                    uid: index,
                    name: element.split("download/")[1],
                    url: element,
                    thumbUrl: element,
                };

                fileListCoverPage.push(obj);
            });
        }




        this.formRef.current.setFieldsValue({
            ...row,
        });
        let userIds = [];
        userIds.push(row.byUserId);
        userIds.push(row.preparedById);
        userIds.push(row.reviewedById);
        userIds.push(row.submittedById);
        userIds.push(row.approvedById);

        this.getUser(userIds);

        this.setState({
            hideUpdate: false,
            hideSave: true,
            hiddenInputs: false,

            id: row.id,
            revNo: row.revNo,
            selectedRow: row,
            fileList: fileList,
            filePaths: Boolean(row.path) ? row.path.split(",") : [],
            fileListCoverPage: fileListCoverPage,
            filePathsCoverPage: pathListCoverPage,

        });
    };


    revise = (row) => {

        let fileList = [];
        let fileListCoverPage = [];
        let pathList = Boolean(row.path) ? row.path.split(",") : [];
        let pathListCoverPage = Boolean(row.coverPageUrl) ? row.coverPageUrl.split(",") : [];
        if (Boolean(pathList) && pathList.length > 0) {

            pathList.forEach((element, index) => {
                let obj = {
                    uid: index,
                    name: element.split("download/")[1],
                    url: element,
                    thumbUrl: element,
                };

                fileList.push(obj);
            });
        }

        if (Boolean(pathListCoverPage) && pathListCoverPage.length > 0) {

            pathListCoverPage.forEach((element, index) => {
                let obj = {
                    uid: index,
                    name: element.split("download/")[1],
                    url: element,
                    thumbUrl: element,
                };

                fileListCoverPage.push(obj);
            });
        }

        this.formRef.current.setFieldsValue({
            ...row,

        });

        let userIds = [];
        userIds.push(row.byUserId);
        userIds.push(row.preparedById);
        userIds.push(row.reviewedById);
        userIds.push(row.submittedById);
        userIds.push(row.approvedById);

        this.getUser(userIds);
        console.log(row);
        this.setState({
            hideUpdate: false,
            hideSave: true,
            hiddenInputs: false,

            id: row.id,
            revNo: row.revNo !== null && row.revNo !== undefined ? row.revNo + 1 : 0,
            revising: true,
            fileList: fileList,
            filePaths: Boolean(row.path) ? row.path.split(",") : [],
            fileListCoverPage: fileListCoverPage,
            filePathsCoverPage: pathListCoverPage

        });
    };

    fillInputs = async (id) => {

        if (Boolean(id)) {
            var response1 = await handleRequest("GET", "/api/transmittalLogs/byLogDetailId/" + id);

            if (response1.type === "ERROR") {
                var response2 = await handleRequest("GET", "/api/logDetails/" + id);

                if (response2.type === "ERROR") {
                    // error(response);
                }
                else {
                    var row = response2.data;

                    let fileList = [];

                    let pathList = Boolean(row.path) ? row.path.split(",") : [];

                    if (Boolean(pathList) && pathList.length > 0) {


                        pathList.forEach((element, index) => {
                            let obj = {
                                uid: index,
                                name: element,
                                url: element,
                                thumbUrl: element,
                            };

                            fileList.push(obj);
                        });
                    }

                    this.formRef.current.setFieldsValue({
                        revNo: row.revNo,
                        documentCode: row.code,
                        description: row.description
                    });


                    this.setState({

                        hiddenInputs: false,

                        revNo: row.revNo,
                        documentCode: row.code,
                        description: row.description,
                        logFileName: row.fileName,
                        logFilePath: row.path,
                        fileList: fileList,
                        filePaths: pathList,

                    });

                }
            }
            else {
                this.edit(response1.data);
            }
        }

    };

    encode = (row) => {

        this.setState({
            modalResponseShow: true,
            selectedRow: row,
        });
    };





    openShareModal = (row) => {

        this.setState({
            shareFileId: row.id,
            showShareModal: true
        });

    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }



    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        }
        var response = await handleRequest("DELETE", "/api/transmittalLogs/" + deletedItem.id);

        if (response.type === "ERROR") {
            error(response);
        }
        else {
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.setState({
                id: null,
                modalDeleteShow: false
            });
        }
    }






    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters, });
    }

    restartTable = async (params = {}) => {

        this.setState({ loading: true });
        const newObj = this.createWhere(params);

        let url = "/api/transmittalLogs/paging";
        const response = await handleRequest("POST", url, newObj);
        if (response.type === "ERROR") {
            error(response);
            this.setState({
                loading: false,
                tableList: [],
                pagination: {
                    ...params.pagination,
                    total: 0,
                },
                sortField: "",
                sortOrder: "",
            });
        }
        else {
            if (response.data.length !== 0) {
                let list = response.data.data;
                list.forEach((element, index) => {
                    element.sn = index + 1;
                });
                this.setState({
                    loading: false,
                    tableList: list,
                    pagination: {
                        ...params.pagination,
                        total: response.data.totalRecords,
                    },
                    sortField: params.sortField,
                    sortOrder: params.sortOrder,
                });
            } else {
                this.setState({
                    loading: false,
                    tableList: [],
                    pagination: {
                        ...params.pagination,
                        total: 0,
                    },
                    sortField: "",
                    sortOrder: "",
                });
            }
        }
    };

    onClickCreateNew = async () => {
        this.resetInputs();
        if (this.state.hiddenInputs) {
            this.setState({
                hiddenInputs: false,
                hiddenSave: false,
                hiddenUpdate: true,
            });
        }
        else {
            this.setState({
                hiddenInputs: true,
                hiddenSave: true,
                hiddenUpdate: false,
            });
        }
    }

    onClickSettings = async () => {

        this.setState({
            showSettingsModal: true
        });

    }

    save = async (values) => {

        const { filePaths, filePathsCoverPage } = this.state;

        const newItem = {
            ...values,
            id: this.state.id,
            path: Boolean(filePaths) ? filePaths.toString() : "",
            coverPageUrl: Boolean(filePathsCoverPage) ? filePathsCoverPage.toString() : "",
            logDetailId: this.state.logDetailId,
            revNo: this.state.revNo,
        }

        if (newItem.id === null) {
            var response = await handleRequest("POST", "/api/transmittalLogs", newItem);
        }
        else {

            if (this.state.revising === true)
                response = await handleRequest("PUT", "/api/transmittalLogs/revise/" + newItem.id, newItem);
            else
                response = await handleRequest("PUT", "/api/transmittalLogs/" + newItem.id, newItem);
        }

        if (response.type === "ERROR") {
            error(response);
        }
        else {

            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.cancel();
        }
    };


    createWhere(params) {
        var current = this.state.pagination.current;
        var pageSize = this.state.pagination.pageSize;
        var folderName = "", sortField = "", sortOrder = "";
        if (Boolean(params) && Boolean(params.pagination)) {
            current = params.pagination.current;
            pageSize = params.pagination.pageSize;


            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }

        }
        const newObj = {
            ...params.pagination,
            firstResult: current - 1,
            maxResults: pageSize,
            sortField: sortField,
            sortOrder: sortOrder
        };
        return newObj;
    }

    downloadMultipleFile = async (url) => {

        if (Boolean(url) && url.length > 0) {
            let pathList = url.split(",");
            console.log(pathList);
            if (Boolean(pathList) && pathList.length > 1) {
                var response = await handleRequest("POST", "/api/transmittalLogs/download", pathList);

                if (response.type === "ERROR") {
                    error(response);
                }
                else {

                    try {
                        const link = document.createElement('a')
                        link.href = response.data;
                        link.download = "Fİles.zip";
                        link.click();

                    } catch (error) {
                        console.log(error);

                    }

                }

            }
            else if (Boolean(pathList) && pathList.length === 1)
                this.downloadFile(pathList[0]);
        }
        else showError("There is no uploaded file !");

    }

    downloadFile = async (url) => {
        window.location.href = url;
    }

    downloadFile2 = async () => {
        const { logFilePath } = this.state;
        this.downloadMultipleFile(logFilePath);
    }

    showReport = async (url) => {
        try {
            const fileRealUrl = url;

            this.setState({
                pdfFile: fileRealUrl,
                modalPdfViewer: true,
            });

        } catch (error) {
            alert("File cant found.");
        }
    }

    searchUserMail = async (email) => {
        if (Boolean(email) && email.length > 0) {
            var response = await handleRequest("GET", "/api/users/mails/" + email);
            if (response.type === "ERROR") {
                error(response);
            }
            else {
                this.setState({
                    userSelectItems: Boolean(response.data) ? response.data : []
                });
            }
        }
        else this.setState({
            userSelectItems: []
        });

    }

    addItem = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "discipline"
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillDisciplines();
            }
        } else {
            showError("Please enter discipline");
        }
    };

    fillDisciplines = async () => {
        const response = await handleRequest("GET", "/api/codes/type/discipline");
        if (Boolean(response.data)) {
            this.setState({
                disciplineSelectItems: response.data
            });
        }
    }

    addDepartment = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "department"
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillDepartments();
            }
        } else {
            showError("Please enter department");
        }
    };

    fillDepartments = async () => {
        const response = await handleRequest("GET", "/api/codes/type/department");
        if (Boolean(response.data)) {
            this.setState({
                departmentSelectItems: response.data
            });
        }
    }

    addDocumentType = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "documentType"
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillDocumentTypes();
            }
        } else {
            showError("Please enter department");
        }
    };

    fillDocumentTypes = async () => {
        const response = await handleRequest("GET", "/api/codes/type/documentType");
        if (Boolean(response.data)) {
            this.setState({
                documentTypeSelectItems: response.data
            });
        }
    }


    addToClient = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "client"
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillClients();
            }
        } else {
            showError("Please enter discipline");
        }
    };

    fillClients = async () => {
        const response = await handleRequest("GET", "/api/codes/type/client");
        if (Boolean(response.data)) {
            this.setState({
                clientSelectItems: response.data
            });
        }
    }

    addActionType = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "actionType"
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillActionTypes();
            }
        } else {
            showError("Please enter discipline");
        }
    };

    fillActionTypes = async () => {
        const response = await handleRequest("GET", "/api/codes/type/actionType");
        if (Boolean(response.data)) {
            this.setState({
                actionTypeSelectItems: response.data
            });
        }
    }


    actionTemplate(row) {

        return <React.Fragment>
            <CustomTransmittalMenu row={row} onDeleteRow={this.deleteModal.bind(this)} onEncode={this.encode.bind(this)} onReportRow={this.generateReport.bind(this)} onReviseRow={this.revise.bind(this)} onEditRow={this.edit.bind(this)} onShareRow={this.openShareModal.bind(this)} />
        </React.Fragment>;

    }

    generateReport = async (row) => {

        let url = "/api/transmittalLogs/report/" + row.id;

        const response = await handleRequest("GET", url);

        if (response.type === "ERROR") {
            error(response);
        }
        else
            if (Boolean(response.data) && response.data.length > 0) {
                this.setState({
                    pdfFile: response.data,
                    modalPdfViewer: true
                });
            } else {

                this.setState({
                    pdfFile: null,
                    modalPdfViewer: false
                });

                showError("There is no created report for selected item");
            }
    }



    handleExportExcel = async () => {

        this.setState({

            exportClick: true,
        });

    }

    ExcelDateToJSDate = (serial) => {

        if ((serial + "").includes(".")) {

            return moment(serial, "DD.MM.YYYY").toDate()
        }
        else {

            var hours = Math.floor((serial % 1) * 24);
            var minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
            return new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
        }

    }

    handleUploadExcel = async (excelJson) => {
        try {
            var list = [];
            if (excelJson === null)
                showError("Select an excel file");
            else {
                excelJson.forEach(element => {


                    const obj = {
                        date: this.ExcelDateToJSDate(element["Date"]),
                        disciplineStr: element["Discipline"],
                        drawingTypeStr: element["Drawing Type"],
                        drawingNo: element["Drawing No"],
                        shtNo: element["Sheet no"],
                        revNo: element["Rev no"],
                        index: element["Index"],
                        locationStr: element["Location"],
                        materialEquipmentStr: element["Material/Equipment"],
                        description: element["Description"],
                        status: element["Status"],
                        remarks: element["Remarks"]
                    }
                    list.push(obj);
                });
                if (Boolean(list) && list.length > 0) {
                    var response = await handleRequest("POST", "/api/transmittalLogs/import", list);
                    if (response.type === "ERROR") {
                        showError("No records to be added");
                    }
                    else {
                        this.resetInputs();
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                        showSuccess();
                        this.cancel();
                    }
                } else {
                    showError("No records to be added");
                }
            }
        } catch (error) {
            showError("");
        }
    }

    getUser = async (id) => {
        var list = [];
        if (Boolean(id)) {
            var response = await handleRequest("GET", "/api/users/list/" + id);
            if (response.type === "ERROR") {
                error(response);
            }
            else {
                let data = Boolean(response.data) ? response.data : [];
                console.log(data);
                data.forEach(element => {
                    list.push(element);
                });

                this.setState({
                    userSelectItems: list
                });
            }
        }
        else this.setState({
            userSelectItems: []
        });
    }

    openCodeModal = (type) => {

        this.setState({
            showCodeModal: true,
            codeType: type,

        })

    }

    addCode = async () => {

        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: this.state.codeType
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: null,
                    showCodeModal: false
                });
                this.fillCode();
            }
        } else {
            showError("Please type value");
        }

    }

    fillCode = async () => {
        const { codeType } = this.state;

        const response = await handleRequest("GET", "/api/codes/type/" + codeType);
        if (Boolean(response.data)) {
            this.setState({
                [codeType + "SelectItems"]: response.data
            });
        }
    }

    showRevisedList = async (row) => {

        let url = "/api/revisedtransmittallog/transmittallog/" + row.id;

        const response = await handleRequest("GET", url);

        if (response.type === "ERROR") {
            error(response);
        }
        else
            if (Boolean(response.data) && response.data.length > 0) {
                this.setState({
                    revisedFileList: response.data,
                    showRevisedListModal: true
                });
            } else {

                this.setState({
                    revisedFileList: [],
                    showRevisedListModal: false
                });

                showError("There is no revised list for selected item");
            }
    }


    share = async () => {

        const { shareFileId, selectedMailAddress } = this.state;

        const shareObject = {
            fileIds: [],
            emails: []
        }

        shareObject.fileIds.push(shareFileId);
        if (Boolean(selectedMailAddress) && selectedMailAddress.length > 0) {
            selectedMailAddress.forEach(element => {
                shareObject.emails.push(element);
            });


            var response = await handleRequest("POST", "/api/transmittalLogs/share", shareObject);

            if (response.type === "ERROR") {
                error(response);
            }
            else {

                showSuccess("Transmittal shared successfully");
                this.setState({
                    showShareModal: false,
                    shareFileId: null,
                    shareEmail: null,
                });
            }
        }
        else
            showError("Please type least one email address");



    }

    closeResponseModal = () => {

        this.setState({
            modalResponseShow: false
        })
        const { pagination } = this.state;
        this.restartTable({ pagination });
    }

    render() {
        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout2 = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
        };

        const renderToolbar = (toolbarSlot) => {
            return (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexGrow: 1,
                            flexShrink: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.previousPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.nextPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomOutButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomPopover}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomInButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.downloadButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.moreActionsPopover}
                        </div>
                    </div>
                </div>
            );
        };

        const props = {
            action: `${API_BASE_ROOT}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        const layoutView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
        ) => {
            return defaultLayout(
                isSidebarOpened,
                container,
                main,
                toolbar(renderToolbar),
                sidebar,
            );
        };

        const settingsColumns = [{

            title: "Settings", key: 'settings', render: (value, row, index) => {
                return row.settingsType;
            }

        }];

        const columns = [
            {
                title: 'S/N', key: 'id', render: (value, row, index) => {
                    return index + 1 + (this.state.pagination.pageSize * (this.state.pagination.current - 1));
                }
            },

            {
                title: 'Transmittal No', key: 'transmittalNo', render: (record) => {
                    const ts = this.state.transmittalSettings;
                    let result = Boolean(ts) ? ts.formatOfTransmittalNo + record.transmittalNo : record.transmittalNo;

                    return Boolean(record.path) && record.path.length > 0 ? (
                        <React.Fragment>
                            <div style={{
                                cursor: "pointer",
                                color: "blue",
                                textDecoration: "underline",
                            }}
                                onClick={(e) => this.downloadMultipleFile(record.path)}>
                                {result}
                            </div>
                        </React.Fragment>
                    ) : result;
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <InputNumber style={{ width: 150 }} value={Boolean(this.state.pagination.transmittalNo) ? this.state.pagination.transmittalNo : ""} placeholder="Transmittal No"
                            onChange={async (value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    transmittalNo: value,
                                    transmittalNoColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }}
                        />
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.transmittalNoColor) ? this.state.pagination.transmittalNoColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Rev. No', key: 'revNo', render: (record) => {
                    // return record.revNo;

                    return (
                        <React.Fragment>
                            <div style={{
                                cursor: "pointer",
                                color: "blue",
                                textDecoration: "underline",
                            }}
                                onClick={(e) => this.showRevisedList(record)}>
                                {record.revNo}
                            </div>
                        </React.Fragment>
                    );
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <InputNumber style={{ width: 100 }} value={Boolean(this.state.pagination.revNo) ? this.state.pagination.revNo : ""} placeholder="Rev. No"
                            onChange={async (value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    revNo: value,
                                    revNoColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })

                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }}
                        />
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.revNoColor) ? this.state.pagination.revNoColor : "#bfbfbf" }} />,
                sorter: true,
            },
            {
                title: 'Department', key: 'departmentId', render: (record) => {
                    if (Boolean(record.department)) {
                        return record.department.name
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Department"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    departmentId: value,
                                    departmentIdColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })

                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }

                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.departmentSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.departmentIdColor) ? this.state.pagination.departmentIdColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Discipline', key: 'disciplineId', render: (record) => {
                    if (Boolean(record.discipline)) {
                        return record.discipline.name
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Discipline"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    disciplineId: value,
                                    disciplineColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })

                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }

                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.disciplineSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.disciplineColor) ? this.state.pagination.disciplineColor : "#bfbfbf" }} />,
                sorter: true,
            },
            {
                title: 'Document Type', key: 'documentTypeId', render: (record) => {
                    if (Boolean(record.documentType)) {
                        return record.documentType.name
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Document Type"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    documentTypeId: value,
                                    documentTypeIdColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.documentTypeSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.documentTypeIdColor) ? this.state.pagination.documentTypeIdColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Document Code', key: 'documentCode', render: (record) => {
                    return record.documentCode
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: 200 }} placeholder="Document Code"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    documentCode: e.target.value,
                                    documentCodeColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }}
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.documentCodeColor) ? this.state.pagination.documentCodeColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Description', key: 'description', render: (record) => {
                    return record.description
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: 200 }} placeholder="Description"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    description: e.target.value,
                                    descriptionColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.descriptionColor) ? this.state.pagination.descriptionColor : "#bfbfbf" }} />,
                sorter: true,
            },

            // {
            //     title: 'By', key: 'byUserId', render: (record) => {
            //         return record.byUserName
            //     },
            //     filterDropdown: () => (
            //         <div style={{ padding: 8 }}>
            //             <Select
            //                 showSearch
            //                 optionFilterProp="children"
            //                 style={{ width: "100%" }} placeholder="By"
            //                 onChange={(value) => {
            //                     const { pagination } = this.state;
            //                     let _pagination = {
            //                         ...pagination,
            //                         byUserId: value,
            //                         byUserIdColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
            //                     };
            //                     this.setState({

            //                         pagination: _pagination
            //                     })

            //                     this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
            //                 }
            //                 }

            //             >
            //                 <Option key={null} value={null}>Select</Option>
            //                 {this.state.userSelectItems.map(i => (
            //                     <Option value={i.id}>{i.email}</Option>
            //                 ))}
            //             </Select>

            //         </div>),
            //     filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.byUserIdColor) ? this.state.pagination.byUserIdColor : "#bfbfbf" }} />,
            //     sorter: true,
            // },

            {
                title: 'Prepared By', key: 'preparedById', render: (record) => {
                    return record.preparedByName;
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="By"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    preparedById: value,
                                    preparedByIdColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })

                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }

                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.userSelectItems.map(i => (
                                <Option value={i.id}>{i.email}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.preparedByIdColor) ? this.state.pagination.preparedByIdColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Reviewed By', key: 'reviewedById', render: (record) => {
                    return record.reviewedByName;
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="By"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    reviewedById: value,
                                    reviewedByIdColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })

                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }

                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.userSelectItems.map(i => (
                                <Option value={i.id}>{i.email}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.reviewedByIdColor) ? this.state.pagination.reviewedByIdColor : "#bfbfbf" }} />,
                sorter: true,
            },
            {
                title: 'Submitted By', key: 'submittedById', render: (record) => {
                    return record.submittedByName;
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="By"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    submittedById: value,
                                    submittedByIdColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })

                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }

                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.userSelectItems.map(i => (
                                <Option value={i.id}>{i.email}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.submittedByIdColor) ? this.state.pagination.submittedByIdColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Approved By', key: 'approvedById', render: (record) => {
                    return record.approvedByName;
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="By"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    approvedById: value,
                                    approvedByIdColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })

                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }

                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.userSelectItems.map(i => (
                                <Option value={i.id}>{i.email}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.approvedByIdColor) ? this.state.pagination.approvedByIdColor : "#bfbfbf" }} />,
                sorter: true,
            },
            {
                title: 'To', key: 'toUserId', render: (record) => {
                    if (Boolean(record.toUser)) {
                        return record.toUser.name
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Client"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    toUserId: value,
                                    toUserIdColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })

                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }

                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.clientSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.toUserIdColor) ? this.state.pagination.toUserIdColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Action Type', key: 'actionTypeId', render: (record) => {
                    if (Boolean(record.actionType)) {
                        return record.actionType.name
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Action Type"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    actionTypeId: value,
                                    actionTypeIdColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })

                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }

                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.actionTypeSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.actionTypeIdColor) ? this.state.pagination.actionTypeIdColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Submittal Date', key: 'dateCreated', render: (record) => {
                    if (Boolean(record.dateCreated)) {
                        return moment(record.dateCreated).format("DD-MM-YYYY");
                    }
                }
            },

            {
                title: 'Response Date', render: (record) => {
                    if (Boolean(record.responseDate)) {
                        return moment(record.responseDate).format("DD-MM-YYYY");
                    }
                }
            },

            {
                title: 'Response', render: (record) => {
                    return record.response;
                }
            },

            {
                title: 'Status', render: (record) => {
                    return record.status;
                }
            },
            {
                title: 'Reference No', render: (record) => {
                    return record.referenceNo;
                }
            },

            {
                title: 'File', key: 'file', render: (record) => {

                    if (Boolean(record.responseFilePath))
                        return <AntButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadMultipleFile(record.responseFilePath)} type="primary" shape="round" icon={<DownloadOutlined />} >

                        </AntButton>

                },

            },

            {
                title: 'Remarks', render: (record) => {
                    return record.remarks;
                }
            },

            {
                title: 'Actions', key: 'actions', render: (record) => {
                    return this.actionTemplate(record);
                }
            },
        ];

        const tsFlag = this.state.transmittalSettings;
        const excelExport = <>
            {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
            {this.state.exportClick ?
                <ExcelFile filename="Transmittal Log" hideElement={true}>
                    <ExcelSheet name="Transmittal Log" data={this.state.tableList}>
                        <ExcelColumn label="S/N" value="sn" />
                        <ExcelColumn label="Transmittal No" value={(col) => Boolean(tsFlag) ? tsFlag.formatOfTransmittalNo + col.transmittalNo : col.transmittalNo} />
                        <ExcelColumn label="Rev. No" value="revNo" />
                        <ExcelColumn label="Department" value={(col) => Boolean(col.department) ? col.department.name : ""} />
                        <ExcelColumn label="Discipline" value={(col) => Boolean(col.discipline) ? col.discipline.name : ""} />
                        <ExcelColumn label="Document Type" value={(col) => Boolean(col.documentType) ? col.documentType.name : ""} />
                        <ExcelColumn label="Document Code" value="documentCode" />
                        <ExcelColumn label="Description" value="description" />
                        <ExcelColumn label="By" value={(col) => Boolean(col.byUserName) ? col.byUserName : ""} />
                        <ExcelColumn label="Prepared By" value={(col) => Boolean(col.preparedByName) ? col.preparedByName : ""} />
                        <ExcelColumn label="Reviewed By" value={(col) => Boolean(col.reviewedByName) ? col.reviewedByName : ""} />
                        <ExcelColumn label="Approved By" value={(col) => Boolean(col.approvedByName) ? col.approvedByName : ""} />
                        <ExcelColumn label="To" value={(col) => Boolean(col.toUser) ? col.toUser.name : ""} />
                        <ExcelColumn label="Action Type" value={(col) => Boolean(col.actionType) ? col.actionType.name : ""} />
                        <ExcelColumn label="Submittal Date" value={(col) => Boolean(col.dateCreated) ? moment(col.dateCreated).format("DD.MM.YYYY") : null} />
                        <ExcelColumn label="Submittal Date" value={(col) => Boolean(col.responseDate) ? moment(col.responseDate).format("DD.MM.YYYY") : null} />
                        <ExcelColumn label="Response" value="response" />
                        <ExcelColumn label="Status" value="status" />
                        <ExcelColumn label="Reference No" value="referenceNo" />
                        <ExcelColumn label="Remarks" value="remarks" />
                    </ExcelSheet>
                </ExcelFile> :
                <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="secondary" fontSize="large" />}></AntButton>
            } */}
        </>

        return (
            <div hidden={this.state.hideInputs} className="card card-custom" style={{ padding: '2rem' }}>

                <Form
                    initialValues={{ remember: false }}
                    onFinish={this.save}
                    onFinishFailed={onFinishFailed}
                    ref={this.formRef}>

                    <Row>
                        <Col xs={1}>
                            <AntButton style={{ border: "0px" }} onClick={() => this.onClickSettings()} icon={<SettingsIcon color="secondary" fontSize="large" />}></AntButton>
                        </Col>
                        <Col xs={20}></Col>
                        <Col xs={1}>
                            {/* <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader> */}
                            {excelExport}
                        </Col>
                        <Col xs={2}>
                            <AntButton hidden={!this.state.hiddenInputs} style={{ border: "0px" }} onClick={() => this.onClickCreateNew()} icon={<CreateIcon color="secondary" fontSize="large" />}></AntButton>
                            <AntButton hidden={this.state.hiddenInputs} style={{ border: "0px" }} onClick={() => this.onClickCreateNew()} icon={<RemoveCircle color="error" fontSize="large" />}></AntButton>
                        </Col>
                    </Row>

                    <div hidden={this.state.hiddenInputs}>

                        {/* {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Transmittal No" />}
                                name="transmittalNo"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Transmittal No" /> }]} >

                                <InputNumber style={{ width: '100%' }} min={0} placeholder="Transmittal No" />

                            </Form.Item>
                        }*/}

                        {/* {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Rev. No" />}
                                name="revNo"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Rev. No" /> }]} >

                                <InputNumber style={{ width: '100%' }} min={0} placeholder="Rev. No" />

                            </Form.Item>
                        } */}

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Department" />}
                                name="departmentId"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="This field is required" /> }]} >

                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }} placeholder="Department" id="DefinitionsType" value={this.state.departmentId} onChange={(value) => {
                                        this.formRef.current.setFieldsValue({
                                            departmentId: value
                                        });
                                        this.setState({ departmentId: value })
                                    }}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>

                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={() => this.openCodeModal("department")}>
                                                    <PlusOutlined /> Add New
                                                </a>
                                            </div>
                                        </div>
                                    )}

                                >
                                    <Option key={null} value={null}>Select</Option>
                                    {this.state.departmentSelectItems.map(i => (
                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                    ))}
                                </Select>


                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Discipline" />}
                                name="disciplineId"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="This field is required" /> }]} >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }} placeholder="Dsicipline" id="DefinitionsType"
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>

                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={() => this.openCodeModal("discipline")}>
                                                    <PlusOutlined /> Add New
                                                </a>
                                            </div>
                                        </div>
                                    )}

                                >
                                    <Option key={null} value={null}>Select</Option>
                                    {this.state.disciplineSelectItems.map(i => (
                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Document Type" />}
                                name="documentTypeId"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="This field is required" /> }]} >

                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }} placeholder="Document Type"
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>

                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={() => this.openCodeModal("documentType")}>
                                                    <PlusOutlined /> Add New
                                                </a>
                                            </div>
                                        </div>
                                    )}

                                >
                                    <Option key={null} value={null}>Select</Option>
                                    {this.state.documentTypeSelectItems.map(i => (
                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                    ))}
                                </Select>


                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Document Code" />}
                                name="documentCode"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type document code" /> }]} >

                                <Input placeholder="Document Code" />

                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Description" />}
                                name="description"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type description" /> }]} >

                                <Input placeholder="Description" />

                            </Form.Item>
                        }


                        {/* {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="By" />}
                                name="byUserId"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type document code" /> }]} >

                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Search with email"
                                    style={{ width: "100%" }} onSearch={this.searchUserMail}  >
                                    <Option key={null} value={null}>Select</Option>
                                    {this.state.userSelectItems.map(i => (
                                        <Option value={i.id}>{i.email}</Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        } */}

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="preparedById" defaultMessage="Prepared By" />}
                                name="preparedById"
                                rules={[{ required: false, message: <FormattedMessage id="preparedById" defaultMessage="Please type document code" /> }]} >

                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onSearch={this.searchUserMail}
                                    placeholder="Search with email"
                                    style={{ width: "100%" }}   >
                                    <Option key={null} value={null}>Select</Option>
                                    {this.state.userSelectItems.map(i => (
                                        <Option value={i.id}>{i.email}</Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="reviewedById" defaultMessage="Reviewed By" />}
                                name="reviewedById"
                                rules={[{ required: false, message: <FormattedMessage id="reviewedById" defaultMessage="Please type document code" /> }]} >

                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Search with email"
                                    style={{ width: "100%" }} onSearch={this.searchUserMail}  >
                                    <Option key={null} value={null}>Select</Option>
                                    {this.state.userSelectItems.map(i => (
                                        <Option value={i.id}>{i.email}</Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="submittedById" defaultMessage="Submitted By" />}
                                name="submittedById"
                                rules={[{ required: false, message: <FormattedMessage id="submittedById" defaultMessage="Please type document code" /> }]} >

                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Search with email"
                                    style={{ width: "100%" }} onSearch={this.searchUserMail}  >
                                    <Option key={null} value={null}>Select</Option>
                                    {this.state.userSelectItems.map(i => (
                                        <Option value={i.id}>{i.email}</Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="approvedById" defaultMessage="Approved By" />}
                                name="approvedById"
                                rules={[{ required: false, message: <FormattedMessage id="approvedById" defaultMessage="Please type document code" /> }]} >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Search with email"
                                    style={{ width: "100%" }} onSearch={this.searchUserMail}  >
                                    <Option key={null} value={null}>Select</Option>
                                    {this.state.userSelectItems.map(i => (
                                        <Option value={i.id}>{i.email}</Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="toUserId" defaultMessage="To" />}
                                name="toUserId"
                                rules={[{ required: true, message: <FormattedMessage id="toUserId" defaultMessage="This field is required" /> }]} >

                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }} placeholder="Client"
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>

                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={() => this.openCodeModal("client")}>
                                                    <PlusOutlined /> Add New
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    <Option key={null} value={null}>Select</Option>
                                    {this.state.clientSelectItems.map(i => (
                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                    ))}
                                </Select>


                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Action Type" />}
                                name="actionTypeId"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="This field is required" /> }]} >

                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }} placeholder="Action Type"
                                // dropdownRender={menu => (
                                //     <div>
                                //         {menu}
                                //         <Divider style={{ margin: '4px 0' }} />
                                //         <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>

                                //             <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                //                 onClick={() => this.openCodeModal("actionType")}>
                                //                 <PlusOutlined /> Add New
                                //             </a>
                                //         </div>
                                //     </div>
                                // )}

                                >
                                    <Option key={null} value={null}>Select</Option>
                                    {this.state.actionTypeSelectItems.map(i => (
                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                    ))}
                                </Select>


                            </Form.Item>
                        }

                        {Boolean(this.state.logDetailId) &&
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="File" />}
                                name="file"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please upload file" /> }]}
                            >
                                <> {this.state.logFileName} <AntButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile2()} type="primary" shape="round" icon={<DownloadOutlined />} ></AntButton></>
                            </Form.Item>
                        }

                        {!Boolean(this.state.logDetailId) &&
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Upload" />}
                                name="path"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please upload file" /> }]}
                            >
                                <Upload {...props} id="path"
                                    accept=".png,.PNG,.jpeg,.JPEG,.jpg,.JPG,.pdf,.PDF"
                                    showUploadList={true}
                                    multiple
                                    fileList={this.state.fileList}
                                    onChange={({ fileList }) => {
                                        var filePaths = [];
                                        const stateFileList = this.state.fileList;
                                        if (fileList.length >= 1) {
                                            for (let index = 0; index < fileList.length; index++) {

                                                if (Boolean(fileList[index].response)) {
                                                    filePaths[index] = fileList[index].response.url;
                                                    fileList[index].url = fileList[index].response.url;

                                                    if (filePaths.length === fileList.length && fileList.length > stateFileList.length)
                                                        showSuccess("Files uploaded successfully");

                                                } else
                                                    if (Boolean(fileList[index].url)) {
                                                        filePaths[index] = fileList[index].url;

                                                    }

                                            }

                                        }

                                        this.setState({
                                            fileList: fileList,
                                            filePaths: filePaths,
                                        });

                                    }}
                                    beforeUpload={(fileList) => {

                                        return true;
                                    }}
                                    onRemove={({ fileList }) => {


                                    }
                                    }>
                                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                        <UploadOutlined /> Upload
                                    </AntButton>
                                </Upload>
                            </Form.Item>
                        }

                        {!Boolean(this.state.logDetailId) &&
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Cover Page" />}
                                name="path"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please upload file" /> }]}
                            >
                                <Upload {...props} id="path"
                                    accept=".png,.PNG,.jpeg,.JPEG,.jpg,.JPG,.pdf,.PDF"
                                    showUploadList={true}
                                    multiple
                                    fileList={this.state.fileListCoverPage}
                                    onChange={({ fileList }) => {
                                        var filePaths = [];
                                        const stateFileList = this.state.fileList;
                                        if (fileList.length >= 1) {
                                            for (let index = 0; index < fileList.length; index++) {

                                                if (Boolean(fileList[index].response)) {
                                                    filePaths[index] = fileList[index].response.url;
                                                    fileList[index].url = fileList[index].response.url;

                                                    if (filePaths.length === fileList.length && fileList.length > stateFileList.length)
                                                        showSuccess("Files uploaded successfully");

                                                } else
                                                    if (Boolean(fileList[index].url)) {
                                                        filePaths[index] = fileList[index].url;

                                                    }

                                            }

                                        }

                                        this.setState({
                                            fileListCoverPage: fileList,
                                            filePathsCoverPage: filePaths,
                                        });

                                    }}
                                    beforeUpload={(fileList) => {

                                        return true;
                                    }}
                                    onRemove={({ fileList }) => {


                                    }
                                    }>
                                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                        <UploadOutlined /> Cover Page
                                    </AntButton>
                                </Upload>
                            </Form.Item>
                        }



                        <div hidden={this.state.hideSave}>
                            {
                                <Row gutter={8}>
                                    <Col span={8}></Col>

                                    <Col span={8}>
                                        {
                                            <Button id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                        }
                                    </Col>
                                </Row>

                            }
                        </div>

                        <div hidden={this.state.hideUpdate}>
                            {
                                <Row gutter={8}>
                                    <Col span={8}></Col>

                                    <Col span={4}>
                                        <Button id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                                    </Col>
                                    <Col span={4}>
                                        <Button id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                                    </Col>

                                </Row>
                            }
                        </div>

                        {Boolean(this.state.id) && Boolean(this.state.selectedRow) && Boolean(this.state.selectedRow.response) && this.state.selectedRow.response.trim().length > 0 &&
                            <Row gutter={8} style={{ marginTop: '2rem' }}>
                                <Col span={8}> </Col>
                                <Col sm="1">
                                    <AntButton type="primary" hidden={!this.state.hideResponse} onClick={() => { this.setState({ hideResponse: false }) }} icon={<PlusOutlined />}></AntButton>
                                    <AntButton type="primary" hidden={this.state.hideResponse} onClick={() => { this.setState({ hideResponse: true }) }} danger icon={<MinusOutlined />}></AntButton>
                                </Col>
                                <Col span={8}>  <FormattedMessage style={{ fontWeight: 'bold' }} id="TransmittalResponse" defaultMessage="Response" /> </Col>

                            </Row>}
                        {!this.state.hideResponse &&
                            <Row gutter={8}>
                                <Col span={24}>
                                    {Boolean(this.state.id) && Boolean(this.state.selectedRow) && Boolean(this.state.selectedRow.response) && this.state.selectedRow.response.trim().length > 0 && <TransmittalResponse transmittalLogId={Boolean(this.state.selectedRow) ? this.state.selectedRow.id : null} closeModal={this.closeResponseModal.bind(this)} close={Boolean(this.cancel) ? this.cancel.bind(this) : null} ></TransmittalResponse>}
                                </Col>

                            </Row>
                        }
                    </div>
                </Form>

                <div style={{ marginTop: '2rem', marginBottom: '1rem' }} className="content-section implementation">
                    <Table
                        bordered
                        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                        columns={columns}
                        dataSource={this.state.tableList}
                        loading={this.state.loading} onChange={this.handleTableChange}
                        pagination={this.state.pagination}
                    />
                </div>

                <Modal
                    show={this.state.showCodeModal}
                    onHide={() => {
                        this.setState({
                            showCodeModal: false
                        })
                    }}

                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> Add item to dropdown </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Input placeholder="Type Value" id="modalCodeName" value={this.state.codeName} onChange={(e) => this.setState({ codeName: e.target.value })} />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showCodeModal: false,
                                codeName: null
                            })
                        }}>Close</Button>

                        <Button variant="primary" onClick={this.addCode} > Save </Button>

                    </Modal.Footer>
                </Modal>


                <Modal
                    show={this.state.showRevisedListModal}
                    onHide={() => {
                        this.setState({
                            showRevisedListModal: false
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Revised List</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table
                            bordered
                            scroll={{ x: 400, scrollToFirstRowOnChange: true }}
                            columns={columns.filter(p => p.key !== "actions")}
                            dataSource={this.state.revisedFileList} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showRevisedListModal: false
                            })
                        }}>Close</Button>

                    </Modal.Footer>
                </Modal>



                <Modal
                    show={this.state.showSettingsModal}
                    onHide={() => {
                        this.setState({
                            showSettingsModal: false
                        })
                    }}

                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="xl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> Transmittal Settings </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div style={{ marginTop: '2rem', marginBottom: '1rem' }} className="content-section implementation">
                            <Table
                                size="small"

                                columns={settingsColumns}
                                dataSource={this.state.settingsData}

                                expandable={{
                                    expandedRowRender: record => record.settingsType === "General" ? <TransmittalSetting  ></TransmittalSetting> : <TransmittalOfficial officialType={record.officialType}  ></TransmittalOfficial>,
                                    rowExpandable: () => { return true },
                                }}
                            />
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showSettingsModal: false,

                            })
                        }}>Close</Button>


                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalDeleteShow}
                    onHide={this.showOrHideDeleteModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</Button>
                        <Button variant="danger" onClick={this.delete} >Delete</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalResponseShow}
                    onHide={() => {
                        this.setState({
                            modalResponseShow: false
                        })
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="xl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> Transmittal Response</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TransmittalResponse transmittalLogId={Boolean(this.state.selectedRow) ? this.state.selectedRow.id : null} closeModal={this.closeResponseModal.bind(this)} ></TransmittalResponse>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="warning" onClick={() => {
                            this.setState({
                                modalResponseShow: false
                            });
                            const { pagination } = this.state;
                            this.restartTable({ pagination });
                        }} >Close</Button>

                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showShareModal}
                    onHide={() => {
                        this.setState({
                            showShareModal: false
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> Share Transmittal </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <Input placeholder="Email" id="todo" value={this.state.shareEmail} onChange={(e) => this.setState({ shareEmail: e.target.value })} /> */}
                        <Select
                            showSearch
                            placeholder="Search with email"
                            style={{ width: "100%" }} mode="tags" onSearch={this.searchUserMail} id="selectedCompanyIds" value={this.state.selectedMailAddress} onChange={(value) => {

                                this.setState({ selectedMailAddress: value })
                            }}>
                            <Option key={null} value={null}>Select</Option>
                            {this.state.userSelectItems.map(i => (
                                <Option value={i.email}>{i.email}</Option>
                            ))}
                        </Select>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showShareModal: false
                            })
                        }}>Close</Button>

                        <Button variant="info" onClick={this.share} > Share </Button>

                    </Modal.Footer>
                </Modal>

                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            PDF  Viewer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                            <div style={{ height: "900px" }}>
                                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                            </div>
                        </Worker>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalPdfViewer}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        // auth: state.auth,
        logout: state.logout,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TransmittalLog)
// export default connect(null, auth.actions)(Domain);
