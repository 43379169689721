import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { error, showSuccess, showError, showWarning } from "../../MessageHelper";
import { Table, Tooltip, Radio, Form, Upload, Button as AntButton, Badge, InputNumber, Space, Tag, Input } from "antd";
import moment from "moment";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DraggableModal from "../../Components/DraggableModal";

export class MaintenanceLogProcess extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      controllerName: "maintenanceLogProcess",
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      tableAddResultList: [],
      sampleSize: 0,
      errorCount: 0,
      samplingCount: 0,

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      paginationAddResult: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,

        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"]
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      searchCriteriaModels: [],
      excelData: []
    };
  }

  formRef = React.createRef();
  formAddResultList = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    // this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = (row) => {
    document.getElementById("kt_scrolltop").click();

    // this.formRef.current.setFieldsValue({
    //   ...row,
    // });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  applyMaintenance = (row) => {
    this.setState(
      {
        selectedChecklistRowData: row
      }, () => {
        this.multipleChecklistModel();
      }
    );
  };

  multipleChecklistModel = async (param = {}) => {
    var row = this.state.selectedChecklistRowData;
    this.setState({ loadingChecklistMultiple: true, loadingAddResult: true });
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getAddResultItem/" + row.id + "/" + this.state.samplingCount);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loadingChecklistMultiple: false,
        loadingAddResult: false
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach((element, index) => {
          element.id = index + 1;
        });
        this.setState({
          tableAddResultList: response.data,
          showResultModal: true,
          modalOnebyoneShow: true,
          modalProcessTypeShow: false,
          loadingChecklistMultiple: false,
          loadingAddResult: false,
          selectedRowKeys: []
        });
      } else {
        this.setState({
          tableAddResultList: [],
          showResultModal: true,
          modalOnebyoneShow: true,
          modalProcessTypeShow: false,
          loadingChecklistMultiple: false,
          loadingAddResult: false,
          selectedRowKeys: []
        });
      }
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);

    let urladdAllByWithControlPlan = "/api/" + this.state.controllerName + "/addAllByWithControlPlan";
    const responseaddAllByWithControlPlan = await handleRequest("POST", urladdAllByWithControlPlan, newObj);
    if (responseaddAllByWithControlPlan.type === "ERROR") {
      error(responseaddAllByWithControlPlan);
    } else {
      let url = "/api/" + this.state.controllerName + "/paging";
      const response = await handleRequest("POST", url, newObj);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      } else {
        if (response.data.length !== 0) {
          let list = response.data;
          list.forEach((element, index) => {
            element.index = index + 1;
          });
          this.setState({
            loading: false,
            tableList: list,
            pagination: {
              ...params.pagination,
              total: response.data.length
            },
            totalRecords: response.data.length,
            sortField: params.sortField,
            sortOrder: params.sortOrder
          });
        } else {
          this.setState({
            loading: false,
            tableList: [],
            pagination: {
              ...params.pagination,
              total: 0
            },
            totalRecords: 0,
            sortField: "",
            sortOrder: ""
          });
        }
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      equipmentLogId: this.props.equipmentLogId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteEquipmentMaintanance/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let applyMaintenanceAction = {
      name: "Apply maintenance",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.applyMaintenance.bind(this)
    };
    let actionList = [];
    actionList.push(applyMaintenanceAction);
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  handleTableChangeAddResult = (pagination, filters, sorter) => {
    this.setState({
      paginationAddResult: pagination
    });
  };

  handleFormMultipleListSubmit = async values => {
    const { sampleNo, sampleSize, samplingDetailId, samplingDetailListId } = this.state;
    var newItem = {
      ...values,
      itemNo: this.state.selectedAddResultAnswer.itemNo,
      inspectionLogItemId: this.state.selectedChecklistRowData.id,
      inspectionLogItemType: this.state.inspectionLogItemType
      // sampleSize: this.state.getChecklistItem.sampleNo
    };
    console.log("newItem", newItem);
    console.log("selectedChecklistRowData", this.state.selectedChecklistRowData);

    let list = [];
    if (Boolean(values)) {
      Object.entries(values).forEach(([anahtar, deger]) => {
        if (anahtar !== "result") {
          list.push({
            dimension: anahtar,
            dimensionValue: parseFloat(deger),
            samplingDetailId: samplingDetailId,
            samplingDetailListId: samplingDetailListId
          });
        }
      });
    }
    console.log("list", list);
    if (
      Boolean(list) &&
      list.length > 0 &&
      Boolean(newItem.inspectionLogItemId) &&
      newItem.inspectionLogItemId > 0 &&
      Boolean(newItem.inspectionLogItemType)
    ) {
      var response = await handleRequest(
        "POST",
        "/api/" +
        this.state.controllerName +
        "/saveInspectionLogItemDimensionResult/" +
        newItem.inspectionLogItemId +
        "/" +
        newItem.inspectionLogItemType +
        "/" +
        sampleNo,
        list
      );
      if (response.type === "ERROR") {
        error(response);
      } else {
        var tableAddResultList = this.state.tableAddResultList;
        var row = this.state.tableAddResultList.find(p => p.itemNo === newItem.itemNo);
        if (Boolean(row)) {
          row.result = newItem.result;
        }

        this.setInspectionChecklistResultObj(values, this.state.selectedAddResultAnswer, newItem);
        let sampleSuccess = false;
        this.setState(
          {
            showResultDimensionalAnswerModal: false,
            selectedRowKeys: [],
            tableAddResultList: tableAddResultList
            // showInspectionResultSuccessModal: true
          },
          () => {
            // showSuccess();
            if (sampleSize > sampleNo) {
              this.setState(
                {
                  sampleNo: sampleNo + 1
                },
                () => {
                  this.setState(
                    {
                      showResultDimensionalAnswerModal: true
                    },
                    () => {
                      const { dynamicAttributes, selectedAddResultAnswer } = this.state;
                      if (Boolean(this.formAddResultList) && Boolean(this.formAddResultList.current)) {
                        this.formAddResultList.current.setFieldsValue({
                          ...selectedAddResultAnswer
                        });

                        dynamicAttributes.forEach(element => {
                          this.formAddResultList.current.setFieldsValue({
                            [element.label]: element.value
                          });
                        });
                      }
                    }
                  );
                }
              );
            }
            else {
              sampleSuccess = true;
            }
          }
        );

        let updatedErrorCount = this.state.errorCount;
        if (this.state.errorResponse) {
          updatedErrorCount++;
        }

        if (updatedErrorCount > this.state.samplingConformingItems) {
          showWarning("maxnonconforming value exceeded");
          this.multipleChecklistModel();
          this.setState(prevState => ({
            errorCount: 0,
            samplingCount: prevState.samplingCount + 1,
            showResultDimensionalAnswerModal: false,
            showInspectionResultModal: true
          }));
        } else if (sampleSuccess && updatedErrorCount > 0) {
          this.setState({
            errorCount: 0,
            errorResponse: false,
            samplingCount: 0,
            showInspectionResultHataSuccessModal: true
          });
        } else if (sampleSuccess) {
          this.setState({
            errorCount: 0,
            errorResponse: false,
            samplingCount: 0,
            showInspectionResultSuccessModal: true
          });
        } else {
          this.setState({
            errorCount: updatedErrorCount,
            errorResponse: false,
            samplingCount: 0
          });
        }
      }
    } else {
      showError("Inspection or type not found");
    }
  };

  setInspectionChecklistResultObj = (values, data, newItem) => {
    if (Boolean(data.answerType)) {
      var options = [];
      if (Boolean(values.options) && values.options.length > 0) {
        values.options.forEach(element => {
          if (Boolean(data.questionnary) && Boolean(data.questionnary.options) && data.questionnary.options.length > 0) {
            var obj = data.questionnary.options.find(p => p.codeId === element);
            if (Boolean(obj)) {
              options.push({
                ["codeId"]: element,
                ["codeName"]: obj.codeName,
                ["value"]: true
              });
            }
          }
        });
        newItem.options = options;
      } else if (Boolean(values.options)) {
        if (Boolean(data.questionnary) && Boolean(data.questionnary.options) && data.questionnary.options.length > 0) {
          var obj = data.questionnary.options.find(p => p.codeId === values.options);
          if (Boolean(obj)) {
            options.push({
              ["codeId"]: values.options,
              ["codeName"]: obj.codeName,
              ["value"]: true
            });
          }
        }
        newItem.options = options;
      }

      var imageOptions = [];
      if (Boolean(values.imageOptions)) {
        imageOptions.push({
          ["imageUrl"]: values.imageOptions,
          ["value"]: true
        });
        newItem.imageOptions = imageOptions;
      }
    }

    newItem.filePath = Boolean(this.state.fileList) && this.state.fileList.length > 0 ? this.state.fileList[0].url : "";
  };

  saveMultipleResultList = async () => {
    this.formAddResultList.current.submit();
  };

  nextClick = () => {
    const { tableAddResultIndex, tableAddResultList } = this.state;

    if ((tableAddResultIndex < tableAddResultList.length - 1)) {
      this.setState({ tableAddResultIndex: tableAddResultIndex + 1 });
      let id = [];
      id.push(tableAddResultList[tableAddResultIndex + 1].id);
      this.onSelectChangeAddResult(id);
    }
    this.setState({
      showNonConformingSamplesModal: false,
      showInspectionResultModal: false
    });
  }

  onSelectChangeAddResult = async selectedRowKeys => {
    console.log("selectedRowKeys", selectedRowKeys);
    if (Boolean(this.formAddResultList) && Boolean(this.formAddResultList.current)) {
      this.formAddResultList.current.resetFields();
    }
    if (Boolean(selectedRowKeys) && selectedRowKeys.length === 1) {
      var row = this.state.tableAddResultList.find(p => p.id === selectedRowKeys[0]);
      console.log("row", row);
      let { selectedChecklistRowData } = this.state;
      console.log("selectedChecklistRowData", selectedChecklistRowData);

      if (Boolean(row)) {
        if (Boolean(row.inspectionLogItemType)) {
          let dynamicAttributes = [];
          let chemicalCompositionList = [];

          if (row.inspectionLogItemType === "Dimension_Control") {
            if (Boolean(selectedChecklistRowData) && Boolean(selectedChecklistRowData.allDimensions)) {
              let dimensionList = selectedChecklistRowData.allDimensions.split(", ");
              console.log("dimensionList", dimensionList);

              if (Boolean(dimensionList) && dimensionList.length > 0) {
                dimensionList.forEach(element => {
                  let dimension = element.split(":");
                  if (Boolean(dimension) && dimension.length > 1) {
                    let obj = {
                      label: dimension[0],
                      value: dimension[1]
                    };
                    dynamicAttributes.push(obj);
                  }
                });
              }
            }
          } else if (row.inspectionLogItemType === "Chemical_Composition") {
            // var responseCompanyProjectId = await handleRequest(
            //   "GET",
            //   "/api/" + this.state.controllerName + "/chemicalComposition/" + selectedChecklistRowData.id
            // );
            // if (responseCompanyProjectId.type === "ERROR") {
            //   error(responseCompanyProjectId);
            // }
            // chemicalCompositionList = Boolean(responseCompanyProjectId.data) ? responseCompanyProjectId.data : [];
            // console.log("chemicalCompositionList", chemicalCompositionList);
          }
          this.setState(
            {
              sampleNo: 1,
              sampleSize: Boolean(row) && Boolean(row.sampleNo) && row.sampleNo > 0 ? row.sampleNo : 0,
              samplingConformingItems:
                Boolean(row) && Boolean(row.samplingConformingItems) && row.samplingConformingItems > 0 ? row.samplingConformingItems : 0,
              samplingDetailId: Boolean(row) && Boolean(row.samplingDetailId) && row.samplingDetailId > 0 ? row.samplingDetailId : null,
              samplingDetailListId:
                Boolean(row) && Boolean(row.samplingDetailListId) && row.samplingDetailListId > 0 ? row.samplingDetailListId : null,
              showInspectionInfo: true,
              // showResultDimensionalAnswerModal: true,
              inspectionLogItemType: row.inspectionLogItemType,
              selectedAddResultAnswer: row,
              dynamicAttributes: dynamicAttributes,
              elementAttributes: chemicalCompositionList,
              fileList: this.getFileList(row)
            },
            () => {
              console.log("dynamicAttributes", this.state.dynamicAttributes);
              if (Boolean(this.formAddResultList) && Boolean(this.formAddResultList.current)) {
                this.formAddResultList.current.setFieldsValue({
                  ...row
                });

                dynamicAttributes.forEach(element => {
                  this.formAddResultList.current.setFieldsValue({
                    [element.label]: element.value
                  });
                });
              }
            }
          );
        } else {
          showError("Type not found.");
        }
      } else {
        showError("Row not found.");
      }
    }
    this.setState({ selectedRowKeys });
  };

  getFileList(row) {
    var fileList = [];
    let filePath = Boolean(row.filePath) ? row.filePath.split(",") : [];
    if (Boolean(filePath) && filePath.length > 0) {
      filePath.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileList.push(obj);
      });
    }
    return fileList;
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search and click enter`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: filtered => <FilterOutlined style={{ color: filtered ? "#1890ff" : "#bfbfbf" }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  saveMaintenanceLogProcess = async (result, rejectedAmount) => {
    let obj = {
      result: result
    };
    if (Boolean(rejectedAmount) && rejectedAmount > 0) {
      obj = {
        result: result,
        rejectedAmount: rejectedAmount
      };
    }
    let response = await handleRequest(
      "PUT",
      "/api/" + this.state.controllerName + "/saveMaintenanceLogProcess/" + this.state.selectedChecklistRowData.id,
      obj
    );

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showResultModal: false,
        showChecklistModal: false
      });
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.resetInputs();
    }
  };

  render() {
    const layout = {
      labelCol: { span: 0 },
      wrapperCol: { span: 16 }
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const { loading, selectedRowKeys } = this.state;
    const rowSelectionAddResult = {
      selectedRowKeys,
      onChange: this.onSelectChangeAddResult
    };

    const columns = [
      {
        key: "key",
        render: record => {
          if (Boolean(record) && (record.result === "Accept" || record.result === "Inspected")) {
            return null;
          }
          return this.action(record);
        }
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLogProcess.Result" defaultMessage="Result" />,
        key: "result",
        render: record => {
          if (Boolean(record.result)) {
            if (record.result === "Accept" || record.result === "Inspected") {
              return (
                <Tag color="green">
                  <FormattedMessage id="MaintenanceLogProcess.Inspected" defaultMessage="Inspected" />
                </Tag>
              );
            } else if (record.result === "Reject") {
              return (
                <Tag color="red">
                  <FormattedMessage id="MaintenanceLogProcess.Reject" defaultMessage="Reject" />
                </Tag>
              );
            } else if (record.result === "OnHold") {
              return (
                <Tag color="blue">
                  <FormattedMessage id="MaintenanceLogProcess.OnHold" defaultMessage="On Hold" />
                </Tag>
              );
            }
          }
          return "";
        },
      },
      {
        title: <FormattedMessage id="MaintenanceLogExpanded.MaintenanceControlPlan" defaultMessage="Maintenance Control Plan" />,
        key: "maintenanceControlPlanDetailId",
        render: record => {
          return record.maintenanceControlPlanDetailName;
        }, ...getColumnFilter({
          inputName: "maintenanceControlPlanDetailId", inputType: "SELECT",
          selectItems: Boolean(this.state["maintenanceControlPlanDetailIdSelectItems"]) && this.state["maintenanceControlPlanDetailIdSelectItems"].length > 0 ? this.state["maintenanceControlPlanDetailIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="MaintenanceLogExpanded.PeriodOfMaintenance" defaultMessage="Period Of Maintenance" />,
        key: "periodOfMaintenance",
        render: record => {
          let list = [];
          if (Boolean(record.periodOfMaintenance)) {
            list.push(record.periodOfMaintenance);
          }
          if (Boolean(record.periodOfMaintenanceTypeName)) {
            list.push(record.periodOfMaintenanceTypeName);
          }
          return list.join(" ");
        }, ...getColumnFilter({
          inputName: "periodOfMaintenance", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="MaintenanceLogExpanded.StartDate" defaultMessage="Start Date" />,
        key: "startDate",
        render: record => {
          return Boolean(record.startDate) ? moment(record.startDate).format("DD-MM-YYYY") : "";
        }, ...getColumnFilter({
          inputName: "startDate", inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="MaintenanceLogExpanded.MaintenanceLocation" defaultMessage="Maintenance Location" />,
        key: "maintenanceLocation",
        render: record => {
          return record.maintenanceLocationName;
        }, ...getColumnFilter({
          inputName: "maintenanceLocation", inputType: "SELECT",
          selectItems: Boolean(this.state["maintenanceLocationSelectItems"]) && this.state["maintenanceLocationSelectItems"].length > 0 ? this.state["maintenanceLocationSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      }
    ];

    const columnsAddResult = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.paginationAddResult.pageSize * (this.state.paginationAddResult.current - 1);
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLogProcess.itemNo" defaultMessage="Status" />,
        key: "samplingStatus",
        width: 100,
        render: record => {
          return "";
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLogProcess.Quantity" defaultMessage="Project" />,
        key: "project",
        width: 100,
        render: record => {
          return record.project;
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLogProcess.itemNo" defaultMessage="Item" />,
        key: "item",
        width: 100,
        render: record => {
          return record.item;
        }
        // ...this.getColumnSearchProps("itemNo"),
        // sorter: (a, b) => Boolean(a.itemNo) && Boolean(b.itemNo) && a.itemNo.localeCompare(b.itemNo)
      },
      {
        title: <FormattedMessage id="MaintenanceLogProcess.Quantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        render: record => {
          return record.quantity;
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLogProcess.Requirement" defaultMessage="Requirement" />,
        key: "requirement",
        width: 100,
        render: record => {
          return record.requirement;
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLogProcess.InspectionType" defaultMessage="Inspection Type" />,
        key: "inspectionType",
        width: 100,
        render: record => {
          return record.inspectionType;
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLogProcess.InspectionType" defaultMessage="Acceptance Criteria" />,
        key: "acceptanceCriteria",
        width: 100,
        render: record => {
          return record.acceptanceCriteria;
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLogProcess.Result" defaultMessage="Result" />,
        key: "",
        width: 100,
        render: record => {
          if (Boolean(record.result)) {
            if (record.result === "Accept") {
              return (
                <Tag color="green">
                  <FormattedMessage id="MaintenanceLogProcess.Accept" defaultMessage="Accept" />
                </Tag>
              );
            } else if (record.result === "Reject") {
              return (
                <Tag color="red">
                  <FormattedMessage id="MaintenanceLogProcess.Reject" defaultMessage="Reject" />
                </Tag>
              );
            } else if (record.result === "OnHold") {
              return (
                <Tag color="blue">
                  <FormattedMessage id="MaintenanceLogProcess.OnHold" defaultMessage="On Hold" />
                </Tag>
              );
            }
          }
          return "";
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLogProcess.InspectionType" defaultMessage="Photo" />,
        key: "photo",
        width: 100,
        render: record => {
          return record.photo;
        }
      }

      // {
      //   title: <FormattedMessage id="MaintenanceLogProcess.Question" defaultMessage="Question" />,
      //   key: "question",
      //   width: 100,
      //   ellipsis: true,
      //   render: record => {
      //     return record.question;
      //   },
      //   ...this.getColumnSearchProps("question"),
      //   sorter: (a, b) => Boolean(a.question) && Boolean(b.question) && a.question.localeCompare(b.question)
      // },

      // {
      //   title: <FormattedMessage id="MaintenanceLogProcess.AcceptanceCondition" defaultMessage="Acceptance Condition" />,
      //   key: "",
      //   width: 100,
      //   render: record => {
      //     return "";
      //   }
      // },
      // {
      //   title: <FormattedMessage id="MaintenanceLogProcess.Referans" defaultMessage="Referans" />,
      //   key: "reference",
      //   width: 100,
      //   render: record => {
      //     return record.reference;
      //   },
      //   ...this.getColumnSearchProps("reference"),
      //   sorter: (a, b) => Boolean(a.reference) && Boolean(b.reference) && a.reference.localeCompare(b.reference)
      // },
      // {
      //   title: <FormattedMessage id="MaintenanceLogProcess.ControlValue" defaultMessage="Control Value" />,
      //   key: "answer",
      //   width: 100,
      //   render: record => {
      //     if (Boolean(record.answer) && Boolean(record.answerType) && record.answerType === "Imagepicker") {
      //       return <Image width={80} src={record.answer}></Image>;
      //     } else if (Boolean(record.filePath)) {
      //       return (
      //         <AntButton
      //           style={{
      //             marginLeft: "2px",
      //             marginTop: "-2px",
      //             alignContent: "center"
      //           }}
      //           onClick={() => this.downloadFile(record.filePath)}
      //           type="primary"
      //           shape="round"
      //           icon={<DownloadOutlined />}
      //         ></AntButton>
      //       );
      //     }
      //     return record.answer;
      //   }
      // },
      // {
      //   title: <FormattedMessage id="MaintenanceLogProcess.Result" defaultMessage="Result" />,
      //   key: "",
      //   width: 100,
      //   render: record => {
      //     if (Boolean(record.result)) {
      //       if (record.result === "Accept") {
      //         return (
      //           <Tag color="green">
      //             <FormattedMessage id="MaintenanceLogProcess.Accept" defaultMessage="Accept" />
      //           </Tag>
      //         );
      //       } else if (record.result === "Reject") {
      //         return (
      //           <Tag color="red">
      //             <FormattedMessage id="MaintenanceLogProcess.Reject" defaultMessage="Reject" />
      //           </Tag>
      //         );
      //       } else if (record.result === "OnHold") {
      //         return (
      //           <Tag color="blue">
      //             <FormattedMessage id="MaintenanceLogProcess.OnHold" defaultMessage="On Hold" />
      //           </Tag>
      //         );
      //       }
      //     }
      //     return "";
      //   }
      // }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        {
          Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 &&
          (<div className="alert alert-custom alert-light">                 <div className="alert-icon">
            <FilterOutlined />
          </div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (
                  <div key={i}
                    className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">
                      {val.label}
                    </span>
                    <strong className="mr-2">
                      {val.type}
                    </strong>
                    <span className="mr-2">
                      {val.operationValue}
                    </span>
                    <span className="mr-2">
                      {val.showValue}
                    </span>
                    <span>
                      <Tooltip title="Remove">
                        <CloseCircleOutlined style={{ color: "crimson" }}
                          onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>);
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined
                  style={{ color: "crimson" }}
                  onClick={() => this.setState({
                    searchCriteriaModels: []
                  }, () => {
                    this.filterChangeItem([]);
                  })} />
              </Tooltip>
            </div>
          </div>)
        }

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            rowKey="id"
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            pagination={false}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <DraggableModal
          title={<FormattedMessage id="MaintenanceLogProcess.ChecklistResult" defaultMessage="Checklist Result" />}
          open={this.state.showResultModal}
          onCancel={() => {
            this.setState({ showResultModal: false });
          }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          width={1400}
          centered
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showResultModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>

              <Button id="AcceptButton" onClick={() => this.saveMaintenanceLogProcess("Accept")} style={{ width: "100%" }} variant="success">
                <FormattedMessage id="MaintenanceLogProcess.Accept" defaultMessage="Accept" />
              </Button>
              {/* <Button id="RejectButton" onClick={this.onClickMultipleItemRejected} style={{ width: "100%" }} variant="danger">
                  <FormattedMessage id="MaintenanceLogProcess.Reject" defaultMessage="Reject" />
                </Button>
                <Button id="OnHoldButton" onClick={() => this.saveMaintenanceLogProcess("OnHold")} style={{ width: "100%" }} variant="primary">
                  <FormattedMessage id="MaintenanceLogProcess.OnHold" defaultMessage="On Hold" />
                </Button> */}
            </Space>
          ]}
          content={
            <Table
              bordered
              rowKey={record => record.id}
              rowSelection={{
                type: "radio",
                ...rowSelectionAddResult
              }}
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columnsAddResult}
              dataSource={this.state.tableAddResultList}
              loading={this.state.loadingAddResult}
              onChange={this.handleTableChangeAddResult}
              pagination={this.state.paginationAddResult}
            />
          }
        />

        <DraggableModal
          open={this.state.showResultDimensionalAnswerModal}
          keyboard={false}
          maskClosable={false}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ showResultDimensionalAnswerModal: false, selectedRowKeys: [] })}
          width={800}
          centered
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showResultDimensionalAnswerModal: false,
                    selectedRowKeys: []
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="success" onClick={this.saveMultipleResultList}>
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </Button>
            </Space>
          ]}
          content={
            <Badge.Ribbon
              text={"Sample:" + " " + this.state.sampleNo + " / " + this.state.sampleSize}
              placement="start"
              color="purple"
              style={{ fontSize: 12 }}
            >
              <Form
                id="FormAddResultList"
                initialValues={{ remember: false }}
                onFinish={this.handleFormMultipleListSubmit}
                ref={this.formAddResultList}
              >
                <br />
                <br />
                <br />
                {Boolean(this.state.inspectionLogItemType) &&
                  (this.state.inspectionLogItemType === "Dimension_Control") && (
                    <>
                      {console.log("dynamicAttributes", this.state.dynamicAttributes)}
                      {Boolean(this.state.dynamicAttributes) &&
                        this.state.dynamicAttributes.length > 0 &&
                        this.state.dynamicAttributes.map((attribute, index) => {
                          return (
                            <Badge overflowCount={attribute.value + 1} count={attribute.value}>
                              <Form.Item
                                {...layout}
                                label={attribute.label}
                                name={attribute.label}
                                rules={[
                                  { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                                key={index}
                              >
                                <InputNumber
                                  parser={value => value.replace(",", ".")}
                                  formatter={value => value.replace(".", ",")}
                                  onChange={value => {
                                    // if (Boolean(value)) {
                                    //   this.warningMessage(
                                    //     value,
                                    //     attribute.value,
                                    //     attribute.label,
                                    //     Boolean(this.state.selectedChecklistRowData) ? this.state.selectedChecklistRowData.id : null
                                    //   );
                                    // }
                                  }}
                                />
                              </Form.Item>
                            </Badge>
                          );
                        })}

                      {
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="MaintenanceLogProcess.Result" defaultMessage="Result" />}
                          name="result"
                          rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Radio.Group>
                            <Radio value={"Accept"}>
                              <FormattedMessage id="MaintenanceLogProcess.Accept" defaultMessage="Accept" />
                            </Radio>
                            <Radio onClick={this.onClickSingleItemRejected} value={"Reject"}>
                              <FormattedMessage id="MaintenanceLogProcess.Reject" defaultMessage="Reject" />
                            </Radio>
                            <Radio value={"OnHold"}>
                              <FormattedMessage id="MaintenanceLogProcess.OnHold" defaultMessage="On Hold" />
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      }

                      {
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="MaintenanceLogProcess.FileUpload" defaultMessage="Photo" />}
                          name="photo"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Upload
                            {...props}
                            showUploadList={true}
                            fileList={this.state.fileList}
                            onChange={({ fileList }) => {
                              if (fileList.length == 1) {
                                if (Boolean(fileList[0].response)) {
                                  fileList[0].url = fileList[0].response.url;
                                  showSuccess("file uploaded successfully");
                                }
                                this.setState({
                                  fileList: fileList
                                });
                              }
                            }}
                            beforeUpload={fileList => {
                              if (this.state.fileList.length > 0) {
                                showError("You have been already choose a file! For change firstly remove the uploaded file.");
                                return false;
                              }
                              return true;
                            }}
                            onRemove={fileList => {
                              this.setState({
                                fileList: []
                              });
                            }}
                          >
                            <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                              <UploadOutlined /> Click to Upload
                            </AntButton>
                          </Upload>
                        </Form.Item>
                      }
                    </>
                  )}
              </Form>
            </Badge.Ribbon>
          }
        />

        <DraggableModal
          title=""
          centered
          open={this.state.modalOnebyoneShow}
          onOk={() => {
            const { tableAddResultList } = this.state;
            if (Boolean(tableAddResultList) && tableAddResultList.length > 0) {
              let id = [];
              id.push(tableAddResultList[0].id);
              this.onSelectChangeAddResult(id);
            }
            this.setState(
              {
                tableAddResultIndex: 0,
                modalOnebyoneShow: false
              }
            );
          }}
          onCancel={() => {
            this.setState({ modalOnebyoneShow: false });
          }}
          okButtonProps={{ hidden: false }}
          cancelButtonProps={{ hidden: false }}
          content={
            <>
              <FormattedMessage id="InspectionLogItem.DoyouwanttoaddresultsOnebyOne" defaultMessage="Do you want to add results `One by One`?" />
              <br />
              <br />
              <br />
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="InspectionLogItem.Info" defaultMessage="INFO" />}
          open={this.state.showInspectionInfo}
          okButtonProps={{ hidden: false }}
          cancelButtonProps={{ hidden: true }}
          closable={false}
          // onCancel={() => this.setState({ showInspectionResultModal: false })}
          onOk={() => {

            console.log("selectedAddResultAnswer", this.state.selectedAddResultAnswer);

            const { selectedAddResultAnswer } = this.state;

            if (Boolean(selectedAddResultAnswer) && Boolean(selectedAddResultAnswer.inspectionLogItemType)) {
              let showResultDimensionalAnswerModal = false;
              let showResultChemicalCompositionAnswerModal = false;

              if (selectedAddResultAnswer.inspectionLogItemType === "Dimension_Control") {
                showResultDimensionalAnswerModal = true;
              } else if (selectedAddResultAnswer.inspectionLogItemType === "Chemical_Composition") {
                showResultChemicalCompositionAnswerModal = true;
              }

              this.setState({
                showInspectionInfo: false,
                showResultDimensionalAnswerModal: showResultDimensionalAnswerModal,
                showResultChemicalCompositionAnswerModal: showResultChemicalCompositionAnswerModal
              });
            } else {
              showWarning("Type not found.");
            }
          }}
          width={800}
          centered
          content={
            <>
              <p style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
                <FormattedMessage id="InspectionLogItem.NonConformingLimitExceeded" defaultMessage="Total Number of Sample : " />{" "}
                {this.state.sampleSize}
              </p>
              <br></br>
              <p style={{ textAlign: "center", color: "green", fontWeight: "bold" }}>
                <FormattedMessage
                  id="InspectionLogItem.PleaseCarryonwithnewsampling"
                  defaultMessage="Samples must be selected randomlay and must represent all lot"
                />
              </p>
            </>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceLogProcess);