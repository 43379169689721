import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Tooltip, Drawer, Space, Cascader } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined, ReplyOutlined, Settings } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../../excel/ExcelReader";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import DraggableModal from "../../../Components/DraggableModal";
import { Checkbox } from "antd";
import { Select } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { EldenEquipmentLogIdentificationDrawer } from "./EldenEquipmentLogIdentificationDrawer";
import DeclineIcon from "@material-ui/icons/CloseOutlined";
import CheckIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import { excelExport } from "../../../ExcelHelper";
import EldenEquipmentLogCode from "./EldenEquipmentLogCode";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class EldenEquipmentLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "EldenEquipmentLog",
      controllerName: "eldenEquipmentLog",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      generalSettingIds: [],
      eldenEquipmentTreeIdSelectItems: [],
      eldenEquipmentTreeId: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      isOperatorSelected: false
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsegroupId = await handleRequest("GET", "/api/" + this.state.controllerName + "/group");
    if (responsegroupId.type === "ERROR") {
      error(responsegroupId);
    }
    var responseOperatorId = await handleRequest("GET", "/api/" + this.state.controllerName + "/operator");
    if (responseOperatorId.type === "ERROR") {
      error(responseOperatorId);
    }

    let eldenEquipmentTreeIdSelectItems = [];
    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/eldenEquipmentTreeId");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      eldenEquipmentTreeIdSelectItems = responseTree.data
    }

    this.setState({
      groupIdSelectItems: Boolean(responsegroupId.data) ? responsegroupId.data : [],
      operatorIdSelectItems: Boolean(responseOperatorId.data) ? responseOperatorId.data : [],
      eldenEquipmentTreeIdSelectItems: eldenEquipmentTreeIdSelectItems,
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    var eldenEquipmentTree = dataList.find(p => p.key === row.eldenEquipmentTreeId);
    let eldenEquipmentTreeId = [];
    if (Boolean(eldenEquipmentTree)) {
      eldenEquipmentTreeId = eldenEquipmentTree.lineage;
    }

    this.formRef.current.setFieldsValue({
      ...row,
      eldenEquipmentTreeId: eldenEquipmentTreeId
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      isOperatorSelected: row.isOperator,
      eldenEquipmentTreeId: row.eldenEquipmentTreeId,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  drawerIdentificationCriteriasModal = row => {
    this.setState({
      drawerIdentificationCriteriasShow: true,
      eldenEquipmentTreeId: row.eldenEquipmentTreeId,
      random: Math.random(),
    });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let drawerIdentificationCriteriasAction = {
      name: "Identification",
      icon: <ReplyOutlined fontSize="small" style={{ border: "0px", color: "#3699FF" }} />,
      actionClick: this.drawerIdentificationCriteriasModal.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);

    if (Boolean(record.eldenEquipmentTreeId) && record.eldenEquipmentTreeId > 0) {
      actionList.push(drawerIdentificationCriteriasAction);
    }
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    let { generalSettingIds } = this.state;
    newItem.generalSettingIds = generalSettingIds;

    newItem.eldenEquipmentTreeId =
      Boolean(values.eldenEquipmentTreeId) && values.eldenEquipmentTreeId.length > 0 ? values.eldenEquipmentTreeId[values.eldenEquipmentTreeId.length - 1] : values.eldenEquipmentTreeId;

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  updateGeneralSettingIds = (generalSettingIds) => {
    this.setState({
      generalSettingIds: generalSettingIds,
    });
  };

  fillTypes = async (groupId) => {
    if (Boolean(groupId) && groupId > 0) {
      var responsetypeId = await handleRequest("GET", "/api/" + this.state.controllerName + "/type/" + groupId);
      if (responsetypeId.type === "ERROR") {
        error(responsetypeId);
      }
      this.setState({
        typeIdSelectItems: Boolean(responsetypeId.data) ? responsetypeId.data : []
      });
    } else {
      this.setState({
        typeIdSelectItems: []
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EldenEquipmentLog.equipment" defaultMessage="Equipment" />,
        key: "eldenEquipmentTreeId",
        render: record => {
          return record.eldenEquipmentTreeName;
        },
        ...getColumnFilter({
          inputName: "eldenEquipmentTreeId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["eldenEquipmentTreeIdSelectItems"]) ? this.state["eldenEquipmentTreeIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentLog.General" defaultMessage="General" />,
        key: "id",
        render: record => {
          return record.generalSettingsNames;
        },
      },
      {
        title: <FormattedMessage id="EldenEquipmentLog.purposeToUse" defaultMessage="Purpose To Use" />,
        key: "purposeToUse",
        render: record => {
          return record.purposeToUse;
        },
        ...getColumnFilter({
          inputName: "purposeToUse",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentLog.identificationCriterias" defaultMessage="Identification Criterias" />,
        key: "identificationCriterias",
        render: record => {
          return record.identificationCriterias;
        },
        ...getColumnFilter({
          inputName: "identificationCriterias",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentLog.codeCriterias" defaultMessage="Code Criterias" />,
        key: "codeCriterias",
        render: record => {
          return record.codeCriterias;
        },
        ...getColumnFilter({
          inputName: "codeCriterias",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentLog.operator" defaultMessage="Operator" />,
        key: "id",
        render: record => {
          return record.isOperator ? <Space>
            <CheckIcon fontSize="large" color="primary" />
            {record.operatorName}
          </Space>
            : <DeclineIcon fontSize="large" color="error" />;
        },
      },
      {
        title: <FormattedMessage id="EldenEquipmentLog.Maintenance" defaultMessage="Maintenance" />,
        key: "isNeedsMaintenance",
        render: record => {
          return record.isNeedsMaintenance ? <CheckIcon fontSize="large" color="primary" /> : <DeclineIcon fontSize="large" color="error" />;
        },
        ...getColumnFilter({
          inputName: "isNeedsMaintenance",
          inputType: "CHECKBOX",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];

    const columnsExcel = [
      { header: "S/N", key: "index" },
      { header: "Equipment", key: "equipment" },
      { header: "Purpose To Use", key: "purposeToUse" },
      { header: "Identification Criterias", key: "identificationCriterias" },
      { header: "Code Criterias", key: "codeCriterias" },
      { header: "Operator", key: "operator" },
      { header: "Maintenance", key: "maintenance" },
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="eldenEquipmentLog.pageTitle" defaultMessage="Elden Equipment Log" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={20}></Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => { excelExport(columnsExcel, this.state.tableList) }}
                  icon={<ExportIcon color="action" fontSize="large" />}
                />
              </Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
              <Col md={1}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <Tooltip title={<FormattedMessage id="EldenEquipmentLog.Settings" defaultMessage="Settings" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.setState({ drawerSettingsShow: true });
                      }}
                      icon={<Settings color="error" fontSize="large" />}
                    ></AntButton>
                  </Tooltip>
                )}
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenEquipmentLog.Equipment" defaultMessage="Equipment" />}
                  name="eldenEquipmentTreeId"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Cascader allowClear showSearch optionFilterProp="children"
                    changeOnSelect style={{ width: "100%" }} options={this.state.eldenEquipmentTreeIdSelectItems}
                    onChange={value => {
                      this.setState({
                        eldenEquipmentTreeId: Boolean(value) && value.length > 0 ? value[value.length - 1] : null
                      });
                    }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenEquipmentLog.isOperator" defaultMessage="Operator" />}
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Space.Compact style={{ width: "100%" }}>
                    {
                      <Form.Item
                        name="isOperator"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        valuePropName="checked"
                        style={{ width: "20%" }}
                      >
                        <Checkbox onChange={e => this.setState({ isOperatorSelected: e.target.checked })}>
                          <FormattedMessage id="EldenEquipmentGroup.Yes" defaultMessage="Yes" />
                        </Checkbox>
                      </Form.Item>
                    }

                    {this.state.isOperatorSelected && (
                      <Form.Item
                        name="operatorId"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        style={{ width: "100%" }}
                      >
                        <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                          {Boolean(this.state["operatorIdSelectItems"]) &&
                            this.state["operatorIdSelectItems"].length > 0 &&
                            this.state["operatorIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    )}
                  </Space.Compact>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenEquipmentLog.isNeedsMaintenance" defaultMessage="Needs maintenance" />}
                  name="isNeedsMaintenance"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox>
                    <FormattedMessage id="EldenEquipmentGroup.Yes" defaultMessage="Yes" />
                  </Checkbox>
                </Form.Item>
              }
              {
                Boolean(this.state.eldenEquipmentTreeId) && this.state.eldenEquipmentTreeId > 0 &&
                <>
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button style={{ width: "100%" }} variant="primary"
                        onClick={() => {
                          this.setState({ modalCodeShow: true });
                        }}>
                        <FormattedMessage id="EldenEquipmentLog.Code" defaultMessage="Code" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                  <br />
                </>
              }
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          {
            Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
              <div className="alert alert-custom alert-light">
                <div className="alert-icon">
                  <FilterOutlined />
                </div>
                <div className="alert-text">
                  {this.state.searchCriteriaModels.map((val, i) => {
                    return (
                      <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                        <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                        <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                        <span>
                          <Tooltip title="Remove">
                            <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                          </Tooltip>
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="alert-close">
                  <Tooltip title="Remove All">
                    <CloseCircleOutlined
                      style={{ color: "crimson" }}
                      onClick={() =>
                        this.setState({ searchCriteriaModels: [] }, () => {
                          this.filterChangeItem([]);
                        })
                      }
                    />
                  </Tooltip>
                </div>
              </div>
            )
          }
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <Drawer
            title={<FormattedMessage id="EldenEquipmentLog.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerSettingsShow: false });
            }}
            open={this.state.drawerSettingsShow}
          >
            <PageSettings pageName={"EldenEquipmentLog"} />
          </Drawer>

          <Drawer
            title={<FormattedMessage id="EldenEquipmentLog.Identification" defaultMessage="Identification" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerIdentificationCriteriasShow: false });
            }}
            open={this.state.drawerIdentificationCriteriasShow}
          >
            <EldenEquipmentLogIdentificationDrawer random={this.state.random}
              updateGeneralSettingIds={this.updateGeneralSettingIds} eldenEquipmentTreeId={this.state.eldenEquipmentTreeId} />
          </Drawer>

          <DraggableModal
            title={<FormattedMessage id="EldenEquipmentLog.Code" defaultMessage="Code" />}
            centered
            width={800}
            open={this.state.modalCodeShow}
            onCancel={() => this.setState({ modalCodeShow: false })}
            okButtonProps={{ hidden: true }}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={
              <EldenEquipmentLogCode random={Math.random()} eldenEquipmentTreeId={this.state.eldenEquipmentTreeId}
                close={() => this.setState({ modalCodeShow: false })} />
            }
          />
        </div >
      </Badge.Ribbon >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EldenEquipmentLog);