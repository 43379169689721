import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Upload, Image, Modal as AntModal } from "antd";
import { API_BASE_ROOT } from "../../ApiConnector";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import FormatListNumberedTwoTone from "@material-ui/icons/FormatListNumberedTwoTone";
import { ExcelReader } from "../excel/ExcelReader";
import { Input } from "antd";
import { Select } from "antd";
import { DatePicker } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";
import { DocumentControlNo } from "./DocumentControlNo";

const { Option } = Select;
const { TextArea } = Input;

export class DocumentControlDetail extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "DocumentControlDetail",
      controllerName: "documentControlDetail",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      typeRevise: false,
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsedistributionId = await handleRequest("GET", "/api/" + this.state.controllerName + "/distributionId");
    if (responsedistributionId.type === "ERROR") {
      error(responsedistributionId);
    }
    this.setState({
      distributionIdSelectItems: Boolean(responsedistributionId.data) ? responsedistributionId.data : []
    });
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      documentControlId: Boolean(this.props) && Boolean(this.props.selectedDocumentControl) && Boolean(this.props.selectedDocumentControl.id) ? this.props.selectedDocumentControl.id : -1,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = (typeRevise, row) => {
    document.getElementById("kt_scrolltop").click();

    let fileUrl = [];
    let filePathList = Boolean(row.fileUrl) ? row.fileUrl.split(",") : [];
    if (Boolean(filePathList) && filePathList.length > 0) {
      filePathList.forEach((item, index) => {
        let obj = {
          uid: index,
          name: item.split("download/")[1],
          url: item,
          thumbUrl: item
        };

        fileUrl.push(obj);
      });
    }
    this.formRef.current.setFieldsValue({
      ...row,
      fileUrl: fileUrl,
      issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null,
    });


    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      typeRevise: typeRevise,
      fileList: fileUrl,
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this, false)
    };
    let reviseAction = {
      name: "Revise",
      icon: <EditOutlined fontSize="small" color="secondary" />,
      actionClick: this.edit.bind(this, true)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);
    actionList.push(reviseAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      typeRevise: false,
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    if (Boolean(this.props) && Boolean(this.props.selectedDocumentControl) && Boolean(this.props.selectedDocumentControl.id)) {
      const newItem = {
        ...values,
        id: this.state.id,
      };
      newItem.documentControlId = this.props.selectedDocumentControl.id;

      let pathList = [];
      if (Boolean(newItem.fileUrl))
        newItem.fileUrl.forEach(item => {
          if (Boolean(item.response) && Boolean(item.response.url)) pathList.push(item.response.url);
          else if (Boolean(item.url)) pathList.push(item.url);
        });
      newItem.fileUrl = pathList.toString();

      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
      } else {
        if (this.state.typeRevise) {
          response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/revise/" + newItem.id, newItem);
        } else {
          response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
        }
      }

      if (response.type === "ERROR") {
        var message = Boolean(response.response) && Boolean(response.response.data) ? response.response.data.message : "";
        if (message === "Document Control No you must determine") {
          this.setState({
            modalDocumentControlNoShow: true
          });
        }
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showError("Document Control not found");
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  onDocumentControlNoClick = () => {
    this.setState({
      modalDocumentControlNoShow: true
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const propsUpload = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="DocumentControlDetail.code" defaultMessage="Code" />,
        key: "code",
        render: record => {
          return record.code;
        }, ...getColumnFilter({
          inputName: "code", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="DocumentControlDetail.description" defaultMessage="Description" />,
        key: "description",
        render: record => {
          return record.description;
        }, ...getColumnFilter({
          inputName: "description", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="DocumentControlDetail.revNo" defaultMessage="Rev no" />,
        key: "revNo",
        render: record => {
          return record.revNo;
        }, ...getColumnFilter({
          inputName: "revNo", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="DocumentControlDetail.issueDate" defaultMessage="Issue Date" />,
        key: "issueDate",
        render: record => {
          return Boolean(record.issueDate) ? moment(record.issueDate).format("DD-MM-YYYY") : "";
        }, ...getColumnFilter({
          inputName: "issueDate", inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="DocumentControlDetail.distributionId" defaultMessage="Distribution" />,
        key: "distributionId",
        render: record => {
          return record.distributionName;
        }, ...getColumnFilter({
          inputName: "distributionId", inputType: "SELECT",
          selectItems: Boolean(this.state["distributionIdSelectItems"]) && this.state["distributionIdSelectItems"].length > 0 ? this.state["distributionIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="DocumentControlDetail.remarks" defaultMessage="Remarks" />,
        key: "remarks",
        render: record => {
          return record.remarks;
        }, ...getColumnFilter({
          inputName: "remarks", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="DocumentControlDetail.File" defaultMessage="File" />,
        key: "fileUrl",
        render: record => {
          if (Boolean(record.fileUrl)) {
            return <Image width={80} src={record.fileUrl}></Image>
          }
        },
      },
    ];
    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="DocumentControlDetail" hideElement={true}>             <ExcelSheet name="DocumentControlDetail" data={this.state.tableList}>               <ExcelColumn label="Code" value="code" />
      <ExcelColumn label="Description" value="description" />
      <ExcelColumn label="Rev no" value="revNo" />
      <ExcelColumn label="Issue Date" value="issueDate" />
      <ExcelColumn label="Distribution" value="distributionName" />
      <ExcelColumn label="Remarks" value="remarks" />
    </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} 
    icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)}     */}
    </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="documentControlDetail.pageTitle" defaultMessage="Document Control Detail" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={20}></Col>
              <Col md={1}>
                <Tooltip title={<FormattedMessage id="DocumentControl.DocumentControlNo" defaultMessage="Document Control No" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={this.onDocumentControlNoClick}
                    icon={<FormatListNumberedTwoTone color="primary" fontSize="large" />}
                  ></AntButton>
                </Tooltip>
              </Col>
              <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />} ></AntButton>
                <AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="DocumentControlDetail.description" defaultMessage="Document Descripiton" />}
                  name="description"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="DocumentControlDetail.issueDate" defaultMessage="Issue Date" />}
                  name="issueDate"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="DocumentControlDetail.fileUrl" defaultMessage="File Upload" />}
                  name="fileUrl"
                  rules={[{ required: false, message: "Missing Load" }]}
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload {...propsUpload} showUploadList={true} maxCount={1}>
                    <AntButton icon={<UploadOutlined />} style={{ width: "100%", marginTop: "0.2rem" }}>
                      <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                    </AntButton>
                  </Upload>
                </Form.Item>
              }
              <Form.Item
                {...layout}
                label={<FormattedMessage id="DocumentControlDetail.distributionId" defaultMessage="Distribution" />}
                name="distributionId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                >
                  {Boolean(this.state["distributionIdSelectItems"]) && this.state["distributionIdSelectItems"].length > 0 &&
                    this.state["distributionIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="DocumentControlDetail.remarks" defaultMessage="Remarks" />}
                  name="remarks"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input.TextArea style={{ width: "100%" }} autoSize={{ minRows: 2, maxRows: 6 }} />
                </Form.Item>
              }
              <div hidden={this.state.hideSave}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>                     <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">                       <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
              <div hidden={this.state.hideUpdate}>
                {<Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
                }
              </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (<div className="alert alert-custom alert-light">                 <div className="alert-icon">                   <FilterOutlined />                 </div>                 <div className="alert-text">                   {this.state.searchCriteriaModels.map((val, i) => { return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">                         <span className="mr-2">{val.label}</span>                         <strong className="mr-2">{val.type}</strong>                         <span className="mr-2">{val.operationValue}</span>                         <span className="mr-2">{val.showValue}</span>                         <span>                           <Tooltip title="Remove">                             <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />                           </Tooltip>                         </span>                       </div>); })}                 </div>                 <div className="alert-close">                   <Tooltip title="Remove All">                     <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />                   </Tooltip>                 </div>               </div>)}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">           <Table bordered scroll={{ x: 900, scrollToFirstRowOnChange: true }} columns={columns} dataSource={this.state.tableList} loading={this.state.loading} onChange={this.handleTableChange} pagination={this.state.pagination} />         </div>
          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>           <Modal.Header closeButton>             <Modal.Title id="contained-modal-title-vcenter">               <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />             </Modal.Title>           </Modal.Header>           <Modal.Body>             <p>               <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />             </p>           </Modal.Body>           <Modal.Footer>             <Button variant="secondary" onClick={this.showOrHideDeleteModal}>               <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />             </Button>             <Button variant="danger" onClick={this.delete}>               <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />             </Button>           </Modal.Footer>         </Modal>

          <AntModal
            open={this.state.modalDocumentControlNoShow}
            onCancel={() => {
              this.setState({ modalDocumentControlNoShow: false });
            }}
            width={1500}
            centered
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDocumentControlNoShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
          >
            <Badge.Ribbon
              text={<FormattedMessage id="DocumentControl.DocumentControlNo" defaultMessage="Document Control No" />}
              placement="start"
              color="purple"
              style={{ fontSize: 12 }}
            >
              <div className="card card-custom" style={{ minHeight: "100%" }}>
                <DocumentControlNo random={Math.random()} documentTypeId={Boolean(this.props) && Boolean(this.props.selectedDocumentControl) && Boolean(this.props.selectedDocumentControl.id) ? this.props.selectedDocumentControl.documentTypeId : -1} />
              </div>
            </Badge.Ribbon>
          </AntModal>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentControlDetail);
