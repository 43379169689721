import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Tree, Form, Space, InputNumber } from "antd";
import { FcFolder, FcAddressBook, FcAreaChart, FcEditImage } from "react-icons/fc";
import { FcTreeStructure, FcLibrary } from "react-icons/fc";
import { Menu, Item, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../../ApiConnector";
import { showError, showSuccess, error } from "../../../MessageHelper";
import DraggableModal from "../../../Components/DraggableModal";

const { show } = useContextMenu();

const Menu_Quantity = "Menu_Quantity";

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

const dataListMaterialType = [];
const generateListMaterialType = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataListMaterialType.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateListMaterialType(node.children);
    }
  }
};

export class EldenCompoundsSettingsProductTreeSelectableCompanyQuantity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "EldenCompoundsSettingsProductTreeSelectableCompanyQuantity",
      controllerName: "eldenCompoundsSettingsProductTree",
      id: null,
      description: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      materialTypeIds: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      refreshTree: true
    };
  }

  inputRef = React.createRef(null);

  formRef = React.createRef();
  formRefProduct = React.createRef();
  formRefCompound = React.createRef();
  formRefComponent = React.createRef();
  formRefItem = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    this.fillTree();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsecategoryId = await handleRequest("GET", "/api/" + this.state.controllerName + "/category");
    if (responsecategoryId.type === "ERROR") {
      error(responsecategoryId);
    }

    this.setState({
      categoryIdSelectItems: Boolean(responsecategoryId.data) ? responsecategoryId.data : [],
    });

    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree");
    if (responseTree.data.length !== 0) {
      generateListMaterialType(responseTree.data);
      this.setState({
        materialTypeIds: responseTree.data
      });
    }
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : ""
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      if (Boolean(this.props) && Boolean(this.props.onTargetKeysCheckCompanyAddProduct)) {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsCompany/" + obj.id + "/" + obj.type);
      } else {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsCompanyQuantity/" + obj.id + "/" + obj.type + "/" + this.props.productTreeAllIds);
      }
    }
    if (Boolean(response) && response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "Add_Product" ? (
            <FcLibrary />
          ) : element.type === "Product" ? (
            <FcTreeStructure />
          ) : element.type === "Component" ? (
            <FcAreaChart />
          ) : element.type === "Compound" ? (
            <FcAddressBook />
          ) : (
            <FcFolder />
          );

        let quantity = Boolean(element.quantity) ? element.quantity : 0;

        if (Boolean(element.type)) {
          if (element.type === "Compound") {
            element.title = element.compoundName + "/" + quantity;
          } else if (element.type === "Component") {
            element.title = element.componentName + "/" + quantity;
          } else if (element.type === "Item") {
            element.title = element.itemName + "/" + quantity;
          } else {
            element.title = element.productName + "/" + quantity;
          }
        } else {
          element.title = element.productName + "/" + quantity;
        }
        element.key = element.id;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "Add_Product";
          }

          element.title = element.productName;
          element.key = element.id;
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  save = async values => {
    const { process, selectedNode, id, type, parentId, parentNode } = this.state;
    const newItem = {
      ...values,
      id: id,
      parentId: parentId,
      type: type,
    };

    newItem.materialTypeId =
      Boolean(values.materialTypeId) && values.materialTypeId.length > 0 ? values.materialTypeId[values.materialTypeId.length - 1] : null;

    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(parentNode)) this.loadData(parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showQuantityModal: false,
        showCompoundModal: false,
        showComponentModal: false,
        showItemModal: false,
      });
    }
  };

  saveQuantity = async values => {
    const { process, id, parentNode } = this.state;
    const newItem = {
      ...values,
      id: id,
    };

    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/saveQuantity/" + newItem.id, newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(parentNode)) this.loadData(parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showQuantityModal: false,
        showCompoundModal: false,
        showComponentModal: false,
        showItemModal: false,
      });
    }
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      description: "",
      code: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null
    });
  };

  expandSelectedNode = () => {
    let key = this.state.selectedNodeKey;
    let node = this.state.selectedNode;
    if (Boolean(key)) {
      let expandedKeys = Boolean(this.state.expandedKeys) ? this.state.expandedKeys : [];
      if (Boolean(expandedKeys.find(p => p === key))) {
        this.loadData(node, false);
      } else {
        this.setState({
          expandedKeys: [...expandedKeys, key]
        });
        this.loadData(node, false);
      }
    }
  };

  onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...this.state.nodes];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    this.setState({
      nodes: data
    });
  };

  onChangeItemGroupId = async value => {
    if (Boolean(value)) {
      var responseitemDescriptionId = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemDescriptionIdSelectItems/" + value);
      if (responseitemDescriptionId.type === "ERROR") {
        error(responseitemDescriptionId);
        this.setState({
          descriptionIdSelectItems: [],
          descriptionId: ""
        });
        this.formRefItem.current.setFieldsValue({
          descriptionId: ""
        });
      } else {
        this.setState({
          descriptionIdSelectItems: Boolean(responseitemDescriptionId.data) ? responseitemDescriptionId.data : [],
          descriptionId: ""
        });
        this.formRefItem.current.setFieldsValue({
          descriptionId: ""
        });
      }
    } else {
      this.setState({
        descriptionIdSelectItems: [],
        descriptionId: ""
      });
      this.formRefItem.current.setFieldsValue({
        descriptionId: ""
      });
    }
  };

  getStockCardItem = async () => {
    const { categoryId, descriptionId, materialTypeId } = this.state;
    var newObj = {
      categoryId: categoryId,
      descriptionSelectId: descriptionId,
      materialTypeId: materialTypeId,
    };
    let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/getStockCardItem", newObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        itemIdSelectItems: Boolean(response.data) ? response.data : [],
      });
    }
  };

  onCheck = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    if (Boolean(this.props) && Boolean(this.props.onTargetKeysCheckCompanyAddProduct)) {
      console.log("checkedNodes", e.checkedNodes);
      console.log("halfCheckedKeys", e.halfCheckedKeys);
      this.props.onTargetKeysCheckCompanyAddProduct(checkedKeys, e.halfCheckedKeys);
    }
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes
    });
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    switch (event.currentTarget.id) {
      case "edit_quantity":
        this.setState({
          showQuantityModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        }, () => {
          this.formRefProduct.current.setFieldsValue({
            quantity: selectedNode.quantity,
          });
        });
        break;
    }
  };

  onRightClick = (event, node) => {
    let parentNode = this.getParentNode(node);
    this.setState(
      {
        selectedNodeKey: node.key,
        selectedNode: node,
        parentNode: parentNode
      },
      this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
    );
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  displayMenu = (e, type) => {
    console.log("TTT", type);
    if (Boolean(type)) {
      if (type !== "Add_Product") {
        show(e, { id: Menu_Quantity });
      }
    }
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>

        <Menu id={Menu_Quantity}>
          <Item id="edit_quantity" onClick={this.handleItemClick}>
            <Space>
              <FcEditImage />
              <span>
                <FormattedMessage id="EldenCompoundsSettingsProductTreeSelectableCompanyQuantity.Quantity" defaultMessage="Quantity" />
              </span>
            </Space>
          </Item>
        </Menu>

        {this.state.refreshTree && (
          <Tree
            style={{ marginTop: "10px", lineHeight: "30px" }}
            className="draggable-tree"
            showLine={{ showLeafIcon: false }}
            showIcon
            checkStrictly={false}
            loadedKeys={this.state.loadedKeys}
            onLoad={this.onLoadData}
            loadData={this.loadData}
            onRightClick={({ event, node }) => this.onRightClick(event, node)}
            onExpand={this.onExpand}
            expandedKeys={this.state.expandedKeys}
            autoExpandParent={false}
            treeData={this.state.nodes}
            blockNode={true}
            draggable
            checkable
            onCheck={this.onCheck}
            checkedKeys={this.state.checkedKeys}
            halfCheckedKeys={this.state.halfCheckedKeys}
            onDragEnter={this.onDragEnter}
            onDrop={this.onDrop}
          />
        )}

        <DraggableModal
          title={<FormattedMessage id="EldenCompoundsSettingsProductTreeSelectableCompanyQuantity.Quantity" defaultMessage="Quantity" />}
          centered
          width={600}
          maskClosable={false}
          open={this.state.showQuantityModal}
          onOk={() => this.formRefProduct.current.submit()}
          onCancel={() => {
            this.setState({ showQuantityModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveQuantity} onFinishFailed={onFinishFailed} ref={this.formRefProduct}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EldenCompoundsSettingsProductTreeSelectableCompanyQuantity.Quantity" defaultMessage="Quantity" />}
                    name="quantity"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </>
          }
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EldenCompoundsSettingsProductTreeSelectableCompanyQuantity);