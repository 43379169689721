import { FilterOutlined } from "@ant-design/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button as AntButton, Badge, Col, DatePicker, Form, Input, Row, Select, Table, Modal as AntModal, Spin } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";
import { ExcelReader } from "../excel/ExcelReader";
import VendorScoring from "./VendorScoring";
import RfqVendorReply from "./RfqVendorReply";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined, ReplyOutlined, ScoreOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import DraggableModal from "../../Components/DraggableModal";

const { RangePicker } = DatePicker;
const { Option } = Select;

export class RequestForQuotationVendorResponse extends Component {
  constructor({ requestForQuotationId }) {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      requestForQuotationId: requestForQuotationId,

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      vendorIdSelectItems: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      saveLoading: false,
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  componentDidUpdate = async nextProps => {
    console.log("AAA", this.props);
    let id = Boolean(this.props) && Boolean(this.props.requestForQuotationId) ? this.props.requestForQuotationId : null;
    if (id !== this.state.requestForQuotationId) {
      this.setState(
        {
          requestForQuotationId: this.props.requestForQuotationId
        },
        () => {
          const { pagination } = this.state;
          this.restartTable({ pagination });
          this.fillComboboxes();
        }
      );
    }
  };

  fillComboboxes = async () => {
    var responsevendorId = await handleRequest("GET", "/api/requestForQuotation/vendorsNotResponded/" + this.state.requestForQuotationId);
    if (responsevendorId.type === "ERROR") {
      error(responsevendorId);
    } else {
      this.setState({
        vendorIdSelectItems: Boolean(responsevendorId.data) ? responsevendorId.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
      responseDate: row.responseDate ? moment(row.responseDate) : null
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/requestForQuotationVendorResponse/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      this.fillComboboxes();
      showSuccess();
      if (this.props.restartTable) {
        this.props.restartTable();
      }
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/requestForQuotationVendorResponse/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    this.setState({ saveLoading: true });
    const newItem = { ...values, requestForQuotationId: this.state.requestForQuotationId, id: this.state.id };

    if (newItem.id === null) {
      let vendor = this.state.vendorIdSelectItems.find(item => item.vendorId === newItem.vendorId);
      newItem.vendorName = vendor.vendorName;
      var response = await handleRequest("POST", "/api/requestForQuotationVendorResponse", newItem);
    } else {
      response = await handleRequest("PUT", "/api/requestForQuotationVendorResponse/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
      this.setState({ saveLoading: false });
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      this.fillComboboxes();
      showSuccess();
      this.setState({ saveLoading: false });
      if (this.props.restartTable) {
        this.props.restartTable();
        this.setState({ saveLoading: false });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      requestForQuotationId: this.state.requestForQuotationId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/requestForQuotationVendorResponse/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Reply",
              icon: <ReplyOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
              actionClick: this.showReplyModal.bind(this)
            },
            // {
            //   name: "Score",
            //   icon: <ScoreOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
            //   actionClick: this.showScoringModal.bind(this)
            // },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  showReplyModal = async row => {
    let vendorId = -1;
    if (Boolean(row) && Boolean(row.vendorId)) {
      vendorId = row.vendorId;
    } else {
      vendorId =
        Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("vendorId"))
          ? this.formRef.current.getFieldValue("vendorId")
          : -1;
    }
    if (Boolean(vendorId) && vendorId > 0) {
      var responsevendor = await handleRequest("GET", "/api/requestForQuotation/" + this.state.requestForQuotationId);
      if (responsevendor.type === "ERROR") {
        error(responsevendor);
      } else {
        var vendor = this.state.vendorIdSelectItems.find(p => p.vendorId === vendorId);
        this.setState({
          showVendorReplyModal: true,
          rfqRow: { ...responsevendor.data },
          rfqVendor: { id: vendorId, name: Boolean(vendor) ? vendor.vendorName : "" }
        });
      }
    } else {
      showError("Please select vendor");
    }
  };

  showScoringModal = async row => {
    let vendorId = -1;
    if (Boolean(row) && Boolean(row.vendorId)) {
      vendorId = row.vendorId;
    } else {
      vendorId =
        Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("vendorId"))
          ? this.formRef.current.getFieldValue("vendorId")
          : -1;
    }
    if (Boolean(vendorId) && vendorId > 0) {
      var response = await handleRequest("GET", "/api/requestForQuotation/" + this.state.requestForQuotationId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        var vendor = this.state.vendorIdSelectItems.find(p => p.vendorId === vendorId);
        this.setState({
          showVendorScoringModal: true,
          rfqRow: { ...response.data },
          rfqVendor: { id: vendorId, name: Boolean(vendor) ? vendor.vendorName : "" }
        });
      }
    } else {
      showError("Please select vendor");
    }
  };

  disabledDate = current => {
    return moment() < current || moment(this.props.requestForQuotation.sentToVendorsDate) > current;
  };

  closeRfqVendorReply = () => {
    this.setState(
      {
        showVendorReplyModal: false
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  closeVendorScoring = () => {
    this.setState(
      {
        showVendorScoringModal: false
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        title: "",
        render: this.action
      },

      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="RequestForQuotationVendorResponse.vendorId" defaultMessage="Vendor" />,
        key: "vendorName",
        render: record => {
          return record.vendorName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.vendorName) ? this.state.pagination.vendorName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  vendorName: e.target.value,
                  vendorNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.vendorNameColor) ? this.state.pagination.vendorNameColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="RequestForQuotationVendorResponse.responseDate" defaultMessage="Response" />,
        key: "responseDate",
        render: record => {
          return record.responseDate ? moment(record.responseDate).format("DD-MM-YYYY HH:mm:ss") : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.responseDate) ? this.state.pagination.responseDate : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  responseDate: e.target.value,
                  responseDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.responseDateColor) ? this.state.pagination.responseDateColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="RequestForQuotationVendorResponse.replyDate" defaultMessage="Reply" />,
        key: "replyDate",
        render: record => {
          return record.replyDate ? moment(record.replyDate).format("DD-MM-YYYY HH:mm:ss") : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.replyDate) ? this.state.pagination.replyDate : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  replyDate: e.target.value,
                  responseDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.responseDateColor) ? this.state.pagination.responseDateColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      // {
      //   title: <FormattedMessage id="RequestForQuotationVendorResponse.scoreDate" defaultMessage="Score" />,
      //   key: "scoreDate",
      //   render: record => {
      //     return record.scoreDate ? moment(record.scoreDate).format("DD-MM-YYYY HH:mm:ss") : "";
      //   },
      //   filterDropdown: () => (
      //     <div style={{ padding: 8 }}>
      //       <Input
      //         style={{ flex: "auto" }}
      //         value={Boolean(this.state.pagination.scoreDate) ? this.state.pagination.scoreDate : null}
      //         onChange={async e => {
      //           const { pagination } = this.state;
      //           let _pagination = {
      //             ...pagination,
      //             scoreDate: e.target.value,
      //             responseDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
      //           };
      //           this.setState({
      //             pagination: _pagination
      //           });

      //           this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
      //         }}
      //       />
      //     </div>
      //   ),
      //   filterIcon: filtered => (
      //     <FilterOutlined style={{ color: Boolean(this.state.pagination.responseDateColor) ? this.state.pagination.responseDateColor : "#bfbfbf" }} />
      //   ),
      //   sorter: true
      // }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <Badge.Ribbon
        text={
          Boolean(this.props.requestForQuotation)
            ? this.props.requestForQuotation.rfqNo + "/" + this.props.requestForQuotation.itemDescriptionName
            : ""
        }
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ minHeight: "100%", padding: "2rem" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={23}></Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              <Form.Item
                {...layout}
                hidden={this.state.id}
                label={<FormattedMessage id="RequestForQuotationVendorResponse.vendorId" defaultMessage="Vendor" />}
                name="vendorId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear showSearch style={{ width: "100%" }} placeholder="Vendor">
                  {Boolean(this.state["vendorIdSelectItems"]) &&
                    this.state["vendorIdSelectItems"].map(i => (
                      <Option key={i.vendorId} value={i.vendorId}>
                        {i.vendorName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                hidden={!this.state.id}
                label={<FormattedMessage id="RequestForQuotationVendorResponse.vendorId" defaultMessage="Vendor" />}
                name="vendorName"
              >
                <Input disabled={true} />
              </Form.Item>

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="RequestForQuotationVendorResponse.responseDate" defaultMessage="Response Date" />}
                  name="responseDate"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <DatePicker
                    disabledDate={this.disabledDate}
                    showTime
                    placeholder="Response Date"
                    format={"DD-MM-YYYY HH:mm"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              }

              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="ButtonAddReply" onClick={this.showReplyModal} style={{ width: "100%" }} variant="outline-danger">
                    <FormattedMessage id="ButtonAddReply" defaultMessage="Add Reply" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
              <br />
              {/* <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="ButtonAddScore" onClick={this.showScoringModal} style={{ width: "100%" }} variant="outline-warning">
                    <FormattedMessage id="ButtonAddScore" defaultMessage="Add Score" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
              <br /> */}

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Spin spinning={this.state.saveLoading}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Spin>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Spin spinning={this.state.saveLoading}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Spin>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              rowKey={record => record.id}
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <DraggableModal
            title={<FormattedMessage id="RequestForQuotationVendorResponse.VendorScoring" defaultMessage="Vendor Scoring" />}
            keyboard={false}
            maskClosable={false}
            open={this.state.showVendorScoringModal}
            onCancel={() => this.setState({ rfqRow: null, showVendorScoringModal: false })}
            okButtonProps={{ hidden: true }}
            cancelText={<FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />}
            width={1200}
            centered
            content={<>
            {this.state.showVendorScoringModal && this.state.rfqRow ? (
              <VendorScoring
                requestForQuotation={this.state.rfqRow}
                vendor={this.state.rfqVendor}
                responsePage={true}
                random={Math.random()}
                closeVendorScoring={this.closeVendorScoring}
              />
            ) : null}
            </>}
          >
            
          </DraggableModal>

          <DraggableModal
            title={<FormattedMessage id="RequestForQuotationVendorResponse.VendorReply" defaultMessage="Vendor Reply" />}
            keyboard={false}
            maskClosable={false}
            open={this.state.showVendorReplyModal}
            onCancel={() => this.setState({ rfqRow: null, showVendorReplyModal: false })}
            okButtonProps={{ hidden: true }}
            cancelText={<FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />}
            width={1400}
            centered
            content={ <>
              {this.state.showVendorReplyModal && this.state.rfqRow ? (
                <RfqVendorReply
                  requestForQuotation={this.state.rfqRow}
                  responsePage={true}
                  vendor={this.state.rfqVendor}
                  random={Math.random()}
                  closeRfqVendorReply={this.closeRfqVendorReply}
                />
              ) : null}
              </>
            }
          >
           
          </DraggableModal>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RequestForQuotationVendorResponse);
