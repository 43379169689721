import React, { useCallback, useRef, useEffect, useState } from "react";
import { handleRequest } from "../../ApiConnector";
import { showError, showInfo, showSuccess, showWarning } from "../../MessageHelper";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  useReactFlow,
  updateEdge
} from "react-flow-renderer";
import { Badge, Col, Input, Radio, Row, Select, Space } from "antd";
import store from "../../../redux/store";
import "../../../custom.scss";
import DraggableModal from "../../Components/DraggableModal";

const { Option } = Select;
const flowKey = "example-flow";

const initialNodes = [
  {
    id: "0",
    type: "resizeRotate",
    data: { label: "New" },
    position: { x: 0, y: -30 },
    style: {
      width: "auto",
      maxWidth: "200px",
      height: "auto",
      display: "flex"
    },
  }
];

let id = 1;
const getId = () => `${id++}`;

const fitViewOptions = {
  padding: 2
};

const ReactFlowChart = props => {
  const state = store.getState();
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { project } = useReactFlow();
  const [rfInstance, setRfInstance] = useState(null);
  const { setViewport } = useReactFlow();
  const [newNodeId, setNewNodeId] = useState(null);
  const [newEdgeId, setNewEdgeId] = useState(null);
  const [nodeName, setNodeName] = useState("New");
  const [edgeName, setEdgeName] = useState("");
  const [modalNodeNamePageShow, setModalNodeNamePageShow] = useState(false);
  const [chartName, setChartName] = useState("");
  const [modalOperationTypeShow, setModalOperationTypeShow] = useState(false);
  const [modalColorShow, setModalColorShow] = useState(false);
  const [type, setType] = useState(null);
  const [node, setNode] = useState(null);
  const [flows, setFlows] = useState([]);
  const [chartJsonString, setChartJsonString] = useState([]);
  const [nodeBg, setNodeBg] = useState("#eee");
  const [id, setId] = useState(null);

  const onNodeClick = (event, node) => {
    setNewNodeId(node.id);
    console.log('newNodeId', node.id);
    console.log('click node', node);
  };
  const onNodeDoubleClick = (event, node) => {
    setNode(node);
    setType(null);
    setModalOperationTypeShow(true);
  };

  const onEdgeClick = (event, edge) => {
    setNewEdgeId(edge.id);
    console.log('newEdgeId', edge.id);
    console.log('click edge', edge);
  };

  useEffect(() => {
    console.log("2222");
    setNodes(nds =>
      nds.map(node => {
        if (node.id === newNodeId) {
          node.data = {
            ...node.data,
            label: nodeName
          };
        }

        return node;
      })
    );
    setEdges(eds =>
      eds.map(edge => {
        if (edge.id === newEdgeId) {
          edge.data = {
            ...edge.data,
            label: edgeName
          };
        }

        return edge;
      })
    );
  }, [nodeName, edgeName, setNodes, setEdges]);

  useEffect(() => {
    console.log("3333");
    setNodes(nds => nds.filter(node => node.id !== newNodeId));
    getFlows();
  }, []);

  useEffect(() => {
    console.log("4444");
    setNodes(nds =>
      nds.map(node => {
        if (node.id === newNodeId) {
          node.style = { ...node.style, backgroundColor: nodeBg };
        }

        return node;
      })
    );
  }, [nodeBg, setNodes]);

  const onConnect = useCallback(params => setEdges(eds => addEdge(params, eds)), [setEdges]);

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectStop = useCallback(
    event => {
      const targetIsPane = event.target.classList.contains("react-flow__pane");

      if (targetIsPane) {
        const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
        const id = getId();
        const newNode = {
          id,
          position: project({ x: event.clientX - left - 75, y: event.clientY - top }),
          data: { label: nodeName },
          type: "resizeRotate",
          style: {
            width: "auto",
            maxWidth: "200px",
            height: "auto",
            display: "flex"
          }
        };

        console.log("setNodes");

        setNodes(nds => nds.concat(newNode));
        setEdges(eds =>
          eds.concat({
            id,
            source: connectingNodeId.current,
            target: newNodeId,
            animated: true,
            style: { stroke: "green" },
            data: { label: edgeName }
          })
        );
      }
    },
    [project]
  );

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => setEdges(els => updateEdge(oldEdge, newConnection, els)), []);

  const onSave = async () => {
    console.log("onSave");
    console.log("chartName", chartName);
    console.log("rfInstance", rfInstance);
    if (rfInstance && Boolean(chartName)) {
      const flow = rfInstance.toObject();
      let obj = {
        id: id,
        chartName: chartName,
        chartJsonString: JSON.stringify(flow)
      };
      const response = await handleRequest("POST", "/api/flowChart/save", obj);
      if (response.type === "ERROR") {
        showError(response);
      } else {
        showSuccess();
        getFlows();
      }
    } else {
      showInfo("Please input chart name.");
    }
  }

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      console.log("chartJsonString", chartJsonString);
      if (Boolean(chartJsonString) && chartJsonString.length > 0) {
        const flow = JSON.parse(chartJsonString);
        console.log("flow", flow);

        const { x = 0, y = 0, zoom = 1 } = flow.viewport;
        setNodes(flow.nodes || []);
        setEdges(flow.edges || []);
        setViewport({ x, y, zoom });
      }
    };
    restoreFlow();
  }, [chartJsonString, setNodes, setViewport]);

  const getFlows = async () => {
    const response = await handleRequest("GET", "/api/flowChart");
    if (response.type === "ERROR") {
      showError(response);
    } else {
      setFlows(response.data);
    }
  };

  const deleteNodeById = id => {
    console.log("id", id);

    setNodes(nds => nds.filter(node => node.id !== id));
  };

  const onNodeNameChange = e => {
    setNodeName(e.target.value);
  };

  const onEdgeNameChange = e => {
    setEdgeName(e.target.value);
  };

  const onChartStringChange = e => {
    let chartJsonString = null;
    if (Boolean(e)) {
      let item = flows.find(p => p.id === e);
      if (Boolean(item.chartJsonString)) {
        chartJsonString = item.chartJsonString;
      }
      if (!Boolean(chartName)) {
        setChartName(item.chartName);
      }
    } else {
      setChartName("");
    }
    setChartJsonString(chartJsonString);
    setId(e);
  };

  useEffect(() => {
    console.log("chartJsonString", chartJsonString);
    if (Boolean(chartJsonString)) {
      onRestore();
    }
  }, [chartJsonString]);

  const onNodeColorChange = e => {
    setNodeBg(e);
    //onRestore();
  };

  const onChartNameChange = e => {
    setChartName(e.target.value);
  };

  return (
    <div ref={reactFlowWrapper} className="card card-custom" style={{ paddingTop: "2px", paddingLeft: "2px", flexGrow: 1, height: "100%" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onInit={setRfInstance}
        onConnectStart={onConnectStart}
        onConnectStop={onConnectStop}
        onEdgeClick={onEdgeClick}
        onNodeClick={onNodeClick}
        onNodeDoubleClick={onNodeDoubleClick}
        snapToGrid
        onEdgeUpdate={onEdgeUpdate}
        fitView
        fitViewOptions={fitViewOptions}
      >
        <div className="updatenode__controls">
          <Row gutter={[16, 16]}>
            <Space>
              <Col md={6}>
                <Select
                  showSearch allowClear optionFilterProp="children"
                  style={{ width: "100px" }}
                  onChange={onChartStringChange}>
                  {flows && flows.length > 0 && flows.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.chartName}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col md={6}>
                <Input placeholder="Chart Name" value={chartName} onChange={onChartNameChange} style={{ width: "100px" }} />
              </Col>
              <Col md={6}>
                {(Boolean(id) && id > 0) ?
                  <Button id="UpdateButton" variant="warning" onClick={onSave}>
                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                  </Button>
                  :
                  <Button id="SaveButton" variant="success" onClick={onSave}>
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                }
              </Col>
            </Space>
          </Row>

        </div>
      </ReactFlow>

      <DraggableModal
        title={<FormattedMessage id="ReactFlowChart.EnterNodeLabel" defaultMessage="Enter Node Label" />}
        closable={false}
        okButtonProps={{ hidden: false }}
        cancelButtonProps={{ hidden: true }}
        open={modalNodeNamePageShow}
        onOk={() => {
          if (Boolean(nodeName)) {
            setModalNodeNamePageShow(false)
          } else {
            showInfo("This field is required.");
          }
        }}
        width={600}
        content={
          <Input value={nodeName} onChange={evt => setNodeName(evt.target.value)} />
        }
      />

      <DraggableModal
        title={<FormattedMessage id="ReactFlowChart.OperationType" defaultMessage="Operation Type" />}
        centered
        width={400}
        open={modalOperationTypeShow}
        onCancel={() => {
          setModalOperationTypeShow(false);
        }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        content={
          <Radio.Group
            onChange={e => {
              setType(e.target.value);
              if (e.target.value === "Rename") {
                setModalOperationTypeShow(false);
                setModalNodeNamePageShow(true);
                setNodeName(Boolean(node.data) && Boolean(node.data.label) ? node.data.label : "");
              } else if (e.target.value === "Delete") {
                deleteNodeById(node.id);
                setModalOperationTypeShow(false);
              } else if (e.target.value === "Color") {
                setModalColorShow(true)
                setModalOperationTypeShow(false);
              }
            }}
            value={type}
          >
            <Radio value={"Rename"}>{"Rename"}</Radio>
            <Radio value={"Delete"}>{"Delete"}</Radio>
            <Radio value={"Color"}>{"Color"}</Radio>
          </Radio.Group>}
      />

      <DraggableModal
        title={<FormattedMessage id="ReactFlowChart.Color" defaultMessage="Color" />}
        closable={false}
        okButtonProps={{ hidden: false }}
        cancelButtonProps={{ hidden: true }}
        open={modalColorShow}
        width={400}
        onOk={() => {
          setModalColorShow(false)
        }}
        content={
          <Select
            showSearch allowClear optionFilterProp="children"
            style={{ width: "300px" }}
            onChange={onNodeColorChange}>
            <Option value="#1266F1">Primary (#1266F1)</Option>
            <Option value="#B23CFD">Secondary (#B23CFD)</Option>
            <Option value="#00B74A">Success (#00B74A)</Option>
            <Option value="#F93154">Danger (#F93154)</Option>
            <Option value="#FFA900">Warning (#FFA900)</Option>
            <Option value="#39C0ED">Info (#39C0ED)</Option>
            <Option value="#FBFBFB">Light (#FBFBFB)</Option>
          </Select>
        }
      />
    </div>
  );
};

export default () => (
  <ReactFlowProvider>
    <ReactFlowChart />
  </ReactFlowProvider>
); 