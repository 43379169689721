import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Select } from "antd";
import { Input } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Tooltip, Cascader } from "antd";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node)) {
      var lineage = [];
      if (Boolean(node.lineage) && node.lineage.length > 0) {
        node.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.value,
        lineage: node.lineage,
        title: node.value
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class MaintenanceWorkStation extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "MaintenanceWorkStation",
      controllerName: "maintenanceWorkStation",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };
  fillComboboxes = async () => {
    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      this.setState({
        parentSelectItems: responseTree.data
      });
    }

    var responseequipment = await handleRequest("GET", "/api/" + this.state.controllerName + "/equipment");
    if (responseequipment.type === "ERROR") {
      error(responseequipment);
    }
    this.setState({
      equipmentSelectItems: Boolean(responseequipment.data) ? responseequipment.data : []
    });
    var responseactivity = await handleRequest("GET", "/api/" + this.state.controllerName + "/activity");
    if (responseactivity.type === "ERROR") {
      error(responseactivity);
    }
    this.setState({
      activitySelectItems: Boolean(responseactivity.data) ? responseactivity.data : []
    });

    var responseproduct = await handleRequest("GET", "/api/" + this.state.controllerName + "/product");
    if (responseproduct.type === "ERROR") {
      error(responseproduct);
    }
    this.setState({
      productSelectItems: Boolean(responseproduct.data) ? responseproduct.data : []
    });

    var responseoperator = await handleRequest("GET", "/api/" + this.state.controllerName + "/operator");
    if (responseoperator.type === "ERROR") {
      error(responseoperator);
    }
    this.setState({
      operatorSelectItems: Boolean(responseoperator.data) ? responseoperator.data : []
    });
    var responselengthUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/lengthUnit");
    if (responselengthUnit.type === "ERROR") {
      error(responselengthUnit);
    }
    this.setState({
      lengthUnitSelectItems: Boolean(responselengthUnit.data) ? responselengthUnit.data : []
    });
    var responsewidthUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/widthUnit");
    if (responsewidthUnit.type === "ERROR") {
      error(responsewidthUnit);
    }
    this.setState({
      widthUnitSelectItems: Boolean(responsewidthUnit.data) ? responsewidthUnit.data : []
    });
    var responseheightUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/heightUnit");
    if (responseheightUnit.type === "ERROR") {
      error(responseheightUnit);
    }
    this.setState({
      heightUnitSelectItems: Boolean(responseheightUnit.data) ? responseheightUnit.data : []
    });
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    var equipments = [];
    if (Boolean(row.workStationEquipments) && row.workStationEquipments.length > 0) {
      row.workStationEquipments.forEach(element => {
        equipments.push(element.equipmentLogId);
      });
    }

    var activitys = [];
    if (Boolean(row.workStationActivitys) && row.workStationActivitys.length > 0) {
      row.workStationActivitys.forEach(element => {
        activitys.push(element.activityId);
      });
    }

    var operators = [];
    if (Boolean(row.workStationOperators) && row.workStationOperators.length > 0) {
      row.workStationOperators.forEach(element => {
        operators.push(element.operatorId);
      });
    }

    var products = [];
    if (Boolean(row.workStationProducts) && row.workStationProducts.length > 0) {
      row.workStationProducts.forEach(element => {
        products.push(element.productId);
      });
    }


    var parent = dataList.find(p => p.key === row.parentId);
    let parentId = [];

    if (Boolean(parent)) {
      parentId = parent.lineage;
    }

    this.formRef.current.setFieldsValue({
      ...row,
      parentId: parentId,
      workStationEquipments: equipments,
      workStationActivitys: activitys,
      workStationOperators: operators,
      workStationProducts: products,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      this.fillComboboxes();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };



    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);



    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: Boolean(values.parentId) ? values.parentId[values.parentId.length - 1] : null,
    };

    let equipments = [];
    if (newItem.workStationEquipments && newItem.workStationEquipments.length > 0)
      newItem.workStationEquipments.forEach(element => {
        equipments.push({ equipmentLogId: element });
      });
    newItem.workStationEquipments = equipments;

    let activitys = [];
    if (newItem.workStationActivitys && newItem.workStationActivitys.length > 0)
      newItem.workStationActivitys.forEach(element => {
        activitys.push({ activityId: element });
      });
    newItem.workStationActivitys = activitys;

    let operators = [];
    if (newItem.workStationOperators && newItem.workStationOperators.length > 0)
      newItem.workStationOperators.forEach(element => {
        operators.push({ operatorId: element });
      });
    newItem.workStationOperators = operators;

    let products = [];
    if (newItem.workStationProducts && newItem.workStationProducts.length > 0)
      newItem.workStationProducts.forEach(element => {
        products.push({ productId: element });
      });
    newItem.workStationProducts = products;

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.fillComboboxes();
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="MaintenanceWorkStation.parentStation" defaultMessage="Parent Station" />,
        key: "parentId",
        render: record => {
          return record.parentWorkStationName;
        }, ...getColumnFilter({
          inputName: "parentId", inputType: "CASCADER",
          selectItems: Boolean(this.state["parentSelectItems"]) ? this.state["parentSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="MaintenanceWorkStation.station" defaultMessage="Station" />,
        key: "station",
        render: record => {
          return record.station;
        }, ...getColumnFilter({
          inputName: "station", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="MaintenanceWorkStation.equipment" defaultMessage="Equipment" />,
        key: "id",
        render: record => {
          return record.equipmentName;
        },
      },
      {
        title: <FormattedMessage id="MaintenanceWorkStation.activity" defaultMessage="Activity" />,
        key: "id",
        render: record => {
          return record.activityName;
        },
      },
      {
        title: <FormattedMessage id="MaintenanceWorkStation.Product" defaultMessage="Product" />,
        key: "id",
        render: record => {
          return record.productName;
        },
      },
      {
        title: <FormattedMessage id="MaintenanceWorkStation.operator" defaultMessage="Operator" />,
        key: "id",
        render: record => {
          return record.operatorName;
        },
      },
      // {
      //   title: <FormattedMessage id="MaintenanceWorkStation.dimension" defaultMessage="Dimension" />,
      //   key: "id",
      //   render: record => {
      //     return (
      //       <table className="table table-bordered table-sm">
      //         <thead>
      //           <tr>
      //             <th><FormattedMessage id="MaintenanceWorkStation.L/W/H" defaultMessage="L/W/H" /> </th>
      //             <th><FormattedMessage id="MaintenanceWorkStation.Unit" defaultMessage="Unit" /> </th>
      //           </tr>
      //         </thead>
      //         <tbody>
      //           {
      //             Boolean(record.length) && Boolean(record.lengthUnitName) && <tr>
      //               <td className="text-nowrap">{record.length + " L"}</td>
      //               <td className="text-nowrap">{record.lengthUnitName}</td>
      //             </tr>
      //           }
      //           {
      //             Boolean(record.width) && Boolean(record.widthUnitName) && <tr>
      //               <td className="text-nowrap">{record.width + " W"}</td>
      //               <td className="text-nowrap">{record.widthUnitName}</td>
      //             </tr>
      //           }
      //           {
      //             Boolean(record.height) && Boolean(record.heightUnitName) && <tr>
      //               <td className="text-nowrap">{record.height + " H"}</td>
      //               <td className="text-nowrap">{record.heightUnitName}</td>
      //             </tr>
      //           }
      //         </tbody>
      //       </table>
      //     );
      //   }
      // },
      // {
      //   title: <FormattedMessage id="MaintenanceWorkStation.coordinates" defaultMessage="Coordinates" />,
      //   key: "id",
      //   render: record => {
      //     return (
      //       <table className="table table-bordered table-sm">
      //         <thead>
      //           <tr>
      //             <th></th>
      //             <th><FormattedMessage id="MaintenanceWorkStation.N" defaultMessage="N" /> </th>
      //             <th><FormattedMessage id="MaintenanceWorkStation.E" defaultMessage="E" /> </th>
      //             <th><FormattedMessage id="MaintenanceWorkStation.El" defaultMessage="El" /> </th>
      //           </tr>
      //         </thead>
      //         <tbody>
      //           {
      //             <tr>
      //               <td className="text-nowrap">{"A1"}</td>
      //               <td className="text-nowrap">{record.coordA1N}</td>
      //               <td className="text-nowrap">{record.coordA1E}</td>
      //               <td className="text-nowrap">{record.coordA1EL}</td>
      //             </tr>
      //           }
      //           {
      //             <tr>
      //               <td className="text-nowrap">{"A2"}</td>
      //               <td className="text-nowrap">{record.coordA2N}</td>
      //               <td className="text-nowrap">{record.coordA2E}</td>
      //               <td className="text-nowrap">{record.coordA2EL}</td>
      //             </tr>
      //           }
      //           {
      //             <tr>
      //               <td className="text-nowrap">{"A3"}</td>
      //               <td className="text-nowrap">{record.coordA3N}</td>
      //               <td className="text-nowrap">{record.coordA3E}</td>
      //               <td className="text-nowrap">{record.coordA3EL}</td>
      //             </tr>
      //           }
      //           {
      //             <tr>
      //               <td className="text-nowrap">{"A4"}</td>
      //               <td className="text-nowrap">{record.coordA4N}</td>
      //               <td className="text-nowrap">{record.coordA4E}</td>
      //               <td className="text-nowrap">{record.coordA4EL}</td>
      //             </tr>
      //           }
      //         </tbody>
      //       </table>
      //     );
      //   }
      // },
      // {
      //   title: <FormattedMessage id="MaintenanceWorkStation.safetyRules" defaultMessage="Safety Rules" />,
      //   key: "id",
      //   render: record => {
      //     if (!Boolean(record.workStationSafetyRules) || record.workStationSafetyRules.length === 0) {
      //       return <span>-</span>;
      //     }
      //     return (
      //       <table className="table table-bordered table-sm">
      //         <thead>
      //           <tr>
      //             <th></th>
      //             <th><FormattedMessage id="MaintenanceWorkStation.Description" defaultMessage="Description" /></th>
      //           </tr>
      //         </thead>
      //         <tbody>
      //           {record.workStationSafetyRules.map((item, index) => {
      //             return (
      //               <tr key={index}>
      //                 <td>{item.checked ?
      //                   <CheckIcon fontSize="large" color="primary" /> :
      //                   <CloseIcon fontSize="large" color="error" />
      //                 }</td>
      //                 <td className="text-nowrap">{item.description}</td>
      //               </tr>
      //             );
      //           })}
      //         </tbody>
      //       </table>
      //     );
      //   }
      // },
    ];
    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="Work Station" hideElement={true}>             <ExcelSheet name="Work Station" data={this.state.tableList}>               <ExcelColumn label="Parent Station" value="Parent Station" />
      <ExcelColumn label="Station" value="Station" />
      <ExcelColumn label="Equipment" value="Equipment" />
      <ExcelColumn label="Activity" value="Activity" />
      <ExcelColumn label="Dimension" value="Dimension" />
      <ExcelColumn label="Coordinates" value="Coordinates" />
      <ExcelColumn label="Safety Rules" value="Safety Rules" />
    </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} 
    icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)}   */}
    </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="MaintenanceWorkStation.pageTitle" defaultMessage="Maintenance Work Station" />} placement="start" color="purple" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>             <Col md={21}></Col>             <Col md={1}>{excelExport}</Col>             <Col md={1}>               <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>             </Col>             <Col md={1}>               <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />}               ></AntButton>               <AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />}               ></AntButton>             </Col>           </Row>
            <div hidden={this.state.hideInputs}>

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="MaintenanceWorkStation.ParentStation" defaultMessage="Parent Station" />}
                  name="parentId"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Cascader changeOnSelect style={{ width: "100%" }} options={this.state.parentSelectItems} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="MaintenanceWorkStation.station" defaultMessage="Station" />}
                  name="station"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="MaintenanceWorkStation.equipment" defaultMessage="Equipment" />}
                  name="workStationEquipments"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    style={{ width: "100%" }}
                    showSearch
                    optionFilterProp="children"
                    mode="multiple"
                  >
                    {Boolean(this.state["equipmentSelectItems"]) && this.state["equipmentSelectItems"].length > 0 &&
                      this.state["equipmentSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="MaintenanceWorkStation.activity" defaultMessage="Activity" />}
                  name="workStationActivitys"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    style={{ width: "100%" }}
                    showSearch
                    optionFilterProp="children"
                    mode="multiple"
                  >
                    {Boolean(this.state["activitySelectItems"]) && this.state["activitySelectItems"].length > 0 &&
                      this.state["activitySelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="MaintenanceWorkStation.activity" defaultMessage="Product" />}
                  name="workStationProducts"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    style={{ width: "100%" }}
                    showSearch
                    optionFilterProp="children"
                    mode="multiple"
                  >
                    {Boolean(this.state["productSelectItems"]) && this.state["productSelectItems"].length > 0 &&
                      this.state["productSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="MaintenanceWorkStation.operator" defaultMessage="Operator" />}
                  name="workStationOperators"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    style={{ width: "100%" }}
                    showSearch
                    optionFilterProp="children"
                    mode="multiple"
                  >
                    {Boolean(this.state["operatorSelectItems"]) && this.state["operatorSelectItems"].length > 0 &&
                      this.state["operatorSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              <div hidden={this.state.hideSave}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>                     <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">                       <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
              <div hidden={this.state.hideUpdate}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>                       <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />                     </Button>                   </Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">                       <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 &&
            (<div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">{val.label}</span>
                    <strong className="mr-2">{val.type}</strong>
                    <span className="mr-2">{val.operationValue}</span>
                    <span className="mr-2">{val.showValue}</span>
                    <span>                           <Tooltip title="Remove">
                      <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                    </Tooltip>
                    </span>
                  </div>);
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined style={{ color: "crimson" }}
                    onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />
                </Tooltip>
              </div>
            </div>)}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>
          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>           <Modal.Header closeButton>             <Modal.Title id="contained-modal-title-vcenter">               <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />             </Modal.Title>           </Modal.Header>           <Modal.Body>             <p>               <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />             </p>           </Modal.Body>           <Modal.Footer>             <Button variant="secondary" onClick={this.showOrHideDeleteModal}>               <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />             </Button>             <Button variant="danger" onClick={this.delete}>               <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />             </Button>           </Modal.Footer>         </Modal>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceWorkStation);
