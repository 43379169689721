import { EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { PlusOutlined, UploadOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Button as AntButton, Col, InputNumber, Form, Input, Row, Select, Divider, Upload, Table, DatePicker } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest, API_BASE_ROOT } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { ExcelReader } from "../excel/ExcelReader";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Option } = Select;

const dataList = [];
const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];
        if (Boolean(node.data)) {
            var lineage = [];
            if (Boolean(node.data.lineage) && node.data.lineage.length > 0) {
                node.data.lineage.forEach(element => {
                    if (!lineage.includes(element)) {
                        lineage.push(element);
                    }
                });
            }

            dataList.push({
                key: node.data.id,
                lineage: lineage,
                title: node.data.menuTitle,
                page: node.data.page
            });
        }
        if (node.children) {
            generateList(node.children);
        }
    }
};

export class ItpActivityQuestionnary extends Component {
    constructor() {
        super();
        this.state = {
            formName: "ItpActivityQuestionnary",
            controllerName: "itpActivityQuestionnary",
            modalDeleteShow: false,
            exportList: [],
            exportClick: false,
            hideUpdate: true,
            hideSave: false,
            hideInputs: true,
            lastUpdateDate: [],
            dynamicMenuCascader: [],
            answerTypeSelectItems: [],
            checklistItemIdsSelectItems: [],
            id: null,
            tableList: [],
            excelData: [],
            createWhereParams: null,
            refreshForm: false,
            filePath: "",
            fileList: [],
            rows: [],

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
                total: 0
            },
            loading: false,
            sortField: "",
            sortOrder: "",
        };
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        this.initialize();
    };

    componentDidUpdate = (prevProps) => {
        let random = this.props.random;
        if (random !== prevProps.random) {
            this.initialize();
        }
    };

    initialize = async () => {
        console.log("AAA", this.props);
        const { pagination } = this.state;
        this.restartTable({ pagination });

        this.fillComboboxes();
    };

    fillComboboxes = async () => {
        this.getChecklistItemIds();
        this.getAnswerType();
        this.fillCode("ItpActivityQuestionnaryTitle");
        this.fillCode("ItpActivityQuestionnaryOptions");
        this.fillCode("ItpActivityQuestionnaryColumns");
    };

    getAnswerType = async () => {
        const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/answerType");
        if (Boolean(response.data)) {
            this.setState({
                answerTypeSelectItems: response.data
            });
        }
    };

    getChecklistItemIds = async () => {
        var responsesatipNo = await handleRequest("GET", "/api/" + this.state.controllerName + "/checklistItemIdsSelectItems/" + this.props.qualityProcedure);
        if (responsesatipNo.type === "ERROR") {
            error(responsesatipNo);
        } else {
            this.setState({
                checklistItemIdsSelectItems: Boolean(responsesatipNo.data) ? responsesatipNo.data : []
            });
        }
    };

    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hideInputs: true
        });
        this.resetInputs();
    };

    resetInputs = () => {
        this.formRef.current.resetFields();
        this.setState({
            id: null,
            answerType: null,
            fileList: [],
        });
    };

    edit = row => {
        console.log("AAA", row);
        document.getElementById("kt_scrolltop").click();

        var options = [];
        if (Boolean(row) && Boolean(row.options) &&
            row.options.length > 0) {
            row.options.forEach(element => {
                options.push(element.codeId);
            });
        }

        var columns = [];
        if (Boolean(row) && Boolean(row.columns) &&
            row.columns.length > 0) {
            row.columns.forEach(element => {
                columns.push(element.codeId);
            });
        }

        let _fileList = [];
        if (Boolean(row.answerType) && row.answerType === 'Imagepicker') {
            if (Boolean(row.imageOptions)) {
                row.imageOptions.forEach((element, index) => {
                    if (Boolean(element.imageUrl)) {
                        let obj = { url: element.imageUrl, uid: index, imagePath: element.imageUrl, id: index, name: element.imageUrl.substring(element.imageUrl.lastIndexOf('/') + 1) }
                        _fileList.push(obj);
                    }
                });
            }
        }

        this.formRef.current.setFieldsValue({
            ...row,
            options: options,
            columns: columns,
        });
        this.setState({
            hideUpdate: false,
            hideSave: true,
            hideInputs: false,
            id: row.id,
            answerType: row.answerType,
            fileList: _fileList,
        });
    };

    deleteModal = row => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    };

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        };
        var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

        if (response.type === "ERROR") {
            error(response);
        } else {
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.setState({
                id: null,
                modalDeleteShow: false
            });
        }
    };

    restartTable = async (params = {}) => {
        this.setState({ loading: true });
        const newObj = this.createWhere(params);
        let url = "/api/" + this.state.controllerName + "/paging";
        const response = await handleRequest("POST", url, newObj);
        if (response.type === "ERROR") {
            error(response);
            this.setState({
                loading: false,
                tableList: [],
                pagination: {
                    ...params.pagination,
                    total: 0
                },
                totalRecords: 0,
                sortField: "",
                sortOrder: ""
            });
        } else {
            if (response.data.length !== 0) {
                let list = response.data.data;
                list.forEach((element, index) => {
                    element.index = index + 1;
                });
                this.setState({
                    loading: false,
                    tableList: list,
                    pagination: {
                        ...params.pagination,
                        total: response.data.totalRecords
                    },
                    totalRecords: response.data.totalRecords,
                    sortField: params.sortField,
                    sortOrder: params.sortOrder
                });
            } else {
                this.setState({
                    loading: false,
                    tableList: [],
                    pagination: {
                        ...params.pagination,
                        total: 0
                    },
                    totalRecords: 0,
                    sortField: "",
                    sortOrder: ""
                });
            }
        }
    };

    createWhere(params) {
        if (Boolean(params._pagination)) params.pagination = params._pagination;

        var lastUpdateDateStart = null,
            lastUpdateDateEnd = null,
            searchCriteriaModels = [],
            sortField = "",
            sortOrder = "";
        if (Boolean(params) && Boolean(params.pagination)) {
            if (Boolean(params.pagination.lastUpdateDate)) {
                lastUpdateDateStart = params.pagination.lastUpdateDate[0];
                lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
            }
            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }
        }
        const newObj = {
            ...params.pagination,
            id: null,
            itpActivityId: this.props.itpActivityId,
            lastUpdateDateStart: lastUpdateDateStart,
            lastUpdateDateEnd: lastUpdateDateEnd,
            firstResult: params.pagination.current - 1,
            maxResults: params.pagination.pageSize,
            sortField: sortField,
            sortOrder: sortOrder,
            searchCriteriaModels: searchCriteriaModels,
        };
        return newObj;
    }

    onClickCreateNew = () => {
        this.resetInputs();
        if (this.state.hideInputs) {
            this.setState({
                hideInputs: false,
                hideSave: false,
                hideUpdate: true
            });
        } else {
            this.setState({
                hideInputs: true,
                hideSave: true,
                hideUpdate: false
            });
        }
    };

    save = async values => {
        console.log("AAA", values);
        var options = [];
        if (Boolean(values.options) && values.options.length > 0) {
            values.options.forEach(element => {
                options.push({
                    ["codeId"]: element,
                });
            });
        }

        var columns = [];
        if (Boolean(values.columns) && values.columns.length > 0) {
            values.columns.forEach(element => {
                columns.push({
                    ["codeId"]: element,
                });
            });
        }

        var _imageOptions = [];
        if (Boolean(this.state.fileList))
            this.state.fileList.forEach(element => {
                let url = null;
                if (Boolean(element.url)) {
                    url = element.url;
                } else if (Boolean(element.response) && Boolean(element.response.url)) {
                    url = element.response.url;
                }
                if (Boolean(url)) {
                    _imageOptions.push({
                        ["imageUrl"]: url,
                    });
                }
            });

        let newItem = {
            ...values,
            id: this.state.id,
            itpActivityId: this.props.itpActivityId,
            options: options,
            columns: columns,
            imageOptions: _imageOptions,
        };

        if (!Boolean(newItem.id)) {
            var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
        } else {
            response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
        }

        if (response.type === "ERROR") {
            error(response);
        } else {
            this.cancel();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
        }
    };

    handleExportExcel = async () => {
        this.setState({
            exportClick: true
        });
    };

    handleUploadExcel = async excelJson => {
        try {
            var list = [];
            if (excelJson === null) showError("Select an excel file");
            else {
                excelJson.forEach(element => {
                    const obj = {};
                    list.push(obj);
                });
                if (Boolean(list) && list.length > 0) {
                    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
                    if (response.type === "ERROR") {
                        showError("No records to be added");
                    } else {
                        this.resetInputs();
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                        showSuccess();
                    }
                } else {
                    showError("No records to be added");
                }
            }
        } catch (error) {
            showError("");
        }
    };

    action = record => {
        return (
            <React.Fragment>
                <DynamicActionMenu
                    row={record}
                    actionList={[
                        {
                            name: "Edit",
                            icon: <EditOutlined fontSize="small" color="primary" />,
                            actionClick: this.edit.bind(this)
                        },
                        {
                            name: "Delete",
                            icon: <DeleteIcon fontSize="small" color="error" />,
                            actionClick: this.deleteModal.bind(this)
                        }
                    ]}
                />
            </React.Fragment>
        );
    };

    setOpenPanels = key => {
        this.setState({
            activePanelKey: key
        });
    };

    addCode = async codeType => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: codeType
            };

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: null,
                });
                this.fillCode(codeType);
            }
        } else {
            showError("Please type value");
        }
    };

    fillCode = async codeType => {
        const response = await handleRequest("GET", "/api/codes/type/" + codeType);
        if (Boolean(response.data)) {
            this.setState({
                [codeType + "SelectItems"]: response.data
            });
        }
    };

    getRatingList = (max) => {
        const numbers = [];
        for (let index = 1; index <= max; index++) {
            numbers.push(index);
        }
        return numbers;
    }

    handleFileChange = ({ fileList }) => this.setState({ fileList });

    render() {
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };

        const props = {
            action: `${API_BASE_ROOT}/File/upload`,
            listType: "picture",
            defaultFileList: [...this.state.fileList]
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 },
            style: {
                marginBottom: 0
            }
        };

        const columns = [
            {
                key: "key",
                title: "Actions",
                render: this.action
            },
            {
                title: "S/N",
                key: "id",
                render: (value, row, index) => {
                    return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
                }
            },
            {
                title: "Item",
                key: "itemName",
                render: record => {
                    return record.itemName;
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            style={{ flex: "auto" }}
                            value={Boolean(this.state.pagination.itemName) ? this.state.pagination.itemName : null}
                            onChange={async e => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    itemName: e.target.value,
                                    itemNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                                };
                                this.setState({
                                    pagination: _pagination
                                });

                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }}
                        />
                    </div>
                ),
                filterIcon: filtered => (
                    <FilterOutlined style={{ color: Boolean(this.state.pagination.itemNameColor) ? this.state.pagination.itemNameColor : "#bfbfbf" }} />
                ),
                sorter: true
            },
            {
                title: "AnswerType",
                key: "answerType",
                render: record => {
                    return record.answerType;
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            style={{ flex: "auto" }}
                            value={Boolean(this.state.pagination.answerType) ? this.state.pagination.answerType : null}
                            onChange={async e => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    answerType: e.target.value,
                                    answerTypeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                                };
                                this.setState({
                                    pagination: _pagination
                                });

                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }}
                        />
                    </div>
                ),
                filterIcon: filtered => (
                    <FilterOutlined style={{ color: Boolean(this.state.pagination.answerTypeColor) ? this.state.pagination.answerTypeColor : "#bfbfbf" }} />
                ),
                sorter: true
            },
            {
                title: "Last Update",
                key: "lastUpdateDate",
                render: record => {
                    return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <RangePicker
                            value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
                            format="DD-MM-YYYY HH:mm"
                            showTime={{ format: "HH:mm" }}
                            onChange={this.onChangeLastUpdateDate}
                        />
                    </div>
                ),
                filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
                sorter: true
            }
        ];

        const excelExport = (
            <>
                {/* {this.state.exportClick
                    ? this.setState({
                        exportClick: false
                    })
                    : ""}
                {this.state.exportClick ? (
                    <ExcelFile filename="Products" hideElement={true}>
                        <ExcelSheet name="Products" data={this.state.tableList}>
                            <ExcelColumn label="S/N" value="index" />
                        </ExcelSheet>
                    </ExcelFile>
                ) : (
                    <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
                )} */}
            </>
        );

        return (
            <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
                <Row gutter={[16, 16]}>
                    <Col md={21}>
                        <span className="h3">
                            <FormattedMessage
                                id={this.state.controllerName + ".pageTitle"}
                                defaultMessage={"Answer Type"}
                            />
                        </span>
                    </Col>
                    <Col md={1}>{excelExport}</Col>
                    <Col md={1}>
                        <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
                    </Col>
                    <Col md={1}>
                        <AntButton
                            style={{ border: "0px" }}
                            hidden={!this.state.hideInputs}
                            onClick={this.onClickCreateNew}
                            icon={<CreateIcon color="secondary" fontSize="large" />}
                        ></AntButton>
                        <AntButton
                            style={{ border: "0px" }}
                            hidden={this.state.hideInputs}
                            onClick={this.onClickCreateNew}
                            icon={<RemoveCircle color="error" fontSize="large" />}
                        ></AntButton>
                    </Col>
                </Row>
                <div hidden={this.state.hideInputs}>
                    <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
                        {
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="ItpActivityQuestionnary.itemName" defaultMessage="Checklist Item" />}
                                tooltip={<FormattedMessage id="ItpActivityQuestionnary.itemName.w" defaultMessage="Items of selected checklist." />}
                                name="itemName"
                                rules={[
                                    {
                                        required: true,
                                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                    }
                                ]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }}
                                    placeholder="Checklist Items"
                                >
                                    {Boolean(this.state["checklistItemIdsSelectItems"]) &&
                                        this.state["checklistItemIdsSelectItems"].length > 0 &&
                                        this.state["checklistItemIdsSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                                {i.value}
                                            </Option>
                                        ))}{" "}
                                </Select>
                            </Form.Item>
                        }

                        <Form.Item
                            {...layout}
                            label={<FormattedMessage id="ItpActivityQuestionnary.answerType" defaultMessage="Answer Type" />}
                            name="answerType"
                            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                            <Select allowClear showSearch style={{ width: "100%" }} placeholder="Answer Type"
                                onChange={(value) => {
                                    this.setState({ answerType: value })
                                }}
                            >
                                {this.state["answerTypeSelectItems"].map(i => (
                                    <Option key={i.key} value={i.key}>
                                        {i.value}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>


                        {Boolean(this.state.answerType) && (this.state.answerType === 'Matrix_Single' || this.state.answerType === 'Matrix_Multiple') &&
                            <Form.List name="matrixValues">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                {...field}
                                                {...layout}
                                                label={index + 1}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                                    }
                                                ]}
                                            >
                                                <Form.Item
                                                    {...field}
                                                    style={{
                                                        display: "inline-block",
                                                        width: "calc(80%)"
                                                    }}
                                                    name={[field.name, "title"]}
                                                >
                                                    <Input></Input>
                                                </Form.Item>

                                                <Form.Item {...field} hidden name={[field.name, "id"]}>
                                                    <InputNumber></InputNumber>
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Row gutter={[16, 16]}>
                                                <Col md={8}></Col>
                                                <Col md={6}>
                                                    <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}></AntButton>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>}

                        {Boolean(this.state.answerType) && (this.state.answerType === 'Matrix_Single' || this.state.answerType === 'Matrix_Multiple') &&
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="ItpActivityQuestionnary.columns" defaultMessage="Columns" />}
                                name="columns"
                                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    placeholder="columns"

                                    value={this.state.selectedColumns}
                                    onChange={(value) => {
                                        this.setState({ selectedColumns: value })
                                    }}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: "4px 0" }} />
                                            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                                <Input
                                                    style={{ flex: "auto" }}
                                                    value={this.state.codeName}
                                                    onChange={event => {
                                                        this.setState({
                                                            codeName: event.target.value
                                                        });
                                                    }}
                                                />
                                                <a
                                                    style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                                    onClick={() => this.addCode("ItpActivityQuestionnaryColumns")}
                                                >
                                                    <PlusOutlined /> Add item
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    {Boolean(this.state.ItpActivityQuestionnaryColumnsSelectItems) && this.state.ItpActivityQuestionnaryColumnsSelectItems.length > 0
                                        && this.state.ItpActivityQuestionnaryColumnsSelectItems.map(i => (
                                            <Option key={i.id} value={i.id}>
                                                {i.name}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>}

                        {Boolean(this.state.answerType) && (this.state.answerType === 'Checkbox' || this.state.answerType === 'Radiogroup' || this.state.answerType === 'Dropdown' || this.state.answerType === 'Matrix_Multiple') &&
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="ItpActivityQuestionnary.options" defaultMessage="Options" />}
                                name="options"
                                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    placeholder="Options"

                                    value={this.state.selectedOptions}
                                    onChange={(value) => {
                                        this.setState({ selectedOptions: value })
                                    }}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: "4px 0" }} />
                                            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                                <Input
                                                    style={{ flex: "auto" }}
                                                    value={this.state.codeName}
                                                    onChange={event => {
                                                        this.setState({
                                                            codeName: event.target.value
                                                        });
                                                    }}
                                                />
                                                <a
                                                    style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                                    onClick={() => this.addCode("ItpActivityQuestionnaryOptions")}
                                                >
                                                    <PlusOutlined /> Add item
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    {Boolean(this.state.ItpActivityQuestionnaryOptionsSelectItems) && this.state.ItpActivityQuestionnaryOptionsSelectItems.length > 0
                                        && this.state.ItpActivityQuestionnaryOptionsSelectItems.map(i => (
                                            <Option key={i.id} value={i.id}>
                                                {i.name}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        }

                        {Boolean(this.state.answerType) && this.state.answerType === 'Rating' &&
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="ItpActivityQuestionnary.rateMax" defaultMessage="Max Rate" />}
                                name="rateMax"
                                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                                <InputNumber />
                            </Form.Item>}

                        {Boolean(this.state.answerType) && this.state.answerType === 'Imagepicker' &&
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="ItpActivityQuestionnary.imagepicker" defaultMessage="Choose Images" />}
                                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                                <Upload {...props}
                                    onChange={this.handleFileChange}
                                >
                                    <AntButton>
                                        <UploadOutlined /> Upload
                                    </AntButton>
                                </Upload>
                            </Form.Item>}

                        {Boolean(this.state.answerType) && this.state.answerType === 'Boolean' &&
                            <Form.Item
                                {...layout}
                                label={<FormattedMessage id="ItpActivityQuestionnary.boolean" defaultMessage="First" />}
                                style={{ marginBottom: "5px" }}
                            >
                                <Row gutter={8}>
                                    <Col span={10}>
                                        <Form.Item name="labelTrue"
                                            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                        >
                                            <Input
                                                style={{
                                                    width: "100%"
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        Last
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item name="labelFalse"
                                            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                        >
                                            <Input
                                                style={{
                                                    width: "100%"
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                        }

                        {Boolean(this.state.answerType) && this.state.answerType === 'Scoring' &&
                            <>
                                <Form.Item
                                    {...layout}
                                    label={<FormattedMessage id="ItpActivityQuestionnary.scoringMin" defaultMessage="Min" />}
                                    style={{ marginBottom: "5px" }}
                                >
                                    <Row gutter={8}>
                                        <Col span={10}>
                                            <Form.Item name="scoringMinType"
                                                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                            >
                                                <Select allowClear showSearch style={{ width: "100%" }}>
                                                    <Option key={"Greater"} value={"Greater"}>{">"}</Option>
                                                    <Option key={"GreaterEqual"} value={"GreaterEqual"}>{">="}</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item name="scoringMin"
                                                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                            >
                                                <InputNumber
                                                    style={{
                                                        width: "100%"
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form.Item>

                                <Form.Item
                                    {...layout}
                                    label={<FormattedMessage id="ItpActivityQuestionnary.scoringMax" defaultMessage="Max" />}
                                    style={{ marginBottom: "5px" }}
                                >
                                    <Row gutter={8}>
                                        <Col span={10}>
                                            <Form.Item name="scoringMaxType"
                                                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                            >
                                                <Select allowClear showSearch style={{ width: "100%" }}>
                                                    <Option key={"Less"} value={"Less"}>{"<"}</Option>
                                                    <Option key={"LessEqual"} value={"LessEqual"}>{"<="}</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item name="scoringMax"
                                                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                            >
                                                <InputNumber
                                                    style={{
                                                        width: "100%"
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </>
                        }

                        <div hidden={this.state.hideSave}>
                            {
                                <Row gutter={[16, 16]}>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                        <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                                            <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                                        </Button>
                                    </Col>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                </Row>
                            }
                        </div>
                        <div hidden={this.state.hideUpdate}>
                            {
                                <Row gutter={[16, 16]}>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                                        <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                                        <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                                        </Button>
                                    </Col>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                </Row>
                            }
                        </div>
                    </Form>
                </div>
                <br />
                <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                    <Table
                        bordered
                        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                        columns={columns}
                        dataSource={this.state.tableList}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                        pagination={this.state.pagination}
                    />
                </div>

                <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={this.delete}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ItpActivityQuestionnary);
