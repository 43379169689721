import { Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button as AntButton } from "antd";

const getQuantityText = row => {
  if (row.quantity) {
    if (row.quantityUnitAbbreviation) return row.quantity + " " + row.quantityUnitAbbreviation;
    else if (row.quantityUnit) return row.quantity + " " + row.quantityUnit.abbreviation;

    return row.quantity;
  }

  return ";";
};
const render2Tags = (value, orderIndex) => {
  return (
    <table className="table table-bordered table-sm">
      {/* <thead>
          <tr>           
            <th>Value</th>
            <th>Order</th>
          </tr>
        </thead> */}
      <tbody>
        <tr key={0}>
          <td>
            {" "}
            <Tag color="green">{value}</Tag>{" "}
          </td>
          <td>
            {" "}
            <Tag color="red">{orderIndex}</Tag>{" "}
          </td>
          {/* <td className="text-nowrap">{item.periodValue + " " + item.periodUnit}</td> */}
        </tr>
      </tbody>
    </table>
  );
};

let defaultColumns = [
  {
    key: "productNameCode",
    title: "Product-Code",
    dataIndex: "productNameCode",
    render: text => <AntButton type="link">{text} </AntButton>
  },
  {
    key: "quantity",
    title: "Quantity",
    render: (value, row, index) => {
      return !row.isTotalRow ? getQuantityText(row) : row.quantity;
    }
  }
];
let columns = [
  {
    key: "productNameCode",
    title: "Product-Code",
    dataIndex: "productNameCode",
    render: text => <AntButton type="link">{text} </AntButton>
  },
  {
    key: "quantity",
    title: "Quantity",
    render: (value, row, index) => {
      return !row.isTotalRow ? getQuantityText(row) : row.quantity;
    }
  },
  {
    title: "Deadline",
    key: "deadline",
    render: (value, row, index) => {
      return !row.isTotalRow ? render2Tags(moment(row.planedDeliveryDate).format("DD-MM-YYYY"), row.planedDeliveryDateOrderIndex) : "";
    }
  },
  {
    title: "Unit Price",
    key: "unitPrice",
    render: (value, row, index) => {
      return !row.isTotalRow ? render2Tags(row.unitPrice, row.unitPriceOrderIndex) : "";
    }
  },
  {
    key: "ShippingPrice",
    title: "Shipping Price(Kg)",
    dataIndex: "shippingPrice"
  },
  { key: "total", title: "Total", dataIndex: "total" },
  { key: "totalWithShipping", title: "Total With Shipping", dataIndex: "totalWithShipping" },
  {
    key: "currency",
    title: "Currency",
    dataIndex: "currency"
  },
  {
    key: "city",
    title: "Delivery Location",
    dataIndex: "city"
  }
];

function getColumns(showColumn) {
  let fIndex = columns.findIndex(p => p.key === showColumn);
  let cols = [...columns];
  if (Boolean(showColumn)) {
    if (showColumn === "all") cols = [...columns];
    else if (fIndex !== -1) {
      cols = [...defaultColumns];
      cols.push(columns[fIndex]);
    } else cols = [...defaultColumns];
  }
  return cols;
}

const VendorSelectionsubTable = ({ currency, showColumn, vendorId, data, onUpdatePriceSelect }) => {
  const [tableDataSource, setTableDataSource] = useState(data);
  const [tableColumns, setTableColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState(
    Boolean(data)
      ? data
        .filter(x => x.selected === true)
        .map(y => {
          return y.key;
        })
      : null
  );

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      if (Boolean(tableDataSource) && tableDataSource.length > 0) {
        tableDataSource.forEach(element => {
          let fObj = selectedRowKeys.find(p => p === element.key);
          if (Boolean(fObj)) element.selected = true;
          else element.selected = false;
        });

        setTableDataSource(tableDataSource);
        onUpdatePriceSelect(vendorId, tableDataSource);
        setSelectedRowKeys(
          Boolean(tableDataSource)
            ? tableDataSource
              .filter(x => x.selected === true)
              .map(y => {
                return y.key;
              })
            : null
        );
      }
    },
    renderCell: (checked, record, index, originNode) => {
      if (record.isTotalRow) return null;
      else return originNode;
    }
  };

  useEffect(() => {
    console.log("currency", currency);
    console.log("data", [...data]);
    setTableColumns(getColumns(showColumn));
    setTableDataSource(data);
  }, [currency, showColumn, data]);

  return (
    <div>
      <Table
        rowSelection={{
          type: "checkbox",
          ...rowSelection
        }}
        columns={tableColumns}
        dataSource={tableDataSource}
        pagination={false}
      />
    </div>
  );
};
export default VendorSelectionsubTable;
