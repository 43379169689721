import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { error } from "../../MessageHelper";
import {
  Form,
  Select,
} from "antd";
import { handleRequest } from "../../ApiConnector";
import LeaveRequestAnnualVacation from "./LeaveRequestAnnualVacation";
import LeaveRequestSickLeave from "./LeaveRequestSickLeave";
import LeaveRequestSympathyBereavement from "./LeaveRequestSympathyBereavement";
import LeaveRequestMaternity from "./LeaveRequestMaternity";
import LeaveRequestPaternity from "./LeaveRequestPaternity";
import LeaveRequestParental from "./LeaveRequestParental";
import LeaveRequestShortTermDisability from "./LeaveRequestShortTermDisability";
import LeaveRequestLongTermDisability from "./LeaveRequestLongTermDisability";
import LeaveRequestUmrah from "./LeaveRequestUmrah";
import LeaveRequestStudySabbatical from "./LeaveRequestStudySabbatical";

const { Option } = Select;

export class LeaveRequest extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      leavePolicyGroupSelectItems: [],
      leaveRequest: "",
      visibleAnotherPersonnelLog: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      excelData: []
    };
  }
  formRef = React.createRef();
  componentDidMount = async () => {
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var response = await handleRequest("GET", "/api/leavePolicy/leavePolicyGroup");
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        leavePolicyGroupSelectItems: Boolean(response.data) ? response.data : [],
      });
    }
  };

  onChangeLeaveRequest = async key => {
    var leavePolicy = this.state.leavePolicyGroupSelectItems.find(p => p.key === key);
    this.setState({
      leaveRequest: Boolean(leavePolicy) ? leavePolicy.value : "",
    });
    var newObj = {
      groupId: key
    }
    var response = await handleRequest("POST", "/api/leavePolicy/anotherEmployee", newObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        visibleAnotherPersonnelLog: response.data,
      });
    }
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} ref={this.formRef}>
          <Form.Item
            {...layout}
            label="Leave Request"
            name="leaveRequest"
            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              value={this.state.leaveRequest}
              onChange={this.onChangeLeaveRequest}
            >
              {this.state.leavePolicyGroupSelectItems.map(i => (
                <Option key={i.key} value={i.key}>
                  {i.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <br />
          {Boolean(this.state.leaveRequest) &&
            <>
              {
                this.state.leaveRequest === "Annual Vacation" &&
                <LeaveRequestAnnualVacation random={Math.random()} visibleAnotherPersonnelLog={this.state.visibleAnotherPersonnelLog} />
              }
              {
                this.state.leaveRequest === "Sick Leave" &&
                <LeaveRequestSickLeave random={Math.random()} visibleAnotherPersonnelLog={this.state.visibleAnotherPersonnelLog} />
              }
              {
                this.state.leaveRequest === "Sympathy Bereavement" &&
                <LeaveRequestSympathyBereavement random={Math.random()} visibleAnotherPersonnelLog={this.state.visibleAnotherPersonnelLog} />
              }
              {
                this.state.leaveRequest === "Maternity" &&
                <LeaveRequestMaternity random={Math.random()} visibleAnotherPersonnelLog={this.state.visibleAnotherPersonnelLog} />
              }
              {
                this.state.leaveRequest === "Paternity" &&
                <LeaveRequestPaternity random={Math.random()} visibleAnotherPersonnelLog={this.state.visibleAnotherPersonnelLog} />
              }
              {
                this.state.leaveRequest === "Parental" &&
                <LeaveRequestParental random={Math.random()} visibleAnotherPersonnelLog={this.state.visibleAnotherPersonnelLog} />
              }
              {
                this.state.leaveRequest === "Short Term Disability" &&
                <LeaveRequestShortTermDisability random={Math.random()} visibleAnotherPersonnelLog={this.state.visibleAnotherPersonnelLog} />
              }
              {
                this.state.leaveRequest === "Long Term Disability" &&
                <LeaveRequestLongTermDisability random={Math.random()} visibleAnotherPersonnelLog={this.state.visibleAnotherPersonnelLog} />
              }
              {
                this.state.leaveRequest === "Umrah" &&
                <LeaveRequestUmrah random={Math.random()} visibleAnotherPersonnelLog={this.state.visibleAnotherPersonnelLog} />
              }
              {
                this.state.leaveRequest === "Study Sabbatical" &&
                <LeaveRequestStudySabbatical random={Math.random()} visibleAnotherPersonnelLog={this.state.visibleAnotherPersonnelLog} />
              }
            </>
          }
        </Form>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LeaveRequest);
