import "antd/dist/antd.css";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Select, DatePicker, Cascader, InputNumber } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined, ReplyOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../../excel/ExcelReader";
import { Input } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, MinusCircleOutlined, PlusOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import DraggableModal from "../../../Components/DraggableModal";

const { Option } = Select;
const { RangePicker } = DatePicker;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class TaxGroupDetail extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "TaxGroupDetail",
      controllerName: "taxGroupDetail",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      nodes: [],
      typeRevise: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefFixRate = React.createRef();
  formRefGradual = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseGovermentAgencyId = await handleRequest("GET", "/api/" + this.state.controllerName + "/govermentAgencyId");
    if (responseGovermentAgencyId.type === "ERROR") {
      error(responseGovermentAgencyId);
    } else {
      this.setState({
        govermentAgencyIdSelectItems: Boolean(responseGovermentAgencyId.data) ? responseGovermentAgencyId.data : [],
      });
    }

    var responseTaxPayerId = await handleRequest("GET", "/api/" + this.state.controllerName + "/taxPayerId");
    if (responseTaxPayerId.type === "ERROR") {
      error(responseTaxPayerId);
    } else {
      this.setState({
        taxPayerIdSelectItems: Boolean(responseTaxPayerId.data) ? responseTaxPayerId.data : [],
      });
    }

    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/taxCodeId");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      this.setState({
        nodes: responseTree.data
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      taxGroupId: this.props.taxGroupId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = (typeRevise, row) => {
    document.getElementById("kt_scrolltop").click();

    var materialType = dataList.find(p => p.key === row.taxCodeId);
    let taxCodeId = [];
    if (Boolean(materialType)) {
      taxCodeId = materialType.lineage;
    }

    let period = [];
    if (Boolean(row.periodMinDate) && Boolean(row.periodMaxDate)) {
      period.push(moment(row.periodMinDate));
      period.push(moment(row.periodMaxDate));
    }

    this.formRef.current.setFieldsValue({
      ...row,
      taxCodeId: taxCodeId,
      effectiveDate: Boolean(row.effectiveDate) ? moment(row.effectiveDate) : null,
      period: period,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      typeRevise: typeRevise,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  rates = row => {
    this.setState({
      modalRatesShow: true,
      taxGroupDetailId: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this, false)
    };
    let reviseAction = {
      name: "Revise",
      icon: <EditOutlined fontSize="small" color="secondary" />,
      actionClick: this.edit.bind(this, true)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };
    let ratesAction = {
      name: "Rates",
      icon: <ReplyOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.rates.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(reviseAction);
    actionList.push(ratesAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      typeRevise: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    if (Boolean(this.props.taxGroupId) && this.props.taxGroupId > 0) {
      const newItem = {
        ...values,
        id: this.state.id,
        taxGroupId: this.props.taxGroupId,
      };

      newItem.taxCodeId =
        Boolean(values.taxCodeId) && values.taxCodeId.length > 0 ? values.taxCodeId[values.taxCodeId.length - 1] : null;

      if (Boolean(values.period) && values.period.length === 2) {
        newItem.periodMinDate = values.period[0];
        newItem.periodMaxDate = values.period[1];
      }

      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
      } else {
        if (this.state.typeRevise) {
          response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/revise/" + newItem.id, newItem);
        } else {
          response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
        }
      }

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showError("Tax Group not found.");
    }
  };

  saveFixRate = async values => {
    if (Boolean(this.state.taxGroupDetailId) && this.state.taxGroupDetailId > 0) {
      const newItem = {
        ...values,
        id: this.state.taxGroupDetailId,
      };

      var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/saveFixRate/" + newItem.id, newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.setState({
          showFixRateModal: false,
        });
      }
    } else {
      showError("Tax Group Detail not found.");
    }
  }

  saveGradual = async values => {
    if (Boolean(this.state.taxGroupDetailId) && this.state.taxGroupDetailId > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveGradual/" + this.state.taxGroupDetailId, values.taxList);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.setState({
          showGradualModal: false,
        });
      }
    } else {
      showError("Tax Group Detail not found.");
    }
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            ...element,
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  taxRatesModal = async row => {
    this.setState({
      modalRatesShow: true,
      taxGroupDetailId: row.id
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="TaxGroupDetail.revNo" defaultMessage="Rev no" />,
        key: "revNo",
        render: record => {
          return record.revNo;
        }, ...getColumnFilter({
          inputName: "revNo", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="TaxGroupDetail.TaxDescription" defaultMessage="Tax Description" />,
        key: "description",
        render: record => {
          return record.description;
        },
        ...getColumnFilter({
          inputName: "description",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="TaxDescriptions.GovermentAgency" defaultMessage="Goverment Agency" />,
        key: "govermentAgencyId",
        render: record => {
          return record.govermentAgencyName;
        },
        ...getColumnFilter({
          inputName: "govermentAgencyId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["govermentAgencyIdSelectItems"]) ? this.state["govermentAgencyIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="TaxDescriptions.TaxCode" defaultMessage="Tax Code" />,
        key: "taxCodeId",
        render: record => {
          return record.taxCodeName;
        },
        ...getColumnFilter({
          inputName: "taxCodeId",
          inputType: "CASCADER",
          selectItems: Boolean(this.state["nodes"]) ? this.state["nodes"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="TaxDescriptions.GovermentAgency" defaultMessage="Goverment Agency" />,
        key: "taxPayerId",
        render: record => {
          return record.taxPayerName;
        },
        ...getColumnFilter({
          inputName: "taxPayerId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["taxPayerIdSelectItems"]) ? this.state["taxPayerIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="TaxDescriptions.Period" defaultMessage="Period" />,
        key: "period",
        render: record => {
          return Boolean(record.periodMinDate) && Boolean(record.periodMaxDate)
            ? moment(record.periodMinDate).format("DD-MM-YYYY HH:mm") + " / " +
            moment(record.periodMaxDate).format("DD-MM-YYYY HH:mm")
            : "";
        }
      },
      {
        title: <FormattedMessage id="TaxDescriptions.EffectiveDate" defaultMessage="Effective Date" />,
        key: "effectiveDate",
        render: record => {
          return Boolean(record.effectiveDate) ? moment(record.effectiveDate).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "effectiveDate",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="TaxDescriptions.TaxRates" defaultMessage="Tax Rates" />,
        key: "taxRates",
        render: record => {
          return (
            <AntButton
              onClick={() => this.taxRatesModal(record)}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ZoomInOutlined fontSize="large" />}
            ></AntButton>
          );
        }
      },
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="TaxGroupDetail" hideElement={true}>
            <ExcelSheet name="TaxGroupDetail" data={this.state.tableList}>
              <ExcelColumn label="Tax Code" value="code" />
              <ExcelColumn label="Tax Description" value="description" />
              <ExcelColumn label="Remarks" value="remarks" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    return (
      <Badge.Ribbon text={<FormattedMessage id="TaxGroupDetail.pageTitle" defaultMessage="Taxes and Tax Rates Log" />} placement="start" color="purple" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>

              <Col md={21}></Col> <Col md={1}>{excelExport}</Col>
              <Col md={1}>

                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>

                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TaxGroupDetail.TaxDescription" defaultMessage="Tax Description" />}
                  name="description"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TaxDescriptions.GovermentAgency" defaultMessage="Goverment Agency" />}
                  name="govermentAgencyId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                  >
                    {Boolean(this.state["govermentAgencyIdSelectItems"]) && this.state["govermentAgencyIdSelectItems"].length > 0 &&
                      this.state["govermentAgencyIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TaxDescriptions.TaxCode" defaultMessage="Tax Code" />}
                  name="taxCodeId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Cascader
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    options={this.state.nodes}
                  />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TaxDescriptions.TaxPayer" defaultMessage="Tax Payer" />}
                  name="taxPayerId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                  >
                    {Boolean(this.state["taxPayerIdSelectItems"]) && this.state["taxPayerIdSelectItems"].length > 0 &&
                      this.state["taxPayerIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TaxGroupDetail.Period" defaultMessage="Period" />}
                  name="period"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <RangePicker format="DD-MM-YYYY HH:mm" showTime={{ format: "HH:mm" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TaxGroupDetail.EffectiveDate" defaultMessage="Effective Date" />}
                  name="effectiveDate"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <DatePicker format="DD-MM-YYYY HH:mm" showTime={{ format: "HH:mm" }} />
                </Form.Item>
              }

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>

                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>

                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">

                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>

                {
                  <Row gutter={[16, 16]}>

                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>

                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>

                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>

                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">

                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">

              <div className="alert-icon">

                <FilterOutlined />
              </div>
              <div className="alert-text">

                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">

                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                      <span>

                        <Tooltip title="Remove">

                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">

                <Tooltip title="Remove All">

                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              columns={columns}
              rowKey={record => record.id}
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>
        </div>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>

          <Modal.Header closeButton>

            <Modal.Title id="contained-modal-title-vcenter">

              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <p>

              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>

            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>

              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>

              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <DraggableModal
          title={<FormattedMessage id="TaxGroupDetail.Type" defaultMessage="Type" />}
          centered
          width={400}
          maskClosable={false}
          open={this.state.modalRatesShow}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ modalRatesShow: false });
          }}
          content={
            <>
              <Button
                onClick={() => {
                  this.setState({
                    showFixRateModal: true,
                    modalRatesShow: false
                  }, async () => {
                    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fixRate/" + this.state.taxGroupDetailId);
                    if (response.type === "ERROR") {
                      error(response);
                    } else {
                      if (Boolean(response.data)) {
                        this.formRefFixRate.current.setFieldsValue({
                          ...response.data,
                        });
                      } else {
                        this.formRefFixRate.current.resetFields();
                      }
                    }
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="TaxGroupDetail.FixRate" defaultMessage="Fix Rate" />}
              </Button>

              <br /> <br />
              <Button
                onClick={() => {
                  this.setState({
                    showGradualModal: true,
                    modalRatesShow: false
                  }, async () => {
                    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/gradualRates/" + this.state.taxGroupDetailId);
                    if (response.type === "ERROR") {
                      error(response);
                    } else {
                      if (Boolean(response.data)) {
                        this.formRefGradual.current.setFieldsValue({
                          taxList: response.data,
                        });
                      } else {
                        this.formRefGradual.current.resetFields();
                      }
                    }
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="TaxGroupDetail.Gradual" defaultMessage="Gradual" />}
              </Button>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="TaxGroupDetail.FixRate" defaultMessage="Fix Rate" />}
          centered
          width={600}
          maskClosable={false}
          open={this.state.showFixRateModal}
          onCancel={() => {
            this.setState({ showFixRateModal: false });
          }}
          onOk={() => {
            this.formRefFixRate.current.submit();
          }}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveFixRate} ref={this.formRefFixRate}>
              <Row gutter={8}>
                <Col span={6}>
                  <Form.Item name="base">
                    <Select allowClear
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                    >
                      <Option key={"Toplam_Maas"} value={"Toplam_Maas"}>
                        {"Toplam Maaş"}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2}>
                </Col>
                <Col span={6}>
                  <Form.Item name="fixPercent">
                    <InputNumber placeholder="percent"
                      onChange={() => {
                        this.formRefFixRate.current.setFieldsValue({
                          "fixAmount": ""
                        });
                      }}
                      style={{
                        width: "100%"
                      }}
                      min={0}
                      max={100}
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                </Col>
                <Col span={6}>
                  <Form.Item name="fixAmount">
                    <InputNumber placeholder="amount"
                      onChange={() => {
                        this.formRefFixRate.current.setFieldsValue({
                          "fixPercent": ""
                        });
                      }}
                      style={{
                        width: "100%"
                      }}
                      min={0}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="TaxGroupDetail.Gradual" defaultMessage="Gradual" />}
          centered
          width={1000}
          maskClosable={false}
          open={this.state.showGradualModal}
          onCancel={() => {
            this.setState({ showGradualModal: false });
          }}
          onOk={() => {
            this.formRefGradual.current.submit();
          }}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveGradual} ref={this.formRefGradual}>
              <Form.List name="taxList">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"TaxGroupDetail.Base"} defaultMessage={"Base"} />
                            </th>
                            <th colSpan="2" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"TaxGroupDetail.Range1"} defaultMessage={"Range 1"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"TaxGroupDetail.Range2"} defaultMessage={"Range 2"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"TaxGroupDetail.Percent"} defaultMessage={"Percent"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"TaxGroupDetail.Amount"} defaultMessage={"Amount"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"TaxGroupDetail.RateCoverage"} defaultMessage={"Rate Coverage"} />
                            </th>
                            <th colSpan="1">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "base"]}>
                                    <Select allowClear
                                      style={{ width: "100%" }}
                                      optionFilterProp="children"
                                    >
                                      <Option key={"Toplam_Maas"} value={"Toplam_Maas"}>
                                        {"Toplam Maaş"}
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "amountMin"]}>
                                    <InputNumber min={0} placeholder="Range 1" />
                                  </Form.Item>
                                </td>
                                to
                                <td>
                                  <Form.Item noStyle name={[field.name, "amountMax"]}>
                                    <InputNumber min={0} placeholder="Range 2" />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "ratePercent"]}>
                                    <InputNumber min={0} max={100} placeholder="%"
                                      onChange={() => {
                                        this.formRefGradual.current.setFieldsValue({
                                          "rateAmount": ""
                                        });
                                      }}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "rateAmount"]}>
                                    <InputNumber min={0} placeholder="rateAmount"
                                      onChange={() => {
                                        this.formRefGradual.current.setFieldsValue({
                                          "ratePercent": ""
                                        });
                                      }}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "rateCoverage"]}>
                                    <Select allowClear
                                      style={{ width: "100%" }}
                                      optionFilterProp="children"
                                    >
                                      <Option key={"Only_This_Line"} value={"Only_This_Line"}>
                                        {"Only this line"}
                                      </Option>
                                      <Option key={"Addition_Of_Previousus"} value={"Addition_Of_Previousus"}>
                                        {"Addition of previousus"}
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"TaxGroupDetail.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          }
        />

      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TaxGroupDetail);