import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Checkbox, Collapse, Select, Space, Typography } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

export class Authorization extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "Authorization",
      controllerName: "pageSettings",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      houseRoomList: [],
      loadingButtonSubmit: false,
      activePanelKey: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    fillFilterOperations();

    this.setState({
      activePanelKey: ["1", "2", "3"]
    }, async () => {
      var responseAuthorization = await handleRequest("GET", "/api/" + this.state.controllerName + "/pageSettings/" + this.props.pageName);
      if (responseAuthorization.type === "ERROR") {
        error(responseAuthorization);
      } else {
        if (Boolean(responseAuthorization.data)) {
          let data = responseAuthorization.data;
          console.log("data", data);

          let userAuthorizations = [];
          if (Boolean(data.userAuthorizations) && data.userAuthorizations.length > 0) {
            data.userAuthorizations.forEach(element => {
              userAuthorizations.push({ "pageActionId": element.pageActionId, "name": element.pageActionName, "value": element.value });
            });
          }

          let seniorAuthorizations = [];
          if (Boolean(data.seniorAuthorizations) && data.seniorAuthorizations.length > 0) {
            data.seniorAuthorizations.forEach(element => {
              seniorAuthorizations.push({ "pageActionId": element.pageActionId, "name": element.pageActionName, "value": element.value });
            });
          }

          let costodianAuthorizations = [];
          if (Boolean(data.costodianAuthorizations) && data.costodianAuthorizations.length > 0) {
            data.costodianAuthorizations.forEach(element => {
              costodianAuthorizations.push({ "pageActionId": element.pageActionId, "name": element.pageActionName, "value": element.value });
            });
          }

          if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
            this.formRef.current.setFieldsValue({
              ...data,
              userAuthorizations: Boolean(userAuthorizations) && userAuthorizations.length > 0 ? userAuthorizations : [],
              seniorAuthorizations: Boolean(seniorAuthorizations) && seniorAuthorizations.length > 0 ? seniorAuthorizations : [],
              costodianAuthorizations: Boolean(costodianAuthorizations) && costodianAuthorizations.length > 0 ? costodianAuthorizations : [],
            });
          }
        }
      }
    });
  };

  fillComboboxes = async () => {
    var responseDepartment = await handleRequest("GET", "/api/" + this.state.controllerName + "/department");
    if (responseDepartment.type === "ERROR") {
      error(responseDepartment);
    }
    this.setState({
      departmentSelectItems: Boolean(responseDepartment.data) ? responseDepartment.data : [],
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: "",
        tableVisibleModal: true,
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder,
          tableVisibleModal: true,
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: "",
          tableVisibleModal: true,
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  resetInputs = () => {

  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  save = async values => {
    console.log("values", values);
    const newItem = {
      ...values,
      pageName: this.props.pageName,
    };

    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAuthorization", newItem);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.props.nextTab();
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  authorizationFunction(type) {
    return <div className="card-header py-3">
      <div className="card-title align-items-start flex-column">
        <h5 className="card-label font-weight-bolder text-dark"><FormattedMessage id="ProjectsTreeCompanyDrawer.Authorizations" defaultMessage="Authorizations" /></h5>
      </div>

      <Form.List name={type}>
        {(fields, { add, remove }) => {
          return (
            <>
              <TableBoot bordered size="sm">
                <tbody>
                  {fields.map(field => {
                    return (
                      <tr key={field.key}>
                        <Space>
                          <Form.Item
                            noStyle name={[field.name, "value"]}
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            valuePropName="checked"
                          >
                            <Checkbox></Checkbox>
                          </Form.Item>

                          <Text type="secondary">
                            {Boolean(this.formRef) && this.formRef.current && this.formRef.current.getFieldValue(type) &&
                              this.formRef.current.getFieldValue(type).length > 0 &&
                              <>
                                {this.formRef.current.getFieldValue(type)[field.name].name}
                              </>
                            }
                          </Text>
                        </Space>
                      </tr>
                    );
                  })}
                </tbody>
              </TableBoot>
            </>
          );
        }}
      </Form.List>
    </div>;
  }

  setOpenPanels = key => {
    this.setState({
      activePanelKey: key
    });
  };

  render() {
    const layout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 8 }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} ref={this.formRef}>

          <Collapse hoverable style={{ align: "left" }} size="small" activeKey={this.state.activePanelKey} onChange={this.setOpenPanels} >
            <Panel
              key="1"
              header={<FormattedMessage id="PageFormInput.User" defaultMessage="User" />}>
              <Row gutter={8}>
                <Col span={10}>
                  <Form.Item
                    name="userViewingAllInTheLog"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox>
                      <FormattedMessage id="PageFormInput.ViewingAllInTheLog" defaultMessage="Viewing all in the log" />
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
                <Col span={10}>
                  <Form.Item
                    name="userViewingOwnInTheLog"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox>
                      <FormattedMessage id="PageFormInput.ViewingOwnInTheLog" defaultMessage="Viewing own in the log" />
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              {this.authorizationFunction("userAuthorizations")}

            </Panel>

            <Panel
              key="2"
              header={<FormattedMessage id="PageFormInput.Senior" defaultMessage="Senior" />}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageFormInput.ViewingLogForImmediateSubLevel" defaultMessage="Viewing log for immediate sub-level" />}
                name="seniorViewingLogForImmediateSubLevel"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageFormInput.ViewingLogForAllSubLevel" defaultMessage="Viewing log for all sub-level" />}
                name="seniorViewingLogForAllSubLevel"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>

              {this.authorizationFunction("seniorAuthorizations")}
            </Panel>

            <Panel
              key="3"
              header={<FormattedMessage id="PageFormInput.Costodian" defaultMessage="Costodian" />}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="RequestForQuotation.Department" defaultMessage="Department" />}
                name="costodianDepartmentId"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select
                  showSearch allowClear optionFilterProp="children"
                  style={{ width: "100%" }}
                >
                  {Boolean(this.state.departmentSelectItems) && this.state.departmentSelectItems.length > 0 &&
                    this.state.departmentSelectItems.map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              {this.authorizationFunction("costodianAuthorizations")}
            </Panel>
          </Collapse>
          <br />
          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={19} lg={19} xl={19} xxl={19}></Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Button type="submit" style={{ width: "100%" }} variant="success">
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Authorization);