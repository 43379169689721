import { UploadOutlined, PlusOutlined, MinusCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button as AntButton, Col, DatePicker, Form, Input, InputNumber, Row, Checkbox, Image, Upload, Space, Tooltip } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { Button, Form as FormBoot, Table as TableBoot } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest, API_BASE_ROOT } from "../../ApiConnector";
import { error, showError, showSuccess, showWarning } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";
import DraggableModal from "../../Components/DraggableModal";

export class InventoryLogReceive extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "inventoryLog",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      filePath: "",
      fileList: [],
      checkedStates: [],
      quantity: 0,
      controlDate: null,
      receivingDate: null,
      controlQuantity: 0,
      receivingQuantity: 0,
      rejectedQuantity: 0,
      receivingUnitPrice: 0,
      calcRejectedQuantity: 0,
      saveRejectedQuantityList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();
  formRefAcceptedRejectedQuantity = React.createRef();
  formRefDecisionForRejectedQuantities = React.createRef();

  componentDidMount = async () => {
    this.initPage();
  };

  initPage = async () => {
    console.log("AAA", this.props);
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();

    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.resetFields();
      if (Boolean(this.props.selectedPO)) {
        this.setState(
          {
            invoice: this.props.selectedPO.invoice,
            delivery: this.props.selectedPO.delivery,
            requiredDocuments: Boolean(this.props.selectedPO.documents) ? this.props.selectedPO.documents : this.props.selectedPO.requiredDocuments,
            quantity: Boolean(this.props.selectedPO.quantity) && this.props.selectedPO.quantity > 0 ? this.props.selectedPO.quantity : 0,
            saveAcceptedQuantity: 0,
            saveRejectedQuantityList: []
          },
          () => {
            if (Boolean(this.state.requiredDocuments) && this.state.requiredDocuments.length > 0) {
              this.state.requiredDocuments.forEach((element, index) => {
                var fileUrl = [];
                if (Boolean(element.fileUrl) && element.fileUrl.length > 0) {
                  if (element.fileUrl.includes("download/")) {
                    let obj = {
                      uid: index,
                      name: element.fileUrl.split("download/")[1],
                      url: element.fileUrl,
                      thumbUrl: element.fileUrl
                    };
                    fileUrl.push(obj);
                  }
                }
                element.fileUrl = fileUrl;
                element.date = Boolean(element.date) ? moment(element.date) : null;
                element.description = Boolean(element.document) ? element.document : element.description;
              });
            }

            this.formRef.current.setFieldsValue({
              ...this.props.selectedPO,
              requiredDocuments: this.state.requiredDocuments
            });
          }
        );
      } else {
        this.formRef.current.setFieldsValue({
          requiredDocuments: null,
          quantity: Boolean(this.props.selectedPO) ? this.props.selectedPO.quantity : null,
          unitPrice: Boolean(this.props.selectedPO) ? this.props.selectedPO.unitPrice : null,
          oldUnitPrice: Boolean(this.state.unitPrice) ? this.state.unitPrice : null
        });
      }
    }
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initPage();
    }
  };

  fillComboboxes = async () => {
    var responseGetAllReceive = await handleRequest(
      "GET",
      "/api/" +
      this.state.controllerName +
      "/findByPriceIdReceive/" +
      this.props.selectedPO.id +
      "/" +
      this.props.selectedPO.materialReceiveStatusName
    );
    if (responseGetAllReceive.type === "ERROR") {
      error(responseGetAllReceive);
    } else {
      const { quantity } = this.state;
      let controlQuantity = responseGetAllReceive.data.controlQuantity;
      let receivingQuantity = responseGetAllReceive.data.receivingQuantity;
      if (receivingQuantity > quantity) {
        receivingQuantity = quantity;
      }
      if (controlQuantity > quantity) {
        controlQuantity = quantity;
      }
      this.setState(
        {
          receivingQuantity: receivingQuantity
        },
        () => {
          this.setState(
            {
              showAcceptRejectModal: Boolean(controlQuantity) ? true : false
            },
            () => {
              if (Boolean(this.formRefAcceptedRejectedQuantity) && Boolean(this.formRefAcceptedRejectedQuantity.current)) {
                this.formRefAcceptedRejectedQuantity.current.setFieldsValue({
                  acceptedQuantity: controlQuantity,
                  rejectedQuantity: receivingQuantity - controlQuantity
                });
              }

              this.formRef.current.setFieldsValue({
                controlDate: Boolean(responseGetAllReceive.data.controlDate) ? moment(responseGetAllReceive.data.controlDate) : null,
                controlQuantity: controlQuantity,
                receivingDate: Boolean(responseGetAllReceive.data.receivingDate) ? moment(responseGetAllReceive.data.receivingDate) : null,
                receivingQuantity: receivingQuantity,
                receivingUnitPrice: responseGetAllReceive.data.receivingUnitPrice
              });
            }
          );
        }
      );
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.props.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => { };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.props.selectedPO.id,
      stockId: this.props.stockId,
      receivingType: this.props.receivingType
    };

    var requiredDocuments = [];
    if (Boolean(newItem.requiredDocuments) && newItem.requiredDocuments.length > 0) {
      newItem.requiredDocuments.forEach(element => {
        if (Boolean(element.fileUrl) && element.fileUrl.length > 0 && Boolean(element.fileUrl[0].response)) {
          requiredDocuments.push({
            ["document"]: element.description,
            ["fileUrl"]: element.fileUrl[0].response.url,
            ["number"]: element.number,
            ["date"]: Boolean(element.date) ? element.date : null
          });
        } else {
          requiredDocuments.push({
            ["document"]: element.description,
            ["fileUrl"]: element.fileUrl.toString(),
            ["number"]: element.number,
            ["date"]: Boolean(element.date) ? element.date : null
          });
        }
      });
    }
    newItem.documents = requiredDocuments;
    newItem.requiredDocuments = null;
    newItem.saveAcceptedQuantity = this.state.saveAcceptedQuantity;
    newItem.saveRejectedQuantityList = this.state.saveRejectedQuantityList;

    console.log("AAA", newItem);
    var response = await handleRequest(
      "PUT",
      "/api/" + this.state.controllerName + "/saveReceiveAcceptedRejectedQuantity/" + newItem.id + "/" + this.props.selectedPO.materialReceiveStatusName,
      newItem
    );
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.props.saveStockReceiving();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  disableDates = () => {
    var today, dd, mm, yyyy;
    today = new Date();
    dd = today.getDate() + 1;
    mm = today.getMonth() + 1;
    yyyy = today.getFullYear();

    return dd + "-" + mm + "-" + yyyy;
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  saveAcceptedRejectedQuantity = async values => {
    if (Boolean(this.props.selectedPO) && Boolean(this.props.selectedPO.id) && this.props.selectedPO.id > 0) {
      if (Boolean(values.rejectedQuantity) && values.rejectedQuantity > 0) {
        this.setState(
          {
            showAcceptRejectModal: false,
            showDecisionForRejectedQuantitiesModal: true,
            acceptedQuantity: values.acceptedQuantity,
            saveAcceptedQuantity: 0,
            saveRejectedQuantityList: []
          },
          async () => {
            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/priceDecisionForRejected/" + this.props.selectedPO.id);
            if (response.type === "ERROR") {
              error(response);
            }
            this.formRefDecisionForRejectedQuantities.current.setFieldsValue({
              decisionForRejectedQuantities: Boolean(response.data) ? response.data : []
            });
          }
        );
      } else {
        if (Boolean(values.acceptedQuantity) && values.acceptedQuantity > 0) {
          this.setState({
            saveAcceptedQuantity: values.acceptedQuantity,
            saveRejectedQuantityList: [],
            showAcceptRejectModal: false
          });
        } else {
          showError("Accepted quantity is required.");
        }
      }
    } else {
      showError("Not found.");
    }
  };

  saveDecisionForRejectedQuantities = async values => {
    if (Boolean(this.props.selectedPO) && Boolean(this.props.selectedPO.id) && this.props.selectedPO.id > 0) {
      if (Boolean(values) && Boolean(values.decisionForRejectedQuantities) && values.decisionForRejectedQuantities.length > 0) {
        this.setState({
          saveRejectedQuantityList: values.decisionForRejectedQuantities,
          saveAcceptedQuantity: this.state.acceptedQuantity,
          showAcceptRejectModal: false,
          showDecisionForRejectedQuantitiesModal: false
        });
      } else {
        showWarning("Not found.");
      }
    } else {
      showWarning("Not found.");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const listUploadProps = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }

      return e && e.fileList;
    };

    const { checkedStates } = this.state;

    const handleCheckboxChange = (index, checked) => {
      const newCheckedStates = [...checkedStates];
      newCheckedStates[index] = checked;

      this.setState({
        checkedStates: newCheckedStates
      });
    };

    const isFieldEnabled = index => (Boolean(checkedStates) && checkedStates.length > 0 ? checkedStates[index] === true : false);

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };

    let { quantity, receivingQuantity } = this.state;

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={8}>
            <Col span={20}>
              <Row gutter={8}>
                <Col span={8}></Col>
                <Col span={4}>
                  <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                    {<FormattedMessage id="InventoryLogReceive.POInfo" defaultMessage="P.O. Quantity" />}
                  </FormBoot.Label>
                </Col>

                <Col span={5}>
                  <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                    {<FormattedMessage id="InventoryLogReceive.Receiving" defaultMessage="Received Quantity" />}
                  </FormBoot.Label>
                </Col>
                <Col span={6}>
                  <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                    {<FormattedMessage id="InventoryLogReceive.AcceptedQuantity" defaultMessage="Accepted Quantity" />}
                  </FormBoot.Label>
                </Col>
              </Row>

              <Form.Item
                {...layout2}
                label={<FormattedMessage id="InventoryLogReceive.Quantity" defaultMessage="Quantity" />}
                style={{ marginBottom: "5px" }}
              >
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item name="quantity">
                      <InputNumber
                        readOnly
                        style={{
                          width: "100%"
                        }}
                        min={0}
                        parser={value => value.replace(",", ".")}
                        formatter={value => value.replace(".", ",")}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item name="receivingQuantity">
                      <InputNumber
                        style={{
                          width: "100%"
                        }}
                        min={0}
                        max={quantity}
                        parser={value => value.replace(",", ".")}
                        formatter={value => value.replace(".", ",")}
                        onChange={value => {
                          this.setState({
                            receivingQuantity: value
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}></Col>
                  <Col span={6}>
                    <Form.Item name="controlQuantity">
                      <InputNumber
                        style={{
                          width: "100%"
                        }}
                        min={0}
                        max={receivingQuantity}
                        parser={value => value.replace(",", ".")}
                        formatter={value => value.replace(".", ",")}
                        onChange={value => {
                          setTimeout(() => {
                            this.setState(
                              {
                                showAcceptRejectModal: value !== receivingQuantity
                              },
                              () => {
                                if (Boolean(this.formRefAcceptedRejectedQuantity) && Boolean(this.formRefAcceptedRejectedQuantity.current)) {
                                  this.formRefAcceptedRejectedQuantity.current.setFieldsValue({
                                    acceptedQuantity: value,
                                    rejectedQuantity: receivingQuantity - value
                                  });
                                }
                              }
                            );
                          }, 2000);
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={3}>
                    <Form.Item>
                      <Tooltip title={this.state.modalControlInfo}>
                        <AntButton
                          icon={<InfoCircleOutlined />}
                          onClick={() => {
                            this.setState({
                              modalControlInfo: true
                            });
                          }}
                        ></AntButton>
                      </Tooltip>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item
                {...layout2}
                label={<FormattedMessage id="InventoryLogReceive.Date" defaultMessage="Date" />}
                style={{ marginBottom: "5px" }}
              >
                <Row gutter={8}>
                  <Col span={6}></Col>
                  <Col span={8}>
                    <Form.Item name="receivingDate">
                      <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" max={this.disableDates()} />
                    </Form.Item>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={8}>
                    <Form.Item name="controlDate">
                      <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" max={this.disableDates()} />
                    </Form.Item>
                  </Col>
                  <Col span={2}></Col>
                </Row>
              </Form.Item>

              <Form.Item
                {...layout2}
                label={<FormattedMessage id="InventoryLogReceive.UnitPrice" defaultMessage="Unit Price" />}
                style={{ marginBottom: "5px" }}
              >
                <Row gutter={8}>
                  <Col span={5}>
                    <Form.Item name="unitPrice">
                      <InputNumber
                        readOnly
                        style={{
                          width: "100%"
                        }}
                        min={0}
                        parser={value => value.replace(",", ".")}
                        formatter={value => value.replace(".", ",")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={5}>
                    <Form.Item name="receivingUnitPrice">
                      <InputNumber
                        style={{
                          width: "100%"
                        }}
                        min={0}
                        parser={value => value.replace(",", ".")}
                        formatter={value => value.replace(".", ",")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={9}></Col>
                </Row>
              </Form.Item>

              {Boolean(this.state.requiredDocuments) && this.state.requiredDocuments.length > 0 && (
                <Form.List name={"requiredDocuments"}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item {...layout2} label={index + 1}>
                          <Form.Item
                            {...field}
                            style={{ display: "inline-block", width: "calc(40%)" }}
                            name={[field.name, "check"]}
                            valuePropName="checked"
                          >
                            <Checkbox onChange={e => handleCheckboxChange(index, e.target.checked)}>
                              <Form.Item
                                {...field}
                                style={{
                                  display: "inline-block",
                                  width: "calc(100%)"
                                }}
                                name={[field.name, "description"]}
                              >
                                <Input readOnly></Input>
                              </Form.Item>
                            </Checkbox>
                          </Form.Item>

                          {isFieldEnabled(index) && (
                            <>
                              <Form.Item
                                {...field}
                                style={{
                                  display: "inline-block",
                                  width: "calc(40%)"
                                }}
                              ></Form.Item>

                              <Form.Item
                                {...field}
                                style={{
                                  display: "inline-block",
                                  width: "calc(30%)"
                                }}
                                name={[field.name, "date"]}
                              >
                                <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
                              </Form.Item>

                              <Form.Item
                                {...field}
                                style={{
                                  display: "inline-block",
                                  width: "calc(40%)"
                                }}
                                name={[field.name, "number"]}
                              >
                                <Input placeholder="Number"></Input>
                              </Form.Item>

                              <Form.Item
                                {...field}
                                name={[field.name, "fileUrl"]}
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                                style={{
                                  display: "inline-block",
                                  width: "calc(30%)"
                                }}
                              >
                                <Upload {...listUploadProps} name="file" maxCount="1">
                                  <AntButton icon={<UploadOutlined />}>
                                    <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                                  </AntButton>
                                </Upload>
                              </Form.Item>
                            </>
                          )}
                        </Form.Item>
                      ))}
                    </>
                  )}
                </Form.List>
              )}
            </Col>
            <Col span={2}>
              <br />
              <br />
              <br />
              {Boolean(this.props.selectedPO) && Boolean(this.props.selectedPO.imageUrl) && <Image src={this.props.selectedPO.imageUrl} />}
            </Col>
            <Col span={2}></Col>
          </Row>
          {
            <Row gutter={[16, 16]}>
              <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}></Col>
            </Row>
          }
        </Form>

        <DraggableModal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={500}
          okButtonProps={{ hidden: true }}
          open={this.state.modalDeleteShow}
          onCancel={() => {
            this.setState({ modalDeleteShow: false });
          }}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDeleteShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Space>
          ]}
          content={
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          }
        />

        <DraggableModal
          centered
          closable={true}
          maskClosable={false}
          width={600}
          title={<FormattedMessage id="InventoryLogReceive.AcceptedRejectedQuantity" defaultMessage="Accepted/Rejected Quantity" />}
          open={this.state.showAcceptRejectModal}
          onCancel={() => {
            this.setState(
              {
                showAcceptRejectModal: false
              },
              () => {
                this.formRef.current.setFieldsValue({
                  controlQuantity: 0
                });
              }
            );
          }}
          onOk={() => {
            this.formRefAcceptedRejectedQuantity.current.submit();
          }}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveAcceptedRejectedQuantity} ref={this.formRefAcceptedRejectedQuantity}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryLogReceive.AcceptedQuantity" defaultMessage="Accepted Quantity" />}
                name="acceptedQuantity"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber
                  min={0}
                  max={receivingQuantity}
                  style={{ width: "100%" }}
                  parser={value => value.replace(",", ".")}
                  formatter={value => value.replace(".", ",")}
                  onChange={value => {
                    this.formRefAcceptedRejectedQuantity.current.setFieldsValue({
                      rejectedQuantity: receivingQuantity - value
                    });
                    this.setState({
                      rejectedQuantity: receivingQuantity - value
                    });
                  }}
                />
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="InventoryLogReceive.RejectedQuantity" defaultMessage="Rejected Quantity" />}
                name="rejectedQuantity"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber
                  min={0}
                  max={receivingQuantity}
                  style={{ width: "100%" }}
                  parser={value => value.replace(",", ".")}
                  formatter={value => value.replace(".", ",")}
                  onChange={value => {
                    this.formRefAcceptedRejectedQuantity.current.setFieldsValue({
                      acceptedQuantity: receivingQuantity - value
                    });
                    this.setState({
                      rejectedQuantity: receivingQuantity - value
                    });
                  }}
                />
              </Form.Item>
            </Form>
          }
        />

        <DraggableModal
          centered
          width={800}
          title={<FormattedMessage id="InventoryLogReceive.DecisionForRejectedQuantities" defaultMessage="Decision for rejected quantities" />}
          open={this.state.showDecisionForRejectedQuantitiesModal}
          onCancel={() => {
            this.setState({
              showDecisionForRejectedQuantitiesModal: false
            });
          }}
          onOk={() => {
            this.formRefDecisionForRejectedQuantities.current.submit();
          }}
          content={
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveDecisionForRejectedQuantities}
              ref={this.formRefDecisionForRejectedQuantities}
            >
              <Form.List name="decisionForRejectedQuantities">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}></th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"InventoryLogReceive.Title"} defaultMessage={"Title"} />
                            </th>
                            <th colSpan="1">
                              <FormattedMessage id={"InventoryLogReceive.Quantity"} defaultMessage={"Quantity"} />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "visibility"]} valuePropName="checked">
                                    <Checkbox />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "titleName"]}>
                                    <Input
                                      style={{
                                        width: "100%"
                                      }}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "quantity"]}>
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={0}
                                      // max={rejectedQuantity - calcRejectedQuantity}
                                      parser={value => value.replace(",", ".")}
                                      formatter={value => value.replace(".", ",")}
                                      onChange={value => {
                                        let decisionForRejectedQuantities =
                                          Boolean(this.formRefDecisionForRejectedQuantities) &&
                                            Boolean(this.formRefDecisionForRejectedQuantities.current) &&
                                            Boolean(this.formRefDecisionForRejectedQuantities.current.getFieldValue("decisionForRejectedQuantities"))
                                            ? this.formRefDecisionForRejectedQuantities.current.getFieldValue("decisionForRejectedQuantities")
                                            : [];

                                        let calcRejectedQuantity = 0;
                                        decisionForRejectedQuantities.forEach(element => {
                                          if (Boolean(element.quantity)) {
                                            calcRejectedQuantity += element.quantity;
                                          }
                                        });
                                        this.setState({
                                          calcRejectedQuantity: calcRejectedQuantity
                                        });
                                      }}
                                    />
                                  </Form.Item>
                                </td>
                                <Form.Item hidden noStyle name={[field.name, "titleId"]}>
                                  <Input
                                    style={{
                                      width: "100%"
                                    }}
                                  />
                                </Form.Item>
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"InventoryLogReceive.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryLogReceive);
