import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleRequest } from '../../ApiConnector';
import CustomMaterialMenu from '../CustomMenu/CustomMaterialMenu';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from "react-intl";
import { Form, Table, Input, Select, Col, Row, Button as AntButton } from 'antd';
import 'antd/dist/antd.css';
import { Avatar } from 'antd';
import { Upload } from 'antd';
import { API_BASE_ROOT } from '../../ApiConnector';
import { PlusOutlined } from '@ant-design/icons';
import CreateIcon from '@material-ui/icons/AddCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { error, showError, showSuccess } from '../../MessageHelper';

const { Option } = Select;

export class SubCompany extends Component {

    constructor() {
        super();
        this.state = {

            modalDeleteShow: false,
            filePath: '',
            fileList: [],
            hiddenInputs: true,
            parentId: null,
            companyId: null,
            tableList: [],
            companyName: "",
            domain: "",
            companyShortName: null,
            companyLogoPath: null,
            address: null,
            countryId: null,
            phone: null,
            fax: null,
            email: null,
            countrySelectItems: [],
            selectedOptions: [],

            licenseStartDate: null,
            licenseEndDate: null,
            companyType: null,
            companySelectItems: [],
            companyLineAge: null,

            hideUpdate: true,
            hideSave: false,
            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['2', '10', '25', '50', '100', '1000', '10000', '100000', '1000000'],
            },
            loading: false,
        };



    }

    formRef = React.createRef();

    componentDidMount = async () => {

        const { pagination } = this.state;
        this.restartTable({ pagination });
        this.fillCountries();
        this.fillcompanies();

    };

    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters, });
    }

    // fillcompanies = async () => {

    //     let url = "/api/companies/authorized";

    //     const response = await handleRequest("GET", url);

    //     if (response.type === "ERROR") {
    //         error(response);
    //     }
    //     else
    //         this.setState({
    //             companySelectItems: Boolean(response.data) ? response.data : []
    //         });

    // }

    fillcompanies = async () => {

        let url = "/api/companies/tree";

        const response = await handleRequest("GET", url);

        if (response.type === "ERROR") {
            error(response);
        }
        else
            this.setState({
                companySelectItems: Boolean(response.data) ? response.data : []
            });

    }

    fillCountries = async () => {

        let url = "/api/countries/selectItems";
        var response = await handleRequest("GET", url);
        if (response.type === "ERROR") {
            error(response);
        }
        else
            this.setState({
                countrySelectItems: Boolean(response.data) ? response.data : []
            });

    }

    cancel = () => {
        this.setState({
            hiddenInputs: true,
            hideUpdate: true,
            hideSave: false
        });
        this.resetInputs();
    };

    resetInputs = () => {

        this.formRef.current.setFieldsValue({
            companyName: "",
            domain: "",

            companyShortName: null,
            companyLogoPath: null,
            address: null,
            countryId: null,
            phone: null,
            fax: null,
            email: null,
            companyLineAge: null,

        });

        this.setState({
            hideUpdate: true,
            hideSave: false,

            parentId: null,
            companyId: null,
            companyName: "",
            domain: "",
            companyShortName: null,
            companyLogoPath: null,
            companyLineAge: null,
            address: null,
            countryId: null,
            phone: null,
            fax: null,
            email: null,
            filePath: null,
            fileList: [],



        })
    }

    edit = (row) => {

        let fileList = [];
        let cla = Boolean(row.companyLineAge) ? row.companyLineAge.split(",") : null;
        let companyLineAge = [];
        if (Boolean(cla)) {
            cla.forEach(element => {
                companyLineAge.push(parseInt(element));
            });
        }

        if (Boolean(row.companyLogoPath) && row.companyLogoPath.length > 0)
            fileList = [{
                uid: 1,
                name: "",
                url: row.companyLogoPath,
                thumbUrl: row.companyLogoPath,
            }];

        this.formRef.current.setFieldsValue({
            companyName: row.companyName,
            domain: row.domain,
            companyShortName: row.companyShortName,
            companyLogoPath: row.companyLogoPath,
            address: row.address,
            countryId: row.countryId,
            phone: row.phone,
            fax: row.fax,
            email: row.email,
            companyLineAge: companyLineAge

        });

        this.setState({
            hideUpdate: false,
            hideSave: true,
            hiddenInputs: false,
            parentId: row.parentId,
            companyId: row.id,
            companyName: row.companyName,
            domain: row.domain,
            companyShortName: row.companyShortName,
            companyLogoPath: row.companyLogoPath,
            address: row.address,
            countryId: row.countryId,
            phone: row.phone,
            fax: row.fax,
            email: row.email,
            filePath: row.companyLogoPath,
            fileList: fileList,
            companyLineAge: companyLineAge

        });

    };




    actionTemplate(row) {

        return <React.Fragment>
            <CustomMaterialMenu row={row} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;

    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            companyId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    delete = async () => {

        const deletedItem = {
            id: this.state.companyId
        }

        var response = await handleRequest("DELETE", "/api/companies/" + deletedItem.id);


        if (response.type === "ERROR") {
            error(response);
        }
        else {
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.setState({
                companyId: null,
                modalDeleteShow: false
            });
        }

    }

    // restartTable = async () => {

    //     let url = "/api/companies/authorized";

    //     const response = await handleRequest("GET", url);

    //     if (response.type === "ERROR") {
    //         error(response);
    //     }
    //     else
    //         this.setState({
    //             tableList: Boolean(response.data) ? response.data : [] 
    //         });

    // }

    restartTable = async (params = {}) => {

        this.setState({ loading: true });
        const newObj = this.createWhere(params);

        let url = "/api/companies/paging";
        const response = await handleRequest("POST", url, newObj);
        if (response.type === "ERROR") {
            error(response);
            this.setState({
                loading: false,
                tableList: [],
                pagination: {
                    ...params.pagination,
                    total: 0,
                },
                sortField: "",
                sortOrder: "",
            });
        }
        else {
            if (response.data.length !== 0) {
                let list = response.data.data;
                list.forEach((element, index) => {
                    element.sn = index + 1;
                });
                this.setState({
                    loading: false,
                    tableList: list,
                    pagination: {
                        ...params.pagination,
                        total: response.data.totalRecords,
                    },
                    sortField: params.sortField,
                    sortOrder: params.sortOrder,
                });
            } else {
                this.setState({
                    loading: false,
                    tableList: [],
                    pagination: {
                        ...params.pagination,
                        total: 0,
                    },
                    sortField: "",
                    sortOrder: "",
                });
            }
        }
    };

    createWhere(params) {
        if (Boolean(params._pagination))
            params.pagination = params._pagination;
        var current = this.state.pagination.current;
        var pageSize = this.state.pagination.pageSize;
        var sortField = "", sortOrder = "";


        if (Boolean(params) && Boolean(params.pagination)) {
            current = params.pagination.current;
            pageSize = params.pagination.pageSize;


            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }

        }
        const newObj = {
            ...params.pagination,
            firstResult: current - 1, // (current - 1) * pageSize,
            maxResults: pageSize,
            sortField: sortField,
            sortOrder: sortOrder,
        };
        return newObj;
    }

    save = async () => {

        const newItem = {

            id: this.state.companyId,
            companyName: this.state.companyName,
            domain: this.state.domain,
            licenseStartDate: this.state.licenseStartDate,
            licenseEndDate: this.state.licenseEndDate,
            companyType: this.state.companyType,
            companyShortName: this.state.companyShortName,
            address: this.state.address,
            countryId: this.state.countryId,
            phone: this.state.phone,
            fax: this.state.fax,
            email: this.state.email,
            companyLogoPath: this.state.filePath

        }

        if (newItem.id === null) {
            var response = await handleRequest("POST", "/api/companies/subcompany", newItem);
        }
        else response = await handleRequest("PUT", "/api/companies/subcompany/" + newItem.id, newItem);


        if (response.type === "ERROR") {
            error(response);
        }
        else {

            const { pagination } = this.state;
            this.restartTable({ pagination });
            this.resetInputs();
            showSuccess();
        }

    };

    loadData = async (selectedOptions) => {

        if (Boolean(selectedOptions)) {

            const targetOption = selectedOptions[selectedOptions.length - 1];

            if (Boolean(targetOption)) {
                targetOption.loading = true;

                console.log(selectedOptions);
                console.log(targetOption);

                let url = "/api/companies/subcompanies/" + targetOption.value;

                const response = await handleRequest("GET", url);

                if (response.type === "ERROR") {
                    error(response);
                }
                else {
                    targetOption.loading = false;
                    targetOption.children = Boolean(response.data) ? response.data : [];
                    this.setState({
                        companySelectItems: [...this.state.companySelectItems]
                    });
                }
            }

        }


    };

    onClickCreateNew = async () => {
        this.resetInputs();
        if (this.state.hiddenInputs) {
            this.setState({
                hiddenInputs: false,
                hiddenSave: false,
                hiddenUpdate: true,
            });
        }
        else {
            this.setState({
                hiddenInputs: true,
                hiddenSave: true,
                hiddenUpdate: false,
            });
        }
    }


    render() {

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout2 = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
        };

        const columns = [

            {
                title: 'Actions', key: 'Actions', render: (record) => {
                    return this.actionTemplate(record);
                }
            },

            {
                title: 'S/N', key: 'index', render: (value, row, index) => {

                    return index + 1;

                }
            },

            {
                title: 'Logo', key: 'companyLogoPath', render: (value, row, index) => {

                    if (Boolean(row.companyLogoPath))
                        return <Avatar shape="square" size={64} src={row.companyLogoPath} />;
                    else return "";

                }
            },

            {
                title: 'Parent Company', key: 'parentCompany', render: (record) => {

                    return Boolean(record.parentCompany) ? record.parentCompany.companyName : "";
                }
            },

            {
                title: 'Company Short Name', key: 'companyShortName', render: (record) => {

                    return record.companyShortName;
                }
            },

            {
                title: 'Company Name', key: 'companyName', render: (record) => {

                    return record.companyName;
                }
            },
            {
                title: 'Domain', key: 'domain', render: (record) => {
                    return record.domain;
                }
            },
            {
                title: 'Address', key: 'address', render: (record) => {

                    return record.address;
                }
            },
            {
                title: 'Country', key: 'countryId', render: (record) => {


                    return record.countryName;
                }
            },
            {
                title: 'Phone', key: 'phone', render: (record) => {

                    return record.phone;
                }
            },
            {
                title: 'Fax', key: 'fax', render: (record) => {

                    return record.fax;
                }
            },
            {
                title: 'Email', key: 'email', render: (record) => {

                    return record.email;
                }
            }
        ];


        const uploadButton = (
            <div>
                <PlusOutlined />
                <div className="ant-upload-text">Upload</div>
            </div>
        );



        const props = {
            action: `${API_BASE_ROOT}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        return (

            <div hidden={this.state.hideInputs} className="card card-custom" style={{ padding: '2rem' }}>

                <Form
                    initialValues={{ remember: false }}
                    onFinish={this.save}
                    onFinishFailed={onFinishFailed}
                    ref={this.formRef}
                >
                    <Row>
                        <Col xs={20}></Col>
                        <Col md={1}>
                            {/* {excelExport} */}
                        </Col>
                        <Col md={1}>
                            {/* <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader> */}
                        </Col>
                        <Col xs={2}>
                            <AntButton hidden={!this.state.hiddenInputs} style={{ border: "0px" }} onClick={() => this.onClickCreateNew()} icon={<CreateIcon color="secondary" fontSize="large" />}></AntButton>
                            <AntButton hidden={this.state.hiddenInputs} style={{ border: "0px" }} onClick={() => this.onClickCreateNew()} icon={<RemoveCircle color="error" fontSize="large" />}></AntButton>
                        </Col>
                    </Row>
                    <div hidden={this.state.hiddenInputs}>
                        {/* {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Parent Company" />}
                                name="companyLineAge"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please select company" /> }]} >

                                <Cascader options={this.state.companySelectItems} value={this.state.companyLineAge} onChange={(value, selectedOptions) => {
                                    this.setState({
                                        parentId: Boolean(value) && value.length > 0 ? value[value.length - 1] : null,
                                        companyLineAge: value,
                                        selectedOptions: selectedOptions

                                    });

                                    // this.loadData(selectedOptions);

                                }} changeOnSelect />

                             
                            </Form.Item>
                        } */}

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Company Name" />}
                                name="companyName"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type company name" /> }]}
                            >

                                <Input placeholder="Company Name" style={{ width: '100%' }} id="todo" value={this.state.companyName} onChange={(e) => {

                                    this.setState({ companyName: e.target.value })
                                }} />


                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Company Short Name" />}
                                name="companyShortName"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type company short name" /> }]}
                            >

                                <Input placeholder="Company Short Name" style={{ width: '100%' }} id="todo" value={this.state.companyShortName} onChange={(e) => {

                                    this.setState({ companyShortName: e.target.value })
                                }} />


                            </Form.Item>
                        }


                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Company Logo" />}
                                name="path"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please upload file" /> }]}
                            >
                                <Upload {...props} id="path"
                                    showUploadList={true}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={this.state.fileList}
                                    onChange={({ fileList }) => {
                                        if (fileList.length === 1) {
                                            var filePath = "";
                                            if (Boolean(fileList[0].response)) {
                                                filePath = fileList[0].response.url;
                                                showSuccess("file uploaded successfully");
                                                // fileName: info.file.name, 
                                            }
                                            this.setState({
                                                fileList: fileList,
                                                filePath: filePath,
                                            });
                                        }
                                    }}
                                    beforeUpload={(fileList) => {
                                        if (this.state.fileList.length > 0) {
                                            showError("You have been already choose a file! For change firstly remove the uploaded file.");
                                            return false;
                                        }
                                        return true;
                                    }}
                                    onRemove={({ fileList }) => {
                                        this.setState({
                                            fileList: [],
                                            filePath: "",
                                        })
                                    }
                                    }>
                                    {uploadButton}
                                </Upload>
                            </Form.Item>
                        }




                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Domain" />}
                                name="domain"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type domain" /> }]} >
                                <Input placeholder="Domain" id="domain" value={this.state.domain}
                                    onChange={(e) => this.setState({ domain: e.target.value })} />


                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Country" />}
                                name="countryId"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please select country" /> }]} >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                        this.setState({
                                            countryId: value,

                                        });

                                    }}>
                                    <Option key={null} value={null}>Select</Option>
                                    {
                                        this.state.countrySelectItems.map(i => <Option value={i.key}>{i.value}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Address" />}
                                name="address"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type address" /> }]} >
                                <Input placeholder="Address" id="domain" value={this.state.address}
                                    onChange={(e) => this.setState({ address: e.target.value })} />


                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Phone" />}
                                name="phone"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type phone" /> }]} >
                                <Input placeholder="Phone" id="domain" value={this.state.phone}
                                    onChange={(e) => this.setState({ phone: e.target.value })} />


                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Fax" />}
                                name="fax"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type fax" /> }]} >
                                <Input placeholder="Fax" id="domain" value={this.state.fax}
                                    onChange={(e) => this.setState({ fax: e.target.value })} />


                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Email" />}
                                name="email"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type email" /> }]} >
                                <Input placeholder="Email" id="domain" value={this.state.email}
                                    onChange={(e) => this.setState({ email: e.target.value })} />


                            </Form.Item>
                        }




                        <div hidden={this.state.hideSave}>
                            {
                                <Row gutter={8}>
                                    <Col span={8}></Col>

                                    <Col span={8}>
                                        {
                                            <Button id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                        }
                                    </Col>
                                </Row>

                            }
                        </div>



                        <div hidden={this.state.hideUpdate}>
                            {
                                <Row gutter={8}>
                                    <Col span={8}></Col>

                                    <Col span={4}>
                                        <Button id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                                    </Col>
                                    <Col span={4}>
                                        <Button id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                                    </Col>

                                </Row>
                            }
                        </div>
                    </div>
                </Form>



                <div style={{ marginTop: '2rem', marginBottom: '1rem' }} className="content-section implementation">
                    <Table
                        size="small"
                        bordered
                        scroll={{ y: 500, x: 900 }}
                        columns={columns}
                        dataSource={this.state.tableList}
                        loading={this.state.loading} onChange={this.handleTableChange}
                        pagination={this.state.pagination}
                    />

                </div>
                <Modal
                    show={this.state.modalDeleteShow}
                    onHide={this.showOrHideDeleteModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</Button>
                        <Button variant="danger" onClick={this.delete} >Delete</Button>
                    </Modal.Footer>
                </Modal>
            </div>


        );


    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(SubCompany)