import SaveIcon from "@material-ui/icons/Save";
import { Button as AntButton, InputNumber } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { connect } from "react-redux";
export class EmployeeRequestScoring extends Component {
  constructor({ employeeRequestData, save }) {
    super();
    this.state = {
      employeeRequestData: employeeRequestData
    };
  }

  componentDidMount = async () => {
    this.calculateWeight();
  };
  componentDidUpdate = async prevProvs => {
    if (this.props.employeeRequestData.id !== prevProvs.employeeRequestData.id) {
      this.setState({ employeeRequestData: this.props.employeeRequestData });
    }
  };
  calculateWeight = async () => {
    if (this.state.employeeRequestData && this.state.employeeRequestData.employeeRequestCriteriaList) {
      let sum = this.state.employeeRequestData.employeeRequestCriteriaList.reduce((sum, item) => {
        return sum + (Boolean(item.score) ? item.score : 0);
      }, 0);
      if (sum > 0) {
        this.state.employeeRequestData.employeeRequestCriteriaList.forEach(item => {
          item.score = Boolean(item.score) ? item.score : 0;
          item.weight = ((100 * item.score) / sum).toFixed(2);
        });
      }
      this.state.employeeRequestData.employeeRequestCriteriaList.forEach(item => {
        if (item.employeeRequestCriteriaContentList) {
          let sum2 = item.employeeRequestCriteriaContentList.reduce((sum, item2) => {
            return sum + (Boolean(item2.score) ? item2.score : 0);
          }, 0);
          if (sum2 > 0) {
            item.employeeRequestCriteriaContentList.forEach(item2 => {
              item2.score = Boolean(item2.score) ? item2.score : 0;
              item2.weight = ((100 * item2.score) / sum2).toFixed(2);
            });
          }
        }
      });
      this.setState({ employeeRequestData: this.state.employeeRequestData });
    }
  };

  save = async () => {
    this.props.save(this.state.employeeRequestData);
  };

  render() {
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <div className="d-flex"></div>
        <div className="content-section implementation">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Item</th>
                <th>Score</th>
                <th>Weight</th>
              </tr>
            </thead>
            <tbody>
              {this.state.employeeRequestData.employeeRequestCriteriaList
                .filter(x => x.selected === true)
                .map((item, index) => {
                  let r = [
                    <tr key={index}>
                      <td>
                        <strong>{item.name}</strong>
                      </td>
                      <td>
                        <InputNumber
                          value={item.score}
                          onChange={value => {
                            item.score = value;
                            this.setState({ employeeRequestData: this.state.employeeRequestData }, () => {
                              this.calculateWeight();
                            });
                          }}
                        />
                      </td>
                      <td>
                        <InputNumber value={item.weight} disabled={true} />
                      </td>
                    </tr>
                  ];
                  item.employeeRequestCriteriaContentList
                    .filter(x => x.selected === true)
                    .forEach((c, i) => {
                      r.push(
                        <tr key={index + "" + i}>
                          <td>{c.name}</td>
                          <td>
                            <InputNumber
                              value={c.score}
                              onChange={value => {
                                c.score = value;
                                //calculate total weight
                                this.setState({ employeeRequestData: this.state.employeeRequestData }, () => {
                                  this.calculateWeight();
                                });
                              }}
                            />
                          </td>
                          <td>
                            <InputNumber value={c.weight} disabled={true} />
                          </td>
                        </tr>
                      );
                    });
                  return r;
                })}
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-row-reverse">
          <span>
            <AntButton
              type="primary"
              onClick={() => {
                this.save();
              }}
              icon={<SaveIcon />}
            >
              Save
            </AntButton>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeRequestScoring);
