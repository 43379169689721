import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { EditOutlined, TextFields } from "@material-ui/icons";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Form, Table, Input, DatePicker, Badge, Button as AntButton, Col, Row, Upload, InputNumber, Drawer, Card, Divider } from "antd";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import CreateIcon from "@material-ui/icons/AddCircle";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Select } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import EldenEquipmentTypeSelectable from "../elden/equipment/EldenEquipmentTypeSelectable";
import EldenEquipmentGroupSelectable from "../elden/equipment/EldenEquipmentGroupSelectable";
import DraggableModal from "../../Components/DraggableModal";
import { getColumnFilter, fillFilterOperations } from "../../Components/DataTableFilterComponent";
import { EldenEquipmentLogIdentificationDrawer } from "../elden/equipment/EldenEquipmentLogIdentificationDrawer";
import { EquipmentLogAssign } from "./EquipmentLogAssign";
import { PrepareFileUploadEdit, PrepareFileUploadSave } from "../../Components/ElementUtils";
import { excelExport } from "../../ExcelHelper";
import ExcelReader from "../excel/ExcelReader";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class EquipmentInsurance extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "equipmentInsurance",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      policyPath: "",
      nodes: [],
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.fillCode("EquipmentInsurance.InsuranceCompanyId");
    fillFilterOperations();
    var responseVehicleId = await handleRequest("GET", "/api/" + this.state.controllerName + "/vehicleId");
    if (responseVehicleId.type === "ERROR") {
      error(responseVehicleId);
    }
    var responseCurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responseCurrency.type === "ERROR") {
      error(responseCurrency);
    }
    this.setState({
      vehicleIdSelectItems: Boolean(responseVehicleId.data) ? responseVehicleId.data : [],
      currencySelectItems: Boolean(responseCurrency.data) ? responseCurrency.data : [],
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  getVendorId = () => {
    return this.state.id;
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      projectId: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      policyPath: "",
    });
  };

  setSelectedFiles = field => {
    let fileList = [];
    if (Boolean(field) && field.length > 0) {
      let files = field.split(",");
      for (let i = 0; i < files.length; i++) {
        fileList.push({
          uid: i,
          name: files[i],
          url: files[i],
          thumbUrl: files[i]
        });
      }
      return fileList;
    }
    return [];
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    let fileList = PrepareFileUploadEdit(row.policyPath);

    this.formRef.current.setFieldsValue({
      ...row,
      issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null,
      policyPath: fileList,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };
    newItem.policyPath = PrepareFileUploadSave(newItem.policyPath);

    if (Boolean(this.props.vehicleId) && this.props.vehicleId > 0) {
      newItem.vehicleId = this.props.vehicleId;
    }

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      vehicleId: this.props.vehicleId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          if (Array.isArray(element))
            element.forEach(element2 => {
              let obj = { columns: element2 };
              list.push(obj);
            });
          else {
            let obj = { columns: element };
            list.push(obj);
          }
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/importDynamicaly", list);
          if (response.type === "ERROR") {
            error(response);
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          error("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  showReport = async rowData => {
    try {
      if (rowData.policyPath !== null && rowData.policyPath !== undefined && rowData.policyPath !== "") {
        const fileRealUrl = rowData.policyPath;
        window.location.href = fileRealUrl;
      } else {
        this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
      }
    } catch (error) {
      this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 12 }
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EquipmentLog.Vehicle" defaultMessage="Vehicle" />,
        key: "vehicleId",
        render: record => {
          return record.vehicleName;
        },
        ...getColumnFilter({
          inputName: "vehicleId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["vehicleIdSelectItems"]) ? this.state["vehicleIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EquipmentLog.PlateNo" defaultMessage="Plate No" />,
        key: "id",
        render: record => {
          return record.plateNo;
        },
      },
      {
        title: <FormattedMessage id="EquipmentLog.Owner" defaultMessage="Owner" />,
        key: "id",
        render: record => {
          return record.owner;
        },
      },
      {
        title: <FormattedMessage id="EquipmentLog.Insureance" defaultMessage="Insureance" />,
        key: "id",
        render: record => {
          return record.insureance;
        },
      },
      {
        title: <FormattedMessage id="EquipmentLog.IssueDate" defaultMessage="Issue Date" />,
        key: "issueDate",
        render: record => {
          return Boolean(record.issueDate) ? moment(record.issueDate).format("DD-MM-YYYY") : "";
        },
        sorter: true
      },
      {
        title: <FormattedMessage id="EquipmentLog.PolicyNo" defaultMessage="Policy No" />,
        key: "policyNo",
        render: record => {
          return record.policyNo;
        },
        ...getColumnFilter({
          inputName: "policyNo",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EquipmentLog.CoveragePeriod" defaultMessage="Coverage Period" />,
        key: "coveragePeriod",
        render: record => {
          return "";
        },
      },
      {
        title: <FormattedMessage id="EquipmentLog.EmergencyContact" defaultMessage="Emergency Contact" />,
        key: "emergencyContact",
        render: record => {
          return "";
        },
      },
      {
        title: <FormattedMessage id="EquipmentLog.PaidAmount" defaultMessage="Paid Amount" />,
        key: "id",
        render: record => {
          return record.paidAmount + " " + record.currency;
        },
      },
      {
        title: <FormattedMessage id="EquipmentLog.RemainingDates" defaultMessage="Remaining Dates" />,
        key: "remainingDate",
        ellipsis: true,
        render: record => {
          return "";
        },
      }
    ];

    const columnsExcel = [
      { header: "S/N", key: "index" },
    ];

    const propsUpload = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    return (
      <Badge.Ribbon text="Insurance" placement="start" color="purple" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <br />
            <Row gutter={[16, 16]}>
              <Col md={21}></Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => { excelExport(columnsExcel, this.state.tableList) }}
                  icon={<ExportIcon color="action" fontSize="large" />}
                />
              </Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>
            <br />
            <div hidden={this.state.hideInputs}>
              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <br></br>
                <br></br>
                <br></br>
                {/* Sol Card */}
                <Col span={1}> </Col>
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {
                      !(Boolean(this.props.vehicleId) && this.props.vehicleId > 0) &&
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.Vehicle" defaultMessage="Vehicle" />}
                        name="vehicleId"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                          {Boolean(this.state["vehicleIdSelectItems"]) &&
                            this.state["vehicleIdSelectItems"].length > 0 &&
                            this.state["vehicleIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.IssueDate" defaultMessage="Issue Date" />}
                        name="issueDate"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.InsuranceCompany" defaultMessage="Insurance Company" />}
                        name="insuranceCompanyId"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          dropdownRender={menu => (
                            <div>
                              {menu}
                              <Divider style={{ margin: "4px 0" }} />
                              <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                <Input
                                  style={{ flex: "auto" }}
                                  value={this.state.codeName}
                                  onChange={event => {
                                    this.setState({
                                      codeName: event.target.value
                                    });
                                  }}
                                />
                                <a
                                  style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                  onClick={() => this.addCode("EquipmentInsurance.InsuranceCompanyId")}
                                >
                                  <PlusOutlined />  <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                                </a>
                              </div>
                            </div>
                          )}
                        >
                          {Boolean(this.state["EquipmentInsurance.InsuranceCompanyIdSelectItems"]) &&
                            this.state["EquipmentInsurance.InsuranceCompanyIdSelectItems"].map(i => (
                              <Option key={i.id} value={i.id}>
                                {i.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.Agency" defaultMessage="Agency" />}
                        name="agencyId"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                          {Boolean(this.state["agencyIdSelectItems"]) &&
                            this.state["agencyIdSelectItems"].length > 0 &&
                            this.state["agencyIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.PolicyNo" defaultMessage="Policy No" />}
                        name="policyNo"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.InsurancePeriod" defaultMessage="Insurance Period" />}
                        name="period"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber min={0} style={{ width: "100%" }} />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.Policy" defaultMessage={"Policy"} />}
                        name="policyPath"
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...propsUpload} showUploadList={true} maxCount={1}>
                          <AntButton icon={<UploadOutlined />} style={{ width: "100%", marginTop: "0.2rem" }}>
                            <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                          </AntButton>
                        </Upload>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.Coverage" defaultMessage={"Coverage"} />}
                        name="coverage"
                      >
                        <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                          <FormattedMessage id="EquipmentLog.Coverage" defaultMessage="Coverage" />
                        </AntButton>
                      </Form.Item>
                    }

                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EquipmentLog.PaidAmount" defaultMessage="Paid Amount" />}
                      style={{ marginBottom: "5px" }}
                    >
                      <Row gutter={8}>
                        <Col span={10}>
                          <Form.Item name="paidAmount">
                            <InputNumber
                              style={{
                                width: "100%"
                              }}
                              min={0}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                        </Col>
                        <Col span={10}>
                          <Form.Item name="currency">
                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                              {Boolean(this.state["currencySelectItems"]) &&
                                this.state["currencySelectItems"].length > 0 &&
                                this.state["currencySelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.EmergencyContact" defaultMessage={"Emergency Contact"} />}
                        name="emergencyContact"
                      >
                        <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                          <FormattedMessage id="EquipmentLog.EmergencyContact" defaultMessage="Emergency Contact" />
                        </AntButton>
                      </Form.Item>
                    }

                  </Card>
                </Col>
                <br></br>
                <br></br>
                {/* Sol Card */}
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.ChasisNo" defaultMessage="Chasis No" />}
                        name="chasisNo"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input readOnly style={{ width: "100%" }} />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.EngineNo" defaultMessage="Engine No" />}
                        name="engineNo"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input readOnly style={{ width: "100%" }} />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="EquipmentLog.RegistationDate" defaultMessage="Registation Date" />}
                        name="registationDate"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input readOnly style={{ width: "100%" }} />
                      </Form.Item>
                    }
                  </Card>
                </Col>
                <Col span={1}> </Col>
              </Row>





              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              rowKey="id"
              size="small"
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div >
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentInsurance);