import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { showError, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Button as AntButton, Col, Row, Select, Table, InputNumber, Divider, Tooltip, Breadcrumb, Space } from "antd";
import { Input } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import { MinusCircleOutlined, PlusOutlined, CloseCircleOutlined, FilterOutlined, ZoomInOutlined } from "@ant-design/icons";
import DraggableModal from "../../../Components/DraggableModal";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const { Option } = Select;

export class ProjectsTreeCompanyDivision extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ProjectsTreeCompanyDivision",
      controllerName: "apartment",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      houseRoomList: [],
      loadingButtonSubmit: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      currentRow: 0
    };
  }

  formRefHouseRooms = React.createRef();
  formRefHouseTypes = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    fillFilterOperations();
    this.getHomeHouseType();
  };

  getHomeHouseType = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getHouseType");
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        tableVisibleModal: true,
        tableList: Boolean(response.data) ? response.data : []
      });
    }
  };

  fillComboboxes = async () => {
    this.fillApartmentTypes("Apartment.ApartmentType");
    this.fillTypeOfHouses();
    this.fillCode("Apartment.ApartmentRoomType");
  };

  fillTypeOfHouses = async () => {
    var responseHouseType = await handleRequest("GET", "/api/" + this.state.controllerName + "/getHouseType");
    if (responseHouseType.type === "ERROR") {
      error(responseHouseType);
    } else {
      this.setState({
        houseTypeSelectItems: Boolean(responseHouseType.data) ? responseHouseType.data : []
      });
    }
  };

  fillHouseRoomsNotNull = async houseTypeId => {
    if (Boolean(houseTypeId) && houseTypeId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/houseRoomsNotNull/" + houseTypeId);
      if (response.type === "ERROR") {
        error(response);
      }

      this.setState({
        houseRoomsNotNull: Boolean(response.data) ? response.data : []
      });
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        this.getHomeHouseType();
      }
    );
  };

  fillTypeOfHousesModal() {
    this.setState(
      {
        typeOfHousesModal: false,
        typeHouseRoomsModal: true
      },
      async () => {
        const { currentRow } = this.state;
        let houseTypes = this.state.houseTypeSelectItems;

        if (Boolean(houseTypes) && houseTypes.length > 0 && houseTypes.length > currentRow) {
          let row = houseTypes[currentRow];
          this.setState(
            {
              houseTypeName: row.houseTypeName,
              houseTypeId: row.houseTypeId
            },
            async () => {
              if (Boolean(this.formRefHouseRooms) && Boolean(this.formRefHouseRooms.current)) {
                await this.fillHouseRoomsNotNull(this.state.houseTypeId);

                const { houseRoomsNotNull } = this.state;
                let houseRooms = [];
                console.log("data", houseRoomsNotNull);
                if (Boolean(houseRoomsNotNull) && houseRoomsNotNull.length > 0) {
                  houseRoomsNotNull.forEach(element => {
                    houseRooms.push({ roomTypeId: element.roomTypeId, numberOfRoom: element.numberOfRoom });
                  });
                }
                this.formRefHouseRooms.current.setFieldsValue({
                  houseRooms: houseRooms
                });
              }
            }
          );
        } else {
          this.setState({
            typeHouseRoomsModal: false,
          });
        }
        this.setState({
          loadingButtonSubmit: false
        });
      }
    );
  }

  saveHouseRooms = async values => {
    const { houseTypeId } = this.state;
    if (Boolean(houseTypeId) && houseTypeId > 0) {
      if (Boolean(values.houseRooms) && values.houseRooms.length > 0) {
        values.houseRooms.forEach(element => {
          element.houseTypeId = houseTypeId;
        });
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveHouseRooms", values.houseRooms);
        if (response.type === "ERROR") {
          error(response);
        } else {
          let { currentRow } = this.state;
          this.setState(
            {
              currentRow: currentRow + 1,
              typeHouseRoomsModal: false
            },
            () => {
              this.fillTypeOfHousesModal();
            }
          );
        }
        this.setState({
          loadingButtonSubmit: false
        });
      } else {
        showWarning("House room not found.");
        this.setState({
          loadingButtonSubmit: false
        });
      }
    } else {
      showWarning("House type not found.");
      this.setState({
        loadingButtonSubmit: false
      });
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillApartmentTypes = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data,
        currentApartmentTypeSelectItems: response.data
      });
    }
  };

  houseRoomModal = async row => {
    if (Boolean(row.houseTypeId) && row.houseTypeId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getRoomByHouseType/" + row.houseTypeId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          modalHouseRoomShow: true,
          typeHouseRoomsModal: false,
          houseRoomList: Boolean(response.data) ? response.data : [],
          houseTypeName: row.houseTypeName,
          houseTypeId: row.houseTypeId
        });
      }
    } else {
      showWarning("House type not found.");
    }
  };

  render() {
    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyDivision.Section" defaultMessage="Section" />,
        key: "houseTypeId",
        render: record => {
          return record.houseTypeName;
        }
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyDivision.Divisions" defaultMessage="Divisions" />,
        key: "houseTypeId",
        render: record => {
          return record.houseTypeId && record.houseTypeId > 0 ? (
            <AntButton
              onClick={() => this.houseRoomModal(record)}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ZoomInOutlined fontSize="large" />}
            ></AntButton>
          ) : (
            ""
          );
        }
      }
    ];

    const columnsHouseRoomList = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyDivision.RoomType" defaultMessage="Room Type" />,
        key: "id",
        render: record => {
          return record.roomTypeName;
        }
      },
      {
        title: <FormattedMessage id="ProjectsTreeCompanyDivision.Quantity" defaultMessage="Quantity" />,
        key: "id",
        render: record => {
          return record.numberOfRoom;
        }
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <div className="card card-custom card-stretch">
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">

              <Breadcrumb separator=">">
                <Breadcrumb.Item className={this.state.tableVisibleModal ? "customBreadcrumbItem" : ""}>
                  <a
                    href="#"
                    onClick={() => {
                      this.setState(
                        {
                          tableVisibleModal: true,

                          typeHouseRoomsModal: false,
                          modalHouseRoomShow: false
                        },
                        () => {
                          this.getHomeHouseType();
                        }
                      );
                    }}
                  >
                    <Space>
                      <SVG style={{ width: '20px', height: '20px' }} src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>
                      <FormattedMessage id="ProjectsTreeCompanyApartment.Floors" defaultMessage="Floors" />
                    </Space>
                  </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a
                    href="#"
                    className={this.state.typeHouseRoomsModal ? "customBreadcrumbItem" : ""}
                    onClick={() => {
                      this.setState(
                        {
                          typeHouseRoomsModal: true,

                          tableVisibleModal: false,
                          modalHouseRoomShow: false,
                          currentRow: 0
                        },
                        () => {
                          this.fillTypeOfHousesModal();
                        }
                      );
                    }}
                  >
                    <FormattedMessage id="ProjectsTreeCompanyApartment.Divisions" defaultMessage="Divisions" />
                  </a>

                  {Boolean(this.state.houseTypeSelectItems) && this.state.houseTypeSelectItems.length > 0 && (
                    <Breadcrumb separator=">">
                      {this.state.houseTypeSelectItems.map((val, i) => {
                        return (
                          <Breadcrumb.Item className={val.houseTypeName === this.state.houseTypeName ? "customBreadcrumbItem" : ""}>
                            <a
                              href="#"
                              onClick={() => {
                                this.setState(
                                  {
                                    currentRow: i
                                  },
                                  () => {
                                    this.fillTypeOfHousesModal();
                                  }
                                );
                              }}
                            >
                              {val.houseTypeName}
                            </a>
                          </Breadcrumb.Item>
                        );
                      })}
                    </Breadcrumb>
                  )}
                </Breadcrumb.Item>
              </Breadcrumb>

            </div>
            <div className="card-toolbar"></div>
          </div>

          <br />
          <h3 className="card-label font-weight-bolder text-dark">
            <FormattedMessage id="ProjectsTreeCompanyDivision.Divisions" defaultMessage="Divisions" />
          </h3>
        </div>

        <>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                {" "}
                <FilterOutlined />{" "}
              </div>{" "}
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span>
                      <span className="mr-2">{val.showValue}</span>
                      <span>
                        {" "}
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table bordered size="small" columns={columns} dataSource={this.state.tableList} loading={this.state.loading} pagination={false} />
          </div>

          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={19} lg={19} xl={19} xxl={19}></Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Button
                onClick={() => {
                  this.props.nextTab();
                }}
                style={{ width: "100%" }}
                variant="primary"
              >
                <FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />
              </Button>
            </Col>
          </Row>
        </>
        <br />

        <DraggableModal
          title={<FormattedMessage id="ProjectsTreeCompanyDivision.Divisions" defaultMessage="Divisions" />}
          centered
          width={1000}
          open={this.state.typeHouseRoomsModal}
          onCancel={() => this.setState({ typeHouseRoomsModal: false })}
          onOk={() => {
            this.formRefHouseRooms.current.submit();
          }}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          cancelButtonProps={{ hidden: true }}
          content={
            <>
              <h3 className="card-label font-weight-bolder text-dark">{this.state.houseTypeName}</h3>
              <Form initialValues={{ remember: false }} onFinish={this.saveHouseRooms} ref={this.formRefHouseRooms}>
                <Form.List name="houseRooms">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ProjectsTreeCompanyDivision.Room"} defaultMessage={"Room"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ProjectsTreeCompanyDivision.NumberOfRoom"} defaultMessage={"Number Of Room"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "roomTypeId"]}>
                                      <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                        dropdownRender={menu => (
                                          <div>
                                            {menu}
                                            <Divider style={{ margin: "4px 0" }} />
                                            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                              <Input
                                                style={{ flex: "auto" }}
                                                value={this.state.codeName}
                                                onChange={event => {
                                                  this.setState({
                                                    codeName: event.target.value
                                                  });
                                                }}
                                              />
                                              <a
                                                style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                                onClick={() => this.addCode("Apartment.ApartmentRoomType")}
                                              >
                                                <PlusOutlined /> Add item
                                              </a>
                                            </div>
                                          </div>
                                        )}
                                      >
                                        {Boolean(this.state["Apartment.ApartmentRoomTypeSelectItems"]) &&
                                          this.state["Apartment.ApartmentRoomTypeSelectItems"].length > 0 &&
                                          this.state["Apartment.ApartmentRoomTypeSelectItems"].map(i => (
                                            <Option key={i.id} value={i.id}>
                                              {i.name}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "numberOfRoom"]}>
                                      <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"ProjectsTreeCompanyDivision.AddNew"} defaultMessage={"Add New"} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              </Form>
            </>
          }
        />

        <DraggableModal
          centered
          width={600}
          title={this.state.houseTypeName}
          closable={false}
          open={this.state.modalHouseRoomShow}
          onCancel={() => {
            this.setState({ modalHouseRoomShow: false });
          }}
          okButtonProps={{ hidden: true }}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Table
                bordered
                size="small"
                columns={columnsHouseRoomList}
                dataSource={this.state.houseRoomList}
                loading={this.state.loading}
                pagination={false}
              />
            </div>
          }
        />
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTreeCompanyDivision);