/* eslint-disable */

import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import LanguageIcon from "@material-ui/icons/Language";
import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import {
  Form,
  Table,
  Input,
  Modal,
  Button,
  Col,
  Row,
  PageHeader,
  Typography,
  Image,
  Space,
  Switch,
  Tooltip,
  Spin,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { error, showError, showSuccess } from "../../MessageHelper";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import Texty from "rc-texty";
import "rc-texty/assets/index.css";
import ExcelReader from "../excel/ExcelReader";

const { Title } = Typography;

const dataList = [];
const generateList = (data) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({ key: node.data.id, lineage: node.data.lineage });
    if (node.children) {
      generateList(node.children);
    }
  }
};

function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex].value : "",
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record }, { ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
      error("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion
export class LanguageSettings extends Component {
  constructor() {
    super();
    this.state = {
      isLanguagesModalVisible: false,
      languageTableColumns: [],
      languageTypeList: [],
      excelList: [],
      spinLoading: false,
    };
  }

  componentDidMount = async () => {
    this.getDynamicMenu();
    this.getLanguageTypes();
    this.restartTable(null);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          allowClear
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
  });
  columns = [
    {
      title: "Page",
      dataIndex: "pageJsFile",
      key: "pageJsFile",
      fixed: "left",
      width: 200,
      render: (value, row, index) => {
        return value;
      },
      ...this.getColumnSearchProps("pageJsFile"),
    },
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      fixed: "left",
      width: 250,
      render: (value, row, index) => {
        return value;
      },
      ...this.getColumnSearchProps("key"),
    },
  ];

  getLanguageTypes = async () => {
    var response = await handleRequest("GET", "/api/languageTypes");
    const langTypes = response.data;
    let c = [...this.columns];
    if (!!langTypes) {
      langTypes.forEach((element) => {
        c.push({
          title: (
            <>
              <span className="mr-5">{element.name}</span>
              <span className="symbol symbol-20">
                <img src={toAbsoluteUrl(element.iconPath)} alt={element.name} />
              </span>
            </>
          ),
          dataIndex: element.id,
          key: element.id,
          editable: true,
          render: (value, row, index) => (
            <span>
              {Boolean(row[element.id]) ? row[element.id].value : null}
            </span>
          ),
          onCell: (record) => ({
            record,
            editable: true,
            dataIndex: element.id,
            title: element.name,
            handleSave: this.handleSave,
          }),
        });
      });
    }
    console.log("langTypes", langTypes);
    this.setState({
      languageTableColumns: c,
      languageTypeList: langTypes,
    });
  };
  getDynamicMenu = async () => {
    const responseTree = await handleRequest("GET", "/api/menus/tree");

    if (responseTree.data.length !== 0) {
      generateList(responseTree.data.treeTable);
      this.setState({
        dynamicMenuCascader: responseTree.data.cascader,
      });
    } else {
      this.setState({
        dynamicMenuCascader: [],
      });
    }
  };
  restartTable = async () => {
    var response100 = [];

    response100 = await handleRequest("GET", "/api/languages");

    const list = response100.data;
    this.setState({
      dataLanguages: this.combineLanguageArray(list),
    });
  };

  combineLanguageArray = (array) => {
    var output = [];

    array.forEach(function (item) {
      var existing = output.filter(function (v, i) {
        return v.key == item.key;
      });
      if (existing.length) {
        var existingIndex = output.indexOf(existing[0]);
        output[existingIndex][item.languageTypeId] = item;
      } else {
        let obj = {
          key: item.key,
          pageJsFile: item.pageJsFile,
          menuId: item.menuId,
          [item.languageTypeId]: item,
        };
        output.push(obj);
      }
    });
    return output;
  };
  handleSave = async (row, value) => {
    const newData = [...this.state.dataLanguages];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    let obj = {
      key: item.key,
      value: value[Object.keys(value)[0]],
      languageTypeId: Object.keys(value)[0],
      pageJsFile: item.pageJsFile,
    };
    if (Boolean(item[Object.keys(value)[0]])) {
      obj.id = item[Object.keys(value)[0]].id;
      const response = await handleRequest(
        "PUT",
        "/api/languages/" + obj.id,
        obj
      );
      if (response.type === "ERROR") {
        return;
      }
      item[Object.keys(value)[0]].value = value[Object.keys(value)[0]];
      showSuccess("Successfully updated");
    } else if (!!value[Object.keys(value)[0]]) {
      const response = await handleRequest("POST", "/api/languages", obj);
      if (response.type === "ERROR") {
        return;
      }
      item[Object.keys(value)[0]] = {
        id: response.data,
        ...obj,
      };
      showSuccess("Successfully added");
    } else return;
    newData.splice(index, 1, { ...item, ...row });

    this.setState({
      dataSource: newData,
    });
  };

  handleLanguageTypeChange = async (value, field) => {
    let { languageTypeList } = this.state;
    let obj = {
      id: field.id,
      lang: field.lang,
      name: field.name,
      showOnLayout: value,
    };
    let item = languageTypeList.filter((item) => item.id == obj.id);
    item[0].showOnLayout = value;

    let response = await handleRequest(
      "PUT",
      "/api/languageTypes/toggle/" + obj.id,
      obj
    );
    if (response.type === "ERROR") {
      error(response.message);
    } else {
      showSuccess("Successfully updated");
      this.setState({
        languageTypeList: [...languageTypeList],
      });
    }
  };
  closeLanguageTypesModal = () => {
    // this.restartTable();
    this.setState({
      isLanguagesModalVisible: false,
    });
  };
  handleUploadExcel = async (excelJson) => {
    const { languageTypeList } = this.state;
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach((element) => {
          languageTypeList.forEach((item) => {
            if (!!element[item.id + "," + item.name]) {
              let obj = {
                languageTypeId: item.id,
                value: element[item.id + "," + item.name],
                pageJsFile: element.Page,
                key: element.Key,
              };
              list.push(obj);
            }
          });
        });
        if (Boolean(list) && list.length > 0) {
          let response = await handleRequest(
            "POST",
            "/api/languages/sync",
            list
          );
          if (response.type === "ERROR") {
            error(response);
          } else {
            showSuccess("Sync Success");
            this.restartTable();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  handleExportExcel = async () => {
    const { dataLanguages, languageTypeList } = this.state;
    let excelList = dataLanguages.map((element, index) => {
      languageTypeList.forEach((item) => {
        if (element[item.id] !== undefined) {
          element[item.lang] = element[item.id].value;
        }
      });
      return element;
    });
    this.setState({
      excelList: excelList,
      exportClick: true,
    });
  };
  render() {
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
    };
    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false,
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Languages" hideElement={true}>
            <ExcelSheet name="Languages" data={this.state.excelList}>
              <ExcelColumn label="Page" value="pageJsFile" />
              <ExcelColumn label="Key" value="key" />
              {this.state.languageTypeList.map((element) => {
                return (
                  <ExcelColumn
                    key={element.id}
                    label={element.id + "," + element.name}
                    value={element.lang}
                  />
                );
              })}
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <Button
            style={{ border: "0px" }}
            onClick={this.handleExportExcel}
            icon={<ExportIcon color="action" fontSize="large" />}
          ></Button>
        )} */}
      </>
    );
    return (
      <div
        className="card card-custom"
        style={{ padding: "2rem", minHeight: "100%" }}
      >
        <Title level={2}>
          <Texty type="mask-bottom" mode="smooth">
            Language Settings
          </Texty>
        </Title>

        <Row justify="end">
          <Col md={1}>
            <Spin spinning={this.state.spinLoading}>
              {excelExport}
            </Spin>
          </Col>
          <Col md={1}>
            <Spin spinning={this.state.spinLoading}>
              <ExcelReader
                onCreateJson={this.handleUploadExcel.bind(this)}
              ></ExcelReader>
            </Spin>
          </Col>
          <Col md={1}>
            <Spin spinning={this.state.spinLoading}>
              <Tooltip title="Change Languages">
                <Button
                  style={{ border: "0px" }}
                  icon={<LanguageIcon color="action" fontSize="large" />}
                  onClick={() => {
                    this.setState({
                      isLanguagesModalVisible: true,
                    });
                  }}
                ></Button>
              </Tooltip>
            </Spin>
          </Col>
          <Col md={1}>
            <Spin spinning={this.state.spinLoading}>
              <Tooltip title="Refresh Table">
                <Button
                  style={{ border: "0px" }}
                  icon={<RefreshRoundedIcon color="action" fontSize="large" />}
                  onClick={() => this.restartTable()}
                ></Button>
              </Tooltip>
            </Spin>
          </Col>
        </Row>
        <Table
          bordered
          components={components}
          rowClassName={() => "editable-row"}
          columns={this.state.languageTableColumns}
          dataSource={this.state.dataLanguages}
          scroll={{ x: 1500, y: 600 }}
        />
        <Modal
          title="Languages"
          visible={this.state.isLanguagesModalVisible}
          onOk={this.closeLanguageTypesModal}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={this.closeLanguageTypesModal}
        >
          {this.state.languageTypeList.map((field) => (
            <Form.Item
              {...layout}
              key={field.id}
              label={
                <>
                  <span className="mr-5">{field.name}</span>
                  <span className="symbol symbol-20">
                    <img src={toAbsoluteUrl(field.iconPath)} alt={field.name} />
                  </span>
                </>
              }
            >
              <Switch
                checked={field.showOnLayout}
                onChange={(e) => {
                  this.handleLanguageTypeChange(e, field);
                }}
              />
            </Form.Item>
          ))}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(LanguageSettings);
