import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { UploadOutlined } from "@ant-design/icons";
import { Button as AntButton, Divider, Upload, Col, InputNumber, Row, Checkbox, Collapse, Radio, Switch } from "antd";
import "antd/dist/antd.css";
import RadioGroup from "antd/lib/radio/group";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { Button, Modal, Col as ColBoot, Row as RowBoot, Form as FormBoot } from "react-bootstrap";

const { Panel } = Collapse;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node.data)) {
      var lineage = [];
      if (Boolean(node.data.lineage) && node.data.lineage.length > 0) {
        node.data.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.data.id,
        lineage: lineage,
        title: node.data.menuTitle,
        page: node.data.page
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class VendorInitialRecording extends Component {
  constructor() {
    super();
    this.state = {
      formName: "VendorInitialRecording",
      controllerName: "vendorInitialRecording",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      dynamicMenuCascader: [],
      pageActionSelectItems: [],
      id: null,
      tableList: [],

      createWhereParams: null,
      refreshForm: false,

      loading: false,
      sortField: "",
      sortOrder: ""
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });

    this.fillComboboxes();
  };

  componentDidUpdate = async nextProps => {};

  fillComboboxes = async () => {
    this.getDynamicMenu();
    this.getItemClassess();
  };

  fillComboboxes = async () => {
    this.getAnswerType();
    this.fillCode("VendorQuestionnaryTitle");
    this.fillCode("VendorQuestionnaryOptions");
    this.fillCode("VendorQuestionnaryColumns");
  };

  getAnswerType = async () => {
    const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/answerType");
    if (Boolean(response.data)) {
      this.setState({
        answerTypeSelectItems: response.data
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    var menu = dataList.find(p => p.key == row.menuId);
    let menuId = [];
    if (Boolean(menu)) {
      menuId = menu.lineage;
      this.handleMenuIdChange(menuId);
    }
    this.formRef.current.setFieldsValue({
      ...row,
      menuId: menuId
    });
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getAllByCompany");
    console.log("XXX", response.data);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: []
      });
    } else {
      this.setState({
        loading: false,
        tableList: response.data
      });
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      searchCriteriaModels = [],
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
      searchCriteriaModels: searchCriteriaModels
    };
    return newObj;
  }

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    let newItem = {
      ...values,
      id: this.state.id
    };
    newItem.menuId = Boolean(values.menuId) && values.menuId.length > 0 ? values.menuId[values.menuId.length - 1] : null;

    if (!Boolean(newItem.id)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      this.restartTable();
      showSuccess();
    }
  };

  saveScoreAndWeight = async () => {
    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/saveScoreAndWeight", this.state.tableList);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      this.restartTable();
      showSuccess();
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  handleMenuIdChange = async value => {
    let menuId = Boolean(value) && value.length > 0 ? value[value.length - 1] : null;
    let pageActions = [];
    if (Boolean(menuId)) {
      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/pageActionsByMenu/" + menuId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        pageActions = response.data;
      }
    }
    this.setState({
      pageActionSelectItems: pageActions
    });
  };

  // renderElement = values => {
  //   return (
  //     Boolean(values) &&
  //     values.length > 0 &&
  //     values.map((item, index) => (
  //       <Panel
  //         header={item.title}
  //         key={index}
  //         extra={
  //           <>
  //             <Space>
  //               S:
  //               <InputNumber
  //                 min={0}
  //                 disabled
  //                 value={item.score}
  //                 placeholder="Score"
  //                 onChange={value => {
  //                   item.score = value;
  //                   this.calcWeight();
  //                 }}
  //               ></InputNumber>
  //               W:
  //               <InputNumber disabled min={0} value={item.weight} placeholder="Weight"></InputNumber>
  //             </Space>
  //           </>
  //         }
  //       >
  //         {Boolean(item.children) && item.children.length > 0 && (
  //           <>
  //             {item.children.map((item, index) => (
  //               <Card

  //                 type="inner"
  //                 size="small"
  //                 actions={[
  //                   <InputNumber
  //                     min={0}
  //                     value={item.score}
  //                     placeholder="Score"
  //                     onChange={value => {
  //                       item.score = value;
  //                       this.calcWeight();
  //                     }}
  //                   ></InputNumber>,
  //                   ,
  //                   ,
  //                   ,
  //                   ,
  //                   ,
  //                   <InputNumber disabled min={0} value={item.weight} placeholder="Weight"></InputNumber>
  //                 ]}
  //                 extra={<>{item.step}</>}
  //               >
  //                 <RowBoot>
  //                   <ColBoot xs={9}>
  //                     <FormBoot.Label style={{ color: "black", marginTop: "0.5rem" }}>
  //                       <Input type="text"></Input>
  //                     </FormBoot.Label>
  //                   </ColBoot>
  //                 </RowBoot>
  //               </Card>
  //             ))}
  //           </>
  //         )}
  //       </Panel>
  //     ))
  //   );
  // };

  renderElement = values => {
    return (
      Boolean(values) &&
      values.length > 0 &&
      values.map((item, index) => (
        <Panel
          header={item.title}
          key={1}
          showArrow={false}
          extra={
            <>
              <InputNumber
                min={0}
                disabled
                value={item.score}
                placeholder="Score"
                onChange={value => {
                  item.score = value;
                  this.calcWeight();
                }}
              ></InputNumber>
            </>
          }
        >
          {Boolean(item.children) && item.children.length > 0 && (
            <>
              <RowBoot>
                <ColBoot xs={4}>
                  <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>{"Question"}</FormBoot.Label>
                </ColBoot>
                <ColBoot xs={5}>
                  <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>{"Answer"}</FormBoot.Label>
                </ColBoot>
                <ColBoot xs={1}>
                  <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>{"Score"}</FormBoot.Label>
                </ColBoot>
                <Divider />
              </RowBoot>

              {item.children.map((item, index) => (
                <RowBoot>
                  <ColBoot xs={4}>
                    <FormBoot.Label style={{ marginTop: "0.5rem" }}>{item.step}</FormBoot.Label>
                  </ColBoot>
                  <ColBoot xs={5}>
                    <FormBoot.Label style={{ marginTop: "0.5rem" }}></FormBoot.Label>
                  </ColBoot>
                  <ColBoot xs={1}>
                    <InputNumber
                      min={0}
                      disabled
                      value={item.score}
                      placeholder="Score"
                      onChange={value => {
                        item.score = value;
                        this.calcWeight();
                      }}
                    ></InputNumber>
                  </ColBoot>
                  <Divider dashed />
                </RowBoot>
              ))}

              {Boolean(item.answerType) && item.answerType === "Comment" && (
                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <ColBoot xs="2">
                    <InputNumber
                      min={0}
                      value={item.score}
                      placeholder="Score"
                      onChange={value => {
                        item.score = value;
                        // this.calcWeight();
                      }}
                    ></InputNumber>
                  </ColBoot>
                  <ColBoot xs="10">
                    <FormBoot.Control as="textarea" rows="3" />
                  </ColBoot>
                </FormBoot.Group>
              )}

              {Boolean(item.answerType) && item.answerType === "Radiogroup" && (
                <>
                  {Boolean(item.options) &&
                    item.options.length > 0 &&
                    item.options.map((opt, j) => (
                      <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                        <ColBoot sm="2">
                          <InputNumber
                            min={0}
                            value={item.score}
                            placeholder="Score"
                            onChange={value => {
                              item.score = value;
                              // this.calcWeight();
                            }}
                          ></InputNumber>
                        </ColBoot>
                        <ColBoot xs="10">
                          <Radio checked={false}>{opt.codeName}</Radio>
                        </ColBoot>
                      </FormBoot.Group>
                    ))}
                </>
              )}

              {Boolean(item.answerType) && item.answerType === "Imagepicker" && (
                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                  {Boolean(item.imageOptions) &&
                    item.imageOptions.length > 0 &&
                    item.imageOptions.map((opt, j) => (
                      <>
                        <ColBoot sm="2">
                          <InputNumber
                            min={0}
                            value={item.score}
                            placeholder="Score"
                            onChange={value => {
                              item.score = value;
                              // this.calcWeight();
                            }}
                          ></InputNumber>
                        </ColBoot>
                        <ColBoot sm="10">
                          <RadioGroup>
                            <Radio checked={false}>
                              <img alt="option" style={{ width: "120px", height: "120px" }} src={opt.imageUrl} />
                            </Radio>
                          </RadioGroup>
                        </ColBoot>
                      </>
                    ))}
                </FormBoot.Group>
              )}

              {Boolean(item.answerType) && item.answerType === "Rating" && (
                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <ColBoot sm="2">
                    <InputNumber
                      min={0}
                      value={item.score}
                      placeholder="Score"
                      onChange={value => {
                        item.score = value;
                        // this.calcWeight();
                      }}
                    ></InputNumber>
                  </ColBoot>

                  <ColBoot xs="10">
                    <Radio.Group buttonStyle="solid">
                      {this.getRatingList(item.rateMax).map((element, index) => (
                        <Radio.Button value={element}>{element}</Radio.Button>
                      ))}
                    </Radio.Group>
                  </ColBoot>
                </FormBoot.Group>
              )}

              {Boolean(item.answerType) && item.answerType === "Boolean" && (
                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <ColBoot sm="2">
                    <InputNumber
                      min={0}
                      value={item.score}
                      placeholder="Score"
                      onChange={value => {
                        item.score = value;
                        // this.calcWeight();
                      }}
                    ></InputNumber>
                  </ColBoot>
                  <ColBoot xs="10">
                    <Switch checkedChildren={item.labelTrue} unCheckedChildren={item.labelFalse} defaultChecked />
                  </ColBoot>
                </FormBoot.Group>
              )}

              {Boolean(item.answerType) && item.answerType === "Checkbox" && (
                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                  {Boolean(item.options) &&
                    item.options.length > 0 &&
                    item.options.map((opt, j) => (
                      <>
                        <ColBoot sm="2">
                          <InputNumber
                            min={0}
                            value={item.score}
                            placeholder="Score"
                            onChange={value => {
                              item.score = value;
                              // this.calcWeight();
                            }}
                          ></InputNumber>
                        </ColBoot>
                        <ColBoot style={{ marginBottom: "1rem" }} xs="10">
                          <Checkbox> {opt.codeName}</Checkbox>
                        </ColBoot>{" "}
                        <br />
                      </>
                    ))}
                </FormBoot.Group>
              )}

              {Boolean(item.answerType) && item.answerType === "Dropdown" && (
                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <ColBoot sm="2">
                    <InputNumber
                      min={0}
                      value={item.score}
                      placeholder="Score"
                      onChange={value => {
                        item.score = value;
                        // this.calcWeight();
                      }}
                    ></InputNumber>
                  </ColBoot>
                  <ColBoot xs="4">
                    <FormBoot.Control name="type" as="select">
                      <option value="Select">Select Option</option>
                      {Boolean(item.options) &&
                        item.options.length > 0 &&
                        item.options.map((opt, j) => <option value={opt.codeName}>{opt.codeName}</option>)}
                    </FormBoot.Control>
                  </ColBoot>
                </FormBoot.Group>
              )}

              {Boolean(item.answerType) && item.answerType === "File" && (
                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <ColBoot sm="2">
                    <InputNumber
                      min={0}
                      value={item.score}
                      placeholder="Score"
                      onChange={value => {
                        item.score = value;
                        // this.calcWeight();
                      }}
                    ></InputNumber>
                  </ColBoot>
                  <ColBoot xs="4">
                    <Upload>
                      <AntButton>
                        <UploadOutlined /> Click to Upload
                      </AntButton>
                    </Upload>
                  </ColBoot>
                </FormBoot.Group>
              )}

              {Boolean(item.answerType) && item.answerType === "Matrix_Single" && Boolean(item.columns) && item.columns.length > 0 && (
                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <ColBoot sm="2"> </ColBoot>
                  <ColBoot> </ColBoot>
                  {item.columns.map((col, j) => (
                    <>
                      <ColBoot>{col.codeName}</ColBoot>
                    </>
                  ))}
                </FormBoot.Group>
              )}

              {Boolean(item.answerType) && item.answerType === "Matrix_Multiple" && (
                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <ColBoot sm="2"> </ColBoot>
                  <ColBoot> </ColBoot>
                  {item.columns.map((col, j) => (
                    <>
                      <ColBoot>{col.codeName}</ColBoot>
                    </>
                  ))}
                </FormBoot.Group>
              )}

              {Boolean(item.answerType) &&
                item.answerType === "Matrix_Single" &&
                Boolean(item.columns) &&
                item.columns.length > 0 &&
                Boolean(item.matrixValues) &&
                item.matrixValues.length > 0 &&
                item.matrixValues.map((row, k) => (
                  <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                    <ColBoot sm="2">
                      <InputNumber
                        min={0}
                        value={item.score}
                        placeholder="Score"
                        onChange={value => {
                          item.score = value;
                          // this.calcWeight();
                        }}
                      ></InputNumber>
                    </ColBoot>
                    <ColBoot>{row.title}</ColBoot>
                    {item.columns.map((col, j) => (
                      <ColBoot>
                        <Radio checked={false}></Radio>
                      </ColBoot>
                    ))}
                  </FormBoot.Group>
                ))}

              {Boolean(item.answerType) &&
                item.answerType === "Matrix_Multiple" &&
                Boolean(item.matrixValues) &&
                item.matrixValues &&
                item.matrixValues.map((row, k) => (
                  <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                    <ColBoot sm="2">
                      <InputNumber
                        min={0}
                        value={item.score}
                        placeholder="Score"
                        onChange={value => {
                          item.score = value;
                          // this.calcWeight();
                        }}
                      ></InputNumber>
                    </ColBoot>
                    <ColBoot> {row.title} </ColBoot>
                    {item.columns.map((col, j) => (
                      <ColBoot>
                        <FormBoot.Control name="type" as="select">
                          <option value="Select">Select Option</option>
                          {Boolean(item.options) &&
                            item.options.length > 0 &&
                            item.options.map((opt, j) => <option value={opt.codeId}>{opt.codeName}</option>)}
                        </FormBoot.Control>
                      </ColBoot>
                    ))}
                  </FormBoot.Group>
                ))}
            </>
          )}
        </Panel>
      ))
    );
  };

  calcWeight = () => {
    var activePanelKey = this.state.activePanelKey;
    var values = this.state.tableList;
    if (Boolean(values) && values.length > 0) {
      var totalParentScore = 0;
      values.forEach((element, index) => {
        if (Boolean(element.score) && element.score > 0) {
          totalParentScore += element.score;
        }
      });

      values.forEach((element, index) => {
        if (Boolean(element.score) && element.score > 0 && totalParentScore > 0) {
          element.weight = Number((element.score / totalParentScore) * 100).toFixed(2);
        }
        if (index == activePanelKey) {
          var totalChildScore = 0;
          if (Boolean(element.children) && element.children.length > 0) {
            element.children.forEach((child, index) => {
              if (Boolean(child.score) && child.score > 0) {
                totalChildScore += child.score;
              }
            });
          }

          values.forEach(element => {
            if (Boolean(element.children) && element.children.length > 0) {
              element.children.forEach((child, index) => {
                if (Boolean(child.score) && child.score > 0 && totalChildScore > 0) {
                  child.weight = Number((child.score / totalChildScore) * 100).toFixed(2);
                }
              });
            }
          });
        }
      });
    }

    this.setState({
      tableList: this.state.tableList
    });
  };

  setOpenPanels = key => {
    this.setState({
      activePanelKey: key
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    return (
      <div className="card card-custom" style={{ padding: "0.5rem", minHeight: "100%" }}>
        {Boolean(this.state.tableList) && this.state.tableList.length > 0 ? (
          <>
            <Collapse defaultActiveKey={["1"]} onChange={this.setOpenPanels} accordion>
              {this.renderElement(this.state.tableList)}
            </Collapse>

            <br />
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="SaveAllButton" onClick={this.saveScoreAndWeight} style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSaveAll" defaultMessage="Save All" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            }
          </>
        ) : (
          ""
        )}

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VendorInitialRecording);
