import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Form, Table, Input, Checkbox, Button as AntButton, Col, Row, DatePicker, Select, Timeline } from "antd";
import { Form as FormBoot } from 'react-bootstrap';
import moment from "moment";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import { CloseOutlined, UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const { RangePicker } = DatePicker;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class TimesheetWorkflow extends Component {
  constructor() {
    super();
    this.state = {
      controller: "timesheetWorkflow",
      modalDeleteShow: false,
      hideInputs: true,
      hideSave: false,
      hideUpdate: true,

      exportClick: false,
      timesheetWorkflowId: null,
      timesheetWorkflowList: [],
      dynamiaIntegrated: false,
      modeOfTimesheet: null,
      methodOfLogging: null,
      workflowStepSelectItems: [],
      stepName: null,
      modeOfTimesheetSelectItems: [],
      methodOfLoggingSelectItems: [],
      stepList: [],
      selectedStep: null,
      selectedDepartments: [],
      selectedDisciplines: [],
      selectedDesignations: [],
      departmentSelectItems: [],
      disciplineSelectItems: [],
      designationSelectItems: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    let urlWorkflowSteps = "/api/" + this.state.controller + "/workflowSteps";
    const responseWorkflowSteps = await handleRequest("GET", urlWorkflowSteps);
    if (responseWorkflowSteps.type === "ERROR") {
      error(responseWorkflowSteps);
    } else {
      this.setState({
        workflowStepSelectItems: Boolean(responseWorkflowSteps.data) ? responseWorkflowSteps.data : [],
      });
    }

    let urlModeOfTimesheets = "/api/" + this.state.controller + "/modeOfTimesheets";
    const responseModeOfTimesheets = await handleRequest("GET", urlModeOfTimesheets);
    if (responseModeOfTimesheets.type === "ERROR") {
      error(responseModeOfTimesheets);
    } else {
      this.setState({
        modeOfTimesheetSelectItems: Boolean(responseModeOfTimesheets.data) ? responseModeOfTimesheets.data : [],
      });
    }

    let urlMethodOfLoggings = "/api/" + this.state.controller + "/methodOfLoggings";
    const responseMethodOfLoggings = await handleRequest("GET", urlMethodOfLoggings);
    if (responseMethodOfLoggings.type === "ERROR") {
      error(responseMethodOfLoggings);
    } else {
      this.setState({
        methodOfLoggingSelectItems: Boolean(responseMethodOfLoggings.data) ? responseMethodOfLoggings.data : [],
      });
    }

    let urlDepartments = "/api/" + this.state.controller + "/departments";
    const responseDepartments = await handleRequest("GET", urlDepartments);
    if (responseDepartments.type === "ERROR") {
      error(responseDepartments);
    } else {
      this.setState({
        departmentSelectItems: Boolean(responseDepartments.data) ? responseDepartments.data : [],
      });
    }

    let urlDisciplines = "/api/" + this.state.controller + "/disciplines";
    const responseDisciplines = await handleRequest("GET", urlDisciplines);
    if (responseDisciplines.data.length > 0) {
      this.setState({
        disciplineSelectItems: responseDisciplines.data,
      });
    } else {
      this.setState({
        disciplineSelectItems: []
      });
    }

    let urlDesignations = "/api/" + this.state.controller + "/designations";
    const responseDesignations = await handleRequest("GET", urlDesignations);
    if (responseDesignations.data.length > 0) {
      this.setState({
        designationSelectItems: responseDesignations.data
      });
    } else {
      this.setState({
        designationSelectItems: []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      timesheetWorkflowId: null,
      workflowName: null,
      dynamiaIntegrated: null,
      modeOfTimesheet: null,
      methodOfLogging: null,
      stepList: [],
      selectedDepartments: [],
      selectedDisciplines: [],
      selectedDesignations: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    console.log("AAA", row);

    document.getElementById("kt_scrolltop").click();

    if (Boolean(row.timesheetWorkflowSteps) && row.timesheetWorkflowSteps.length > 0) {
      row.timesheetWorkflowSteps.forEach(element => {
        element.value = element.stepName;
      });
    }

    var selectedDepartments = [];
    if (Boolean(row.department) && row.department.length > 0) {
      row.department.forEach(element => {
        selectedDepartments.push(element.id);
      });
    }

    console.log("AAA", selectedDepartments);

    var selectedDisciplines = [];
    if (Boolean(row.discipline) && row.discipline.length > 0) {
      row.discipline.forEach(element => {
        selectedDisciplines.push(element.id);
      });
    }

    var selectedDesignations = [];
    if (Boolean(row.designation) && row.designation.length > 0) {
      row.designation.forEach(element => {
        selectedDesignations.push(element.id);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      timesheetWorkflowId: row.id,
      workflowName: row.workflowName,
      dynamiaIntegrated: row.dynamiaIntegrated,
      modeOfTimesheet: row.modeOfTimesheet,
      methodOfLogging: row.methodOfLogging,
      stepList: row.timesheetWorkflowSteps,
      selectedDepartments: selectedDepartments,
      selectedDisciplines: selectedDisciplines,
      selectedDesignations: selectedDesignations,
    });

    this.setState({
      timesheetWorkflowId: row.id,
      workflowName: row.workflowName,
      dynamiaIntegrated: row.dynamiaIntegrated,
      modeOfTimesheet: row.modeOfTimesheet,
      methodOfLogging: row.methodOfLogging,
      stepList: row.timesheetWorkflowSteps,
      selectedDepartments: selectedDepartments,
      selectedDisciplines: selectedDisciplines,
      selectedDesignations: selectedDesignations,
      hideSave: true,
      hideUpdate: false,
      hideInputs: false,
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controller + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controller + "/paging";
    const response = await handleRequest("POST", url, newObj);
    console.log("AAA", response.data);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const timesheetWorkflowSteps = this.state.stepList;
    const selectedDepartments = this.state.selectedDepartments;
    const selectedDisciplines = this.state.selectedDisciplines;
    const selectedDesignations = this.state.selectedDesignations;

    timesheetWorkflowSteps.forEach((element, index) => {
      element.stepOrder = index;
    });

    if (Boolean(selectedDepartments) && selectedDepartments.length === 0 && Boolean(selectedDisciplines) && selectedDisciplines.length === 0 && Boolean(selectedDesignations) && selectedDesignations.length === 0) {
      showError("Please select at least one applicable area.");
      return;
    }

    var department = [];
    if (Boolean(selectedDepartments) && selectedDepartments.length > 0) {
      selectedDepartments.forEach(element => {
        department.push({
          ["id"]: element,
        });
      });
    }


    var discipline = [];
    if (Boolean(selectedDisciplines) && selectedDisciplines.length > 0) {
      selectedDisciplines.forEach(element => {
        discipline.push({
          ["id"]: element,
        });
      });
    }

    var designation = [];
    if (Boolean(selectedDesignations) && selectedDesignations.length > 0) {
      selectedDesignations.forEach(element => {
        designation.push({
          ["id"]: element,
        });
      });
    }

    const newItem = {
      id: this.state.timesheetWorkflowId,
      workflowName: this.state.workflowName,
      dynamiaIntegrated: this.state.dynamiaIntegrated,
      modeOfTimesheet: this.state.modeOfTimesheet,
      methodOfLogging: this.state.methodOfLogging,
      timesheetWorkflowSteps: timesheetWorkflowSteps,
      department: department,
      discipline: discipline,
      designation: designation,
    }
    console.log("AAA", newItem);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controller + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controller + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controller + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  moveUp = () => {

    const list = this.state.stepList;
    const step = this.state.selectedStep;

    if (Boolean(list) && list.length > 0) {

      var index = list.findIndex(p => p.stepName === step.stepName && p.active === true);

      if (index > 0) {

        let flag = list[index - 1];
        list[index - 1] = step;
        list[index] = flag;

      }

      this.setState({
        stepList: list
      });

    }

  }

  moveDown = () => {

    var list = this.state.stepList;
    const step = this.state.selectedStep;

    if (Boolean(list) && list.length > 0) {

      var index = list.findIndex(p => p.stepName === step.stepName && p.active === true);

      if (index < list.length - 1 && index !== -1) {

        let flag = list[index + 1];
        list[index + 1] = step;
        list[index] = flag;

      }

      this.setState({
        stepList: list
      });
    }
  }

  removeStep = (step) => {
    this.setState(prevState => ({
      stepList: prevState.stepList.map(
        el => el.stepName === step.stepName ? { ...el, active: false } : el
      )
    }))

    this.setState({ selectedStep: null })
  }

  addStep = async () => {
    var list = this.state.stepList.filter(p => p.active === true);
    if (Boolean(this.state.stepName)) {
      let isThere = list.find(p => p.stepName === this.state.stepName);
      if (Boolean(isThere)) {
        showError("This workflow added before as a step !")
        return;
      }

      var fObj = {
        id: null,
        stepName: this.state.stepName,
        label: this.state.workflowStepSelectItems.find(p => p.key === this.state.stepName).value,
        value: this.state.workflowStepSelectItems.find(p => p.key === this.state.stepName).value,
        stepOrder: this.state.stepList.length,
        active: true
      };

      list.push(fObj);

      this.setState({
        stepList: list
      })
    }
    else showError("Please select workflow step ! ");
  }

  clickStepValue = (step) => {
    this.setState({ selectedStep: step });
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="Timesheet.workflowName" defaultMessage="Workflow Name" />,
        key: "workflowName",
        render: record => {
          return record.workflowName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.workflowName) ? this.state.pagination.workflowName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  workflowName: e.target.value,
                  workflowNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.workflowNameColor) ? this.state.pagination.workflowNameColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Timesheet.modeOfTimesheet" defaultMessage="Mode Of Timesheet" />,
        key: "modeOfTimesheet",
        render: record => {
          return record.modeOfTimesheet;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.modeOfTimesheet) ? this.state.pagination.modeOfTimesheet : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  modeOfTimesheet: e.target.value,
                  modeOfTimesheetColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.modeOfTimesheetColor) ? this.state.pagination.modeOfTimesheetColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Timesheet.methodOfLogging" defaultMessage="Method Of Logging" />,
        key: "methodOfLogging",
        render: record => {
          return record.methodOfLogging;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.methodOfLogging) ? this.state.pagination.methodOfLogging : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  methodOfLogging: e.target.value,
                  methodOfLoggingColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.methodOfLoggingColor) ? this.state.pagination.methodOfLoggingColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Timesheet.timesheetApplicableAreas" defaultMessage="Applicaple Areas" />,
        key: "timesheetApplicableAreas",
        render: record => {
          return record.timesheetApplicableAreas;
        },
        sorter: false
      },
      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
            <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
          )} */}
      </>
    );

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={1}>
              {/* {excelExport} */}
            </Col>
            <Col md={1}>
              {/* <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader> */}
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="TimesheetWorkflow.workflowName" defaultMessage="Workflow Name" />}
                name="workflowName"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input id="todo" value={this.state.workflowName}
                  onChange={(e) => this.setState({ workflowName: e.target.value })} />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="TimesheetWorkflow.stepName" defaultMessage="Workflow Steps" />}
                name="stepName"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select allowClear
                  value={this.state.stepName} onChange={(value) => {
                    this.setState({
                      stepName: value,
                    })
                  }}
                >
                  {this.state.workflowStepSelectItems.map(i => (
                    <Option key={i.key} value={i.key}>{i.value}</Option>
                  ))}
                </Select>
              </Form.Item>
            }

            {Boolean(this.state.stepName) && this.state.stepName === "LOGGING" &&
              <>
                <Form.Item
                  {...layout}
                  label={"Not dynamia integrated applications"}
                  name="dynamiaIntegrated"
                  rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type dynamia integrated" /> }]} >
                  <Checkbox onChange={(e) => {
                    this.setState({
                      methodOfLogging: null,
                      dynamiaIntegrated: e.target.checked
                    })
                  }} checked={this.state.dynamiaIntegrated}></Checkbox>
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TimesheetWorkflow.modeOfTimesheet" defaultMessage="Mode of timesheet" />}
                  name="modeOfTimesheet"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select allowClear
                    value={this.state.modeOfTimesheet} onChange={(value) => {
                      this.setState({
                        modeOfTimesheet: value
                      })
                    }}  >
                    {this.state.modeOfTimesheetSelectItems.map(i => (
                      <Option key={i.key} value={i.key}>{i.value}</Option>
                    ))}
                  </Select>
                </Form.Item>

                {this.state.dynamiaIntegrated &&
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="TimesheetWorkflow.methodOfLogging" defaultMessage="Method Of Logging" />}
                    name="methodOfLogging"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Select allowClear
                      value={this.state.methodOfLogging} onChange={(value) => {
                        this.setState({
                          methodOfLogging: value
                        })
                      }}
                    >
                      {this.state.methodOfLoggingSelectItems.map(i => (
                        <Option key={i.key} value={i.key}>{i.value}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                }
              </>
            }

            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="TimesheetWorkflow.AddButton" type="button" onClick={this.addStep}
                    variant="outline-info" ><FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" /></Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            }
            <br />
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <FormBoot.Group style={{ marginBottom: '0rem' }} as={Row}>
                  <Col sm="2">
                    <FormBoot.Row hidden={!Boolean(this.state.selectedStep)} >
                      <Col sm="4">
                        <AntButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }}
                          type="text" onClick={this.moveUp}
                          icon={<UpCircleOutlined style={{ fontSize: '32px' }} />} />
                      </Col>
                      <Col sm="4">
                        <AntButton style={{
                          marginRight: '10px', marginTop: '5px', border: '0px',
                          alignContent: 'right'
                        }} type="text" onClick={this.moveDown}
                          icon={<DownCircleOutlined style={{ fontSize: '32px' }} />} />
                      </Col>
                    </FormBoot.Row>
                  </Col>
                  <Col sm="4">
                    <Timeline mode='left'>
                      {this.state.stepList.filter(p => p.active === true).map((opt, j) => (
                        <Timeline.Item> <a
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.clickStepValue(opt)}
                        >
                          {opt.value}
                          <AntButton style={{ marginLeft: '10px' }} type="dashed"
                            onClick={() => this.removeStep(opt)} shape="circle" icon={<CloseOutlined />} />
                        </a>
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  </Col>
                </FormBoot.Group>
              </Row>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="todo" defaultMessage="Applicable Areas" />} >
                <>
                </>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="TimesheetWorkflow.Department" defaultMessage="Department" />}
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select allowClear mode="tags" value={this.state.selectedDepartments} onChange={(value) => {
                  this.setState({
                    selectedDepartments: value,
                  })
                }}  >
                  {this.state.departmentSelectItems.map(i => (
                    <Option key={i.key} value={i.key}>{i.value}</Option>
                  ))}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="todo" defaultMessage="Discipline" />}
                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type discipline" /> }]} >
                <Select allowClear mode="tags" value={this.state.selectedDisciplines} onChange={(value) => {
                  this.setState({
                    selectedDisciplines: value
                  })
                }}
                >
                  {this.state.disciplineSelectItems.map(i => (
                    <Option key={i.key} value={i.key}>{i.value}</Option>
                  ))}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="todo" defaultMessage="Designation" />}
                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type designation" /> }]} >
                <Select allowClear mode="tags" value={this.state.selectedDesignations} onChange={(value) => {
                  this.setState({
                    selectedDesignations: value
                  })
                }}
                >
                  {this.state.designationSelectItems.map(i => (
                    <Option key={i.key} value={i.key}>{i.value}</Option>
                  ))}
                </Select>
              </Form.Item>
            }

            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>

            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            rowKey="id"
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetWorkflow);
