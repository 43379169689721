import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Tooltip, Drawer, Tree, Checkbox, Space, Radio } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined, NoteAdd, Settings, FilterList, Add, PictureAsPdf } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import store from "../../../redux/store";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import DraggableModal from "../../Components/DraggableModal";
import { InputNumber } from "antd";
import { Input } from "antd";
import { DatePicker } from "antd";
import { Select } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import TenderingLogProjectDocuments from "./TenderingLogProjectDocuments";
import { FcFile, FcFolder } from "react-icons/fc";
import { FaFileAudio, FaRegFile } from "react-icons/fa";
import TenderingLogAddProduct from "./TenderingLogAddProduct";

const { Option } = Select;

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

export class TenderingLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "TenderingLog",
      controllerName: "tenderingLog",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      countrySelectItems: []
    };
  }

  formRef = React.createRef();
  formRefNewClient = React.createRef();
  formRefProjectDurationDates = React.createRef();
  formRefProjectDurationPeriod = React.createRef();
  formRefProjectFollowUpsPeriodShow = React.createRef();
  formRefDeclineOk = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.fillClient();
    var responseTenderingType = await handleRequest("GET", "/api/" + this.state.controllerName + "/tenderingType");
    if (responseTenderingType.type === "ERROR") {
      error(responseTenderingType);
    }

    var responseProjectDurationPeriodDate = await handleRequest("GET", "/api/" + this.state.controllerName + "/dayWeekMonthYear");
    if (responseProjectDurationPeriodDate.type === "ERROR") {
      error(responseProjectDurationPeriodDate);
    }

    var responseProjectScope = await handleRequest("GET", "/api/" + this.state.controllerName + "/projectScope");
    if (responseProjectScope.type === "ERROR") {
      error(responseProjectScope);
    }

    this.setState({
      projectDurationPeriodDateIdSelectItems: Boolean(responseProjectDurationPeriodDate.data) ? responseProjectDurationPeriodDate.data : [],
      tenderingTypeIdSelectItems: Boolean(responseTenderingType.data) ? responseTenderingType.data : [],
      projectScopeSelectItems: Boolean(responseProjectScope.data) ? responseProjectScope.data : []
    });

    this.fillCode("TenderingLogclientId");
    this.fillCode("TenderingLogcontractorId");
    this.fillCode("TenderingLogcontactPersonId");
    this.fillCountries();
    this.fillTree();

  };


  fillClient = async () => {
    var responseclientId = await handleRequest("GET", "/api/" + this.state.controllerName + "/client");
    if (responseclientId.type === "ERROR") {
      error(responseclientId);
    }
    this.setState({
      clientIdSelectItems: Boolean(responseclientId.data) ? responseclientId.data : []
    });
  }




  fillCountries = async () => {
    let url = "/api/" + this.state.controllerName + "/countries";
    var response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else
      this.setState({
        countrySelectItems: Boolean(response.data) ? response.data : []
      });
  };

  fillCities = async countryId => {
    if (Boolean(countryId)) {
      let url = "/api/" + this.state.controllerName + "/cities/" + countryId;
      var response = await handleRequest("GET", url);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          cityIdSelectItems: Boolean(response.data) ? response.data : []
        });
      }
    } else {
      this.setState({
        cityIdSelectItems: []
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }






  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  handleFormNewClientSubmit = async values => {
    const newItem = {
      ...values,
      client: true,
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createVendor", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showNewClientModal: false
      });
      showSuccess();
      this.fillClient();
    }
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
      issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null,
      biddingClosingDate: Boolean(row.biddingClosingDate) ? moment(row.biddingClosingDate) : null,
      tenderingDate: Boolean(row.tenderingDate) ? moment(row.tenderingDate) : null,
      projectStartDate: Boolean(row.projectStartDate) ? moment(row.projectStartDate) : null,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      checkedKeys: { checked: row.projectsTreeIds }
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  projectDocumentsModal = row => {
    this.setState({
      modalProjectDocumentsShow: true,
      id: row.id
    });
  };

  addProductModal = row => {
    this.setState({
      modalAddProductShow: true,
      id: row.id,
      random: Math.random(),
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  
  disabledDate = current => {
    return current && current < moment(this.state.issueDate).startOf("day");
  };

  disabledDate1 = current => {
    return current && current < moment(this.state.biddingClosingDate).startOf("day");
  };
  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "ProjectName"
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitems/" + obj.id);
    }
    if (response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "ProjectName" ? (
            <FcFile />
          ) : element.type === "Project" ? (
            <FaRegFile />
          ) : element.type === "ProjectChild" ? (
            <FaFileAudio />
          ) : (
            <FcFolder />
          );
        element.title = element.projectName;
        element.key = element.id;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  onCheck = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes
    });
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "ProjectName";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };
    let reviseAction = {
      name: "Revise",
      icon: <CreateIcon fontSize="small" color="error" />,
      actionClick: this.edit.bind(this)
    };
    let declineAction = {
      name: "Decline",
      icon: <NoteAdd fontSize="small" color="primary" />,
      actionClick: this.declineModal.bind(this)
    };
    let projectDocumentsAction = {
      name: "Project Documents",
      icon: <CreateIcon fontSize="small" color="secondary" />,
      actionClick: this.projectDocumentsModal.bind(this)
    };
    let addProductAction = {
      name: "Add Product",
      icon: <CreateIcon fontSize="small" color="primary" />,
      actionClick: this.addProductModal.bind(this)
    };

    let actionList = [];

    actionList.push(declineAction);

    actionList.push(deleteAction);

    if (Boolean(record.projectScope) && record.projectScope === "Supply_Of_Products") {
      actionList.push(addProductAction);
    }
    if (Boolean(record.status) && record.status === "Sent") {
      actionList.push(reviseAction);
    }
    else {
      actionList.push(editAction);
      actionList.push(projectDocumentsAction);
    }
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  openModal = row => {
    this.setState({
      id: row.id
    });
  };

  declineModal = row => {
    this.setState({
      id: row.id,
      modalDeclineShow: true,
    });
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  saveProjectDurationPeriod = async values => {
    this.setState({
      saveProjectDurationPeriod: values,
      modalProjectDurationPeriodShow: false,
    });
  }

  saveProjectFollowUpsPeriod = async values => {
    this.setState({
      saveProjectFollowUpsPeriod: values,
      modalProjectFollowUpsPeriodShow: false,
    });
  }

  saveProjectDurationDates = async values => {
    this.setState({
      saveProjectDurationDates: values,
      modalProjectDurationDatesShow: false,
    });
  }

  save = async values => {
    const { saveProjectDurationPeriod, saveProjectFollowUpsPeriod, saveProjectDurationDates } = this.state;

    const newItem = {
      ...values,
      id: this.state.id,
      projectsTreeIds:
        Boolean(this.state.checkedKeys) && Boolean(this.state.checkedKeys.checked) && this.state.checkedKeys.checked.length > 0
          ? this.state.checkedKeys.checked
          : [],
      ...saveProjectDurationPeriod,
      ...saveProjectFollowUpsPeriod,
      ...saveProjectDurationDates,
    };

    console.log("newItem", newItem);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  }; save = async values => {
    const { saveProjectDurationPeriod, saveProjectFollowUpsPeriod, saveProjectDurationDates } = this.state;

    const newItem = {
      ...values,
      id: this.state.id,
      projectsTreeIds:
        Boolean(this.state.checkedKeys) && Boolean(this.state.checkedKeys.checked) && this.state.checkedKeys.checked.length > 0
          ? this.state.checkedKeys.checked
          : [],
      ...saveProjectDurationPeriod,
      ...saveProjectFollowUpsPeriod,
      ...saveProjectDurationDates,
    };

    console.log("newItem", newItem);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  saveTenderingDecline = async values => {
    let newObj = {
      tenderingDecline: values.tenderingDecline
    }
    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/saveTenderingDecline/" + this.state.id, newObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        modalDeclineOkShow: false,
      });
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  getTenderingDecline = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getTenderingDecline/" + this.state.id);
    if (response.type === "ERROR") {
      error(response);
    } else {
      let data = Boolean(response.data) ? response.data : null;
      this.setState({
        modalDeclineShow: false,
        modalDeclineOkShow: true,
      }, () => {
        this.formRefDeclineOk.current.setFieldsValue({
          tenderingDecline: Boolean(data) ? data.tenderingDecline : "",
        });
      });
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };
  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };
  onTargetKeysCheck = async targetKeys => {
    console.log("targetKeys", targetKeys);
    this.setState({
      targetKeys: targetKeys
    });
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const layout2 = {
      labelCol: { span: 12 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="TenderingLog.tenderProjectNo" defaultMessage="Tender Project No" />,
        key: "tenderProjectNo",
        render: record => {
          return record.tenderProjectNo;
        },
        ...getColumnFilter({
          inputName: "tenderProjectNo",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="TenderingLog.ProjectScope" defaultMessage="Project Scope" />,
        key: "projectScope",
        render: record => {
          return record.projectScopeName;
        },
        ...getColumnFilter({
          inputName: "projectScope",
          inputType: "SELECT",
          selectItems: Boolean(this.state["projectScopeSelectItems"]) ? this.state["projectScopeSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="TenderingLog.revNo" defaultMessage="Rev No" />,
        key: "revNo",
        render: record => {
          return record.revNo;
        },
        ...getColumnFilter({
          inputName: "revNo",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="TenderingLog.date" defaultMessage="Date" />,
        key: "date",
        render: record => {
          return record.date;
        },
        ...getColumnFilter({
          inputName: "date",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="TenderingLog.Status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          return (
            Boolean(record.status) && (
              <React.Fragment>
                {record.status === "Evaluation" ? (
                  <div
                    style={{
                      cursor: "pointer",
                      color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                      textDecoration: "underline",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <span onClick={e => this.generateReportEvaluation(record)} style={{ marginRight: "5px" }}>
                      <PictureAsPdf />
                    </span>
                    <span
                      onClick={e => this.rfqStatus(record)}
                      style={{
                        cursor: "pointer",
                        color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                        textDecoration: "underline",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      {record.statusName}
                    </span>
                  </div>
                ) : (
                  <div
                    style={{
                      cursor: "pointer",
                      color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                      textDecoration: "underline",
                      display: "flex",
                      alignItems: "center"
                    }}
                    onClick={e => this.rfqStatus(record)}
                  >
                    {record.statusName}
                  </div>
                )}
              </React.Fragment>
            )
          );
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        })
      },
      {
        title: <FormattedMessage id="TenderingLog.clientId" defaultMessage="Client" />,
        key: "clientId",
        render: record => {
          return record.clientName;
        },
        ...getColumnFilter({
          inputName: "clientId",
          inputType: "SELECT_ADDABLE",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="TenderingLog.contractorId" defaultMessage="Contractor" />,
        key: "contractorId",
        render: record => {
          return record.contractorName;
        },
        ...getColumnFilter({
          inputName: "contractorName",
          inputType: "SELECT_ADDABLE",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="TenderingLog.projectDescription" defaultMessage="Project Description" />,
        key: "projectDescription",
        render: record => {
          return record.projectDescription;
        },
        ...getColumnFilter({
          inputName: "projectDescription",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="TenderingLog.clientProjectNo" defaultMessage="Client Project No" />,
        key: "clientProjectNo",
        render: record => {
          return record.clientProjectNo;
        },
        ...getColumnFilter({
          inputName: "clientProjectNo",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="TenderingLog.projectScope" defaultMessage="Project Scope" />,
        key: "projectScope",
        render: record => {
          return record.projectScope;
        },
        ...getColumnFilter({
          inputName: "projectScope",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: "Projects",
        key: "id",
        render: record => {
          return Boolean(record.projectsTreeNames) && record.projectsTreeNames.length > 0 ? record.projectsTreeNames.join(", ") : "";
        }
      },
      {
        title: <FormattedMessage id="TenderingLog.projectDetails" defaultMessage="Project Details" />,
        key: "projectDetails",
        render: record => {
          return record.projectDetails;
        },
        ...getColumnFilter({
          inputName: "projectDetails",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="TenderingLog.tenderingDocuments" defaultMessage="Tendering Documents" />,
        key: "tenderingDocuments",
        render: record => {
          return record.tenderingDocuments;
        },
        ...getColumnFilter({
          inputName: "tenderingDocuments",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="TenderingLog.tenderingTypeId" defaultMessage="Tendering Type" />,
        key: "tenderingTypeId",
        render: record => {
          return record.tenderingTypeName;
        },
        ...getColumnFilter({
          inputName: "tenderingTypeName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="TenderingLog.biddingClosingDate" defaultMessage="Bidding Closing Date" />,
        key: "biddingClosingDate",
        render: record => {
          return record.biddingClosingDate;
        },
        ...getColumnFilter({
          inputName: "biddingClosingDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="TenderingLog.tenderingDate" defaultMessage="Tendering Date" />,
        key: "tenderingDate",
        render: record => {
          return record.tenderingDate;
        },
        ...getColumnFilter({
          inputName: "tenderingDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="TenderingLog.projectStartDate" defaultMessage="Project Start Date" />,
        key: "projectStartDate",
        render: record => {
          return record.projectStartDate;
        },
        ...getColumnFilter({
          inputName: "projectStartDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];
    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="TenderingLog" hideElement={true}>
            <ExcelSheet name="TenderingLog" data={this.state.tableList}>
              <ExcelColumn label="Tender Project No" value="tenderProjectNo" />
              <ExcelColumn label="Rev No" value="revNo" />
              <ExcelColumn label="Date" value="date" />
              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="Client" value="clientName" />
              <ExcelColumn label="Contractor" value="contractorName" />
              <ExcelColumn label="Project Description" value="projectDescription" />
              <ExcelColumn label="Client Project No" value="clientProjectNo" />
              <ExcelColumn label="Project Scope" value="projectScope" />
              <ExcelColumn label="Project Details" value="projectDetails" />
              <ExcelColumn label="Tendering Documents" value="tenderingDocuments" />
              <ExcelColumn label="Tendering Type" value="tenderingTypeName" />
              <ExcelColumn label="Bidding Closing Date" value="biddingClosingDate" />
              <ExcelColumn label="Tendering Date" value="tenderingDate" />
              <ExcelColumn label="Project Start Date" value="projectStartDate" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    return (
      <Badge.Ribbon
        text={<FormattedMessage id="tenderingLog.pageTitle" defaultMessage="Tendering Log" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={20}></Col> <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
              <Col md={1}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <Tooltip title={<FormattedMessage id="TenderingLog.Settings" defaultMessage="Settings" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.setState({ drawerSettingsShow: true });
                      }}
                      icon={<Settings color="error" fontSize="large" />}
                    ></AntButton>
                  </Tooltip>
                )}
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TenderingLog.TenderingType" defaultMessage="Tendering Type" />}
                  name="tenderingTypeId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["tenderingTypeIdSelectItems"]) &&
                      this.state["tenderingTypeIdSelectItems"].length > 0 &&
                      this.state["tenderingTypeIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }
              <Form.Item
                {...layout}
                label={<FormattedMessage id="TenderingLog.ProjectScope" defaultMessage="Project Scope" />}
                name="projectScope"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Radio.Group
                  onChange={e => {
                    this.setState({
                      projectScope: e.target.value
                    });
                  }}
                >
                  <Radio value={"Supply_Of_Products"}><FormattedMessage id="TenderingLog.SupplyOfProducts" defaultMessage="Supply of Products" /></Radio>
                  <Radio value={"Projects"}><FormattedMessage id="TenderingLog.Projects" defaultMessage="Projects" /></Radio>
                </Radio.Group>
              </Form.Item>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TenderingLog.projectDescription" defaultMessage="Project Description" />}
                  name="projectDescription"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TenderingLog.detailOfProject" defaultMessage="Detail Of Project" />}
                  name="detailOfProject"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              
              <Form.Item {...layout} label={<FormattedMessage id="TenderingLog.clientId" defaultMessage="Client" />}>
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name="clientId"
                    // rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    style={{ width: "90%" }}
                  >
                    <Select
                      allowClear
                      showSearch
                      onSearch={this.searchItem}
                      style={{ marginBottom: 0, width: "100%" }}
                      loading={this.state.loadingSearch}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {Boolean(this.state["clientIdSelectItems"]) &&
                        this.state["clientIdSelectItems"].length > 0 &&
                        this.state["clientIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <AntButton
                    type="default"
                    style={{
                      color: "black",
                      background: "white",
                      width: "10%"
                    }}
                    onClick={() => {
                      this.setState({ showItemReachSearchModal: true });
                    }}
                    icon={<Add />}
                  ></AntButton>
                </Space.Compact>
              </Form.Item>




              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TenderingLog.clientProjectNo" defaultMessage="Client Project No" />}
                  name="clientProjectNo"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TenderingLog.contractorId" defaultMessage="Contractor" />}
                  name="contractorId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("TenderingLogcontractorId")}
                          >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["TenderingLogcontractorIdSelectItems"]) &&
                      this.state["TenderingLogcontractorIdSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }


              <Form.Item {...layout} label={<FormattedMessage id="TenderingLog.customerId" defaultMessage="Contractor" />}>
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name="contractorId"
                    // rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    style={{ width: "90%" }}
                  >

                    <Select
                      allowClear
                      showSearch
                      onSearch={this.searchItem}
                      style={{ marginBottom: 0, width: "100%" }}
                      loading={this.state.loadingSearch}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={value => {
                        this.getPurchaseCriteria(value);
                      }}
                    >
                      {Boolean(this.state["clientIdSelectItems"]) &&
                        this.state["clientIdSelectItems"].length > 0 &&
                        this.state["clientIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <AntButton
                    type="default"
                    style={{
                      color: "black",
                      background: "white",
                      width: "10%"
                    }}
                    onClick={() => {
                      this.setState({ showItemReachSearchModal: true });
                    }}
                    icon={<Add />}
                  ></AntButton>
                </Space.Compact>
              </Form.Item>

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TenderingLog.projectLocationId" defaultMessage="Project Location" />}
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <>
                    {
                      <Form.Item
                        {...layout}
                        name="countryId"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          onChange={value => {
                            this.fillCities(value);
                          }}
                          placeholder={<FormattedMessage id="CompanyProject.Country" defaultMessage="Country" />}
                        >
                          {this.state.countrySelectItems.map(i => (
                            <Option value={i.key}>{i.value}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        name="cityId"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          placeholder={<FormattedMessage id="CustomerLog.cityId" defaultMessage="City" />}
                        >
                          {Boolean(this.state["cityIdSelectItems"]) &&
                            this.state["cityIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        style={{
                          marginBottom: 0
                        }}
                        name="address"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input placeholder="Address" style={{ width: "100%" }} />
                      </Form.Item>
                    }
                  </>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TenderingLog.issueDate" defaultMessage="Issue Date" />}
                  name="issueDate"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker                   
                   placeholder="Response Date"
                   format={"DD-MM-YYYY"}
                   style={{ width: "100%" }}
                   onChange={(value)=>{
                    this.setState({
                      issueDate: value
                    })
                   }}
                  />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TenderingLog.biddingClosingDate" defaultMessage="Bidding Closing Date" />}
                  name="biddingClosingDate"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker                  
                  disabledDate={this.disabledDate}
                  placeholder="Response Date"
                  format={"DD-MM-YYYY"}
                  style={{ width: "100%" }}/>
                  onChange={(value)=>{
                    this.setState({
                      biddingClosingDate: value
                    })                    
                  }}                  
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TenderingLog.tenderingDate" defaultMessage="Tendering Date" />}
                  name="tenderingDate"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker 
                  disabledDate={this.disabledDate1}
                  placeholder="Response Date"
                  format={"DD-MM-YYYY"}
                  style={{ width: "100%" }}/>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TenderingLog.Project Duration" defaultMessage="Project Duration" />}
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name="isProjectDurationDates"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        valuePropName="checked"
                        onChange={(e) => {
                          this.setState({ modalProjectDurationDatesShow: e.target.checked });
                        }}
                      >
                        <Checkbox>{<FormattedMessage id="TenderingLog.Dates" defaultMessage="Dates" />}</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="isProjectDurationPeriod"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        valuePropName="checked"
                        onChange={(e) => {
                          this.setState({ modalProjectDurationPeriodShow: e.target.checked });
                        }}
                      >
                        <Checkbox>{<FormattedMessage id="TenderingLog.Period" defaultMessage="Period" />}</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TenderingLog.ProjectFollowups" defaultMessage="Project Follow-ups" />}
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name="isProjectFollowUpsDate"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        valuePropName="checked"
                      >
                        <Checkbox>{<FormattedMessage id="TenderingLog.Date" defaultMessage="Date" />}</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="isProjectFollowUpsPeriod"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        valuePropName="checked"
                        onChange={(e) => {
                          this.setState({ modalProjectFollowUpsPeriodShow: e.target.checked });
                        }}
                      >
                        <Checkbox>{<FormattedMessage id="TenderingLog.Period" defaultMessage="Period" />}</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TenderingLog.contactPersonId" defaultMessage="Contact Person" />}
                  name="contactPersonId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("TenderingLogcontactPersonId")}
                          >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["TenderingLogcontactPersonIdSelectItems"]) &&
                      this.state["TenderingLogcontactPersonIdSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }

{/*
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TenderingLog.Projects" defaultMessage="Projects" />}
                  name="projectsTreeIds"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Tree
                    style={{ marginTop: "10px", lineHeight: "30px" }}
                    className="draggable-tree"
                    showLine={{ showLeafIcon: false }}
                    showIcon
                    checkStrictly={true}
                    loadedKeys={this.state.loadedKeys}
                    onLoad={this.onLoadData}
                    loadData={this.loadData}
                    onExpand={this.onExpand}
                    expandedKeys={this.state.expandedKeys}
                    autoExpandParent={false}
                    treeData={this.state.nodes}
                    blockNode={true}
                    checkable
                    onCheck={this.onCheck}
                    checkedKeys={this.state.checkedKeys}
                    halfCheckedKeys={this.state.halfCheckedKeys}
                  />
                </Form.Item>
              }
              */}

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>
          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>
          <Drawer
            title={<FormattedMessage id="TenderingLog.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerSettingsShow: false });
            }}
            open={this.state.drawerSettingsShow}
          >
            <PageSettings pageName={"TenderingLog"} />
          </Drawer>

          <DraggableModal
            title={<FormattedMessage id="TenderingLog.ProjectDocuments" defaultMessage="Project Documents" />}
            centered
            width={1200}
            open={this.state.modalProjectDocumentsShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalProjectDocumentsShow: false })}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<TenderingLogProjectDocuments tenderingLogId={this.state.id} random={Math.random()}
              close={() => this.setState({ modalProjectDocumentsShow: false })} />}
          />

          <DraggableModal
            title={<FormattedMessage id="TenderingLog.AddProduct" defaultMessage="Add Product" />}
            centered
            width={800}
            open={this.state.modalAddProductShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalAddProductShow: false })}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<TenderingLogAddProduct tenderingLogId={this.state.id} random={this.state.random}
              close={() => this.setState({ modalAddProductShow: false })} />}
          />

          <DraggableModal
            title={<FormattedMessage id="TenderingLog.Dates" defaultMessage="Dates" />}
            centered
            width={600}
            open={this.state.modalProjectDurationDatesShow}
            onOk={() => this.formRefProjectDurationDates.current.submit()}
            onCancel={() => this.setState({ modalProjectDurationDatesShow: false })}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveProjectDurationDates}
                onFinishFailed={onFinishFailed}
                ref={this.formRefProjectDurationDates}
              >
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="TenderingLog.firstDate" defaultMessage="Start Date" />}
                    name="startDate"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <DatePicker                    
                    placeholder="Start Date"
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}/>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="TenderingLog.firstDate" defaultMessage="End Date" />}
                    name="endDate"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                  </Form.Item>
                }
              </Form>
            }
          />
          <DraggableModal
            title={<FormattedMessage id="TenderingLog.Period" defaultMessage="Period" />}
            centered
            width={600}
            open={this.state.modalProjectDurationPeriodShow}
            onOk={() => this.formRefProjectDurationPeriod.current.submit()}
            onCancel={() => this.setState({ modalProjectDurationPeriodShow: false })}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveProjectDurationPeriod}
                onFinishFailed={onFinishFailed}
                ref={this.formRefProjectDurationPeriod}
              >
                <Space.Compact style={{ width: "80%" }}>
                  {
                    <Form.Item
                      name="projectDurationPeriodNumber"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      style={{ width: "20%" }}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      name="projectDurationPeriodDateId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      style={{ width: "40%" }}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["projectDurationPeriodDateIdSelectItems"]) &&
                          this.state["projectDurationPeriodDateIdSelectItems"].length > 0 &&
                          this.state["projectDurationPeriodDateIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                </Space.Compact>
              </Form>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="TenderingLog.Period" defaultMessage="Period" />}
            centered
            width={600}
            open={this.state.modalProjectFollowUpsPeriodShow}
            onOk={() => this.formRefProjectFollowUpsPeriodShow.current.submit()}
            onCancel={() => this.setState({ modalProjectFollowUpsPeriodShow: false })}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveProjectFollowUpsPeriod}
                onFinishFailed={onFinishFailed}
                ref={this.formRefProjectFollowUpsPeriodShow}
              >
                <Form.Item
                  name="isProjectFollowUpsPeriodWeek"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                  onChange={(e) => {
                    this.setState({ modalProjectFollowUpsPeriodShow: e.target.checked });
                  }}
                >
                  <Checkbox>{<FormattedMessage id="TenderingLog.Week" defaultMessage="Week" />}</Checkbox>
                </Form.Item>

                <Form.Item
                  name="isProjectFollowUpsPeriodMonth"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                  onChange={(e) => {
                    this.setState({ modalProjectFollowUpsPeriodShow: e.target.checked });
                  }}
                >
                  <Checkbox>{<FormattedMessage id="TenderingLog.Month" defaultMessage="Month" />}</Checkbox>
                </Form.Item>
              </Form>
            }
          />

          <DraggableModal
            title={
              <span>
                <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  <FormattedMessage id="TenderingLog.Decline" defaultMessage="Decline" />
                </span>
              </span>
            }
            centered
            open={this.state.modalDeclineShow}
            onOk={() => this.getTenderingDecline()}
            onCancel={() => this.setState({ modalDeclineShow: false })}
            okText={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />}
            cancelText={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
            content={
              <>
                <FormattedMessage
                  id="TenderingLog.MessageAreYouSureDecline"
                  defaultMessage="Are you sure decline ?"
                />
              </>
            }
          />

          <DraggableModal
            title={
              <span>
                <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  <FormattedMessage id="TenderingLog.Decline" defaultMessage="Decline" />
                </span>
              </span>
            }
            centered
            open={this.state.modalDeclineOkShow}
            onOk={() => {
              this.formRefDeclineOk.current.submit();
            }}
            onCancel={() => this.setState({ modalDeclineOkShow: false })}
            content={
              <Form initialValues={{ remember: false }} onFinish={this.saveTenderingDecline} ref={this.formRefDeclineOk}>
                <Form.Item
                  {...layout2}
                  label={<FormattedMessage id="TenderingLog.DeclineDescription" defaultMessage="The reason(s) of decline" />}
                  name="tenderingDecline"

                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </Form>
            }
          />
        </div>
      </Badge.Ribbon >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TenderingLog);
