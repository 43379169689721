import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import {
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import { fillFilterOperations } from "../../Components/DataTableFilterComponent";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditOutlined } from "@material-ui/icons";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { inputTypeSelectItems } from "../../Components/helpers/ComponentProperties";
import {
  Form,
  Input,
  Button as AntButton,
  Col,
  Row, Card, Modal as AntModal,
  Checkbox,
  InputNumber
} from "antd";
import { handleRequest } from "../../ApiConnector";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";
import PageAutoDesignFormInput from "./PageAutoDesignFormInput";

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node)) {
      var lineage = [];
      if (Boolean(node.lineage) && node.lineage.length > 0) {
        node.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.value,
        lineage: node.lineage,
        title: node.value
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class PageAutoDesignForm extends Component {
  constructor() {
    super();
    this.state = {
      formName: "PageAutoDesignForm",
      controllerName: "pageAutoDesignForm",
      modalDeleteShow: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      selectedRow: null,
      tableList: [],
      filePath: "",
      fileList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      textFormattingDetailId: null,
      excelData: [],
      searchCriteriaModels: [],
      nodes: [],
      inputTypeSelectItems: null
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    this.getPageAutoDesignForm();
    this.fillComboboxes();
    fillFilterOperations();
  };

  getPageAutoDesignForm = async () => {
    let pageAutoDesignId = Boolean(this.props.pageAutoDesignId) ? this.props.pageAutoDesignId : -1;
    let pageAutoDesignFormInputId = Boolean(this.props.pageAutoDesignFormInputId) ? this.props.pageAutoDesignFormInputId : -1;
    var response = await handleRequest(
      "GET",
      "/api/" + this.state.controllerName + "/getPageAutoDesignForm/" + pageAutoDesignId + "/" + pageAutoDesignFormInputId
    );
    if (response.type === "ERROR") {
      error(response);
    }
    if (Boolean(response.data)) {
      pageAutoDesignId = response.data.pageAutoDesignId;
      this.formRef.current.setFieldsValue({
        ...response.data
      });
    } else {
      this.formRef.current.resetFields();
    }
    this.setState({
      pageAutoDesignId: pageAutoDesignId
    });
  };

  fillComboboxes = async () => {
    this.setState({
      inputTypeSelectItems: inputTypeSelectItems
    });
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showInputFormModal = row => {
    this.setState({
      modalFormatShow: true,
      id: row.id
    });
  };

  showInputModal = row => {
    this.setState({
      modalInputShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  save = async values => {
    if (Boolean(this.state.pageAutoDesignId) && this.state.pageAutoDesignId > 0) {
      const newItem = {
        ...values,
        id: this.state.id
      };
      newItem.pageAutoDesignId = this.state.pageAutoDesignId;

      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/save", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.fillComboboxes();
        this.cancel();
        showSuccess();
        this.props.closeFormModal();
      }
    } else {
      showError("Page Auto Design not found");
    }
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let inputs = [];
    let a = inputTypeSelectItems.find(x => x.key === row.inputType).inputs;
    if (row.inputs) {
      inputs = [...a];
      let rowProps = JSON.parse(row.inputs);
      for (let i = 0; i < inputs.length; i++) {
        let p = rowProps.find(x => x.key === inputs[i].key);
        if (p) {
          inputs[i][inputs[i].key] = p[inputs[i].key];
        }
      }
    }
    this.formRef.current.setFieldsValue({
      ...row,
      inputs: inputs
    });
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      selectedRow: row
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.fillComboboxes();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedRow: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState({
      searchCriteriaModels: searchCriteriaModels,
      pagination: {
        ...this.state.pagination,
        current: 1
      }
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 }
    };

    const layout1 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };

    return (
      <div className="card card-custom" style={{ padding: "3rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>

          <Row gutter={16} style={{ marginBottom: "16px" }}>

            <br></br>
            <br></br>
            {/* Sol Card */}

            <Col span={14}>
              <Card
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                  height: "100%"
                }}
              >
                <Col span={1}>
                  <Form.Item>
                    <Row gutter={[20, 20]}>
                      <Col md={1}></Col>
                      <Col md={10}>

                        <AntButton >
                          <FormattedMessage id="PageAutoDesignForm.NoAccess" defaultMessage="No Access" />
                        </AntButton>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Form.Item>
                    <Row gutter={[20, 20]}>
                      <Col md={1}></Col>
                      <Col md={10}>

                        <AntButton >
                          <FormattedMessage id="PageAutoDesignForm.Standard" defaultMessage="Standard" />
                        </AntButton>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="PageAutoDesignForm.formName" defaultMessage="Form Name" />}
                    name="formName"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input placeholder="FormName, no space" style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Card>
            </Col>

            <Col span={11}>
              <Card
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                  height: "100%"
                }}
              >
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="PageAutoDesignForm.formName" defaultMessage="Form Name" />}
                    name="formName"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input placeholder="FormName, no space" style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="PageAutoDesignForm.ribbonName" defaultMessage="Ribbon Name" />}
                    name="ribbonName"
                    placeholder="RibbonName"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input placeholder="RibbonName, no space" style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="PageAutoDesignForm.controllerName" defaultMessage="Controller Name" />}
                    name="controllerName"
                    placeholder="controllerName"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input placeholder="controllerName, no space" style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="PageAutoDesignForm.controllerName" defaultMessage="Base Entity" />}
                    name="baseEntity"
                    extra={
                      <FormattedMessage id="PageAutoDesignForm.CheckifitwillconsistofBaseEntity" defaultMessage="Check if it will consist of BaseEntity" />
                    }
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox></Checkbox>
                  </Form.Item>
                }

                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="PageAutoDesignForm.controllerName" defaultMessage="Opening Modal" />}
                    name="isModal"
                    extra={<FormattedMessage id="PageAutoDesignForm.CheckifitwillopeningModal" defaultMessage="Check if it will opening Modal" />}
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox></Checkbox>
                  </Form.Item>
                }

              </Card>
            </Col>

            {/* Orta Card */}
            <Col span={13}>
              <Card
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                  height: "100%"
                }}
              >
                {
                  <Form.List name="inputs">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (

                          <Form.Item
                            {...field}
                            {...layout1}
                            label={index + 1}
                            rules={[
                              {
                                required: false,
                                message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                              }
                            ]}
                          >
                            <tr key={field.key}>
                              <td>
                                <Form.Item
                                  {...field}
                                  style={{
                                    display: "inline-block",
                                    width: '100%'
                                  }}
                                  name={[field.name, "inputName"]}
                                  tooltip={<FormattedMessage id="PageAutoDesignForm.message.w" defaultMessage="Backend entity input name (Please write without spaces)" />}
                                /*extra={
                                    <FormattedMessage
                                      id="PageAutoDesignForm.BackendEntityInputNamePleaseWriteWithoutSpaces"
                                      defaultMessage="Backend entity input name (Please write without spaces)"
                                    />
                                  } */
                                >
                                  <Input></Input>
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item {...field} hidden name={[field.name, "id"]}>
                                  <InputNumber></InputNumber>
                                </Form.Item>
                              </td>
                              <td>
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                              </td>
                              <td>
                                <Form.Item>
                                  <Row gutter={[20, 20]}>
                                    <Col md={1}></Col>
                                    <Col md={20}>
                                      <Button
                                        onClick={() => {
                                          this.setState({
                                            showInputFormModal: true,
                                          });
                                        }}
                                        style={{ width: "100%" }}
                                        variant="secondary"
                                      >
                                        {<FormattedMessage id="PageAutoDesignForm.Format" defaultMessage="Format" />}
                                      </Button>

                                    </Col>
                                  </Row>
                                </Form.Item>
                              </td>
                            </tr>
                          </Form.Item>
                        ))}
                        <Col span={1}>
                          <Form.Item>
                            <Row gutter={[20, 20]}>
                              <Col md={1}></Col>
                              <Col md={10}>

                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id="PageAutoDesignForm.AddInput" defaultMessage="Add Input" />
                                </AntButton>
                              </Col>
                            </Row>
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Form.List>
                }
              </Card>
            </Col>
            <Col span={1}></Col>
          </Row>
          {
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Col>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            </Row>
          }
        </Form>

        <AntModal
          centered
          width={900}
          title={<FormattedMessage id="PageAutoDesign.Format" defaultMessage="Format" />}
          open={this.state.showInputFormModal}
          onCancel={() => {
            this.setState({ showInputFormModal: false });
          }}
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  showInputInputFormModal: false
                });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
        >
          {
            <PageAutoDesignFormInput
              pageAutoDesignFormInputId={this.state.pageAutoDesignId}
              random={Math.random()}
              closeInputFormModal={this.closeInputFormModal}
            ></PageAutoDesignFormInput>
          }
        </AntModal>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageAutoDesignForm);
