import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { FilterOutlined, ExclamationCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { Form, Table, Badge, Select, InputNumber, DatePicker, Image, Col, Row, Modal, Space, Tooltip, Checkbox } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";

const { Option } = Select;

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "effectiveDate" || dataIndex === "paymentDate"
      ? form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY") : ""
      })
      : form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
      });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "effectiveDate") {
        const values = await form.validateFields();
        values.effectiveDate = Boolean(values.effectiveDate) ? moment(values.effectiveDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "paymentDate") {
        const values = await form.validateFields();
        values.paymentDate = Boolean(values.paymentDate) ? moment(values.paymentDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) { }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
      >
        {dataIndex === "effectiveDate" || dataIndex === "paymentDate" ? (
          <DatePicker ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY"} />
        ) : dataIndex === "currency" ? (
          <Select ref={inputRef} onChange={toggleSave} onBlur={toggleSave} showSearch optionFilterProp="children" >
            {Boolean(record.currencySelectItems) &&
              record.currencySelectItems.length > 0 &&
              record.currencySelectItems.map(i => (
                <Option key={i.key} value={i.key}>
                  {i.value}
                </Option>
              ))}
          </Select>
        ) : (
          <InputNumber min={0} ref={inputRef} onPressEnter={toggleSave} onBlur={toggleSave} />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

export class PriceList extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "priceList",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [],
      searchCriteriaModels: [],
      applyAllEffectiveDate: null,
      applyToAll: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },

      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    fillFilterOperations();
  };

  fillComboboxes = async () => {
    var responseStockCardId = await handleRequest("GET", "/api/" + this.state.controllerName + "/stockCardId");
    if (responseStockCardId.type === "ERROR") {
      error(responseStockCardId);
    }

    var responseCurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responseCurrency.type === "ERROR") {
      error(responseCurrency);
    }

    var responseEffectiveDates = await handleRequest("GET", "/api/" + this.state.controllerName + "/effectiveDates");
    if (responseEffectiveDates.type === "ERROR") {
      error(responseEffectiveDates);
    }

    this.setState({
      currencySelectItems: Boolean(responseCurrency.data) ? responseCurrency.data : [],
      stockCardIdSelectItems: Boolean(responseStockCardId.data) ? responseStockCardId.data : [],
      effectiveSelectItems: Boolean(responseEffectiveDates.data) ? responseEffectiveDates.data : [],
    }, () => {
      const { pagination } = this.state;
      this.restartTable({ pagination });
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedRow: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },

      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false,
        selectedRow: null
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.key = index + 1;
          element.currencySelectItems = this.state.currencySelectItems;
          if (Boolean(element.effectiveDate)) {
            element.effectiveDate = moment(element.effectiveDate).format("DD-MM-YYYY");
          }
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async () => {
    const { tableList } = this.state;
    if (Boolean(tableList) && tableList.length > 0) {
      tableList.forEach(element => {
        if (Boolean(element.effectiveDate)) {
          element.effectiveDate = moment(element.effectiveDate, "DD-MM-YYYY");
        }
      });
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAllPrice", tableList);

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showError("Not found.")
    }
  };

  discountModal = async () => {

  }

  effectiveDatesModal = async () => {
    this.setState({
      modalEffectiveDatesShow: true,
    });
  }

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      effectiveDate: Boolean(this.state.effectiveDate) ? this.state.effectiveDate : null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  handleSellingPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.sellingPrice === value.sellingPrice) return;
        item.oldSellingPrice = item.sellingPrice;
        item.sellingPrice = value.sellingPrice;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleCurrency = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.currency === value.currency) return;
        item.currency = value.currency;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleEffectiveDate = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.effectiveDate === value.effectiveDate) return;
        item.effectiveDate = value.effectiveDate;
        this.setState({
          tableList: newData,
          modalApplyToAllShow: true,
          applyAllEffectiveDate: value.effectiveDate,
        });
      }
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      },
      header: {
        cell: ResizableTitle
      }
    };

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="PriceList.Item" defaultMessage="Item" />,
        key: "id",
        width: 100,
        ellipsis: true,
        render: record => {
          return record.itemName;
        },
        ...getColumnFilter({
          inputName: "stockCardId", inputType: "SELECT",
          selectItems: Boolean(this.state["stockCardIdSelectItems"]) ? this.state["stockCardIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="PriceList.imageUrl" defaultMessage="Photo" />,
        key: "imageUrl",
        width: 50,
        render: record => {
          return Boolean(record.imageUrl) && <Image src={record.imageUrl} />;
        }
      },
      {
        title: <FormattedMessage id="PriceList.SellingPrice" defaultMessage="Selling Price" />,
        key: "sellingPrice",
        width: 100,
        render: record => {
          return record.sellingPrice;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "sellingPrice",
          handleSave: this.handleSellingPrice
        }),
      },

      {
        title: <FormattedMessage id="PriceList.Currency" defaultMessage="Currency" />,
        key: "currency",
        width: 100,
        render: record => {
          return record.currency;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "currency",
          handleSave: this.handleCurrency
        }),
      },
      {
        title: <FormattedMessage id="PriceList.EffectiveDate" defaultMessage="Effective Date" />,
        key: "effectiveDate",
        width: 100,
        render: record => {
          return record.effectiveDate;
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "effectiveDate",
          handleSave: this.handleEffectiveDate
        }),
      },
    ];

    const handleResizeColumns = index => (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumns(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResizeColumns(index)
          })
        }));

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage="Price List" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "4rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={15}></Col>
              <Col md={3}>
                <Button style={{ width: "100%" }} variant="success" onClick={this.save}>
                  <FormattedMessage id="PriceList.Save" defaultMessage="Save" />
                </Button>
              </Col>
              <Col md={3}>
                <Button style={{ width: "100%" }} variant="warning" onClick={this.discountModal}>
                  <FormattedMessage id="PriceList.Discount" defaultMessage="Discount" />
                </Button>
              </Col>
              <Col md={3}>
                <Button style={{ width: "100%" }} variant="danger" onClick={this.effectiveDatesModal}>
                  <FormattedMessage id="PriceList.EffectiveDates" defaultMessage="Effective Dates" />
                </Button>
              </Col>
            </Row>
          </Form>
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
              <div className="alert alert-custom alert-light">
                <div className="alert-icon">
                  <FilterOutlined />
                </div>
                <div className="alert-text">
                  {this.state.searchCriteriaModels.map((val, i) => {
                    return (
                      <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                        <span className="mr-2">{val.label}</span>
                        <strong className="mr-2">{val.type}</strong>
                        <span className="mr-2">{val.operationValue}</span>
                        <span className="mr-2">{val.showValue}</span>
                        <span>
                          <Tooltip title="Remove">
                            <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                          </Tooltip>
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="alert-close">
                  <Tooltip title="Remove All">
                    <CloseCircleOutlined
                      style={{ color: "crimson" }}
                      onClick={() =>
                        this.setState({ searchCriteriaModels: [] }, () => {
                          this.filterChangeItem([]);
                        })
                      }
                    />
                  </Tooltip>
                </div>
              </div>
            )}

            <Table
              bordered
              rowKey="key"
              scroll={{ x: 1200, y: 900, scrollToFirstRowOnChange: true }}
              components={components}
              rowClassName={() => "editable-row"}
              columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.modalDeleteShow}
            onCancel={() => {
              this.setState({ modalDeleteShow: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalDeleteShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.delete}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal>


          <Modal
            title={<FormattedMessage id="PriceList.EffectiveDates" defaultMessage="Effective Dates" />}
            centered
            width={500}
            open={this.state.modalEffectiveDatesShow}
            onCancel={() => {
              this.setState({
                modalEffectiveDatesShow: false, effectiveDate: null
              }, () => {
                const { pagination } = this.state;
                this.restartTable({ pagination });
              });
            }}
            onOk={() => {
              this.setState({
                modalEffectiveDatesShow: false
              }, () => {
                const { pagination } = this.state;
                this.restartTable({ pagination });
              });
            }}
          >
            <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}
              value={this.state.effectiveDate}
              onChange={value => {
                this.setState({
                  effectiveDate: value
                });
              }}>
              {Boolean(this.state["effectiveSelectItems"]) &&
                this.state["effectiveSelectItems"].length > 0 &&
                this.state["effectiveSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {moment(i.key).format("DD-MM-YYYY")}
                  </Option>
                ))}
            </Select>
          </Modal>

          <Modal
            title={
              <span>
                <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  {this.state.applyAllEffectiveDate}
                </span>
              </span>
            }
            centered
            width={500}
            open={this.state.modalApplyToAllShow}
            onOk={() => {
              const { tableList, applyToAll } = this.state;
              if (applyToAll) {
                if (Boolean(tableList) && tableList.length > 0) {
                  tableList.forEach(element => {
                    element.effectiveDate = this.state.applyAllEffectiveDate
                  });
                }
                this.setState({
                  tableList: tableList,
                  modalApplyToAllShow: false
                });
              } else {
                showWarning("Please select Apply To All");
              }
            }}
            onCancel={() => this.setState({ modalApplyToAllShow: false })}
            okText={<FormattedMessage id="GeneralButtonOK" defaultMessage="OK" />}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          >
            <Checkbox value={this.state.applyToAll} onChange={e => {
              this.setState({
                applyToAll: e.target.checked
              });
            }}>
              <FormattedMessage id="PriceList.ApplyToAll" defaultMessage="Apply To All" />
            </Checkbox>
          </Modal>
        </div>
      </Badge.Ribbon >
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PriceList);
