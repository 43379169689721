import "antd/dist/antd.css";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Tooltip, Drawer, Radio, Cascader, Tag, Space } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { Add, Description, EditOutlined, FilterList, Send, Settings } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import DraggableModal from "../../../Components/DraggableModal";
import { Select } from "antd";
import { InputNumber, Card } from "antd";
import { Input } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import BillOfQuantitySendRFQ from "./BillOfQuantitySendRFQ";
import BillOfQuantityAddPrice from "./BillOfQuantityAddPrice";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import BillOfQuantityVendor from "./BillOfQuantityVendor";
import BillOfQuantityVendorResponse from "./BillOfQuantityVendorResponse";
import ExcelReader from "../../excel/ExcelReader";
import BillOfQuantityAddProduct from "./BillOfQuantityAddProduct";
import EldenCompoundsSettingsProductTreeSelectableCompanyEditable from "../../elden/equipment/EldenCompoundsSettingsProductTreeSelectableCompanyEditable";
import BillOfQuantityAddNewItem from "./BillOfQuantityAddNewItem";
import BillOfQuantityNativeForm from "./BillOfQuantityNativeForm";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class BillOfQuantity extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BillOfQuantity",
      controllerName: "billOfQuantity",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      productTreeAllIds: [],
      moveDay: 0,
      continueMatching: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefCreateNew = React.createRef();
  formRefItem = React.createRef();
  formRefNewSupplier = React.createRef();
  formRefNewManufacturer = React.createRef();
  formRefNewBrand = React.createRef();
  formRefMove = React.createRef();
  formRefStatistics = React.createRef();
  formRefSerialNumber = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  }

  fillComboboxes = async () => {
    this.fillSupplier();
    this.fillManufacturer();
    var responseitemGroupId = await handleRequest("GET", "/api/" + this.state.controllerName + "/category");
    if (responseitemGroupId.type === "ERROR") {
      error(responseitemGroupId);
    } else {
      this.setState({
        itemGroupIdSelectItems: Boolean(responseitemGroupId.data) ? responseitemGroupId.data : []
      });
    }

    var responsecurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responsecurrency.type === "ERROR") {
      error(responsecurrency);
    } else {
      this.setState({
        currencySelectItems: Boolean(responsecurrency.data) ? responsecurrency.data : []
      });
    }

    var responseContinueMatching = await handleRequest("GET", "/api/" + this.state.controllerName + "/continueMatching/" + this.props.tenderingLogId);
    if (responseContinueMatching.type === "ERROR") {
      error(responseContinueMatching);
    } else {
      this.setState({
        continueMatching: Boolean(responseContinueMatching.data) ? responseContinueMatching.data : false
      });
    }
  };

  fillSupplier = async () => {
    var responsesupplierId = await handleRequest("GET", "/api/" + this.state.controllerName + "/supplier");
    if (responsesupplierId.type === "ERROR") {
      error(responsesupplierId);
    }
    this.setState({
      supplierIdSelectItems: Boolean(responsesupplierId.data) ? responsesupplierId.data : []
    });
  }

  fillManufacturer = async () => {
    var responsemanufacturerId = await handleRequest("GET", "/api/" + this.state.controllerName + "/manufacturer");
    if (responsemanufacturerId.type === "ERROR") {
      error(responsemanufacturerId);
    }
    this.setState({
      manufacturerIdSelectItems: Boolean(responsemanufacturerId.data) ? responsemanufacturerId.data : []
    });
  }

  onChangeItemGroupId = async value => {
    if (Boolean(value)) {
      var responseitemDescriptionId = await handleRequest("GET", "/api/" + this.state.controllerName + "/description/" + value);
      if (responseitemDescriptionId.type === "ERROR") {
        error(responseitemDescriptionId);
      } else {
        this.setState({
          itemDescriptionIdSelectItems: Boolean(responseitemDescriptionId.data) ? responseitemDescriptionId.data : []
        });
      }
    } else {
      this.setState({
        itemDescriptionIdSelectItems: []
      });
    }
  };

  fillMaterialType = async () => {
    const { itemGroupId, itemDescriptionId } = this.state;
    if (Boolean(itemGroupId) && itemGroupId > 0 && Boolean(itemDescriptionId) && itemDescriptionId > 0) {
      const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree/" + itemGroupId + "/" + itemDescriptionId);
      if (responseTree.data.length !== 0) {
        generateList(responseTree.data);
        this.setState({
          nodes: responseTree.data
        });
      }
    } else {
      this.setState({
        nodes: []
      });
    }
  };

  getPurchaseCriteria = async value => {
    if (Boolean(value)) {
      let responsePurchaseCriteria = await handleRequest("GET", "/api/" + this.state.controllerName + "/purchasingcriterias/" + value);
      if (responsePurchaseCriteria.type === "ERROR") {
      } else {
        this.setState({
          purchasingcriteriaSelectItems: Boolean(responsePurchaseCriteria.data) && responsePurchaseCriteria.data.length > 0 ? responsePurchaseCriteria.data : []
        });
      }
    } else {
      this.setState({
        purchasingcriteriaSelectItems: []
      });
    }
  };

  onChangeItemGroupIdforNameCode = async () => {
    const { type, itemGroupId, materialTypeId, itemDescriptionId } = this.state;
    let obj = {
      type: Boolean(type) ? type : null,
      descriptionId: Boolean(itemDescriptionId) ? itemDescriptionId : null,
      categoryId: Boolean(itemGroupId) ? itemGroupId : null,
      materialTypeId: Boolean(materialTypeId) && materialTypeId.length > 0 ? materialTypeId[materialTypeId.length - 1] : null,
    };

    var responseCodeSelectItems = await handleRequest("POST", "/api/" + this.state.controllerName + "/nameCode", obj);
    if (responseCodeSelectItems.type === "ERROR") {
      error(responseCodeSelectItems);
    } else {
      this.setState({
        itemIdSelectItems: Boolean(responseCodeSelectItems.data) ? responseCodeSelectItems.data : []
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);

    let url = "/api/" + this.state.controllerName + "/paging";

    const response = await handleRequest("POST", url, newObj);

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      moveDay: this.state.moveDay,
      tenderingLogId: this.props.tenderingLogId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    this.setState({
      showCreateNewModal: true,
      random: Math.random(),
      selectedRow: row,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  sendRFQModal = row => {
    let itemIds = [];
    if (Boolean(row) && Boolean(row.itemId) && row.itemId > 0) {
      itemIds.push(row.itemId);
    }
    if (Boolean(itemIds) && itemIds.length > 0) {
      this.setState({
        modalVendorsShow: true,
        itemIds: itemIds,
        billOfQuantityIds: [row.id]
      });
    } else {
      showWarning("Item not found, please match the item first.");
    }
  };

  serialNumberModal = row => {
    if (Boolean(row.serialNumber) && row.serialNumber > 0) {
      this.setState({
        modalSerialNumberShow: true,
        serialNumber: row.serialNumber,
      });
    } else {
      showWarning("Serial number not found.");
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let sendRFQAction = {
      name: "Send RFQ",
      icon: <Send fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.sendRFQModal.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };
    let serialNumberAction = {
      name: "Serial Number/Item",
      icon: <Description fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.serialNumberModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    if (Boolean(record.status) && record.status === "Created" && Boolean(record.itemId) && record.itemId > 0) {
      actionList.push(sendRFQAction);
    }
    if (Boolean(record.serialNumber) && record.serialNumber > 0) {
      actionList.push(serialNumberAction);
    }
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    if (Boolean(this.formRefCreateNew) && Boolean(this.formRefCreateNew.current)) {
      this.formRefCreateNew.current.resetFields();
    }
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      showCreateNewModal: false,
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    if (Boolean(this.props.tenderingLogId) && this.props.tenderingLogId > 0) {
      const newItem = {
        ...values,
        id: this.state.id,
        unitId: Boolean(values.unitId) && values.unitId.length > 0 ? values.unitId[values.unitId.length - 1] : null,
        tenderingLogId: this.props.tenderingLogId,
      };

      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
      }

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showWarning("Bidding Log not found.");
    }
  };

  saveItem = async values => {
    console.log("Values", values);
    this.setState(
      {
        showItemReachSearchModal: false
      },
      () => {
        this.formRefCreateNew.current.setFieldsValue({
          itemId: values.code
        });
      }
    );
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcelNativeForm = async excelJson => {
    try {
      if (Boolean(this.props.tenderingLogId) && this.props.tenderingLogId > 0) {
        var list = [];
        if (excelJson === null) showError("Select an excel file");
        else {
          excelJson.forEach(element => {
            const obj = {
              ...element,
              tenderingLogId: this.props.tenderingLogId,
              nativeExcel: true,
            };
            list.push(obj);
          });
          if (Boolean(list) && list.length > 0) {
            var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/billOfQuantityImport", list);
            if (response.type === "ERROR") {
              showError("No records to be added");
            } else {
              console.log("response", response);
              showWarning(response.data);
              this.resetInputs();
              const { pagination } = this.state;
              this.restartTable({ pagination });
              this.setState({ showExcelModal: false });
            }
          } else {
            showError("No records to be added");
          }
        }
      } else {
        showWarning("Bidding Log not found.");
      }
    } catch (error) {
      showError("");
    }
  };

  handleUploadExcelModified = async excelJson => {
    try {
      if (Boolean(this.props.tenderingLogId) && this.props.tenderingLogId > 0) {
        var list = [];
        if (excelJson === null) showError("Select an excel file");
        else {
          excelJson.forEach(element => {
            const obj = {
              ...element,
              tenderingLogId: this.props.tenderingLogId,
              nativeExcel: false,
            };
            list.push(obj);
          });
          if (Boolean(list) && list.length > 0) {
            var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/billOfQuantityImport", list);
            if (response.type === "ERROR") {
              showError("No records to be added");
            } else {
              console.log("response", response);
              showWarning(response.data);
              this.resetInputs();
              const { pagination } = this.state;
              this.restartTable({ pagination });
              this.setState({ showExcelModal: false });
            }
          } else {
            showError("No records to be added");
          }
        }
      } else {
        showWarning("Bidding Log not found.");
      }
    } catch (error) {
      showError("");
    }
  };

  handleUploadProcurementExcel = async excelJson => {
    try {
      if (Boolean(this.props.tenderingLogId) && this.props.tenderingLogId > 0) {
        var list = [];
        if (excelJson === null) showError("Select an excel file");
        else {
          excelJson.forEach(element => {
            console.log("element", element);
            const obj = {
              ...element,
              tenderingLogId: this.props.tenderingLogId,
            };
            list.push(obj);
          });

          console.log("list", list);
          if (Boolean(list) && list.length > 0) {
            var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/billOfQuantityProcurementExcel", list);
            if (response.type === "ERROR") {
              showError("No records to be added");
            } else {
              console.log("response", response);
              showWarning(response.data);
              this.resetInputs();
              const { pagination } = this.state;
              this.restartTable({ pagination });
              this.setState({ showExcelModal: false });
            }
          } else {
            showError("No records to be added");
          }
        }
      } else {
        showWarning("Bidding Log not found.");
      }
    } catch (error) {
      showError("");
    }
  };

  searchItem = async item => {
    console.log("item", item);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemSearch/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          searchItemIdSelectItems: [],
          loadingSearch: false
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            searchItemIdSelectItems: response.data,
            loadingSearch: false
          });
        } else {
          this.setState({
            searchItemIdSelectItems: [],
            loadingSearch: false
          });
        }
      }
    } else {
      this.setState({
        loadingSearch: false
      });
    }
  };

  handleFormNewSupplierSubmit = async values => {
    const newItem = {
      ...values,
      supplier: true,
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createVendor", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showNewSupplierModal: false
      });
      showSuccess();
      this.fillSupplier();
    }
  };

  handleFormNewManufacturerSubmit = async values => {
    const newItem = {
      ...values,
      manufacturer: true,
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createVendor", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showNewManufacturerModal: false
      });
      showSuccess();
      this.fillManufacturer();
    }
  };

  updateSelectedVendorRowKeys = (selectedVendorRowKeys, billOfQuantityIds) => {
    this.setState({
      selectedVendorRowKeys: selectedVendorRowKeys,
      billOfQuantityIds: billOfQuantityIds
    });
  };

  updateSendToVendors = async () => {
    const { selectedVendorRowKeys, billOfQuantityIds } = this.state;
    let vendorList = [];
    if (Boolean(selectedVendorRowKeys) && selectedVendorRowKeys.length > 0) {
      if (Boolean(billOfQuantityIds) && billOfQuantityIds.length > 0) {
        selectedVendorRowKeys.forEach(element => {
          if (Boolean(element) && element > 0) {
            vendorList.push({ "vendorId": element });
          }
        });

        var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateSendToVendors/" + billOfQuantityIds, vendorList);
        if (response.type === "ERROR") {
          error(response);
        } else {
          this.setState({
            modalVendorsShow: false,
          });
          const { pagination } = this.state;
          this.restartTable({ pagination });
        }
      } else {
        showWarning("Bill Of Quantity not found.");
      }
    } else {
      showWarning("Please select vendor.");
    }
  };

  saveMove = async values => {
    if (Boolean(values.moveDay)) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/saveMove/" + this.props.tenderingLogId + "/" + values.moveDay);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          moveDay: values.moveDay,
          showMoveModal: false,
        }, () => {
          const { pagination } = this.state;
          this.restartTable({ pagination });
        });
      }
    } else {
      this.setState({
        moveDay: 0,
        showMoveModal: false,
      }, () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      });
    }
  }

  saveSerialNumber = async values => {
    if (Boolean(values.itemId) && values.itemId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/saveSerialNumber/" + this.props.tenderingLogId + "/" + values.itemId + "/" + this.state.serialNumber);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          modalSerialNumberShow: false,
          searchItemIdSelectItems: [],
        }, () => {
          const { pagination } = this.state;
          this.restartTable({ pagination });
        });
      }
    } else {
      showWarning("Please select item.");
    }
  }

  onTargetKeysCheckCompanyAddProduct = async (targetKeys, halfCheckedKeys) => {
    console.log("targetKeys", targetKeys);

    let productTreeAllIds = [];
    if (Boolean(targetKeys) && targetKeys.length > 0) {
      targetKeys.forEach(element => {
        productTreeAllIds.push(element);
      });
    }
    if (Boolean(halfCheckedKeys) && halfCheckedKeys.length > 0) {
      halfCheckedKeys.forEach(element => {
        productTreeAllIds.push(element);
      });
    }

    this.setState({
      targetKeys: targetKeys,
      productTreeAllIds: productTreeAllIds,
    });
  };

  getTenderingLog = async () => {
    var responseTenderingLog = await handleRequest("GET", "/api/" + this.state.controllerName + "/getTenderingLog/" + this.props.tenderingLogId);
    if (responseTenderingLog.type === "ERROR") {
      error(responseTenderingLog);
    } else {
      this.setState({
        tenderingLog: Boolean(responseTenderingLog.data) ? responseTenderingLog.data : null,
        showProcessSelectModal: true
      });
    }
  };

  getStatisticals = async () => {
    this.setState({
      showStatisticsModal: true
    }, async () => {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getStatisticals/" + this.props.tenderingLogId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.formRefStatistics.current.setFieldsValue({
          ...response.data
        });
      }
    });
  };

  getMissingItemsCount = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getMissingItemsCount/" + this.props.tenderingLogId);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        missingItemsCount: response.data
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout2 = {
      labelCol: { span: 12 },
      wrapperCol: { span: 8 }
    };

    const layout3 = {
      labelCol: { span: 14 },
      wrapperCol: { span: 8 }
    };

    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false, showExcelExportModal: false }) : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="BillOfQuantity" hideElement={true}>
            <ExcelSheet name="BillOfQuantity" data={this.state.tableList}>
              <ExcelColumn label="Excel Line No" value="serialNumber" />
              <ExcelColumn label="Status" value="statusName" />
              <ExcelColumn label="Discipline" value="disciplineName" />
              <ExcelColumn label="Service" value="serviceName" />
              <ExcelColumn label="Compound" value="compound" />
              <ExcelColumn label="Component" value="componentName" />
              <ExcelColumn label="Client Code" value="clientCode" />
              <ExcelColumn label="Client Item Description" value="clientItemDescription" />
              <ExcelColumn label="Reference Code" value="referenceCode" />
              <ExcelColumn label="Stock Code" value="itemName" />
              <ExcelColumn label="Clients Quantity" value="clientsQuantity" />
              <ExcelColumn label="Unit Price" value="unitPrice" />
              <ExcelColumn label="Currency" value="currencyName" />
              <ExcelColumn label="Supplier" value="supplierName" />
              <ExcelColumn label="Manufacturer" value="manufacturerName" />
              <ExcelColumn label="Brand" value="brandName" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <Button onClick={this.handleExportExcel}
            style={{ width: "100%" }}
            variant="secondary"
          >
            {<FormattedMessage id="BillOfQuantity.BillOfQuantity" defaultMessage="Bill Of Quantity" />}
          </Button>
        )} */}
      </>
    );

    const columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="BillOfQuantity.ExcelLineNo" defaultMessage="Excel Line No" />,
        key: "serialNumber",
        render: record => {
          return record.serialNumber;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <InputNumber
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.serialNumber) ? this.state.pagination.serialNumber : null}
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  serialNumber: value,
                  serialNumberColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.serialNumberColor) ? this.state.pagination.serialNumberColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="BillOfQuantity.Status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          if (Boolean(record.statusName)) {
            if (record.statusName === "Created") {
              return <Tag color="red">{"Created"}</Tag>;
            }
            else if (record.statusName === "Requested") {
              return <Tag color="green">{"Requested"}</Tag>;
            }
            else if (record.statusName === "Reply") {
              return <Tag color="blue">{"Reply"}</Tag>;
            } else {
              return <Tag color="gray">{record.statusName}</Tag>;
            }
          }
        },
        ...getColumnFilter({
          inputName: "status",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ServiceStockCardLog.Discipline" defaultMessage="Discipline" />,
        key: "disciplineId",
        render: record => {
          return record.disciplineName;
        },
        ...getColumnFilter({
          inputName: "disciplineId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["disciplineIdSelectItems"]) ? this.state["disciplineIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ServiceStockCardLog.Service" defaultMessage="Service" />,
        key: "serviceId",
        render: record => {
          return record.serviceName;
        },
        ...getColumnFilter({
          inputName: "serviceId",
          inputType: "CASCADER",
          selectItems: Boolean(this.state["serviceIdSelectItems"]) ? this.state["serviceIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ServiceStockCardLog.Compound" defaultMessage="Compound" />,
        key: "compound",
        render: record => {
          return record.compound;
        },
        ...getColumnFilter({
          inputName: "compound",
          inputType: "SELECT",
          selectItems: Boolean(this.state["compoundIdSelectItems"]) ? this.state["compoundIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="ServiceStockCardLog.Component" defaultMessage="Component" />,
        key: "componentId",
        render: record => {
          return record.componentName;
        },
        ...getColumnFilter({
          inputName: "componentId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["componentIdSelectItems"]) ? this.state["componentIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BillOfQuantity.ClientCode" defaultMessage="Client Code" />,
        key: "clientCode",
        render: record => {
          return record.clientCode;
        },
        ...getColumnFilter({
          inputName: "clientCode",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BillOfQuantity.ClientItemDescription" defaultMessage="Client Item Description" />,
        key: "clientItemDescription",
        render: record => {
          return record.clientItemDescription;
        },
        ...getColumnFilter({
          inputName: "clientItemDescription",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BillOfQuantity.ReferenceCode" defaultMessage="Reference Code" />,
        key: "referenceCode",
        render: record => {
          return record.referenceCode;
        },
        ...getColumnFilter({
          inputName: "referenceCode",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BillOfQuantity.StockCode" defaultMessage="Stock Code" />,
        key: "itemId",
        render: record => {
          return record.itemName;
        },
        ...getColumnFilter({
          inputName: "itemId",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BillOfQuantity.Description" defaultMessage="Description" />,
        key: "description",
        render: record => {
          return record.description;
        },
        ...getColumnFilter({
          inputName: "description",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BillOfQuantity.ClientQuantity" defaultMessage="Client Quantity" />,
        key: "clientsQuantity",
        render: record => {
          let clientQuantity = "";
          if (Boolean(record.clientsQuantity) && record.clientsQuantity > 0) {
            clientQuantity += record.clientsQuantity + " ";

            if (Boolean(record.unitName)) {
              clientQuantity += record.unitName;
            }
          }
          return clientQuantity;
        },
        ...getColumnFilter({
          inputName: "clientsQuantity",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BillOfQuantity.UnitPrice" defaultMessage="Unit Price" />,
        key: "unitPrice",
        render: record => {
          let unitPrice = "";
          if (Boolean(record.unitPrice) && record.unitPrice > 0) {
            unitPrice += record.unitPrice + " ";

            if (Boolean(record.currencyName)) {
              unitPrice += record.currencyName;
            }
          }
          return unitPrice;
        },
        ...getColumnFilter({
          inputName: "unitPrice",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BillOfQuantity.Supplier" defaultMessage="Supplier" />,
        key: "supplierId",
        render: record => {
          return record.supplierName;
        },
        ...getColumnFilter({
          inputName: "supplierName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BillOfQuantity.Manufacturer" defaultMessage="Manufacturer" />,
        key: "manufacturerId",
        render: record => {
          return record.manufacturerName;
        },
        ...getColumnFilter({
          inputName: "manufacturerId",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BillOfQuantity.Brand" defaultMessage="Brand" />,
        key: "brandId",
        render: record => {
          return record.brandName;
        },
        ...getColumnFilter({
          inputName: "brandName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
            <Col md={4}>
              <Button
                onClick={() => {
                  this.getMissingItemsCount();
                  this.setState({
                    continueToAdd: true,
                    showContinueToAdd2Modal: true,
                    sendSerialNumber: Boolean(this.state.tableList) && this.state.tableList.length > 0 ? this.state.tableList[0].serialNumber : -1
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
                hidden={Boolean(this.state.tableList) && this.state.tableList.length > 0 ? false : true}
              >
                {this.state.continueMatching ?
                  <FormattedMessage id="BillOfQuantity.ContinueMatching" defaultMessage="Continue Matching" />
                  :
                  <FormattedMessage id="BillOfQuantity.Matching" defaultMessage="Matching" />
                }
              </Button>
            </Col>

            <Col md={1}>
              <Tooltip title={<FormattedMessage id="BillOfQuantity.Statisticals" defaultMessage="Statisticals" />}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => {
                    this.getStatisticals();
                  }}
                  icon={<FilterList fontSize="small" style={{ border: "0px", color: "#007bff" }} />}
                ></AntButton>
              </Tooltip>
            </Col>
            <Col md={12}></Col>

            <Col md={3}>
              <Button
                onClick={() => {
                  this.setState({
                    showSelectModal: true,
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
                hidden={Boolean(this.state.tableList) && this.state.tableList.length > 0 ? false : true}
              >
                {<FormattedMessage id="BillOfQuantity.Pricing" defaultMessage="Pricing" />}
              </Button>
            </Col>

            <Col md={1}>
              <Tooltip title={<FormattedMessage id="BillOfQuantity.PDF" defaultMessage="PDF" />}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => {
                    this.setState({
                      showPDFModal: true
                    });
                  }}
                  icon={<PictureAsPdfIcon style={{ color: "red" }} fontSize="large" />}
                ></AntButton>
              </Tooltip>
            </Col>

            <Col md={1}>
              <Tooltip title={<FormattedMessage id="BillOfQuantity.Excel" defaultMessage="Excel" />}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => {
                    this.setState({
                      showExcelModal: true
                    });
                  }}
                  icon={<ExportIcon color="action" fontSize="large" />}
                ></AntButton>
              </Tooltip>
            </Col>
            <Col md={1}>
              <Tooltip title={<FormattedMessage id="BillOfQuantity.Create" defaultMessage="Create" />}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => {
                    this.getTenderingLog();
                  }}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
              </Tooltip>
            </Col>
            <Col md={1}>
              {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                <Tooltip title={<FormattedMessage id="BillOfQuantity.Settings" defaultMessage="Settings" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      this.setState({ drawerSettingsShow: true });
                    }}
                    icon={<Settings color="error" fontSize="large" />}
                  ></AntButton>
                </Tooltip>
              )}
            </Col>
          </Row>
        </Form>
        {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
          <div className="alert alert-custom alert-light">
            <div className="alert-icon">
              <FilterOutlined />
            </div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (
                  <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                    <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                    <span>
                      <Tooltip title="Remove">
                        <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined
                  style={{ color: "crimson" }}
                  onClick={() =>
                    this.setState({ searchCriteriaModels: [] }, () => {
                      this.filterChangeItem([]);
                    })
                  }
                />
              </Tooltip>
            </div>
          </div>
        )}
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Drawer
          title={<FormattedMessage id="BillOfQuantity.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"BillOfQuantity"} />
        </Drawer>

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantity.Item" defaultMessage="Item" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showItemReachSearchModal}
          onOk={() => this.formRefItem.current.submit()}
          onCancel={() => {
            this.setState({ showItemReachSearchModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveItem} onFinishFailed={onFinishFailed} ref={this.formRefItem}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProjectTakeOff.type" defaultMessage="Type" />}
                    name="type"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Radio.Group onChange={e => {
                      this.setState({
                        type: e.target.value
                      });
                    }}>
                      <Radio value={"EQUIPMENT"}>{<FormattedMessage id="ProjectTakeOff.Equipment" defaultMessage="Equipment" />}</Radio>
                      <Radio value={"MATERIAL"}>{<FormattedMessage id="ProjectTakeOff.Material" defaultMessage="Material" />}</Radio>
                    </Radio.Group>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.itemGroupId" defaultMessage="Item Group" />}
                    name="itemGroupId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                      onChange={value => {
                        this.onChangeItemGroupId(value);
                        this.setState({ itemGroupId: value }, () => {
                          this.onChangeItemGroupIdforNameCode();
                          this.fillMaterialType();
                        });
                      }}
                    >
                      {Boolean(this.state["itemGroupIdSelectItems"]) &&
                        this.state["itemGroupIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.itemDescriptionId" defaultMessage="Item Description" />}
                    name="itemDescriptionId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      showSearch
                      onChange={value => {
                        this.setState({ itemDescriptionId: value }, () => {
                          this.onChangeItemGroupIdforNameCode();
                          this.fillMaterialType();
                        });
                      }}
                      allowClear
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                    >
                      {Boolean(this.state["itemDescriptionIdSelectItems"]) &&
                        this.state["itemDescriptionIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.materialTypeId" defaultMessage="Material Type" />}
                    name="materialTypeId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Cascader
                      onChange={value => {
                        this.setState({ materialTypeId: value }, () => {
                          this.onChangeItemGroupIdforNameCode();
                        });
                      }}
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                      options={this.state.nodes}
                    />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.NameCode" defaultMessage="Name/Code" />}
                    name="code"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                      {Boolean(this.state["itemIdSelectItems"]) &&
                        this.state["itemIdSelectItems"].length > 0 &&
                        this.state["itemIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
              </Form>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantity.NewSupplier" defaultMessage="New Supplier" />}
          centered
          width={600}
          open={this.state.showNewSupplierModal}
          onOk={() => this.formRefNewSupplier.current.submit()}
          onCancel={() => {
            this.setState({ showNewSupplierModal: false });
          }}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Form id="FormVendor" initialValues={{ remember: false }} onFinish={this.handleFormNewSupplierSubmit} ref={this.formRefNewSupplier}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Vendor.name" defaultMessage="Name" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="VendorContact.address" defaultMessage="Address" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="VendorContact.emails" defaultMessage="Email" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="emails"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </div>
          }
        ></DraggableModal>


        <DraggableModal
          title={<FormattedMessage id="BillOfQuantity.NewManufacturer" defaultMessage="New Manufacturer" />}
          centered
          width={600}
          maskClosable={false}
          open={this.state.showNewManufacturerModal}
          onOk={() => this.formRefNewManufacturer.current.submit()}
          onCancel={() => {
            this.setState({ showNewManufacturerModal: false });
          }}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Form id="FormVendor" initialValues={{ remember: false }} onFinish={this.handleFormNewManufacturerSubmit} ref={this.formRefNewManufacturer}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Vendor.name" defaultMessage="Name" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="VendorContact.address" defaultMessage="Address" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="VendorContact.emails" defaultMessage="Email" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="emails"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </div>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantity.NewBrand" defaultMessage="New Brand" />}
          centered
          width={600}
          maskClosable={false}
          open={this.state.showNewBrandModal}
          onOk={() => this.formRefNewBrand.current.submit()}
          onCancel={() => {
            this.setState({ showNewBrandModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveNewBrand} onFinishFailed={onFinishFailed} ref={this.formRefNewBrand}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.NewBrand" defaultMessage="New Brand" />}
                    name="newBrand"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantity.PurchasingPricesOrExport" defaultMessage="Purchasing Prices or Inventory Control" />}
          centered
          width={400}
          maskClosable={false}
          open={this.state.showPDFModal}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ showPDFModal: false });
          }}
          content={
            <>
              <Button
                onClick={() => {
                  this.setState({
                    showPurchasingPricesPDFModal: true,
                    showPDFModal: false
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="BillOfQuantity.PurchasingPrices" defaultMessage="Purchasing Prices" />}
              </Button>
              <br /> <br />
              <Button
                onClick={() => {
                  this.setState({
                    showInventoryControlPDFModal: true,
                    showPDFModal: false
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="BillOfQuantity.InventoryControl" defaultMessage="Inventory Control" />}
              </Button>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantity.ImportOrExport" defaultMessage="Import or Export" />}
          centered
          width={400}
          maskClosable={false}
          open={this.state.showExcelModal}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ showExcelModal: false });
          }}
          content={
            <>
              <Button
                onClick={() => {
                  this.setState({
                    showExcelExportModal: true,
                    showExcelModal: false
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="BillOfQuantity.Export" defaultMessage="Export" />}
              </Button>

              {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                <>
                  <br /> <br />
                  <ExcelReader excelConfig={"Procurement Excel"} onCreateJson={this.handleUploadProcurementExcel.bind(this)}></ExcelReader>
                </>
              )}
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantity.Excel" defaultMessage="Excel Export" />}
          centered
          width={400}
          maskClosable={false}
          open={this.state.showExcelExportModal}
          okButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ showExcelExportModal: false });
          }}
          content={
            <>
              <Button
                onClick={() => {
                  this.setState({
                    showExcelImportModal: true
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="BillOfQuantity.PurchasingPrices" defaultMessage="Purchasing Prices" />}
              </Button>
              <br /> <br />
              <Button
                onClick={() => {
                  this.setState({
                    showExcelExportModal: true,
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="BillOfQuantity.InventoryControl" defaultMessage="Inventory Control" />}
              </Button>
              <br /> <br />
              {excelExport}
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantity.ContinueMatching" defaultMessage="Continue Matching" />}
          centered
          width={400}
          maskClosable={false}
          open={this.state.showContinueToAdd2Modal}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ showContinueToAdd2Modal: false });
          }}
          content={
            <>
              <Button
                onClick={() => {
                  this.setState({
                    showAddPriceModal: true,
                    showContinueToAdd2Modal: false,
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="BillOfQuantity.FromStart" defaultMessage="From Start" />}
              </Button>
              <br /> <br />
              <Button
                onClick={() => {
                  this.setState({
                    showAddPriceMissingItemsModal: true,
                    showContinueToAdd2Modal: false,
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="BillOfQuantity.MissingItems" defaultMessage="Missing Items" />}
              </Button>
              <br /> <br />
              <Button
                onClick={() => {
                  this.setState({
                    showNativeFormModal: true,
                    showContinueToAdd2Modal: false,
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="BillOfQuantity.NativeForm" defaultMessage="Native Form" />}
              </Button>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantity.NativeForm" defaultMessage="Native Form" />}
          centered
          width={1400}
          maskClosable={false}
          open={this.state.showNativeFormModal}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ showNativeFormModal: false });
            const { pagination } = this.state;
            this.restartTable({ pagination });
          }}
          content={
            <BillOfQuantityNativeForm tenderingLogId={this.props.tenderingLogId}
              onCancel={() => {
                this.setState({ showNativeFormModal: false });
                const { pagination } = this.state;
                this.restartTable({ pagination });
              }}
              random={Math.random()}
              serialNumber={this.state.sendSerialNumber}
            />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantity.FromStart" defaultMessage="From Start" />}
          centered
          width={1400}
          maskClosable={false}
          open={this.state.showAddPriceModal}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ showAddPriceModal: false });
          }}
          content={
            <BillOfQuantityAddPrice tenderingLogId={this.props.tenderingLogId}
              onCancel={() => {
                this.setState({ showAddPriceModal: false });
              }}
              random={Math.random()}
              serialNumber={this.state.sendSerialNumber}
            />
          }
        />

        <DraggableModal
          title={Boolean(this.state.missingItemsCount) && this.state.missingItemsCount > 0
            ? "Missing " + this.state.missingItemsCount + " Items" :
            <FormattedMessage id="BillOfQuantity.MissingItems" defaultMessage="Missing Items" />}
          centered
          width={1400}
          maskClosable={false}
          open={this.state.showAddPriceMissingItemsModal}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ showAddPriceMissingItemsModal: false });
          }}
          content={
            <BillOfQuantityAddPrice tenderingLogId={this.props.tenderingLogId}
              onCancel={() => {
                this.setState({ showAddPriceMissingItemsModal: false });
              }}
              random={Math.random()}
              missingItems={true}
              getMissingItemsCount={this.getMissingItemsCount}
            />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantity.AddMaterial" defaultMessage="Add Material" />}
          centered
          width={1400}
          maskClosable={false}
          open={this.state.showSelectFromProductModal}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ showSelectFromProductModal: false });
            const { pagination } = this.state;
            this.restartTable({ pagination });
          }}
          content={
            <BillOfQuantityAddProduct tenderingLogId={this.props.tenderingLogId} productTreeIds={this.state.targetKeys}
              productTreeAllIds={this.state.productTreeAllIds} continueToAdd={this.state.continueToAdd}
              onCancel={() => {
                this.setState({ showSelectFromProductModal: false });
                const { pagination } = this.state;
                this.restartTable({ pagination });
              }}
              random={Math.random()}
            />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="ProductLog.CreateNewProduct" defaultMessage="Create New Product" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showCreateANewProductModal}
          onCancel={() => {
            this.setState({ showCreateANewProductModal: false });
          }}
          onOk={() => {
            if (Boolean(this.state.targetKeys) && this.state.targetKeys.length > 0) {
              this.setState({
                showSelectFromProductModal: true,
                showCreateANewProductModal: false
              });
            } else {
              showWarning("Please select item.");
            }
          }}
          content={
            <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%" }}>
              <EldenCompoundsSettingsProductTreeSelectableCompanyEditable
                onTargetKeysCheckCompanyAddProduct={this.onTargetKeysCheckCompanyAddProduct}
                tenderingLogId={this.props.tenderingLogId} continueToAdd={this.state.continueToAdd}
              />
            </div>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantity.SendRFQ" defaultMessage="Send RFQ" />}
          centered
          width={1400}
          maskClosable={false}
          open={this.state.showSendRFQModal}
          okButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ showSendRFQModal: false });
          }}
          onClose={() => this.setState({ showSendRFQModal: false })}
          content={
            <BillOfQuantitySendRFQ tenderingLogId={this.props.tenderingLogId} random={Math.random()}
              close={() => {
                this.setState({ showSendRFQModal: false });
                const { pagination } = this.state;
                this.restartTable({ pagination });
              }}
            />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantity.VendorResponse" defaultMessage="Vendor Response" />}
          centered
          width={1200}
          maskClosable={false}
          open={this.state.showVendorResponseModal}
          okButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ showVendorResponseModal: false });
          }}
          content={<BillOfQuantityVendorResponse tenderingLogId={this.props.tenderingLogId} random={Math.random()} />}
        />

        <DraggableModal
          title={Boolean(this.state.selectedRow) ? <FormattedMessage id="BillOfQuantity.Edit" defaultMessage="Edit" /> : <FormattedMessage id="BillOfQuantity.AddNewMaterial" defaultMessage="Add New Material" />}
          centered
          width={1400}
          maskClosable={false}
          open={this.state.showCreateNewModal}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ showCreateNewModal: false });
          }}
          content={
            <BillOfQuantityAddNewItem tenderingLogId={this.props.tenderingLogId} random={this.state.random}
              selectedRow={this.state.selectedRow}
              close={() => {
                this.setState({ showCreateNewModal: false });
                const { pagination } = this.state;
                this.restartTable({ pagination });
              }}
            />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantity.Vendors" defaultMessage="Vendors" />}
          centered
          width={1200}
          open={this.state.modalVendorsShow}
          onOk={() => this.updateSendToVendors()}
          onCancel={() => this.setState({ modalVendorsShow: false })}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <BillOfQuantityVendor
              itemIds={this.state.itemIds}
              billOfQuantityIds={this.state.billOfQuantityIds}
              random={Math.random()}
              updateSelectedVendorRowKeys={this.updateSelectedVendorRowKeys} />
          }
        />

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="mr-2">
                {<FormattedMessage id="BillOfQuantity.VendorQuotationReceived" defaultMessage="Please enter the number of days for latest recieved quotations " />}
              </span>
            </span>
          }
          centered
          width={800}
          open={this.state.showMoveModal}
          onOk={() => this.formRefMove.current.submit()}
          onCancel={() => this.setState({ showMoveModal: false })}
          okText={<FormattedMessage id="GeneralButtonOk" defaultMessage="Ok" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveMove} ref={this.formRefMove}>
              <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
                <br></br>
                <br></br>

                <Col md={10}>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.latesttt" defaultMessage="Latest" />}
                    name="latest"

                  ></Form.Item>

                </Col>
                <Col md={12}>
                  <Form.Item
                    name="moveDay"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >

                    <InputNumber addonAfter="day" min={1} style={{ width: '50%' }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          }
        />

        < DraggableModal
          title={< FormattedMessage id="BillOfQuantity.AddNewProduct" defaultMessage="Add New" />}
          centered
          width={400}
          maskClosable={false}
          open={this.state.showProcessSelectModal}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ showProcessSelectModal: false });
          }}
          content={
            <>
              <Button
                onClick={() => {
                  this.setState({
                    showCreateNewModal: true,
                    hideInputs: false,
                    hideSave: false,
                    hideUpdate: true,
                    showProcessSelectModal: false,
                    random: Math.random(),
                    selectedRow: null,
                  }, () => {
                    this.resetInputs();
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="BillOfQuantity.AddNewMaterial" defaultMessage="New Item" />}
              </Button>

              {
                Boolean(this.state.tenderingLog) && this.state.tenderingLog.projectScope !== "Supply_Of_Products" &&
                <>  <br /> <br />
                  <ExcelReader excelConfig={"Import from Excel - Native Form"} onCreateJson={this.handleUploadExcelNativeForm.bind(this)}></ExcelReader>
                </>
              }

              {
                Boolean(this.state.tenderingLog) && this.state.tenderingLog.projectScope !== "Supply_Of_Products" &&
                <>  <br />
                  <ExcelReader excelConfig={"Import from Excel - Modified"} onCreateJson={this.handleUploadExcelModified.bind(this)}></ExcelReader>
                </>
              }
              <br />
              <Button
                onClick={() => {
                  this.setState({
                    showCreateANewProductModal: true,
                    showProcessSelectModal: false,
                    continueToAdd: false,
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="BillOfQuantity.SelectFromProducts" defaultMessage="Base of Product" />}
              </Button>
            </>
          }
        />


        < DraggableModal
          title={< FormattedMessage id="BillOfQuantity.Select" defaultMessage="Select" />}
          centered
          width={400}
          maskClosable={false}
          open={this.state.showSelectModal}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ showSelectModal: false });
          }}
          content={
            <>
              <Button
                type="link"
                onClick={() => {
                  this.setState({
                    showMoveModal: true
                  }, () => {
                    this.resetInputs();
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="BillOfQuantity.ReflectLatestsQuotations" defaultMessage="Reflect Latests Quotations" />}
              </Button>


              <>  <br /> <br />
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({
                      showSendRFQModal: true,
                      showMoveModal: false
                    });
                  }}
                  style={{ width: "100%" }}
                  variant="secondary"
                >
                  {<FormattedMessage id="BillOfQuantity.SelectFromServices" defaultMessage="Send New RFQs" />}
                </Button>
              </>

              <br /> <br />
              <Button
                type="primary"
                onClick={() => {
                  this.setState({
                    showVendorResponseModal: true,
                    showSendRFQModal: false,
                    showMoveModal: false,
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="BillOfQuantity.AddNewVendorReplies" defaultMessage="Add New Vendor Replies" />}
              </Button>
            </>
          }
        />

        <DraggableModal
          title={< FormattedMessage id="BillOfQuantity.Statistics" defaultMessage="Statistics" />}
          centered
          width={1200}
          maskClosable={false}
          open={this.state.showStatisticsModal}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ showStatisticsModal: false });
          }}

          content={

            <Form initialValues={{ remember: false }} ref={this.formRefStatistics}>

              <Row gutter={16} style={{ marginBottom: "16px" }}>

                <br></br>
                {/* Sol Card */}
                <Col span={1}> </Col>
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Form.Item
                      {...layout2}
                      label={<FormattedMessage id="BillOfQuantity.TransferredFromExcel" defaultMessage="Transferred from Excel" />}
                      name="transferredFromExcel"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber style={{ width: "100%" }} readOnly />
                    </Form.Item>

                    <Form.Item
                      {...layout2}
                      label={<FormattedMessage id="BillOfQuantity.AddedItems" defaultMessage="Added Items" />}
                      name="addedItems"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber style={{ width: "100%" }} readOnly />
                    </Form.Item>
                    <br></br>
                    <br></br>
                    <br></br>

                    <Form.Item
                      {...layout2}
                      label={<FormattedMessage id="BillOfQuantity.TotalNumberOfItems" defaultMessage="Total Number of Items" />}
                      name="totalNumberOfItems"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      style={{ color: "#b6db0f" }}
                    >
                      <InputNumber style={{ width: "100%" }} readOnly />
                    </Form.Item>
                  </Card>
                </Col>

                {/* Sağ Card */}
                <Col span={11}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Form.Item
                      {...layout3}
                      label={<FormattedMessage id="BillOfQuantity.TransferredFromPreviousRFQs" defaultMessage="Transferred from Previous RFQs" />}
                      name="transferredFromPreviousRFQ"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber style={{ width: "100%" }} readOnly />
                    </Form.Item>

                    <Form.Item
                      {...layout3}
                      label={<FormattedMessage id="BillOfQuantity.RepliedFromSupplier" defaultMessage="Replied from Supplier" />}
                      name="repliedFromSupplier"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber style={{ width: "100%" }} readOnly />
                    </Form.Item>

                    <Form.Item
                      {...layout3}
                      label={<FormattedMessage id="BillOfQuantity.ManuallyPriced" defaultMessage="Manually Priced" />}
                      name="manuallyPriced"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber style={{ width: "100%" }} readOnly />
                    </Form.Item>

                    <Form.Item
                      {...layout3}
                      label={<FormattedMessage id="BillOfQuantity.Balance" defaultMessage="Balance" />}
                      name="balance"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber style={{ width: "100%" }} readOnly />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={1}></Col>
              </Row>

            </Form>
          }
        />

        <DraggableModal
          title={
            <Space>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="mr-2">
                {<FormattedMessage id="BillOfQuantity.SerialNumberItem" defaultMessage="Serial Number/Item" />}
              </span>
            </Space>
          }
          centered
          width={800}
          open={this.state.modalSerialNumberShow}
          onOk={() => this.formRefSerialNumber.current.submit()}
          onCancel={() => this.setState({ modalSerialNumberShow: false })}
          okText={<FormattedMessage id="GeneralButtonOk" defaultMessage="Ok" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveSerialNumber} ref={this.formRefSerialNumber}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenEquivalentStockCodesLogRevise.Item" defaultMessage="Item" />}
                name="itemId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={this.searchItem}
                  style={{ marginBottom: 0, width: "100%" }}
                  loading={this.state.loadingSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Boolean(this.state["searchItemIdSelectItems"]) &&
                    this.state["searchItemIdSelectItems"].length > 0 &&
                    this.state["searchItemIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Form>
          }
        />
      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BillOfQuantity);