import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, } from "react-bootstrap";
import { Form, Col, Row, Modal, Button as AntButton, Radio, Space, Cascader, Tooltip, InputNumber, Select, DatePicker, Input } from "antd";
import { handleRequest } from "../../../ApiConnector";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import ImageSearch from "@material-ui/icons/ImageSearch";
import { evaluate, round } from "mathjs";
import moment from "moment";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node.data)) {
      var lineage = [];
      if (Boolean(node.data.lineage) && node.data.lineage.length > 0) {
        node.data.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.data.id,
        lineage: lineage,
        title: node.data.menuTitle,
        page: node.data.page
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

function createOperator(operator) {
  switch (operator) {
    case "add":
      return "+";
    case "subtract":
      return "-";
    case "multiply":
      return "*";
    case "divide":
      return "/";
    case "sqrt":
      return "sqrt";
    case "pow":
      return "^";
    case "open_parenthesis":
      return "(";
    case "close_parenthesis":
      return ")";
    default:
      return "";
  }
}

export class Emergency extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "Emergency",
      controllerName: "inventoryLogEmergency",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,

      hideUpdate: true,
      hideSave: false,
      hideInputs: false,

      tableList: [],
      materialList: [],
      usageList: [],
      fileList: [],
      nodes: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  }

  fillComboboxes = async () => {
    var responsecategoryId = await handleRequest("GET", "/api/" + this.state.controllerName + "/category");
    if (responsecategoryId.type === "ERROR") {
      error(responsecategoryId);
    } else {
      this.setState({
        categoryIdSelectItems: Boolean(responsecategoryId.data) ? responsecategoryId.data : []
      });
    }

    var responsevendor = await handleRequest("GET", "/api/" + this.state.controllerName + "/vendor");
    if (responsevendor.type === "ERROR") {
      error(responsevendor);
    } else {
      this.setState({
        vendorSelectItems: Boolean(responsevendor.data) ? responsevendor.data : []
      });
    }

    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      this.setState({
        nodes: responseTree.data
      });
    }

    var responseCompanyProjectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/companyProjectId");
    if (responseCompanyProjectId.type === "ERROR") {
      error(responseCompanyProjectId);
    }
    this.setState({
      companyProjectSelectItems: Boolean(responseCompanyProjectId.data) ? responseCompanyProjectId.data : []
    });
  };

  restartTable = async (params = {}) => {

  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    console.log("row", row);
    document.getElementById("kt_scrolltop").click();

    let ticketMessages = [];

    row.ticketMessageMaterial = row.ticketMessageMaterials;
    ticketMessages.push(row);

    console.log("ticketMessages", ticketMessages);

    this.formRef.current.setFieldsValue({
      ticketMessages: ticketMessages,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  save = async values => {
    if (Boolean(this.props.stockId) && this.props.stockId > 0) {
      const { residueOfDimensions, selectedStockCard } = this.state;
      const newItem = {
        ...values,
        id: this.state.id,
        stockCardId: selectedStockCard.id,
      };

      if (Boolean(residueOfDimensions)) {
        newItem.residueOfDimensions = residueOfDimensions;
      } else {
        newItem.residueOfDimensions = [];
      }

      newItem.materialTypeId =
        Boolean(values.materialTypeId) && values.materialTypeId.length > 0 ? values.materialTypeId[values.materialTypeId.length - 1] : null;

      newItem.stockId = this.props.stockId;

      console.log(newItem);
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveEmergency", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.props.close();
      }
    } else {
      showWarning("Stock not found.");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          modalAddNewDimensionShow: false,
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  onChangeItemGroupId = async value => {
    if (Boolean(value)) {
      var responseitemDescriptionId = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemDescriptionIdSelectItems/" + value);
      if (responseitemDescriptionId.type === "ERROR") {
        error(responseitemDescriptionId);
        this.setState({
          descriptionIdSelectItems: [],
          descriptionId: null
        });
        this.formRef.current.setFieldsValue({
          descriptionId: null
        });
      } else {
        this.setState({
          descriptionIdSelectItems: Boolean(responseitemDescriptionId.data) ? responseitemDescriptionId.data : [],
          selectedEldenItem: null,
          descriptionId: null
        });
        this.formRef.current.setFieldsValue({
          descriptionId: null
        });
      }
    } else {
      this.setState({
        descriptionIdSelectItems: [],
        descriptionId: null
      });
      this.formRef.current.setFieldsValue({
        descriptionId: null
      });
    }
  };

  handleEldenItemIdChange = async () => {
    this.formRef.current.setFieldsValue({
      coatingId: null,
      surfaceFinishingId: null,
      standardId: null
    });

    var newObj = {
      materialCategoryId: this.formRef.current.getFieldValue("categoryId"),
      id: this.formRef.current.getFieldValue("descriptionId")
    };
    let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/eldenItem", newObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        selectedEldenItem: response.data,
        descriptionType: Boolean(response.data.descriptionId) ? "ADD" : "SELECT"
      });
      if (Boolean(response.data) && Boolean(response.data.itemDimension) && response.data.itemDimension.length > 0) {
        response.data.itemDimension.forEach(element => {
          this.fillCode("InventoryLog.Emergency" + element.itemDefinition);
          this.formRef.current.setFieldsValue({
            [element.itemDefinition]: null
          });
        });
      }
      if (Boolean(response.data) && Boolean(response.data.itemCodeCriterias) && response.data.itemCodeCriterias.length > 0) {
        response.data.itemCodeCriterias.forEach(element => {
          this.fillCode("InventoryLog.Emergency" + element.itemDefinition);
          this.formRef.current.setFieldsValue({
            [element.itemDefinition]: null
          });
        });
      }
    }
  };

  descriptionResidueOfDimensionsChange = async () => {
    let itemDescription = null;
    let residueOfDimensions = [];
    const { descriptionId } = this.state;
    if (Boolean(descriptionId) && descriptionId > 0) {
      let responseItemDescription = await handleRequest("GET", "/api/" + this.state.controllerName + "/residueOfDimensions/" + descriptionId);
      if (responseItemDescription.type === "ERROR") {
        error(responseItemDescription);
      } else {
        itemDescription = Boolean(responseItemDescription.data) ? responseItemDescription.data : null;
      }
    }

    if (Boolean(itemDescription)) {
      if (Boolean(itemDescription.itemDimension) && itemDescription.itemDimension.length > 0) {
        itemDescription.itemDimension.forEach(element => {
          this.fillCode("StockCard" + element.itemDefinition);
          if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
            this.formRef.current.setFieldsValue({
              [element.itemDefinition]: null
            });
          }
        });
      }
      if (Boolean(itemDescription.itemCodeCriterias) && itemDescription.itemCodeCriterias.length > 0) {
        itemDescription.itemCodeCriterias.forEach(element => {
          this.fillCode("StockCard" + element.itemDefinition);
          if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
            this.formRef.current.setFieldsValue({
              [element.itemDefinition]: null
            });
          }
        });
      }
      if (Boolean(itemDescription.dimensionList) && itemDescription.dimensionList.length > 0) {
        itemDescription.dimensionList.forEach(element => {
          if (Boolean(itemDescription.itemDimension) && itemDescription.itemDimension.length > 0) {
            let f = itemDescription.itemDimension.find(x => x.itemDefinition === element.definition);
            if (!Boolean(f)) {
              residueOfDimensions.push({
                key: element.id,
                definition: element.definition,
                value: null,
                itemDimensionId: element.id,
                unitId: null,
                units: Boolean(element.unitType) ? element.unitType.units : []
              });
            }
          } else {
            residueOfDimensions.push({
              key: element.id,
              definition: element.definition,
              value: null,
              itemDimensionId: element.id,
              unitId: null,
              units: Boolean(element.unitType) ? element.unitType.units : []
            });
          }
        });
      }
    }
    this.setState({
      residueOfDimensions: residueOfDimensions,
      itemDescription: itemDescription
    });
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.setFieldsValue({
        residueOfDimensions: residueOfDimensions
      });
    }
  };

  fillCode = async codeType => {
    const { materialTypeId, itemGroupId, descriptionId, projectTakeOffListId } = this.state;
    let obj = {
      materialTypeId: Boolean(materialTypeId) ? materialTypeId : null,
      categoryId: Boolean(itemGroupId) ? itemGroupId : null,
      descriptionId: Boolean(descriptionId) ? descriptionId : null,
      projectTakeOffListId: Boolean(projectTakeOffListId) ? projectTakeOffListId : null
    };

    let responsecode = await handleRequest("POST", "/api/" + this.state.controllerName + "/controlCode", obj);
    if (responsecode.type === "ERROR") {
      error(responsecode);
    } else {
      this.setState({
        codeSelectItems: Boolean(responsecode.data) ? responsecode.data : []
      });
    }
  };


  fillPurchasingCriterias = async (edit) => {
    const { selectedStockCard } = this.state;
    let responsePurchaseCriteria = await handleRequest("GET", "/api/" + this.state.controllerName + "/purchasingcriterias/" + selectedStockCard.id);
    if (responsePurchaseCriteria.type === "ERROR") {
      error(responsePurchaseCriteria);
    } else {
      let purchasingCriteriaSelectItems = [];
      let purchasingCriteriaId = null;
      if (Boolean(responsePurchaseCriteria.data)) {
        purchasingCriteriaSelectItems = responsePurchaseCriteria.data;
        purchasingCriteriaId = responsePurchaseCriteria.data[0].key;
      }
      this.setState({
        purchasingCriteriaSelectItems: purchasingCriteriaSelectItems,
      });

      if (!Boolean(edit) && Boolean(this.formRef) && Boolean(this.formRef.current)) {
        this.formRef.current.setFieldsValue({
          purchasingCriteriaId: purchasingCriteriaId,
        });

        if (Boolean(purchasingCriteriaId)) {
          this.fillPurchasingCriteriaUnits(purchasingCriteriaId);
        }
      }
    }
  };

  fillPurchasingCriteriaUnits = async (criteriaId, edit) => {
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.setFieldsValue({
        purchasingCriteriaUnitId: null
      });
    }
    if (Boolean(criteriaId)) {
      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getUnitType/" + criteriaId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        let purchasingCriteriaUnitSelectItems = [];
        let purchasingCriteriaUnitId = null;
        if (Boolean(response.data) && response.data.length > 0) {
          purchasingCriteriaUnitSelectItems = response.data;
          purchasingCriteriaUnitId = response.data[0].id;
        }
        this.setState({
          purchasingCriteriaUnitSelectItems: purchasingCriteriaUnitSelectItems,
        });

        if (!Boolean(edit) && Boolean(this.formRef) && Boolean(this.formRef.current)) {
          this.formRef.current.setFieldsValue({
            purchasingCriteriaUnitId: purchasingCriteriaUnitId,
          });
        }
      }
    } else {
      this.setState({
        purchasingCriteriaUnitSelectItems: [],
      });
    }
  };

  calculateWeight = row => {
    const { itemDescription, selectedStockCard } = this.state;
    console.log("itemDescription", itemDescription);

    if (Boolean(selectedStockCard) && Boolean(selectedStockCard.weight)) {
      console.log("selectedStockCard", selectedStockCard);

      this.formRef.current.setFieldsValue({
        weight: selectedStockCard.weight
      });
      return selectedStockCard.weight;
    }

    if (Boolean(itemDescription) && Boolean(itemDescription.features) && itemDescription.features.length > 0) {
      let weightFeature = itemDescription.features[0];
      if (weightFeature.itemFeatureFormula && weightFeature.itemFeatureFormula.length > 0) {
        let formulaText = "";
        weightFeature.itemFeatureFormula.forEach((element, index) => {
          let constant = Boolean(element.constant)
            ? element.constant
            : Boolean(element.dimensionAbbreviation)
              ? this.getDimensionValue(element.dimension, element.dimensionId, row)
              : Boolean(element.numberConstant)
                ? element.numberConstant
                : "";
          let operator = Boolean(element.operator) ? createOperator(element.operator) : "";
          formulaText += constant + operator;
        });
        console.log("formulaText", formulaText);
        if (!formulaText.includes("DIMENSION_NOT_FOUND")) {
          let result = round(evaluate(formulaText), 2);
          console.log("result", result);
          let weight = result;
          if (row) {
            console.log("row", row);
            if (Boolean(result)) {
              if (Boolean(row.unit) && Boolean(row.unitSelectItems) && row.unitSelectItems.length > 0) {
                let unit = row.unitSelectItems.find(p => p.abbreviation === row.unit);
                let unitRow = [];
                if (
                  Boolean(row.residueOfDimensions) &&
                  row.residueOfDimensions.length > 0 &&
                  row.residueOfDimensions[0].units &&
                  row.residueOfDimensions[0].units.length > 0
                ) {
                  unitRow = row.residueOfDimensions[0].units.find(p => p.abbreviation === row.unit);
                }
                if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                  weight = parseFloat((result / unit.coefficent).toFixed(2)).toFixed(2);
                  console.log("weight111", weight);
                } else if (Boolean(unitRow) && Boolean(unitRow.coefficent) && unitRow.coefficent > 0) {
                  weight = parseFloat((result / unitRow.coefficent).toFixed(2)).toFixed(2);
                  console.log("weight222", weight);
                } else {
                  weight = result.toFixed(2);
                  console.log("weight333", weight);
                }
              } else {
                weight = result.toFixed(2);
                console.log("weight444", weight);
              }
            }
          }
          this.formRef.current.setFieldsValue({
            weight: weight
          });
          return weight;
        }
      }
    }
    console.log("weight3:0");
    this.formRef.current.setFieldsValue({
      weight: ""
    });
    return 0;
  };

  getDimensionValue = (dimension, dimensionId, row) => {
    const { residueOfDimensions, selectedStockCard } = this.state;

    let dimensionValue = "DIMENSION_NOT_FOUND";
    let stockCard = { ...selectedStockCard };
    let rdo = residueOfDimensions;
    if (row) {
      stockCard = this.state.codeSelectItems.find(i => i.code === row.code);
      rdo = row.residueOfDimensions;
    }

    if (Boolean(rdo) && rdo.length > 0) {
      rdo.forEach(element => {
        if (element.itemDimensionId === dimensionId) {
          if (Boolean(element.value)) {
            dimensionValue = element.value;
          }
        }
      });
    }
    if (dimensionValue === "DIMENSION_NOT_FOUND" && stockCard && stockCard.itemDimension && stockCard.itemDimension.length > 0) {
      stockCard.itemDimension.forEach(element => {
        if (element.itemDefinition === dimension) {
          let code = Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"])
            ? this.state["StockCard" + element.itemDefinition + "SelectItems"].find(x => x.id === element.codeId)
            : null;
          dimensionValue = Boolean(code) ? code.name : "DIMENSION_NOT_FOUND";
        }
      });
    }

    return dimensionValue;
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const tailFormItemLayout2 = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form
          initialValues={{ remember: false }}
          onFinish={this.save}
          onFinishFailed={onFinishFailed}
          ref={this.formRef}>
          <div hidden={this.state.hideInputs}>

            <Row gutter={8}>
              <Col span={8}>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="emergencyType"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Radio.Group>
                    <Radio value={"PROJECT"}>
                      <FormattedMessage id="InventoryLog.Emergency.ProjectItem" defaultMessage="Project Item" />
                    </Radio>
                    <Radio value={"SPARE"}>
                      <FormattedMessage id="InventoryLog.Emergency.SpareParts" defaultMessage="Spare Parts" />
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={8}>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="type"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Radio.Group
                    onChange={e => {
                      this.setState({
                        type: e.target.value
                      });
                    }}
                  >
                    <Radio value={"EQUIPMENT"}><FormattedMessage id="InventoryLog.Emergency.Equipment" defaultMessage={"Equipment"} /></Radio>
                    <Radio value={"MATERIAL"}><FormattedMessage id="InventoryLog.Emergency.Material" defaultMessage={"Material"} /></Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ManufacturingPlanning.Project" defaultMessage="Project" />}
                name="companyProjectId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children" >
                  {Boolean(this.state["companyProjectSelectItems"]) &&
                    this.state["companyProjectSelectItems"].length > 0 &&
                    this.state["companyProjectSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={
                  this.state.type === "EQUIPMENT" ? (
                    <FormattedMessage id="InventoryLog.Emergency.EquipmentCategory" defaultMessage={"Equipment Category"} />
                  ) : (
                    <FormattedMessage id="InventoryLog.Emergency.MaterialCategory" defaultMessage={"Material Category"} />
                  )
                }
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="categoryId"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        style={{ width: "100%" }}
                        onChange={value => {
                          this.setState(
                            {
                              categoryId: value
                            },
                            () => {
                              this.onChangeItemGroupId(value);
                            }
                          );
                        }}
                      >
                        {Boolean(this.state["categoryIdSelectItems"]) &&
                          this.state["categoryIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                              {i.description !== null && i.description !== "" && (
                                <img src={i.description} alt={i.value} style={{ width: "30px", marginLeft: "10px" }} />
                              )}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={
                  this.state.type === "EQUIPMENT" ? (
                    <FormattedMessage id="InventoryLog.Emergency.Equipment" defaultMessage={"Equipment Description"} />
                  ) : (
                    <FormattedMessage id="InventoryLog.Emergency.Material" defaultMessage={"Material Description"} />
                  )
                }
              >
                <Row>
                  <Col span={20}>
                    <Form.Item
                      name="descriptionId"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        onChange={value => {
                          this.setState(
                            {
                              descriptionId: value
                            },
                            () => {
                              this.handleEldenItemIdChange();
                              this.descriptionResidueOfDimensionsChange();
                            }
                          );
                        }}
                        style={{ width: "100%" }}
                      >
                        {Boolean(this.state["descriptionIdSelectItems"]) &&
                          this.state["descriptionIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={2}>
                    {Boolean(this.state["descriptionIdSelectItems"]) && this.state["descriptionIdSelectItems"].length > 0 && (
                      <Tooltip title="Select From Image">
                        <AntButton
                          style={{ border: "0px" }}
                          onClick={this.onClickImageViewer}
                          icon={<ImageSearch color="secondary" fontSize="large" />}
                        ></AntButton>
                      </Tooltip>
                    )}
                  </Col>
                </Row>
              </Form.Item>
            }
            <Row>
              <Col span={8}></Col>
              <Col span={16}>
                {Boolean(this.state.selectedEldenItem) &&
                  Boolean(this.state.selectedEldenItem.photoPath) &&
                  this.getImageGalleryFromUrls(this.state.selectedEldenItem.photoPath, this.state.selectedEldenItem.id)}
              </Col>
            </Row>

            <Form.Item
              {...layout}
              label={
                this.state.type === "EQUIPMENT" ? (
                  <FormattedMessage id="InventoryLog.Emergency.EquipmentType" defaultMessage={"Equipment Type"} />
                ) : (
                  <FormattedMessage id="InventoryLog.Emergency.MaterialType" defaultMessage={"Material Type"} />
                )
              }
              name="materialTypeId"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <Cascader
                showSearch
                allowClear
                optionFilterProp="children"
                style={{ width: "100%" }}
                onChange={value => {
                  this.setState(
                    {
                      materialTypeId: Boolean(value) && value.length > 0 ? value[value.length - 1] : null
                    },
                    () => {
                      this.handleEldenItemIdChange();
                    }
                  );
                }}
                options={this.state.nodes}
              />
            </Form.Item>

            {
              <Form.Item {...layout} label={<FormattedMessage id="InventoryLog.Emergency.NameCode" defaultMessage="Name/Code" />}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="code"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        placeholder="Code"
                        onChange={value => {
                          const { codeSelectItems } = this.state;
                          console.log("value", value);
                          console.log("codeSelectItems", codeSelectItems);
                          let unitName = "mm";
                          if (Boolean(value)) {
                            let fObj = codeSelectItems.find(i => i.code === value);
                            if (Boolean(fObj)) {
                              if (Boolean(fObj.itemCodeCriterias) && fObj.itemCodeCriterias.length > 0) {
                                fObj.itemCodeCriterias.forEach(element => {
                                  this.formRef.current.setFieldsValue({
                                    [element.itemDefinition]: element.codeId
                                  });
                                });
                              }

                              if (Boolean(fObj.itemDimension) && fObj.itemDimension.length > 0) {
                                fObj.itemDimension.forEach(element => {
                                  this.formRef.current.setFieldsValue({
                                    [element.itemDefinition]: element.codeId,
                                    [element.itemDefinition + "Unit"]: element.unitId
                                  });
                                });
                              }
                            }

                            if (Boolean(this.state.residueOfDimensions) && this.state.residueOfDimensions.length > 0) {
                              this.state.residueOfDimensions.forEach(element => {
                                if (element.definition === "Length" || element.definition === "Width") {
                                  if (Boolean(element) && Boolean(element.unitId) && Boolean(element.units) && element.units.length > 0) {
                                    let unit = element.units.find(p => p.id === element.unitId);
                                    if (Boolean(unit)) {
                                      unitName = unit.abbreviation;
                                    }
                                  }
                                }
                              });
                            }

                            this.setState(
                              {
                                selectedStockCard: fObj
                              },
                              () => {
                                this.fillPurchasingCriterias();
                                let row = {
                                  code: value,
                                  unit: unitName,
                                  residueOfDimensions:
                                    Boolean(this.state.residueOfDimensions) && this.state.residueOfDimensions.length > 0
                                      ? this.state.residueOfDimensions
                                      : [],
                                  unitSelectItems:
                                    Boolean(this.state.unitSelectItems) && this.state.unitSelectItems.length > 0 ? this.state.unitSelectItems : []
                                };
                                this.calculateWeight(row);
                              }
                            );
                          } else {
                            this.formRef.current.resetFields();
                            this.setState({
                              selectedStockCard: null
                            });
                          }
                        }}
                      >
                        {Boolean(this.state["codeSelectItems"]) &&
                          this.state["codeSelectItems"].map(i => (
                            <Option key={i.code} value={i.code}>
                              {i.renameText}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            }

            {Boolean(this.state.residueOfDimensions) &&
              this.state.residueOfDimensions.length > 0 &&
              this.state.residueOfDimensions.map((dimension, index) => {
                return (
                  <Form.Item key={index} {...layout} label={dimension.definition}>
                    <Row>
                      <Col span={11}>
                        <InputNumber
                          parser={value => value.replace(",", ".")}
                          formatter={value => value.replace(".", ",")}
                          placeholder={dimension.definition}
                          style={{ width: "100%" }}
                          min={0}
                          value={dimension.value}
                          onChange={value => {
                            let list = this.state.residueOfDimensions;
                            list[index].value = value;
                            let unitName = "mm";
                            if (Boolean(dimension) && Boolean(dimension.unitId) && Boolean(dimension.units) && dimension.units.length > 0) {
                              let unit = dimension.units.find(p => p.id === dimension.unitId);
                              if (Boolean(unit)) {
                                unitName = unit.abbreviation;
                              }
                            }
                            this.setState(
                              {
                                residueOfDimensions: list
                              },
                              () => {
                                if (dimension.definition === "Length" || dimension.definition === "Width") {
                                  console.log("selectedStockCard", this.state.selectedStockCard);
                                  let row = {
                                    code: Boolean(this.state.selectedStockCard) ? this.state.selectedStockCard.code : "",
                                    unit: unitName,
                                    residueOfDimensions:
                                      Boolean(this.state.residueOfDimensions) && this.state.residueOfDimensions.length > 0
                                        ? this.state.residueOfDimensions
                                        : [],
                                    unitSelectItems:
                                      Boolean(this.state.unitSelectItems) && this.state.unitSelectItems.length > 0 ? this.state.unitSelectItems : []
                                  };
                                  this.calculateWeight(row);
                                }
                              }
                            );
                          }}
                        />
                      </Col>
                      <Col span={2}></Col>
                      <Col span={11}>
                        <Select
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          style={{ width: "100%" }}
                          placeholder="Unit"
                          value={dimension.unitId}
                          onChange={value => {
                            console.log("VVV", value);
                            console.log(dimension);
                            let list = this.state.residueOfDimensions;
                            list[index].unitId = value;
                            let unitName = "mm";
                            if (Boolean(value) && Boolean(dimension) && Boolean(dimension.units) && dimension.units.length > 0) {
                              let unit = dimension.units.find(p => p.id === value);
                              if (Boolean(unit)) {
                                unitName = unit.abbreviation;
                              }
                            }
                            this.setState(
                              {
                                residueOfDimensions: list
                              },
                              () => {
                                if (dimension.definition === "Length" || dimension.definition === "Width") {
                                  console.log("selectedStockCard", this.state.selectedStockCard);
                                  let row = {
                                    code: Boolean(this.state.selectedStockCard) ? this.state.selectedStockCard.code : "",
                                    unit: unitName,
                                    residueOfDimensions:
                                      Boolean(this.state.residueOfDimensions) && this.state.residueOfDimensions.length > 0
                                        ? this.state.residueOfDimensions
                                        : [],
                                    unitSelectItems:
                                      Boolean(this.state.unitSelectItems) && this.state.unitSelectItems.length > 0 ? this.state.unitSelectItems : []
                                  };
                                  this.calculateWeight(row);
                                }
                              }
                            );
                          }}
                        >
                          {dimension.units &&
                            dimension.units.map(i => (
                              <Option key={i.id} value={i.id}>
                                {i.description}
                              </Option>
                            ))}
                        </Select>
                      </Col>
                    </Row>
                  </Form.Item>
                );
              })}


            {
              <Form.Item
                {...layout}
                label={
                  <FormattedMessage
                    id="InventoryLog.Emergency.Quantity"
                    defaultMessage="Quantity"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  />
                }
              >
                <Row>
                  <Col span={8}>
                    <Form.Item
                      name="emergencyQuantity"
                      extra={<FormattedMessage id="InventoryLog.Emergency.Quantity" defaultMessage="Quantity" />}
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber style={{ width: "100%" }}
                        parser={value => value.replace(",", ".")}
                        formatter={value => value.replace(".", ",")}
                        className="w-100"
                        min={1}
                        onChange={value => {
                          this.setState({
                            emergencyQuantity: value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={7}>
                    <Form.Item
                      name={"purchasingCriteriaId"}
                      extra={<FormattedMessage id="InventoryLog.Emergency.Criteria" defaultMessage="Criteria" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        style={{ width: "100%" }}
                        onChange={value => {
                          this.fillPurchasingCriteriaUnits(value);
                        }}
                      >
                        {this.state.purchasingCriteriaSelectItems &&
                          this.state.purchasingCriteriaSelectItems.map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={7}>
                    <Form.Item
                      name={"purchasingCriteriaUnitId"}
                      extra={<FormattedMessage id="InventoryLog.Emergency.Unit" defaultMessage="Unit" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }} >
                        {Boolean(this.state.purchasingCriteriaUnitSelectItems) &&
                          this.state.purchasingCriteriaUnitSelectItems.map(i => (
                            <Option key={i.id} value={i.id}>
                              {i.abbreviation}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            }

            <Form.Item
              {...layout}
              label={<FormattedMessage id="InventoryLog.Emergency.Vendor" defaultMessage="Vendor" />}
              name="vendorId"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                style={{ width: "100%" }}
              >
                {Boolean(this.state["vendorSelectItems"]) && this.state["vendorSelectItems"].length > 0 &&
                  this.state["vendorSelectItems"].map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              {...layout}
              label={<FormattedMessage id="InventoryLog.Emergency.UnitPrice" defaultMessage="Unit Price" />}
              name="unitPrice"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <InputNumber style={{ width: "100%" }}
                parser={value => value.replace(",", ".")}
                formatter={value => value.replace(".", ",")}
              />
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="InventoryLog.Emergency.PurchaseOrderDate" defaultMessage="Purchase Order Date" />}
              name="purchaseOrderDate"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime={{ defaultValue: moment("00:00", "HH:mm") }} />
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="InventoryLog.Emergency.PaymentDate" defaultMessage="Payment Date" />}
              name="paymentDate"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime={{ defaultValue: moment("00:00", "HH:mm") }} />
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="InventoryLog.Emergency.ReceivedQuantity" defaultMessage="Received Quantity" />}
              name="receivedQuantity"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <InputNumber style={{ width: "100%" }}
                parser={value => value.replace(",", ".")}
                formatter={value => value.replace(".", ",")}
                className="w-100"
                min={1}
                max={this.state.emergencyQuantity}
                onChange={value => {
                  this.setState({
                    receivedQuantity: value,
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="InventoryLog.Emergency.ReceivingDate" defaultMessage="Receiving Date" />}
              name="receiveDate"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime={{ defaultValue: moment("00:00", "HH:mm") }} />
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="InventoryLog.Emergency.InvoiceNo" defaultMessage="Invoice No" />}
              name="invoiceNo"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="InventoryLog.Emergency.InvoiceDate" defaultMessage="Invoice Date" />}
              name="invoiceDate"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm" showTime={{ defaultValue: moment("00:00", "HH:mm") }} />
            </Form.Item>

            <div hidden={this.state.hideSave}>
              {<Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="SaveButton"
                    type="submit"
                    style={{ width: "100%" }}
                    variant="success">
                    <FormattedMessage id="GeneralButtonSave"
                      defaultMessage="Save" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>}
            </div>

            <div hidden={this.state.hideUpdate}>
              {<Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="CancelButton" style={{ width: "100%" }}
                    variant="secondary"
                    onClick={this.cancel}>
                    <FormattedMessage
                      id="GeneralButtonCancel"
                      defaultMessage="Cancel" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="UpdateButton"
                    style={{ width: "100%" }}
                    variant="warning" type="submit">
                    <FormattedMessage id="GeneralButtonUpdate"
                      defaultMessage="Update" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>}
            </div>
          </div>
        </Form>

        <Modal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={500}
          okButtonProps={{ hidden: true }}
          open={this.state.modalDeleteShow}
          onCancel={() => {
            this.setState({ modalDeleteShow: false });
          }}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDeleteShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Space>
          ]}
        >
          <p>
            <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
          </p>
        </Modal>
      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Emergency);