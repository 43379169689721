import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button as AntButton, Col, Form, Tag, Row, Checkbox, InputNumber, Timeline, Badge } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../../ApiConnector";
import { error, showError, showSuccess, showWarning } from "../../../MessageHelper";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { Modal as AntModal } from "antd";
import { Skeleton, Input, Progress } from "antd";
import moment from "moment";
import { RightCircleTwoTone, LeftCircleTwoTone, CheckOutlined, CloseOutlined } from "@ant-design/icons";

export class WorkOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formName: "WorkOrders",
      controllerName: "timeline",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,

      restartTable: false,
      selectedItems: [],
      stopVisible: false,
      endVisible: false,
      continueVisible: false,
      progress: 0,
    };
  }

  formRef = React.createRef();

  componentDidMount() {
    this.getWorkStationStartDate();
    //this.getWorkStationStartDateTimline();
    this.fillComboboxes();
    this.interval = setInterval(this.getProgress, 60000); // 60,000 milisaniye = 1 dakika
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fillComboboxes = async () => {

  };

  getProgress = async () => {
    console.log("AAA");
    const { selectedItem } = this.state;
    console.log("selectedItem", selectedItem);
    if (Boolean(selectedItem) && Boolean(selectedItem.productId) && selectedItem.productId > 0
      && Boolean(selectedItem.manufacturingPlanningId) && selectedItem.manufacturingPlanningId > 0) {
      let url = "/api/" + this.state.controllerName + "/progress/" + selectedItem.productId + "/" + selectedItem.manufacturingPlanningId;
      const response = await handleRequest("GET", url);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          progress: 0,
          endVisible: false,
        });
      } else {
        this.setState({
          progress: Boolean(response.data) ? response.data.progress : 0,
          endVisible: Boolean(response.data) ? response.data.endVisible : false,
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      timelineListId: this.state.selectedItems.id,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  getWorkStationStartDate = async () => {
    const newObj = {
      startDate: moment(),
      endDate: moment(),
    };
    let url = "/api/" + this.state.controllerName + "/manufacturingPlanningByDate";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.formRef.current.setFieldsValue({
        timelineLists: null,
      });
    } else {
      let list = [];
      if (Boolean(response.data) && response.data.length > 0) {
        list = response.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
      }

      this.formRef.current.setFieldsValue({
        timelineLists: list,
      });
    }
  };

  getWorkStationStartDateTimline = async () => {
    const newObj = {
      next: this.state.next,
      previous: this.state.previous,
      startDate: moment(this.state.modalStepDate),
      productName: this.state.productName,
    };

    let url = "/api/" + this.state.controllerName + "/manufacturingPlanningByDateTimline";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        stepList: [],
        modalStepShow: false,
      });
    } else {
      let list = [];
      if (Boolean(response.data) && response.data.length > 0) {
        list = response.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
      }
      this.setState({
        stepList: list,
        modalStepShow: true,
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      modalWorkOrdersStop: null,
      productId: null,
      next: false,
      previous: false,
      startDate: null,
      modalStepDate: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  edit = row => {
    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  onStepClick = () => {
    let selectedWorkStation = this.state.workStationSelectItems.find(p => p.value === this.state.productName);
    this.setState({
      next: false,
      previous: false,
      //timelineName:Boolean(timeline) ? timeline.label : "",
      modalStepDate: this.state.startDate,
    }, () => {
      const { pagination } = this.state;
      this.getWorkStationStartDateTimline({ pagination });
    });
  };

  onChangeStepsNext = () => {
    this.setState({
      next: true,
      previous: false,
      modalStepDate: moment(this.state.modalStepDate).add(1, 'day'),
    }, () => {
      const { pagination } = this.state;
      this.getWorkStationStartDateTimline({ pagination });
    });
  };

  onChangeStepsPrevious = () => {
    this.setState({
      next: false,
      previous: true,
      modalStepDate: moment(this.state.modalStepDate).subtract(1, 'day'),
    }, () => {
      this.getWorkStationStartDateTimline();
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async () => {
    this.setState({ restartTable: !this.state.restartTable });
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  start = async () => {
    const { selectedItem } = this.state;
    console.log("selectedItem", selectedItem);
    if (Boolean(selectedItem) && Boolean(selectedItem.processingQuantity) && selectedItem.processingQuantity > 0) {
      selectedItem.quantity = selectedItem.processingQuantity;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/start", selectedItem);
      if (response.type === "ERROR") {
        showError(response);
      } else {
        await this.getWorkStationStartDate();
        this.setState({
          startVisible: false,
          stopVisible: true,
          showListVisible: true,
          continueVisible: false,
        });
        let showList = [];
        showList.push({ "productName": selectedItem.productName, "processingQuantity": selectedItem.processingQuantity });
        this.formRef.current.setFieldsValue({
          showList: showList,
        });
      }
    } else {
      showWarning("Please select item.");
    }
  };

  continue = async () => {
    const { selectedItem } = this.state;
    console.log("selectedItem", selectedItem);
    if (Boolean(selectedItem)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/continue", selectedItem);
      if (response.type === "ERROR") {
        showError(response);
      } else {
        await this.getWorkStationStartDate();
        this.setState({
          startVisible: false,
          stopVisible: true,
          showListVisible: true,
          continueVisible: false,
        });
        let showList = [];
        showList.push({ "productName": selectedItem.productName, "processingQuantity": selectedItem.processingQuantity });
        this.formRef.current.setFieldsValue({
          showList: showList,
        });
      }
    } else {
      showWarning("Please select item.");
    }
  };

  stop = async () => {
    const { selectedItem } = this.state;
    console.log("selectedItem", selectedItem);
    if (Boolean(selectedItem)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/stop", selectedItem);
      if (response.type === "ERROR") {
        showError(response);
      } else {
        await this.getWorkStationStartDate();
        this.setState({
          stopVisible: false,
          continueVisible: true,
        });
      }
    } else {
      showWarning("Please select item.");
    }
  };

  end = async () => {
    const { selectedItem } = this.state;
    console.log("selectedItem", selectedItem);
    if (Boolean(selectedItem)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/end", selectedItem);
      if (response.type === "ERROR") {
        showError(response);
      } else {
        await this.getWorkStationStartDate();
        this.setState({
          startVisible: false,
          stopVisible: false,
          continueVisible: false,
          showListVisible: false,
          selectedItem: null
        });
      }
    } else {
      showWarning("Please select item.");
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  sendSelectedItemsToSpring = async values => {
    const { selectedItems } = this.state;

    var response = await handleRequest("POST", "/api/timeline/sendSelectedList", selectedItems);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        modalSendSelectShow: false
      })
    }
    this.setState({
      selectedRowKeys: []
    });
  }

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            this.restartTable();
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError();
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const { selectedItem } = this.state;
    // const list = Boolean(this.formRef) && Boolean(this.formRef.current) ? this.formRef.current.getFieldValue("timelineLists") : [];
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col md={8}>
          </Col>
          <Col md={8}>
            <Button
              style={{
                width: '380px',
                marginBottom: '20px',
                height: '60px',
                backgroundColor: '#2ea108',
                border: '2px solid #248205',
                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
              }}
              onClick={() =>
                this.setState({ modalStepShow: true })
              }
            >
              <FormattedMessage id="WorkOrders.TIMELINE" defaultMessage="TIMELINE" />
            </Button>
            <br></br>

            <Form initialValues={{ remember: false }} onFinishFailed={onFinishFailed} ref={this.formRef}>
              {!this.state.showListVisible &&
                <Form.List name="timelineLists" >
                  {(fields, { add, remove }) => {

                    const onChanceIsSelectted = (fieldKey) => {
                      const timelineLists = this.formRef.current.getFieldValue("timelineLists");
                      if (Boolean(timelineLists) && timelineLists.length > 0) {
                        timelineLists.forEach((element, index) => {
                          if (fieldKey !== index) {
                            element.isSelected = false;
                          } else {
                            element.isSelected = true;
                          }
                        });
                      }
                      const selectedItem = timelineLists.find(p => p.isSelected);
                      console.log("selectedItem", selectedItem);
                      this.formRef.current.setFieldsValue({
                        timelineLists: timelineLists
                      });

                      let startVisible = true;
                      let stopVisible = false;
                      let showListVisible = false;
                      let continueVisible = false;
                      if (Boolean(selectedItem) && selectedItem.status === "Stop") {
                        startVisible = false;
                        stopVisible = true;
                        showListVisible = true;
                        continueVisible = false;

                        let showList = [];
                        showList.push({ "productName": selectedItem.productName, "processingQuantity": selectedItem.processingQuantity });
                        this.formRef.current.setFieldsValue({
                          showList: showList,
                        });
                      } else if (Boolean(selectedItem) && selectedItem.status === "Continue") {
                        startVisible = false;
                        stopVisible = false;
                        showListVisible = true;
                        continueVisible = true;

                        let showList = [];
                        showList.push({ "productName": selectedItem.productName, "processingQuantity": selectedItem.processingQuantity });
                        this.formRef.current.setFieldsValue({
                          showList: showList,
                        });
                      }

                      this.setState({
                        selectedItem: selectedItem,
                        startVisible: startVisible,
                        stopVisible: stopVisible,
                        showListVisible: showListVisible,
                        continueVisible: continueVisible,
                        fieldKey: fieldKey,
                      }, () => {
                        this.getProgress();
                      });
                    };

                    return (
                      <div style={{ height: '300px', overflowY: 'scroll' }}>
                        <table>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <Form.Item hidden
                                    name={[field.name, "isSelected"]}
                                  // valuePropName="checked"
                                  >
                                    {/* <Checkbox
                                    style={{
                                      width: '60px',
                                      height: '60px',
                                      border: '1px solid #07607e',
                                    }} onChange={() => onChanceIsSelectted(field.name)}>
                                  </Checkbox> */}
                                  </Form.Item>

                                  {Boolean(this.state.selectedItem) &&
                                    this.state.fieldKey === field.name ? <td>
                                    <AntButton
                                      type="default" style={{
                                        marginBottom: "23px",
                                        width: '60px',
                                        height: '60px',
                                        color: "white", background: "#1BC5BD", borderColor: "#1BC5BD"
                                      }}
                                      onClick={() => onChanceIsSelectted(field.name)}
                                      icon={<CheckOutlined />}
                                    ></AntButton>
                                  </td>
                                    :
                                    <td>
                                      <AntButton
                                        type="default" style={{
                                          marginBottom: "23px",
                                          width: '60px',
                                          height: '60px', color: "white", background: "white", borderColor: "#1BC5BD"
                                        }}
                                        onClick={() => onChanceIsSelectted(field.name)}
                                        icon={<CloseOutlined />}
                                      ></AntButton>
                                    </td>}

                                  <td>
                                    <Form.Item
                                      name={[field.name, "productName"]}
                                    >
                                      <Input
                                        readOnly
                                        style={{
                                          width: '240px',
                                          height: '60px',
                                          // backgroundColor: '#1ef7c5',
                                        }}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      name={[field.name, "processingQuantity"]}
                                    >
                                      <InputNumber
                                        style={{
                                          width: '60px',
                                          height: '60px',
                                          border: '2px solid #07607e'
                                        }}
                                        onChange={() => onChanceIsSelectted(field.name)}
                                      />
                                    </Form.Item>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )
                  }}
                </Form.List>
              }
              <br></br>

              {Boolean(selectedItem) && selectedItem.isSelected && this.state.startVisible &&
                <Button
                  style={{
                    width: '380px',
                    marginBottom: '20px',
                    height: '60px',
                    backgroundColor: '#ffd966',
                    border: '2px solid #ffd966',
                    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
                    color: "black"
                  }}
                  onClick={this.start}
                >
                  <FormattedMessage id="WorkOrders.START" defaultMessage="START" />
                </Button>
              }
              <br></br>
              <br></br>

              {this.state.showListVisible &&
                <>
                  <Form.List name="showList">
                    {(fields, { add, remove }) => (
                      <table>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item
                                    name={[field.name, "productName"]}>
                                    <Input
                                      readOnly
                                      style={{
                                        width: '300px',
                                        height: '60px',
                                        // backgroundColor: '#1ef7c5',
                                      }} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item
                                    name={[field.name, "processingQuantity"]}>
                                    <InputNumber
                                      readOnly
                                      style={{
                                        width: '60px',
                                        height: '60px',
                                        border: '2px solid #07607e'
                                      }} />
                                  </Form.Item>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </Form.List>
                  <br></br>
                  <Row style={{ marginBottom: '10px' }}>
                    <Progress percent={this.state.progress} style={{ width: '380px' }} status="active" strokeColor={{ from: "#108ee9", to: "#87d068" }} />
                  </Row>
                </>
              }

              <Row>
                {this.state.stopVisible &&
                  <>
                    <Button
                      style={{
                        width: '380px',
                        marginBottom: '20px',
                        height: '60px',
                        backgroundColor: '#ffa3a3',
                        border: '2px solid #ffa3a3',
                        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
                        color: "black"
                      }}
                      onClick={this.stop}
                    >
                      <FormattedMessage id="WorkOrders.STOP" defaultMessage="STOP" />
                    </Button>
                    <br></br>
                  </>
                }
                {this.state.continueVisible &&
                  <>
                    <Button
                      style={{
                        width: '380px',
                        marginBottom: '20px',
                        height: '60px',
                        backgroundColor: '#ffd966',
                        border: '2px solid #ffd966',
                        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
                        color: "black"
                      }}
                      onClick={this.continue}
                    >
                      <FormattedMessage id="WorkOrders.CONTINUE" defaultMessage="CONTINUE" />
                    </Button>
                    <br></br>
                  </>
                }
                {this.state.endVisible &&
                  <>
                    <Button
                      style={{
                        width: '380px',
                        marginBottom: '20px',
                        height: '60px',
                        backgroundColor: '#00b050',
                        border: '2px solid #00b050',
                        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
                        color: "black"
                      }}
                      onClick={this.end}
                    >
                      <FormattedMessage id="WorkOrders.END" defaultMessage="END" />
                    </Button>
                    <br></br>
                  </>
                }
              </Row>
            </Form>
          </Col>
          <Col md={8}>
          </Col>
        </Row>

        <AntModal
          title={<Badge.Ribbon text={<FormattedMessage id="WorkOrders.Timeline" defaultMessage="Timeline" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
          open={this.state.modalStepShow}
          onCancel={() => {
            this.setState({ modalStepShow: false });
          }}
          width={900}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalStepShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
        >
          <div className="card card-custom" style={{ padding: "0.1rem", minHeight: "100%" }}>
            <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 ml-5 align-content-center">
              <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
              <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
              <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />

              <Row>
                <Col span={8}></Col>
                <AntButton
                  style={{ marginLeft: "10px", border: "0px", alignContent: "left" }}
                  type="text"
                  onClick={this.onChangeStepsPrevious}
                  icon={<LeftCircleTwoTone style={{ fontSize: "32px" }} />}
                />
                <Col span={1}></Col>
                <Col span={3}>
                  <Tag color="red"> {Boolean(this.state.modalStepDate) ? moment(this.state.modalStepDate).format("DD-MM-YYYY") : ""}</Tag>
                </Col>
                <Col span={1}></Col>
                <AntButton
                  style={{ marginRight: "10px", border: "0px", alignContent: "right" }}
                  type="text"
                  onClick={this.onChangeStepsNext}
                  icon={<RightCircleTwoTone style={{ fontSize: "32px" }} />}
                />
              </Row>
              <br />



              <div className="card card-custom" style={{ minHeight: "100%", padding: "2rem" }}>
                <Timeline mode="left">
                  {Boolean(this.state.stepList) &&
                    this.state.stepList.length > 0 &&
                    this.state.stepList.map((item) => {
                      return (
                        <Timeline.Item label={Boolean(item.startDate) && Boolean(item.endDate) ? moment(item.startDate).format("DD-MM-YYYY HH:mm") + " / " +
                          moment(item.endDate).format("DD-MM-YYYY HH:mm") : ""}
                          children={item.companyProjectName + " - " + item.productName + " - " + item.processingQuantity}>
                        </Timeline.Item>
                      );
                    })}
                </Timeline>
              </div>

            </div>
          </div>
        </AntModal>
      </div >
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrders);