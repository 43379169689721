import { DatePicker, Form } from "antd";
import React from "react";
import PrepareFormItemProps from "./ElementUtils";

const TimePickerComponent = ({ field, layout }) => {
  return (
    <>
      <Form.Item {...layout} {...PrepareFormItemProps(field)}>
        <DatePicker picker="time" format="HH:mm:ss" style={{ width: "100%" }} />
      </Form.Item>
    </>
  );
};

export default TimePickerComponent;
