import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Input, Tree, Form, Select, Badge, Space, } from "antd";
import { FcFolder, FcEditImage, FcAddressBook, FcAreaChart } from "react-icons/fc";
import { Menu, Item, useContextMenu } from "react-contexify";
import { FcTreeStructure, FcLibrary } from "react-icons/fc";
import { MdDelete } from "react-icons/md";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../../ApiConnector";
import { showError, showSuccess, error } from "../../../MessageHelper";
import DraggableModal from "../../../Components/DraggableModal";

const Menu_Budget = "Menu_Budget";
const Menu_Category = "Menu_Category";
const Menu_SubCategory = "Menu_SubCategory";
const Menu_Activity = "Menu_Activity";

const { Option } = Select;
//const { TextArea } = Input;

const { show } = useContextMenu();

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

// var dataList = [];
// const generateList = data => {
//   for (let i = 0; i < data.length; i++) {
//     const node = data[i];
//     dataList.push({ key: node.key, title: node.title });
//     if (node.children) {
//       generateList(node.children);
//     }
//   }
// };

export class EldenBudgetItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "EldenBudgetItems",
      controllerName: "eldenBudgetItems",
      id: null,
      description: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      refreshTree: true
    };
  }

  inputRef = React.createRef(null);

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "Page"
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      if (treenode.type === "Budget") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsBudget/" + obj.id);
      } else if (treenode.type === "Category") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsCategory/" + obj.id);
      } else if (treenode.type === "SubCategory") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsSubCategory/" + obj.id);
      } else if (treenode.type === "Activity") {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsActivity/" + obj.id);
      } else {
        response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitems/" + obj.id);
      }
    }
    if (Boolean(response) && response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "Budget" ? (
            <FcLibrary />
          ) : element.type === "Category" ? (
            <FcTreeStructure />
          ) : element.type === "SubCategory" ? (
            <FcAreaChart />
          ) : element.type === "Activity" ? (
            <FcAddressBook />
          ) : (
            <FcFolder />
          );

        element.title = element.value;
        element.id = element.key;
        element.key = element.key + "-" + element.type;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  formRef = React.createRef();
  formRefCategory = React.createRef();
  formRefSubCategory = React.createRef();
  formRefActivity = React.createRef();

  componentDidMount = async () => {
    this.fillTree();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseDisciplineIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/discipline");
    if (responseDisciplineIdSelectItems.type === "ERROR") {
      error(responseDisciplineIdSelectItems);
    }
    this.setState({
      disciplineIdSelectItems: Boolean(responseDisciplineIdSelectItems.data) ? responseDisciplineIdSelectItems.data : []
    });
    var responseActivityTypeIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/activityType");
    if (responseActivityTypeIdSelectItems.type === "ERROR") {
      error(responseActivityTypeIdSelectItems);
    }
    this.setState({
      activityTypeIdSelectItems: Boolean(responseActivityTypeIdSelectItems.data) ? responseActivityTypeIdSelectItems.data : []
    });
    var responseActivityIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/activity");
    if (responseActivityIdSelectItems.type === "ERROR") {
      error(responseActivityIdSelectItems);
    }
    this.setState({
      activityIdSelectItems: Boolean(responseActivityIdSelectItems.data) ? responseActivityIdSelectItems.data : []
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "Budget";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  onRightClick = (event, node) => {
    let parentNode = this.getParentNode(node);
    this.setState(
      {
        selectedNodeKey: node.key,
        selectedNode: node,
        parentNode: parentNode
      },
      this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
    );
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    // eslint-disable-next-line default-case
    switch (event.currentTarget.id) {
      case "create_category":
        this.setState({
          showCategoryModal: true,
          parentId: selectedNode.id,
          type: "Category"
        });
        break;

      case "create_subCategoryCategory":
        this.setState({
          showSubCategoryCategoryModal: true,
          eldenBudgetItemsCategoryId: selectedNode.id,
          type: "SubCategory"
        });
        break;

      case "create_subCategorySubCategory":
        this.setState({
          showSubCategorySubCategoryModal: true,
          parentId: selectedNode.id,
          type: "SubCategory"
        });
        break;

      case "create_activityCategory":
        this.setState({
          showActivityCategoryModal: true,
          eldenBudgetItemsCategoryId: selectedNode.id,
          type: "Activity"
        });
        break;

      case "create_activitySubCategory":
        this.setState({
          showActivitySubCategoryModal: true,
          eldenBudgetItemsSubCategoryId: selectedNode.id,
          type: "Activity"
        });
        break;

      case "edit_category":
        this.setState({
          showEditCategoryModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        });
        break;

      case "edit_subCategory":
        this.setState({
          showEditSubCategoryCategoryModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          eldenBudgetItemsCategoryId: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "edit_activity":
        this.setState({
          showEditActivityCategoryModal: true,
          id: selectedNode.id,
          eldenBudgetItemsCategoryId: selectedNode.id,
          eldenBudgetItemsSubCategoryId: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "delete_category":
        this.setState({
          showDeleteCategoryModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "delete_subCategory":
        this.setState({
          showDeleteSubCategoryModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;

      case "delete_activity":
        this.setState({
          showDeleteActivityModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;
    }
  };

  displayMenu = (e, type) => {
    console.log("TTT", type);
    if (Boolean(type)) {
      if (type === "Budget") {
        show(e, { id: Menu_Budget });
      } else if (type === "Category") {
        show(e, { id: Menu_Category });
      } else if (type === "SubCategory") {
        show(e, { id: Menu_SubCategory });
      } else if (type === "Activity") {
        show(e, { id: Menu_Activity });
      }
    }
  };

  deleteCategory = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteCategory/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteCategoryModal: false
        });
      }
    } else {
      showError("Category not found.");
    }
  };

  deleteSubCategory = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteSubCategory/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteSubCategoryModal: false
        });
      }
    } else {
      showError("SubCategory not found.");
    }
  };

  deleteActivity = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteActivity/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteActivityModal: false
        });
      }
    } else {
      showError("Activity not found.");
    }
  };

  saveCategory = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveCategory", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateCategory/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showCategoryModal: false,
        showEditCategoryModal: false
      });
    }
  };

  saveSubCategoryCategory = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      eldenBudgetItemsCategoryId: this.state.eldenBudgetItemsCategoryId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveSubCategory", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateSubCategory/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showSubCategoryCategoryModal: false,
        showEditSubCategoryCategoryModal: false
      });
    }
  };

  saveSubCategorySubCategory = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: this.state.parentId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveSubCategory", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateSubCategory/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showSubCategorySubCategoryModal: false,
        showEditSubCategorySubCategoryModal: false
      });
    }
  };

  saveActivityCategory = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      eldenBudgetItemsCategoryId: this.state.eldenBudgetItemsCategoryId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveActivity", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateActivity/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showActivityCategoryModal: false,
        showEditActivityCategoryModal: false
      });
    }
  };

  saveActivitySubCategory = async values => {
    const { process, selectedNode } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      eldenBudgetItemsSubCategoryId: this.state.eldenBudgetItemsSubCategoryId
    };
    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveActivity", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateActivity/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showActivitySubCategoryModal: false,
        showEditActivitySubCategoryModal: false
      });
    }
  };

  paste = () => {
    this.formRef.current.submit();
    this.formRefCategory.current.submit();
    this.formRefSubCategory.current.submit();
    this.formRefActivity.current.submit();
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      description: "",
      code: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null
    });
  };

  expandSelectedNode = () => {
    let key = this.state.selectedNodeKey;
    let node = this.state.selectedNode;
    if (Boolean(key)) {
      let expandedKeys = Boolean(this.state.expandedKeys) ? this.state.expandedKeys : [];
      if (Boolean(expandedKeys.find(p => p === key))) {
        this.loadData(node, false);
      } else {
        this.setState({
          expandedKeys: [...expandedKeys, key]
        });
        this.loadData(node, false);
      }
    }
  };

  itemTemplate(item) {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <h5 className="mb-2">{item.title}</h5>
        </div>
      </div>
    );
  }

  onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...this.state.nodes];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    this.setState({
      nodes: data
    });
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"EldenBudgetItems "} />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <br />
          <br />

          {this.state.refreshTree && (
            <Tree
              style={{ marginTop: "10px", lineHeight: "30px" }}
              className="draggable-tree"
              showLine={{ showLeafIcon: false }}
              showIcon
              checkStrictly={true}
              loadedKeys={this.state.loadedKeys}
              onLoad={this.onLoadData}
              loadData={this.loadData}
              onRightClick={({ event, node }) => this.onRightClick(event, node)}
              onExpand={this.onExpand}
              expandedKeys={this.state.expandedKeys}
              autoExpandParent={false}
              treeData={this.state.nodes}
              blockNode={true}
              // draggable
              onDragEnter={this.onDragEnter}
              onDrop={this.onDrop}
            />
          )}

          <Menu id={Menu_Budget}>
            <Item id="create_category" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenBudgetItems.CreateCategory" defaultMessage="Create Category" />
                </span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Category}>
            <Item id="edit_category" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenBudgetItems.Edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="create_subCategoryCategory" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenBudgetItems.Sub-Category" defaultMessage="Create Sub-Category" />
                </span>
              </Space>
            </Item>
            <Item id="create_activityCategory" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenBudgetItems.CreateActivity" defaultMessage="Create Activity" />
                </span>
              </Space>
            </Item>
            <Item id="delete_category" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="EldenBudgetItems.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_SubCategory}>
            <Item id="edit_subCategory" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenBudgetItems.Edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="create_subCategorySubCategory" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenBudgetItems.Sub-Category" defaultMessage="Create Sub-Category" />
                </span>
              </Space>
            </Item>
            <Item id="create_activitySubCategory" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenBudgetItems.CreateActivity" defaultMessage="Create Activity" />
                </span>
              </Space>
            </Item>
            <Item id="delete_subCategory" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="EldenBudgetItems.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Activity}>
            <Item id="edit_activity" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenBudgetItems.Edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="delete_activity" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="EldenBudgetItems.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>

          <DraggableModal
            title={<FormattedMessage id="EldenBudgetItems.Class" defaultMessage="Category" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showCategoryModal}
            onOk={() => this.formRefCategory.current.submit()}
            onCancel={() => {
              this.setState({ showCategoryModal: false });
            }}
            content={
              <>
                <Form initialValues={{ remember: false }} onFinish={this.saveCategory} onFinishFailed={onFinishFailed} ref={this.formRefCategory}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.Category" defaultMessage="Category" />}
                      name="category"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.UniformCode" defaultMessage="Uniform Code" />}
                      name="uniformCodeCategoryId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["uniformCodeCategoryIdSelectItems"]) &&
                          this.state["uniformCodeCategoryIdSelectItems"].length > 0 &&
                          this.state["uniformCodeCategoryIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenBudgetItems.Sub-Category" defaultMessage="Sub-Category" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showSubCategoryCategoryModal}
            onOk={() => this.formRefSubCategory.current.submit()}
            onCancel={() => {
              this.setState({ showSubCategoryCategoryModal: false });
            }}
            content={
              <>
                <Form
                  initialValues={{ remember: false }}
                  onFinish={this.saveSubCategoryCategory}
                  onFinishFailed={onFinishFailed}
                  ref={this.formRefSubCategory}
                >
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.Category" defaultMessage="Category" />}
                      name="subCategory"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.UniformCode" defaultMessage="Uniform Code" />}
                      name="uniformCodeSubCategoryId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["uniformCodeSubCategoryIdSelectItems"]) &&
                          this.state["uniformCodeSubCategoryIdSelectItems"].length > 0 &&
                          this.state["uniformCodeSubCategoryIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenBudgetItems.Sub-Category" defaultMessage="Sub-Category" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showSubCategorySubCategoryModal}
            onOk={() => this.formRefSubCategory.current.submit()}
            onCancel={() => {
              this.setState({ showSubCategorySubCategoryModal: false });
            }}
            content={
              <>
                <Form
                  initialValues={{ remember: false }}
                  onFinish={this.saveSubCategorySubCategory}
                  onFinishFailed={onFinishFailed}
                  ref={this.formRefSubCategory}
                >
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.Category" defaultMessage="Category" />}
                      name="subCategory"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.UniformCode" defaultMessage="Uniform Code" />}
                      name="uniformCodeSubCategoryId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["uniformCodeSubCategoryIdSelectItems"]) &&
                          this.state["uniformCodeSubCategoryIdSelectItems"].length > 0 &&
                          this.state["uniformCodeSubCategoryIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenBudgetItems.Activity" defaultMessage="Activity" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showActivityCategoryModal}
            onOk={() => this.formRefActivity.current.submit()}
            onCancel={() => {
              this.setState({ showActivityCategoryModal: false });
            }}
            content={
              <>
                <Form
                  initialValues={{ remember: false }}
                  onFinish={this.saveActivityCategory}
                  onFinishFailed={onFinishFailed}
                  ref={this.formRefActivity}
                >
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.Discipline" defaultMessage="Discipline" />}
                      name="disciplineId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["disciplineIdSelectItems"]) &&
                          this.state["disciplineIdSelectItems"].length > 0 &&
                          this.state["disciplineIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.ActivityType" defaultMessage="Activity Type" />}
                      name="activityTypeId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["activityTypeIdSelectItems"]) &&
                          this.state["activityTypeIdSelectItems"].length > 0 &&
                          this.state["activityTypeIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.Activity" defaultMessage="Activity" />}
                      name="activityId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["activityIdSelectItems"]) &&
                          this.state["activityIdSelectItems"].length > 0 &&
                          this.state["activityIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenBudgetItems.Activity" defaultMessage="Activity" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showActivitySubCategoryModal}
            onOk={() => this.formRefActivity.current.submit()}
            onCancel={() => {
              this.setState({ showActivitySubCategoryModal: false });
            }}
            content={
              <>
                <Form
                  initialValues={{ remember: false }}
                  onFinish={this.saveActivitySubCategory}
                  onFinishFailed={onFinishFailed}
                  ref={this.formRefActivity}
                >
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.Discipline" defaultMessage="Discipline" />}
                      name="disciplineId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["disciplineIdSelectItems"]) &&
                          this.state["disciplineIdSelectItems"].length > 0 &&
                          this.state["disciplineIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.ActivityType" defaultMessage="Activity Type" />}
                      name="activityTypeId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["activityTypeIdSelectItems"]) &&
                          this.state["activityTypeIdSelectItems"].length > 0 &&
                          this.state["activityTypeIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.Activity" defaultMessage="Activity" />}
                      name="activityId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["activityIdSelectItems"]) &&
                          this.state["activityIdSelectItems"].length > 0 &&
                          this.state["activityIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          {/* EditModal */}

          <DraggableModal
            title={<FormattedMessage id="EldenBudgetItems.Class" defaultMessage="Category" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showEditCategoryModal}
            onOk={() => this.formRefCategory.current.submit()}
            onCancel={() => {
              this.setState({ showEditCategoryModal: false });
            }}
            content={
              <>
                <Form initialValues={{ remember: false }} onFinish={this.saveCategory} onFinishFailed={onFinishFailed} ref={this.formRefCategory}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.Category" defaultMessage="Category" />}
                      name="category"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.UniformCode" defaultMessage="Uniform Code" />}
                      name="uniformCodeCategoryId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["uniformCodeCategoryIdSelectItems"]) &&
                          this.state["uniformCodeCategoryIdSelectItems"].length > 0 &&
                          this.state["uniformCodeCategoryIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenBudgetItems.Sub-Category" defaultMessage="Sub-Category" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showEditSubCategoryCategoryModal}
            onOk={() => this.formRefSubCategory.current.submit()}
            onCancel={() => {
              this.setState({ showEditSubCategoryCategoryModal: false });
            }}
            content={
              <>
                <Form
                  initialValues={{ remember: false }}
                  onFinish={this.saveSubCategoryCategory}
                  onFinishFailed={onFinishFailed}
                  ref={this.formRefSubCategory}
                >
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.Category" defaultMessage="Category" />}
                      name="subCategory"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.UniformCode" defaultMessage="Uniform Code" />}
                      name="uniformCodeSubCategoryId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["uniformCodeSubCategoryIdSelectItems"]) &&
                          this.state["uniformCodeSubCategoryIdSelectItems"].length > 0 &&
                          this.state["uniformCodeSubCategoryIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenBudgetItems.Activity" defaultMessage="Activity" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showEditActivityCategoryModal}
            onOk={() => this.formRefActivity.current.submit()}
            onCancel={() => {
              this.setState({ showEditActivityCategoryModal: false });
            }}
            content={
              <>
                <Form
                  initialValues={{ remember: false }}
                  onFinish={this.saveActivityCategory}
                  onFinishFailed={onFinishFailed}
                  ref={this.formRefActivity}
                >
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.Discipline" defaultMessage="Discipline" />}
                      name="disciplineId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["disciplineIdSelectItems"]) &&
                          this.state["disciplineIdSelectItems"].length > 0 &&
                          this.state["disciplineIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.ActivityType" defaultMessage="Activity Type" />}
                      name="activityTypeId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["activityTypeIdSelectItems"]) &&
                          this.state["activityTypeIdSelectItems"].length > 0 &&
                          this.state["activityTypeIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }

                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenBudgetItems.Activity" defaultMessage="Activity" />}
                      name="activityId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                        {Boolean(this.state["activityIdSelectItems"]) &&
                          this.state["activityIdSelectItems"].length > 0 &&
                          this.state["activityIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          {/* DeleteModal */}

          <DraggableModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={400}
            maskClosable={false}
            open={this.state.showDeleteCategoryModal}
            okText={<FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />}
            onOk={this.deleteCategory}
            onCancel={() => {
              this.setState({ showDeleteCategoryModal: false });
            }}
            content={
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={400}
            maskClosable={false}
            open={this.state.showDeleteSubCategoryModal}
            okText={<FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />}
            onOk={this.deleteSubCategory}
            onCancel={() => {
              this.setState({ showDeleteSubCategoryModal: false });
            }}
            content={
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={400}
            maskClosable={false}
            open={this.state.showDeleteActivityModal}
            okText={<FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />}
            onOk={this.deleteActivity}
            onCancel={() => {
              this.setState({ showDeleteActivityModal: false });
            }}
            content={
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EldenBudgetItems);
