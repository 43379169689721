import SyncIcon from "@material-ui/icons/Sync";
import { Button as AntButton, Cascader, Col, Form, Row, Select, Switch } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error, showSuccess } from "../../MessageHelper";

const { Option } = Select;
const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.data.id,
      lineage: node.data.lineage,
      title: node.data.menuTitle
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class PageFormInputCompany extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      dynamicMenuCascader: [],

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.getDynamicMenu();
  };

  getDynamicMenu = async () => {
    const responseTree = await handleRequest("GET", "/api/menus/tree");

    if (responseTree.data.length !== 0) {
      generateList(responseTree.data.treeTable);
      this.setState({
        dynamicMenuCascader: responseTree.data.cascader
      });
    }
  };
  getPageFormsByMenuId = async menuId => {
    const response = await handleRequest("GET", "/api/pageFormInputCompany/pageForm/" + menuId);
    if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pageFormList: response.data,
        menuId: menuId
      });
    } else {
      this.setState({
        pageFormList: []
      });
      let filter = {
        menuId: menuId
      };
      this.restartTable(filter);
    }
  };
  getPageFormsByMenuIdAndFormName = async formName => {
    const { menuId } = this.state;
    if (!Boolean(formName)) {
      this.setState(
        {
          loading: false,
          tableList: []
        },
        () => {
          this.formRef.current.setFieldsValue({
            pageFormInputList: null
          });
        }
      );
      return;
    }
    let filter = {
      menuId: menuId,
      formName: formName
    };
    this.restartTable(filter);
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  restartTable = async params => {
    this.setState({ loading: true });
    let url = "/api/pageFormInputCompany/all";
    const response = await handleRequest("POST", url, params);
    if (response.type === "ERROR") {
      error(response);
      this.setState(
        {
          loading: false,
          tableList: []
        },
        () => {
          this.formRef.current.setFieldsValue({
            pageFormInputList: null
          });
        }
      );
    } else {
      if (response.data.length !== 0) {
        let list = response.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState(
          {
            loading: false,
            tableList: list
          },
          () => {
            this.formRef.current.setFieldsValue({
              pageFormInputList: list
            });
          }
        );
      } else {
        this.setState(
          {
            loading: false,
            tableList: []
          },
          () => {
            this.formRef.current.setFieldsValue({
              pageFormInputList: null
            });
          }
        );
      }
    }
  };

  save = async values => {
    const newItem = { ...values };
    var response = await handleRequest("POST", "/api/pageFormInputCompany/import", newItem.pageFormInputList);

    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
    }
  };
  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };
  handleMenuChange = value => {
    this.getPageFormsByMenuId(value[value.length - 1]);
  };
  handleFormChange = value => {
    this.getPageFormsByMenuIdAndFormName(value);
  };
  sync = async () => {
    var response = await handleRequest("GET", "/api/pageFormInputCompany/sync");

    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col md={21}></Col>
          <Col md={1}></Col>
          <Col md={1}></Col>
          <Col md={1}>
            <AntButton style={{ border: "0px" }} onClick={this.sync} icon={<SyncIcon color="primary" />}></AntButton>
          </Col>
        </Row>
        <Form>
          <Form.Item
            {...layout}
            label={<FormattedMessage id="PageForm.menuId" defaultMessage="MenuId" />}
            name="menuId"
            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
          >
            <Cascader style={{ width: "100%" }} options={this.state.dynamicMenuCascader} onChange={this.handleMenuChange} />
          </Form.Item>
          {this.state.pageFormList && this.state.pageFormList.length > 0 ? (
            <Form.Item {...layout} label={"Page Form"} name="formName">
              <Select allowClear style={{ width: "100%" }} onChange={this.handleFormChange}>
                {this.state.pageFormList.map(item => (
                  <Option key={item.formName} value={item.formName}>
                    {item.formName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Form.List name="pageFormInputList">
              {(fields, { add, remove }) => {
                return (
                  <>
                    <Table bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>Field</th>
                          <th>Show On Form</th>
                          <th>Required</th>
                          <th>Show On What Is</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map(field => {
                          if (!Boolean(field) || !Boolean(this.state.tableList)) return null;
                          let f = this.state.tableList[field.name];
                          if (!f) return null;
                          return (
                            <tr key={field.name}>
                              <td>{f.label}</td>
                              <td>
                                <Form.Item noStyle name={[field.name, "showOnForm"]} valuePropName="checked">
                                  <Switch disabled={f.pageFormInput.required} />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "required"]} valuePropName="checked">
                                  <Switch disabled={f.pageFormInput.required} />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item noStyle name={[field.name, "showWhatIs"]} valuePropName="checked">
                                  <Switch />
                                </Form.Item>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                );
              }}
            </Form.List>
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Col>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            </Row>
          </Form>
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageFormInputCompany);
