import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Form, Select, Divider, Tooltip, Table, Input, Image, DatePicker, Badge, Button as AntButton, Col, Row, Switch, Upload } from "antd";
import moment from "moment";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import { UploadOutlined, CheckCircleTwoTone, DownloadOutlined } from "@ant-design/icons";
import ExcelReaderReadAllWorkSheets from "../excel/ExcelReaderReadAllWorkSheets";

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

export class CustomerLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      controllerName: "customerLog",

      id: null,
      tableList: [],
      filePath: "",
      fileListLogo: [],
      fileListInHouse: [],
      fileListEmployee: [],
      fileListOrganizational: [],

      selectedOptions: [],
      countrySelectItems: [],
      clientSelectItems: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.fillCountries();
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  getCusrtomerLogId = () => {
    return this.state.id;
  };
  fillCountries = async () => {
    let url = "/api/" + this.state.controllerName + "/countries";
    var response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else
      this.setState({
        countrySelectItems: Boolean(response.data) ? response.data : []
      });
  };

  fillCities = async countryId => {
    if (Boolean(countryId)) {
      let url = "/api/" + this.state.controllerName + "/cities/" + countryId;
      var response = await handleRequest("GET", url);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          cityIdSelectItems: Boolean(response.data) ? response.data : []
        });
      }
    } else {
      this.setState({
        cityIdSelectItems: []
      });
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileListLogo: [],
      fileListEmployee: [],
      fileListInHouse: [],
      fileListOrganizational: []
    });
  };

  setSelectedFiles = field => {
    let fileList = [];
    if (Boolean(field) && field.length > 0) {
      let files = field.split(",");
      for (let i = 0; i < files.length; i++) {
        fileList.push({
          uid: i,
          name: files[i],
          url: files[i],
          thumbUrl: files[i]
        });
      }
      return fileList;
    }
    return [];
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileListLogo = [];
    let fileListEmployee = [];
    let fileListInHouse = [];
    let fileListOrganizational = [];

    fileListLogo = this.setSelectedFiles(row.logoPath);

    fileListEmployee = this.setSelectedFiles(row.employeeListPath);

    fileListInHouse = this.setSelectedFiles(row.inHouseResourcesPath);

    fileListOrganizational = this.setSelectedFiles(row.organizationalChartPath);

    this.formRef.current.setFieldsValue({
      ...row
    });
    console.log(row);
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      fileListLogo: fileListLogo,
      fileListEmployee: fileListEmployee,
      fileListInHouse: fileListInHouse,
      fileListOrganizational: fileListOrganizational
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/customerLog/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  handleTabChange = key => {
    //console.log(key);
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/customerLog/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  getSelectedFiles = field => {
    if (Boolean(field) && Boolean(field.fileList) && field.fileList.length > 0) {
      let files = "";
      files = field.fileList[0].response.url;
      for (let i = 1; i < field.fileList.length; i++) {
        if (Boolean(field.fileList[i].response)) {
          files += "," + field.fileList[i].response.url;
        }
      }
      return files;
    }
    return field;
  };
  save = async values => {
    const newItem = { ...values, id: this.state.id };

    newItem.logoPath = this.getSelectedFiles(newItem.logoPath);

    newItem.employeeListPath = this.getSelectedFiles(newItem.employeeListPath);

    newItem.inHouseResourcesPath = this.getSelectedFiles(newItem.inHouseResourcesPath);

    newItem.organizationalChartPath = this.getSelectedFiles(newItem.organizationalChartPath);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/customerLog", newItem);
    } else {
      response = await handleRequest("PUT", "/api/customerLog/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          if (Array.isArray(element))
            element.forEach(element2 => {
              let obj = { columns: element2 };
              list.push(obj);
            });
          else {
            let obj = { columns: element };
            list.push(obj);
          }
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/customerLog/importDynamicaly", list);
          if (response.type === "ERROR") {
            error(response);
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          error("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        render: this.action,
        fixed: "left",
        width: "3%"
      },

      {
        title: "S/N",
        key: "id",
        fixed: "left",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="CustomerLog.name" defaultMessage="Name" />,
        key: "name",
        fixed: "left",
        ellipsis: true,
        render: record => {
          return record.name;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.name) ? this.state.pagination.name : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  name: e.target.value,
                  nameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.nameColor) ? this.state.pagination.nameColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },

      {
        title: <FormattedMessage id="CustomerLog.shortName" defaultMessage="Short Name" />,
        key: "shortName",
        render: record => {
          return record.shortName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <TextArea
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.shortName) ? this.state.pagination.shortName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  shortName: e.target.value,
                  shortNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.shortNameColor) ? this.state.pagination.shortNameColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },

      {
        title: "Country",
        key: "countryId",
        render: record => {
          return record.countryName;
        }
      },
      {
        title: "City",
        key: "cityId",
        render: record => {
          return record.cityName;
        }
      },
      {
        title: "Address",
        key: "address",
        render: record => {
          return record.address;
        }
      },
      {
        title: <FormattedMessage id="CustomerLog.logoPath" defaultMessage="Logo" />,
        key: "logoPath",
        render: row => {
          let path = row.logoPath;
          if (Boolean(path)) return <Image width={80} src={path} />;
          else return "";
        }
      },
      {
        title: <FormattedMessage id="CustomerLog.manufacturer" defaultMessage="Manufacturer" />,
        key: "manufacturer",
        render: row => {
          let path = row.manufacturer;
          if (Boolean(path)) return <CheckCircleTwoTone style={{ fontSize: "24px", color: "#52c41a" }} twoToneColor="#52c41a" />;
          else return "";
        }
      },
      {
        title: <FormattedMessage id="CustomerLog.supplier" defaultMessage="Supplier" />,
        key: "supplier",
        render: row => {
          let path = row.supplier;
          if (Boolean(path)) return <CheckCircleTwoTone style={{ fontSize: "24px", color: "#52c41a" }} twoToneColor="#52c41a" />;
          else return "";
        }
      },
      {
        title: <FormattedMessage id="CustomerLog.about" defaultMessage="About" />,
        key: "about",
        render: record => {
          return record.about;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.about) ? this.state.pagination.about : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  about: e.target.value,
                  aboutColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.aboutColor) ? this.state.pagination.aboutColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="CustomerLog.vision" defaultMessage="Vision" />,
        key: "vision",
        render: record => {
          return record.vision;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.vision) ? this.state.pagination.vision : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  vision: e.target.value,
                  visionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.visionColor) ? this.state.pagination.visionColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="CustomerLog.mission" defaultMessage="Mission" />,
        key: "mission",
        render: record => {
          return record.mission;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.mission) ? this.state.pagination.mission : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  mission: e.target.value,
                  missionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.missionColor) ? this.state.pagination.missionColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="CustomerLog.website" defaultMessage="Website" />,
        key: "website",
        render: record => {
          return record.website;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.website) ? this.state.pagination.website : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  website: e.target.value,
                  websiteColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.websiteColor) ? this.state.pagination.websiteColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="CustomerLog.Phone" defaultMessage="Phone" />,
        key: "Phone",
        render: record => {
          return record.phone;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.phone) ? this.state.pagination.phone : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  phone: e.target.value,
                  phoneColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.phoneColor) ? this.state.pagination.phoneColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="CustomerLog.employeeListPath" defaultMessage="Employee List" />,
        key: "employeeListPath",
        render: row => {
          let path = row.employeeListPath;
          if (Boolean(path))
            return (
              <AntButton
                style={{
                  marginLeft: "2px",
                  marginTop: "-2px",
                  alignContent: "center"
                }}
                href={path}
                target="_blank"
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
              ></AntButton>
            );
          else return "";
        }
      },
      {
        title: <FormattedMessage id="CustomerLog.inHouseResourcesPath" defaultMessage="In House Resources" />,
        key: "inHouseResourcesPath",
        render: row => {
          let path = row.inHouseResourcesPath;
          if (Boolean(path))
            return (
              <AntButton
                style={{
                  marginLeft: "2px",
                  marginTop: "-2px",
                  alignContent: "center"
                }}
                href={path}
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
              ></AntButton>
            );
          else return "";
        }
      },
      {
        title: <FormattedMessage id="CustomerLog.organizationalChartPath" defaultMessage="Organizational Chart" />,
        key: "organizationalChartPath",
        render: row => {
          let path = row.organizationalChartPath;
          if (Boolean(path))
            return (
              <AntButton
                style={{
                  marginLeft: "2px",
                  marginTop: "-2px",
                  alignContent: "center"
                }}
                href={path}
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
              ></AntButton>
            );
          else return "";
        }
      },
      {
        title: "Last Update",
        key: "lastUpdated",
        ellipsis: true,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
              exportClick: false
            })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
              <ExcelColumn label="Name" value="name" />
              <ExcelColumn label="Customer" value="customer" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    return (
      <Badge.Ribbon text="Customer Log" placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={22}></Col>
              {/* <Col md={1}>{excelExport}</Col> */}
              <Col md={1}>
                <ExcelReaderReadAllWorkSheets onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReaderReadAllWorkSheets>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.name" defaultMessage="Name" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input placeholder="Name" style={{ width: "100%" }} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.shortName" defaultMessage="Short Name" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="shortName"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input placeholder="Short Name" style={{ width: "100%" }} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CompanyProject.Country" defaultMessage="Country" />}
                  name="countryId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    onChange={value => {
                      this.fillCities(value);
                    }}
                  >
                    {this.state.countrySelectItems.map(i => (
                      <Option value={i.key}>{i.value}</Option>
                    ))}
                  </Select>
                </Form.Item>
              }
              {""}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.cityId" defaultMessage="City" />}
                  name="cityId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select style={{ width: "100%" }} allowClear showSearch optionFilterProp="children">
                    {Boolean(this.state["cityIdSelectItems"]) &&
                      this.state["cityIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.address" defaultMessage="Address" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="address"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input placeholder="Address" style={{ width: "100%" }} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.logoPath" defaultMessage="Logo" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="logoPath"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Upload
                    {...props}
                    showUploadList={true}
                    fileList={this.state.fileListLogo}
                    onChange={({ fileList }) => {
                      if (fileList.length === 1) {
                        if (Boolean(fileList[0].response)) {
                          fileList[0].url = fileList[0].response.url;
                          showSuccess("file uploaded successfully");
                          // fileName: info.file.name,
                        }
                        this.setState({
                          fileListLogo: fileList
                        });
                      }
                    }}
                    beforeUpload={fileListLogo => {
                      if (this.state.fileListLogo.length > 0) {
                        showError("You have been already choose a file! For change firstly remove the uploaded file.");
                        return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      this.setState({
                        fileListLogo: []
                      });
                    }}
                  >
                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> Upload
                    </AntButton>
                  </Upload>
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.cr" defaultMessage="CR" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="cr"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input placeholder="CR" style={{ width: "100%" }} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.about" defaultMessage="About" />}
                  style={{
                    marginBottom: "4px"
                  }}
                  name="about"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <TextArea placeholder="About" rows={2} style={{ width: "100%" }} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.vision" defaultMessage="Vision" />}
                  style={{
                    marginBottom: "4px"
                  }}
                  name="vision"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <TextArea placeholder="Vision" rows={2} style={{ width: "100%" }} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.mission" defaultMessage="Mission" />}
                  style={{
                    marginBottom: "4px"
                  }}
                  name="mission"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <TextArea placeholder="Mission" rows={2} style={{ width: "100%" }} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.website" defaultMessage="Website" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="website"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input placeholder="Website" style={{ width: "100%" }} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.Phone" defaultMessage="Phone" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="phone"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input placeholder="Phone" style={{ width: "100%" }} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.taxNumber" defaultMessage="Tax Number" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="taxNumber"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input placeholder="Tax Number" style={{ width: "100%" }} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.vatNumer" defaultMessage="Vat Number" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="vatNumber"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input placeholder="Vat Number" style={{ width: "100%" }} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.bankName" defaultMessage="Bank Name" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="bankName"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input placeholder="Bank Name" style={{ width: "100%" }} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.ibanNo" defaultMessage="IBAN" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="ibanNo"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input placeholder="IBAN" style={{ width: "100%" }} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.employeeListPath" defaultMessage="Employee List" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="employeeListPath"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Upload
                    {...props}
                    showUploadList={true}
                    fileList={this.state.fileListEmployee}
                    onChange={({ fileList }) => {
                      if (fileList.length === 1) {
                        if (Boolean(fileList[0].response)) {
                          fileList[0].url = fileList[0].response.url;
                          showSuccess("file uploaded successfully");
                          // fileName: info.file.name,
                        }
                        this.setState({
                          fileListEmployee: fileList
                        });
                      }
                    }}
                    beforeUpload={fileListEmployee => {
                      if (this.state.fileListEmployee.length > 0) {
                        showError("You have been already choose a file! For change firstly remove the uploaded file.");
                        return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      this.setState({
                        fileListEmployee: []
                      });
                    }}
                  >
                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> Upload
                    </AntButton>
                  </Upload>
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.inHouseResourcesPath" defaultMessage="In House Resources" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="inHouseResourcesPath"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Upload
                    {...props}
                    showUploadList={true}
                    fileList={this.state.fileListInHouse}
                    onChange={({ fileList }) => {
                      if (fileList.length === 1) {
                        if (Boolean(fileList[0].response)) {
                          fileList[0].url = fileList[0].response.url;
                          showSuccess("file uploaded successfully");
                          // fileName: info.file.name,
                        }
                        this.setState({
                          fileListInHouse: fileList
                        });
                      }
                    }}
                    beforeUpload={fileListInHouse => {
                      if (this.state.fileListInHouse.length > 0) {
                        showError("You have been already choose a file! For change firstly remove the uploaded file.");
                        return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      this.setState({
                        fileListInHouse: []
                      });
                    }}
                  >
                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> Upload
                    </AntButton>
                  </Upload>
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="CustomerLog.organizationalChartPath" defaultMessage="Organizational Chart" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="organizationalChartPath"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Upload
                    {...props}
                    showUploadList={true}
                    fileList={this.state.fileListOrganizational}
                    onChange={({ fileList }) => {
                      if (fileList.length === 1) {
                        if (Boolean(fileList[0].response)) {
                          fileList[0].url = fileList[0].response.url;
                          showSuccess("file uploaded successfully");
                          // fileName: info.file.name,
                        }
                        this.setState({
                          fileListOrganizational: fileList
                        });
                      }
                    }}
                    beforeUpload={fileListOrganizational => {
                      if (this.state.fileListOrganizational.length > 0) {
                        showError("You have been already choose a file! For change firstly remove the uploaded file.");
                        return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      this.setState({
                        fileListOrganizational: []
                      });
                    }}
                  >
                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> Upload
                    </AntButton>
                  </Upload>
                </Form.Item>
              }{" "}
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              rowKey="id"
              size="small"
              scroll={{ x: 2000, y: 1000, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLog);
