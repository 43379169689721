import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Select, Input, Button as AntButton, Tree, Checkbox, Space } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import { Table as TableBoot } from "react-bootstrap";
import { PlusOutlined, MinusCircleOutlined, CheckOutlined, MoreOutlined, UserOutlined } from "@ant-design/icons";
import DraggableModal from "../../../Components/DraggableModal";
import { MoreHoriz } from "@material-ui/icons";

const { Option } = Select;

export class Location extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "Location",
      controllerName: "pageSettings",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      houseRoomList: [],
      loadingButtonSubmit: false,
      nodes: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    fillFilterOperations();

    var responseMail = await handleRequest("GET", "/api/" + this.state.controllerName + "/pageLocations/" + this.props.pageName);
    if (responseMail.type === "ERROR") {
      error(responseMail);
    } else {
      if (Boolean(responseMail.data)) {
        let data = responseMail.data;
        console.log("data", data);
        if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
          this.formRef.current.setFieldsValue({
            locations: data
          });
        }
      } else {
        if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
          this.formRef.current.resetFields();
        }
      }
    }
  };

  fillComboboxes = async () => {
    var responseAction = await handleRequest("GET", "/api/" + this.state.controllerName + "/actions/" + this.props.pageName);
    if (responseAction.type === "ERROR") {
      error(responseAction);
    } else {
      this.setState({
        actionsSelectItems: Boolean(responseAction.data) ? responseAction.data : []
      });
    }

    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(response.data))
        this.setState({
          nodes: Boolean(response.data) ? response.data : []
        });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: "",
        tableVisibleModal: true
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder,
          tableVisibleModal: true
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: "",
          tableVisibleModal: true
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  resetInputs = () => {};

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  save = async values => {
    console.log("values", values);
    let list = [];
    if (Boolean(values) && Boolean(values.locations) && values.locations.length > 0) {
      values.locations.forEach(element => {
        element.pageName = this.props.pageName;

        let notificationTos = [];
        if (Boolean(element.notificationTos) && element.notificationTos.length > 0) {
          element.notificationTos.forEach(to => {
            console.log("to", to);
            if (Boolean(to.id) && to.id > 0) {
              notificationTos.push({ id: to.id, userId: to.userId, departmentId: to.departmentId });
            } else {
              let type = to.split("~")[0];
              let value = to.split("~")[1];
              if (Boolean(type) && Boolean(value)) {
                if (type === "CODE") {
                  notificationTos.push({ userId: value });
                } else if (type === "CATEGORY") {
                  notificationTos.push({ departmentId: value });
                }
              }
            }
          });
        }
        element.notificationTos = notificationTos;

        let notificationInfos = [];
        if (Boolean(element.notificationInfos) && element.notificationInfos.length > 0) {
          element.notificationInfos.forEach(info => {
            if (Boolean(info.id) && info.id > 0) {
              notificationInfos.push({ id: info.id, userId: info.userId, departmentId: info.departmentId });
            } else {
              let type = info.split("~")[0];
              let value = info.split("~")[1];
              if (Boolean(type) && Boolean(value)) {
                if (type === "CODE") {
                  notificationInfos.push({ userId: value });
                } else if (type === "CATEGORY") {
                  notificationInfos.push({ departmentId: value });
                }
              }
            }
          });
        }
        element.notificationInfos = notificationInfos;

        list.push(element);
      });
    }
    console.log("list", list);

    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveLocation", list);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.props.nextTab();
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  onCheckTo = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    this.setState({
      checkedKeysTo: checkedKeys,
      halfCheckedKeysTo: e.halfCheckedKeys,
      checkedNodesTo: e.checkedNodes
    });
  };

  onCheckInfo = async (checkedKeys, e) => {
    this.setState({
      checkedKeysInfo: checkedKeys,
      halfCheckedKeysInfo: e.halfCheckedKeys,
      checkedNodesInfo: e.checkedNodes
    });
  };

  render() {
    const suffix = (
      <UserOutlined
        style={{
          fontSize: 16,
          color: "#1677ff"
        }}
      />
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} ref={this.formRef}>
          <Form.List name="locations">
            {(fields, { add, remove }) => {
              const handleAddClick = () => {
                let locations = this.formRef.current.getFieldValue("locations");

                if (Boolean(locations) && locations.length > 0) {
                  locations.push({
                    notificationTos: [],
                    notificationInfos: []
                  });
                } else {
                  locations = [
                    {
                      notificationTos: [],
                      notificationInfos: []
                    }
                  ];
                }
                this.formRef.current.setFieldsValue({
                  locations: locations
                });
              };

              const onChanceToSelectted = async fieldKey => {
                let locations = this.formRef.current.getFieldValue("locations");
                let row = locations[fieldKey];
                console.log("row", row);

                if (Boolean(row) && Boolean(row.id) && row.id > 0) {
                  var res = await handleRequest("GET", "/api/" + this.state.controllerName + "/notificationTos/" + row.id);
                  if (res.type === "ERROR") {
                    error(res);
                  } else {
                    let list = Boolean(res.data) ? res.data : [];
                    let keyList = [];
                    list.forEach(element => {
                      if (Boolean(element.userId) && element.userId > 0) {
                        keyList.push("CODE~" + element.userId);
                      } else if (Boolean(element.departmentId) && element.departmentId > 0) {
                        keyList.push("CATEGORY~" + element.departmentId);
                      }
                    });
                    console.log(keyList);
                    this.setState({
                      checkedKeysTo: keyList,
                      halfCheckedKeysTo: null,
                      checkedNodesTo: null,

                      fieldKey: fieldKey,
                      modalToDepartmentShow: true
                    });
                  }
                } else {
                  this.setState({
                    checkedKeysTo: null,
                    halfCheckedKeysTo: null,
                    checkedNodesTo: null,

                    fieldKey: fieldKey,
                    modalToDepartmentShow: true
                  });
                }
              };

              const onChanceInfoSelectted = async fieldKey => {
                let locations = this.formRef.current.getFieldValue("locations");
                let row = locations[fieldKey];
                console.log("row", row);

                if (Boolean(row) && Boolean(row.id) && row.id > 0) {
                  var res = await handleRequest("GET", "/api/" + this.state.controllerName + "/notificationInfos/" + row.id);
                  if (res.type === "ERROR") {
                    error(res);
                  } else {
                    let list = Boolean(res.data) ? res.data : [];
                    let keyList = [];
                    list.forEach(element => {
                      if (Boolean(element.userId) && element.userId > 0) {
                        keyList.push("CODE~" + element.userId);
                      } else if (Boolean(element.departmentId) && element.departmentId > 0) {
                        keyList.push("CATEGORY~" + element.departmentId);
                      }
                    });
                    console.log(keyList);
                    this.setState({
                      checkedKeysInfo: keyList,
                      halfCheckedKeysInfo: null,
                      checkedNodesInfo: null,

                      fieldKey: fieldKey,
                      modalInfoDepartmentShow: true
                    });
                  }
                } else {
                  this.setState({
                    checkedKeysInfo: null,
                    halfCheckedKeysInfo: null,
                    checkedNodesInfo: null,

                    fieldKey: fieldKey,
                    modalInfoDepartmentShow: true
                  });
                }
              };
              return (
                <>
                  <TableBoot bordered size="sm">
                    <thead>
                      <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="Location.Action" defaultMessage="Action" />
                        </th>
                        <th colSpan="2" style={{ textAlign: "center" }}>
                          <FormattedMessage id="Location.To" defaultMessage="To" />
                        </th>
                        <th colSpan="2" style={{ textAlign: "center" }}>
                          <FormattedMessage id="Location.Info" defaultMessage="Info" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map(field => {
                        return (
                          <tr key={field.key}>
                            <Form.Item hidden name={[field.name, "id"]}></Form.Item>
                            <Form.Item hidden name={[field.name, "notificationTos"]}></Form.Item>
                            <Form.Item hidden name={[field.name, "notificationInfos"]}></Form.Item>
                            <td>
                              <Form.Item noStyle name={[field.name, "pageActionId"]}>
                                <Select showSearch allowClear optionFilterProp="children" style={{ width: "300px" }}>
                                  {Boolean(this.state["actionsSelectItems"]) &&
                                    this.state["actionsSelectItems"].length > 0 &&
                                    this.state["actionsSelectItems"].map(i => (
                                      <Option key={i.id} value={i.id}>
                                        {i.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>
                            <td>
                              <Space.Compact>
                                <Form.Item noStyle name={[field.name, "toMessage"]}>
                                  <Input placeholder="Message"></Input>
                                </Form.Item>
                                <AntButton
                                  type="default"
                                  style={{
                                    color: "black",
                                    background: "white"
                                  }}
                                  onClick={() => onChanceToSelectted(field.name)}
                                  icon={<MoreHoriz />}
                                ></AntButton>
                              </Space.Compact>
                            </td>
                            <td>
                              <Space.Compact>
                                <Form.Item noStyle name={[field.name, "infoMessage"]}>
                                  <Input placeholder="Message" />
                                </Form.Item>
                                <AntButton
                                  type="default"
                                  style={{
                                    color: "black",
                                    background: "white"
                                  }}
                                  onClick={() => onChanceInfoSelectted(field.name)}
                                  icon={<MoreHoriz />}
                                ></AntButton>
                              </Space.Compact>
                            </td>
                            <td>
                              <MinusCircleOutlined onClick={() => remove(field.name)} />
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={11}>
                          <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                            <FormattedMessage id={"Location.AddNew"} defaultMessage={"Add New"} />
                          </AntButton>
                        </td>
                      </tr>
                    </tbody>
                  </TableBoot>
                </>
              );
            }}
          </Form.List>
          <br />
          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={19} lg={19} xl={19} xxl={19}></Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Button type="submit" style={{ width: "100%" }} variant="success">
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </Button>
            </Col>
          </Row>
        </Form>

        <DraggableModal
          title={<FormattedMessage id="Location.To" defaultMessage="To" />}
          closable="false"
          open={this.state.modalToDepartmentShow}
          onCancel={() => {
            this.setState({ modalToDepartmentShow: false });
          }}
          width={800}
          centered
          okButtonProps={{ hidden: false }}
          onOk={() => {
            const { checkedKeysTo, fieldKey } = this.state;

            if (Boolean(checkedKeysTo) && checkedKeysTo.length > 0) {
              const locations = this.formRef.current.getFieldValue("locations");

              const row = locations[fieldKey];
              console.log("row", row);

              row.notificationTos = checkedKeysTo;

              console.log("locations", locations);

              this.formRef.current.setFieldsValue({
                locations: locations
              });
              this.setState({ modalToDepartmentShow: false });
            } else {
              showWarning("Please select item.");
            }
          }}
          content={
            <Tree
              blockNode
              checkable
              showLine={true}
              onCheck={this.onCheckTo}
              checkedKeys={this.state.checkedKeysTo}
              halfCheckedKeys={this.state.halfCheckedKeysTo}
              treeData={this.state.nodes}
            />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="Location.Info" defaultMessage="Info" />}
          closable="false"
          open={this.state.modalInfoDepartmentShow}
          onCancel={() => {
            this.setState({ modalInfoDepartmentShow: false });
          }}
          width={800}
          centered
          okButtonProps={{ hidden: false }}
          onOk={() => {
            const { checkedKeysInfo, fieldKey } = this.state;
            if (Boolean(checkedKeysInfo) && checkedKeysInfo.length > 0) {
              const locations = this.formRef.current.getFieldValue("locations");
              const row = locations[fieldKey];
              console.log("row", row);
              row.notificationInfos = checkedKeysInfo;
              console.log("locations", locations);
              this.formRef.current.setFieldsValue({
                locations: locations
              });
              this.setState({ modalInfoDepartmentShow: false });
            } else {
              showWarning("Please select item.");
            }
          }}
          content={
            <Tree
              blockNode
              checkable
              showLine={true}
              onCheck={this.onCheckInfo}
              checkedKeys={this.state.checkedKeysInfo}
              halfCheckedKeys={this.state.halfCheckedKeysInfo}
              treeData={this.state.nodes}
            />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="Location.MessageTo" defaultMessage="Message To" />}
          closable="false"
          open={this.state.modalMessageTo}
          onCancel={() => {
            this.setState({ modalMessageTo: false });
          }}
          width={800}
          centered
          okButtonProps={{ hidden: false }}
          onOk={() => this.save()}
          content={
            <>
              <Form.Item
                label={<FormattedMessage id="Location.MarkAsSeen" defaultMessage="Mark as Seen" />}
                name="markAsSeen"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox onChange={e => {}}></Checkbox>
              </Form.Item>

              <Form.Item
                label={<FormattedMessage id="Location.RemindMeLater" defaultMessage="Remind me later" />}
                name="remindMeLater"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox onChange={e => {}}></Checkbox>
              </Form.Item>

              <Form.Item
                label={<FormattedMessage id="Location.MarkAsImportant" defaultMessage="Mark as Important" />}
                name="markAsImportant"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox onChange={e => {}}></Checkbox>
              </Form.Item>
            </>
          }
        />
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Location);
