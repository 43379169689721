import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { Form, Input, Col, Row } from "antd";
import { handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";

export class InventoryMaterialProperty extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      itemId: null,
      inventoryId: null,
      propertyGroupId: null,
      propertyList: [],

      id: null,
      activeKey: null,
      list: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let propertyGroupId = Boolean(this.props) && Boolean(this.props.propertyGroupId) ? this.props.propertyGroupId : null;
    let itemId = Boolean(this.props) && Boolean(this.props.itemId) ? this.props.itemId : null;
    let inventoryId = Boolean(this.props) && Boolean(this.props.inventoryId) ? this.props.inventoryId : null;

    let prevPropertyGroupId = this.state.propertyGroupId; //Boolean(prevProps) && Boolean(prevProps.logId) ? prevProps.logId : null;
    let prevItemId = this.state.itemId;
    let prevInventoryId = this.state.inventoryId;

    if (prevPropertyGroupId !== propertyGroupId || prevItemId !== itemId || prevInventoryId !== inventoryId) {
      this.initialize();
    }
  };

  initialize = async () => {
    this.setState(
      {
        propertyGroupId: Boolean(this.props) && Boolean(this.props.propertyGroupId) ? this.props.propertyGroupId : null,
        itemId: Boolean(this.props) && Boolean(this.props.itemId) ? this.props.itemId : null,
        inventoryId: Boolean(this.props) && Boolean(this.props.inventoryId) ? this.props.inventoryId : null
      },
      () => {
        this.fillProperties();
      }
    );
  };

  fillProperties = async () => {
    const { itemId, propertyGroupId, inventoryId } = this.state;

    var responseInventoryProperty = await handleRequest(
      "GET",
      "/api/inventoryMaterialProperty/" + propertyGroupId + "/" + itemId + "/" + inventoryId
    );
    var responseItemProperty = await handleRequest("GET", "/api/itemProperty/hasproperty/" + propertyGroupId + "/" + itemId);

    if (responseInventoryProperty.type === "ERROR" || responseItemProperty.type === "ERROR") {
      error(responseInventoryProperty);
      error(responseItemProperty);
    } else {
      let propertyList = responseInventoryProperty.data;
      let itemPropertyList = responseItemProperty.data;
      if (!Boolean(propertyList)) propertyList = [];

      if (Boolean(itemPropertyList))
        itemPropertyList.forEach(element => {
          if (propertyList.length > 0) {
            let f = propertyList.find(p => p.itemPropertyId === element.id);
            if (Boolean(f)) {
              f.name = f.property.description;
            } else
              propertyList.push({
                id: null,
                inventoryId: inventoryId,
                itemId: itemId,
                propertyGroupId: propertyGroupId,
                itemPropertyId: element.id,
                propertyId: element.propertyId,
                name: element.property.description,
                value: ""
              });
          } else
            propertyList.push({
              id: null,
              inventoryId: inventoryId,
              itemId: itemId,
              propertyGroupId: propertyGroupId,
              itemPropertyId: element.id,
              propertyId: element.propertyId,
              name: element.property.description,
              value: ""
            });
        });

      console.log(propertyList);

      this.setState({
        propertyList: propertyList
      });
    }
  };

  onChange = activeKey => {
    this.setState({ activeKey });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/propertyGroup/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/propertyGroup/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const list = this.state.propertyList;
    console.log(list);
    var response = await handleRequest("POST", "/api/inventoryMaterialProperty/saveall", list);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <div>
            {this.state.propertyList.map((i, index) => (
              <Form.Item
                {...layout}
                label={i.name}
                style={{
                  marginBottom: 0
                }}
                name={i.name}
              >
                <Row>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Input
                      placeholder={i.name}
                      style={{ width: "100%" }}
                      value={i.value}
                      onChange={e => {
                        let list = this.state.propertyList;

                        list[index].value = e.target.value;

                        this.setState({
                          propertyList: list
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
            ))}

            <div>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryMaterialProperty);
