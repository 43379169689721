import React, { Component } from "react";
import { connect } from "react-redux";
import "primereact/resources/themes/nova-accent/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/components/datatable/DataTable";
import { Column } from "primereact/components/column/Column";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Form, Col, Row } from "react-bootstrap";
import { handleRequest, error, showError, showSuccess, API_BASE_ROOT as API_BASE } from "../../ApiConnector";
import CustomMaterialMenu from "../CustomMenu/CustomMaterialMenu";
import { Modal, Button as BootButton } from "react-bootstrap";
import { Tree } from "antd";
import { Select } from "antd";
import { Button as AntButton } from "antd";

const { Option } = Select;

export class MenuAuthorization extends Component {
  constructor() {
    super();

    var newArray = [{ name: "All", items: [] }];
    this.state = {
      modalShow: false,
      departmentName: "",
      departmentId: null,
      departments: [],

      disciplineName: "",
      disciplineId: null,
      disciplines: [],

      roleGroupName: "",
      roleGroupId: null,
      roleGroups: [],

      designationName: "",
      designationId: null,
      designations: [],

      userName: "Select User",
      userId: 0,
      users: [],

      actionNames: [],
      actionKeys: [],
      actions: [],

      formNames: [],
      formId: "",
      forms: newArray,

      selectedRow: {},
      authorizationList: [],

      selectedNodeKeys: [],
      checkedKeys: [],
      halfCheckedKeys: [],
      nodes: [],
      userKeys: [],
      menuList: [],
      userList: [],
      allUsers: [],
      defaultExpandedKeys: ["All"],
      checkedNodes: []
    };

    console.log("constructure", this.state.forms);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.onClickModal = this.onClickModal.bind(this);
    this.onHideModal = this.onHideModal.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);
  }

  componentDidMount = async () => {
    //const response = await axios.get(`${API_BASE}/Department/getAll`,config);
    try {
      this.setState({
        menuList: this.props.auth.authToken.authList.filter(p => p.action === "view")
      });
    } catch (error) {}

    this.getUsers();

    const responseTreeDynamicMenu = await handleRequest("GET", "/api/menus/tree/authorization");
    if (responseTreeDynamicMenu.data.length !== 0) {
      console.log(responseTreeDynamicMenu.data);
      this.setState({
        nodes: responseTreeDynamicMenu.data
      });
    } else {
      this.setState({
        nodes: []
      });
    }
  };

  handleUserChange2 = value => {
    this.setState(
      {
        userKeys: value
      },
      this.setCheckedNodes
    );
  };

  setCheckedNodes = async () => {
    var list = [];
    var nodes = [];
    var half = [];
    const userKeys = this.state.userKeys;
    console.log(userKeys);

    if (Boolean(userKeys) && userKeys.length > 0) {
      const response = await handleRequest("GET", "/api/authorizations/intersecting/" + userKeys);

      if (response.type === "Error") {
        this.showError(response.message);
      } else {
        const data = response.data;
        console.log(data);
        if (Boolean(data)) {
          data.forEach(item => {
            if (Boolean(item.actionId)) {
              if (list.findIndex(p => p === item.actionId + "ACT") === -1) {
                list.push(item.actionId + "ACT");
                let obj = { key: item.actionId + "ACT", menuId: item.menuId };
                nodes.push(obj);
              }
            } else if (list.findIndex(p => p === item.menuId + "MENU") === -1 && !item.halfChecked) {
              list.push(item.menuId + "MENU");
              let obj = { key: item.menuId + "MENU", menuId: item.menuId };
              nodes.push(obj);
            } else if (half.findIndex(p => p === item.menuId + "MENU") === -1 && item.halfChecked) {
              half.push(item.menuId + "MENU");
              let obj = { key: item.menuId + "MENU", menuId: item.menuId, halfChecked: true };
              nodes.push(obj);
            }
          });
        }
      }
    }

    this.setState({
      checkedKeys: list.length > 0 ? list : [],
      halfCheckedKeys: half.length > 0 ? half : [],
      checkedNodes: nodes
    });
  };

  handleFormChange = e => {
    console.log(e.value);
    if (e.value.includes("All")) {
      this.setState({
        formNames: ["All"]
      });
      return;
    }

    this.setState({
      formNames: e.value
    });
  };

  actionTemplate2(rowData, column) {
    return (
      <React.Fragment>
        <Button
          type="button"
          onClick={() => this.onClickModal("displayBasic", rowData)}
          icon="pi pi-times"
          className="p-button-danger"
          style={{ marginRight: ".5em" }}
        ></Button>
        <Dialog
          header="Delete This  Record?"
          visible={this.state.displayBasic}
          style={{ width: "25vw" }}
          onHide={() => this.onHideModal("displayBasic")}
          modal={false}
          footer={this.renderFooter("displayBasic")}
        >
          <p>This record will be deleted. Are you sure?</p>
        </Dialog>
      </React.Fragment>
    );
  }

  onClickModal(name, rowData) {
    this.setState({
      [`${name}`]: true
    });
  }

  onHideModal(name) {
    this.setState({
      [`${name}`]: false
    });
  }

  saveAuthorization = async () => {
    if (this.state.checkedKeys.length > 0 && Boolean(this.state.userKeys) && this.state.userKeys.length > 0) {
      var list = [];

      this.state.checkedKeys.forEach(f => {
        this.state.userKeys.forEach(userId => {
          if (f.includes("MENU")) {
            if (f !== "All" && list.findIndex(p => p.menuId == f.split("MENU")[0] && p.action === "view" && p.userId === userId) === -1) {
              const newItem = {
                menuId: f.split("MENU")[0],
                actionId: null,
                action: "view",
                userId: userId,
                halfChecked: false
              };
              list.push(newItem);
            }
          } else if (f.includes("ACT")) {
            if (f !== "All" && list.findIndex(p => p.actionId == f.split("ACT")[0] && p.userId === userId) === -1) {
              let parentMenu = this.state.checkedNodes.find(p => p.key == f);
              console.log(parentMenu);
              const newItem = {
                actionId: f.split("ACT")[0],
                menuId: parentMenu.menuId,
                userId: userId,
                action: "",
                halfChecked: false
              };
              list.push(newItem);
            }
          }
        });
      });
      if (this.state.halfCheckedKeys !== null && this.state.halfCheckedKeys.length > 0)
        this.state.halfCheckedKeys.forEach(f => {
          this.state.userKeys.forEach(userId => {
            if (f.includes("MENU")) {
              if (f !== "All" && list.findIndex(p => p.menuId == f.split("MENU")[0] && p.action === "view" && p.userId === userId) === -1) {
                const newItem = {
                  menuId: f.split("MENU")[0],
                  action: "view",
                  actionId: null,
                  userId: userId,
                  halfChecked: true
                };
                list.push(newItem);
              }
            }
          });
        });

      var r = await handleRequest("POST", "/api/authorizations/save", list);
      if (r.data === "ERROR-002") {
        this.showError("This record added before! Please change your selections.");
      } else {
        if (r.data === "ERROR-001") {
          this.showError("There is no value for save !");
        } else {
          this.showSuccess();
          this.getUsers();
        }
      }
    } else {
      this.showError();
    }
  };

  showSuccess() {
    this.toast.show({ severity: "success", summary: "Success Message", detail: "Successful" });
  }

  showError(msg) {
    if (!msg) {
      msg = "Something went wrong. Please check inputs.";
    }
    this.toast.show({ severity: "error", summary: "Error Message", detail: msg });
  }

  handleSelectionChange = e => {
    console.log(e);
    console.log(e.value);

    this.setState({ selectedNodeKeys: e.value });
  };

  onCheck = (checkedKeys, e) => {
    console.log("onCheck", checkedKeys);
    console.log("halfCheckedKeys", e.halfCheckedKeys);
    console.log(e);
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes
    });

    var uIds = [];

    console.log(this.state.userList);

    /*  this.state.userList.forEach(user => {
              var size = 0;
              checkedKeys.forEach(ck => {
                  if (user.authList !== null && user.authList !== undefined && user.authList.length > 0) {
                      if (ck.includes('MENU')) {
  
                          if (user.authList.findIndex(p => p.menuId == ck.split('MENU')[0]) >= 0)
                              size++;
                      }
                      else
                          if (ck.includes('ACT')) {
  
                              if (user.authList.findIndex(p => p.actionId == ck.split('ACT')[0]) >= 0)
                                  size++;
                          }
  
                  }
  
  
              });
  
              if (checkedKeys.findIndex(p => p === "All") >= 0) {
                  size++;
              }
  
              if (size > 0 && size === checkedKeys.length) {
                  uIds.push(user.id.toString())
              }
  
          });
  
          this.setState({
              userKeys: uIds
          });
          */
  };

  actionTemplate(rowData, column) {
    return (
      <React.Fragment>
        {" "}
        <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} />{" "}
      </React.Fragment>
    );
  }

  deleteModal = row => {
    this.setState({
      modalShow: true,
      selectedRow: row
    });
  };

  showOrHideModal = () => {
    this.setState({
      modalShow: !this.state.modalShow
    });
  };

  refreshDisciplineDesignationRoleGroups = () => {
    const departmentId = this.state.departmentId;
    if (Boolean(departmentId)) {
      let selectedDepartment = this.state.departments.find(p => p.id === departmentId);
      let disciplines = [];
      let designations = [];
      let roleGroups = [];
      let userList = [];
      let userKeys = [];
      if (
        Boolean(selectedDepartment) &&
        Boolean(selectedDepartment.disciplineDepartmentList) &&
        selectedDepartment.disciplineDepartmentList.length > 0
      ) {
        selectedDepartment.disciplineDepartmentList.forEach(element => {
          disciplines.push(element.discipline);
        });
      }

      if (
        Boolean(selectedDepartment) &&
        Boolean(selectedDepartment.departmentDesignationList) &&
        selectedDepartment.departmentDesignationList.length > 0
      ) {
        console.log(selectedDepartment.departmentDesignationList);
        selectedDepartment.departmentDesignationList.forEach(element => {
          designations.push(element.designation);
          roleGroups.push(element.designation.roleGroup);
        });
      }

      if (Boolean(selectedDepartment)) {
        let result = this.state.allUsers.filter(p => Boolean(p.staff) && p.staff.departmentId === departmentId);
        if (Boolean(result)) {
          userList = result;
          result.forEach(element => {
            userKeys.push(element.id);
          });
        }
      }

      this.setState(
        {
          disciplines: disciplines,
          designations: designations,
          roleGroups: roleGroups,
          userList: userList,
          userKeys: userKeys
        },
        this.setCheckedNodes
      );
    } else {
      this.getDisciplines();
      this.getDesignations();
      this.getRoleGoups();
      this.getUsers();
    }
  };

  getUsers = async () => {
    const responseUser = await handleRequest("GET", "/api/users/company");

    if (responseUser.data.length === 0) {
      this.setState({
        users: []
      });
    }

    if (responseUser.data.length !== 0) {
      this.setState({
        userList: responseUser.data,
        allUsers: responseUser.data
      });
    }
  };

  render() {
    let optionsUsers = this.state.users.map(data => (
      <option key={data.id} value={data.name}>
        {data.name}
      </option>
    ));

    let optionsForms = [];
    this.state.forms.forEach(data => {
      optionsForms.push({ label: data.name, value: data.name });
    });

    let actionHeader = <Button type="button" icon="pi pi-cog"></Button>;

    return (
      <div>
        <Card>
          <Row>
            <Col sm="6">
              <Form>
                <Form.Group style={{ marginTop: "2rem" }} as={Row}>
                  <Col sm="3"></Col>
                  <Form.Label style={{ color: "black" }} column sm="2">
                    Form/Action
                  </Form.Label>
                  <Col sm="7">
                    <Tree
                      style={{ width: "100%" }}
                      checkable
                      showLine={true}
                      onCheck={this.onCheck}
                      checkedKeys={this.state.checkedKeys}
                      halfCheckedKeys={this.state.halfCheckedKeys}
                      treeData={this.state.nodes}
                      defaultExpandedKeys={["All"]}
                    />
                  </Col>
                </Form.Group>
              </Form>
            </Col>
            <Col sm="6">
              <Form>
                <Form.Group style={{ marginTop: "2rem", marginBottom: "1rem" }} as={Row}>
                  <Form.Label style={{ color: "black" }} column sm="2">
                    User
                  </Form.Label>
                  <Col sm="4">
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder="Tags Mode"
                      value={this.state.userKeys}
                      onChange={this.handleUserChange2}
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {this.state.userList.map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.username}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col sm="3"></Col>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col sm="6"></Col>
            <Col sm="3">
              <AntButton style={{ width: "100%" }} id="add-authorization" type="primary" onClick={this.saveAuthorization}>
                Save All
              </AntButton>
            </Col>
          </Row>
          <Toast ref={el => (this.toast = el)} />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MenuAuthorization);
