import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableAnt } from "react-bootstrap";
import { FilterOutlined, PlusOutlined, CloseCircleOutlined, ArrowRightOutlined, UndoOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditOutlined } from "@material-ui/icons";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { inputTypeSelectItems } from "../../Components/helpers/ComponentProperties";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Table,
  Form,
  Input,
  Select,
  Divider,
  Tooltip,
  Button as AntButton,
  Col,
  Row,
} from "antd";

import moment from "moment";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import CreateIcon from "@material-ui/icons/AddCircle";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node)) {
      var lineage = [];
      if (Boolean(node.lineage) && node.lineage.length > 0) {
        node.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.value,
        lineage: node.lineage,
        title: node.value
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class CoderPageFormInputType extends Component {
  constructor() {
    super();
    this.state = {
      formName: "CoderPageFormInputType",
      controllerName: "coderPageFormInputType",
      modalDeleteShow: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      selectedRow: null,
      tableList: [],
      filePath: "",
      fileList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      textFormattingDetailId: null,
      excelData: [],
      searchCriteriaModels: [],
      nodes: [],
      inputTypeSelectItems: null,
      frontCodes: [],
      backendCodes: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = (prevProps) => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    fillFilterOperations();
  };

  fillComboboxes = async () => {
    this.fillCode("CoderPageFormInputTypeSourceId");
    this.fillCode("CoderPageFormInputTypeGroupId");
    this.fillCode("CoderPageFormInputTypeOperator");
    this.fillCode("CoderPageFormInputTypeImplementationOptions");
    this.fillCode("CoderPageFormInputTypeInputClass");
    this.fillCode("CoderPageFormInputTypeLink");
    this.fillCode("CoderPageFormInputTypeSubTemplateTitleId");
    this.fillCode("CoderPageFormInputTypeOptionId");
    this.setState({
      inputTypeSelectItems: inputTypeSelectItems
    });
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showInputModal = row => {
    this.setState({
      modalInputShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  save = async values => {
    const newItem = { ...values, id: this.state.id };

    let frontCodes = [];
    if (Boolean(this.state.frontCodes) && this.state.frontCodes.length > 0) {
      this.state.frontCodes.forEach(element => {
        if (Boolean(element.frontCodes) && element.frontCodes.length > 0) {
          element.frontCodes.forEach(field => {
            let frontOptions = [];
            if (Boolean(field.frontOptions) && field.frontOptions.length > 0) {
              field.frontOptions.forEach(element => {
                frontOptions.push({ optionId: element });
              });
            }
            frontCodes.push({ subTemplateTitleId: field.subTemplateTitleId, frontOptions: frontOptions, operatorId: field.operatorId, inputClassId: field.inputClassId, linkId: field.linkId, text: field.text });
          });
        }
      });
    }
    newItem.frontCodes = frontCodes;

    let backendCodes = [];
    if (Boolean(this.state.backendCodes) && this.state.backendCodes.length > 0) {
      this.state.backendCodes.forEach(element => {
        if (Boolean(element.backendCodes) && element.backendCodes.length > 0) {
          element.backendCodes.forEach(field => {
            let backOptions = [];
            if (Boolean(field.backOptions) && field.backOptions.length > 0) {
              field.backOptions.forEach(element => {
                backOptions.push({ optionId: element });
              });
            }
            backendCodes.push({ subTemplateTitleId: field.subTemplateTitleId, backOptions: backOptions, operatorId: field.operatorId, inputClassId: field.inputClassId, linkId: field.linkId, text: field.text });
          });
        }
      });
    }
    newItem.backendCodes = backendCodes;

    console.log("AAA", this.state.frontCodes);
    console.log(this.state.backendCodes);
    console.log(newItem);

    let implOptions = [];
    if (Boolean(values.implOptions) && values.implOptions.length > 0) {
      values.implOptions.forEach(element => {
        implOptions.push({ implementationOptionId: element });
      });
    }
    newItem.implOptions = implOptions;

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.fillComboboxes();
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  edit = (row) => {
    console.log("RRR", row);
    document.getElementById("kt_scrolltop").click();

    var implOptions = [];
    if (Boolean(row.implOptions) && row.implOptions.length > 0) {
      row.implOptions.forEach(element => {
        implOptions.push(element.implementationOptionId);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      implOptions: implOptions,
    });

    let codesBackend = "";
    if (Boolean(row.backendCodes) && row.backendCodes) {
      row.backendCodes.forEach(back => {
        if (Boolean(back.operatorId) && back.operatorId > 0) {
          var operator = this.state["CoderPageFormInputTypeOperatorSelectItems"].find(p => p.id === back.operatorId);
          if (Boolean(operator)) {
            codesBackend += operator.name + " ";
          }
        }
        if (Boolean(back.inputClassId) && back.inputClassId > 0) {
          var inputClass = this.state["CoderPageFormInputTypeInputClassSelectItems"].find(p => p.id === back.inputClassId);
          if (Boolean(inputClass)) {
            codesBackend += inputClass.name + " ";
          }
        }
        if (Boolean(back.linkId) && back.linkId > 0) {
          var link = this.state["CoderPageFormInputTypeLinkSelectItems"].find(p => p.id === back.linkId);
          if (Boolean(link)) {
            codesBackend += link.name + " ";
          }
        }
        if (Boolean(back.text)) {
          codesBackend += back.text + " ";
        }
        codesBackend += "<br />";
      });
    }

    let codesFrontEnd = "";
    if (Boolean(row.frontCodes) && row.frontCodes) {
      row.frontCodes.forEach(front => {
        if (Boolean(front.operatorId) && front.operatorId > 0) {
          var operator = this.state["CoderPageFormInputTypeOperatorSelectItems"].find(p => p.id === front.operatorId);
          if (Boolean(operator)) {
            codesFrontEnd += operator.name + " ";
          }
        }
        if (Boolean(front.inputClassId) && front.inputClassId > 0) {
          var inputClass = this.state["CoderPageFormInputTypeInputClassSelectItems"].find(p => p.id === front.inputClassId);
          if (Boolean(inputClass)) {
            codesFrontEnd += inputClass.name + " ";
          }
        }
        if (Boolean(front.linkId) && front.linkId > 0) {
          var link = this.state["CoderPageFormInputTypeLinkSelectItems"].find(p => p.id === front.linkId);
          if (Boolean(link)) {
            codesFrontEnd += link.name + " ";
          }
        }
        if (Boolean(front.text)) {
          codesFrontEnd += front.text + " ";
        }
        codesFrontEnd += "<br />";
      });
    }

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      selectedRow: row,
      frontCodes: row.frontCodes,
      backendCodes: row.backendCodes,
      codesFrontEnd: codesFrontEnd,
      codesBackend: codesBackend,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.fillComboboxes();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    console.log("XXX", newObj);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data && response.data.data) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedRow: null,
      frontCodes: [],
      backendCodes: [],
      codesFrontEnd: "",
      codesBackend: "",

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  addToCodesFrontEnd = index => {
    const { frontCodes } = this.state;
    let field = frontCodes[index];

    console.log("AAA", frontCodes);
    console.log(field);

    let object = { subTemplateTitleId: field.subTemplateTitleId, frontOptions: field.frontOptions, operatorId: field.operatorId, inputClassId: field.inputClassId, linkId: field.linkId, text: field.text };
    if (!Boolean(field.frontCodes)) {
      field.frontCodes = [];
    }

    object.indexOrder = Boolean(field.frontCodes) ? field.frontCodes.length + 1 : 0;

    field.frontCodes.push(object);
    console.log(field.frontCodes);
    field.operatorId = null;
    field.inputClassId = null;
    field.linkId = null;
    field.text = null;
    field.subTemplateTitleId = null;
    field.frontOptions = [];

    let codesFrontEnd = this.getCodesFrontEnd(frontCodes);
    this.setState({ frontCodes, codesFrontEnd: codesFrontEnd });
  };

  removeFromCodesFrontEnd = index => {
    const { frontCodes } = this.state;
    let field = frontCodes[index];
    console.log("AAA", frontCodes);
    console.log(field);
    if (field.frontCodes && field.frontCodes.length > 0) {
      field.frontCodes.pop();
    }
    let codesFrontEnd = this.getCodesFrontEnd(frontCodes);
    this.setState({ frontCodes, codesFrontEnd: codesFrontEnd });
  };

  addNewLineFrontEnd = () => {
    const { frontCodes } = this.state;
    let key = 0;
    if (Boolean(frontCodes) && frontCodes.length > 0) {
      key = frontCodes[frontCodes.length - 1].key + 1;
    }
    if (key === 0) {
      frontCodes.push({ key: key });
    } else frontCodes.push({ key: key });

    this.setState({ frontCodes });
  };

  removeFrontEndLine = index => {
    const { frontCodes } = this.state;
    frontCodes.splice(index, 1);

    let codesFrontEnd = this.getCodesFrontEnd(frontCodes);
    this.setState({ frontCodes, codesFrontEnd: codesFrontEnd });
  };

  getCodesFrontEnd(frontCodes) {
    console.log("XXX", frontCodes);
    let codesFrontEnd = "";
    if (Boolean(frontCodes) && frontCodes) {
      frontCodes.forEach(element => {
        if (Boolean(element.frontCodes) && element.frontCodes) {
          element.frontCodes.forEach(front => {
            if (Boolean(front.subTemplateTitleId) && front.subTemplateTitleId > 0) {
              var subTemplateTitle = this.state["CoderPageFormInputTypeSubTemplateTitleIdSelectItems"].find(p => p.id === front.subTemplateTitleId);
              if (Boolean(subTemplateTitle)) {
                codesFrontEnd += subTemplateTitle.name + " ";
              }
            }
            if (Boolean(front.frontOptions) && front.frontOptions.length > 0) {
              front.frontOptions.forEach(element => {
                var option = this.state["CoderPageFormInputTypeOptionIdSelectItems"].find(p => p.id === element);
                if (Boolean(option)) {
                  codesFrontEnd += option.name + " ";
                }
              });
            }

            if (Boolean(front.operatorId) && front.operatorId > 0) {
              var operator = this.state["CoderPageFormInputTypeOperatorSelectItems"].find(p => p.id === front.operatorId);
              if (Boolean(operator)) {
                codesFrontEnd += operator.name + " ";
              }
            }
            if (Boolean(front.inputClassId) && front.inputClassId > 0) {
              var inputClass = this.state["CoderPageFormInputTypeInputClassSelectItems"].find(p => p.id === front.inputClassId);
              if (Boolean(inputClass)) {
                codesFrontEnd += inputClass.name + " ";
              }
            }
            if (Boolean(front.linkId) && front.linkId > 0) {
              var link = this.state["CoderPageFormInputTypeLinkSelectItems"].find(p => p.id === front.linkId);
              if (Boolean(link)) {
                codesFrontEnd += link.name + " ";
              }
            }
            if (Boolean(front.text)) {
              codesFrontEnd += front.text + " ";
            }
          });
          codesFrontEnd += "<br />";
        }
      });
    }
    return codesFrontEnd;
  }

  addToCodesBackend = index => {
    const { backendCodes } = this.state;
    let field = backendCodes[index];

    console.log("AAA", backendCodes);
    console.log(field);

    let object = { subTemplateTitleId: field.subTemplateTitleId, backOptions: field.backOptions, operatorId: field.operatorId, inputClassId: field.inputClassId, linkId: field.linkId, text: field.text };
    if (!Boolean(field.backendCodes)) {
      field.backendCodes = [];
    }

    object.indexOrder = Boolean(field.backendCodes) ? field.backendCodes.length + 1 : 0;

    field.backendCodes.push(object);
    console.log(field.backendCodes);
    field.operatorId = null;
    field.inputClassId = null;
    field.linkId = null;
    field.text = null;

    let codesBackend = this.getCodesBackend(backendCodes);
    this.setState({ backendCodes, codesBackend: codesBackend });
  };

  removeFromCodesBackend = index => {
    const { backendCodes } = this.state;
    let field = backendCodes[index];
    console.log("AAA", backendCodes);
    console.log(field);
    if (field.backendCodes && field.backendCodes.length > 0) {
      field.backendCodes.pop();
    }
    let codesBackend = this.getCodesBackend(backendCodes);
    this.setState({ backendCodes, codesBackend: codesBackend });
  };

  addNewLineBackend = () => {
    const { backendCodes } = this.state;
    let key = 0;
    if (Boolean(backendCodes) && backendCodes.length > 0) {
      key = backendCodes[backendCodes.length - 1].key + 1;
    }
    if (key === 0) {
      backendCodes.push({ key: key });
    } else backendCodes.push({ key: key });

    this.setState({ backendCodes });
  };

  removeBackendLine = index => {
    const { backendCodes } = this.state;
    backendCodes.splice(index, 1);

    let codesBackend = this.getCodesBackend(backendCodes);
    this.setState({ backendCodes, codesBackend: codesBackend });
  };

  getCodesBackend(backendCodes) {
    console.log("XXX", backendCodes);
    let codesBackend = "";
    if (Boolean(backendCodes) && backendCodes) {
      backendCodes.forEach(element => {
        if (Boolean(element.backendCodes) && element.backendCodes) {
          element.backendCodes.forEach(back => {
            if (Boolean(back.subTemplateTitleId) && back.subTemplateTitleId > 0) {
              var subTemplateTitle = this.state["CoderPageFormInputTypeSubTemplateTitleIdSelectItems"].find(p => p.id === back.subTemplateTitleId);
              if (Boolean(subTemplateTitle)) {
                codesBackend += subTemplateTitle.name + " ";
              }
            }
            if (Boolean(back.backOptions) && back.backOptions.length > 0) {
              back.backOptions.forEach(element => {
                var option = this.state["CoderPageFormInputTypeOptionIdSelectItems"].find(p => p.id === element);
                if (Boolean(option)) {
                  codesBackend += option.name + " ";
                }
              });
            }
            if (Boolean(back.operatorId) && back.operatorId > 0) {
              var operator = this.state["CoderPageFormInputTypeOperatorSelectItems"].find(p => p.id === back.operatorId);
              if (Boolean(operator)) {
                codesBackend += operator.name + " ";
              }
            }
            if (Boolean(back.inputClassId) && back.inputClassId > 0) {
              var inputClass = this.state["CoderPageFormInputTypeInputClassSelectItems"].find(p => p.id === back.inputClassId);
              if (Boolean(inputClass)) {
                codesBackend += inputClass.name + " ";
              }
            }
            if (Boolean(back.linkId) && back.linkId > 0) {
              var link = this.state["CoderPageFormInputTypeLinkSelectItems"].find(p => p.id === back.linkId);
              if (Boolean(link)) {
                codesBackend += link.name + " ";
              }
            }
            if (Boolean(back.text)) {
              codesBackend += back.text + " ";
            }
          });
          codesBackend += "<br />";
        }
      });
    }
    return codesBackend;
  }


  addToCodesFrontEnd = index => {
    const { frontCodes } = this.state;
    let field = frontCodes[index];

    console.log("AAA", frontCodes);
    console.log(field);

    let object = { subTemplateTitleId: field.subTemplateTitleId, frontOptions: field.frontOptions, operatorId: field.operatorId, inputClassId: field.inputClassId, linkId: field.linkId, text: field.text };
    if (!Boolean(field.frontCodes)) {
      field.frontCodes = [];
    }

    object.indexOrder = Boolean(field.frontCodes) ? field.frontCodes.length + 1 : 0;

    field.frontCodes.push(object);
    console.log(field.frontCodes);
    field.operatorId = null;
    field.inputClassId = null;
    field.linkId = null;
    field.text = null;
    field.subTemplateTitleId = null;
    field.frontOptions = [];

    let codesFrontEnd = this.getCodesFrontEnd(frontCodes);
    this.setState({ frontCodes, codesFrontEnd: codesFrontEnd });
  };

  removeFromCodesFrontEnd = index => {
    const { frontCodes } = this.state;
    let field = frontCodes[index];
    console.log("AAA", frontCodes);
    console.log(field);
    if (field.frontCodes && field.frontCodes.length > 0) {
      field.frontCodes.pop();
    }
    let codesFrontEnd = this.getCodesFrontEnd(frontCodes);
    this.setState({ frontCodes, codesFrontEnd: codesFrontEnd });
  };

  addNewLineFrontEnd = () => {
    const { frontCodes } = this.state;
    let key = 0;
    if (Boolean(frontCodes) && frontCodes.length > 0) {
      key = frontCodes[frontCodes.length - 1].key + 1;
    }
    if (key === 0) {
      frontCodes.push({ key: key });
    } else frontCodes.push({ key: key });

    this.setState({ frontCodes });
  };

  removeFrontEndLine = index => {
    const { frontCodes } = this.state;
    frontCodes.splice(index, 1);

    let codesFrontEnd = this.getCodesFrontEnd(frontCodes);
    this.setState({ frontCodes, codesFrontEnd: codesFrontEnd });
  };

  getCodesFrontEnd(frontCodes) {
    console.log("XXX", frontCodes);
    let codesFrontEnd = "";
    if (Boolean(frontCodes) && frontCodes) {
      frontCodes.forEach(element => {
        if (Boolean(element.frontCodes) && element.frontCodes) {
          element.frontCodes.forEach(front => {
            if (Boolean(front.subTemplateTitleId) && front.subTemplateTitleId > 0) {
              var subTemplateTitle = this.state["CoderPageFormInputTypeSubTemplateTitleIdSelectItems"].find(p => p.id === front.subTemplateTitleId);
              if (Boolean(subTemplateTitle)) {
                codesFrontEnd += subTemplateTitle.name + " ";
              }
            }
            if (Boolean(front.frontOptions) && front.frontOptions.length > 0) {
              front.frontOptions.forEach(element => {
                var option = this.state["CoderPageFormInputTypeOptionIdSelectItems"].find(p => p.id === element);
                if (Boolean(option)) {
                  codesFrontEnd += option.name + " ";
                }
              });
            }

            if (Boolean(front.operatorId) && front.operatorId > 0) {
              var operator = this.state["CoderPageFormInputTypeOperatorSelectItems"].find(p => p.id === front.operatorId);
              if (Boolean(operator)) {
                codesFrontEnd += operator.name + " ";
              }
            }
            if (Boolean(front.inputClassId) && front.inputClassId > 0) {
              var inputClass = this.state["CoderPageFormInputTypeInputClassSelectItems"].find(p => p.id === front.inputClassId);
              if (Boolean(inputClass)) {
                codesFrontEnd += inputClass.name + " ";
              }
            }
            if (Boolean(front.linkId) && front.linkId > 0) {
              var link = this.state["CoderPageFormInputTypeLinkSelectItems"].find(p => p.id === front.linkId);
              if (Boolean(link)) {
                codesFrontEnd += link.name + " ";
              }
            }
            if (Boolean(front.text)) {
              codesFrontEnd += front.text + " ";
            }
          });
          codesFrontEnd += "<br />";
        }
      });
    }
    return codesFrontEnd;
  }

  addToCodesBackend = index => {
    const { backendCodes } = this.state;
    let field = backendCodes[index];

    console.log("AAb", backendCodes);
    console.log(field);

    let object = { subTemplateTitleId: field.subTemplateTitleId, backOptions: field.backOptions, operatorId: field.operatorId, inputClassId: field.inputClassId, linkId: field.linkId, text: field.text };
    if (!Boolean(field.backendCodes)) {
      field.backendCodes = [];
    }

    object.indexOrder = Boolean(field.backendCodes) ? field.backendCodes.length + 1 : 0;

    field.backendCodes.push(object);
    console.log(field.backendCodes);
    field.operatorId = null;
    field.inputClassId = null;
    field.linkId = null;
    field.text = null;

    let codesBackend = this.getCodesBackend(backendCodes);
    this.setState({ backendCodes, codesBackend: codesBackend });
  };

  removeFromCodesBackend = index => {
    const { backendCodes } = this.state;
    let field = backendCodes[index];
    console.log("AAA", backendCodes);
    console.log(field);
    if (field.backendCodes && field.backendCodes.length > 0) {
      field.backendCodes.pop();
    }
    let codesBackend = this.getCodesBackend(backendCodes);
    this.setState({ backendCodes, codesBackend: codesBackend });
  };

  addNewLineBackend = () => {
    const { backendCodes } = this.state;
    let key = 0;
    if (Boolean(backendCodes) && backendCodes.length > 0) {
      key = backendCodes[backendCodes.length - 1].key + 1;
    }
    if (key === 0) {
      backendCodes.push({ key: key });
    } else backendCodes.push({ key: key });

    this.setState({ backendCodes });
  };

  removeBackendLine = index => {
    const { backendCodes } = this.state;
    backendCodes.splice(index, 1);

    let codesBackend = this.getCodesBackend(backendCodes);
    this.setState({ backendCodes, codesBackend: codesBackend });
  };

  getCodesBackend(backendCodes) {
    console.log("XXX", backendCodes);
    let codesBackend = "";
    if (Boolean(backendCodes) && backendCodes) {
      backendCodes.forEach(element => {
        if (Boolean(element.backendCodes) && element.backendCodes) {
          element.backendCodes.forEach(back => {
            if (Boolean(back.subTemplateTitleId) && back.subTemplateTitleId > 0) {
              var subTemplateTitle = this.state["CoderPageFormInputTypeSubTemplateTitleIdSelectItems"].find(p => p.id === back.subTemplateTitleId);
              if (Boolean(subTemplateTitle)) {
                codesBackend += subTemplateTitle.name + " ";
              }
            }
            if (Boolean(back.backOptions) && back.backOptions.length > 0) {
              back.backOptions.forEach(element => {
                var option = this.state["CoderPageFormInputTypeOptionIdSelectItems"].find(p => p.id === element);
                if (Boolean(option)) {
                  codesBackend += option.name + " ";
                }
              });
            }
            if (Boolean(back.operatorId) && back.operatorId > 0) {
              var operator = this.state["CoderPageFormInputTypeOperatorSelectItems"].find(p => p.id === back.operatorId);
              if (Boolean(operator)) {
                codesBackend += operator.name + " ";
              }
            }
            if (Boolean(back.inputClassId) && back.inputClassId > 0) {
              var inputClass = this.state["CoderPageFormInputTypeInputClassSelectItems"].find(p => p.id === back.inputClassId);
              if (Boolean(inputClass)) {
                codesBackend += inputClass.name + " ";
              }
            }
            if (Boolean(back.linkId) && back.linkId > 0) {
              var link = this.state["CoderPageFormInputTypeLinkSelectItems"].find(p => p.id === back.linkId);
              if (Boolean(link)) {
                codesBackend += link.name + " ";
              }
            }
            if (Boolean(back.text)) {
              codesBackend += back.text + " ";
            }
          });
          codesBackend += "<br />";
        }
      });
    }
    return codesBackend;
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      style: {
        marginBottom: 0
      }
    };

    const modules = {
      toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link", "code"],
        ["clean"]
      ]
    };

    const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "code"];

    const columns = [
      {
        key: "key",
        width: 100,
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="CoderPageFormInputType.inputType" defaultMessage="Input Type" />,
        key: "inputType",
        render: record => {
          return record.inputType;
        },
        ...getColumnFilter({
          inputName: "inputType", inputType: "SELECT",
          selectItems: Boolean(this.state["inputTypeSelectItems"]) ? this.state["inputTypeSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="CoderPageFormInputType.source" defaultMessage="Source" />,
        key: "sourceId",
        render: record => {
          return record.sourceName;
        },
        ...getColumnFilter({
          inputName: "sourceId", inputType: "SELECT",
          selectItems: Boolean(this.state["CoderPageFormInputTypeSourceIdSelectItems"]) ? this.state["CoderPageFormInputTypeSourceIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="CoderPageFormInputType.GroupFrontEnd" defaultMessage="Group Frontend" />,
        key: "groupIdFrontEnd",
        render: record => {
          return record.groupNameFrontEnd;
        },
        ...getColumnFilter({
          inputName: "groupIdFrontEnd", inputType: "SELECT",
          selectItems: Boolean(this.state["CoderPageFormInputTypeGroupIdSelectItems"]) ? this.state["CoderPageFormInputTypeGroupIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="CoderPageFormInputType.GroupBackend" defaultMessage="Group Backend" />,
        key: "groupIdBackend",
        render: record => {
          return record.groupNameBackend;
        },
        ...getColumnFilter({
          inputName: "groupIdBackend", inputType: "SELECT",
          selectItems: Boolean(this.state["CoderPageFormInputTypeGroupIdSelectItems"]) ? this.state["CoderPageFormInputTypeGroupIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: "Last Update",
        key: "lastUpdated",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        ...getColumnFilter({
          inputName: "lastUpdated", inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        })
      }
    ];


    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];

      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    return (
      <div className="card card-custom" style={{ padding: "3rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
            <Col md={2}></Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CoderPageFormInputType.inputType" defaultMessage="Input Type" />}
                name="inputType"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Input Type"
                  onChange={value => {
                    let props = inputTypeSelectItems.find(item => item.key === value);
                    if (!Boolean(props.inputProps)) {
                      this.setState(
                        {
                          inputProps: null
                        },
                        () => {
                          this.formRef.current.setFieldsValue({
                            inputProps: []
                          });
                        }
                      );
                      return;
                    }
                    this.setState(
                      {
                        inputProps: props.inputProps
                      },
                      () => {
                        this.formRef.current.setFieldsValue({
                          inputProps: props.inputProps
                        });
                      }
                    );
                  }}
                >
                  {Boolean(this.state["inputTypeSelectItems"]) &&
                    this.state["inputTypeSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.key}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CoderPageFormInputType.sourceId" defaultMessage="Source" />}
                name="sourceId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <a
                          style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                          onClick={() => this.addCode("CoderPageFormInputTypeSourceId")}
                        >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                        </a>
                      </div>
                    </div>
                  )}
                >
                  {Boolean(this.state["CoderPageFormInputTypeSourceIdSelectItems"]) &&
                    this.state["CoderPageFormInputTypeSourceIdSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CoderPageFormInputType.implementationOptions" defaultMessage="Applicable for" />}
                name="implOptions"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  mode="multiple"
                  style={{ width: "100%" }}
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <a
                          style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                          onClick={() => this.addCode("CoderPageFormInputTypeImplementationOptions")}
                        >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                        </a>
                      </div>
                    </div>
                  )}
                >
                  {Boolean(this.state["CoderPageFormInputTypeImplementationOptionsSelectItems"]) &&
                    this.state["CoderPageFormInputTypeImplementationOptionsSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }

            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <Button id="FrontEndButton" style={{ width: "100%" }} variant="dark">
                    <FormattedMessage id="CoderPageFormInputType.BackendEnd" defaultMessage="Back-End" />
                  </Button>
                </Col>
              </Row>
            }
            <br />
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CoderPageFormInputType.groupIdBackend" defaultMessage="Group Backend" />}
                name="groupIdBackend"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <a
                          style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                          onClick={() => this.addCode("CoderPageFormInputTypeGroupId")}
                        >
                          <PlusOutlined />  <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                        </a>
                      </div>
                    </div>
                  )}
                >
                  {Boolean(this.state["CoderPageFormInputTypeGroupIdSelectItems"]) &&
                    this.state["CoderPageFormInputTypeGroupIdSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }

            <Form.Item {...layout2} label={<FormattedMessage id="CoderPageFormInputType.CodesBackend" defaultMessage="Codes Backend" />}>
              <>
                <TableAnt borderless size="sm">
                  <thead>
                    {Boolean(this.state.backendCodes) && this.state.backendCodes.length > 0 && (
                      <tr>
                        <th>
                          <FormattedMessage id={"CoderPageFormInputType.SubTemplateTitle"} defaultMessage={"Sub-Template Title"} />
                        </th>
                        <th>
                          <FormattedMessage id={"CoderPageFormInputType.Options"} defaultMessage={"Options"} />
                        </th>
                        <th>
                          <FormattedMessage id={"CoderPageFormInputType.Operator"} defaultMessage={"Operator"} />
                        </th>
                        <th>
                          <FormattedMessage id={"CoderPageFormInputType.dimension"} defaultMessage={"Input Class"} />
                        </th>
                        <th>
                          <FormattedMessage id={"CoderPageFormInputType.Link"} defaultMessage={"Link"} />
                        </th>
                        <th>
                          <FormattedMessage id={"CoderPageFormInputType.Text"} defaultMessage={"Text"} />
                        </th>
                        <th></th>
                        <th></th>
                        <th>
                          <FormattedMessage id="GENERAL.Remove" defaultMessage="Remove" />
                        </th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {Boolean(this.state.backendCodes) &&
                      this.state.backendCodes.map((field, index) => {
                        return (
                          <tr>
                            <td>
                              {
                                <Select
                                  allowClear
                                  style={{ width: "100%" }}
                                  onChange={value => {
                                    const { backendCodes } = this.state;
                                    backendCodes[index].subTemplateTitleId = value;
                                    this.setState({ backendCodes });
                                  }}
                                  dropdownRender={menu => (
                                    <div>
                                      {menu}
                                      <Divider style={{ margin: "4px 0" }} />
                                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                        <Input
                                          style={{ flex: "auto" }}
                                          value={this.state.codeName}
                                          onChange={event => {
                                            this.setState({
                                              codeName: event.target.value
                                            });
                                          }}
                                        />
                                        <a
                                          style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                          onClick={() => this.addCode("CoderPageFormInputTypeSubTemplateTitleId")}
                                        >
                                          <PlusOutlined />  <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                                        </a>
                                      </div>
                                    </div>
                                  )}
                                >
                                  {Boolean(this.state["CoderPageFormInputTypeSubTemplateTitleIdSelectItems"]) &&
                                    this.state["CoderPageFormInputTypeSubTemplateTitleIdSelectItems"].map(i => (
                                      <Option key={i.id} value={i.id}>
                                        {i.name}
                                      </Option>
                                    ))}{" "}
                                </Select>
                              }
                            </td>
                            <td>
                              {
                                <Select
                                  allowClear
                                  mode="multiple"
                                  style={{ width: "100%" }}
                                  onChange={value => {
                                    const { backendCodes } = this.state;
                                    backendCodes[index].backOptions = value;
                                    this.setState({ backendCodes });
                                  }}
                                  dropdownRender={menu => (
                                    <div>
                                      {menu}
                                      <Divider style={{ margin: "4px 0" }} />
                                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                        <Input
                                          style={{ flex: "auto" }}
                                          value={this.state.codeName}
                                          onChange={event => {
                                            this.setState({
                                              codeName: event.target.value
                                            });
                                          }}
                                        />
                                        <a
                                          style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                          onClick={() => this.addCode("CoderPageFormInputTypeOptionId")}
                                        >
                                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                                        </a>
                                      </div>
                                    </div>
                                  )}
                                >
                                  {Boolean(this.state["CoderPageFormInputTypeOptionIdSelectItems"]) &&
                                    this.state["CoderPageFormInputTypeOptionIdSelectItems"].map(i => (
                                      <Option key={i.id} value={i.id}>
                                        {i.name}
                                      </Option>
                                    ))}{" "}
                                </Select>
                              }
                            </td>
                            <td>
                              <Select
                                allowClear
                                style={{ width: "100%" }}
                                onChange={value => {
                                  const { backendCodes } = this.state;
                                  backendCodes[index].operatorId = value;
                                  this.setState({ backendCodes });
                                }}
                                dropdownRender={menu => (
                                  <div>
                                    {menu}
                                    <Divider style={{ margin: "4px 0" }} />
                                    <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                      <Input
                                        style={{ flex: "auto" }}
                                        value={this.state.codeName}
                                        onChange={event => {
                                          this.setState({
                                            codeName: event.target.value
                                          });
                                        }}
                                      />
                                      <a
                                        style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                        onClick={() => this.addCode("CoderPageFormInputTypeOperator")}
                                      >
                                        <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                                      </a>
                                    </div>
                                  </div>
                                )}
                              >
                                {Boolean(this.state["CoderPageFormInputTypeOperatorSelectItems"]) &&
                                  this.state["CoderPageFormInputTypeOperatorSelectItems"].map(i => (
                                    <Option key={i.id} value={i.id}>
                                      {i.name}
                                    </Option>
                                  ))}{" "}
                              </Select>
                            </td>
                            <td>
                              <Select
                                allowClear
                                style={{ width: "100%" }}
                                onChange={value => {
                                  const { backendCodes } = this.state;
                                  backendCodes[index].inputClassId = value;
                                  this.setState({ backendCodes });
                                }}
                                dropdownRender={menu => (
                                  <div>
                                    {menu}
                                    <Divider style={{ margin: "4px 0" }} />
                                    <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                      <Input
                                        style={{ flex: "auto" }}
                                        value={this.state.codeName}
                                        onChange={event => {
                                          this.setState({
                                            codeName: event.target.value
                                          });
                                        }}
                                      />
                                      <a
                                        style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                        onClick={() => this.addCode("CoderPageFormInputTypeInputClass")}
                                      >
                                        <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                                      </a>
                                    </div>
                                  </div>
                                )}
                              >
                                {Boolean(this.state["CoderPageFormInputTypeInputClassSelectItems"]) &&
                                  this.state["CoderPageFormInputTypeInputClassSelectItems"].map(i => (
                                    <Option key={i.id} value={i.id}>
                                      {i.name}
                                    </Option>
                                  ))}{" "}
                              </Select>
                            </td>
                            <td>
                              <Select
                                allowClear
                                style={{ width: "100%" }}
                                onChange={value => {
                                  const { backendCodes } = this.state;
                                  backendCodes[index].linkId = value;
                                  this.setState({ backendCodes });
                                }}
                                dropdownRender={menu => (
                                  <div>
                                    {menu}
                                    <Divider style={{ margin: "4px 0" }} />
                                    <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                      <Input
                                        style={{ flex: "auto" }}
                                        value={this.state.codeName}
                                        onChange={event => {
                                          this.setState({
                                            codeName: event.target.value
                                          });
                                        }}
                                      />
                                      <a
                                        style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                        onClick={() => this.addCode("CoderPageFormInputTypeLink")}
                                      >
                                        <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                                      </a>
                                    </div>
                                  </div>
                                )}
                              >
                                {Boolean(this.state["CoderPageFormInputTypeLinkSelectItems"]) &&
                                  this.state["CoderPageFormInputTypeLinkSelectItems"].map(i => (
                                    <Option key={i.id} value={i.id}>
                                      {i.name}
                                    </Option>
                                  ))}{" "}
                              </Select>
                            </td>
                            <td>
                              <Input
                                value={field.text}
                                onChange={e => {
                                  const { backendCodes } = this.state;
                                  backendCodes[index].text = e.target.value;
                                  this.setState({ backendCodes });
                                }}
                              ></Input>
                            </td>
                            <td>
                              <ArrowRightOutlined onClick={() => this.addToCodesBackend(index)} />
                            </td>
                            <td>
                              <UndoOutlined onClick={() => this.removeFromCodesBackend(index)} />
                            </td>
                            <td>
                              <MinusCircleOutlined onClick={() => this.removeBackendLine(index)} />
                            </td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td colSpan={8} className="justify-content-end">
                        <AntButton type="dashed" onClick={() => this.addNewLineBackend()} block icon={<PlusOutlined />}>
                          <FormattedMessage id={"CoderPageFormInputType.AddNewLine"} defaultMessage={"Add New Line"} />
                        </AntButton>
                      </td>
                    </tr>
                  </tbody>
                </TableAnt>
              </>
            </Form.Item>
            <br />
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                <ReactQuill theme="snow" value={this.state.codesBackend || ""} readOnly={true} modules={modules} formats={formats} />
              </Col>
            </Row>
            <br />

            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <Button id="FrontEndButton" style={{ width: "100%" }} variant="dark">
                    <FormattedMessage id="CoderPageFormInputType.FrontEnd" defaultMessage="Front-End" />
                  </Button>
                </Col>
              </Row>
            }
            <br />
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CoderPageFormInputType.groupIdFrontEnd" defaultMessage="Group Frontend" />}
                name="groupIdFrontEnd"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <a
                          style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                          onClick={() => this.addCode("CoderPageFormInputTypeGroupId")}
                        >
                          <PlusOutlined />  <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                        </a>
                      </div>
                    </div>
                  )}
                >
                  {Boolean(this.state["CoderPageFormInputTypeGroupIdSelectItems"]) &&
                    this.state["CoderPageFormInputTypeGroupIdSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }

            <Form.Item {...layout2} label={<FormattedMessage id="CoderPageFormInputType.CodesFrontEnd" defaultMessage="Codes Frontend" />}>
              <>
                <TableAnt borderless size="sm">
                  <thead>
                    {Boolean(this.state.frontCodes) && this.state.frontCodes.length > 0 && (
                      <tr>
                        <th>
                          <FormattedMessage id={"CoderPageFormInputType.SubTemplateTitle"} defaultMessage={"Sub-Template Title"} />
                        </th>
                        <th>
                          <FormattedMessage id={"CoderPageFormInputType.Options"} defaultMessage={"Options"} />
                        </th>
                        <th>
                          <FormattedMessage id={"CoderPageFormInputType.Operator"} defaultMessage={"Operator"} />
                        </th>
                        <th>
                          <FormattedMessage id={"CoderPageFormInputType.dimension"} defaultMessage={"Input Class"} />
                        </th>
                        <th>
                          <FormattedMessage id={"CoderPageFormInputType.Link"} defaultMessage={"Link"} />
                        </th>
                        <th>
                          <FormattedMessage id={"CoderPageFormInputType.Text"} defaultMessage={"Text"} />
                        </th>
                        <th></th>
                        <th></th>
                        <th>
                          <FormattedMessage id="GENERAL.Remove" defaultMessage="Remove" />
                        </th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {Boolean(this.state.frontCodes) &&
                      this.state.frontCodes.map((field, index) => {
                        return (
                          <tr>
                            <td>
                              {
                                <Select
                                  allowClear
                                  style={{ width: "100%" }}
                                  onChange={value => {
                                    const { frontCodes } = this.state;
                                    frontCodes[index].subTemplateTitleId = value;
                                    this.setState({ frontCodes });
                                  }}
                                  dropdownRender={menu => (
                                    <div>
                                      {menu}
                                      <Divider style={{ margin: "4px 0" }} />
                                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                        <Input
                                          style={{ flex: "auto" }}
                                          value={this.state.codeName}
                                          onChange={event => {
                                            this.setState({
                                              codeName: event.target.value
                                            });
                                          }}
                                        />
                                        <a
                                          style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                          onClick={() => this.addCode("CoderPageFormInputTypeSubTemplateTitleId")}
                                        >
                                          <PlusOutlined />  <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                                        </a>
                                      </div>
                                    </div>
                                  )}
                                >
                                  {Boolean(this.state["CoderPageFormInputTypeSubTemplateTitleIdSelectItems"]) &&
                                    this.state["CoderPageFormInputTypeSubTemplateTitleIdSelectItems"].map(i => (
                                      <Option key={i.id} value={i.id}>
                                        {i.name}
                                      </Option>
                                    ))}{" "}
                                </Select>
                              }
                            </td>
                            <td>
                              {
                                <Select
                                  allowClear
                                  mode="multiple"
                                  style={{ width: "100%" }}
                                  onChange={value => {
                                    const { frontCodes } = this.state;
                                    frontCodes[index].frontOptions = value;
                                    this.setState({ frontCodes });
                                  }}
                                  dropdownRender={menu => (
                                    <div>
                                      {menu}
                                      <Divider style={{ margin: "4px 0" }} />
                                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                        <Input
                                          style={{ flex: "auto" }}
                                          value={this.state.codeName}
                                          onChange={event => {
                                            this.setState({
                                              codeName: event.target.value
                                            });
                                          }}
                                        />
                                        <a
                                          style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                          onClick={() => this.addCode("CoderPageFormInputTypeOptionId")}
                                        >
                                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                                        </a>
                                      </div>
                                    </div>
                                  )}
                                >
                                  {Boolean(this.state["CoderPageFormInputTypeOptionIdSelectItems"]) &&
                                    this.state["CoderPageFormInputTypeOptionIdSelectItems"].map(i => (
                                      <Option key={i.id} value={i.id}>
                                        {i.name}
                                      </Option>
                                    ))}{" "}
                                </Select>
                              }
                            </td>
                            <td>
                              <Select
                                allowClear
                                style={{ width: "100%" }}
                                onChange={value => {
                                  const { frontCodes } = this.state;
                                  frontCodes[index].operatorId = value;
                                  this.setState({ frontCodes });
                                }}
                                dropdownRender={menu => (
                                  <div>
                                    {menu}
                                    <Divider style={{ margin: "4px 0" }} />
                                    <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                      <Input
                                        style={{ flex: "auto" }}
                                        value={this.state.codeName}
                                        onChange={event => {
                                          this.setState({
                                            codeName: event.target.value
                                          });
                                        }}
                                      />
                                      <a
                                        style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                        onClick={() => this.addCode("CoderPageFormInputTypeOperator")}
                                      >
                                        <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                                      </a>
                                    </div>
                                  </div>
                                )}
                              >
                                {Boolean(this.state["CoderPageFormInputTypeOperatorSelectItems"]) &&
                                  this.state["CoderPageFormInputTypeOperatorSelectItems"].map(i => (
                                    <Option key={i.id} value={i.id}>
                                      {i.name}
                                    </Option>
                                  ))}{" "}
                              </Select>
                            </td>
                            <td>
                              <Select
                                allowClear
                                style={{ width: "100%" }}
                                onChange={value => {
                                  const { frontCodes } = this.state;
                                  frontCodes[index].inputClassId = value;
                                  this.setState({ frontCodes });
                                }}
                                dropdownRender={menu => (
                                  <div>
                                    {menu}
                                    <Divider style={{ margin: "4px 0" }} />
                                    <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                      <Input
                                        style={{ flex: "auto" }}
                                        value={this.state.codeName}
                                        onChange={event => {
                                          this.setState({
                                            codeName: event.target.value
                                          });
                                        }}
                                      />
                                      <a
                                        style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                        onClick={() => this.addCode("CoderPageFormInputTypeInputClass")}
                                      >
                                        <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                                      </a>
                                    </div>
                                  </div>
                                )}
                              >
                                {Boolean(this.state["CoderPageFormInputTypeInputClassSelectItems"]) &&
                                  this.state["CoderPageFormInputTypeInputClassSelectItems"].map(i => (
                                    <Option key={i.id} value={i.id}>
                                      {i.name}
                                    </Option>
                                  ))}{" "}
                              </Select>
                            </td>
                            <td>
                              <Select
                                allowClear
                                style={{ width: "100%" }}
                                onChange={value => {
                                  const { frontCodes } = this.state;
                                  frontCodes[index].linkId = value;
                                  this.setState({ frontCodes });
                                }}
                                dropdownRender={menu => (
                                  <div>
                                    {menu}
                                    <Divider style={{ margin: "4px 0" }} />
                                    <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                      <Input
                                        style={{ flex: "auto" }}
                                        value={this.state.codeName}
                                        onChange={event => {
                                          this.setState({
                                            codeName: event.target.value
                                          });
                                        }}
                                      />
                                      <a
                                        style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                        onClick={() => this.addCode("CoderPageFormInputTypeLink")}
                                      >
                                        <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                                      </a>
                                    </div>
                                  </div>
                                )}
                              >
                                {Boolean(this.state["CoderPageFormInputTypeLinkSelectItems"]) &&
                                  this.state["CoderPageFormInputTypeLinkSelectItems"].map(i => (
                                    <Option key={i.id} value={i.id}>
                                      {i.name}
                                    </Option>
                                  ))}{" "}
                              </Select>
                            </td>
                            <td>
                              <Input
                                value={field.text}
                                onChange={e => {
                                  const { frontCodes } = this.state;
                                  frontCodes[index].text = e.target.value;
                                  this.setState({ frontCodes });
                                }}
                              ></Input>
                            </td>
                            <td>
                              <ArrowRightOutlined onClick={() => this.addToCodesFrontEnd(index)} />
                            </td>
                            <td>
                              <UndoOutlined onClick={() => this.removeFromCodesFrontEnd(index)} />
                            </td>
                            <td>
                              <MinusCircleOutlined onClick={() => this.removeFrontEndLine(index)} />
                            </td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td colSpan={8} className="justify-content-end">
                        <AntButton type="dashed" onClick={() => this.addNewLineFrontEnd()} block icon={<PlusOutlined />}>
                          <FormattedMessage id={"CoderPageFormInputType.AddNewLine"} defaultMessage={"Add New Line"} />
                        </AntButton>
                      </td>
                    </tr>
                  </tbody>
                </TableAnt>
              </>
            </Form.Item>
            <br />
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                <ReactQuill theme="snow" value={this.state.codesFrontEnd || ""} readOnly={true} modules={modules} formats={formats} />
              </Col>
            </Row>
            <br />
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span>
                      <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span>
                      <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}

          <Table
            rowKey="id"
            bordered
            size="small"
            components={{
              header: {
                cell: ResizableTitle
              }
            }}
            columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CoderPageFormInputType);
