import { EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button as AntButton, Col, Form, Row, Input, Modal as AntModal, Tooltip, Space, Table } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import DataTableComponent2 from "../../Components/DataTableComponent2";
import ElementsProvider from "../../Components/ElementsProvider";
import { GetDynamicFormItems, PrepareRowForEdit, PrepareRowForSave } from "../../Components/ElementUtils";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { ExcelReader } from "../excel/ExcelReader";
import { PlusOutlined, CloseOutlined, CheckOutlined, ZoomInOutlined } from "@ant-design/icons";

export class InsurancePolicy extends Component {
  constructor() {
    super();
    this.state = {
      formName: "InsurancePolicy",
      controllerName: "insurancePolicy",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      tableList: [],
      restartTable: false,
      pageFormData: null,
      excelData: [],
      createWhereParams: null,
      refreshForm: false,
      coverages: [],
      benefits: [],
      coverageId: 0,
      benefitId: 0,
      addNewCategory: false,
      editCoverage: false,
      editBenefit: false,
      dataList: [],
      paginationAssetCoverages: {
        current: 1,
        pageSize: 10,
      },
      paginationBenefitsCoverages: {
        current: 1,
        pageSize: 10,
      },
      coveragesList: [],
      benefitsList: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    let data = await GetDynamicFormItems(this.state.formName);
    this.setState({
      pageFormData: data
    });

    this.fillComboboxes();
  };
  componentDidUpdate = async nextProps => { };
  fillComboboxes = async () => { };

  cancel = () => {
    if (!this.state.addNewCategory) {
      this.setState({
        hideUpdate: true,
        hideSave: false,
        hideInputs: true
      });
    }
    this.resetInputs();
  };

  resetInputs = () => {
    if (!this.state.addNewCategory) {
      this.formRef.current.resetFields();
    } else {
      var inputCompanyList = this.state.pageFormData.pageFormInputCompanyList;
      if (Boolean(inputCompanyList) && inputCompanyList.length > 0) {
        inputCompanyList.forEach(element => {
          if (Boolean(element.inputName) && element.inputName !== "typeId" && element.inputName !== "insuranceCompanyId" &&
            element.inputName !== "agreementDate" && element.inputName !== "validtyPrices") {
            this.formRef.current.resetFields([element.inputName])
          }
        });
      }
    }
    this.setState({
      id: null,
      coverages: [],
      coverageId: null,
      benefits: [],
      benefitId: null,
      addNewCategory: false,
    });
  };

  setData = data => {
    this.setState({
      tableList: data
    });
  };

  edit = row => {
    let handledValues = {
      ...row
    };
    handledValues = PrepareRowForEdit(handledValues, this.state.pageFormData.pageFormInputCompanyList);
    this.formRef.current.setFieldsValue({
      ...handledValues
    });

    var coverages = [];
    var coverageId = 1;
    if (Boolean(row.coverages)) {
      coverages = JSON.parse(row.coverages);
      if (Boolean(coverages) && coverages.length > 0) {
        coverages.forEach((element, index) => {
          element.id = index + 1;
          coverageId = element.id;
        });
      }
    }

    var benefits = [];
    var benefitId = 1;
    if (Boolean(row.benefits)) {
      benefits = JSON.parse(row.benefits);
      if (Boolean(benefits) && benefits.length > 0) {
        benefits.forEach((element, index) => {
          element.id = index + 1;
          benefitId = element.id;
        });
      }
    }

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      coverages: coverages,
      coverageId: coverageId,
      benefits: benefits,
      benefitId: benefitId,
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  coveragesModal = row => {
    var coveragesList = [];
    if (Boolean(row.coverages)) {
      coveragesList = JSON.parse(row.coverages);
    }
    this.setState({
      modalCoveragesShow: true,
      coveragesList: coveragesList,
    });
  };

  benefitsModal = row => {
    var benefitsList = [];
    if (Boolean(row.benefits)) {
      benefitsList = JSON.parse(row.benefits);
    }
    this.setState({
      modalBenefitsShow: true,
      benefitsList: benefitsList,
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async () => {
    this.setState({ restartTable: !this.state.restartTable });
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    var coverages = null;
    var coveragesList = [];
    if (Boolean(this.state.coverages) && this.state.coverages.length > 0) {
      this.state.coverages.forEach(element => {
        coveragesList.push({ ["coverageTitle"]: element.coverageTitle, ["coverage"]: element.coverage });
      });
      coverages = JSON.stringify(coveragesList);
    }

    var benefits = null;
    var benefitsList = [];
    if (Boolean(this.state.benefits) && this.state.benefits.length > 0) {
      this.state.benefits.forEach(element => {
        benefitsList.push({ ["benefitTitle"]: element.benefitTitle, ["benefit"]: element.benefit });
      });
      benefits = JSON.stringify(benefitsList);
    }

    let newItem = {
      id: this.state.id,
      ...values,
      coverages: coverages,
      benefits: benefits,
    };
    newItem = PrepareRowForSave(newItem, this.state.pageFormData.pageFormInputCompanyList);
    if (!Boolean(newItem.id)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      this.restartTable();
      showSuccess();
    }
  };

  addNewCategoryButton = () => {
    this.setState({
      addNewCategory: true,
    }, this.formRef.current.submit());
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          this.state.pageFormData.pageFormInputCompanyList.forEach(input => {
            Boolean(input.tableColumnValue) ? obj[input.tableColumnValue] = element[input.label] : obj[input.inputName] = element[input.label];
          });
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            this.restartTable();
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  }; 

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            },
            {
              name: "Coverages",
              icon: <ZoomInOutlined style={{ fontSize: "small", color: "#FFA800" }} />,
              actionClick: this.coveragesModal.bind(this)
            },
            {
              name: "Benefits",
              icon: <ZoomInOutlined style={{ fontSize: "small", color: "#1BC5BD" }} />,
              actionClick: this.benefitsModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  addNewCoverage = async () => {
    if (Boolean(this.state.coverage) && Boolean(this.state.coverageTitle)) {
      this.setState((prevState) => ({
        coverages: [
          ...prevState.coverages,
          {
            id: this.state.coverageId + 1,
            coverageTitle: this.state.coverageTitle,
            coverage: this.state.coverage,
          },
        ],
        coverageId: this.state.coverageId + 1,
        coverageTitle: "",
        coverage: "",
      }));
      this.formRef.current.setFieldsValue({
        coverageTitle: "",
        coverage: "",
      });
    } else {
      showError("Please enter title and coverage");
    }
  };

  removeCoverage = async (id) => {
    if (id > 0) {
      this.setState({
        coverages: this.state.coverages.filter(
          (p) => p.id !== id
        ),
      });
    } else {
      showError();
    }
  };

  editCoverage = async (row) => {
    if (Boolean(row.id)) {
      this.setState({
        editCoverageId: row.id,
        coverageTitle: row.coverageTitle,
        coverage: row.coverage,
        editCoverage: true,
      });
    } else {
      showError();
    }
  };

  saveEditCoverage = async () => {
    const item = this.state.coverages.find((p) => p.id === this.state.editCoverageId);
    if (Boolean(item)) {
      item.coverage = this.state.coverage;
      item.coverageTitle = this.state.coverageTitle;
      this.setState({
        editCoverage: false,
        coverageTitle: "",
        coverage: "",
        editCoverageId: null,
      });
      showSuccess();
    } else {
      showError();
    }
  };

  addNewBenefit = async () => {
    if (Boolean(this.state.benefit) && Boolean(this.state.benefitTitle)) {
      this.setState((prevState) => ({
        benefits: [
          ...prevState.benefits,
          {
            id: this.state.benefitId + 1,
            benefitTitle: this.state.benefitTitle,
            benefit: this.state.benefit,
          },
        ],
        benefitId: this.state.benefitId + 1,
        benefitTitle: "",
        benefit: "",
      }));
      this.formRef.current.setFieldsValue({
        benefitTitle: "",
        benefit: "",
      });
    } else {
      showError("Please enter title and benefit");
    }
  };

  removeBenefit = async (id) => {
    if (id > 0) {
      this.setState({
        benefits: this.state.benefits.filter(
          (p) => p.id !== id
        ),
      });
    } else {
      showError();
    }
  };

  editBenefit = async (row) => {
    if (Boolean(row.id)) {
      this.setState({
        editBenefitId: row.id,
        benefitTitle: row.benefitTitle,
        benefit: row.benefit,
        editBenefit: true,
      });
    } else {
      showError();
    }
  };

  saveEditBenefit = async () => {
    const item = this.state.benefits.find((p) => p.id === this.state.editBenefitId);
    if (Boolean(item)) {
      item.benefit = this.state.benefit;
      item.benefitTitle = this.state.benefitTitle;
      this.setState({
        editBenefit: false,
        benefitTitle: "",
        benefit: "",
        editBenefitId: "",
      });
      showSuccess();
    } else {
      showError();
    }
  };

  handleCoveragesChange = (paginationAssetCoverages, filters, sorter) => {
    this.setState({
      paginationAssetCoverages: paginationAssetCoverages,
    });
  };

  handleBenefitsChange = (paginationBenefitsCoverages, filters, sorter) => {
    this.setState({
      paginationBenefitsCoverages: paginationBenefitsCoverages,
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    const layout1 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const columnsCovereges = [
      {
        title: 'S/N', key: 'id', render: (value, row, index) => {
          return index + 1 + (this.state.paginationAssetCoverages.pageSize * (this.state.paginationAssetCoverages.current - 1));
        },
        width: "5%",
        align: 'center',
      },
      {
        title: 'Title', key: 'coverageTitle', render: (record) => {
          return record.coverageTitle;
        },
        width: "45%",
      },
      {
        title: 'Coverage', key: 'coverage', render: (record) => {
          return record.coverage;
        },
        width: "50%",
      },
    ];

    const columnsBenefits = [
      {
        title: 'S/N', key: 'id', render: (value, row, index) => {
          return index + 1 + (this.state.paginationBenefitsCoverages.pageSize * (this.state.paginationBenefitsCoverages.current - 1));
        },
        width: "5%",
        align: 'center',
      },
      {
        title: 'Title', key: 'benefitTitle', render: (record) => {
          return record.benefitTitle;
        },
        width: "45%",
      },
      {
        title: 'Benefit', key: 'benefit', render: (record) => {
          return record.benefit;
        },
        width: "50%",
      },
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename={this.state.formName} hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
              {Boolean(this.state.pageFormData) &&
                this.state.pageFormData.pageFormInputCompanyList.map(item => {
                  return (
                    <ExcelColumn
                      key={item.inputName}
                      label={item.label}
                      value={Boolean(item.tableColumnValue) ? item.tableColumnValue : item.inputName}
                    />
                  );
                })}
            </ExcelSheet>
          </ExcelFile>
        ) : (
            <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
          )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col md={21}>
            <span className="h3">
              <FormattedMessage
                id={this.state.controllerName + ".pageTitle"}
                defaultMessage={Boolean(this.state.pageFormData) ? this.state.pageFormData.pageForm.title : ""}
              />
            </span>
          </Col>
          <Col md={1}>{excelExport}</Col>
          <Col md={1}>
            <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
          </Col>
          <Col md={1}>
            <AntButton
              style={{ border: "0px" }}
              hidden={!this.state.hideInputs}
              onClick={this.onClickCreateNew}
              icon={<CreateIcon color="secondary" fontSize="large" />}
            ></AntButton>
            <AntButton
              style={{ border: "0px" }}
              hidden={this.state.hideInputs}
              onClick={this.onClickCreateNew}
              icon={<RemoveCircle color="error" fontSize="large" />}
            ></AntButton>
          </Col>
        </Row>
        <div hidden={this.state.hideInputs}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
              <ElementsProvider
                inputs={this.state.pageFormData.pageFormInputCompanyList}
                layout={layout}
                form={this.formRef}
                refresh={this.state.refreshForm}
              />
            ) : null}
            <br />

            {
              <Form.Item
                {...layout1}
                label={
                  <FormattedMessage
                    id="todo"
                    defaultMessage="Insurance Coverage"
                  />
                }
              >
                <Row>
                  <Col span={6}>
                    {
                      <Form.Item>
                        <Input
                          placeholder="Title"
                          value={this.state.coverageTitle}
                          onChange={(e) =>
                            this.setState({
                              coverageTitle: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    }
                  </Col>
                  <Col span={6}>
                    {
                      <Form.Item>
                        <Input
                          placeholder="Coverage"
                          id="coverage"
                          value={this.state.coverage}
                          onChange={(e) =>
                            this.setState({
                              coverage: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    }
                  </Col>
                  <Col span={1}>
                    {
                      <>
                        {this.state.editCoverage === false &&
                          <Tooltip title="Add">
                            <AntButton
                              type="primary"
                              onClick={this.addNewCoverage}
                              icon={<PlusOutlined />}
                            ></AntButton>
                          </Tooltip>}
                        {this.state.editCoverage === true &&
                          <Tooltip title="Save">
                            <AntButton
                              type="default" style={{ color: "white", background: "#1BC5BD", borderColor: "#1BC5BD" }}
                              onClick={this.saveEditCoverage}
                              icon={<CheckOutlined />}
                            ></AntButton>
                          </Tooltip>}
                      </>
                    }
                  </Col>
                </Row>
              </Form.Item>
            }

            {this.state.coverages.map(
              (item, index) => (
                <Form.Item
                  {...layout1}
                  label={index + 1}
                >
                  <Row gutter={[8, 8]}>
                    <Col span={6}>
                      {
                        <Form.Item>{item.coverageTitle}</Form.Item>
                      }
                    </Col>
                    <Col span={6}>
                      {<Form.Item>{item.coverage}</Form.Item>}
                    </Col>
                    <Col span={2}>
                      {
                        <Space>
                          <Tooltip title="Edit">
                            <AntButton
                              type="default"
                              style={{ color: "white", background: "#FFA800", borderColor: "#FFA800" }}
                              onClick={() =>
                                this.editCoverage(item)
                              }
                              primary
                              icon={<EditOutlined />}
                            ></AntButton>
                          </Tooltip>

                          <Tooltip title="Delete">
                            <AntButton
                              type="primary"
                              onClick={() =>
                                this.removeCoverage(item.id)
                              }
                              danger
                              icon={<CloseOutlined />}
                            ></AntButton>
                          </Tooltip>
                        </Space>
                      }
                    </Col>
                  </Row>
                </Form.Item>
              )
            )}

            {
              <Form.Item
                {...layout1}
                label={
                  <FormattedMessage
                    id="todo"
                    defaultMessage="Benefits"
                  />
                }
              >
                <Row>
                  <Col span={6}>
                    {
                      <Form.Item>
                        <Input
                          placeholder="Title"
                          value={this.state.benefitTitle}
                          onChange={(e) =>
                            this.setState({
                              benefitTitle: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    }
                  </Col>
                  <Col span={6}>
                    {
                      <Form.Item>
                        <Input
                          placeholder="Benefit"
                          id="benefit"
                          value={this.state.benefit}
                          onChange={(e) =>
                            this.setState({
                              benefit: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    }
                  </Col>
                  <Col span={1}>
                    {
                      <>
                        {this.state.editBenefit === false &&
                          <Tooltip title="Add">
                            <AntButton
                              type="primary"
                              onClick={this.addNewBenefit}
                              icon={<PlusOutlined />}
                            ></AntButton>
                          </Tooltip>}
                        {this.state.editBenefit === true &&
                          <Tooltip title="Save">
                            <AntButton
                              type="default" style={{ color: "white", background: "#1BC5BD", borderColor: "#1BC5BD" }}
                              onClick={this.saveEditBenefit}
                              icon={<CheckOutlined />}
                            ></AntButton>
                          </Tooltip>}
                      </>
                    }
                  </Col>
                </Row>
              </Form.Item>
            }

            {this.state.benefits.map(
              (item, index) => (
                <Form.Item
                  {...layout1}
                  label={index + 1}
                >
                  <Row gutter={[8, 8]}>
                    <Col span={6}>
                      {
                        <Form.Item>{item.benefitTitle}</Form.Item>
                      }
                    </Col>
                    <Col span={6}>
                      {<Form.Item>{item.benefit}</Form.Item>}
                    </Col>
                    <Col span={2}>
                      {
                        <Space>
                          <Tooltip title="Edit">
                            <AntButton
                              type="default"
                              style={{ color: "white", background: "#FFA800", borderColor: "#FFA800" }}
                              onClick={() =>
                                this.editBenefit(item)
                              }
                              primary
                              icon={<EditOutlined />}
                            ></AntButton>
                          </Tooltip>

                          <Tooltip title="Delete">
                            <AntButton
                              type="primary"
                              onClick={() =>
                                this.removeBenefit(item.id)
                              }
                              danger
                              icon={<CloseOutlined />}
                            ></AntButton>
                          </Tooltip>
                        </Space>
                      }
                    </Col>
                  </Row>
                </Form.Item>
              )
            )}

            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="AddNewCategoryButton" onClick={this.addNewCategoryButton} style={{ width: "100%" }} variant="primary">
                      <FormattedMessage id="InsuranceAddNewCategory" defaultMessage="Add New Category" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </Form>
        </div>
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
            <DataTableComponent2
              key="dataTable"
              field={this.state.pageFormData.pageForm}
              inputs={this.state.pageFormData.pageFormInputCompanyList}
              actions={this.action}
              editRow={this.edit.bind(this)}
              restartTable={this.state.restartTable}
              createWhereParams={this.state.createWhereParams}
              setData={this.setData.bind(this)}
            />
          ) : null}
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <AntModal title="Coverages"
          visible={this.state.modalCoveragesShow}
          onCancel={() => {
            this.setState({
              modalCoveragesShow: false
            });
          }}
          width={1000}
          centered
          footer={[
            <Button variant="secondary" onClick={() => {
              this.setState({
                modalCoveragesShow: false
              });
            }}>Close</Button>
          ]}
        >
          <Table
            bordered
            columns={columnsCovereges}
            dataSource={this.state.coveragesList}
            onChange={this.handleCoveragesChange}
          />
        </AntModal>

        <AntModal title="Benefits"
          visible={this.state.modalBenefitsShow}
          onCancel={() => {
            this.setState({
              modalBenefitsShow: false
            });
          }}
          width={1000}
          centered
          footer={[
            <Button variant="secondary" onClick={() => {
              this.setState({
                modalBenefitsShow: false
              });
            }}>Close</Button>
          ]}
        >
          <Table
            bordered
            columns={columnsBenefits}
            dataSource={this.state.benefitsList}
            onChange={this.handleBenefitsChange}
          />
        </AntModal>

      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InsurancePolicy);
