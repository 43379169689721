import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Tooltip, Drawer, Switch, Tree, InputNumber, Checkbox } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined, Settings } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import DraggableModal from "../../../Components/DraggableModal";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import BillOfQuantityVendor from "./BillOfQuantityVendor";
import { FcFolder, FcFile } from "react-icons/fc";
import { FaFileAudio, FaRegFile } from "react-icons/fa";

function updateTreeDataVendor(list, key, children, expandedKeysVendor) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeysVendor) };
    }

    if (node.children) {
      return { ...node, children: updateTreeDataVendor(node.children, key, children, expandedKeysVendor) };
    }

    return node;
  });
}

function updateTreeDataGroup(list, key, children, expandedKeysGroup) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeysGroup) };
    }

    if (node.children) {
      return { ...node, children: updateTreeDataGroup(node.children, key, children, expandedKeysGroup) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

export class BillOfQuantitySendRFQ extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BillOfQuantitySendRFQ",
      controllerName: "billOfQuantity",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      itemIds: [],
      nodesGroup: [],
      nodesVendor: [],
      dontSendForItems: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      selectedRowKeys: []
    };
  }

  formRef = React.createRef();
  formRefGroupVendor = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillTreeGroup();
    this.fillTreeVendor();
  }

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/pagingSendRFQ";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      tenderingLogId: this.props.tenderingLogId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  updateTransportation = async (id, transportation) => {
    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateTransportation/" + id + "/" + transportation);
    if (response.type === "ERROR") {
      this.error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  updateSendToVendors = async () => {
    const { selectedVendorRowKeys, billOfQuantityIds } = this.state;
    let vendorList = [];
    if (Boolean(selectedVendorRowKeys) && selectedVendorRowKeys.length > 0) {
      if (Boolean(billOfQuantityIds) && billOfQuantityIds.length > 0) {
        selectedVendorRowKeys.forEach(element => {
          if (Boolean(element) && element > 0) {
            vendorList.push({ "vendorId": element });
          }
        });

        var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateSendToVendors/" + billOfQuantityIds, vendorList);
        if (response.type === "ERROR") {
          error(response);
        } else {
          this.setState({
            modalVendorsShow: false,
            modalSendToVendorsShow: false,
          });
          if (Boolean(this.props.close)) {
            this.props.close();
          }
        }
      } else {
        showWarning("Bill Of Quantity not found.");
      }
    } else {
      showWarning("Please select vendor.");
    }
  };

  updateSelectedVendorRowKeys = (selectedVendorRowKeys, billOfQuantityIds) => {
    this.setState({
      selectedVendorRowKeys: selectedVendorRowKeys,
      billOfQuantityIds: billOfQuantityIds
    });
  };

  sendToVendors = async () => {
    const { selectedRowKeys, tableList } = this.state;
    let itemIds = [];
    if (Boolean(selectedRowKeys) && selectedRowKeys.length > 0) {
      selectedRowKeys.forEach(element => {
        let row = tableList.find(p => p.id === element);
        if (Boolean(row) && Boolean(row.itemId) && row.itemId > 0) {
          itemIds.push(row.itemId);
        }
      });
    }

    if (Boolean(itemIds) && itemIds.length > 0) {
      this.setState({
        modalSendToVendorsShow: false,
        modalVendorsShow: true,
        itemIds: itemIds,
      });
    } else {
      showWarning("Item not found, please match the item first.");
    }
  };

  onExpandVendor = expandedKeys => {
    this.setState({
      expandedKeysVendor: expandedKeys,
      autoExpandParent: false
    });
  };

  onExpandGroup = expandedKeys => {
    this.setState({
      expandedKeysGroup: expandedKeys,
      autoExpandParent: false
    });
  };

  loadDataGroup = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeysGroup, nodesGroup } = this.state;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "ProjectName"
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsGroup/" + obj.id + "/" + this.props.tenderingLogId);
    }
    if (response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "ProjectName" ? (
            <FcFile />
          ) : element.type === "Project" ? (
            <FaRegFile />
          ) : element.type === "ProjectChild" ? (
            <FaFileAudio />
          ) : (
            <FcFolder />
          );
        element.id = element.key;
      });

      var res = updateTreeDataGroup(nodesGroup, treenode.key, data, expandedKeysGroup);
      this.setState({
        nodesGroup: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  loadDataVendor = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeysVendor, nodesVendor } = this.state;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "ProjectName"
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsVendor/" + obj.id + "/" + this.props.tenderingLogId);
    }
    if (response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "ProjectName" ? (
            <FcFile />
          ) : element.type === "Project" ? (
            <FaRegFile />
          ) : element.type === "ProjectChild" ? (
            <FaFileAudio />
          ) : (
            <FcFolder />
          );
        element.id = element.key;
      });

      var res = updateTreeDataVendor(nodesVendor, treenode.key, data, expandedKeysVendor);
      this.setState({
        nodesVendor: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  onLoadDataVendor = async loadedKeys => {
    this.setState({
      loadedKeysVendor: loadedKeys
    });
  };

  onLoadDataGroup = async loadedKeys => {
    this.setState({
      loadedKeysGroup: loadedKeys
    });
  };

  onCheckGroup = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    this.setState({
      checkedKeysGroup: checkedKeys,
    });
  };

  onCheckVendor = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    this.setState({
      checkedKeysVendor: checkedKeys,
    });
  };

  fillTreeGroup = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/rootGroup");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "ProjectName";
          }
        });

        this.setState({
          nodesGroup: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  fillTreeVendor = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/rootVendor");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "ProjectName";
          }
        });

        this.setState({
          nodesVendor: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  sendRfqGroupVendor = async (values) => {
    const { checkedKeysGroup, checkedKeysVendor } = this.state;
    console.log("values", values);
    console.log("checkedKeysGroup", checkedKeysGroup);
    console.log("checkedKeysVendor", checkedKeysVendor);

    let newObj = {
      ...values,
      vendorIds: Boolean(checkedKeysVendor) ? checkedKeysVendor.checked : [],
      groupIds: Boolean(checkedKeysGroup) ? checkedKeysGroup.checked : [],
    }
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/sendRfqGroupVendor/" + this.props.tenderingLogId, newObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(response.data) && Boolean(response.data.itemIds) && response.data.itemIds.length > 0
        && Boolean(response.data.billOfQuantityIds) && response.data.billOfQuantityIds.length > 0) {
        this.setState({
          modalSendShow: false,
          modalVendorsShow: true,
          itemIds: response.data.itemIds,
          selectedRowKeys: response.data.billOfQuantityIds,
        });
        if (Boolean(this.props.close)) {
          this.props.close();
        }
      } else {
        showWarning("Item not found.");
      }
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: record => {
        return {
          checked: Boolean(record.id)
        };
      }
    };

    const layout2 = {
      labelCol: { span: 6 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="BillOfQuantitySendRFQ.Group" defaultMessage="Group" />,
        key: "groupId",
        render: record => {
          return record.groupName;
        },
        ...getColumnFilter({
          inputName: "groupName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BillOfQuantitySendRFQ.ItemDescription" defaultMessage="Item Description" />,
        key: "itemName",
        render: record => {
          return record.itemName;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="BillOfQuantitySendRFQ.Quantity" defaultMessage="Quantity" />,
        key: "clientsQuantity",
        render: record => {
          let clientQuantity = "";
          if (Boolean(record.clientsQuantity) && record.clientsQuantity > 0) {
            clientQuantity += record.clientsQuantity + " ";

            if (Boolean(record.unitName)) {
              clientQuantity += record.unitName;
            }
          }
          return clientQuantity;
        },
        ...getColumnFilter({
          inputName: "clientsQuantity",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BillOfQuantitySendRFQ.PlannedDeliveryDate" defaultMessage="Planned Delivery Date" />,
        key: "plannedDeliveryDate",
        render: record => {
          return record.plannedDeliveryDate;
        },
        ...getColumnFilter({
          inputName: "plannedDeliveryDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BillOfQuantitySendRFQ.DeliveryPlace" defaultMessage="Delivery Place" />,
        key: "deliveryPlace",
        render: record => {
          return record.deliveryPlace;
        },
        ...getColumnFilter({
          inputName: "deliveryPlace",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="BillOfQuantitySendRFQ.Transportation" defaultMessage="Transportation" />,
        key: "transportation",
        render: (record) => {
          return (
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={record.transportation ? true : false}
              onChange={(value) => {
                this.updateTransportation(record.id, value);
              }}
            />
          );
        },
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
            <Col md={3}>
              <Button
                variant="primary"
                onClick={() => {
                  this.setState({
                    modalSendShow: true
                  });
                }}
                style={{ width: "100%", marginTop: "10px" }}
                disabled={Boolean(this.state.tableList) && this.state.tableList.length > 0 ?
                  Boolean(this.state.selectedRowKeys) && this.state.selectedRowKeys.length > 0 ? true : false
                  : true}
              >
                {<FormattedMessage id="BillOfQuantitySendRFQ.Send" defaultMessage="Send" />}
              </Button>
            </Col>
            <Col md={20}>
              <Button
                variant="primary"
                onClick={() => {
                  this.setState({
                    modalSendToVendorsShow: true
                  });
                }}
                style={{ marginTop: "10px" }}
                disabled={Boolean(this.state.selectedRowKeys) && this.state.selectedRowKeys.length > 0 ? false : true}
                loading={this.state.loading}
              >
                <FormattedMessage id="BillOfQuantitySendRFQ.SendSelected" defaultMessage="Send Selected" />
              </Button>
              <span style={{ marginLeft: 8 }}>
                {Boolean(this.state.selectedRowKeys) && this.state.selectedRowKeys.length > 0 ? `Selected ${selectedRowKeys.length} items` : ""}
              </span>
            </Col>
            <Col md={1}>
              {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                <Tooltip title={<FormattedMessage id="BillOfQuantitySendRFQ.Settings" defaultMessage="Settings" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      this.setState({ drawerSettingsShow: true });
                    }}
                    icon={<Settings color="error" fontSize="large" />}
                  ></AntButton>
                </Tooltip>
              )}
            </Col>
          </Row>
        </Form>

        {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
          <div className="alert alert-custom alert-light">
            <div className="alert-icon">
              <FilterOutlined />
            </div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (
                  <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                    <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                    <span>
                      <Tooltip title="Remove">
                        <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined
                  style={{ color: "crimson" }}
                  onClick={() =>
                    this.setState({ searchCriteriaModels: [] }, () => {
                      this.filterChangeItem([]);
                    })
                  }
                />
              </Tooltip>
            </div>
          </div>
        )}

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            rowKey={record => record.id}
            rowSelection={rowSelection}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Drawer
          title={<FormattedMessage id="BillOfQuantitySendRFQ.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"BillOfQuantitySendRFQ"} />
        </Drawer>

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="BillOfQuantitySendRFQ.SendToVendors" defaultMessage="Send To Vendors" />
              </span>
            </span>
          }
          centered
          open={this.state.modalSendToVendorsShow}
          onOk={() => this.sendToVendors()}
          onCancel={() => this.setState({ modalSendToVendorsShow: false })}
          okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <>
              <FormattedMessage
                id="BillOfQuantitySendRFQ.MessageSendRFQToVendors"
                defaultMessage="Do you really want to send this RFQ to Vendors ?"
              />
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantitySendRFQ.Vendors" defaultMessage="Vendors" />}
          centered
          width={1200}
          open={this.state.modalVendorsShow}
          onOk={() => this.updateSendToVendors()}
          onCancel={() => this.setState({ modalVendorsShow: false })}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <BillOfQuantityVendor
              itemIds={this.state.itemIds}
              billOfQuantityIds={this.state.selectedRowKeys}
              random={Math.random()}
              updateSelectedVendorRowKeys={this.updateSelectedVendorRowKeys} />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantitySendRFQ.Send" defaultMessage="Send" />}
          centered
          width={800}
          open={this.state.modalSendShow}
          onOk={() => this.formRefGroupVendor.current.submit()}
          onCancel={() => this.setState({ modalSendShow: false })}
          okText={<FormattedMessage id="GeneralButtonOk" defaultMessage="Ok" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.sendRfqGroupVendor} onFinishFailed={onFinishFailed} ref={this.formRefGroupVendor}>
              <Tree
                style={{ marginTop: "10px", lineHeight: "30px" }}
                className="draggable-tree"
                showLine={{ showLeafIcon: false }}
                showIcon
                checkStrictly={true}
                loadedKeys={this.state.loadedKeysGroup}
                onLoad={this.onLoadDataGroup}
                loadData={this.loadDataGroup}
                onExpand={this.onExpandGroup}
                expandedKeys={this.state.expandedKeysGroup}
                autoExpandParent={false}
                treeData={this.state.nodesGroup}
                blockNode={true}
                checkable
                onCheck={this.onCheckGroup}
                checkedKeys={this.state.checkedKeysGroup}
              />
              <Tree
                style={{ marginTop: "10px", lineHeight: "30px" }}
                className="draggable-tree"
                showLine={{ showLeafIcon: false }}
                showIcon
                checkStrictly={true}
                loadedKeys={this.state.loadedKeysVendor}
                onLoad={this.onLoadDataVendor}
                loadData={this.loadDataVendor}
                onExpand={this.onExpandVendor}
                expandedKeys={this.state.expandedKeysVendor}
                autoExpandParent={false}
                treeData={this.state.nodesVendor}
                blockNode={true}
                checkable
                onCheck={this.onCheckVendor}
                checkedKeys={this.state.checkedKeysVendor}
              />
              <br /><br />

              <Form.Item
                {...layout2}
                label={<FormattedMessage id="PageAutoDesignTableInput.DontSendForItems" defaultMessage="Don't send for items" />}
                name="dontSendForItems"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox onChange={(e) => {
                  this.setState({
                    dontSendForItems: e.target.checked
                  });
                }}></Checkbox>
              </Form.Item>


              {this.state.dontSendForItems && <Form.Item
                {...layout2}
                label={<FormattedMessage id="BillOfQuantitySendRFQ.RFQReceivedInLast" defaultMessage=" RFQ received in last" />}
                name="day"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber placeholder="day" style={{ width: "100%" }} addonAfter="days" />
              </Form.Item>}
            </Form>
          }
        />
      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BillOfQuantitySendRFQ);