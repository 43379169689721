import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Button as AntButton, Drawer, Card, Space, Image, Cascader, Spin, Tooltip, Checkbox } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { Add, EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import DraggableModal from "../../../Components/DraggableModal";
import { InputNumber } from "antd";
import { Select } from "antd";
import { Input } from "antd";
import { DragOutlined } from "@ant-design/icons";
import EldenCompoundsSettingsProductTreeSelectableCompanyQuantity from "../../elden/equipment/EldenCompoundsSettingsProductTreeSelectableCompanyQuantity";
import BillOfQuantityAddProductLaborship from "./BillOfQuantityAddProductLaborship";
import BillOfQuantityAddProductIsolation from "./BillOfQuantityAddProductIsolation";
import BillOfQuantityAddProductItem from "./BillOfQuantityAddProductItem";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class BillOfQuantityAddProduct extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BillOfQuantityAddProduct",
      controllerName: "billOfQuantityAddProduct",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      latesPurchases: [],
      purchasingcriteriaSelectItems: [],
      residueOfDimensions: [],
      companyName: "Stock Card",
      selectedRow: null,
      page: 0,
      showAddMoreClick: false,
      quotationDay: -1,
      purchaseDay: -1,
      loadingPurchases: false,
      loadingQuotation: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      }
    };
  }

  formRef = React.createRef();
  formRefNewSupplier = React.createRef();
  formRefNewManufacturer = React.createRef();
  formRefNewBrand = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    this.setState({
      page: 0,
    }, () => {
      this.fillSelectedRow();
    });
    this.fillComboboxes();
    this.resetInputs();
  };

  fillSelectedRow = async () => {
    this.formRef.current.resetFields();
    this.setState({ loading: true });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getSelectedRow/" + this.props.tenderingLogId + "/"
      + this.props.productTreeIds + "/" + this.state.page + "/" + this.props.continueToAdd);
    if (response.type === "ERROR") {
      error(response);
    }
    let data = Boolean(response.data) ? response.data : null;
    if (Boolean(data)) {
      var unit = dataList.find(p => p.key === data.unitId);
      let unitId = [];
      if (Boolean(unit)) {
        unitId = unit.lineage;
      }

      this.formRef.current.setFieldsValue({
        ...data,
        unitId: unitId,
      });
      this.setState({
        selectedRow: data,
        loading: false,
      }, () => {
        this.fillItems();
        this.fillBrand();
      });
    } else {
      showWarning("There are no items left in the list.");
      this.props.onCancel();
    }
  }

  fillItems = async () => {
    const { selectedRow } = this.state;
    if (Boolean(selectedRow) && Boolean(selectedRow.id) && selectedRow.id > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillItems/" + selectedRow.id);
      if (response.type === "ERROR") {
        error(response);
      }
      let data = Boolean(response.data) ? response.data : [];
      this.setState({
        itemIdSelectItems: data,
        stockCardId: Boolean(data) && data.length > 0 ? data[0].id : 0,
        proposedVendorId: selectedRow.proposedVendorId,
      }, () => {
        this.fillBrand();
        this.getPurchaseCriteria();
        this.fillLatesPurchases();
        this.fillLatesQuotations();
        this.fillDimensions();
      });
    } else {
      this.setState({
        itemIdSelectItems: [],
      });
    }
  }

  fillComboboxes = async () => {
    this.fillSupplier();
    this.fillManufacturer();
    var responseGroup = await handleRequest("GET", "/api/" + this.state.controllerName + "/group");
    if (responseGroup.type === "ERROR") {
      error(responseGroup);
    }
    this.setState({
      groupIdSelectItems: Boolean(responseGroup.data) ? responseGroup.data : []
    });

    var responsecurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responsecurrency.type === "ERROR") {
      error(responsecurrency);
    } else {
      this.setState({
        currencySelectItems: Boolean(responsecurrency.data) ? responsecurrency.data : []
      });
    }

    var responseCompanyName = await handleRequest("GET", "/api/" + this.state.controllerName + "/getCompanyName");
    if (responseCompanyName.type === "ERROR") {
      error(responseCompanyName);
    } else {
      this.setState({
        companyName: Boolean(responseCompanyName.data) ? "" + responseCompanyName.data.companyShortName + " Stock Card" : "Stock Card"
      });
    }
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const { residueOfDimensions } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      unitId: Boolean(values.unitId) && values.unitId.length > 0 ? values.unitId[values.unitId.length - 1] : null,
      productTreeId: this.state.selectedRow.id,
      tenderingLogId: this.props.tenderingLogId,
    };

    if (Boolean(residueOfDimensions)) {
      newItem.residueOfDimensions = residueOfDimensions;
    } else {
      newItem.residueOfDimensions = [];
    }

    console.log("newItem", newItem);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showAddMoreOptions: this.state.showAddMoreClick,
        showAddMoreClick: false,
        billOfQuantityAddProductId: response.data,
      });
    }
  };

  skip = async () => {
    this.setState({
      page: this.state.page + 1,
      latesPurchases: null,
      latesQuotations: null
    }, () => {
      this.formRef.current.setFieldsValue({
        latesPurchases: null,
        latesQuotations: null,
      });
      this.fillSelectedRow();
    });
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleFormNewSupplierSubmit = async values => {
    const newItem = {
      ...values,
      supplier: true,
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createVendor", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showNewSupplierModal: false
      });
      showSuccess();
      this.fillSupplier();
    }
  };

  handleFormNewManufacturerSubmit = async values => {
    const newItem = {
      ...values,
      manufacturer: true,
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createVendor", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showNewManufacturerModal: false
      });
      showSuccess();
      this.fillManufacturer();
    }
  };

  fillSupplier = async () => {
    const { brandId } = this.state;
    let brand = Boolean(brandId) ? brandId : -1;
    var responsesupplierId = await handleRequest("GET", "/api/" + this.state.controllerName + "/supplier/" + brand);
    if (responsesupplierId.type === "ERROR") {
      error(responsesupplierId);
    }
    this.setState({
      supplierIdSelectItems: Boolean(responsesupplierId.data) ? responsesupplierId.data : []
    });
  }

  fillManufacturer = async () => {
    var responsemanufacturerId = await handleRequest("GET", "/api/" + this.state.controllerName + "/manufacturer");
    if (responsemanufacturerId.type === "ERROR") {
      error(responsemanufacturerId);
    }
    this.setState({
      manufacturerIdSelectItems: Boolean(responsemanufacturerId.data) ? responsemanufacturerId.data : []
    });
  }

  fillBrand = async () => {
    const { proposedVendorId, stockCardId } = this.state;
    if (Boolean(stockCardId)) {
      let vendorId = Boolean(proposedVendorId) ? proposedVendorId : -1;
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/brand/" + vendorId + "/" + stockCardId);
      if (response.type === "ERROR") {
        error(response);
      }
      this.setState({
        brandIdSelectItems: Boolean(response.data) ? response.data : []
      });
    }
  }

  getPurchaseCriteria = async () => {
    const { stockCardId } = this.state;
    if (Boolean(stockCardId)) {
      let responsePurchaseCriteria = await handleRequest("GET", "/api/" + this.state.controllerName + "/purchasingcriterias/" + stockCardId);
      if (responsePurchaseCriteria.type === "ERROR") {
        error(responsePurchaseCriteria);
      } else {
        generateList(responsePurchaseCriteria.data);
        this.setState({
          purchasingcriteriaSelectItems: Boolean(responsePurchaseCriteria.data) && responsePurchaseCriteria.data.length > 0 ? responsePurchaseCriteria.data : []
        });
      }
    } else {
      this.setState({
        purchasingcriteriaSelectItems: []
      });
    }
  };

  fillLatesPurchases = async () => {
    this.setState({ loadingPurchases: true });
    let { stockCardId, purchaseDay } = this.state;
    if (!Boolean(purchaseDay)) {
      purchaseDay = -1;
    }
    if (Boolean(stockCardId)) {
      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillLatesPurchases/" + stockCardId + "/" + purchaseDay);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          loadingPurchases: false
        });
      } else {
        let latesPurchases = Boolean(response.data) && response.data.length > 0 ? response.data : [];
        this.setState({
          latesPurchases: latesPurchases,
          loadingPurchases: false
        });
        this.formRef.current.setFieldsValue({
          latesPurchases: latesPurchases,
        });
      }
    } else {
      this.setState({
        latesPurchases: null,
        loadingPurchases: false
      });
      this.formRef.current.setFieldsValue({
        latesPurchases: null,
      });
    }
  };

  fillDimensions = async () => {
    const { stockCardId } = this.state;
    if (Boolean(stockCardId) && stockCardId > 0) {
      let responseItemDescription = await handleRequest("GET", "/api/" + this.state.controllerName + "/getEldenItem/" + stockCardId);

      if (responseItemDescription.type === "ERROR") {
        error(responseItemDescription);
      } else {
        let itemDescription = Boolean(responseItemDescription.data) ? responseItemDescription.data : [];
        this.setState({
          itemDescription: itemDescription
        });

        if (Boolean(itemDescription.itemDimension) && itemDescription.itemDimension.length > 0) {
          itemDescription.itemDimension.forEach(element => {
            this.fillCode("StockCard" + element.itemDefinition);
            if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
              this.formRef.current.setFieldsValue({
                [element.itemDefinition]: null
              });
            }
          });
        }
        if (Boolean(itemDescription.itemCodeCriterias) && itemDescription.itemCodeCriterias.length > 0) {
          itemDescription.itemCodeCriterias.forEach(element => {
            this.fillCode("StockCard" + element.itemDefinition);
            if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
              this.formRef.current.setFieldsValue({
                [element.itemDefinition]: null
              });
            }
          });
        }
        let residueOfDimensions = [];
        if (Boolean(itemDescription.dimensionList) && itemDescription.dimensionList.length > 0) {
          itemDescription.dimensionList.forEach(element => {
            if (Boolean(itemDescription.itemDimension) && itemDescription.itemDimension.length > 0) {
              let f = itemDescription.itemDimension.find(x => x.itemDefinition === element.definition);
              if (!Boolean(f)) {
                residueOfDimensions.push({
                  key: element.id,
                  definition: element.definition,
                  value: null,
                  itemDimensionId: element.id,
                  unitId: null,
                  units: Boolean(element.unitType) ? element.unitType.units : []
                });
              }
            } else {
              residueOfDimensions.push({
                key: element.id,
                definition: element.definition,
                value: null,
                itemDimensionId: element.id,
                unitId: null,
                units: Boolean(element.unitType) ? element.unitType.units : []
              });
            }
          });
        }
        this.setState({
          residueOfDimensions: residueOfDimensions
        });
        if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
          this.formRef.current.setFieldsValue({
            residueOfDimensions: residueOfDimensions
          });
        }

        let selectedInch = false;
        let response = await handleRequest("GET", "/api/stockCard/" + stockCardId);
        if (response.type === "ERROR") {
          error(response);
        } else {
          let stockCard = Boolean(response.data) ? response.data : [];
          console.log("stockCard", stockCard);

          if (Boolean(stockCard.itemDimension) && stockCard.itemDimension.length > 0) {
            stockCard.itemDimension.forEach(element => {
              console.log("element111", element);
              if (element.unitAbbreviation === "Inch" || element.unitAbbreviation === "''") {
                selectedInch = true;
              }
              this.formRef.current.setFieldsValue({
                [element.itemDefinition]: element.codeId,
                [element.itemDefinition + "Unit"]: element.unitId,
                [element.itemDefinition + "inchValue"]: element.inchValue,
                [element.itemDefinition + "inchValueFraction"]: element.inchValueFraction
              });
            });
          }
          if (Boolean(stockCard.itemCodeCriterias) && stockCard.itemCodeCriterias.length > 0) {
            stockCard.itemCodeCriterias.forEach(element => {
              console.log("element", element);
              let data = stockCard.stockCardOptions.filter(p => p.itemDefinitionName === element.itemDefinition);
              console.log("data", data);

              this.formRef.current.setFieldsValue({
                [element.itemDefinition]: element.codeId,
                [element.itemDefinition + "stockCardOptions"]: data,
              });
            });
          }


          if (itemDescription?.itemDimension?.length > 0) {
            itemDescription.itemDimension.forEach(element => {
              let selectedInch = false;
              if (stockCard?.itemDimension?.length > 0) {
                selectedInch = stockCard.itemDimension.some(item => {
                  return element.itemDefinition === item.itemDefinition &&
                    (item.unitAbbreviation === "Inch" || item.unitAbbreviation === "''");
                });
              }
              element.selectedInch = selectedInch;
            });
          }

          this.setState({
            itemDescription: itemDescription,
          });
        }
      }
    }
  }

  fillLatesQuotations = async () => {
    this.setState({ loadingQuotation: true });
    const { stockCardId, quotationDay } = this.state;
    if (!Boolean(quotationDay)) {
      quotationDay = -1;
    }
    if (Boolean(stockCardId)) {
      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillLatesQuotations/" + stockCardId + "/" + quotationDay);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          loadingQuotation: false
        });
      } else {
        let latesQuotations = Boolean(response.data) && response.data.length > 0 ? response.data : [];
        this.setState({
          latesQuotations: latesQuotations,
          loadingQuotation: false
        });
        this.formRef.current.setFieldsValue({
          latesQuotations: latesQuotations,
        });
      }
    } else {
      this.setState({
        latesQuotations: null,
        loadingQuotation: false
      });
      this.formRef.current.setFieldsValue({
        latesQuotations: null,
      });
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  addMore = async () => {
    this.formRef.current.submit();
    this.setState({ showAddMoreClick: true });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layoutLeft = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };

    const layoutRight = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 }
    };
    const { selectedRow } = this.state;
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Spin spinning={this.state.loading}>
            {Boolean(selectedRow) && <>
              <Card
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                  height: "100%"
                }}
              >
                <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
                  <Col span={5}>
                    <Form.Item
                      name="productName"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Input style={{ width: "100%" }} readOnly placeholder="Product" />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      name="compoundName"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Input style={{ width: "100%" }} readOnly placeholder="Compound" />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      name="componentName"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Input style={{ width: "100%" }} readOnly placeholder="Component" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    {selectedRow.imageUrl &&
                      <Image width={100} src={selectedRow.imageUrl} />}
                  </Col>
                  <Col span={5}>
                    <Button id="ProductsButton" style={{ width: "100%" }} variant="secondary"
                      onClick={() => {
                        this.setState({ showProductsModal: true });
                      }}>
                      <FormattedMessage id="BillOfQuantity.Products" defaultMessage="Products" />
                    </Button>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
                  <Col md={20}>
                    {
                      <Form.Item
                        name="itemName"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input style={{ width: "100%" }} readOnly placeholder="Item Description" />
                      </Form.Item>
                    }
                  </Col>
                  <Col md={4}>
                  </Col>
                </Row>
              </Card>
              <br /><br />

              <Row gutter={16} style={{ marginBottom: "16px" }}>
                {/* Sol Card */}
                <Col span={8}>
                  <h5>
                    {this.state.companyName}
                  </h5>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="BillOfQuantityAddProduct.Item" defaultMessage="Item" />}
                      name="itemId"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children"
                        onChange={async (value) => {
                          this.setState({
                            stockCardId: value
                          }, () => {
                            this.fillBrand();
                            this.getPurchaseCriteria();
                            this.fillLatesPurchases();
                            this.fillLatesQuotations();
                            this.fillDimensions();
                          });
                          if (Boolean(value) && value > 0) {
                            let item = this.state["itemIdSelectItems"].find(p => p.id === value);
                            if (Boolean(item)) {
                              console.log("item", item);

                              this.formRef.current.setFieldsValue({
                                clientCode: item.clientCode,
                                description: item.description,
                                referenceCode: item.referenceCode,
                              });

                            } else {
                              this.formRef.current.setFieldsValue({
                                clientCode: "",
                                description: "",
                                referenceCode: "",
                              });
                            }
                          } else {
                            this.formRef.current.setFieldsValue({
                              clientCode: "",
                              description: "",
                              referenceCode: "",
                            });
                          }
                        }}>
                        {Boolean(this.state["itemIdSelectItems"]) &&
                          this.state["itemIdSelectItems"].length > 0 &&
                          this.state["itemIdSelectItems"].map(i => (
                            <Option key={i.id} value={i.id}>
                              {i.renameText}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="BillOfQuantityAddProduct.Supplier" defaultMessage="Supplier" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <Form.Item
                            name="proposedVendorId"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            style={{ width: "90%" }}
                          >
                            <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children"
                              onChange={value => {
                                this.setState({
                                  proposedVendorId: value,
                                }, () => {
                                  this.fillBrand();
                                });
                              }}>
                              {Boolean(this.state["supplierIdSelectItems"]) &&
                                this.state["supplierIdSelectItems"].length > 0 &&
                                this.state["supplierIdSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>

                          <AntButton
                            type="default"
                            style={{
                              color: "black",
                              background: "white",
                              width: "10%"
                            }}
                            onClick={() => {
                              this.setState({ showNewSupplierModal: true });
                            }}
                            icon={<Add />}
                          ></AntButton>
                        </Space.Compact>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="BillOfQuantityAddProduct.Brand" defaultMessage="Brand" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <Form.Item
                            name="brandId"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            style={{ width: "90%" }}
                          >
                            <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children"
                              onChange={value => {
                                this.setState({
                                  brandId: value,
                                }, () => {
                                  this.fillSupplier();
                                });
                              }}>
                              {Boolean(this.state["brandIdSelectItems"]) &&
                                this.state["brandIdSelectItems"].length > 0 &&
                                this.state["brandIdSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Space.Compact>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="BillOfQuantityAddProduct.Quantity" defaultMessage="Quantity" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Row gutter={8}>
                          <Col span={12}>
                            <Form.Item
                              name="quantity"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <InputNumber style={{ width: '100%' }} min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="unitId"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <Cascader showSearch allowClear optionFilterProp="children" style={{ width: '100%' }} options={this.state.purchasingcriteriaSelectItems} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    }

                    {Boolean(this.state.itemDescription) &&
                      Boolean(this.state.itemDescription.codeCriterias) &&
                      this.state.itemDescription.codeCriterias.length > 0 &&
                      this.state.itemDescription.codeCriterias.map(element => (
                        <>
                          {Boolean(element.criteriaOptions) && element.criteriaOptions.length > 0
                            ?
                            <>
                              <Row>
                                <Col md={4}></Col>
                                <Col md={20}>
                                  {element.itemDefinition}
                                  <Form.List name={element.itemDefinition + "stockCardOptions"}>
                                    {(fields, { add, remove }) => {
                                      return (
                                        <>
                                          <TableBoot bordered size="sm">
                                            <tbody>
                                              {fields.map(field => {
                                                return (
                                                  <tr key={field.key}>
                                                    <Form.Item hidden noStyle name={[field.name, "itemCodeCriteriaId"]}>
                                                      <InputNumber style={{ width: "100%" }} />
                                                    </Form.Item>
                                                    {/* <td>
                                                      <Form.Item noStyle name={[field.name, "selected"]}
                                                        valuePropName="checked"
                                                      >
                                                        <Checkbox style={{ width: "100%" }} />
                                                      </Form.Item>
                                                    </td> */}
                                                    <td>
                                                      <Form.Item noStyle name={[field.name, "itemCodeCriteriaName"]}>
                                                        <Input readOnly style={{ width: "100%" }} />
                                                      </Form.Item>
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            </tbody>
                                          </TableBoot>
                                        </>
                                      );
                                    }}
                                  </Form.List>
                                </Col>
                              </Row>
                            </>
                            :
                            <Form.Item
                              {...layoutLeft}
                              label={
                                <Tooltip title={element.itemDefinition}>
                                  <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', maxWidth: '150px' }}>
                                    {element.itemDefinition}
                                  </span>
                                </Tooltip>
                              }
                              name={element.itemDefinition}
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <Select disabled showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                {Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"]) &&
                                  this.state["StockCard" + element.itemDefinition + "SelectItems"].map(i => (
                                    <Option key={i.id} value={i.id}>
                                      {i.name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          }
                        </>
                      ))}

                    {Boolean(this.state.itemDescription) &&
                      Boolean(this.state.itemDescription.itemDimension) &&
                      this.state.itemDescription.itemDimension.length > 0 &&
                      this.state.itemDescription.itemDimension.map(element => (
                        <Form.Item
                          {...layoutLeft}
                          label={
                            <Tooltip title={element.itemDefinition}>
                              <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', maxWidth: '150px' }}>
                                {element.itemDefinition}
                              </span>
                            </Tooltip>
                          }
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Row>
                            <Col span={element.selectedInch ? 6 : 11}>
                              <Form.Item
                                name={element.itemDefinition}
                                rules={[
                                  { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                              >
                                <Select disabled showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                  {Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"]) &&
                                    this.state["StockCard" + element.itemDefinition + "SelectItems"].map(i => (
                                      <Option key={i.id} value={i.id}>
                                        {i.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            {element.selectedInch ?
                              <>
                                <Col span={element.selectedInch ? 5 : 0}>
                                  <Form.Item
                                    name={element.itemDefinition + "inchValue"}
                                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                  >
                                    <Input disabled style={{ width: "100%" }} />
                                  </Form.Item>
                                </Col>
                                /
                                <Col span={element.selectedInch ? 5 : 0}>
                                  <Form.Item
                                    name={element.itemDefinition + "inchValueFraction"}
                                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                                  >
                                    <Input
                                      style={{ width: "100%" }}
                                      disabled
                                    />
                                  </Form.Item>
                                </Col>
                              </>
                              :
                              <Col span={1}></Col>
                            }
                            <Col span={element.selectedInch ? 6 : 11}>
                              <Form.Item
                                name={element.itemDefinition + "Unit"}
                                rules={[
                                  { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                              >
                                <Select disabled showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                  {element.units &&
                                    element.units.map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                      ))}

                    {Boolean(this.state.residueOfDimensions) &&
                      this.state.residueOfDimensions.length > 0 &&
                      this.state.residueOfDimensions.map((dimension, index) => {
                        return (
                          <Form.Item key={index} {...layoutLeft}
                            label={
                              <Tooltip title={dimension.definition}>
                                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', maxWidth: '150px' }}>
                                  {dimension.definition}
                                </span>
                              </Tooltip>
                            }
                          >
                            <Row>
                              <Col span={11}>
                                <InputNumber
                                  parser={value => value.replace(",", ".")}
                                  formatter={value => value.replace(".", ",")}
                                  placeholder={dimension.definition}
                                  style={{ width: "100%" }}
                                  min={0}
                                  value={dimension.value}
                                  onChange={value => {
                                    let list = this.state.residueOfDimensions;
                                    list[index].value = value;
                                    this.setState(
                                      {
                                        residueOfDimensions: list
                                      },
                                      () => {
                                        if (dimension.definition === "Length" || dimension.definition === "Width") {
                                          console.log("selectedStockCard", this.state.selectedStockCard);
                                        }
                                      }
                                    );
                                  }}
                                />
                              </Col>
                              <Col span={2}></Col>
                              <Col span={11}>
                                <Select
                                  showSearch
                                  allowClear
                                  optionFilterProp="children"
                                  style={{ width: "100%" }}
                                  placeholder="Unit"
                                  value={dimension.unitId}
                                  onChange={value => {
                                    console.log("VVV", value);
                                    console.log(dimension);
                                    let list = this.state.residueOfDimensions;
                                    list[index].unitId = value;
                                    this.setState(
                                      {
                                        residueOfDimensions: list
                                      },
                                      () => {
                                        if (dimension.definition === "Length" || dimension.definition === "Width") {
                                          console.log("selectedStockCard", this.state.selectedStockCard);
                                        }
                                      }
                                    );
                                  }}
                                >
                                  {dimension.units &&
                                    dimension.units.map(i => (
                                      <Option key={i.id} value={i.id}>
                                        {i.description}
                                      </Option>
                                    ))}
                                </Select>
                              </Col>
                            </Row>
                          </Form.Item>
                        );
                      })}
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="BillOfQuantityAddProduct.UnitPrice" defaultMessage="Unit Price" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Row gutter={8}>
                          <Col span={12}>
                            <Form.Item
                              name="unitPrice"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <InputNumber style={{ width: '100%' }} min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="currency"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                {Boolean(this.state.currencySelectItems) &&
                                  this.state.currencySelectItems.length > 0 &&
                                  this.state.currencySelectItems.map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    }

                    {
                      <Row gutter={[16, 16]}>
                        <Button id="AddMoreButton" onClick={this.addMore} style={{ width: "100%" }} variant="primary">
                          <FormattedMessage id="GeneralButtonAddMore" defaultMessage="Add More" />
                        </Button>
                      </Row>
                    }

                  </Card>
                </Col>

                <Col span={8}>
                  <h5>
                    <FormattedMessage id="BillOfQuantityAddProduct.LatestPurchases" defaultMessage="Latest Purchases" />
                  </h5>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Form.Item
                      {...layoutRight}
                      name="latesPurchasesDay"
                      label={<FormattedMessage id="BillOfQuantityAddProduct.Last" defaultMessage="Last" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber
                        onChange={value => {
                          this.setState({
                            purchaseDay: value
                          }, () => {
                            this.fillLatesPurchases();
                          });
                        }}
                        addonAfter="day" min={0} style={{ width: '100%' }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                    </Form.Item>

                    <Spin spinning={this.state.loadingPurchases}>
                      <Form.List name="latesPurchases">
                        {(fields, { add, remove }) => {
                          const handleMoveClick = (fieldName) => {
                            const latesPurchases = this.formRef.current.getFieldValue("latesPurchases");
                            let row = latesPurchases[fieldName];
                            if (Boolean(row) && Boolean(row.unitPrice) && row.unitPrice > 0) {
                              this.formRef.current.setFieldsValue({
                                unitPrice: row.unitPrice,
                              });
                            } else {
                              this.formRef.current.setFieldsValue({
                                unitPrice: 0,
                              });
                            }
                          };
                          return (
                            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                              <TableBoot bordered size="sm">
                                <thead>
                                  <tr>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.UnitPrice"} defaultMessage={"Unit Price"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.Date"} defaultMessage={"Date"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.Vendor"} defaultMessage={"Vendor"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.Move"} defaultMessage={"Move"} />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item name={[field.name, "selectedValue"]} valuePropName="checked">
                                            <Checkbox onClick={(e) => {
                                              const latesPurchases = this.formRef.current.getFieldValue("latesPurchases");
                                              let row = latesPurchases[field.name];
                                              row.selectedValue = e.target.checked;

                                              this.setState({
                                                latesPurchases: latesPurchases,
                                              });
                                              this.formRef.current.setFieldsValue({
                                                latesPurchases: latesPurchases,
                                              });

                                              let total = 0;
                                              let count = 0;
                                              latesPurchases.forEach(element => {
                                                if (element.selectedValue) {
                                                  total += parseFloat(element.unitPrice);
                                                  count++;
                                                }
                                              });

                                              console.log("latesPurchases", latesPurchases);
                                              if (Boolean(total) && total > 0 && Boolean(count) && count > 0) {
                                                this.formRef.current.setFieldsValue({
                                                  latesPurchasesAverage: parseFloat((total / count).toFixed(2)),
                                                });
                                              } else {
                                                this.formRef.current.setFieldsValue({
                                                  latesPurchasesAverage: 0,
                                                });
                                              }
                                            }} />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "unitPrice"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "date"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "vendorName"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <DragOutlined onClick={() => {
                                            handleMoveClick(field.name);
                                          }} />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </TableBoot>
                            </div>
                          );
                        }}
                      </Form.List>
                    </Spin>

                    {Boolean(this.state.latesPurchases) && this.state.latesPurchases.length > 1 &&
                      <Form.Item
                        {...layoutRight}
                        name="latesPurchasesAverage"
                        label={<FormattedMessage id="BillOfQuantityAddProduct.Average" defaultMessage="Average" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        style={{ marginTop: '30px' }}
                      >
                        <InputNumber readOnly addonAfter={
                          <Tooltip title="Move">
                            <DragOutlined onClick={() => {
                              const latesPurchasesAverage = this.formRef.current.getFieldValue("latesPurchasesAverage");
                              if (Boolean(latesPurchasesAverage) && latesPurchasesAverage > 0) {
                                this.formRef.current.setFieldsValue({
                                  unitPrice: latesPurchasesAverage,
                                });
                              } else {
                                this.formRef.current.setFieldsValue({
                                  unitPrice: 0,
                                });
                              }
                            }} />
                          </Tooltip>
                        } placeholder="unit price"
                          min={0} style={{ width: '100%' }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                      </Form.Item>}
                  </Card>
                </Col>

                <Col span={8}>
                  <h5>
                    <FormattedMessage id="BillOfQuantityAddProduct.LatestQuotations" defaultMessage="Latest Quotations" />
                  </h5>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Form.Item
                      {...layoutRight}
                      name="latesQuotationsDay"
                      label={<FormattedMessage id="BillOfQuantityAddProduct.Last" defaultMessage="Last" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber
                        onChange={value => {
                          this.setState({
                            quotationDay: value
                          }, () => {
                            this.fillLatesQuotations();
                          });
                        }}
                        addonAfter="day" min={0} style={{ width: '100%' }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                    </Form.Item>

                    <Spin spinning={this.state.loadingQuotation}>
                      <Form.List name="latesQuotations">
                        {(fields, { add, remove }) => {
                          const handleMoveClick = (fieldName) => {
                            const latesQuotations = this.formRef.current.getFieldValue("latesQuotations");
                            let row = latesQuotations[fieldName];
                            if (Boolean(row) && Boolean(row.unitPrice) && row.unitPrice > 0) {
                              this.formRef.current.setFieldsValue({
                                unitPrice: row.unitPrice,
                              });
                            } else {
                              this.formRef.current.setFieldsValue({
                                unitPrice: 0,
                              });
                            }
                          };

                          return (
                            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                              <TableBoot bordered size="sm">
                                <thead>
                                  <tr>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.UnitPrice"} defaultMessage={"Unit Price"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.Date"} defaultMessage={"Date"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.Project"} defaultMessage={"Project"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.Deviation"} defaultMessage={"Deviation"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.Move"} defaultMessage={"Move"} />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "unitPrice"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "date"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "projectName"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "deviation"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <DragOutlined onClick={() => {
                                            handleMoveClick(field.name);
                                          }} />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </TableBoot>
                            </div>
                          );
                        }}
                      </Form.List>
                    </Spin>
                  </Card>
                </Col>
              </Row>
            </>}
          </Spin>
          <br />
          <br />
          <div hidden={this.state.hideSave}>
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={18} lg={18} xl={18} xxl={18}></Col>
                <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
                  <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.skip}>
                    <FormattedMessage id="GeneralButtonSkip" defaultMessage="Skip" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
                  <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Col>
              </Row>
            }
          </div>
          {/*  <div hidden={this.state.hideUpdate}>
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            }
          </div> */}
        </Form>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Drawer
          title={<FormattedMessage id="BillOfQuantityAddProduct.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"BillOfQuantityAddProduct"} />
        </Drawer>

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantityAddProduct.NewSupplier" defaultMessage="New Supplier" />}
          centered
          width={600}
          open={this.state.showNewSupplierModal}
          onOk={() => this.formRefNewSupplier.current.submit()}
          onCancel={() => {
            this.setState({ showNewSupplierModal: false });
          }}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Form id="FormVendor" initialValues={{ remember: false }} onFinish={this.handleFormNewSupplierSubmit} ref={this.formRefNewSupplier}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Vendor.name" defaultMessage="Name" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="VendorContact.address" defaultMessage="Address" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="VendorContact.emails" defaultMessage="Email" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="emails"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </div>
          }
        ></DraggableModal>


        <DraggableModal
          title={<FormattedMessage id="BillOfQuantityAddProduct.NewManufacturer" defaultMessage="New Manufacturer" />}
          centered
          width={600}
          maskClosable={false}
          open={this.state.showNewManufacturerModal}
          onOk={() => this.formRefNewManufacturer.current.submit()}
          onCancel={() => {
            this.setState({ showNewManufacturerModal: false });
          }}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Form id="FormVendor" initialValues={{ remember: false }} onFinish={this.handleFormNewManufacturerSubmit} ref={this.formRefNewManufacturer}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Vendor.name" defaultMessage="Name" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="VendorContact.address" defaultMessage="Address" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="VendorContact.emails" defaultMessage="Email" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="emails"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </div>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantityAddProduct.Products" defaultMessage="Products" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showProductsModal}
          onCancel={() => {
            this.setState({ showProductsModal: false });
          }}
          okButtonProps={{ hidden: true }}
          content={
            <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%" }}>
              <EldenCompoundsSettingsProductTreeSelectableCompanyQuantity productTreeAllIds={this.props.productTreeAllIds} />
            </div>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantity.AddMore" defaultMessage="Add More" />}
          centered
          width={400}
          maskClosable={false}
          open={this.state.showAddMoreOptions}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.cancel();
            this.setState({
              showAddMoreOptions: false,
              page: this.state.page + 1,
              latesPurchases: null,
              latesQuotations: null,
              showAddMoreClick: false,
              billOfQuantityAddProductId: null
            }, () => {
              this.formRef.current.setFieldsValue({
                latesPurchases: null,
                latesQuotations: null,
              });
              this.fillSelectedRow();
            });
          }}
          content={
            <>
              <Button
                onClick={() => {
                  this.setState({
                    showItemModal: true,
                    random: Math.random(),
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="BillOfQuantity.Item" defaultMessage="Item" />}
              </Button>
              <br /> <br />
              <Button
                onClick={() => {
                  this.setState({
                    showLaborshipModal: true,
                    random: Math.random(),
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="BillOfQuantity.Laborship" defaultMessage="Laborship" />}
              </Button>
              <br /> <br />
              <Button
                onClick={() => {
                  this.setState({
                    showIsolationModal: true,
                    random: Math.random(),
                  });
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                {<FormattedMessage id="BillOfQuantity.Isolation" defaultMessage="Isolation" />}
              </Button>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantityAddProduct.Item" defaultMessage="Item" />}
          centered
          width={1300}
          maskClosable={false}
          open={this.state.showItemModal}
          onCancel={() => {
            this.setState({ showItemModal: false });
          }}
          okButtonProps={{ hidden: true }}
          content={
            <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%" }}>
              <BillOfQuantityAddProductItem
                random={this.state.random}
                billOfQuantityAddProductId={this.state.billOfQuantityAddProductId}
                productTreeId={Boolean(this.state.selectedRow) ? this.state.selectedRow.id : null}
                tenderingLogId={this.props.tenderingLogId}
                close={() => {
                  this.setState({ showItemModal: false });
                }}
              />
            </div>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantityAddProduct.Laborship" defaultMessage="Laborship" />}
          centered
          width={1200}
          maskClosable={false}
          open={this.state.showLaborshipModal}
          onCancel={() => {
            this.setState({ showLaborshipModal: false });
          }}
          okButtonProps={{ hidden: true }}
          content={
            <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%" }}>
              <BillOfQuantityAddProductLaborship
                random={this.state.random}
                billOfQuantityAddProductId={this.state.billOfQuantityAddProductId}
                close={() => {
                  this.setState({ showLaborshipModal: false });
                }}
              />
            </div>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantityAddProduct.Isolation" defaultMessage="Isolation" />}
          centered
          width={1200}
          maskClosable={false}
          open={this.state.showIsolationModal}
          onCancel={() => {
            this.setState({ showIsolationModal: false });
          }}
          okButtonProps={{ hidden: true }}
          content={
            <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%" }}>
              <BillOfQuantityAddProductIsolation
                random={this.state.random}
                billOfQuantityAddProductId={this.state.billOfQuantityAddProductId}
                close={() => {
                  this.setState({ showIsolationModal: false });
                }}
              />
            </div>
          }
        />

      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BillOfQuantityAddProduct);