import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { Form, Table, Col, Row, Modal as AntModal } from "antd";
import { handleRequest } from "../../ApiConnector";
import CommentIcon from "@material-ui/icons/Comment";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditOutlined } from "@material-ui/icons";
import moment from "moment";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class CompanyAssetOnEmployee extends Component {
  constructor() {
    super();
    this.state = {
      controller: "companyAssetOnEmployee",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      personnelsSelectItems: [],
      totalBalance: 0,
      detailList: [],

      id: null,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
  };

  async initialize() {
    const { pagination } = this.state;
    this.restartTable({ pagination });
  }

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    var parent = dataList.find(p => p.key === row.parentId);
    let parentId = [];

    if (Boolean(parent)) {
      parentId = parent.lineage;
    }

    this.formRef.current.setFieldsValue({
      ...row,
      parentId: parentId
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controller + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controller + "/paging";
    const response = await handleRequest("POST", url, newObj);
    console.log("AAA", response.data);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalBalance: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (Boolean(response.data) && response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
          element.key = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
          },
          totalBalance: response.data.totalBalance,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalBalance: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      parentId: Boolean(values.parentId) ? values.parentId[values.parentId.length - 1] : null
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controller + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controller + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";

    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controller + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              // actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              // actionClick: this.deleteModal.bind(this)
            },
            {
              name: "Show",
              icon: <CommentIcon fontSize="small" color="secondary" />,
              actionClick: this.show.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  show = async (record) => {
    var response = await handleRequest("GET", "/api/" + this.state.controller + "/salaryDetail");
    if (response.type === "ERROR") {
      showError();
    } else {
      var detailList = [];
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach((item, index) => {
          item.key = index;
        });
        detailList = response.data;
      }
      var personnelName = Boolean(record.name) ? record.name + " - " : "";
      var identificationNo = Boolean(record.identificationNo) ? record.identificationNo + " - " : "";
      var departmentName = Boolean(record.departmentName) ? record.departmentName : "";
      this.setState({
        detailShow: true,
        detailList: detailList,
        modalDetailTitle: personnelName + identificationNo + departmentName,
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="CompanyAssetOnEmployee.name" defaultMessage="Name" />,
        key: "name",
        render: record => {
          return record.name;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="CompanyAssetOnEmployee.identificationNo" defaultMessage="ID" />,
        key: "identificationNo",
        render: record => {
          return record.identificationNo;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="CompanyAssetOnEmployee.departmentName" defaultMessage="Department" />,
        key: "departmentName",
        render: record => {
          return record.departmentName;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="CompanyAssetOnEmployee.designationName" defaultMessage="Designation" />,
        key: "designationName",
        render: record => {
          return record.designationName;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="CompanyAssetOnEmployee.assets" defaultMessage="Assets" />,
        key: "assets",
        render: record => {
          return record.assets;
        },
        sorter: false
      },
    ];

    const columnsDetail = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="CompanyAssetOnEmployee.asset" defaultMessage="Asset" />,
        key: "asset",
        render: record => {
          return record.asset;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="CompanyAssetOnEmployee.description" defaultMessage="Description" />,
        key: "description",
        render: record => {
          return record.description;
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="CompanyAssetOnEmployee.photo" defaultMessage="Photo" />,
        key: "photo",
        render: record => {
          return "";
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="CompanyAssetOnEmployee.dateOfUseReceive" defaultMessage="Date of use/receive" />,
        key: "dateOfUseReceive",
        render: record => {
          return Boolean(record.dateOfUseReceive) ? moment(record.dateOfUseReceive).format("DD-MM-YYYY") : "";
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="CompanyAssetOnEmployee.handoverDate" defaultMessage="Handover Date" />,
        key: "handoverDate",
        render: record => {
          return Boolean(record.handoverDate) ? moment(record.handoverDate).format("DD-MM-YYYY") : "";
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="CompanyAssetOnEmployee.surrenderDate" defaultMessage="Surrender Date" />,
        key: "surrenderDate",
        render: record => {
          return Boolean(record.surrenderDate) ? moment(record.surrenderDate).format("DD-MM-YYYY") : "";
        },
        sorter: false
      },
      {
        title: <FormattedMessage id="CompanyAssetOnEmployee.status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          return record.status;
        },
        sorter: false
      },
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={24}>
              <span className="h3">
                <FormattedMessage
                  id={this.state.controllerName + ".pageTitle"}
                  defaultMessage={"Company Asset on Employee"}
                />
              </span>
            </Col>
          </Row>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            rowKey="id"
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <AntModal
          title={this.state.modalDetailTitle}
          visible={this.state.detailShow}
          onCancel={() => {
            this.setState({ detailShow: false });
          }}
          width={1200}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ detailShow: false });
              }}
            >
              Close
            </Button>,
          ]}
        >
          <Table
            rowKey="key"
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columnsDetail}
            dataSource={this.state.detailList}
            loading={this.state.loading}
            expandable={{
              expandedRowRender: (record) => (
                <Table
                  rowKey="key"
                  bordered
                  scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                  columns={columnsDetail}
                  dataSource={record.subList}
                  loading={this.state.loading}
                />
              ),
              rowExpandable: () => {
                return true;
              },
            }}
          />
        </AntModal>
      </div >
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAssetOnEmployee);