import { CheckOutlined, EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import SaveIcon from "@material-ui/icons/Save";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import { Button as AntButton, Checkbox, Collapse, Input, Modal as AntModal, Select } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
const { Panel } = Collapse;
const { Option } = Select;
export class RoleDescriptionContentCompany extends Component {
  constructor({ roleDescriptionCompanyId, roleDescriptionName }) {
    super();
    this.state = {
      formName: "RoleDescriptionContentCompany",
      controllerName: "roleDescriptionContentCompany",
      roleDescriptionCompanyId: roleDescriptionCompanyId,
      roleDescriptionName: roleDescriptionName,
      templateGroupId: 1, //may need if more templates needed
      modalDeleteShow: false,
      lastUpdateDate: [],
      id: null,
      tableList: [],
      templateList: [],
      showSimilarWordsModal: false,
      currentSimilarWords: [],
      selectedObject: null,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      contentToAdd: ""
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.restartTable();
    this.fillComboboxes();
  };
  componentDidUpdate = async prevProvs => {
    if (this.props.roleDescriptionCompanyId !== prevProvs.roleDescriptionCompanyId) {
      this.setState({ roleDescriptionCompanyId: this.props.roleDescriptionCompanyId, roleDescriptionName: this.props.roleDescriptionName }, () => {
        this.restartTable();
        this.fillComboboxes();
      });
    }
  };
  fillComboboxes = async () => {
    let response = await handleRequest("GET", "/api/roleDescriptionTemplateCompany/group/" + this.state.templateGroupId);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        templateList: response.data
      });
    }
  };

  edit = row => {
    this.formRef.current.setFieldsValue({
      ...row
    });
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showSimilarWordsModal = row => {
    this.setState({
      selectedObject: row,
      currentSimilarWords: row.similarNameList,
      showSimilarWordsModal: true
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async () => {
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/roleDescription/" + this.state.roleDescriptionCompanyId);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        tableList: response.data
      });
    }
  };

  save = async values => {
    if (!Boolean(values.id)) {
      values.templateGroupId = this.state.templateGroupId;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", values);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + values.id, values);
    }

    if (response.type === "ERROR") {
      showError(response);
      return false;
    } else {
      this.formRef.current.resetFields();
      this.restartTable();
      showSuccess();
      return true;
    }
  };
  saveAll = async () => {
    let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll", this.state.tableList);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
    }
  };

  addContent = async (roleDescriptionTemplate, contentToAdd) => {
    const { tableList } = this.state;
    if (contentToAdd.length > 0) {
      let newContent = {
        roleDescriptionCompanyId: this.state.roleDescriptionCompanyId,
        roleDescriptionTemplateCompanyId: roleDescriptionTemplate.id,
        name: contentToAdd,
        selected: true
      };
      tableList.push(newContent);

      this.setState({
        tableList: tableList
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Similar Words",
              icon: <SpellcheckIcon fontSize="small" color="secondary" />,
              actionClick: this.showSimilarWordsModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <div className="d-flex">
          <span className="flex-grow-1 h3">{this.state.roleDescriptionName}</span>
          <span>
            <AntButton
              style={{ border: "0px" }}
              onClick={() => {
                this.saveAll();
              }}
              icon={<SaveIcon color="primary" fontSize="large" />}
            >
              Save All
            </AntButton>
          </span>
        </div>
        <Collapse style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {this.state.templateList.map((item, index) => {
            //find content list by template id
            let contentList = this.state.tableList.filter(x => x.roleDescriptionTemplateCompanyId === item.id);
            return (
              <Panel header={item.name} key={index}>
                <div className="d-flex mb-2">
                  <span className="flex-grow-1 h3 mb-0">
                    <Input
                      onChange={e => {
                        this.setState({ ["contentToAdd" + index]: e.target.value });
                      }}
                      value={this.state["contentToAdd" + index]}
                      style={{ width: "100%" }}
                    />
                  </span>
                  <span>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.addContent(item, this.state["contentToAdd" + index]);
                        this.setState({ ["contentToAdd" + index]: "" });
                      }}
                      icon={<CreateIcon color="secondary" fontSize="large" />}
                    ></AntButton>
                  </span>
                </div>
                <ul className="list-group">
                  {Boolean(contentList) && contentList.length > 0
                    ? contentList.map((c, i) => (
                        <li key={i} className="list-group-item d-flex">
                          <span>
                            <Checkbox
                              checked={c.selected}
                              className="mr-2"
                              onChange={e => {
                                c.selected = e.target.checked;
                                this.setState({ tableList: this.state.tableList });
                              }}
                            ></Checkbox>
                          </span>
                          <span className="flex-grow-1 mb-0">{c.name}</span>
                        </li>
                      ))
                    : null}
                </ul>
              </Panel>
            );
          })}
        </Collapse>
        <div className="d-flex flex-row-reverse">
          <span>
            <AntButton
              style={{ border: "0px" }}
              onClick={() => {
                this.saveAll();
              }}
              icon={<SaveIcon color="primary" fontSize="large" />}
            >
              Save All
            </AntButton>
          </span>
        </div>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <AntModal
          title="Similar Words"
          visible={this.state.showSimilarWordsModal}
          onCancel={() => {
            this.setState({ showSimilarWordsModal: false });
          }}
          cancelButtonProps={{ style: { display: "none" } }}
          onOk={() => {
            this.setState({ showSimilarWordsModal: false });
          }}
          maskClosable={false}
        >
          <ul className="list-group">
            {this.state.currentSimilarWords.map((item, index) => (
              <li key={index} className="list-group-item d-flex">
                <span className={"flex-grow-1 h4 mb-0 pt-2" + (this.state.selectedObject.name === item ? " text-success" : "")}>{item}</span>
                <span>
                  <AntButton
                    disabled={this.state.selectedObject.name === item}
                    onClick={() => {
                      this.selectSimilarWord(item);
                    }}
                    icon={<CheckOutlined color="action" />}
                  >
                    {this.state.selectedObject.name === item ? "Using" : "Use"}
                  </AntButton>
                </span>
              </li>
            ))}
          </ul>
        </AntModal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RoleDescriptionContentCompany);
