import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import { Form, Col, Row, Button as AntButton, Drawer, Select, Input, Upload, Divider } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../redux/store";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { PrepareFileUploadEdit, PrepareFileUploadSave } from "../../Components/ElementUtils";

const { Option } = Select;

export class TenderingLogProjectDocuments extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "TenderingLogProjectDocuments",
      controllerName: "tenderingLogProjectDocuments",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      }
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    this.fillComboboxes();
  }

  fillComboboxes = async () => {
    this.fillCode("TenderingLogProjectDocuments.DocumentType");

    var responseData = await handleRequest("GET", "/api/" + this.state.controllerName + "/getDocuments/" + this.props.tenderingLogId);
    if (responseData.type === "ERROR") {
      error(responseData);
    }
    else {
      let tenderingLogProjectDocumentsList = Boolean(responseData.data) ? responseData.data : []
      tenderingLogProjectDocumentsList.forEach(element => {
        element.uploadPath = PrepareFileUploadEdit(element.uploadPath);
      });
      this.formRef.current.setFieldsValue({
        tenderingLogProjectDocumentsList: tenderingLogProjectDocumentsList,
      });
    }
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = { ...values };
    const { tenderingLogId } = this.props;
    if (Boolean(newItem.tenderingLogProjectDocumentsList) && newItem.tenderingLogProjectDocumentsList.length > 0) {
      newItem.tenderingLogProjectDocumentsList.forEach(item => {
        item.uploadPath = PrepareFileUploadSave(item.uploadPath);
      });
    }
    var response = await handleRequest(
      "POST",
      "/api/" + this.state.controllerName + "/saveProjectDocuments/" + tenderingLogId,
      values.tenderingLogProjectDocumentsList
    );

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      showSuccess();
      this.props.close();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };


  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    const normFile = e => {
      console.log("Upload event:", e);

      if (Array.isArray(e)) {
        return e;
      }

      return e && e.fileList;
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Form.List name="tenderingLogProjectDocumentsList">
            {(fields, { add, remove }) => {
              const handleAddClick = () => {
                add();
              };

              return (
                <>
                  <TableBoot bordered size="sm">
                    <thead>
                      <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="TenderingLogProjectDocuments.DocumentType" defaultMessage="Document Type" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center", width: "150px" }}>
                          <FormattedMessage id="TenderingLogProjectDocuments.Upload" defaultMessage="Upload" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="TenderingLogProjectDocuments.FileName" defaultMessage="File Name" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="TenderingLogProjectDocuments.DocNo" defaultMessage="Doc. No" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map((field, index) => {
                        return (
                          <tr key={field.key}>
                            <Form.Item hidden name={[field.name, "id"]}></Form.Item>
                            <td>
                              <Form.Item noStyle name={[field.name, "documentTypeId"]}>
                                <Select
                                  allowClear
                                  style={{ width: "100%" }}
                                  optionFilterProp="children"
                                  dropdownRender={menu => (
                                    <div>
                                      {menu}
                                      <Divider style={{ margin: "4px 0" }} />
                                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                        <Input
                                          style={{ flex: "auto" }}
                                          value={this.state.codeName}
                                          onChange={event => {
                                            this.setState({
                                              codeName: event.target.value
                                            });
                                          }}
                                        />
                                        <a
                                          style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                          onClick={() => this.addCode("TenderingLogProjectDocuments.DocumentType")}
                                        >
                                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                                        </a>
                                      </div>
                                    </div>
                                  )}
                                >
                                  {Boolean(this.state["TenderingLogProjectDocuments.DocumentTypeSelectItems"]) &&
                                    this.state["TenderingLogProjectDocuments.DocumentTypeSelectItems"].map(i => (
                                      <Option key={i.id} value={i.id}>
                                        {i.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item noStyle valuePropName="fileList" getValueFromEvent={normFile} name={[field.name, "uploadPath"]}>
                                <Upload {...props}>
                                  <AntButton icon={<UploadOutlined />}>
                                    <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                                  </AntButton>
                                </Upload>
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item style={{ width: "100%" }} name={[field.name, "fileName"]}>
                                <Input noStyle />
                              </Form.Item>
                            </td>

                            <td>
                              <Form.Item noStyle name={[field.name, "docNo"]}>
                                <Input noStyle />
                              </Form.Item>
                            </td>
                            <td>
                              <MinusCircleOutlined onClick={() => remove(field.name)} />
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={14}>
                          <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                            <FormattedMessage id={"TenderingLogProjectDocuments.AddNew"} defaultMessage={"Add New"} />
                          </AntButton>
                        </td>
                      </tr>
                    </tbody>
                  </TableBoot>
                </>
              );
            }}
          </Form.List>

          <div hidden={this.state.hideSave}>
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            }
          </div>
          <div hidden={this.state.hideUpdate}>
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            }
          </div>
        </Form>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Drawer
          title={<FormattedMessage id="TenderingLogProjectDocuments.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"TenderingLogProjectDocuments"} />
        </Drawer>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TenderingLogProjectDocuments);