import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Button as AntButton, Space } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Select, Cascader } from "antd";
import SVG from "react-inlinesvg";
import { Input } from "antd";
import { InputNumber } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, PlusOutlined, MinusCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import DraggableModal from "../../../Components/DraggableModal";

const { Option } = Select;

const dataList = [];
const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];

        dataList.push({
            key: node.value,
            lineage: node.lineage,
            title: node.value
        });
        if (node.children) {
            generateList(node.children);
        }
    }
};

export class ProjectsTreeCompanyRoomQuantity extends Component {
    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            formName: "ProjectsTreeCompanyRoomQuantity",
            controllerName: "roomQuantity",
            id: null,
            loading: false,
            sortField: "",
            sortOrder: "",
            exportClick: false,
            hideUpdate: true,
            hideSave: false,
            hideInputs: true,
            tableList: [],
            modalPersonnelList: [],
            modalEquipmentList: [],
            unitSelectItems: [],
            disciplineIdSelectItems: [],
            applicationIdSelectItems: [],
            modalApplicationShow: false,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
                total: 0
            },
            searchCriteriaModels: [],
        };
    }

    formRef = React.createRef();
    formRefDimension = React.createRef();
    formRefItem = React.createRef();

    componentDidMount = async () => {
        this.initialize();
    };

    componentDidUpdate = prevProps => {
        let random = this.props.random;
        if (random !== prevProps.random)
            this.initialize();
    };

    initialize = async () => {
        console.log("props", this.props);
        const { pagination } = this.state;
        this.restartTable({ pagination });
        fillFilterOperations();
        this.fillComboboxes();
        this.fillRoomQuantity();
    }

    fillRoomQuantity = async () => {
        let insert = true;
        let discipline = "";
        var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getRoomQuantity/" + this.props.houseTypeId
            + "/" + this.props.roomTypeId + "/" + this.props.id);
        if (response.type === "ERROR") {
            error(response);
        } else {
            if (Boolean(response.data) && response.data.length > 0) {
                insert = false;
                discipline = response.data[0].discipline;
                this.formRef.current.setFieldsValue({
                    discipline: discipline,
                    roomQuantityList: response.data,
                });

            }
        }
        if (insert) {
            this.formRef.current.resetFields();
        }
        this.setState({
            discipline: discipline,
        }, () => {
            this.fillDiscipline();
            this.fillRoomTypeId(this.props.houseTypeId);
            this.fillStructure();
        });
    }

    fillComboboxes = async () => {
        this.fillCode("Apartment.TypeOfHouses");

        var unitSelectItems = [];
        const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/units");
        if (responseTree.type === "ERROR") {
            error(responseTree);
        } else {
            if (Boolean(responseTree.data) && responseTree.data.length > 0) {
                generateList(responseTree.data);
                unitSelectItems = responseTree.data;
            }
        }
        this.setState({
            unitSelectItems: unitSelectItems
        });
    };

    fillStockCardId = async () => {
        const { itemId } = this.state;
        if (Boolean(itemId) && itemId > 0) {
            var responseEldenStockCardId = await handleRequest("GET", "/api/" + this.state.controllerName + "/eldenStockCardId/" + itemId);
            if (responseEldenStockCardId.type === "ERROR") {
                error(responseEldenStockCardId);
            } else {
                this.setState({
                    eldenStockCardIdSelectItems: Boolean(responseEldenStockCardId.data) ? responseEldenStockCardId.data : []
                });
            }
        } else {
            this.setState({
                eldenStockCardIdSelectItems: []
            });
        }
    }

    fillStockCardIdIn = async () => {
        const { itemIds } = this.state;
        console.log("itemIds", itemIds);
        if (Boolean(itemIds) && itemIds.length > 0) {
            var responseEldenStockCardId = await handleRequest("GET", "/api/" + this.state.controllerName + "/eldenStockCardIdIn/" + itemIds);
            if (responseEldenStockCardId.type === "ERROR") {
                error(responseEldenStockCardId);
            } else {
                this.setState({
                    eldenStockCardIdSelectItems: Boolean(responseEldenStockCardId.data) ? responseEldenStockCardId.data : []
                });
            }
        } else {
            this.setState({
                eldenStockCardIdSelectItems: []
            });
        }
    }

    fillRoomTypeId = async (houseTypeId) => {
        if (Boolean(houseTypeId) && houseTypeId > 0) {
            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/roomTypeId/" + houseTypeId);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    roomTypeIdSelectItems: Boolean(response.data) ? response.data : []
                });

                if (Boolean(this.props.roomTypeId) && this.props.roomTypeId > 0) {
                    let roomType = response.data.find(p => p.key === this.props.roomTypeId);
                    if (Boolean(roomType)) {
                        this.formRef.current.setFieldsValue({
                            "roomTypeName": roomType.value
                        });
                    }
                }
            }
        } else {
            this.setState({
                roomTypeIdSelectItems: []
            });
        }
    };

    fillDiscipline = async () => {
        var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/discipline");
        if (response.type === "ERROR") {
            error(response);
        } else {
            this.setState({
                disciplineIdSelectItems: Boolean(response.data) ? response.data : []
            });
        }
    };

    fillStructure = async () => {
        const { discipline } = this.state;
        if (Boolean(discipline)) {
            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/structure/" + discipline);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    structureSelectItems: Boolean(response.data) ? response.data : []
                });
            }
        } else {
            this.setState({
                structureSelectItems: []
            });
        }
    };

    fillSubStructure = async () => {
        const { structure } = this.state;
        if (Boolean(structure)) {
            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subStructure/" + structure);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    subStructureSelectItems: Boolean(response.data) ? response.data : []
                });
            }
        } else {
            this.setState({
                subStructureSelectItems: []
            });
        }
    };

    fillShape = async () => {
        const { subStructure } = this.state;
        if (Boolean(subStructure)) {
            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/shape/" + subStructure);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    shapeIdSelectItems: Boolean(response.data) ? response.data : []
                });
            }
        } else {
            this.setState({
                shapeIdSelectItems: []
            });
        }
    };

    fillSubStructureType = async () => {
        const { shapeId } = this.state;
        if (Boolean(shapeId)) {
            var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subStructureType/" + shapeId);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    subStructureTypeSelectItems: Boolean(response.data) ? response.data : []
                });
            }
        } else {
            this.setState({
                subStructureTypeSelectItems: []
            });
        }
    };

    fillItems = async () => {
        let newObj = {
            discipline: this.state.discipline,
            structure: this.state.structure,
            subStructure: this.state.subStructure,
            shapeId: this.state.shapeId,
            subStructureType: this.state.subStructureType,
        }
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/items", newObj);
        if (response.type === "ERROR") {
            error(response);
        } else {
            this.setState({
                roomApplicationItems: Boolean(response.data) ? response.data : []
            });
        }
    };

    restartTable = async (params = {}) => {
        // this.setState({ loading: true });
        // const newObj = this.createWhere(params);
        // let url = "/api/" + this.state.controllerName + "/paging";
        // const response = await handleRequest("POST", url, newObj);
        // if (response.type === "ERROR") {
        //     error(response);
        //     this.setState({
        //         loading: false,
        //         tableList: [],
        //         pagination: {
        //             ...params.pagination,
        //             total: 0
        //         },
        //         totalRecords: 0,
        //         sortField: "",
        //         sortOrder: ""
        //     });
        // } else {
        //     if (response.data.length !== 0) {
        //         let list = response.data.data;
        //         list.forEach((element, index) => {
        //             element.index = index + 1;
        //         });
        //         this.setState({
        //             loading: false,
        //             tableList: list,
        //             pagination: {
        //                 ...params.pagination,
        //                 total: response.data.totalRecords
        //             },
        //             totalRecords: response.data.totalRecords,
        //             sortField: params.sortField,
        //             sortOrder: params.sortOrder
        //         });
        //     } else {
        //         this.setState({
        //             loading: false,
        //             tableList: [],
        //             pagination: {
        //                 ...params.pagination,
        //                 total: 0
        //             },
        //             totalRecords: 0,
        //             sortField: "",
        //             sortOrder: ""
        //         });
        //     }
        // }
    };

    createWhere(params) {
        console.log(params);
        if (Boolean(params._pagination)) params.pagination = params._pagination;

        var lastUpdateDateStart = null,
            lastUpdateDateEnd = null,
            sortField = "",
            sortOrder = "";
        if (Boolean(params) && Boolean(params.pagination)) {
            if (Boolean(params.pagination.lastUpdateDate)) {
                lastUpdateDateStart = params.pagination.lastUpdateDate[0];
                lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
            }
            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }
        }
        const newObj = {
            ...params.pagination,
            id: null,

            searchCriteriaModels: this.state.searchCriteriaModels,
            lastUpdateDateStart: lastUpdateDateStart,
            lastUpdateDateEnd: lastUpdateDateEnd,
            firstResult: params.pagination.current - 1,
            maxResults: params.pagination.pageSize,
            sortField: sortField,
            sortOrder: sortOrder
        };
        return newObj;
    }

    fillCode = async codeType => {
        const response = await handleRequest("GET", "/api/codes/type/" + codeType);
        if (Boolean(response.data)) {
            this.setState({
                [codeType + "SelectItems"]: response.data
            });
            if (Boolean(this.props.houseTypeId) && this.props.houseTypeId > 0) {
                let houseType = response.data.find(p => p.id === this.props.houseTypeId);
                if (Boolean(houseType)) {
                    this.formRef.current.setFieldsValue({
                        "houseTypeName": houseType.name
                    });
                }
                this.fillRoomTypeId(this.props.houseTypeId);
            }
        }
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
    };

    edit = row => {
        document.getElementById("kt_scrolltop").click();

        let itemIds = [];
        if (Boolean(row.itemQuantities) && row.itemQuantities.length > 0) {
            row.itemQuantities.forEach(element => {
                if (Boolean(element.itemId) && !itemIds.includes(element.itemId)) {
                    itemIds.push(element.itemId);
                }
            });
        }

        this.setState({
            hideUpdate: false,
            hideSave: true,
            hideInputs: false,
            id: row.id,
            discipline: row.discipline,
            usedBefore: row.usedBefore,
            structure: row.structure,
            subStructure: row.subStructure,
            shapeId: row.shapeId,
            subStructureType: row.subStructureType,
            itemIds: itemIds,
        }, async () => {
            this.fillRoomTypeId(row.houseTypeId);
            this.fillDiscipline();
            this.fillStructure();
            this.fillSubStructure();
            this.fillShape();
            this.fillSubStructureType();
            this.fillItems();
            this.fillStockCardIdIn();

            if (Boolean(row.quantityDimensions) && row.quantityDimensions.length > 0) {
                row.quantityDimensions.forEach(element => {
                    if (Boolean(element.quantityUnitId)) {
                        var quantityUnit = dataList.find(p => p.key === element.quantityUnitId);
                        let quantityUnitId = [];
                        if (Boolean(quantityUnit)) {
                            quantityUnitId = quantityUnit.lineage;
                        }
                        element.quantityUnitId = quantityUnitId;
                    }
                });
            }

            if (Boolean(row.itemQuantities) && row.itemQuantities.length > 0) {
                row.itemQuantities.forEach(element => {
                    if (Boolean(element.quantityUnitId)) {
                        var quantityUnit = dataList.find(p => p.key === element.quantityUnitId);
                        let quantityUnitId = [];
                        if (Boolean(quantityUnit)) {
                            quantityUnitId = quantityUnit.lineage;
                        }
                        element.quantityUnitId = quantityUnitId;
                    }
                });
            }

            var quantityUnit = dataList.find(p => p.key === row.quantityUnitId);
            let quantityUnitId = [];
            if (Boolean(quantityUnit)) {
                quantityUnitId = quantityUnit.lineage;
            }

            var materialUnit = dataList.find(p => p.key === row.itemUnitId);
            let itemUnitId = [];
            if (Boolean(materialUnit)) {
                itemUnitId = materialUnit.lineage;
            }

            this.formRef.current.setFieldsValue({
                quantityUnitId: quantityUnitId,
                itemUnitId: itemUnitId,
                roomQuantityList: [row],
            });
        });
    };

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        };
        var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

        if (response.type === "ERROR") {
            error(response);
        } else {
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.setState({
                id: null,
                modalDeleteShow: false
            });
        }
    };
    deleteModal = row => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    };
    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };
    removeSearchCriteria = index => {
        let searchCriteriaModels = this.state.searchCriteriaModels;
        searchCriteriaModels.splice(index, 1);
        this.setState(
            {
                searchCriteriaModels: searchCriteriaModels
            },
            () => {
                this.filterChangeItem(searchCriteriaModels);
            }
        );
    };
    action = record => {
        let editAction = {
            name: "Edit",
            icon: <EditOutlined fontSize="small" color="primary" />,
            actionClick: this.edit.bind(this)
        };
        let deleteAction = {
            name: "Delete",
            icon: <DeleteIcon fontSize="small" color="error" />,
            actionClick: this.deleteModal.bind(this)
        };



        let actionList = [];
        actionList.push(editAction);
        actionList.push(deleteAction);



        return (
            <React.Fragment>
                <DynamicActionMenu row={record} actionList={actionList} />
            </React.Fragment>
        );
    };
    resetInputs = () => {
        this.formRef.current.resetFields();
        this.setState({
            id: null,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
                total: this.state.totalRecords
            },
            loading: false,
            sortField: "",
            sortOrder: "",
        });
    };
    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hideInputs: true
        });
        this.resetInputs();
        if (Boolean(this.props) && Boolean(this.props.roomQuantityModalClose)) {
            this.props.roomQuantityModalClose();
        }
    };
    onClickCreateNew = () => {
        this.resetInputs();
        if (this.state.hideInputs) {
            this.setState({
                hideInputs: false,
                hideSave: false,
                hideUpdate: true
            });
        } else {
            this.setState({
                hideInputs: true,
                hideSave: true,
                hideUpdate: false
            });
        }
    };

    save = async values => {
        console.log("values", values);
        if (Boolean(this.props.companyProjectId) && this.props.companyProjectId > 0) {
            if (Boolean(this.props.id) && this.props.id > 0 &&
                Boolean(this.props.houseTypeId) && this.props.houseTypeId > 0 && Boolean(this.props.roomTypeId) && this.props.roomTypeId > 0
                && Boolean(values.roomQuantityList) && values.roomQuantityList.length > 0) {

                let list = [];
                values.roomQuantityList.forEach(element => {
                    element.houseTypeId = this.props.houseTypeId;
                    element.roomTypeId = this.props.roomTypeId;
                    element.apartmentPeriferyWallContainId = this.props.id;

                    if (element.quantityDimensions && element.quantityDimensions.length > 0) {
                        element.quantityDimensions.forEach(dimension => {
                            if (Boolean(dimension.quantityUnitId) && dimension.quantityUnitId.length > 0) {
                                dimension.quantityUnitId = dimension.quantityUnitId[dimension.quantityUnitId.length - 1];
                            }
                        });
                    }

                    if (element.itemQuantities && element.itemQuantities.length > 0) {
                        element.itemQuantities.forEach(item => {
                            if (Boolean(item.quantityUnitId) && item.quantityUnitId.length > 0) {
                                item.quantityUnitId = item.quantityUnitId[item.quantityUnitId.length - 1];
                            }
                        });
                    }
                    list.push(element);
                });

                console.log("list", list);
                var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll/"
                    + this.props.houseTypeId + "/" + this.props.roomTypeId + "/" + this.props.companyProjectId, list);

                if (response.type === "ERROR") {
                    error(response);
                } else {
                    this.cancel();
                    const { pagination } = this.state;
                    this.restartTable({ pagination });
                    showSuccess();

                    if (Boolean(this.props) && Boolean(this.props.roomQuantityModalClose)) {
                        this.props.roomQuantityModalClose();
                    }
                }
            } else {
                showWarning("Please house type, room type or room unit not found.");
            }
        } else {
            showWarning("Company Project not found.");
        }
    };

    saveDimensions = values => {
        this.setState({
            modalDimensionShow: false,
        }, () => {
            const { fieldKey } = this.state;

            let roomQuantityList = this.formRef.current.getFieldValue("roomQuantityList");
            let row = roomQuantityList[fieldKey];
            console.log("row", row);

            if (Boolean(row)) {
                row.quantityDimensions = values.quantityDimensions;
            } else {
                roomQuantityList[fieldKey] = { quantityDimensions: values.quantityDimensions };
            }

            this.formRef.current.setFieldsValue({
                roomQuantityList: roomQuantityList
            });
        });
    }

    saveItemQuantities = values => {
        this.setState({
            modalItemQuantityShow: false,
        }, () => {
            const { fieldKey } = this.state;

            let roomQuantityList = this.formRef.current.getFieldValue("roomQuantityList");
            let row = roomQuantityList[fieldKey];
            console.log("row", row);

            if (Boolean(row)) {
                row.itemQuantities = values.itemQuantities;
            } else {
                roomQuantityList[fieldKey] = { itemQuantities: values.itemQuantities };
            }

            this.formRef.current.setFieldsValue({
                roomQuantityList: roomQuantityList
            });
        });
    }

    handleExportExcel = async () => {
        this.setState({
            exportClick: true
        });
    };

    handleUploadExcel = async excelJson => {
        try {
            var list = [];
            if (excelJson === null) showError("Select an excel file");
            else {
                excelJson.forEach(element => {
                    const obj = {
                    };
                    list.push(obj);
                });
                if (Boolean(list) && list.length > 0) {
                    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
                    if (response.type === "ERROR") {
                        showError("No records to be added");
                    } else {
                        this.resetInputs();
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                        showSuccess();
                    }
                } else {
                    showError("No records to be added");
                }
            }
        } catch (error) {
            showError("");
        }
    };

    filterChangeItem = searchCriteriaModels => {
        this.setState(
            {
                searchCriteriaModels: searchCriteriaModels,
                pagination: {
                    ...this.state.pagination,
                    current: 1
                }
            },
            () => {
                const { pagination } = this.state;
                this.restartTable({ pagination });
            }
        );
    };

    usedBeforeModal = async row => {
        let modalUsedBeforeList = [];
        modalUsedBeforeList.push({ "usedRoomTypeName": row.usedRoomTypeName, "usedRoomUnit": row.usedRoomUnit });

        this.setState({
            modalUsedBeforeShow: true,
            modalUsedBeforeList: modalUsedBeforeList
        });
    };

    fillQuantityDimensions = async (shapeId) => {
        if (Boolean(shapeId) && shapeId > 0) {
            this.setState({
                modalDimensionShow: true,
            }, async () => {
                const { id } = this.state;
                let quantityDimensions = [];
                let roomApplicationMaterials = [];
                let roomQuantityId = Boolean(id) && id > 0 ? id : -1;
                var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/dimensions/" + shapeId + "/" + roomQuantityId);
                if (response.type === "ERROR") {
                    error(response);
                } else {
                    quantityDimensions = Boolean(response.data) ? response.data : []
                }
                this.formRefDimension.current.setFieldsValue({
                    quantityDimensions: quantityDimensions
                });
                this.setState({
                    roomApplicationMaterials: roomApplicationMaterials,
                });
            });
        } else {
            showWarning("Please select shape");
        }
    }

    fillMaterialListEdit(value) {
        let usedBeforeVisible = false;
        let roomApplicationMaterials = [];
        if (Boolean(value) && value > 0) {
            let application = this.state["applicationIdSelectItems"].find(p => p.roomApplicationId === value);
            if (Boolean(application)) {
                if (application.application === "Main Structure") {
                    usedBeforeVisible = true;
                }
                roomApplicationMaterials = application.roomApplicationMaterials;
            }
        }
        this.setState({
            roomApplicationId: value,
            usedBeforeVisible: usedBeforeVisible,
            roomApplicationMaterials: roomApplicationMaterials,
        });
    }

    render() {
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };
     
        return (
            <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
                <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
                    <Form.List name="roomQuantityList">
                        {(fields, { add, remove }) => {
                            const handleDimensionClick = fieldKey => {
                                const roomQuantityList = this.formRef.current.getFieldValue("roomQuantityList");
                                let row = { ...roomQuantityList[fieldKey] };
                                console.log("row", row);
                                let itemIds = [];
                                if (Boolean(row.itemQuantities) && row.itemQuantities.length > 0) {
                                    row.itemQuantities.forEach(element => {
                                        if (Boolean(element.itemId) && !itemIds.includes(element.itemId)) {
                                            itemIds.push(element.itemId);
                                        }
                                    });
                                }

                                let houseTypeId = this.props.houseTypeId;

                                this.setState({
                                    modalDimensionShow: true,
                                    fieldKey: fieldKey,

                                    id: row.id,
                                    discipline: row.discipline,
                                    usedBefore: row.usedBefore,
                                    structure: row.structure,
                                    subStructure: row.subStructure,
                                    shapeId: row.shapeId,
                                    subStructureType: row.subStructureType,
                                    itemIds: itemIds,
                                }, () => {
                                    let shapeId = -1;
                                    if (Boolean(row) && Boolean(row.shapeId)) {
                                        shapeId = row.shapeId;
                                    }
                                    this.fillQuantityDimensions(shapeId);
                                    this.fillRoomTypeId(houseTypeId);
                                    this.fillDiscipline();
                                    this.fillStructure();
                                    this.fillSubStructure();
                                    this.fillShape();
                                    this.fillSubStructureType();
                                    this.fillItems();
                                    this.fillStockCardIdIn();
                                });
                            };

                            const handleItemClick = fieldKey => {
                                const roomQuantityList = this.formRef.current.getFieldValue("roomQuantityList");
                                let row = { ...roomQuantityList[fieldKey] };
                                console.log("row", row);

                                let itemIds = [];
                                if (Boolean(row.itemQuantities) && row.itemQuantities.length > 0) {
                                    row.itemQuantities.forEach(element => {
                                        if (Boolean(element.itemId) && !itemIds.includes(element.itemId)) {
                                            itemIds.push(element.itemId);
                                        }
                                    });
                                }

                                let houseTypeId = this.props.houseTypeId;

                                this.setState({
                                    modalItemQuantityShow: true,
                                    fieldKey: fieldKey,

                                    id: row.id,
                                    discipline: row.discipline,
                                    usedBefore: row.usedBefore,
                                    structure: row.structure,
                                    subStructure: row.subStructure,
                                    shapeId: row.shapeId,
                                    subStructureType: row.subStructureType,
                                    itemIds: itemIds,
                                }, () => {
                                    this.formRefItem.current.setFieldsValue({
                                        itemQuantities: row.itemQuantities
                                    });

                                    this.fillRoomTypeId(houseTypeId);
                                    this.fillDiscipline();
                                    this.fillStructure();
                                    this.fillSubStructure();
                                    this.fillShape();
                                    this.fillSubStructureType();
                                    this.fillItems();
                                    this.fillStockCardIdIn();
                                });
                            };

                            return (
                                <TableBoot bordered size="sm">
                                    <thead>
                                        <tr>
                                            <th colSpan="1" style={{ textAlign: "center" }}>
                                                <FormattedMessage id="ProjectsTreeCompanyQuantity.Discipline" defaultMessage="Discipline" />
                                            </th>
                                            <th colSpan="1" style={{ textAlign: "center" }}>
                                                <FormattedMessage id="ProjectsTreeCompanyQuantity.Structure" defaultMessage="Structure" />
                                            </th>
                                            <th colSpan="1" style={{ textAlign: "center" }}>
                                                <FormattedMessage id="ProjectsTreeCompanyQuantity.Sub-Structure" defaultMessage="Sub-Structure" />
                                            </th>
                                            <th colSpan="1" style={{ textAlign: "center" }}>
                                                <FormattedMessage id="ProjectsTreeCompanyQuantity.Shape" defaultMessage="Shape" />
                                            </th>
                                            <th colSpan="1" style={{ textAlign: "center" }}>
                                                <FormattedMessage id="ProjectsTreeCompanyQuantity.Sub-StructureType" defaultMessage="Sub-Structure Type" />
                                            </th>
                                            <th colSpan="1" style={{ textAlign: "center" }}>
                                                <FormattedMessage id="ProjectsTreeCompanyQuantity.Method" defaultMessage="Method" />
                                            </th>
                                            <th colSpan="1" style={{ textAlign: "center" }}>
                                                <FormattedMessage id="ProjectsTreeCompanyQuantity.Dimension" defaultMessage="Dimension" />
                                            </th>
                                            <th colSpan="1" style={{ textAlign: "center" }}>
                                                <FormattedMessage id="ProjectsTreeCompanyQuantity.Item" defaultMessage="Item" />
                                            </th>
                                            <th colSpan="1" style={{ textAlign: "center" }}> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fields.map(field => {
                                            return (
                                                <tr key={field.key}>
                                                    <Form.Item hidden name={[field.name, "id"]}></Form.Item>
                                                    <td>
                                                        <Form.Item style={{ width: "100px" }} name={[field.name, "discipline"]}>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                optionFilterProp="children"
                                                                style={{ width: "100%" }}
                                                                onChange={value => {
                                                                    this.setState({
                                                                        discipline: value
                                                                    }, () => {
                                                                        this.fillStructure();
                                                                    });
                                                                }}
                                                            >
                                                                {Boolean(this.state["disciplineIdSelectItems"]) && this.state["disciplineIdSelectItems"].length > 0 &&
                                                                    this.state["disciplineIdSelectItems"].map(i => (
                                                                        <Option key={i.key} value={i.key}>
                                                                            {i.value}
                                                                        </Option>
                                                                    ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </td>

                                                    <td>
                                                        <Form.Item style={{ width: "100px" }} name={[field.name, "structure"]}>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                optionFilterProp="children"
                                                                style={{ width: "150px" }}
                                                                onChange={value => {
                                                                    this.setState({
                                                                        structure: value,
                                                                    }, () => {
                                                                        this.fillSubStructure();
                                                                    });
                                                                }}
                                                            >
                                                                {Boolean(this.state["structureSelectItems"]) && this.state["structureSelectItems"].length > 0 &&
                                                                    this.state["structureSelectItems"].map(i => (
                                                                        <Option key={i.key} value={i.key}>
                                                                            {i.value}
                                                                        </Option>
                                                                    ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </td>

                                                    <td>
                                                        <Form.Item style={{ width: "100px" }} name={[field.name, "subStructure"]}>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                optionFilterProp="children"
                                                                style={{ width: "150px" }}
                                                                onChange={value => {
                                                                    this.setState({
                                                                        subStructure: value,
                                                                    }, () => {
                                                                        this.fillShape();
                                                                    });
                                                                }}
                                                            >
                                                                {Boolean(this.state["subStructureSelectItems"]) && this.state["subStructureSelectItems"].length > 0 &&
                                                                    this.state["subStructureSelectItems"].map(i => (
                                                                        <Option key={i.key} value={i.key}>
                                                                            {i.value}
                                                                        </Option>
                                                                    ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </td>

                                                    <td>
                                                        <Form.Item style={{ width: "100px" }} name={[field.name, "shapeId"]}>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                optionFilterProp="children"
                                                                style={{ width: "150px" }}
                                                                onChange={value => {
                                                                    this.setState({
                                                                        shapeId: value,
                                                                    }, () => {
                                                                        this.fillSubStructureType();
                                                                    });
                                                                }}
                                                            >
                                                                {Boolean(this.state["shapeIdSelectItems"]) && this.state["shapeIdSelectItems"].length > 0 &&
                                                                    this.state["shapeIdSelectItems"].map(i => (
                                                                        <Option key={i.key} value={i.key}>
                                                                            {i.value}
                                                                        </Option>
                                                                    ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </td>

                                                    <td>
                                                        <Form.Item style={{ width: "100px" }} name={[field.name, "subStructureType"]}>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                optionFilterProp="children"
                                                                style={{ width: "150px" }}
                                                                onChange={value => {
                                                                    this.setState({
                                                                        subStructureType: value,
                                                                    }, () => {
                                                                        this.fillItems();
                                                                    });
                                                                }}
                                                            >
                                                                {Boolean(this.state["subStructureTypeSelectItems"]) && this.state["subStructureTypeSelectItems"].length > 0 &&
                                                                    this.state["subStructureTypeSelectItems"].map(i => (
                                                                        <Option key={i.key} value={i.key}>
                                                                            {i.value}
                                                                        </Option>
                                                                    ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </td>

                                                    <td>
                                                        <Form.Item style={{ width: "100px" }} name={[field.name, "methodId"]}>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                optionFilterProp="children"
                                                                style={{ width: "150px" }}
                                                                onChange={value => {
                                                                    this.setState({
                                                                        methodId: value,
                                                                    });
                                                                }}
                                                            >
                                                                {Boolean(this.state["methodIdSelectItems"]) && this.state["methodIdSelectItems"].length > 0 &&
                                                                    this.state["methodIdSelectItems"].map(i => (
                                                                        <Option key={i.key} value={i.key}>
                                                                            {i.value}
                                                                        </Option>
                                                                    ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </td>

                                                    <td>
                                                        <a
                                                            href="#"
                                                            className={`navi-link py-4`}
                                                            onClick={() => {
                                                                handleDimensionClick(field.name);
                                                            }}
                                                        >
                                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>
                                                        </a>
                                                    </td>

                                                    <td>
                                                        <a
                                                            href="#"
                                                            className={`navi-link py-4`}
                                                            onClick={() => {
                                                                handleItemClick(field.name);
                                                            }}
                                                        >
                                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                                    </td>
                                                </tr>
                                            );
                                        })}

                                        <tr>
                                            <td colSpan={11}>
                                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                    <FormattedMessage id={"ProjectsTreeCompanyRoomQuantity.AddNew"} defaultMessage={"Add New"} />
                                                </AntButton>
                                            </td>
                                        </tr>
                                    </tbody>
                                </TableBoot>
                            );
                        }}
                    </Form.List>

                    <br /><br />
                    {
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}></Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                <Button id="CancelButton" style={{ width: "100%" }}
                                    variant="secondary"
                                    onClick={this.cancel}
                                >
                                    <FormattedMessage
                                        id="GeneralButtonCancel"
                                        defaultMessage="Cancel" />
                                </Button>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                                </Button>
                            </Col>
                        </Row>
                    }

                </Form>
                {
                    Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 &&
                    (<div className="alert alert-custom alert-light">                 <div className="alert-icon">
                        <FilterOutlined />
                    </div>
                        <div className="alert-text">
                            {this.state.searchCriteriaModels.map((val, i) => {
                                return (
                                    <div key={i}
                                        className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                                        <span className="mr-2">
                                            {val.label}
                                        </span>
                                        <strong className="mr-2">
                                            {val.type}
                                        </strong>
                                        <span className="mr-2">
                                            {val.operationValue}
                                        </span>
                                        <span className="mr-2">
                                            {val.showValue}
                                        </span>
                                        <span>
                                            <Tooltip title="Remove">

                                                <CloseCircleOutlined style={{ color: "crimson" }}
                                                    onClick={() => this.removeSearchCriteria(i)} />
                                            </Tooltip>
                                        </span>
                                    </div>);
                            })}
                        </div>
                        <div className="alert-close">
                            <Tooltip title="Remove All">
                                <CloseCircleOutlined
                                    style={{ color: "crimson" }}
                                    onClick={() => this.setState({
                                        searchCriteriaModels: []
                                    }, () => {
                                        this.filterChangeItem([]);
                                    })} />
                            </Tooltip>
                        </div>
                    </div>)
                }

                <DraggableModal
                    title={
                        <span>
                            <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                            <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                                {<FormattedMessage id="ProjectsTreeCompanyRoomQuantity.Dimensions" defaultMessage="Dimensions" />}
                            </span>
                        </span>
                    }
                    centered
                    open={this.state.modalDimensionShow}
                    onCancel={() => this.setState({ modalDimensionShow: false })}
                    okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                    cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                    onOk={() => this.formRefDimension.current.submit()}
                    cancelButtonProps={{ hidden: true }}
                    width={1000}
                    content={
                        <Form initialValues={{ remember: false }} onFinish={this.saveDimensions} ref={this.formRefDimension}>
                            <Form.List name="quantityDimensions">
                                {(fields, { add, remove }) => {
                                    return (
                                        <TableBoot bordered size="sm">
                                            <thead>
                                                <tr>
                                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                                        <FormattedMessage id="ProjectsTreeCompanyRoomQuantity.Dimension" defaultMessage="Dimension" />
                                                    </th>
                                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                                        <FormattedMessage id="ProjectsTreeCompanyRoomQuantity.Value" defaultMessage="Value" />
                                                    </th>
                                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                                        <FormattedMessage id="ProjectsTreeCompanyRoomQuantity.Unit" defaultMessage="Unit" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {fields.map(field => {
                                                    return (
                                                        <tr key={field.key}>
                                                            <td>
                                                                <Form.Item
                                                                    noStyle name={[field.name, "dimensionName"]}>
                                                                    <Input readOnly />
                                                                </Form.Item>
                                                            </td>
                                                            <td>
                                                                <Form.Item
                                                                    noStyle name={[field.name, "quantityArea"]}>
                                                                    <InputNumber style={{ width: "100%" }}
                                                                        parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} className="w-100" placeholder="    " min={0} />
                                                                </Form.Item>
                                                            </td>
                                                            <td>
                                                                <Form.Item
                                                                    noStyle name={[field.name, "quantityUnitId"]}>
                                                                    <Cascader
                                                                        showSearch
                                                                        allowClear
                                                                        optionFilterProp="children"
                                                                        style={{ width: "100%" }} options={this.state.unitSelectItems} />
                                                                </Form.Item>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </TableBoot>
                                    );
                                }}
                            </Form.List>
                        </Form>
                    }
                />

                <DraggableModal
                    title={
                        <span>
                            <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                            <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                                {<FormattedMessage id="ProjectsTreeCompanyRoomQuantity.Items" defaultMessage="Items" />}
                            </span>
                        </span>
                    }
                    centered
                    open={this.state.modalItemQuantityShow}
                    onCancel={() => this.setState({ modalItemQuantityShow: false })}
                    okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                    cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                    onOk={() => this.formRefItem.current.submit()}
                    cancelButtonProps={{ hidden: true }}
                    width={1000}
                    content={
                        <Form initialValues={{ remember: false }} onFinish={this.saveItemQuantities} ref={this.formRefItem}>
                            <Form.List name="itemQuantities">
                                {(fields, { add, remove }) => {

                                    const addNewStockCard = () => {
                                        this.setState({
                                            modalStockCardShow: true,
                                        });
                                    };

                                    return (
                                        <div style={{ overflowX: 'auto' }}>
                                            <TableBoot bordered size="sm">
                                                <thead>
                                                    <tr>
                                                        <th colSpan="1" style={{ textAlign: "center" }}>
                                                            <FormattedMessage id="ProjectsTreeCompanyRoomQuantity.Item" defaultMessage="Item" />
                                                        </th>
                                                        <th colSpan="1" style={{ textAlign: "center" }}>
                                                            <FormattedMessage id="ProjectsTreeCompanyRoomQuantity.StockCard" defaultMessage="Stock Card" />
                                                        </th>
                                                        <th colSpan="2" style={{ textAlign: "center" }}>
                                                            <FormattedMessage id="ProjectsTreeCompanyRoomQuantity.Unit" defaultMessage="Quantity" />
                                                        </th>
                                                        <th colSpan="1" style={{ textAlign: "center" }}>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fields.map(field => {
                                                        return (
                                                            <tr key={field.key}>
                                                                <td>
                                                                    <Form.Item noStyle name={[field.name, "itemId"]}>
                                                                        <Select
                                                                            showSearch
                                                                            allowClear
                                                                            optionFilterProp="children"
                                                                            style={{ width: "300px" }}
                                                                            onChange={value => {
                                                                                this.setState({
                                                                                    itemId: value,
                                                                                }, () => {
                                                                                    this.fillStockCardId();
                                                                                });
                                                                            }}
                                                                        >
                                                                            {Boolean(this.state["roomApplicationItems"]) && this.state["roomApplicationItems"].length > 0 &&
                                                                                this.state["roomApplicationItems"].map(i => (
                                                                                    <Option key={i.id} value={i.id}>
                                                                                        {i.material}
                                                                                    </Option>
                                                                                ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item noStyle name={[field.name, "eldenStockCardId"]}>
                                                                        <Select
                                                                            showSearch
                                                                            allowClear
                                                                            optionFilterProp="children"
                                                                            style={{ width: "300px" }}
                                                                            onChange={value => {
                                                                                this.setState({
                                                                                    eldenStockCardId: value,
                                                                                });
                                                                            }}
                                                                        >
                                                                            {Boolean(this.state["eldenStockCardIdSelectItems"]) && this.state["eldenStockCardIdSelectItems"].length > 0 &&
                                                                                this.state["eldenStockCardIdSelectItems"].map(i => (
                                                                                    <Option key={i.key} value={i.key}>
                                                                                        {i.value}
                                                                                    </Option>
                                                                                ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item
                                                                        style={{ width: "200px" }}
                                                                        name={[field.name, "quantityArea"]}>
                                                                        <InputNumber style={{ width: "100%" }}
                                                                            parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} className="w-100" placeholder="    " min={0} />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item
                                                                        noStyle name={[field.name, "quantityUnitId"]}>
                                                                        <Cascader
                                                                            showSearch
                                                                            allowClear
                                                                            optionFilterProp="children"
                                                                            style={{ width: "200px" }} options={this.state.unitSelectItems} />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Space>
                                                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                                                        <AntButton type="dashed" onClick={() => addNewStockCard()} block icon={<PlusOutlined />}>
                                                                            <FormattedMessage id={"ProjectsTreeCompanyRoomQuantity.AddNewCard"} defaultMessage={"Add New Card"} />
                                                                        </AntButton>
                                                                    </Space>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    <tr>
                                                        <td colSpan={11}>
                                                            <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                <FormattedMessage id={"ProjectsTreeCompanyRoomQuantity.AddNew"} defaultMessage={"Add New"} />
                                                            </AntButton>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </TableBoot>
                                        </div>
                                    );
                                }}
                            </Form.List>
                        </Form>
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTreeCompanyRoomQuantity);