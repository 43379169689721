import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleRequest } from '../../ApiConnector';
import { FormattedMessage } from "react-intl";
import { Form, Row } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { error } from '../../MessageHelper';

export class Info extends Component {
    constructor() {
        super();
        this.state = {
            companyId: null,
            companyList: [],
            companyName: "",
            domain: "",
            licenseStartDate: null,
            licenseEndDate: null,
            companyType: null,
        };
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        var pathArray = this.props.location.pathname.split('/');
        if (pathArray.length === 3)
            this.fillCompany(pathArray[2]);
    };

    componentWillUpdate() {
        var pathArray = this.props.location.pathname.split('/');
        if (pathArray.length === 3)
            this.fillCompany(pathArray[2]);
    }





    fillCompany = async (id) => {

        let url = "/api/companies/" + id;

        const response = await handleRequest("GET", url);

        if (response.type === "ERROR") {
            error(response);
        }
        else {
            let row = response.data;
            if (Boolean(Row))
                this.setState({
                    companyName: row.companyName,
                    domain: row.domain,
                    licenseStartDate: moment(row.licenseStartDate).format("DD-MM-YYYY"),
                    licenseEndDate: moment(row.licenseEndDate).format("DD-MM-YYYY"),
                    companyType: row.companyType,
                });
        }
    }








    render() {

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout2 = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
        };


        return (

            <div hidden={this.state.hideInputs} className="card card-custom" style={{ padding: '2rem' }}>

                <Form
                    initialValues={{ remember: false }}
                    onFinish={this.save}
                    onFinishFailed={onFinishFailed}
                    ref={this.formRef}
                >



                    {
                        <Form.Item
                            {...layout2}
                            label={<FormattedMessage id="todo" defaultMessage="Company Name" />}
                            name="companyName"
                        >

                            {this.state.companyName}


                        </Form.Item>
                    }




                    {
                        <Form.Item
                            {...layout2}
                            label={<FormattedMessage id="todo" defaultMessage="Domain" />}
                            name="domain"
                        >
                            {this.state.domain}

                        </Form.Item>
                    }

                    {
                        <Form.Item
                            {...layout2}
                            label={<FormattedMessage id="todo" defaultMessage="License Start Date" />}
                            name="licenseStartDate"
                        >

                            {this.state.licenseStartDate}

                        </Form.Item>
                    }

                    {
                        <Form.Item
                            {...layout2}
                            label={<FormattedMessage id="todo" defaultMessage="License End Date" />}
                            name="licenseEndDate"
                        >
                            {this.state.licenseEndDate}

                        </Form.Item>
                    }

                    {
                        <Form.Item
                            {...layout2}
                            label={<FormattedMessage id="todo" defaultMessage="Company Type" />}
                            name="companyType">
                            {this.state.companyType}
                        </Form.Item>
                    }



                </Form>





            </div>


        );


    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Info)