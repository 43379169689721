import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import PageAutoDesignForm from "./PageAutoDesignForm";
import PageAutoDesignTable from "./PageAutoDesignTable";


export function PageAutoDesignNew({ pageAutoDesignId }) {
  console.log("pageAutoDesignId", pageAutoDesignId);
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  const [selectedTab, setSelectedTab] = React.useState("form");
  useEffect(() => {
    return () => { };
  }, [user]);

  return (
    <>
      {user && (
        <div className="d-flex flex-row">
          <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
            <div className="card card-custom card-stretch">
              {/* begin::Body */}
              <div className="card-body pt-4">
                {/* begin::Toolbar */}
                <div className="d-flex justify-content-end"></div>
                {/* end::Toolbar */}
                {/* begin::Nav */}
                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="PageAutoDesignNew.Form" defaultMessage="Form" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "form" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("form");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="PageAutoDesignNew.Form" defaultMessage="Form" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="PageAutoDesignNew.Table" defaultMessage="Table" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "table" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("table");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="PageAutoDesignNew.Table" defaultMessage="Table" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="PageAutoDesignNew.PartList" defaultMessage="Drawer" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "drawer" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("drawer");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Article.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="PageAutoDesignNew.PartList" defaultMessage="Drawer" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="PageAutoDesignNew.PartList" defaultMessage="Collapse" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "collapse" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("collapse");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Article.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="PageAutoDesignNew.PartList" defaultMessage="Collapse" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="PageAutoDesignNew.Tab Pane" defaultMessage="Tab Pane" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "tabPane" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("tabPane");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="PageAutoDesignNew.Tab Pane" defaultMessage="Tab Pane" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
             
                </div>
                {/* end::Nav */}
              </div>
              {/* end::Body */}
            </div>
          </div>
          <div className="flex-row-fluid ml-lg-8">
            {selectedTab === "form" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                  {
                      <PageAutoDesignForm parentId={pageAutoDesignId} parentName={""}
                        nextTab={() => setSelectedTab("tabPane")}
                      />
                    }

                  </div>
                </div>
              </div>
            )}

            {selectedTab === "table" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                  {
                      <PageAutoDesignTable parentId={pageAutoDesignId} parentName={""}
                        nextTab={() => setSelectedTab("tabPane")}
                      />
                    }

                  </div>
                </div>
              </div>
            )}

            {selectedTab === "drawer" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "collapse" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "tabPane" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    
                  </div>
                </div>
              </div>
            )}            
          </div>
        </div>
      )}
    </>
  );
}
