import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../modules/Auth/_redux/authRedux";
import EditIcon from "@material-ui/icons/EditTwoTone";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import PageviewIcon from "@material-ui/icons/Pageview";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { handleRequest } from "../../ApiConnector";
import { API_BASE_ROOT } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";

import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Form, message, Table, Input, Select, Switch, Col, Row, DatePicker, Button as AntButton, InputNumber, Divider } from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { Upload } from "antd";
import { UploadOutlined, PlusOutlined, MinusCircleOutlined, MinusOutlined } from "@ant-design/icons";
import { DownloadOutlined } from "@ant-design/icons";
import ShareIcon from "@material-ui/icons/Share";
import { TransmittalLog } from "./TransmittalLog";
import { PartyModeSharp } from "@material-ui/icons";
import CustomLogDetailMenu from "../CustomMenu/CustomLogDetailMenu";
import store from "../../../redux/store";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";

const { Option } = Select;

export class LogDetail extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      modalSubmitShow: false,
      hideUpdate: true,
      hideSave: false,
      hiddenInputs: true,
      hideExtraFields: true,
      filePath: "",
      filePaths: [],
      fileList: [],
      showRevisedListModal: false,
      revisedFileList: [],
      extraInfoList: [],
      inputTypeSelectItems: [],
      showCodeModal: false,
      codeName: null,
      codeType: null,
      user: store.getState().auth.user,

      id: null,
      logId: null,
      log: null,
      logDetailExtraInfoList: [],
      folderId: null,
      fileName: null,
      revNo: 0,
      code: null,
      folderSelectItems: [],
      revising: false,
      logName: null,
      documentName: null,
      description: null,
      remarks: null,
      redirect: null,
      selectedRow: null,

      showShareModal: false,
      shareFileId: null,
      shareEmail: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["10", "25", "50", "100"],
        keyValueList: []
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      columns: []
    };
  }

  formRef = React.createRef();

  componentDidMount = () => {
    this.initialize();
    this.fillComboBoxes();
  };

  componentDidUpdate = prevProps => {
    let logId = Boolean(this.props) && Boolean(this.props.logId) ? this.props.logId : null;
    let prevLogId = this.state.logId; //Boolean(prevProps) && Boolean(prevProps.logId) ? prevProps.logId : null;

    if (prevLogId !== logId) {
      console.log("LOGID", logId);
      console.log("Previous LOGID", prevLogId);
      this.initialize();
    }
  };

  initialize = async () => {
    this.setState(
      {
        logId: Boolean(this.props) && Boolean(this.props.logId) ? this.props.logId : null,
        folderId: Boolean(this.props) && Boolean(this.props.folderId) ? this.props.folderId : null
      },
      this.fillExtraInfos
    );

    this.formRef.current.setFieldsValue({
      logId: Boolean(this.props) && Boolean(this.props.logId) ? this.props.logId : null,
      folderId: Boolean(this.props) && Boolean(this.props.folderId) ? this.props.folderId : null
    });

    const { pagination } = this.state;
    this.restartTable({ pagination });
  };

  fillComboBoxes = async () => {
    let url = "/api/files/inputTypes";
    var responseSeverity = await handleRequest("GET", url);
    if (responseSeverity.type === "ERROR") {
      error(responseSeverity);
    } else {
      this.setState({
        inputTypeSelectItems: Boolean(responseSeverity.data) ? responseSeverity.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hiddenInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.setFieldsValue({
      filePath: "",
      //id: Boolean(this.props) && Boolean(this.props.logId) ? this.props.logId : null,
      logId: Boolean(this.props) && Boolean(this.props.logId) ? this.props.logId : null,
      folderId: Boolean(this.props) && Boolean(this.props.folderId) ? this.props.folderId : null,
      fileName: null,
      revNo: 0,
      code: null,
      logName: null,
      documentName: null,
      description: null,
      remarks: null
    });

    this.setState({
      filePath: "",
      fileList: [],
      modalPdfViewer: false,
      pdfFile: "",
      id: null,
      logId: Boolean(this.props) && Boolean(this.props.logId) ? this.props.logId : null,
      folderId: Boolean(this.props) && Boolean(this.props.folderId) ? this.props.folderId : null,
      fileName: null,
      revising: false,
      revNo: 0,
      code: null,
      logName: null,
      documentName: null,
      description: null,
      remarks: null,
      folderNameColor: "#bfbfbf",
      fileNameColor: "#bfbfbf",
      logNameColor: "#bfbfbf",
      documentNameColor: "#bfbfbf",
      descriptionColor: "#bfbfbf",
      remarksColor: "#bfbfbf",
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["10", "25", "50", "100"],
        keyValueList: []
      }
    });
  };

  edit = row => {
    let fileList = [];

    let pathList = Boolean(row.path) ? row.path.split(",") : [];
    let fileNames = Boolean(row.fileName) ? row.fileName.split(",") : [];

    if (Boolean(pathList) && pathList.length > 0) {
      if (fileNames.length !== pathList.length) {
        fileNames = [];
        pathList.forEach((element, index) => {
          fileNames.push(element.split("download/")[1]);

          let obj = {
            uid: index,
            name: element.split("download/")[1],
            url: element,
            thumbUrl: element
          };

          fileList.push(obj);
        });
      } else
        pathList.forEach((element, index) => {
          let obj = {
            uid: index,
            name: fileNames[index],
            url: element,
            thumbUrl: element
          };

          fileList.push(obj);
        });
    }

    let extraInfoObj = {};

    if (Boolean(row.logDetailExtraInfoList) && row.logDetailExtraInfoList.length > 0) {
      row.logDetailExtraInfoList.forEach(element => {
        if (Boolean(element.extraInfo)) {
          if (element.extraInfo.inputType === "String") extraInfoObj[element.extraInfoId + ""] = element.value;
          if (element.extraInfo.inputType === "Date") extraInfoObj[element.extraInfoId + ""] = moment(element.dateValue);
          if (element.extraInfo.inputType === "Dropdown") extraInfoObj[element.extraInfoId + ""] = element.dropdownValueId;
        } else {
          extraInfoObj[element.extraInfoId + ""] = element.value;
        }
      });
    }

    console.log(extraInfoObj);

    let log = this.state.log;
    if (Boolean(log) && Boolean(log.extraInfoList) && log.extraInfoList.length > 0) {
      if (!(Boolean(row.logDetailExtraInfoList) && row.logDetailExtraInfoList.length > 0)) row.logDetailExtraInfoList = [];

      log.extraInfoList.forEach(element => {
        let findedIndex = row.logDetailExtraInfoList.findIndex(p => p.extraInfoId === element.id);
        if (findedIndex >= 0) {
          row.logDetailExtraInfoList[findedIndex].label = element.label;
          row.logDetailExtraInfoList[findedIndex].inputType = element.inputType;
        } else {
          let obj = { ...element, extraInfoId: element.id, id: null };
          row.logDetailExtraInfoList.push(obj);
        }
      });
    }

    this.formRef.current.setFieldsValue({
      ...extraInfoObj,
      folderId: row.folderId,
      fileName: fileNames.toString(),
      revNo: row.revNo,
      code: row.code,
      path: row.path,
      logName: row.logName,
      documentName: row.documentName,
      description: row.description,
      remarks: row.remarks
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hiddenInputs: false,
      hideExtraFields: true,
      revNo: row.revNo,
      code: row.code,
      id: row.id,
      folderId: row.folderId,
      fileName: fileNames.toString(),
      filePaths: Boolean(row.path) ? row.path.split(",") : [],
      fileList: fileList,
      logName: row.logName,
      documentName: row.documentName,
      description: row.description,
      remarks: row.remarks,
      logDetailExtraInfoList: row.logDetailExtraInfoList
    });
  };

  revise = row => {
    let fileList = [];

    let pathList = Boolean(row.path) ? row.path.split(",") : [];
    let fileNames = Boolean(row.fileName) ? row.fileName.split(",") : [];

    if (Boolean(pathList) && pathList.length > 0) {
      if (fileNames.length !== pathList.length) {
        fileNames = [];
        pathList.forEach((element, index) => {
          fileNames.push(element.split("download/")[1]);
        });
      }
      pathList.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileList.push(obj);
      });
    }

    let extraInfoObj = {};

    if (Boolean(row.logDetailExtraInfoList) && row.logDetailExtraInfoList.length > 0) {
      row.logDetailExtraInfoList.forEach(element => {
        if (Boolean(element.extraInfo)) {
          if (element.extraInfo.inputType === "String") extraInfoObj[element.extraInfoId + ""] = element.value;
          if (element.extraInfo.inputType === "Date") extraInfoObj[element.extraInfoId + ""] = moment(element.dateValue);
          if (element.extraInfo.inputType === "Dropdown") extraInfoObj[element.extraInfoId + ""] = element.dropdownValueId;
        } else {
          extraInfoObj[element.extraInfoId + ""] = element.value;
        }
      });
    }

    console.log(extraInfoObj);

    let log = this.state.log;
    if (Boolean(log) && Boolean(log.extraInfoList) && log.extraInfoList.length > 0) {
      if (!(Boolean(row.logDetailExtraInfoList) && row.logDetailExtraInfoList.length > 0)) row.logDetailExtraInfoList = [];

      log.extraInfoList.forEach(element => {
        let findedIndex = row.logDetailExtraInfoList.findIndex(p => p.extraInfoId === element.id);
        if (findedIndex >= 0) {
          row.logDetailExtraInfoList[findedIndex].label = element.label;
          row.logDetailExtraInfoList[findedIndex].inputType = element.inputType;
        } else {
          let obj = { ...element, extraInfoId: element.id, id: null };
          row.logDetailExtraInfoList.push(obj);
        }
      });
    }

    this.formRef.current.setFieldsValue({
      ...extraInfoObj,
      folderId: row.folderId,
      fileName: fileNames.toString(),
      revNo: row.revNo + 1,
      code: row.code,
      logName: row.logName,
      documentName: row.documentName,
      description: row.description,
      remarks: row.remarks
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hiddenInputs: false,
      hideExtraFields: true,
      revNo: row.revNo + 1,
      code: row.code,
      revising: true,
      id: row.id,
      folderId: row.folderId,
      fileName: fileNames.toString(),
      filePaths: Boolean(row.path) ? row.path.split(",") : [],
      fileList: fileList,
      logName: row.logName,
      documentName: row.documentName,
      description: row.description,
      remarks: row.remarks,
      logDetailExtraInfoList: row.logDetailExtraInfoList
    });
  };

  showRevisedList = async row => {
    let url = "/api/revisedLogDetails/logDetails/" + row.id;

    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        revisedFileList: response.data,
        showRevisedListModal: true
      });
    } else {
      this.setState({
        revisedFileList: [],
        showRevisedListModal: false
      });

      showError("There is no revised files for selected item");
    }
  };

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  openShareModal = row => {
    this.setState({
      shareFileId: row.id,
      showShareModal: true
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  submitModal = row => {
    this.setState({
      modalSubmitShow: true,
      id: row.id,
      selectedRow: row
    });
  };

  submit = () => {
    const { id } = this.state;
    console.log("geldi");
    this.setState({
      redirect: id
    });

    this.props.pressSubmit();
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/logDetails/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  // share = async () => {

  //     const { shareFileId, shareEmail } = this.state;

  //     const shareObject = {
  //         fileIds: [],
  //         emails: []
  //     }

  //     shareObject.fileIds.push(shareFileId);
  //     shareObject.emails.push(shareEmail);

  //     var response = await handleRequest("POST", "/api/files/share", shareObject);

  //     if (response.type === "ERROR") {
  //         error(response);
  //     }
  //     else {

  //         showSuccess("File sended successfully");
  //         this.setState({
  //             showShareModal: false,
  //             shareFileId: null,
  //             shareEmail: null,
  //         });
  //     }

  // }

  onchangeFolderName = async event => {
    if (Boolean(event) && Boolean(event.target) && Boolean(event.target.value)) {
      await this.setState({
        folderNameColor: "#1890ff",
        folderName: event.target.value,
        pagination: {
          ...this.state.pagination,
          folderName: event.target.value
        }
      });
    } else {
      await this.setState({
        folderNameColor: "#bfbfbf",
        folderName: "",
        pagination: {
          ...this.state.pagination,
          folderName: ""
        }
      });
    }

    const { pagination } = this.state;
    this.restartTable({ pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
  };

  onchangeFileName = async event => {
    if (Boolean(event) && Boolean(event.target) && Boolean(event.target.value)) {
      await this.setState({
        fileNameColor: "#1890ff",
        fileName: event.target.value,
        pagination: {
          ...this.state.pagination,
          fileName: event.target.value
        }
      });
    } else {
      await this.setState({
        fileNameColor: "#bfbfbf",
        fileName: "",
        pagination: {
          ...this.state.pagination,
          fileName: ""
        }
      });
    }

    const { pagination } = this.state;
    this.restartTable({ pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);

    let url = "/api/logDetails/paging";
    const response = await handleRequest("POST", url, newObj);
    console.log(response.data.data);

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let data = response.data.data;
        if (Boolean(data) && data.length > 0) {
          data.forEach(element => {
            if (Boolean(element.logDetailExtraInfoList) && element.logDetailExtraInfoList.length > 0) {
              element.logDetailExtraInfoList.forEach(ei => {
                if (Boolean(ei.extraInfo)) {
                  if (ei.extraInfo.inputType === "String") element[ei.extraInfoId + ""] = ei.value;
                  if (ei.extraInfo.inputType === "Date") element[ei.extraInfoId + ""] = moment(ei.dateValue).format("DD-MM-YYYY");
                  if (ei.extraInfo.inputType === "Dropdown") element[ei.extraInfoId + ""] = Boolean(ei.dropdownValue) ? ei.dropdownValue.name : "";
                } else {
                  element[ei.extraInfoId + ""] = ei.value;
                }
              });
            }
          });
        }
        this.setState({
          loading: false,
          tableList: data,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  fillExtraInfos = async () => {
    const { logId } = this.state;
    let columns = [
      {
        title: "Actions",
        key: "name",
        render: record => {
          return this.actionTemplate(record);
        },
        width: 80,
        ellipsis: true
      },
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        },
        width: 50,
        ellipsis: true
      },

      {
        title: "Document Name",
        key: "documentName",
        render: record => {
          return record.documentName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              value={Boolean(this.state.pagination.documentName) ? this.state.pagination.documentName : ""}
              placeholder="Document Name"
              onChange={e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  documentName: e.target.value,
                  documentNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });
                this.restartTable({ pagination: _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            ></Input>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.documentNameColor) ? this.state.pagination.documentNameColor : "#bfbfbf" }} />
        ),
        sorter: true,
        width: 200,
        ellipsis: true
      },
      {
        title: "Document Code",
        key: "code",
        render: record => {
          return record.code;
        },

        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              value={Boolean(this.state.pagination.code) ? this.state.pagination.code : ""}
              placeholder="Document Code"
              onChange={e => {
                let pagination = {
                  ...this.state.pagination,
                  code: e.target.value,
                  codeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: pagination
                });
                this.restartTable({ pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            ></Input>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.codeColor) ? this.state.pagination.codeColor : "#bfbfbf" }} />
        ),
        sorter: true,
        width: 200,
        ellipsis: true
      },
      {
        title: "Rev No",
        key: "revNo",
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline"
                }}
                onClick={e => this.showRevisedList(record)}
              >
                {record.revNo}
              </div>
            </React.Fragment>
          );
        },

        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              value={Boolean(this.state.pagination.revNo) ? this.state.pagination.revNo : ""}
              placeholder="Rev. No"
              onChange={e => {
                let pagination = {
                  ...this.state.pagination,
                  revNo: e.target.value,
                  revNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: pagination
                });
                this.restartTable({ pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            ></Input>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.revNoColor) ? this.state.pagination.revNoColor : "#bfbfbf" }} />
        ),
        sorter: true,
        width: 120,
        ellipsis: true
      },
      {
        title: "Description",
        key: "description",
        render: record => {
          return record.description;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              value={Boolean(this.state.pagination.description) ? this.state.pagination.description : ""}
              placeholder="Description"
              onChange={e => {
                let pagination = {
                  ...this.state.pagination,
                  description: e.target.value,
                  descriptionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: pagination
                });
                this.restartTable({ pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            ></Input>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.descriptionColor) ? this.state.pagination.descriptionColor : "#bfbfbf" }} />
        ),
        sorter: true,
        width: 200,
        ellipsis: true
      },
      {
        title: "Remarks",
        key: "remarks",
        render: record => {
          return record.remarks;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              value={Boolean(this.state.pagination.remarks) ? this.state.pagination.remarks : ""}
              placeholder="Remarks"
              onChange={e => {
                let pagination = {
                  ...this.state.pagination,
                  remarks: e.target.value,
                  remarksColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: pagination
                });
                this.restartTable({ pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            ></Input>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.remarksColor) ? this.state.pagination.remarksColor : "#bfbfbf" }} />
        ),
        sorter: true,
        width: 150,
        ellipsis: true
      },

      {
        title: "File",
        key: "path",
        render: record => {
          if (Boolean(record.path))
            return (
              <AntButton
                style={{ marginLeft: "2px", marginTop: "-2px", alignContent: "center" }}
                onClick={() => this.downloadMultipleFile(record.path)}
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
              ></AntButton>
            );
        },
        width: 120,
        ellipsis: true
      }
    ];
    let url = "/api/files/" + logId;
    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else {
      let log = Boolean(response.data) ? response.data : null;
      console.log(log);
      let logDetailExtraInfoList = [];
      if (Boolean(log) && Boolean(log.extraInfoList) && log.extraInfoList.length > 0) {
        log.extraInfoList.forEach(element => {
          let obj = { ...element, extraInfoId: element.id, id: null };
          logDetailExtraInfoList.push(obj);
          this.fillCodeWithType("ExtraInfo" + element.id);
          let col =
            Boolean(element.inputType) && element.inputType === "Date"
              ? {
                  title: element.label,
                  key: element.id + "",
                  dataIndex: element.id + "",
                  filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                      <DatePicker
                        format="YYYY-MM-DD"
                        value={
                          Boolean(this.state.pagination.keyValueList.find(p => p.key === element.id)) &&
                          Boolean(this.state.pagination.keyValueList.find(p => p.key === element.id).value)
                            ? moment(this.state.pagination.keyValueList.find(p => p.key === element.id).value, "YYYY-MM-DD")
                            : null
                        }
                        onChange={value => {
                          const { pagination } = this.state;
                          let keyValueObj = pagination.keyValueList.find(p => p.key === element.id);
                          let dateValue = null;
                          if (Boolean(value)) {
                            dateValue = moment(value).format("YYYY-MM-DD");
                          }
                          if (Boolean(keyValueObj)) {
                            keyValueObj.value = dateValue;
                          } else {
                            keyValueObj = {
                              key: element.id,
                              value: dateValue,
                              type: element.inputType
                            };
                            pagination.keyValueList.push(keyValueObj);
                          }
                          let _pagination = {
                            ...pagination,
                            [element.id + "color"]: Boolean(value) ? "#1890ff" : "#bfbfbf"
                          };

                          this.setState({
                            pagination: _pagination
                          });
                          this.restartTable({ pagination: _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                        }}
                      />
                    </div>
                  ),
                  filterIcon: filtered => (
                    <FilterOutlined
                      style={{
                        color: Boolean(this.state.pagination[element.id + "color"]) ? this.state.pagination[element.id + "color"] : "#bfbfbf"
                      }}
                    />
                  ),
                  width: 150,
                  ellipsis: true
                }
              : {
                  title: element.label,
                  key: element.id + "",
                  dataIndex: element.id + "",
                  filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                      <Input
                        style={{ flex: "auto" }}
                        value={
                          Boolean(this.state.pagination.keyValueList.find(p => p.key === element.id))
                            ? this.state.pagination.keyValueList.find(p => p.key === element.id).value
                            : ""
                        }
                        onChange={e => {
                          const { pagination } = this.state;
                          let keyValueObj = pagination.keyValueList.find(p => p.key === element.id);
                          if (Boolean(keyValueObj)) {
                            keyValueObj.value = e.target.value;
                          } else {
                            keyValueObj = {
                              key: element.id,
                              value: e.target.value,
                              type: element.inputType
                            };
                            pagination.keyValueList.push(keyValueObj);
                          }
                          let _pagination = {
                            ...pagination,
                            [element.id + "color"]: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                          };

                          this.setState({
                            pagination: _pagination
                          });
                          this.restartTable({ pagination: _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                        }}
                      />
                    </div>
                  ),
                  filterIcon: filtered => (
                    <FilterOutlined
                      style={{
                        color: Boolean(this.state.pagination[element.id + "color"]) ? this.state.pagination[element.id + "color"] : "#bfbfbf"
                      }}
                    />
                  ),
                  width: 150,
                  ellipsis: true
                };

          columns.push(col);
        });
      }

      columns.push({
        title: "Submit",
        key: "key",
        render: record => {
          if (record.showSubmit)
            return (
              <Button variant="primary" onClick={() => this.submitModal(record)}>
                {" "}
                Submit{" "}
              </Button>
            );
        },
        width: 120,
        ellipsis: true
      });

      // columns.push({
      //     title: 'Update', key: 'key', render: (record) => {
      //         return <EditIcon fontSize="default" color="primary" backgroundColor="black" onClick={() => this.edit(record)} />
      //     },
      //     width: 120 ,
      //     ellipsis: true,
      // });

      // columns.push({
      //     title: 'Delete', key: 'key', render: (record) => {
      //         return <DeleteIcon fontSize="default" color="error" onClick={() => this.deleteModal(record)} />
      //     },
      //     width: 120 ,
      //     ellipsis: true,
      // })

      columns.push({
        title: "Response Date",
        render: record => {
          if (Boolean(record.responseDate)) {
            return moment(record.responseDate).format("DD-MM-YYYY");
          }
        },
        width: 150,
        ellipsis: true
      });

      columns.push({
        title: "Response",
        render: record => {
          return record.response;
        },
        width: 150,
        ellipsis: true
      });

      columns.push({
        title: "Status",
        render: record => {
          return record.status;
        },
        width: 150,
        ellipsis: true
      });

      columns.push({
        title: "File",
        key: "file",
        render: record => {
          if (Boolean(record.filePath))
            return (
              <AntButton
                style={{ marginLeft: "2px", marginTop: "-2px", alignContent: "center" }}
                onClick={() => this.downloadMultipleFile(record.filePath)}
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
              ></AntButton>
            );
        },
        width: 120,
        ellipsis: true
      });

      console.log(columns);

      this.setState({
        log: log,
        extraInfoList: Boolean(log) && Boolean(log.extraInfoList) ? log.extraInfoList : [],
        logDetailExtraInfoList: logDetailExtraInfoList,
        columns: columns
      });
    }
  };

  onClickCreateNew = async () => {
    this.resetInputs();
    if (this.state.hiddenInputs) {
      this.setState({
        hiddenInputs: false,
        hiddenSave: false,
        hiddenUpdate: true,
        hideExtraFields: true
      });
    } else {
      this.setState({
        hiddenInputs: true,
        hiddenSave: true,
        hiddenUpdate: false,
        hideExtraFields: true
      });
    }
  };

  saveExtraFields = async () => {
    const extraInfoList = this.state.extraInfoList;
    const log = this.state.log;

    if (Boolean(extraInfoList) && extraInfoList.length > 0)
      extraInfoList.forEach(element => {
        if (element.id <= 0) element.id = null;
      });

    log.extraInfoList = extraInfoList;

    var response = await handleRequest("PUT", "/api/files/" + log.id, log);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.initialize();
      showSuccess();
    }
  };

  save = async () => {
    var fileName = [];

    if (Boolean(this.state.fileList) && this.state.fileList.length > 0) {
      //? Boolean(this.state.fileList[0].response) ? this.state.fileList[0].response.name : this.state.fileList[0].name : "";
      this.state.fileList.forEach(element => {
        fileName.push(element.name);
      });
    }
    const logDetailExtraInfoList = this.state.logDetailExtraInfoList;
    const { filePaths } = this.state;

    const newItem = {
      id: this.state.id,
      logId: this.state.logId,
      fileName: fileName.toString(),
      path: Boolean(filePaths) ? filePaths.toString() : "",
      code: this.state.code,
      revNo: this.state.revNo,
      documentName: this.state.documentName,
      description: this.state.description,
      remarks: this.state.remarks,
      logDetailExtraInfoList: logDetailExtraInfoList
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/logDetails", newItem);
    } else {
      if (this.state.revising === true) response = await handleRequest("PUT", "/api/logDetails/revise/" + newItem.id, newItem);
      else response = await handleRequest("PUT", "/api/logDetails/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.resetInputs();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.cancel();
    }
  };

  createWhere(params) {
    var current = this.state.pagination.current;
    var pageSize = this.state.pagination.pageSize;
    var sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      current = params.pagination.current;
      pageSize = params.pagination.pageSize;

      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      id: null,
      ...params.pagination,
      logId: Boolean(this.props) && Boolean(this.props.logId) ? this.props.logId : null,
      folderId: Boolean(this.props) && Boolean(this.props.folderId) ? this.props.folderId : null,

      firstResult: current - 1, //(current - 1) * pageSize,
      maxResults: pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    const { pagination } = this.state;
    const newObj = this.createWhere({ pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });

    let url = "/api/files/export";
    var response = await handleRequest("POST", url, newObj);

    if (Boolean(response.data) && Boolean(response.data.url)) {
      window.location.href = response.data.url;
    } else {
      message.error("File cant found.");
    }
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            folderName: element["Folder Name"]
          };
          list.push(obj);
        });

        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/files/import", list);

          if (response.type === "ERROR") {
            error(response);
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.cancel();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      error();
    }
  };

  generateReport = async () => {
    let logId = this.state.logId;

    let url = "/api/logDetails/report/" + logId;
    console.log("report url: " + url);

    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });

      showError("There is no created report for selected item");
    }
  };

  downloadFile = async url => {
    window.location.href = url;
  };

  downloadMultipleFile = async url => {
    let pathList = url.split(",");

    if (Boolean(pathList) && pathList.length > 0) {
      var response = await handleRequest("POST", "/api/transmittalLogs/download", pathList);

      if (response.type === "ERROR") {
        error(response);
      } else {
        try {
          const link = document.createElement("a");
          link.href = response.data;
          link.download = "Fİles.zip";
          link.click();
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  addExtraInfo = () => {
    var { extraInfoList } = this.state;
    let obj = { id: 0, label: "", value: "", inputType: "String" };

    if (Boolean(extraInfoList) && extraInfoList.length > 0) {
      obj.id = extraInfoList.length * -1;
      let index = extraInfoList.findIndex(p => p.id === obj.id);
      while (index >= 0) {
        obj.id = obj.id - 1;
        index = extraInfoList.findIndex(p => p.id === obj.id);
      }
    } else extraInfoList = [];

    extraInfoList.push(obj);

    console.log(extraInfoList);

    this.setState({
      extraInfoList: extraInfoList
    });
  };

  removeExtraInfo = id => {
    var { extraInfoList } = this.state;

    if (Boolean(extraInfoList) && extraInfoList.length > 0) {
      let index = extraInfoList.findIndex(p => p.id === id);
      if (index >= 0) {
        extraInfoList.splice(index, 1);
      }
    } else extraInfoList = [];

    this.setState({
      extraInfoList: extraInfoList
    });
  };

  openExtraInfoCodeModal = extraInfoId => {
    this.setState({
      showCodeModal: true,
      codeType: "ExtraInfo" + extraInfoId
    });
  };

  addCode = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: this.state.codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode();
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async () => {
    const { codeType } = this.state;

    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillCodeWithType = async type => {
    const response = await handleRequest("GET", "/api/codes/type/" + type);
    if (Boolean(response.data)) {
      this.setState({
        [type + "SelectItems"]: response.data
      });
    }
  };

  actionTemplate(row) {
    return (
      <React.Fragment>
        <CustomLogDetailMenu row={row} onDeleteRow={this.deleteModal.bind(this)} onRevise={this.revise.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };
    const columns = this.state.columns;
    const revisedColumns = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        },
        width: 50,
        ellipsis: true
      },

      {
        title: "Document Name",
        key: "documentName",
        render: record => {
          return record.documentName;
        }
      },
      {
        title: "Document Code",
        key: "code",
        render: record => {
          return record.code;
        }
      },
      {
        title: "Rev No",
        key: "revNo",
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline"
                }}
                onClick={e => this.showRevisedList(record)}
              >
                {record.revNo}
              </div>
            </React.Fragment>
          );
        }
      },
      {
        title: "Description",
        key: "description",
        render: record => {
          return record.description;
        }
      },
      {
        title: "Remarks",
        key: "remarks",
        render: record => {
          return record.remarks;
        }
      },

      {
        title: "File",
        key: "path",
        render: record => {
          if (Boolean(record.path))
            return (
              <AntButton
                style={{ marginLeft: "2px", marginTop: "-2px", alignContent: "center" }}
                onClick={() => this.downloadMultipleFile(record.path)}
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
              ></AntButton>
            );
        }
      }
    ];

    console.log("redirect", this.state.redirect);
    if (this.state.redirect) {
      return <TransmittalLog logDetailId={this.state.redirect} documentCode={this.state.documentCode} revNo={this.state.revNo}></TransmittalLog>;
    } else
      return (
        <div className="card card-custom" style={{ padding: "2rem" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row>
              <Col xl={3} xs={3}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <>
                    {" "}
                    <AntButton
                      hidden={!this.state.hideExtraFields}
                      type="dashed"
                      style={{ marginBottom: "10px" }}
                      onClick={() => {
                        this.setState({
                          hideExtraFields: false,
                          hiddenInputs: true
                        });
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Fields
                    </AntButton>
                    <AntButton
                      hidden={this.state.hideExtraFields}
                      type="dashed"
                      style={{ marginBottom: "10px" }}
                      onClick={() => {
                        this.setState({
                          hideExtraFields: true,
                          hiddenInputs: true
                        });
                      }}
                      block
                      icon={<MinusOutlined />}
                    >
                      Fields
                    </AntButton>
                  </>
                )}
              </Col>
              <Col xs={12}></Col>
              <Col xl={2} xs={2}>
                {/* <ExportIcon fontSize="large" color="action" onClick={() => this.handleExportExcel()} /> */}
              </Col>
              <Col xl={2} xs={2}>
                {/* <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader> */}
              </Col>
              <Col xl={3} xs={3}>
                <Button id="generateReportForDocumentLog" type="button" onClick={this.generateReport} variant="success">
                  <FormattedMessage id="generateReportForDocumentLog" defaultMessage="Report" />
                </Button>
              </Col>
              <Col xl={2} xs={2}>
                <CreateIcon hidden={!this.state.hiddenInputs} fontSize="large" color="secondary" onClick={() => this.onClickCreateNew()} />
                <RemoveCircle hidden={this.state.hiddenInputs} fontSize="large" color="error" onClick={() => this.onClickCreateNew()} />
              </Col>
            </Row>

            <div hidden={this.state.hideExtraFields}>
              {this.state.extraInfoList.map(i => (
                <Form.Item>
                  <Row gutter={8}>
                    <Col span={8}></Col>
                    {/* <Col span={4}>
                                        <Input placeholder="Label" style={{ width: '100%' }} id="todo" value={i.label} onChange={(e) => {
                                            const { extraInfoList } = this.state;
                                            console.log(extraInfoList);
                                            let obj = extraInfoList.find(p => p.id === i.id);
                                            obj.label = e.target.value;
                                            this.setState({ extraInfoList: extraInfoList })
                                        }} />
                                    </Col> */}
                    <Col span={8}>
                      <Input
                        placeholder="Field"
                        style={{ width: "100%" }}
                        id="todo"
                        value={i.label}
                        onChange={e => {
                          const { extraInfoList } = this.state;
                          let obj = extraInfoList.find(p => p.id === i.id);
                          obj.label = e.target.value;
                          this.setState({ extraInfoList: extraInfoList });
                        }}
                      />
                    </Col>

                    <Col span={4}>
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Input Type"
                        value={i.inputType}
                        onChange={value => {
                          const { extraInfoList } = this.state;
                          let obj = extraInfoList.find(p => p.id === i.id);
                          obj.inputType = value;
                          this.setState({ extraInfoList: extraInfoList });
                        }}
                      >
                        <Option key={null} value={null}>
                          Select
                        </Option>
                        {this.state.inputTypeSelectItems.map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                      </Select>
                    </Col>

                    <Col span={1}>
                      <MinusCircleOutlined onClick={() => this.removeExtraInfo(i.id)} />
                    </Col>
                  </Row>
                </Form.Item>
              ))}

              {
                <Row gutter={8}>
                  <Col span={8}></Col>

                  <Col span={8}>
                    {
                      <AntButton type="dashed" style={{ marginBottom: "10px" }} onClick={() => this.addExtraInfo()} block icon={<PlusOutlined />}>
                        Add New Field
                      </AntButton>
                    }
                  </Col>
                </Row>
              }

              <div>
                {
                  <Row gutter={8}>
                    <Col span={8}></Col>

                    <Col span={8}>
                      {
                        <Button
                          id="OrganizationalLevelSaveButton"
                          type="button"
                          onClick={this.saveExtraFields}
                          style={{ width: "100%" }}
                          variant="success"
                        >
                          <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                        </Button>
                      }
                    </Col>
                  </Row>
                }
              </div>
            </div>

            <div hidden={this.state.hiddenInputs}>
              {
                <Form.Item
                  {...layout2}
                  label={<FormattedMessage id="todo" defaultMessage="Document Name" />}
                  name="documentName"
                  rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type document name" /> }]}
                >
                  <Input
                    placeholder="Document Name"
                    style={{ width: "100%" }}
                    id="todo"
                    value={this.state.documentName}
                    onChange={e => {
                      this.setState({ documentName: e.target.value });
                    }}
                  />
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout2}
                  label={<FormattedMessage id="todo" defaultMessage="Document Code" />}
                  name="code"
                  rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type document code" /> }]}
                >
                  <Input
                    placeholder="Document Code"
                    style={{ width: "100%" }}
                    id="todo"
                    value={this.state.code}
                    onChange={e => {
                      this.setState({ code: e.target.value });
                    }}
                  />
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout2}
                  label={<FormattedMessage id="todo" defaultMessage="Rev. No." />}
                  name="revNo"
                  rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type folder name" /> }]}
                >
                  <InputNumber
                    placeholder="Rev. No."
                    style={{ width: "100%" }}
                    id="todo"
                    min={0}
                    value={this.state.revNo}
                    onChange={value => {
                      this.setState({ revNo: value });
                    }}
                  />
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout2}
                  label={<FormattedMessage id="todo" defaultMessage="Description" />}
                  name="description"
                  rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type description" /> }]}
                >
                  <Input
                    placeholder="Description"
                    style={{ width: "100%" }}
                    id="todo"
                    value={this.state.description}
                    onChange={e => {
                      this.setState({ description: e.target.value });
                    }}
                  />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout2}
                  label={<FormattedMessage id="todo" defaultMessage="Remarks" />}
                  name="remarks"
                  rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type remarks" /> }]}
                >
                  <Input
                    placeholder="Description"
                    style={{ width: "100%" }}
                    id="todo"
                    value={this.state.remarks}
                    onChange={e => {
                      this.setState({ remarks: e.target.value });
                    }}
                  />
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout2}
                  label={<FormattedMessage id="todo" defaultMessage="upload" />}
                  name="path"
                  rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please upload file" /> }]}
                >
                  <Upload
                    {...props}
                    id="path"
                    showUploadList={true}
                    multiple
                    fileList={this.state.fileList}
                    onChange={({ fileList }) => {
                      var filePaths = [];
                      const stateFileList = this.state.fileList;
                      if (fileList.length >= 1) {
                        for (let index = 0; index < fileList.length; index++) {
                          if (Boolean(fileList[index].response)) {
                            filePaths[index] = fileList[index].response.url;
                            fileList[index].url = fileList[index].response.url;
                            fileList[index].name = fileList[index].response.name;

                            if (filePaths.length === fileList.length && fileList.length > stateFileList.length)
                              showSuccess("Files uploaded successfully");
                          } else if (Boolean(fileList[index].url)) {
                            filePaths[index] = fileList[index].url;
                          }
                        }
                      }
                      this.setState({
                        fileList: fileList,
                        filePaths: filePaths
                      });
                    }}
                    beforeUpload={fileList => {
                      return true;
                    }}
                    onRemove={({ fileList }) => {}}
                  >
                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> Upload
                    </AntButton>
                  </Upload>
                </Form.Item>
              }

              {Boolean(this.state.logDetailExtraInfoList) &&
                this.state.logDetailExtraInfoList.map((i, index) => (
                  <>
                    {Boolean(i.inputType) && i.inputType === "String" && (
                      <Form.Item
                        {...layout2}
                        label={i.label}
                        name={i.extraInfoId}
                        rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type input" /> }]}
                      >
                        <Input
                          placeholder="Value"
                          style={{ width: "100%" }}
                          id="todo"
                          value={i.value}
                          onChange={e => {
                            const { logDetailExtraInfoList } = this.state;
                            let obj = logDetailExtraInfoList[index];
                            obj.value = e.target.value;
                            this.setState({ logDetailExtraInfoList: logDetailExtraInfoList });
                          }}
                        />
                      </Form.Item>
                    )}

                    {Boolean(i.inputType) && i.inputType === "Date" && (
                      <Form.Item
                        {...layout2}
                        label={i.label}
                        name={i.extraInfoId}
                        rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type input" /> }]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={(value, dateString) => {
                            const { logDetailExtraInfoList } = this.state;
                            let obj = logDetailExtraInfoList[index];
                            obj.value = dateString;
                            obj.dateValue = value.toDate();
                            this.setState({ logDetailExtraInfoList: logDetailExtraInfoList });
                          }}
                        />
                      </Form.Item>
                    )}

                    {Boolean(i.inputType) && i.inputType === "Dropdown" && (
                      <Form.Item
                        {...layout2}
                        label={i.label}
                        name={i.extraInfoId}
                        rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type input" /> }]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          style={{ width: "100%" }}
                          placeholder="Response"
                          onChange={value => {
                            const { logDetailExtraInfoList } = this.state;
                            let obj = logDetailExtraInfoList[index];
                            obj.value = "";
                            obj.dropdownValueId = value;
                            this.setState({ logDetailExtraInfoList: logDetailExtraInfoList });
                          }}
                          dropdownRender={menu => (
                            <div>
                              {menu}
                              <Divider style={{ margin: "4px 0" }} />
                              <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                <a
                                  style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                  onClick={() => this.openExtraInfoCodeModal(i.extraInfoId)}
                                >
                                  <PlusOutlined /> Add New
                                </a>
                              </div>
                            </div>
                          )}
                        >
                          <Option key={null} value={null}>
                            Select
                          </Option>
                          {Boolean(this.state["ExtraInfo" + i.extraInfoId + "SelectItems"]) &&
                            this.state["ExtraInfo" + i.extraInfoId + "SelectItems"].map(i => (
                              <Option key={i.id} value={i.id}>
                                {i.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    )}
                  </>
                ))}

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={8}>
                    <Col span={8}></Col>

                    <Col span={8}>
                      {
                        <Button id="OrganizationalLevelSaveButton" type="submit" style={{ width: "100%" }} variant="success">
                          <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                        </Button>
                      }
                    </Col>
                  </Row>
                }
              </div>

              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={8}>
                    <Col span={8}></Col>

                    <Col span={4}>
                      <Button id="OrganizationalLevelCancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col span={4}>
                      <Button id="OrganizationalLevelUpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            {
              <Table
                size="small"
                bordered
                scroll={{ y: 500, x: 900, scrollToFirstRowOnChange: true }}
                columns={columns}
                dataSource={this.state.tableList}
                loading={this.state.loading}
                onChange={this.handleTableChange}
                pagination={this.state.pagination}
              />
            }
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.modalSubmitShow}
            onHide={() => {
              this.setState({
                modalSubmitShow: false
              });
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be submitted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Boolean(this.state.selectedRow) && (
                <p>
                  Do you want to submit {this.state.selectedRow.documentName} - {this.state.selectedRow.code} - {this.state.selectedRow.revNo}
                </p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => this.submit()}>
                Yes
              </Button>
              <Button
                variant="warning"
                onClick={() => {
                  this.setState({
                    modalSubmitShow: false,
                    selectedRow: null
                  });
                }}
              >
                {" "}
                No
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.showCodeModal}
            onHide={() => {
              this.setState({
                showCodeModal: false
              });
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter"> Add item to dropdown </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Input
                placeholder="Type Value"
                id="modalCodeName"
                value={this.state.codeName}
                onChange={e => this.setState({ codeName: e.target.value })}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showCodeModal: false,
                    codeName: null
                  });
                }}
              >
                Close
              </Button>

              <Button variant="primary" onClick={this.addCode}>
                {" "}
                Save{" "}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.showRevisedListModal}
            onHide={() => {
              this.setState({
                showRevisedListModal: false
              });
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Revised Logs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered scroll={{ x: 400, scrollToFirstRowOnChange: true }} columns={revisedColumns} dataSource={this.state.revisedFileList} />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showRevisedListModal: false
                  });
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.showShareModal}
            onHide={() => {
              this.setState({
                showShareModal: false
              });
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter"> Share File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Input placeholder="Email" id="todo" value={this.state.shareEmail} onChange={e => this.setState({ shareEmail: e.target.value })} />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showShareModal: false
                  });
                }}
              >
                Close
              </Button>

              <Button variant="info" onClick={this.share}>
                {" "}
                Share{" "}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            size="xl"
            show={this.state.modalPdfViewer}
            onHide={this.showOrHideModalPdfViewer}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                <div style={{ height: "900px" }}>
                  <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                </div>
              </Worker>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideModalPdfViewer}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
  }
}

const mapStateToProps = state => {
  console.log("YYY", state);

  return {
    // auth: state.auth,
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LogDetail);
// export default connect(null, auth.actions)(Domain);
