import React, { Component } from 'react';
import Gantt from './components/Gantt';
import './App.css';
import Toolbar from './components/Toolbar';
import { handleRequest } from "../../ApiConnector";
import { error } from "../../MessageHelper";

class AppGant extends Component {
    state = {
        currentZoom: 'Days',
        messages: [],
        controllerName: "apartment",
        data: null,
    };

    componentDidMount = async () => {
        // this.getGanttChart();
    };

    addMessage(message) {
        const maxLogLength = 5;
        const newMessage = { message };
        const messages = [
            newMessage,
            ...this.state.messages
        ];

        if (messages.length > maxLogLength) {
            messages.length = maxLogLength;
        }
        this.setState({ messages });
    }

    logDataUpdate = (entityType, action, itemData, id) => {
        let text = itemData && itemData.text ? ` (${itemData.text})` : '';
        let message = `${entityType} ${action}: ${id} ${text}`;
        if (entityType === 'link' && action !== 'delete') {
            message += ` ( source: ${itemData.source}, target: ${itemData.target} )`;
        }
        this.addMessage(message);
    }

    handleZoomChange = (zoom) => {
        this.setState({
            currentZoom: zoom
        });
    }

    getGanttChart = async () => {
        var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getGanttChart");
        if (response.type === "ERROR") {
            error(response);
        }
        console.log("data", this.state.data);
        this.setState({
            data: Boolean(response.data) ? response.data : null,
        }, () => {
            console.log("data", this.state.data);
        });
    };

    render() {
        const { currentZoom, messages, data } = this.state;
        return (
            <div>
                {/* {Boolean(data) &&
                    <> */}
                <Toolbar
                    zoom={currentZoom}
                    onZoomChange={this.handleZoomChange}
                />
                <div className="gantt-container">
                    <Gantt
                        tasks={data}
                        zoom={currentZoom}
                        onDataUpdated={this.logDataUpdate}
                    />
                </div>
                {/* <MessageArea
                    messages={ messages }
                />  */}
                {/* </>
                } */}
            </div>
        )
    }
}
export default AppGant;