import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, DatePicker, Space, Spin, Drawer } from "antd";
import { InputNumber } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import {
  ExclamationCircleOutlined,
  ArrowRightOutlined,
  FileDoneOutlined,
  ExportOutlined,
  StopOutlined,
  DatabaseOutlined,
  CalculatorOutlined,
  TrademarkOutlined,
  ArrowDownOutlined,
  SettingOutlined
} from "@ant-design/icons";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import "../style.css";
import DraggableModal from "../../../Components/DraggableModal";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import store from "../../../../redux/store";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate"
      ? form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY") : ""
      })
      : form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
      });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "planedDeliveryDate") {
        const values = await form.validateFields();
        values.planedDeliveryDate = Boolean(values.planedDeliveryDate) ? moment(values.planedDeliveryDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "paymentDate") {
        const values = await form.validateFields();
        values.paymentDate = Boolean(values.paymentDate) ? moment(values.paymentDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) { }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
      >
        {dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate" ? (
          <DatePicker ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY"} />
        ) : (
          <InputNumber
            min={0}
            max={record.quantity > 0 ? record.quantity : 0}
            parser={value => value.replace(",", ".")}
            formatter={value => value.replace(".", ",")}
            ref={inputRef}
            onPressEnter={toggleSave}
            onBlur={toggleSave}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

export class StockControlAssigment extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "StockControlAssigment",
      controllerName: "stockControl",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      modalSendAll: false,
      collapsedAvailable: true,
      collapsedReserved: false,
      collapsedToBeReleased: false,
      collapsedRequested: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      reservedQuantityList: [],
      avaliableQuantityList: [],
      loadingButtonSubmit: false,
      user: store.getState().auth.user
    };
  }

  formRef = React.createRef();
  formRefDesicion = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();

    this.setState({
      selectedRow: this.props.selectedRow
    }, () => {
      this.desicionModal(this.props.selectedRow);
    });
  }

  fillComboboxes = async () => {
    var responseCompanyProjectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/companyProjectId");
    if (responseCompanyProjectId.type === "ERROR") {
      error(responseCompanyProjectId);
    }

    this.setState({
      companyProjectIdSelectItems: Boolean(responseCompanyProjectId.data) ? responseCompanyProjectId.data : []
    });
  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => { };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  sendAll = async () => {
    let list = [];
    const { id, selectedRow } = this.state;
    if (Boolean(id) && Boolean(selectedRow)) {
      let result = this.setQuantityToBeProcured(selectedRow);
      if (Boolean(result) && result > 0) {
        list.push({ id: id, quantityToBeProcured: result });
      }

      if (Boolean(list) && list.length > 0) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/sendToRFQ", list);
        if (response.type === "ERROR") {
          error(response);
        } else {
          const { pagination } = this.state;
          this.restartTable({ pagination });
          showSuccess();
          this.setState({
            id: null,
            modalSendAll: false
          });
        }
      } else {
        showWarning("No item found to send.");
      }
    } else {
      showWarning("Not found.");
    }
  };

  sendStockControlAssigmentApproval = async () => {
    let list = [];
    const { id, selectedRow } = this.state;
    if (Boolean(id) && Boolean(selectedRow)) {
      let result = this.setQuantityToBeProcured(selectedRow);
      if (Boolean(result) && result > 0) {
        list.push({ id: id, quantityToBeProcured: result });
      }

      if (Boolean(list) && list.length > 0) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/sendToStockControlAssigmentApproval", list);
        if (response.type === "ERROR") {
          error(response);
        } else {
          const { pagination } = this.state;
          this.restartTable({ pagination });
          showSuccess();
          this.setState({
            id: null,
            modalSendAll: false
          });
        }
      } else {
        showWarning("No item found to send.");
      }
    } else {
      showWarning("Not found.");
    }
  };

  desicionModal = async row => {
    if (Boolean(row.id) && row.id > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/desicion/" + row.id);
      if (response.type === "ERROR") {
        error(response);
      }

      let desicionList = Boolean(response.data) ? response.data : null;
      let companyProjectIds = [];
      if (Boolean(desicionList)) {
        if (Boolean(desicionList.reserved) && desicionList.reserved.length > 0) {
          desicionList.reserved.forEach(reserved => {
            if (!companyProjectIds.includes(reserved.companyProjectId)) {
              companyProjectIds.push(reserved.companyProjectId);
            }
          });
        }

        if (Boolean(desicionList.toBeReleased) && desicionList.toBeReleased.length > 0) {
          desicionList.toBeReleased.forEach(toBeReleased => {
            if (!companyProjectIds.includes(toBeReleased.companyProjectId)) {
              companyProjectIds.push(toBeReleased.companyProjectId);
            }
          });
        }
      }

      this.setState(
        {
          totalQuantity: 0,
          balance: 0,
          selectedRow: row,
          modalDesicionShow: true,
          desicionList: desicionList,
          companyProjectIds: companyProjectIds,
          collapsedAvailable: true,
          collapsedReserved: false,
          collapsedToBeReleased: false,
          collapsedRequested: false
        },
        () => {
          this.formRefDesicion.current.resetFields();
          this.onChangeValues();
        }
      );
    } else {
      showError("Not found.");
    }
  };

  onChangeValues = async () => {
    const { companyProjectIds, desicionList, selectedRow } = this.state;
    console.log("companyProjectIds", companyProjectIds);

    let requiredQuantity = selectedRow.quantity;
    console.log("requiredQuantity", requiredQuantity);
    console.log("desicionList", desicionList);

    let reserve = 0;
    if (Boolean(desicionList) && Boolean(desicionList.reserved)) {
      desicionList.reserved.forEach(r => {
        if (Boolean(r.quantity) && r.quantity > 0) {
          reserve += r.quantity;
        }
      });
    }
    console.log("reserve", reserve);

    let toBeRelease = 0;
    if (Boolean(desicionList) && Boolean(desicionList.toBeReleased)) {
      desicionList.toBeReleased.forEach(t => {
        if (Boolean(t.quantity) && t.quantity > 0) {
          toBeRelease += t.quantity;
        }
      });
    }
    console.log("toBeRelease", toBeRelease);
    console.log("balance", requiredQuantity - (reserve + toBeRelease));

    this.setState({
      // totalQuantity: this.state.selectedRow.quantity - balance,
      balance: requiredQuantity - (reserve + toBeRelease),
      collapsedAvailable: true,
      collapsedReserved: reserve > 0 ? true : false,
      collapsedToBeReleased: toBeRelease > 0 ? true : false
    });
  };

  reservedQuantityModal = async row => {
    console.log("row", row);
    console.log("selectedRow", this.state.selectedRow);
    const { selectedRow } = this.state;

    let reservedQuantityList = [];
    if (Boolean(selectedRow.id) && selectedRow.id > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/reserved/" + selectedRow.id + "/" + row.companyProjectId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(Boolean(response.data)) && Boolean(response.data.reserved) && response.data.reserved.length > 0) {
          reservedQuantityList = response.data.reserved;
        }
      }
    }
    console.log("reservedQuantityList", reservedQuantityList);

    this.setState(
      {
        modalReservedQuantityShow: true,
        reservedQuantityList: reservedQuantityList
      },
      () => {
        this.setColumnsReserved();
      }
    );
  };

  avaliableQuantityModal = async () => {
    console.log("selectedRow", this.state.selectedRow);
    const { selectedRow } = this.state;

    let avaliableQuantityList = [];
    if (Boolean(selectedRow.id) && selectedRow.id > 0 && Boolean(selectedRow.companyProjectId) && selectedRow.companyProjectId > 0) {
      var response = await handleRequest(
        "GET",
        "/api/" + this.state.controllerName + "/avaliable/" + selectedRow.id + "/" + selectedRow.companyProjectId
      );
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(Boolean(response.data)) && Boolean(response.data.avaliable) && response.data.avaliable.length > 0) {
          avaliableQuantityList = response.data.avaliable;
        }
      }
    }
    console.log("avaliableQuantityList", avaliableQuantityList);

    this.setState(
      {
        modalAvaliableQuantityShow: true,
        avaliableQuantityList: avaliableQuantityList
      },
      () => {
        this.setColumnsAvaliable();
      }
    );
  };

  toBeReleasedQuantityModal = async row => {
    console.log("row", row);
    console.log("selectedRow", this.state.selectedRow);
    const { selectedRow } = this.state;

    let toBeReleasedQuantityList = [];
    if (Boolean(selectedRow.id) && selectedRow.id > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/toBeReleased/" + selectedRow.id + "/" + row.companyProjectId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(Boolean(response.data)) && Boolean(response.data.toBeReleased) && response.data.toBeReleased.length > 0) {
          toBeReleasedQuantityList = response.data.toBeReleased;
        }
      }
    }
    console.log("toBeReleasedQuantityList", toBeReleasedQuantityList);

    this.setState(
      {
        modalToBeReleasedQuantityShow: true,
        toBeReleasedQuantityList: toBeReleasedQuantityList
      },
      () => {
        this.setColumnsToBeReleased();
      }
    );
  };

  setColumnsToBeReleased() {
    const { selectedRow, toBeReleasedQuantityList } = this.state;

    let columnsToBeReleased = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="StockControlAssigment.Stock" defaultMessage="Stock" />,
        key: "stockName",
        render: record => {
          return record.stockName;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="StockControlAssigment.InStock" defaultMessage="In Stock" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        },
        width: 100
      }
    ];

    if (Boolean(selectedRow) && Boolean(selectedRow.allDimensions)) {
      let dimensionList = selectedRow.allDimensions.split(", ");

      if (Boolean(toBeReleasedQuantityList) && toBeReleasedQuantityList.length > 0) {
        toBeReleasedQuantityList.forEach(element => {
          if (Boolean(element.allDimensions)) {
            let dimensionRowList = element.allDimensions.split(", ");

            if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
              dimensionRowList.forEach(element => {
                if (!dimensionList.includes(element)) {
                  dimensionList.push(element);
                }
              });
            }
          }
        });
      }

      if (Boolean(dimensionList) && dimensionList.length > 0) {
        dimensionList.forEach(element => {
          let dimension = element.split(":");
          if (Boolean(dimension) && dimension.length > 1) {
            let obj = {
              // key: row.key,
              title: dimension[0],
              dataIndex: dimension[0]
              // [dimension[0]]: dimension[1],
            };

            let addColumn = columnsToBeReleased.find(p => p.title === obj.title);
            if (!Boolean(addColumn)) {
              columnsToBeReleased.push(obj);
            }

            if (Boolean(toBeReleasedQuantityList) && toBeReleasedQuantityList.length > 0) {
              toBeReleasedQuantityList.forEach((element, index) => {
                if (Boolean(element.allDimensions) && element.allDimensions.includes(dimension[0])) {
                  let dimensionRowList = element.allDimensions.split(", ");
                  if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
                    console.log("dimensionRowList", dimensionRowList);
                    dimensionRowList.forEach(subRow => {
                      console.log("subRow", subRow);

                      let subDimension = subRow.split(":");
                      if (Boolean(subDimension) && subDimension.length > 1 && dimension[0] === subDimension[0]) {
                        element.key = index + 1;
                        element[dimension[0]] = subDimension[1];
                        return;
                      }
                    });
                  }
                } else {
                  element.key = index + 1;
                  element[dimension[0]] = "";
                }
              });
            }
          }
        });
      }
    }

    let toBeReleased = {
      title: <FormattedMessage id="StockControlAssigment.ToBeReleased" defaultMessage="To be Released" />,
      key: "toBeReleasedQuantity",
      render: record => {
        return record.toBeReleasedQuantity;
      },
      width: 100,
      editable: true,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: "toBeReleasedQuantity",
        title: <FormattedMessage id="StockControlAssigment.ToBeReleased" defaultMessage="To be Released" />,
        handleSave: this.handleToBeReleasedQuantityList
      })
    };

    columnsToBeReleased.push(toBeReleased);

    this.setState(
      {
        columnsToBeReleased: columnsToBeReleased
      },
      () => {
        console.log("toBeReleasedQuantityList", toBeReleasedQuantityList);
        console.log("columnsToBeReleased", this.state.columnsToBeReleased);
      }
    );
  }

  setColumnsReserved() {
    const { selectedRow, reservedQuantityList } = this.state;

    let columnsReserved = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="StockControlAssigment.Stock" defaultMessage="Stock" />,
        key: "stockName",
        render: record => {
          return record.stockName;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="StockControlAssigment.InStock" defaultMessage="In Stock" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        },
        width: 100
      }
    ];

    if (Boolean(selectedRow) && Boolean(selectedRow.allDimensions)) {
      let dimensionList = selectedRow.allDimensions.split(", ");
      console.log("selectedRow", dimensionList);

      if (Boolean(reservedQuantityList) && reservedQuantityList.length > 0) {
        reservedQuantityList.forEach(element => {
          if (Boolean(element.allDimensions)) {
            let dimensionRowList = element.allDimensions.split(", ");

            if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
              dimensionRowList.forEach(element => {
                if (!dimensionList.includes(element)) {
                  dimensionList.push(element);
                }
              });
            }
          }
        });
      }

      if (Boolean(dimensionList) && dimensionList.length > 0) {
        dimensionList.forEach(element => {
          let dimension = element.split(":");
          if (Boolean(dimension) && dimension.length > 1) {
            let obj = {
              // key: row.key,
              title: dimension[0],
              dataIndex: dimension[0]
              // [dimension[0]]: dimension[1],
            };

            let addColumn = columnsReserved.find(p => p.title === obj.title);
            if (!Boolean(addColumn)) {
              columnsReserved.push(obj);
            }

            if (Boolean(reservedQuantityList) && reservedQuantityList.length > 0) {
              reservedQuantityList.forEach((element, index) => {
                if (Boolean(element.allDimensions) && element.allDimensions.includes(dimension[0])) {
                  let dimensionRowList = element.allDimensions.split(", ");
                  if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
                    console.log("dimensionRowList", dimensionRowList);
                    dimensionRowList.forEach(subRow => {
                      console.log("subRow", subRow);

                      let subDimension = subRow.split(":");
                      if (Boolean(subDimension) && subDimension.length > 1 && dimension[0] === subDimension[0]) {
                        element.key = index + 1;
                        element[dimension[0]] = subDimension[1];
                        return;
                      }
                    });
                  }
                } else {
                  element.key = index + 1;
                  element[dimension[0]] = "";
                }
              });
            }
          }
        });
      }
    }

    let reserved = {
      title: <FormattedMessage id="StockControlAssigment.Reserved" defaultMessage="Reserved" />,
      key: "reservedQuantity",
      render: record => {
        return record.reservedQuantity;
      },
      width: 100,
      editable: true,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: "reservedQuantity",
        title: <FormattedMessage id="StockControlAssigment.Reserved" defaultMessage="Reserved" />,
        handleSave: this.handleReservedQuantityList
      })
    };

    columnsReserved.push(reserved);

    this.setState(
      {
        columnsReserved: columnsReserved
      },
      () => {
        console.log("reservedQuantityList", reservedQuantityList);
        console.log("columnsReserved", this.state.columnsReserved);
      }
    );
  }

  handleReservedQuantityList = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.reservedQuantityList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.reservedQuantity === value.reservedQuantity) return;
        item.reservedQuantity = value.reservedQuantity;

        console.log("item", item);
        this.setState(
          {
            reservedQuantityList: newData
          },
          () => {
            this.setColumnsReserved();
            console.log("reserved", this.state.reservedQuantityList);
          }
        );
      }
    }
  };

  handleToBeReleasedQuantityList = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.toBeReleasedQuantityList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.toBeReleasedQuantity === value.toBeReleasedQuantity) return;
        item.toBeReleasedQuantity = value.toBeReleasedQuantity;
        console.log("item", item);

        this.setState(
          {
            toBeReleasedQuantityList: newData
          },
          () => {
            this.setColumnsToBeReleased();
            console.log("toBeReleased", this.state.toBeReleasedQuantityList);
          }
        );
      }
    }
  };

  saveReservedQuantity = async () => {
    const { reservedQuantityList, selectedRow } = this.state;
    console.log("reservedQuantityList", reservedQuantityList);
    console.log("selectedRow", selectedRow);

    if (Boolean(reservedQuantityList) && reservedQuantityList.length > 0) {
      const newItem = {
        projectTakeOffQuantityId: this.state.selectedRow.id,
        companyProjectId: this.state.selectedRow.companyProjectId,
        reserved: reservedQuantityList
      };
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveReserved", newItem);

      if (response.type === "ERROR") {
        error(response);
        this.setState({ loadingButtonSubmit: false });
      } else {
        this.cancel();
        this.desicionModal(selectedRow);
        showSuccess();
        this.setState({
          modalReservedQuantityShow: false,
          loadingButtonSubmit: false
        });
      }
    } else {
      showError("Not found.");
      this.setState({ loadingButtonSubmit: false });
    }
  };

  saveToBeReleasedQuantity = async () => {
    const { toBeReleasedQuantityList, selectedRow } = this.state;
    console.log("toBeReleasedQuantityList", toBeReleasedQuantityList);
    console.log("selectedRow", selectedRow);

    if (Boolean(toBeReleasedQuantityList) && toBeReleasedQuantityList.length > 0) {
      toBeReleasedQuantityList.forEach(element => {
        if (Boolean(element.quantity) && element.quantity > 0) {
          element.toBeReleasedQuantity = element.quantity;
        }
      });
      const newItem = {
        projectTakeOffQuantityId: this.state.selectedRow.id,
        companyProjectId: this.state.selectedRow.companyProjectId,
        toBeReleased: toBeReleasedQuantityList
      };
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveToBeReleased", newItem);

      if (response.type === "ERROR") {
        error(response);
        this.setState({ loadingButtonSubmit: false });
      } else {
        this.cancel();
        await this.desicionModal(selectedRow);
        showSuccess();
        this.setState({
          modalToBeReleasedQuantityShow: false,
          loadingButtonSubmit: false,
          modalDesicionShow: false
        });
      }
    } else {
      showError("Not found.");
      this.setState({ loadingButtonSubmit: false });
    }
  };

  setColumnsAvaliable() {
    const { selectedRow, avaliableQuantityList } = this.state;

    let columnsAvaliable = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="StockControlAssigment.Stock" defaultMessage="Stock" />,
        key: "stockName",
        render: record => {
          return record.stockName;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="StockControlAssigment.InStock" defaultMessage="In Stock" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="StockControlAssigment.Reserve" defaultMessage="Reserved" />,
        key: "reserve",
        render: record => {
          return record.reserve;
        },
        width: 100
      }
    ];

    if (Boolean(selectedRow) && Boolean(selectedRow.allDimensions)) {
      let dimensionList = selectedRow.allDimensions.split(", ");
      console.log("selectedRow", dimensionList);

      if (Boolean(avaliableQuantityList) && avaliableQuantityList.length > 0) {
        avaliableQuantityList.forEach(element => {
          if (Boolean(element.allDimensions)) {
            let dimensionRowList = element.allDimensions.split(", ");

            if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
              dimensionRowList.forEach(element => {
                if (!dimensionList.includes(element)) {
                  dimensionList.push(element);
                }
              });
            }
          }
        });
      }

      if (Boolean(dimensionList) && dimensionList.length > 0) {
        dimensionList.forEach(element => {
          let dimension = element.split(":");
          if (Boolean(dimension) && dimension.length > 1) {
            let obj = {
              // key: row.key,
              title: dimension[0],
              dataIndex: dimension[0]
              //[dimension[0]]: dimension[1],
            };

            let addColumn = columnsAvaliable.find(p => p.title === obj.title);
            if (!Boolean(addColumn)) {
              columnsAvaliable.push(obj);
            }

            if (Boolean(avaliableQuantityList) && avaliableQuantityList.length > 0) {
              avaliableQuantityList.forEach((element, index) => {
                if (Boolean(element.allDimensions) && element.allDimensions.includes(dimension[0])) {
                  let dimensionRowList = element.allDimensions.split(", ");
                  if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
                    console.log("dimensionRowList", dimensionRowList);
                    dimensionRowList.forEach(subRow => {
                      console.log("subRow", subRow);

                      let subDimension = subRow.split(":");
                      if (Boolean(subDimension) && subDimension.length > 1 && dimension[0] === subDimension[0]) {
                        element.key = index + 1;
                        element[dimension[0]] = subDimension[1];
                        return;
                      }
                    });
                  }
                } else {
                  element.key = index + 1;
                  element[dimension[0]] = "";
                }
              });
            }
          }
        });
      }
    }

    let toBeReleased = {
      title: <FormattedMessage id="StockControlAssigment.ToBeReleased" defaultMessage="To Be Released" />,
      key: "avaliableQuantity",
      render: record => {
        return record.avaliableQuantity;
      },
      width: 100,
      editable: true,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: "avaliableQuantity",
        title: <FormattedMessage id="StockControlAssigment.ToBeReleased" defaultMessage="To Be Released" />,
        handleSave: this.handleAvaliableQuantityList
      })
    };

    columnsAvaliable.push(toBeReleased);

    this.setState(
      {
        columnsAvaliable: columnsAvaliable
      },
      () => {
        console.log("avaliableQuantityList", avaliableQuantityList);
        console.log("columnsAvaliable", this.state.columnsAvaliable);
      }
    );
  }

  handleAvaliableQuantityList = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.avaliableQuantityList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.avaliableQuantity === value.avaliableQuantity) return;

        // let result = this.setQuantityToBeProcured(this.state.selectedRow);
        let result = this.state.balance;
        console.log("selectedRow", this.state.selectedRow);
        console.log("result", result);
        if (Boolean(result) && result >= value.avaliableQuantity) {
          item.avaliableQuantity = value.avaliableQuantity;

          this.setState(
            {
              avaliableQuantityList: newData
            },
            () => {
              this.setColumnsAvaliable();
              console.log("avaliable", this.state.avaliableQuantityList);
            }
          );
        } else {
          showError("Available quantity can be max " + result);
        }
      }
    }
  };

  saveAvaliableQuantity = async () => {
    const { avaliableQuantityList, selectedRow } = this.state;
    console.log("avaliableQuantityList", avaliableQuantityList);
    console.log("selectedRow", selectedRow);

    if (Boolean(avaliableQuantityList) && avaliableQuantityList.length > 0) {
      const newItem = {
        projectTakeOffQuantityId: this.state.selectedRow.id,
        companyProjectId: this.state.selectedRow.companyProjectId,
        avaliable: avaliableQuantityList
      };

      console.log("newItem", newItem);

      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAvaliable", newItem);

      if (response.type === "ERROR") {
        error(response);
        this.setState({ loadingButtonSubmit: false });
      } else {
        const { pagination } = this.state;
        this.restartTable({ pagination });

        let availableStock = selectedRow.availableStock;
        avaliableQuantityList.forEach(element => {
          if (Boolean(element.avaliableQuantity) && element.avaliableQuantity > 0) {
            availableStock = availableStock - element.avaliableQuantity;
          }
        });
        selectedRow.availableStock = availableStock;

        //this.cancel();
        this.desicionModal(selectedRow);
        showSuccess();
        this.setState({
          modalAvaliableQuantityShow: false,
          loadingButtonSubmit: false
        });
      }
    } else {
      showError("Not found.");
      this.setState({ loadingButtonSubmit: false });
    }
  };

  setQuantityToBeProcured(record) {
    let quantity = 0;
    if (Boolean(record.quantity)) {
      quantity = record.quantity;
    }

    let reservedQuantity = 0;
    if (Boolean(record.reservedQuantity)) {
      reservedQuantity = record.reservedQuantity;
    }

    let toBeReleasedQuantity = 0;
    if (Boolean(record.toBeReleasedQuantity)) {
      toBeReleasedQuantity = record.toBeReleasedQuantity;
    }

    let requested = 0;
    if (Boolean(record.requested)) {
      requested = record.requested;
    }

    let result = quantity - (reservedQuantity + toBeReleasedQuantity + requested);
    if (result > 0) {
      return result;
    }
    return 0;
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  sendSelected = async () => {
    const { selectedRowKeys, tableList } = this.state;
    console.log("selectedRowKeys", selectedRowKeys);
    if (Boolean(selectedRowKeys) && selectedRowKeys.length > 0) {
      let list = [];
      selectedRowKeys.forEach(key => {
        let row = tableList.find(p => p.id === key);
        if (Boolean(row)) {
          let result = this.setQuantityToBeProcured(row);
          if (Boolean(result) && result > 0) {
            list.push({ id: key, quantityToBeProcured: result });
          }
        }
      });
      if (Boolean(list) && list.length > 0) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/sendToRFQ", list);
        if (response.type === "ERROR") {
          error(response);
        } else {
          const { pagination } = this.state;
          this.restartTable({ pagination });
          showSuccess();
          this.setState({
            modalSendSelectShow: false
          });
        }
        this.setState({
          selectedRowKeys: []
        });
      } else {
        showWarning("No item found to send.");
      }
    } else {
      showWarning("Please select item.");
    }
  };

  clearAndCallBackAllAssignment = async status => {
    const { selectedRow } = this.state;
    if (
      Boolean(selectedRow) &&
      Boolean(selectedRow.id) &&
      selectedRow.id > 0 &&
      Boolean(selectedRow.companyProjectId) &&
      selectedRow.companyProjectId > 0 &&
      Boolean(status)
    ) {
      var response = await handleRequest(
        "GET",
        "/api/" + this.state.controllerName + "/clearAndCallBackAllAssignment/" + selectedRow.id + "/" + selectedRow.companyProjectId + "/" + status
      );
      if (response.type === "ERROR") {
        error(response);
      } else {
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.setState({
          modalClearAllAssignments: false,
          modalCallBackAction: false
        });
      }
    } else {
      showWarning("Project take off or company project not found.");
    }
  };

  sendSelectModal = () => {
    this.setState({
      modalSendSelectShow: true
    });
  };

  toggleCollapse = collapse => {
    this.setState(prevState => ({
      ["collapsed" + collapse]: !prevState["collapsed" + collapse]
    }));
  };

  getStatusColor = status => {
    if (Boolean(status)) {
      if (status === "Sent" || status === "RfqInPRogress") {
        return "processing";
      } else if (status === "Clear_All_Assignments" || status === "Call_Back") {
        return "warning";
      } else if (status === "StockControlAssigmentSentToRfq") {
        return "success";
      } else if (status === "Stock_Control_In_Progress") {
        return "error";
      }
    }
    return "default";
  };

  getModalContent = () => {
    // İçeriği burada oluşturun veya başka bir fonksiyona çağırın
    return <Table rowKey={"index"} bordered dataSource={this.state.tableList} loading={this.state.loading} pagination={false} />;
  };

  render() {
    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };
    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.printButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    };
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideSelectAll: true,
      renderCell: (checked, record, index, originNode) => {
        return originNode;
      }
    };

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}></Col>
          <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
            <Form initialValues={{ remember: false }} onFinish={this.save} ref={this.formRefDesicion}>
              {Boolean(this.state.desicionList) && (
                <>
                  <div className="card card-custom" style={{ minHeight: "100%" }}>
                    <div className="arrow-container">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <TrademarkOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                        <span style={{ fontSize: "15px" }}>
                          <FormattedMessage id="StockControlAssigment.RequiredQuantity" defaultMessage="Required Quantity" />
                        </span>
                      </div>
                      <Space>
                        <InputNumber className="w-100" value={this.state.selectedRow.quantity} readOnly />
                      </Space>
                    </div>
                  </div>

                  <br />

                  <div className="card card-custom" style={{ minHeight: "100%" }}>
                    <div className="arrow-container">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CalculatorOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                        <span style={{ fontSize: "15px" }}>
                          <FormattedMessage id="StockControlAssigment.Balance" defaultMessage="Balance" />
                        </span>
                      </div>
                      <Space>
                        <InputNumber className="w-100" readOnly value={this.state.balance} />
                      </Space>
                    </div>
                  </div>

                  <br />

                  <div className="card card-custom" style={{ minHeight: "100%" }}>
                    <div className="arrow-container">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <DatabaseOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                        <span style={{ fontSize: "15px" }}>
                          <FormattedMessage id="StockControlAssigment.Available" defaultMessage="Available" />
                        </span>
                      </div>
                      <Space>
                        <IconButton onClick={() => this.toggleCollapse("Available")}>
                          {this.state.collapsedAvailable ? <ArrowDownOutlined /> : <ArrowRightOutlined />}
                        </IconButton>
                      </Space>
                    </div>

                    {this.state.collapsedAvailable ? (
                      <Row>
                        <Col span={6}></Col>
                        <Col span={4}>
                          <InputNumber
                            className="w-100"
                            value={this.state.selectedRow.availableStock}
                            readOnly
                            addonAfter={<SettingOutlined onClick={() => this.avaliableQuantityModal()} />}
                          />
                        </Col>
                        <Col span={1}></Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                  <br />

                  <div className="card card-custom" style={{ minHeight: "100%" }}>
                    <div className="arrow-container">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <StopOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                        <span style={{ fontSize: "15px" }}>
                          <FormattedMessage id="StockControlAssigment.Reserved" defaultMessage="Reserved" />
                        </span>
                      </div>
                      <Space>
                        <IconButton onClick={() => this.toggleCollapse("Reserved")}>
                          {this.state.collapsedReserved ? <ArrowDownOutlined /> : <ArrowRightOutlined />}
                        </IconButton>
                      </Space>
                    </div>
                    {this.state.collapsedReserved
                      ? Boolean(this.state.desicionList.reserved) &&
                      this.state.desicionList.reserved.length > 0 &&
                      this.state.desicionList.reserved.map(i => (
                        <Row key={i.project}>
                          <Col span={6}>{i.project}</Col>
                          <Col span={4}>
                            <InputNumber
                              className="w-100"
                              value={i.quantity}
                              readOnly
                              addonAfter={<SettingOutlined onClick={() => this.reservedQuantityModal(i)} />}
                            />
                          </Col>
                          <Col span={1}></Col>
                        </Row>
                      ))
                      : ""}
                  </div>

                  <br />

                  <div className="card card-custom" style={{ minHeight: "100%" }}>
                    <div className="arrow-container">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ExportOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                        <span style={{ fontSize: "15px" }}>
                          <FormattedMessage id="StockControlAssigment.ToBeReleased" defaultMessage="To Be Released" />
                        </span>
                      </div>
                      <Space>
                        <IconButton onClick={() => this.toggleCollapse("ToBeReleased")}>
                          {this.state.collapsedToBeReleased ? <ArrowDownOutlined /> : <ArrowRightOutlined />}
                        </IconButton>
                      </Space>
                    </div>
                    {this.state.collapsedToBeReleased
                      ? Boolean(this.state.desicionList.toBeReleased) &&
                      this.state.desicionList.toBeReleased.length > 0 &&
                      this.state.desicionList.toBeReleased.map(i => (
                        <Row key={i.project}>
                          <Col span={6}>{i.project}</Col>
                          <Col span={4}>
                            <InputNumber
                              className="w-100"
                              value={i.quantity}
                              readOnly
                              addonAfter={<SettingOutlined onClick={() => this.toBeReleasedQuantityModal(i)} />}
                            />
                          </Col>
                          <Col span={1}></Col>
                        </Row>
                      ))
                      : ""}
                  </div>

                  <br />
                  <div className="card card-custom" style={{ minHeight: "100%" }}>
                    <div className="arrow-container">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FileDoneOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                        <span style={{ fontSize: "15px" }}>
                          <FormattedMessage id="StockControlAssigment.Requested" defaultMessage="Requested" />
                        </span>
                      </div>
                      <Space>
                        <IconButton onClick={() => this.toggleCollapse("Requested")}>
                          {this.state.collapsedRequested ? <ArrowDownOutlined /> : <ArrowRightOutlined />}
                        </IconButton>
                      </Space>
                    </div>
                    {this.state.collapsedRequested
                      ? Boolean(this.state.desicionList.requested) &&
                      this.state.desicionList.requested.length > 0 &&
                      this.state.desicionList.requested.map(i => <Row></Row>)
                      : ""}
                  </div>

                  <br />
                </>
              )}
            </Form>
          </Col>
        </Row>

        <DraggableModal
          title={<FormattedMessage id="StockControlAssigment.ReservedQuantity" defaultMessage="Reserved Quantity" />}
          width={800}
          centered={true}
          closable={false}
          open={this.state.modalReservedQuantityShow}
          onOk={() => this.sendAll()}
          onCancel={() => this.setState({ modalReservedQuantityShow: false })}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Table
                bordered
                size="small"
                components={components}
                rowClassName={() => "editable-row"}
                columns={this.state.columnsReserved}
                dataSource={this.state.reservedQuantityList}
                loading={this.state.loading}
                pagination={false}
              />
            </div>
          }
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalReservedQuantityShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>

              <Spin spinning={this.state.loadingButtonSubmit}>
                <Button
                  style={{ width: "100%" }}
                  variant="success"
                  onClick={() => {
                    this.setState(
                      {
                        loadingButtonSubmit: true
                      },
                      () => {
                        this.saveReservedQuantity();
                      }
                    );
                  }}
                >
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Spin>
            </Space>
          ]}
        />

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="StockControlAssigment.SendToStockControlAssigmentApproval" defaultMessage="Send To Stock Control Approval" />
              </span>
            </span>
          }
          open={this.state.modalSendAll}
          onOk={() => this.sendStockControlAssigmentApproval()}
          onCancel={() => this.setState({ modalSendAll: false })}
          content={
            <FormattedMessage
              id="GeneralMessage.SelectedProjectItemWillSendedToStockControlAssigmentAreYouSure"
              defaultMessage="Selected Project Item will sended to Approval. Are you sure?"
            />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="StockControlAssigment.ToBeReleasedQuantity" defaultMessage="To Be Released Quantity" />}
          closable="false"
          open={this.state.modalToBeReleasedQuantityShow}
          onCancel={() => {
            this.setState({ modalToBeReleasedQuantityShow: false });
          }}
          width={800}
          centered
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalToBeReleasedQuantityShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
              <Spin spinning={this.state.loadingButtonSubmit}>
                <Button
                  style={{ width: "100%" }}
                  variant="success"
                  onClick={() => {
                    this.setState(
                      {
                        loadingButtonSubmit: true
                      },
                      () => {
                        this.saveToBeReleasedQuantity();
                      }
                    );
                  }}
                >
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Spin>
            </Space>
          ]}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Table
                bordered
                size="small"
                components={components}
                rowClassName={() => "editable-row"}
                columns={this.state.columnsToBeReleased}
                dataSource={this.state.toBeReleasedQuantityList}
                loading={this.state.loading}
                pagination={false}
              />
            </div>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="StockControlAssigment.AvailableQuantity" defaultMessage="Available Quantity" />}
          closable="false"
          open={this.state.modalAvaliableQuantityShow}
          onCancel={() => {
            this.setState({ modalAvaliableQuantityShow: false });
          }}
          width={800}
          centered
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalAvaliableQuantityShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>

              <Spin spinning={this.state.loadingButtonSubmit}>
                <Button
                  style={{ width: "100%" }}
                  variant="success"
                  onClick={() => {
                    this.setState(
                      {
                        loadingButtonSubmit: true
                      },
                      () => {
                        this.saveAvaliableQuantity();
                      }
                    );
                  }}
                >
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Spin>
            </Space>
          ]}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Table
                bordered
                size="small"
                components={components}
                rowClassName={() => "editable-row"}
                columns={this.state.columnsAvaliable}
                dataSource={this.state.avaliableQuantityList}
                loading={this.state.loading}
                pagination={false}
              />
            </div>
          }
        />

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="StockControlAssigment.SendToRFQ" defaultMessage="Send To RFQ" />
              </span>
            </span>
          }
          centered
          open={this.state.modalSendSelectShow}
          onOk={() => this.sendSelected()}
          onCancel={() => this.setState({ modalSendSelectShow: false })}
          okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={<FormattedMessage id="GeneralMessageDoYouWantToProceedThisRequest" defaultMessage="Do you want to proceed this request?" />}
        />

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="StockControlAssigment.ClearAllAssignments" defaultMessage="Clear All Assignments" />
              </span>
            </span>
          }
          centered
          open={this.state.modalClearAllAssignments}
          onOk={() => this.clearAndCallBackAllAssignment("Clear_All_Assignments")}
          onCancel={() => this.setState({ modalClearAllAssignments: false })}
          okText={<FormattedMessage id="GeneralButtonClear" defaultMessage="Clear" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={<FormattedMessage id="GeneralMessageDoYouWantToProceedThisRequest" defaultMessage="Do you want to proceed this request?" />}
        />

        <DraggableModal
          title={<FormattedMessage id="GeneralPDFReportViewer" defaultMessage="PDF Report Viewer" />}
          open={this.state.modalPdfViewer}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalPdfViewer: false })}
          width={1200}
          centered
          content={
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div style={{ marginTop: "2rem" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>
          }
        ></DraggableModal>

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="StockControlAssigment.CallBack" defaultMessage="Call Back" />
              </span>
            </span>
          }
          centered
          open={this.state.modalCallBackAction}
          onOk={() => this.clearAndCallBackAllAssignment("Call_Back")}
          onCancel={() => this.setState({ modalCallBackAction: false })}
          okText={<FormattedMessage id="GeneralButtonCallBack" defaultMessage="Call Back" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={<FormattedMessage id="GeneralMessageDoYouWantToProceedThisRequest" defaultMessage="Do you want to proceed this request?" />}
        />

        <Drawer
          title={<FormattedMessage id="StockControlAssigment.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"Stock_Control"} />
        </Drawer>
      </>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(StockControlAssigment);
