import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Button as AntButton, Drawer, Card, Space, Image, Cascader, Spin, Tooltip, Checkbox, Radio, Avatar } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { Add, EditOutlined, FilterList } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import DraggableModal from "../../../Components/DraggableModal";
import { InputNumber } from "antd";
import { Select } from "antd";
import { Input } from "antd";
import { DragOutlined } from "@ant-design/icons";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const dataListUnit = [];
const generateListUnit = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataListUnit.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateListUnit(node.children);
    }
  }
};

export class BillOfQuantityAddNewItem extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BillOfQuantityAddNewItem",
      controllerName: "billOfQuantity",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      latesPurchases: [],
      purchasingcriteriaSelectItems: [],
      companyName: "Stock Card",
      selectedRow: null,
      quotationDay: -1,
      purchaseDay: -1,
      loadingPurchases: false,
      loadingQuotation: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      }
    };
  }

  formRef = React.createRef();
  formRefNewSupplier = React.createRef();
  formRefNewManufacturer = React.createRef();
  formRefNewBrand = React.createRef();
  formRefItem = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    console.log("row", this.props.selectedRow);
    this.fillComboboxes();
    this.resetInputs();

    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
    }, () => {
      if (Boolean(this.props) && Boolean(this.props.selectedRow)) {
        let row = this.props.selectedRow;
        if (Boolean(row.itemName)) {
          this.searchItem(row.itemName);
        } else {
          this.setState({
            itemIdSelectItems: [],
          });
        }
        this.setState({
          hideUpdate: false,
          hideSave: true,
          hideInputs: false,
          id: row.id,
          itemId: row.itemId,
          supplierId: row.supplierId,
        }, () => {
          this.fillSupplier();
          this.fillBrand();
          this.getPurchaseCriteria();
          this.fillLatesPurchases();
          this.fillLatesQuotations();

          this.fillService(row.disciplineId);
          this.fillCompound(row.serviceId);
          this.fillComponent(row.compound);
        });

        var parent = dataListUnit.find(p => p.key === row.unitId);
        let unitId = [];
        if (Boolean(parent)) {
          unitId = parent.lineage;
        }

        this.formRef.current.setFieldsValue({
          ...row,
          unitId: unitId,
        });
      }
    });
  };

  fillComboboxes = async () => {
    this.fillSupplier();
    this.fillManufacturer();
    var responseitemGroupId = await handleRequest("GET", "/api/" + this.state.controllerName + "/category");
    if (responseitemGroupId.type === "ERROR") {
      error(responseitemGroupId);
    }
    var responsecurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responsecurrency.type === "ERROR") {
      error(responsecurrency);
    }
    var responsedisciplineId = await handleRequest("GET", "/api/" + this.state.controllerName + "/discipline");
    if (responsedisciplineId.type === "ERROR") {
      error(responsedisciplineId);
    }
    this.setState({
      disciplineIdSelectItems: Boolean(responsedisciplineId.data) ? responsedisciplineId.data : [],
      currencySelectItems: Boolean(responsecurrency.data) ? responsecurrency.data : [],
      itemGroupIdSelectItems: Boolean(responseitemGroupId.data) ? responseitemGroupId.data : [],
    });
  };

  fillService = async (disciplineId) => {
    if (Boolean(disciplineId) && disciplineId > 0) {
      var responseserviceId = await handleRequest("GET", "/api/" + this.state.controllerName + "/service/" + disciplineId);
      if (responseserviceId.type === "ERROR") {
        error(responseserviceId);
      }
      this.setState({
        serviceIdSelectItems: Boolean(responseserviceId.data) ? responseserviceId.data : [],
      });
    } else {
      this.setState({
        serviceIdSelectItems: [],
      });
    }
  }

  fillCompound = async (serviceId) => {
    if (Boolean(serviceId) && serviceId > 0) {
      var responseCompound = await handleRequest("GET", "/api/" + this.state.controllerName + "/compound/" + serviceId);
      if (responseCompound.type === "ERROR") {
        error(responseCompound);
      }
      this.setState({
        compoundSelectItems: Boolean(responseCompound.data) ? responseCompound.data : [],
      });
    } else {
      this.setState({
        compoundSelectItems: [],
      });
    }
  }

  fillComponent = async (compound) => {
    if (Boolean(compound)) {
      var responseComponent = await handleRequest("GET", "/api/" + this.state.controllerName + "/component/" + compound);
      if (responseComponent.type === "ERROR") {
        error(responseComponent);
      }
      this.setState({
        componentSelectItems: Boolean(responseComponent.data) ? responseComponent.data : [],
      });
    } else {
      this.setState({
        componentSelectItems: [],
      });
    }
  }

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    if (Boolean(this.props.tenderingLogId) && this.props.tenderingLogId > 0) {
      const newItem = {
        ...values,
        id: this.state.id,
        unitId: Boolean(values.unitId) && values.unitId.length > 0 ? values.unitId[values.unitId.length - 1] : null,
        tenderingLogId: this.props.tenderingLogId,
      };

      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
      }

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        showSuccess();
        this.props.close();
      }
    } else {
      showWarning("Bidding Log not found.");
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleFormNewSupplierSubmit = async values => {
    const newItem = {
      ...values,
      supplier: true,
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createVendor", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showNewSupplierModal: false
      });
      showSuccess();
    }
  };

  handleFormNewManufacturerSubmit = async values => {
    const newItem = {
      ...values,
      manufacturer: true,
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createVendor", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showNewManufacturerModal: false
      });
      showSuccess();
      this.fillManufacturer();
    }
  };

  fillSupplier = async () => {
    var responsesupplierId = await handleRequest("GET", "/api/" + this.state.controllerName + "/supplier");
    if (responsesupplierId.type === "ERROR") {
      error(responsesupplierId);
    }
    this.setState({
      supplierIdSelectItems: Boolean(responsesupplierId.data) ? responsesupplierId.data : []
    });
  }

  fillManufacturer = async () => {
    var responsemanufacturerId = await handleRequest("GET", "/api/" + this.state.controllerName + "/manufacturer");
    if (responsemanufacturerId.type === "ERROR") {
      error(responsemanufacturerId);
    }
    this.setState({
      manufacturerIdSelectItems: Boolean(responsemanufacturerId.data) ? responsemanufacturerId.data : []
    });
  }

  fillBrand = async () => {
    const { supplierId, itemId } = this.state;
    if (Boolean(supplierId) && Boolean(itemId)) {

      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/brand/" + supplierId + "/" + itemId);
      if (response.type === "ERROR") {
        error(response);
      }
      this.setState({
        brandIdSelectItems: Boolean(response.data) ? response.data : []
      });
    }
  }

  getPurchaseCriteria = async () => {
    const { itemId } = this.state;
    if (Boolean(itemId)) {
      let responsePurchaseCriteria = await handleRequest("GET", "/api/" + this.state.controllerName + "/purchasingcriterias/" + itemId);
      if (responsePurchaseCriteria.type === "ERROR") {
        error(responsePurchaseCriteria);
      } else {
        generateListUnit(responsePurchaseCriteria.data);
        this.setState({
          purchasingcriteriaSelectItems: Boolean(responsePurchaseCriteria.data) && responsePurchaseCriteria.data.length > 0 ? responsePurchaseCriteria.data : []
        });
      }
    } else {
      this.setState({
        purchasingcriteriaSelectItems: []
      });
    }
  };

  fillLatesPurchases = async () => {
    this.setState({ loadingPurchases: true });
    let { itemId, purchaseDay } = this.state;
    if (!Boolean(purchaseDay)) {
      purchaseDay = -1;
    }

    if (Boolean(itemId)) {
      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillLatesPurchases/" + itemId + "/" + purchaseDay);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          loadingPurchases: false
        });
      } else {
        let latesPurchases = Boolean(response.data) && response.data.length > 0 ? response.data : [];
        this.setState({
          latesPurchases: latesPurchases,
          loadingPurchases: false
        });
        this.formRef.current.setFieldsValue({
          latesPurchases: latesPurchases,
        });
      }
    } else {
      this.setState({
        latesPurchases: null,
        loadingPurchases: false
      });
      this.formRef.current.setFieldsValue({
        latesPurchases: null,
      });
    }
  };

  fillLatesQuotations = async () => {
    this.setState({ loadingQuotation: true });
    let { itemId, quotationDay } = this.state;
    if (!Boolean(quotationDay)) {
      quotationDay = -1;
    }
    if (Boolean(itemId)) {
      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillLatesQuotations/" + itemId + "/" + quotationDay);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          loadingQuotation: false
        });
      } else {
        let latesQuotations = Boolean(response.data) && response.data.length > 0 ? response.data : [];
        this.setState({
          latesQuotations: latesQuotations,
          loadingQuotation: false
        });
        this.formRef.current.setFieldsValue({
          latesQuotations: latesQuotations,
        });
      }
    } else {
      this.setState({
        latesQuotations: null,
        loadingQuotation: false
      });
      this.formRef.current.setFieldsValue({
        latesQuotations: null,
      });
    }
  };

  searchItem = async item => {
    console.log("item", item);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemSearch/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          itemIdSelectItems: [],
          loadingSearch: false
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            itemIdSelectItems: response.data,
            loadingSearch: false
          });
        } else {
          this.setState({
            itemIdSelectItems: [],
            loadingSearch: false
          });
        }
      }
    } else {
      this.setState({
        itemIdSelectItems: [],
        loadingSearch: false
      });
    }
  };

  onChangeItemGroupIdforNameCode = async () => {
    const { type, itemGroupId, materialTypeId, itemDescriptionId } = this.state;
    let obj = {
      type: Boolean(type) ? type : null,
      descriptionId: Boolean(itemDescriptionId) ? itemDescriptionId : null,
      categoryId: Boolean(itemGroupId) ? itemGroupId : null,
      materialTypeId: Boolean(materialTypeId) && materialTypeId.length > 0 ? materialTypeId[materialTypeId.length - 1] : null,
    };

    var responseCodeSelectItems = await handleRequest("POST", "/api/" + this.state.controllerName + "/nameCode", obj);
    if (responseCodeSelectItems.type === "ERROR") {
      error(responseCodeSelectItems);
    } else {
      this.setState({
        itemIdSelectItems: Boolean(responseCodeSelectItems.data) ? responseCodeSelectItems.data : []
      });
    }
  };

  onChangeItemGroupId = async value => {
    if (Boolean(value)) {
      var responseitemDescriptionId = await handleRequest("GET", "/api/" + this.state.controllerName + "/description/" + value);
      if (responseitemDescriptionId.type === "ERROR") {
        error(responseitemDescriptionId);
      } else {
        this.setState({
          itemDescriptionIdSelectItems: Boolean(responseitemDescriptionId.data) ? responseitemDescriptionId.data : []
        });
      }
    } else {
      this.setState({
        itemDescriptionIdSelectItems: []
      });
    }
  };

  fillMaterialType = async () => {
    const { itemGroupId, itemDescriptionId } = this.state;
    if (Boolean(itemGroupId) && itemGroupId > 0 && Boolean(itemDescriptionId) && itemDescriptionId > 0) {
      const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree/" + itemGroupId + "/" + itemDescriptionId);
      if (responseTree.data.length !== 0) {
        generateList(responseTree.data);
        this.setState({
          nodes: responseTree.data
        });
      }
    } else {
      this.setState({
        nodes: []
      });
    }
  };

  saveItem = async values => {
    console.log("Values", values);
    this.setState(
      {
        showItemReachSearchModal: false
      },
      () => {
        this.formRef.current.setFieldsValue({
          itemId: values.code
        });
      }
    );
  };

  getLaborship = async (checked) => {
    const { itemId } = this.state;
    if (Boolean(itemId) && itemId > 0) {
      const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getLaborship/" + itemId);
      if (Boolean(response.data) && response.data > 0) {
        let unitPrice = this.formRef.current.getFieldValue("unitPrice");
        if (checked) {
          unitPrice += response.data;
        } else {
          unitPrice -= response.data;
        }
        this.formRef.current.setFieldsValue({
          unitPrice: unitPrice,
        });
        showSuccess();
      } else {
        showWarning("Laborship not found.");
      }
    } else {
      showWarning("Please select item.");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layoutLeft = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };

    const layoutRight = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 }
    };
    const { selectedRow } = this.state;
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Spin spinning={this.state.loading}>
            <Card
              title={
                <FormattedMessage
                  id="BillOfQuantity.ClientIdentifications"
                  defaultMessage="Client Identifications"
                />
              }
              style={{
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                width: "100%",
                height: "100%",
                fontSize: '11px'
              }}
            >

              {Boolean(this.props) && Boolean(this.props.selectedRow) ?
                <Row gutter={[16, 16]}>
                  <Col span={20}>
                    <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
                      <Col span={6}>
                        <Form.Item
                          name="disciplineName"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} readOnly placeholder="Discipline" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="serviceName"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} readOnly placeholder="Service" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="compound"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} readOnly placeholder="Compound" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="componentName"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} readOnly placeholder="Component" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
                      <Col span={6}>
                        <Form.Item
                          name="serialNumber"
                          {...layoutRight}
                          label={<FormattedMessage id="BillOfQuantity.ExcelLineNo" defaultMessage="Excel Line No" />}
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} placeholder="Excel Line No" readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={18}>
                        <Form.Item
                          name="clientItemDescription"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} readOnly placeholder="Item Description" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={4}>
                    <Avatar shape="square" size={128} src={Boolean(selectedRow) ? selectedRow.imageUrl : null} />
                  </Col>
                </Row>
                :
                <Row gutter={[16, 16]}>
                  <Col span={20}>
                    <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
                      <Col span={6}>
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="BillOfQuantity.Discipline" defaultMessage="Discipline" />}
                          name="disciplineId"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select allowClear showSearch style={{ width: "150px" }} optionFilterProp="children"
                            onChange={(value) => {
                              this.fillService(value);
                            }}
                          >
                            {Boolean(this.state["disciplineIdSelectItems"]) &&
                              this.state["disciplineIdSelectItems"].length > 0 &&
                              this.state["disciplineIdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="BillOfQuantity.Service" defaultMessage="Service" />}
                          name="serviceId"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select allowClear showSearch style={{ width: "150px" }} optionFilterProp="children"
                            onChange={(value) => {
                              this.fillCompound(value);
                            }}>
                            {Boolean(this.state["serviceIdSelectItems"]) &&
                              this.state["serviceIdSelectItems"].length > 0 &&
                              this.state["serviceIdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...layoutRight}
                          label={<FormattedMessage id="BillOfQuantity.Compound" defaultMessage="Compound" />}
                          name="compound"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select allowClear showSearch style={{ width: "150px" }} optionFilterProp="children"
                            onChange={(value) => {
                              this.fillComponent(value);
                            }}>
                            {Boolean(this.state["compoundSelectItems"]) &&
                              this.state["compoundSelectItems"].length > 0 &&
                              this.state["compoundSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...layoutRight}
                          label={<FormattedMessage id="BillOfQuantity.Component" defaultMessage="Component" />}
                          name="component"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select allowClear showSearch style={{ width: "150px" }} optionFilterProp="children" >
                            {Boolean(this.state["componentSelectItems"]) &&
                              this.state["componentSelectItems"].length > 0 &&
                              this.state["componentSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
                      <Col span={6}>
                        <Form.Item
                          name="serialNumber"
                          {...layoutRight}
                          label={<FormattedMessage id="BillOfQuantity.ExcelLineNo" defaultMessage="Excel Line No" />}
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} placeholder="Excel Line No" readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={18}>
                        <Form.Item
                          name="clientItemDescription"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} placeholder="Item Description" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={4}>
                    <Avatar shape="square" size={128} src={Boolean(selectedRow) ? selectedRow.imageUrl : null} />
                  </Col>
                </Row>
              }
            </Card>

            <br /><br />

            <Row gutter={16} style={{ marginBottom: "16px" }}>
              <Col span={8}></Col>

              <Col span={8}>
                <Form.Item
                  {...layoutRight}
                  label={<FormattedMessage id="BillOfQuantity.IncludeLaborship" defaultMessage="Include Laborship" />}
                  name="includeLaborship"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={(e) => {
                      this.getLaborship(e.target.checked);
                    }}
                  ></Checkbox>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  {...layoutRight}
                  label={<FormattedMessage id="BillOfQuantity.IncludeIsolation" defaultMessage="Include Isolation" />}
                  name="includeIsolation"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginBottom: "16px" }}>
              {/* Sol Card */}
              <Col span={8}>
                <h5>
                  {this.state.companyName}
                </h5>
                <Card
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <Form.Item {...layoutLeft} label={<FormattedMessage id="BillOfQuantity.Item" defaultMessage="Item" />}>
                    <Space.Compact style={{ width: "100%" }}>
                      <Form.Item
                        name="itemId"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        style={{ width: "90%" }}
                      >
                        <Select
                          allowClear
                          showSearch
                          onSearch={this.searchItem}
                          style={{ marginBottom: 0, width: "100%" }}
                          loading={this.state.loadingSearch}
                          onChange={value => {
                            this.setState({
                              itemId: value
                            }, () => {
                              this.fillSupplier();
                              this.fillBrand();
                              this.getPurchaseCriteria();
                              this.fillLatesPurchases();
                              this.fillLatesQuotations();
                            });
                          }}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {Boolean(this.state["itemIdSelectItems"]) &&
                            this.state["itemIdSelectItems"].length > 0 &&
                            this.state["itemIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <AntButton
                        type="default"
                        style={{
                          color: "black",
                          background: "white",
                          width: "10%"
                        }}
                        onClick={() => {
                          this.setState({ showItemReachSearchModal: true });
                        }}
                        icon={<FilterList />}
                      ></AntButton>
                    </Space.Compact>
                  </Form.Item>
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="BillOfQuantityAddNewItem.Supplier" defaultMessage="Supplier" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Space.Compact style={{ width: "100%" }}>
                        <Form.Item
                          name="supplierId"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          style={{ width: "90%" }}
                        >
                          <Select allowClear style={{ width: "100%" }} optionFilterProp="children"
                            onChange={value => {
                              this.setState({
                                supplierId: value,
                              }, () => {
                                this.fillBrand();
                              });
                            }}>
                            {Boolean(this.state["supplierIdSelectItems"]) &&
                              this.state["supplierIdSelectItems"].length > 0 &&
                              this.state["supplierIdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>

                        <AntButton
                          type="default"
                          style={{
                            color: "black",
                            background: "white",
                            width: "10%"
                          }}
                          onClick={() => {
                            this.setState({ showNewSupplierModal: true });
                          }}
                          icon={<Add />}
                        ></AntButton>
                      </Space.Compact>
                    </Form.Item>
                  }

                  {/* {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="BillOfQuantityAddNewItem.manufacturerId" defaultMessage="Manufacturer" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Space.Compact style={{ width: "100%" }}>
                        <Form.Item
                          name="manufacturerId"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          style={{ width: "90%" }}
                        >
                          <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                            {Boolean(this.state["manufacturerIdSelectItems"]) &&
                              this.state["manufacturerIdSelectItems"].length > 0 &&
                              this.state["manufacturerIdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>

                        <AntButton
                          type="default"
                          style={{
                            color: "black",
                            background: "white",
                            width: "10%"
                          }}
                          onClick={() => {
                            this.setState({ showNewManufacturerModal: true });
                          }}
                          icon={<Add />}
                        ></AntButton>
                      </Space.Compact>
                    </Form.Item>
                  } */}

                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="BillOfQuantityAddNewItem.Brand" defaultMessage="Brand" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Space.Compact style={{ width: "100%" }}>
                        <Form.Item
                          name="brandId"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          style={{ width: "90%" }}
                        >
                          <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                            {Boolean(this.state["brandIdSelectItems"]) &&
                              this.state["brandIdSelectItems"].length > 0 &&
                              this.state["brandIdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>

                        {/* <AntButton
                          type="default"
                          style={{
                            color: "black",
                            background: "white",
                            width: "10%"
                          }}
                          onClick={() => {
                            this.setState({ showNewBrandModal: true });
                          }}
                          icon={<Add />}
                        ></AntButton> */}
                      </Space.Compact>
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="BillOfQuantityAddNewItem.Quantity" defaultMessage="Quantity" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Row gutter={8}>
                        <Col span={12}>
                          <Form.Item
                            name="clientsQuantity"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <InputNumber style={{ width: '100%' }} min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="unitId"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Cascader showSearch allowClear optionFilterProp="children" style={{ width: '100%' }} options={this.state.purchasingcriteriaSelectItems} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="BillOfQuantityAddNewItem.UnitPrice" defaultMessage="Unit Price" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Row gutter={8}>
                        <Col span={12}>
                          <Form.Item
                            name="unitPrice"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <InputNumber style={{ width: '100%' }} min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="currency"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                              {Boolean(this.state.currencySelectItems) &&
                                this.state.currencySelectItems.length > 0 &&
                                this.state.currencySelectItems.map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  }
                </Card>
              </Col>

              <Col span={8}>
                <h5>
                  <FormattedMessage id="BillOfQuantityAddNewItem.LatestPurchases" defaultMessage="Latest Purchases" />
                </h5>
                <Card
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <Form.Item
                    {...layoutRight}
                    name="latesPurchasesDay"
                    label={<FormattedMessage id="BillOfQuantityAddNewItem.Last" defaultMessage="Last" />}
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <InputNumber
                      onChange={value => {
                        this.setState({
                          purchaseDay: value
                        }, () => {
                          this.fillLatesPurchases();
                        });
                      }}
                      addonAfter="day" min={0} style={{ width: '100%' }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                  </Form.Item>

                  <Spin spinning={this.state.loadingPurchases}>
                    <Form.List name="latesPurchases">
                      {(fields, { add, remove }) => {
                        const handleMoveClick = (fieldName) => {
                          const latesPurchases = this.formRef.current.getFieldValue("latesPurchases");
                          let row = latesPurchases[fieldName];
                          if (Boolean(row) && Boolean(row.unitPrice) && row.unitPrice > 0) {
                            this.formRef.current.setFieldsValue({
                              unitPrice: row.unitPrice,
                            });
                          } else {
                            this.formRef.current.setFieldsValue({
                              unitPrice: 0,
                            });
                          }
                        };
                        return (
                          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            <TableBoot bordered size="sm">
                              <thead>
                                <tr>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                  </th>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"BillOfQuantityAddNewItem.UnitPrice"} defaultMessage={"Unit Price"} />
                                  </th>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"BillOfQuantityAddNewItem.Date"} defaultMessage={"Date"} />
                                  </th>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"BillOfQuantityAddNewItem.Vendor"} defaultMessage={"Vendor"} />
                                  </th>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"BillOfQuantityAddNewItem.Move"} defaultMessage={"Move"} />
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {fields.map(field => {
                                  return (
                                    <tr key={field.key}>
                                      <td>
                                        <Form.Item name={[field.name, "selectedValue"]} valuePropName="checked">
                                          <Checkbox onClick={(e) => {
                                            const latesPurchases = this.formRef.current.getFieldValue("latesPurchases");
                                            let row = latesPurchases[field.name];
                                            row.selectedValue = e.target.checked;

                                            this.setState({
                                              latesPurchases: latesPurchases,
                                            });
                                            this.formRef.current.setFieldsValue({
                                              latesPurchases: latesPurchases,
                                            });

                                            let total = 0;
                                            let count = 0;
                                            latesPurchases.forEach(element => {
                                              if (element.selectedValue) {
                                                total += parseFloat(element.unitPrice);
                                                count++;
                                              }
                                            });

                                            console.log("latesPurchases", latesPurchases);
                                            if (Boolean(total) && total > 0 && Boolean(count) && count > 0) {
                                              this.formRef.current.setFieldsValue({
                                                latesPurchasesAverage: parseFloat((total / count).toFixed(2)),
                                              });
                                            } else {
                                              this.formRef.current.setFieldsValue({
                                                latesPurchasesAverage: 0,
                                              });
                                            }
                                          }} />
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "unitPrice"]}>
                                          <Input readOnly />
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "date"]}>
                                          <Input readOnly />
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "vendorName"]}>
                                          <Input readOnly />
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <DragOutlined onClick={() => {
                                          handleMoveClick(field.name);
                                        }} />
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </TableBoot>
                          </div>
                        );
                      }}
                    </Form.List>
                  </Spin>

                  {Boolean(this.state.latesPurchases) && this.state.latesPurchases.length > 1 &&
                    <Form.Item
                      {...layoutRight}
                      name="latesPurchasesAverage"
                      label={<FormattedMessage id="BillOfQuantityAddNewItem.Average" defaultMessage="Average" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      style={{ marginTop: '30px' }}
                    >
                      <InputNumber readOnly addonAfter={
                        <Tooltip title="Move">
                          <DragOutlined onClick={() => {
                            const latesPurchasesAverage = this.formRef.current.getFieldValue("latesPurchasesAverage");
                            if (Boolean(latesPurchasesAverage) && latesPurchasesAverage > 0) {
                              this.formRef.current.setFieldsValue({
                                unitPrice: latesPurchasesAverage,
                              });
                            } else {
                              this.formRef.current.setFieldsValue({
                                unitPrice: 0,
                              });
                            }
                          }} />
                        </Tooltip>
                      } placeholder="unit price"
                        min={0} style={{ width: '100%' }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                    </Form.Item>}
                </Card>
              </Col>

              <Col span={8}>
                <h5>
                  <FormattedMessage id="BillOfQuantityAddNewItem.LatestQuotations" defaultMessage="Latest Quotations" />
                </h5>
                <Card
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <Form.Item
                    {...layoutRight}
                    name="latesQuotationsDay"
                    label={<FormattedMessage id="BillOfQuantityAddNewItem.Last" defaultMessage="Last" />}
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <InputNumber
                      onChange={value => {
                        this.setState({
                          quotationDay: value
                        }, () => {
                          this.fillLatesQuotations();
                        });
                      }}
                      addonAfter="day" min={0} style={{ width: '100%' }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                  </Form.Item>

                  <Spin spinning={this.state.loadingQuotation}>
                    <Form.List name="latesQuotations">
                      {(fields, { add, remove }) => {
                        const handleMoveClick = (fieldName) => {
                          const latesQuotations = this.formRef.current.getFieldValue("latesQuotations");
                          let row = latesQuotations[fieldName];
                          if (Boolean(row) && Boolean(row.unitPrice) && row.unitPrice > 0) {
                            this.formRef.current.setFieldsValue({
                              unitPrice: row.unitPrice,
                            });
                          } else {
                            this.formRef.current.setFieldsValue({
                              unitPrice: 0,
                            });
                          }
                        };

                        return (
                          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            <TableBoot bordered size="sm">
                              <thead>
                                <tr>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"BillOfQuantityAddNewItem.UnitPrice"} defaultMessage={"Unit Price"} />
                                  </th>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"BillOfQuantityAddNewItem.Date"} defaultMessage={"Date"} />
                                  </th>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"BillOfQuantityAddNewItem.Project"} defaultMessage={"Project"} />
                                  </th>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"BillOfQuantityAddNewItem.Deviation"} defaultMessage={"Deviation"} />
                                  </th>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"BillOfQuantityAddNewItem.Move"} defaultMessage={"Move"} />
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {fields.map(field => {
                                  return (
                                    <tr key={field.key}>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "unitPrice"]}>
                                          <Input readOnly />
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "date"]}>
                                          <Input readOnly />
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "projectName"]}>
                                          <Input readOnly />
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "deviation"]}>
                                          <Input readOnly />
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <DragOutlined onClick={() => {
                                          handleMoveClick(field.name);
                                        }} />
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </TableBoot>
                          </div>
                        );
                      }}
                    </Form.List>
                  </Spin>
                </Card>
              </Col>
            </Row>
          </Spin>
          <br />
          <br />
          <div hidden={this.state.hideSave}>
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={20} lg={20} xl={20} xxl={20}></Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Col>
              </Row>
            }
          </div>
          <div hidden={this.state.hideUpdate}>
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={20} lg={20} xl={20} xxl={20}></Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                  </Button>
                </Col>
              </Row>
            }
          </div>
        </Form>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Drawer
          title={<FormattedMessage id="BillOfQuantityAddNewItem.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"BillOfQuantityAddNewItem"} />
        </Drawer>

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantityAddNewItem.NewSupplier" defaultMessage="New Supplier" />}
          centered
          width={600}
          open={this.state.showNewSupplierModal}
          onOk={() => this.formRefNewSupplier.current.submit()}
          onCancel={() => {
            this.setState({ showNewSupplierModal: false });
          }}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Form id="FormVendor" initialValues={{ remember: false }} onFinish={this.handleFormNewSupplierSubmit} ref={this.formRefNewSupplier}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Vendor.name" defaultMessage="Name" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="VendorContact.address" defaultMessage="Address" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="VendorContact.emails" defaultMessage="Email" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="emails"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </div>
          }
        ></DraggableModal>


        <DraggableModal
          title={<FormattedMessage id="BillOfQuantityAddNewItem.NewManufacturer" defaultMessage="New Manufacturer" />}
          centered
          width={600}
          maskClosable={false}
          open={this.state.showNewManufacturerModal}
          onOk={() => this.formRefNewManufacturer.current.submit()}
          onCancel={() => {
            this.setState({ showNewManufacturerModal: false });
          }}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Form id="FormVendor" initialValues={{ remember: false }} onFinish={this.handleFormNewManufacturerSubmit} ref={this.formRefNewManufacturer}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Vendor.name" defaultMessage="Name" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="VendorContact.address" defaultMessage="Address" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="VendorContact.emails" defaultMessage="Email" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="emails"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </div>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantityAddNewItem.NewBrand" defaultMessage="New Brand" />}
          centered
          width={600}
          maskClosable={false}
          open={this.state.showNewBrandModal}
          onOk={() => this.formRefNewBrand.current.submit()}
          onCancel={() => {
            this.setState({ showNewBrandModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveNewBrand} onFinishFailed={onFinishFailed} ref={this.formRefNewBrand}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantityAddNewItem.NewSupplier" defaultMessage="New Brand" />}
                    name="newBrand"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantity.Item" defaultMessage="Item" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showItemReachSearchModal}
          onOk={() => this.formRefItem.current.submit()}
          onCancel={() => {
            this.setState({ showItemReachSearchModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveItem} onFinishFailed={onFinishFailed} ref={this.formRefItem}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProjectTakeOff.type" defaultMessage="Type" />}
                    name="type"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Radio.Group onChange={e => {
                      this.setState({
                        type: e.target.value
                      });
                    }}>
                      <Radio value={"EQUIPMENT"}>{<FormattedMessage id="ProjectTakeOff.Equipment" defaultMessage="Equipment" />}</Radio>
                      <Radio value={"MATERIAL"}>{<FormattedMessage id="ProjectTakeOff.Material" defaultMessage="Material" />}</Radio>
                    </Radio.Group>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.itemGroupId" defaultMessage="Item Group" />}
                    name="itemGroupId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                      onChange={value => {
                        this.onChangeItemGroupId(value);
                        this.setState({ itemGroupId: value }, () => {
                          this.onChangeItemGroupIdforNameCode();
                          this.fillMaterialType();
                        });
                      }}
                    >
                      {Boolean(this.state["itemGroupIdSelectItems"]) &&
                        this.state["itemGroupIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.itemDescriptionId" defaultMessage="Item Description" />}
                    name="itemDescriptionId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      showSearch
                      onChange={value => {
                        this.setState({ itemDescriptionId: value }, () => {
                          this.onChangeItemGroupIdforNameCode();
                          this.fillMaterialType();
                        });
                      }}
                      allowClear
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                    >
                      {Boolean(this.state["itemDescriptionIdSelectItems"]) &&
                        this.state["itemDescriptionIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.materialTypeId" defaultMessage="Material Type" />}
                    name="materialTypeId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Cascader
                      onChange={value => {
                        this.setState({ materialTypeId: value }, () => {
                          this.onChangeItemGroupIdforNameCode();
                        });
                      }}
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                      options={this.state.nodes}
                    />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.NameCode" defaultMessage="Name/Code" />}
                    name="code"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                      {Boolean(this.state["itemIdSelectItems"]) &&
                        this.state["itemIdSelectItems"].length > 0 &&
                        this.state["itemIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
              </Form>
            </>
          }
        />
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BillOfQuantityAddNewItem);