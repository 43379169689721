import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined, SaveOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import { Form, Table, Input, Select, Switch, Button as AntButton, Col, Row, Tag, Space } from "antd";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import VendorSelectionsubTable from "./VendorSelectionSubTable";
import XMLParser from "react-xml-parser";
import { evaluate, round } from "mathjs";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import DraggableModal from "../../Components/DraggableModal";

const { Option } = Select;

function getUnitPriceOrderIndex(list, productNameCode, vendorId) {
  let productList = [...list].filter(p => p.productNameCode === productNameCode);
  productList.sort((a, b) => a.unitPrice - b.unitPrice);
  let orderIndex = 1;
  for (let i = 0; i < productList.length; i++) {
    if (i === 0) {
      productList[i].priceOrder = orderIndex;
    } else {
      if (productList[i].unitPrice === productList[i - 1].unitPrice) {
        productList[i].priceOrder = orderIndex;
      } else {
        orderIndex++;
        productList[i].priceOrder = orderIndex;
      }
    }
  }
  let result = productList.find(p => p.vendorId === vendorId);
  return result.priceOrder;
}

function getPlannedDeliveryDateOrderIndex(list, productNameCode, vendorId) {
  let productList = [...list].filter(p => p.productNameCode === productNameCode);
  productList.sort((a, b) => moment(a.planedDeliveryDate) - moment(b.planedDeliveryDate));
  let orderIndex = 1;
  for (let i = 0; i < productList.length; i++) {
    if (i === 0) {
      productList[i].pddOrder = orderIndex;
    } else {
      if (moment(productList[i].planedDeliveryDate) === moment(productList[i - 1].planedDeliveryDate)) {
        productList[i].pddOrder = orderIndex;
      } else {
        orderIndex++;
        productList[i].pddOrder = orderIndex;
      }
    }
  }
  let result = productList.find(p => p.vendorId === vendorId);
  return result.pddOrder;
}

function getAverageOrderIndex(list) {
  list.sort((a, b) => a.average - b.average);
  let orderIndex = 1;
  for (let i = 0; i < list.length; i++) {
    if (i === 0) {
      list[i].averageOrder = orderIndex;
    } else {
      if (list[i].average === list[i - 1].average) {
        list[i].averageOrder = orderIndex;
      } else {
        orderIndex++;
        list[i].averageOrder = orderIndex;
      }
    }
  }
}

function getAverageWithShippingOrderIndex(list) {
  list.sort((a, b) => a.averageWithShipping - b.averageWithShipping);
  let orderIndex = 1;
  for (let i = 0; i < list.length; i++) {
    if (i === 0) {
      list[i].averageWithShippingOrder = orderIndex;
    } else {
      if (list[i].averageWithShipping === list[i - 1].averageWithShipping) {
        list[i].averageWithShippingOrder = orderIndex;
      } else {
        orderIndex++;
        list[i].averageWithShippingOrder = orderIndex;
      }
    }
  }
}

function setOrderByGivenProperty(tableList, property, productId) {
  tableList.forEach(element => {
    if (Boolean(element.costDetail)) {
      let fObj = element.costDetail.find(p => p.projectTakeOffQuantityId === productId);
      if (Boolean(fObj)) {
        element.tableOrderIndex = fObj[property];
      } else {
        element.tableOrderIndex = Math.max();
      }
    } else element.tableOrderIndex = Math.max();
  });

  tableList.sort((a, b) => a.tableOrderIndex - b.tableOrderIndex);
}

function createOperator(operator) {
  console.log(operator);
  switch (operator) {
    case "add":
      return "+";
    case "subtract":
      return "-";
    case "multiply":
      return "*";
    case "divide":
      return "/";
    case "sqrt":
      return "sqrt";
    case "pow":
      return "^";
    case "open_parenthesis":
      return "(";
    case "close_parenthesis":
      return ")";
    default:
      return "";
  }
}

export class VendorSelectionOnTotal extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "vendorScoringOnTotalQuotation",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      inputTypeIdSelectItems: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      rfqId: null,
      excelData: [],
      selectedRowKeys: [],
      selectedVendorExpectedPricesKeys: [],
      showAllTableList: true,
      paymentMethodSelectItems: [],
      currencySelectItems: [],
      currency: null,
      requestedQuantityUnit: null,
      quantityUnitSelectItems: [
        { key: "m", value: "m" },
        { key: "kg", value: "kg" }
      ],
      productId: null,
      productSelectItems: [],
      showColumn: "all",
      showColumnSelectItems: [
        {
          title: "All",
          key: "all"
        },
        {
          title: "Deadline",
          key: "deadline"
        },
        {
          title: "Unit Price",
          key: "unitPrice"
        },
        {
          key: "ShippingPrice",
          title: "Shipping Price(Kg)"
        },
        { key: "total", title: "Total" },
        { key: "totalWithShipping", title: "Total With Shipping" },
        { key: "average", title: "Average" },
        { key: "averageWithShipping", title: "Average With Shippning" }
      ],
      paymentMethod: null,
      otherRfqNoList: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = (prevProps) => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var currencyResponse = await handleRequest("GET", "/api/vendorScoringOnTotalQuotation/currency");
    if (currencyResponse.type === "ERROR") {
      error(currencyResponse);
    }

    var paymentMethodResponse = await handleRequest("GET", "/api/vendorScoringOnTotalQuotation/paymentMethod/" + this.props.requestForQuotation.id);
    if (paymentMethodResponse.type === "ERROR") {
      error(paymentMethodResponse);
    }

    var responseKur = await handleRequest("GET", "/api/vendorScoringOnTotalQuotation/kur");
    if (responseKur.type === "ERROR") {
      error(responseKur);
    }

    var kur = null;
    try {
      kur = new XMLParser().parseFromString(responseKur.data);
    } catch (error) { }

    this.setState({
      kur: kur,
      rfqId: this.props.requestForQuotation.id,
      paymentMethodSelectItems: Boolean(paymentMethodResponse.data) && paymentMethodResponse.data.length > 0 ? paymentMethodResponse.data : [],
      paymentMethod: null,
      currencySelectItems: Boolean(currencyResponse.data) ? currencyResponse.data : []
    }, () => {
      this.loadRfqVendorExpectedPricesByRfqId();
    });
  };

  getKurValue() {
    let dollarKur = 0,
      euroKur = 0,
      poundKur = 0,
      srKur = 0;
    if (Boolean(this.state.kur) && Boolean(this.state.kur.children) && this.state.kur.children.length > 0) {
      this.state.kur.children.forEach(element => {
        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "USD") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            dollarKur = obj.value;
          }
        }

        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "EUR") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            euroKur = obj.value;
          }
        }

        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "GBP") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            poundKur = obj.value;
          }
        }

        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "SAR") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            srKur = obj.value;
          }
        }
      });
    }
    return { dollarKur, euroKur, poundKur, srKur };
  }

  getPriceWtihKur(currency, price) {
    let { dollarKur, euroKur, poundKur, srKur } = this.getKurValue();
    let priceSubTotal = 0;
    if (currency === "DOLLAR") {
      if (this.state.currency === "DOLLAR") {
        priceSubTotal += price;
      } else if (this.state.currency === "EURO") {
        priceSubTotal += price * (dollarKur / euroKur);
      } else if (this.state.currency === "POUND") {
        priceSubTotal += price * (dollarKur / poundKur);
      } else if (this.state.currency === "SR") {
        priceSubTotal += price * (dollarKur / srKur);
      } else if (this.state.currency === "TL") {
        priceSubTotal += price * dollarKur;
      }
    } else if (currency === "EURO") {
      if (this.state.currency === "EURO") {
        priceSubTotal += price;
      } else if (this.state.currency === "DOLLAR") {
        priceSubTotal += price * (euroKur / dollarKur);
      } else if (this.state.currency === "POUND") {
        priceSubTotal += price * (euroKur / poundKur);
      } else if (this.state.currency === "SR") {
        priceSubTotal += price * (euroKur / srKur);
      } else if (this.state.currency === "TL") {
        priceSubTotal += price * euroKur;
      }
    } else if (currency === "POUND") {
      if (this.state.currency === "POUND") {
        priceSubTotal += price;
      } else if (this.state.currency === "EURO") {
        priceSubTotal += price * (poundKur / euroKur);
      } else if (this.state.currency === "DOLLAR") {
        priceSubTotal += price * (poundKur / dollarKur);
      } else if (this.state.currency === "SR") {
        priceSubTotal += price * (poundKur / srKur);
      } else if (this.state.currency === "TL") {
        priceSubTotal += price * poundKur;
      }
    } else if (currency === "SR") {
      if (this.state.currency === "SR") {
        priceSubTotal += price;
      } else if (this.state.currency === "POUND") {
        priceSubTotal += price * (srKur / poundKur);
      } else if (this.state.currency === "EURO") {
        priceSubTotal += price * (srKur / euroKur);
      } else if (this.state.currency === "DOLLAR") {
        priceSubTotal += price * (srKur / dollarKur);
      } else if (this.state.currency === "TL") {
        priceSubTotal += price * srKur;
      }
    } else if (currency === "TL") {
      if (this.state.currency === "TL") {
        priceSubTotal += price;
      } else if (this.state.currency === "SR") {
        priceSubTotal += price * (1 / srKur);
      } else if (this.state.currency === "POUND") {
        priceSubTotal += price * (1 / poundKur);
      } else if (this.state.currency === "EURO") {
        priceSubTotal += price * (1 / euroKur);
      } else if (this.state.currency === "DOLLAR") {
        priceSubTotal += price * (1 / dollarKur);
      }
    } else {
      return 0;
    }
    return priceSubTotal;
  }

  loadRfqVendorExpectedPricesByRfqId = async () => {
    var { rfqId, currency, productId, paymentMethod } = this.state;
    if (!Boolean(paymentMethod)) {
      paymentMethod = -1;
    }

    if (Boolean(rfqId)) {
      var response = await handleRequest("GET", "/api/vendorScoringOnTotalQuotation/vendorprices/" + rfqId + "/" + paymentMethod);
      if (response.type === "ERROR") {
        error(response);
      } else {
        let tableList = [];
        let productSelectItems = [];
        let data = response.data;
        for (let i = 0; i < data.length; i++) {
          if (tableList.findIndex(x => x.vendorId === data[i].vendorId) === -1) {
            let obj = { key: data[i].vendorId, vendorId: data[i].vendorId, vendorName: data[i].vendorName, total: 0, rank: 0, costDetail: [] };
            let vendorPrices = data.filter(x => x.vendorId === data[i].vendorId);
            let totalObj = { productNameCode: "Total", quantity: 0.0, total: 0.0, totalWithShipping: 0.0, isTotalRow: true };
            vendorPrices.forEach(element => {
              if (Boolean(productId)) {
                if (productId === element.projectTakeOffQuantityId) {
                  if (Boolean(currency)) this.customiseElementWithCurrency(element, obj, totalObj, productSelectItems, data, i, currency);
                  else this.customiseElement(element, obj, totalObj, productSelectItems, data, i);
                }
              } else {
                if (Boolean(currency)) this.customiseElementWithCurrency(element, obj, totalObj, productSelectItems, data, i, currency);
                else this.customiseElement(element, obj, totalObj, productSelectItems, data, i);
              }
            });
            totalObj.quantity = round(totalObj.quantity, 2);
            totalObj.total = round(totalObj.total, 2);
            totalObj.totalWithShipping = round(totalObj.totalWithShipping, 2);
            obj.costDetail.push(totalObj);
            obj.average = round(totalObj.total / totalObj.quantity, 2);
            obj.averageWithShipping = round(totalObj.totalWithShipping / totalObj.quantity, 2);
            tableList.push(obj);
          }
        }
        getAverageOrderIndex(tableList);
        getAverageWithShippingOrderIndex(tableList);
        this.setState({ tableList });
        this.setState({ productSelectItems });
        this.getSelectedKeysFromDB(tableList, rfqId);
      }
    } else {
      error("Please select RFQ");
      this.setState({
        tableList: [],
        selectedRowKeys: []
      });
    }
  };

  customiseElement = (element, obj, totalObj, productSelectItems, data, i) => {
    element.unitPrice = Boolean(element.unitPrice) ? element.unitPrice : 0;
    element.quantity = Boolean(element.quantity) ? element.quantity : 0;
    element.shippingPrice = Boolean(element.shippingPrice) ? element.shippingPrice : 0;
    let cd = {
      key: element.id,
      id: element.id, //rfqVendorExpectedPriceId
      isTotalRow: false,
      selected: false,
      weightFeature: element.weightFeature,
      quantityUnit: element.quantityUnit,
      quantityUnitType: element.quantityUnitType,
      stockcardCodeDimensions: element.stockcardCodeDimensions,
      residueOfDimensions: element.residueOfDimensions,
      projectTakeOffQuantityId: element.projectTakeOffQuantityId,
      productNameCode: element.productNameCode,
      unitPrice: element.unitPrice,
      unitPriceOrderIndex: getUnitPriceOrderIndex(data, element.productNameCode, data[i].vendorId),
      quantity: element.quantity,
      planedDeliveryDate: element.planedDeliveryDate,
      planedDeliveryDateOrderIndex: getPlannedDeliveryDateOrderIndex(data, element.productNameCode, data[i].vendorId),
      shippingPrice: element.shippingPrice,
      total: round(element.unitPrice * element.quantity, 2),
      totalWithShipping: round((element.unitPrice + element.shippingPrice) * element.quantity, 2),
      currency: Boolean(element.currency) ? element.currency : "TL",
      city: element.city
    };
    this.checkUnit(cd);
    obj.costDetail.push(cd);
    this.ifNotInListAddToProductSelectItems(cd, productSelectItems);
    totalObj.quantity += cd.quantity;
    totalObj.total += cd.total;
    totalObj.totalWithShipping += cd.totalWithShipping;
  };

  customiseElementWithCurrency = (element, obj, totalObj, productSelectItems, data, i, currency) => {
    element.unitPrice = Boolean(element.unitPrice) ? element.unitPrice : 0;
    element.quantity = Boolean(element.quantity) ? element.quantity : 0;
    element.shippingPrice = Boolean(element.shippingPrice) ? element.shippingPrice : 0;
    let up = round(this.getPriceWtihKur(Boolean(element.currency) ? element.currency : "TL", element.unitPrice), 2);
    let sp = round(this.getPriceWtihKur(Boolean(element.currency) ? element.currency : "TL", element.shippingPrice), 2);
    let cd = {
      key: element.id,
      id: element.id, //rfqVendorExpectedPriceId
      isTotalRow: false,
      selected: false,
      weightFeature: element.weightFeature,
      quantityUnit: element.quantityUnit,
      quantityUnitType: element.quantityUnitType,
      stockcardCodeDimensions: element.stockcardCodeDimensions,
      residueOfDimensions: element.residueOfDimensions,
      projectTakeOffQuantityId: element.projectTakeOffQuantityId,
      productNameCode: element.productNameCode,
      unitPrice: up,
      unitPriceOrderIndex: getUnitPriceOrderIndex(data, element.productNameCode, data[i].vendorId),
      quantity: element.quantity,
      planedDeliveryDate: element.planedDeliveryDate,
      planedDeliveryDateOrderIndex: getPlannedDeliveryDateOrderIndex(data, element.productNameCode, data[i].vendorId),
      shippingPrice: sp,
      total: round(up * element.quantity, 2),
      totalWithShipping: round((up + sp) * element.quantity, 2),
      currency: currency,
      city: element.city
    };
    this.checkUnit(cd);
    obj.costDetail.push(cd);
    this.ifNotInListAddToProductSelectItems(cd, productSelectItems);
    totalObj.quantity += cd.quantity;
    totalObj.total += cd.total;
    totalObj.totalWithShipping += cd.totalWithShipping;
  };

  checkUnit = cd => {
    const { requestedQuantityUnit } = this.state;
    let convertableUnitTypes = ["length", "weight", "quantity"];
    console.log("if den önce", { ...cd });
    if (
      Boolean(requestedQuantityUnit) &&
      Boolean(cd.quantityUnit) &&
      Boolean(cd.quantityUnitType) &&
      convertableUnitTypes.includes(cd.quantityUnitType.toLowerCase())
    ) {
      console.log("Girdi", requestedQuantityUnit, { ...cd });
      console.log("Weight", this.calculateWeight(cd));
      let weight = Boolean(this.calculateWeight(cd)) ? this.calculateWeight(cd) : 1;

      switch (requestedQuantityUnit) {
        case "kg":
          cd.quantityUnitAbbreviation = "kg";
          switch (cd.quantityUnitType.toLowerCase()) {
            case "length":
              cd.newQuantity = weight * (cd.quantity * cd.quantityUnit.coefficent);
              cd.unitPrice = round((cd.quantity * cd.unitPrice) / cd.newQuantity, 2);
              break;
            case "quantity":
              cd.newQuantity = weight * cd.quantity;
              cd.unitPrice = round((cd.quantity * cd.unitPrice) / cd.newQuantity, 2);
              break;
            case "weight":
              cd.newQuantity = cd.quantity * cd.quantityUnit.coefficent;
              cd.unitPrice = round((cd.quantity * cd.unitPrice) / cd.newQuantity, 2);
              break;

            default:
              break;
          }

          cd.quantity = cd.newQuantity;
          break;

        case "m":
          cd.quantityUnitAbbreviation = "m";
          switch (cd.quantityUnitType.toLowerCase()) {
            case "length":
              cd.newQuantity = cd.quantity * cd.quantityUnit.coefficent;
              cd.unitPrice = round((cd.quantity * cd.unitPrice) / cd.newQuantity, 2);
              break;
            case "quantity":
              cd.newQuantity = cd.quantity;
              cd.unitPrice = round((cd.quantity * cd.unitPrice) / cd.newQuantity, 2);
              break;
            case "weight":
              cd.newQuantity = round((cd.quantity * cd.quantityUnit.coefficent) / weight, 6);
              cd.unitPrice = round((cd.quantity * cd.unitPrice) / cd.newQuantity, 2);
              break;

            default:
              break;
          }

          cd.quantity = cd.newQuantity;
          break;

        default:
          break;
      }

      console.log("Çıkti", requestedQuantityUnit, { ...cd });
    }
  };

  calculateWeight = cd => {
    let weightFeature = cd.weightFeature;
    if (weightFeature.itemFeatureFormula && weightFeature.itemFeatureFormula.length > 0) {
      let formulaText = "";
      weightFeature.itemFeatureFormula.forEach((element, index) => {
        let constant = Boolean(element.constant)
          ? element.constant
          : Boolean(element.dimensionAbbreviation)
            ? this.getDimensionValue(element.dimension, element.dimensionId, cd)
            : Boolean(element.numberConstant)
              ? element.numberConstant
              : "";
        let operator = Boolean(element.operator) ? createOperator(element.operator) : "";
        formulaText += constant + operator;
      });

      console.log("Formül", formulaText);

      if (!formulaText.includes("DIMENSION_NOT_FOUND")) {
        let evaluatedValue = evaluate(formulaText);
        let result = round(evaluatedValue, 2);

        return result;
      }

      return null;
    }
  };

  getDimensionValue = (dimension, dimensionId, cd) => {
    let dimensionValue = "DIMENSION_NOT_FOUND";
    let stockcardCodeDimensions = cd.stockcardCodeDimensions;
    let rdo = cd.residueOfDimensions;

    if (Boolean(rdo) && rdo.length > 0) {
      rdo.forEach(element => {
        if (element.itemDimensionId === dimensionId) {
          if (Boolean(element.value)) {
            dimensionValue = element.value;
          }
        }
      });
    }

    if (dimensionValue === "DIMENSION_NOT_FOUND" && stockcardCodeDimensions.length > 0) {
      stockcardCodeDimensions.forEach(element => {
        if (element.itemDefinition === dimension) {
          dimensionValue = Boolean(Number(element.value)) ? Number(element.value) : "DIMENSION_NOT_FOUND";
        }
      });
    }

    return dimensionValue;
  };

  ifNotInListAddToProductSelectItems = (cd, productSelectItems) => {
    if (productSelectItems.findIndex(p => p.key === cd.projectTakeOffQuantityId) === -1) {
      productSelectItems.push({ key: cd.projectTakeOffQuantityId, value: cd.productNameCode });
    }
  };

  getSelectedKeysFromDB = async (tableList, rfqId) => {
    var response = await handleRequest("GET", "/api/vendorScoringOnTotalQuotation/selectedvendors/" + rfqId);
    if (response.type === "ERROR") {
      error(response);
    } else {
      let selectedRowKeys = [];
      let data = response.data;
      if (Boolean(data) && data.length > 0) {
        data.forEach(element => {
          if (selectedRowKeys.findIndex(p => p === element.vendorId) === -1) {
            selectedRowKeys.push(element.vendorId);
            let row = tableList.find(p => p.vendorId === element.vendorId);
            let costDetail = Boolean(row) ? row.costDetail : [];
            let responseCostDetail = data.filter(p => p.vendorId === element.vendorId);

            responseCostDetail.forEach(element => {
              let f = costDetail.findIndex(c => c.id === element.rfqVendorExpectedPriceId);
              if (f !== -1) costDetail[f].selected = element.selected;
            });
          }
        });
      } else {
        tableList.forEach(element => {
          if (selectedRowKeys.findIndex(p => p === element.vendorId) === -1) {
            selectedRowKeys.push(element.vendorId);
          }
          if (element.costDetail)
            element.costDetail.forEach(cd => {
              cd.selected = true;
            });
        });
      }
      this.setState({ selectedRowKeys }, this.setShowingTableList);
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  handleTableChange = (pagination, filters, sorter) => { };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  updatePriceSelect = async (vendorId, costDetail) => {
    const { tableList } = this.state;

    let item = tableList.find(p => p.vendorId === vendorId);
    item.costDetail = costDetail;
    this.setState({ tableList }, this.setShowingTableList);
  };

  setShowingTableList = () => {
    const { showAllTableList, tableList, selectedRowKeys } = this.state;

    if (showAllTableList) {
      console.log("showingTableList", [...tableList]);
      this.setState({
        showingTableList: [...tableList]
      });
    } else {
      if (Boolean(selectedRowKeys) && selectedRowKeys.length > 0) {
        let temp = tableList.slice();
        let fTableList = temp.filter(p => selectedRowKeys.includes(p.vendorId));
        // fTableList.forEach(element => {
        //   element.costDetail =
        //     Boolean(element.costDetail) && element.costDetail.length > 1 ? element.costDetail.slice().filter(p => p.selected && !p.isTotalRow) : [];
        //   let totalObj = this.getTotalObj(element.costDetail);
        //   if (Boolean(totalObj)) element.costDetail.push(totalObj);
        // });

        console.log("showingTableList", fTableList);
        this.setState({
          showingTableList: fTableList
        });
      } else {
        this.setState({
          showingTableList: []
        });
      }
    }
  };

  getTotalObj = costDetail => {
    if (Boolean(costDetail) && costDetail.length > 0) {
      let totalObj = { productNameCode: "Total", quantity: 0, total: 0, totalWithShipping: 0, isTotalRow: true };

      costDetail.forEach(cd => {
        totalObj.quantity += cd.quantity;
        totalObj.total += cd.total;
        totalObj.totalWithShipping += cd.totalWithShipping;
      });
      return totalObj;
    } else return null;
  };

  renderCostDetail = (vendorId, costDetail) => {
    // const { tableList } = this.state;
    // let costDetail = tableList.find(p => p.vendorId === vendorId).costDetail;
    console.log("SSS", costDetail);
    return (
      costDetail && (
        <VendorSelectionsubTable
          currency={this.state.currency}
          showColumn={this.state.showColumn}
          vendorId={vendorId}
          data={costDetail}
          onUpdatePriceSelect={this.updatePriceSelect.bind(this)}
        />
      )
    );
  };

  render2Tags = (value, orderIndex) => {
    return (
      <table className="table table-bordered table-sm">
        {/* <thead>
          <tr>           
            <th>Value</th>
            <th>Order</th>
          </tr>
        </thead> */}
        <tbody>
          <tr key={0}>
            <td>
              {" "}
              <Tag color="green">{value}</Tag>{" "}
            </td>
            <td>
              {" "}
              <Tag color="red">{orderIndex}</Tag>{" "}
            </td>
            {/* <td className="text-nowrap">{item.periodValue + " " + item.periodUnit}</td> */}
          </tr>
        </tbody>
      </table>
    );
  };

  checkEquivalent = async () => {
    const { rfqId } = this.state;
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/checkEquivalent/" + rfqId);
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        let otherRfqNoList = [];
        response.data.forEach(element => {
          otherRfqNoList.push(element.rfqNo);
        });
        this.setState({
          otherRfqList: response.data,
          otherRfqNoList: otherRfqNoList,
          modalcheckEquivalentShow: true
        });
      } else {
        this.setState({
          otherRfqNoList: [],
        }, () => {
          this.save(false);
        });
      }
    }
  }

  save = (equivelantItemDelete) => {
    const { tableList, rfqId, selectedRowKeys, otherRfqList } = this.state;

    let list = [];
    if (Boolean(selectedRowKeys) && selectedRowKeys.length > 0) {
      let control = true;

      for (let i = 0; i < selectedRowKeys.length; i++) {
        const element = selectedRowKeys[i];
        let fObj = [...tableList].find(x => x.key === element);

        if (Boolean(fObj.costDetail) && fObj.costDetail.length > 0) {
          for (let index = 0; index < fObj.costDetail.length; index++) {
            const cd = fObj.costDetail[index];

            if (!cd.isTotalRow) {
              const obj = {
                vendorId: fObj.key,
                rfqVendorExpectedPriceId: cd.id,
                requestForQuotationId: rfqId,
                projectTakeOffQuantityId: cd.projectTakeOffQuantityId,
                selected: cd.selected,
                averagePrice: fObj.average,
                averagePriceWithShipping: fObj.averageWithShipping
              };

              if (
                cd.selected &&
                list.findIndex(p => p.vendorId !== obj.vendorId && p.projectTakeOffQuantityId === obj.projectTakeOffQuantityId && p.selected) !== -1
              ) {
                control = false;
              }
              list.push(obj);
            }
          }
        }
      }
      if (control && list.length > 0) {
        let otherRfqIds = [];
        if (Boolean(otherRfqList) && otherRfqList.length > 0 && equivelantItemDelete) {
          otherRfqList.forEach(element => {
            otherRfqIds.push(element.id);
          });
        } else {
          otherRfqIds.push(-1);
        }

        let response = handleRequest("POST", "/api/vendorScoringOnTotalQuotation/setSelectedKeys/" + otherRfqIds, list);
        if (response.type === "ERROR") {
          error(response);
        } else {
          this.setState({
            modalcheckEquivalentShow: false
          });
          showSuccess();
          if (Boolean(this.props.closeVendorEvaluation)) {
            this.props.closeVendorEvaluation();
          }
        }
      } else {
        if (list.length > 0) showError("There is same products that selected for two or more vendor!");
        else showError("Please select at least one vendor and product !");
      }
    } else {
      let response = handleRequest("DELETE", "/api/vendorScoringOnTotalQuotation/setSelectedKeys/" + rfqId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          modalcheckEquivalentShow: false
        });
        showSuccess();
        if (Boolean(this.props.closeVendorEvaluation)) {
          this.props.closeVendorEvaluation();
        }
      }
    }
  };

  onSelectChange = selectedRowKeys => {
    console.log("XXX", selectedRowKeys);
    this.setState({ selectedRowKeys }, this.setShowingTableList);
  };

  updateTableOrder = () => {
    const { productId, showColumn, tableList } = this.state;

    if (Boolean(productId) && Boolean(showColumn)) {
      switch (showColumn) {
        case "all":
          getAverageWithShippingOrderIndex(tableList);
          break;
        case "deadline":
          setOrderByGivenProperty(tableList, "planedDeliveryDateOrderIndex", productId);
          break;
        case "unitPrice":
          setOrderByGivenProperty(tableList, "unitPriceOrderIndex", productId);
          break;

        case "ShippingPrice":
          setOrderByGivenProperty(tableList, "shippingPrice", productId);
          break;

        case "total":
          setOrderByGivenProperty(tableList, "total", productId);
          break;

        case "totalWithShipping":
          setOrderByGivenProperty(tableList, "totalWithShipping", productId);
          break;

        case "average":
          getAverageOrderIndex(tableList);
          break;

        case "averageWithShipping":
          getAverageWithShippingOrderIndex(tableList);
          break;

        default:
          break;
      }
    }

    this.setState({ tableList }, this.setShowingTableList);
  };

  render() {
    const onFinishFailed = errorInfo => { };

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideSelectAll: true,
      renderCell: (checked, record, index, originNode) => {
        return originNode;
      }
    };

    const columns = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="VendorSelectionOnTotal.inputValue" defaultMessage="Supplier" />,
        key: "vendorId",
        render: record => {
          return record.vendorName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.vendorName) ? this.state.pagination.vendorName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  vendorName: e.target.value,
                  vendorNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.vendorNameColor) ? this.state.pagination.vendorNameColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PurchaseAuthorization.costDetail" defaultMessage="Cost Detail" />,
        key: "purchaseAuthorizationPriceList",
        render: record => {
          if (Boolean(record.costDetail) && record.costDetail.length > 0) return this.renderCostDetail(record.vendorId, record.costDetail);
        }
      },
      {
        title: <FormattedMessage id="PurchaseAuthorization.averageKg" defaultMessage="Average (Kg)" />,
        key: "purchaseAuthorizationPriceList",
        render: record => {
          return this.render2Tags(record.average, record.averageOrder);
        }
      },
      {
        title: <FormattedMessage id="PurchaseAuthorization.averageWithShippingKg" defaultMessage="Average With Shipping (Kg)" />,
        key: "purchaseAuthorizationPriceList",
        render: record => {
          return this.render2Tags(record.averageWithShipping, record.averageWithShippingOrder);
        }
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.checkEquivalent} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <div>
            <Row gutter={[16, 16]}>
              <Col span={4}>
                <AntButton type="dashed" style={{ marginBottom: "10px" }} onClick={this.checkEquivalent} block icon={<SaveOutlined />}>
                  Save
                </AntButton>
              </Col>
              <Col span={3}>
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Payment Method"
                  value={this.state.paymentMethod}
                  onChange={value => {
                    this.setState({ paymentMethod: value }, this.loadRfqVendorExpectedPricesByRfqId);
                  }}
                >
                  {this.state.paymentMethodSelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={3}>
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Currency"
                  value={this.state.currency}
                  onChange={value => {
                    // const { showColumn } = this.state;
                    // let sc = "all";
                    // if (Boolean(showColumn)) sc = null;

                    this.setState({ currency: value }, this.loadRfqVendorExpectedPricesByRfqId);
                  }}
                >
                  {this.state.currencySelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={3}>
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Unit"
                  value={this.state.requestedQuantityUnit}
                  onChange={value => {
                    this.setState({ requestedQuantityUnit: value }, this.loadRfqVendorExpectedPricesByRfqId);
                  }}
                >
                  {this.state.quantityUnitSelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={3}>
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Product"
                  value={this.state.productId}
                  onChange={value => {
                    this.setState({ productId: value }, this.loadRfqVendorExpectedPricesByRfqId);
                  }}
                >
                  {this.state.productSelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={3}>
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Column"
                  value={this.state.showColumn}
                  onChange={value => {
                    this.setState({ showColumn: value }, this.updateTableOrder);
                  }}
                >
                  {this.state.showColumnSelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.title}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={4}>
                <Switch
                  checkedChildren="Show All"
                  unCheckedChildren="Show Selecteds"
                  checked={this.state.showAllTableList}
                  onChange={checked => {
                    this.setState(
                      {
                        showAllTableList: checked
                      },
                      this.setShowingTableList
                    );
                  }}
                />
              </Col>
            </Row>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            rowSelection={rowSelection}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.showingTableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="VendorSelectionOnTotal.DoYouWantToDeleteTheOthers" defaultMessage="Do you want to delete the others ?" />
              </span>
            </span>
          }
          centered
          keyboard={false}
          maskClosable={false}
          open={this.state.modalcheckEquivalentShow}
          onCancel={() => this.setState({ modalcheckEquivalentShow: false })}
          cancelButtonProps={{ hidden: true }}
          okButtonProps={{ hidden: true }}
          footer={
            [
              <Space>
                <Button style={{ width: "100%" }} variant="secondary" onClick={() => this.save(false)}>
                  <FormattedMessage id="GeneralButtonNo" defaultMessage="No" />
                </Button>
                <Button style={{ width: "100%" }} variant="primary" onClick={() => this.save(true)}>
                  <FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />
                </Button>
              </Space>
            ]}

          content={<>
            <FormattedMessage id="VendorSelectionOnTotal.ThereAreOtherEquivelantItemsAddedOfRFQLists" defaultMessage="There are other equivelant items added of RFQ lists ?" />
            <br />
            <br />
            {this.state.otherRfqNoList.join(", ")}
          </>

          }
        >

        </DraggableModal>
      </div >
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VendorSelectionOnTotal);
