import { Button, Input, Table, Upload } from "antd";
import React, { Component } from "react";
import { UploadOutlined, FilterOutlined } from "@ant-design/icons";
import SyncIcon from "@material-ui/icons/Sync";
import formattedmessages from "./formattedmessages";
import { handleRequest } from "../../ApiConnector";
import { error, showSuccess } from "../../MessageHelper";
let fileElements = [];
let filePages = [];
export class GenerateFormattedMessagesJson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foundElementsTable: formattedmessages ?? [],
      fileList: [],
      uploading: false,
      tablePage: 1,
      filePages: [],
      processing: false,
    };
  }
  componentDidMount() {
    //this.findMatched(this.pageString(), "demo");
  }
  findMatched = (content, page) => {
    let r = /<FormattedMessage(.*?\s.*?)id="(.*?)\"(.*?\s.*?)defaultMessage="(.*?)\"(.*?\s.*?)\/>/g;
    let m = content.matchAll(r);
    //let n = r.exec(this.pageString());
    // console.log("matched", [...m], n);
    // let o = [];
    let index = 0;
    for (let i of [...m]) {
      fileElements.push({
        index: page + i[2] + index,
        page: page,
        element: i[0],
        messageId: i[2],
        defaultMessage: i[4],
      });
      index++;
    }
    // fileElements.push(...o);
    filePages.push(content);
    this.setState({
      filePages: filePages,
    });
  };

  downloadObjectAsJson = (exportObj, exportName) => {
    var dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(this.state.foundElementsTable));
    var downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "formattedmessages" + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };
  uniqByKeepFirst = (a, key, key2) => {
    let seen = new Set();
    return a.filter((item) => {
      // let obj = {
      //   page: key2(item),
      //   id: key(item),
      // };
      // if (key(item) === "Vendor.name") {
      //   console.log("item", obj.page, obj.id);
      //   console.log(
      //     [...seen].filter(
      //       (item) => item.id === obj.id && item.page === obj.page
      //     ).length > 0
      //   );
      // }
      let k = key(item);
      // let b =
      //   [...seen].filter((item) => item.id === obj.id && item.page === obj.page)
      //     .length > 0;
      // return b ? false : seen.add(obj);
      return seen.has(k) ? false : seen.add(k);
    });
  };
  handleLastProcess = (page) => {
    let filteredElements = fileElements.filter((it) => it.messageId !== "todo");
    filteredElements = this.uniqByKeepFirst(
      filteredElements,
      (it) => it.messageId,
      (it) => it.page
    );
    this.setState({ foundElementsTable: filteredElements });
  };
  fetchReadFileContent = (file, callback) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      console.log(e.target.result);
      callback(e.target.result);
    };
    reader.readAsText(file);
  };
  handleUpload = () => {
    const { fileList } = this.state;
    fileList.forEach((element) => {
      this.fetchReadFileContent(element, (content) => {
        content = content
          .replace("`${API_BASE_ROOT}/File/upload`", "null")
          .replaceAll("\r\n", " ");

        this.findMatched(content, element.name);
      });
    });
  };
  sync = async () => {
    let postObj = this.state.foundElementsTable.map((it) => {
      return {
        key: it.messageId,
        value: it.defaultMessage,
        pageJsFile: it.page,
        languageTypeId: 1,
      };
    });
    let response = await handleRequest("POST", "/api/languages/sync", postObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess("Sync Success");
    }
  };
  render() {
    const columns = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        },
      },
      {
        title: "Page",
        dataIndex: "page",
        name: "page",
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input.Search
              allowClear
              onSearch={(nameSearch) =>
                this.setState({
                  foundElementsTable: this.state.foundElementsTable.filter(
                    (m) => m.page.includes(nameSearch)
                  ),
                })
              }
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: "#bfbfbf",
            }}
          />
        ),
      },
      {
        title: "Element",
        name: "element",
        dataIndex: "element",
      },
      {
        title: "messageId",
        name: "messageId",
        dataIndex: "messageId",
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input.Search
              allowClear
              onSearch={(nameSearch) =>
                this.setState({
                  foundElementsTable: this.state.foundElementsTable.filter(
                    (m) => m.messageId.includes(nameSearch)
                  ),
                })
              }
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: "#bfbfbf",
            }}
          />
        ),
      },
      {
        title: "defaultMessage",
        name: "defaultMessage",
        dataIndex: "defaultMessage",
      },
    ];
    const { fileList } = this.state;
    const props = {
      fileList: this.state.fileList,
      beforeUpload: (file, fileList) => {
        //console.log("Filelist", fileList);

        if (file.type !== "text/javascript") {
          return Upload.LIST_IGNORE;
        }
        this.setState({
          fileList: fileList,
          fileElements: [],
          filePages: [],
        });

        // Prevent upload
        return false;
      },
      className: "upload-list-inline",
      accept: ".js",
    };
    return (
      <>
        <h2>Internationalization</h2>
        <Upload {...props} directory>
          <Button icon={<UploadOutlined />}>Select Folder</Button>
        </Upload>
        <Button
          type="primary"
          onClick={this.handleUpload}
          disabled={fileList.length === 0}
          style={{ marginTop: 16 }}
          loading={this.state.processing}
        >
          Start Process
        </Button>
        <Button
          type="primary"
          onClick={this.handleLastProcess}
          disabled={
            fileList.length === 0 ||
            fileList.length !== this.state.filePages.length
          }
          style={{ marginTop: 16 }}
        >
          Show On Table
        </Button>
        <Button
          type="primary"
          onClick={this.downloadObjectAsJson}
          disabled={
            fileList.length === 0 ||
            fileList.length !== this.state.filePages.length
          }
          style={{ marginTop: 16 }}
        >
          Download As Json
        </Button>
        <Button
          style={{ border: "0px" }}
          onClick={this.sync}
          icon={<SyncIcon color="primary" />}
        ></Button>
        <Table
          rowKey="index"
          columns={columns}
          dataSource={this.state.foundElementsTable}
          pagination={{
            pageSize: 50,
          }}
        />
      </>
    );
  }
}

export default GenerateFormattedMessagesJson;
