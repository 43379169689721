import {
  BankOutlined,
  CommentOutlined,
  ContactsOutlined,
  ExperimentOutlined,
  FileDoneOutlined,
  LikeOutlined,
  MessageOutlined,
  PhoneOutlined,
  ReadOutlined,
  TeamOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Modal as AntModal, Steps } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import DataTableComponent2 from "../../Components/DataTableComponent2";
import { GetDynamicFormItems } from "../../Components/ElementUtils";
import FormTopRightButtonsComponent from "../../Components/FormTopRightButtonsComponent";
import PersonnelLogSaveFormJson from "../../Components/PersonnelLogSaveForm";
import { error, showError, showSuccess, showWarning } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";
import { PersonnelBankAccount } from "./PersonnelBankAccount";
import { PersonnelComment } from "./PersonnelComment";
import { PersonnelContact } from "./PersonnelContact";
import { PersonnelContract } from "./PersonnelContract";
import { PersonnelEducation } from "./PersonnelEducation";
import { PersonnelExperience } from "./PersonnelExperience";
import { PersonnelHobby } from "./PersonnelHobby";
import { PersonnelLanguage } from "./PersonnelLanguage";
import { PersonnelLogPromptForm } from "./PersonnelLogPromptForm";
import PersonnelLogSaveFormFull from "./PersonnelLogSaveFormFull";
import { PersonnelReference } from "./PersonnelReference";
import { PersonnelTraining } from "./PersonnelTraining";

const { Step } = Steps;

export class PersonnelLog extends Component {
  constructor() {
    super();
    this.state = {
      formName: "PersonnelLog",
      modalDeleteShow: false,
      dataTable: PersonnelLogSaveFormJson.dataTable,
      inputs: PersonnelLogSaveFormJson.inputs,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      isPromptModalVisible: false,
      isPersonnelSaveModalVisible: false,
      id: null,
      currentRow: null,
      currentStep: 0,
      tableList: [],
      restartTable: false,
      pageFormData: null
    };
  }

  componentDidMount = async () => {
    let data = await GetDynamicFormItems(this.state.formName);
    this.setState({
      pageFormData: data
    });
  };

  fillComboboxes = async () => {};

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onPersonnelSave = values => {
    this.setState({
      id: values.id,
      currentPersonnelName: values.name,
      isPersonnelSaveModalVisible: false
    });
    this.restartTable();
  };

  onPersonnelPromptSave = values => {
    this.setState({
      isPersonnelSaveModalVisible: false
    });
    this.restartTable();
  };

  resetInputs = () => {
    this.setState({
      id: null,
      currentRow: null,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      currentPersonnelName: row.name + " " + (Boolean(row.midName) ? row.midName : "") + " " + row.lastName,
      currentRow: row,
      isPrompt: false
    });
  };

  setData = data => {
    this.setState({
      tableList: data
    });
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/personnelLog/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ restartTable: !this.state.restartTable });
  };

  onClickShowPrompt = () => {
    this.setState({
      isPromptModalVisible: true
    });
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        isPromptModalVisible: false
      });
    } else {
      this.setState({
        id: null,
        currentRow: null,
        hideInputs: true,
        isPromptModalVisible: false,
        currentStep: 0
      });
    }
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]

            companyId: "14",
            projectId: "13",
            name: element["Ad"],
            midName: element["Ad2"],
            lastName: element["Soyad"],
            phoneNumber: element["Telefon"],
            email: element["Mail Adresi"]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/personnelLog/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            this.restartTable();
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };
  closePersonnelSaveModal = () => {
    this.setState({
      isPersonnelSaveModalVisible: false
    });
  };
  onStepsChange = current => {
    this.setState({ currentStep: current });
  };

  render() {
    const { currentStep } = this.state;

    const next = () => {
      if (steps[currentStep + 1].disabled) {
        showWarning("Please fill all the required fields and save!");
        return;
      }
      this.setState({
        currentStep: currentStep + 1
      });
    };

    const prev = () => {
      this.setState({
        currentStep: currentStep - 1
      });
    };
    let iconStyle = {
      minWidth: "50px",
      fontSize: "50px"
    };
    const steps = [
      {
        title: "Personal Information",
        description: "0/15",
        content: (
          <PersonnelLogSaveFormFull
            row={this.state.currentRow}
            isPrompt={false}
            onSave={this.onPersonnelSave.bind(this)}
            onCancel={this.onClickCreateNew.bind(this)}
          />
        ),
        icon: <UserOutlined style={{ ...iconStyle }} />
      },
      {
        title: "Contacts",
        description: "0 Records. 0/15",
        content: <PersonnelContact personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <PhoneOutlined style={{ ...iconStyle }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "Tranings",
        description: "0 Records. 0/15",
        content: <PersonnelTraining personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <TeamOutlined style={{ ...iconStyle }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "Contract",
        description: "0 Records. 0/15",
        content: <PersonnelContract personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <FileDoneOutlined style={{ fontSize: "50px" }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "Experiences",
        description: "0 Records. 0/15",
        content: <PersonnelExperience personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <ExperimentOutlined style={{ fontSize: "50px" }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "Education",
        description: "0 Records. 0/15",
        content: <PersonnelEducation personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <ReadOutlined style={{ fontSize: "50px" }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "Language Skills",
        description: "0 Records. 0/15",
        content: <PersonnelLanguage personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <MessageOutlined style={{ fontSize: "50px" }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "Bank Accounts",
        description: "0 Records. 0/15",
        content: <PersonnelBankAccount personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <BankOutlined style={{ fontSize: "50px" }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "Hobbies",
        description: "0 Records. 0/15",
        content: <PersonnelHobby personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <LikeOutlined style={{ fontSize: "50px" }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "References",
        description: "0 Records. 0/15",
        content: <PersonnelReference personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <ContactsOutlined style={{ fontSize: "50px" }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "Comments",
        description: "0 Records. 0/15",
        content: <PersonnelComment personnelId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <CommentOutlined style={{ fontSize: "50px" }} />,
        disabled: !Boolean(this.state.id)
      }
    ];
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <FormTopRightButtonsComponent
          title="Personnel Registration"
          inputs={this.state.inputs}
          tableList={this.state.dataTable}
          handleUploadExcel={this.handleUploadExcel.bind(this)}
          hideInputs={this.state.hideInputs}
          onClickCreateNew={this.onClickShowPrompt.bind(this)}
          onClickCancel={this.onClickCreateNew.bind(this)}
        />
        <div hidden={this.state.hideInputs}>
          <PerfectScrollbar style={{ minHeight: "90px" }}>
            <Steps type="navigation" current={currentStep} onChange={this.onStepsChange}>
              {steps.map(item => (
                <Step
                  style={{ minWidth: "230px" }}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  description={item.description}
                  disabled={item.disabled}
                />
              ))}
            </Steps>
          </PerfectScrollbar>
          <div className="steps-action">
            {currentStep < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button type="primary" onClick={() => showSuccess("Processing complete!")}>
                Done
              </Button>
            )}
            {currentStep > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </div>
          <div className="steps-content">{steps[currentStep].content}</div>
        </div>

        <div hidden={!this.state.hideInputs} style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
            <DataTableComponent2
              key="dataTable"
              field={this.state.pageFormData.pageForm}
              inputs={this.state.pageFormData.pageFormInputCompanyList}
              actions={this.action}
              editRow={this.edit.bind(this)}
              restartTable={this.state.restartTable}
              createWhereParams={this.state.createWhereParams}
              setData={this.setData.bind(this)}
            />
          ) : null}
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <AntModal
          title="Personnel Information"
          visible={this.state.isPromptModalVisible}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ isPromptModalVisible: false })}
        >
          <Button
            onClick={() => {
              this.setState({ isPromptModalVisible: false, isPersonnelSaveModalVisible: true });
            }}
            style={{ width: "100%", marginBottom: "2rem" }}
            variant="success"
          >
            <FormattedMessage id="PersonnelLog.PromtRecordMessage" defaultMessage="Prompt Record" />
          </Button>
          <Button onClick={this.onClickCreateNew} style={{ width: "100%", marginBottom: "2rem" }} variant="warning">
            <FormattedMessage id="PersonnelLog.NormalRecordMessage" defaultMessage="Normal Record" />
          </Button>
        </AntModal>

        <AntModal
          title="Personnel Record"
          visible={this.state.isPersonnelSaveModalVisible}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ isPersonnelSaveModalVisible: false })}
          width={800}
        >
          <PersonnelLogPromptForm row={null} closeModal={this.closePersonnelSaveModal.bind(this)} onSave={this.onPersonnelPromptSave.bind(this)} />
        </AntModal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PersonnelLog);
