import React, { useEffect, useState } from 'react';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import '../planning/components/Gantt/Gantt.css';
import { handleRequest } from "../../ApiConnector";
import { error } from "../../MessageHelper";
import './components/Gantt/GanttChart.css';

const ToDoGantt = () => {
    const [tasks, setTasks] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchTasks();
    }, []);

    const fetchTasks = async () => {
        var response = await handleRequest("GET", "/api/toDo/getGanttChart/" + page);
        if (response.type === "ERROR") {
            error(response);
        } else {
            let data = [];
            let totalPages = 0;
            if (Boolean(response.data) && Boolean(response.data.data) && response.data.data.length > 0 && Boolean(response.data.data[0].data)) {
                data = response.data.data[0].data;
                totalPages = response.data.totalRecords;
            }
            console.log("data", data);
            setTasks(data)
            initializeGantt(data);
            setTotalPages(totalPages);
        }
    };

    const initializeGantt = (data) => {
        gantt.init('gantt_to_do');
        gantt.parse({ data });
        gantt.attachEvent('onAfterTaskUpdate', handleTaskUpdate);
        gantt.attachEvent('onAfterTaskAdd', handleTaskAdd);
        gantt.attachEvent('onAfterTaskDelete', handleTaskDelete);

        // Yeni görev ekleme işlemini engelle
        gantt.attachEvent('onBeforeLightbox', handleBeforeLightbox);

        // Eşleştirme işlemini engellemek için olay dinleyicilerini ekle
        gantt.attachEvent('onAfterLinkAdd', handleAfterLinkAdd);
        gantt.attachEvent('onAfterLinkDelete', handleAfterLinkDelete);

        // Task'ların sürükleme işlemini engellemek için olay dinleyicisini ekle
        gantt.attachEvent('onBeforeTaskDrag', handleBeforeTaskDrag);
    };

    const handleBeforeTaskDrag = (id, mode, event) => {
        // Task'ların sürükleme işlemini iptal et
        return false;
    };

    const handleAfterLinkAdd = (id, link) => {
        // Eşleştirme işlemini iptal et
        gantt.deleteLink(id);
    };

    const handleAfterLinkDelete = (id, link) => {
        // Eşleştirme işlemini yeniden ekle
        // Burada uygun bir işlem belirleyebilirsiniz
    };

    const handleBeforeLightbox = (id) => {
        return false;

        // if (gantt.getState().lightbox !== null) {
        //   // Eğer bir düzenleme işlemi açıksa yeni görev ekleme işlemini engelle
        //   return false;
        // }

        // // Kullanıcıya onay mesajı göstererek yeni görev ekleme işlemini engellemek için
        // const confirmResult = window.confirm('Yeni görev eklemek istediğinize emin misiniz?');
        // if (!confirmResult) {
        //   return false;
        // }

        // // Yeni görev ekleme işlemleri
        // // ...
        // return true;
    };

    const handleTaskUpdate = (id, task) => {
        // Güncellenen görevi sunucuya gönder
        fetch(`/api/tasks/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(task),
        })
            .then((response) => response.json())
            .then((data) => {
                // Sunucudan gelen cevabı işle
                // Örneğin, sunucu tarafında yapılan değişiklikler varsa güncelle
                fetchTasks();
            })
            .catch((error) => {
                console.error('Görev güncelleme hatası:', error);
            });
    };

    const handleTaskAdd = (id, task) => {
        // Yeni görevi sunucuya gönder
        fetch('/api/tasks', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(task),
        })
            .then((response) => response.json())
            .then((data) => {
                // Sunucudan gelen cevabı işle
                // Örneğin, sunucu tarafında yapılan değişiklikler varsa güncelle
                fetchTasks();
            })
            .catch((error) => {
                console.error('Görev ekleme hatası:', error);
            });
    };

    const handleTaskDelete = (id) => {
        // Silinen görevi sunucuya gönder
        fetch(`/api/tasks/${id}`, {
            method: 'DELETE',
        })
            .then((response) => response.json())
            .then((data) => {
                // Sunucudan gelen cevabı işle
                // Örneğin, sunucu tarafında yapılan değişiklikler varsa güncelle
                fetchTasks();
            })
            .catch((error) => {
                console.error('Görev silme hatası:', error);
            });
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
            fetchTasks();
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
            fetchTasks();
        }
    };

    return <div>
        {Boolean(tasks) &&
            <>
                <div id="gantt_to_do" style={{ width: '100%', height: '600px' }} />
                {/* <div>
                    <button onClick={handlePrevPage} disabled={page === 1}>
                        Önceki Sayfa
                    </button>
                    <span>{`Sayfa ${page} / ${totalPages}`}</span>
                    <button onClick={handleNextPage} disabled={page === totalPages}>
                        Sonraki Sayfa
                    </button>
                </div> */}
            </>
        }
    </div>;
};

export default ToDoGantt;