import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined, PlusOutlined, RightCircleOutlined, LeftCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { EditOutlined, ReplyOutlined, ScoreOutlined, Print } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import Forward from "@material-ui/icons/Forward";
import DoneIcon from "@material-ui/icons/Done";
import ForwardToInboxIcon from "@material-ui/icons/ForwardTwoTone";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import SendIcon from "@material-ui/icons/Send";
import {
  Button as AntButton,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal as AntModal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Upload,
  Skeleton,
  Badge
} from "antd";
import { MessageOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { Button, Col as ColBoot, Form as FormBoot, Modal, Row as RowBoot } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { PrepareFileUploadEdit } from "../../Components/ElementUtils";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";
import RfqVendorReply from "./RfqVendorReply";
import { Resizable } from "react-resizable";

import DraggableModal from "../../Components/DraggableModal";

const { Panel } = Collapse;

const { Option } = Select;

var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0");
var yyyy = today.getFullYear();

today = dd + "/" + mm + "/" + yyyy;

var _splitRows = [
  {
    splitIndex: 1,
    quantity: "",
    date: today
  }
];

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class ProcurementRequirements extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "procurementRequirements",
      controllerNameRequestForQuotation: "requestForQuotation",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      modalPdfViewer: false,
      pdfFile: "",
      typeReviseQuote: "Edit",
      modalHistoryShow: false,
      historyList: [],
      modalStatusShow: false,
      statusList: [],
      showRevisedListModal: false,
      revisedList: [],

      id: null,
      tableList: [],
      projectTakeOffsSelectItems: [],
      quoteValidityDateTypeSelectItems: [],
      receptionOptionsSelectItems: [],
      transportOptionsSelectItems: [],

      generalTerms: [],
      techRequirements: [],
      pricePaymentInvoicing: [],
      paymentTerms: [],
      deliveryPlaces: [],
      taxes: [],
      confidentialities: [],
      inspectionAndAcceptances: [],
      competentCourt: [],
      liabilityInsurances: [],
      intellectualProperties: [],
      contractRecords: [],
      informationPrivacies: [],
      essenceDeliveryDates: [],
      warranties: [],
      languages: [],
      requestedDocuments: [],
      terminations: [],
      miscellaneous: [],
      technicalRequirementChecklists: [],
      additionsList: [],

      fileList: [],
      fileUrl: "",

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      paginationDeliveryDate: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      paginationHistory: {
        current: 1,
        pageSize: 25,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      spinLoading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };


  fillComboboxes = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerNameRequestForQuotation + "/projectTakeOff");
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        projectTakeOffsSelectItems: Boolean(response.data) ? response.data : []
      });
    }

    var responseValidityDateType = await handleRequest("GET", "/api/" + this.state.controllerNameRequestForQuotation + "/quoteValidityDateType");
    if (responseValidityDateType.type === "ERROR") {
      error(responseValidityDateType);
    } else {
      this.setState({
        quoteValidityDateTypeSelectItems: Boolean(responseValidityDateType.data) ? responseValidityDateType.data : []
      });
    }

    var responseReceptionForQuotation = await handleRequest("GET", "/api/" + this.state.controllerNameRequestForQuotation + "/receptionOptions");
    if (responseReceptionForQuotation.type === "ERROR") {
      error(responseReceptionForQuotation);
    } else {
      this.setState({
        receptionOptionsSelectItems: Boolean(responseReceptionForQuotation.data) ? responseReceptionForQuotation.data : []
      });
    }

    var responseTransportOptions = await handleRequest("GET", "/api/" + this.state.controllerNameRequestForQuotation + "/transportOptions");
    if (responseTransportOptions.type === "ERROR") {
      error(responseTransportOptions);
    } else {
      this.setState({
        transportOptionsSelectItems: Boolean(responseTransportOptions.data) ? responseTransportOptions.data : []
      });
    }

    var responseCompanyShortName = await handleRequest("GET", "/api/" + this.state.controllerName + "/getCompanyShortName");
    if (responseCompanyShortName.type === "ERROR") {
      error(responseCompanyShortName);
    } else {
      this.setState({
        companyShortNameSelectItems: Boolean(responseCompanyShortName.data) ? responseCompanyShortName.data : []
      });
    }

  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      typeReviseQuote: "Edit",


      generalTerms: [],
      techRequirements: [],
      pricePaymentInvoicing: [],
      paymentTerms: [],
      deliveryPlaces: [],
      taxes: [],
      confidentialities: [],
      inspectionAndAcceptances: [],
      competentCourt: [],
      liabilityInsurances: [],
      intellectualProperties: [],
      contractRecords: [],
      informationPrivacies: [],
      essenceDeliveryDates: [],
      warranties: [],
      languages: [],
      requestedDocuments: [],
      terminations: [],
      miscellaneous: [],
      technicalRequirementChecklists: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      paginationDeliveryDate: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      fileList: []
    });
  };

  edit = (typeReviseQuote, row) => {
    document.getElementById("kt_scrolltop").click();
    let newItem = {
      generalTermsFileUrl: PrepareFileUploadEdit(row.generalTermsFileUrl),
      techRequirementsFileUrl: PrepareFileUploadEdit(row.techRequirementsFileUrl),
      pricePaymentInvoicingFileUrl: PrepareFileUploadEdit(row.pricePaymentInvoicingFileUrl),
      paymentTermsFileUrl: PrepareFileUploadEdit(row.paymentTermsFileUrl),
      deliveryPlacesFileUrl: PrepareFileUploadEdit(row.deliveryPlacesFileUrl),
      taxesFileUrl: PrepareFileUploadEdit(row.taxesFileUrl),
      essenceDeliveryDatesFileUrl: PrepareFileUploadEdit(row.essenceDeliveryDatesFileUrl),
      warrantiesFileUrl: PrepareFileUploadEdit(row.warrantiesFileUrl),
      languagesFileUrl: PrepareFileUploadEdit(row.languagesFileUrl),
      requestedDocumentsFileUrl: PrepareFileUploadEdit(row.requestedDocumentsFileUrl),
      terminationsFileUrl: PrepareFileUploadEdit(row.terminationsFileUrl),
      confidentialitiesFileUrl: PrepareFileUploadEdit(row.confidentialitiesFileUrl),
      inspectionAndAcceptancesFileUrl: PrepareFileUploadEdit(row.inspectionAndAcceptancesFileUrl),
      competentCourtFileUrl: PrepareFileUploadEdit(row.competentCourtFileUrl),
      liabilityInsurancesFileUrl: PrepareFileUploadEdit(row.liabilityInsurancesFileUrl),
      intellectualPropertiesFileUrl: PrepareFileUploadEdit(row.intellectualPropertiesFileUrl),
      contractRecordsFileUrl: PrepareFileUploadEdit(row.contractRecordsFileUrl),
      informationPrivaciesFileUrl: PrepareFileUploadEdit(row.informationPrivaciesFileUrl),
      miscellaneousFileUrl: PrepareFileUploadEdit(row.miscellaneousFileUrl),
      projectTakeOffListString: row.projectTakeOffListCode
    };

    let newItemState = {
      generalTerms: row.generalTerms,
      techRequirements: row.techRequirements,
      pricePaymentInvoicing: row.pricePaymentInvoicing,
      paymentTerms: row.paymentTerms,
      deliveryPlaces: row.deliveryPlaces,
      taxes: row.taxes,
      essenceDeliveryDates: row.essenceDeliveryDates,
      warranties: row.warranties,
      languages: row.languages,
      requestedDocuments: row.requestedDocuments,
      terminations: row.terminations,
      confidentialities: row.confidentialities,
      inspectionAndAcceptances: row.inspectionAndAcceptances,
      competentCourt: row.competentCourt,
      liabilityInsurances: row.liabilityInsurances,
      intellectualProperties: row.intellectualProperties,
      contractRecords: row.contractRecords,
      informationPrivacies: row.informationPrivacies,
      miscellaneous: row.miscellaneous,
      additionsList: row.additionsList,
      technicalRequirementChecklists: row.technicalRequirementChecklists
    };

    if (Boolean(newItemState.generalTerms) && newItemState.generalTerms.length > 0)
      newItemState.generalTerms.splice(0, 1);
    else {
      newItemState.generalTerms = [];
    }
    if (Boolean(newItemState.techRequirements) && newItemState.techRequirements.length > 0)
      newItemState.techRequirements.splice(0, 1);
    else {
      newItemState.techRequirements = [];
    }
    if (Boolean(newItemState.pricePaymentInvoicing) && newItemState.pricePaymentInvoicing.length > 0)
      newItemState.pricePaymentInvoicing.splice(0, 1);
    else {
      newItemState.pricePaymentInvoicing = [];
    }
    if (Boolean(newItemState.paymentTerms) && newItemState.paymentTerms.length > 0)
      newItemState.paymentTerms.splice(0, 1);
    else {
      newItemState.paymentTerms = [];
    }
    if (Boolean(newItemState.deliveryPlaces) && newItemState.deliveryPlaces.length > 0)
      newItemState.deliveryPlaces.splice(0, 1);
    else {
      newItemState.deliveryPlaces = [];
    }
    if (Boolean(newItemState.taxes) && newItemState.taxes.length > 0)
      newItemState.taxes.splice(0, 1);
    else {
      newItemState.taxes = [];
    }
    if (Boolean(newItemState.essenceDeliveryDates) && newItemState.essenceDeliveryDates.length > 0)
      newItemState.essenceDeliveryDates.splice(0, 1);
    else {
      newItemState.essenceDeliveryDates = [];
    }
    if (Boolean(newItemState.warranties) && newItemState.warranties.length > 0)
      newItemState.warranties.splice(0, 1);
    else {
      newItemState.warranties = [];
    }
    if (Boolean(newItemState.languages) && newItemState.languages.length > 0)
      newItemState.languages.splice(0, 1);
    else {
      newItemState.languages = [];
    }
    if (Boolean(newItemState.requestedDocuments) && newItemState.requestedDocuments.length > 0)
      newItemState.requestedDocuments.splice(0, 1);
    else {
      newItemState.requestedDocuments = [];
    }
    if (Boolean(newItemState.terminations) && newItemState.terminations.length > 0)
      newItemState.terminations.splice(0, 1);
    else {
      newItemState.terminations = [];
    }
    if (Boolean(newItemState.confidentialities) && newItemState.confidentialities.length > 0)
      newItemState.confidentialities.splice(0, 1);
    else {
      newItemState.confidentialities = [];
    }
    if (Boolean(newItemState.inspectionAndAcceptances) && newItemState.inspectionAndAcceptances.length > 0)
      newItemState.inspectionAndAcceptances.splice(0, 1);
    else {
      newItemState.inspectionAndAcceptances = [];
    }
    if (Boolean(newItemState.competentCourt) && newItemState.competentCourt.length > 0)
      newItemState.competentCourt.splice(0, 1);
    else {
      newItemState.competentCourt = [];
    }
    if (Boolean(newItemState.liabilityInsurances) && newItemState.liabilityInsurances.length > 0)
      newItemState.liabilityInsurances.splice(0, 1);
    else {
      newItemState.liabilityInsurances = [];
    }
    if (Boolean(newItemState.intellectualProperties) && newItemState.intellectualProperties.length > 0)
      newItemState.intellectualProperties.splice(0, 1);
    else {
      newItemState.intellectualProperties = [];
    }
    if (Boolean(newItemState.contractRecords) && newItemState.contractRecords.length > 0)
      newItemState.contractRecords.splice(0, 1);
    else {
      newItemState.contractRecords = [];
    }
    if (Boolean(newItemState.informationPrivacies) && newItemState.informationPrivacies.length > 0)
      newItemState.informationPrivacies.splice(0, 1);
    else {
      newItemState.informationPrivacies = [];
    }
    if (Boolean(newItemState.miscellaneous) && newItemState.miscellaneous.length > 0)
      newItemState.miscellaneous.splice(0, 1);
    else {
      newItemState.miscellaneous = [];
    }
    if (Boolean(newItemState.additionsList) && newItemState.additionsList.length > 0)
      newItemState.additionsList.forEach(addition => {
        if (Boolean(addition.prModelList) && addition.prModelList.length > 0)
          addition.prModelList.splice(0, 1);
      });
    else {
      newItemState.additionsList = [];
    }

    newItem.validityValue = row.validityValue;
    newItem.quoteValidityDateType = row.quoteValidityDateType;
    newItem.receptionOptions = row.receptionOptions;
    newItem.transportOptions = row.transportOptions;

    this.formRef.current.setFieldsValue({
      ...row,
      ...newItem
    });

    let rfqVendor = { "id": 624 }

    this.setState({
      rfqRow: row,
      rfqVendor: rfqVendor,
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      ...newItemState,
      typeReviseQuote: typeReviseQuote,
      receptionOptions: row.receptionOptions,
      transportOptions: row.transportOptions,
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  evaluationModal = row => {
    this.setState({
      showEvaluationModal: true,
      rfqRow: { ...row }
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      this.fillComboboxes();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  restartTableRevised = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    newObj.procurementRequirementsId = this.state.modalProcurementRequirementsId;
    let url = "/api/" + this.state.controllerName + "/pagingRevised";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        revisedList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          revisedList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          revisedList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  save = async values => {
    var generalTerms = [];
    if (Boolean(this.state.generalTerms) && this.state.generalTerms.length > 0) {
      var data = this.state.generalTerms;
      if (Boolean(data) && data.length > 0) {
        generalTerms.push({ check: true, description: "General Terms and Conditions", remarks: "" });
        data.forEach(element => {
          generalTerms.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    }

    let generalTermsFileUrl = [];
    if (Boolean(values.generalTermsFileUrl)) {
      values.generalTermsFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) generalTermsFileUrl.push(item.response.url);
        else if (Boolean(item.url)) generalTermsFileUrl.push(item.url);
      });
    }

    var techRequirements = [];
    if (Boolean(this.state.techRequirements) && this.state.techRequirements.length > 0) {
      var data = this.state.techRequirements;
      if (Boolean(data) && data.length > 0) {
        techRequirements.push({ check: true, description: "Technical Requirements Checklist", remarks: "" });
        data.forEach(element => {
          techRequirements.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    }

    let techRequirementsFileUrl = [];
    if (Boolean(values.techRequirementsFileUrl)) {
      values.techRequirementsFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) techRequirementsFileUrl.push(item.response.url);
        else if (Boolean(item.url)) techRequirementsFileUrl.push(item.url);
      });
    }

    var pricePaymentInvoicing = [];
    if (Boolean(this.state.pricePaymentInvoicing) && this.state.pricePaymentInvoicing.length > 0) {
      var data = this.state.pricePaymentInvoicing;
      if (Boolean(data) && data.length > 0) {
        pricePaymentInvoicing.push({ check: true, description: "Price, payment and invoicing", remarks: "" });
        data.forEach(element => {
          pricePaymentInvoicing.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    } else {
      if (
        (Boolean(values.priceOffer) && values.priceOffer === true) ||
        (Boolean(values.goodsAndService) && values.goodsAndService === true) ||
        (Boolean(values.insurance) && values.insurance === true) ||
        (Boolean(values.shipping) && values.shipping === true)
      ) {
        pricePaymentInvoicing.push({ check: true, description: "Price, payment and invoicing", remarks: "" });
      }
    }

    if (Boolean(values.priceOffer) && values.priceOffer === true) {
      pricePaymentInvoicing.push({ ["check"]: true, ["description"]: "Your offer for price for", remarks: "" });
    }

    if (Boolean(values.goodsAndService) && values.goodsAndService === true) {
      pricePaymentInvoicing.push({ ["check"]: true, ["description"]: "Goods and Service", remarks: "" });
    }

    if (Boolean(values.insurance) && values.insurance === true) {
      pricePaymentInvoicing.push({ ["check"]: true, ["description"]: "Insurance", remarks: "" });
    }

    if (Boolean(values.shipping) && values.shipping === true) {
      pricePaymentInvoicing.push({ ["check"]: true, ["description"]: "Shipping", remarks: "" });
    }

    let pricePaymentInvoicingFileUrl = [];
    if (Boolean(values.pricePaymentInvoicingFileUrl)) {
      values.pricePaymentInvoicingFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) pricePaymentInvoicingFileUrl.push(item.response.url);
        else if (Boolean(item.url)) pricePaymentInvoicingFileUrl.push(item.url);
      });
    }

    var paymentTerms = [];
    if (Boolean(this.state.paymentTerms) && this.state.paymentTerms.length > 0) {
      var data = this.state.paymentTerms;
      if (Boolean(data) && data.length > 0) {
        paymentTerms.push({ check: true, description: "Payment Terms", remarks: "" });
        data.forEach(element => {
          paymentTerms.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    } else {
      if (Boolean(values.termsForPayment) && values.termsForPayment === true) {
        paymentTerms.push({ check: true, description: "Payment Terms", remarks: "" });
      }
    }

    if (Boolean(values.termsForPayment) && values.termsForPayment === true) {
      paymentTerms.push({ ["check"]: true, ["description"]: "Your terms for payment", remarks: "" });
    }

    let paymentTermsFileUrl = [];
    if (Boolean(values.paymentTermsFileUrl)) {
      values.paymentTermsFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) paymentTermsFileUrl.push(item.response.url);
        else if (Boolean(item.url)) paymentTermsFileUrl.push(item.url);
      });
    }

    var deliveryPlaces = [];
    if (Boolean(this.state.deliveryPlaces) && this.state.deliveryPlaces.length > 0) {
      var data = this.state.deliveryPlaces;
      if (Boolean(data) && data.length > 0) {
        deliveryPlaces.push({ check: true, description: "Delivery Place", remarks: "" });
        data.forEach(element => {
          deliveryPlaces.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    } else {
      if (
        (Boolean(values.projectSite) && values.projectSite === true) ||
        (Boolean(values.manufacturingPlace) && values.manufacturingPlace === true)
      ) {
        deliveryPlaces.push({ check: true, description: "Delivery Place", remarks: "" });
      }
    }

    if (Boolean(values.projectSite) && values.projectSite === true) {
      deliveryPlaces.push({ ["check"]: true, ["description"]: "(Company name) Project site", remarks: "" });
    }

    if (Boolean(values.manufacturingPlace) && values.manufacturingPlace === true) {
      deliveryPlaces.push({ ["check"]: true, ["description"]: "Your manufacturing place", remarks: "" });
    }

    let deliveryPlacesFileUrl = [];
    if (Boolean(values.deliveryPlacesFileUrl)) {
      values.deliveryPlacesFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) deliveryPlacesFileUrl.push(item.response.url);
        else if (Boolean(item.url)) deliveryPlacesFileUrl.push(item.url);
      });
    }

    var taxes = [];
    if (Boolean(this.state.taxes) && this.state.taxes.length > 0) {
      var data = this.state.taxes;
      if (Boolean(data) && data.length > 0) {
        taxes.push({ check: true, description: "Taxes", remarks: "" });
        data.forEach(element => {
          taxes.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    }

    let taxesFileUrl = [];
    if (Boolean(values.taxesFileUrl)) {
      values.taxesFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) taxesFileUrl.push(item.response.url);
        else if (Boolean(item.url)) taxesFileUrl.push(item.url);
      });
    }

    var essenceDeliveryDates = [];
    if (Boolean(this.state.essenceDeliveryDates) && this.state.essenceDeliveryDates.length > 0) {
      var data = this.state.essenceDeliveryDates;
      if (Boolean(data) && data.length > 0) {
        essenceDeliveryDates.push({ check: true, description: "Time of essence/Delivery dates", remarks: "" });
        data.forEach(element => {
          essenceDeliveryDates.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    } else {
      if (
        (Boolean(values.fixDate) && values.fixDate === true) ||
        (Boolean(values.proposalDeliveryDate) && values.proposalDeliveryDate === true) ||
        (Boolean(values.mutualNegotiations) && values.mutualNegotiations === true)
      ) {
        essenceDeliveryDates.push({ check: true, description: "Time of essence/Delivery dates", remarks: "" });
      }
    }

    if (Boolean(values.fixDate) && values.fixDate === true) {
      essenceDeliveryDates.push({ ["check"]: true, ["description"]: "Fix date", remarks: "" });
    }

    if (Boolean(values.proposalDeliveryDate) && values.proposalDeliveryDate === true) {
      essenceDeliveryDates.push({ ["check"]: true, ["description"]: "Your proposal of delivery date", remarks: "" });
    }

    if (Boolean(values.mutualNegotiations) && values.mutualNegotiations === true) {
      essenceDeliveryDates.push({ ["check"]: true, ["description"]: "To be decided by mutual negotiations", remarks: "" });
    }

    let essenceDeliveryDatesFileUrl = [];
    if (Boolean(values.essenceDeliveryDatesFileUrl)) {
      values.essenceDeliveryDatesFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) essenceDeliveryDatesFileUrl.push(item.response.url);
        else if (Boolean(item.url)) essenceDeliveryDatesFileUrl.push(item.url);
      });
    }

    var warranties = [];
    if (Boolean(this.state.warranties) && this.state.warranties.length > 0) {
      var data = this.state.warranties;
      if (Boolean(data) && data.length > 0) {
        warranties.push({ check: true, description: "Warranty", remarks: "" });
        data.forEach(element => {
          warranties.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    }

    let warrantiesFileUrl = [];
    if (Boolean(values.warrantiesFileUrl)) {
      values.warrantiesFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) warrantiesFileUrl.push(item.response.url);
        else if (Boolean(item.url)) warrantiesFileUrl.push(item.url);
      });
    }

    var languages = [];
    if (Boolean(this.state.languages) && this.state.languages.length > 0) {
      var data = this.state.languages;
      if (Boolean(data) && data.length > 0) {
        languages.push({ check: true, description: "Language", remarks: "" });
        data.forEach(element => {
          languages.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    }

    let languagesFileUrl = [];
    if (Boolean(values.languagesFileUrl)) {
      values.languagesFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) languagesFileUrl.push(item.response.url);
        else if (Boolean(item.url)) languagesFileUrl.push(item.url);
      });
    }

    var requestedDocuments = [];
    if (Boolean(this.state.requestedDocuments) && this.state.requestedDocuments.length > 0) {
      var data = this.state.requestedDocuments;
      if (Boolean(data) && data.length > 0) {
        requestedDocuments.push({ check: true, description: "Requested documents", remarks: "" });
        data.forEach(element => {
          requestedDocuments.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    }

    let requestedDocumentsFileUrl = [];
    if (Boolean(values.requestedDocumentsFileUrl)) {
      values.requestedDocumentsFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) requestedDocumentsFileUrl.push(item.response.url);
        else if (Boolean(item.url)) requestedDocumentsFileUrl.push(item.url);
      });
    }

    var terminations = [];
    if (Boolean(this.state.terminations) && this.state.terminations.length > 0) {
      var data = this.state.terminations;
      if (Boolean(data) && data.length > 0) {
        terminations.push({ check: true, description: "Termination", remarks: "" });
        data.forEach(element => {
          terminations.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    }

    let terminationsFileUrl = [];
    if (Boolean(values.terminationsFileUrl)) {
      values.terminationsFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) terminationsFileUrl.push(item.response.url);
        else if (Boolean(item.url)) terminationsFileUrl.push(item.url);
      });
    }

    var confidentialities = [];
    if (Boolean(this.state.confidentialities) && this.state.confidentialities.length > 0) {
      var data = this.state.confidentialities;
      if (Boolean(data) && data.length > 0) {
        confidentialities.push({ check: true, description: "Confidentiality, privacy and publicity", remarks: "" });
        data.forEach(element => {
          confidentialities.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    }

    let confidentialitiesFileUrl = [];
    if (Boolean(values.confidentialitiesFileUrl)) {
      values.confidentialitiesFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) confidentialitiesFileUrl.push(item.response.url);
        else if (Boolean(item.url)) confidentialitiesFileUrl.push(item.url);
      });
    }

    var inspectionAndAcceptances = [];
    if (Boolean(this.state.inspectionAndAcceptances) && this.state.inspectionAndAcceptances.length > 0) {
      var data = this.state.inspectionAndAcceptances;
      if (Boolean(data) && data.length > 0) {
        inspectionAndAcceptances.push({ check: true, description: "Inspection and acceptance", remarks: "" });
        data.forEach(element => {
          inspectionAndAcceptances.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    }

    let inspectionAndAcceptancesFileUrl = [];
    if (Boolean(values.inspectionAndAcceptancesFileUrl)) {
      values.inspectionAndAcceptancesFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) inspectionAndAcceptancesFileUrl.push(item.response.url);
        else if (Boolean(item.url)) inspectionAndAcceptancesFileUrl.push(item.url);
      });
    }

    var competentCourt = [];
    if (Boolean(this.state.competentCourt) && this.state.competentCourt.length > 0) {
      var data = this.state.competentCourt;
      if (Boolean(data) && data.length > 0) {
        competentCourt.push({ check: true, description: "Governing law and competent court", remarks: "" });
        data.forEach(element => {
          competentCourt.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    }

    let competentCourtFileUrl = [];
    if (Boolean(values.competentCourtFileUrl)) {
      values.competentCourtFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) competentCourtFileUrl.push(item.response.url);
        else if (Boolean(item.url)) competentCourtFileUrl.push(item.url);
      });
    }

    var liabilityInsurances = [];
    if (Boolean(this.state.liabilityInsurances) && this.state.liabilityInsurances.length > 0) {
      var data = this.state.liabilityInsurances;
      if (Boolean(data) && data.length > 0) {
        liabilityInsurances.push({ check: true, description: "Liability and insurance", remarks: "" });
        data.forEach(element => {
          liabilityInsurances.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    }

    let liabilityInsurancesFileUrl = [];
    if (Boolean(values.liabilityInsurancesFileUrl)) {
      values.liabilityInsurancesFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) liabilityInsurancesFileUrl.push(item.response.url);
        else if (Boolean(item.url)) liabilityInsurancesFileUrl.push(item.url);
      });
    }

    var intellectualProperties = [];
    if (Boolean(this.state.intellectualProperties) && this.state.intellectualProperties.length > 0) {
      var data = this.state.intellectualProperties;
      if (Boolean(data) && data.length > 0) {
        intellectualProperties.push({ check: true, description: "Intellectual property", remarks: "" });
        data.forEach(element => {
          intellectualProperties.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    }

    let intellectualPropertiesFileUrl = [];
    if (Boolean(values.intellectualPropertiesFileUrl)) {
      values.intellectualPropertiesFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) intellectualPropertiesFileUrl.push(item.response.url);
        else if (Boolean(item.url)) intellectualPropertiesFileUrl.push(item.url);
      });
    }

    var contractRecords = [];
    if (Boolean(this.state.contractRecords) && this.state.contractRecords.length > 0) {
      var data = this.state.contractRecords;
      if (Boolean(data) && data.length > 0) {
        contractRecords.push({ check: true, description: "Contract records", remarks: "" });
        data.forEach(element => {
          contractRecords.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    }

    let contractRecordsFileUrl = [];
    if (Boolean(values.contractRecordsFileUrl)) {
      values.contractRecordsFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) contractRecordsFileUrl.push(item.response.url);
        else if (Boolean(item.url)) contractRecordsFileUrl.push(item.url);
      });
    }

    var informationPrivacies = [];
    if (Boolean(this.state.informationPrivacies) && this.state.informationPrivacies.length > 0) {
      var data = this.state.informationPrivacies;
      if (Boolean(data) && data.length > 0) {
        informationPrivacies.push({ check: true, description: "Freedom of information and privacy", remarks: "" });
        data.forEach(element => {
          informationPrivacies.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    }

    let informationPrivaciesFileUrl = [];
    if (Boolean(values.informationPrivaciesFileUrl)) {
      values.informationPrivaciesFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) informationPrivaciesFileUrl.push(item.response.url);
        else if (Boolean(item.url)) informationPrivaciesFileUrl.push(item.url);
      });
    }

    var miscellaneous = [];
    if (Boolean(this.state.miscellaneous) && this.state.miscellaneous.length > 0) {
      var data = this.state.miscellaneous;
      if (Boolean(data) && data.length > 0) {
        miscellaneous.push({ check: true, description: "Miscellaneous", remarks: "" });
        data.forEach(element => {
          miscellaneous.push({
            ...element,
            check: element.check,
            description: element.description,
            remarks: Boolean(element.remarks) ? element.remarks : ""
          });
        });
      }
    }

    let miscellaneousFileUrl = [];
    if (Boolean(values.miscellaneousFileUrl)) {
      values.miscellaneousFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) miscellaneousFileUrl.push(item.response.url);
        else if (Boolean(item.url)) miscellaneousFileUrl.push(item.url);
      });
    }

    var aList = [];
    if (this.state.additionsList.length > 0) {
      this.state.additionsList.forEach(element => {
        let objList = [];
        var data = element.prModelList;
        if (Boolean(data) && data.length > 0) {
          objList.push({ check: true, description: element.description, remarks: "" });
          data.forEach(e => {
            objList.push({
              ...element,
              check: e.check,
              description: e.description,
              remarks: Boolean(e.remarks) ? e.remarks : ""
            });
          });
          aList.push({ check: true, description: element.description, prModelList: objList, remarks: "" });
        }
      });
    }

    const newItem = {
      ...values,
      id: this.state.id,
      generalTerms: generalTerms,
      generalTermsFileUrl: generalTermsFileUrl.toString(),
      techRequirements: techRequirements,
      techRequirementsFileUrl: techRequirementsFileUrl.toString(),
      pricePaymentInvoicing: pricePaymentInvoicing,
      pricePaymentInvoicingFileUrl: pricePaymentInvoicingFileUrl.toString(),
      paymentTerms: paymentTerms,
      paymentTermsFileUrl: paymentTermsFileUrl.toString(),
      deliveryPlaces: deliveryPlaces,
      deliveryPlacesFileUrl: deliveryPlacesFileUrl.toString(),
      taxes: taxes,
      taxesFileUrl: taxesFileUrl.toString(),
      essenceDeliveryDates: essenceDeliveryDates,
      essenceDeliveryDatesFileUrl: essenceDeliveryDatesFileUrl.toString(),
      warranties: warranties,
      warrantiesFileUrl: warrantiesFileUrl.toString(),
      languages: languages,
      languagesFileUrl: languagesFileUrl.toString(),
      requestedDocuments: requestedDocuments,
      requestedDocumentsFileUrl: requestedDocumentsFileUrl.toString(),
      terminations: terminations,
      terminationsFileUrl: terminationsFileUrl.toString(),
      confidentialities: confidentialities,
      confidentialitiesFileUrl: confidentialitiesFileUrl.toString(),
      inspectionAndAcceptances: inspectionAndAcceptances,
      inspectionAndAcceptancesFileUrl: inspectionAndAcceptancesFileUrl.toString(),
      competentCourt: competentCourt,
      competentCourtFileUrl: competentCourtFileUrl.toString(),
      liabilityInsurances: liabilityInsurances,
      liabilityInsurancesFileUrl: liabilityInsurancesFileUrl.toString(),
      intellectualProperties: intellectualProperties,
      intellectualPropertiesFileUrl: intellectualPropertiesFileUrl.toString(),
      contractRecords: contractRecords,
      contractRecordsFileUrl: contractRecordsFileUrl.toString(),
      informationPrivacies: informationPrivacies,
      informationPrivaciesFileUrl: informationPrivaciesFileUrl.toString(),
      miscellaneous: miscellaneous,
      miscellaneousFileUrl: miscellaneousFileUrl.toString(),
      additionsList: aList
    };
    var response = null;
    if (this.state.typeReviseQuote === "Edit") {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    } else if (this.state.typeReviseQuote === "Revise") {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/revise/" + newItem.id, newItem);
    } else if (this.state.typeReviseQuote === "Quote") {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/quote/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this, "Edit")
    };
    let reviseAction = {
      name: "Revise",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this, "Revise")
    };
    // let quoteAction = {
    //   name: "Quote",
    //   icon: <ScoreOutlined fontSize="small" color="primary" />,
    //   actionClick: this.edit.bind(this, "Quote")
    // };

    // let sendAction = {
    //   name: "Send",
    //   icon: <SendIcon fontSize="small" color="secondary" />,
    //   actionClick: this.showSendModal.bind(this)
    // };

    let quoteAction = {
      name: "Quote",
      icon: <ScoreOutlined fontSize="small" color="primary" />,
      actionClick: this.showQuoteModal.bind(this, "Quote")
    };

    let sendAction = {
      name: "Send",
      icon: <SendIcon fontSize="small" color="secondary" />,
      actionClick: this.showPostModal.bind(this)
    };

    let printAction = {
      name: "RFQ Print",
      icon: <Print fontSize="small" color="action" />,
      // actionClick: this.showPrintModal.bind(this)
    };
    let uploadAction = {
      name: "Upload",
      icon: <ReplyOutlined fontSize="small" color="error" />,
      actionClick: this.showUploadModal.bind(this)
    };

    let actionList = [];
    if (Boolean(record.remarks)) {
      if (record.remarks === "Open") {
        actionList.push(quoteAction);
      }
      else if (record.remarks === "Send") {
        actionList.push(reviseAction);
      } else {
        actionList.push(editAction);
      }
    }
    actionList.push(printAction);
    actionList.push(uploadAction);
    actionList.push(sendAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  generateReport = async row => {
    let url = "/api/" + this.state.controllerNameRequestForQuotation + "/getReport/" + row.id;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });
      showError("There is no created report for selected item");
    }
  };

  generateReportRevision = async row => {
    let url = "/api/" + this.state.controllerNameRequestForQuotation + "/getReportRevision/" + row.requestForQuotationId + "/" + row.revNo;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });
      showError("There is no created report for selected item");
    }
  };

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showReport = async url => {
    try {
      const fileRealUrl = url;

      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true
      });
    } catch (error) {
      alert("File cant found.");
    }
  };

  showRevisedList = async (row) => {
    this.setState({
      showRevisedListModal: true,
      modalProcurementRequirementsId: row.id,
    }, () => {
      const { pagination } = this.state;
      this.restartTableRevised({ pagination });
    });
  };

  showUploadModal = row => {
    let fileList = [];
    if (row.fileUrl) {
      let fileUrl = Boolean(row.fileUrl) ? row.fileUrl.split(",") : [];
      if (Boolean(fileUrl) && fileUrl.length > 0) {
        fileUrl.forEach((element, index) => {
          let obj = {
            uid: index,
            name: element.split("download/")[1],
            url: element,
            thumbUrl: element
          };
          fileList.push(obj);
        });
      }
    }

    this.setState({
      modalUploadShow: true,
      id: row.id,
      fileList: fileList,
    });
  };

  saveUpload = async () => {
    if (Boolean(this.state.fileUrl)) {
      const newItem = {
        id: this.state.id,
        fileUrl: this.state.fileUrl
      };
      var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/upload/" + newItem.id, newItem);

      if (response.type === "ERROR") {
        error(response);
      } else {
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.setState({
          id: null,
          modalUploadShow: false,
          fileList: [],
          fileUrl: "",
        });
      }
    } else {
      showError("Please upload a file");
    }
  };

  showSendModal = (row) => {
    if (Boolean(row.fileUrl)) {
      this.setState({
        modalSendShow: true,
        id: row.id,
      });
    } else {
      showError("Please upload a file");
      this.setState({
        modalUploadShow: true,
        id: row.id,
      });
    }
  };

  showPostModal = row => {
    this.setState({
      modalPostShow: true,
      id: row.id,
      selectedRow: row,
    });
  };

  showQuoteModal = (typeReviseQuote, row) => {
    let newItem = {
      generalTermsFileUrl: PrepareFileUploadEdit(row.generalTermsFileUrl),
      techRequirementsFileUrl: PrepareFileUploadEdit(row.techRequirementsFileUrl),
      pricePaymentInvoicingFileUrl: PrepareFileUploadEdit(row.pricePaymentInvoicingFileUrl),
      paymentTermsFileUrl: PrepareFileUploadEdit(row.paymentTermsFileUrl),
      deliveryPlacesFileUrl: PrepareFileUploadEdit(row.deliveryPlacesFileUrl),
      taxesFileUrl: PrepareFileUploadEdit(row.taxesFileUrl),
      essenceDeliveryDatesFileUrl: PrepareFileUploadEdit(row.essenceDeliveryDatesFileUrl),
      warrantiesFileUrl: PrepareFileUploadEdit(row.warrantiesFileUrl),
      languagesFileUrl: PrepareFileUploadEdit(row.languagesFileUrl),
      requestedDocumentsFileUrl: PrepareFileUploadEdit(row.requestedDocumentsFileUrl),
      terminationsFileUrl: PrepareFileUploadEdit(row.terminationsFileUrl),
      confidentialitiesFileUrl: PrepareFileUploadEdit(row.confidentialitiesFileUrl),
      inspectionAndAcceptancesFileUrl: PrepareFileUploadEdit(row.inspectionAndAcceptancesFileUrl),
      competentCourtFileUrl: PrepareFileUploadEdit(row.competentCourtFileUrl),
      liabilityInsurancesFileUrl: PrepareFileUploadEdit(row.liabilityInsurancesFileUrl),
      intellectualPropertiesFileUrl: PrepareFileUploadEdit(row.intellectualPropertiesFileUrl),
      contractRecordsFileUrl: PrepareFileUploadEdit(row.contractRecordsFileUrl),
      informationPrivaciesFileUrl: PrepareFileUploadEdit(row.informationPrivaciesFileUrl),
      miscellaneousFileUrl: PrepareFileUploadEdit(row.miscellaneousFileUrl),
      projectTakeOffListString: row.projectTakeOffListCode
    };

    let newItemState = {
      generalTerms: row.generalTerms,
      techRequirements: row.techRequirements,
      pricePaymentInvoicing: row.pricePaymentInvoicing,
      paymentTerms: row.paymentTerms,
      deliveryPlaces: row.deliveryPlaces,
      taxes: row.taxes,
      essenceDeliveryDates: row.essenceDeliveryDates,
      warranties: row.warranties,
      languages: row.languages,
      requestedDocuments: row.requestedDocuments,
      terminations: row.terminations,
      confidentialities: row.confidentialities,
      inspectionAndAcceptances: row.inspectionAndAcceptances,
      competentCourt: row.competentCourt,
      liabilityInsurances: row.liabilityInsurances,
      intellectualProperties: row.intellectualProperties,
      contractRecords: row.contractRecords,
      informationPrivacies: row.informationPrivacies,
      miscellaneous: row.miscellaneous,
      additionsList: row.additionsList,
      technicalRequirementChecklists: row.technicalRequirementChecklists
    };

    if (Boolean(newItemState.generalTerms) && newItemState.generalTerms.length > 0)
      newItemState.generalTerms.splice(0, 1);
    else {
      newItemState.generalTerms = [];
    }
    if (Boolean(newItemState.techRequirements) && newItemState.techRequirements.length > 0)
      newItemState.techRequirements.splice(0, 1);
    else {
      newItemState.techRequirements = [];
    }
    if (Boolean(newItemState.pricePaymentInvoicing) && newItemState.pricePaymentInvoicing.length > 0)
      newItemState.pricePaymentInvoicing.splice(0, 1);
    else {
      newItemState.pricePaymentInvoicing = [];
    }
    if (Boolean(newItemState.paymentTerms) && newItemState.paymentTerms.length > 0)
      newItemState.paymentTerms.splice(0, 1);
    else {
      newItemState.paymentTerms = [];
    }
    if (Boolean(newItemState.deliveryPlaces) && newItemState.deliveryPlaces.length > 0)
      newItemState.deliveryPlaces.splice(0, 1);
    else {
      newItemState.deliveryPlaces = [];
    }
    if (Boolean(newItemState.taxes) && newItemState.taxes.length > 0)
      newItemState.taxes.splice(0, 1);
    else {
      newItemState.taxes = [];
    }
    if (Boolean(newItemState.essenceDeliveryDates) && newItemState.essenceDeliveryDates.length > 0)
      newItemState.essenceDeliveryDates.splice(0, 1);
    else {
      newItemState.essenceDeliveryDates = [];
    }
    if (Boolean(newItemState.warranties) && newItemState.warranties.length > 0)
      newItemState.warranties.splice(0, 1);
    else {
      newItemState.warranties = [];
    }
    if (Boolean(newItemState.languages) && newItemState.languages.length > 0)
      newItemState.languages.splice(0, 1);
    else {
      newItemState.languages = [];
    }
    if (Boolean(newItemState.requestedDocuments) && newItemState.requestedDocuments.length > 0)
      newItemState.requestedDocuments.splice(0, 1);
    else {
      newItemState.requestedDocuments = [];
    }
    if (Boolean(newItemState.terminations) && newItemState.terminations.length > 0)
      newItemState.terminations.splice(0, 1);
    else {
      newItemState.terminations = [];
    }
    if (Boolean(newItemState.confidentialities) && newItemState.confidentialities.length > 0)
      newItemState.confidentialities.splice(0, 1);
    else {
      newItemState.confidentialities = [];
    }
    if (Boolean(newItemState.inspectionAndAcceptances) && newItemState.inspectionAndAcceptances.length > 0)
      newItemState.inspectionAndAcceptances.splice(0, 1);
    else {
      newItemState.inspectionAndAcceptances = [];
    }
    if (Boolean(newItemState.competentCourt) && newItemState.competentCourt.length > 0)
      newItemState.competentCourt.splice(0, 1);
    else {
      newItemState.competentCourt = [];
    }
    if (Boolean(newItemState.liabilityInsurances) && newItemState.liabilityInsurances.length > 0)
      newItemState.liabilityInsurances.splice(0, 1);
    else {
      newItemState.liabilityInsurances = [];
    }
    if (Boolean(newItemState.intellectualProperties) && newItemState.intellectualProperties.length > 0)
      newItemState.intellectualProperties.splice(0, 1);
    else {
      newItemState.intellectualProperties = [];
    }
    if (Boolean(newItemState.contractRecords) && newItemState.contractRecords.length > 0)
      newItemState.contractRecords.splice(0, 1);
    else {
      newItemState.contractRecords = [];
    }
    if (Boolean(newItemState.informationPrivacies) && newItemState.informationPrivacies.length > 0)
      newItemState.informationPrivacies.splice(0, 1);
    else {
      newItemState.informationPrivacies = [];
    }
    if (Boolean(newItemState.miscellaneous) && newItemState.miscellaneous.length > 0)
      newItemState.miscellaneous.splice(0, 1);
    else {
      newItemState.miscellaneous = [];
    }
    if (Boolean(newItemState.additionsList) && newItemState.additionsList.length > 0)
      newItemState.additionsList.forEach(addition => {
        if (Boolean(addition.prModelList) && addition.prModelList.length > 0)
          addition.prModelList.splice(0, 1);
      });
    else {
      newItemState.additionsList = [];
    }

    newItem.validityValue = row.validityValue;
    newItem.quoteValidityDateType = row.quoteValidityDateType;
    newItem.receptionOptions = row.receptionOptions;
    newItem.transportOptions = row.transportOptions;

    this.formRef.current.setFieldsValue({
      ...row,
      ...newItem
    });

    let rfqVendor = { "id": 624 }

    this.setState({
      modalQuoteShow: true,
      rfqRow: row,
      rfqVendor: rfqVendor,
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      ...newItemState,
      typeReviseQuote: typeReviseQuote,
      receptionOptions: row.receptionOptions,
      transportOptions: row.transportOptions,
    });
  };


  sendModal = async () => {
    const newItem = {
      id: this.state.id,
    };
    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/send/" + newItem.id, newItem);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalSendShow: false,
      });
    }
  }

  render() {

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.printButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    }

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout1 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      style: {
        marginBottom: "0px"
      }
    };

    const layout2 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };

    const mapContainerStyle = {
      height: "300px",
      width: "100%"
    };

    const listUploadProps = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action,
        width: "%10"
      },
      {
        title: "S/N",
        width: 100,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: "RFQ No",
        width: 100,
        key: "id",
        render: record => {
          let rfqNo = "";
          if (record.id.toString().length === 2) {
            rfqNo = "RFQ-0" + record.id;
          } else if (record.id.toString().length === 1) {
            rfqNo = "RFQ-00" + record.id;
          } else {
            rfqNo = "RFQ-" + record.id;
          }
          return <React.Fragment>
            <Tag color={"blue"}>{rfqNo}</Tag>
          </React.Fragment>
        }
      },
      {
        title: "Rev No",
        width: 100,
        key: "revNo",
        render: (record) => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline",
                }}
                onClick={(e) => this.showRevisedList(record)}
              >
                {record.revNo}
              </div>
            </React.Fragment>
          );
        },
      },
      {
        title: "Item Description",
        key: "projectTakeOffListDescription",
        ellipsis: true,
        width: 100,
        render: record => {
          return (
            <Tooltip
              title={record.projectTakeOffListDescription}
            >
              {record.projectTakeOffListDescription}
            </Tooltip>
          )
        }
      },
      {
        title: "Code",
        key: "projectTakeOffListCode",
        ellipsis: true,
        width: 100,
        render: record => {
          return (
            <Tooltip
              title={record.projectTakeOffListCode}
            >
              {record.projectTakeOffListCode}
            </Tooltip>
          )
        }
      },
      {
        title: "Issue Date",
        key: "requestDate",
        ellipsis: true,
        width: 100,
        render: record => {
          return Boolean(record.sentToVendorsDate) ? moment(record.sentToVendorsDate).format("DD-MM-YYYY HH:mm:ss") : ""
        }
      },
      {
        title: "Validity Date",
        key: "validityValue",
        width: 100,
        render: record => {
          return record.dueDate;
        }
      },
      {
        title: "Mode of Reply",
        key: "modeOffReply",
        width: 100,
        render: record => {
          return record.modeOffReply;
        }
      },
      {
        title: "Status",
        key: "status",
        width: 100,
        render: record => {
          return record.status;
        }
      },
      {
        title: "Remarks",
        key: "remarks",
        width: 100,
        render: record => {
          return Boolean(record.remarks) &&
            <Tag color={"default"}>{record.remarks}</Tag>
        }
      }
    ];

    const revisedColumns = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: "RFQ No",
        key: "requestForQuotationId",
        render: record => {
          let rfqNo = "";
          if (record.requestForQuotationId.toString().length === 2) {
            rfqNo = "RFQ-0" + record.requestForQuotationId;
          } else if (record.requestForQuotationId.toString().length === 1) {
            rfqNo = "RFQ-00" + record.requestForQuotationId;
          } else {
            rfqNo = "RFQ-" + record.requestForQuotationId;
          }
          return <React.Fragment>
            <div
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
              onClick={(e) => this.generateReportRevision(record)}
            >
              <Tag color={"blue"}>{rfqNo}</Tag>
            </div>
          </React.Fragment>
        }
      },
      {
        title: "Rev No",
        key: "revNo",
        render: (record) => {
          return record.revNo;
        },
      },
      {
        title: "Item Description",
        key: "projectTakeOffListDescription",
        ellipsis: true,
        width: 100,
        render: record => {
          return (
            <Tooltip
              title={record.projectTakeOffListDescription}
            >
              {record.projectTakeOffListDescription}
            </Tooltip>
          )
        }
      },
      {
        title: "Code",
        key: "projectTakeOffListCode",
        ellipsis: true,
        width: 100,
        render: record => {
          return (
            <Tooltip
              title={record.projectTakeOffListCode}
            >
              {record.projectTakeOffListCode}
            </Tooltip>
          )
        }
      },
      {
        title: "Issue Date",
        key: "requestDate",
        ellipsis: true,
        width: 100,
        render: record => {
          return Boolean(record.sentToVendorsDate) ? moment(record.sentToVendorsDate).format("DD-MM-YYYY HH:mm:ss") : ""
        }
      },
      {
        title: "Validity Date",
        key: "validityValue",
        render: record => {
          return record.dueDate;
        }
      },
      {
        title: "Mode of Reply",
        key: "modeOffReply",
        render: record => {
          return record.modeOffReply;
        }
      },
      {
        title: "Status",
        key: "status",
        render: record => {
          return record.status;
        }
      },
      {
        title: "Remarks",
        key: "remarks",
        render: record => {
          return Boolean(record.remarks) &&
            <Tag color={"default"}>{record.remarks}</Tag>
        }
      }
    ];


    const columnsHistory = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.paginationHistory.pageSize * (this.state.paginationHistory.current - 1);
        }
      },
      {
        title: "Date",
        key: "date",
        render: record => {
          return Boolean(record.date) ? moment(record.date).format("DD-MM-YYYY HH:mm:ss") : "";
        }
      },
      {
        title: "Remarks",
        key: "remarks",
        render: record => {
          return record.remarks;
        }
      },
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns,
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));



    return (
      <Badge.Ribbon
        text={this.state.companyShortNameSelectItems + " Procurement Requirements"}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>

            <div hidden={this.state.hideInputs}>
              {/* <Form.Item
                {...layout}
                hidden={!this.state.id}
                label={<FormattedMessage id="ProcurementRequirements.item" defaultMessage="Item" />}
                style={{
                  marginBottom: 0
                }}
                name="projectTakeOffListString"
              >
                <Input readOnly style={{ width: "100%" }} />
              </Form.Item> */}

              {/* <Collapse accordion>
                {this.state.generalTerms && this.state.generalTerms.length > 0 &&
                  <Panel header="General Terms and Conditions" key="1">
                    {this.state.generalTerms.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    generalTerms: this.state.generalTerms,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      generalTerms: this.state.generalTerms,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}

                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.generalTermsFileUrl" defaultMessage="File" />}
                      name={"generalTermsFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.techRequirements && this.state.techRequirements.length > 0 &&
                  <Panel header="Technical Requirements" key="2">
                    {this.state.techRequirements.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    techRequirements: this.state.techRequirements,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      techRequirements: this.state.techRequirements,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}

                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.techRequirementsFileUrl" defaultMessage="File" />}
                      name={"techRequirementsFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.pricePaymentInvoicing && this.state.pricePaymentInvoicing.length > 0 &&
                  <Panel header="Price, payment and invoicing" key="3">
                    {this.state.pricePaymentInvoicing.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    pricePaymentInvoicing: this.state.pricePaymentInvoicing,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      pricePaymentInvoicing: this.state.pricePaymentInvoicing,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}

                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.pricePaymentInvoicingFileUrl" defaultMessage="File" />}
                      name={"pricePaymentInvoicingFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>

                    <RfqVendorReply
                      requestForQuotation={this.state.rfqRow}
                      vendor={this.state.rfqVendor}
                      random={Math.random()}
                    />
                  </Panel>
                }

                {this.state.paymentTerms && this.state.paymentTerms.length > 0 &&
                  <Panel header="Payment Terms" key="4">
                    {this.state.paymentTerms.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    paymentTerms: this.state.paymentTerms,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      paymentTerms: this.state.paymentTerms,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}

                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.paymentTermsFileUrl" defaultMessage="File" />}
                      name={"paymentTermsFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.deliveryPlaces && this.state.deliveryPlaces.length > 0 &&
                  <Panel header="Delivery Place" key="5">
                    {this.state.deliveryPlaces.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    paymentTerms: this.state.paymentTerms,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      paymentTerms: this.state.paymentTerms,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.deliveryPlacesFileUrl" defaultMessage="File" />}
                      name={"deliveryPlacesFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.taxes && this.state.taxes.length > 0 &&
                  <Panel header="Taxes" key="6">
                    {this.state.taxes.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    taxes: this.state.taxes,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      taxes: this.state.taxes,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}

                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.taxesFileUrl" defaultMessage="File" />}
                      name={"taxesFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.essenceDeliveryDates && this.state.essenceDeliveryDates.length > 0 &&
                  <Panel header="Time of essence/Delivery dates" key="7">
                    {this.state.essenceDeliveryDates.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    essenceDeliveryDates: this.state.essenceDeliveryDates,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      essenceDeliveryDates: this.state.essenceDeliveryDates,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}

                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.essenceDeliveryDatesFileUrl" defaultMessage="File" />}
                      name={"essenceDeliveryDatesFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                <Panel header="Validity of Quotation" key="8">
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.validityValue" defaultMessage="Validity Value" />}
                      name="validityValue"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <InputNumber disabled />
                    </Form.Item>
        <DraggableModal
          title="History"
          visible={this.state.modalHistoryShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalHistoryShow: false })}
          width={1200}
          content={
            <Table
            bordered
            rowKey={record => record.id}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columnsHistory}
            dataSource={this.state.historyList}
            loading={this.state.loading}
            pagination={this.state.paginationHistory}
          />
          }
        >
         
        </DraggableModal>

        <DraggableModal
          title="Status"
          visible={this.state.modalStatusShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalStatusShow: false })}
          width={800}
          content={<>
           <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 ml-5 align-content-center">
            <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
            <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
            <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
            <div className="timeline timeline-5 mt-3 pr-5 pl-5 mr-5 ml-5">
              {Boolean(this.state.statusList) && this.state.statusList.length > 0 && this.state.statusList.map((item, index) => {
                return (
                  <div key={index} className="timeline-item align-items-center">
                    <Row>
                      <Col className="pr-5 pl-5 mr-5 ml-5"><div className="timeline-label font-weight-bolder text-dark-75 font-size-lg"><Tag color={item.color}>{item.status}</Tag></div></Col>
                      <Col className="pr-5 pl-5 mr-5 ml-5"><div className="timeline-badge">{index + 1}</div></Col>
                      <Col className="pr-5 pl-5 mr-5 ml-5"><Tag color={"default"}>04.07.2022</Tag></Col>
                      <Col className="pr-5 pl-5 mr-5 ml-5">
                        <div className="timeline-content d-flex">
                          <span className="font-weight-bolder text-dark-75 pl-12 font-size-lg">
                            <h3>
                              {Boolean(item.color) && item.color === "green" ?
                                <>
                                  <DoneIcon htmlColor={item.color} fontSize="large" />
                                </>
                                : <Forward htmlColor="lime" fontSize="large" />
                              }
                            </h3>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
          </div>
          </>}
        >

         
        </DraggableModal >

        <DraggableModal
          title="Revised Procurement Requirements"
          visible={this.state.showRevisedListModal}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ showRevisedListModal: false })}
          width={1500}
          content={
<Table
            bordered
            scroll={{ x: 400, scrollToFirstRowOnChange: true }}
            columns={revisedColumns}
            dataSource={this.state.revisedList}
          />
          }
        >
          
        </DraggableModal>

        <DraggableModal
          title="Upload"
          visible={this.state.modalUploadShow}
          onCancel={() => this.setState({ modalUploadShow: false })}
          onOk={() => this.saveUpload()}
          width={600}
          content={
            <Form.Item
            {...layout}
            label={<FormattedMessage id="ProcurementRequirements.fileUrl" defaultMessage="File" />}
            name={"fileUrl"}
            rules={[{ required: false, message: "Missing Load" }]}
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload {...props} id="fileUrl"
              showUploadList={true}
              fileList={this.state.fileList}
              onChange={({ fileList }) => {
                if (fileList.length === 1) {
                  var fileUrl = "";
                  if (Boolean(fileList[0].response)) {
                    fileUrl = fileList[0].response.url;
                    showSuccess("file uploaded successfully");
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.quoteValidityDateType" defaultMessage="Validity Type" />}
                      name="quoteValidityDateType"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Select disabled style={{ width: "100%" }}>
                        {this.state.quoteValidityDateTypeSelectItems.map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  }
                </Panel>
                <Panel header="Reception of Quotation" key="9">
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.receptionOptions" defaultMessage="Reception of Quotation" />}
                      name="receptionOptions"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Radio.Group disabled
                        onChange={e => {
                          this.setState({
                            receptionOptions: e.target.value
                          });
                        }}
                      >
                        {this.state.receptionOptionsSelectItems.map(i => (
                          <Radio key={i.key} value={i.key}>
                            {i.value}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  }

                  {this.state.receptionOptions === "OTHER" && (
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.receptionOtherValue" defaultMessage="Other Value" />}
                      name="receptionOtherValue"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  )}
                </Panel>
                <Panel header="Mode of Transport" key="10">
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.transportOptions" defaultMessage="Mode of Transport" />}
                      name="transportOptions"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Radio.Group disabled
                        onChange={e => {
                          this.setState({
                            transportOptions: e.target.value
                          });
                        }}
                      >
                        {this.state.transportOptionsSelectItems.map(i => (
                          <Radio key={i.key} value={i.key}>
                            {i.value}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  }

                  {this.state.transportOptions === "OTHER" && (
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.transportOtherValue" defaultMessage="Other Value" />}
                      name="transportOtherValue"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  )}
                </Panel>

                {this.state.warranties && this.state.warranties.length > 0 &&
                  <Panel header="Warranty" key="11">
                    {this.state.warranties.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    warranties: this.state.warranties,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      warranties: this.state.warranties,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.warrantiesFileUrl" defaultMessage="File" />}
                      name={"warrantiesFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.languages && this.state.languages.length > 0 &&
                  <Panel header="Language" key="12">
                    {this.state.languages.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    languages: this.state.languages,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      languages: this.state.languages,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.languagesFileUrl" defaultMessage="File" />}
                      name={"languagesFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.requestedDocuments && this.state.requestedDocuments.length > 0 &&
                  <Panel header="Requested documents" key="13">
                    {this.state.requestedDocuments.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    requestedDocuments: this.state.requestedDocuments,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      requestedDocuments: this.state.requestedDocuments,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.requestedDocumentsFileUrl" defaultMessage="File" />}
                      name={"requestedDocumentsFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.terminations && this.state.terminations.length > 0 &&
                  <Panel header="Termination" key="14">
                    {this.state.terminations.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    terminations: this.state.terminations,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      terminations: this.state.terminations,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.terminationsFileUrl" defaultMessage="File" />}
                      name={"terminationsFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.confidentialities && this.state.confidentialities.length > 0 &&
                  <Panel header="Confidentiality, privacy and publicity" key="15">
                    {this.state.confidentialities.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    confidentialities: this.state.confidentialities,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      confidentialities: this.state.confidentialities,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.confidentialitiesFileUrl" defaultMessage="File" />}
                      name={"confidentialitiesFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.inspectionAndAcceptances && this.state.inspectionAndAcceptances.length > 0 &&
                  <Panel header="Inspection and acceptance" key="16">
                    {this.state.inspectionAndAcceptances.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    inspectionAndAcceptances: this.state.inspectionAndAcceptances,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      inspectionAndAcceptances: this.state.inspectionAndAcceptances,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.inspectionAndAcceptancesFileUrl" defaultMessage="File" />}
                      name={"inspectionAndAcceptancesFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.competentCourt && this.state.competentCourt.length > 0 &&
                  <Panel header="Governing law and competent court" key="17">
                    {this.state.competentCourt.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    competentCourt: this.state.competentCourt,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      competentCourt: this.state.competentCourt,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.competentCourtFileUrl" defaultMessage="File" />}
                      name={"competentCourtFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.liabilityInsurances && this.state.liabilityInsurances.length > 0 &&
                  <Panel header="Liability and insurance" key="18">
                    {this.state.liabilityInsurances.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>

                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    liabilityInsurances: this.state.liabilityInsurances,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      liabilityInsurances: this.state.liabilityInsurances,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.liabilityInsurancesFileUrl" defaultMessage="File" />}
                      name={"liabilityInsurancesFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.intellectualProperties && this.state.intellectualProperties.length > 0 &&
                  <Panel header="Intellectual property" key="19">
                    {this.state.intellectualProperties.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    intellectualProperties: this.state.intellectualProperties,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      intellectualProperties: this.state.intellectualProperties,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.intellectualPropertiesFileUrl" defaultMessage="File" />}
                      name={"intellectualPropertiesFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.contractRecords && this.state.contractRecords.length > 0 &&
                  <Panel header="Contract records" key="20">
                    {this.state.contractRecords.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    contractRecords: this.state.contractRecords,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      contractRecords: this.state.contractRecords,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.contractRecordsFileUrl" defaultMessage="File" />}
                      name={"contractRecordsFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.informationPrivacies && this.state.informationPrivacies.length > 0 &&
                  <Panel header="Freedom of information and privacy" key="21">
                    {this.state.informationPrivacies.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    informationPrivacies: this.state.informationPrivacies,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      informationPrivacies: this.state.informationPrivacies,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.informationPrivaciesFileUrl" defaultMessage="File" />}
                      name={"informationPrivaciesFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.miscellaneous && this.state.miscellaneous.length > 0 &&
                  <Panel header="Miscellaneous" key="22">
                    {this.state.miscellaneous.map((item, index) => (
                      <Form.Item key={index} {...layout1} label={index + 1}>
                        <Row gutter={[8, 8]}>
                          <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                          <Col span={1}>
                            <Form.Item>
                              <Checkbox
                                onChange={e => {
                                  item.check = e.target.checked;
                                  this.setState({
                                    miscellaneous: this.state.miscellaneous,
                                  });
                                }}
                                checked={item.check}
                              />
                            </Form.Item>
                          </Col>

                          {!item.check &&
                            <Col span={6}>
                              <Form.Item>
                                <Input
                                  value={item.remarks}
                                  onChange={e => {
                                    item.remarks = e.target.value;
                                    this.setState({
                                      miscellaneous: this.state.miscellaneous,
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                        </Row>
                      </Form.Item>
                    ))}
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProcurementRequirements.miscellaneousFileUrl" defaultMessage="File" />}
                      name={"miscellaneousFileUrl"}
                      rules={[{ required: false, message: "Missing Load" }]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload {...listUploadProps} name="file" maxCount="1">
                        <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                      </Upload>
                    </Form.Item>
                  </Panel>
                }

                {this.state.additionsList.map((a, i) => {
                  return (
                    (Boolean(this.state.additionsList[i].prModelList) && this.state.additionsList[i].prModelList.length > 0)
                    &&
                    <Panel
                      header={a.description}
                      key={23 + i}
                    >
                      {this.state.additionsList[i].prModelList.map((item, index) => (
                        <Form.Item {...layout1} style={{ marginBottom: "2px" }} label={index + 1} key={i + "-" + index}>
                          <Row gutter={[8, 8]} className="mb-2">
                            <Col span={9}>{item.description}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    let { additionsList } = this.state;
                                    item.check = e.target.checked;
                                    additionsList[i].prModelList[index] = item;
                                    this.setState({ additionsList: additionsList });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      let { additionsList } = this.state;
                                      item.remarks = e.target.value;
                                      additionsList[i].prModelList[index] = item;
                                      this.setState({ additionsList: additionsList });
                                    }}
                                  />
                                </Form.Item>
                              </Col>}

                          </Row>
                        </Form.Item>
                      ))}
                    </Panel>
                  );
                })}
              </Collapse> */}

              <br />
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form >

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              rowKey={record => record.id}
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              components={{
                header: {
                  cell: ResizableTitle
                }
              }}
              columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal
            show={this.state.modalDeleteShow}
            onHide={this.showOrHideDeleteModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
              <AntButton style={{ marginLeft: "auto" }} onClick={this.showOrHideDeleteModal} icon={<CloseIcon color="secondary" />}></AntButton>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            size="xl"
            show={this.state.modalPdfViewer}
            onHide={this.showOrHideModalPdfViewer}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                <div style={{ height: "900px" }}>
                  <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                </div>
              </Worker>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideModalPdfViewer}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <AntModal
            title="History"
            visible={this.state.modalHistoryShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalHistoryShow: false })}
            width={1200}
          >
            <Table
              bordered
              rowKey={record => record.id}
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columnsHistory}
              dataSource={this.state.historyList}
              loading={this.state.loading}
              pagination={this.state.paginationHistory}
            />
          </AntModal>

          <AntModal
            title="Status"
            visible={this.state.modalStatusShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalStatusShow: false })}
            width={800}
          >

            <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 ml-5 align-content-center">
              <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
              <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
              <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
              <div className="timeline timeline-5 mt-3 pr-5 pl-5 mr-5 ml-5">
                {Boolean(this.state.statusList) && this.state.statusList.length > 0 && this.state.statusList.map((item, index) => {
                  return (
                    <div key={index} className="timeline-item align-items-center">
                      <Row>
                        <Col className="pr-5 pl-5 mr-5 ml-5"><div className="timeline-label font-weight-bolder text-dark-75 font-size-lg"><Tag color={item.color}>{item.status}</Tag></div></Col>
                        <Col className="pr-5 pl-5 mr-5 ml-5"><div className="timeline-badge">{index + 1}</div></Col>
                        <Col className="pr-5 pl-5 mr-5 ml-5"><Tag color={"default"}>04.07.2022</Tag></Col>
                        <Col className="pr-5 pl-5 mr-5 ml-5">
                          <div className="timeline-content d-flex">
                            <span className="font-weight-bolder text-dark-75 pl-12 font-size-lg">
                              <h3>
                                {Boolean(item.color) && item.color === "green" ?
                                  <>
                                    <DoneIcon htmlColor={item.color} fontSize="large" />
                                  </>
                                  : <Forward htmlColor="lime" fontSize="large" />
                                }
                              </h3>
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </div>
            </div>
          </AntModal >

          <AntModal
            title="Revised Procurement Requirements"
            visible={this.state.showRevisedListModal}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ showRevisedListModal: false })}
            width={1500}
          >
            <Table
              bordered
              scroll={{ x: 400, scrollToFirstRowOnChange: true }}
              columns={revisedColumns}
              dataSource={this.state.revisedList}
            />
          </AntModal>

          <AntModal
            title="Upload"
            open={this.state.modalUploadShow}
            onCancel={() => this.setState({ modalUploadShow: false })}
            onOk={() => this.saveUpload()}
            width={600}
          >
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProcurementRequirements.fileUrl" defaultMessage="File" />}
              name={"fileUrl"}
              rules={[{ required: false, message: "Missing Load" }]}
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload {...props} id="fileUrl"
                showUploadList={true}
                fileList={this.state.fileList}
                onChange={({ fileList }) => {
                  if (fileList.length === 1) {
                    var fileUrl = "";
                    if (Boolean(fileList[0].response)) {
                      fileUrl = fileList[0].response.url;
                      showSuccess("file uploaded successfully");
                    }
                    this.setState({
                      fileList: fileList,
                      fileUrl: fileUrl,
                    });
                  }
                }}
                beforeUpload={(fileList) => {
                  if (this.state.fileList.length > 0) {
                    showError("You have been already choose a file! For change firstly remove the uploaded file.");
                    return false;
                  }
                  return true;
                }}
                onRemove={({ fileList }) => {
                  this.setState({
                    fileList: [],
                    fileUrl: "",
                  })
                }
                }>
                <AntButton icon={<UploadOutlined />}> Upload </AntButton>
              </Upload>
            </Form.Item>

          </AntModal>

          <AntModal
            title="Send"
            open={this.state.modalSendShow}
            onCancel={() => this.setState({ modalSendShow: false })}
            onOk={() => this.sendModal()}
            width={600}
          >
            <p>Are you sure?</p>
          </AntModal>

          <DraggableModal
            title={
              <span>
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  <FormattedMessage id="ProcurementRequirements.SendToRFQ" defaultMessage="Send To RFQ" />
                </span>
              </span>
            }
            centered
            open={this.state.modalPostShow}
            onOk={() => this.showSendModal(this.state.selectedRow)}
            onCancel={() => this.setState({ modalPostShow: false })}
            okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Yes" />}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="No" />}
            content={
              <FormattedMessage id="ProcurementRequirements.MessageSendRFQToVendors" defaultMessage="Do you want post your Quotation" />
            }
          />

          <DraggableModal
            title={
              <span>
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  {"RFQ-" + this.state.id + " "}
                </span>
              </span>
            }
            centered
            width={1000}
            open={this.state.modalQuoteShow}
            onOk={() => {
              console.log("Ok tuşuna basıldı.")
            }}
            onCancel={() => this.setState({ modalQuoteShow: false })}
            okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Confirm" />}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="No" />}
            content={
              <>
                {/* <Form.Item
                  {...layout}
                  hidden={!this.state.id}
                  label={"RFQ-" + this.state.id}
                  style={{
                    marginBottom: 0
                  }}
                  name="projectTakeOffListString"
                >
                  <Input readOnly style={{ width: "100%" }} />
                </Form.Item> */}

                <Collapse accordion>
                  {this.state.generalTerms && this.state.generalTerms.length > 0 &&
                    <Panel header="General Terms and Conditions" key="1">
                      {this.state.generalTerms.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      generalTerms: this.state.generalTerms,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        generalTerms: this.state.generalTerms,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}

                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.generalTermsFileUrl" defaultMessage="File" />}
                        name={"generalTermsFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.techRequirements && this.state.techRequirements.length > 0 &&
                    <Panel header="Technical Requirements" key="2">
                      {this.state.techRequirements.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      techRequirements: this.state.techRequirements,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        techRequirements: this.state.techRequirements,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}

                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.techRequirementsFileUrl" defaultMessage="File" />}
                        name={"techRequirementsFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.pricePaymentInvoicing && this.state.pricePaymentInvoicing.length > 0 &&
                    <Panel header="Price, payment and invoicing" key="3">
                      {/* {this.state.pricePaymentInvoicing.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      pricePaymentInvoicing: this.state.pricePaymentInvoicing,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        pricePaymentInvoicing: this.state.pricePaymentInvoicing,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))} */}

                      {/* <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.pricePaymentInvoicingFileUrl" defaultMessage="File" />}
                        name={"pricePaymentInvoicingFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item> */}

                      <div style={{ fontWeight: 'bold', color: 'blue', textAlign: 'center' }}>
                        Please indicate your prices, delivering dates and payment terms in the below table.
                      </div>

                      <RfqVendorReply
                        requestForQuotation={this.state.rfqRow}
                        vendor={this.state.rfqVendor}
                        random={Math.random()}
                      />
                    </Panel>
                  }

                  {this.state.paymentTerms && this.state.paymentTerms.length > 0 &&
                    <Panel header="Payment Terms" key="4">
                      {this.state.paymentTerms.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      paymentTerms: this.state.paymentTerms,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        paymentTerms: this.state.paymentTerms,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}

                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.paymentTermsFileUrl" defaultMessage="File" />}
                        name={"paymentTermsFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.deliveryPlaces && this.state.deliveryPlaces.length > 0 &&
                    <Panel header="Delivery Place" key="5">
                      {this.state.deliveryPlaces.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      paymentTerms: this.state.paymentTerms,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        paymentTerms: this.state.paymentTerms,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.deliveryPlacesFileUrl" defaultMessage="File" />}
                        name={"deliveryPlacesFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.taxes && this.state.taxes.length > 0 &&
                    <Panel header="Taxes" key="6">
                      {this.state.taxes.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      taxes: this.state.taxes,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        taxes: this.state.taxes,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}

                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.taxesFileUrl" defaultMessage="File" />}
                        name={"taxesFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.essenceDeliveryDates && this.state.essenceDeliveryDates.length > 0 &&
                    <Panel header="Time of essence/Delivery dates" key="7">
                      {this.state.essenceDeliveryDates.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      essenceDeliveryDates: this.state.essenceDeliveryDates,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        essenceDeliveryDates: this.state.essenceDeliveryDates,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}

                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.essenceDeliveryDatesFileUrl" defaultMessage="File" />}
                        name={"essenceDeliveryDatesFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  <Panel header="Validity of Quotation" key="8">
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.validityValue" defaultMessage="Validity Value" />}
                        name="validityValue"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <InputNumber disabled />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.quoteValidityDateType" defaultMessage="Validity Type" />}
                        name="quoteValidityDateType"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Select disabled style={{ width: "100%" }}>
                          {this.state.quoteValidityDateTypeSelectItems.map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    }
                  </Panel>
                  <Panel header="Reception of Quotation" key="9">
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.receptionOptions" defaultMessage="Reception of Quotation" />}
                        name="receptionOptions"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Radio.Group disabled
                          onChange={e => {
                            this.setState({
                              receptionOptions: e.target.value
                            });
                          }}
                        >
                          {this.state.receptionOptionsSelectItems.map(i => (
                            <Radio key={i.key} value={i.key}>
                              {i.value}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    }

                    {this.state.receptionOptions === "OTHER" && (
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.receptionOtherValue" defaultMessage="Other Value" />}
                        name="receptionOtherValue"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                    )}
                  </Panel>
                  <Panel header="Mode of Transport" key="10">
                    {
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.transportOptions" defaultMessage="Mode of Transport" />}
                        name="transportOptions"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Radio.Group disabled
                          onChange={e => {
                            this.setState({
                              transportOptions: e.target.value
                            });
                          }}
                        >
                          {this.state.transportOptionsSelectItems.map(i => (
                            <Radio key={i.key} value={i.key}>
                              {i.value}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    }

                    {this.state.transportOptions === "OTHER" && (
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.transportOtherValue" defaultMessage="Other Value" />}
                        name="transportOtherValue"
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                    )}
                  </Panel>

                  {this.state.warranties && this.state.warranties.length > 0 &&
                    <Panel header="Warranty" key="11">
                      {this.state.warranties.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      warranties: this.state.warranties,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        warranties: this.state.warranties,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.warrantiesFileUrl" defaultMessage="File" />}
                        name={"warrantiesFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.languages && this.state.languages.length > 0 &&
                    <Panel header="Language" key="12">
                      {this.state.languages.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      languages: this.state.languages,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        languages: this.state.languages,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.languagesFileUrl" defaultMessage="File" />}
                        name={"languagesFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.requestedDocuments && this.state.requestedDocuments.length > 0 &&
                    <Panel header="Requested documents" key="13">
                      {this.state.requestedDocuments.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      requestedDocuments: this.state.requestedDocuments,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        requestedDocuments: this.state.requestedDocuments,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.requestedDocumentsFileUrl" defaultMessage="File" />}
                        name={"requestedDocumentsFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.terminations && this.state.terminations.length > 0 &&
                    <Panel header="Termination" key="14">
                      {this.state.terminations.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      terminations: this.state.terminations,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        terminations: this.state.terminations,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.terminationsFileUrl" defaultMessage="File" />}
                        name={"terminationsFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.confidentialities && this.state.confidentialities.length > 0 &&
                    <Panel header="Confidentiality, privacy and publicity" key="15">
                      {this.state.confidentialities.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      confidentialities: this.state.confidentialities,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        confidentialities: this.state.confidentialities,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.confidentialitiesFileUrl" defaultMessage="File" />}
                        name={"confidentialitiesFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.inspectionAndAcceptances && this.state.inspectionAndAcceptances.length > 0 &&
                    <Panel header="Inspection and acceptance" key="16">
                      {this.state.inspectionAndAcceptances.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      inspectionAndAcceptances: this.state.inspectionAndAcceptances,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        inspectionAndAcceptances: this.state.inspectionAndAcceptances,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.inspectionAndAcceptancesFileUrl" defaultMessage="File" />}
                        name={"inspectionAndAcceptancesFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.competentCourt && this.state.competentCourt.length > 0 &&
                    <Panel header="Governing law and competent court" key="17">
                      {this.state.competentCourt.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      competentCourt: this.state.competentCourt,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        competentCourt: this.state.competentCourt,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.competentCourtFileUrl" defaultMessage="File" />}
                        name={"competentCourtFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.liabilityInsurances && this.state.liabilityInsurances.length > 0 &&
                    <Panel header="Liability and insurance" key="18">
                      {this.state.liabilityInsurances.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>

                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      liabilityInsurances: this.state.liabilityInsurances,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        liabilityInsurances: this.state.liabilityInsurances,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.liabilityInsurancesFileUrl" defaultMessage="File" />}
                        name={"liabilityInsurancesFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.intellectualProperties && this.state.intellectualProperties.length > 0 &&
                    <Panel header="Intellectual property" key="19">
                      {this.state.intellectualProperties.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      intellectualProperties: this.state.intellectualProperties,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        intellectualProperties: this.state.intellectualProperties,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.intellectualPropertiesFileUrl" defaultMessage="File" />}
                        name={"intellectualPropertiesFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.contractRecords && this.state.contractRecords.length > 0 &&
                    <Panel header="Contract records" key="20">
                      {this.state.contractRecords.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      contractRecords: this.state.contractRecords,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        contractRecords: this.state.contractRecords,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.contractRecordsFileUrl" defaultMessage="File" />}
                        name={"contractRecordsFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.informationPrivacies && this.state.informationPrivacies.length > 0 &&
                    <Panel header="Freedom of information and privacy" key="21">
                      {this.state.informationPrivacies.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      informationPrivacies: this.state.informationPrivacies,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        informationPrivacies: this.state.informationPrivacies,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.informationPrivaciesFileUrl" defaultMessage="File" />}
                        name={"informationPrivaciesFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.miscellaneous && this.state.miscellaneous.length > 0 &&
                    <Panel header="Miscellaneous" key="22">
                      {this.state.miscellaneous.map((item, index) => (
                        <Form.Item key={index} {...layout1} label={index + 1}>
                          <Row gutter={[8, 8]}>
                            <Col span={9}>{<Form.Item>{item.description}</Form.Item>}</Col>
                            <Col span={1}>
                              <Form.Item>
                                <Checkbox
                                  onChange={e => {
                                    item.check = e.target.checked;
                                    this.setState({
                                      miscellaneous: this.state.miscellaneous,
                                    });
                                  }}
                                  checked={item.check}
                                />
                              </Form.Item>
                            </Col>

                            {!item.check &&
                              <Col span={6}>
                                <Form.Item>
                                  <Input
                                    value={item.remarks}
                                    onChange={e => {
                                      item.remarks = e.target.value;
                                      this.setState({
                                        miscellaneous: this.state.miscellaneous,
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </Col>}
                          </Row>
                        </Form.Item>
                      ))}
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="ProcurementRequirements.miscellaneousFileUrl" defaultMessage="File" />}
                        name={"miscellaneousFileUrl"}
                        rules={[{ required: false, message: "Missing Load" }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Upload {...listUploadProps} name="file" maxCount="1">
                          <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                        </Upload>
                      </Form.Item>
                    </Panel>
                  }

                  {this.state.additionsList.map((a, i) => {
                    return (
                      (Boolean(this.state.additionsList[i].prModelList) && this.state.additionsList[i].prModelList.length > 0)
                      &&
                      <Panel
                        header={a.description}
                        key={23 + i}
                      >
                        {this.state.additionsList[i].prModelList.map((item, index) => (
                          <Form.Item {...layout1} style={{ marginBottom: "2px" }} label={index + 1} key={i + "-" + index}>
                            <Row gutter={[8, 8]} className="mb-2">
                              <Col span={9}>{item.description}</Col>
                              <Col span={1}>
                                <Form.Item>
                                  <Checkbox
                                    onChange={e => {
                                      let { additionsList } = this.state;
                                      item.check = e.target.checked;
                                      additionsList[i].prModelList[index] = item;
                                      this.setState({ additionsList: additionsList });
                                    }}
                                    checked={item.check}
                                  />
                                </Form.Item>
                              </Col>

                              {!item.check &&
                                <Col span={6}>
                                  <Form.Item>
                                    <Input
                                      value={item.remarks}
                                      onChange={e => {
                                        let { additionsList } = this.state;
                                        item.remarks = e.target.value;
                                        additionsList[i].prModelList[index] = item;
                                        this.setState({ additionsList: additionsList });
                                      }}
                                    />
                                  </Form.Item>
                                </Col>}

                            </Row>
                          </Form.Item>
                        ))}
                      </Panel>
                    );
                  })}
                </Collapse>

                {

                  <Button id="UpdateButton" style={{ width: "100%", marginTop: "10px" }} variant="warning" type="submit">
                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                  </Button>
                }

              </>
            }
          />

          <DraggableModal
            title="Send"
            visible={this.state.modalSendShow}
            onCancel={() => this.setState({ modalSendShow: false })}
            onOk={() => this.sendModal()}
            width={600}
            content={
              <>
                <p>Are you sure?</p>
              </>
            }
          >

          </DraggableModal>

        </div >
      </Badge.Ribbon>

    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProcurementRequirements);
