import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { Button, Modal } from 'react-bootstrap';
import { FilterOutlined } from '@ant-design/icons';
import { handleRequest } from '../../ApiConnector';
import { API_BASE_ROOT } from '../../ApiConnector';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import CustomEditReviseDeleteMenu from '../CustomMenu/CustomEditReviseDeleteMenu';
import CreateIcon from '@material-ui/icons/AddCircle';
import ExportIcon from '@material-ui/icons/CloudDownloadRounded';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { Form, message, Table, Input, Select, Col, Row, DatePicker, Button as AntButton, InputNumber, Divider } from 'antd';
import { ExcelReader } from '../excel/ExcelReader';
import moment from 'moment';
import { Upload } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import PageviewIcon from '@material-ui/icons/Pageview';

const { Option } = Select;

function JSDateToExcelDate(inDate) {
    var returnDateTime = 25569.0 + ((inDate.getTime() - (inDate.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));
    return returnDateTime.toString().substr(0, 20);
}

export class SatipLog extends Component {

    constructor() {
        super();
        this.state = {

            modalDeleteShow: false,
            exportList: [],
            exportClick: false,

            hideUpdate: true,
            hideSave: false,
            hiddenInputs: true,
            filePaths: [],
            fileList: [],
            bulkFileList: [],
            showRevisedListModal: false,
            revisedFileList: [],
            modalPdfViewer: false,
            pdfFile: "",
            codeName: "",
            disciplineSelectItems: [],
            id: null,
            selectedRow: null,
            revising: false,
            index: null,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['2', '10', '25', '50', '100', '1000', '10000', '100000', '1000000'],
            },
            loading: false,
            sortField: "",
            sortOrder: "",
        };

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });

        this.fillDisciplines();
    };

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hiddenInputs: true,
        });
        this.resetInputs();
    };

    resetInputs = () => {

        this.formRef.current.resetFields();

        this.setState({
            filePath: '',
            filePaths: [],
            fileList: [],
            bulkFileList: [],
            id: null,
            revising: false,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['2', '10', '25', '50', '100', '1000', '10000', '100000', '1000000'],
            },
        })
    }

    error = (response) => {
        try {
            if (Boolean(response.response) && response.response.status === 403)
                window.location.href = "/logout";
            else
                this.showError((Boolean(response.response) && Boolean(response.response.data)) ? response.response.data.message : "");
        } catch (error) {
        }
    }

    edit = (row) => {

        let fileList = [];

        let pathList = Boolean(row.filePath) ? row.filePath.split(",") : [];
        if (Boolean(pathList) && pathList.length > 0) {

            pathList.forEach((element, index) => {
                let obj = {
                    uid: index,
                    name: element.split("download/")[1],
                    url: element,
                    thumbUrl: element,
                };

                fileList.push(obj);
            });
        }


        this.formRef.current.setFieldsValue({
            ...row,
            issueDate: moment(row.issueDate),


        });


        this.setState({
            hideUpdate: false,
            hideSave: true,
            hiddenInputs: false,

            id: row.id,
            fileList: fileList,
            filePaths: Boolean(row.filePath) ? row.filePath.split(",") : [],

        });
    };

    revise = (row) => {

        let fileList = [];

        let pathList = Boolean(row.filePath) ? row.filePath.split(",") : [];
        if (Boolean(pathList) && pathList.length > 0) {

            pathList.forEach((element, index) => {
                let obj = {
                    uid: index,
                    name: element.split("download/")[1],
                    url: element,
                    thumbUrl: element,
                };

                fileList.push(obj);
            });
        }



        this.formRef.current.setFieldsValue({
            ...row,
            revNo: row.revNo + 1,
            issueDate: moment(row.issueDate),
        });

        this.setState({
            hideUpdate: false,
            hideSave: true,
            hiddenInputs: false,

            id: row.id,
            fileList: fileList,
            filePaths: Boolean(row.filePath) ? row.filePath.split(",") : [],
            revising: true
        });
    };

    showRevisedList = async (row) => {

        let url = "/api/revisedchecklist/checklist/" + row.id;

        const response = await handleRequest("GET", url);

        if (response.type === "ERROR") {
            this.error(response);
        }
        else
            if (Boolean(response.data) && response.data.length > 0) {
                this.setState({
                    revisedFileList: response.data,
                    showRevisedListModal: true
                });
            } else {

                this.setState({
                    revisedFileList: [],
                    showRevisedListModal: false
                });

                this.showError("There is no revised list for selected item");
            }
    }



    openShareModal = (row) => {

        this.setState({
            shareFileId: row.id,
            showShareModal: true
        });

    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }



    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        }
        var response = await handleRequest("DELETE", "/api/satiplog/" + deletedItem.id);

        if (response.type === "ERROR") {
            this.error(response);
        }
        else {
            const { pagination } = this.state;
            this.restartTable({ pagination });
            this.showSuccess();
            this.setState({
                id: null,
                modalDeleteShow: false
            });
        }
    }






    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters, });
    }

    restartTable = async (params = {}) => {

        this.setState({ loading: true });
        const newObj = this.createWhere(params);

        let url = "/api/satiplog/paging";
        const response = await handleRequest("POST", url, newObj);
        if (response.type === "ERROR") {
            this.error(response);
            this.setState({
                loading: false,
                tableList: [],
                pagination: {
                    ...params.pagination,
                    total: 0,
                },
                sortField: "",
                sortOrder: "",
            });
        }
        else {
            if (response.data.length !== 0) {
                let list = response.data.data;
                list.forEach((element, index) => {
                    element.sn = index + 1;
                });
                this.setState({
                    loading: false,
                    tableList: list,
                    pagination: {
                        ...params.pagination,
                        total: response.data.totalRecords,
                    },
                    sortField: params.sortField,
                    sortOrder: params.sortOrder,
                });
            } else {
                this.setState({
                    loading: false,
                    tableList: [],
                    pagination: {
                        ...params.pagination,
                        total: 0,
                    },
                    sortField: "",
                    sortOrder: "",
                });
            }
        }
    };

    onClickCreateNew = async () => {
        this.resetInputs();
        if (this.state.hiddenInputs) {
            this.setState({
                hiddenInputs: false,
                hiddenSave: false,
                hiddenUpdate: true,
            });
        }
        else {
            this.setState({
                hiddenInputs: true,
                hiddenSave: true,
                hiddenUpdate: false,
            });
        }
    }

    save = async (values) => {

        const { filePaths } = this.state;

        const newItem = {
            ...values,
            id: this.state.id,
            issueDate: values.issueDate.toDate(),
            filePath: Boolean(filePaths) ? filePaths.toString() : "",

        }


        if (newItem.id === null) {
            var response = await handleRequest("POST", "/api/checklist", newItem);
        }
        else {
            if (this.state.revising === true)
                response = await handleRequest("PUT", "/api/satiplog/revise/" + newItem.id, newItem);
            else
                response = await handleRequest("PUT", "/api/satiplog/" + newItem.id, newItem);
        }

        if (response.type === "ERROR") {
            this.error(response);
        }
        else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            this.showSuccess();
            this.cancel();
        }
    };

    createWhere(params) {
        if (Boolean(params._pagination))
            params.pagination = params._pagination;
        var current = this.state.pagination.current;
        var pageSize = this.state.pagination.pageSize;
        var sortField = "", sortOrder = "";


        if (Boolean(params) && Boolean(params.pagination)) {
            current = params.pagination.current;
            pageSize = params.pagination.pageSize;


            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }

        }
        const newObj = {
            ...params.pagination,
            firstResult: current - 1, // (current - 1) * pageSize,
            maxResults: pageSize,
            sortField: sortField,
            sortOrder: sortOrder,
        };
        return newObj;
    }

    showSuccess(msg) {
        if (Boolean(msg) && msg.length > 0)
            message.success(msg);
        else
            message.success('Success');
    };

    showError(msg) {
        if (Boolean(msg) && msg.length > 0)
            message.error(msg);
        else
            message.error('An error has occured');
    };


    downloadFile = async (url) => {
        window.location.href = url;
    }

    downloadMultipleFile = async (url) => {

        let pathList = url.split(",");
        console.log(pathList);
        if (Boolean(pathList) && pathList.length > 1) {
            var response = await handleRequest("POST", "/api/transmittalLogs/download", pathList);

            if (response.type === "ERROR") {
                this.error(response);
            }
            else {

                try {
                    const link = document.createElement('a')
                    link.href = response.data;
                    link.download = "Fİles.zip";
                    link.click();

                } catch (error) {
                    console.log(error);

                }

            }

        }
        else if (Boolean(pathList) && pathList.length === 1)
            this.downloadFile(pathList[0]);

    }

    showReport = async (record) => {
        var response = await handleRequest("GET", "/api/satiplog/report/" + record.satipNo);
        if (response.type === "ERROR") {
            this.showError("An error occurred while generating the report.")
        }
        else {
            const fileRealUrl = response.data;
            this.setState({
                pdfFile: fileRealUrl,
                modalPdfViewer: true,
            });
        }
    }

    searchUserMail = async (email) => {
        if (Boolean(email) && email.length > 0) {
            var response = await handleRequest("GET", "/api/users/mails/" + email);
            if (response.type === "ERROR") {
                this.error(response);
            }
            else {
                this.setState({
                    userSelectItems: Boolean(response.data) ? response.data : []
                });
            }
        }
        else this.setState({
            userSelectItems: []
        });
    }

    addItem = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "discipline"
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                this.error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillDisciplines();
            }
        } else {
            this.showError("Please enter discipline");
        }
    };

    fillDisciplines = async () => {
        const response = await handleRequest("GET", "/api/codes/type/discipline");
        if (Boolean(response.data)) {
            this.setState({
                disciplineSelectItems: response.data
            });
        }
    }





    actionTemplate(row) {

        return <React.Fragment>
            <CustomEditReviseDeleteMenu row={row} onDeleteRow={this.deleteModal.bind(this)} onReviseRow={this.revise.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;

    }



    handleExportExcel = async () => {

        this.setState({

            exportClick: true,
        });

    }

    ExcelDateToJSDate = (serial) => {

        if ((serial + "").includes(".")) {

            return moment(serial, "DD.MM.YYYY").toDate()
        }
        else {

            var hours = Math.floor((serial % 1) * 24);
            var minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
            return new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
        }

    }

    handleUploadExcel = async (excelJson) => {
        try {
            var list = [];
            if (excelJson === null)
                this.showError("Select an excel file");
            else {
                excelJson.forEach(element => {
                    const obj = {
                        discipline1: element["Discipline-1"],
                        discipline2: element["Discipline-2"],
                        satipNo: element["SATIP no"],
                        revNo: element["Rev no"],
                        satipDescription: element["SATIP Description"],
                        approvedDate: element["Approved Date"],
                        itemNo: element["Item No"],
                        projectPhaseActivityCode: element["Project Phase Activity Code"],
                        taskActivity: element["Task Activity"],
                        qualityProcedure: element["Quality Procedure"],
                        qualityRecord: element["Quality Record"],
                        xxIr: element["xx IR"],
                        contractorIr: element["Contractor IR"],
                        saudiAramcoIr: element["SAUDI ARAMCO IR"],
                        sapmtCommTeam: element["SAPMT COMM TEAM"],
                        remarks: element["Remarks"],
                        gn1: element["GN-1"],
                        gn2: element["GN-2"],
                        gn3: element["GN-3"],
                        gn4: element["GN-4"],
                        gn5: element["GN-5"],
                        gn6: element["GN-6"],
                        gn7: element["GN-7"],
                        gn8: element["GN-8"],
                        gn9: element["GN-9"],
                        gn10: element["GN-10"],
                        lgd1: element["LGD-1"],
                        lgd2: element["LGD-2"],
                        lgd3: element["LGD-3"],
                        lgd4: element["LGD-4"],
                        lgd5: element["LGD-5"],
                        lgd6: element["LGD-6"],
                        lgd7: element["LGD-7"],
                        lgd8: element["LGD-8"],
                        lgd9: element["LGD-9"],
                        lgd10: element["LGD-10"],
                        lgd11: element["LGD-11"]
                    }
                    list.push(obj);
                });
                if (Boolean(list) && list.length > 0) {
                    try {
                        var response = await handleRequest("POST", "/api/satiplog/import", list);
                        if (response.data) {
                            if (response.data === "WARNING") {
                                this.setState({
                                    listSatipLog: list,
                                });
                                this.showOrHideWarningMesaj();
                            }
                            else if (response.data === "ERROR") {
                                this.showWarning(<FormattedMessage id="GeneralMessageNoRecordsAdd" defaultMessage="No records to be added" />);
                            } else if (response.data === "SUCCESS") {
                                this.resetInputs();
                                const { pagination } = this.state;
                                this.restartTable({ pagination });
                                this.showSuccess();
                                this.cancel();
                            }
                        }
                    } catch (error) {
                        this.showError();
                    }
                } else {
                    this.showError("No records to be added");
                }
            }
        } catch (error) {
            this.showError("");
        }
    }


    sync = async () => {

        let response = await handleRequest("GET", "/api/satiplog/sync");


        if (response.type === "ERROR") {
            this.error(response);
        }
        else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            this.showSuccess();
            this.cancel();
        }


    };

    render() {
        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout2 = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
        };

        const renderToolbar = (toolbarSlot) => {
            return (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexGrow: 1,
                            flexShrink: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.previousPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.nextPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomOutButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomPopover}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomInButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.downloadButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.moreActionsPopover}
                        </div>
                    </div>
                </div>
            );
        };

        const props = {
            action: `${API_BASE_ROOT}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        const layoutView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
        ) => {
            return defaultLayout(
                isSidebarOpened,
                container,
                main,
                toolbar(renderToolbar),
                sidebar,
            );
        };

        const columns = [
            {
                title: 'S/N', key: 'id', render: (value, row, index) => {
                    return index + 1 + (this.state.pagination.pageSize * (this.state.pagination.current - 1));
                },
                width: 80,
                ellipsis: true,
            },
            {
                dataIndex: "discipline1",
                key: "discipline1",
                title: <FormattedMessage id="SatipLogDiscipline1" defaultMessage="Discipline-1" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="Discipline-1"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    discipline1: e.target.value,
                                    discipline1Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.discipline1Color) ? this.state.pagination.discipline1Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "discipline2",
                key: "discipline2",
                title: <FormattedMessage id="SatipLogDiscipline2" defaultMessage="Discipline-2" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="Discipline-2"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    discipline2: e.target.value,
                                    discipline2Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.discipline2Color) ? this.state.pagination.discipline2Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "satipNo",
                key: "satipNo",
                title: <FormattedMessage id="SatipLogSatipNo" defaultMessage="SATIP no" />,
                render: (text, record) => {
                    return (<React.Fragment>
                        <div style={{ cursor: "pointer", textDecoration: "underline", fontWeight: "bold" }}
                            onClick={() => this.showReport(record)}>
                            {record.satipNo}
                        </div>
                    </React.Fragment>);
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="Satip No"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    satipNo: e.target.value,
                                    satipNoColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.satipNoColor) ? this.state.pagination.satipNoColor : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "revNo",
                key: "revNo",
                title: <FormattedMessage id="SatipLogRevNo" defaultMessage="Rev no" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="Rev No"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    revNo: e.target.value,
                                    revNoColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.revNoColor) ? this.state.pagination.revNoColor : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "satipDescription",
                key: "satipDescription",
                title: <FormattedMessage id="SatipLogSatipDescription" defaultMessage="SATIP Description" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="Satip Description"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    satipDescription: e.target.value,
                                    satipDescriptionColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.satipDescriptionColor) ? this.state.pagination.satipDescriptionColor : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "approvedDate",
                key: "approvedDate",
                title: <FormattedMessage id="SatipLogApprovedDate" defaultMessage="Approved Date" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="Approve Date"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    approvedDate: e.target.value,
                                    approvedDateColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.approvedDateColor) ? this.state.pagination.approvedDateColor : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "itemNo",
                key: "itemNo",
                title: <FormattedMessage id="SatipLogItemNo" defaultMessage="Item No" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="Item No"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    itemNo: e.target.value,
                                    itemNoColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.itemNoColor) ? this.state.pagination.itemNoColor : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "projectPhaseActivityCode",
                key: "projectPhaseActivityCode",
                title: <FormattedMessage id="SatipLogProjectPhaseActivityCode" defaultMessage="Project Phase & Activity Code" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="Project Phase & Activity Code"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    projectPhaseActivityCode: e.target.value,
                                    projectPhaseActivityCodeColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.projectPhaseActivityCodeColor) ? this.state.pagination.projectPhaseActivityCodeColor : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "taskActivity",
                key: "taskActivity",
                title: <FormattedMessage id="SatipLogTaskActivity" defaultMessage="Task/Activity" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="Task/Activity"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    taskActivity: e.target.value,
                                    taskActivityColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.taskActivityColor) ? this.state.pagination.taskActivityColor : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "qualityProcedure",
                key: "qualityProcedure",
                title: <FormattedMessage id="SatipLogQualityProcedure" defaultMessage="Quality Procedure" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }}
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    qualityProcedure: e.target.value,
                                    qualityProcedureColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.qualityProcedureColor) ? this.state.pagination.qualityProcedureColor : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "qualityRecord",
                key: "qualityRecord",
                title: <FormattedMessage id="SatipLogQualityRecord" defaultMessage="Quality Record" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="Task/Activity"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    qualityRecord: e.target.value,
                                    qualityRecordColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.qualityRecordColor) ? this.state.pagination.qualityRecordColor : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "xxIr",
                key: "xxIr",
                title: <FormattedMessage id="SatipLogXXIr" defaultMessage="xx IR" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }}
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    xxIr: e.target.value,
                                    xxIrColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.xxIrColor) ? this.state.pagination.xxIrColor : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "contractorIr",
                key: "contractorIr",
                title: <FormattedMessage id="SatipLogContractorIr" defaultMessage="Contractor IR" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }}
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    contractorIr: e.target.value,
                                    contractorIrColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.contractorIrColor) ? this.state.pagination.contractorIrColor : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "saudiAramcoIr",
                key: "saudiAramcoIr",
                title: <FormattedMessage id="SatipLogSaudiAramcoIr" defaultMessage="SAUDI ARAMCO IR" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }}
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    saudiAramcoIr: e.target.value,
                                    saudiAramcoIrColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.saudiAramcoIrColor) ? this.state.pagination.saudiAramcoIrColor : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "sapmtCommTeam",
                key: "sapmtCommTeam",
                title: <FormattedMessage id="SatipLogSapmtCommTeam" defaultMessage="SAPMT COMM TEAM" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }}
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    sapmtCommTeam: e.target.value,
                                    sapmtCommTeamColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.sapmtCommTeamColor) ? this.state.pagination.sapmtCommTeamColor : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "remarks",
                key: "remarks",
                title: <FormattedMessage id="SatipLogRemarks" defaultMessage="Remarks" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="Remarks"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    remarks: e.target.value,
                                    remarksColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.remarksColor) ? this.state.pagination.remarksColor : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "gn1",
                key: "gn1",
                title: <FormattedMessage id="SatipLogGN1" defaultMessage="GN-1" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="GN-1"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    gn1: e.target.value,
                                    gn1Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.gn1Color) ? this.state.pagination.gn1Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "gn2",
                key: "gn2",
                title: <FormattedMessage id="SatipLogGN2" defaultMessage="GN-2" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="GN-2"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    gn2: e.target.value,
                                    gn2Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.gn2Color) ? this.state.pagination.gn2Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "gn3",
                key: "gn3",
                title: <FormattedMessage id="SatipLogGN3" defaultMessage="GN-3" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="GN-3"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    gn3: e.target.value,
                                    gn3Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.gn3Color) ? this.state.pagination.gn3Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "gn4",
                key: "gn4",
                title: <FormattedMessage id="SatipLogGN4" defaultMessage="GN-4" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="GN-4"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    gn4: e.target.value,
                                    gn4Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.gn4Color) ? this.state.pagination.gn4Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "gn5",
                key: "gn5",
                title: <FormattedMessage id="SatipLogGN5" defaultMessage="GN-5" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="GN-5"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    gn5: e.target.value,
                                    gn5Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.gn5Color) ? this.state.pagination.gn5Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "gn6",
                key: "gn6",
                title: <FormattedMessage id="SatipLogGN6" defaultMessage="GN-6" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="GN-6"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    gn6: e.target.value,
                                    gn6Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.gn6Color) ? this.state.pagination.gn6Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "gn7",
                key: "gn7",
                title: <FormattedMessage id="SatipLogGN7" defaultMessage="GN-7" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="GN-7"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    gn7: e.target.value,
                                    gn7Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.gn7Color) ? this.state.pagination.gn7Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "gn8",
                key: "gn8",
                title: <FormattedMessage id="SatipLogGN8" defaultMessage="GN-8" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="GN-8"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    gn8: e.target.value,
                                    gn8Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.gn8Color) ? this.state.pagination.gn8Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "gn9",
                key: "gn9",
                title: <FormattedMessage id="SatipLogGN9" defaultMessage="GN-9" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="GN-9"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    gn9: e.target.value,
                                    gn9Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.gn9Color) ? this.state.pagination.gn9Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "gn10",
                key: "gn10",
                title: <FormattedMessage id="SatipLogGN10" defaultMessage="GN-10" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="GN-10"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    gn10: e.target.value,
                                    gn10Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.gn10Color) ? this.state.pagination.gn10Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "lgd1",
                key: "lgd1",
                title: <FormattedMessage id="SatipLogLGD1" defaultMessage="LGD-1" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="LGD-1"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    lgd1: e.target.value,
                                    lgd1Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.lgd1Color) ? this.state.pagination.lgd1Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "lgd2",
                key: "lgd2",
                title: <FormattedMessage id="SatipLogLGD2" defaultMessage="LGD-2" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="LGD-2"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    lgd2: e.target.value,
                                    lgd2Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.lgd2Color) ? this.state.pagination.lgd2Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "lgd3",
                key: "lgd3",
                title: <FormattedMessage id="SatipLogLGD3" defaultMessage="LGD-3" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="LGD-3"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    lgd3: e.target.value,
                                    lgd3Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.lgd3Color) ? this.state.pagination.lgd3Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "lgd4",
                key: "lgd4",
                title: <FormattedMessage id="SatipLogLGD4" defaultMessage="LGD-4" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="LGD-4"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    lgd4: e.target.value,
                                    lgd4Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.lgd4Color) ? this.state.pagination.lgd4Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "lgd5",
                key: "lgd5",
                title: <FormattedMessage id="SatipLogLGD5" defaultMessage="LGD-5" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="LGD-5"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    lgd5: e.target.value,
                                    lgd5Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.lgd5Color) ? this.state.pagination.lgd5Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "lgd6",
                key: "lgd6",
                title: <FormattedMessage id="SatipLogLGD6" defaultMessage="LGD-6" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="LGD-6"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    lgd6: e.target.value,
                                    lgd6Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.lgd6Color) ? this.state.pagination.lgd6Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "lgd7",
                key: "lgd7",
                title: <FormattedMessage id="SatipLogLGD2" defaultMessage="LGD-7" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="LGD-7"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    lgd7: e.target.value,
                                    lgd7Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.lgd7Color) ? this.state.pagination.lgd7Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "lgd8",
                key: "lgd8",
                title: <FormattedMessage id="SatipLogLGD8" defaultMessage="LGD-8" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="LGD-8"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    lgd8: e.target.value,
                                    lgd8Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.lgd8Color) ? this.state.pagination.lgd8Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "lgd9",
                key: "lgd9",
                title: <FormattedMessage id="SatipLogLGD9" defaultMessage="LGD-9" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="LGD-9"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    lgd9: e.target.value,
                                    lgd9Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.lgd9Color) ? this.state.pagination.lgd9Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "lgd10",
                key: "lgd10",
                title: <FormattedMessage id="SatipLogLGD10" defaultMessage="LGD-10" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="LGD-10"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    lgd10: e.target.value,
                                    lgd10Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.lgd10Color) ? this.state.pagination.lgd10Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },
            {
                dataIndex: "lgd11",
                key: "lgd11",
                title: <FormattedMessage id="SatipLogLGD11" defaultMessage="LGD-11" />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            style={{ width: 200 }} placeholder="LGD-11"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    lgd11: e.target.value,
                                    lgd11Color: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >
                        </Input>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.lgd11Color) ? this.state.pagination.lgd11Color : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            }
        ];

        const excelExport = <>
            {/* {this.state.exportClick ?
                this.setState({
                    exportClick: false
                }) : ""}
            {this.state.exportClick ?
                <ExcelFile filename="SATIP Log" hideElement={true}>
                    <ExcelSheet name="SATIP Log" data={this.state.tableList}>
                        <ExcelColumn label="S/N" value="sn" />
                        <ExcelColumn label="Discipline-1" value="discipline1" />
                        <ExcelColumn label="Discipline-2" value="discipline2" />
                        <ExcelColumn label="SATIP No" value="satipNo" />
                        <ExcelColumn label="Revision No" value="revNo" />
                        <ExcelColumn label="SATIP Description" value="satipDescription" />
                        <ExcelColumn label="Approved Date" value="approvedDate" />
                        <ExcelColumn label="Item No" value="itemNo" />
                        <ExcelColumn label="Project Phase & Activity Code" value="projectPhaseActivityCode" />
                        <ExcelColumn label="Task/Activity" value="taskActivity" />
                        <ExcelColumn label="Quality Procedure" value="qualityProcedure" />
                        <ExcelColumn label="Quality Record" value="qualityRecord" />
                        <ExcelColumn label="xx IR" value="xxIr" />
                        <ExcelColumn label="Contractor IR" value="contractorIr" />
                        <ExcelColumn label="SAUDI ARAMCO IR" value="saudiAramcoIr" />
                        <ExcelColumn label="SAPMT COMM TEAM" value="sapmtCommTeam" />
                        <ExcelColumn label="Remarks" value="remarks" />
                        <ExcelColumn label="GN-1" value="gn1" />
                        <ExcelColumn label="GN-2" value="gn2" />
                        <ExcelColumn label="GN-3" value="gn3" />
                        <ExcelColumn label="GN-4" value="gn4" />
                        <ExcelColumn label="GN-5" value="gn5" />
                        <ExcelColumn label="GN-6" value="gn6" />
                        <ExcelColumn label="GN-7" value="gn7" />
                        <ExcelColumn label="GN-8" value="gn8" />
                        <ExcelColumn label="GN-9" value="gn9" />
                        <ExcelColumn label="GN-10" value="gn10" />
                        <ExcelColumn label="LGD-1" value="lgd1" />
                        <ExcelColumn label="LGD-2" value="lgd2" />
                        <ExcelColumn label="LGD-3" value="lgd3" />
                        <ExcelColumn label="LGD-4" value="lgd4" />
                        <ExcelColumn label="LGD-5" value="lgd5" />
                        <ExcelColumn label="LGD-6" value="lgd6" />
                        <ExcelColumn label="LGD-7" value="lgd7" />
                        <ExcelColumn label="LGD-8" value="lgd8" />
                        <ExcelColumn label="LGD-9" value="lgd9" />
                        <ExcelColumn label="LGD-10" value="lgd10" />
                        <ExcelColumn label="LGD-11" value="lgd11" />
                    </ExcelSheet>
                </ExcelFile> :
                <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="secondary" fontSize="large" />}></AntButton>
            } */}
        </>

        return (
            <div hidden={this.state.hideInputs} className="card card-custom" style={{ padding: '2rem', minHeight: '100%' }}>

                <Form
                    initialValues={{ remember: false }}
                    ref={this.formRef}>

                    {/*<Form    ---> Eskiden bu şekildeydi Form Component içeriği
                    initialValues={{ remember: false }}
                    onFinish={this.save}
                    onFinishFailed={onFinishFailed}
                    ref={this.formRef}>*/}

                    <Row>
                        <Col xs={18}>
                        </Col>
                        <Col xs={2}>
                            {excelExport}
                        </Col>
                        <Col xs={2}>
                            <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
                        </Col>
                        <Col xs={2}>
                            {/* <AntButton hidden={!this.state.hiddenInputs} style={{ border: "0px" }} onClick={() => this.onClickCreateNew()} icon={<CreateIcon color="secondary" fontSize="large" />}></AntButton>
                            <AntButton hidden={this.state.hiddenInputs} style={{ border: "0px" }} onClick={() => this.onClickCreateNew()} icon={<RemoveCircle color="error" fontSize="large" />}></AntButton> */}
                        </Col>
                    </Row>

                    <div hidden={this.state.hiddenInputs}>




                        <div hidden={this.state.hideSave}>
                            {
                                <Row gutter={8}>
                                    <Col span={8}></Col>

                                    <Col span={8}>
                                        {
                                            <Button id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                        }
                                    </Col>
                                </Row>

                            }
                        </div>

                        <div hidden={this.state.hideUpdate}>
                            {
                                <Row gutter={8}>
                                    <Col span={8}></Col>

                                    <Col span={4}>
                                        <Button id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                                    </Col>
                                    <Col span={4}>
                                        <Button id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                                    </Col>

                                </Row>
                            }
                        </div>
                    </div>
                </Form>

                <div style={{ marginTop: '2rem', marginBottom: '1rem' }} className="content-section implementation">
                    <Table
                        size="small"
                        bordered
                        scroll={{ y: 500, x: 900 }}
                        columns={columns}
                        dataSource={this.state.tableList}
                        loading={this.state.loading} onChange={this.handleTableChange}
                        pagination={this.state.pagination}
                    />
                </div>

                <Modal
                    show={this.state.modalDeleteShow}
                    onHide={this.showOrHideDeleteModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</Button>
                        <Button variant="danger" onClick={this.delete} >Delete</Button>
                    </Modal.Footer>
                </Modal>



                <Modal
                    show={this.state.showRevisedListModal}
                    onHide={() => {
                        this.setState({
                            showRevisedListModal: false
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Revised Drawings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table
                            bordered
                            scroll={{ x: 400, scrollToFirstRowOnChange: true }}
                            columns={columns.filter(p => p.key !== "actions" && p.key !== "revisedVersions")}
                            dataSource={this.state.revisedFileList} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showRevisedListModal: false
                            })
                        }}>Close</Button>

                    </Modal.Footer>
                </Modal>


                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            PDF Report Viewer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                            <div style={{ height: "900px" }}>
                                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                            </div>
                        </Worker>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalPdfViewer}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    console.log("YYY", state);

    return {
        // auth: state.auth,
        logout: state.logout,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(SatipLog)
// export default connect(null, auth.actions)(Domain);
