import { UploadOutlined } from "@ant-design/icons";
import { Button as AntButton, Col, Badge, DatePicker, Form, Input, InputNumber, Row, Checkbox, Image, Upload, Modal, Space } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest, API_BASE_ROOT } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";

export class ProductLogReceive extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "productLog",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      filePath: "",
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initPage();
  };

  initPage = async params => {
    console.log("AAA", this.props);
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();

    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      if (Boolean(this.props.selectedPO)) {
        this.setState({
          invoice: this.props.selectedPO.invoice,
          delivery: this.props.selectedPO.delivery,
          requiredDocuments: this.props.selectedPO.requiredDocuments
        });

        let invoiceFileUrl = [];
        let fileUrlInvoice = Boolean(this.props.selectedPO.invoiceFileUrl) ? this.props.selectedPO.invoiceFileUrl.split(",") : [];
        if (Boolean(fileUrlInvoice) && fileUrlInvoice.length > 0) {
          fileUrlInvoice.forEach((element, index) => {
            let obj = {
              uid: index,
              name: element.split("download/")[1],
              url: element,
              thumbUrl: element
            };
            invoiceFileUrl.push(obj);
          });
        }

        let deliveryFileUrl = [];
        let fileUrlDelivery = Boolean(this.props.selectedPO.deliveryFileUrl) ? this.props.selectedPO.deliveryFileUrl.split(",") : [];
        if (Boolean(fileUrlDelivery) && fileUrlDelivery.length > 0) {
          fileUrlDelivery.forEach((element, index) => {
            let obj = {
              uid: index,
              name: element.split("download/")[1],
              url: element,
              thumbUrl: element
            };
            deliveryFileUrl.push(obj);
          });
        }

        if (
          Boolean(this.props.selectedPO) &&
          Boolean(this.props.selectedPO.requiredDocuments) &&
          this.props.selectedPO.requiredDocuments.length > 0
        ) {
          this.props.selectedPO.requiredDocuments.forEach((element, index) => {
            var fileUrl = [];
            if (Boolean(element.fileUrl) && element.fileUrl.length > 0) {
              var fileUrl = [];
              let obj = {
                uid: index,
                name: element.fileUrl.split("download/")[1],
                url: element.fileUrl,
                thumbUrl: element.fileUrl
              };
              fileUrl.push(obj);
            }
            element.fileUrl = fileUrl;
          });
        }

        this.formRef.current.setFieldsValue({
          ...this.props.selectedPO,
          invoiceFileUrl: invoiceFileUrl,
          deliveryFileUrl: deliveryFileUrl,
          invoiceDate: Boolean(this.props.selectedPO.invoiceDate) ? moment(this.props.selectedPO.invoiceDate) : null,
          deliveryDate: Boolean(this.props.selectedPO.deliveryDate) ? moment(this.props.selectedPO.deliveryDate) : null
        });
      } else {
        this.formRef.current.setFieldsValue({
          quantity: Boolean(this.props.selectedPO) ? this.props.selectedPO.quantity : null,
          unitPrice: Boolean(this.props.selectedPO) ? this.props.selectedPO.unitPrice : null,
          oldUnitPrice: Boolean(this.state.unitPrice) ? this.state.unitPrice : null
        });
      }
    }
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initPage();
    }
  };

  fillComboboxes = async () => {};

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.props.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {};

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  saveSubmit = async receiveStatus => {
    this.setState(
      {
        receiveStatus: receiveStatus
      },
      () => {
        this.formRef.current.submit();
      }
    );
  };

  save = async values => {
    let invoiceFileUrl = [];
    if (Boolean(values.invoiceFileUrl)) {
      values.invoiceFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) invoiceFileUrl.push(item.response.url);
        else if (Boolean(item.url)) invoiceFileUrl.push(item.url);
      });
    }

    let deliveryFileUrl = [];
    if (Boolean(values.deliveryFileUrl)) {
      values.deliveryFileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) deliveryFileUrl.push(item.response.url);
        else if (Boolean(item.url)) deliveryFileUrl.push(item.url);
      });
    }

    const newItem = {
      ...values,
      id: this.props.selectedPO.id,
      stockId: this.props.stockId,
      receivingType: this.props.receivingType,
      invoiceFileUrl: invoiceFileUrl.toString(),
      deliveryFileUrl: deliveryFileUrl.toString(),
      receiveStatus: this.state.receiveStatus
    };

    var requiredDocuments = [];
    if (Boolean(newItem.requiredDocuments) && newItem.requiredDocuments.length > 0) {
      newItem.requiredDocuments.forEach(element => {
        if (Boolean(element.fileUrl) && element.fileUrl.length > 0 && Boolean(element.fileUrl[0].response)) {
          requiredDocuments.push({
            ["check"]: element.check,
            ["description"]: element.description,
            ["fileUrl"]: element.fileUrl[0].response.url,
            ["codeId"]: element.codeId
          });
        } else {
          requiredDocuments.push({
            ["check"]: element.check,
            ["description"]: element.description,
            ["fileUrl"]: element.fileUrl.toString(),
            ["codeId"]: element.codeId
          });
        }
      });
    }
    newItem.requiredDocuments = requiredDocuments;

    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/receive/" + newItem.id, newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.props.saveStockReceiving();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const listUploadProps = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }

      return e && e.fileList;
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={8}>
            <Col span={20}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProductLogReceive.quantity" defaultMessage="Quantity" />}
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProductLogReceive.unitPrice" defaultMessage="Unit Price" />}
                  name="unitPrice"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  {Boolean(this.state.oldUnitPrice) && this.state.oldUnitPrice > 0 ? (
                    <Badge showZero overflowCount={this.state.oldUnitPrice + 1} count={this.state.oldUnitPrice}>
                      <InputNumber style={{ width: "100%" }} />
                    </Badge>
                  ) : (
                    <InputNumber style={{ width: "100%" }} />
                  )}
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProductLogReceive.invoice" defaultMessage="Invoice" />}
                  name="invoice"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox
                    style={{ width: "100%" }}
                    onChange={e => {
                      this.setState({
                        invoice: e.target.checked
                      });
                    }}
                  />
                </Form.Item>
              }{" "}
              {this.state.invoice === true && (
                <>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductLogReceive.invoiceDate" defaultMessage="Invoice Date" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="invoiceDate"
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductLogReceive.invoiceNo" defaultMessage="Invoice No" />}
                    name="invoiceNo"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductLogReceive.invoiceFileUrl" defaultMessage="File" />}
                    name={"invoiceFileUrl"}
                    rules={[{ required: false, message: "Missing Load" }]}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                  >
                    <Upload {...listUploadProps} name="file" maxCount="1">
                      <AntButton icon={<UploadOutlined />}>
                        <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                      </AntButton>
                    </Upload>
                  </Form.Item>
                </>
              )}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProductLogReceive.deliveryNote" defaultMessage="Delivery Note" />}
                  name="delivery"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox
                    style={{ width: "100%" }}
                    onChange={e => {
                      this.setState({
                        delivery: e.target.checked
                      });
                    }}
                  />
                </Form.Item>
              }{" "}
              {this.state.delivery === true && (
                <>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductLogReceive.deliveryDate" defaultMessage="Delivery Date" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="deliveryDate"
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductLogReceive.deliveryNo" defaultMessage="Delivery No" />}
                    name="deliveryNo"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProductLogReceive.deliveryFileUrl" defaultMessage="File" />}
                    name={"deliveryFileUrl"}
                    rules={[{ required: false, message: "Missing Load" }]}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                  >
                    <Upload {...listUploadProps} name="file" maxCount="1">
                      <AntButton icon={<UploadOutlined />}>
                        {" "}
                        <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />{" "}
                      </AntButton>
                    </Upload>
                  </Form.Item>
                </>
              )}
              {Boolean(this.state.requiredDocuments) && this.state.requiredDocuments.length > 0 && (
                <Form.List name={"requiredDocuments"}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item {...layout2} label={index + 1}>
                          <Form.Item
                            {...field}
                            style={{
                              display: "inline-block",
                              width: "calc(10%)"
                            }}
                            name={[field.name, "check"]}
                            valuePropName="checked"
                          >
                            <Checkbox autoFocus checked={true} defaultChecked={true}></Checkbox>
                          </Form.Item>

                          <Form.Item
                            {...field}
                            style={{
                              display: "inline-block",
                              width: "calc(40%)"
                            }}
                            name={[field.name, "description"]}
                          >
                            <Input readOnly></Input>
                          </Form.Item>

                          <Form.Item
                            {...field}
                            name={[field.name, "fileUrl"]}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            style={{
                              display: "inline-block",
                              width: "calc(30%)"
                            }}
                          >
                            <Upload {...listUploadProps} name="file" maxCount="1">
                              <AntButton icon={<UploadOutlined />}>
                                {" "}
                                <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />{" "}
                              </AntButton>
                            </Upload>
                          </Form.Item>
                        </Form.Item>
                      ))}
                    </>
                  )}
                </Form.List>
              )}
            </Col>
            <Col span={2}>
              <br />
              <br />
              <br />
              {Boolean(this.props.selectedPO) && Boolean(this.props.selectedPO.imageUrl) && <Image src={this.props.selectedPO.imageUrl} />}
            </Col>
            <Col span={2}></Col>
          </Row>
          {
            <Row gutter={[16, 16]}>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}></Col>
              <Col xs={4} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <Button id="AcceptButton" onClick={() => this.saveSubmit("Accept")} style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonAccept" defaultMessage="Accept" />
                </Button>
              </Col>
              <Col xs={4} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <Button id="RejectButton" onClick={() => this.saveSubmit("Reject")} style={{ width: "100%" }} variant="danger">
                  <FormattedMessage id="GeneralButtonReject" defaultMessage="Reject" />
                </Button>
              </Col>
              <Col xs={4} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <Button id="OnHoldButton" onClick={() => this.saveSubmit("OnHold")} style={{ width: "100%" }} variant="secondary">
                  <FormattedMessage id="GeneralButtonOnHold" defaultMessage="On Hold" />
                </Button>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}></Col>
            </Row>
          }
        </Form>

        <Modal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={500}
          okButtonProps={{ hidden: true }}
          visible={this.state.modalDeleteShow}
          onCancel={() => {
            this.setState({ modalDeleteShow: false });
          }}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDeleteShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Space>
          ]}
        >
          <p>
            <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
          </p>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductLogReceive);
