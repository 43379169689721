import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Radio, Select, Space, InputNumber, Divider, Switch, Modal, Spin } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import { Input } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, SettingTwoTone, MinusCircleOutlined, ZoomInOutlined, PlusOutlined, ApiTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd";
import RoomQuantity from "../engineering/RoomQuantity";

const { Option } = Select;

export class Apartment extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "Apartment",
      controllerName: "apartment",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      houseRoomList: [],
      loadingButtonSubmit: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      innerHouseTypeIds: [],
    };
  }

  formRef = React.createRef();
  formRefTotalNumberOfApartments = React.createRef();
  formRefApartmentTypes = React.createRef();
  formRefApartmentsAndTypes = React.createRef();
  formRefSectionAndApartments = React.createRef();
  formRefNumberOfFloors = React.createRef();
  formRefSectionHouses = React.createRef();
  formRefHouseRooms = React.createRef();
  formRefOuterCoordinates = React.createRef();
  formRefInnerCoordinates = React.createRef();
  formRefHouseTypes = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    fillFilterOperations();
  };

  fillComboboxes = async () => {
    this.fillApartmentTypes("Apartment.ApartmentType");
    this.fillCode("Apartment.TypeOfHouses");
    var responseNewRequests = await handleRequest("GET", "/api/" + this.state.controllerName + "/newRequests");
    if (responseNewRequests.type === "ERROR") {
      error(responseNewRequests);
    }
    this.setState({
      newRequestsSelectItems: Boolean(responseNewRequests.data) ? responseNewRequests.data : []
    });
  };

  fillApartmentAndTypes = async () => {
    this.fillApartmentTypes("Apartment.ApartmentType");
    var responseApartmentId = await handleRequest("GET", "/api/" + this.state.controllerName + "/apartmentId");
    if (responseApartmentId.type === "ERROR") {
      error(responseApartmentId);
    }

    this.setState({
      apartmentIdSelectItems: Boolean(responseApartmentId.data) ? responseApartmentId.data : [],
      currentApartmentIdSelectItems: Boolean(responseApartmentId.data) ? responseApartmentId.data : [],
    });
  };

  fillApartmentsWithTypeNotNull = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/apartmentsWithTypeNotNull");
    if (response.type === "ERROR") {
      error(response);
    }

    this.setState({
      apartmentsWithTypeNotNullList: Boolean(response.data) ? response.data : [],
    });
  };

  fillApartmentsWithHouseNotNull = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/apartmentsWithHouseNotNull");
    if (response.type === "ERROR") {
      error(response);
    }

    this.setState({
      apartmentsWithHouseNotNull: Boolean(response.data) ? response.data : [],
    });
  };

  fillApartmentsWithSectionNotNull = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/apartmentsWithSectionNotNull");
    if (response.type === "ERROR") {
      error(response);
    }

    this.setState({
      apartmentsWithSectionNotNull: Boolean(response.data) ? response.data : [],
    });
  };

  fillHouseRoomsNotNull = async (houseTypeId) => {
    if (Boolean(houseTypeId) && houseTypeId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/houseRoomsNotNull/" + houseTypeId);
      if (response.type === "ERROR") {
        error(response);
      }

      this.setState({
        houseRoomsNotNull: Boolean(response.data) ? response.data : [],
      });
    }
  };

  fillNumberOfFloorsNotNull = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/numberOfFloorsNotNull");
    if (response.type === "ERROR") {
      error(response);
    }

    this.setState({
      numberOfFloorsNotNull: Boolean(response.data) ? response.data : [],
    });
  };

  fillFloors = async (apartmentId) => {
    if (Boolean(apartmentId) && apartmentId > 0) {
      var responseHouseTypeId = await handleRequest("GET", "/api/" + this.state.controllerName + "/floor/" + apartmentId);
      if (responseHouseTypeId.type === "ERROR") {
        error(responseHouseTypeId);
      }

      this.setState({
        floorSelectItems: Boolean(responseHouseTypeId.data) ? responseHouseTypeId.data : [],
      });
    } else {
      this.setState({
        floorSelectItems: [],
      });
    }
  };

  fillApartmentSection = async (apartmentId) => {
    let currentSectionSelectItems = [], sectionSelectItems = [];
    if (Boolean(apartmentId) && apartmentId > 0) {
      var responseSection = await handleRequest("GET", "/api/" + this.state.controllerName + "/sectionId/" + apartmentId);
      if (responseSection.type === "ERROR") {
        error(responseSection);
      } else {
        sectionSelectItems = Boolean(responseSection.data) ? responseSection.data : [];
      }

      const sectionHouses = this.formRefSectionHouses.current.getFieldValue("sectionHouses");

      console.log("sectionHouses", sectionHouses);

      if (Boolean(sectionHouses) && sectionHouses.length > 0) {
        console.log("sectionSelectItems", sectionSelectItems);
        if (sectionSelectItems.length > 0) {
          sectionSelectItems.forEach(element => {
            console.log("element", element);
            const row = sectionHouses.find(p => Boolean(p) && Boolean(p.apartmentId) && p.apartmentId === apartmentId && p.section === element.key);
            console.log("row", row);
            if (!row) {
              const hasSec = currentSectionSelectItems.find(p => p.key === element.key);
              console.log("hasSec", currentSectionSelectItems);
              if (!hasSec) {
                console.log("element.key", element.key);
                currentSectionSelectItems.push({ key: element.key, value: element.value });
              }
              console.log("1111");
            } else {
              console.log("2222");
            }
          });
        }
      }
    }

    console.log("currentSectionSelectItems", currentSectionSelectItems);
    this.setState({
      currentSectionSelectItems: currentSectionSelectItems,
      sectionSelectItems: sectionSelectItems,
    });
  };

  fillApartmentSectionNumberOfFloors = async (apartmentId) => {
    let currentSectionSelectItems = [], sectionSelectItems = [];
    if (Boolean(apartmentId) && apartmentId > 0) {
      var responseSection = await handleRequest("GET", "/api/" + this.state.controllerName + "/sectionId/" + apartmentId);
      if (responseSection.type === "ERROR") {
        error(responseSection);
      } else {
        sectionSelectItems = Boolean(responseSection.data) ? responseSection.data : [];
      }

      const numberOfFloors = this.formRefNumberOfFloors.current.getFieldValue("numberOfFloors");

      console.log("numberOfFloors", numberOfFloors);

      if (Boolean(numberOfFloors) && numberOfFloors.length > 0) {
        console.log("sectionSelectItems", sectionSelectItems);
        if (sectionSelectItems.length > 0) {
          sectionSelectItems.forEach(element => {
            console.log("element", element);
            const row = numberOfFloors.find(p => Boolean(p) && Boolean(p.id) && p.id === apartmentId && p.section === element.key);
            console.log("row", row);
            if (!row) {
              const hasSec = currentSectionSelectItems.find(p => p.key === element.key);
              console.log("hasSec", currentSectionSelectItems);
              if (!hasSec) {
                console.log("element.key", element.key);
                currentSectionSelectItems.push({ key: element.key, value: element.value });
              }
              console.log("1111");
            } else {
              console.log("2222");
            }
          });
        }
      }
    }

    console.log("currentSectionSelectItems", currentSectionSelectItems);
    this.setState({
      currentSectionSelectItems: currentSectionSelectItems,
      sectionSelectItems: sectionSelectItems,
    });
  };

  fillConnectionType = async () => {
    var responseConnectionType = await handleRequest("GET", "/api/" + this.state.controllerName + "/connectionType");
    if (responseConnectionType.type === "ERROR") {
      error(responseConnectionType);
    }

    this.setState({
      connectionTypeSelectItems: Boolean(responseConnectionType.data) ? responseConnectionType.data : [],
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  onClickTotalNumberOfApartment = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getNumberOfAllFloors");
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({ totalApartmentsModal: true }, () => {
        if (Boolean(this.formRefTotalNumberOfApartments) && Boolean(this.formRefTotalNumberOfApartments.current)) {
          this.formRefTotalNumberOfApartments.current.setFieldsValue({
            totalNumberOfApartments: response.data
          });
        }
      });
    }
  }

  saveTotalNumberOfApartments = async values => {
    if (Boolean(values) && Boolean(values.totalNumberOfApartments) && values.totalNumberOfApartments > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveTotalNumberOfApartments/" + values.totalNumberOfApartments);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        this.fillApartmentAndTypes();
        this.setState({
          totalApartmentsModal: false,
          typesOfApartmentsModal: true
        }, () => {
          if (Boolean(this.formRefApartmentTypes) && Boolean(this.formRefApartmentTypes.current)) {
            this.formRefApartmentTypes.current.resetFields();
            let apartmentTypes = [];
            if (this.state["Apartment.ApartmentTypeSelectItems"] && this.state["Apartment.ApartmentTypeSelectItems"].length > 0) {
              this.state["Apartment.ApartmentTypeSelectItems"].forEach(element => {
                apartmentTypes.push({ "apartmentTypeName": element.name });
              });
            }
            this.formRefApartmentTypes.current.setFieldsValue({
              apartmentTypes: apartmentTypes
            });
          }
        });
      }
    } else {
      showWarning("Not found.");
    }
  };

  saveApartmentTypes = async values => {
    if (Boolean(values.apartmentTypes) && values.apartmentTypes.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveApartmentTypes", values.apartmentTypes);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.fillApartmentTypes("Apartment.ApartmentType");
        this.setState({
          typesOfApartmentsModal: false,
          apartmentsAndTypesModal: true
        }, async () => {
          if (Boolean(this.formRefApartmentsAndTypes) && Boolean(this.formRefApartmentsAndTypes.current)) {
            await this.fillApartmentsWithTypeNotNull();

            const { apartmentsWithTypeNotNullList } = this.state;
            let apartmentsAndTypes = [];
            console.log("data", apartmentsWithTypeNotNullList);
            if (Boolean(apartmentsWithTypeNotNullList) && apartmentsWithTypeNotNullList.length > 0) {
              apartmentsWithTypeNotNullList.forEach(element => {
                apartmentsAndTypes.push({ "id": element.id, "apartmentTypeId": element.apartmentTypeId });
              });
            }
            this.formRefApartmentsAndTypes.current.setFieldsValue({
              apartmentsAndTypes: apartmentsAndTypes
            });
            this.calcApartmentsForApartmentsAndTypes();
          }

          this.setState({
            loadingButtonSubmit: false
          });
        });
      }
    } else {
      showWarning("Please enter at least one apartment type.");
      this.setState({
        loadingButtonSubmit: false
      });
    }
  };

  saveHouseTypes = async values => {
    if (Boolean(values.typeOfHouses) && values.typeOfHouses.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveHouseTypes", values.typeOfHouses);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          typeOfHousesModal: false,
          typeHouseRoomsModal: true
        }, async () => {
          if (Boolean(this.formRefSectionHouses) && Boolean(this.formRefSectionHouses.current)) {
            this.fillCode("Apartment.TypeOfHouses");
            await this.fillApartmentsWithHouseNotNull();

            const { apartmentsWithHouseNotNull } = this.state;
            let sectionHouses = [];
            console.log("data", apartmentsWithHouseNotNull);
            if (Boolean(apartmentsWithHouseNotNull) && apartmentsWithHouseNotNull.length > 0) {
              apartmentsWithHouseNotNull.forEach(element => {
                sectionHouses.push({
                  "apartmentTypeId": element.apartmentTypeId, "apartmentTypeName": element.apartmentTypeName, "section": element.section
                  , "floor": element.floor, "houseTypeId": element.houseTypeId, "numberOfHouse": element.numberOfHouse
                });
              });
            }
            this.formRefSectionHouses.current.setFieldsValue({
              sectionHouses: sectionHouses
            });
            //this.calcApartmentsForSectionHouses();
          }
          this.setState({
            loadingButtonSubmit: false
          });
        });
      }
    } else {
      showWarning("Please enter at least one house type.");
      this.setState({
        loadingButtonSubmit: false
      });
    }
  };

  calcApartmentsForApartmentsAndTypes = () => {
    const apartmentsAndTypes = this.formRefApartmentsAndTypes.current.getFieldValue("apartmentsAndTypes");
    const { apartmentIdSelectItems } = this.state;
    let selectedApartments = [];
    if (Boolean(apartmentIdSelectItems) && apartmentIdSelectItems.length > 0) {
      apartmentIdSelectItems.forEach(element => {
        if (apartmentsAndTypes != null && apartmentsAndTypes.length > 0) {
          let row = apartmentsAndTypes.find(p => Boolean(p) && Boolean(p.id) && p.id === element.key);
          if (!Boolean(row)) {
            selectedApartments.push({ "key": element.key, "value": element.value });
          }
        } else {
          selectedApartments.push({ "key": element.key, "value": element.value });
        }
      });
    }
    this.setState({ "currentApartmentIdSelectItems": selectedApartments });
  }

  calcApartmentsSectionsApartmentsAndTypes = () => {
    const apartmentSections = this.formRefSectionAndApartments.current.getFieldValue("apartmentSections");
    console.log("apartmentSections", apartmentSections);
    let apartmentIdSelectItems = this.state["Apartment.ApartmentTypeSelectItems"];
    console.log("apartmentIdSelectItems", apartmentIdSelectItems);
    let selectedApartmentTypes = [];
    if (Boolean(apartmentIdSelectItems) && apartmentIdSelectItems.length > 0) {
      apartmentIdSelectItems.forEach(element => {
        if (apartmentSections != null && apartmentSections.length > 0) {
          let row = apartmentSections.find(p => Boolean(p) && Boolean(p.apartmentTypeId) && p.apartmentTypeId === element.id);
          if (!Boolean(row)) {
            selectedApartmentTypes.push({ "id": element.id, "name": element.name });
          }
        } else {
          selectedApartmentTypes.push({ "id": element.id, "name": element.name });
        }
      });
    }
    console.log("currentApartmentTypeSelectItems", selectedApartmentTypes);
    this.setState({ "currentApartmentTypeSelectItems": selectedApartmentTypes });
  }

  calcApartmentsForSectionHouses = async () => {
    const sectionHouses = this.formRefSectionHouses.current.getFieldValue("sectionHouses");
    console.log("sectionHouses", sectionHouses);
    const { apartmentIdSelectItems } = this.state;
    console.log("apartmentIdSelectItems", apartmentIdSelectItems);

    const selectedApartments = [];
    async function fetchSectionData(apartment, sectionHouses, controllerName) {
      console.log("sectionHouses", sectionHouses);

      const currentApartmentId = apartment.key;
      if (currentApartmentId > 0 && Boolean(sectionHouses) && sectionHouses.length > 0) {
        const responseSection = await handleRequest("GET", `/api/${controllerName}/sectionId/${currentApartmentId}`);
        if (responseSection.type === "ERROR") {
          error(responseSection);
        } else {
          const sectionSelectItems = responseSection.data || [];
          console.log("sectionSelectItems", sectionSelectItems);
          if (sectionSelectItems.length > 0) {
            sectionSelectItems.forEach(element => {
              console.log("element", element);
              const row = sectionHouses.find(p => Boolean(p) && Boolean(p.apartmentId) && Boolean(p.section) && p.apartmentId === currentApartmentId && p.section === element.key);
              console.log("row", row);
              if (!row) {
                const hasApt = selectedApartments.find(p => p.key === apartment.key);
                if (!hasApt) {
                  selectedApartments.push({ key: apartment.key, value: apartment.value });
                }
              }
            });
          } else {
            const hasApt = selectedApartments.find(p => p.key === apartment.key);
            if (!hasApt) {
              selectedApartments.push({ key: apartment.key, value: apartment.value });
            }
            console.log("3333");
          }
        }
      } else {
        const hasApt = selectedApartments.find(p => p.key === apartment.key);
        if (!hasApt) {
          selectedApartments.push({ key: apartment.key, value: apartment.value });
        }
        console.log("4444");
      }
    }

    for (const apartment of apartmentIdSelectItems) {
      await fetchSectionData(apartment, sectionHouses, this.state.controllerName);
    }

    console.log("selectedApartments", selectedApartments);
    this.setState({
      currentApartmentIdSelectItems: selectedApartments,
    });
  }

  calcApartmentTypeAndSection = async () => {
    const numberOfFloors = this.formRefNumberOfFloors.current.getFieldValue("numberOfFloors");
    const { apartmentIdSelectItems } = this.state;
    const selectedApartments = [];
    async function fetchSectionData(apartment, numberOfFloors, controllerName) {
      console.log("numberOfFloors", numberOfFloors);

      const currentApartmentId = apartment.key;
      if (currentApartmentId > 0 && Boolean(numberOfFloors) && numberOfFloors.length > 0) {
        const responseSection = await handleRequest("GET", `/api/${controllerName}/sectionId/${currentApartmentId}`);
        if (responseSection.type === "ERROR") {
          error(responseSection);
        } else {
          const sectionSelectItems = responseSection.data || [];
          console.log("sectionSelectItems", sectionSelectItems);
          if (sectionSelectItems.length > 0) {
            sectionSelectItems.forEach(element => {
              console.log("element", element);
              const row = numberOfFloors.find(p => Boolean(p) && Boolean(p.id) && Boolean(p.section) && p.id === currentApartmentId && p.section === element.key);
              console.log("row", row);
              if (!row) {
                const hasApt = selectedApartments.find(p => p.key === apartment.key);
                if (!hasApt) {
                  selectedApartments.push({ key: apartment.key, value: apartment.value });
                }
              }
            });
          } else {
            const hasApt = selectedApartments.find(p => p.key === apartment.key);
            if (!hasApt) {
              selectedApartments.push({ key: apartment.key, value: apartment.value });
            }
            console.log("3333");
          }
        }
      } else {
        const hasApt = selectedApartments.find(p => p.key === apartment.key);
        if (!hasApt) {
          selectedApartments.push({ key: apartment.key, value: apartment.value });
        }
        console.log("4444");
      }
    }

    for (const apartment of apartmentIdSelectItems) {
      await fetchSectionData(apartment, numberOfFloors, this.state.controllerName);
    }

    console.log("selectedApartments", selectedApartments);
    this.setState({
      currentApartmentIdSelectItems: selectedApartments,
    });
  }

  saveApartmentsAndTypes = async values => {
    if (Boolean(values.apartmentsAndTypes) && values.apartmentsAndTypes.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveApartmentsAndTypes", values.apartmentsAndTypes);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });

        this.setState({
          apartmentsAndTypesModal: false,
          sectionsOfApartmentsModal: true,
        }, async () => {
          if (Boolean(this.formRefSectionAndApartments) && Boolean(this.formRefSectionAndApartments.current)) {
            await this.fillApartmentsWithSectionNotNull();
            let allApartmentTypes = this.state["Apartment.ApartmentTypeSelectItems"];

            const { apartmentsWithSectionNotNull, apartmentIdSelectItems } = this.state;
            let apartmentSections = [];
            console.log("data", apartmentsWithSectionNotNull);
            if (Boolean(apartmentsWithSectionNotNull) && apartmentsWithSectionNotNull.length > 0) {
              apartmentsWithSectionNotNull.forEach(element => {
                apartmentSections.push({ "apartmentTypeId": element.apartmentTypeId, "section": element.section });
              });
            }
            this.formRefSectionAndApartments.current.setFieldsValue({
              apartmentSections: apartmentSections
            });

            let selectedApartments = [];
            if (Boolean(apartmentIdSelectItems) && apartmentIdSelectItems.length > 0) {
              apartmentIdSelectItems.forEach(element => {
                selectedApartments.push({ "key": element.key, "value": element.value });
              });
            }

            let apartmentsAndTypes = this.formRefApartmentsAndTypes.current.getFieldValue("apartmentsAndTypes");
            let currentApartmentTypeSelectItems = [];

            if (apartmentsAndTypes != null && apartmentsAndTypes.length > 0) {
              apartmentsAndTypes.forEach(element => {
                let apartmentType = allApartmentTypes.find(p => p.id === element.apartmentTypeId);
                let hasType = currentApartmentTypeSelectItems.find(p => p.id === element.apartmentTypeId);
                if (!Boolean(hasType) && Boolean(apartmentType)) {
                  currentApartmentTypeSelectItems.push({ "id": element.apartmentTypeId, "name": apartmentType.name });
                }
              });
            }

            this.setState({
              "currentApartmentIdSelectItems": selectedApartments,
              currentApartmentTypeSelectItems: currentApartmentTypeSelectItems,
              loadingButtonSubmit: false,
            });
          }
        });
      }
    }
  };

  saveSectionAndApartments = async values => {
    if (Boolean(values.apartmentSections) && values.apartmentSections.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveSectionAndApartments", values.apartmentSections);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });

        this.setState({
          sectionsOfApartmentsModal: false,
          numberOfFloorsModal: true
        }, async () => {
          if (Boolean(this.formRefNumberOfFloors) && Boolean(this.formRefNumberOfFloors.current)) {
            await this.fillNumberOfFloorsNotNull();

            const { numberOfFloorsNotNull } = this.state;
            console.log("data", numberOfFloorsNotNull);
            if (Boolean(numberOfFloorsNotNull)) {
              let allSameVariableType = numberOfFloorsNotNull.allSameVariableType;
              let floor = numberOfFloorsNotNull.floor;
              let numberOfFloors = numberOfFloorsNotNull.numberOfFloors;
              this.formRefNumberOfFloors.current.setFieldsValue({
                allSameVariableType: allSameVariableType,
                floor: floor,
                numberOfFloors: numberOfFloors,
              });

              this.setState({
                allSameVariableType: allSameVariableType,

              });
            } else {
              this.formRefNumberOfFloors.current.resetFields();
            }
          }
          this.setState({
            loadingButtonSubmit: false
          });
        })
      }
    }
  };

  saveNumberOfFloors = async values => {
    console.log("values", values);
    if (Boolean(values.allSameVariableType)) {
      if (values.allSameVariableType === "AllSame") {
        var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/saveNumberOfAllFloors/" + values.floor);
        if (response.type === "ERROR") {
          error(response);
        } else {
          this.cancel();
          const { pagination } = this.state;
          this.restartTable({ pagination });

          this.setState({
            numberOfFloorsModal: false,
            typeOfHousesModal: true
          }, () => {
            if (Boolean(this.formRefHouseTypes) && Boolean(this.formRefHouseTypes.current)) {
              this.formRefHouseTypes.current.resetFields();
              let typeOfHouses = [];
              if (this.state["Apartment.TypeOfHousesSelectItems"] && this.state["Apartment.TypeOfHousesSelectItems"].length > 0) {
                this.state["Apartment.TypeOfHousesSelectItems"].forEach(element => {
                  typeOfHouses.push({ "houseTypeName": element.name });
                });
              }
              console.log("TypeOfHousesSelectItems", this.state["Apartment.TypeOfHousesSelectItems"]);
              console.log("typeOfHouses", typeOfHouses);
              this.formRefHouseTypes.current.setFieldsValue({
                typeOfHouses: typeOfHouses
              });
            }
          });

          this.setState({
            loadingButtonSubmit: false,
          });
        }
      } else if (Boolean(values.numberOfFloors) && values.numberOfFloors.length > 0) {
        const isValid = values.numberOfFloors.every(element => Boolean(element) && Boolean(element.apartmentTypeId) && Boolean(element.section) && Boolean(element.floor));
        if (!isValid) {
          showWarning("Apartment type, section and floor are required.");
          this.setState({
            loadingButtonSubmit: false,
          });
        } else {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveNumberOfFloors", values.numberOfFloors);
          if (response.type === "ERROR") {
            error(response);
          } else {
            this.cancel();
            const { pagination } = this.state;
            this.restartTable({ pagination });

            this.setState({
              numberOfFloorsModal: false,
              typeOfHousesModal: true
            }, () => {
              if (Boolean(this.formRefHouseTypes) && Boolean(this.formRefHouseTypes.current)) {
                this.formRefHouseTypes.current.resetFields();
                let typeOfHouses = [];
                if (this.state["Apartment.TypeOfHousesSelectItems"] && this.state["Apartment.TypeOfHousesSelectItems"].length > 0) {
                  this.state["Apartment.TypeOfHousesSelectItems"].forEach(element => {
                    typeOfHouses.push({ "houseTypeName": element.name });
                  });
                }
                console.log("typeOfHouses", typeOfHouses);
                this.formRefHouseTypes.current.setFieldsValue({
                  typeOfHouses: typeOfHouses
                });
              }
            });

            this.setState({
              loadingButtonSubmit: false,
            });
          }
        }
      }
    } else {
      showWarning("Please select type.");
      this.setState({
        loadingButtonSubmit: false,
      });
    }
  };

  saveSectionHouses = async values => {
    const sectionHouses = values.sectionHouses;
    if (Boolean(sectionHouses) && sectionHouses.length > 0) {
      const isValid = sectionHouses.every(element => Boolean(element) && Boolean(element.apartmentTypeId) && Boolean(element.section)
        && Boolean(element.floor) && Boolean(element.houseTypeId));
      if (!isValid) {
        showWarning("Apartment type, section, floor and house type are required.");
        this.setState({
          loadingButtonSubmit: false
        });
      } else {
        let houseTypeIds = [];
        let apartmentIds = [];
        let apartmentIdsMap = {};
        values.sectionHouses.forEach(house => {
          if (!houseTypeIds.includes(house.houseTypeId)) {
            houseTypeIds.push(house.houseTypeId);
          }
          if (!apartmentIdsMap[house.apartmentTypeId]) {
            apartmentIdsMap[house.apartmentTypeId] = true;
            apartmentIds.push({ "apartmentTypeId": house.apartmentTypeId, "maxFloor": house.floor, "section": house.section });
          }
        });
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveSectionHouses", values.sectionHouses);
        if (response.type === "ERROR") {
          error(response);
        } else {
          this.cancel();
          const { pagination } = this.state;
          this.restartTable({ pagination });

          let houseTypeId = null;
          let houseTypeName = "";
          if (Boolean(houseTypeIds) && houseTypeIds.length > 0) {
            houseTypeId = houseTypeIds[0];
            let houseType = this.state["Apartment.TypeOfHousesSelectItems"].find(p => p.id === houseTypeId);
            if (Boolean(houseType)) {
              houseTypeName = houseType.name;
            }
          }

          let maxFloor = 0;
          let apartmentTypeId = null;
          let apartmentName = "";
          let section = "";
          if (Boolean(apartmentIds) && apartmentIds.length > 0) {
            apartmentTypeId = apartmentIds[0].apartmentTypeId;
            maxFloor = apartmentIds[0].maxFloor;
            section = apartmentIds[0].section;
            let apartment = this.state.apartmentIdSelectItems.find(p => p.key === apartmentTypeId);
            if (Boolean(apartment)) {
              apartmentName = apartment.value;
            }
          }

          this.fillCode("Apartment.ApartmentRoomType");
          this.setState({
            houseTypeIds: houseTypeIds,
            houseTypeName: houseTypeName,
            houseTypeId: houseTypeId,
            apartmentIds: apartmentIds,
            apartmentName: apartmentName,
            apartmentTypeId: apartmentTypeId,
            whichFloor: 1,
            maxFloor: maxFloor,
            section: section,

            typeHouseRoomsModal: false,
            typesOfHouseRoomModal: true
          }, async () => {
            if (Boolean(this.formRefHouseRooms) && Boolean(this.formRefHouseRooms.current)) {
              await this.fillHouseRoomsNotNull(houseTypeId);

              const { houseRoomsNotNull } = this.state;
              let houseRooms = [];
              console.log("data", houseRoomsNotNull);
              if (Boolean(houseRoomsNotNull) && houseRoomsNotNull.length > 0) {
                houseRoomsNotNull.forEach(element => {
                  houseRooms.push({ "roomTypeId": element.roomTypeId, "numberOfRoom": element.numberOfRoom });
                });
              }
              this.formRefHouseRooms.current.setFieldsValue({
                houseRooms: houseRooms
              });
            }
          });
        }
        this.setState({
          loadingButtonSubmit: false
        });
      }
    } else {
      showWarning("Apartment type, section, floor and house type are required.");
      this.setState({
        loadingButtonSubmit: false
      });
    }
  };

  saveHouseRooms = async values => {
    //this.fillConnectionType();
    if (Boolean(this.state.houseTypeId) && this.state.houseTypeId > 0) {
      if (Boolean(values.houseRooms) && values.houseRooms.length > 0) {
        let houseTypeIdsMap = {};
        const { houseTypeId } = this.state;
        let innerHouseTypeIds = [];
        values.houseRooms.forEach(element => {
          element.houseTypeId = houseTypeId;

          if (!houseTypeIdsMap[houseTypeId + "-" + element.roomTypeId]) {
            houseTypeIdsMap[houseTypeId + "-" + element.roomTypeId] = true;
            innerHouseTypeIds.push({ "houseTypeId": houseTypeId, "roomTypeId": element.roomTypeId });
          }
        });
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveHouseRooms", values.houseRooms);
        if (response.type === "ERROR") {
          error(response);
        } else {
          this.cancel();
          const { pagination } = this.state;
          this.restartTable({ pagination });

          let houseTypeName = "", houseTypeId = null, roomTypeName = "", roomTypeId = null;
          if (Boolean(innerHouseTypeIds) && innerHouseTypeIds.length > 0) {
            houseTypeId = innerHouseTypeIds[0].houseTypeId;
            houseTypeName = this.state["Apartment.TypeOfHousesSelectItems"].find(p => p.id === houseTypeId).name;

            roomTypeId = innerHouseTypeIds[0].roomTypeId;
            roomTypeName = this.state["Apartment.ApartmentRoomTypeSelectItems"].find(p => p.id === roomTypeId).name;
          }

          console.log("innerHouseTypeIds", innerHouseTypeIds);
          console.log("houseTypeId", houseTypeId);
          console.log("roomTypeId", roomTypeId);
          console.log("houseTypeName", houseTypeName);
          console.log("roomTypeName", roomTypeName);

          const { houseTypeIds } = this.state;
          this.setState({
            houseTypeIds: houseTypeIds.filter((_, index) => index !== 0),
            typesOfHouseRoomModal: false,
            innerHouseTypeIds: innerHouseTypeIds,
            houseTypeName: houseTypeName,
            houseTypeId: houseTypeId,
            roomTypeName: roomTypeName,
            roomTypeId: roomTypeId,
            roomQuantityModal: true,
            random: Math.random(),
          }, () => {
            //    TODO 
            // const { houseTypeIds } = this.state;
            // if (Boolean(houseTypeIds) && houseTypeIds.length > 0) {
            //   let houseTypeId = houseTypeIds[0];
            //   let houseTypeName = this.state["Apartment.TypeOfHousesSelectItems"].find(p => p.id === houseTypeId);
            //   this.setState({
            //     typesOfHouseRoomModal: true,
            //     houseTypeName: houseTypeName.name,
            //     houseTypeId: houseTypeId
            //   }, async () => {
            //     if (Boolean(this.formRefHouseRooms) && Boolean(this.formRefHouseRooms.current)) {
            //       await this.fillHouseRoomsNotNull(houseTypeId);

            //       const { houseRoomsNotNull } = this.state;
            //       let houseRooms = [];
            //       console.log("data", houseRoomsNotNull);
            //       if (Boolean(houseRoomsNotNull) && houseRoomsNotNull.length > 0) {
            //         houseRoomsNotNull.forEach(element => {
            //           houseRooms.push({ "roomTypeId": element.roomTypeId, "numberOfRoom": element.numberOfRoom });
            //         });
            //       }
            //       this.formRefHouseRooms.current.setFieldsValue({
            //         houseRooms: houseRooms
            //       });
            //     }
            //   });
            // } else {
            //   this.setState({
            //     typesOfHouseRoomModal: false,
            //     outerLayoutApartmentTypeModal: true
            //   }, async () => {
            //     if (Boolean(this.formRefOuterCoordinates) && Boolean(this.formRefOuterCoordinates.current)) {
            //       this.formRefOuterCoordinates.current.resetFields();
            //     }
            //   });
            // }
          });
        }
        this.setState({
          loadingButtonSubmit: false
        });
      } else {
        showWarning("House room not found.")
        this.setState({
          loadingButtonSubmit: false
        });
      }
    } else {
      showWarning("House type not found.")
      this.setState({
        loadingButtonSubmit: false
      });
    }
  };

  saveOuterCoordinates = async values => {
    if (Boolean(this.state.apartmentId) && this.state.apartmentId > 0) {
      if (Boolean(values.outerCoordinates) && values.outerCoordinates.length > 0) {
        values.outerCoordinates.forEach(element => {
          element.apartmentId = this.state.apartmentId;
          element.whichFloor = this.state.whichFloor;
          element.maxFloor = this.state.maxFloor;
          element.section = this.state.section;
        });
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveOuterCoordinates/" + this.state.whichFloor, values.outerCoordinates);
        if (response.type === "ERROR") {
          error(response);
        } else {
          this.cancel();
          const { pagination } = this.state;
          this.restartTable({ pagination });

          const { whichFloor, maxFloor } = this.state;
          if (maxFloor > whichFloor) {
            this.setState({
              whichFloor: whichFloor + 1,
              outerLayoutApartmentTypeModal: false,
            }, () => {
              this.setState({
                outerLayoutApartmentTypeModal: true,
              }, () => {
                if (Boolean(this.formRefOuterCoordinates) && Boolean(this.formRefOuterCoordinates.current)) {
                  this.formRefOuterCoordinates.current.resetFields();
                }
              });
            });
          } else {
            const { apartmentIds } = this.state;
            console.log("apartmentIds", apartmentIds);
            this.setState({
              apartmentIds: apartmentIds.filter((_, index) => index !== 0),
              outerLayoutApartmentTypeModal: false,
              whichFloor: 1,
              apartmentName: "",
              apartmentId: null,
              section: "",
            }, () => {
              const { apartmentIds } = this.state;
              console.log("apartmentIds", apartmentIds);
              if (Boolean(apartmentIds) && apartmentIds.length > 0) {
                let apartmentId = apartmentIds[0].apartmentId;
                let section = apartmentIds[0].section;
                let apartmentName = this.state.apartmentIdSelectItems.find(p => p.key === apartmentId);
                console.log("apartmentIds", apartmentIds);
                this.setState({
                  outerLayoutApartmentTypeModal: true,
                  apartmentName: apartmentName.value,
                  apartmentId: apartmentId,
                  section: section,
                }, () => {
                  if (Boolean(this.formRefOuterCoordinates) && Boolean(this.formRefOuterCoordinates.current)) {
                    this.formRefOuterCoordinates.current.resetFields();
                  }
                });
              } else {
                this.setState({
                  outerLayoutApartmentTypeModal: false,
                  innerHouseTypeRoomModal: true
                }, () => {
                  if (Boolean(this.formRefOuterCoordinates) && Boolean(this.formRefOuterCoordinates.current)) {
                    this.formRefOuterCoordinates.current.resetFields();
                  }
                });
              }
            });
          }
        }
      }
    } else {
      showWarning("Apartment not found.")
    }
  };

  saveInnerCoordinates = async values => {
    if (Boolean(this.state.roomTypeId) && this.state.roomTypeId > 0 && Boolean(this.state.houseTypeId) && this.state.houseTypeId > 0) {
      if (Boolean(values.innerCoordinates) && values.innerCoordinates.length > 0) {
        values.innerCoordinates.forEach(element => {
          element.roomTypeId = this.state.roomTypeId;
          element.houseTypeId = this.state.houseTypeId;
        });
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveInnerCoordinates", values.innerCoordinates);
        if (response.type === "ERROR") {
          error(response);
        } else {
          this.cancel();
          const { pagination } = this.state;
          this.restartTable({ pagination });

          const { innerHouseTypeIds } = this.state;
          console.log("innerHouseTypeIds", innerHouseTypeIds);
          this.setState({
            innerHouseTypeIds: innerHouseTypeIds.filter((_, index) => index !== 0),
            innerHouseTypeRoomModal: false,
            whichFloor: 1,
            apartmentName: "",
            apartmentId: null,
            section: "",
          }, () => {
            const { innerHouseTypeIds } = this.state;
            console.log("innerHouseTypeIds", innerHouseTypeIds);
            if (Boolean(innerHouseTypeIds) && innerHouseTypeIds.length > 0) {

              let houseTypeName = "", houseTypeId = null, roomTypeName = "", roomTypeId = null;
              if (Boolean(innerHouseTypeIds) && innerHouseTypeIds.length > 0) {
                houseTypeId = innerHouseTypeIds[0].houseTypeId;
                houseTypeName = this.state["Apartment.TypeOfHousesSelectItems"].find(p => p.id === houseTypeId).name;

                roomTypeId = innerHouseTypeIds[0].roomTypeId;
                roomTypeName = this.state["Apartment.ApartmentRoomTypeSelectItems"].find(p => p.id === roomTypeId).name;
              }

              console.log("innerHouseTypeIds", innerHouseTypeIds);
              this.setState({
                innerHouseTypeRoomModal: true,
                innerHouseTypeIds: innerHouseTypeIds,
                houseTypeName: houseTypeName,
                houseTypeId: houseTypeId,
                roomTypeName: roomTypeName,
                roomTypeId: roomTypeId,
              }, () => {
                if (Boolean(this.formRefInnerCoordinates) && Boolean(this.formRefInnerCoordinates.current)) {
                  this.formRefInnerCoordinates.current.resetFields();
                }
              });
            } else {
              this.setState({
                innerHouseTypeRoomModal: false,
              }, () => {
                if (Boolean(this.formRefInnerCoordinates) && Boolean(this.formRefInnerCoordinates.current)) {
                  this.formRefInnerCoordinates.current.resetFields();
                }
              });
            }
          });
        }
      }
    } else {
      showWarning("Room type or house type not found.")
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillApartmentTypes = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data,
        //  currentApartmentTypeSelectItems: response.data,
      });
    }
  };

  houseRoomModal = async (row) => {
    if (Boolean(row.houseTypeId) && row.houseTypeId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/houseRooms/" + row.houseTypeId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          modalDimensionShow: true,
          houseRoomList: Boolean(response.data) ? response.data : []
        });
      }
    } else {
      this.setState({
        modalDimensionShow: true,
        houseRoomList: []
      });
    }
  };

  roomQuantityModalClose = () => {
    this.setState({
      roomQuantityModal: false
    }, () => {
      let innerHouseTypeIds = this.state.innerHouseTypeIds.filter((_, index) => index !== 0);
      console.log("innerHouseTypeIds", innerHouseTypeIds);
      if (Boolean(innerHouseTypeIds), innerHouseTypeIds.length > 0) {
        this.setState({
          innerHouseTypeIds: innerHouseTypeIds,
          typesOfHouseRoomModal: false,
          houseTypeId: innerHouseTypeIds[0].houseTypeId,
          roomTypeId: innerHouseTypeIds[0].roomTypeId,
          roomQuantityModal: true,
          random: Math.random(),
        });
      } else {
        const { houseTypeIds } = this.state;
        if (Boolean(houseTypeIds) && houseTypeIds.length > 0) {
          let houseTypeId = houseTypeIds[0];
          let houseTypeName = this.state["Apartment.TypeOfHousesSelectItems"].find(p => p.id === houseTypeId);
          this.setState({
            typesOfHouseRoomModal: true,
            houseTypeName: houseTypeName.name,
            houseTypeId: houseTypeId
          }, async () => {
            if (Boolean(this.formRefHouseRooms) && Boolean(this.formRefHouseRooms.current)) {
              await this.fillHouseRoomsNotNull(houseTypeId);

              const { houseRoomsNotNull } = this.state;
              let houseRooms = [];
              console.log("data", houseRoomsNotNull);
              if (Boolean(houseRoomsNotNull) && houseRoomsNotNull.length > 0) {
                houseRoomsNotNull.forEach(element => {
                  houseRooms.push({ "roomTypeId": element.roomTypeId, "numberOfRoom": element.numberOfRoom });
                });
              }
              this.formRefHouseRooms.current.setFieldsValue({
                houseRooms: houseRooms
              });
            }
          });
        } else {
          this.setState({
            typesOfHouseRoomModal: false,
            outerLayoutApartmentTypeModal: true
          }, async () => {
            if (Boolean(this.formRefOuterCoordinates) && Boolean(this.formRefOuterCoordinates.current)) {
              this.formRefOuterCoordinates.current.resetFields();
            }
          });
        }
      }
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };

    const columns = [
      // {
      //   key: "key",
      //   render: this.action
      // },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="Apartment.ApartmentNo" defaultMessage="Apartment no" />,
        key: "id",
        render: record => {
          return record.name.match(/\d+/g);
        },
      },
      {
        title: <FormattedMessage id="Apartment.Description" defaultMessage="Description" />,
        key: "name",
        render: record => {
          return record.name;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.name) ? this.state.pagination.name : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  name: e.target.value,
                  nameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.nameColor) ? this.state.pagination.nameColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Apartment.ApartmentType" defaultMessage="Apartment Type" />,
        key: "apartmentTypeId",
        render: record => {
          return record.apartmentTypeName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch allowClear optionFilterProp="children"
              style={{ width: "300px" }}
              value={Boolean(this.state.pagination.apartmentTypeId) ? this.state.pagination.apartmentTypeId : null}
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  apartmentTypeId: value,
                  apartmentTypeIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            >
              {Boolean(this.state["Apartment.ApartmentTypeSelectItems"]) && this.state["Apartment.ApartmentTypeSelectItems"].length > 0 &&
                this.state["Apartment.ApartmentTypeSelectItems"].map(i => (
                  <Option key={i.id} value={i.id}>
                    {i.name}
                  </Option>
                ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.apartmentTypeIdColor) ? this.state.pagination.apartmentTypeIdColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Apartment.Sections" defaultMessage="Sections" />,
        key: "section",
        render: record => {
          return record.section;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.section) ? this.state.pagination.section : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  section: e.target.value,
                  sectionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.sectionColor) ? this.state.pagination.sectionColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Apartment.Floors" defaultMessage="Floors" />,
        key: "floor",
        render: record => {
          return record.floor;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <InputNumber
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.floor) ? this.state.pagination.floor : null}
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  floor: value,
                  floorColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.floorColor) ? this.state.pagination.floorColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Apartment.AllFloor" defaultMessage="All Floor" />,
        key: "allFloor",
        render: record => {
          return (
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={record.allFloor ? true : false}
            />
          );
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch allowClear optionFilterProp="children"
              style={{ width: "300px" }}
              value={Boolean(this.state.pagination.allFloor) ? this.state.pagination.allFloor : null}
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  allFloor: value,
                  allFloorColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            >
              <Option key={true} value={true}>
                <FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />
              </Option>
              <Option key={false} value={false}>
                <FormattedMessage id="GeneralButtonNo" defaultMessage="No" />
              </Option>
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.allFloorColor) ? this.state.pagination.allFloorColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Apartment.HouseType" defaultMessage="House Type" />,
        key: "houseTypeId",
        render: record => {
          return record.houseTypeName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch allowClear optionFilterProp="children"
              style={{ width: "300px" }}
              value={Boolean(this.state.pagination.houseTypeId) ? this.state.pagination.houseTypeId : null}
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  houseTypeId: value,
                  houseTypeIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            >
              {Boolean(this.state["Apartment.TypeOfHousesSelectItems"]) && this.state["Apartment.TypeOfHousesSelectItems"].length > 0 &&
                this.state["Apartment.TypeOfHousesSelectItems"].map(i => (
                  <Option key={i.id} value={i.id}>
                    {i.name}
                  </Option>
                ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.houseTypeIdColor) ? this.state.pagination.houseTypeIdColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Apartment.NumberOfHouse" defaultMessage="Number of House" />,
        key: "numberOfHouse",
        render: record => {
          return record.numberOfHouse;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <InputNumber
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.numberOfHouse) ? this.state.pagination.numberOfHouse : null}
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  numberOfHouse: value,
                  numberOfHouseColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.numberOfHouseColor) ? this.state.pagination.numberOfHouseColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Apartment.NumberOfHouses" defaultMessage="Number of Houses" />,
        key: "houseTypeId",
        render: record => {
          return (record.houseTypeId) && record.houseTypeId > 0 ? (
            <AntButton
              onClick={() => this.houseRoomModal(record)}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ZoomInOutlined fontSize="large" />}
            ></AntButton>
          ) : (
            ""
          )
        },
      },
    ];

    const columnsHouseRoomList = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="RoomApplication.RoomType" defaultMessage="Room Type" />,
        key: "id",
        render: record => {
          return record.roomTypeName;
        },
      },
      {
        title: <FormattedMessage id="RoomApplication.NumberOfRoom" defaultMessage="Number of Room" />,
        key: "id",
        render: record => {
          return record.numberOfRoom;
        },
      },
    ];

    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="Apartment" hideElement={true}>             <ExcelSheet name="Apartment" data={this.state.tableList}>               <ExcelColumn label="Project" value="project" />
      <ExcelColumn label="Apartment No" value="itemGroup" />
      <ExcelColumn label="Description" value="item" />
      <ExcelColumn label="Apartment Type" value="requestDate" />
      <ExcelColumn label="Sections" value="quantity" />

    </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} 
    icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)}      */}
    </>);

    return (
      <Badge.Ribbon text={<FormattedMessage id="Apartment.pageTitle" defaultMessage="Apartments Details" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={23}></Col>
              <Col md={1}>
                <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickTotalNumberOfApartment}
                  icon={<SettingTwoTone />} ></AntButton>
              </Col>
              {/* <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />} ></AntButton>
                <AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />} ></AntButton>
              </Col> */}
            </Row>
          </Form>

          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (<div className="alert alert-custom alert-light">
            <div className="alert-icon">                   <FilterOutlined />                 </div>                 <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                  <span className="mr-2">{val.label}</span>                         <strong className="mr-2">{val.type}</strong>
                  <span className="mr-2">{val.operationValue}</span>
                  <span className="mr-2">{val.showValue}</span>
                  <span>                           <Tooltip title="Remove">
                    <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                  </Tooltip>
                  </span>
                </div>);
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />
              </Tooltip>
            </div>
          </div>)}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table bordered scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination} />
          </div>

          <Modal
            title={<Badge.Ribbon text={<FormattedMessage id="Apartment.Apartments" defaultMessage="Apartments" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
            keyboard={false}
            maskClosable={false}
            open={this.state.totalApartmentsModal}
            onCancel={() => {
              this.setState({ totalApartmentsModal: false });
            }}
            okButtonProps={{ hidden: false }}
            okText={<FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />}
            onOk={() => this.formRefTotalNumberOfApartments.current.submit()}
            width={800}
            centered
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveTotalNumberOfApartments} ref={this.formRefTotalNumberOfApartments}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Apartment.TotalNumberOfApartments" defaultMessage="Total Number Of Apartments" />}
                name="totalNumberOfApartments"

                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber value={Boolean(this.state.currentNumberOfApartments) ? this.state.currentNumberOfApartments : 1} min={1} style={{ width: "100%" }} />
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title={<Badge.Ribbon text={<FormattedMessage id="Apartment.TypesOfApartments" defaultMessage="Types of Apartments" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
            keyboard={false}
            maskClosable={false}
            open={this.state.typesOfApartmentsModal}
            onCancel={() => {
              this.setState({
                typesOfApartmentsModal: false
              });
            }}
            okButtonProps={{ hidden: true }}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            width={800}
            centered
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      totalApartmentsModal: true,
                      typesOfApartmentsModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonPrevious" defaultMessage="Previous" />
                </Button>
                <Spin spinning={this.state.loadingButtonSubmit}>
                  <Button
                    variant="primary"
                    onClick={() => {
                      this.formRefApartmentTypes.current.submit()
                    }}
                  >
                    <FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />
                  </Button>
                </Spin>
              </Space>
            ]}
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveApartmentTypes} ref={this.formRefApartmentTypes}>
              <Form.List name="apartmentTypes">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.ApartmentType"} defaultMessage={"Apartment Type"} />
                            </th>
                            <th colSpan="1">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "apartmentTypeName"]}>
                                    <Input />
                                  </Form.Item>
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"Apartment.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          </Modal>

          <Modal
            title={<Badge.Ribbon text={<FormattedMessage id="Apartment.ApartmentsAndTypes" defaultMessage="Apartments And Types" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
            keyboard={false}
            maskClosable={false}
            open={this.state.apartmentsAndTypesModal}
            onCancel={() => {
              this.setState({ apartmentsAndTypesModal: false });
            }}
            okButtonProps={{ hidden: true }}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            width={1000}
            centered
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      typesOfApartmentsModal: true,
                      apartmentsAndTypesModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonPrevious" defaultMessage="Previous" />
                </Button>
                <Spin spinning={this.state.loadingButtonSubmit}>
                  <Button
                    variant="primary"
                    onClick={() => {
                      this.setState({
                        loadingButtonSubmit: true,
                      }, () => {
                        this.formRefApartmentsAndTypes.current.submit();
                      });
                    }}
                  >
                    <FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />
                  </Button>
                </Spin>
              </Space>
            ]}
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveApartmentsAndTypes} ref={this.formRefApartmentsAndTypes}>
              <Form.List name="apartmentsAndTypes">
                {(fields, { add, remove }) => {
                  const handleAddClick = () => {
                    this.calcApartmentsForApartmentsAndTypes();
                    add();
                  };

                  const handleRemoveClick = (fieldKey) => {
                    remove(fieldKey);
                    this.calcApartmentsForApartmentsAndTypes();
                  };

                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.Apartment"} defaultMessage={"Apartment"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.ApartmentType"} defaultMessage={"Apartment Type"} />
                            </th>
                            <th colSpan="1">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "id"]}>
                                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                      {Boolean(this.state["currentApartmentIdSelectItems"]) && this.state["currentApartmentIdSelectItems"].length > 0 &&
                                        this.state["currentApartmentIdSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "apartmentTypeId"]}>
                                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                      {Boolean(this.state["Apartment.ApartmentTypeSelectItems"]) && this.state["Apartment.ApartmentTypeSelectItems"].length > 0 &&
                                        this.state["Apartment.ApartmentTypeSelectItems"].map(i => (
                                          <Option key={i.id} value={i.id}>
                                            {i.name}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => handleRemoveClick(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"Apartment.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          </Modal>

          <Modal
            title={<Badge.Ribbon text={<FormattedMessage id="Apartment.SectionsOfApartmentTypes" defaultMessage="Sections of Apartment Types" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
            keyboard={false}
            maskClosable={false}
            open={this.state.sectionsOfApartmentsModal}
            onCancel={() => {
              this.setState({ sectionsOfApartmentsModal: false, });
            }}
            okButtonProps={{ hidden: true }}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            width={1000}
            centered
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      apartmentsAndTypesModal: true,
                      sectionsOfApartmentsModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonPrevious" defaultMessage="Previous" />
                </Button>
                <Spin spinning={this.state.loadingButtonSubmit}>
                  <Button
                    variant="primary"
                    onClick={() => {
                      let exception = false;
                      const apartmentSections = this.formRefSectionAndApartments.current.getFieldValue("apartmentSections");
                      console.log("apartmentSections", apartmentSections);
                      const uniqueData = [];
                      if (Boolean(apartmentSections) && apartmentSections.length > 0) {
                        apartmentSections.forEach(item => {
                          const { apartmentTypeId, section } = item;
                          if (!uniqueData.some(uniqueItem => uniqueItem.apartmentTypeId === apartmentTypeId && uniqueItem.section === section)) {
                            uniqueData.push(item);
                          } else {
                            let apartmentType = this.state["Apartment.ApartmentTypeSelectItems"].find(p => p.id === apartmentTypeId);
                            showWarning("Apartment and Section must be unique. (Apartment Type: " + apartmentType.name + ", Section: " + section + ")");
                            exception = true;
                            return;
                          }
                        });
                      }

                      if (!exception) {
                        this.formRefSectionAndApartments.current.submit();
                      }
                    }}
                  >
                    <FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />
                  </Button>
                </Spin>
              </Space>
            ]}
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveSectionAndApartments} ref={this.formRefSectionAndApartments}>
              <Form.List name="apartmentSections">
                {(fields, { add, remove }) => {

                  const handleAddClick = () => {
                    // this.calcApartmentsSectionsApartmentsAndTypes();
                    add();
                  };

                  const handleRemoveClick = (fieldKey) => {
                    remove(fieldKey);
                    // this.calcApartmentsSectionsApartmentsAndTypes();
                  };

                  const handleCopyClick = (fieldKey) => {
                    let newIndex = parseInt(fieldKey, 10) + 1;
                    const apartmentSections = this.formRefSectionAndApartments.current.getFieldValue("apartmentSections");
                    let apartmentSection = { ...apartmentSections[fieldKey] };;
                    apartmentSection.section = "";

                    apartmentSections.splice(newIndex, 0, apartmentSection);

                    console.log("apartmentSections", apartmentSections);

                    this.formRefSectionAndApartments.current.setFieldsValue({
                      apartmentSections: apartmentSections
                    });

                    // this.calcApartmentsSectionsApartmentsAndTypes();
                  };

                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.ApartmentType"} defaultMessage={"Apartment Type"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.Sections"} defaultMessage={"Sections"} />
                            </th>
                            <th colSpan="1"></th>
                            <th colSpan="1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "apartmentTypeId"]}>
                                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                      {Boolean(this.state["currentApartmentTypeSelectItems"]) && this.state["currentApartmentTypeSelectItems"].length > 0 &&
                                        this.state["currentApartmentTypeSelectItems"].map(i => (
                                          <Option key={i.id} value={i.id}>
                                            {i.name}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "section"]}>
                                    <Input style={{ width: "100%" }} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <PlusOutlined onClick={() => handleCopyClick(field.name)} />
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => handleRemoveClick(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"Apartment.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          </Modal>

          <Modal
            title={<Badge.Ribbon text={<FormattedMessage id="Apartment.NumberOfFloors" defaultMessage="Number of Floors" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
            keyboard={false}
            maskClosable={false}
            open={this.state.numberOfFloorsModal}
            onCancel={() => {
              this.setState({ numberOfFloorsModal: false });
            }}
            okButtonProps={{ hidden: true }}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            width={800}
            centered
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      sectionsOfApartmentsModal: true,
                      numberOfFloorsModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonPrevious" defaultMessage="Previous" />
                </Button>
                <Spin spinning={this.state.loadingButtonSubmit}>
                  <Button
                    variant="primary"
                    onClick={() => {
                      this.formRefNumberOfFloors.current.submit();
                    }}
                  >
                    <FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />
                  </Button>
                </Spin>
              </Space>
            ]}
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveNumberOfFloors} ref={this.formRefNumberOfFloors}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Apartment.Type" defaultMessage="Type" />}
                name="allSameVariableType"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Radio.Group
                  onChange={e => {
                    this.setState({
                      allSameVariableType: e.target.value
                    });
                  }}
                >
                  <Radio key={"AllSame"} value={"AllSame"}>
                    {<FormattedMessage id="Apartment.AllSame" defaultMessage="All Same" />}
                  </Radio>
                  <Radio key={"Variable"} value={"Variable"}>
                    {<FormattedMessage id="Apartment.Variable" defaultMessage="Variable" />}
                  </Radio>
                </Radio.Group>
              </Form.Item>

              {Boolean(this.state.allSameVariableType) &&
                <>
                  {this.state.allSameVariableType === "AllSame" &&
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="Apartment.Floor" defaultMessage="Floor" />}
                      name="floor"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber min={1} style={{ width: "100%" }} />
                    </Form.Item>
                  }

                  {this.state.allSameVariableType === "Variable" &&
                    <Form.List name="numberOfFloors">
                      {(fields, { add, remove }) => {
                        const handleAddClick = () => {
                          const numberOfFloors = this.formRefNumberOfFloors.current.getFieldValue("numberOfFloors");
                          if (Boolean(numberOfFloors) && numberOfFloors.length > 0) {
                            const isValid = numberOfFloors.every(element => Boolean(element) && Boolean(element.apartmentTypeId) && Boolean(element.section) && Boolean(element.floor));
                            if (!isValid) {
                              showWarning("Apartment type, section and floor are required.");
                            } else {
                              this.calcApartmentTypeAndSection();
                              add();
                            }
                          } else {
                            this.calcApartmentTypeAndSection();
                            add();
                          }
                        };

                        const handleRemoveClick = (fieldKey) => {
                          remove(fieldKey);
                          this.calcApartmentTypeAndSection();
                        };

                        return (
                          <>
                            <TableBoot bordered size="sm">
                              <thead>
                                <tr>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"Apartment.ApartmentType"} defaultMessage={"Apartment Type"} />
                                  </th>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"Apartment.Sections"} defaultMessage={"Sections"} />
                                  </th>
                                  <th colSpan="1" style={{ textAlign: "center" }}>
                                    <FormattedMessage id={"Apartment.NumberOfFloors"} defaultMessage={"Number Of Floors"} />
                                  </th>
                                  <th colSpan="1">
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {fields.map(field => {
                                  return (
                                    <tr key={field.key}>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "apartmentTypeId"]}>
                                          <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}
                                            onChange={value => {
                                              this.fillApartmentSectionNumberOfFloors(value);
                                            }}>
                                            {Boolean(this.state["currentApartmentTypeSelectItems"]) && this.state["currentApartmentTypeSelectItems"].length > 0 &&
                                              this.state["currentApartmentTypeSelectItems"].map(i => (
                                                <Option key={i.id} value={i.id}>
                                                  {i.name}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "section"]}>
                                          <Select allowClear
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                          >
                                            {Boolean(this.state["currentSectionSelectItems"]) && this.state["currentSectionSelectItems"].length > 0 &&
                                              this.state["currentSectionSelectItems"].map(i => (
                                                <Option key={i.key} value={i.key}>
                                                  {i.value}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <Form.Item noStyle name={[field.name, "floor"]}>
                                          <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <MinusCircleOutlined onClick={() => handleRemoveClick(field.name)} />
                                      </td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <td colSpan={11}>
                                    <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                                      <FormattedMessage id={"Apartment.AddNew"} defaultMessage={"Add New"} />
                                    </AntButton>
                                  </td>
                                </tr>
                              </tbody>
                            </TableBoot>
                          </>
                        );
                      }}
                    </Form.List>
                  }
                </>
              }
            </Form>
          </Modal>

          <Modal
            title={<Badge.Ribbon text={<FormattedMessage id="Apartment.TypeOfHouses" defaultMessage="Type Of Houses" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
            keyboard={false}
            maskClosable={false}
            open={this.state.typeOfHousesModal}
            onCancel={() => {
              this.setState({ typeOfHousesModal: false });
            }}
            okButtonProps={{ hidden: true }}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            width={800}
            centered
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      numberOfFloorsModal: true,
                      typeOfHousesModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonPrevious" defaultMessage="Previous" />
                </Button>
                <Spin spinning={this.state.loadingButtonSubmit}>
                  <Button
                    variant="primary"
                    onClick={() => {
                      this.formRefHouseTypes.current.submit();
                    }}
                  >
                    <FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />
                  </Button>
                </Spin>
              </Space>
            ]}
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveHouseTypes} ref={this.formRefHouseTypes}>
              <Form.List name="typeOfHouses">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.HouseType"} defaultMessage={"House Type"} />
                            </th>
                            <th colSpan="1">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "houseTypeName"]}>
                                    <Input />
                                  </Form.Item>
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"Apartment.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          </Modal>

          <Modal
            title={<Badge.Ribbon text={<FormattedMessage id="Apartment.NumberOfHouses" defaultMessage="Number of Houses" />} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
            keyboard={false}
            maskClosable={false}
            open={this.state.typeHouseRoomsModal}
            onCancel={() => {
              this.setState({ typeHouseRoomsModal: false });
            }}
            okButtonProps={{ hidden: true }}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            width={1300}
            centered
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      typeOfHousesModal: true,
                      typeHouseRoomsModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonPrevious" defaultMessage="Previous" />
                </Button>
                <Spin spinning={this.state.loadingButtonSubmit}>
                  <Button
                    variant="primary"
                    onClick={() => {
                      this.formRefSectionHouses.current.submit();
                    }}
                  >
                    <FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />
                  </Button>
                </Spin>
              </Space>
            ]}
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveSectionHouses} ref={this.formRefSectionHouses}>
              <Form.List name="sectionHouses">
                {(fields, { add, remove }) => {

                  const handleAddClick = () => {
                    const sectionHouses = this.formRefSectionHouses.current.getFieldValue("sectionHouses");
                    if (Boolean(sectionHouses) && sectionHouses.length > 0) {
                      const isValid = sectionHouses.every(element => Boolean(element) && Boolean(element.apartmentTypeId) && Boolean(element.section)
                        && Boolean(element.floor) && Boolean(element.houseTypeId));
                      if (!isValid) {
                        showWarning("Apartment type, section, floor and house type are required.");
                      } else {
                        //this.calcApartmentsForSectionHouses();
                        add();
                      }
                    } else {
                      //this.calcApartmentsForSectionHouses();
                      add();
                    }
                  };

                  const handleRemoveClick = (fieldKey) => {
                    remove(fieldKey);
                    //this.calcApartmentsForSectionHouses();
                  };

                  const handleCopyClick = (fieldKey) => {
                    let newIndex = parseInt(fieldKey, 10) + 1;
                    const sectionHouses = this.formRefSectionHouses.current.getFieldValue("sectionHouses");
                    let apartmentSection = { ...sectionHouses[fieldKey] };;
                    apartmentSection.houseTypeId = null;
                    apartmentSection.numberOfHouse = null;

                    sectionHouses.splice(newIndex, 0, apartmentSection);

                    console.log("sectionHouses", sectionHouses);

                    this.formRefSectionHouses.current.setFieldsValue({
                      sectionHouses: sectionHouses
                    });
                    //this.calcApartmentsForSectionHouses();
                  };

                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.ApartmentType"} defaultMessage={"Apartment Type"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.Sections"} defaultMessage={"Sections"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.Floor"} defaultMessage={"Floor"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.HouseType"} defaultMessage={"House Type"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.NumberOfHouse"} defaultMessage={"Number of House"} />
                            </th>
                            <th colSpan="1">  </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <>
                                <Form.Item hidden name={[field.name, "apartmentTypeId"]}>
                                  <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}
                                    onChange={value => {
                                      this.fillFloors(value);
                                      this.fillApartmentSection(value);
                                    }}>
                                    {Boolean(this.state["currentApartmentTypeSelectItems"]) && this.state["currentApartmentTypeSelectItems"].length > 0 &&
                                      this.state["currentApartmentTypeSelectItems"].map(i => (
                                        <Option key={i.id} value={i.id}>
                                          {i.name}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item name={[field.name, "apartmentTypeName"]}>
                                      <Input readOnly></Input>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item name={[field.name, "section"]}>
                                      <Input readOnly></Input>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item name={[field.name, "floor"]}>
                                      <Input readOnly></Input>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "houseTypeId"]}>
                                      <Select allowClear
                                        style={{ width: "100%" }}
                                        optionFilterProp="children"
                                      >
                                        {Boolean(this.state["Apartment.TypeOfHousesSelectItems"]) && this.state["Apartment.TypeOfHousesSelectItems"].length > 0 &&
                                          this.state["Apartment.TypeOfHousesSelectItems"].map(i => (
                                            <Option key={i.id} value={i.id}>
                                              {i.name}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "numberOfHouse"]}>
                                      <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Space>
                                      <PlusOutlined onClick={() => handleCopyClick(field.name)} />
                                      <MinusCircleOutlined onClick={() => handleRemoveClick(field.name)} />
                                    </Space>
                                  </td>
                                </tr>
                              </>
                            );
                          })}

                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"Apartment.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          </Modal>

          <Modal
            title={<Badge.Ribbon text={this.state.houseTypeName} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
            keyboard={false}
            maskClosable={false}
            open={this.state.typesOfHouseRoomModal}
            onCancel={() => {
              this.setState({ typesOfHouseRoomModal: false });
            }}
            okButtonProps={{ hidden: true }}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            width={800}
            centered
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      typeHouseRoomsModal: true,
                      typesOfHouseRoomModal: false
                    });
                  }}
                >
                  <FormattedMessage id="GeneralButtonPrevious" defaultMessage="Previous" />
                </Button>
                <Spin spinning={this.state.loadingButtonSubmit}>
                  <Button
                    variant="primary"
                    onClick={() => {
                      this.formRefHouseRooms.current.submit();
                      const { houseTypeIds } = this.state;
                      if (Boolean(houseTypeIds) && houseTypeIds.length > 0) {
                        let houseTypeId = houseTypeIds[0];
                        let houseTypeName = this.state["Apartment.TypeOfHousesSelectItems"].find(p => p.id === houseTypeId);
                        this.setState({
                          houseTypeName: houseTypeName.name,
                          houseTypeId: houseTypeId
                        });
                      } else {
                        this.setState({
                          typesOfHouseRoomModal: false,
                          outerLayoutApartmentTypeModal: true
                        }, () => {
                          if (Boolean(this.formRefOuterCoordinates) && Boolean(this.formRefOuterCoordinates.current)) {
                            this.formRefOuterCoordinates.current.resetFields();
                          }
                        });
                      }
                    }}
                  >
                    <FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />
                  </Button>
                </Spin>
              </Space>
            ]}
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveHouseRooms} ref={this.formRefHouseRooms}>
              <Form.List name="houseRooms">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.Room"} defaultMessage={"Room"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.NumberOfRoom"} defaultMessage={"Number Of Room"} />
                            </th>
                            <th colSpan="1">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "roomTypeId"]}>
                                    <Select
                                      showSearch
                                      allowClear
                                      optionFilterProp="children"
                                      style={{ width: "100%" }}
                                      dropdownRender={menu => (
                                        <div>
                                          {menu}
                                          <Divider style={{ margin: "4px 0" }} />
                                          <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                            <Input
                                              style={{ flex: "auto" }}
                                              value={this.state.codeName}
                                              onChange={event => {
                                                this.setState({
                                                  codeName: event.target.value
                                                });
                                              }}
                                            />
                                            <a
                                              style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                              onClick={() => this.addCode("Apartment.ApartmentRoomType")}
                                            >
                                              <PlusOutlined /> Add item
                                            </a>
                                          </div>
                                        </div>
                                      )}
                                    >
                                      {Boolean(this.state["Apartment.ApartmentRoomTypeSelectItems"]) && this.state["Apartment.ApartmentRoomTypeSelectItems"].length > 0 &&
                                        this.state["Apartment.ApartmentRoomTypeSelectItems"].map(i => (
                                          <Option key={i.id} value={i.id}>
                                            {i.name}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "numberOfRoom"]}>
                                    <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}

                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"Apartment.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          </Modal>

          <Modal
            title=""
            keyboard={false}
            maskClosable={false}
            open={this.state.roomQuantityModal}
            onCancel={() => {
              this.setState({ roomQuantityModal: false });
            }}
            okButtonProps={{ hidden: true }}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            width={1200}
            centered
          >
            <RoomQuantity random={this.state.random}
              houseTypeId={this.state.houseTypeId}
              roomTypeId={this.state.roomTypeId}
              roomQuantityModalClose={this.roomQuantityModalClose} />
          </Modal>

          {/* 
          <Modal
            title={<Badge.Ribbon text={"Outer Layout Coordinates " + this.state.apartmentName + "/" + this.state.whichFloor} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
            keyboard={false}
            maskClosable={false}
            open={this.state.outerLayoutApartmentTypeModal}
            onCancel={() => {
              this.setState({ outerLayoutApartmentTypeModal: false });
            }}
            okButtonProps={{ hidden: false }}
            okText={<FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            onOk={() => {
              this.formRefOuterCoordinates.current.submit();
            }}
            width={800}
            centered
          >

            <Form initialValues={{ remember: false }} onFinish={this.saveOuterCoordinates} ref={this.formRefOuterCoordinates}>
              <Form.List name="outerCoordinates">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.Point"} defaultMessage={"Point"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.N"} defaultMessage={"N"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.E"} defaultMessage={"E"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.H"} defaultMessage={"H"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.ConnectionType"} defaultMessage={"Connection Type"} />
                            </th>
                            <th colSpan="1">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "point"]}>
                                    <Input />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "n"]}>
                                    <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "e"]}>
                                    <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "h"]}>
                                    <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "connectionType"]}>
                                    <Select allowClear
                                      style={{ width: "100%" }}
                                      optionFilterProp="children"
                                    >
                                      {Boolean(this.state["connectionTypeSelectItems"]) && this.state["connectionTypeSelectItems"].length > 0 &&
                                        this.state["connectionTypeSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"Apartment.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          </Modal> */}

          {/* <Modal
            title={<Badge.Ribbon text={"Inner Layout Coordinates " + this.state.houseTypeName + "/" + this.state.roomTypeName} placement="start" color="volcano" style={{ fontSize: 12 }}></Badge.Ribbon>}
            keyboard={false}
            maskClosable={false}
            open={this.state.innerHouseTypeRoomModal}
            onCancel={() => {
              this.setState({ innerHouseTypeRoomModal: false });
            }}
            okButtonProps={{ hidden: false }}
            okText={<FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            onOk={() => {
              this.formRefInnerCoordinates.current.submit();
            }}
            width={800}
            centered
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveInnerCoordinates} ref={this.formRefInnerCoordinates}>
              <Form.List name="innerCoordinates">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.Point"} defaultMessage={"Point"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.N"} defaultMessage={"N"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.E"} defaultMessage={"E"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.EL"} defaultMessage={"EL"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"Apartment.ConnectionType"} defaultMessage={"Connection Type"} />
                            </th>
                            <th colSpan="1">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "point"]}>
                                    <Input />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "n"]}>
                                    <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "e"]}>
                                    <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "el"]}>
                                    <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "connectionType"]}>
                                    <Select allowClear
                                      style={{ width: "100%" }}
                                      optionFilterProp="children"
                                    >
                                      {Boolean(this.state["connectionTypeSelectItems"]) && this.state["connectionTypeSelectItems"].length > 0 &&
                                        this.state["connectionTypeSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"Apartment.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          </Modal> */}

          <Modal
            title={<FormattedMessage id="RoomApplication.NumberOfHouses" defaultMessage="Number of Houses" />}
            closable="false"
            open={this.state.modalDimensionShow}
            onCancel={() => {
              this.setState({ modalDimensionShow: false });
            }}
            width={1000}
            centered
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDimensionShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
          >
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Table
                bordered
                size="small"
                columns={columnsHouseRoomList}
                dataSource={this.state.houseRoomList}
                loading={this.state.loading}
                pagination={false}
              />
            </div>
          </Modal>
        </div >
      </Badge.Ribbon >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Apartment);
