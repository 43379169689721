import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Space, Radio, Drawer } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import DraggableModal from "../../../Components/DraggableModal";
import { PageAutoDesignDrawerParentNoEntry } from "./PageAutoDesignDrawerParentNoEntry";
import PageAutoDesignDrawerParent from "./PageAutoDesignDrawerParent";

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class PageAutoDesignFormType extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "PageAutoDesignFormType",
      controllerName: "pageAutoDesign",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      materialList: [],
      usageList: [],
      generateType: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.resetFields();
    }

    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {

  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          modalAddNewDimensionShow: false,
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const options = [
      { label: 'Option 1', value: 'Parent_No_Entry', img: '/media/pageAutoDesign/Parent-No-Entry.png' },
      { label: 'Option 2', value: 'Parent', img: '/media/pageAutoDesign/Parent.png' },
      { label: 'Option 3', value: 'Parent_Child', img: '/media/pageAutoDesign/Parent-Child.png' },
      { label: 'Option 4', value: 'Parent_Child_Child', img: '/media/pageAutoDesign/Parent-Child-Child.png' },
      { label: 'Option 5', value: 'Collapse_No_Entry', img: '/media/pageAutoDesign/Collapse-No-Entry.png' },
      { label: 'Option 6', value: 'Collapse_Parent', img: '/media/pageAutoDesign/Collapse-Parent.png' },
      { label: 'Option 7', value: 'Drawer_Index_Cont_Ind_Cont', img: '/media/pageAutoDesign/Drawer-Index-Cont-Ind-Cont.png' },
      { label: 'Option 8', value: 'Drawer_Index_One_Container', img: '/media/pageAutoDesign/Drawer-Index-One Container.png' },
    ];

    const handleChange = e => {
      console.log(e.target.value);
      if (Boolean(e.target.value)) {
        if (e.target.value === "Parent_No_Entry") {
          this.setState({
            modalPageAutoDesignParentNoEntryShow: true,
            random: Math.random(),
            generateType: e.target.value
          });
        } else if (e.target.value === "Parent") {
          this.setState({
            modalPageAutoDesignParentShow: true,
            random: Math.random(),
            generateType: e.target.value
          });
        }
      }
    };

    return (
      <div
        className="card card-custom"
        style={{ padding: "2rem", minHeight: "100%" }}>
        <Form
          initialValues={{ remember: false }}
          onFinish={this.save}
          onFinishFailed={onFinishFailed}
          ref={this.formRef}>
          <div>
            <br /> <br />
            <Form.Item name="type">
              <Radio.Group onChange={handleChange}>
                {options && options.length > 0 &&
                  options.map(i => (
                    <Radio key={i.value} value={i.value}>
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src={toAbsoluteUrl(i.img)}
                          alt={i.label}
                          style={{ width: '200px', height: '200px' }}
                        />
                      </div>
                    </Radio>
                  ))}
              </Radio.Group>
            </Form.Item>
          </div>
        </Form>

        <DraggableModal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={500}
          okButtonProps={{ hidden: true }}
          open={this.state.modalDeleteShow}
          onCancel={() => {
            this.setState({ modalDeleteShow: false });
          }}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDeleteShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Space>
          ]}
          content={
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          }
        />

        <Drawer
          title={<FormattedMessage id="PageAutoDesignFormType.PageAutoDesign" defaultMessage="Page Auto Design" />}
          width={1500} closable={true}
          onClose={() => {
            if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
              this.formRef.current.resetFields();
            }
            this.setState({ modalPageAutoDesignParentNoEntryShow: false });
          }}
          open={this.state.modalPageAutoDesignParentNoEntryShow}>
          <PageAutoDesignDrawerParentNoEntry random={this.state.random} parentId={this.props.parentId}
            generateType={this.state.generateType}
            folder={this.state.folder}
            close={() => {
              if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
                this.formRef.current.resetFields();
              }
              this.setState({ modalPageAutoDesignParentNoEntryShow: false });
              if (Boolean(this.props) && Boolean(this.props.close)) {
                this.props.close();
              }
            }} />
        </Drawer>

        <Drawer
          title={<FormattedMessage id="PageAutoDesignFormType.PageAutoDesign" defaultMessage="Page Auto Design" />}
          width={1500} closable={true}
          onClose={() => {
            if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
              this.formRef.current.resetFields();
            }
            this.setState({ modalPageAutoDesignParentShow: false });
          }}
          open={this.state.modalPageAutoDesignParentShow}>
          <PageAutoDesignDrawerParent random={this.state.random} parentId={this.props.parentId}
            generateType={this.state.generateType}
            folder={this.state.folder}
            close={() => {
              if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
                this.formRef.current.resetFields();
              }
              this.setState({ modalPageAutoDesignParentShow: false });
              if (Boolean(this.props) && Boolean(this.props.close)) {
                this.props.close();
              }
            }} />
        </Drawer>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PageAutoDesignFormType);