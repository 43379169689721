import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { Form, Table, Button as AntButton, Badge, Col, Row, InputNumber, Radio, Image } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import { Select } from "antd";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { Input } from "antd";
import { Modal as AntModal } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, PlusOutlined, MinusCircleOutlined, ArrowRightOutlined, UndoOutlined } from "@ant-design/icons"
import { Tooltip } from "antd";
import { Table as BootstrapTable } from "react-bootstrap";
import MathJax from "react-mathjax2";

const { Option } = Select;

function createOperator(operator) {
  console.log(operator);
  switch (operator) {
    case "add":
      return "+";
    case "subtract":
      return "-";
    case "multiply":
      return "*";
    case "divide":
      return "/";
    case "sqrt":
      return "sqrt";
    case "pow":
      return "^";
    case "open_parenthesis":
      return "(";
    case "close_parenthesis":
      return ")";
    default:
      return "";
  }
}

export class ProductForm extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ProductForm",
      controllerName: "productForm",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      dimensions: [],
      frontCodes: [],
      fileList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseConstant = await handleRequest("GET", "/api/" + this.state.controllerName + "/constant");
    if (responseConstant.type === "ERROR") {
      error(responseConstant);
    }
    this.setState({
      constantSelectItems: Boolean(responseConstant.data) ? responseConstant.data : []
    });

    var responseOperator = await handleRequest("GET", "/api/" + this.state.controllerName + "/operator");
    if (responseOperator.type === "ERROR") {
      error(responseOperator);
    }
    this.setState({
      operatorSelectItems: Boolean(responseOperator.data) ? responseOperator.data : []
    });

    var responseUnitType = await handleRequest("GET", "/api/" + this.state.controllerName + "/unitType");
    if (responseUnitType.type === "ERROR") {
      error(responseUnitType);
    }
    this.setState({
      unitTypeSelectItems: Boolean(responseUnitType.data) ? responseUnitType.data : []
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  addFeature = () => {
    const { features } = this.state;
    let key = 0;
    if (Boolean(features) && features.length > 0) {
      key = features[features.length - 1].key + 1;
    }
    if (key === 0) {
      features.push({ key: key, definition: "Weight", formulaText: "" });
    } else features.push({ key: key, definition: "", formulaText: "" });

    this.setState({ features });
  };

  removeFeature = index => {
    const { features } = this.state;
    features.splice(index, 1);
    this.setState({ features });
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];
    let fileUrl = Boolean(row.fileUrl) ? row.fileUrl.split(",") : [];
    if (Boolean(fileUrl) && fileUrl.length > 0) {
      fileUrl.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileList.push(obj);
      });
    }
    console.log("row", row);
    console.log("fileList", fileList);

    this.formRef.current.setFieldsValue({
      ...row,
      fileUrl: fileList,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      fileList: fileList,
      volumeFormula: row.volumeFormula,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };



    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);



    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    let pathList = [];
    if (Boolean(newItem.fileUrl))
      newItem.fileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) pathList.push(item.response.url);
        else if (Boolean(item.url)) pathList.push(item.url);
      });
    newItem.fileUrl = pathList.toString();

    console.log(newItem);

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addFormAreasToFormulaList = index => {
    console.log("index", index);
    let formAreas =
      Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("formAreas"))
        ? this.formRef.current.getFieldValue("formAreas")
        : [];
    console.log("formAreas", formAreas);

    let field = formAreas[index];
    console.log("field", field);

    let dimensions =
      Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("dimensions"))
        ? this.formRef.current.getFieldValue("dimensions")
        : [];
    console.log("dimensions", dimensions);

    let formulaObject = { constant: field.constant, numberConstant: field.numberConstant, operator: field.operator };
    if (!Boolean(field.formulas)) {
      field.formulas = [];
    }

    formulaObject.indexOrder = Boolean(field.formulas) ? field.formulas.length + 1 : 0;
    if (Boolean(field.dimension)) {
      let d = dimensions.find(x => x.abbreviation === field.dimension);
      formulaObject.dimensionAbbreviation = d.abbreviation;
      formulaObject.dimension = d.definition;
    }
    field.formulas.push(formulaObject);
    field.formulaText = this.getformulaText(field.formulas);
    console.log(field.formulas);
    console.log(field.formulaText);
    field.dimension = null;
    field.constant = null;
    field.numberConstant = null;
    field.operator = null;

    console.log("formAreas", formAreas);
    this.formRef.current.setFieldsValue({
      formAreas: formAreas,
    });
    this.setState({
      formAreas: formAreas,
    });
  };

  removeFormAreasFromFormulaList = index => {
    let formAreas =
      Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("formAreas"))
        ? this.formRef.current.getFieldValue("formAreas")
        : [];
    console.log("formAreas", formAreas);

    let field = formAreas[index];
    if (field.formulas && field.formulas.length > 0) {
      field.formulas.pop();
      field.formulaText = this.getformulaText(field.formulas);
      this.formRef.current.setFieldsValue({
        formAreas: formAreas,
      });
      this.setState({
        formAreas: formAreas,
      });
    }
  };

  addFormVolumesToFormulaList = index => {
    console.log("index", index);
    let formVolumes =
      Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("formVolumes"))
        ? this.formRef.current.getFieldValue("formVolumes")
        : [];
    console.log("formVolumes", formVolumes);

    let field = formVolumes[index];
    console.log("field", field);

    let dimensions =
      Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("dimensions"))
        ? this.formRef.current.getFieldValue("dimensions")
        : [];
    console.log("dimensions", dimensions);

    let formulaObject = { constant: field.constant, numberConstant: field.numberConstant, operator: field.operator };
    if (!Boolean(field.formulas)) {
      field.formulas = [];
    }

    formulaObject.indexOrder = Boolean(field.formulas) ? field.formulas.length + 1 : 0;
    if (Boolean(field.dimension)) {
      let d = dimensions.find(x => x.abbreviation === field.dimension);
      formulaObject.dimensionAbbreviation = d.abbreviation;
      formulaObject.dimension = d.definition;
    }
    field.formulas.push(formulaObject);
    field.formulaText = this.getformulaText(field.formulas);
    console.log(field.formulas);
    console.log(field.formulaText);
    field.dimension = null;
    field.constant = null;
    field.numberConstant = null;
    field.operator = null;

    console.log("formVolumes", formVolumes);
    this.formRef.current.setFieldsValue({
      formVolumes: formVolumes,
    });
    this.setState({
      formVolumes: formVolumes,
    });
  };

  removeFormVolumesFromFormulaList = index => {
    let formVolumes =
      Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("formVolumes"))
        ? this.formRef.current.getFieldValue("formVolumes")
        : [];
    console.log("formVolumes", formVolumes);

    let field = formVolumes[index];
    if (field.formulas && field.formulas.length > 0) {
      field.formulas.pop();
      field.formulaText = this.getformulaText(field.formulas);
      this.formRef.current.setFieldsValue({
        formVolumes: formVolumes,
      });
      this.setState({
        formVolumes: formVolumes,
      });
    }
  };


  getformulaText = formulaList => {
    console.log("formulaList", formulaList);
    let formulaText = "";
    if (Boolean(formulaList) && formulaList.length > 0) {
      formulaList.forEach(element => {
        let constant = Boolean(element.constant)
          ? element.constant
          : Boolean(element.dimensionAbbreviation)
            ? element.dimensionAbbreviation
            : Boolean(element.numberConstant)
              ? element.numberConstant
              : "";
        let operator = Boolean(element.operator) ? createOperator(element.operator) : "";
        formulaText += constant + operator;
      });
    }
    return formulaText;
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    let dimensions =
      Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("dimensions"))
        ? this.formRef.current.getFieldValue("dimensions")
        : [];
    console.log("DIMENSIONS", dimensions);

    let formAreas =
      Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("formAreas"))
        ? this.formRef.current.getFieldValue("formAreas")
        : [];
    console.log("formAreas", formAreas);


    let formVolumes =
      Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("formVolumes"))
        ? this.formRef.current.getFieldValue("formVolumes")
        : [];
    console.log("formVolumes", formVolumes);


    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      style: {
        marginBottom: 0
      }
    };

    const propsUpload = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ProductForm.productForm" defaultMessage="Product Form" />,
        key: "productForm",
        render: record => {
          return record.productForm;
        }, ...getColumnFilter({
          inputName: "productForm", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ProductForm.Sketch" defaultMessage="Sketch" />,
        key: "fileUrl",
        render: record => {
          if (Boolean(record.fileUrl)) {
            return <Image width={50} src={record.fileUrl}></Image>
          }
        },
      },
      {
        title: <FormattedMessage id="ProductForm.area" defaultMessage="Area" />,
        key: "area",
        render: record => {
          return record.area;
        },
      },
      {
        title: <FormattedMessage id="ProductForm.volume" defaultMessage="Volume" />,
        key: "volume",
        render: record => {
          return record.volume;
        },
      },
    ];
    const layoutFormula = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      style: {
        marginBottom: 0
      }
    };

    const excelExport = (<>  
           {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="ProductForm" hideElement={true}>             <ExcelSheet name="ProductForm" data={this.state.tableList}>               <ExcelColumn label="Product Form" value="productForm" />
      <ExcelColumn label="Area" value="area" />
      <ExcelColumn label="Volume" value="volume" />
    </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} 
    icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)}      */}
      </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="productForm.pageTitle" defaultMessage="Product Form" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>             <Col md={21}></Col>             <Col md={1}>{excelExport}</Col>             <Col md={1}>               <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>             </Col>             <Col md={1}>               <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />}               ></AntButton>               <AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />}               ></AntButton>             </Col>           </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProductForm.productForm" defaultMessage="Product Form" />}
                  name="productForm"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProductForm.fileUrl" defaultMessage={"Upload"} />}
                  name="fileUrl"
                  rules={[{ required: false, message: "Missing Load" }]}
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload {...propsUpload} showUploadList={true} maxCount={1}>
                    <AntButton icon={<UploadOutlined />} style={{ width: "100%", marginTop: "0.2rem" }}>
                      <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                    </AntButton>
                  </Upload>
                </Form.Item>
              }

              <br />      <h4><FormattedMessage id={"ProductForm.Dimensions"} defaultMessage={"Dimensions"} /></h4>

              <Form.List name="dimensions">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProductForm.Definition"} defaultMessage={"Definition"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProductForm.Abbreviation"} defaultMessage={"Abbreviation"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProductForm.UnitType"} defaultMessage={"Unit Type"} />
                            </th>
                            <th colSpan="1">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "definition"]}>
                                    <Input style={{ width: "100%" }} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "abbreviation"]}>
                                    <Input style={{ width: "100%" }} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "unitTypeId"]}>
                                    <Select allowClear
                                      style={{ width: "100%" }}
                                      optionFilterProp="children"
                                    >
                                      {Boolean(this.state["unitTypeSelectItems"]) && this.state["unitTypeSelectItems"].length > 0 &&
                                        this.state["unitTypeSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}{" "}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}

                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => {
                                add();
                                this.setState({
                                  addNewDimensions: true
                                });
                              }} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"ProductForm.AddNewDimensions"} defaultMessage={"Add New Dimensions"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>


              <br />      <h4><FormattedMessage id={"ProductForm.AreaFormula"} defaultMessage={"Area Formula"} /></h4>

              <Form.List name="formAreas">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProductForm.Dimension"} defaultMessage={"Dimension"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" 
                          
                          }}>
                              <FormattedMessage id={"ProductForm.Constant"} defaultMessage={"Constant"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProductForm.NumericConstant"} defaultMessage={"Numeric Constant"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProductForm.Operator"} defaultMessage={"Operator"} />
                            </th>
                            <th colSpan="1">
                              <FormattedMessage id={"ProductForm.Add"} defaultMessage={"Add"} />
                            </th>
                            <th colSpan="1">
                              <FormattedMessage id={"ProductForm.Undo"} defaultMessage={"Undo"} />
                            </th>
                            <th colSpan="1">
                              <FormattedMessage id={"ProductForm.Formula"} defaultMessage={"Formula"} />
                            </th>
                            <th colSpan="1">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            console.log("field-field", field)
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "dimension"]}>
                                    <Select allowClear
                                      style={{ width: "100%" }}
                                      optionFilterProp="children"
                                    >
                                      {Boolean(dimensions) &&
                                        dimensions.map(i => (
                                          Boolean(i) && Boolean(i.definition) && Boolean(i.abbreviation) &&
                                          <Option key={i.abbreviation} value={i.abbreviation}>
                                            {i.definition}
                                          </Option>
                                        ))}{" "}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "constant"]}>
                                    <Select allowClear
                                      style={{ width: "100%" }}
                                      optionFilterProp="children"
                                    >
                                      {Boolean(this.state["constantSelectItems"]) && this.state["constantSelectItems"].length > 0 &&
                                        this.state["constantSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}{" "}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "numberConstant"]}>
                                    <InputNumber style={{ width: "100%" }} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "operator"]}>
                                    <Select allowClear
                                      style={{ width: "100%" }}
                                      optionFilterProp="children"
                                    >
                                      {Boolean(this.state["operatorSelectItems"]) && this.state["operatorSelectItems"].length > 0 &&
                                        this.state["operatorSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}{" "}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <ArrowRightOutlined onClick={() => this.addFormAreasToFormulaList(field.key)} />
                                </td>
                                <td>
                                  <UndoOutlined onClick={() => this.removeFormAreasFromFormulaList(field.key)} />
                                </td>
                                <td>
                                  {console.log("formulaText", formAreas)}
                                  {Boolean(formAreas) && formAreas.length > 0 && Boolean(formAreas[field.key]) && Boolean(formAreas[field.key].formulaText) &&
                                    <div>
                                      <MathJax.Context input="ascii">
                                        <div>
                                          <MathJax.Node>{
                                            formAreas[field.key].formulaText
                                          }</MathJax.Node>
                                        </div>
                                      </MathJax.Context>
                                    </div>}
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}

                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"ProductForm.AddNewDimensions"} defaultMessage={"Add New Dimensions"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProductForm.VolumeFormula" defaultMessage="Volume Formula" />}
                name="volumeFormula"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Radio.Group
                  onChange={e => {
                    this.setState({
                      volumeFormula: e.target.value
                    });

                    if (Boolean(e.target.value) && e.target.value === "AreaXLength") {
                      this.formRef.current.setFieldsValue({
                        formVolumes: null,
                      });
                    }
                  }}
                >
                  <Radio value={"AreaXLength"}>Area x Length</Radio>
                  <Radio value={"Formula"}>Formula</Radio>
                </Radio.Group>
              </Form.Item>

              {Boolean(this.state.volumeFormula) && this.state.volumeFormula === "Formula" &&
                <>
                  <br />      <h4><FormattedMessage id={"ProductForm.VolumeFormula"} defaultMessage={"Volume Formula"} /></h4>

                  <Form.List name="formVolumes">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <TableBoot bordered size="sm">
                            <thead>
                              <tr>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ProductForm.Dimension"} defaultMessage={"Dimension"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ProductForm.Constant"} defaultMessage={"Constant"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ProductForm.NumericConstant"} defaultMessage={"Numeric Constant"} />
                                </th>
                                <th colSpan="1" style={{ textAlign: "center" }}>
                                  <FormattedMessage id={"ProductForm.Operator"} defaultMessage={"Operator"} />
                                </th>
                                <th colSpan="1">
                                  <FormattedMessage id={"ProductForm.Add"} defaultMessage={"Add"} />
                                </th>
                                <th colSpan="1">
                                  <FormattedMessage id={"ProductForm.Undo"} defaultMessage={"Undo"} />
                                </th>
                                <th colSpan="1">
                                  <FormattedMessage id={"ProductForm.Formula"} defaultMessage={"Formula"} />
                                </th>
                                <th colSpan="1">
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(field => {
                                return (
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "dimension"]}>
                                        <Select allowClear
                                          style={{ width: "100%" }}
                                          optionFilterProp="children"
                                        >
                                          {Boolean(dimensions) &&
                                            dimensions.map(i => (
                                              Boolean(i) && Boolean(i.definition) && Boolean(i.abbreviation) &&
                                              <Option key={i.abbreviation} value={i.abbreviation}>
                                                {i.definition}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "constant"]}>
                                        <Select allowClear
                                          style={{ width: "100%" }}
                                          optionFilterProp="children"
                                        >
                                          {Boolean(this.state["constantSelectItems"]) && this.state["constantSelectItems"].length > 0 &&
                                            this.state["constantSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "numberConstant"]}>
                                        <InputNumber style={{ width: "100%" }} />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item noStyle name={[field.name, "operator"]}>
                                        <Select allowClear
                                          style={{ width: "100%" }}
                                          optionFilterProp="children"
                                        >
                                          {Boolean(this.state["operatorSelectItems"]) && this.state["operatorSelectItems"].length > 0 &&
                                            this.state["operatorSelectItems"].map(i => (
                                              <Option key={i.key} value={i.key}>
                                                {i.value}
                                              </Option>
                                            ))}{" "}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <ArrowRightOutlined onClick={() => this.addFormVolumesToFormulaList(field.key)} />
                                    </td>
                                    <td>
                                      <UndoOutlined onClick={() => this.removeFormVolumesFromFormulaList(field.key)} />
                                    </td>
                                    <td>
                                      {console.log("formulaText", formVolumes)}
                                      {Boolean(formVolumes) && formVolumes.length > 0 && Boolean(formVolumes[field.key]) && Boolean(formVolumes[field.key].formulaText) &&
                                        <div>
                                          <MathJax.Context input="ascii">
                                            <div>
                                              <MathJax.Node>{
                                                formVolumes[field.key].formulaText
                                              }</MathJax.Node>
                                            </div>
                                          </MathJax.Context>
                                        </div>}
                                    </td>
                                    <td>
                                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"ProductForm.AddNewDimensions"} defaultMessage={"Add New Dimensions"} />
                                  </AntButton>
                                </td>
                              </tr>
                            </tbody>
                          </TableBoot>
                        </>
                      );
                    }}
                  </Form.List>
                </>
              }
              <br />
              <div hidden={this.state.hideSave}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>                     <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">                       <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
              <div hidden={this.state.hideUpdate}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>                       <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />                     </Button>                   </Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">                       <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (<div className="alert alert-custom alert-light">                 <div className="alert-icon">                   <FilterOutlined />                 </div>                 <div className="alert-text">                   {this.state.searchCriteriaModels.map((val, i) => { return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">                         <span className="mr-2">{val.label}</span>                         <strong className="mr-2">{val.type}</strong>                         <span className="mr-2">{val.operationValue}</span>                         <span className="mr-2">{val.showValue}</span>                         <span>                           <Tooltip title="Remove">                             <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />                           </Tooltip>                         </span>                       </div>); })}                 </div>                 <div className="alert-close">                   <Tooltip title="Remove All">                     <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />                   </Tooltip>                 </div>               </div>)}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">           <Table bordered scroll={{ x: 900, scrollToFirstRowOnChange: true }} columns={columns} dataSource={this.state.tableList} loading={this.state.loading} onChange={this.handleTableChange} pagination={this.state.pagination} />         </div>
        </div>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          {" "}
          <Modal.Header closeButton>
            {" "}
            <Modal.Title id="contained-modal-title-vcenter">
              {" "}
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />{" "}
            </Modal.Title>{" "}
          </Modal.Header>{" "}
          <Modal.Body>
            {" "}
            <p>
              {" "}
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />{" "}
            </p>{" "}
          </Modal.Body>{" "}
          <Modal.Footer>
            {" "}
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              {" "}
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />{" "}
            </Button>{" "}
            <Button variant="danger" onClick={this.delete}>
              {" "}
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />{" "}
            </Button>{" "}
          </Modal.Footer>{" "}
        </Modal>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);