import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Tree } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../../excel/ExcelReader";
import { Select } from "antd";
import { Input, Cascader } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Tooltip, Modal as AntModal } from "antd";
import { Divider } from "antd";
import { PlusOutlined, ZoomInOutlined } from "@ant-design/icons";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node)) {
      var lineage = [];
      if (Boolean(node.lineage) && node.lineage.length > 0) {
        node.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.value,
        lineage: node.lineage,
        title: node.value
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

export class TypeOfTests extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "TypeOfTests",
      controllerName: "typeOfTests",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      filePath: "",
      fileList: [],
      tableList: [],
      nodes: [], // for cascader.
      type: "MATERIAL",

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      loading: false,
      sortField: "",
      sortOrder: "",
      checkedKeys: null,
      halfCheckedKeys: null,
      checkedNodes: null,
      parentTestIdSelectItems: [],

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
    this.fillTree();
  };

  fillComboboxes = async () => {
    var responseparentTestId = await handleRequest("GET", "/api/" + this.state.controllerName + "/parentTestId");
    if (responseparentTestId.type === "ERROR") {
      error(responseparentTestId);
    } else {
      generateList(responseparentTestId.data);
    }
    this.setState({
      parentTestIdSelectItems: Boolean(responseparentTestId.data) ? responseparentTestId.data : []
    });
    this.fillCode("TypeOfTestsmethodOfTest");
    this.fillCode("TypeOfTeststestSpecimenId");
  };

  fillTree = async () => {
    const { type } = this.state;
    var response = await handleRequest("GET", "/api/vendorProduct/root/" + type);

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(response.data))
        this.setState({
          nodes: Boolean(response.data) ? response.data : []
        });
    }
  };

  onLoadData = async loadedKeys => {
    console.log("onLoadData data", loadedKeys);
    console.log(loadedKeys);

    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async treenode => {
    const { expandedKeys } = this.state;
    const { type } = this.state;
    var nodes = this.state.nodes;
    console.log(treenode);
    let obj = { id: Boolean(treenode.key) ? treenode.key.split("~")[1] : -1, type: treenode.type, parentType: type };

    if (Boolean(treenode.key))
      var response = await handleRequest("GET", "/api/vendorProduct/subitems/" + obj.parentType + "/" + obj.type + "/" + obj.id);

    if (Boolean(response.data)) {
      const data = response.data;
      console.log(data);

      data.forEach(element => {
        if (element.type === "CODE") {
          element.isLeaf = true;
        }
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      console.log(res);
      this.setState({
        nodes: res
      });
    }
  };

  onCheck = async (checkedKeys, e) => {
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    var parent = dataList.find(p => p.key === row.parentTestId);
    let parentTestId = [];

    if (Boolean(parent)) {
      parentTestId = parent.lineage;
    }

    this.formRef.current.setFieldsValue({
      ...row,
      parentTestId: parentTestId,
    });

    this.getCheckedKeys(row.id);

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  getCheckedKeys = async (id) => {
    if (Boolean(id) && id > 0) {
      var res = await handleRequest("GET", "/api/" + this.state.controllerName + "/typeOfTestsProducts/" + id);
      if (res.type === "ERROR") {
        error(res);
      } else {
        let list = Boolean(res.data) ? res.data : [];
        let keyList = [];
        list.forEach(element => {
          keyList.push("CODE~" + element.stockCardId);
        });
        console.log(keyList);
        this.setState({
          checkedKeys: keyList
        });
      }
    }
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      this.fillComboboxes();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };



    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);



    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      checkedKeys: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      parentTestId: Boolean(values.parentTestId) ? values.parentTestId[values.parentTestId.length - 1] : null,
    };

    console.log("AAA", this.state.checkedKeys);

    let list = [];
    if (Boolean(this.state.checkedKeys) && this.state.checkedKeys.length > 0) {
      this.state.checkedKeys.forEach(element => {
        let obj = { stockCardId: element.split("~")[1], type: element.split("~")[0], vendorId: this.state.vendorId };
        if (obj.type === "CODE") {
          // obj.code = e.checkedNodes.find(p => p.key === element).title;
          list.push(obj);
        }
      });
    }

    if (Boolean(list) && list.length > 0) {
      newItem.typeOfTestsProducts = list;
    } else {
      newItem.typeOfTestsProducts = [];
    }

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      this.fillComboboxes();
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };
  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  applicableMaterialsModal = async row => {
    this.setState({
      modalApplicableMaterialsShow: true,
      applicableMaterials: Boolean(row.typeOfTestsProducts) && row.typeOfTestsProducts.length > 0 ? row.typeOfTestsProducts : [],
    });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="TypeOfTests.parentTestId" defaultMessage="Parent Test" />,
        key: "parentTestId",
        render: record => {
          return record.parentTestName;
        }, ...getColumnFilter({
          inputName: "parentTestId", inputType: "CASCADER",
          selectItems: Boolean(this.state["parentTestIdSelectItems"]) && this.state["parentTestIdSelectItems"].length > 0 ? this.state["parentTestIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="TypeOfTests.typeOfTest" defaultMessage="Type Of Test" />,
        key: "typeOfTest",
        render: record => {
          return record.typeOfTest;
        }, ...getColumnFilter({
          inputName: "typeOfTest", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="TypeOfTests.methodofTestId" defaultMessage="Method Of Test" />,
        key: "methodofTestId",
        render: record => {
          return record.methotofTestName;
        }, ...getColumnFilter({
          inputName: "methodofTestId", inputType: "SELECT_ADDABLE",
          selectItems: Boolean(this.state["TypeOfTestsmethodOfTestSelectItems"]) && this.state["TypeOfTestsmethodOfTestSelectItems"].length > 0 ? this.state["TypeOfTestsmethodOfTestSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="TypeOfTests.description" defaultMessage="Descripiton" />,
        key: "description",
        render: record => {
          return record.description;
        }, ...getColumnFilter({
          inputName: "description", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="TypeOfTests.TestSpecimen" defaultMessage="Test Specimen" />,
        key: "testSpecimenId",
        render: record => {
          return record.testSpecimenName;
        }, ...getColumnFilter({
          inputName: "testSpecimenId", inputType: "SELECT_ADDABLE",
          selectItems: Boolean(this.state["TypeOfTeststestSpecimenIdSelectItems"]) && this.state["TypeOfTeststestSpecimenIdSelectItems"].length > 0 ? this.state["TypeOfTeststestSpecimenIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="TypeOfTests.applicableMaterials" defaultMessage="Applicable Materials" />,
        key: "applicableMaterialsId",
        render: record => {
          return (
            <AntButton
              onClick={() => this.applicableMaterialsModal(record)}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ZoomInOutlined fontSize="large" />}
            ></AntButton>
          );
        }
      },
    ];

    const columnsApplicableMaterials = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="TypeOfTests.ApplicableMaterials" defaultMessage="Applicable Materials" />,
        key: "stockCardName",
        render: record => {
          return record.stockCardName;
        },
      },
    ];

    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="TypeOfTests" hideElement={true}>             <ExcelSheet name="TypeOfTests" data={this.state.tableList}>               <ExcelColumn label="ParentTest" value="ParentTestName" />
      <ExcelColumn label="TypeOfTest" value="TypeOfTest" />
      <ExcelColumn label="MethodOfTest" value="MethodOfTestName" />
      <ExcelColumn label="Descripiton" value="Description" />
      <ExcelColumn label="TestSpecimen" value="TestSpecimenName" />
      <ExcelColumn label="ApplicableMaterials" value="ApplicableMaterialsName" />
    </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel}
     icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)}       */}
    </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="typeOfTests.pageTitle" defaultMessage="Types of Tests" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>             <Col md={21}></Col>             <Col md={1}>{excelExport}</Col>             <Col md={1}>               <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>             </Col>             <Col md={1}>               <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />}               ></AntButton>               <AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />}               ></AntButton>             </Col>           </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TypeOfTests.parentTestId" defaultMessage="Parent Test" />}
                  name="parentTestId"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Cascader changeOnSelect style={{ width: "100%" }} options={this.state.parentTestIdSelectItems} onChange={this.handleStockIdChange} />
                </Form.Item>
              }

              <Form.Item
                {...layout}
                label={<FormattedMessage id="TypeOfTests.typeOfTest" defaultMessage="Type of Test" />}
                name="typeOfTest"
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="GENERAL.REQUIRED"
                        defaultMessage="This field is required."
                      />
                    ),
                  },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TypeOfTests.methodOfTest" defaultMessage="Method of Test" />}
                  name="methodofTestId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }} optionFilterProp="children"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("TypeOfTestsmethodOfTest")}
                          >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["TypeOfTestsmethodOfTestSelectItems"]) &&
                      this.state["TypeOfTestsmethodOfTestSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              <Form.Item
                {...layout}
                label={<FormattedMessage id="TypeOfTests.description" defaultMessage="Description" />}
                name="description"
                rules={[
                  {
                    required: false,
                    message: (
                      <FormattedMessage
                        id="GENERAL.REQUIRED"
                        defaultMessage="This field is required."
                      />
                    ),
                  },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TypeOfTests.TestSpecimen" defaultMessage="Test Specimen" />}
                  name="testSpecimenId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }} optionFilterProp="children"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("TypeOfTeststestSpecimenId")}
                          >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["TypeOfTeststestSpecimenIdSelectItems"]) &&
                      this.state["TypeOfTeststestSpecimenIdSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TypeOfTests.TestSpecimen" defaultMessage="Test Specimen" />}
                  name="typeOfTestsProducts"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Tree
                    blockNode
                    checkable
                    showLine={true}
                    onCheck={this.onCheck}
                    checkedKeys={this.state.checkedKeys}
                    halfCheckedKeys={this.state.halfCheckedKeys}
                    treeData={this.state.nodes}
                  />
                </Form.Item>
              }


              <div hidden={this.state.hideSave}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>                     <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">                       <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
              <div hidden={this.state.hideUpdate}>
                {<Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>                   </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>                   </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>}
              </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (<div className="alert alert-custom alert-light">                 <div className="alert-icon">                   <FilterOutlined />                 </div>                 <div className="alert-text">                   {this.state.searchCriteriaModels.map((val, i) => { return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">                         <span className="mr-2">{val.label}</span>                         <strong className="mr-2">{val.type}</strong>                         <span className="mr-2">{val.operationValue}</span>                         <span className="mr-2">{val.showValue}</span>                         <span>                           <Tooltip title="Remove">                             <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />                           </Tooltip>                         </span>                       </div>); })}                 </div>                 <div className="alert-close">                   <Tooltip title="Remove All">                     <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />                   </Tooltip>                 </div>               </div>)}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table bordered scroll={{ x: 900, scrollToFirstRowOnChange: true }} columns={columns} dataSource={this.state.tableList} loading={this.state.loading} onChange={this.handleTableChange} pagination={this.state.pagination} />         </div>
          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>           <Modal.Header closeButton>             <Modal.Title id="contained-modal-title-vcenter">               <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />             </Modal.Title>           </Modal.Header>           <Modal.Body>             <p>               <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />             </p>           </Modal.Body>           <Modal.Footer>             <Button variant="secondary" onClick={this.showOrHideDeleteModal}>               <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />             </Button>             <Button variant="danger" onClick={this.delete}>               <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />             </Button>           </Modal.Footer>         </Modal>


          <AntModal
            title={<FormattedMessage id="TypeOfTests.applicableMaterials" defaultMessage="Applicable Materials" />}
            closable="false"
            open={this.state.modalApplicableMaterialsShow}
            onCancel={() => {
              this.setState({ modalApplicableMaterialsShow: false });
            }}
            width={800}
            centered
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalApplicableMaterialsShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
          >
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Table
                bordered
                size="small"
                columns={columnsApplicableMaterials}
                dataSource={this.state.applicableMaterials}
                loading={this.state.loading}
                pagination={false}
              />
            </div>
          </AntModal>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TypeOfTests);
