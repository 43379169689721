import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import {
  Form,
  Table,
  Input,
  DatePicker,
  Cascader,
  Button as AntButton,
  Col,
  Row,
} from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";

const { RangePicker } = DatePicker;

const dataList = [];
const generateList = (data) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.data.id,
      lineage: node.data.lineage,
      title: node.data.menuTitle,
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class HowToUse extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: [
          "2",
          "10",
          "25",
          "50",
          "100",
          "1000",
          "10000",
          "100000",
          "1000000",
        ],
        total: 0,
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    const responseTree = await handleRequest("GET", "/api/menus/tree");

    if (responseTree.data.length !== 0) {
      generateList(responseTree.data.treeTable);
      this.setState({
        nodes: responseTree.data.cascader,
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: [
          "2",
          "10",
          "25",
          "50",
          "100",
          "1000",
          "10000",
          "100000",
          "1000000",
        ],
        total: this.state.totalRecords,
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: [],
    });
  };

  edit = (row) => {
    document.getElementById("kt_scrolltop").click();

    var menu = dataList.find((p) => p.key === row.menuId);
    let menuId = [];
    if (Boolean(menu)) {
      menuId = menu.lineage;
    }

    this.formRef.current.setFieldsValue({
      ...row,
      menuId: menuId,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  deleteModal = (row) => {
    this.setState({
      modalDeleteShow: true,
      id: row.id,
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow,
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id,
    };
    var response = await handleRequest(
      "DELETE",
      "/api/pageHowtoUse/" + deletedItem.id
    );

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false,
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/pageHowtoUse/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0,
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: "",
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords,
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder,
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0,
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: "",
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true,
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false,
      });
    }
  };

  save = async (values) => {
    const newItem = {
      ...values,
      id: this.state.id,
      menuId: Boolean(values.menuId)
        ? values.menuId[values.menuId.length - 1]
        : null,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/pageHowtoUse", newItem);
    } else {
      response = await handleRequest(
        "PUT",
        "/api/pageHowtoUse/" + newItem.id,
        newItem
      );
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.resetInputs();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true,
    });
  };

  handleUploadExcel = async (excelJson) => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach((element) => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest(
            "POST",
            "/api/pageHowtoUse/import",
            list
          );
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = (e) => {
    this.setState({
      alarmTypeId: e,
    });
  };

  addCode = async (codeType) => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType,
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false,
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async (codeType) => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data,
      });
    }
  };

  action = (record) => {
    return (
      <React.Fragment>
        <ActionMenu
          row={record}
          onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.edit.bind(this)}
        />
      </React.Fragment>
    );
  };

  render() {
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action,
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return (
            index +
            1 +
            this.state.pagination.pageSize * (this.state.pagination.current - 1)
          );
        },
      },

      {
        title: (
          <FormattedMessage id="PageHowtoUse.menuId" defaultMessage="Menu" />
        ),
        key: "menuId",
        render: (record) => {
          if (Boolean(record.menu)) return record.menu.menuTitle;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={
                Boolean(this.state.pagination.menuId)
                  ? this.state.pagination.menuId
                  : null
              }
              onChange={async (e) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  menuId: e.target.value,
                  menuIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.menuIdColor)
                ? this.state.pagination.menuIdColor
                : "#bfbfbf",
            }}
          />
        ),

        sorter: true,
      },
      {
        title: (
          <FormattedMessage
            id="PageHowtoUse.description"
            defaultMessage="Description"
          />
        ),
        key: "description",
        render: (record) => {
          return record.description;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={
                Boolean(this.state.pagination.description)
                  ? this.state.pagination.description
                  : null
              }
              onChange={async (e) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  description: e.target.value,
                  descriptionColor: Boolean(e.target.value)
                    ? "#1890ff"
                    : "#bfbfbf",
                };
                this.setState({
                  pagination: _pagination,
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder,
                });
              }}
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.descriptionColor)
                ? this.state.pagination.descriptionColor
                : "#bfbfbf",
            }}
          />
        ),

        sorter: true,
      },

      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: (record) => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={
                Boolean(this.state.pagination.lastUpdateDate)
                  ? this.state.pagination.lastUpdateDate
                  : null
              }
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />
        ),
        sorter: true,
      },
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
              exportClick: false,
            })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton
            style={{ border: "0px" }}
            onClick={this.handleExportExcel}
            icon={<ExportIcon color="action" fontSize="large" />}
          ></AntButton>
        )} */}
      </>
    );

    const props = {
      action: `${API_BASE_ROOT}/api/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList],
    };

    return (
      <div
        className="card card-custom"
        style={{ padding: "2rem", minHeight: "100%" }}
      >
        <Form
          initialValues={{ remember: false }}
          onFinish={this.save}
          onFinishFailed={onFinishFailed}
          ref={this.formRef}
        >
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader
                onCreateJson={this.handleUploadExcel.bind(this)}
              ></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={
                  <FormattedMessage
                    id="PageHowtoUse.menuId"
                    defaultMessage="Menu"
                  />
                }
                style={{
                  marginBottom: 0,
                }}
                name="menuId"
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="GENERAL.REQUIRED"
                        defaultMessage="This field is required."
                      />
                    ),
                  },
                ]}
              >
                <Cascader
                  style={{ width: "100%" }}
                  options={this.state.nodes}
                />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={
                  <FormattedMessage
                    id="PageHowtoUse.description"
                    defaultMessage="Description"
                  />
                }
                style={{
                  marginBottom: 0,
                }}
                name="description"
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="GENERAL.REQUIRED"
                        defaultMessage="This field is required."
                      />
                    ),
                  },
                ]}
              >
                <Input placeholder="Description" style={{ width: "100%" }} />
              </Form.Item>
            }
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button
                      id="SaveButton"
                      type="submit"
                      style={{ width: "100%" }}
                      variant="success"
                    >
                      <FormattedMessage
                        id="GeneralButtonSave"
                        defaultMessage="Save"
                      />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button
                      id="CancelButton"
                      style={{ width: "100%" }}
                      variant="secondary"
                      onClick={this.cancel}
                    >
                      <FormattedMessage
                        id="GeneralButtonCancel"
                        defaultMessage="Cancel"
                      />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button
                      id="UpdateButton"
                      style={{ width: "100%" }}
                      variant="warning"
                      type="submit"
                    >
                      <FormattedMessage
                        id="GeneralButtonUpdate"
                        defaultMessage="Update"
                      />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div
          style={{ marginTop: "2rem", marginBottom: "1rem" }}
          className="content-section implementation"
        >
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal
          show={this.state.modalDeleteShow}
          onHide={this.showOrHideDeleteModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Chosen record will be deleted !
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HowToUse);
