import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { handleRequest } from "../../../ApiConnector";
import React, { Component } from "react";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { Form, Button as AntButton, Drawer, Input, InputNumber, Row, Col, Divider } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import { Select } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

export class BiddingLogQuotationExpenses extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BiddingLogQuotationExpenses",
      controllerName: "biddingLogQuotationExpenses",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  }

  fillComboboxes = async () => {
    this.fillCode("BiddingLogQuotationExpenses.PaymentGroup");
    this.fillCode("BiddingLogQuotationExpenses.Base");

    var responseExpenses = await handleRequest("POST", "/api/" + this.state.controllerName + "/export", { "tenderingLogId": this.props.tenderingLogId });
    if (responseExpenses.type === "ERROR") {
      error(responseExpenses);
    }
    this.formRef.current.setFieldsValue({
      expensesList: Boolean(responseExpenses.data) ? responseExpenses.data : [],
    });
  };

  restartTable = async (params = {}) => {
    //   this.setState({ loading: true });
    //   const newObj = this.createWhere(params);
    //   let url = "/api/" + this.state.controllerName + "/paging";
    //   const response = await handleRequest("POST", url, newObj);
    //   if (response.type === "ERROR") {
    //     error(response);
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   } else {
    //     if (response.data.length !== 0) {
    //       let list = response.data.data;
    //       list.forEach((element, index) => {
    //         element.index = index + 1;
    //         element.key = element.id;
    //       });
    //       this.setState({
    //         loading: false,
    //         tableList: list,
    //         pagination: {
    //           ...params.pagination,
    //           total: response.data.totalRecords
    //         },
    //         totalRecords: response.data.totalRecords,
    //         sortField: params.sortField,
    //         sortOrder: params.sortOrder
    //       });
    //     } else {
    //       this.setState({
    //         loading: false,
    //         tableList: [],
    //         pagination: {
    //           ...params.pagination,
    //           total: 0
    //         },
    //         totalRecords: 0,
    //         sortField: "",
    //         sortOrder: ""
    //       });
    //     }
    //   }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      tenderingLogId: this.props.tenderingLogId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  saveAll = async (values) => {
    if (Boolean(this.props.tenderingLogId) && this.props.tenderingLogId > 0) {
      if (Boolean(values) && Boolean(values.expensesList) && values.expensesList.length > 0) {
        values.expensesList.forEach(element => {
          element.tenderingLogId = this.props.tenderingLogId;
        });
      }
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll/" + this.props.tenderingLogId, values.expensesList);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.props.close();
      }
    } else {
      showWarning("Bill Of Quantity not found.");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    let details =
      Boolean(this.formRef) &&
        Boolean(this.formRef.current) &&
        Boolean(this.formRef.current.getFieldValue("expensesList")) &&
        this.formRef.current.getFieldValue("expensesList").length > 0
        ? this.formRef.current.getFieldValue("expensesList")
        : [];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.saveAll} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={3}>
              <Button
                variant="primary"
                onClick={() => this.formRef.current.submit()}
                style={{ width: "100%", marginTop: "10px" }}
                loading={this.state.loading}
              >
                <FormattedMessage id="BiddingLogQuotationExpenses.SaveAll" defaultMessage="Save All" />
              </Button>
            </Col>
          </Row>
          <br />
          <Form.List name="expensesList">
            {(fields, { add, remove }) => {
              const handleAddClick = () => {
                add();
              };

              const handleRemoveClick = (fieldKey) => {
                remove(fieldKey);
              };

              return (
                <>
                  <TableBoot bordered size="sm">
                    <thead>
                      <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"BiddingLogQuotationExpenses.PaymentGroup"} defaultMessage={"Payment Group"} />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"BiddingLogQuotationExpenses.Payment"} defaultMessage={"Payment"} />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"BiddingLogQuotationExpenses.Base"} defaultMessage={"Base"} />
                        </th>
                        <th colSpan="2" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"BiddingLogQuotationExpenses.Rate"} defaultMessage={"Rate"} />
                        </th>
                        <th colSpan="1">
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map(field => {
                        return (
                          <tr key={field.key}>
                            <td>
                              <Form.Item noStyle name={[field.name, "paymentGroupId"]}>
                                <Select
                                  showSearch
                                  allowClear
                                  optionFilterProp="children"
                                  style={{ width: "300px" }}
                                  dropdownRender={menu => (
                                    <div>
                                      {menu}
                                      <Divider style={{ margin: "4px 0" }} />
                                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                        <Input
                                          style={{ flex: "auto" }}
                                          value={this.state.codeName}
                                          onChange={event => {
                                            this.setState({
                                              codeName: event.target.value
                                            });
                                          }}
                                        />
                                        <a
                                          style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                          onClick={() => this.addCode("BiddingLogQuotationExpenses.PaymentGroup")}
                                        >
                                          <PlusOutlined /> Add item
                                        </a>
                                      </div>
                                    </div>
                                  )}
                                >
                                  {Boolean(this.state["BiddingLogQuotationExpenses.PaymentGroupSelectItems"]) && this.state["BiddingLogQuotationExpenses.PaymentGroupSelectItems"].length > 0 &&
                                    this.state["BiddingLogQuotationExpenses.PaymentGroupSelectItems"].map(i => (
                                      <Option key={i.id} value={i.id}>
                                        {i.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "payment"]}>
                                <Input style={{ width: "100%" }} placeholder="Payment" />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "baseId"]}>
                                <Select showSearch allowClear optionFilterProp="children" style={{ width: "150px" }}
                                  onChange={value => {
                                    let updatedDetails = { ...details };
                                    if (Boolean(updatedDetails[field.name]) && Boolean(updatedDetails[field.name].baseId)) {
                                      updatedDetails[field.name].baseId = value;
                                      updatedDetails[field.name].percentRate = null;
                                    } else {
                                      updatedDetails[field.name] = { baseId: value, amountRate: null };
                                    }

                                    this.formRef.current.setFieldsValue({
                                      expensesList: updatedDetails,
                                    });
                                    this.setState({
                                      details: updatedDetails
                                    });
                                  }}
                                >
                                  {Boolean(this.state["BiddingLogQuotationExpenses.BaseSelectItems"]) && this.state["BiddingLogQuotationExpenses.BaseSelectItems"].length > 0 &&
                                    this.state["BiddingLogQuotationExpenses.BaseSelectItems"].map(i => (
                                      <Option key={i.id} value={i.id}>
                                        {i.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>
                            <td>
                              {details[field.name]?.baseId ?
                                <Form.Item noStyle name={[field.name, "percentRate"]}>
                                  <InputNumber parser={value => value.replace(",", ".")}
                                    formatter={value => value.replace(".", ",")}
                                    style={{ width: "100%" }} max={100} placeholder="Percent"
                                    onChange={async (value) => {
                                      if (!Boolean(value)) {
                                        value = 0;
                                      }

                                      let subTotal = 0;
                                      subTotal += this.props.subTotal;
                                      let detailExpenses = details.filter(p => p.paymentGroupName === "Contract Expenses");
                                      if (Boolean(detailExpenses) && detailExpenses.length > 0) {
                                        detailExpenses.forEach(element => {
                                          if (element.id !== details[field.name].id && Boolean(element.amountRate)) {
                                            subTotal += parseFloat(element.amountRate);
                                          }
                                        });
                                      }

                                      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/paymentRatio/"
                                        + this.props.profit + "/" + this.props.tax + "/" + value + "/" + subTotal
                                      );
                                      if (response.type === "ERROR") {
                                        error(response);
                                      } else {
                                        let data = Boolean(response.data) ? response.data.toFixed(2) : null;
                                        let updatedDetails = { ...details };
                                        if (Boolean(updatedDetails[field.name]) && Boolean(updatedDetails[field.name].amountRate)) {
                                          updatedDetails[field.name].amountRate = data;
                                          updatedDetails[field.name].percentRate = value;
                                        } else {
                                          updatedDetails[field.name] = { amountRate: data, percentRate: value };
                                        }
                                        this.formRef.current.setFieldsValue({
                                          expensesList: updatedDetails,
                                        });
                                        this.setState({
                                          details: updatedDetails
                                        });
                                      }
                                    }} />
                                </Form.Item>
                                :
                                <Form.Item noStyle name={[field.name, "percentRate"]}>
                                  <InputNumber disabled parser={value => value.replace(",", ".")}
                                    formatter={value => value.replace(".", ",")}
                                    style={{ width: "100%" }} max={100} placeholder="Percent" />
                                </Form.Item>
                              }
                            </td>
                            <td>
                              {details[field.name]?.baseId ?
                                <Form.Item noStyle name={[field.name, "amountRate"]}>
                                  <InputNumber disabled parser={value => value.replace(",", ".")}
                                    formatter={value => value.replace(".", ",")}
                                    style={{ width: "100%" }} placeholder="Amount" />
                                </Form.Item>
                                :
                                <Form.Item noStyle name={[field.name, "amountRate"]}>
                                  <InputNumber parser={value => value.replace(",", ".")}
                                    formatter={value => value.replace(".", ",")}
                                    style={{ width: "100%" }} placeholder="Amount" />
                                </Form.Item>
                              }
                            </td>
                            <td>
                              <MinusCircleOutlined onClick={() => handleRemoveClick(field.name)} />
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={11}>
                          <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                            <FormattedMessage id={"BiddingLogQuotationExpenses.AddNew"} defaultMessage={"Add New"} />
                          </AntButton>
                        </td>
                      </tr>
                    </tbody>
                  </TableBoot>
                </>
              );
            }}
          </Form.List>
        </Form>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Drawer
          title={<FormattedMessage id="BiddingLogQuotationExpenses.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"BiddingLogQuotationExpenses"} />
        </Drawer>
      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BiddingLogQuotationExpenses);