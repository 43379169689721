import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Row, Col, Button as AntButton, Input, Card, Checkbox, Tooltip, Select, Radio, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined, ZoomInOutlined } from '@ant-design/icons';
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import DraggableModal from "../../../Components/DraggableModal";
import { Filter } from "@material-ui/icons";
import PageAutoDesignFrontendCode from "./PageAutoDesignFrontendCode";
import PageAutoDesignBackendCode from "./PageAutoDesignBackendCode";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class PageAutoDesignDrawerParent extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "PageAutoDesignDrawerParent",
      controllerName: "pageAutoDesign",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      materialList: [],
      usageList: [],
      inputDetails: [],
      inputFormDetails: [],
      selectedTab: "Form",
      pageAutoDesign: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();
  formRefDetail = React.createRef();
  formRefFormDetail = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseAutoDesignOnClick = await handleRequest("GET", "/api/" + this.state.controllerName + "/getAutoDesignOnClickList");
    if (responseAutoDesignOnClick.type === "ERROR") {
      error(responseAutoDesignOnClick);
    }
    var responseInputTyp = await handleRequest("GET", "/api/" + this.state.controllerName + "/getInputTypes");
    if (responseInputTyp.type === "ERROR") {
      error(responseInputTyp);
    }
    this.setState({
      inputTypeSelectItems: Boolean(responseInputTyp.data) ? responseInputTyp.data : [],
      autoDesignOnClickList: Boolean(responseAutoDesignOnClick.data) ? responseAutoDesignOnClick.data : []
    });
    this.getFolders();
    this.getEntities();
  };

  getFolders = async () => {
    const responseFolder = await handleRequest("GET", "/ApplicationSettings/getDomainFolders");
    if (Boolean(responseFolder.data) && responseFolder.data.length > 0) {
      this.setState({
        folderSelectItems: responseFolder.data
      });
    }
  };

  getEntities = async folder => {
    if (!Boolean(folder)) {
      let response = await handleRequest("GET", "/api/pageForm/getEntitiesFromLocalDomain");
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          entitySelectItems: response.data
        });
      }
    } else {
      let response = await handleRequest("GET", "/api/pageForm/getEntitiesFromFolderDomain/" + folder);
      console.log(response);
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          entitySelectItems: response.data
        });
      }
    }
  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }

    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  handleAddDetail = () => {
    const detailValues = this.formRefDetail.current.getFieldsValue();
    console.log("detailValues", detailValues);
    const { selectedInputIndex, selectedInputName, inputDetails } = this.state;

    let updatedDetails = [...inputDetails];
    if (selectedInputIndex !== -1) {
      let inputDetail = updatedDetails[selectedInputIndex] || {};
      inputDetail = { ...inputDetail, ...detailValues };
      inputDetail.inputName = selectedInputName;
      updatedDetails[selectedInputIndex] = inputDetail;
      this.setState({
        inputDetails: updatedDetails,
        selectedInputIndex: -1,
        selectedInputName: "",
      });
      this.formRefDetail.current.resetFields();
    }
  }

  handleAddDetailForm = () => {
    const detailValues = this.formRefFormDetail.current.getFieldsValue();
    console.log("detailValues", detailValues);
    const { selectedInputIndex, selectedInputName, inputFormDetails } = this.state;

    let updatedDetails = [...inputFormDetails];
    if (selectedInputIndex !== -1) {
      let inputDetail = updatedDetails[selectedInputIndex] || {};
      inputDetail = { ...inputDetail, ...detailValues };
      inputDetail.inputName = selectedInputName;
      updatedDetails[selectedInputIndex] = inputDetail;
      this.setState({
        inputFormDetails: updatedDetails,
        selectedInputIndex: -1,
        selectedInputName: "",
      });
      this.formRefFormDetail.current.resetFields();
    }
  }

  save = async values => {
    const { generateType, parentId } = this.props;
    let { inputDetails, inputFormDetails } = this.state;

    let formName = values.formName;

    if (!Boolean(generateType)) {
      showWarning("Generate type not found.");
      return;
    }

    if (!(Boolean(formName) && formName.length > 1)) {
      showWarning("Form name not found.");
      return;
    }

    const newItem = {
      ...values,
      id: this.state.id,
      parentId: parentId,
      description: formName,
      generateType: generateType,
    };

    let controllerName = formName.charAt(0).toLowerCase() + formName.slice(1) + 's';
    let forms = [];
    if ((Boolean(inputDetails) && inputDetails.length > 0) || (Boolean(inputFormDetails) && inputFormDetails.length > 0)) {
      forms.push({
        formName: formName,
        ribbonName: values.ribbonName,
        controllerName: controllerName,
        tableInputs: inputDetails,
        inputs: inputFormDetails,
      });
    }
    newItem.forms = forms;
    console.log("newItem", newItem);
    if (Boolean(this.props.saveFormChild)) {
      this.props.saveFormChild(newItem);
    } else if (Boolean(this.props.saveTableChild)) {
      this.props.saveTableChild(newItem);
    }
    this.props.close();
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          modalAddNewDimensionShow: false,
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillField = async (entity) => {
    let { folder } = this.state;
    let url = "/api/" + this.state.controllerName + "/bymodeldto/" + entity;
    if (Boolean(folder)) {
      url = "/api/" + this.state.controllerName + "/bymodeldtoandfolder/" + entity + "/" + folder;
    }
    var response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    }
    else
      this.setState({
        fieldIdSelectItems: Boolean(response.data) ? response.data : []
      });
  }

  onChangeFolder = async value => {
    let folder = value;
    if (!Boolean(folder)) {
      let response = await handleRequest("GET", "/api/pageForm/getEntitiesFromLocalDomain");
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          entitySelectItems: response.data
        });
      }
    } else {
      let response = await handleRequest("GET", "/api/pageForm/getEntitiesFromFolderDomain/" + folder);

      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          entitySelectItems: response.data
        });
      }
    }
    this.setState({
      folder: folder
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const onFinishFailedDetail = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout1 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };

    const { selectedInputName, selectedInputIndex, inputDetails, inputFormDetails, selectedTab } = this.state;

    return (
      <div
        className="card card-custom"
        style={{ padding: "2rem", minHeight: "100%" }}>
        <Form
          initialValues={{ remember: false }}
          onFinish={this.save}
          onFinishFailed={onFinishFailed}
          ref={this.formRef}>
          <div className="d-flex flex-row">
            <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
              <div className="card card-custom card-stretch">
                {/* begin::Body */}
                <div className="card-body pt-4">
                  {/* begin::Toolbar */}
                  <div className="d-flex justify-content-end"></div>
                  {/* end::Toolbar */}
                  {/* begin::Nav */}
                  <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                    <br />
                    <Row gutter={[16, 16]}>

                      <Button style={{ width: "100%" }} variant={this.state.selectedTab === "Form" ? "warning" : "outline-warning"} onClick={() => {
                        this.setState({
                          selectedTab: "Form"
                        });
                      }}>
                        <FormattedMessage id="PageAutoDesignDrawerParent.Form" defaultMessage="Form" />
                      </Button>

                      <br />

                      <Button style={{ width: "100%" }} variant={this.state.selectedTab === "Table" ? "warning" : "outline-warning"} onClick={() => {
                        this.setState({
                          selectedTab: "Table"
                        });
                      }}>
                        <FormattedMessage id="PageAutoDesignDrawerParent.ParentTable" defaultMessage="Parent Table" />
                      </Button>

                      <br />

                      <Button style={{ width: "100%" }} variant={this.state.selectedTab === "Button" ? "warning" : "outline-warning"} onClick={() => {
                        this.setState({
                          selectedTab: "Button"
                        });
                      }}>
                        <FormattedMessage id="PageAutoDesignDrawerParent.Buttons" defaultMessage="Buttons" />
                      </Button>

                    </Row>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-row-fluid ml-lg-12">
              <div className="card card-custom card-stretch">
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">

                    <Row gutter={[16, 16]}>
                      <Col md={4}>
                      </Col>
                      <Col md={8} style={{ marginBottom: '20px' }}>
                        <AntButton type="default" icon={<Filter />} style={{ borderRadius: '5px' }} onClick={() => {
                          let { formName, relatedFolder, relatedEntity, ribbonName, inputFormDetails, generateType } = this.state;

                          let controllerName = "";
                          if (typeof formName === 'string' && formName.length > 0) {
                            controllerName = formName.charAt(0).toLowerCase() + formName.slice(1) + 's';
                          }
                          let pageAutoDesign = {
                            type: "Page",
                            description: formName,
                            relatedFolder: relatedFolder,
                            relatedEntity: relatedEntity,
                            generateType: generateType,
                            tables: [],
                            forms: [{
                              formName: formName,
                              ribbonName: ribbonName,
                              controllerName: controllerName,
                              inputs: inputFormDetails,
                            }],
                          };
                          this.setState({
                            random: Math.random(),
                            showFrontendCodeModal: true,
                            pageAutoDesign: pageAutoDesign,
                          });
                        }}>
                          <FormattedMessage id="PageAutoDesignDrawerParent.FrontendCodes" defaultMessage="Frontend Codes" />
                        </AntButton>
                      </Col>
                      <Col md={4}>
                      </Col>
                      <Col md={8} style={{ marginBottom: '20px' }}>
                        <AntButton type="primary" style={{ borderRadius: '5px' }} onClick={() => {
                          let { formName, relatedFolder, relatedEntity, ribbonName, inputFormDetails, generateType } = this.state;

                          let controllerName = "";
                          if (typeof formName === 'string' && formName.length > 0) {
                            controllerName = formName.charAt(0).toLowerCase() + formName.slice(1) + 's';
                          }
                          let pageAutoDesign = {
                            type: "Page",
                            description: formName,
                            relatedFolder: relatedFolder,
                            relatedEntity: relatedEntity,
                            generateType: generateType,
                            tables: [],
                            forms: [{
                              formName: formName,
                              ribbonName: ribbonName,
                              controllerName: controllerName,
                              inputs: inputFormDetails,
                            }],
                          };
                          this.setState({
                            random: Math.random(),
                            showBackendCodeModal: true,
                            pageAutoDesign: pageAutoDesign,
                          });
                        }}>
                          <FormattedMessage id="PageAutoDesignDrawerParent.BackendCodes" defaultMessage="Backend Codes" />
                        </AntButton>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                      <Form.Item
                        name="formName"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input placeholder="Form Name" style={{ width: "100%" }}
                          onChange={(e) => {
                            this.setState({
                              formName: e.target.value
                            });
                          }} />
                      </Form.Item>

                      <Form.Item
                        name="ribbonName"
                        placeholder="RibbonName"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input placeholder="Ribbon Name" style={{ width: "100%" }}
                          onChange={(e) => {
                            this.setState({
                              ribbonName: e.target.value
                            });
                          }} />
                      </Form.Item>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
            this.formRender(layout1, selectedInputIndex, inputFormDetails, selectedInputName, layout, selectedTab)
          }

          {
            this.tableRender(layout1, selectedInputIndex, inputDetails, selectedInputName, layout, selectedTab)
          }

          {
            this.buttonRender(layout1, selectedTab)
          }

          {(selectedTab === "Table" || selectedTab === "Form") &&
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Button id="SaveAllButton" type="submit" style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSaveAll" defaultMessage="Save All" />
                </Button>
              </Col>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            </Row>
          }
        </Form>

        <DraggableModal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={500}
          okButtonProps={{ hidden: true }}
          open={this.state.modalDeleteShow}
          onCancel={() => {
            this.setState({ modalDeleteShow: false });
          }}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDeleteShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Space>
          ]}
          content={
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          }
        />

        <DraggableModal
          title={
            <FormattedMessage id="PageAutoDesignDrawerParent.FrontendCodes" defaultMessage="Frontend Codes" />
          }
          centered
          width={1200}
          okButtonProps={{ hidden: true }}
          open={this.state.showFrontendCodeModal}
          onCancel={() => {
            this.setState({ showFrontendCodeModal: false });
          }}
          content={
            <PageAutoDesignFrontendCode pageAutoDesign={this.state.pageAutoDesign} random={this.state.random}
              folder={this.state.folder}
            />
          }
        />

        <DraggableModal
          title={
            <FormattedMessage id="PageAutoDesignDrawerParent.BackendCodes" defaultMessage="Backend Codes" />
          }
          centered
          width={1200}
          okButtonProps={{ hidden: true }}
          open={this.state.showBackendCodeModal}
          onCancel={() => {
            this.setState({ showBackendCodeModal: false });
          }}
          content={
            <PageAutoDesignBackendCode pageAutoDesign={this.state.pageAutoDesign} random={this.state.random}
              folder={this.state.folder}
            />
          }
        />
      </div>
    );
  }

  formRender(layout1, selectedInputIndex, inputFormDetails, selectedInputName, layout, selectedTab) {
    return <div className="flex-row-fluid ml-lg-8" hidden={selectedTab === "Form" ? false : true}>
      <div className="card card-custom card-stretch">
        <div className="form">
          <div className="card-body">
            <br /><br />
            <div className="d-flex flex-row">
              <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
                <div className="card card-custom card-stretch">
                  <div className="card-body pt-4">
                    <div className="d-flex justify-content-end"></div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                      <Card
                        style={{
                          borderRadius: "8px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          width: "100%",
                          height: "100%"
                        }}
                      >
                        {<Form.List name="formRenderInputs">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map((field, index) => (
                                <Form.Item
                                  {...field}
                                  {...layout1}
                                  label={index + 1}
                                  rules={[
                                    {
                                      required: false,
                                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                    }
                                  ]}
                                >
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        style={{
                                          display: "inline-block",
                                          width: '100%'
                                        }}
                                        name={[field.name, "inputName"]}
                                      >
                                        <Input
                                          onChange={(e) => {
                                            if (field.name === selectedInputIndex) {
                                              this.setState({
                                                selectedInputName: e.target.value,
                                              });
                                            }
                                          }} />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Tooltip title="Format">
                                        <ZoomInOutlined
                                          onClick={() => {
                                            const formRenderInputs = this.formRef.current.getFieldValue("formRenderInputs");
                                            if (Boolean(formRenderInputs) && formRenderInputs.length > 0) {
                                              let inputName = formRenderInputs[field.name];
                                              this.setState({
                                                selectedInputIndex: field.name,
                                                selectedInputName: inputName,
                                              }, () => {
                                                let row = inputFormDetails.find(p => p.inputName === inputName);
                                                if (Boolean(row)) {
                                                  if (Boolean(this.formRefFormDetail) && Boolean(this.formRefFormDetail.current)) {
                                                    this.formRefFormDetail.current.setFieldsValue({
                                                      ...row
                                                    });
                                                  }
                                                } else {
                                                  if (Boolean(this.formRefFormDetail) && Boolean(this.formRefFormDetail.current)) {
                                                    this.formRefFormDetail.current.resetFields();
                                                  }
                                                }
                                              });
                                            }
                                          }} />
                                      </Tooltip>
                                    </td>
                                    <td>
                                      <Tooltip title="Delete">
                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                      </Tooltip>
                                    </td>
                                  </tr>
                                </Form.Item>
                              ))}
                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"PageAutoDesignDrawerParent.AddInput"} defaultMessage={"Add Input"} />
                                  </AntButton>
                                </td>
                              </tr>
                            </>
                          )}
                        </Form.List>}
                      </Card>
                    </div>
                    {/* end::Nav */}
                  </div>
                  {/* end::Body */}
                </div>
              </div>
              <div className="flex-row-fluid ml-lg-8">
                {selectedInputIndex > -1 && Boolean(selectedInputName) && (
                  <div className="card card-custom card-stretch">
                    <div className="card-header py-3">
                      <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                          {selectedInputName}
                        </h3>
                      </div>
                      <div className="card-toolbar"></div>
                    </div>
                    <div className="form">
                      <div className="card-body">
                        <Form
                          initialValues={{ remember: false }}
                          onFinish={this.handleAddDetailForm}
                          ref={this.formRefFormDetail}
                        >
                          {<Form.Item
                            {...layout}
                            label={<FormattedMessage id="PageAutoDesignFormInput.label" defaultMessage="Label" />}
                            name="label"
                            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Input style={{ width: "100%" }} />
                          </Form.Item>}
                          {<Form.Item
                            {...layout}
                            label={<FormattedMessage id="PageAutoDesignFormInput.placeholder" defaultMessage="Placeholder" />}
                            name="placeholder"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Input style={{ width: "100%" }} />
                          </Form.Item>}
                          {<Form.Item
                            {...layout}
                            label={<FormattedMessage id="PageAutoDesignFormInput.placeholder" defaultMessage="Input Type" />}
                            name="inputType"
                            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Select
                              showSearch
                              allowClear
                              optionFilterProp="children"
                              style={{ width: "100%" }}
                              value={this.state.inputType}
                              onChange={e => {
                                this.setState({ inputType: e });
                              }}
                            >
                              {Boolean(this.state["inputTypeSelectItems"]) && this.state["inputTypeSelectItems"].length > 0 &&
                                this.state["inputTypeSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.key}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>}

                          {this.state.inputType && (
                            <>
                              <Form.Item
                                {...layout}
                                label={<FormattedMessage id="PageAutoDesignFormInput.Folder" defaultMessage="Folder" />}
                                name="folderName"
                                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                              >
                                <Select
                                  showSearch
                                  allowClear
                                  optionFilterProp="children"
                                  style={{ width: "100%" }}
                                  value={this.state.folder}
                                  onChange={this.onChangeFolder}
                                >
                                  {Boolean(this.state["folderSelectItems"]) && this.state["folderSelectItems"].length > 0 &&
                                    this.state["folderSelectItems"].map(i => (
                                      <Option key={i} value={i}>
                                        {i}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                              {/* //////////////////////////////////////// */}
                              <Form.Item
                                {...layout}
                                label={<FormattedMessage id="PageAutoDesignFormInput.Entity" defaultMessage="Entity" />}
                                name="entity"
                                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                              >
                                <Select
                                  showSearch
                                  allowClear
                                  optionFilterProp="children"
                                  placeholder="Entity Name"
                                  style={{ width: "100%" }}
                                  value={this.state.entity}
                                  onChange={value => {
                                    this.fillField(value);
                                  }}
                                >
                                  {Boolean(this.state["entitySelectItems"]) && this.state["entitySelectItems"].length > 0 &&
                                    this.state.entitySelectItems.map(i => (
                                      <Option key={i} value={i}>
                                        {i}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                              {/* //////////////////////////////////////// */}
                              <Form.Item
                                {...layout}
                                label={<FormattedMessage id="PageAutoDesignFormInput.Field" defaultMessage="Field" />}
                                name="fieldName"
                                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                              >
                                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                  {Boolean(this.state["fieldIdSelectItems"]) &&
                                    this.state["fieldIdSelectItems"].length > 0 &&
                                    this.state["fieldIdSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </>
                          )}
                          {
                            <Form.Item
                              {...layout}
                              label={<FormattedMessage id="PageAutoDesignFormInput.hover" defaultMessage="Hover" />}
                              name="hover"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <Input style={{ width: "100%" }} />
                            </Form.Item>
                          }
                          {
                            <Form.Item
                              {...layout}
                              label={<FormattedMessage id="PageAutoDesignFormInput.required" defaultMessage="Required" />}
                              name="required"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                              valuePropName="checked"
                            >
                              <Checkbox></Checkbox>
                            </Form.Item>
                          }
                        </Form>

                        <Row gutter={[16, 16]}>
                          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                            <AntButton
                              type="primary"
                              style={{ width: "100%" }}
                              onClick={this.handleAddDetailForm}
                            >
                              <FormattedMessage id="GeneralButtonAddDetail" defaultMessage="Add Detail" />
                            </AntButton>
                          </Col>
                          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>;
  }

  tableRender(layout1, selectedInputIndex, inputDetails, selectedInputName, layout, selectedTab) {
    return <div className="flex-row-fluid ml-lg-8" hidden={selectedTab === "Table" ? false : true}>
      <div className="card card-custom card-stretch">
        <div className="form">
          <div className="card-body">

            <br /><br />
            <div className="d-flex flex-row">
              <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
                <div className="card card-custom card-stretch">
                  {/* begin::Body */}
                  <div className="card-body pt-4">
                    {/* begin::Toolbar */}
                    <div className="d-flex justify-content-end"></div>
                    {/* end::Toolbar */}
                    {/* begin::Nav */}
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                      <Card
                        style={{
                          borderRadius: "8px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          width: "100%",
                          height: "100%"
                        }}
                      >
                        {<Form.List name="tableRenderInputs">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map((field, index) => (
                                <Form.Item
                                  {...field}
                                  {...layout1}
                                  label={index + 1}
                                  rules={[
                                    {
                                      required: false,
                                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                    }
                                  ]}
                                >
                                  <tr key={field.key}>
                                    <td>
                                      <Form.Item
                                        {...field}
                                        style={{
                                          display: "inline-block",
                                          width: '100%'
                                        }}
                                        name={[field.name, "inputName"]}
                                      >
                                        <Input
                                          onChange={(e) => {
                                            if (field.name === selectedInputIndex) {
                                              this.setState({
                                                selectedInputName: e.target.value,
                                              });
                                            }
                                          }} />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Tooltip title="Format">
                                        <ZoomInOutlined
                                          onClick={() => {
                                            const tableRenderInputs = this.formRef.current.getFieldValue("tableRenderInputs");
                                            if (Boolean(tableRenderInputs) && tableRenderInputs.length > 0) {
                                              let inputName = tableRenderInputs[field.name];
                                              this.setState({
                                                selectedInputIndex: field.name,
                                                selectedInputName: inputName,
                                              }, () => {
                                                let row = inputDetails.find(p => p.inputName === inputName);
                                                if (Boolean(row)) {
                                                  if (Boolean(this.formRefDetail) && Boolean(this.formRefDetail.current)) {
                                                    this.formRefDetail.current.setFieldsValue({
                                                      ...row
                                                    });
                                                  }
                                                } else {
                                                  if (Boolean(this.formRefDetail) && Boolean(this.formRefDetail.current)) {
                                                    this.formRefDetail.current.resetFields();
                                                  }
                                                }
                                              });
                                            }
                                          }} />
                                      </Tooltip>
                                    </td>
                                    <td>
                                      <Tooltip title="Delete">
                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                      </Tooltip>
                                    </td>
                                  </tr>
                                </Form.Item>
                              ))}
                              <tr>
                                <td colSpan={11}>
                                  <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    <FormattedMessage id={"PageAutoDesignDrawerParent.AddInput"} defaultMessage={"Add Input"} />
                                  </AntButton>
                                </td>
                              </tr>
                            </>
                          )}
                        </Form.List>}
                      </Card>
                    </div>
                    {/* end::Nav */}
                  </div>
                  {/* end::Body */}
                </div>
              </div>
              <div className="flex-row-fluid ml-lg-8">
                {selectedInputIndex > -1 && Boolean(selectedInputName) && (
                  <div className="card card-custom card-stretch">
                    <div className="card-header py-3">
                      <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                          {selectedInputName}
                        </h3>
                      </div>
                      <div className="card-toolbar"></div>
                    </div>
                    <div className="form">
                      <div className="card-body">
                        <Form
                          initialValues={{ remember: false }}
                          onFinish={this.handleAddDetail}
                          ref={this.formRefDetail}
                        >
                          {<Form.Item
                            {...layout}
                            label={<FormattedMessage id="PageAutoDesignFormInput.label" defaultMessage="Label" />}
                            name="label"
                            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Input style={{ width: "100%" }} />
                          </Form.Item>}
                          {<Form.Item
                            {...layout}
                            label={<FormattedMessage id="PageAutoDesignFormInput.placeholder" defaultMessage="Placeholder" />}
                            name="placeholder"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Input style={{ width: "100%" }} />
                          </Form.Item>}
                          {<Form.Item
                            {...layout}
                            label={<FormattedMessage id="PageAutoDesignFormInput.placeholder" defaultMessage="Input Type" />}
                            name="inputType"
                            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Select
                              showSearch
                              allowClear
                              optionFilterProp="children"
                              style={{ width: "100%" }}
                              value={this.state.inputType}
                              onChange={e => {
                                this.setState({ inputType: e });
                              }}
                            >
                              {Boolean(this.state["inputTypeSelectItems"]) && this.state["inputTypeSelectItems"].length > 0 &&
                                this.state["inputTypeSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.key}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>}

                          {this.state.inputType && (
                            <>
                              <Form.Item
                                {...layout}
                                label={<FormattedMessage id="PageAutoDesignFormInput.Folder" defaultMessage="Folder" />}
                                name="folderName"
                                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                              >
                                <Select
                                  showSearch
                                  allowClear
                                  optionFilterProp="children"
                                  style={{ width: "100%" }}
                                  value={this.state.folder}
                                  onChange={this.onChangeFolder}
                                >
                                  {Boolean(this.state["folderSelectItems"]) && this.state["folderSelectItems"].length > 0 &&
                                    this.state["folderSelectItems"].map(i => (
                                      <Option key={i} value={i}>
                                        {i}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                              {/* //////////////////////////////////////// */}
                              <Form.Item
                                {...layout}
                                label={<FormattedMessage id="PageAutoDesignFormInput.Entity" defaultMessage="Entity" />}
                                name="entity"
                                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                              >
                                <Select
                                  showSearch
                                  allowClear
                                  optionFilterProp="children"
                                  placeholder="Entity Name"
                                  style={{ width: "100%" }}
                                  value={this.state.entity}
                                  onChange={value => {
                                    this.fillField(value);
                                  }}
                                >
                                  {Boolean(this.state["entitySelectItems"]) && this.state["entitySelectItems"].length > 0 &&
                                    this.state.entitySelectItems.map(i => (
                                      <Option key={i} value={i}>
                                        {i}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                              {/* //////////////////////////////////////// */}
                              <Form.Item
                                {...layout}
                                label={<FormattedMessage id="PageAutoDesignFormInput.Field" defaultMessage="Field" />}
                                name="fieldName"
                                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                              >
                                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                  {Boolean(this.state["fieldIdSelectItems"]) &&
                                    this.state["fieldIdSelectItems"].length > 0 &&
                                    this.state["fieldIdSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </>
                          )}
                          {
                            <Form.Item
                              {...layout}
                              label={<FormattedMessage id="PageAutoDesignFormInput.hover" defaultMessage="Hover" />}
                              name="hover"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <Input style={{ width: "100%" }} />
                            </Form.Item>
                          }
                          {
                            <Form.Item
                              {...layout}
                              label={<FormattedMessage id="PageAutoDesignFormInput.required" defaultMessage="Required" />}
                              name="required"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                              valuePropName="checked"
                            >
                              <Checkbox></Checkbox>
                            </Form.Item>
                          }
                          {/*
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="PageAutoDesignFormInput.hideAtStart" defaultMessage="Backend Only" />}
                    name="backendOnly"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox></Checkbox>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="PageAutoDesignFormInput.hideAtStart" defaultMessage="Hide at start" />}
                    name="hideAtStart"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox></Checkbox>
                  </Form.Item>
                } 
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="PageAutoDesignFormInput.whatIsLanguageKey" defaultMessage="What Is Language Key" />}
                    name="whatIsLanguageKey"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="PageAutoDesignFormInput.whatIsDescription" defaultMessage="What Is Description" />}
                    name="whatIsDescription"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="PageAutoDesignFormInput.labelLanguageKey" defaultMessage="Label Language Key" />}
                    name="labelLanguageKey"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                  */}
                        </Form>

                        <Row gutter={[16, 16]}>
                          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                            <AntButton
                              type="primary"
                              style={{ width: "100%" }}
                              onClick={this.handleAddDetail}
                            >
                              <FormattedMessage id="GeneralButtonAddDetail" defaultMessage="Add Detail" />
                            </AntButton>
                          </Col>
                          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>;
  }

  buttonRender(layout1, selectedTab) {
    return <div hidden={selectedTab === "Button" ? false : true}>
      <Form.Item
        {...layout1}
        label={<FormattedMessage id="PageAutoDesignDrawerParent.Actions" defaultMessage="Actions" />}
        name="actions"
        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
      >
        <Checkbox.Group>
          {/* <Checkbox value="create">
            <FormattedMessage id="PageAutoDesignDrawerParent.Create" defaultMessage="Create" />
          </Checkbox>
          <Checkbox value="update">
            <FormattedMessage id="PageAutoDesignDrawerParent.Update" defaultMessage="Update" />
          </Checkbox>
          <Checkbox value="delete">
            <FormattedMessage id="PageAutoDesignDrawerParent.Delete" defaultMessage="Delete" />
          </Checkbox> */}
          <Checkbox value="export">
            <FormattedMessage id="PageAutoDesignDrawerParent.Export" defaultMessage="Export" />
          </Checkbox>
          <Checkbox value="import">
            <FormattedMessage id="PageAutoDesignDrawerParent.Import" defaultMessage="Import" />
          </Checkbox>
          <Checkbox value="setting">
            <FormattedMessage id="PageAutoDesignDrawerParent.Settings" defaultMessage="Settings" />
          </Checkbox>
          <Checkbox value="pdf">
            <FormattedMessage id="PageAutoDesignDrawerParent.Pdf" defaultMessage="Pdf" />
          </Checkbox>

        </Checkbox.Group>
      </Form.Item>
    </div>
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PageAutoDesignDrawerParent);