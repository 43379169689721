import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { handleRequest } from "../../ApiConnector";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import CustomMaterialMenu from "../CustomMenu/CustomMaterialMenu";
import { Form, Input, Select, Col, Row, Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { TransmittalResponse } from "./TransmittalResponse";
import { error, showError, showSuccess } from "../../MessageHelper";

const { Option } = Select;

export class TransmittalSetting extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      showCodeModal: false,
      showSettingsModal: false,

      hideUpdate: true,
      hideSave: false,
      hiddenInputs: true,
      filePaths: [],
      fileList: [],
      showRevisedListModal: false,
      showLinkedListModal: false,
      revisedFileList: [],
      modalPdfViewer: false,
      pdfFile: "",
      codeName: "",
      drawingTypeSelectItems: [],
      disciplineSelectItems: [],
      departmentSelectItems: [],
      documentTypeSelectItems: [],
      actionTypeSelectItems: [],
      clientSelectItems: [],
      userSelectItems: [],
      locationSelectItems: [],

      materialEquipmentSelectItems: [],
      id: null,
      modalResponseShow: false,
      selectedRow: null,
      revising: false,

      disciplineId: null,
      departmentId: null,
      drawingTypeId: null,
      drawingSearch: null,
      linkDrawingList: [],
      linkedDrawingIds: [],
      dateOfSupersede: null,
      modalSupersedeAction: false,
      index: null,
      logDetailId: null,
      logFileName: null,
      logFilePath: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: [
          "2",
          "10",
          "25",
          "50",
          "100",
          "1000",
          "10000",
          "100000",
          "1000000",
        ],
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillDisciplines();

    const response = await handleRequest(
      "GET",
      "/api/transmittalSettings/initialize"
    );
    if (Boolean(response.data)) {
      const row = response.data;
      this.edit(row);
    }
  };

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hiddenInputs: true,
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();

    this.setState({
      filePaths: [],
      fileList: [],

      id: null,
      logDetailId: null,
      revising: false,
      logFileName: null,
      logFilePath: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: [
          "2",
          "10",
          "25",
          "50",
          "100",
          "1000",
          "10000",
          "100000",
          "1000000",
        ],
      },
    });
  };

  edit = (row) => {
    let disciplineIds = [];

    if (Boolean(row.disciplineIds)) {
      let arrayOfDisciplines = row.disciplineIds.split(",");
      if (Boolean(arrayOfDisciplines) && arrayOfDisciplines.length > 0)
        arrayOfDisciplines.forEach((element) => {
          disciplineIds.push(parseInt(element));
        });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      disciplineIds: disciplineIds,
    });

    this.getUser(row.byUserId);

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hiddenInputs: false,
      id: row.id,
    });
  };

  deleteModal = (row) => {
    this.setState({
      modalDeleteShow: true,
      id: row.id,
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow,
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id,
    };
    var response = await handleRequest(
      "DELETE",
      "/api/transmittalSettings/" + deletedItem.id
    );

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false,
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  onClickCreateNew = async () => {
    this.resetInputs();
    if (this.state.hiddenInputs) {
      this.setState({
        hiddenInputs: false,
        hiddenSave: false,
        hiddenUpdate: true,
      });
    } else {
      this.setState({
        hiddenInputs: true,
        hiddenSave: true,
        hiddenUpdate: false,
      });
    }
  };

  onClickSettings = async () => {
    this.setState({
      showSettingsModal: true,
    });
  };

  save = async (values) => {
    const newItem = {
      ...values,
      id: this.state.id,
      disciplineIds: Boolean(values.disciplineIds)
        ? values.disciplineIds.join()
        : null,
    };

    if (!Boolean(newItem.id)) {
      var response = await handleRequest(
        "POST",
        "/api/transmittalSettings",
        newItem
      );
    } else {
      response = await handleRequest(
        "PUT",
        "/api/transmittalSettings/" + newItem.id,
        newItem
      );
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
    }
  };

  addItem = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "discipline",
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: "",
        });
        this.fillDisciplines();
      }
    } else {
      showError("Please enter discipline");
    }
  };

  fillDisciplines = async () => {
    const response = await handleRequest("GET", "/api/codes/type/discipline");
    if (Boolean(response.data)) {
      this.setState({
        disciplineSelectItems: response.data,
      });
    }
  };

  actionTemplate(row) {
    return (
      <React.Fragment>
        <CustomMaterialMenu
          row={row}
          onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.edit.bind(this)}
        />
      </React.Fragment>
    );
  }

  searchUserMail = async (email) => {
    if (Boolean(email) && email.length > 0) {
      var response = await handleRequest("GET", "/api/users/mails/" + email);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          userSelectItems: Boolean(response.data) ? response.data : [],
        });
      }
    } else
      this.setState({
        userSelectItems: [],
      });
  };

  getUser = async (id) => {
    var list = [];
    if (Boolean(id)) {
      var response = await handleRequest("GET", "/api/users/" + id);
      if (response.type === "ERROR") {
        error(response);
      } else {
        list.push(response.data);
        this.setState({
          userSelectItems: list,
        });
      }
    } else
      this.setState({
        userSelectItems: [],
      });
  };

  openCodeModal = (type) => {
    this.setState({
      showCodeModal: true,
      codeType: type,
    });
  };

  addCode = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: this.state.codeType,
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false,
        });
        this.fillCode();
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async () => {
    const { codeType } = this.state;

    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data,
      });
    }
  };

  render() {
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center",
            }}
          >
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar
      );
    };

    return (
      <div
        hidden={this.state.hideInputs}
        className="card card-custom"
        style={{ padding: "2rem" }}
      >
        <Form
          initialValues={{ remember: false }}
          onFinish={this.save}
          onFinishFailed={onFinishFailed}
          ref={this.formRef}
        >
          {
            <Form.Item
              {...layout2}
              label={
                <FormattedMessage
                  id="todo"
                  defaultMessage="Format Of Transmittal No"
                />
              }
              name="formatOfTransmittalNo"
              rules={[
                {
                  required: false,
                  message: (
                    <FormattedMessage
                      id="todo"
                      defaultMessage="Please type description"
                    />
                  ),
                },
              ]}
            >
              <Input placeholder="Format Of Transmittal No" />
            </Form.Item>
          }

          {
            <Form.Item
              {...layout2}
              label={<FormattedMessage id="todo" defaultMessage="Discipline" />}
              name="disciplineIds"
              rules={[
                {
                  required: false,
                  message: (
                    <FormattedMessage
                      id="todo"
                      defaultMessage="This field is required"
                    />
                  ),
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Dsicipline"
                id="DefinitionsType"
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        padding: 8,
                      }}
                    >
                      <a
                        style={{
                          flex: "none",
                          padding: "8px",
                          display: "block",
                          cursor: "pointer",
                        }}
                        onClick={() => this.openCodeModal("discipline")}
                      >
                        <PlusOutlined /> Add New
                      </a>
                    </div>
                  </div>
                )}
              >
                <Option key={null} value={null}>
                  Select
                </Option>
                {this.state.disciplineSelectItems.map((i) => (
                  <Option key={i.id} value={i.id}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          }

          {
            <Row gutter={8}>
              <Col span={8}></Col>

              <Col span={8}>
                {
                  <Button
                    id="OrganizationalLevelSaveButton"
                    type="submit"
                    style={{ width: "100%" }}
                    variant="success"
                  >
                    <FormattedMessage
                      id="GeneralButtonSave"
                      defaultMessage="Save"
                    />
                  </Button>
                }
              </Col>
            </Row>
          }
        </Form>

        <Modal
          show={this.state.showCodeModal}
          onHide={() => {
            this.setState({
              showCodeModal: false,
            });
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {" "}
              Add item to dropdown{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Input
              placeholder="Type Value"
              id="modalCodeName"
              value={this.state.codeName}
              onChange={(e) => this.setState({ codeName: e.target.value })}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  showCodeModal: false,
                  codeName: null,
                });
              }}
            >
              Close
            </Button>

            <Button variant="primary" onClick={this.addCode}>
              {" "}
              Save{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalDeleteShow}
          onHide={this.showOrHideDeleteModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Chosen record will be deleted !
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalResponseShow}
          onHide={() => {
            this.setState({
              modalResponseShow: false,
            });
            const { pagination } = this.state;
            this.restartTable({ pagination });
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {" "}
              Transmittal Response
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TransmittalResponse
              transmittalLogId={
                Boolean(this.state.selectedRow)
                  ? this.state.selectedRow.id
                  : null
              }
            ></TransmittalResponse>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                this.setState({
                  modalResponseShow: false,
                });
                const { pagination } = this.state;
                this.restartTable({ pagination });
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Viewer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfViewer}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // auth: state.auth,
    logout: state.logout,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TransmittalSetting);
// export default connect(null, auth.actions)(Domain);
