import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../modules/Auth/_redux/authRedux";
import EditIcon from '@material-ui/icons/EditTwoTone';
import { Button, Modal, Form as FormBootstrap, Col as ColBootstrap, Row as RowBootstrap } from 'react-bootstrap';
import { FilterOutlined, FolderOpenFilled, CarryOutOutlined, FormOutlined } from '@ant-design/icons';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { handleRequest } from '../../ApiConnector';
import CreateIcon from '@material-ui/icons/AddCircle';
import ExportIcon from '@material-ui/icons/CloudDownloadRounded';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { Form, message, Table, Input, Select, Switch, Col, Row, DatePicker, Tree, Button as AntButton, Image, Space } from 'antd';
import { ExcelReader } from '../excel/ExcelReader';
import moment from 'moment';
import { FcFolder, FcEditImage, FcDownload, FcFile } from "react-icons/fc";
import { MdShare } from "react-icons/md";

import { SiWebauthn } from "react-icons/si";
import { BiShow, BiEdit } from "react-icons/bi";
import { VscSymbolProperty } from "react-icons/vsc";
import { Upload } from 'antd';
import { LogDetail } from './LogDetail';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { API_BASE_ROOT } from '../../ApiConnector';
import { error, showError, showSuccess } from '../../MessageHelper';

import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

import { FileIcon, defaultStyles } from 'react-file-icon';
// import logger from 'logging-library';
import FileViewer from 'react-file-viewer';
// import { CustomErrorComponent } from 'custom-error';

import {
    Menu,
    Item,
    Separator,
    Submenu,
    useContextMenu

} from "react-contexify";
import { FaDownload, FaFileUpload, FaPlus, FaRegFile } from 'react-icons/fa';
import { MdCreateNewFolder, MdDelete } from "react-icons/md";
import "react-contexify/dist/ReactContexify.css";
import { select } from '@redux-saga/core/effects';

const { Option } = Select;
const { Search } = Input;
const FOLDER_MENU = "folderMenu";
const FILE_MENU = "fileMenu";
const LOG_MENU = "logMenu";
const { show } = useContextMenu();

function IconFont(props) {
    return <i {...props} />
}

function updateTreeData(list, key, children, expandedKeys) {
    return list.map((node) => {
        if (node.key === key) {
            return { ...node, children: setChildren(node.children, children, expandedKeys) };
        }

        if (node.children) {
            return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
        }

        return node;
    });
}



function setChildren(oldList, newList, keys) {
    let result = [];

    if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
        oldList.forEach(node => {
            if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
                let findInNewIndex = newList.findIndex(p => p.key === node.key);
                if (findInNewIndex >= 0) {
                    result.push(node);
                    newList.splice(findInNewIndex, 1);
                }
            }

        });

        newList.forEach(element => {
            result.push(element);
        });

    }
    else result = Boolean(newList) && newList.length > 0 ? newList : [];

    return result;
}

var dataList = [];
const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];
        dataList.push({ key: node.key, title: node.title });
        if (node.children) {
            generateList(node.children);
        }
    }
};
export class Filling extends Component {

    constructor() {
        super();

        this.state = {

            modalDeleteShow: false,
            hideUpdate: true,
            hideSave: false,
            hiddenInputs: true,
            checkable: false,

            id: null,
            parentId: null,
            logId: null,
            folderName: null,
            logName: null,
            logTitle: null,
            projectId: null,
            companyId: null,
            authorizationType: null,

            searchValue: "",
            selectedNodeKey: null,
            selectedNode: null,
            parentNode: null,
            selectedFile: null,
            selectedFolder: null,
            nodes: [],
            expandedKeys: [],
            loadedKeys: [],
            autoExpandParent: false,
            showFolderModal: false,
            showFileModal: false,
            showLogModal: false,
            showShareModal: false,
            showAuthModal: false,
            modalPropertiesFile: false,
            checkedKeys: [],
            halfCheckedKeys: [],
            checkedNodes: [],

            userSelectItems: [],
            selectedMailAddress: [],
            selectedUserIds: [],
            filePath: '',
            fileList: [],
            filePaths: [],
            type: null,
            path: null,
            showFileViewer: false,
            showFileRenameModal: false,
            title: null,
            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '25', '50', '100'],
            },
            loading: false,
            sortField: "",
            sortOrder: "",
        };


    }

    formRef = React.createRef();
    inputRef = React.createRef(null);
    inputRefFileLog = React.createRef(null);
    inputRefLog = React.createRef(null);

    componentDidMount = async () => {

        this.fillTree();
    };

    componentDidUpdate = (prevProps) => {
        if (Boolean(this.inputRef) && Boolean(this.inputRef.current))
            this.inputRef.current.focus({
                preventScroll: true,
            });
        if (Boolean(this.inputRefFileLog) && Boolean(this.inputRefFileLog.current))
            this.inputRefFileLog.current.focus({
                preventScroll: true,
            });
        if (Boolean(this.inputRefLog) && Boolean(this.inputRefLog.current))
            this.inputRefLog.current.focus({
                preventScroll: true,
            });
    }

    searchUserMail = async (email) => {
        if (Boolean(email) && email.length > 0) {
            var response = await handleRequest("GET", "/api/users/mails/" + email);
            if (response.type === "ERROR") {
                error(response);
            }
            else {
                this.setState({
                    userSelectItems: Boolean(response.data) ? response.data : []
                });
            }
        }
        else this.setState({
            userSelectItems: []
        });
    }


    handleItemClick = ({ event, props, triggerEvent, data }) => {
        console.log(event, props, triggerEvent, data);
        console.log(event.currentTarget.id);
        var key = Boolean(this.state.selectedNodeKey) ? parseInt(this.state.selectedNodeKey.split('~')[1]) : null;
        const selectedNode = this.state.selectedNode;

        // eslint-disable-next-line default-case
        switch (event.currentTarget.id) {
            case "NewFolder":
                this.setState({
                    showFolderModal: true,
                    parentId: key,
                    folderName: ""
                })

                break;
            case "RenameFolder":
                if (selectedNode.type === "FOLDER")
                    this.setState({
                        id: key,
                        showFolderModal: true,
                        folderName: Boolean(selectedNode) ? selectedNode.title : null
                    })
                else
                    this.setState({
                        id: key,
                        showFileRenameModal: true,
                        title: Boolean(selectedNode) ? selectedNode.originalTitle : null
                    })
                break;
            case "NewFile":
                this.setState({
                    id: key,
                    showFileModal: true,

                })
                break;
            case "NewLog":
                this.setState({
                    id: key,
                    showLogModal: true,
                })
                break;
            case "Share":
                this.setState({
                    id: key,
                    showShareModal: true,
                })
                break;
            case "AuthReadFolder":
                this.setState({
                    id: key,
                    authorizationType: "R",
                    showAuthModal: true,
                })
                break;
            case "AuthWriteFolder":
                this.setState({
                    id: key,
                    authorizationType: "RW",
                    showAuthModal: true,
                })
                break;
            case "DeleteFolder":
                this.setState({
                    modalDeleteShow: true,
                    id: key
                });
                break;
            case "PropertiesFile":

                this.setState({
                    modalPropertiesFile: true,
                    id: key
                }, this.fillFileProperties);
                break;
            case "PropertiesFolder":
                this.setState({
                    modalPropertiesFolder: true,
                    id: key
                }, this.fillFolderProperties);
                break;
            case "DownloadFolder":
                this.setState({
                    id: key
                }, this.downloadFolder);
                break;
            case "DownloadFile":

                if (this.state.selectedNode.type === "FILE" || this.state.selectedNode.type === "LOG")
                    window.location.href = this.state.selectedNode.path;
                break;
        }
    }

    displayMenu = (e, type) => {

        console.log(type);
        if (Boolean(type))
            switch (type) {
                case "FOLDER":
                    show(e, { id: FOLDER_MENU });

                    break;
                case "FILE":
                    show(e, {
                        id: FILE_MENU
                    });

                    break;
                case "LOG":
                    show(e, {
                        id: LOG_MENU
                    });

                    break;

                default:
                    break;
            }

    }




    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hiddenInputs: true,
        });
        this.resetInputs();
    };

    resetInputs = () => {

        this.formRef.current.setFieldsValue({
            id: null,
            parentId: null,
            folderName: null,
            projectId: null,
            companyId: null,
        });

        this.setState({
            id: null,
            folderName: null,
            parentId: null,
            projectId: null,
            companyId: null,
            showFolderModal: false,
            showFileModal: false,
            showLogModal: false,
            folderNameColor: '#bfbfbf',
            selectedNode: null,
            selectedNodeKey: null,
            parentNode: null,
            filePath: '',
            fileList: [],
            filePaths: [],
            type: null,
            path: null,
            logId: null,
            showFileRenameModal: false,
            title: null,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '25', '50', '100'],
            },
        })
    }


    edit = (row) => {
        this.formRef.current.setFieldsValue({

            folderName: row.folderName,
            projectId: row.projectId,
            companyId: row.companyId,
        });

        this.setState({
            hideUpdate: false,
            hideSave: true,
            hiddenInputs: false,

            id: row.id,
            folderName: row.folderName,
            projectId: row.projectId,
            companyId: row.companyId,
        });
    };

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        }
        let selectedNode = this.state.selectedNode;
        if (selectedNode.type === "FOLDER")
            var response = await handleRequest("DELETE", "/api/folders/" + deletedItem.id);
        else
            response = await handleRequest("DELETE", "/api/files/" + deletedItem.id);


        if (response.type === "ERROR") {
            error(response);
        }
        else {
            // var nodes = deleteSelectedNode(this.state.nodes, "FOLDER~" + deletedItem.id);
            if (Boolean(this.state.parentNode))
                this.loadData(this.state.parentNode);
            else
                this.fillTree();

            showSuccess();
            this.setState({
                id: null,
                modalDeleteShow: false,

            });
        }
    }




    onClickCreateNew = async () => {
        this.resetInputs();
        if (this.state.hiddenInputs) {
            this.setState({
                hiddenInputs: false,
                hiddenSave: false,
                hiddenUpdate: true,
            });
        }
        else {
            this.setState({
                hiddenInputs: true,
                hiddenSave: true,
                hiddenUpdate: false,
            });
        }
    }



    saveFolder = async () => {

        const newItem = {
            id: this.state.id,
            parentId: this.state.parentId,
            folderName: this.state.folderName,
            /// projectId: this.state.projectId,
            // companyId: this.state.companyId,
        }

        if (newItem.id === null) {
            var response = await handleRequest("POST", "/api/folders", newItem);
        }
        else {
            response = await handleRequest("PUT", "/api/folders/foldername", newItem);
        }

        if (response.type === "ERROR") {
            error(response);
        }
        else {

            if (newItem.id === null) {
                this.expandSelectedNode();
            }
            else {

                if (Boolean(this.state.parentNode))
                    this.loadData(this.state.parentNode);
                else
                    this.fillTree();
            }

            showSuccess();
            this.cancel();
        }
    };

    saveFile = async () => {

        if (Boolean(this.state.fileList) && this.state.fileList.length > 0) {
            let list = [];
            this.state.fileList.forEach(element => {
                const obj = {

                    folderId: this.state.id,
                    path: element.url,
                    fileName: element.name,
                    type: "FILE",
                    size: element.size / (1024),
                    extension: element.name.includes ? element.name.substring(element.name.lastIndexOf(".") + 1, element.name.length) : "",

                }
                list.push(obj);

            });


            var response = await handleRequest("POST", "/api/files/list", list);

            if (response.type === "ERROR") {
                error(response);
            }
            else {

                this.expandSelectedNode();
                showSuccess();
                this.cancel();
            }

        }

        else showError("Please select minimum one file !");

    };

    saveLog = async () => {

        const newItem = {

            folderId: this.state.id,
            logName: this.state.logName,
            fileName: "",
            type: "LOG",

        }

        var response = await handleRequest("POST", "/api/files", newItem);

        if (response.type === "ERROR") {
            error(response);
        }
        else {

            console.log("logId", response.data);
            showSuccess();
            this.setState({
                logId: response.data,
                logTitle: newItem.logName,
                logName: null,

            })
        }
    };

    renameFileLog = async () => {

        const selectedNode = this.state.selectedNode;
        if (selectedNode.type === "FILE")
            var newItem = {

                id: this.state.id,
                fileName: this.state.title

            }

        if (selectedNode.type === "LOG")
            newItem = {

                id: this.state.id,
                logName: this.state.title

            }

        var response = await handleRequest("PUT", "/api/files/rename", newItem);

        if (response.type === "ERROR") {
            error(response);
        }
        else {

            if (Boolean(this.state.parentNode))
                this.loadData(this.state.parentNode);
            else
                this.fillTree();

            showSuccess();
            this.cancel();
        }
    };

    expandSelectedNode = () => {


        console.log("expandSelectedNode", this.state.selectedNodeKey);
        console.log("expandedkeys", this.state.expandedKeys);
        let key = this.state.selectedNodeKey;
        let node = this.state.selectedNode;
        if (Boolean(key) && key.includes("FOLDER~")) {
            let expandedKeys = Boolean(this.state.expandedKeys) ? this.state.expandedKeys : [];
            if (Boolean(expandedKeys.find(p => p === key))) {
                this.loadData(node);
            }
            else {
                this.setState({
                    expandedKeys: [...expandedKeys, key]
                });
                this.loadData(node);
            }
        }
    }

    expandDragDrop = (pNode) => {

        let key = pNode.key;
        let node = pNode;
        if (Boolean(key) && key.includes("FOLDER~")) {
            let expandedKeys = Boolean(this.state.expandedKeys) ? this.state.expandedKeys : [];
            if (Boolean(expandedKeys.find(p => p === key))) {
                this.loadData(node);
            }
            else {
                this.setState({
                    expandedKeys: [...expandedKeys, key]
                });
                this.loadData(node);
            }
        }
    }

    createWhere(params) {
        var current = this.state.pagination.current;
        var pageSize = this.state.pagination.pageSize;
        var folderName = "", sortField = "", sortOrder = "";;
        if (Boolean(params) && Boolean(params.pagination)) {
            current = params.pagination.current;
            pageSize = params.pagination.pageSize;
            if (Boolean(params.pagination.folderName)) {
                folderName = params.pagination.folderName;
            }

            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }

        }
        const newObj = {
            id: null,
            folderName: folderName,
            firstResult: current - 1, //(current - 1) * pageSize,
            maxResults: pageSize,
            sortField: sortField,
            sortOrder: sortOrder,
        };
        return newObj;
    }



    onRightClick = (event, node) => {

        console.log(node);
        let parentNode = this.getParentNode(node);

        this.setState(
            {
                selectedNodeKey: node.key,
                selectedNode: node,
                parentNode: parentNode
            }, this.displayMenu(event, node.type)
        );

    }

    getParentNode = (node) => {
        let parentNode = null;
        var list = this.state.nodes;
        var posArray = node.pos.split("-");

        for (let index = 1; index < posArray.length - 1; index++) {
            let element = parseInt(posArray[index]);
            parentNode = list[element]
            list = parentNode.children;
        }

        return parentNode;
    }

    onClick = (selectedKeys, e) => {
        console.log(e);
        var node = e.node;
        var key = Boolean(node) ? node.key.split('~')[1] : null;

        if (node.type === "LOG") {
            this.setState({
                logId: key,
                showLogModal: true,
                logTitle: node.title
            })
        }

        if (node.type === "FILE") {
            this.setState({
                path: node.path,
                type: Boolean(node.extension) && node.extension.length > 0 ? node.extension.toLowerCase() : "",
                showFileViewer: true,

            })
        }


    }


    onExpand = expandedKeys => {
        console.log("onexpand data", expandedKeys);
        this.setState({
            expandedKeys: expandedKeys,
            autoExpandParent: false,
        });
    };

    onLoadData = async (loadedKeys) => {
        console.log("onLoadData data", loadedKeys);
        console.log(loadedKeys);

        this.setState({

            loadedKeys: loadedKeys

        });
    }


    loadData = async (treenode) => {

        console.log("load data");
        const { expandedKeys } = this.state;
        var nodes = this.state.nodes;
        console.log(treenode.key);
        let obj = { id: Boolean(treenode.key) ? treenode.key.split('~')[1] : -1 };


        if (Boolean(treenode.key))
            var response = await handleRequest("GET", "/api/folders/subitems/" + obj.id);

        if (Boolean(response.data)) {
            const data = response.data;
            console.log(data);
            console.log()
            //treeNode.children = data;
            //this.findAndSetNode(nodes, treeNode);
            data.forEach(element => {
                let obj = { ...element }
                if (element.type === "FOLDER")
                    element.icon = <FcFolder />;
                else {
                    element.isLeaf = true;
                    element.originalTitle = obj.title;

                    element.title = <span style={{ padding: "10px" }} > {element.title}</span>;
                }
                if (element.type === "FILE")
                    element.icon = <div >  <FileIcon extension={element.extension} {...defaultStyles[element.extension]} />  <br /> </div>;
                if (element.type === "LOG")
                    element.icon = <div >  <FileIcon extension={"LOG"} color={"#2596be"} />  <br /> </div>; //<FcFile />; 

            });

            var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
            console.log(res);
            this.setState({
                nodes: res,

            });
        }

    };





    onDrop = async (info) => {

        console.log(info);
        console.log(info.dragNode.key);
        console.log(info.node.key);
        let droppedNodeKey = info.node.key.split("~")
        if (Boolean(droppedNodeKey) && droppedNodeKey.length > 0 && droppedNodeKey[0] === "FOLDER") {
            let dragNodeKey = info.dragNode.key.split("~");
            if (Boolean(dragNodeKey) && dragNodeKey.length > 0)
                switch (dragNodeKey[0]) {
                    case "FILE":
                        let obj = {
                            id: dragNodeKey[1],
                            folderId: droppedNodeKey[1]
                        }
                        let response = await handleRequest("PUT", "/api/files/move", obj);

                        if (response.type === "ERROR") {
                            error(response);
                        }
                        else {

                            let parentNode = this.getParentNode(info.dragNode);
                            if (Boolean(parentNode))
                                this.expandDragDrop(parentNode);


                            setTimeout(() => {
                                this.expandDragDrop(info.node);
                            }, 500);


                            showSuccess();
                            this.cancel();
                        }

                        break;
                    case "LOG":
                        obj = {
                            id: dragNodeKey[1],
                            folderId: droppedNodeKey[1]
                        }
                        response = await handleRequest("PUT", "/api/files/move", obj);

                        if (response.type === "ERROR") {
                            error(response);
                        }
                        else {
                            let parentNode = this.getParentNode(info.dragNode);
                            if (Boolean(parentNode))
                                this.expandDragDrop(parentNode);


                            setTimeout(() => {
                                this.expandDragDrop(info.node);
                            }, 500);

                            showSuccess();
                            this.cancel();
                        }
                        break;
                    case "FOLDER":
                        obj = {
                            id: dragNodeKey[1],
                            parentId: droppedNodeKey[1]
                        }
                        response = await handleRequest("PUT", "/api/folders/move", obj);

                        if (response.type === "ERROR") {
                            error(response);
                        }
                        else {
                            let parentNode = this.getParentNode(info.dragNode);
                            console.log(parentNode);
                            if (Boolean(parentNode))
                                this.expandDragDrop(parentNode);
                            else this.fillTree();

                            setTimeout(() => {
                                this.expandDragDrop(info.node);
                            }, 500);


                            showSuccess();
                            this.cancel();
                        }
                        break;

                    default:
                        break;
                }
        }
    }

    fillTree = async () => {

        var response = await handleRequest("GET", "/api/folders/root");

        if (response.type === "ERROR") {
            error(response);
        }
        else {
            if (Boolean(response.data)) {
                response.data.forEach(element => {
                    element.icon = <FcFolder />;

                });

                this.setState({
                    nodes: Boolean(response.data) ? response.data : []
                });


            }
        }

    }

    fillFileProperties = async () => {
        const { id } = this.state;

        var response = await handleRequest("GET", "/api/files/" + id);

        if (response.type === "ERROR") {
            error(response);
        }
        else {
            if (Boolean(response.data)) {

                this.setState({
                    selectedFile: Boolean(response.data) ? response.data : []
                });

            }
        }

    }

    fillFolderProperties = async () => {
        const { id } = this.state;

        var response = await handleRequest("GET", "/api/folders/properties/" + id);

        if (response.type === "ERROR") {
            error(response);
        }
        else {
            if (Boolean(response.data)) {

                this.setState({
                    selectedFolder: Boolean(response.data) ? response.data : []
                });

            }
        }

    }

    downloadFolder = async () => {
        const { id } = this.state;

        var response = await handleRequest("GET", "/api/folders/download/" + id);

        if (response.type === "ERROR") {
            error(response);
        }
        else {

            try {

                const link = document.createElement('a')
                link.href = response.data;
                link.download = this.state.selectedNode.title + ".zip";
                link.click()
            } catch (error) {
                console.log(error);

            }

        }

    }

    downloadAllSelectedFiles = async () => {

        const { checkedNodes } = this.state;
        const paramList = [];
        const checkedFiles = checkedNodes.filter(p => p.type === "FILE");
        if (Boolean(checkedFiles) && checkedFiles.length > 0) {

            checkedFiles.forEach(element => {
                let obj = { id: parseInt(element.key.split('~')[1]) };
                paramList.push(obj);
            });
            var response = await handleRequest("POST", "/api/files/download", paramList);

            if (response.type === "ERROR") {
                error(response);
            }
            else {

                try {
                    const link = document.createElement('a')
                    link.href = response.data;
                    link.download = "Fİles.zip";
                    link.click();
                    this.setState({
                        checkedKeys: [],
                        checkedNodes: [],
                        halfCheckedKeys: []
                    })
                } catch (error) {
                    console.log(error);

                }

            }
        }
        else showError("Please select least one file");

    }

    onError(e) {
        console.log(e);
        //  logger.logError(e, 'error in file-viewer');
    }

    clickFolderName(el) {
        document.getElementById('modalFolderName').click();
    }

    onChangeSearch = async (e) => {

        const { value } = e.target;
        this.setState({
            searchValue: value
        });
    };

    search = async () => {

        var response = [];
        const value = this.state.searchValue;

        var obj = {
            folderName: value
        }

        this.setState({
            expandedKeys: [],
            nodes: [],
            // loadedKeys: []
        });

        if (value.length > 0) {
            response = await handleRequest("POST", "/api/folders/search", obj);

            if (response.data !== undefined && response.data !== null && response.data.length !== 0) {
                dataList = [];
                generateList(response.data);
                const expandedKeys = [];

                dataList.forEach(element => {
                    expandedKeys.push(element.key);
                });

                this.setState({
                    expandedKeys: expandedKeys,
                    // loadedKeys:expandedKeys

                });

                response.data.forEach(element => {

                    this.initialize(element);


                });
                this.setState({
                    nodes: response.data
                });

            }


        }
        else {
            this.setState({
                expandedKeys: [],
                loadedKeys: []

            });
            this.fillTree();
        }

    };

    initialize(element) {
        let obj = { ...element }
        if (element.type === "FOLDER")
            element.icon = <FcFolder />;
        else {
            element.isLeaf = true;
            element.originalTitle = obj.title;

            element.title = <span style={{ padding: "10px" }} > {element.title}</span>;
        }
        if (element.type === "FILE")
            element.icon = <div >  <FileIcon extension={element.extension} {...defaultStyles[element.extension]} />  <br /> </div>;
        if (element.type === "LOG")
            element.icon = <div >  <FileIcon extension={"LOG"} color={"#2596be"} />  <br /> </div>; //<FcFile />; 

        if (Boolean(element.children) && element.children.length > 0) {
            element.children.forEach(child => {
                this.initialize(child);
            });

        }

    }

    share = async () => {

        const { id, selectedMailAddress } = this.state;

        const shareObject = {
            fileIds: [],
            emails: []
        }

        shareObject.fileIds.push(id);
        if (Boolean(selectedMailAddress) && selectedMailAddress.length > 0) {
            selectedMailAddress.forEach(element => {
                shareObject.emails.push(element);
            });


            var response = await handleRequest("POST", "/api/files/share", shareObject);

            if (response.type === "ERROR") {
                error(response);
            }
            else {

                showSuccess("File sended successfully");
                this.setState({
                    showShareModal: false,
                    id: null,
                    shareEmail: null,
                });
            }
        }
        else
            showError("Please type least one email address");



    }

    auth = async () => {

        let folderList = [], fileList = [];
        const { id, authorizationType } = this.state;
        let selectedFolderIds = [];
        let selectedUserIds = this.state.selectedUserIds;
        let selectedFileIds = [];

        const selectedNode = this.state.selectedNode;

        if (selectedNode.type === "FILE")
            selectedFileIds.push(id);

        if (selectedNode.type === "LOG")
            selectedFileIds.push(id);

        if (selectedNode.type === "FOLDER")
            selectedFolderIds.push(id);


        if (Boolean(selectedUserIds) && selectedUserIds.length > 0)

            selectedUserIds.forEach(userId => {
                if (Boolean(selectedFolderIds))
                    selectedFolderIds.forEach(folderId => {
                        let obj = { userId: userId, folderId: folderId, authorizationType: authorizationType, halfChecked: false };
                        folderList.push(obj);
                    });


                if (Boolean(selectedFileIds))
                    selectedFileIds.forEach(fileId => {
                        let obj = { userId: userId, fileId: fileId, authorizationType: authorizationType };
                        fileList.push(obj);
                    });


            });


        var response = await handleRequest("POST", "/api/users/authorizeSingle", { selectedUserIds: selectedUserIds, authorizationType: authorizationType, fileList: fileList, folderList: folderList });


        if (response.type === "ERROR") {
            error(response);
        }
        else {
            // this.resetInputs();
            // this.restartTable();
            showSuccess();
            this.setState({
                showAuthModal: false,
                id: null,
                selectedUserIds: null,
                authorizationType: null,
            });
        }

    };

    pressSubmit = () => {

        this.setState({
            logTitle: "TRANSFER TO TRANSMITTAL LOG"
        })

    }


    onCheck = (checkedKeys, e) => {
        console.log(checkedKeys);
        this.setState({
            checkedKeys: checkedKeys,
            halfCheckedKeys: e.halfCheckedKeys,
            checkedNodes: e.checkedNodes
        });
    };

    render() {
        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout2 = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
        };

        const props = {
            action: `${API_BASE_ROOT}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        const renderToolbar = (toolbarSlot) => {
            return (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexGrow: 1,
                            flexShrink: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.previousPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.nextPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomOutButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomPopover}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomInButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.downloadButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.moreActionsPopover}
                        </div>
                    </div>
                </div>
            );
        };

        const layoutView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
        ) => {
            return defaultLayout(
                isSidebarOpened,
                container,
                main,
                toolbar(renderToolbar),
                sidebar,
            );
        };

        return (
            <div hidden={this.state.hideInputs} className="card card-custom" style={{ padding: '2rem' }}>

                <Form
                    initialValues={{ remember: false }}
                    onFinish={this.save}
                    onFinishFailed={onFinishFailed}
                    ref={this.formRef}>



                    <div>
                        <div style={{ marginBottom: 16 }}>
                            <Space>
                                {/* <Button variant="danger" onClick={this.deleteAllSelectedFiles} disabled={this.state.checkedKeys.length === 0}  >
                                    Delete All
                                </Button> */}
                                Select: <Switch checked={this.state.checkable} onChange={(checked) => {

                                    this.setState({
                                        checkable: checked
                                    });

                                }} />
                                {this.state.checkable && <Button variant="warning" onClick={this.downloadAllSelectedFiles} disabled={this.state.checkedKeys.length === 0} >
                                    Download All Selected
                                </Button>}
                            </Space>
                        </div>
                        <div>
                            <Search style={{ marginBottom: 8 }} placeholder="Search" onChange={this.onChangeSearch} onPressEnter={this.search} />
                            {/* <Button variant="primary" onClick={this.clear}>Clear</Button>    */}
                            <Tree
                                className="draggable-tree"
                                checkable={this.state.checkable}
                                onCheck={this.onCheck}
                                checkedKeys={this.state.checkedKeys}
                                halfCheckedKeys={this.state.halfCheckedKeys}
                                onDrop={this.onDrop}
                                draggable
                                showLine={{ showLeafIcon: false }}
                                showIcon
                                checkStrictly={true}
                                onSelect={this.onClick}
                                loadedKeys={this.state.loadedKeys}
                                onLoad={this.onLoadData}
                                loadData={this.loadData}
                                onRightClick={({ event, node }) => this.onRightClick(event, node)}
                                onExpand={this.onExpand}
                                expandedKeys={this.state.expandedKeys}
                                autoExpandParent={false}
                                treeData={this.state.nodes}
                                blockNode={true}
                                style={{ lineHeight: "30px" }}
                            />


                            <Menu id={FOLDER_MENU}>

                                <Submenu label={<Fragment><FaPlus /><span>New</span></Fragment>}>
                                    <Item id="NewFolder" onClick={this.handleItemClick} ><MdCreateNewFolder /><span>Folder</span></Item>
                                    <Item id="NewLog" onClick={this.handleItemClick}><FaRegFile /><span>Log</span></Item>
                                    <Item id="NewFile" onClick={this.handleItemClick} > <FaFileUpload /> <span> File Upload
                                    </span></Item>

                                </Submenu>
                                {/* <Item id="DownloadFolder" onClick={this.handleItemClick}><FcDownload /><span>Download</span></Item> */}
                                <Item id="DeleteFolder" onClick={this.handleItemClick}><MdDelete size={20} /><span>Delete</span></Item>
                                <Item id="RenameFolder" onClick={this.handleItemClick}><FcEditImage /><span>Rename</span></Item>
                                <Submenu label={<Fragment><SiWebauthn /><span>Authorization</span></Fragment>}>
                                    <Item id="AuthReadFolder" onClick={this.handleItemClick} ><BiShow /><span>Read</span></Item>
                                    <Item id="AuthWriteFolder" onClick={this.handleItemClick} ><BiEdit /><span>Write</span></Item>
                                </Submenu>
                                <Item id="PropertiesFolder" onClick={this.handleItemClick} ><VscSymbolProperty /><span>Properties</span></Item>
                                <Item id="DownloadFolder" onClick={this.handleItemClick}><FcDownload /><span>Download</span></Item>
                            </Menu>

                            <Menu id={FILE_MENU}>


                                <Item id="DownloadFile" onClick={this.handleItemClick}><FcDownload /><span>Download</span></Item>
                                <Item id="Share" onClick={this.handleItemClick}><MdShare /><span>Share</span></Item>
                                <Item id="DeleteFolder" onClick={this.handleItemClick}><MdDelete size={20} /><span>Delete</span></Item>
                                <Item id="RenameFolder" onClick={this.handleItemClick}><FcEditImage /><span>Rename</span></Item>
                                <Submenu label={<Fragment><SiWebauthn /><span>Authorization</span></Fragment>}>
                                    <Item id="AuthReadFolder" onClick={this.handleItemClick} ><BiShow /><span>Read</span></Item>
                                    <Item id="AuthWriteFolder" onClick={this.handleItemClick} ><BiEdit /><span>Write</span></Item>
                                </Submenu>
                                <Item id="PropertiesFile" onClick={this.handleItemClick} ><VscSymbolProperty /><span>Properties</span></Item>

                            </Menu>

                            <Menu id={LOG_MENU}>

                                {/* <Item id="DownloadFolder" onClick={this.handleItemClick}><FcDownload /><span>Download</span></Item> */}
                                <Item id="DeleteFolder" onClick={this.handleItemClick}><MdDelete size={20} /><span>Delete</span></Item>
                                <Item id="RenameFolder" onClick={this.handleItemClick}><FcEditImage /><span>Rename</span></Item>
                                <Submenu label={<Fragment><SiWebauthn /><span>Authorization</span></Fragment>}>
                                    <Item id="AuthReadFolder" onClick={this.handleItemClick} ><BiShow /><span>Read</span></Item>
                                    <Item id="AuthWriteFolder" onClick={this.handleItemClick} ><BiEdit /><span>Write</span></Item>
                                </Submenu>
                                {/* <Item id="PropertiesLog" onClick={this.handleItemClick} ><VscSymbolProperty /><span>Properties</span></Item> */}

                            </Menu>
                        </div>

                    </div>
                </Form>

                <Modal
                    show={this.state.modalDeleteShow}
                    onHide={this.showOrHideDeleteModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</Button>
                        <Button variant="danger" onClick={this.delete} >Delete</Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showFolderModal}
                    onHide={() => {
                        this.setState({
                            showFolderModal: false
                        })
                    }}

                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> Folder </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Input placeholder="Folder Name" id="modalFolderName" ref={this.inputRef} onPressEnter={this.saveFolder} value={this.state.folderName} onChange={(e) => this.setState({ folderName: e.target.value })} />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showFolderModal: false
                            })
                        }}>Close</Button>

                        <Button variant="primary" onClick={this.saveFolder} > Save </Button>

                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showFileRenameModal}
                    onHide={() => {
                        this.setState({
                            showFileRenameModal: false
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> Rename </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Input placeholder="File/Log Name" id="todo" ref={this.inputRefFileLog} onPressEnter={this.renameFileLog} value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })} />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showFileRenameModal: false
                            })
                        }}>Close</Button>

                        <Button variant="primary" onClick={this.renameFileLog} > Save </Button>

                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showFileModal}
                    onHide={() => {

                        this.setState({
                            showFileModal: false,
                            fileList: [],
                            filePaths: []
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> Upload File </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <Upload {...props} id="path"
                            showUploadList={true}
                            fileList={this.state.fileList}
                            onChange={({ fileList }) => {
                                if (fileList.length === 1) {
                                    var filePath = "";
                                    if (Boolean(fileList[0].response)) {
                                        filePath = fileList[0].response.url;
                                        showSuccess("file uploaded successfully");
                                        // fileName: info.file.name, 
                                    }
                                    this.setState({
                                        fileList: fileList,
                                        filePath: filePath,
                                    });
                                }
                            }}
                            beforeUpload={(fileList) => {
                                if (this.state.fileList.length > 0) {
                                    showError("You have been already choose a file! For change firstly remove the uploaded file.");
                                    return false;
                                }
                                return true;
                            }}
                            onRemove={({ fileList }) => {
                                this.setState({
                                    fileList: [],
                                    filePath: "",
                                })
                            }
                            }>
                            <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                <UploadOutlined /> Upload File
                            </AntButton>
                        </Upload> */}

                        <Upload {...props} id="path"
                            showUploadList={true}
                            multiple
                            fileList={this.state.fileList}
                            onChange={({ fileList }) => {
                                var filePaths = [];
                                const stateFileList = this.state.fileList;
                                if (fileList.length >= 1) {
                                    for (let index = 0; index < fileList.length; index++) {

                                        if (Boolean(fileList[index].response)) {
                                            filePaths[index] = fileList[index].response.url;
                                            fileList[index].url = fileList[index].response.url;
                                            fileList[index].name = fileList[index].response.name;
                                            fileList[index].size = fileList[index].response.size;

                                            if (filePaths.length === fileList.length && fileList.length > stateFileList.length)
                                                showSuccess("Files uploaded successfully");

                                        } else
                                            if (Boolean(fileList[index].url)) {
                                                filePaths[index] = fileList[index].url;

                                            }
                                    }
                                }
                                this.setState({
                                    fileList: fileList,
                                    filePaths: filePaths,
                                });
                            }}
                            beforeUpload={(fileList) => {

                                return true;
                            }}
                            onRemove={({ fileList }) => {


                            }
                            }>
                            <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                <UploadOutlined /> Upload
                            </AntButton>
                        </Upload>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {

                            this.setState({
                                showFileModal: false,
                                fileList: [],
                                filePaths: []
                            })
                        }}>Close</Button>

                        <Button variant="primary" onClick={this.saveFile} > Save </Button>

                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showLogModal}
                    onHide={() => {
                        this.expandSelectedNode();
                        this.resetInputs();
                        this.setState({
                            showLogModal: false,
                            logTitle: null
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="xl"
                    scrollable={true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> {this.state.logTitle} </Modal.Title>
                       
                    </Modal.Header>
                    <Modal.Body>
                        {Boolean(this.state.logId) && this.state.logId > 0 ? <LogDetail folderId={this.state.id} logId={this.state.logId} pressSubmit={this.pressSubmit.bind(this)} ></LogDetail> :
                            <Input placeholder="Log Name" id="modalLogName" ref={this.inputRefLog} onPressEnter={this.saveLog} value={this.state.logName} onChange={(e) => this.setState({ logName: e.target.value })} />

                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.expandSelectedNode();
                            this.resetInputs();

                            this.setState({
                                showLogModal: false,
                                logTitle: null
                            })
                        }}>Close</Button>

                        {/* <Button variant="primary" onClick={this.saveLog} > Save </Button> */}

                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showShareModal}
                    onHide={() => {
                        this.setState({
                            showShareModal: false
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> {Boolean(this.state.selectedNode) ? this.state.selectedNode.title : ""} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <Input placeholder="Email" id="todo" value={this.state.shareEmail} onChange={(e) => this.setState({ shareEmail: e.target.value })} /> */}
                        <Select
                            showSearch
                            placeholder="Search with email"
                            style={{ width: "100%" }} mode="tags" onSearch={this.searchUserMail} id="selectedCompanyIds" value={this.state.selectedMailAddress} onChange={(value) => {

                                this.setState({ selectedMailAddress: value })
                            }}>
                            <Option key={null} value={null}>Select</Option>
                            {this.state.userSelectItems.map(i => (
                                <Option value={i.email}>{i.email}</Option>
                            ))}
                        </Select>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showShareModal: false
                            })
                        }}>Close</Button>

                        <Button variant="info" onClick={this.share} > Share </Button>

                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showAuthModal}
                    onHide={() => {
                        this.setState({
                            showAuthModal: false
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> {Boolean(this.state.selectedNode) ? this.state.selectedNode.title : ""} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <Input placeholder="Email" id="todo" value={this.state.shareEmail} onChange={(e) => this.setState({ shareEmail: e.target.value })} /> */}
                        <Select
                            showSearch
                            optionFilterProp="children"
                            placeholder="Search with email"
                            style={{ width: "100%" }} mode="multiple" onSearch={this.searchUserMail} id="selectedCompanyIds" value={this.state.selectedUserIds} onChange={(value) => {
                                this.setState({ selectedUserIds: value })
                            }}>
                            <Option key={null} value={null}>Select</Option>
                            {this.state.userSelectItems.map(i => (
                                <Option value={i.id}>{i.email}</Option>
                            ))}
                        </Select>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showAuthModal: false
                            })
                        }}>Close</Button>

                        <Button variant="info" onClick={this.auth} > Save </Button>

                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalPropertiesFile}
                    onHide={() => {
                        this.setState({
                            modalPropertiesFile: false,
                            selectedFile: null
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> {Boolean(this.state.selectedNode) ? this.state.selectedNode.title : ""} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormBootstrap.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <ColBootstrap sm="4">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    <FormattedMessage id="todo" defaultMessage="File Name" />
                                </FormBootstrap.Label>
                            </ColBootstrap>
                            <ColBootstrap sm="6">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    {Boolean(this.state.selectedFile) ? this.state.selectedFile.fileName : ""}
                                </FormBootstrap.Label>
                            </ColBootstrap>

                        </FormBootstrap.Group>
                        <FormBootstrap.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <ColBootstrap sm="4">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    <FormattedMessage id="todo" defaultMessage="Size(KB)" />
                                </FormBootstrap.Label>
                            </ColBootstrap>
                            <ColBootstrap sm="6">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    {Boolean(this.state.selectedFile) && Boolean(this.state.selectedFile.size) ? this.state.selectedFile.size.toFixed(2) : ""}
                                </FormBootstrap.Label>
                            </ColBootstrap>
                        </FormBootstrap.Group>
                        <FormBootstrap.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <ColBootstrap sm="4">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    <FormattedMessage id="todo" defaultMessage="Extension" />
                                </FormBootstrap.Label>
                            </ColBootstrap>
                            <ColBootstrap sm="6">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    {Boolean(this.state.selectedFile) && Boolean(this.state.selectedFile.extension) ? this.state.selectedFile.extension : ""}
                                </FormBootstrap.Label>
                            </ColBootstrap>

                        </FormBootstrap.Group>

                        <FormBootstrap.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <ColBootstrap sm="4">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    <FormattedMessage id="todo" defaultMessage="Created By" />
                                </FormBootstrap.Label>
                            </ColBootstrap>
                            <ColBootstrap sm="6">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    {Boolean(this.state.selectedFile) && Boolean(this.state.selectedFile.createUser) ? this.state.selectedFile.createUser : ""}
                                </FormBootstrap.Label>
                            </ColBootstrap>

                        </FormBootstrap.Group>

                        <FormBootstrap.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <ColBootstrap sm="4">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    <FormattedMessage id="todo" defaultMessage="Last Updated By" />
                                </FormBootstrap.Label>
                            </ColBootstrap>
                            <ColBootstrap sm="6">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    {Boolean(this.state.selectedFile) && Boolean(this.state.selectedFile.remoteUser) ? this.state.selectedFile.remoteUser : ""}
                                </FormBootstrap.Label>
                            </ColBootstrap>

                        </FormBootstrap.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                modalPropertiesFile: false,
                                selectedFile: null
                            })
                        }}>Close</Button>



                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalPropertiesFolder}
                    onHide={() => {
                        this.setState({
                            modalPropertiesFolder: false,
                            selectedFolder: null
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> {Boolean(this.state.selectedNode) ? this.state.selectedNode.title : ""} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormBootstrap.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <ColBootstrap sm="4">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    <FormattedMessage id="todo" defaultMessage="Folder Name" />
                                </FormBootstrap.Label>
                            </ColBootstrap>
                            <ColBootstrap sm="6">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    {Boolean(this.state.selectedFolder) ? this.state.selectedFolder.folderName : ""}
                                </FormBootstrap.Label>
                            </ColBootstrap>

                        </FormBootstrap.Group>
                        <FormBootstrap.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <ColBootstrap sm="4">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    <FormattedMessage id="todo" defaultMessage="Total Size of Files (KB)" />
                                </FormBootstrap.Label>
                            </ColBootstrap>
                            <ColBootstrap sm="6">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    {Boolean(this.state.selectedFolder) && Boolean(this.state.selectedFolder.size) ? this.state.selectedFolder.size.toFixed(2) : ""}
                                </FormBootstrap.Label>
                            </ColBootstrap>
                        </FormBootstrap.Group>
                        <FormBootstrap.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <ColBootstrap sm="4">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    <FormattedMessage id="todo" defaultMessage="File Count" />
                                </FormBootstrap.Label>
                            </ColBootstrap>
                            <ColBootstrap sm="6">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    {Boolean(this.state.selectedFolder) ? this.state.selectedFolder.count : ""}
                                </FormBootstrap.Label>
                            </ColBootstrap>

                        </FormBootstrap.Group>

                        <FormBootstrap.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <ColBootstrap sm="4">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    <FormattedMessage id="todo" defaultMessage="Created By" />
                                </FormBootstrap.Label>
                            </ColBootstrap>
                            <ColBootstrap sm="6">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    {Boolean(this.state.selectedFolder) && Boolean(this.state.selectedFolder.createUser) ? this.state.selectedFolder.createUser : ""}
                                </FormBootstrap.Label>
                            </ColBootstrap>

                        </FormBootstrap.Group>

                        <FormBootstrap.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <ColBootstrap sm="4">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    <FormattedMessage id="todo" defaultMessage="Last Updated By" />
                                </FormBootstrap.Label>
                            </ColBootstrap>
                            <ColBootstrap sm="6">
                                <FormBootstrap.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                    {Boolean(this.state.selectedFolder) && Boolean(this.state.selectedFolder.remoteUser) ? this.state.selectedFolder.remoteUser : ""}
                                </FormBootstrap.Label>
                            </ColBootstrap>

                        </FormBootstrap.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                modalPropertiesFolder: false,
                                selectedFolder: null
                            })
                        }}>Close</Button>



                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showFileViewer}
                    onHide={() => {
                        this.resetInputs();
                        this.setState({
                            showFileViewer: false
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="xl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> File Viewer </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {Boolean(this.state.type) && this.state.type === "pdf" ? <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                            <div style={{ height: "900px" }}>
                                <Viewer fileUrl={this.state.path} layout={layoutView} />
                            </div>
                        </Worker> : Boolean(this.state.type) && (this.state.type === "jpeg" || this.state.type === "jpg" || this.state.type === "png") ? <Image
                            width={750}
                            src={this.state.path}
                        /> : <FileViewer
                            fileType={this.state.type}
                            filePath={this.state.path}
                            onError={this.onError} />}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.resetInputs();

                            this.setState({
                                showFileViewer: false
                            })
                        }}>Close</Button>

                        {/* <Button variant="primary" onClick={this.saveFile} > Save </Button> */}

                    </Modal.Footer>
                </Modal>

            </div>
        );


    }
}

const mapStateToProps = state => {
    console.log("YYY", state);

    return {
        // auth: state.auth,
        logout: state.logout,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Filling)
// export default connect(null, auth.actions)(Domain);
