import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { handleRequest, API_BASE_ROOT } from "../../ApiConnector";
import { Button, Modal, Table as TableBoot, Col as ColBoot, Form as FormBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { Form, Table, Col, Row, Button as AntButton, InputNumber, Badge, Cascader, Radio, Checkbox, Upload, Switch, Collapse } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Select } from "antd";
import { Input } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, MinusCircleOutlined, UploadOutlined, CloseOutlined, LinkOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import RadioGroup from "antd/lib/radio/group";

const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

const layoutEvaluation = {
  labelCol: { span: 8 },
  // wrapperCol: { span: 16 }
  wrapperCol: { span: 20, offset: 6 }
};

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class QualityChecklistSublist extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "QualityChecklistSublist",
      controllerName: "qualityChecklistSublist",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      inspectionTypeSelectItems: [],
      fileList: [],
      rows: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      frontCodes: [],
      backendCodes: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };
  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillStandardId = async standardId => {
    if (Boolean(standardId) && standardId > 0) {
      var responsestandart = await handleRequest("GET", "/api/" + this.state.controllerName + "/standardId/" + standardId);
      if (responsestandart.type === "ERROR") {
        error(responsestandart);
      }
      this.setState({
        standardIdSelectItems: Boolean(responsestandart.data) ? responsestandart.data : []
      });
    } else {
      this.setState({
        standardIdSelectItems: []
      });
    }
  };

  getQualityChecklistType = async inspectionTypeId => {
    if (Boolean(inspectionTypeId) && inspectionTypeId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getQualityChecklistType/" + inspectionTypeId);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          addItem: false,
          chemical: false,
          mechanical: false
        });
      } else {
        let data = response.data;
        if (Boolean(data)) {
          // let addItem = true;
          // if (data.chemical === true) {
          //   addItem = false;
          // }
          // if (data.mechanical === true) {
          //   addItem = false;
          // }

          this.setState({
            // addItem: addItem,
            addItem: data.addItem,
            chemical: data.chemical,
            mechanical: data.mechanical
          });
        } else {
          this.setState({
            // addItem: true,
            addItem: false,
            chemical: false,
            mechanical: false
          });
        }
      }
    } else {
      this.setState({
        addItem: false,
        chemical: false,
        mechanical: false
      });
    }
  };

  fillComboboxes = async () => {
    var responseitem = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemId");
    if (responseitem.type === "ERROR") {
      error(responseitem);
    }
    this.setState({
      itemSelectItems: Boolean(responseitem.data) ? responseitem.data : []
    });

    var responseitem = await handleRequest("GET", "/api/" + this.state.controllerName + "/inspectionActivityId");
    if (responseitem.type === "ERROR") {
      error(responseitem);
    }
    this.setState({
      inspectionActivityIdSelectItems: Boolean(responseitem.data) ? responseitem.data : []
    });

    var responsereferenceId = await handleRequest("GET", "/api/" + this.state.controllerName + "/reference");
    if (responsereferenceId.type === "ERROR") {
      error(responsereferenceId);
    }
    this.setState({
      referenceIdSelectItems: Boolean(responsereferenceId.data) ? responsereferenceId.data : []
    });
    var responseacceptanceCriteriaId = await handleRequest("GET", "/api/" + this.state.controllerName + "/acceptanceCriteriaId");
    if (responseacceptanceCriteriaId.type === "ERROR") {
      error(responseacceptanceCriteriaId);
    }
    this.setState({
      acceptanceCriteriaIdSelectItems: Boolean(responseacceptanceCriteriaId.data) ? responseacceptanceCriteriaId.data : []
    });
    var responserecordId = await handleRequest("GET", "/api/" + this.state.controllerName + "/recordId");
    if (responserecordId.type === "ERROR") {
      error(responserecordId);
    }
    this.setState({
      recordIdSelectItems: Boolean(responserecordId.data) ? responserecordId.data : []
    });
    var responsemeasuringDeviceId = await handleRequest("GET", "/api/" + this.state.controllerName + "/measuringDeviceId");
    if (responsemeasuringDeviceId.type === "ERROR") {
      error(responsemeasuringDeviceId);
    }
    this.setState({
      measuringDeviceIdSelectItems: Boolean(responsemeasuringDeviceId.data) ? responsemeasuringDeviceId.data : []
    });

    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/inspectionType");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      this.setState({
        inspectionTypeSelectItems: responseTree.data
      });
    }

    var responsethickness1Type = await handleRequest("GET", "/api/" + this.state.controllerName + "/thickness1Type");
    if (responsethickness1Type.type === "ERROR") {
      error(responsethickness1Type);
    }
    this.setState({
      thickness1TypeSelectItems: Boolean(responsethickness1Type.data) ? responsethickness1Type.data : []
    });

    var responsethickness2Type = await handleRequest("GET", "/api/" + this.state.controllerName + "/thickness2Type");
    if (responsethickness2Type.type === "ERROR") {
      error(responsethickness2Type);
    }
    this.setState({
      thickness2TypeSelectItems: Boolean(responsethickness2Type.data) ? responsethickness2Type.data : []
    });

    var responsetensile1TypeId = await handleRequest("GET", "/api/" + this.state.controllerName + "/tensile1TypeId");
    if (responsetensile1TypeId.type === "ERROR") {
      error(responsetensile1TypeId);
    }
    this.setState({
      tensile1TypeIdSelectItems: Boolean(responsetensile1TypeId.data) ? responsetensile1TypeId.data : []
    });

    var responsetensile2TypeId = await handleRequest("GET", "/api/" + this.state.controllerName + "/tensile2TypeId");
    if (responsetensile2TypeId.type === "ERROR") {
      error(responsetensile2TypeId);
    }
    this.setState({
      tensile2TypeIdSelectItems: Boolean(responsetensile2TypeId.data) ? responsetensile2TypeId.data : []
    });

    var responseelementId = await handleRequest("GET", "/api/" + this.state.controllerName + "/elementId");
    if (responseelementId.type === "ERROR") {
      error(responseelementId);
    }
    this.setState({
      elementIdSelectItems: Boolean(responseelementId.data) ? responseelementId.data : []
    });

    var responsecontentType = await handleRequest("GET", "/api/" + this.state.controllerName + "/contentType");
    if (responsecontentType.type === "ERROR") {
      error(responsecontentType);
    }
    this.setState({
      contentTypeSelectItems: Boolean(responsecontentType.data) ? responsecontentType.data : []
    });

    var responseorientation = await handleRequest("GET", "/api/" + this.state.controllerName + "/orientation");
    if (responseorientation.type === "ERROR") {
      error(responseorientation);
    }
    this.setState({
      orientationSelectItems: Boolean(responseorientation.data) ? responseorientation.data : []
    });

    var responseorientation = await handleRequest("GET", "/api/" + this.state.controllerName + "/temperature");
    if (responseorientation.type === "ERROR") {
      error(responseorientation);
    }
    this.setState({
      temperatureSelectItems: Boolean(responseorientation.data) ? responseorientation.data : []
    });

    var responsephase = await handleRequest("GET", "/api/" + this.state.controllerName + "/phase");
    if (responsephase.type === "ERROR") {
      error(responsephase);
    }
    this.setState({
      phaseSelectItems: Boolean(responsephase.data) ? responsephase.data : []
    });

    const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/answerType");
    if (Boolean(response.data)) {
      this.setState({
        answerTypeSelectItems: response.data
      });
    }

    this.fillCode("QualityChecklistSublistphase");
    this.fillCode("QualityChecklistSublistTitle");
    this.fillCode("QualityChecklistSublistOptions");
    this.fillCode("QualityChecklistSublistColumns");
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      qualityChecklistId: this.props.selectedQualityChecklist.id,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    var options = [];
    if (Boolean(row) && Boolean(row.options) && row.options.length > 0) {
      row.options.forEach(element => {
        options.push(element.codeId);
      });
    }

    var columns = [];
    if (Boolean(row) && Boolean(row.columns) && row.columns.length > 0) {
      row.columns.forEach(element => {
        columns.push(element.codeId);
      });
    }

    let _fileList = [];
    if (Boolean(row.answerType) && row.answerType === "Imagepicker") {
      if (Boolean(row.imageOptions)) {
        row.imageOptions.forEach((element, index) => {
          if (Boolean(element.imageUrl)) {
            let obj = {
              url: element.imageUrl,
              uid: index,
              imagePath: element.imageUrl,
              id: index,
              name: element.imageUrl.substring(element.imageUrl.lastIndexOf("/") + 1)
            };
            _fileList.push(obj);
          }
        });
      }
    }

    var i = dataList.find(p => p.key === row.inspectionTypeId);
    let inspectionTypeId = [];
    if (Boolean(i)) {
      inspectionTypeId = i.lineage;
    }

    this.formRef.current.setFieldsValue({
      ...row,
      inspectionTypeId: inspectionTypeId,
      options: options,
      columns: columns
    });

    this.fillStandardId(row.inspectionTypeId);
    this.getQualityChecklistType(row.inspectionTypeId);

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      inspectionTypeId: Boolean(inspectionTypeId) && inspectionTypeId.length > 0 ? inspectionTypeId[inspectionTypeId.length - 1] : null,
      answerType: row.answerType,
      fileList: _fileList,
      type: row.inspectionReference
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      answerType: null,
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    console.log("AAA", values);
    var options = [];
    if (Boolean(values.options) && values.options.length > 0) {
      values.options.forEach(element => {
        options.push({
          ["codeId"]: element
        });
      });
    }

    var columns = [];
    if (Boolean(values.columns) && values.columns.length > 0) {
      values.columns.forEach(element => {
        columns.push({
          ["codeId"]: element
        });
      });
    }

    var _imageOptions = [];
    if (Boolean(this.state.fileList))
      this.state.fileList.forEach(element => {
        let url = null;
        if (Boolean(element.url)) {
          url = element.url;
        } else if (Boolean(element.response) && Boolean(element.response.url)) {
          url = element.response.url;
        }
        if (Boolean(url)) {
          _imageOptions.push({
            ["imageUrl"]: url
          });
        }
      });

    const newItem = {
      ...values,
      id: this.state.id,
      qualityChecklistId: this.props.selectedQualityChecklist.id,
      options: options,
      columns: columns,
      imageOptions: _imageOptions
    };
    newItem.inspectionTypeId =
      Boolean(values.inspectionTypeId) && values.inspectionTypeId.length > 0 ? values.inspectionTypeId[values.inspectionTypeId.length - 1] : null;

    console.log("newItem", newItem);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  handleFileChange = ({ fileList }) => this.setState({ fileList });

  renderElement = () => {
    let item = this.formRef.current.getFieldsValue();
    console.log("item", item);

    let options = this.state.QualityChecklistSublistOptionsSelectItems;

    let newOpts = [];
    if (Boolean(item.options) && item.options.length > 0) {
      item.options.forEach(opt => {
        let option = options.find(p => p.id === opt);
        console.log("option", option);

        if (Boolean(option)) {
          let newOpt = {
            id: opt,
            name: option.name
          };
          newOpts.push(newOpt);
        }
      });
    }
    item.newOpts = newOpts;

    console.log("item", item);

    return (
      Boolean(item) && (
        <>
          <div style={{ backgroundColor: "white", padding: "10px", border: "1px solid #1ab394" }}>
            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
              <FormBoot.Label style={{ color: "black" }} column sm="9">
                {item.question}
              </FormBoot.Label>

              <ColBoot sm="2">
                <div style={{ float: "right" }}>
                  {item.linkedQuestion && <AntButton style={{ marginRight: "10px" }} type="dashed" shape="circle" icon={<LinkOutlined />} />}
                  <AntButton style={{ marginRight: "10px" }} type="dashed" onClick={() => this.edit(item)} shape="circle" icon={<EditOutlined />} />
                  <AntButton type="dashed" onClick={() => this.deleteModal(item)} shape="circle" icon={<CloseOutlined />} />
                </div>
              </ColBoot>
            </FormBoot.Group>
            {Boolean(item.answerType) && item.answerType === "Comment" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot xs="9">
                  <FormBoot.Control as="textarea" rows="3" />
                </ColBoot>
                <ColBoot xs="3">
                  <Form.Item
                    // label={<FormattedMessage id="QualityChecklistSublist.phase" defaultMessage="Phase" />}
                    name="evaluationId"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear showSearch style={{ width: "50%" }} optionFilterProp="children">
                      {Boolean(this.state["evaluationIdSelectItems"]) &&
                        this.state["evaluationIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                </ColBoot>
              </FormBoot.Group>
            )}

            {Boolean(item.answerType) && item.answerType === "Radiogroup" && (
              <>
                {Boolean(item.newOpts) &&
                  item.newOpts.length > 0 &&
                  item.newOpts.map((opt, j) => (
                    <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                      <ColBoot xs="8">
                        <Radio checked={false}>{opt.name}</Radio>
                      </ColBoot>
                      <ColBoot xs="3">                  <Form.Item
                    // label={<FormattedMessage id="QualityChecklistSublist.phase" defaultMessage="Phase" />}
                    name="evaluationId"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear showSearch style={{ width: "50%" }} optionFilterProp="children">
                      {Boolean(this.state["evaluationIdSelectItems"]) &&
                        this.state["evaluationIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  </ColBoot>
                    </FormBoot.Group>
                  ))}
              </>
            )}

            {Boolean(item.answerType) && item.answerType === "Imagepicker" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot xs="9">
                  {Boolean(item.imageOptions) &&
                    item.imageOptions.length > 0 &&
                    item.imageOptions.map((opt, j) => (
                      <>
                        <RadioGroup>
                          <Radio checked={false}>
                            <img alt="option" style={{ width: "120px", height: "120px" }} src={opt.imageUrl} />
                          </Radio>
                        </RadioGroup>
                      </>
                    ))}
                </ColBoot>
                <ColBoot xs="3">
                <Form.Item
                    // label={<FormattedMessage id="QualityChecklistSublist.phase" defaultMessage="Phase" />}
                    name="evaluationId"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear showSearch style={{ width: "50%" }} optionFilterProp="children">
                      {Boolean(this.state["evaluationIdSelectItems"]) &&
                        this.state["evaluationIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  </ColBoot>
              </FormBoot.Group>
            )}

            {Boolean(item.answerType) && item.answerType === "Rating" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot xs="9">
                  <Radio.Group buttonStyle="solid">
                    {this.getRatingList(item.rateMax).map((element, index) => (
                      <Radio.Button value={element}>{element}</Radio.Button>
                    ))}
                  </Radio.Group>
                </ColBoot>
                <ColBoot xs="3">
                <Form.Item
                    // label={<FormattedMessage id="QualityChecklistSublist.phase" defaultMessage="Phase" />}
                    name="evaluationId"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear showSearch style={{ width: "50%" }} optionFilterProp="children">
                      {Boolean(this.state["evaluationIdSelectItems"]) &&
                        this.state["evaluationIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                </ColBoot>
              </FormBoot.Group>
            )}

            {Boolean(item.answerType) && item.answerType === "Boolean" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot xs="12">
                  <Switch checkedChildren={item.labelTrue} unCheckedChildren={item.labelFalse} defaultChecked />
                </ColBoot>
                <ColBoot xs="3">
                <Form.Item
                    // label={<FormattedMessage id="QualityChecklistSublist.phase" defaultMessage="Phase" />}
                    name="evaluationId"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear showSearch style={{ width: "50%" }} optionFilterProp="children">
                      {Boolean(this.state["evaluationIdSelectItems"]) &&
                        this.state["evaluationIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  </ColBoot>
              </FormBoot.Group>
            )}

            {Boolean(item.answerType) && item.answerType === "Checkbox" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                {Boolean(item.newOpts) &&
                  item.newOpts.length > 0 &&
                  item.newOpts.map((opt, j) => (
                    <>
                      <ColBoot style={{ marginBottom: "1rem" }} xs="11">
                        <Checkbox> {opt.name}</Checkbox>
                      </ColBoot>{" "}
                      <ColBoot xs="3">
                      <Form.Item
                    name="evaluationId"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear showSearch style={{ width: "50%" }} optionFilterProp="children">
                      {Boolean(this.state["evaluationIdSelectItems"]) &&
                        this.state["evaluationIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                      </ColBoot>
                      <br />
                    </>
                  ))}
              </FormBoot.Group>
            )}

            {Boolean(item.answerType) && item.answerType === "Dropdown" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot xs="4">
                  <FormBoot.Control name="type" as="select">
                    <option value="Select">Select Option</option>
                    {Boolean(item.newOpts) &&
                      item.newOpts.length > 0 &&
                      item.newOpts.map((opt, j) => <option value={opt.name}>{opt.name}</option>)}
                  </FormBoot.Control>
                </ColBoot>
                <ColBoot xs="3">
                <Form.Item
                    // label={<FormattedMessage id="QualityChecklistSublist.phase" defaultMessage="Phase" />}
                    name="evaluationId"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear showSearch style={{ width: "50%" }} optionFilterProp="children">
                      {Boolean(this.state["evaluationIdSelectItems"]) &&
                        this.state["evaluationIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  </ColBoot>
              </FormBoot.Group>
            )}

            {Boolean(item.answerType) && item.answerType === "File" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot xs="4">
                  <Upload>
                    <AntButton>
                      <UploadOutlined /> Click to Upload
                    </AntButton>
                  </Upload>
                </ColBoot>
                <ColBoot xs="3">
                <Form.Item
                    // label={<FormattedMessage id="QualityChecklistSublist.phase" defaultMessage="Phase" />}
                    name="evaluationId"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear showSearch style={{ width: "50%" }} optionFilterProp="children">
                      {Boolean(this.state["evaluationIdSelectItems"]) &&
                        this.state["evaluationIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                </ColBoot>
              </FormBoot.Group>
            )}

            {Boolean(item.answerType) && item.answerType === "Matrix_Single" && Boolean(item.columns) && item.columns.length > 0 && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot> </ColBoot>
                {item.columns.map((col, j) => (
                  <ColBoot>{col.codeName}</ColBoot>
                ))}
              </FormBoot.Group>
            )}

            {Boolean(item.answerType) && item.answerType === "Matrix_Multiple" && (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot> </ColBoot>
                {item.columns.map((col, j) => (
                  <ColBoot>{col.codeName}</ColBoot>
                ))}
              </FormBoot.Group>
            )}

            {Boolean(item.answerType) &&
              item.answerType === "Matrix_Single" &&
              Boolean(item.columns) &&
              item.columns.length > 0 &&
              Boolean(item.matrixValues) &&
              item.matrixValues.length > 0 &&
              item.matrixValues.map((row, k) => (
                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <ColBoot>{row.title}</ColBoot>
                  {item.columns.map((col, j) => (
                    <ColBoot>
                      <Radio checked={false}></Radio>
                    </ColBoot>
                  ))}
                </FormBoot.Group>
              ))}

            {Boolean(item.answerType) &&
              item.answerType === "Matrix_Multiple" &&
              Boolean(item.matrixValues) &&
              item.matrixValues &&
              item.matrixValues.map((row, k) => (
                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <ColBoot> {row.title} </ColBoot>
                  {item.columns.map((col, j) => (
                    <ColBoot>
                      <FormBoot.Control name="type" as="select">
                        <option value="Select">Select Option</option>
                        {Boolean(item.options) &&
                          item.options.length > 0 &&
                          item.options.map((opt, j) => <option value={opt.codeId}>{opt.codeName}</option>)}
                      </FormBoot.Control>
                    </ColBoot>
                  ))}
                </FormBoot.Group>
              ))}
          </div>
          <br />
        </>
      )
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="QualityChecklistSublist.clauseNo" defaultMessage="Clause No" />,
        key: "clauseNo",
        render: record => {
          return record.clauseNo;
        }
      },
      {
        title: <FormattedMessage id="QualityChecklistSublist.phase" defaultMessage="Phase" />,
        key: "phase",
        render: record => {
          return record.phaseName;
        },
        ...getColumnFilter({
          inputName: "phase",
          inputType: "SELECT",
          selectItems: Boolean(this.state["phaseSelectItems"]) && this.state["phaseSelectItems"].length > 0 ? this.state["phaseSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="QualityChecklistSublist.InspectionType" defaultMessage="Inspection Type" />,
        key: "inspectionTypeId",
        ellipsis: true,
        render: record => {
          return record.inspectionTypeName;
        },
        ...getColumnFilter({
          inputName: "inspectionTypeId",
          inputType: "CASCADER",
          selectItems:
            Boolean(this.state["inspectionTypeSelectItems"]) && this.state["inspectionTypeSelectItems"].length > 0
              ? this.state["inspectionTypeSelectItems"]
              : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="QualityChecklistSublist.ApplicableItems" defaultMessage="Applicable Items" />,
        key: "itemId",
        ellipsis: true,
        render: record => {
          return record.itemName;
        },
        ...getColumnFilter({
          inputName: "itemId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["itemSelectItems"]) && this.state["itemSelectItems"].length > 0 ? this.state["itemSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="QualityChecklistSublist.inspectionActivityId" defaultMessage="Inspection Activity" />,
        key: "inspectionActivityId",
        ellipsis: true,
        render: record => {
          return record.inspectionActivityName;
        },
        ...getColumnFilter({
          inputName: "inspectionActivityId",
          inputType: "SELECT",
          selectItems: [],
          selectItems:
            Boolean(this.state["inspectionActivityIdSelectItems"]) && this.state["inspectionActivityIdSelectItems"].length > 0
              ? this.state["inspectionActivityIdSelectItems"]
              : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="QualityChecklistSublist.Standard" defaultMessage="Standard" />,
        key: "standardId",
        render: record => {
          return record.standardName;
        },
        ...getColumnFilter({
          inputName: "standardId",
          inputType: "SELECT",
          selectItems:
            Boolean(this.state["standardIdSelectItems"]) && this.state["standardIdSelectItems"].length > 0 ? this.state["standardIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="QualityChecklistSublist.reference" defaultMessage="Reference" />,
        key: "reference",
        render: record => {
          return record.reference;
        },
        ...getColumnFilter({
          inputName: "reference",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="QualityChecklistSublist.acceptanceCriteria" defaultMessage="Acceptance Criteria" />,
        key: "acceptanceCriteria",
        render: record => {
          return record.acceptanceCriteria;
        },
        ...getColumnFilter({
          inputName: "acceptanceCriteria",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="QualityChecklistSublist.record" defaultMessage="Record" />,
        key: "record",
        render: record => {
          return record.record;
        },
        ...getColumnFilter({
          inputName: "record",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="QualityChecklistSublist.MeasuringDevice" defaultMessage="Measuring Device" />,
        key: "measuringDeviceId",
        render: record => {
          return record.measuringDeviceName;
        },
        ...getColumnFilter({
          inputName: "measuringDeviceId",
          inputType: "SELECT",
          selectItems:
            Boolean(this.state["measuringDeviceIdSelectItems"]) && this.state["measuringDeviceIdSelectItems"].length > 0
              ? this.state["measuringDeviceIdSelectItems"]
              : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="QualityChecklistSublist.DetailOfInspection" defaultMessage="Detail of Inspection" />,
        key: "detailOfInspection",
        ellipsis: true,
        render: record => {
          return record.detailOfInspection;
        },
        ...getColumnFilter({
          inputName: "detailOfInspection",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="QualityChecklistSublist.InspectionReference" defaultMessage="Inspection Reference" />,
        key: "inspectionReference",
        render: record => {
          return record.inspectionReferenceName;
        }
      }
    ];
    const excelExport = (
      <>
        {/* {" "}
        {this.state.exportClick ? this.setState({ exportClick: false }) : ""}{" "}
        {this.state.exportClick ? (
          <ExcelFile filename="QualityChecklistSublist" hideElement={true}>
            {" "}
            <ExcelSheet name="QualityChecklistSublist" data={this.state.tableList}>
              {" "}
              <ExcelColumn label="Clause No" value="clauseNo" />
              <ExcelColumn label="Phase" value="phase" />
              <ExcelColumn label="inspectionTypeName" value="Inspection Type" />
              <ExcelColumn label="applicableItems" value="Applicable Items" />
              <ExcelColumn label="Inspection Activity" value="inspectionActivityId" />
              <ExcelColumn label="Standard" value="standard" />
              <ExcelColumn label="Reference" value="reference" />
              <ExcelColumn label="Acceptance Criteria" value="acceptanceCriteria" />
              <ExcelColumn label="Record" value="record" />
              <ExcelColumn label="Measuring Device" value="measuringDevice" />
            </ExcelSheet>{" "}
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )}{" "} */}
      </>
    );
    return (
      <Badge.Ribbon
        text={<FormattedMessage id="qualityChecklistSublist.pageTitle" defaultMessage="Checklist Detail" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              {" "}
              <Col md={21}></Col> <Col md={1}>{excelExport}</Col>{" "}
              <Col md={1}>
                {" "}
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>{" "}
              </Col>{" "}
              <Col md={1}>
                {" "}
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>{" "}
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>{" "}
              </Col>{" "}
            </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="QualityChecklistSublist.InspectionReference" defaultMessage="Inspection Reference" />}
                  name="inspectionReference"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Radio.Group
                    onChange={e => {
                      this.setState({
                        type: e.target.value
                      });
                    }}
                  >
                    <Radio value={"ReferenceOfStandard"}>
                      <FormattedMessage id="QualityChecklistSublist.ReferenceOfStandard" defaultMessage="Reference of Standard" />
                    </Radio>
                    <Radio value={"CreateManually"}>
                      <FormattedMessage id="QualityChecklistSublist.CreateManually" defaultMessage="Create Manually" />
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="QualityChecklistSublist.phase" defaultMessage="Phase" />}
                  name="phase"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["phaseSelectItems"]) &&
                      this.state["phaseSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              <Form.Item
                {...layout}
                label={<FormattedMessage id="QualityChecklistSublist.InspectionType" defaultMessage="Inspection Type" />}
                name="inspectionTypeId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Cascader
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  onChange={value => {
                    this.setState(
                      {
                        inspectionTypeId: Boolean(value) && value.length > 0 ? value[value.length - 1] : null
                      },
                      () => {
                        this.formRef.current.setFieldsValue({
                          standardId: null
                        });
                        this.fillStandardId(this.state.inspectionTypeId);
                        this.getQualityChecklistType(this.state.inspectionTypeId);
                      }
                    );
                  }}
                  options={this.state.inspectionTypeSelectItems}
                />
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="QualityChecklistSublist.Item" defaultMessage="Item" />}
                name="itemId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["itemSelectItems"]) &&
                    this.state["itemSelectItems"].length > 0 &&
                    this.state["itemSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="QualityChecklistSublist.inspectionActivityId" defaultMessage="Inspection Activity" />}
                  name="inspectionActivityId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["inspectionActivityIdSelectItems"]) &&
                      this.state["inspectionActivityIdSelectItems"].length > 0 &&
                      this.state["inspectionActivityIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              <Form.Item
                {...layout}
                label={<FormattedMessage id="QualityChecklistSublist.Standard" defaultMessage="Standard" />}
                name="standardId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["standardIdSelectItems"]) &&
                    this.state["standardIdSelectItems"].length > 0 &&
                    this.state["standardIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="QualityChecklistSublist.DetailOfInspection" defaultMessage="Detail of Inspection" />}
                name="detailOfInspection"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>

              {Boolean(this.state.type) && this.state.type === "CreateManually" && (
                <>
                  {Boolean(this.state.addItem) && this.state.addItem && (
                    <>
                      <br />{" "}
                      <h4>
                        <FormattedMessage id={"QualityChecklistSublist.AddItem"} defaultMessage={"Add Question"} />
                      </h4>
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="QualityChecklistSublist.Question" defaultMessage="Question" />}
                        name="question"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="QualityChecklistSublist.Reference" defaultMessage="Reference" />}
                        name="reference"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="QualityChecklistSublist.CreateLink" defaultMessage="Create Link" />}
                        name="createLink"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        valuePropName="checked"
                      >
                        <Checkbox></Checkbox>
                      </Form.Item>
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="QualityChecklistSublist.answerType" defaultMessage="Answer Type" />}
                        name="answerType"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select
                          allowClear
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Answer Type"
                          onChange={value => {
                            this.setState({ answerType: value });
                          }}
                        >
                          {Boolean(this.state["answerTypeSelectItems"]) &&
                            this.state["answerTypeSelectItems"].length > 0 &&
                            this.state["answerTypeSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      {Boolean(this.state.answerType) && (this.state.c === "Matrix_Single" || this.state.answerType === "Matrix_Multiple") && (
                        <Form.List name="matrixValues">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map((field, index) => (
                                <Form.Item
                                  {...field}
                                  {...layout}
                                  label={index + 1}
                                  rules={[
                                    {
                                      required: false,
                                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                    }
                                  ]}
                                >
                                  <Form.Item
                                    {...field}
                                    style={{
                                      display: "inline-block",
                                      width: "calc(80%)"
                                    }}
                                    name={[field.name, "title"]}
                                  >
                                    <Input></Input>
                                  </Form.Item>

                                  <Form.Item {...field} hidden name={[field.name, "id"]}>
                                    <InputNumber></InputNumber>
                                  </Form.Item>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </Form.Item>
                              ))}
                              <Form.Item>
                                <Row gutter={[16, 16]}>
                                  <Col md={8}></Col>
                                  <Col md={6}>
                                    <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}></AntButton>
                                  </Col>
                                </Row>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      )}
                      {Boolean(this.state.answerType) && (this.state.answerType === "Matrix_Single" || this.state.answerType === "Matrix_Multiple") && (
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="QualityChecklistSublist.columns" defaultMessage="Columns" />}
                          name="columns"
                          rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select
                            mode="tags"
                            style={{ width: "100%" }}
                            placeholder="columns"
                            value={this.state.selectedColumns}
                            onChange={value => {
                              this.setState({ selectedColumns: value });
                            }}
                            dropdownRender={menu => (
                              <div>
                                {menu}
                                <Divider style={{ margin: "4px 0" }} />
                                <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                  <Input
                                    style={{ flex: "auto" }}
                                    value={this.state.codeName}
                                    onChange={event => {
                                      this.setState({
                                        codeName: event.target.value
                                      });
                                    }}
                                  />
                                  <a
                                    style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                    onClick={() => this.addCode("QualityChecklistSublistColumns")}
                                  >
                                    <PlusOutlined /> Add item
                                  </a>
                                </div>
                              </div>
                            )}
                          >
                            {Boolean(this.state.QualityChecklistSublistColumnsSelectItems) &&
                              this.state.QualityChecklistSublistColumnsSelectItems.length > 0 &&
                              this.state.QualityChecklistSublistColumnsSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>
                                  {i.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      )}
                      {Boolean(this.state.answerType) &&
                        (this.state.answerType === "Checkbox" ||
                          this.state.answerType === "Radiogroup" ||
                          this.state.answerType === "Dropdown" ||
                          this.state.answerType === "Matrix_Multiple") && (
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="QualityChecklistSublist.options" defaultMessage="Options" />}
                            name="options"
                            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Select
                              mode="tags"
                              style={{ width: "100%" }}
                              placeholder="Options"
                              value={this.state.selectedOptions}
                              onChange={value => {
                                this.setState({ selectedOptions: value });
                              }}
                              dropdownRender={menu => (
                                <div>
                                  {menu}
                                  <Divider style={{ margin: "4px 0" }} />
                                  <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                                    <Input
                                      style={{ flex: "auto" }}
                                      value={this.state.codeName}
                                      onChange={event => {
                                        this.setState({
                                          codeName: event.target.value
                                        });
                                      }}
                                    />
                                    <a
                                      style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                                      onClick={() => this.addCode("QualityChecklistSublistOptions")}
                                    >
                                      <PlusOutlined /> Add item
                                    </a>
                                  </div>
                                </div>
                              )}
                            >
                              {Boolean(this.state.QualityChecklistSublistOptionsSelectItems) &&
                                this.state.QualityChecklistSublistOptionsSelectItems.length > 0 &&
                                this.state.QualityChecklistSublistOptionsSelectItems.map(i => (
                                  <Option key={i.id} value={i.id}>
                                    {i.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        )}
                      {Boolean(this.state.answerType) && this.state.answerType === "Rating" && (
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="QualityChecklistSublist.rateMax" defaultMessage="Max Rate" />}
                          name="rateMax"
                          rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <InputNumber />
                        </Form.Item>
                      )}
                      {Boolean(this.state.answerType) && this.state.answerType === "Imagepicker" && (
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="QualityChecklistSublist.imagepicker" defaultMessage="Choose Images" />}
                          rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Upload {...props} onChange={this.handleFileChange}>
                            <AntButton>
                              <UploadOutlined /> Upload
                            </AntButton>
                          </Upload>
                        </Form.Item>
                      )}
                      {Boolean(this.state.answerType) && this.state.answerType === "Boolean" && (
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="QualityChecklistSublist.boolean" defaultMessage="First" />}
                          style={{ marginBottom: "5px" }}
                        >
                          <Row gutter={8}>
                            <Col span={10}>
                              <Form.Item name="labelTrue">
                                <Input
                                  style={{
                                    width: "100%"
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>Last</Col>
                            <Col span={10}>
                              <Form.Item name="labelFalse">
                                <Input
                                  style={{
                                    width: "100%"
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                      )}
                      {
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="QualityChecklistSublist.CreateLink" defaultMessage="Evaluation" />}
                          name="evaluation"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          valuePropName="checked"
                        >
                          <Checkbox
                            onChange={e => {
                              this.setState({
                                evaluation: e.target.checked
                              });
                            }}
                          ></Checkbox>
                        </Form.Item>
                      }
                    </>
                  )}

                  {Boolean(this.state.evaluation) && this.state.evaluation ? this.renderElement() : ""}

                  {Boolean(this.state.chemical) && this.state.chemical && (
                    <>
                      <br />{" "}
                      <h4>
                        <FormattedMessage id={"QualityChecklistSublist.ChemicalComposition"} defaultMessage={"Chemical Composition"} />
                      </h4>
                      <Form.List name="checklistChemicalComposition">
                        {(fields, { add, remove }) => {
                          return (
                            <>
                              <TableBoot bordered size="sm">
                                <thead>
                                  <tr>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.Element"} defaultMessage={"Element"} />
                                    </th>
                                    <th colSpan="2" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.Thickness"} defaultMessage={"Thickness"} />
                                    </th>
                                    <th colSpan="2" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.Content"} defaultMessage={"Content"} />
                                    </th>
                                    <th colSpan="1"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "elementId"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["elementIdSelectItems"]) &&
                                                this.state["elementIdSelectItems"].length > 0 &&
                                                this.state["elementIdSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thicknessType"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness1TypeSelectItems"]) &&
                                                this.state["thickness1TypeSelectItems"].length > 0 &&
                                                this.state["thickness1TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "content"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "contentType"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["contentTypeSelectItems"]) &&
                                                this.state["contentTypeSelectItems"].length > 0 &&
                                                this.state["contentTypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </td>
                                      </tr>
                                    );
                                  })}

                                  <tr>
                                    <td colSpan={11}>
                                      <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        <FormattedMessage
                                          id={"QualityChecklistSublist.AddNewChemicalComposition"}
                                          defaultMessage={"Add New Chemical Composition"}
                                        />
                                      </AntButton>
                                    </td>
                                  </tr>
                                </tbody>
                              </TableBoot>
                            </>
                          );
                        }}
                      </Form.List>
                    </>
                  )}
                  {Boolean(this.state.cev) && this.state.cev && (
                    <>
                      <h4>
                        {" "}
                        <FormattedMessage id={"QualityChecklistSublist.CEV"} defaultMessage={"CEV"} />{" "}
                      </h4>
                      <Form.List name="checklistCEV">
                        {(fields, { add, remove }) => {
                          return (
                            <>
                              <TableBoot bordered size="sm">
                                <thead>
                                  <tr>
                                    <th colSpan="5" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.NominalThicknessMm"} defaultMessage={"Nominal Thickness, mm"} />
                                    </th>
                                    <th colSpan="2" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.CEVPercent"} defaultMessage={"CEV%"} />
                                    </th>
                                    <th colSpan="1"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness1"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness1Type"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness1TypeSelectItems"]) &&
                                                this.state["thickness1TypeSelectItems"].length > 0 &&
                                                this.state["thickness1TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <FormattedMessage id={"QualityChecklistSublist.To"} defaultMessage={"to"} />
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness2"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness2Type"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness2TypeSelectItems"]) &&
                                                this.state["thickness2TypeSelectItems"].length > 0 &&
                                                this.state["thickness2TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "yield1"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "yieldTypeId"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["tensile1TypeIdSelectItems"]) &&
                                                this.state["thickness1TypeSelectItems"].length > 0 &&
                                                this.state["tensile1TypeIdSelectItems"].map(i => (
                                                  <Option key={i.id} value={i.id}>
                                                    {i.abbreviation}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </td>
                                      </tr>
                                    );
                                  })}

                                  <tr>
                                    <td colSpan={11}>
                                      <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        <FormattedMessage id={"QualityChecklistSublist.AddNewCEV"} defaultMessage={"Add New CEV"} />
                                      </AntButton>
                                    </td>
                                  </tr>
                                </tbody>
                              </TableBoot>
                            </>
                          );
                        }}
                      </Form.List>
                      <br />{" "}
                    </>
                  )}
                  {Boolean(this.state.mechanical) && this.state.mechanical && (
                    <>
                      <br />{" "}
                      <h4>
                        <FormattedMessage id={"QualityChecklistSublist.TensileTest"} defaultMessage={"Tensile Test"} />
                      </h4>
                      <Form.List name="checklistTensileTest">
                        {(fields, { add, remove }) => {
                          return (
                            <>
                              <TableBoot bordered size="sm">
                                <thead>
                                  <tr>
                                    <th colSpan="5" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.NominalThicknessMm"} defaultMessage={"Nominal Thickness, mm"} />
                                    </th>
                                    <th colSpan="5" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.TensileStrength"} defaultMessage={"Tensile Strength"} />
                                    </th>
                                    <th colSpan="1"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness1"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness1Type"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness1TypeSelectItems"]) &&
                                                this.state["thickness1TypeSelectItems"].length > 0 &&
                                                this.state["thickness1TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <FormattedMessage id={"QualityChecklistSublist.To"} defaultMessage={"to"} />
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness2"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness2Type"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness2TypeSelectItems"]) &&
                                                this.state["thickness2TypeSelectItems"].length > 0 &&
                                                this.state["thickness2TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "tensile1"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "tensile1TypeId"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["tensile1TypeIdSelectItems"]) &&
                                                this.state["thickness1TypeSelectItems"].length > 0 &&
                                                this.state["tensile1TypeIdSelectItems"].map(i => (
                                                  <Option key={i.id} value={i.id}>
                                                    {i.abbreviation}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <FormattedMessage id={"QualityChecklistSublist.To"} defaultMessage={"to"} />
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "tensile2"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "tensile2TypeId"]}>
                                            <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["tensile2TypeIdSelectItems"]) &&
                                                this.state["tensile2TypeIdSelectItems"].length > 0 &&
                                                this.state["tensile2TypeIdSelectItems"].map(i => (
                                                  <Option key={i.id} value={i.id}>
                                                    {i.abbreviation}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </td>
                                      </tr>
                                    );
                                  })}

                                  <tr>
                                    <td colSpan={11}>
                                      <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        <FormattedMessage id={"QualityChecklistSublist.AddNewTensileTest"} defaultMessage={"Add New Tensile Test"} />
                                      </AntButton>
                                    </td>
                                  </tr>
                                </tbody>
                              </TableBoot>
                            </>
                          );
                        }}
                      </Form.List>
                      <br />{" "}
                      <h4>
                        {" "}
                        <FormattedMessage id={"QualityChecklistSublist.YieldTest"} defaultMessage={"Yield Test"} />{" "}
                      </h4>
                      <Form.List name="checklistYieldTest">
                        {(fields, { add, remove }) => {
                          return (
                            <>
                              <TableBoot bordered size="sm">
                                <thead>
                                  <tr>
                                    <th colSpan="5" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.NominalThicknessMm"} defaultMessage={"Nominal Thickness, mm"} />
                                    </th>
                                    <th colSpan="2" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.MinYieldStrength"} defaultMessage={"Min. Yield Strength"} />
                                    </th>
                                    <th colSpan="1"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness1"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness1Type"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness1TypeSelectItems"]) &&
                                                this.state["thickness1TypeSelectItems"].length > 0 &&
                                                this.state["thickness1TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <FormattedMessage id={"QualityChecklistSublist.To"} defaultMessage={"to"} />
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness2"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness2Type"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness2TypeSelectItems"]) &&
                                                this.state["thickness2TypeSelectItems"].length > 0 &&
                                                this.state["thickness2TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "yield1"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "yieldTypeId"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["tensile1TypeIdSelectItems"]) &&
                                                this.state["thickness1TypeSelectItems"].length > 0 &&
                                                this.state["tensile1TypeIdSelectItems"].map(i => (
                                                  <Option key={i.id} value={i.id}>
                                                    {i.abbreviation}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </td>
                                      </tr>
                                    );
                                  })}

                                  <tr>
                                    <td colSpan={11}>
                                      <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        <FormattedMessage id={"QualityChecklistSublist.AddNewYieldTest"} defaultMessage={"Add New Yield Test"} />
                                      </AntButton>
                                    </td>
                                  </tr>
                                </tbody>
                              </TableBoot>
                            </>
                          );
                        }}
                      </Form.List>
                      <br />{" "}
                      <h4>
                        <FormattedMessage
                          id={"QualityChecklistSublist.ElongationLo80MmlOrientation"}
                          defaultMessage={"Elongation, Lo= 80 mm, l orientation"}
                        />
                      </h4>
                      <Form.List name="checklistElongation80L">
                        {(fields, { add, remove }) => {
                          return (
                            <>
                              <TableBoot bordered size="sm">
                                <thead>
                                  <tr>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage
                                        id={"QualityChecklistSublist.OrientationOfTestPieece"}
                                        defaultMessage={"Orientation of test pieece"}
                                      />
                                    </th>
                                    <th colSpan="5" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.NominalThicknessMm"} defaultMessage={"Nominal Thickness, mm"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.MinElongation"} defaultMessage={"Min. %Elongation"} />
                                    </th>
                                    <th colSpan="1"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "orientation"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["orientationSelectItems"]) &&
                                                this.state["orientationSelectItems"].length > 0 &&
                                                this.state["orientationSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness1"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness1Type"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness1TypeSelectItems"]) &&
                                                this.state["thickness1TypeSelectItems"].length > 0 &&
                                                this.state["thickness1TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <FormattedMessage id={"QualityChecklistSublist.To"} defaultMessage={"to"} />
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness2"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness2Type"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness2TypeSelectItems"]) &&
                                                this.state["thickness2TypeSelectItems"].length > 0 &&
                                                this.state["thickness2TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "minElongation"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </td>
                                      </tr>
                                    );
                                  })}

                                  <tr>
                                    <td colSpan={11}>
                                      <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        <FormattedMessage
                                          id={"QualityChecklistSublist.AddNewElongationLo80MmlOrientation"}
                                          defaultMessage={"Add New Elongation, Lo= 80 mm, l orientation"}
                                        />
                                      </AntButton>
                                    </td>
                                  </tr>
                                </tbody>
                              </TableBoot>
                            </>
                          );
                        }}
                      </Form.List>
                      <br />{" "}
                      <h4>
                        <FormattedMessage
                          id={"QualityChecklistSublist.ElongationLo80MmtOrientation"}
                          defaultMessage={"Elongation, Lo= 80 mm, t orientation"}
                        />
                      </h4>
                      <Form.List name="checklistElongation80T">
                        {(fields, { add, remove }) => {
                          return (
                            <>
                              <TableBoot bordered size="sm">
                                <thead>
                                  <tr>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage
                                        id={"QualityChecklistSublist.OrientationOfTestPieece"}
                                        defaultMessage={"Orientation of test pieece"}
                                      />
                                    </th>
                                    <th colSpan="5" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.NominalThicknessMm"} defaultMessage={"Nominal Thickness, mm"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.MinElongation"} defaultMessage={"Min. %Elongation"} />
                                    </th>
                                    <th colSpan="1"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "orientation"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["orientationSelectItems"]) &&
                                                this.state["orientationSelectItems"].length > 0 &&
                                                this.state["orientationSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness1"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness1Type"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness1TypeSelectItems"]) &&
                                                this.state["thickness1TypeSelectItems"].length > 0 &&
                                                this.state["thickness1TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <FormattedMessage id={"QualityChecklistSublist.To"} defaultMessage={"to"} />
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness2"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness2Type"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness2TypeSelectItems"]) &&
                                                this.state["thickness2TypeSelectItems"].length > 0 &&
                                                this.state["thickness2TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "minElongation"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </td>
                                      </tr>
                                    );
                                  })}

                                  <tr>
                                    <td colSpan={11}>
                                      <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        <FormattedMessage
                                          id={"QualityChecklistSublist.AddNewElongationLo80MmtOrientation"}
                                          defaultMessage={"Add New Elongation, Lo= 80 mm, t orientation"}
                                        />
                                      </AntButton>
                                    </td>
                                  </tr>
                                </tbody>
                              </TableBoot>
                            </>
                          );
                        }}
                      </Form.List>
                      <br />{" "}
                      <h4>
                        <FormattedMessage
                          id={"QualityChecklistSublist.ElongationLo80MmtOrientation"}
                          defaultMessage={"Elongation, Lo= 5,65√So, l orientation"}
                        />
                      </h4>
                      <Form.List name="checklist565L">
                        {(fields, { add, remove }) => {
                          return (
                            <>
                              <TableBoot bordered size="sm">
                                <thead>
                                  <tr>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage
                                        id={"QualityChecklistSublist.OrientationOfTestPieece"}
                                        defaultMessage={"Orientation of test pieece"}
                                      />
                                    </th>
                                    <th colSpan="5" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.NominalThicknessMm"} defaultMessage={"Nominal Thickness, mm"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.MinElongation"} defaultMessage={"Min. %Elongation"} />
                                    </th>
                                    <th colSpan="1"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "orientation"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["orientationSelectItems"]) &&
                                                this.state["orientationSelectItems"].length > 0 &&
                                                this.state["orientationSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness1"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness1Type"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness1TypeSelectItems"]) &&
                                                this.state["thickness1TypeSelectItems"].length > 0 &&
                                                this.state["thickness1TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <FormattedMessage id={"QualityChecklistSublist.To"} defaultMessage={"to"} />
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness2"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness2Type"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness2TypeSelectItems"]) &&
                                                this.state["thickness2TypeSelectItems"].length > 0 &&
                                                this.state["thickness2TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "minElongation"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </td>
                                      </tr>
                                    );
                                  })}

                                  <tr>
                                    <td colSpan={11}>
                                      <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        <FormattedMessage
                                          id={"QualityChecklistSublist.AddNewElongationLo565SolOrientation"}
                                          defaultMessage={"Add New Elongation, Lo= 5,65√So, l orientation"}
                                        />
                                      </AntButton>
                                    </td>
                                  </tr>
                                </tbody>
                              </TableBoot>
                            </>
                          );
                        }}
                      </Form.List>
                      <br />{" "}
                      <h4>
                        <FormattedMessage
                          id={"QualityChecklistSublist.ElongationLo80MmtOrientation"}
                          defaultMessage={"Elongation, Lo= 5,65√So, t orientation"}
                        />
                      </h4>
                      <Form.List name="checklist565T">
                        {(fields, { add, remove }) => {
                          return (
                            <>
                              <TableBoot bordered size="sm">
                                <thead>
                                  <tr>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage
                                        id={"QualityChecklistSublist.OrientationOfTestPieece"}
                                        defaultMessage={"Orientation of test pieece"}
                                      />
                                    </th>
                                    <th colSpan="5" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.NominalThicknessMm"} defaultMessage={"Nominal Thickness, mm"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.MinElongation"} defaultMessage={"Min. %Elongation"} />
                                    </th>
                                    <th colSpan="1"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "orientation"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["orientationSelectItems"]) &&
                                                this.state["orientationSelectItems"].length > 0 &&
                                                this.state["orientationSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness1"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness1Type"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness1TypeSelectItems"]) &&
                                                this.state["thickness1TypeSelectItems"].length > 0 &&
                                                this.state["thickness1TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <FormattedMessage id={"QualityChecklistSublist.To"} defaultMessage={"to"} />
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness2"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness2Type"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness2TypeSelectItems"]) &&
                                                this.state["thickness2TypeSelectItems"].length > 0 &&
                                                this.state["thickness2TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "minElongation"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </td>
                                      </tr>
                                    );
                                  })}

                                  <tr>
                                    <td colSpan={11}>
                                      <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        <FormattedMessage
                                          id={"QualityChecklistSublist.AddNewElongationLo565SotOrientation"}
                                          defaultMessage={"Add New Elongation, Lo= 5,65√So, t orientation"}
                                        />
                                      </AntButton>
                                    </td>
                                  </tr>
                                </tbody>
                              </TableBoot>
                            </>
                          );
                        }}
                      </Form.List>
                      <br />{" "}
                      <h4>
                        <FormattedMessage id={"QualityChecklistSublist.ImpactEnergy"} defaultMessage={"Impact Energy"} />
                      </h4>
                      <Form.List name="checklistImpactEnergy">
                        {(fields, { add, remove }) => {
                          return (
                            <>
                              <TableBoot bordered size="sm">
                                <thead>
                                  <tr>
                                    <th colSpan="2" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.Temperature"} defaultMessage={"Temperature"} />
                                    </th>
                                    <th colSpan="5" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.NominalThicknessMm"} defaultMessage={"Nominal Thickness, mm"} />
                                    </th>
                                    <th colSpan="2" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"QualityChecklistSublist.MinEnergy"} defaultMessage={"Min. Energy"} />
                                    </th>
                                    <th colSpan="1"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "temperature"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "orientation"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["temperatureSelectItems"]) &&
                                                this.state["temperatureSelectItems"].length > 0 &&
                                                this.state["temperatureSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness1"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness1Type"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness1TypeSelectItems"]) &&
                                                this.state["thickness1TypeSelectItems"].length > 0 &&
                                                this.state["thickness1TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <FormattedMessage id={"QualityChecklistSublist.To"} defaultMessage={"to"} />
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness2"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "thickness2Type"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["thickness2TypeSelectItems"]) &&
                                                this.state["thickness2TypeSelectItems"].length > 0 &&
                                                this.state["thickness2TypeSelectItems"].map(i => (
                                                  <Option key={i.key} value={i.key}>
                                                    {i.value}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "minEnergy"]}>
                                            <InputNumber
                                              min={0}
                                              parser={value => value.replace(",", ".")}
                                              formatter={value => value.replace(".", ",")}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "minEnergyTypeId"]}>
                                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                              {Boolean(this.state["tensile1TypeIdSelectItems"]) &&
                                                this.state["thickness1TypeSelectItems"].length > 0 &&
                                                this.state["tensile1TypeIdSelectItems"].map(i => (
                                                  <Option key={i.id} value={i.id}>
                                                    {i.abbreviation}
                                                  </Option>
                                                ))}{" "}
                                            </Select>
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </td>
                                      </tr>
                                    );
                                  })}

                                  <tr>
                                    <td colSpan={11}>
                                      <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        <FormattedMessage
                                          id={"QualityChecklistSublist.AddNewImpactEnergy"}
                                          defaultMessage={"Add New Impact Energy"}
                                        />
                                      </AntButton>
                                    </td>
                                  </tr>
                                </tbody>
                              </TableBoot>
                            </>
                          );
                        }}
                      </Form.List>
                    </>
                  )}
                </>
              )}

              <div hidden={this.state.hideSave}>
                {" "}
                {
                  <Row gutter={[16, 16]}>
                    {" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      {" "}
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        {" "}
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                  </Row>
                }{" "}
              </div>
              <div hidden={this.state.hideUpdate}>
                {" "}
                {
                  <Row gutter={[16, 16]}>
                    {" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      {" "}
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        {" "}
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      {" "}
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        {" "}
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                  </Row>
                }{" "}
              </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              {" "}
              <div className="alert-icon">
                {" "}
                <FilterOutlined />{" "}
              </div>{" "}
              <div className="alert-text">
                {" "}
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      {" "}
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>{" "}
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>{" "}
                      <span>
                        {" "}
                        <Tooltip title="Remove">
                          {" "}
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />{" "}
                        </Tooltip>{" "}
                      </span>{" "}
                    </div>
                  );
                })}{" "}
              </div>{" "}
              <div className="alert-close">
                {" "}
                <Tooltip title="Remove All">
                  {" "}
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />{" "}
                </Tooltip>{" "}
              </div>{" "}
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>
          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>{" "}
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(QualityChecklistSublist);
