import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { error } from "../../MessageHelper";
import {
  Form,
  Table,
  Input,
  Select,
  DatePicker,
  Col,
  Row
} from "antd";
import { handleRequest } from "../../ApiConnector";

const { Option } = Select;

export class LeaveRequestPersonnelLogFind extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      lastUpdateDate: [],
      departmentSelectItems: [],
      designationSelectItems: [],
      controllerName: "personnelLog",

      id: null,
      tableList: [],
      selectedRowKeys: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.setState({
      tableList: [],
      selectedRowKeys: [],
    });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsedepartmentId = await handleRequest("GET", "/api/" + this.state.controllerName + "/departments");
    if (responsedepartmentId.type === "ERROR") {
      error(responsedepartmentId);
    } else {
      this.setState({
        departmentSelectItems: Boolean(responsedepartmentId.data) ? responsedepartmentId.data : []
      });
    }
    var responsedesignationId = await handleRequest("GET", "/api/" + this.state.controllerName + "/designations");
    if (responsedesignationId.type === "ERROR") {
      error(responsedesignationId);
    } else {
      this.setState({
        designationSelectItems: Boolean(responsedesignationId.data) ? responsedesignationId.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      tableList: [],
      selectedRowKeys: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/personnelLogFindPaging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  search = async values => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "",
      personnelName = null,
      identificationNo = null,
      departmentId = null,
      designationId = null;
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
      if (Boolean(this.formRef.current.getFieldValue("personnelName"))) {
        personnelName = this.formRef.current.getFieldValue("personnelName");
      }
      if (Boolean(this.formRef.current.getFieldValue("personidentificationNonelName"))) {
        identificationNo = this.formRef.current.getFieldValue("personidentificationNonelName");
      }
      if (Boolean(this.formRef.current.getFieldValue("departmentId"))) {
        departmentId = this.formRef.current.getFieldValue("departmentId");
      }
      if (Boolean(this.formRef.current.getFieldValue("designationId"))) {
        designationId = this.formRef.current.getFieldValue("designationId");
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
      designationId: designationId,
      departmentId: departmentId,
      personnelName: personnelName,
      identificationNo: identificationNo,
    };
    return newObj;
  }

  onSelectChange = selectedRowKeys => {
    var anotherPersonnelName = "";
    if (Boolean(selectedRowKeys) && selectedRowKeys.length > 0) {
      var data = this.state.tableList.find(p => p.id === selectedRowKeys[0]);
      if (Boolean(data)) {
        anotherPersonnelName = data.name;
      }
    }
    this.setState({ selectedRowKeys });
    this.props.personnelLogFind(selectedRowKeys, anotherPersonnelName)
  };

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        title: 'S/N', key: 'name', render: (value, row, index) => {
          return index + 1 + (this.state.pagination.pageSize * (this.state.pagination.current - 1));
        },
        width: "5%",
      },
      {
        title: 'Name', key: 'name', render: (record) => {
          return record.name;
        }
      },
      {
        title: 'Email', key: 'name', render: (record) => {
          return record.email;
        }
      },
      {
        title: 'Department', key: 'departmentId', render: (record) => {
          return record.departmentName;
        }
      },
      {
        title: 'Designation', key: 'designationId', render: (record) => {
          return record.designationName;
        }
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.search} onFinishFailed={onFinishFailed} ref={this.formRef}>
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="LeaveRequestPersonnelLogFind.departmentId" defaultMessage="Department" />}
              name="departmentId"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select allowClear style={{ width: "100%" }} placeholder="Department">
                {this.state.departmentSelectItems.map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="LeaveRequestPersonnelLogFind.designationId" defaultMessage="Designation" />}
              name="designationId"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select allowClear style={{ width: "100%" }} placeholder="Designation">
                {this.state.designationSelectItems.map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="LeaveRequestPersonnelLogFind.personnelName" defaultMessage="Name" />}
              name="personnelName"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input placeholder="Name" style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="LeaveRequestPersonnelLogFind.identificationNo" defaultMessage="ID" />}
              name="identificationNo"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input placeholder="ID" style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <Button id="ClearButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                  <FormattedMessage id="GeneralButtonClear" defaultMessage="Clear" />
                </Button>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <Button id="SearchButton" type="submit" style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSearch" defaultMessage="Search" />
                </Button>
              </Col>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            </Row>
          }
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            rowKey={record => record.id}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            rowClassName={(record, index) => (Boolean(record.status) && record.status === "NOT_PAID" ? "text-danger" : "")}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LeaveRequestPersonnelLogFind);