import SVG from "react-inlinesvg";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import EldenEquipmentLogSettings from "../elden/equipment/EldenEquipmentLogSettings";
import EldenEquipmentCalibration from "../elden/equipment/EldenEquipmentCalibration";
import EldenEquipmentPerformanceCriteria from "../elden/equipment/EldenEquipmentPerformanceCriteria";
import EldenEquipmentLicence from "../elden/equipment/EldenEquipmentLicence";
import EldenEquipmentInsurance from "../elden/equipment/EldenEquipmentInsurance";
import EldenEquipmentPeriodicControls from "../elden/equipment/EldenEquipmentPeriodicControls";
import EldenEquipmentLegal from "../elden/equipment/EldenEquipmentLegal";
import EldenEquipmentCertificates from "../elden/equipment/EldenEquipmentCertificates";
import EldenEquipmentHardware from "../elden/equipment/EldenEquipmentHardware";
import EldenEquipmentSoftware from "../elden/equipment/EldenEquipmentSoftware";
import EldenEquipmentStatisticals from "../elden/equipment/EldenEquipmentStatisticals";
import MaintenanceLogProcess from "./MaintenanceLogProcess";
import EldenCompoundsSettingsProductTree from "../elden/equipment/EldenCompoundsSettingsProductTree";
import EquipmentInsurance from "./EquipmentInsurance";

export function EquipmentLogIdentificationDrawer({ eldenEquipmentTreeId, equipmentLogId }) {
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  const [selectedTab, setSelectedTab] = React.useState("overall");
  useEffect(() => {
    return () => { };
  }, [user]);

  return (
    <>
      {user && (
        <div className="d-flex flex-row">
          <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
            <div className="card card-custom card-stretch">
              {/* begin::Body */}
              <div className="card-body pt-4">
                {/* begin::Toolbar */}
                <div className="d-flex justify-content-end"></div>
                {/* end::Toolbar */}
                {/* begin::Nav */}
                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">



                  {/* OVERALL */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Overall" defaultMessage="Overall" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "overall" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("overall");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Overall" defaultMessage="Overall" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* LEGAL */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Legal" defaultMessage="Legal" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "legal" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("legal");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Legal" defaultMessage="Legal" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* LICENCE */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Licence" defaultMessage="Licence" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "licence" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("licence");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Licence" defaultMessage="Licence" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* PART LIST */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.PartList" defaultMessage="Part List" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "partList" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("partList");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.PartList" defaultMessage="Part List" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* ATTACHMENTS */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Attachment" defaultMessage="Attachment" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "attachment" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("attachment");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Attachment" defaultMessage="Attachment" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* HARDWARES */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Hardware" defaultMessage="Hardware" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "hardwares" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("hardwares");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Hardware" defaultMessage="Hardware" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* SOFTWARES */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Software" defaultMessage="Software" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "software" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("software");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Software" defaultMessage="Software" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* INSURANCE */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Insurance" defaultMessage="Insurance" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "insurance" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("insurance");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Insurance" defaultMessage="Insurance" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* CALIBRATION */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Calibration" defaultMessage="Calibration" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "calibration" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("calibration");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Calibration" defaultMessage="Calibration" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* PERIODIC CONTROLS */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.PeriodicControls" defaultMessage="Periodic Controls" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "periodicControls" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("periodicControls");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.PeriodicControls" defaultMessage="Periodic Controls" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* CERTIFICATES */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Certificates" defaultMessage="Certificates" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "certificates" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("certificates");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Certificates" defaultMessage="Certificates" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* MAINTENANCE */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Maintenance" defaultMessage="Maintenance" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "maintenance" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("maintenance");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Maintenance" defaultMessage="Maintenance" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* PERFORMANCE CRITERIAS */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.PerformanceCriteria" defaultMessage="Performance Criterias" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "performanceCriteria" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("performanceCriteria");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.PerformanceCriteria" defaultMessage="Performance Criterias" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  {/* STATISTICALS */}
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Statisticals" defaultMessage="Statisticals" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "statisticals" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("statisticals");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EquipmentLogIdentificationDrawer.Statisticals" defaultMessage="Statisticals" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                </div>
                {/* end::Nav */}
              </div>
              {/* end::Body */}
            </div>
          </div>
          <div className="flex-row-fluid ml-lg-8">
            {selectedTab === "overall" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{<EldenEquipmentLogSettings nextTab={() => setSelectedTab("licence")} eldenEquipmentTreeId={eldenEquipmentTreeId} />}</div>
                </div>
              </div>
            )}
            {selectedTab === "licence" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{<EldenEquipmentLicence nextTab={() => setSelectedTab("partList")} />}</div>
                </div>
              </div>
            )}
            {selectedTab === "partList" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{<EldenCompoundsSettingsProductTree nextTab={() => setSelectedTab("attachments")} />}</div>
                </div>
              </div>
            )}
            {selectedTab === "attachment" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{<EldenCompoundsSettingsProductTree nextTab={() => setSelectedTab("hardwares")} />}</div>
                </div>
              </div>
            )}

            {selectedTab === "hardwares" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{<EldenEquipmentHardware nextTab={() => setSelectedTab("software")} eldenEquipmentTreeId={eldenEquipmentTreeId} />}</div>
                </div>
              </div>
            )}

            {selectedTab === "software" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{<EldenEquipmentSoftware nextTab={() => setSelectedTab("insurance")} />}</div>
                </div>
              </div>
            )}
            {selectedTab === "insurance" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{<EquipmentInsurance nextTab={() => setSelectedTab("calibration")} vehicleId={equipmentLogId} />}</div>
                </div>
              </div>
            )}
            {selectedTab === "calibration" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{<EldenEquipmentCalibration nextTab={() => setSelectedTab("periodicControls")} eldenEquipmentTreeId={eldenEquipmentTreeId} />}</div>
                </div>
              </div>
            )}
            {selectedTab === "periodicControls" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{<EldenEquipmentPeriodicControls nextTab={() => setSelectedTab("legal")} />}</div>
                </div>
              </div>
            )}

            {selectedTab === "legal" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{<EldenEquipmentLegal nextTab={() => setSelectedTab("certificates")} eldenEquipmentTreeId={eldenEquipmentTreeId} />}</div>
                </div>
              </div>
            )}
            {selectedTab === "certificates" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{<EldenEquipmentCertificates nextTab={() => setSelectedTab("maintenance")} />}</div>
                </div>
              </div>
            )}
            {selectedTab === "maintenance" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{<MaintenanceLogProcess nextTab={() => setSelectedTab("performanceCriteria")} equipmentLogId={equipmentLogId} />}</div>
                </div>
              </div>
            )}
            {selectedTab === "performanceCriteria" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{<EldenEquipmentPerformanceCriteria nextTab={() => setSelectedTab("statisticals")} eldenEquipmentTreeId={eldenEquipmentTreeId} />}</div>
                </div>
              </div>
            )}
            {selectedTab === "statisticals" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">{<EldenEquipmentStatisticals nextTab={() => setSelectedTab("overall")} eldenEquipmentTreeId={eldenEquipmentTreeId} />}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}