import "antd/dist/antd.css";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Tooltip, Drawer, Spin, Card, Space, Cascader, Input, InputNumber, Checkbox } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined, Settings } from "@material-ui/icons";
import { Add } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../../excel/ExcelReader";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import { Select } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, DragOutlined } from "@ant-design/icons";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class BillOfQuantityAddProductItem extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BillOfQuantityAddProductItem",
      controllerName: "billOfQuantityAddProduct",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      companyName: "Stock Card",
      quotationDay: -1,
      purchaseDay: -1,
      loadingPurchases: false,
      loadingQuotation: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  }

  fillComboboxes = async () => {

  };

  restartTable = async (params = {}) => {
    if (Boolean(this.props.billOfQuantityAddProductId) && this.props.billOfQuantityAddProductId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/childAddProduct/" + this.props.billOfQuantityAddProductId);
      if (response.type === "ERROR") {
        error(response);
      }
      this.setState({
        tableList: Boolean(response.data) ? response.data : []
      });
    } else {
      this.setState({
        tableList: []
      });
    }

    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
      responseDate: Boolean(row.responseDate) ? moment(row.responseDate) : null,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      vendorIdSelectItems: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    if (Boolean(this.props.billOfQuantityAddProductId) && this.props.billOfQuantityAddProductId > 0) {
      const { residueOfDimensions } = this.state;
      const newItem = {
        ...values,
        id: this.state.id,
        unitId: Boolean(values.unitId) && values.unitId.length > 0 ? values.unitId[values.unitId.length - 1] : null,
        productTreeId: this.props.productTreeId,
        tenderingLogId: this.props.tenderingLogId,
        parentId: this.props.billOfQuantityAddProductId,
      };

      if (Boolean(residueOfDimensions)) {
        newItem.residueOfDimensions = residueOfDimensions;
      } else {
        newItem.residueOfDimensions = [];
      }

      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/addChildAddProduct", newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
      }

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.fillComboboxes();
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showWarning("Bill Of Quantity Add Product not found.");
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleFormNewSupplierSubmit = async values => {
    const newItem = {
      ...values,
      supplier: true,
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createVendor", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showNewSupplierModal: false
      });
      showSuccess();
      this.fillSupplier();
    }
  };

  handleFormNewManufacturerSubmit = async values => {
    const newItem = {
      ...values,
      manufacturer: true,
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createVendor", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showNewManufacturerModal: false
      });
      showSuccess();
      this.fillManufacturer();
    }
  };

  fillSupplier = async () => {
    var responsesupplierId = await handleRequest("GET", "/api/" + this.state.controllerName + "/supplier");
    if (responsesupplierId.type === "ERROR") {
      error(responsesupplierId);
    }
    this.setState({
      supplierIdSelectItems: Boolean(responsesupplierId.data) ? responsesupplierId.data : []
    });
  }

  fillManufacturer = async () => {
    var responsemanufacturerId = await handleRequest("GET", "/api/" + this.state.controllerName + "/manufacturer");
    if (responsemanufacturerId.type === "ERROR") {
      error(responsemanufacturerId);
    }
    this.setState({
      manufacturerIdSelectItems: Boolean(responsemanufacturerId.data) ? responsemanufacturerId.data : []
    });
  }

  fillBrand = async () => {
    const { proposedVendorId, stockCardId } = this.state;
    if (Boolean(proposedVendorId) && Boolean(stockCardId)) {

      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/brand/" + proposedVendorId + "/" + stockCardId);
      if (response.type === "ERROR") {
        error(response);
      }
      this.setState({
        brandIdSelectItems: Boolean(response.data) ? response.data : []
      });
    }
  }

  getPurchaseCriteria = async () => {
    const { stockCardId } = this.state;
    if (Boolean(stockCardId)) {
      let responsePurchaseCriteria = await handleRequest("GET", "/api/" + this.state.controllerName + "/purchasingcriterias/" + stockCardId);
      if (responsePurchaseCriteria.type === "ERROR") {
        error(responsePurchaseCriteria);
      } else {
        generateList(responsePurchaseCriteria.data);
        this.setState({
          purchasingcriteriaSelectItems: Boolean(responsePurchaseCriteria.data) && responsePurchaseCriteria.data.length > 0 ? responsePurchaseCriteria.data : []
        });
      }
    } else {
      this.setState({
        purchasingcriteriaSelectItems: []
      });
    }
  };

  fillLatesPurchases = async () => {
    this.setState({ loadingPurchases: true });
    let { stockCardId, purchaseDay } = this.state;
    if (!Boolean(purchaseDay)) {
      purchaseDay = -1;
    }
    if (Boolean(stockCardId)) {
      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillLatesPurchases/" + stockCardId + "/" + purchaseDay);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          loadingPurchases: false
        });
      } else {
        let latesPurchases = Boolean(response.data) && response.data.length > 0 ? response.data : [];
        this.setState({
          latesPurchases: latesPurchases,
          loadingPurchases: false
        });
        this.formRef.current.setFieldsValue({
          latesPurchases: latesPurchases,
        });
      }
    } else {
      this.setState({
        latesPurchases: null,
        loadingPurchases: false
      });
      this.formRef.current.setFieldsValue({
        latesPurchases: null,
      });
    }
  };

  fillDimensions = async () => {
    const { stockCardId } = this.state;
    if (Boolean(stockCardId) && stockCardId > 0) {
      let responseItemDescription = await handleRequest("GET", "/api/" + this.state.controllerName + "/getEldenItem/" + stockCardId);

      if (responseItemDescription.type === "ERROR") {
        error(responseItemDescription);
      } else {
        let itemDescription = Boolean(responseItemDescription.data) ? responseItemDescription.data : [];
        this.setState({
          itemDescription: itemDescription
        });

        if (Boolean(itemDescription.itemDimension) && itemDescription.itemDimension.length > 0) {
          itemDescription.itemDimension.forEach(element => {
            this.fillCode("StockCard" + element.itemDefinition);
            if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
              this.formRef.current.setFieldsValue({
                [element.itemDefinition]: null
              });
            }
          });
        }
        if (Boolean(itemDescription.itemCodeCriterias) && itemDescription.itemCodeCriterias.length > 0) {
          itemDescription.itemCodeCriterias.forEach(element => {
            this.fillCode("StockCard" + element.itemDefinition);
            if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
              this.formRef.current.setFieldsValue({
                [element.itemDefinition]: null
              });
            }
          });
        }
        let residueOfDimensions = [];
        if (Boolean(itemDescription.dimensionList) && itemDescription.dimensionList.length > 0) {
          itemDescription.dimensionList.forEach(element => {
            if (Boolean(itemDescription.itemDimension) && itemDescription.itemDimension.length > 0) {
              let f = itemDescription.itemDimension.find(x => x.itemDefinition === element.definition);
              if (!Boolean(f)) {
                residueOfDimensions.push({
                  key: element.id,
                  definition: element.definition,
                  value: null,
                  itemDimensionId: element.id,
                  unitId: null,
                  units: Boolean(element.unitType) ? element.unitType.units : []
                });
              }
            } else {
              residueOfDimensions.push({
                key: element.id,
                definition: element.definition,
                value: null,
                itemDimensionId: element.id,
                unitId: null,
                units: Boolean(element.unitType) ? element.unitType.units : []
              });
            }
          });
        }
        this.setState({
          residueOfDimensions: residueOfDimensions
        });
        if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
          this.formRef.current.setFieldsValue({
            residueOfDimensions: residueOfDimensions
          });
        }

        let response = await handleRequest("GET", "/api/stockCard/" + stockCardId);
        if (response.type === "ERROR") {
          error(response);
        } else {
          let stockCard = Boolean(response.data) ? response.data : [];

          if (Boolean(stockCard.itemDimension) && stockCard.itemDimension.length > 0) {
            stockCard.itemDimension.forEach(element => {
              this.formRef.current.setFieldsValue({
                [element.itemDefinition]: element.codeId,
                [element.itemDefinition + "Unit"]: element.unitId
              });
            });
          }
          if (Boolean(stockCard.itemCodeCriterias) && stockCard.itemCodeCriterias.length > 0) {
            stockCard.itemCodeCriterias.forEach(element => {
              this.formRef.current.setFieldsValue({
                [element.itemDefinition]: element.codeId
              });
            });
          }
        }
      }
    }
  }

  fillLatesQuotations = async () => {
    this.setState({ loadingQuotation: true });
    const { stockCardId, quotationDay } = this.state;
    if (!Boolean(quotationDay)) {
      quotationDay = -1;
    }
    if (Boolean(stockCardId)) {
      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillLatesQuotations/" + stockCardId + "/" + quotationDay);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          loadingQuotation: false
        });
      } else {
        let latesQuotations = Boolean(response.data) && response.data.length > 0 ? response.data : [];
        this.setState({
          latesQuotations: latesQuotations,
          loadingQuotation: false
        });
        this.formRef.current.setFieldsValue({
          latesQuotations: latesQuotations,
        });
      }
    } else {
      this.setState({
        latesQuotations: null,
        loadingQuotation: false
      });
      this.formRef.current.setFieldsValue({
        latesQuotations: null,
      });
    }
  };

  searchItem = async item => {
    console.log("item", item);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemSearch/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          itemIdSelectItems: [],
          loadingSearch: false
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            itemIdSelectItems: response.data,
            loadingSearch: false
          });
        } else {
          this.setState({
            itemIdSelectItems: [],
            loadingSearch: false
          });
        }
      }
    } else {
      this.setState({
        loadingSearch: false
      });
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layoutLeft = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };

    const layoutRight = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 }
    };

    const columns = [
      {
        key: "key", render: this.action,
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="BillOfQuantityAddProductItem.Item" defaultMessage="Item" />,
        key: "itemId",
        render: record => {
          return record.itemName;
        },
      },
      {
        title: <FormattedMessage id="BillOfQuantityAddProductItem.Quantity" defaultMessage="Quantity" />,
        key: "quantity",
        render: record => {
          let quantityUnit = [];
          if (Boolean(record.quantity)) {
            quantityUnit.push(record.quantity);
          }
          if (Boolean(record.unitName)) {
            quantityUnit.push(record.unitName);
          }
          return quantityUnit.join(" ");
        }
      },
      {
        title: <FormattedMessage id="BillOfQuantityAddProductItem.UnitPrice" defaultMessage="Unit Price" />,
        key: "unitPrice",
        render: record => {
          let unitPriceCurrency = [];
          if (Boolean(record.unitPrice)) {
            unitPriceCurrency.push(record.unitPrice);
          }
          if (Boolean(record.currency)) {
            unitPriceCurrency.push(record.currency);
          }
          return unitPriceCurrency.join(" ");
        }
      },
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="BillOfQuantityAddProductItem" hideElement={true}>
            <ExcelSheet name="BillOfQuantityAddProductItem" data={this.state.tableList}>
              <ExcelColumn label="Item" value="itemName" />
              <ExcelColumn label="Vendor" value="vendorName" />
              <ExcelColumn label="Response" value="response" />
              <ExcelColumn label="Reply" value="reply" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={20}></Col> <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
            <Col md={1}>
              {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                <Tooltip title={<FormattedMessage id="BillOfQuantityAddProductItem.Settings" defaultMessage="Settings" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      this.setState({ drawerSettingsShow: true });
                    }}
                    icon={<Settings color="error" fontSize="large" />}
                  ></AntButton>
                </Tooltip>
              )}
            </Col>
          </Row>
          <div hidden={this.state.hideInputs}>
            <Spin spinning={this.state.loading}>
              <Row gutter={16} style={{ marginBottom: "16px" }}>
                {/* Sol Card */}
                <Col span={8}>
                  <h5>
                    {this.state.companyName}
                  </h5>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="BillOfQuantityAddProduct.Item" defaultMessage="Item" />}
                      name="itemId"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select
                        allowClear
                        showSearch
                        placeholder="Search"
                        onSearch={this.searchItem}
                        style={{ width: "100%" }} optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={async (value) => {
                          this.setState({
                            stockCardId: value
                          }, () => {
                            this.fillBrand();
                            this.getPurchaseCriteria();
                            this.fillLatesPurchases();
                            this.fillLatesQuotations();
                            this.fillDimensions();
                          });
                        }}>
                        {Boolean(this.state["itemIdSelectItems"]) &&
                          this.state["itemIdSelectItems"].length > 0 &&
                          this.state["itemIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="BillOfQuantityAddProduct.Supplier" defaultMessage="Supplier" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <Form.Item
                            name="proposedVendorId"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            style={{ width: "90%" }}
                          >
                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children"
                              onChange={value => {
                                this.setState({
                                  proposedVendorId: value,
                                }, () => {
                                  this.fillBrand();
                                });
                              }}>
                              {Boolean(this.state["supplierIdSelectItems"]) &&
                                this.state["supplierIdSelectItems"].length > 0 &&
                                this.state["supplierIdSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>

                          <AntButton
                            type="default"
                            style={{
                              color: "black",
                              background: "white",
                              width: "10%"
                            }}
                            onClick={() => {
                              this.setState({ showNewSupplierModal: true });
                            }}
                            icon={<Add />}
                          ></AntButton>
                        </Space.Compact>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="BillOfQuantityAddProduct.Brand" defaultMessage="Brand" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <Form.Item
                            name="brandId"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            style={{ width: "90%" }}
                          >
                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                              {Boolean(this.state["brandIdSelectItems"]) &&
                                this.state["brandIdSelectItems"].length > 0 &&
                                this.state["brandIdSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Space.Compact>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="BillOfQuantityAddProduct.Quantity" defaultMessage="Quantity" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Row gutter={8}>
                          <Col span={12}>
                            <Form.Item
                              name="quantity"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <InputNumber style={{ width: '100%' }} min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="unitId"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <Cascader showSearch allowClear optionFilterProp="children" style={{ width: '100%' }} options={this.state.purchasingcriteriaSelectItems} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    }

                    {Boolean(this.state.itemDescription) &&
                      Boolean(this.state.itemDescription.itemCodeCriterias) &&
                      this.state.itemDescription.itemCodeCriterias.length > 0 &&
                      this.state.itemDescription.itemCodeCriterias.map(element => (
                        <Form.Item
                          {...layoutLeft}
                          label={element.itemDefinition}
                          name={element.itemDefinition}
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select disabled showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                            {Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"]) &&
                              this.state["StockCard" + element.itemDefinition + "SelectItems"].map(i => (
                                <Option key={i.id} value={i.id}>
                                  {i.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      ))}

                    {Boolean(this.state.itemDescription) &&
                      Boolean(this.state.itemDescription.itemDimension) &&
                      this.state.itemDescription.itemDimension.length > 0 &&
                      this.state.itemDescription.itemDimension.map(element => (
                        <Form.Item
                          {...layoutLeft}
                          label={element.itemDefinition}
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Row>
                            <Col span={11}>
                              <Form.Item
                                name={element.itemDefinition}
                                rules={[
                                  { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                              >
                                <Select disabled showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                  {Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"]) &&
                                    this.state["StockCard" + element.itemDefinition + "SelectItems"].map(i => (
                                      <Option key={i.id} value={i.id}>
                                        {i.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={11}>
                              <Form.Item
                                name={element.itemDefinition + "Unit"}
                                rules={[
                                  { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                              >
                                <Select disabled showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                  {element.units &&
                                    element.units.map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                      ))}

                    {Boolean(this.state.residueOfDimensions) &&
                      this.state.residueOfDimensions.length > 0 &&
                      this.state.residueOfDimensions.map((dimension, index) => {
                        return (
                          <Form.Item key={index} {...layoutLeft} label={dimension.definition}>
                            <Row>
                              <Col span={11}>
                                <InputNumber
                                  parser={value => value.replace(",", ".")}
                                  formatter={value => value.replace(".", ",")}
                                  placeholder={dimension.definition}
                                  style={{ width: "100%" }}
                                  min={0}
                                  value={dimension.value}
                                  onChange={value => {
                                    let list = this.state.residueOfDimensions;
                                    list[index].value = value;
                                    this.setState(
                                      {
                                        residueOfDimensions: list
                                      },
                                      () => {
                                        if (dimension.definition === "Length" || dimension.definition === "Width") {
                                          console.log("selectedStockCard", this.state.selectedStockCard);
                                        }
                                      }
                                    );
                                  }}
                                />
                              </Col>
                              <Col span={2}></Col>
                              <Col span={11}>
                                <Select
                                  showSearch
                                  allowClear
                                  optionFilterProp="children"
                                  style={{ width: "100%" }}
                                  placeholder="Unit"
                                  value={dimension.unitId}
                                  onChange={value => {
                                    console.log("VVV", value);
                                    console.log(dimension);
                                    let list = this.state.residueOfDimensions;
                                    list[index].unitId = value;
                                    this.setState(
                                      {
                                        residueOfDimensions: list
                                      },
                                      () => {
                                        if (dimension.definition === "Length" || dimension.definition === "Width") {
                                          console.log("selectedStockCard", this.state.selectedStockCard);
                                        }
                                      }
                                    );
                                  }}
                                >
                                  {dimension.units &&
                                    dimension.units.map(i => (
                                      <Option key={i.id} value={i.id}>
                                        {i.description}
                                      </Option>
                                    ))}
                                </Select>
                              </Col>
                            </Row>
                          </Form.Item>
                        );
                      })}
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="BillOfQuantityAddProduct.UnitPrice" defaultMessage="Unit Price" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Row gutter={8}>
                          <Col span={12}>
                            <Form.Item
                              name="unitPrice"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <InputNumber style={{ width: '100%' }} min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="currency"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                {Boolean(this.state.currencySelectItems) &&
                                  this.state.currencySelectItems.length > 0 &&
                                  this.state.currencySelectItems.map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    }
                  </Card>
                </Col>

                <Col span={8}>
                  <h5>
                    <FormattedMessage id="BillOfQuantityAddProduct.LatestPurchases" defaultMessage="Latest Purchases" />
                  </h5>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Form.Item
                      {...layoutRight}
                      name="latesPurchasesDay"
                      label={<FormattedMessage id="BillOfQuantityAddProduct.Last" defaultMessage="Last" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber
                        onChange={value => {
                          this.setState({
                            purchaseDay: value
                          }, () => {
                            this.fillLatesPurchases();
                          });
                        }}
                        addonAfter="day" min={0} style={{ width: '100%' }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                    </Form.Item>

                    <Spin spinning={this.state.loadingPurchases}>
                      <Form.List name="latesPurchases">
                        {(fields, { add, remove }) => {
                          const handleMoveClick = (fieldName) => {
                            const latesPurchases = this.formRef.current.getFieldValue("latesPurchases");
                            let row = latesPurchases[fieldName];
                            if (Boolean(row) && Boolean(row.unitPrice) && row.unitPrice > 0) {
                              this.formRef.current.setFieldsValue({
                                unitPrice: row.unitPrice,
                              });
                            } else {
                              this.formRef.current.setFieldsValue({
                                unitPrice: 0,
                              });
                            }
                          };
                          return (
                            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                              <TableBoot bordered size="sm">
                                <thead>
                                  <tr>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.UnitPrice"} defaultMessage={"Unit Price"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.Date"} defaultMessage={"Date"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.Vendor"} defaultMessage={"Vendor"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.Move"} defaultMessage={"Move"} />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item name={[field.name, "selectedValue"]} valuePropName="checked">
                                            <Checkbox onClick={(e) => {
                                              const latesPurchases = this.formRef.current.getFieldValue("latesPurchases");
                                              let row = latesPurchases[field.name];
                                              row.selectedValue = e.target.checked;

                                              this.setState({
                                                latesPurchases: latesPurchases,
                                              });
                                              this.formRef.current.setFieldsValue({
                                                latesPurchases: latesPurchases,
                                              });

                                              let total = 0;
                                              let count = 0;
                                              latesPurchases.forEach(element => {
                                                if (element.selectedValue) {
                                                  total += parseFloat(element.unitPrice);
                                                  count++;
                                                }
                                              });

                                              console.log("latesPurchases", latesPurchases);
                                              if (Boolean(total) && total > 0 && Boolean(count) && count > 0) {
                                                this.formRef.current.setFieldsValue({
                                                  latesPurchasesAverage: parseFloat((total / count).toFixed(2)),
                                                });
                                              } else {
                                                this.formRef.current.setFieldsValue({
                                                  latesPurchasesAverage: 0,
                                                });
                                              }
                                            }} />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "unitPrice"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "date"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "vendorName"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <DragOutlined onClick={() => {
                                            handleMoveClick(field.name);
                                          }} />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </TableBoot>
                            </div>
                          );
                        }}
                      </Form.List>
                    </Spin>

                    {Boolean(this.state.latesPurchases) && this.state.latesPurchases.length > 1 &&
                      <Form.Item
                        {...layoutRight}
                        name="latesPurchasesAverage"
                        label={<FormattedMessage id="BillOfQuantityAddProduct.Average" defaultMessage="Average" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        style={{ marginTop: '30px' }}
                      >
                        <InputNumber readOnly addonAfter={
                          <Tooltip title="Move">
                            <DragOutlined onClick={() => {
                              const latesPurchasesAverage = this.formRef.current.getFieldValue("latesPurchasesAverage");
                              if (Boolean(latesPurchasesAverage) && latesPurchasesAverage > 0) {
                                this.formRef.current.setFieldsValue({
                                  unitPrice: latesPurchasesAverage,
                                });
                              } else {
                                this.formRef.current.setFieldsValue({
                                  unitPrice: 0,
                                });
                              }
                            }} />
                          </Tooltip>
                        } placeholder="unit price"
                          min={0} style={{ width: '100%' }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                      </Form.Item>}
                  </Card>
                </Col>

                <Col span={8}>
                  <h5>
                    <FormattedMessage id="BillOfQuantityAddProduct.LatestQuotations" defaultMessage="Latest Quotations" />
                  </h5>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Form.Item
                      {...layoutRight}
                      name="latesQuotationsDay"
                      label={<FormattedMessage id="BillOfQuantityAddProduct.Last" defaultMessage="Last" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber
                        onChange={value => {
                          this.setState({
                            quotationDay: value
                          }, () => {
                            this.fillLatesQuotations();
                          });
                        }}
                        addonAfter="day" min={0} style={{ width: '100%' }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                    </Form.Item>

                    <Spin spinning={this.state.loadingQuotation}>
                      <Form.List name="latesQuotations">
                        {(fields, { add, remove }) => {
                          const handleMoveClick = (fieldName) => {
                            const latesQuotations = this.formRef.current.getFieldValue("latesQuotations");
                            let row = latesQuotations[fieldName];
                            if (Boolean(row) && Boolean(row.unitPrice) && row.unitPrice > 0) {
                              this.formRef.current.setFieldsValue({
                                unitPrice: row.unitPrice,
                              });
                            } else {
                              this.formRef.current.setFieldsValue({
                                unitPrice: 0,
                              });
                            }
                          };

                          return (
                            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                              <TableBoot bordered size="sm">
                                <thead>
                                  <tr>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.UnitPrice"} defaultMessage={"Unit Price"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.Date"} defaultMessage={"Date"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.Project"} defaultMessage={"Project"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.Deviation"} defaultMessage={"Deviation"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddProduct.Move"} defaultMessage={"Move"} />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "unitPrice"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "date"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "projectName"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "deviation"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <DragOutlined onClick={() => {
                                            handleMoveClick(field.name);
                                          }} />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </TableBoot>
                            </div>
                          );
                        }}
                      </Form.List>
                    </Spin>
                  </Card>
                </Col>
              </Row>
            </Spin>
            <br /><br />
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>
        {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
          <div className="alert alert-custom alert-light">
            <div className="alert-icon">
              <FilterOutlined />
            </div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (
                  <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                    <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                    <span>
                      <Tooltip title="Remove">
                        <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined
                  style={{ color: "crimson" }}
                  onClick={() =>
                    this.setState({ searchCriteriaModels: [] }, () => {
                      this.filterChangeItem([]);
                    })
                  }
                />
              </Tooltip>
            </div>
          </div>
        )}
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, y: 400, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            pagination={false}
          />
        </div>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Drawer
          title={<FormattedMessage id="BillOfQuantityAddProductItem.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"BillOfQuantityAddProductItem"} />
        </Drawer>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BillOfQuantityAddProductItem);