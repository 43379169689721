import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { fillFilterOperations } from "../../Components/DataTableFilterComponent";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditOutlined } from "@material-ui/icons";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import {
  Form,
  Input,
  Select,
  Col,
  Row,
  Checkbox,
  Radio,
  Cascader
} from "antd";
import { handleRequest } from "../../ApiConnector";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node)) {
      var lineage = [];
      if (Boolean(node.lineage) && node.lineage.length > 0) {
        node.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.value,
        lineage: node.lineage,
        title: node.value
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class PageAutoDesignFormInput extends Component {
  constructor() {
    super();
    this.state = {
      formName: "PageAutoDesignFormInput",
      controllerName: "pageAutoDesignFormInput",
      modalDeleteShow: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      selectedRow: null,
      tableList: [],
      filePath: "",
      fileList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      textFormattingDetailId: null,
      excelData: [],
      searchCriteriaModels: [],
      nodes: [],
      inputTypeSelectItems: null,
      autoDesignOnClick: null,
      inputType: null,

      folderSelectItems: [],
      entitySelectItems: [],
      fieldIdSelectItems: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = (prevProps) => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    this.getPageAutoDesignFormInput();
    this.fillComboboxes();
    fillFilterOperations();
  };

  getPageAutoDesignFormInput = async () => {
    let pageAutoDesignFormInputId = Boolean(this.props.pageAutoDesignFormInputId) ? this.props.pageAutoDesignFormInputId : -1;
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getPageAutoDesignFormInput/" + pageAutoDesignFormInputId);
    if (response.type === "ERROR") {
      error(response);
    }
    if (Boolean(response.data)) {
      this.formRef.current.setFieldsValue({
        ...response.data,
      });
      this.setState({
        autoDesignOnClick: response.data.autoDesignOnClick,
        inputType: response.data.inputType,
      });
    } else {
      this.formRef.current.resetFields();
    }
  };

  fillComboboxes = async () => {
    var responseAutoDesignOnClick = await handleRequest("GET", "/api/" + this.state.controllerName + "/getAutoDesignOnClickList");
    if (responseAutoDesignOnClick.type === "ERROR") {
      error(responseAutoDesignOnClick);
    }
    var responseInputTyp = await handleRequest("GET", "/api/" + this.state.controllerName + "/getInputTypes");
    if (responseInputTyp.type === "ERROR") {
      error(responseInputTyp);
    }
    this.setState({
      inputTypeSelectItems: Boolean(responseInputTyp.data) ? responseInputTyp.data : [],
      autoDesignOnClickList: Boolean(responseAutoDesignOnClick.data) ? responseAutoDesignOnClick.data : []
    });
    this.getFolders();
    this.getEntities();
  };

  getFolders = async () => {
    const responseFolder = await handleRequest("GET", "/ApplicationSettings/getDomainFolders");
    if (Boolean(responseFolder.data) && responseFolder.data.length > 0) {
      this.setState({
        folderSelectItems: responseFolder.data
      });
    }
  };

  getEntities = async folder => {
    if (!Boolean(folder)) {
      let response = await handleRequest("GET", "/api/pageForm/getEntitiesFromLocalDomain");
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          entitySelectItems: response.data
        });
      }
    } else {
      let response = await handleRequest("GET", "/api/pageForm/getEntitiesFromFolderDomain/" + folder);
      console.log(response);
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          entitySelectItems: response.data
        });
      }
    }
  };

  onChangeFolder = async value => {
    let folder = value;
    if (!Boolean(folder)) {
      let response = await handleRequest("GET", "/api/pageForm/getEntitiesFromLocalDomain");
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          entitySelectItems: response.data
        });
      }
    } else {
      let response = await handleRequest("GET", "/api/pageForm/getEntitiesFromFolderDomain/" + folder);

      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          entitySelectItems: response.data
        });
      }
    }
    this.setState({
      folder: folder
    });
  };

  getEntityFields = async (entity, callback) => {
    let { folder } = this.state;
    let url = "/api/" + this.state.controllerName + "/bymodeldto/" + entity;
    if (Boolean(folder)) {
      url = "/api/" + this.state.controllerName + "/bymodeldtoandfolder/" + entity + "/" + folder;
    }
    const response = await handleRequest("GET", url);
    if (Boolean(response.data) && response.data.length > 0) {
      const data = response.data;
      data.forEach((element, index) => {
        element.rowIndex = index + 1;
        if (Boolean(element.inputProps)) {
          element.inputProps = JSON.parse(element.inputProps);
        }
        if (!Boolean(element.inputType)) {
          element.inputType = "TEXT";
          element.inputProps = this.state.inputTypeSelectItems.find(i => i.key === "TEXT").inputProps;
        }
      });

      this.setState(
        {
          dynamicFormList: data
        },
        () => {
          callback(data);
        }
      );
    }
  };

  fillField = async (entity) => {
    let { folder } = this.state;
    let url = "/api/" + this.state.controllerName + "/bymodeldto/" + entity;
    if (Boolean(folder)) {
      url = "/api/" + this.state.controllerName + "/bymodeldtoandfolder/" + entity + "/" + folder;
    }
    var response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    }
    else
      this.setState({
        fieldIdSelectItems: Boolean(response.data) ? response.data : []
      });
  }


  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showInputModal = row => {
    this.setState({
      modalInputShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  save = async values => {
    if (Boolean(this.props.pageAutoDesignFormInputId) && this.props.pageAutoDesignFormInputId > 0) {
      const newItem = { ...values, id: this.state.id };
      newItem.id = this.props.pageAutoDesignFormInputId;

      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/save", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.fillComboboxes();
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        if (Boolean(this.props) && Boolean(this.props.closeInputFormatModal)) {
          this.props.closeInputFormatModal();
        }
      }
    } else {
      showError("Page Auto Design not found");
    }
  };

  edit = (row) => {
    document.getElementById("kt_scrolltop").click();

    let inputs = [];
    let a = this.state.inputTypeSelectItems.find(x => x.key === row.inputType).inputs;
    if (row.inputs) {
      inputs = [...a];
      let rowProps = JSON.parse(row.inputs);
      for (let i = 0; i < inputs.length; i++) {
        let p = rowProps.find(x => x.key === inputs[i].key);
        if (p) {
          inputs[i][inputs[i].key] = p[inputs[i].key];
        }
      }
    }
    this.formRef.current.setFieldsValue({
      ...row,
      inputs: inputs
    });
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      selectedRow: row,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.fillComboboxes();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data && response.data.data) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedRow: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: this.props.pageAutoDesignFormInputId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      style: { marginBottom: 0 }
    };

    return (
      <div className="card card-custom" style={{ padding: "3rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageAutoDesignFormInput.inputName" defaultMessage="Input Name" />}
              name="inputName"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              extra={<FormattedMessage id="PageAutoDesignFormInput.BackendEntityInputNamePleaseWriteWithoutSpaces"
                defaultMessage="Backend entity input name (Please write without spaces)" />}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageAutoDesignFormInput.label" defaultMessage="Label" />}
              name="label"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageAutoDesignFormInput.placeholder" defaultMessage="Placeholder" />}
              name="placeholder"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageAutoDesignFormInput.placeholder" defaultMessage="Input Type" />}
              name="inputType"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: "100%" }}
                value={this.state.inputType}
                onChange={e => {
                  this.setState({ inputType: e });
                }}
              >
                {Boolean(this.state["inputTypeSelectItems"]) && this.state["inputTypeSelectItems"].length > 0 &&
                  this.state["inputTypeSelectItems"].map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.key}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          }
          {/* {this.state.inputType && (["SELECT", "ENUM", "SELECT_MULTIPLE_ADDABLE", "SELECT_MULTIPLE"].includes(this.state.inputType)) && (
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageAutoDesignFormInput.inputName" defaultMessage="Entity Name" />}
              name="entityName"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              extra={<FormattedMessage id="PageAutoDesignFormInput.EntityFieldfromwhichwewanttogetinformation" defaultMessage="Entity Field from which we want to get information" />}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          )} */}
          {this.state.inputType && (["SELECT", "ENUM", "SELECT_MULTIPLE_ADDABLE", "SELECT_MULTIPLE"].includes(this.state.inputType)) && (
            <>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageAutoDesignFormInput.Folder" defaultMessage="Folder" />}
                name="folderName"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  value={this.state.folder}
                  onChange={this.onChangeFolder}
                >
                  {Boolean(this.state["folderSelectItems"]) && this.state["folderSelectItems"].length > 0 &&
                    this.state["folderSelectItems"].map(i => (
                      <Option key={i} value={i}>
                        {i}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              {/* //////////////////////////////////////// */}
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageAutoDesignFormInput.Entity" defaultMessage="Entity" />}
                name="entity"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  placeholder="Entity Name"
                  style={{ width: "100%" }}
                  value={this.state.entity}
                  onChange={value => {
                    this.fillField(value);
                  }}
                >
                  {this.state.entitySelectItems.map(i => (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {/* //////////////////////////////////////// */}
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageAutoDesignFormInput.Field" defaultMessage="Field" />}
                name="fieldName"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["fieldIdSelectItems"]) &&
                    this.state["fieldIdSelectItems"].length > 0 &&
                    this.state["fieldIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </>
          )}
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageAutoDesignFormInput.hideAtStart" defaultMessage="Backend Only" />}
              name="backendOnly"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              valuePropName="checked"
            >
              <Checkbox></Checkbox>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageAutoDesignFormInput.hideAtStart" defaultMessage="Hide at start" />}
              name="hideAtStart"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              valuePropName="checked"
            >
              <Checkbox></Checkbox>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageAutoDesignFormInput.required" defaultMessage="Required" />}
              name="required"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              valuePropName="checked"
            >
              <Checkbox></Checkbox>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageAutoDesignFormInput.hover" defaultMessage="Hover" />}
              name="hover"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }

          {Boolean(this.state.inputType) && this.state.inputType === "BUTTON" &&
            <>
              {
                <Form.Item {...layout2}
                  label={<FormattedMessage id="PageAutoDesignFormInput.autoDesignOnClick" defaultMessage="On Click" />}
                  name="autoDesignOnClick"
                >
                  <Radio.Group
                    onChange={e => {
                      this.setState({
                        autoDesignOnClick: e.target.value
                      });
                    }}
                  >
                    {Boolean(this.state.autoDesignOnClickList) && this.state.autoDesignOnClickList.length > 0 &&
                      this.state.autoDesignOnClickList.map(i => {
                        return (
                          <Radio key={i.key} value={i.key}> {i.value}</Radio>
                        );
                      })}
                  </Radio.Group>
                </Form.Item>
              }
              {
                Boolean(this.state.autoDesignOnClick) && <Form.Item
                  {...layout}
                  label={<FormattedMessage id="PageAutoDesignFormInput.onClickName" defaultMessage="On Click Name" />}
                  name="onClickName"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
            </>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageAutoDesignFormInput.whatIsLanguageKey" defaultMessage="What Is Language Key" />}
              name="whatIsLanguageKey"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageAutoDesignFormInput.whatIsDescription" defaultMessage="What Is Description" />}
              name="whatIsDescription"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageAutoDesignFormInput.labelLanguageKey" defaultMessage="Label Language Key" />}
              name="labelLanguageKey"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="PageAutoDesignFormInput.controllerName" defaultMessage="Controller Name" />}
              name="controllerName"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Col>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            </Row>
          }
        </Form>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}



const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageAutoDesignFormInput);
