import "antd/dist/antd.css";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Tooltip, Drawer, Select, DatePicker, Input, Radio } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined, Settings, Visibility } from "@material-ui/icons";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import { ExcelReader } from "../../excel/ExcelReader";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import DraggableModal from "../../../Components/DraggableModal";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { EldenEquivalentStockCodesLogRevise } from "./EldenEquivalentStockCodesLogRevise";
import EldenEquivalentStockCodesLogAuthority from "./EldenEquivalentStockCodesLogAuthority";
import EldenEquivalentStockCodesLogDetail from "./EldenEquivalentStockCodesLogDetail";

const { Option } = Select;

export class EldenEquivalentStockCodesLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "EldenEquivalentStockCodesLog",
      controllerName: "eldenEquivalentStockCodesLog",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      addModal: false,
      hideInputs: true,
      loadingSearch: false,
      user: store.getState().auth.user,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      columns: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    this.fillComboboxes();
  }

  fillComboboxes = async () => {
    var responseAuthorityId = await handleRequest("GET", "/api/" + this.state.controllerName + "/authorityId");
    if (responseAuthorityId.type === "ERROR") {
      error(responseAuthorityId);
    }

    var responseType = await handleRequest("GET", "/api/" + this.state.controllerName + "/type");
    if (responseType.type === "ERROR") {
      error(responseType);
    }

    this.setState({
      authorityIdSelectItems: Boolean(responseAuthorityId.data) ? responseAuthorityId.data : [],
      typeSelectItems: Boolean(responseType.data) ? responseType.data : [],
    }, () => {
      this.setColumnsReserved();
      const { pagination } = this.state;
      this.restartTable({ pagination });
    });
  };

  setColumnsReserved() {
    const { authorityIdSelectItems } = this.state;

    let columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EldenEquivalentStockCodesLog.Type" defaultMessage="Type" />,
        key: "type",
        render: record => {
          return record.type;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "250px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  type: value,
                  typeColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["typeSelectItems"]) && this.state["typeSelectItems"].length > 0 &&
                this.state["typeSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.typeColor) ? this.state.pagination.typeColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
      },
      {
        title: <FormattedMessage id="EldenEquivalentStockCodesLog.Item" defaultMessage="Item" />,
        key: "id",
        render: record => {
          return record.itemName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "250px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  id: value,
                  idColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["itemIdSelectItems"]) && this.state["itemIdSelectItems"].length > 0 &&
                this.state["itemIdSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.idColor) ? this.state.pagination.idColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
      },
      {
        title: <FormattedMessage id="EldenEquivalentStockCodesLog.StockCode" defaultMessage="Stock Code" />,
        key: "code",
        render: record => {
          return record.code;
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "250px" }}>
            <Input
              style={{ width: "100%" }}
              onChange={e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  code: e.target.value,
                  codeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.codeColor) ? this.state.pagination.codeColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
      },
    ];

    if (Boolean(authorityIdSelectItems) && authorityIdSelectItems.length > 0) {
      authorityIdSelectItems.forEach(element => {
        // let newColumn = {
        //   title: element.value,
        //   key: element.key,
        //   dataIndex: element.key,
        //   width: 100,
        // }; 
        let newColumn = {
          title: element.value,
          children: [
            {
              title: <FormattedMessage id="EldenEquivalentStockCodesLog.Code" defaultMessage="Code" />,
              key: element.key + "_Code",
              dataIndex: element.key + "_Code",
              width: 100,
            },
            {
              title: <FormattedMessage id="EldenEquivalentStockCodesLog.Description" defaultMessage="Description" />,
              key: element.key + "_Description",
              dataIndex: element.key + "_Description",
              width: 100,
            },
            {
              title: <FormattedMessage id="EldenEquivalentStockCodesLog.EffectiveDate" defaultMessage="Effective Date" />,
              key: element.key + "_EffectiveDate",
              dataIndex: element.key + "_EffectiveDate",
              width: 100,
            },
          ]
        }
        columns.push(newColumn);
      });
    }

    this.setState({
      columns: columns
    });
  }

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        const { authorityIdSelectItems } = this.state;
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
          if (Boolean(authorityIdSelectItems) && authorityIdSelectItems.length > 0 &&
            Boolean(element.details) && element.details.length > 0) {

            element.details.forEach(detail => {
              let authority = authorityIdSelectItems.find(p => p.key === detail.authorityId);
              if (Boolean(authority)) {
                element[detail.authorityId + "_Code"] = detail.code;
                element[detail.authorityId + "_Description"] = detail.description;
                element[detail.authorityId + "_EffectiveDate"] = Boolean(detail.effectiveDate) ? moment(detail.effectiveDate).format("DD-MM-YYYY") : null
              }
            });

          }
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
      effectiveDate: Boolean(row.effectiveDate) ? moment(row.effectiveDate) : null
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  addModal = async (row) => {
    await this.resetInputs();
    this.setState({
      addModal: true,

      hideInputs: false,
      hideSave: false,
      hideUpdate: true
    }, () => {
      this.formRef.current.setFieldsValue({
        itemId: row.itemId,
        type: row.type,
      });
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let detailAction = {
      name: "Detail",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.detailModal.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };
    let addAction = {
      name: "Add",
      icon: <CreateIcon fontSize="small" color="secondary" />,
      actionClick: this.addModal.bind(this)
    };

    let actionList = [];
    if (Boolean(record.id) && record.id > 0) {
      actionList.push(detailAction);
      actionList.push(deleteAction);
    } else {
      actionList.push(addAction);
    }

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  detailModal = (row) => {
    this.setState({
      id: row.id,
      modalDetailShow: true,
      random: Math.random(),
    });
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      addModal: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    if (Boolean(values.itemId) && values.itemId > 0) {
      const newItem = {
        ...values,
        id: this.state.id
      };

      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
      }

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showWarning("Please select Item or Stock Code");
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  EldenEquivalentStockCodesLogReviseModal = record => {
    this.setState({
      showEldenEquivalentStockCodesLogReviseModal: true,
      selectedEldenEquivalentStockCodesLogRevise: record
    });
  };
  EldenEquivalentStockCodesLogReviseModal = record => {
    this.setState({
      showEldenEquivalentStockCodesLogReviseModal: true,
      selectedEldenEquivalentStockCodesLogRevise: record
    });
  };
  EldenEquivalentStockCodesLogReviseModal = record => {
    this.setState({
      showEldenEquivalentStockCodesLogReviseModal: true,
      selectedEldenEquivalentStockCodesLogRevise: record
    });
  };

  searchItem = async item => {
    console.log("item", item);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemSearch/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          itemIdSelectItems: [],
          loadingSearch: false
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            itemIdSelectItems: response.data,
            loadingSearch: false
          });
        } else {
          this.setState({
            itemIdSelectItems: [],
            loadingSearch: false
          });
        }
      }
    } else {
      this.setState({
        loadingSearch: false
      });
    }
  };

  searchItemCode = async item => {
    console.log("item", item);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemSearchCode/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          stockCodeIdSelectItems: [],
          loadingSearch: false
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            stockCodeIdSelectItems: response.data,
            loadingSearch: false
          });
        } else {
          this.setState({
            stockCodeIdSelectItems: [],
            loadingSearch: false
          });
        }
      }
    } else {
      this.setState({
        loadingSearch: false
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="EldenEquivalentStockCodesLog" hideElement={true}>
            <ExcelSheet name="EldenEquivalentStockCodesLog" data={this.state.tableList}>
              <ExcelColumn label="Group" value="group" />
              <ExcelColumn label="Item Description" value="itemDescription" />
              <ExcelColumn label="Stock Code" value="code" />
              <ExcelColumn label="Code" value="code" />
              <ExcelColumn label="Description" value="description" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    return (
      <Badge.Ribbon
        text={<FormattedMessage id="EldenEquivalentStockCodesLog.pageTitle" defaultMessage="Elden Equivalent Stock Codes Log" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={19}></Col>
              <Col md={1}>
                <Tooltip title={<FormattedMessage id="EldenEquivalentStockCodesLog.Authority" defaultMessage="Authority" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      this.setState({
                        modalAuthorityShow: true,
                        random: Math.random(),
                      });
                    }}
                    icon={<Visibility fontSize="large" />}
                  ></AntButton>
                </Tooltip>
              </Col>
              <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
              <Col md={1}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <Tooltip title={<FormattedMessage id="EldenEquivalentStockCodesLog.Settings" defaultMessage="Settings" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.setState({ drawerSettingsShow: true });
                      }}
                      icon={<Settings color="error" fontSize="large" />}
                    ></AntButton>
                  </Tooltip>
                )}
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenEquivalentStockCodesLogRevise.Type" defaultMessage="Type" />}
                name="type"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                hidden={this.state.addModal}
              >
                <Radio.Group
                  onChange={e => {
                    this.setState({
                      type: e.target.value
                    });
                  }}
                >
                  <Radio value={"EQUIPMENT"}>Equipment</Radio>
                  <Radio value={"MATERIAL"}>Material</Radio>
                </Radio.Group>
              </Form.Item>

              {!this.state.hiddenItem && <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenEquivalentStockCodesLogRevise.Item" defaultMessage="Item" />}
                name="itemId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                hidden={this.state.addModal}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={this.searchItem}
                  style={{ marginBottom: 0, width: "100%" }}
                  loading={this.state.loadingSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={value => {
                    this.setState({
                      hiddenStockCode: value
                    });
                  }}
                >
                  {Boolean(this.state["itemIdSelectItems"]) &&
                    this.state["itemIdSelectItems"].length > 0 &&
                    this.state["itemIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              }

              {!this.state.hiddenStockCode && <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenEquivalentStockCodesLogRevise.StockCode" defaultMessage="Stock Code" />}
                name="itemId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                hidden={this.state.addModal}
              >
                <Select
                  allowClear
                  showSearch
                  onSearch={this.searchItemCode}
                  style={{ marginBottom: 0, width: "100%" }}
                  loading={this.state.loadingSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={value => {
                    this.setState({
                      hiddenItem: value
                    });
                  }}
                >
                  {Boolean(this.state["stockCodeIdSelectItems"]) &&
                    this.state["stockCodeIdSelectItems"].length > 0 &&
                    this.state["stockCodeIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              }

              <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenEquivalentStockCodesLogRevise.authorityId" defaultMessage="Authority" />}
                name="authorityId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["authorityIdSelectItems"]) &&
                    this.state["authorityIdSelectItems"].length > 0 &&
                    this.state["authorityIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenEquivalentStockCodesLogRevise.code" defaultMessage="Code" />}
                  name="code"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenEquivalentStockCodesLogRevise.description" defaultMessage="Description" />}
                  name="description"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenEquivalentStockCodesLogRevise.effectiveDate" defaultMessage="Effective Date" />}
                  name="effectiveDate"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              }

              <br />
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={this.state.columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>
          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>
          <Drawer
            title={<FormattedMessage id="EldenEquivalentStockCodesLog.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerSettingsShow: false });
            }}
            open={this.state.drawerSettingsShow}
          >
            <PageSettings pageName={"EldenEquivalentStockCodesLog"} />
          </Drawer>
          <DraggableModal
            title={
              <FormattedMessage
                id="EldenEquivalentStockCodesLog.EldenEquivalentStockCodesLogRevise"
                defaultMessage="Revise"
              />
            }
            centered
            width={1200}
            okButtonProps={{ hidden: true }}
            maskClosable={false}
            open={this.state.showEldenEquivalentStockCodesLogReviseModal}
            onCancel={() => {
              this.setState({ showEldenEquivalentStockCodesLogReviseModal: false });
            }}
            content={<EldenEquivalentStockCodesLogRevise />}
          ></DraggableModal>

          <DraggableModal
            title={
              <FormattedMessage
                id="EldenEquivalentStockCodesLog.Authority"
                defaultMessage="Authority"
              />
            }
            centered
            width={1200}
            okButtonProps={{ hidden: true }}
            maskClosable={false}
            open={this.state.modalAuthorityShow}
            onCancel={() => {
              this.setState({ modalAuthorityShow: false });
            }}
            content={<EldenEquivalentStockCodesLogAuthority random={this.state.random} />}
          />

          <DraggableModal
            centered
            width={1200}
            title={<FormattedMessage id="EldenEquivalentStockCodesLogDetail.Detail" defaultMessage="Detail" />}
            visible={this.state.modalDetailShow}
            onCancel={() => {
              this.setState({ modalDetailShow: false });
              this.fillComboboxes();
            }}
            footer={[
              <Button variant="secondary" onClick={() => {
                this.setState({ modalDetailShow: false });
                this.fillComboboxes();
              }}>
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
            content={
              <EldenEquivalentStockCodesLogDetail equivalentStockCodesLogId={this.state.id} authorityId={this.state.authorityId} random={this.state.random} />
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EldenEquivalentStockCodesLog);