import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import {
  Form,
  Table,
  Input,
  DatePicker,
  Tooltip,
  Col,
  Row,
  Alert,
  Button as AntButton
} from "antd";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";


const { RangePicker } = DatePicker;

export class PaymentOrder extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      controllerName: "paymentPlan",
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      selectedRowKeys: [],
      request: false,
      totalOfRequest: 0,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      request: false,
      totalOfRequest: 0,
      selectedRowKeys: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  edit = (row) => {
    document.getElementById("kt_scrolltop").click();
    var chequeHolder = [];
    if (Boolean(row.chequeHolderIds) && row.chequeHolderIds.length > 0) {
      chequeHolder = row.chequeHolderIds;
    }
    var signatureAuthority = [];
    if (Boolean(row.signatureAuthorityIds) && row.signatureAuthorityIds.length > 0) {
      signatureAuthority = row.signatureAuthorityIds;
    }

    this.formRef.current.setFieldsValue({
      ...row,
      chequeHolder: chequeHolder,
      signatureAuthority: signatureAuthority
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  requestButtonClick = () => {
    this.setState({
      request: true,
    });
  }

  save = async () => {
    if (Boolean(this.state.selectedRowKeys) && this.state.selectedRowKeys.length > 0) {
      var newItem = {
        ids: this.state.selectedRowKeys
      }
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveRequest", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showError("Please select payment plan")
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  onSelectChange = (selectedRowKeys) => {
    var totalOfRequest = 0;
    if (Boolean(selectedRowKeys) && selectedRowKeys.length > 0) {
      selectedRowKeys.forEach(element => {
        var row = this.state.tableList.find(p => p.id === element);
        if (Boolean(row) && Boolean(row.amount)) {
          totalOfRequest = totalOfRequest + row.amount;
        }
      });
    }
    this.setState({
      selectedRowKeys,
      totalOfRequest
    });
  };

  render() {
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: record => {
        return {
          disabled: Boolean(record.requestStatus) // Column configuration not to be checked
        };
      }
    };
    const hasSelected = selectedRowKeys.length > 0;

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        title: "S/N",
        key: "id",                
        ellipsis: true,
        width: 50,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title:<FormattedMessage id="PaymentOrder.paymentDate" defaultMessage="Payment Date" />,
        key: "paymentDate",        
        ellipsis: true,
        width: 150,
        render: record => {
          if (Boolean(record.paymentDate)) return moment(record.paymentDate).format("DD/MM/YYYY");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.paymentDate) ? this.state.pagination.paymentDate : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  paymentDate: e.target.value,
                  paymentDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.paymentDateColor) ? this.state.pagination.paymentDateColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="PaymentOrder.amount" defaultMessage="Total Amount" />,
        key: "amount",        
        ellipsis: true,
        width: 100,
        render: record => {
          return <Tooltip title={record.amount}>{record.amount}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.amount) ? this.state.pagination.amount : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  amount: e.target.value,
                  amountColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.amountColor) ? this.state.pagination.amountColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PaymentOrder.reasonForPaymentId" defaultMessage="Reason For Payment" />,
        key: "reasonForPaymentId",        
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.reasonForPaymentName}>{record.reasonForPaymentName}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.reasonForPaymentId) ? this.state.pagination.reasonForPaymentId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  reasonForPaymentId: e.target.value,
                  reasonForPaymentIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.reasonForPaymentIdColor) ? this.state.pagination.reasonForPaymentIdColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PaymentOrder.paymentMode" defaultMessage="Mode Of Payment" />,
        key: "paymentMode",        
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.paymentMode}>{record.paymentMode}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.paymentMode) ? this.state.pagination.paymentMode : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  paymentMode: e.target.value,
                  paymentModeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.paymentModeColor) ? this.state.pagination.paymentModeColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PaymentOrder.paymentGroupId" defaultMessage="Payment Group" />,
        key: "paymentGroupId",        
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.paymentGroupName}>{record.paymentGroupName}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.paymentGroupId) ? this.state.pagination.paymentGroupId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  paymentGroupId: e.target.value,
                  paymentGroupIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.paymentGroupIdColor) ? this.state.pagination.paymentGroupIdColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PaymentOrder.paymentDescription" defaultMessage="Payment Description" />,
        key: "paymentDescription",        
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.paymentDescription}>{record.paymentDescription}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.paymentDescription) ? this.state.pagination.paymentDescription : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  paymentDescription: e.target.value,
                  paymentDescriptionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.paymentDescriptionColor) ? this.state.pagination.paymentDescriptionColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PaymentOrder.creditorId" defaultMessage="Creditor" />,
        key: "creditorId",        
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.creditorName}>{record.creditorName}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.creditorId) ? this.state.pagination.creditorId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  creditorId: e.target.value,
                  creditorIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.creditorIdColor) ? this.state.pagination.creditorIdColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PaymentOrder.paymentDocument" defaultMessage="Payment Document" />,
        key: "paymentDocument",        
        ellipsis: true,
        width: 100,
        render: record => {
          return "";
        },
      },
      {
        title: <FormattedMessage id="PaymentOrder.requestStatus" defaultMessage="Status" />,
        key: "requestStatus",        
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.requestStatus}>{record.requestStatus}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.requestStatus) ? this.state.pagination.requestStatus : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  requestStatus: e.target.value,
                  requestStatusColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.requestStatusColor) ? this.state.pagination.requestStatusColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: "Last Update",
        key: "lastUpdateDate",        
        ellipsis: true,
        width: 150,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={10}></Col>
            <Col md={6}>
              <Alert style={{ backgroundColor: "#f5f5f5", borderColor: "#f5f5f5" }} message="Total of Request:"
                action={
                  <AntButton size="small" type="primary" ghost>
                    {this.state.totalOfRequest}
                  </AntButton>
                } />
            </Col>
            <Col md={4}>
              <Button id="RequestButton" onClick={this.requestButtonClick} style={{ width: "100%" }} variant="primary">
                <FormattedMessage id="RequestButton" defaultMessage="Request" />
              </Button>
            </Col>
            <Col md={4}>
              <Button id="SaveButton" disabled={!hasSelected} onClick={this.save} style={{ width: "100%" }} variant="success">
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </Button>
            </Col>
          </Row>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {this.state.request ?
            <Table
              rowKey="id"
              bordered
              rowSelection={rowSelection}
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            /> :
            <Table
              rowKey="id"
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />}
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOrder);