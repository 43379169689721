import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Progress, Spin } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import {
  Form,
  Table,
  Select,
  Radio,
  Cascader,
  Button as AntButton,
  Col,
  Row,
  Skeleton,
  Steps,
  Tooltip
} from "antd";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import ProjectTakeOffQuantity from "./ProjectTakeOffQuantity";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";
import DraggableModal from "../../Components/DraggableModal";

const { Step } = Steps;
const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class ProjectTakeOff extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "projectTakeOff",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      showBreakDownModal: false,
      modalPdfViewer: false,
      pdfFile: "",
      modalsendAll: false,
      loadingButtonSubmit: false,

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [],
      projectList: [],
      itemGroupIdSelectItems: null,
      itemDescriptionIdSelectItems: null,
      materialTypeIdSelectItems: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      statusList: [],
      modalStatusShow: false,
      deliveredAmount: 0,
      searchCriteriaModels: [],
      saveLoading: false,
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    fillFilterOperations();
  };

  fillComboboxes = async () => {
    var responseitemGroupId = await handleRequest("GET", "/api/stockCard/category");
    if (responseitemGroupId.type === "ERROR") {
      error(responseitemGroupId);
    } else {
      this.setState({
        itemGroupIdSelectItems: Boolean(responseitemGroupId.data) ? responseitemGroupId.data : []
      });
    }

    const responseTree = await handleRequest("GET", "/api/stockCard/tree");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      this.setState({
        nodes: responseTree.data
      });
    }

    const responseProjectList = await handleRequest("GET", "/api/" + this.state.controllerName + "/projectList");
    if (responseProjectList.data.length !== 0) {
      generateList(responseProjectList.data);
      this.setState({
        projectList: responseProjectList.data
      });
    }

    var responseType = await handleRequest("GET", "/api/" + this.state.controllerName + "/types");
    if (responseType.type === "ERROR") {
      error(responseType);
    } else {
      this.setState({
        typeSelectItems: Boolean(responseType.data) ? responseType.data : []
      });
    }
  };

  onChangeItemGroupId = async value => {
    if (Boolean(value)) {
      var responseitemDescriptionId = await handleRequest("GET", "/api/projectTakeOff/itemDescriptionIdSelectItems/" + value);
      if (responseitemDescriptionId.type === "ERROR") {
        error(responseitemDescriptionId);
      } else {
        this.setState({
          itemDescriptionIdSelectItems: Boolean(responseitemDescriptionId.data) ? responseitemDescriptionId.data : []
        });
      }
    } else {
      this.setState({
        itemDescriptionIdSelectItems: []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];
    this.onChangeItemGroupId(row.itemGroupId);
    var materialType = dataList.find(p => p.key === row.materialTypeId);
    let materialTypeId = [];
    if (Boolean(materialType)) {
      materialTypeId = materialType.lineage;
      materialTypeId.push(materialType.key);
    }

    this.formRef.current.setFieldsValue({
      ...row,
      materialTypeId: materialTypeId
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/projectTakeOff/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/projectTakeOff/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.key = element.id;
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    this.setState({ saveLoading: true });
    if (Boolean(this.props.companyId) && Boolean(this.props.companyProjectId)) {
      const newItem = { ...values, id: this.state.id };

      newItem.materialTypeId =
        Boolean(values.materialTypeId) && values.materialTypeId.length > 0 ? values.materialTypeId[values.materialTypeId.length - 1] : null;

      newItem.companyId = this.props.companyId;
      newItem.projectId = this.props.projectId;
      newItem.companyProjectId = this.props.companyProjectId;

      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/projectTakeOff", newItem);
      } else {
        response = await handleRequest("PUT", "/api/projectTakeOff/" + newItem.id, newItem);
      }
      if (response.type === "ERROR") {
        error(response);
        this.setState({ saveLoading: false });
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.setState({ saveLoading: false });
      }
    } else {
      showError("Company/Project not found.");
      this.setState({ saveLoading: false });
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      companyId: this.props.companyId,
      projectId: this.props.projectId,
      companyProjectId: this.props.companyProjectId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/projectTakeOff/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  sendAllModal = row => {
    this.setState({
      modalsendAll: true,
      id: row.id
    });
  };

  showOrHidesendAllModal = () => {
    this.setState({
      modalsendAll: !this.state.modalsendAll
    });
  };

  sendAll = async () => {
    const closedItem = {
      id: this.state.id
    };
    var response = await handleRequest("POST", "/api/projectTakeOff/sendAll/" + closedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalsendAll: false
      });
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu
          row={record}
          onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.edit.bind(this)}
          onAddQuantity={this.addBreakDownModal.bind(this)}
          onPdfToReport={this.generateReport}
          onSendAll={this.sendAllModal.bind(this)}
        />
      </React.Fragment>
    );
  };

  addBreakDownModal = record => {
    this.setState({
      random: Math.random(),
      showBreakDownModal: true,
      selectedItemBreakdown: record
    });
  };

  takeOffStatus = async row => {
    let url = "/api/projectTakeOff/getStatus/" + row.id;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        statusList: response.data,
        modalStatusShow: true
      });
    }
  };

  rfqStatus = async row => {
    let url = "/api/requestForQuotation/getStatus/" + row.id;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        statusList: response.data,
        modalStatusShow: true
      });
    }
  };

  generateReport = async record => {
    let url = "/api/" + this.state.controllerName + "/getReport/" + record.id;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });
      showError("There is no created report for selected item");
    }
  };

  generateReportList = async () => {
    this.setState({ loadingButtonSubmit: true });
    let url = "/api/" + this.state.controllerName + "/getReportList/" + this.props.companyProjectId;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
      this.setState({ loadingButtonSubmit: false });
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true,
        loadingButtonSubmit: false,
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false,
        loadingButtonSubmit: false,
      });
      showError("There is no created report for selected item");
    }
  };
  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showReport = async url => {
    try {
      const fileRealUrl = url;

      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true
      });
    } catch (error) {
      alert("File cant found.");
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  render() {
    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.printButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        width: 100,
        render: this.action,
        responsive: ["sm"],
        fixed: "left"
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        fixed: "left",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ProjectTakeOff.Status" defaultMessage="Status" />,
        key: "status",
        width: 100,
        render: record => {
          return (
            Boolean(record.status) && (
              <React.Fragment>
                <div
                  style={{
                    cursor: "pointer",
                    color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                    textDecoration: "underline"
                  }}
                  onClick={e => this.takeOffStatus(record)}
                >
                  {record.status}
                </div>
              </React.Fragment>
            )
          );
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.status === "Deleted" ? "#d3d3d3" : null
          }
        })
      },
      {
        title: <FormattedMessage id="ProjectTakeOff.type" defaultMessage="Type" />,
        fixed: "left",
        key: "type",
        width: 100,
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                  textDecoration: "underline"
                }}
                onClick={e => this.generateReport(record)}
              >
                {record.type}
              </div>
            </React.Fragment>
          );
        },
        ...getColumnFilter({
          inputName: "type", inputType: "SELECT",
          selectItems: Boolean(this.state["typeSelectItems"]) ? this.state["typeSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="ProjectTakeOff.itemGroupId" defaultMessage="Item Group" />,
        key: "itemGroupId",
        width: 100,
        render: record => {
          if (Boolean(record.itemGroup)) {
            return record.itemGroup.name;
          } else {
            return "";
          }
        },
        ...getColumnFilter({
          inputName: "itemGroupId", inputType: "SELECT",
          selectItems: Boolean(this.state["itemGroupIdSelectItems"]) ? this.state["itemGroupIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="ProjectTakeOff.itemDescriptionId" defaultMessage="Item Description" />,
        key: "itemDescriptionId",
        ellipsis: true,
        width: 100,
        render: record => {
          if (Boolean(record.itemDescription)) {
            return record.itemDescription.name;
          } else {
            return "";
          }
        },
        ...getColumnFilter({
          inputName: "itemDescriptionId", inputType: "SELECT",
          selectItems: Boolean(this.state["itemDescriptionIdSelectItems"]) ? this.state["itemDescriptionIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="ProjectTakeOff.materialTypeId" defaultMessage="Material Type" />,
        key: "materialTypeId",
        ellipsis: true,
        width: 100,
        render: record => {
          if (Boolean(record.materialType)) {
            return record.materialType.name;
          } else {
            return "";
          }
        },
        ...getColumnFilter({
          inputName: "materialTypeId", inputType: "CASCADER",
          selectItems: Boolean(this.state["nodes"]) ? this.state["nodes"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="ProjectTakeOff.DeliveredAmount" defaultMessage="Delivered Amount" />,
        key: "deliveryAmount",
        width: 100,
        render: record => {
          return <Progress type="circle" percent={record.deliveryPercent} success={{ percent: record.deliveryPercent }}
            // format={() => Boolean(record.deliveryPercent) ? record.deliveryPercent : 0}
            width={85} />

          //<Progress type="circle" percent={record.deliveryPercent} success={{ percent: record.deliveryPercent }} width={85} />;
        },
      },
      {
        title: <FormattedMessage id="ProjectTakeOff.LastUpdate" defaultMessage="Last Update" />,
        key: "lastUpdateDate",
        width: 100,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        ...getColumnFilter({
          inputName: "lastUpdated", inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        })
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];

      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={22}></Col>
            <Col md={1}>
              <Spin spinning={this.state.loadingButtonSubmit}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={this.generateReportList}
                  icon={<PictureAsPdfIcon style={{ color: "red" }} fontSize="large" />}
                ></AntButton>
              </Spin>
            </Col>

            {/* <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col> */}

            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProjectTakeOff.type" defaultMessage="Type" />}
                name="type"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Radio.Group>
                  <Radio value={"EQUIPMENT"}>{<FormattedMessage id="ProjectTakeOff.Equipment" defaultMessage="Equipment" />}</Radio>
                  <Radio value={"MATERIAL"}>{<FormattedMessage id="ProjectTakeOff.Material" defaultMessage="Material" />}</Radio>
                </Radio.Group>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProjectTakeOff.itemGroupId" defaultMessage="Item Group" />}
                name="itemGroupId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  onChange={value => {
                    this.onChangeItemGroupId(value);
                  }}
                >
                  {Boolean(this.state["itemGroupIdSelectItems"]) &&
                    this.state["itemGroupIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProjectTakeOff.itemDescriptionId" defaultMessage="Item Description" />}
                name="itemDescriptionId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  style={{ width: "100%" }}>
                  {Boolean(this.state["itemDescriptionIdSelectItems"]) &&
                    this.state["itemDescriptionIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProjectTakeOff.materialTypeId" defaultMessage="Material Type" />}
                name="materialTypeId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Cascader showSearch allowClear optionFilterProp="children" style={{ width: "100%" }} options={this.state.nodes} />
              </Form.Item>
            }
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Spin spinning={this.state.saveLoading}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Spin>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Spin spinning={this.state.saveLoading}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Spin>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span>
                      <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span>
                      <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}

          <Table
            bordered
            size="small"
            // scroll={{ x: 900, y: 1200, scrollToFirstRowOnChange: true }}
            components={{
              header: {
                cell: ResizableTitle
              }
            }}
            columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
            expandable={{
              expandedRowRender: record => Boolean(record) && <ProjectTakeOffQuantity main={this.props.main} selectedItemBreakdown={record}
                companyId={this.props.companyId} projectId={this.props.projectId} companyProjectId={this.props.companyProjectId} random={this.state.random}></ProjectTakeOffQuantity>,
              rowExpandable: () => true,
              onExpand: (expanded, record) => {
                this.setState({ random: Math.random() });
              },
            }}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.modalsendAll} onHide={this.showOrHidesendAllModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be sent !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Selected Project Item will sended to Request For Quotation. Are yo sure ? </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHidesendAllModal}>
              Cancel
            </Button>
            <Button variant="dark" onClick={this.sendAll}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>

        <DraggableModal
          title={<FormattedMessage id="GeneralPDFReportViewer" defaultMessage="PDF Report Viewer" />}
          open={this.state.modalPdfViewer}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalPdfViewer: false })}
          width={1200}
          centered
          content={
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div style={{ marginTop: "2rem" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>
          }
        />

        <DraggableModal
          title="Status"
          visible={this.state.modalStatusShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalStatusShow: false })}
          width={700}
          centered
          content={
            <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 ml-5 align-content-center">
              <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
              <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
              <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
              <Steps percent={60} direction="vertical" className="pl-5-custom">
                {Boolean(this.state.statusList) &&
                  this.state.statusList.length > 0 &&
                  this.state.statusList.map((item, index) => {
                    return (
                      <Step
                        icon={item.icon}
                        title={item.status + (item.count >= 0 ? " " + item.requestCount + " - " + item.count : "")}
                        status={
                          item.color === "green"
                            ? "finish"
                            : item.color === "blue"
                              ? "process"
                              : item.status === "Satınalma Siparişi"
                                ? "wait"
                                : item.status === "Tedarikçi Üretimi"
                                  ? "wait"
                                  : item.status === "Malzeme Teslimi"
                                    ? "wait"
                                    : "wait"
                        }
                        description={item.color === "green" || item.color === "blue" ? moment(item.date).format("DD-MM-YYYY HH:mm:ss") : ""}
                      />
                    );
                  })}
              </Steps>
            </div>
          }
        >

        </DraggableModal>

        {this.state.showBreakDownModal && (
          <DraggableModal
            centered
            width={1500}
            title="Project Take Off Quantity"
            visible={this.state.showBreakDownModal}
            onCancel={() => {
              this.setState({ showBreakDownModal: false });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showBreakDownModal: false
                  });
                }}
              >
                Close
              </Button>
            ]}

            content={
              <>
                {this.state.showBreakDownModal && (
                  <ProjectTakeOffQuantity main={this.props.main} selectedItemBreakdown={this.state.selectedItemBreakdown}
                    companyId={this.props.companyId} projectId={this.props.projectId} companyProjectId={this.props.companyProjectId} random={this.state.random}></ProjectTakeOffQuantity>
                )}
              </>
            }
          >

          </DraggableModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTakeOff);
