import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Table, Badge, Drawer, Row, Col, Tooltip, Button, Form, Input } from "antd";
import { handleRequest } from "../../ApiConnector";
import { ProjectTakeOff } from "./ProjectTakeOff";
import { error } from "../../MessageHelper";
import SettingsIcon from "@material-ui/icons/Settings";
import store from "../../../redux/store";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import { UserOutlined } from "@ant-design/icons";
import DraggableModal from "../../Components/DraggableModal";
import { Assignment } from "@material-ui/icons";

export class ProjectTakeOffMain extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "projectTakeOff",
      tableList: [],

      loading: false,
      sortField: "",
      sortOrder: "",
      user: store.getState().auth.user
    };
  }

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {};

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    let url = "/api/" + this.state.controllerName + "/getCompanyProject";
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.length
          },
          totalRecords: response.data.length,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="ProjectTakeOff.companyProject" defaultMessage="Company/Project" />,
        key: "id",
        render: record => {
          return record.companyProject;
        }
      },
      {
        title: <FormattedMessage id="ProjectTakeOff.ProfilImage" defaultMessage="Profil Image" />,
        key: "id",
        render: record => {
          return record.profilImage;
        }
      },
      {
        key: "id",
        render: record => {
          return record.userName;
        }
      },
      {
        key: "id",
        render: record => {
          return record.totalPercent;
        }
      },

      {
        key: "id",
        render: record => {
          return (
            <>
              <span
                style={{ marginRight: 8, cursor: "pointer" }}
                onClick={() => {
                  this.setState({
                    showSummaryModal: true
                  });
                }}
              >
                <Assignment style={{ marginRight: 8 }} />
              </span>
            </>
          );
        }
      }
    ];

    return (
      <Badge.Ribbon text="Project Item Take Off List" placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Row gutter={[16, 16]}>
            <Col md={23}></Col>
            <Col md={1}>
              {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                <Tooltip title={<FormattedMessage id="ProjectTakeOffMain.Settings" defaultMessage="Settings" />}>
                  <Button
                    style={{ border: "0px" }}
                    onClick={() => {
                      this.setState({
                        drawerSettingsShow: true
                      });
                    }}
                    icon={<SettingsIcon color="error" fontSize="large" />}
                  ></Button>
                </Tooltip>
              )}
            </Col>
          </Row>

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              rowKey={"index"}
              bordered
              // scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              pagination={false}
              expandable={{
                expandedRowRender: record =>
                  Boolean(record) && (
                    <ProjectTakeOff
                      main={record}
                      companyId={record.companyId}
                      projectId={record.projectId}
                      companyProjectId={record.companyProjectId}
                      random={this.state.random}
                    ></ProjectTakeOff>
                  ),
                rowExpandable: () => true,
                onExpand: (expanded, record) => {
                  this.setState({ random: Math.random() });
                }
              }}
            />
          </div>
        </div>

        <Drawer
          title={<FormattedMessage id="ProjectTakeOffMain.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"Project_Take_Off"} />
        </Drawer>

        <DraggableModal
          title={<FormattedMessage id="ProjectTakeOffMain.Summary" defaultMessage="Summary" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showSummaryModal}
          onCancel={() => {
            this.setState({ showSummaryModal: false });
          }}
          content={
            <>
              <Form
                initialValues={{ remember: false }}
                // onFinish={this.save}
                // onFinishFailed={}
                // ref={this.formRef}
              >
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProjectTakeOffMain.TotalItems" defaultMessage="Total Items" />}
                    name="totalItems"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProjectTakeOffMain.Sent" defaultMessage="Sent" />}
                    name="sent"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProjectTakeOffMain.Balance" defaultMessage="Balance" />}
                    name="balance"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </>
          }
        ></DraggableModal>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTakeOffMain);
