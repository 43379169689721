/* eslint-disable */

import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import MenuIcon from '@material-ui/icons/Menu';
import PostAddOutlined from '@material-ui/icons/PostAddOutlined';
import ShareIcon from '@material-ui/icons/Share';


export default ({ row, onDeleteRow, onEditRow, onReviseRow, onEncode, onReportRow, onShareRow, size, editBgColor, deleteBgColor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteRow = () => {
    if (onDeleteRow) {
      onDeleteRow(row);
      setAnchorEl(null);
    }
  };

  const editRow = () => {
    if (onEditRow) {
      onEditRow(row);
      setAnchorEl(null);
    }
  };

  const encode = () => {
    if (onEncode) {
      onEncode(row);
      setAnchorEl(null);
    }
  };

  const showReport = () => {
    if (onReportRow) {
      onReportRow(row);
      setAnchorEl(null);
    }
  };

  const reviseRow = () => {
    if (onReviseRow) {
      onReviseRow(row);
      setAnchorEl(null);
    }
  };

  const shareRow = () => {
    if (onShareRow) {
      onShareRow(row);
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu" // flaticon-layer
        aria-haspopup="true"
        onClick={handleClick}
        size={size}
      >
        {/* <i className={`kt-menu__link-icon  flaticon-layer`} /> */}
        <MenuIcon color="primary" />
        {/* <MoreVertIcon /> */}
      </IconButton>
      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

        <MenuItem onClick={editRow} style={{ backgroundColor: '#' + editBgColor }}>
          <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">
            Edit
          </Typography>
        </MenuItem>
        <Divider />

        <MenuItem onClick={reviseRow} style={{ backgroundColor: '#' + editBgColor }}>
          <ListItemIcon>
            <AccountTreeIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">
            Revise
          </Typography>
        </MenuItem>
        {!(Boolean(row.response) && row.response.trim().length > 0) &&
          <>
            <Divider />

            <MenuItem onClick={encode} style={{ backgroundColor: '#' + editBgColor }}>
              <ListItemIcon>
                <AccountTreeIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <Typography variant="inherit">
                Encode Response
              </Typography>
            </MenuItem></>}

        <Divider />

        <MenuItem onClick={deleteRow} style={{ backgroundColor: '#' + deleteBgColor }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <Typography variant="inherit">
            Delete
          </Typography>
        </MenuItem>

        <Divider />

        <MenuItem onClick={showReport} style={{ backgroundColor: '#' + editBgColor }}>
          <ListItemIcon>
            <PostAddOutlined fontSize="small" color="info" />
          </ListItemIcon>
          <Typography variant="inherit">
            Report
          </Typography>
        </MenuItem>

        <Divider />

        <MenuItem onClick={shareRow} style={{ backgroundColor: '#' + editBgColor }}>
          <ListItemIcon>
            <ShareIcon fontSize="small" color="info" />
          </ListItemIcon>
          <Typography variant="inherit">
            Share
          </Typography>
        </MenuItem>

      </Menu>
    </div>
  );
};