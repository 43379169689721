import "antd/dist/antd.css";
import {connect} from "react-redux";
import React, {Component} from "react";
import {FormattedMessage} from "react-intl";
import {Button, Modal} from "react-bootstrap";
import {FilterOutlined} from "@ant-design/icons";
import {showError, showSuccess, error} from "../../MessageHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import {
    Form,
    Table,
    Input,
    Select,
    InputNumber,
    Checkbox,
    Radio,
    Divider,
    DatePicker,
    Cascader,
    TimePicker,
    Switch,
    Button as AntButton,
    Col,
    Row, Typography,
    Modal as AntModal, Spin
} from "antd";
import {ExcelReader} from "../excel/ExcelReader";
import moment from "moment";
import {API_BASE_ROOT, handleRequest} from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import {PlusOutlined, CloseOutlined, CheckOutlined, MinusOutlined, MinusCircleOutlined} from "@ant-design/icons";
import {Upload} from "antd";
import {UploadOutlined, AlignCenterOutlined} from "@ant-design/icons";
import {DownloadOutlined} from "@ant-design/icons";
import {Table as TableBoot} from "react-bootstrap";
import RestorePageTwoTone from "@material-ui/icons/RestorePageTwoTone";


const {RangePicker} = DatePicker;
const {Option} = Select;
const {TextArea} = Input;
const {Text} = Typography;

export class AbsenceInfractionsSub extends Component {
    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            exportList: [],
            exportClick: false,
            hideUpdate: true,
            hideSave: false,
            hideInputs: true,
            lastUpdateDate: [],

            id: null,
            tableList: [],
            filePath: "",
            fileList: [],
            nodes: [], // for cascader.
            AbsencePeriodEquationsSelectItems: null,
            absencePeriodUnitSelectItems: null,
            deductionTypeSelectItems: null,
            DeductionUnitSelectItems: null,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
                total: 0
            },
            loading: false,
            sortField: "",
            sortOrder: "",

            excelData: []
        };
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        const {pagination} = this.state;
        this.restartTable({pagination});
        this.fillComboboxes();
    };

    componentDidUpdate = prevProps => {
        let random = this.props.random;
        if (random !== prevProps.random) this.initialize();
    };

    fillComboboxes = async () => {
        var responseAbsencePeriod = await handleRequest("GET", "/api/absenceInfractions/absencePeriods");
        if (responseAbsencePeriod.type === "ERROR") {
            error(responseAbsencePeriod);
        } else {
            this.setState({
                AbsencePeriodEquationsSelectItems: Boolean(responseAbsencePeriod.data) ? responseAbsencePeriod.data : []
            });
        }
        var responseabsencePeriodUnit = await handleRequest("GET", "/api/codes/type/absencePeriodUnit");
        if (responseabsencePeriodUnit.type === "ERROR") {
            error(responseabsencePeriodUnit);
        } else {
            this.setState({
                absencePeriodUnitSelectItems: Boolean(responseabsencePeriodUnit.data) ? responseabsencePeriodUnit.data : []
            });
        }
        var responsedeductionType = await handleRequest("GET", "/api/absenceInfractions/durations");
        if (responsedeductionType.type === "ERROR") {
            error(responsedeductionType);
        } else {
            this.setState({
                deductionTypeSelectItems: Boolean(responsedeductionType.data) ? responsedeductionType.data : [],
            });
        }

    };

    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hideInputs: true
        });
        this.resetInputs();
    };

    resetInputs = () => {
        this.formRef.current.resetFields();
        this.setState({
            id: null,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
                total: this.state.totalRecords
            },
            loading: false,
            sortField: "",
            sortOrder: "",
            filePath: "",
            fileList: []
        });
    };

    edit = row => {
        document.getElementById("kt_scrolltop").click();

        let fileList = [];

        let additionalDays = [];
        if (Boolean(row.additionalDays) && row.additionalDays.length > 0) {
            row.additionalDays.forEach((element, index) => {
                additionalDays.push(element.additionalDay);
            });
        }

        let offDayList = [];
        if (Boolean(row.offDayList) && row.offDayList.length > 0) {
            row.offDayList.forEach((element, index) => {
                offDayList.push(element.dayOfWeek);
            });
        }

        let overtimeCriterias = [];
        if (Boolean(row.overtimeCriterias) && row.overtimeCriterias.length > 0) {
            row.overtimeCriterias.forEach((element, index) => {
                overtimeCriterias.push(element.overtimeCriteria);
            });
        }

        let absencePeriodList = [];
        absencePeriodList.push(row);

        let absencePeriodUnitList = [];
        absencePeriodUnitList.push(row);

        let deductionTypeList = [];
        deductionTypeList.push(row);

        let deductionList = [];
        deductionList.push(row);

        this.formRef.current.setFieldsValue({
            ...row,
            effectiveDate: Boolean(row.effectiveDate) ? moment(row.effectiveDate) : null,
        });

        this.setState({
            hideUpdate: false,
            hideSave: true,
            hideInputs: false,
            id: row.id
        });
    };

    deleteModal = row => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    };

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        };
        var response = await handleRequest("DELETE", "/api/absenceInfractions/" + deletedItem.id);

        if (response.type === "ERROR") {
            error(response);
        } else {
            const {pagination} = this.state;
            this.restartTable({pagination});
            showSuccess();
            this.setState({
                id: null,
                modalDeleteShow: false
            });
        }
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters});
    };

    restartTable = async (params = {}) => {
        this.setState({loading: true});
        const newObj = this.createWhere(params);
        let url = "/api/absenceInfractions/paging";
        const response = await handleRequest("POST", url, newObj);
        if (response.type === "ERROR") {
            error(response);
            this.setState({
                loading: false,
                tableList: [],
                pagination: {
                    ...params.pagination,
                    total: 0
                },
                totalRecords: 0,
                sortField: "",
                sortOrder: ""
            });
        } else {
            if (response.data.length !== 0) {
                let list = response.data.data;
                list.forEach((element, index) => {
                    element.index = index + 1;
                });
                this.setState({
                    loading: false,
                    tableList: list,
                    pagination: {
                        ...params.pagination,
                        total: response.data.totalRecords
                    },
                    totalRecords: response.data.totalRecords,
                    sortField: params.sortField,
                    sortOrder: params.sortOrder
                });
            } else {
                this.setState({
                    loading: false,
                    tableList: [],
                    pagination: {
                        ...params.pagination,
                        total: 0
                    },
                    totalRecords: 0,
                    sortField: "",
                    sortOrder: ""
                });
            }
        }
    };

    onClickCreateNew = () => {
        this.resetInputs();
        if (this.state.hideInputs) {
            this.setState({
                hideInputs: false,
                hideSave: false,
                hideUpdate: true
            });
        } else {
            this.setState({
                hideInputs: true,
                hideSave: true,
                hideUpdate: false
            });
        }
    };

    save = async values => {
        const newItem = {...values, id: this.state.id};
        let additionalDays = [];
        if (Boolean(newItem.additionalDays) && newItem.additionalDays.length > 0) {
            newItem.additionalDays.forEach((element, index) => {
                additionalDays.push({additionalDay: element});
            });
        }

        let offDayList = [];
        if (Boolean(newItem.offDayList) && newItem.offDayList.length > 0) {
            newItem.offDayList.forEach((element, index) => {
                offDayList.push({dayOfWeek: element});
            });
        }

        let overtimeCriterias = [];
        if (Boolean(newItem.overtimeCriterias) && newItem.overtimeCriterias.length > 0) {
            newItem.overtimeCriterias.forEach((element, index) => {
                overtimeCriterias.push({overtimeCriteria: element});
            });
        }

        newItem.additionalDays = additionalDays;
        newItem.offDayList = offDayList;
        newItem.overtimeCriterias = overtimeCriterias;

        if (newItem.id === null) {
            var response = await handleRequest("POST", "/api/absenceInfractions", newItem);
        } else {
            response = await handleRequest("PUT", "/api/absenceInfractions/" + newItem.id, newItem);
        }

        if (response.type === "ERROR") {
            error(response);
        } else {
            this.cancel();
            const {pagination} = this.state;
            this.restartTable({pagination});
            showSuccess();
        }
    };

    createWhere(params) {
        console.log(params);
        if (Boolean(params._pagination)) params.pagination = params._pagination;

        var lastUpdateDateStart = null,
            lastUpdateDateEnd = null,
            sortField = "",
            sortOrder = "";
        if (Boolean(params) && Boolean(params.pagination)) {
            if (Boolean(params.pagination.lastUpdateDate)) {
                lastUpdateDateStart = params.pagination.lastUpdateDate[0];
                lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
            }
            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }
        }
        const newObj = {
            ...params.pagination,
            id: null,
            lastUpdateDateStart: lastUpdateDateStart,
            lastUpdateDateEnd: lastUpdateDateEnd,
            firstResult: params.pagination.current - 1,
            maxResults: params.pagination.pageSize,
            sortField: sortField,
            sortOrder: sortOrder
        };
        return newObj;
    }

    handleExportExcel = async () => {
        this.setState({
            exportClick: true
        });
    };

    handleUploadExcel = async excelJson => {
        try {
            var list = [];
            if (excelJson === null) showError("Select an excel file");
            else {
                excelJson.forEach(element => {
                    const obj = {
                        //name: element["Name"],
                        //shortName: element["Short Name"],
                        // [IMPORT_OBJECT]
                    };
                    list.push(obj);
                });
                if (Boolean(list) && list.length > 0) {
                    var response = await handleRequest("POST", "/api/absenceInfractions/import", list);
                    if (response.type === "ERROR") {
                        showError("No records to be added");
                    } else {
                        this.resetInputs();
                        const {pagination} = this.state;
                        this.restartTable({pagination});
                        showSuccess();
                    }
                } else {
                    showError("No records to be added");
                }
            }
        } catch (error) {
            showError("");
        }
    };

    handleChangeAlarmType = e => {
        this.setState({
            alarmTypeId: e
        });
    };

    addCode = async codeType => {
        const {codeName} = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: codeType
            };

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: null,
                    showCodeModal: false
                });
                this.fillCode(codeType);
            }
        } else {
            showError("Please type value");
        }
    };

    fillCode = async codeType => {
        const response = await handleRequest("GET", "/api/codes/type/" + codeType);
        if (Boolean(response.data)) {
            this.setState({
                [codeType + "SelectItems"]: response.data
            });
        }
    };

    action = record => {
        return (
            <React.Fragment>
                <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)}/>
            </React.Fragment>
        );
    };

    render() {
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };

        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 8}
        };
        const layoutVacation = {
            labelCol: {span: 8},
            wrapperCol: {span: 16}
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 16,
                    offset: 0
                },
                sm: {
                    span: 16,
                    offset: 8
                }
            }
        };
        const columns = [
            {
                key: "key",
                title: "Actions",
                render: this.action
            },

            {
                title: "S/N",
                key: "id",
                render: (value, row, index) => {
                    return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
                }
            },

            {
                title: <FormattedMessage id="AbsenceInfractions.absencePeriod" defaultMessage="Absence Period"/>,
                key: "absencePeriod",
                render: record => {
                    return record.absencePeriod;
                },
                filterDropdown: () => (
                    <div style={{padding: 8}}>
                        <Input
                            style={{flex: "auto"}}
                            value={Boolean(this.state.pagination.absencePeriod) ? this.state.pagination.absencePeriod : null}
                            onChange={async e => {
                                const {pagination} = this.state;
                                let _pagination = {
                                    ...pagination,
                                    absencePeriod: e.target.value,
                                    absencePeriodColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                                };
                                this.setState({
                                    pagination: _pagination
                                });

                                this.restartTable({
                                    _pagination,
                                    sortField: this.state.sortField,
                                    sortOrder: this.state.sortOrder
                                });
                            }}
                        />
                    </div>
                ),
                filterIcon: filtered => (
                    <FilterOutlined
                        style={{color: Boolean(this.state.pagination.absencePeriodColor) ? this.state.pagination.absencePeriodColor : "#bfbfbf"}}
                    />
                ),

                sorter: true
            },
            {
                title: <FormattedMessage id="AbsenceInfractions.penaltyScore" defaultMessage="Penalty Score"/>,
                key: "penaltyScore",
                render: record => {
                    return record.penaltyScore;
                },
                filterDropdown: () => (
                    <div style={{padding: 8}}>
                        <Input
                            style={{flex: "auto"}}
                            value={Boolean(this.state.pagination.penaltyScore) ? this.state.pagination.penaltyScore : null}
                            onChange={async e => {
                                const {pagination} = this.state;
                                let _pagination = {
                                    ...pagination,
                                    penaltyScore: e.target.value,
                                    penaltyScoreColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                                };
                                this.setState({
                                    pagination: _pagination
                                });

                                this.restartTable({
                                    _pagination,
                                    sortField: this.state.sortField,
                                    sortOrder: this.state.sortOrder
                                });
                            }}
                        />
                    </div>
                ),
                filterIcon: filtered => (
                    <FilterOutlined
                        style={{color: Boolean(this.state.pagination.penaltyScoreColor) ? this.state.pagination.penaltyScoreColor : "#bfbfbf"}}
                    />
                ),

                sorter: true
            },
            {
                title: <FormattedMessage id="AbsenceInfractions.deduction" defaultMessage="Deduction"/>,
                key: "deduction",
                render: record => {
                    return record.deductionType + " - " + record.deduction;
                },
                filterDropdown: () => (
                    <div style={{padding: 8}}>
                        <Input
                            style={{flex: "auto"}}
                            value={Boolean(this.state.pagination.deduction) ? this.state.pagination.deduction : null}
                            onChange={async e => {
                                const {pagination} = this.state;
                                let _pagination = {
                                    ...pagination,
                                    deduction: e.target.value,
                                    deductionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                                };
                                this.setState({
                                    pagination: _pagination
                                });

                                this.restartTable({
                                    _pagination,
                                    sortField: this.state.sortField,
                                    sortOrder: this.state.sortOrder
                                });
                            }}
                        />
                    </div>
                ),
                filterIcon: filtered => (
                    <FilterOutlined
                        style={{color: Boolean(this.state.pagination.deductionColor) ? this.state.pagination.deductionColor : "#bfbfbf"}}
                    />
                ),

                sorter: true
            },

            {
                title: "Last Update",
                key: "lastUpdateDate",
                render: record => {
                    return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
                },
                filterDropdown: () => (
                    <div style={{padding: 8}}>
                        <RangePicker
                            value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
                            format="DD-MM-YYYY HH:mm"
                            showTime={{format: "HH:mm"}}
                            onChange={this.onChangeLastUpdateDate}
                        />
                    </div>
                ),
                filterIcon: filtered => <FilterOutlined style={{color: this.state.lastUpdatedColor}}/>,
                sorter: true
            }
        ];

        const excelExport = (
            <>
                {/* {this.state.exportClick
                    ? this.setState({
                        exportClick: false
                    })
                    : ""}
                {this.state.exportClick ? (
                    <ExcelFile filename="Products" hideElement={true}>
                        <ExcelSheet name="Products" data={this.state.tableList}>
                            <ExcelColumn label="S/N" value="index"/>
                        </ExcelSheet>
                    </ExcelFile>
                ) : (
                    <AntButton style={{border: "0px"}} onClick={this.handleExportExcel}
                               icon={<ExportIcon color="action" fontSize="large"/>}></AntButton>
                )} */}
            </>
        );

        const props = {
            action: `${API_BASE_ROOT}/File/upload`,
            listType: "picture",
            defaultFileList: [...this.state.fileList]
        };

        return (
            <div className="card card-custom" style={{padding: "2rem", minHeight: "100%"}}>
                <Form initialValues={{remember: false}} onFinish={this.save} onFinishFailed={onFinishFailed}
                      ref={this.formRef}>
                    <Row gutter={[16, 16]}>
                        <Col md={18}></Col>
                        <Col md={3}>
                            <AntButton
                                style={{border: '1px solid #193058'}}
                                hidden={!this.state.hideInputs}
                                onClick={() => this.setState({
                                    showTemplateModal: true,
                                })}
                                icon={<AlignCenterOutlined color="secondary" fontSize="large"/>}
                            > Template
                            </AntButton>
                        </Col>
                        <Col md={1}>{excelExport}</Col>
                        <Col md={1}>
                            <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
                        </Col>
                        <Col md={1}>
                            <AntButton
                                style={{border: "0px"}}
                                hidden={!this.state.hideInputs}
                                onClick={this.onClickCreateNew}
                                icon={<CreateIcon color="secondary" fontSize="large"/>}
                            ></AntButton>
                            <AntButton
                                style={{border: "0px"}}
                                hidden={this.state.hideInputs}
                                onClick={this.onClickCreateNew}
                                icon={<RemoveCircle color="error" fontSize="large"/>}
                            ></AntButton>
                        </Col>
                    </Row>

                    <div hidden={this.state.hideInputs}>
                        <h3 className="card-label font-weight-bolder text-dark">{this.state.houseTypeName}</h3>

                        <Form.List name="absencePeriods">
                            {(fields, {add, remove}) => {
                                return (
                                    <>
                                        <TableBoot bordered size="sm">
                                            <thead>
                                            <tr>
                                                <th colSpan="5" style={{textAlign: "center"}}>
                                                    <FormattedMessage id={"AbsenceInfractionsSub.AbsencePeriod"}
                                                                      defaultMessage={"Absence Period"}/>
                                                </th>
                                                <th style={{textAlign: "center", width: "150px"}}>
                                                    <FormattedMessage id={"AbsenceInfractionsSub.PenaltyScore"}
                                                                      defaultMessage={"Penalty Score"}/>
                                                </th>
                                                <th style={{textAlign: "center"}}>
                                                    <FormattedMessage id={"AbsenceInfractionsSub.DeductionType"}
                                                                      defaultMessage={"Deduction Type"}/>
                                                </th>
                                                <th colSpan="2" style={{textAlign: "center"}}>
                                                    <FormattedMessage id={"AbsenceInfractionsSub.Deduction"}
                                                                      defaultMessage={"Deduction"}/>
                                                </th>
                                                <th colSpan="1">
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {fields.map(field => {
                                                return (
                                                    <tr key={field.key}>
                                                        <td style={{width: "80px"}}>
                                                            <Form.Item noStyle name={[field.name, "absencePeriodStart"]}>
                                                                <InputNumber min={0} style={{ width: "100%" }}
                                                                             parser={value => value.replace(",", ".")}
                                                                             formatter={value => value.replace(".", ",")}/>
                                                            </Form.Item>
                                                        </td>
                                                        <td style={{width: "20px", textAlign: "center"}}>
                                                            to
                                                        </td>
                                                        <td style={{width: "80px"}}>
                                                            <Form.Item noStyle name={[field.name, "absencePeriodEnd"]}>
                                                                <InputNumber min={0} style={{ width: "100%" }}
                                                                             parser={value => value.replace(",", ".")}
                                                                             formatter={value => value.replace(".", ",")}/>
                                                            </Form.Item>
                                                        </td>
                                                        <td style={{width: "200px"}}>
                                                            <Form.Item noStyle name={[field.name, "absencePeriodEquations"]}>
                                                                <Select
                                                                    showSearch
                                                                    allowClear
                                                                    optionFilterProp="children"
                                                                    style={{width: "100%"}}
                                                                    dropdownRender={menu => (
                                                                        <div>
                                                                            {menu}
                                                                            <Divider style={{margin: "4px 0"}}/>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexWrap: "nowrap",
                                                                                padding: 8
                                                                            }}>
                                                                                <Input
                                                                                    style={{flex: "auto"}}
                                                                                    value={this.state.codeName}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            codeName: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                                <a
                                                                                    style={{
                                                                                        flex: "none",
                                                                                        padding: "8px",
                                                                                        display: "block",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                    onClick={() => this.addCode("AbsenceInfractions.absencePeriodEquations")}
                                                                                >
                                                                                    <PlusOutlined/> Add item
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                >
                                                                    {Boolean(this.state["AbsenceInfractions.AbsencePeriodEquationsSelectItems"]) && this.state["AbsenceInfractions.AbsencePeriodEquationsSelectItems"].length > 0 &&
                                                                        this.state["AbsenceInfractions.AbsencePeriodEquationsSelectItems"].map(i => (
                                                                            <Option key={i.id} value={i.id}>
                                                                                {i.name}
                                                                            </Option>
                                                                        ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </td>
                                                        <td style={{width: "200px"}}>
                                                            <Form.Item noStyle name={[field.name, "absencePeriodUnit"]}>
                                                                <Select
                                                                    showSearch
                                                                    allowClear
                                                                    optionFilterProp="children"
                                                                    style={{width: "100%"}}
                                                                    dropdownRender={menu => (
                                                                        <div>
                                                                            {menu}
                                                                            <Divider style={{margin: "4px 0"}}/>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexWrap: "nowrap",
                                                                                padding: 8
                                                                            }}>
                                                                                <Input
                                                                                    style={{flex: "auto"}}
                                                                                    value={this.state.codeName}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            codeName: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                                <a
                                                                                    style={{
                                                                                        flex: "none",
                                                                                        padding: "8px",
                                                                                        display: "block",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                    onClick={() => this.addCode("AbsenceInfractions.AbsencePeriodUnit")}
                                                                                >
                                                                                    <PlusOutlined/> Add item
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                >
                                                                    {Boolean(this.state["AbsenceInfractions.AbsencePeriodUnitSelectItems"]) && this.state["AbsenceInfractions.AbsencePeriodUnitSelectItems"].length > 0 &&
                                                                        this.state["AbsenceInfractions.AbsencePeriodUnitSelectItems"].map(i => (
                                                                            <Option key={i.id} value={i.id}>
                                                                                {i.name}
                                                                            </Option>
                                                                        ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </td>
                                                        <td>
                                                            <Form.Item noStyle name={[field.name, "penaltyScore"]}>
                                                                <Input style={{ width: "100%" }} />
                                                            </Form.Item>
                                                        </td>
                                                        <td style={{width: "200px"}}>
                                                            <Form.Item noStyle name={[field.name, "deductionType"]}>
                                                                <Select
                                                                    showSearch
                                                                    allowClear
                                                                    optionFilterProp="children"
                                                                    style={{width: "100%"}}
                                                                    dropdownRender={menu => (
                                                                        <div>
                                                                            {menu}
                                                                            <Divider style={{margin: "4px 0"}}/>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexWrap: "nowrap",
                                                                                padding: 8
                                                                            }}>
                                                                                <Input
                                                                                    style={{flex: "auto"}}
                                                                                    value={this.state.codeName}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            codeName: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                                <a
                                                                                    style={{
                                                                                        flex: "none",
                                                                                        padding: "8px",
                                                                                        display: "block",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                    onClick={() => this.addCode("AbsenceInfractions.DeductionType")}
                                                                                >
                                                                                    <PlusOutlined/> Add item
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                >
                                                                    {Boolean(this.state["AbsenceInfractions.DeductionTypeSelectItems"]) && this.state["AbsenceInfractions.DeductionTypeSelectItems"].length > 0 &&
                                                                        this.state["AbsenceInfractions.DeductionTypeSelectItems"].map(i => (
                                                                            <Option key={i.id} value={i.id}>
                                                                                {i.name}
                                                                            </Option>
                                                                        ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </td>
                                                        <td style={{width: "80px"}}>
                                                            <Form.Item noStyle name={[field.name, "deduction"]}>
                                                                <InputNumber min={0} style={{width: "100%"}}
                                                                             parser={value => value.replace(",", ".")}
                                                                             formatter={value => value.replace(".", ",")}/>
                                                            </Form.Item>
                                                        </td>
                                                        <td style={{width: "200px"}}>
                                                            <Form.Item noStyle name={[field.name, "deductionUnit"]}>
                                                                <Select
                                                                    showSearch
                                                                    allowClear
                                                                    optionFilterProp="children"
                                                                    style={{width: "100%"}}
                                                                    dropdownRender={menu => (
                                                                        <div>
                                                                            {menu}
                                                                            <Divider style={{margin: "4px 0"}}/>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexWrap: "nowrap",
                                                                                padding: 8
                                                                            }}>
                                                                                <Input
                                                                                    style={{flex: "auto"}}
                                                                                    value={this.state.codeName}
                                                                                    onChange={event => {
                                                                                        this.setState({
                                                                                            codeName: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                                <a
                                                                                    style={{
                                                                                        flex: "none",
                                                                                        padding: "8px",
                                                                                        display: "block",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                    onClick={() => this.addCode("AbsenceInfractions.DeductionUnit")}
                                                                                >
                                                                                    <PlusOutlined/> Add item
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                >
                                                                    {Boolean(this.state["AbsenceInfractions.DeductionUnitSelectItems"]) && this.state["AbsenceInfractions.DeductionUnitSelectItems"].length > 0 &&
                                                                        this.state["AbsenceInfractions.DeductionUnitSelectItems"].map(i => (
                                                                            <Option key={i.id} value={i.id}>
                                                                                {i.name}
                                                                            </Option>
                                                                        ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </td>
                                                        <td style={{width: "25px", textAlign: "center"}}>
                                                            <MinusCircleOutlined onClick={() => remove(field.name)}/>
                                                        </td>
                                                    </tr>
                                                );
                                            })}

                                            <tr>
                                                <td colSpan={10}>
                                                    <AntButton type="dashed" onClick={() => add()} block
                                                               icon={<PlusOutlined/>}>
                                                        <FormattedMessage id={"AbsenceInfractionsSub.AddNew"}
                                                                          defaultMessage={"Add New"}/>
                                                    </AntButton>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </TableBoot>
                                    </>
                                );
                            }}
                        </Form.List>

                        <div hidden={this.state.hideSave}>
                            {
                                <Row gutter={[16, 16]}>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                        <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                                            <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                                        </Button>
                                    </Col>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                </Row>
                            }
                        </div>
                        <div hidden={this.state.hideUpdate}>
                            {
                                <Row gutter={[16, 16]}>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                                        <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                                        <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                                        </Button>
                                    </Col>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                </Row>
                            }
                        </div>
                    </div>
                </Form>

                <div style={{marginTop: "2rem", marginBottom: "1rem"}} className="content-section implementation">
                    <Table
                        bordered
                        scroll={{x: 900, scrollToFirstRowOnChange: true}}
                        columns={columns}
                        dataSource={this.state.tableList}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                        pagination={this.state.pagination}
                    />
                </div>

                <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal}
                       aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={this.delete}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                <AntModal
                    centered
                    width={1300}
                    title="Template"
                    open={this.state.showTemplateModal}
                    okButtonProps={{hidden: false}}
                    onCancel={() => this.setState({showTemplateModal: false})}
                    onOk={() => this.formRef.current.submit()}
                    okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save"/>}
                    cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel"/>}
                >
                    <Form initialValues={{remember: false}}
                          onFinish={this.save}
                        // onFinishFailed={onFinishFailed}
                          ref={this.formRef}>

                        {
                            <>
                            </>
                        }
                    </Form>

                </AntModal>

            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AbsenceInfractionsSub);
