import { FilterOutlined, MinusCircleOutlined } from "@ant-design/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import {
  Button as AntButton,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Table,
  Typography,
  Drawer,
  Tooltip,
  Badge,
  Spin,
  Checkbox
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess, showWarning } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";
import { ExcelReader } from "../excel/ExcelReader";
import { ProductList } from "./ProductList";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import { Settings } from "@material-ui/icons";
import store from "../../../redux/store";
import DraggableModal from "../../Components/DraggableModal";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

export class ProductMovement extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [],
      loading: false,
      stockIdSelectItems: null,
      vendorIdSelectItems: null,
      showProductListModal: false,
      selectedProducts: [],
      movementToFromWhom: null,
      movementType: null,
      stockId: null,
      stockId2: null,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      sortField: "dateCreated",
      sortOrder: "descend",

      excelData: [],
      user: store.getState().auth.user
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initPage();
  };

  initPage = async params => {
    const { pagination, sortField, sortOrder } = this.state;
    this.restartTable({ pagination, sortField, sortOrder });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsestockId = await handleRequest("GET", "/api/stock/list");
    if (responsestockId.type === "ERROR") {
      error(responsestockId);
    } else {
      this.setState({
        stockIdSelectItems: Boolean(responsestockId.data) ? responsestockId.data : []
      });
    }
    var responsevendorId = await handleRequest("GET", "/api/vendor/list");
    if (responsevendorId.type === "ERROR") {
      error(responsevendorId);
    } else {
      this.setState({
        vendorIdSelectItems: Boolean(responsevendorId.data) ? responsevendorId.data : []
      });
    }

    var responseUsers = await handleRequest("GET", "/api/users");
    if (responseUsers.type === "ERROR") {
      error(responseUsers);
    } else {
      this.setState({
        consignmentUserIdSelectItems: Boolean(responseUsers.data) ? responseUsers.data : []
      });
    }
  };
  movementTypeSelectItems = [
    { value: "RELEASE", label: "Release" },
    { value: "RECEIVE", label: "Receive" }
  ];

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      selectedProducts: []
    });
    this.resetInputs();
  };
  openProductListModal = () => {
    this.setState({
      showProductListModal: true,
      random: Math.random(),
    });
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedProducts: [],
      movementToFromWhom: null,
      movementType: null,
      stockId: null,
      stockId2: null,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "dateCreated",
      sortOrder: "descend",
      filePath: "",
      fileList: [],
      totalAmount: 0,
      totalQuantity: 0,
      totalSoldQuantity: 0
    });
    this.productMessages = [
      {
        variant: "info",
        text: "Please choose from the upper option first"
      }
    ];
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      movementType: row.movementType,
      movementToFromWhom: row.movementToFromWhom
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/stockMovement/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination, sortField, sortOrder } = this.state;
      this.restartTable({ pagination, sortField, sortOrder });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/stockMovement/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      const { sortField, sortOrder } = this.state;
      if (response.data.length !== 0) {
        let list = response.data.data;
        let quantity = 0;
        list.forEach((element, index) => {
          element.index = index + 1;
          quantity += element.quantity;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: Boolean(params.sortField) ? params.sortField : sortField,
          sortOrder: Boolean(params.sortOrder) ? params.sortOrder : sortOrder,
          currentQuantity: quantity
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  onCancel = () => {
    this.resetInputs();
  };

  showSelectedProducts = async (data, released) => {
    this.setState({ loading: true });
    const { selectedProducts } = this.state;
    let invoiceProductList = [...selectedProducts];

    for (const element of data) {
      let totalReleased = await this.getTotalReleased(element);

      let f = Boolean(selectedProducts) && selectedProducts.length > 0 ? selectedProducts.find(p => p.productId === element.id) : null;
      if (!Boolean(f)) {
        let obj = {};
        obj.productId = element.id;
        obj.name = Boolean(element.producDetail) ? element.producDetail.name : element.materialName;
        obj.quantity = element.quantity;

        obj.totalReleased = totalReleased;

        if (!released) {
          obj.quantity = null;
        } else {
          obj.relatedReceiveId = element.relatedReceiveId;
          obj.receivedQuantity = element.receivedQuantity;
          obj.quantity_available = element.quantity;
          obj.amount = element.amount;
          obj.soldQuantity = element.receivedQuantity - element.quantity;
        }

        invoiceProductList.push(obj);
      }
    }

    showSuccess();
    this.setState({
      selectedProducts: invoiceProductList,
      showProductListModal: false,
      loading: false
    });
  };

  addSelectedProducts = async selectedRows => {
    this.showSelectedProducts(selectedRows);
    // let response = await handleRequest(
    //   "POST",
    //   "/api/invoice/selectedproducts",
    //   selectedRowKeys
    // );

    // if (response.type === "ERROR") {
    //   error(response);
    // } else {
    //   let data = response.data;
    //   this.showSelectedProducts(data);
    // }
  };
  removeSelectedProduct = index => {
    let list = this.state.selectedProducts;

    list.splice(index, 1);
    if (this.state.movementType === "RECEIVE" && this.state.movementToFromWhom === "CONSIGNMENT") {
      list = this.calculateTotal(list);
    }
    this.setState({
      selectedProducts: list
    });
  };

  calculateTotal = data => {
    let totalQuantity = 0;
    let totalSoldQuantity = 0;
    data.forEach(element => {
      totalQuantity += element.quantity;
      totalSoldQuantity += element.receivedQuantity - element.quantity;
    });
    this.setState({
      totalQuantity,
      totalSoldQuantity
    });
    return data;
  };

  getProductsByConsignmentUser = async consignmentUserId => {
    this.setState({
      consignmentUserId: consignmentUserId,
      loading: true,
    }, async () => {
      if (this.state.movementType === "RECEIVE" && consignmentUserId === undefined) {
        this.setState({
          selectedProducts: [],
          loading: false,
        });
        return;
      } else if (this.state.movementType === "RELEASE" || !!!consignmentUserId) {
        this.setState({
          loading: false,
        });
        return;
      }
      let response = await handleRequest("GET", "/api/stockMovement/waitList/" + consignmentUserId);

      let response2 = await handleRequest("GET", "/api/stockMovement/waitList/amount/" + consignmentUserId);
      if (response.type === "ERROR" || response2.type === "ERROR") {
        error(response);
        this.setState({
          loading: false,
        });
      } else if (response.data.length > 0) {
        let products = response.data.map(element => {
          return {
            id: element.inventoryId,
            materialName: element.inventory,
            quantity: element.quantity,
            receivedQuantity: element.receivedQuantity,
            relatedReceiveId: element.id
          };
        });
        let totalAmount = 0;
        response2.data.forEach(element => {
          totalAmount += element.amount;
        });
        products = this.calculateTotal(products);
        this.showSelectedProducts(products, true);
        this.setState({
          totalAmount,
          loading: false,
        });
        this.formRef.current.setFieldsValue({
          expectedAmount: totalAmount,
          receivedAmount: totalAmount
        });
      } else {
        showWarning("No products available for this consignment");
        this.productMessages = [
          {
            variant: "warning",
            text: "No products available for this consignment"
          }
        ];
        this.setState({
          selectedProducts: [],
          loading: false,
        });
      }
    });
  };

  getProductsByReleasedStock = async () => {
    const { stockId, stockId2, movementType } = this.state;
    if (movementType === "RELEASE" || !!!stockId || !!!stockId2) {
      return;
    } else if (movementType === "RECEIVE" && (!!stockId || !!stockId2)) {
      this.setState({
        selectedProducts: []
      });
    }
    let response = await handleRequest("GET", "/api/stockMovement/waitListByStock/" + stockId + "/" + stockId2);
    if (response.type === "ERROR") {
      error(response);
    } else if (response.data.length > 0) {
      let products = response.data.map(element => {
        return {
          id: element.inventoryId,
          materialName: element.inventory,
          quantity: element.quantity * -1,
          relatedReceiveId: element.id
        };
      });
      this.showSelectedProducts(products, true);
    } else {
      showWarning("No products available for this stock");
      this.productMessages = [
        {
          variant: "warning",
          text: "No products available for this stock"
        }
      ];
      this.setState({
        selectedProducts: []
      });
    }
  };

  save = async values => {
    console.log("values", values);
    console.log("selectedProducts", this.state.selectedProducts);
    if (this.state.selectedProducts.length === 0) {
      showError("Please select at least one product");
      return;
    }
    var list = [];
    this.state.selectedProducts.forEach(element => {
      let obj = {
        ...values,
        inventoryId: element.productId,
        quantity: element.quantity
      };

      if (obj.quantity === 0) {
        obj.stockMovementCalc = element.stockMovementCalc;
      }

      delete obj.expectedAmount;
      delete obj.receivedAmount;
      if (values.movementType === "RELEASE") {
        obj.quantity = -obj.quantity;
      } else if (values.movementType === "RECEIVE" && values.movementToFromWhom !== "VENDOR") {
        obj.relatedReceiveId = element.relatedReceiveId;
        obj.receivedQuantity = element.receivedQuantity;
      }

      list.push(obj);
    });
    let requestObj = {
      stockMovements: list
    };

    if (this.state.movementType === "RECEIVE" && this.state.movementToFromWhom === "CONSIGNMENT") {
      requestObj.salesman = {
        consignmentUserId: values.consignmentUserId,
        expectedAmount: values.expectedAmount,
        receivedAmount: values.receivedAmount,
        balance: values.receivedAmount - values.expectedAmount
      };
    }

    console.log("requestObj", requestObj);
    var response = await handleRequest("POST", "/api/stockMovement/importWithSalesmanCash", requestObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination, sortOrder, sortField } = this.state;
      this.restartTable({ pagination, sortOrder, sortField });
      showSuccess();
    }
  };

  async getTotalReleased(element) {
    let totalReleased = 0;
    if (Boolean(this.state.movementType) && this.state.movementToFromWhom === "CONSIGNMENT" && this.state.movementType === "RELEASE") {
      const { consignmentUserId } = this.state;
      if (Boolean(element.id) && Boolean(consignmentUserId) && consignmentUserId > 0) {
        let response = await handleRequest("GET", "/api/stockMovement/getTotalReleased/" + element.id + "/" + consignmentUserId);
        if (response.type === "ERROR") {
          error(response);
        } else {
          totalReleased = response.data;
        }
      }
    }
    return totalReleased;
  }

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/stockMovement/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  productMessages = [
    {
      variant: "info",
      text: "Please choose from the upper option first"
    }
  ];
  showProductMessage = () => {
    return this.productMessages.map((obj, index) => {
      return (
        <Alert key={index} variant={obj.variant}>
          <ArrowUpwardRoundedIcon />
          {obj.text}
        </Alert>
      );
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const layout3 = {
      labelCol: {
        xs: {
          span: 6,
          offset: 0
        },
        sm: {
          span: 6,
          offset: 4
        }
      },
      wrapperCol: { span: 6 }
    };
    const tailProductLayout = {
      wrapperCol: {
        xs: {
          span: 8,
          offset: 0
        },
        sm: {
          span: 8,
          offset: 10
        }
      }
    };

    const columns = [
      // {
      //   key: "key",
      //   title: "Actions",
      //   render: this.action
      // },
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: "Stock",
        key: "stockId",
        render: record => {
          return record.stock;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.stockId) ? this.state.pagination.stockId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  stockId: e.target.value,
                  stockIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.stockIdColor) ? this.state.pagination.stockIdColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: "Inventory",
        key: "",
        render: record => {
          return record.inventory;
        }
      },

      {
        title: <FormattedMessage id="StockMovement.movementType" defaultMessage="Process" />,
        key: "movementType",
        render: record => {
          return record.movementType;
        }
      },
      {
        title: <FormattedMessage id="StockMovement.movementToFromWhom" defaultMessage="Move to" />,
        key: "movementToFromWhom",
        render: record => {
          return record.movementToFromWhom;
        }
      },
      {
        title: <FormattedMessage id="StockMovement.quantity" defaultMessage="Quantity" />,
        key: "quantity",
        render: record => {
          if (record.movementType === "RECEIVE") {
            return <Text type="success">{record.quantity}</Text>;
          } else {
            return <Text type="danger">{record.quantity}</Text>;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.quantity) ? this.state.pagination.quantity : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  quantity: e.target.value,
                  quantityColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.quantityColor) ? this.state.pagination.quantityColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: "Vendor",
        key: "vendorId",
        render: record => {
          return Boolean(record.vendorName) ? record.vendorName : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.vendorId) ? this.state.pagination.vendorId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  vendorId: e.target.value,
                  vendorIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.vendorIdColor) ? this.state.pagination.vendorIdColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="StockMovement.consignmentId" defaultMessage="Consignment" />,
        key: "movementToFromWhom",
        render: record => {
          return record.consignment;
        }
      },
      {
        title: <FormattedMessage id="StockMovement.toFromStock" defaultMessage="To/From Stock" />,
        key: "movementToFromWhom",
        render: record => {
          if (record.sentStock) return record.sentStock;
          else if (record.incomingStock) return record.incomingStock;
        }
      },
      {
        title: <FormattedMessage id="StockMovement.remarks" defaultMessage="Remarks" />,
        key: "remarks",
        render: record => {
          return record.remarks;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.remarks) ? this.state.pagination.remarks : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  remarks: e.target.value,
                  remarksColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.remarksColor) ? this.state.pagination.remarksColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },

      {
        title: "Date",
        key: "dateCreated",
        render: record => {
          return moment(record.dateCreated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="ProductMovement.ReleaseReceiveProducts" defaultMessage="Release/Receive Products" />}
        placement="start"
        color="purple"
        style={{ fontSize: 12 }}
      >
        <Spin spinning={this.state.loading}>
          <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
              <Row gutter={[16, 16]}>
                <Col md={20}></Col>
                <Col md={1}>{excelExport}</Col>
                <Col md={1}>
                  <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
                </Col>
                <Col md={1}>
                  <AntButton
                    style={{ border: "0px" }}
                    hidden={!this.state.hideInputs}
                    onClick={this.onClickCreateNew}
                    icon={<CreateIcon color="secondary" fontSize="large" />}
                  ></AntButton>
                  <AntButton
                    style={{ border: "0px" }}
                    hidden={this.state.hideInputs}
                    onClick={this.onClickCreateNew}
                    icon={<RemoveCircle color="error" fontSize="large" />}
                  ></AntButton>
                </Col>
                <Col md={1}>
                  {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                    <Tooltip title={<FormattedMessage id="ProductMovement.Settings" defaultMessage="Settings" />}>
                      <AntButton
                        style={{ border: "0px" }}
                        onClick={() => {
                          this.setState({
                            drawerSettingsShow: true
                          });
                        }}
                        icon={<Settings color="error" fontSize="large" />}
                      ></AntButton>
                    </Tooltip>
                  )}
                </Col>
              </Row>

              <div hidden={this.state.hideInputs}>
                <Form.Item
                  {...layout}
                  name="movementType"
                  label="Release/Receive"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="required." />
                    }
                  ]}
                >
                  <Select
                    onChange={value => {
                      this.setState({
                        movementType: value,
                        consignmentUserId: null,
                        incomingStockId: null,
                        sentStockId: null,
                        vendorId: null,
                        selectedProducts: []
                      });
                    }}
                  >
                    {Boolean(this.movementTypeSelectItems) &&
                      this.movementTypeSelectItems.map(i => (
                        <Option key={i.value} value={i.value}>
                          {i.label}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
                {Boolean(this.state.movementType) && (
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="StockMovement.movementToFromWhom" defaultMessage="Move to " />}
                    name="movementToFromWhom"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Radio.Group
                      onChange={e => {
                        this.setState({
                          movementToFromWhom: e.target.value,
                          selectedProducts: []
                        });
                        this.productMessages = [
                          {
                            variant: "info",
                            text: "Please choose from the upper option first"
                          }
                        ];
                        this.formRef.current.setFieldsValue({
                          consignmentUserId: null,
                          incomingStockId: null,
                          sentStockId: null,
                          vendorId: null,
                          selectedProducts: []
                        });
                      }}
                    >
                      <Radio value={"CONSIGNMENT"}>
                        <FormattedMessage id="MovementToFromWhom.Consignment" defaultMessage="Consignment" />
                      </Radio>
                      <Radio value={"VENDOR"}>
                        <FormattedMessage id="MovementToFromWhom.Vendor" defaultMessage="Vendor" />
                      </Radio>
                      <Radio value={"TRANSFER"}>
                        <FormattedMessage id="MovementToFromWhom.Transfer" defaultMessage="Transfer" />
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                )}{" "}
                {Boolean(this.state.movementToFromWhom) && (
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="StockMovement.stockId" defaultMessage="Stock" />}
                    name="stockId"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Stock"
                      onChange={value => {
                        this.setState(
                          {
                            stockId: value
                          },
                          this.getProductsByReleasedStock
                        );
                      }}
                    >
                      {Boolean(this.state["stockIdSelectItems"]) &&
                        this.state["stockIdSelectItems"].map(i => (
                          <Option key={i.key} disabled={Boolean(this.state.stockId2) && this.state.stockId2 === i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                )}{" "}
                {Boolean(this.state.movementToFromWhom) && this.state.movementToFromWhom === "CONSIGNMENT" && (
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="StockMovement.consignmentUserId" defaultMessage="Name" />}
                    name="consignmentUserId"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Select allowClear style={{ width: "100%" }} placeholder="Name" onChange={this.getProductsByConsignmentUser}>
                      {Boolean(this.state["consignmentUserIdSelectItems"]) &&
                        this.state["consignmentUserIdSelectItems"].map(i => (
                          <Option key={i.id} value={i.id}>
                            {i.name + " " + i.surname}
                            <br />
                            {i.email}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                )}
                {Boolean(this.state.movementToFromWhom) && this.state.movementToFromWhom === "VENDOR" && (
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="StockMovement.vendorId" defaultMessage="Vendor" />}
                    name="vendorId"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Select allowClear style={{ width: "100%" }} placeholder="Vendor">
                      {Boolean(this.state["vendorIdSelectItems"]) &&
                        this.state["vendorIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                )}{" "}
                {Boolean(this.state.movementToFromWhom) &&
                  Boolean(this.state.movementType) &&
                  this.state.movementToFromWhom === "TRANSFER" &&
                  this.state.movementType === "RELEASE" && (
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="StockMovement.sentStockId2" defaultMessage="Stock to Send" />}
                      name="sentStockId"
                      rules={[
                        {
                          required: true,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Select
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Stock"
                        onChange={value => {
                          this.setState({
                            stockId2: value
                          });
                        }}
                      >
                        {Boolean(this.state["stockIdSelectItems"]) &&
                          this.state["stockIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key} disabled={Boolean(this.state.stockId) && this.state.stockId === i.key}>
                              {i.value}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                  )}{" "}
                {Boolean(this.state.movementToFromWhom) &&
                  Boolean(this.state.movementType) &&
                  this.state.movementToFromWhom === "TRANSFER" &&
                  this.state.movementType === "RECEIVE" && (
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="StockMovement.incomingStock2" defaultMessage="Incoming Stock" />}
                      name="incomingStockId"
                      rules={[
                        {
                          required: true,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Select
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Stock"
                        onChange={value => {
                          this.setState(
                            {
                              stockId2: value
                            },
                            this.getProductsByReleasedStock
                          );
                        }}
                      >
                        {Boolean(this.state["stockIdSelectItems"]) &&
                          this.state["stockIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key} disabled={Boolean(this.state.stockId) && this.state.stockId === i.key}>
                              {i.value}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                  )}{" "}
                {Boolean(this.state.movementToFromWhom) &&
                  Boolean(this.state.movementType) &&
                  !(this.state.movementToFromWhom !== "VENDOR" && this.state.movementType === "RECEIVE") && (
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="ProductMovement.selectProducts" defaultMessage="Add Products" />}
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <AntButton onClick={this.openProductListModal}>Select Products</AntButton>
                    </Form.Item>
                  )}
                {Boolean(this.state.movementToFromWhom) && Boolean(this.state.movementType) && this.state.selectedProducts.length === 0 && (
                  <Form.Item {...layout} label=" " colon={false}>
                    {this.showProductMessage()}
                  </Form.Item>
                )}

                {this.state.selectedProducts.map((product, index) => (
                  <Form.Item key={index} {...layout3} label={product.name} style={{ marginBottom: "5px" }}>
                    {
                      <Form.Item
                        extra={Boolean(product.receivedQuantity) ? "Released Quantity:" + product.receivedQuantity : "Quantity"}
                        style={{
                          display: "inline-block",
                          width: "100%",
                          marginBottom: "5px"
                        }}
                        rules={[
                          {
                            required: false,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <InputNumber
                          placeholder="Quantity"
                          style={{ width: "50%" }}
                          min={0}
                          max={product.quantity_available}
                          value={product.quantity}
                          onChange={value => {
                            let list = this.state.selectedProducts;

                            list[index].quantity = value;
                            this.setState({
                              selectedProducts: list
                            });
                          }}
                        />

                        <>
                          {Boolean(this.state.movementType) && this.state.movementToFromWhom === "CONSIGNMENT" && this.state.movementType === "RECEIVE" && (
                            <span style={{ paddingLeft: "20px", width: "35%" }}>
                              {<FormattedMessage id="ProductMovement.SoldQuantity" defaultMessage="Sold" />}:{product.soldQuantity}
                            </span>
                          )}

                          {Boolean(this.state.movementType) && this.state.movementToFromWhom === "CONSIGNMENT" && this.state.movementType === "RELEASE" && (
                            <span style={{ paddingLeft: "20px", width: "35%" }}>
                              {<FormattedMessage id="ProductMovement.Released" defaultMessage="Released" />}:{Math.abs(product.totalReleased)}
                            </span>
                          )}

                          {product.quantity === 0 &&
                            <Form.Item
                              style={{
                                marginLeft: "10px",
                                display: "inline-block",
                                width: "10%",
                                fontSize: "20px",
                                color: "#f64e60",
                                verticalAlign: "0px"
                              }}
                              name={`stockMovementCalc-${index}`}
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                              valuePropName="checked"
                            >
                              <Checkbox
                                checked={product.stockMovementCalc}
                                onChange={e => {
                                  const updatedProducts = this.state.selectedProducts.map((prod, idx) => {
                                    if (idx === index) { // Güncellenen ürünün index'ine göre kontrol yapılıyor
                                      return { ...prod, stockMovementCalc: e.target.checked }; // Durumu güncelliyoruz
                                    }
                                    return prod;
                                  });
                                  this.setState({
                                    selectedProducts: updatedProducts // Güncellenmiş ürün listesini yeniden ayarlıyoruz
                                  });
                                }}
                              >{<FormattedMessage id="ProductMovement.Confirmed" defaultMessage="Confirmed" />}</Checkbox>
                            </Form.Item>
                          }
                        </>

                        {/* <MinusCircleOutlined
                          style={{
                            marginLeft: "2px",
                            display: "inline-block",
                            width: "10%",
                            fontSize: "20px",
                            color: "#f64e60",
                            verticalAlign: "0px"
                          }}
                          onClick={() => this.removeSelectedProduct(index)}
                        /> */}
                      </Form.Item>
                    }
                  </Form.Item>
                ))}
                {Boolean(this.state.selectedProducts.length) &&
                  Boolean(this.state.movementType) &&
                  this.state.movementToFromWhom === "CONSIGNMENT" &&
                  this.state.movementType === "RECEIVE" && (
                    <>
                      <Form.Item {...tailProductLayout} style={{ marginBottom: "5px" }}>
                        <span style={{ marginRight: "10px" }}>
                          <FormattedMessage id="ProductMovement.ReceivedQuantity" defaultMessage="Received:" />
                          <Text strong>{this.state.totalQuantity}</Text>
                        </span>
                        <span style={{ marginRight: "10px" }}>
                          <FormattedMessage id="ProductMovement.TotalSoldQuantity" defaultMessage="Total Sold:" />
                          <Text strong>{this.state.totalSoldQuantity}</Text>
                        </span>

                      </Form.Item>
                      <Form.Item {...layout3} label="Total Price" style={{ marginBottom: "5px" }}>
                        <Form.Item
                          name="expectedAmount"
                          extra="Expected"
                          style={{
                            display: "inline-block",
                            width: "50%",
                            marginBottom: "5px"
                          }}
                        >
                          <InputNumber disabled style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                          name="receivedAmount"
                          extra="Received"
                          style={{
                            display: "inline-block",
                            width: "50%",
                            marginBottom: "5px"
                          }}
                        >
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Form.Item>
                    </>
                  )}
                {Boolean(this.state.movementToFromWhom) && Boolean(this.state.movementType) && (
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="StockMovement.remarks" defaultMessage="Remarks" />}
                    name="remarks"
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <TextArea placeholder="Remarks" style={{ width: "100%" }} />
                  </Form.Item>
                )}
                <div hidden={this.state.hideSave}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                          <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
                <div hidden={this.state.hideUpdate}>
                  {
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                          <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                          <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  }
                </div>
              </div>
            </Form>

            <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
              <Table
                bordered
                rowKey="id"
                scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                columns={columns}
                dataSource={this.state.tableList}
                loading={this.state.loading}
                onChange={this.handleTableChange}
                pagination={this.state.pagination}
              />
            </div>

            <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Are you sure?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={this.delete}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>

            <DraggableModal
              title="Product List"
              closable={true}
              maskClosable={false}
              centered
              visible={this.state.showProductListModal}
              onCancel={() => {
                this.setState({
                  showProductListModal: false
                });
              }}
              okButtonProps={{ style: { display: "none" } }}
              width={1000}
              content={
                <ProductList random={this.state.random} disableProductNotExist={false} addSelectedProducts={this.addSelectedProducts.bind(this)} />
              }
            />

            <Drawer
              title={<FormattedMessage id="ProductMovement.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
              width={1200}
              closable={false}
              onClose={() => {
                this.setState({ drawerSettingsShow: false });
              }}
              open={this.state.drawerSettingsShow}
            >
              <PageSettings pageName={"Product_Movement"} />
            </Drawer>
          </div>
        </Spin>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductMovement);