import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Input, Tree, Form, Select, Badge, Space, InputNumber, Cascader, Drawer } from "antd";
import { FcFolder, FcEditImage, FcAddressBook, FcAreaChart } from "react-icons/fc";
import { Menu, Item, useContextMenu } from "react-contexify";
import { FcTreeStructure, FcLibrary } from "react-icons/fc";
import { MdDelete } from "react-icons/md";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../../ApiConnector";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import DraggableModal from "../../../Components/DraggableModal";
import { EldenEquipmentLogIdentificationDrawer } from "./EldenEquipmentLogIdentificationDrawer";

const Menu_Equipment_Tree = "Menu_Equipment_Tree";
const Menu_Group = "Menu_Group";
const Menu_Category = "Menu_Category";
const Menu_Type = "Menu_Type";
const Menu_Equipment = "Menu_Equipment";
const Menu_Manufacturer = "Menu_Manufacturer";
const Menu_Identification = "Menu_Identification";

const { Option } = Select;
//const { TextArea } = Input;

const { show } = useContextMenu();

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

const dataListMaterialType = [];
const generateListMaterialType = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataListMaterialType.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateListMaterialType(node.children);
    }
  }
};

export class EldenEquipmentTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "EldenEquipmentTree",
      controllerName: "eldenEquipmentTree",
      id: null,
      description: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      materialTypeIds: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      refreshTree: true
    };
  }

  inputRef = React.createRef(null);
  formRef = React.createRef();
  formRefGroup = React.createRef();
  formRefCategory = React.createRef();
  formRefType = React.createRef();
  formRefEquipment = React.createRef();
  formRefManufacturer = React.createRef();
  formRefIdentification = React.createRef();

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : ""
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitems/" + obj.id);
    }
    if (Boolean(response) && response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.icon =
          Boolean(element.type) && element.type === "Add_Equipment_Tree" ? (
            <FcLibrary />
          ) : element.type === "Group" ? (
            <FcTreeStructure />
          ) : element.type === "Category" ? (
            <FcTreeStructure />
          ) : element.type === "Type" ? (
            <FcAreaChart />
          ) : element.type === "Equipment" ? (
            <FcAddressBook />
          ) : element.type === "Manufacturer" ? (
            <FcAddressBook />
          ) : (
            <FcFolder />
          );

        let quantity = Boolean(element.quantity) ? element.quantity : 0;

        if (Boolean(element.type)) {
          if (element.type === "Group") {
            element.title = element.name;
          } else if (element.type === "Category") {
            element.title = element.name;
          } else if (element.type === "Type") {
            element.title = element.name;
          } else if (element.type === "Equipment") {
            element.title = element.name;
          } else if (element.type === "Manufacturer") {
            element.title = element.name;
          } else
            element.title = element.name;
        }
        element.key = element.id;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    this.fillTree();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {

  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "Add_Equipment_Tree";
          }
          element.title = element.name;
          element.key = element.id;
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  onRightClick = (event, node) => {
    let parentNode = this.getParentNode(node);
    this.setState(
      {
        selectedNodeKey: node.key,
        selectedNode: node,
        parentNode: parentNode
      },
      this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
    );
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  handleItemClick = async ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    switch (event.currentTarget.id) {
      case "create_group":
        this.setState({
          showGroupModal: true,
          parentId: selectedNode.id,
          type: "Group",
          id: null,
        }, () => {
          this.formRefGroup.current.setFieldsValue({
            name: "",
            code: "",
            quantity: "",
          });
        });
        break;

      case "edit_group":
        this.setState({
          showGroupModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        }, () => {
          this.formRefGroup.current.setFieldsValue({
            name: selectedNode.name,
            code: selectedNode.code
          });
        });
        break;

      case "create_category":
        this.setState({
          showCategoryModal: true,
          parentId: selectedNode.id,
          type: "Category",
          id: null,
        }, () => {
          this.formRefCategory.current.setFieldsValue({
            name: "",
            code: "",
          });
        });
        break;

      case "edit_category":
        this.setState({
          showCategoryModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        }, () => {
          this.formRefCategory.current.setFieldsValue({
            name: selectedNode.name,
            code: selectedNode.code,
          });
        });
        break;

      case "create_type":
        this.setState({
          showTypeModal: true,
          parentId: selectedNode.id,
          type: "Type",
          id: null,
        }, () => {
          this.formRefType.current.setFieldsValue({
            name: "",
            code: "",
            quantity: "",
          });
        });
        break;

      case "edit_type":
        this.setState({
          showTypeModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        }, () => {
          this.formRefType.current.setFieldsValue({
            name: selectedNode.name,
            code: selectedNode.code,
            quantity: selectedNode.quantity,
          });
        });
        break;

      case "create_equipment":
        this.setState({
          showEquipmentModal: true,
          parentId: selectedNode.id,
          type: "Equipment",
          id: null,
        }, () => {
          this.formRefEquipment.current.setFieldsValue({
            name: "",
            code: "",
            quantity: "",
          });
        });
        break;

      case "edit_equipment":
        this.setState({
          showEquipmentModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        }, () => {
          this.formRefEquipment.current.setFieldsValue({
            name: selectedNode.name,
            code: selectedNode.code,
            quantity: selectedNode.quantity,
          });
        });
        break;

      case "create_manufacturer":
        this.setState({
          showManufacturerModal: true,
          parentId: selectedNode.id,
          type: "Manufacturer",
          id: null,
        }, () => {
          this.formRefManufacturer.current.setFieldsValue({
            name: "",
            code: "",
            quantity: "",
          });
        });
        break;

      case "edit_manufacturer":
        this.setState({
          showManufacturerModal: true,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type
        }, () => {
          this.formRefManufacturer.current.setFieldsValue({
            name: selectedNode.name,
            code: selectedNode.code,
            quantity: selectedNode.quantity,
          });
        });
        break;

      case "create_identification":
        this.setState({
          modalReflectToAllChildsShow: true,
          eldenEquipmentTreeId: selectedNode.id,
          parentId: selectedNode.id,
          type: "Identification",
          id: null,
        });
        break;

      case "delete_group":
        this.setState({
          showDeleteGroupModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;
    }
  };

  displayMenu = (e, type) => {
    console.log("TTT", type);
    if (Boolean(type)) {
      if (type === "Add_Equipment_Tree") {
        show(e, { id: Menu_Equipment_Tree });
      } else if (type === "Group") {
        show(e, { id: Menu_Group });
      } else if (type === "Category") {
        show(e, { id: Menu_Category });
      } else if (type === "Type") {
        show(e, { id: Menu_Type });
      } else if (type === "Equipment") {
        show(e, { id: Menu_Equipment });
      } else if (type === "Manufacturer") {
        show(e, { id: Menu_Manufacturer });
      } else if (type === "Identification") {
        show(e, { id: Menu_Identification });
      }
    }
  }

  deleteProduct = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteProductModal: false
        });
      }
    } else {
      showError("Category not found.");
    }
  };

  save = async values => {
    const { process, selectedNode, id, type, parentId, parentNode } = this.state;
    const newItem = {
      ...values,
      id: id,
      parentId: parentId,
      type: type,
      createCompany: Boolean(this.props.onTargetKeysCheckCompanyAddProduct) ? true : false,
    };

    if (newItem.id === null) {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(parentNode)) this.loadData(parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
      this.setState({
        id: null,
        showGroupModal: false,
        showCategoryModal: false,
        showTypeModal: false,
        showEquipmentModal: false,
        showManufacturerModal: false,
        showIdentificationModal: false,
        modalReflectToAllChildsShow: false,
      });
    }
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      description: "",
      code: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null
    });
  };

  expandSelectedNode = () => {
    let key = this.state.selectedNodeKey;
    let node = this.state.selectedNode;
    if (Boolean(key)) {
      let expandedKeys = Boolean(this.state.expandedKeys) ? this.state.expandedKeys : [];
      if (Boolean(expandedKeys.find(p => p === key))) {
        this.loadData(node, false);
      } else {
        this.setState({
          expandedKeys: [...expandedKeys, key]
        });
        this.loadData(node, false);
      }
    }
  };

  onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...this.state.nodes];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    this.setState({
      nodes: data
    });
  };

  onCheck = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    if (Boolean(this.props) && Boolean(this.props.onTargetKeysCheckCompanyAddProduct)) {
      console.log("checkedKeys", e.checkedNodes);
      console.log("checkedKeys", e.halfCheckedKeys);
      this.props.onTargetKeysCheckCompanyAddProduct(checkedKeys.checked);
    }
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes
    });
  };

  yesReflectToAllChilds = async () => {
    if (Boolean(this.state.eldenEquipmentTreeId) && this.state.eldenEquipmentTreeId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/yesReflectToAllChilds/" + this.state.eldenEquipmentTreeId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          modalReflectToAllChildsShow: false,
          showIdentificationModal: true,
        });
      }
    } else {
      showWarning("Elden Equipment Tree not found.");
    }
  };

  noReflectToAllChilds = async () => {
    if (Boolean(this.state.eldenEquipmentTreeId) && this.state.eldenEquipmentTreeId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/noReflectToAllChilds/" + this.state.eldenEquipmentTreeId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          modalReflectToAllChildsShow: false,
          showIdentificationModal: true,
        });
      }
    } else {
      showWarning("Elden Equipment Tree not found.");
    }
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Equipment Tree "} />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <br />
          <br />

          {this.state.refreshTree && (
            Boolean(this.props.onTargetKeysCheckCompanyAddProduct) ?
              <Tree
                style={{ marginTop: "10px", lineHeight: "30px" }}
                className="draggable-tree"
                showLine={{ showLeafIcon: false }}
                showIcon
                checkStrictly={true}
                loadedKeys={this.state.loadedKeys}
                onLoad={this.onLoadData}
                loadData={this.loadData}
                onRightClick={({ event, node }) => this.onRightClick(event, node)}
                onExpand={this.onExpand}
                expandedKeys={this.state.expandedKeys}
                autoExpandParent={false}
                treeData={this.state.nodes}
                blockNode={true}
                draggable
                checkable
                onCheck={this.onCheck}
                checkedKeys={this.state.checkedKeys}
                halfCheckedKeys={this.state.halfCheckedKeys}
                onDrop={this.onDrop}
              />
              :
              <Tree
                style={{ marginTop: "10px", lineHeight: "30px" }}
                className="draggable-tree"
                showLine={{ showLeafIcon: false }}
                showIcon
                checkStrictly={true}
                loadedKeys={this.state.loadedKeys}
                onLoad={this.onLoadData}
                loadData={this.loadData}
                onRightClick={({ event, node }) => this.onRightClick(event, node)}
                onExpand={this.onExpand}
                expandedKeys={this.state.expandedKeys}
                autoExpandParent={false}
                treeData={this.state.nodes}
                blockNode={true}
                // draggable
                onDragEnter={this.onDragEnter}
                onDrop={this.onDrop}
              />
          )}

          { /************ Ağaç yapısında Sabit "Equipment Tree" ana düğmesi oluşturulmalı ve sağ tuşa tıklandığında "Create Group" açılmalı*/}
          <Menu id={Menu_Equipment_Tree}>
            <Item id="create_group" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.CreateGroup" defaultMessage="Create Group" />
                </span>
              </Space>
            </Item>
          </Menu>

          { /********************************** */}

          <Menu id={Menu_Group}>
            <Item id="edit_group" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="create_category" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.CreateCategory" defaultMessage="Create Category" />
                </span>
              </Space>
            </Item>
            <Item id="create_identification" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Identification" defaultMessage="Identification" />
                </span>
              </Space>
            </Item>
            <Item id="delete_group" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Category}>
            <Item id="edit_category" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="create_type" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.CreateType" defaultMessage="Create Type" />
                </span>
              </Space>
            </Item>
            <Item id="create_identification" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Identification" defaultMessage="Identification" />
                </span>
              </Space>
            </Item>
            <Item id="delete_category" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Type}>
            <Item id="edit_type" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="create_equipment" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Equipment" defaultMessage="Equipment" />
                </span>
              </Space>
            </Item>
            <Item id="create_identification" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Identification" defaultMessage="Identification" />
                </span>
              </Space>
            </Item>
            <Item id="delete_type" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Equipment}>
            <Item id="edit_equipment" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="create_manufacturer" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Manufacturer" defaultMessage="Manufacturer" />
                </span>
              </Space>
            </Item>
            <Item id="create_identification" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Identification" defaultMessage="Identification" />
                </span>
              </Space>
            </Item>
            <Item id="delete_equipment" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Manufacturer}>
            <Item id="edit_manufacturer" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="create_identification" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Identification" defaultMessage="Identification" />
                </span>
              </Space>
            </Item>
            <Item id="delete_manufacturer" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="EldenEquipmentTree.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>

          <DraggableModal
            title={<FormattedMessage id="EldenEquipmentTree.Group" defaultMessage="Group" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showGroupModal}
            onOk={() => this.formRefGroup.current.submit()}
            onCancel={() => {
              this.setState({ showGroupModal: false });
            }}
            content={
              <>
                <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRefGroup}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenEquipmentTree.GroupName" defaultMessage="Group Name" />}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenEquipmentTree.Code" defaultMessage="Code" />}
                      name="code"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenEquipmentTree.Category" defaultMessage="Category" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showCategoryModal}
            onOk={() => this.formRefCategory.current.submit()}
            onCancel={() => {
              this.setState({ showCategoryModal: false });
            }}
            content={
              <>
                <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRefCategory}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenEquipmentTree.CategoryName" defaultMessage="Category Name" />}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenEquipmentTree.Code" defaultMessage="Code" />}
                      name="code"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenEquipmentTree.Type" defaultMessage="Type" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showTypeModal}
            onOk={() => this.formRefType.current.submit()}
            onCancel={() => {
              this.setState({ showTypeModal: false });
            }}
            content={
              <>
                <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRefType}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenEquipmentTree.TypeName" defaultMessage="Type Name" />}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenEquipmentTree.Code" defaultMessage="Code" />}
                      name="code"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenEquipmentTree.Type" defaultMessage="Equipment" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showEquipmentModal}
            onOk={() => this.formRefEquipment.current.submit()}
            onCancel={() => {
              this.setState({ showEquipmentModal: false });
            }}
            content={
              <>
                <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRefEquipment}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenEquipmentTree.EquipmentName" defaultMessage="Equipment" />}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenEquipmentTree.Code" defaultMessage="Code" />}
                      name="code"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenEquipmentTree.Manufacturer" defaultMessage="Manufacturer" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showManufacturerModal}
            onOk={() => this.formRefManufacturer.current.submit()}
            onCancel={() => {
              this.setState({ showManufacturerModal: false });
            }}
            content={
              <>
                <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRefManufacturer}>
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenEquipmentTree.ManufacturerName" defaultMessage="Manufacturer Name" />}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                  {
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EldenEquipmentTree.Code" defaultMessage="Code" />}
                      name="code"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  }
                </Form>
              </>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenEquipmentLog.ReflectToAllChilds" defaultMessage="Reflect to all Childs" />}
            centered
            closable={false}
            maskClosable={false}
            open={this.state.modalReflectToAllChildsShow}
            onOk={() => this.yesReflectToAllChilds()}
            onCancel={() => this.noReflectToAllChilds()}
            okText={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />}
            cancelText={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
            content={
              <>
                <FormattedMessage
                  id="EldenEquipmentLog.MessageReflectToAllChilds"
                  defaultMessage="Do you really want to Reflect to all Childs ?"
                />
              </>
            }
          />

          <Drawer
            title={<FormattedMessage id="EldenEquipmentLog.Identification" defaultMessage="Identification" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ showIdentificationModal: false });
            }}
            open={this.state.showIdentificationModal}
          >
            <EldenEquipmentLogIdentificationDrawer random={this.state.random} eldenEquipmentTreeId={this.state.eldenEquipmentTreeId} />
          </Drawer>

          <DraggableModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={400}
            maskClosable={false}
            open={this.state.showDeleteProductModal}
            okText={<FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />}
            onOk={this.deleteProduct}
            onCancel={() => {
              this.setState({ showDeleteProductModal: false });
            }}
            content={
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            }
          />

        </div>
      </Badge.Ribbon>
    );

  }
}



const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EldenEquipmentTree);