import "antd/dist/antd.css";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { SaveOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import { Form, Table, Select, Cascader, Button as AntButton, Col, Row, Tag, Modal as AntModal, Space, InputNumber, DatePicker, Input, Tooltip, Spin } from "antd";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import VendorSelectionsubTable from "./VendorSelectionSubTable";
import XMLParser from "react-xml-parser";
import { round } from "mathjs";
import { ExclamationCircleOutlined, FilterOutlined } from "@ant-design/icons";
import POMonthlyInterestRate from "./POMonthlyInterestRate";
import DraggableModal from "../../Components/DraggableModal";

const { Option } = Select;

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate"
      ? form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY") : ""
      })
      : form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
      });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "planedDeliveryDate") {
        const values = await form.validateFields();
        values.planedDeliveryDate = Boolean(values.planedDeliveryDate) ? moment(values.planedDeliveryDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "paymentDate") {
        const values = await form.validateFields();
        values.paymentDate = Boolean(values.paymentDate) ? moment(values.paymentDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) { }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <>
        {dataIndex === "deliveryPlace" ? (
          <>
            <Form.Item
              label={<FormattedMessage id="VendorSelectionOnTotalNew.town" defaultMessage="Town" />}
              name="town"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Form.Item
              label={<FormattedMessage id="VendorSelectionOnTotalNew.city" defaultMessage="City" />}
              name="city"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Button id="SaveButton" onClick={toggleSave} style={{ width: "100%" }} variant="success">
              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
            </Button>
          </>
        ) : (
          <Form.Item
            style={{
              margin: 0
            }}
            name={dataIndex}
          >
            {dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate" ? (
              <DatePicker ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY"} />
            ) : dataIndex === "currency" ? (
              <Select ref={inputRef} onChange={toggleSave} onBlur={toggleSave} showSearch optionFilterProp="children" style={{ width: "200px" }}>
                {Boolean(record.currencySelectItems) &&
                  record.currencySelectItems.length > 0 &&
                  record.currencySelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            ) : dataIndex === "unit" ? (
              <Cascader
                style={{ width: "100%" }}
                options={record.unitSelectItems}
                ref={inputRef} onChange={toggleSave}
                onBlur={toggleSave}
              />
            ) : (
              <InputNumber parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")}
                min={0} ref={inputRef} onPressEnter={toggleSave} onBlur={toggleSave} />
            )}
          </Form.Item>
        )}
      </>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion



const render2Tags = (value, orderIndex) => {
  let colors = ["green", "blue", "red", "yellow", "orange", "purple", "pink", "cyan", "magenta", "teal"];
  return (
    <table className="table table-bordered table-sm">
      <tbody>
        <tr key={0}>
          <td>
            {" "}
            <Tag color="green">{value}</Tag>{" "}
          </td>
          <td>
            {" "}
            <Tag color={colors[(colors.length + 1) % orderIndex]}>{orderIndex}</Tag>{" "}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

function getUnitPriceOrderIndex(list, productNameCode, vendorId) {
  let productList = list.filter(p => p.productNameCode === productNameCode);

  productList.sort((a, b) => {
    const priceA = parseFloat((a.calculatedUnitPrice || 0).toFixed(2));
    const priceB = parseFloat((b.calculatedUnitPrice || 0).toFixed(2));
    return priceA - priceB;
  });

  let orderIndex = 1;
  for (let i = 0; i < productList.length; i++) {
    const currentPrice = parseFloat((productList[i].calculatedUnitPrice || 0).toFixed(2));

    if (i === 0 || Math.abs(currentPrice - (parseFloat(productList[i - 1].calculatedUnitPrice) || 0)) < 0.001) {
      productList[i].priceOrder = orderIndex;
    } else {
      orderIndex++;
      productList[i].priceOrder = orderIndex;
    }
  }
  let result = productList.find(p => p.vendorId === vendorId);
  if (result) {
    return result.priceOrder;
  } else {
    return null;
  }
}

function getPlannedDeliveryDateOrderIndex(list, productNameCode, vendorId) {
  let productList = [...list].filter(p => p.productNameCode === productNameCode);
  const compareDates = (a, b) => moment(a, "YYYY-MM-DD") - moment(b, "YYYY-MM-DD");
  productList.sort((a, b) => compareDates(a.planedDeliveryDate, b.planedDeliveryDate));

  let orderIndex = 1;
  for (let i = 0; i < productList.length; i++) {
    if (i === 0 || compareDates(productList[i].planedDeliveryDate, productList[i - 1].planedDeliveryDate) === 0) {
      productList[i].pddOrder = orderIndex;
    } else {
      orderIndex++;
      productList[i].pddOrder = orderIndex;
    }
  }

  let result = productList.find(p => p.vendorId === vendorId);
  if (result) {
    return result.pddOrder;
  } else {
    return null;
  }
}

function getAverageOrderIndex(list) {
  list.sort((a, b) => a.average - b.average);
  let orderIndex = 1;
  for (let i = 0; i < list.length; i++) {
    if (i === 0) {
      list[i].averageOrder = orderIndex;
    } else {
      if (list[i].average === list[i - 1].average) {
        list[i].averageOrder = orderIndex;
      } else {
        orderIndex++;
        list[i].averageOrder = orderIndex;
      }
    }
  }
}

function getAverageWithShippingOrderIndex(list) {
  list.sort((a, b) => a.averageWithShipping - b.averageWithShipping);
  let orderIndex = 1;
  for (let i = 0; i < list.length; i++) {
    if (i === 0) {
      list[i].averageWithShippingOrder = orderIndex;
    } else {
      if (list[i].averageWithShipping === list[i - 1].averageWithShipping) {
        list[i].averageWithShippingOrder = orderIndex;
      } else {
        orderIndex++;
        list[i].averageWithShippingOrder = orderIndex;
      }
    }
  }
}

function setOrderByGivenProperty(tableList, property, productId) {
  tableList.forEach(element => {
    if (Boolean(element.costDetail)) {
      let fObj = element.costDetail.find(p => p.projectTakeOffQuantityId === productId);
      if (Boolean(fObj)) {
        element.tableOrderIndex = fObj[property];
      } else {
        element.tableOrderIndex = Math.max();
      }
    } else element.tableOrderIndex = Math.max();
  });

  tableList.sort((a, b) => a.tableOrderIndex - b.tableOrderIndex);
}

function setOrderShowingTableList(showingTableList) {
  if (Boolean(showingTableList) && showingTableList.length > 0) {
    return showingTableList.sort((a, b) => {
      if (a.projectTakeOffQuantityId !== b.projectTakeOffQuantityId) {
        return a.projectTakeOffQuantityId - b.projectTakeOffQuantityId;
      }
      if (a.planedDeliveryDateOrderIndex !== b.planedDeliveryDateOrderIndex) {
        return a.planedDeliveryDateOrderIndex - b.planedDeliveryDateOrderIndex;
      }
      if (a.unitPriceOrderIndex !== b.unitPriceOrderIndex) {
        return a.unitPriceOrderIndex - b.unitPriceOrderIndex;
      }
    });
  }
}

export class VendorSelectionOnTotalNew extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "vendorScoringOnTotalQuotation",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      inputTypeIdSelectItems: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      rfqId: null,
      excelData: [],
      selectedRowKeys: [],
      selectedVendorExpectedPricesKeys: [],
      showAllTableList: true,
      paymentMethodSelectItems: [],
      currencyInterestSelectItems: [],
      currency: null,
      requestedQuantityUnit: null,
      quantityUnitSelectItems: [],
      productId: null,
      productSelectItems: [],
      showColumn: "all",
      saveLoading: false,
      showColumnSelectItems: [
        {
          title: "All",
          key: "all"
        },
        {
          title: "Deadline",
          key: "deadline"
        },
        {
          title: "Unit Price",
          key: "unitPrice"
        },
        {
          key: "ShippingPrice",
          title: "Shipping Price(Kg)"
        },
        { key: "total", title: "Total" },
        { key: "totalWithShipping", title: "Total With Shipping" },
        { key: "average", title: "Average" },
        { key: "averageWithShipping", title: "Average With Shippning" }
      ],
      paymentMethod: null,
      otherRfqNoList: [],
      tableListPaymentTerms: [],
      columnsPaymentTerms: [
        {
          title: <FormattedMessage id="VendorSelectionOnTotalNew.Product" defaultMessage="Product" />,
          key: "id",
          render: row => {
            return row.productNameCode;
          },
          date: new Date("01-01-1990"),
        },
        {
          title: <FormattedMessage id="VendorSelectionOnTotalNew.Supplier" defaultMessage="Supplier" />,
          key: "id",
          render: row => {
            return row.vendorName;
          },
          date: new Date("01-01-1990"),
        },
        {
          title: <FormattedMessage id="VendorSelectionOnTotalNew.CalculatedTotal" defaultMessage="Calculated Total" />,
          key: "id",
          render: row => {
            return Boolean(row.calculatedTotal) &&
              render2Tags(row.calculatedTotal.toFixed(2), row.calculatedTotalIndex);
          },
          date: new Date("01-01-1990"),
        },
      ],
    };
  }

  formRef = React.createRef();
  formMonthlyInterestRate = React.createRef();
  formRefPaymentTerms = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = (prevProps) => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.fillCurrencyInterest();

    var paymentMethodResponse = await handleRequest("GET", "/api/vendorScoringOnTotalQuotation/paymentMethod/" + this.props.requestForQuotation.id);
    if (paymentMethodResponse.type === "ERROR") {
      error(paymentMethodResponse);
    }

    var responseKur = await handleRequest("GET", "/api/vendorScoringOnTotalQuotation/kur");
    if (responseKur.type === "ERROR") {
      error(responseKur);
    }

    var responseAllUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/allUnit");
    if (responseAllUnit.type === "ERROR") {
      error(responseAllUnit);
    }

    var responseQuantityUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/quantityUnit/" + this.props.requestForQuotation.id);
    if (responseQuantityUnit.type === "ERROR") {
      error(responseQuantityUnit);
    }

    var kur = null;
    try {
      kur = new XMLParser().parseFromString(responseKur.data);
    } catch (error) { }

    this.setState({
      kur: kur,
      rfqId: this.props.requestForQuotation.id,
      paymentMethodSelectItems: Boolean(paymentMethodResponse.data) && paymentMethodResponse.data.length > 0 ? paymentMethodResponse.data : [],
      paymentMethod: null,
      tableListPaymentTerms: [],
      allUnitSelectItems: Boolean(responseAllUnit.data) ? responseAllUnit.data : [],
      quantityUnitSelectItems: Boolean(responseQuantityUnit.data) ? responseQuantityUnit.data : [],
    }, () => {
      this.loadRfqVendorExpectedPricesByRfqId();
    });
  };

  fillCurrencyInterest = async () => {
    var currencyInterestResponse = await handleRequest("GET", "/api/vendorScoringOnTotalQuotation/currencyInterest");
    if (currencyInterestResponse.type === "ERROR") {
      error(currencyInterestResponse);
    }
    this.setState({
      currencyInterestSelectItems: Boolean(currencyInterestResponse.data) ? currencyInterestResponse.data : [],
    });
  };

  getKurValue() {
    let dollarKur = 0,
      euroKur = 0,
      poundKur = 0,
      srKur = 0;
    if (Boolean(this.state.kur) && Boolean(this.state.kur.children) && this.state.kur.children.length > 0) {
      this.state.kur.children.forEach(element => {
        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "USD") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            dollarKur = obj.value;
          }
        }

        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "EUR") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            euroKur = obj.value;
          }
        }

        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "GBP") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            poundKur = obj.value;
          }
        }

        if (Boolean(element.children) && element.children.length > 0 && element.attributes && element.attributes.Kod === "SAR") {
          let obj = element.children.find(p => p.name === "ForexSelling");
          if (Boolean(obj)) {
            srKur = obj.value;
          }
        }
      });
    }
    return { dollarKur, euroKur, poundKur, srKur };
  }

  getPriceWtihKur(currency, price) {
    let { dollarKur, euroKur, poundKur, srKur } = this.getKurValue();
    let priceSubTotal = 0;
    if (currency === "DOLLAR") {
      if (this.state.currency === "DOLLAR") {
        priceSubTotal += price;
      } else if (this.state.currency === "EURO") {
        priceSubTotal += price * (dollarKur / euroKur);
      } else if (this.state.currency === "POUND") {
        priceSubTotal += price * (dollarKur / poundKur);
      } else if (this.state.currency === "SR") {
        priceSubTotal += price * (dollarKur / srKur);
      } else if (this.state.currency === "TL") {
        priceSubTotal += price * dollarKur;
      }
    } else if (currency === "EURO") {
      if (this.state.currency === "EURO") {
        priceSubTotal += price;
      } else if (this.state.currency === "DOLLAR") {
        priceSubTotal += price * (euroKur / dollarKur);
      } else if (this.state.currency === "POUND") {
        priceSubTotal += price * (euroKur / poundKur);
      } else if (this.state.currency === "SR") {
        priceSubTotal += price * (euroKur / srKur);
      } else if (this.state.currency === "TL") {
        priceSubTotal += price * euroKur;
      }
    } else if (currency === "POUND") {
      if (this.state.currency === "POUND") {
        priceSubTotal += price;
      } else if (this.state.currency === "EURO") {
        priceSubTotal += price * (poundKur / euroKur);
      } else if (this.state.currency === "DOLLAR") {
        priceSubTotal += price * (poundKur / dollarKur);
      } else if (this.state.currency === "SR") {
        priceSubTotal += price * (poundKur / srKur);
      } else if (this.state.currency === "TL") {
        priceSubTotal += price * poundKur;
      }
    } else if (currency === "SR") {
      if (this.state.currency === "SR") {
        priceSubTotal += price;
      } else if (this.state.currency === "POUND") {
        priceSubTotal += price * (srKur / poundKur);
      } else if (this.state.currency === "EURO") {
        priceSubTotal += price * (srKur / euroKur);
      } else if (this.state.currency === "DOLLAR") {
        priceSubTotal += price * (srKur / dollarKur);
      } else if (this.state.currency === "TL") {
        priceSubTotal += price * srKur;
      }
    } else if (currency === "TL") {
      if (this.state.currency === "TL") {
        priceSubTotal += price;
      } else if (this.state.currency === "SR") {
        priceSubTotal += price * (1 / srKur);
      } else if (this.state.currency === "POUND") {
        priceSubTotal += price * (1 / poundKur);
      } else if (this.state.currency === "EURO") {
        priceSubTotal += price * (1 / euroKur);
      } else if (this.state.currency === "DOLLAR") {
        priceSubTotal += price * (1 / dollarKur);
      }
    } else {
      return 0;
    }
    return priceSubTotal;
  }

  loadRfqVendorExpectedPricesByRfqId = async () => {
    this.setState({ loading: true });
    var { rfqId, currency, productId, paymentMethod } = this.state;
    if (!Boolean(paymentMethod)) {
      paymentMethod = -1;
    }

    if (Boolean(rfqId)) {
      var response = await handleRequest("GET", "/api/vendorScoringOnTotalQuotation/vendorprices/" + rfqId + "/" + paymentMethod);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          tableList: [],
          selectedRowKeys: [],
          loading: false,
        });
      } else {
        let tableList = [];
        let productSelectItems = [];
        let data = response.data;
        for (let i = 0; i < data.length; i++) {
          if (tableList.findIndex(x => x.vendorId === data[i].vendorId) === -1) {
            let obj = { key: data[i].vendorId, vendorId: data[i].vendorId, vendorName: data[i].vendorName, total: 0, rank: 0, costDetail: [], unitName: data[i].unitName };
            let vendorPrices = data.filter(x => x.vendorId === data[i].vendorId);
            let totalObj = { productNameCode: "Total", quantity: 0.0, total: 0.0, totalWithShipping: 0.0, isTotalRow: true };
            vendorPrices.forEach(element => {
              if (Boolean(productId)) {
                if (productId === element.projectTakeOffQuantityId) {
                  if (Boolean(currency)) this.customiseElementWithCurrency(element, obj, totalObj, productSelectItems, data, i, currency);
                  else this.customiseElement(element, obj, totalObj, productSelectItems, data, i);
                }
              } else {
                if (Boolean(currency)) this.customiseElementWithCurrency(element, obj, totalObj, productSelectItems, data, i, currency);
                else this.customiseElement(element, obj, totalObj, productSelectItems, data, i);
              }
            });

            totalObj.quantity = round(totalObj.quantity, 2);
            totalObj.total = round(totalObj.total, 2);
            totalObj.totalWithShipping = round(totalObj.totalWithShipping, 2);
            //obj.costDetail.push(totalObj);
            obj.average = round(totalObj.total / totalObj.quantity, 2);
            obj.averageWithShipping = round(totalObj.totalWithShipping / totalObj.quantity, 2);
            obj.weight = data[i].weight;
            obj.length = data[i].length;
            tableList.push(obj);
          }
        }
        getAverageOrderIndex(tableList);
        getAverageWithShippingOrderIndex(tableList);
        this.setState({ tableList });
        this.setState({ productSelectItems });
        this.getSelectedKeysFromDB(tableList, rfqId);
        this.setState({ loading: false, });
      }
    } else {
      error("Please select RFQ");
      this.setState({
        tableList: [],
        selectedRowKeys: [],
        loading: false,
      });
    }
  };

  customiseElement = (element, obj, totalObj, productSelectItems, data, i) => {
    element.unitPrice = Boolean(element.unitPrice) ? element.unitPrice : 0;
    element.calculatedUnitPrice = element.unitPrice;
    element.quantity = Boolean(element.quantity) ? element.quantity : 0;
    element.shippingPrice = Boolean(element.shippingPrice) ? element.shippingPrice : 0;
    let cd = {
      key: element.id,
      id: element.id, //rfqVendorExpectedPriceId
      isTotalRow: false,
      selected: false,
      weightFeature: element.weightFeature,
      quantityUnit: element.quantityUnit,
      quantityUnitType: element.quantityUnitType,
      stockcardCodeDimensions: element.stockcardCodeDimensions,
      residueOfDimensions: element.residueOfDimensions,
      projectTakeOffQuantityId: element.projectTakeOffQuantityId,
      productNameCode: element.productNameCode,
      unitPrice: element.unitPrice,
      calculatedUnitPrice: element.calculatedUnitPrice,
      quantity: element.quantity,
      planedDeliveryDate: element.planedDeliveryDate,
      shippingPrice: element.shippingPrice,
      total: round(element.unitPrice * element.quantity, 2),
      totalWithShipping: round((element.unitPrice + element.shippingPrice) * element.weight, 2),
      currency: Boolean(element.currency) ? element.currency : "TL",
      city: element.city,
      paymentTerms: element.paymentTerms,
    };
    obj.costDetail.push(cd);
    this.ifNotInListAddToProductSelectItems(cd, productSelectItems);
    totalObj.quantity += cd.quantity;
    totalObj.total += cd.total;
    totalObj.totalWithShipping += cd.totalWithShipping;
  };

  customiseElementWithCurrency = (element, obj, totalObj, productSelectItems, data, i, currency) => {
    element.unitPrice = Boolean(element.unitPrice) ? element.unitPrice : 0;
    element.calculatedUnitPrice = element.unitPrice;
    element.quantity = Boolean(element.quantity) ? element.quantity : 0;
    element.shippingPrice = Boolean(element.shippingPrice) ? element.shippingPrice : 0;
    let up = round(this.getPriceWtihKur(Boolean(element.currency) ? element.currency : "TL", element.unitPrice), 2);
    let sp = round(this.getPriceWtihKur(Boolean(element.currency) ? element.currency : "TL", element.shippingPrice), 2);
    let cd = {
      key: element.id,
      id: element.id, //rfqVendorExpectedPriceId
      isTotalRow: false,
      selected: false,
      weightFeature: element.weightFeature,
      quantityUnit: element.quantityUnit,
      quantityUnitType: element.quantityUnitType,
      stockcardCodeDimensions: element.stockcardCodeDimensions,
      residueOfDimensions: element.residueOfDimensions,
      projectTakeOffQuantityId: element.projectTakeOffQuantityId,
      productNameCode: element.productNameCode,
      unitPrice: up,
      calculatedUnitPrice: up,
      quantity: element.quantity,
      planedDeliveryDate: element.planedDeliveryDate,
      shippingPrice: sp,
      total: round(up * element.quantity, 2),
      totalWithShipping: round((up + sp) * element.quantity, 2),
      currency: currency,
      city: element.city,
    };
    obj.costDetail.push(cd);
    this.ifNotInListAddToProductSelectItems(cd, productSelectItems);
    totalObj.quantity += cd.quantity;
    totalObj.total += cd.total;
    totalObj.totalWithShipping += cd.totalWithShipping;
  };

  getDimensionValue = (dimension, dimensionId, cd) => {
    let dimensionValue = "DIMENSION_NOT_FOUND";
    let stockcardCodeDimensions = cd.stockcardCodeDimensions;
    let rdo = cd.residueOfDimensions;

    if (Boolean(rdo) && rdo.length > 0) {
      rdo.forEach(element => {
        if (element.itemDimensionId === dimensionId) {
          if (Boolean(element.value)) {
            dimensionValue = element.value;
          }
        }
      });
    }

    if (dimensionValue === "DIMENSION_NOT_FOUND" && stockcardCodeDimensions.length > 0) {
      stockcardCodeDimensions.forEach(element => {
        if (element.itemDefinition === dimension) {
          dimensionValue = Boolean(Number(element.value)) ? Number(element.value) : "DIMENSION_NOT_FOUND";
        }
      });
    }

    return dimensionValue;
  };

  ifNotInListAddToProductSelectItems = (cd, productSelectItems) => {
    if (productSelectItems.findIndex(p => p.key === cd.projectTakeOffQuantityId) === -1) {
      productSelectItems.push({ key: cd.projectTakeOffQuantityId, value: cd.productNameCode });
    }
  };

  getSelectedKeysFromDB = async (tableList, rfqId) => {
    var response = await handleRequest("GET", "/api/vendorScoringOnTotalQuotation/selectedvendors/" + rfqId);
    if (response.type === "ERROR") {
      error(response);
    } else {
      let selectedRowKeys = [];
      let data = response.data;
      if (Boolean(data) && data.length > 0) {
        data.forEach(element => {
          if (selectedRowKeys.findIndex(p => p === element.vendorId) === -1) {
            selectedRowKeys.push(element.vendorId);
            let row = tableList.find(p => p.vendorId === element.vendorId);
            let costDetail = Boolean(row) ? row.costDetail : [];
            let responseCostDetail = data.filter(p => p.vendorId === element.vendorId);

            responseCostDetail.forEach(element => {
              let f = costDetail.findIndex(c => c.id === element.rfqVendorExpectedPriceId);
              if (f !== -1) costDetail[f].selected = element.selected;
            });
          }
        });
      } else {
        tableList.forEach(element => {
          if (selectedRowKeys.findIndex(p => p === element.vendorId) === -1) {
            selectedRowKeys.push(element.vendorId);
          }
          if (element.costDetail)
            element.costDetail.forEach(cd => {
              cd.selected = true;
            });
        });
      }
      this.setState({ selectedRowKeys }, this.setShowingTableList);
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  handleTableChange = (pagination, filters, sorter) => { };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  updatePriceSelect = async (vendorId, costDetail) => {
    const { tableList } = this.state;

    let item = tableList.find(p => p.vendorId === vendorId);
    item.costDetail = costDetail;
    this.setState({ tableList }, this.setShowingTableList);
  };

  setShowingTableList = () => {
    const { showAllTableList, showingTableList, tableList, selectedRowKeys } = this.state;

    if (showAllTableList) {
      let showingTableList = [];
      if (Boolean(tableList) && tableList.length > 0) {
        tableList.forEach(element => {
          if (Boolean(element.costDetail) && element.costDetail.length > 0) {
            element.costDetail.forEach(cost => {
              const combinedObj = {
                ...element,
                ...cost,
                key: cost.key
              };
              showingTableList.push(combinedObj);
            });
          }
        });
      }

      if (Boolean(showingTableList) && showingTableList.length > 0) {
        showingTableList.forEach(element => {
          let unitPriceOrderIndex = getUnitPriceOrderIndex(showingTableList, element.productNameCode, element.vendorId);
          element.unitPriceOrderIndex = unitPriceOrderIndex;

          let planedDeliveryDateOrderIndex = getPlannedDeliveryDateOrderIndex(showingTableList, element.productNameCode, element.vendorId);
          element.planedDeliveryDateOrderIndex = planedDeliveryDateOrderIndex;
        });
      }

      this.setState({
        showingTableList: showingTableList
      }, () => {
        this.fillCurrencyInterest();
        this.paymentTermsModal(false);
      });
    } else {
      if (Boolean(selectedRowKeys) && selectedRowKeys.length > 0) {
        let temp = tableList.slice();
        let fTableList = temp.filter(p => selectedRowKeys.includes(p.vendorId));

        if (Boolean(fTableList) && fTableList.length > 0) {
          fTableList.forEach(element => {
            let unitPriceOrderIndex = getUnitPriceOrderIndex(fTableList, element.productNameCode, element.vendorId);
            element.unitPriceOrderIndex = unitPriceOrderIndex;

            let planedDeliveryDateOrderIndex = getPlannedDeliveryDateOrderIndex(fTableList, element.productNameCode, element.vendorId);
            element.planedDeliveryDateOrderIndex = planedDeliveryDateOrderIndex;
          });
        }

        this.setState({
          showingTableList: fTableList
        }, () => {
          this.fillCurrencyInterest();
          this.paymentTermsModal(false);
        });
      } else {
        this.setState({
          showingTableList: []
        });
      }
    }
  };

  renderCostDetail = (vendorId, costDetail) => {
    return (
      costDetail && (
        <VendorSelectionsubTable
          currency={this.state.currency}
          showColumn={this.state.showColumn}
          vendorId={vendorId}
          data={costDetail}
          onUpdatePriceSelect={this.updatePriceSelect.bind(this)}
        />
      )
    );
  };

  checkEquivalent = async () => {
    this.setState({ saveLoading: true });
    const { rfqId } = this.state;
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/checkEquivalent/" + rfqId);
    if (response.type === "ERROR") {
      error(response);
      this.setState({ saveLoading: false });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        let otherRfqNoList = [];
        response.data.forEach(element => {
          otherRfqNoList.push(element.rfqNo);
        });
        this.setState({
          otherRfqList: response.data,
          otherRfqNoList: otherRfqNoList,
          modalcheckEquivalentShow: true
        });
      } else {
        this.setState({
          otherRfqNoList: [],
        }, () => {
          this.save(false);
        });
      }
    }
  }

  save = (equivelantItemDelete) => {
    this.setState({ saveLoading: true });
    const { showingTableList, rfqId, selectedRowKeys, otherRfqList } = this.state;

    let list = [];
    if (Boolean(selectedRowKeys) && selectedRowKeys.length > 0) {
      let control = true;
      for (let i = 0; i < selectedRowKeys.length; i++) {
        const element = selectedRowKeys[i];
        let fObj = [...showingTableList].find(x => x.key === element);

        if (Boolean(fObj) && Boolean(fObj.costDetail) && fObj.costDetail.length > 0) {
          for (let index = 0; index < fObj.costDetail.length; index++) {
            const cd = fObj.costDetail[index];

            if (!cd.isTotalRow) {
              cd.selected = true;
              const obj = {
                vendorId: fObj.vendorId,
                rfqVendorExpectedPriceId: cd.id,
                requestForQuotationId: rfqId,
                projectTakeOffQuantityId: cd.projectTakeOffQuantityId,
                selected: cd.selected,
                averagePrice: fObj.average,
                averagePriceWithShipping: fObj.averageWithShipping,
                shippingPrice: fObj.shippingPrice
              };

              // if (cd.selected && list.findIndex(p => p.vendorId !== obj.vendorId && p.projectTakeOffQuantityId === obj.projectTakeOffQuantityId && p.selected) !== -1) {
              //   control = false;
              // }
              list.push(obj);
            }
          }
        }
      }
      if (control && list.length > 0) {
        let otherRfqIds = [];
        if (Boolean(otherRfqList) && otherRfqList.length > 0 && equivelantItemDelete) {
          otherRfqList.forEach(element => {
            otherRfqIds.push(element.id);
          });
        } else {
          otherRfqIds.push(-1);
        }

        let response = handleRequest("POST", "/api/vendorScoringOnTotalQuotation/setSelectedKeys/" + otherRfqIds, list);
        if (response.type === "ERROR") {
          error(response);
          this.setState({ saveLoading: false });
        } else {
          this.setState({
            modalcheckEquivalentShow: false
          });
          showSuccess();
          if (Boolean(this.props.closeVendorEvaluation)) {
            this.props.closeVendorEvaluation();
          }
          this.setState({ saveLoading: false });
        }
      } else {
        // if (list.length > 0)
        //   showError("There is same products that selected for two or more vendor!");
        // else 
        showError("Please select at least one vendor and product !");
        this.setState({ saveLoading: false });
      }
    } else {
      let response = handleRequest("DELETE", "/api/vendorScoringOnTotalQuotation/setSelectedKeys/" + rfqId);
      if (response.type === "ERROR") {
        error(response);
        this.setState({ saveLoading: false });
      } else {
        this.setState({
          modalcheckEquivalentShow: false
        });
        showSuccess();
        if (Boolean(this.props.closeVendorEvaluation)) {
          this.props.closeVendorEvaluation();
        }
        this.setState({ saveLoading: false });
      }
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys }, this.setShowingTableList);
  };

  updateTableOrder = () => {
    const { productId, showColumn, tableList } = this.state;

    if (Boolean(productId) && Boolean(showColumn)) {
      switch (showColumn) {
        case "all":
          getAverageWithShippingOrderIndex(tableList);
          break;
        case "deadline":
          setOrderByGivenProperty(tableList, "planedDeliveryDateOrderIndex", productId);
          break;
        case "unitPrice":
          setOrderByGivenProperty(tableList, "unitPriceOrderIndex", productId);
          break;

        case "ShippingPrice":
          setOrderByGivenProperty(tableList, "shippingPrice", productId);
          break;

        case "total":
          setOrderByGivenProperty(tableList, "total", productId);
          break;

        case "totalWithShipping":
          setOrderByGivenProperty(tableList, "totalWithShipping", productId);
          break;

        case "average":
          getAverageOrderIndex(tableList);
          break;

        case "averageWithShipping":
          getAverageWithShippingOrderIndex(tableList);
          break;

        default:
          break;
      }
    }

    this.setState({ tableList }, this.setShowingTableList);
  };

  getMonthlyInterestRateShow = () => {
    this.setState({
      modalMonthlyInterestRateShow: true,
    });
  };

  paymentTermsModal = (modalOpen) => {
    this.setState({
      tableListPaymentTerms: [],
      columnsPaymentTerms: [
        {
          title: <FormattedMessage id="VendorSelectionOnTotalNew.Product" defaultMessage="Product" />,
          key: "id",
          render: row => {
            return row.productNameCode;
          },
          date: new Date("01-01-1990"),
        },
        {
          title: <FormattedMessage id="VendorSelectionOnTotalNew.Supplier" defaultMessage="Supplier" />,
          key: "id",
          render: row => {
            return row.vendorName;
          },
          date: new Date("01-01-1990"),
        },
        {
          title: <FormattedMessage id="VendorSelectionOnTotalNew.CalculatedTotal" defaultMessage="Calculated Total" />,
          key: "id",
          render: row => {
            return Boolean(row.calculatedTotal) &&
              render2Tags(row.calculatedTotal.toFixed(2), row.calculatedTotalIndex);
          },
          date: new Date("01-01-1990"),
        },
      ],
    }, () => {
      if (Boolean(this.state.showingTableList) && this.state.showingTableList.length > 0) {
        const { columnsPaymentTerms } = this.state;
        let tableListPaymentTerms = [];
        let dateSelectItems = [];
        let index = 1;
        this.state.showingTableList.forEach(row => {
          if (Boolean(row.paymentTerms) && row.paymentTerms.length > 0) {
            row.paymentTerms.forEach(element => {
              if (Boolean(element.scheduleList) && element.scheduleList) {
                element.scheduleList.forEach((schedule) => {
                  if (Boolean(schedule.calcDate)) {
                    let obj = {
                      key: row.key,
                      title: moment(schedule.calcDate).format("DD-MM-YYYY"),
                      date: new Date(schedule.calcDate),
                      dataIndex: "amount" + (index),
                      vendorName: row.vendorName,
                      productNameCode: row.productNameCode,
                      ["amount" + (index)]: schedule.amount,
                      ["date" + (index)]: moment(schedule.calcDate).format("DD-MM-YYYY"),
                    };

                    let addColumn = columnsPaymentTerms.find(p => p.title === obj.title);
                    if (!Boolean(addColumn)) {
                      columnsPaymentTerms.push(obj);

                      dateSelectItems.push({ "title": obj.title, "date": obj.date });
                    }

                    let t = tableListPaymentTerms.find(p => p.vendorName === obj.vendorName && p.productNameCode === obj.productNameCode);
                    if (Boolean(t)) {
                      t["amount" + (index)] = schedule.amount;
                      t["date" + (index)] = moment(schedule.calcDate).format("DD-MM-YYYY");
                    } else {
                      tableListPaymentTerms.push(obj);
                    }
                    index++;
                  }
                });
              }
            });
          }
        });
        this.sortByDate(columnsPaymentTerms, tableListPaymentTerms, dateSelectItems, modalOpen);
      }
    });
  };

  sortByDate = (list, tableListPaymentTerms, dateSelectItems, modalOpen) => {
    const newItems = list.slice();
    newItems.sort((a, b) => {
      return a.date.getTime() - b.date.getTime();
    });

    const dateSelect = dateSelectItems.slice();
    dateSelect.sort((a, b) => {
      return a.date.getTime() - b.date.getTime();
    });

    this.setState({
      columnsPaymentTerms: newItems,
      tableListPaymentTerms: tableListPaymentTerms,
      dateSelectItems: dateSelect,
      modalPaymentTermsShow: modalOpen,
    });
  }

  calculatedTotalCash = () => {
    this.setState({
      tableListPaymentTermsRender: true,
    }, () => {
      const { tableListPaymentTerms, interestRate, showingTableList, paymentTermsDate } = this.state;
      if (Boolean(tableListPaymentTerms) && tableListPaymentTerms.length > 0 && Boolean(paymentTermsDate)) {
        if (Boolean(interestRate) && interestRate > 0) {
          let index = 1;
          tableListPaymentTerms.forEach(element => {
            let calculatedTotal = 0;
            do {
              if (element["date" + index] === paymentTermsDate) {
                calculatedTotal += Boolean(element["amount" + index]) ? element["amount" + index] : 0;
              } else {
                const amount = Boolean(element["amount" + index]) ? element["amount" + index] : 0;
                if (Boolean(amount) && amount > 0) {
                  var datePaymentTermsParts = paymentTermsDate.split("-");
                  var dateParts = element["date" + index].split("-");

                  const date1 = new Date(+datePaymentTermsParts[2], datePaymentTermsParts[1] - 1, +datePaymentTermsParts[0]);
                  const date2 = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

                  const timeDiff = date1.getTime() - date2.getTime();
                  const dayDiff = timeDiff / (1000 * 3600 * 24);

                  // Mutlak değer alınarak sonuç döndürülüyor
                  const absoluteDayDiff = Math.abs(dayDiff);

                  if (dayDiff > 0) {
                    let a = amount * (1 + ((absoluteDayDiff / 30) * (interestRate / 100)));
                    if (Boolean(a)) {
                      calculatedTotal += a;
                    }
                  } else {
                    let a = amount / (1 + ((absoluteDayDiff / 30) * (interestRate / 100)));
                    if (Boolean(a)) {
                      calculatedTotal += a;
                    }
                  }
                }
              }
              index++;
            } while (Boolean(element["date" + index]));
            element.calculatedTotal = calculatedTotal;
          });
        } else {
          showError("Interest Rate not found. Please Select Currency.");
        }
      }

      tableListPaymentTerms.sort((a, b) => a.calculatedTotal - b.calculatedTotal);

      if (Boolean(tableListPaymentTerms) && tableListPaymentTerms.length > 0) {
        tableListPaymentTerms.forEach((element, index) => {
          element.calculatedTotalIndex = index + 1;

          let table = showingTableList.find(p => p.key === element.key);
          if (Boolean(table)) {
            table.calculatedTotal = element.calculatedTotal;
            table.calculatedTotalIndex = element.calculatedTotalIndex;
          }
        });
      }
      this.setState({
        tableListPaymentTerms: tableListPaymentTerms,
        tableListPaymentTermsRender: false,
        showingTableList: showingTableList,
      });
    });
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search and click enter`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: filtered => <FilterOutlined style={{ color: filtered ? "#1890ff" : "#bfbfbf" }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleShippingPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.showingTableList];
      const tableList = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(item)) {
        item.shippingPrice = value.shippingPrice;

        if (Boolean(tableList) && tableList.length > 0) {
          tableList.map(element => {
            if (Boolean(element.costDetail) && element.costDetail.length > 0) {
              let costDetail = element.costDetail.find(p => p.key === item.key);
              if (Boolean(costDetail)) {
                costDetail.shippingPrice = value.shippingPrice;
                return;
              }
            }
          });
        }
        this.setState({
          showingTableList: newData,
          tableList: tableList,
        });
      }
    }
  };

  render() {
    const onFinishFailed = errorInfo => { };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      },
    };

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideSelectAll: true,
      renderCell: (checked, record, index, originNode) => {
        return originNode;
      }
    };

    const columns = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="VendorSelectionOnTotalNew.Supplier" defaultMessage="Supplier" />,
        key: "vendorName",
        render: row => {
          return <Tooltip
            color={"white"}
            title={<div className="w-100" style={{ color: "black" }}>
              {row.vendorName}
            </div>}
          >
            {Boolean(row.vendorName) && row.vendorName.length > 10 ? row.vendorName.substring(0, 10) + " ..." : row.vendorName}
          </Tooltip>
        },
        ...this.getColumnSearchProps("vendorName"),
        sorter: (a, b) => Boolean(a.vendorName) && Boolean(b.vendorName) && (a.vendorName + "").localeCompare(b.vendorName + "")
      },
      {
        title: <FormattedMessage id="VendorSelectionOnTotalNew.Product" defaultMessage="Product" />,
        key: "productNameCode",
        render: row => {
          return <Tooltip
            color={"white"}
            title={<div className="w-100" style={{ color: "black" }}>
              {row.productNameCode}
            </div>}
          >
            <div style={{ color: "black", whiteSpace: "nowrap" }}>
              {Boolean(row.productNameCode) && row.productNameCode.length > 10 ? row.productNameCode.substring(0, 10) + "..." : row.productNameCode}
            </div>
          </Tooltip>
        },
        ...this.getColumnSearchProps("productNameCode"),
        sorter: (a, b) => Boolean(a.productNameCode) && Boolean(b.productNameCode) && (a.productNameCode + "").localeCompare(b.productNameCode + "")
      },
      {
        title: <FormattedMessage id="VendorSelectionOnTotalNew.Offer" defaultMessage="Offer" />,
        key: "unit",
        render: record => {
          if (Boolean(this.state.requestedQuantityUnit)) {
            let weight = "";
            if (this.state.requestedQuantityUnit && Boolean(record.weight) && Boolean(record.quantity)) {
              if (Boolean(this.state.allUnitSelectItems) && this.state.allUnitSelectItems.length > 0) {
                let unit = this.state.allUnitSelectItems.find(p => p.abbreviation === this.state.requestedQuantityUnit);
                if (Boolean(unit.abbreviation) && unit.abbreviation === "ad") {
                  weight = record.quantity;
                } else {
                  if (unit.unitTypeName === "Length") {
                    if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                      weight = ((record.length * record.quantity) / unit.coefficent);
                    } else {
                      weight = (record.length * record.quantity);
                    }
                  } else {
                    if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                      weight = ((record.quantity * record.weight) / unit.coefficent);
                    } else {
                      weight = (record.weight * record.quantity);
                    }
                  }
                }
              }
            }
            return <div style={{ color: "black", whiteSpace: "nowrap" }}>
              {((weight.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ')) + " " + this.state.requestedQuantityUnit)}
            </div>
          } else {
            let weight = "";
            if (record.unitName && Boolean(record.weight) && Boolean(record.quantity)) {
              if (Boolean(this.state.allUnitSelectItems) && this.state.allUnitSelectItems.length > 0) {
                let unit = this.state.allUnitSelectItems.find(p => p.abbreviation === record.unitName);
                if (Boolean(unit.abbreviation) && unit.abbreviation === "ad") {
                  weight = record.quantity;
                } else {
                  if (unit.unitTypeName === "Length") {
                    if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                      weight = ((record.length * record.quantity) / unit.coefficent);
                    } else {
                      weight = (record.length * record.quantity);
                    }
                  } else {
                    if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                      weight = ((record.quantity * record.weight) / unit.coefficent);
                    } else {
                      weight = (record.weight * record.quantity);
                    }
                  }
                }
              }
            }
            return <div style={{ color: "black", whiteSpace: "nowrap" }}>
              {((weight.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ')) + " " + record.unitName)}
            </div>
          }
        },
      },
      {
        title: <FormattedMessage id="VendorSelectionOnTotalNew.Deadline" defaultMessage="Deadline" />,
        key: "deadline",
        render: row => {
          return !row.isTotalRow ? render2Tags(Boolean(row.planedDeliveryDate) ?
            moment(row.planedDeliveryDate).format("DD-MM-YYYY")
            : "", row.planedDeliveryDateOrderIndex) : "";
        },
        ...this.getColumnSearchProps("planedDeliveryDate"),
        sorter: (a, b) => Boolean(a.planedDeliveryDate) && Boolean(b.planedDeliveryDate) && (a.planedDeliveryDate + "").localeCompare(b.planedDeliveryDate + "")
      },
      {
        title: <FormattedMessage id="VendorSelectionOnTotalNew.UnitPrice" defaultMessage="Unit Price" />,
        key: "unitPrice",
        render: row => {
          let unitPrice = row.unitPrice;
          if (Boolean(this.state.requestedQuantityUnit) && Boolean(row.unitName)) {
            if (this.state.requestedQuantityUnit && Boolean(row.weight) && Boolean(row.quantity)) {
              if (Boolean(this.state.allUnitSelectItems) && this.state.allUnitSelectItems.length > 0) {
                let selectedUnit = this.state.allUnitSelectItems.find(p => p.abbreviation === this.state.requestedQuantityUnit);
                let rowUnit = this.state.allUnitSelectItems.find(p => p.abbreviation === row.unitName);
                if (Boolean(selectedUnit.abbreviation)) {
                  let length = 1;
                  if (Boolean(row.length) && row.length > 0) {
                    length = row.length;
                  }
                  let meterWeight = row.weight / length;

                  if (selectedUnit.abbreviation === "ad") {
                    if (rowUnit.unitTypeName === "Weight") {
                      unitPrice = (row.unitPrice * row.weight) / rowUnit.coefficent;
                    } else if (row.unitName === "m") {
                      unitPrice = (row.unitPrice / meterWeight) * row.weight;
                    }
                  } else if (selectedUnit.unitTypeName === "Weight") {
                    if (rowUnit.unitTypeName === "Weight" && selectedUnit.abbreviation !== rowUnit.abbreviation) {
                      unitPrice = (row.unitPrice * row.weight) / rowUnit.coefficent;
                    } else if (rowUnit.unitTypeName === "Length") {
                      unitPrice = (row.unitPrice / meterWeight) / rowUnit.coefficent;
                    } else if (row.unitName === "ad") {
                      unitPrice = row.unitPrice / row.weight;
                    }
                  } else if (selectedUnit.unitTypeName === "Length") {
                    if (rowUnit.unitTypeName === "Length" && selectedUnit.abbreviation !== rowUnit.abbreviation) {
                      unitPrice = (row.unitPrice / meterWeight) / rowUnit.coefficent;
                    } else if (rowUnit.unitTypeName === "Weight") {
                      unitPrice = (row.unitPrice * meterWeight) / rowUnit.coefficent;
                    } else if (row.unitName === "ad") {
                      unitPrice = (row.unitPrice / row.weight) * meterWeight;
                    }
                  }
                }
              }
            }
          }

          if (Boolean(row.currency) && Boolean(this.state.currency)) {
            unitPrice = round(this.getPriceWtihKur(row.currency, unitPrice), 2);
          }
          row.calculatedUnitPrice = unitPrice;

          return render2Tags(unitPrice.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' '), row.unitPriceOrderIndex);
        },
        ...this.getColumnSearchProps("unitPrice"),
        sorter: (a, b) => Boolean(a.unitPrice) && Boolean(b.unitPrice) && (a.unitPrice + "").localeCompare(b.unitPrice + "")
      },
      {
        title: <FormattedMessage id="VendorSelectionOnTotalNew.Currency" defaultMessage="Currency" />,
        key: "currency",
        render: row => {
          return Boolean(this.state.currency) ? this.state.currency : row.currency;
        },
        ...this.getColumnSearchProps("currency"),
        sorter: (a, b) => Boolean(a.currency) && Boolean(b.currency) && (a.currency + "").localeCompare(b.currency + "")
      },
      {
        title: <FormattedMessage id="VendorSelectionOnTotalNew.ShippingPriceKg" defaultMessage="Shipping Price(Kg)" />,
        key: "shippingPrice",
        render: record => {
          if (Boolean(record.shippingPrice)) {
            return record.shippingPrice.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ');
          }
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "shippingPrice",
          title: <FormattedMessage id="VendorSelectionOnTotalNew.ShippingPrice" defaultMessage="Shipping Price" />,
          handleSave: this.handleShippingPrice
        }),
        ...this.getColumnSearchProps("shippingPrice"),
        sorter: (a, b) => Boolean(a.shippingPrice) && Boolean(b.shippingPrice) && (a.shippingPrice + "").localeCompare(b.shippingPrice + "")
      },
      {
        title: <FormattedMessage id="VendorSelectionOnTotalNew.TotalPrice" defaultMessage="Total Price" />,
        key: "total",
        render: record => {
          let total = record.total;
          if (Boolean(record.shippingPrice) && record.shippingPrice > 0) {
            let weight = "";
            if (Boolean(record.weight) && Boolean(record.quantity)) {
              if (Boolean(this.state.allUnitSelectItems) && this.state.allUnitSelectItems.length > 0) {
                let unit = this.state.allUnitSelectItems.find(p => p.abbreviation === "kg");
                if (Boolean(unit.abbreviation) && unit.abbreviation === "ad") {
                  weight = record.quantity;
                } else {
                  if (unit.unitTypeName === "Length") {
                    if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                      weight = ((record.length * record.quantity) / unit.coefficent);
                    } else {
                      weight = (record.length * record.quantity);
                    }
                  } else {
                    if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                      weight = ((record.quantity * record.weight) / unit.coefficent);
                    } else {
                      weight = (record.weight * record.quantity);
                    }
                  }
                }
              }
            }
            console.log("weight", weight);
            if (Boolean(weight) && weight > 0) {
              console.log("shippingPrice", weight * record.shippingPrice);
              total += weight * record.shippingPrice;
            }
          }
          if (Boolean(record.currency) && Boolean(this.state.currency)) {
            total = round(this.getPriceWtihKur(record.currency, total), 2);
          }
          return <div style={{ color: "black", whiteSpace: "nowrap" }}>
            {total.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ')}
          </div>
        },
        ...this.getColumnSearchProps("total"),
        sorter: (a, b) => Boolean(a.total) && Boolean(b.total) && (a.total + "").localeCompare(b.total + "")
      },
      {
        title: <FormattedMessage id="VendorSelectionOnTotalNew.CalculatedTotal" defaultMessage="Calculated Total" />,
        key: "calculatedTotal",
        render: row => {
          if (Boolean(row.calculatedTotal)) {
            let calculatedTotal = row.calculatedTotal;
            if (Boolean(row.currency) && Boolean(this.state.currency)) {
              calculatedTotal = round(this.getPriceWtihKur(row.currency, calculatedTotal), 2);
            }
            return render2Tags(calculatedTotal.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' '), row.calculatedTotalIndex);
          }
        },
        ...this.getColumnSearchProps("calculatedTotal"),
        sorter: (a, b) => Boolean(a.calculatedTotal) && Boolean(b.calculatedTotal) && (a.calculatedTotal + "").localeCompare(b.calculatedTotal + "")
      },
      {
        title: <FormattedMessage id="VendorSelectionOnTotalNew.GrandTotal" defaultMessage="Grand Total" />,
        key: "grandTotal",
        render: row => {
          return row.grandTotal;
        },
        ...this.getColumnSearchProps("grandTotal"),
        sorter: (a, b) => Boolean(a.grandTotal) && Boolean(b.grandTotal) && (a.grandTotal + "").localeCompare(b.grandTotal + "")
      },
      {
        title: <FormattedMessage id="VendorSelectionOnTotalNew.averageKgPrice" defaultMessage="Average Kg Price" />,
        key: "averageKgPrice",
        render: row => {
          return row.averageKgPrice;
        },
        ...this.getColumnSearchProps("averageKgPrice"),
        sorter: (a, b) => Boolean(a.averageKgPrice) && Boolean(b.averageKgPrice) && (a.averageKgPrice + "").localeCompare(b.averageKgPrice + "")
      },
      {
        title: <FormattedMessage id="VendorSelectionOnTotalNew.averageKgPriceWithShipment" defaultMessage="Average Kg Price With Shipment" />,
        key: "averageKgPriceWithShipment",
        render: row => {
          return row.averageKgPriceWithShipment;
        },
        ...this.getColumnSearchProps("averageKgPriceWithShipment"),
        sorter: (a, b) => Boolean(a.averageKgPriceWithShipment) && Boolean(b.averageKgPriceWithShipment) && (a.averageKgPriceWithShipment + "").localeCompare(b.averageKgPriceWithShipment + "")
      },
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.checkEquivalent} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <div>
            <Row gutter={[16, 16]}>
              <Col span={3}>
                <Spin spinning={this.state.saveLoading}>
                  <Button id="SaveButton" onClick={this.checkEquivalent} style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Spin>
              </Col>
              <Col span={3}>
                <Form.Item
                  extra={<FormattedMessage id="VendorSelectionOnTotalNew.Currency" defaultMessage="Currency" />}>
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    onChange={value => {
                      let interestRate = 0;
                      let currency = null;
                      if (Boolean(value) && Boolean(this.state["currencyInterestSelectItems"]) && this.state["currencyInterestSelectItems"].length > 0) {
                        let currencyInterest = this.state["currencyInterestSelectItems"].find(p => p.id === value);
                        if (Boolean(currencyInterest)) {
                          interestRate = currencyInterest.rate;
                          currency = currencyInterest.currency;
                        }
                      }
                      this.setState({
                        interestRate: interestRate,
                        currency: currency,
                      }, () => {
                        this.calculatedTotalCash();
                      });
                    }}
                  >
                    {Boolean(this.state["currencyInterestSelectItems"]) && this.state["currencyInterestSelectItems"].length > 0 &&
                      this.state["currencyInterestSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.currency}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  extra={<FormattedMessage id="VendorSelectionOnTotalNew.Date" defaultMessage="Date" />}>
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    onChange={value => {
                      this.setState({
                        paymentTermsDate: value
                      }, () => {
                        this.calculatedTotalCash();
                      });
                    }}
                  >
                    {Boolean(this.state["dateSelectItems"]) && this.state["dateSelectItems"].length > 0 &&
                      this.state["dateSelectItems"].map(i => (
                        <Option key={i.title} value={i.title}>
                          {i.title}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  extra={<FormattedMessage id="VendorSelectionOnTotalNew.Unit" defaultMessage="Unit" />}>
                  <Cascader
                    style={{ width: "100%" }}
                    options={this.state.quantityUnitSelectItems}
                    onChange={value => {
                      let unitId = Boolean(value) && value.length > 0 ? value[value.length - 1] : null;
                      let unitTypeId = Boolean(value) && value.length > 0 ? value[0] : null;
                      let unitAbbreviation = null;
                      if (Boolean(this.state.allUnitSelectItems) && this.state.allUnitSelectItems.length > 0) {
                        let unit = this.state.allUnitSelectItems.find(p => p.id === unitId);
                        if (Boolean(unit)) {
                          unitAbbreviation = unit.abbreviation
                        }
                      }
                      this.setState({ requestedQuantityUnit: unitAbbreviation });
                      // this.setState({ requestedQuantityUnit: unitAbbreviation }, this.loadRfqVendorExpectedPricesByRfqId);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Button id="InterestRateButton" onClick={this.getMonthlyInterestRateShow} style={{ width: "100%" }} variant="warning">
                  <FormattedMessage id="VendorSelectionOnTotalNew.InterestRate" defaultMessage="Interest Rate" />
                </Button>
              </Col>
              <Col span={3}>
                <Button id="ComparisionButton" onClick={() => {
                  this.fillCurrencyInterest();
                  this.paymentTermsModal(true);
                }} style={{ width: "100%" }} variant="danger">
                  <FormattedMessage id="VendorSelectionOnTotalNew.Comparision" defaultMessage="Comparision" />
                </Button>
              </Col>

            </Row>
          </div>
        </Form >

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            components={components}
            rowClassName={() => "editable-row"}
            rowSelection={rowSelection}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={setOrderShowingTableList(this.state.showingTableList)}
            loading={this.state.loading}
            pagination={false}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="VendorSelectionOnTotalNew.DoYouWantToDeleteTheOthers" defaultMessage="Do you want to delete the others ?" />
              </span>
            </span>
          }
          centered
          keyboard={false}
          maskClosable={false}
          open={this.state.modalcheckEquivalentShow}
          onCancel={() => this.setState({ modalcheckEquivalentShow: false })}
          cancelButtonProps={{ hidden: true }}
          okButtonProps={{ hidden: true }}
          footer={
            [
              <Space>
                <Button style={{ width: "100%" }} variant="secondary" onClick={() => this.save(false)}>
                  <FormattedMessage id="GeneralButtonNo" defaultMessage="No" />
                </Button>
                <Spin spinning={this.state.saveLoading}>
                  <Button style={{ width: "100%" }} variant="primary" onClick={() => this.save(true)}>
                    <FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />
                  </Button>
                </Spin>
              </Space>
            ]}

          content={<>
            <FormattedMessage id="VendorSelectionOnTotalNew.ThereAreOtherEquivelantItemsAddedOfRFQLists" defaultMessage="There are other equivelant items added of RFQ lists ?" />
            <br />
            <br />
            {this.state.otherRfqNoList.join(", ")}
          </>}
        >

        </DraggableModal>

        <DraggableModal
          title={<FormattedMessage id="VendorSelectionOnTotalNew.PaymentTerms" defaultMessage="Payment Terms" />}
          open={this.state.modalPaymentTermsShow}
          width={1300}
          centered
          keyboard={false}
          maskClosable={false}
          okButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ modalPaymentTermsShow: false });
          }}

          content={
            <>
              <Form initialValues={{ remember: false }} ref={this.formRefPaymentTerms}>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="VendorSelectionOnTotalNew.currency" defaultMessage="Currency" />}
                  name="currency"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    showSearch
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    onChange={value => {
                      let interestRate = 0;
                      if (Boolean(value) && Boolean(this.state["currencyInterestSelectItems"]) && this.state["currencyInterestSelectItems"].length > 0) {
                        let currencyInterest = this.state["currencyInterestSelectItems"].find(p => p.id === value);
                        if (Boolean(currencyInterest)) {
                          interestRate = currencyInterest.rate;
                        }
                      }
                      this.setState({
                        interestRate: interestRate,
                      }, () => {
                        this.calculatedTotalCash()
                      });
                    }}
                  >
                    {Boolean(this.state["currencyInterestSelectItems"]) && this.state["currencyInterestSelectItems"].length > 0 &&
                      this.state["currencyInterestSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.currency}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="VendorSelectionOnTotalNew.Date" defaultMessage="Date" />}
                  name="date"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    showSearch
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    onChange={value => {
                      this.setState({
                        paymentTermsDate: value
                      }, () => {
                        this.calculatedTotalCash()
                      });
                    }}
                  >
                    {Boolean(this.state["dateSelectItems"]) && this.state["dateSelectItems"].length > 0 &&
                      this.state["dateSelectItems"].map(i => (
                        <Option key={i.title} value={i.title}>
                          {i.title}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Form>

              {!Boolean(this.state.tableListPaymentTermsRender) &&
                <Table
                  bordered
                  scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                  columns={this.state.columnsPaymentTerms}
                  dataSource={this.state.tableListPaymentTerms}
                  loading={this.state.loading}
                  pagination={false}
                />
              }
            </>
          }
        >

        </DraggableModal>

        <DraggableModal
          title={<FormattedMessage id="VendorSelectionOnTotalNew.MonthlyInterestRate" defaultMessage="Monthly Interest Rate" />}
          open={this.state.modalMonthlyInterestRateShow}
          width={1300}
          centered
          keyboard={false}
          maskClosable={false}
          okButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ modalMonthlyInterestRateShow: false });
          }}
          content={
            <>
              <POMonthlyInterestRate random={Math.random()}></POMonthlyInterestRate>
            </>
          }
        >

        </DraggableModal>
      </div >
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VendorSelectionOnTotalNew);
