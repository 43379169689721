import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { Form, Col, Row, Tree, Radio, Button as AntButton, Button, Space, Input, Spin } from "antd";
import { handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import { FormattedMessage } from "react-intl";
import DraggableModal from "../../Components/DraggableModal";
import { Menu, Item, useContextMenu } from "react-contexify";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { FcEditImage } from "react-icons/fc";

const Menu_Brand = "Menu_Brand";

const { show } = useContextMenu();

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

export class VendorProduct extends Component {
  constructor({ vendorId, vendorName }) {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      vendorId: vendorId,
      vendorName: vendorName,
      currentVendorContactName: "",
      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      contactTypeIdSelectItems: null,
      countryIdSelectItems: null,
      type: "MATERIAL",
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      checkedKeys: null,
      halfCheckedKeys: null,
      checkedNodes: null,

      excelData: []
    };
  }

  formRef = React.createRef();
  formRefBrand = React.createRef();

  componentDidMount = async () => {
    this.fillTree();
  };

  componentDidUpdate = async nextProps => {
    let id = Boolean(this.props) && Boolean(this.props.vendorId) ? this.props.vendorId : null;
    if (id !== this.state.vendorId) {
      this.setState(
        {
          vendorId: this.props.vendorId,
          vendorName: this.props.vendorName
        },
        () => {
          this.fillTree();
        }
      );
    }
  };

  fillTree = async () => {
    this.setState({ loading: true });
    const { type } = this.state;
    var response = await handleRequest("GET", "/api/vendorProduct/root/" + type);

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(response.data))
        this.setState(
          {
            nodes: Boolean(response.data) ? response.data : [],
            loading: false
          },
          this.getCheckedKeys
        );
    }
  };

  getCheckedKeys = async () => {
    var res = await handleRequest("GET", "/api/vendorProduct/vendor/" + this.state.vendorId);
    if (res.type === "ERROR") {
      error(res);
    } else {
      let list = Boolean(res.data) ? res.data : [];
      let keyList = [];
      list.forEach(element => {
        keyList.push("CODE~" + element.stockCardId);
      });
      console.log(keyList);
      this.setState({
        checkedKeys: keyList
      });
    }
  };

  onLoadData = async loadedKeys => {
    console.log("onLoadData data", loadedKeys);
    console.log(loadedKeys);

    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async treenode => {
    const { expandedKeys } = this.state;
    const { type } = this.state;
    var nodes = this.state.nodes;
    console.log(treenode);
    let obj = { id: Boolean(treenode.key) ? treenode.key.split("~")[1] : -1, type: treenode.type, parentType: type };

    if (Boolean(treenode.key))
      var response = await handleRequest("GET", "/api/vendorProduct/subitems/" + obj.parentType + "/" + obj.type + "/" + obj.id);

    if (Boolean(response.data)) {
      const data = response.data;
      console.log(data);

      data.forEach(element => {
        if (element.type === "CODE") {
          element.isLeaf = true;
        }
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      console.log(res);
      this.setState({
        nodes: res
      });
    }
  };

  onCheck = async (checkedKeys, e) => {
    console.log(checkedKeys);
    console.log(e);
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys,
      checkedNodes: e.checkedNodes
    });

    let list = [];
    if (Boolean(checkedKeys) && checkedKeys.length > 0) {
      checkedKeys.forEach(element => {
        let obj = { stockCardId: element.split("~")[1], type: element.split("~")[0], vendorId: this.state.vendorId };
        if (obj.type === "CODE") {
          obj.code = e.checkedNodes.find(p => p.key === element).title;
          list.push(obj);
        }
      });

      if (Boolean(list) && list.length > 0) {
        var res = await handleRequest("POST", "/api/vendorProduct", list);
        if (res.type === "ERROR") {
          error(res);
        } else {
          showSuccess();
        }
      } else {
        showError("Please select items");
      }
    }
  };

  fillComboboxes = async () => {
    this.fillContactTypeIdSelectItems();
    var responsecountryId = await handleRequest("GET", "/api/countries/selectItems");
    if (responsecountryId.type === "ERROR") {
      error(responsecountryId);
    } else {
      this.setState({
        countryIdSelectItems: Boolean(responsecountryId.data) ? responsecountryId.data : []
      });
    }
  };
  fillContactTypeIdSelectItems = async () => {
    var responsecontactTypeId = await handleRequest("GET", "/api/codes/type/vendorContact");
    if (responsecontactTypeId.type === "ERROR") {
      error(responsecontactTypeId);
    } else {
      this.setState({
        contactTypeIdSelectItems: Boolean(responsecontactTypeId.data) ? responsecontactTypeId.data : []
      });
    }
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      currentVendorContactName: row.contactType.name + " " + row.address
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/vendorContact/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });

    let url = "/api/vendor/tree";
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      let list = response.data;
      list.forEach((element, index) => {
        element.index = index + 1;
      });
      this.setState({
        loading: false,
        nodes: list,
        pagination: {
          ...params.pagination,
          total: response.data.totalRecords
        },
        totalRecords: response.data.totalRecords,
        sortField: params.sortField,
        sortOrder: params.sortOrder
      });
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      vendorId: this.state.vendorId,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/vendorContact", newItem);
    } else {
      response = await handleRequest("PUT", "/api/vendorContact/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.resetInputs();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  saveBrand = async values => {
    if (Boolean(values.brandDetails) && values.brandDetails.length > 0) {
      const newItem = {
        vendorId: this.state.vendorId,
        categoryId: this.state.categoryId,
        descriptionId: this.state.descriptionId,
        stockCardId: this.state.stockCardId,
        brandDetails: values.brandDetails.filter(p => (p.active === undefined || p.active === true))
      };

      console.log("newItem", newItem);
      var response = await handleRequest("POST", "/api/vendorProduct/saveBrand", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          showBrandModal: false,
        });
        showSuccess();
      }
    } else {
      showWarning("Please select brand.");
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      vendorId: this.state.vendorId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            active: Boolean("1"),
            companyId: element["companyId"],
            projectId: element["projectId"],
            vendorId: element["vendorId"],
            phones: element["phones"],
            faxes: element["faxes"],
            contactTypeId: element["contactTypeId"],
            countryId: element["countryId"]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/vendorContact/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addContactType = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "vendorContact"
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: ""
        });
        this.fillContactTypeIdSelectItems();
      }
    } else {
      this.showError("Please enter value");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  onExpand = expandedKeys => {
    console.log("onexpand data", expandedKeys);
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };


  onRightClick = (event, node) => {
    console.log("node", node);
    let parentNode = this.getParentNode(node);
    this.setState(
      {
        selectedNodeKey: node.key,
        selectedNode: node,
        parentNode: parentNode
      },
      this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
    );
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  displayMenu = (e, type) => {
    console.log("TTT", type);
    if (Boolean(type)) {
      show(e, { id: Menu_Brand });
    }
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    let categoryId = null;
    let descriptionId = null;
    let stockCardId = null;
    if (key.includes("CATEGORY~")) {
      categoryId = key.replace("CATEGORY~", "");
    } else if (key.includes("DESC~")) {
      descriptionId = key.replace("DESC~", "");
    } if (key.includes("CODE~")) {
      stockCardId = key.replace("CODE~", "");
    }

    switch (event.currentTarget.id) {
      case "add_brand":
        this.setState({
          showBrandModal: true,
          parentId: selectedNode.id,
          categoryId: categoryId,
          descriptionId: descriptionId,
          stockCardId: stockCardId,
        }, async () => {
          const newItem = {
            vendorId: this.state.vendorId,
            categoryId: this.state.categoryId,
            descriptionId: this.state.descriptionId,
            stockCardId: this.state.stockCardId,
          };

          var response = await handleRequest("POST", "/api/vendorProduct/getBrand", newItem);
          if (response.type === "ERROR") {
            error(response);
          } else {
            this.formRefBrand.current.setFieldsValue({
              brandDetails: Boolean(response.data)
                && Boolean(response.data.brandDetails) && response.data.brandDetails.length > 0
                ?
                response.data.brandDetails
                : []
            });
          }
        });
        break;
    }
  };


  render() {
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form>
          <div style={{ marginBottom: "16px" }}>
            <Row gutter={[16, 16]}>
              <Col span={8}></Col>
              <Col span={16}>
                <Space size={16}>
                  <Button type="primary" style={{ borderRadius: "4px" }} onClick={() => this.setState({ modalAddItems: true })}>
                    <FormattedMessage id="VendorProduct.AddItems" defaultMessage="Add Items" />
                  </Button>
                  <Button type="primary" style={{ borderRadius: "4px" }} onClick={() => this.setState({ modalVendorProducts: true })}>
                    <FormattedMessage id="VendorProduct.VendorProducts" defaultMessage="Vendor Products" />
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>

          <Row gutter={[16, 16]}>
            <Col md={8}></Col>
            <Col md={16}>
              <Radio.Group
                value={this.state.type}
                onChange={e => {
                  this.setState(
                    {
                      type: e.target.value,
                      loadedKeys: [],
                      expandedKeys: [],
                      checkedKeys: []
                    },
                    this.fillTree
                  );
                }}
              >
                <Radio value={"MATERIAL"}>Material</Radio>
                <Radio value={"EQUIPMENT"}>Equipment</Radio>
                <Radio value={"SERVICE"}>Service</Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col md={8}></Col>
            <Col md={16}>
              <Spin spinning={this.state.loading}>
                <Tree
                  blockNode
                  checkable
                  showLine={true}
                  onCheck={this.onCheck}
                  checkedKeys={this.state.checkedKeys}
                  halfCheckedKeys={this.state.halfCheckedKeys}
                  treeData={this.state.nodes}
                  onRightClick={({ event, node }) => this.onRightClick(event, node)}
                />
              </Spin>
            </Col>
            <Col md={8}></Col>
          </Row>

          <Menu id={Menu_Brand}>
            <Item id="add_brand" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="VendorProduct.CreateTitle" defaultMessage="Add Brand" />
                </span>
              </Space>
            </Item>
          </Menu>
        </Form>

        <DraggableModal
          title={<FormattedMessage id="VendorProduct.AddItems" defaultMessage="Add Items" />}
          centered
          open={this.state.modalAddItems}
          // onOk={() => this.addItems}
          onCancel={() => this.setState({ modalAddItems: false })}
          okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={123}
        />

        <DraggableModal
          title={<FormattedMessage id="VendorProduct.VendorProducts" defaultMessage="Vendor Products" />}
          centered
          width={400}
          open={this.state.modalVendorProducts}
          // onOk={() => this.addItems}
          onCancel={() => this.setState({ modalVendorProducts: false })}
          okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <>
              <Tree
                blockNode
                checkable
                showLine={true}
                onCheck={this.onCheck}
                checkedKeys={this.state.checkedKeys}
                halfCheckedKeys={this.state.halfCheckedKeys}
                treeData={this.state.nodes}
              />
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="VendorProduct.AddBrand" defaultMessage="Add Brand" />}
          centered
          width={600}
          maskClosable={false}
          open={this.state.showBrandModal}
          onOk={() => this.formRefBrand.current.submit()}
          onCancel={() => {
            this.setState({ showBrandModal: false });
          }}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveBrand} ref={this.formRefBrand}>
              {
                <Form.List name="brandDetails">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"VendorProduct.Brand"} defaultMessage={"Brand"} />
                              </th>
                              <th colSpan="1">
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <Form.Item noStyle hidden name={[field.name, "active"]}> <Input style={{ width: "100%" }} />  </Form.Item>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "brand"]}>
                                      <Input style={{ width: "100%" }} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"VendorProduct.AddNew"} defaultMessage={"Add New"} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              }
            </Form>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VendorProduct); 