import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Tooltip, InputNumber, Button as AntButton, Tree, Modal } from "antd";
import { FilterOutlined, MinusCircleOutlined, SettingTwoTone, ZoomInOutlined, PlusOutlined, CloseOutlined, CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { Resizable } from "react-resizable";
import moment from "moment";

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class HistoryLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "HistoryLog",
      controllerName: "pageSettings",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      houseRoomList: [],
      loadingButtonSubmit: false,
      nodes: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    fillFilterOperations();
    const { pagination } = this.state;
    this.restartTable({ pagination });
  };

  fillComboboxes = async () => {

  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/historyLogPaging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: "",
        tableVisibleModal: true,
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder,
          tableVisibleModal: true,
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: "",
          tableVisibleModal: true,
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      pageName: this.props.pageName,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  resetInputs = () => {

  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  save = async values => {
    console.log("values", values);
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  onCheckTo = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    this.setState({
      checkedKeysTo: checkedKeys,
      halfCheckedKeysTo: e.halfCheckedKeys,
      checkedNodesTo: e.checkedNodes
    });
  };

  onCheckInfo = async (checkedKeys, e) => {
    this.setState({
      checkedKeysInfo: checkedKeys,
      halfCheckedKeysInfo: e.halfCheckedKeys,
      checkedNodesInfo: e.checkedNodes
    });
  };

  render() {

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns,
      });
    };
    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="PageSettings.HistoryLog.Company" defaultMessage="Company" />,
        key: "companyId",
        width: 150,
        render: record => {
          return record.companyName;
        },
        editable: true,
        ...getColumnFilter({
          inputName: "companyId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["companyIdSelectItems"]) ? this.state["companyIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="PageSettings.HistoryLog.Branche" defaultMessage="Branche" />,
        key: "companyProjectId",
        width: 150,
        render: record => {
          return record.companyProjectName;
        },
        editable: true,
        ...getColumnFilter({
          inputName: "companyProjectId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["companyProjectIdSelectItems"]) ? this.state["companyProjectIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="PageSettings.HistoryLog.Project" defaultMessage="Project" />,
        key: "projectId",
        width: 150,
        render: record => {
          return record.projectName;
        },
        editable: true,
        ...getColumnFilter({
          inputName: "projectId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["projectIdSelectItems"]) ? this.state["projectIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="PageSettings.HistoryLog.Menu" defaultMessage="Menu" />,
        key: "menuId",
        width: 150,
        render: record => {
          return record.menuName;
        },
        ...getColumnFilter({
          inputName: "menuId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["menuIdSelectItems"]) ? this.state["menuIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="PageSettings.HistoryLog.Page" defaultMessage="Page" />,
        key: "page",
        width: 150,
        ellipsis: true,
        render: record => {
          return record.page;
        },
        ...getColumnFilter({
          inputName: "page",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="PageSettings.HistoryLog.Action" defaultMessage="Action" />,
        key: "action",
        width: 150,
        render: (record) => {
          if (record.revType === 0) {
            return "Insert";
          } else if (record.revType === 1 && record.active) {
            return "Update";
          } else if (record.revType === 1 && !record.active) {
            return "Delete";
          }
        },
        ...getColumnFilter({
          inputName: "action",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="PageSettings.HistoryLog.ActionBy" defaultMessage="Action By" />,
        key: "remoteUserId",
        width: 100,
        render: record => {
          return record.remoteUserName;
        },
        ...getColumnFilter({
          inputName: "remoteUserId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["remoteUserIdSelectItems"]) ? this.state["remoteUserIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="PageSettings.HistoryLog.Date" defaultMessage="Date" />,
        key: "lastUpdateDate",
        width: 150,
        render: record => {
          return Boolean(record.lastUpdateDate) ? moment(record.lastUpdateDate).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "lastUpdateDate",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} ref={this.formRef}>

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
              <div className="alert alert-custom alert-light">
                <div className="alert-icon">
                  <FilterOutlined />
                </div>
                <div className="alert-text">
                  {this.state.searchCriteriaModels.map((val, i) => {
                    return (
                      <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                        <span className="mr-2">{val.label}</span>
                        <strong className="mr-2">{val.type}</strong>
                        <span className="mr-2">{val.operationValue}</span>
                        <span className="mr-2">{val.showValue}</span>
                        <span>
                          <Tooltip title="Remove">
                            <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                          </Tooltip>
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="alert-close">
                  <Tooltip title="Remove All">
                    <CloseCircleOutlined
                      style={{ color: "crimson" }}
                      onClick={() =>
                        this.setState({ searchCriteriaModels: [] }, () => {
                          this.filterChangeItem([]);
                        })
                      }
                    />
                  </Tooltip>
                </div>
              </div>
            )}

            <Table
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              components={{
                header: {
                  cell: ResizableTitle
                }
              }}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>


        </Form>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(HistoryLog);