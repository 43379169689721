import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Col, Form, Row, Input, Select } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { GetDynamicFormItems, PrepareRowForEdit, PrepareRowForSave } from "../../Components/ElementUtils";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import ElementComponent from "../../Components/Element";

const { Option } = Select;

const generateItemList = data => {
  let itemList = [];
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    itemList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children && node.children.length > 0) {
      itemList.push(...generateItemList(node.children));
    }
  }
  return itemList;
};
export class ChequeLogTo extends Component {
  constructor() {
    super();
    this.state = {
      formName: "ChequeLogTo",
      controllerName: "chequeLog",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      tableList: [],
      restartTable: false,
      pageFormData: null,
      excelData: [],
      createWhereParams: null,
      refreshForm: false,
      signedBySelectList: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();

    let data = await GetDynamicFormItems(this.state.formName);
    this.setState({
      pageFormData: data
    });
  };

  componentDidUpdate = (prevProps) => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    let data = await GetDynamicFormItems(this.state.formName);
    this.setState({
      pageFormData: data
    });
    this.getChequeLogTo();
    this.fillComboboxes(data.pageFormInputCompanyList);
  };

  getChequeLogTo = async () => {
    var row = this.props.chequeLog;
    let handledValues = {
      ...row,
    };
    handledValues = PrepareRowForEdit(handledValues, this.state.pageFormData.pageFormInputCompanyList);
    this.state.pageFormData.pageFormInputCompanyList.forEach(item => {
      if (item.inputType === "CASCADER" && this.state[item.inputName + "Cascader"]) {
        let itemId = this.state[item.inputName + "Cascader"].find(p => p.key === row[item.inputName]);
        if (itemId) {
          handledValues[item.inputName] = itemId.lineage;
        }
      }
    });
    this.formRef.current.setFieldsValue({
      ...handledValues
    });
    this.setState({
      id: row.id,
    });
  };

  fillComboboxes = async inputList => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/signedBy/" + this.props.chequeLog.id);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        signedBySelectList: Boolean(response.data) ? response.data : [],
      });
    }

    inputList.forEach(async (item, index) => {
      if (item.inputType === "CASCADER") {
        let url = item.loadUrl;
        var response = await handleRequest("GET", url);
        if (response.type === "ERROR") {
          showError(response);
        } else {
          let list = generateItemList(response.data);
          this.setState({
            [item.inputName + "Cascader"]: list
          });
        }
      }
    }); 
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null
    });
  };

  setData = data => {
    this.setState({
      tableList: data
    });
  };

  edit = row => {
    let handledValues = {
      ...row
    };
    handledValues = PrepareRowForEdit(handledValues, this.state.pageFormData.pageFormInputCompanyList);
    this.state.pageFormData.pageFormInputCompanyList.forEach(item => {
      if (item.inputType === "CASCADER" && this.state[item.inputName + "Cascader"]) {
        let itemId = this.state[item.inputName + "Cascader"].find(p => p.key === row[item.inputName]);
        if (itemId) {
          handledValues[item.inputName] = itemId.lineage;
        }
      }
    });
    this.formRef.current.setFieldsValue({
      ...handledValues
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async () => {
    this.setState({ restartTable: !this.state.restartTable });
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    if (this.props.chequeLog) {
      let newItem = {
        id: this.props.chequeLog.id,
        ...values
      };
      newItem = PrepareRowForSave(newItem, this.state.pageFormData.pageFormInputCompanyList);
      var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/chequeLogTo/" + newItem.id, newItem);
      if (response.type === "ERROR") {
        showError(response);
      } else {
        this.props.saveChequeLogTo();
        this.cancel();
        this.restartTable();
        showSuccess();
      }
    } else {
      showError("Cheque Log not found.");
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          this.state.pageFormData.pageFormInputCompanyList.forEach(input => {
            Boolean(input.tableColumnValue) ? (obj[input.tableColumnValue] = element[input.label]) : (obj[input.inputName] = element[input.label]);
          });
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            this.restartTable();
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
            <>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="ChequeLogTo.bankAccountsName"
                  defaultMessage={"Bank name"} />}
                name="bankAccountsName"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input disabled style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="ChequeLogTo.branchName"
                  defaultMessage={"Branch name"} />}
                name="branchName"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input disabled style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="ChequeLogTo.chequeBookNo"
                  defaultMessage={"Cheque book no"} />}
                name="chequeBookNo"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input disabled style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="ChequeLogTo.serialNo"
                  defaultMessage={"Serial no"} />}
                name="serialNo"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input disabled style={{ width: "100%" }} />
              </Form.Item>

              {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
                <>
                  {(Boolean(this.state.pageFormData.pageFormInputCompanyList) && this.state.pageFormData.pageFormInputCompanyList.length > 0) ?
                    this.state.pageFormData.pageFormInputCompanyList.map((field, i) =>
                      field.inputName === "signedBy" ?
                        <>
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="ChequeLogTo.signedBy" defaultMessage="Signed By" />}
                            name="signedBy"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Select
                              allowClear
                              style={{ width: "100%" }}
                              placeholder="Signed By"
                              optionFilterProp="children"
                            >
                              {this.state.signedBySelectList.map(i => (
                                <Option value={i.key}>{i.value}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </>
                        :
                        <>
                          <ElementComponent key={i} field={field} layout={layout}
                            form={this.formRef} refresh={this.state.refreshForm} />
                        </>
                    )
                    : null}
                </>
              ) : null}
            </>
          ) : null}
          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </Button>
            </Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
          </Row>
        </Form>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div >
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChequeLogTo);