import "antd/dist/antd.css";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { Form, Row, Col, Space } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import DraggableModal from "../../../Components/DraggableModal";
import { API_BASE_ROOT, handleRequest } from "../../../ApiConnector";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import moment from "moment";
import TextToParaLogFormatCreate from "./TextToParaLogFormatCreate";

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class TextToParaLogFormat extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "TextToParaLogFormat",
      controllerName: "textToParaLogFormat",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      materialList: [],
      usageList: [],
      inputDetails: [],
      selectedItem: { "itemNo": "", "originalItemNo": "" },
      pageAutoDesign: null,
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.fillItems();
  };

  fillItems = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillItems/" + this.props.textToParaLogId);
    if (response.type === "ERROR") {
      error(response);
    }

    let selectedItem = { "itemNo": "", "originalItemNo": "" };
    let itemSelectItems = Boolean(response.data) ? response.data : [];
    if (Boolean(itemSelectItems) && itemSelectItems.length > 0) {
      selectedItem = itemSelectItems[0];

      this.formRef.current.setFieldsValue({
        ...selectedItem
      });
    }

    this.setState({
      itemSelectItems: itemSelectItems,
      selectedItem: selectedItem,
    });
  };

  fillFormats = async () => {
    const { selectedItem } = this.state;
    if (Boolean(selectedItem) && Boolean(selectedItem.id) && selectedItem.id > 0) {
      var responseItem = await handleRequest("GET", "/api/" + this.state.controllerName + "/formats/" + this.state.selectedItem.id);
      if (responseItem.type === "ERROR") {
        error(responseItem);
      }
      if (Boolean(responseItem.data)) {
        responseItem.data.forEach(element => {
          element.preparationDate = Boolean(element.preparationDate) ? moment(element.preparationDate) : null;
          element.reviewDate = Boolean(element.reviewDate) ? moment(element.reviewDate) : null;
          element.approvalDate = Boolean(element.approvalDate) ? moment(element.approvalDate) : null;

          if (element.inputType === "Image") {
            let uploadedFiles = [];
            let photoPath = Boolean(element.itemValue) ? element.itemValue.split(",") : [];
            if (Boolean(photoPath) && photoPath.length > 0) {
              photoPath.forEach((item, index) => {
                let obj = { uid: index, name: item.split("download/")[1], url: item, thumbUrl: item };
                uploadedFiles.push(obj);
              });
            }
            element.itemValue = uploadedFiles;
          }

        });
      }
      this.formRef.current.setFieldsValue({
        formats: Boolean(responseItem.data) ? responseItem.data : null,
      });
    } else {
      this.formRef.current.setFieldsValue({
        formats: null,
      });
    }
  }

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }

    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.fillItems();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  saveAddNew = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      autoReportId: this.props.autoReportId,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.fillComboboxes();
      showSuccess();
      this.setState({
        modalAddNewShow: false,
      });
    }
  }

  save = async values => {
    const { selectedItem } = this.state;
    if (!Boolean(selectedItem.id)) {
      showWarning("Auto Report Item not found.");
      return;
    }
    console.log("values", values);

    if (Boolean(values.formats) && values.formats.length > 0) {
      values.formats.forEach(element => {
        if (element.inputType === "Image") {
          let pathList = [];
          if (Boolean(element.itemValue))
            element.itemValue.forEach(item => {
              if (Boolean(item.response) && Boolean(item.response.url)) pathList.push(item.response.url);
              else if (Boolean(item.url)) pathList.push(item.url);
            });
          element.itemValue = pathList.toString();
        }
      });
    }

    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveFormats/" + selectedItem.id, values.formats);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      if (Boolean(this.props) && Boolean(this.props.close)) {
        this.props.close();
      }
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          modalAddNewDimensionShow: false,
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  textClick = async () => {
  }

  tableClick = async () => {
  }

  sketchClick = async () => {
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const options = [
      { label: 'Option 1', value: 'Type1', img: '/media/autoReport/Type1.png' },
      { label: 'Option 2', value: 'Type2', img: '/media/autoReport/Type2.png' },
      { label: 'Option 3', value: 'Type3', img: '/media/autoReport/Type3.png' },
      { label: 'Option 4', value: 'Type4', img: '/media/autoReport/Type4.png' },
    ];

    const handleChange = e => {
      console.log(e.target.value);
      if (Boolean(e.target.value)) {
        this.setState({
          modalTextToParaLogFormatShow: true,
          random: Math.random(),
          type: e.target.value
        });
      }
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout1 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };

    const handleInputTypeChange = (fieldName, value) => {
      this.formRef.current.setFieldsValue({
        formats: this.formRef.current.getFieldValue('formats').map((item, index) =>
          index === fieldName ? { ...item, inputType: value } : item
        )
      });
    };

    const propsUpload = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    const { selectedInputName, selectedInputIndex, itemSelectItems, selectedItem } = this.state;

    return (
      <div
        className="card card-custom"
        style={{ padding: "2rem", minHeight: "100%" }}>
        <Form
          initialValues={{ remember: false }}
          onFinish={this.save}
          onFinishFailed={onFinishFailed}
          ref={this.formRef}>
          <div className="d-flex flex-row">
            <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
              <div className="card card-custom card-stretch">
                {/* begin::Body */}
                <div className="card-body pt-4">
                  {/* begin::Toolbar */}
                  <div className="d-flex justify-content-end"></div>
                  {/* end::Toolbar */}
                  {/* begin::Nav */}
                  <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                    <br />
                    <Row gutter={[16, 16]}>
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={() => this.setState({ modalAddNewShow: true, random: Math.random() })}>
                          <PlusOutlined /> Add New
                        </a>
                      </div>

                      {/*  */}

                      {Boolean(itemSelectItems) && itemSelectItems.length > 0 &&
                        itemSelectItems.map((item) => (
                          <div className="navi-item mb-2">
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip>
                                  {item.itemNo + "-" + item.originalTitle}
                                </Tooltip>
                              }
                            >
                              <a
                                href="#"
                                className={`navi-link py-4 ${selectedItem.itemNo === item.itemNo ? "active-link" : ""}`}
                                onClick={() => {
                                  this.setState({
                                    selectedItem: item
                                  });
                                  this.edit(item)
                                }}
                              >
                                <span className="navi-icon mr-2">
                                  <span className="svg-icon">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>
                                  </span>
                                </span>
                                <span className="navi-text">
                                  {item.itemNo + "-" + item.originalTitle}
                                </span>
                              </a>
                            </OverlayTrigger>
                          </div>
                        ))}
                      <br />
                    </Row>
                  </div>
                </div>
              </div>
            </div>


            {Boolean(selectedItem) &&
              <div className="flex-row-fluid ml-lg-8">
                <div className="card card-custom card-stretch" >
                  <div className="form">
                    <div className="card-body">
                      <div className="flex-row-fluid ml-lg-12">
                        <div className="card card-custom card-stretch" >
                          <div className="card-header py-3">
                            <div className="card-title align-items-start flex-column">
                              {selectedItem.itemNo + "-" + selectedItem.originalItemNo}
                            </div>
                          </div>
                        </div>
                      </div>
                      <br /><br />
                      <div className="flex-row-fluid ml-lg-12">
                        <div className="card card-custom card-stretch" >
                            <div className="card-title align-items-start flex-column">
                              <Row gutter={[16, 16]}>
                                <Col xs={4}>
                                  <Button style={{ width: "100%" }} variant="success" onClick={this.textClick}>
                                    <FormattedMessage id="TextToParaLogFormat.Text" defaultMessage="Text" />
                                  </Button>
                                </Col>
                                <Col xs={4}>
                                  <Button style={{ width: "100%" }} variant="success" onClick={this.tableClick}>
                                    <FormattedMessage id="TextToParaLogFormat.Table" defaultMessage="Table" />
                                  </Button>
                                </Col>
                                <Col xs={4}>
                                  <Button style={{ width: "100%" }} variant="success" onClick={this.sketchClick}>
                                    <FormattedMessage id="TextToParaLogFormat.Sketch" defaultMessage="Sketch" />
                                  </Button>
                                </Col>
                              </Row>



                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </Form >

        <DraggableModal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={500}
          okButtonProps={{ hidden: true }}
          open={this.state.modalDeleteShow}
          onCancel={() => {
            this.setState({ modalDeleteShow: false });
          }}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDeleteShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Space>
          ]}
          content={
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="TextToParaLogFormat.AddNew" defaultMessage="Add New" />}
          open={this.state.modalAddNewShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ modalAddNewShow: false })}
          width={1000}
          centered
          content={
            <TextToParaLogFormatCreate textToParaLogId={this.props.textToParaLogId} random={this.state.random}
              close={() => {
                this.setState({ modalAddNewShow: false });
                this.fillItems();
              }} />
          }
        />
      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TextToParaLogFormat);