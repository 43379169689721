import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button as AntButton, Col, DatePicker, Divider, Form, Input, PageHeader, Row, Select, Table } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";
import { ExcelReader } from "../excel/ExcelReader";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

export class PersonnelReference extends Component {
  constructor({ personnelId, personnelName }) {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      personnelId: personnelId,
      personnelName: personnelName,
      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      countryIdSelectItems: null,
      relationTypeIdSelectItems: null,
      currentCountry: "tr",
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };
  componentDidUpdate = async nextProps => {
    let id = Boolean(this.props) && Boolean(this.props.personnelId) ? this.props.personnelId : null;
    if (id !== this.state.personnelId) {
      this.setState(
        {
          personnelId: this.props.personnelId,
          personnelName: this.props.personnelName
        },
        () => {
          const { pagination } = this.state;
          this.restartTable({ pagination });
          this.fillComboboxes();
        }
      );
    }
  };
  fillComboboxes = async () => {
    var responsecountryId = await handleRequest("GET", "/api/countries/selectItems");
    if (responsecountryId.type === "ERROR") {
      error(responsecountryId);
    } else {
      this.setState({
        countryIdSelectItems: Boolean(responsecountryId.data) ? responsecountryId.data : []
      });
    }
    var responserelationTypeId = await handleRequest("GET", "/api/codes/type/personnelExperienceRelationTypeId");
    if (responserelationTypeId.type === "ERROR") {
      error(responserelationTypeId);
    } else {
      this.setState({
        personnelExperienceRelationTypeIdSelectItems: Boolean(responserelationTypeId.data) ? responserelationTypeId.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/personnelReference/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/personnelReference/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = { ...values, personnelId: this.state.personnelId, id: this.state.id };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/personnelReference", newItem);
    } else {
      response = await handleRequest("PUT", "/api/personnelReference/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      personnelId: this.state.personnelId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/personnelReference/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="PersonnelReference.referenceName" defaultMessage="Reference Name" />,
        key: "referenceName",
        render: record => {
          return record.referenceName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.referenceName) ? this.state.pagination.referenceName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  referenceName: e.target.value,
                  referenceNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.referenceNameColor) ? this.state.pagination.referenceNameColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelReference.jobDescription" defaultMessage="Job Description" />,
        key: "jobDescription",
        render: record => {
          return record.jobDescription;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.jobDescription) ? this.state.pagination.jobDescription : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  jobDescription: e.target.value,
                  jobDescriptionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.jobDescriptionColor) ? this.state.pagination.jobDescriptionColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelReference.companyName" defaultMessage="Company Name" />,
        key: "companyName",
        render: record => {
          return record.companyName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.companyName) ? this.state.pagination.companyName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  companyName: e.target.value,
                  companyNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.companyNameColor) ? this.state.pagination.companyNameColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelReference.countryId" defaultMessage="Country" />,
        key: "countryId",
        render: record => {
          return Boolean(record.country) ? record.country.nicename : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.countryId) ? this.state.pagination.countryId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  countryId: e.target.value,
                  countryIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.countryIdColor) ? this.state.pagination.countryIdColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelReference.email" defaultMessage="Email" />,
        key: "email",
        render: record => {
          return record.email;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.email) ? this.state.pagination.email : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  email: e.target.value,
                  emailColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.emailColor) ? this.state.pagination.emailColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelReference.phoneNumber" defaultMessage="Phone Number" />,
        key: "phoneNumber",
        render: record => {
          return record.phoneNumber;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.phoneNumber) ? this.state.pagination.phoneNumber : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  phoneNumber: e.target.value,
                  phoneNumberColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.phoneNumberColor) ? this.state.pagination.phoneNumberColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelReference.relationTypeId" defaultMessage="Relation" />,
        key: "relationTypeId",
        render: record => {
          return Boolean(record.relationType) ? record.relationType.name : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.relationTypeId) ? this.state.pagination.relationTypeId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  relationTypeId: e.target.value,
                  relationTypeIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.relationTypeIdColor) ? this.state.pagination.relationTypeIdColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PersonnelReference.remarks" defaultMessage="Remarks" />,
        key: "remarks",
        render: record => {
          return record.remarks;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.remarks) ? this.state.pagination.remarks : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  remarks: e.target.value,
                  remarksColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.remarksColor) ? this.state.pagination.remarksColor : "#bfbfbf" }} />
        ),

        sorter: true
      },

      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
              exportClick: false
            })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <div className="card-header">
          <div className="card-title">
            <PageHeader className="site-page-header" title={"Personnel:" + this.state.personnelName} />
          </div>
        </div>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PersonnelReference.referenceName" defaultMessage="Reference Name" />}
                name="referenceName"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="Reference Name" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PersonnelReference.jobDescription" defaultMessage="Job Description" />}
                name="jobDescription"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="Job Description" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PersonnelReference.companyName" defaultMessage="Company Name" />}
                name="companyName"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="Company Name" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PersonnelReference.countryId" defaultMessage="Country" />}
                name="countryId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Country"
                  onChange={e => {
                    let obj = this.state.countryIdSelectItems.find(x => x.key === e);
                    this.setState({
                      currentCountry: obj.description.toLowerCase()
                    });
                  }}
                >
                  {Boolean(this.state["countryIdSelectItems"]) &&
                    this.state["countryIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PersonnelReference.email" defaultMessage="Email" />}
                name="email"
                rules={[
                  { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                  { type: "email", message: <FormattedMessage id="GENERAL.EMAIL" defaultMessage="This field is not a valid email." /> }
                ]}
              >
                <Input placeholder="Email" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PersonnelReference.phoneNumber" defaultMessage="Phone Number" />}
                name="phoneNumber"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <PhoneInput enableSearch country={this.state.currentCountry} placeholder="Phone Number" inputStyle={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PersonnelReference.relationTypeId" defaultMessage="Relation" />}
                name="relationTypeId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Relation"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("personnelExperienceRelationTypeId")}>
                          Add
                        </AntButton>
                      </div>
                    </div>
                  )}
                >
                  {Boolean(this.state["personnelExperienceRelationTypeIdSelectItems"]) &&
                    this.state["personnelExperienceRelationTypeIdSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PersonnelReference.remarks" defaultMessage="Remarks" />}
                name="remarks"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                style={{ marginBottom: "5px" }}
              >
                <TextArea placeholder="Remarks" style={{ width: "100%" }} />
              </Form.Item>
            }
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PersonnelReference);
