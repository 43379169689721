import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, DatePicker, Space, Spin, Tag, Drawer, Select, Radio, Card } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { PictureAsPdf } from "@material-ui/icons";
import { Carousel } from "antd";
import SendIcon from "@material-ui/icons/Send";
import { InputNumber } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import ReplyIcon from "@material-ui/icons/Reply";
import {
  FilterOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ArrowRightOutlined,
  FileDoneOutlined,
  ExportOutlined,
  StopOutlined,
  ArrowDownOutlined,
  CheckOutlined,
  SettingOutlined
} from "@ant-design/icons";
import { Tooltip } from "antd";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import "../style.css";
import DraggableModal from "../../../Components/DraggableModal";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import SettingsIcon from "@material-ui/icons/Settings";
import store from "../../../../redux/store";

import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";
import { Resizable } from "react-resizable";
import PlurkSvg from "./Icon/plurk.svg";
import KickStarter from "./Icon/kickstarter.svg";
import Vimeo from "./Icon/vimeo.svg";

const { Option } = Select;

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate"
      ? form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY") : ""
      })
      : form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
      });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "planedDeliveryDate") {
        const values = await form.validateFields();
        values.planedDeliveryDate = Boolean(values.planedDeliveryDate) ? moment(values.planedDeliveryDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "paymentDate") {
        const values = await form.validateFields();
        values.paymentDate = Boolean(values.paymentDate) ? moment(values.paymentDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) { }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
      >
        {dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate" ? (
          <DatePicker ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY"} />
        ) : (
          <InputNumber
            min={0}
            max={record.quantity > 0 ? record.quantity : 0}
            parser={value => value.replace(",", ".")}
            formatter={value => value.replace(".", ",")}
            ref={inputRef}
            onPressEnter={toggleSave}
            onBlur={toggleSave}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

const sentList = ["RfqSentToVendors", "StockControlSentToRfq"];

export class StockControl extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "StockControl",
      controllerName: "stockControl",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      modalSendAll: false,
      collapsedAvailable: true,
      collapsedReserved: false,
      collapsedToBeReleased: false,
      collapsedRequested: false,
      tab: "projectTakeOff",

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      reservedQuantityList: [],
      avaliableQuantityList: [],
      loadingButtonSubmit: false,
      user: store.getState().auth.user
    };
  }

  formRef = React.createRef();
  formRefDesicion = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseCompanyProjectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/companyProjectId");
    if (responseCompanyProjectId.type === "ERROR") {
      error(responseCompanyProjectId);
    }

    this.setState({
      companyProjectIdSelectItems: Boolean(responseCompanyProjectId.data) ? responseCompanyProjectId.data : []
    });

    var responseNewRequests = await handleRequest("GET", "/api/" + this.state.controllerName + "/newRequests");
    if (responseNewRequests.type === "ERROR") {
      error(responseNewRequests);
    }

    this.setState({
      newRequestsSelectItems: Boolean(responseNewRequests.data) ? responseNewRequests.data : []
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);

    let url = "/api/" + this.state.controllerName + "/paging";
    if (this.state.tab === "purchaseRequisition") {
      url = "/api/" + this.state.controllerName + "/pagingRequisition";
    }

    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let sendAction = {
      name: "Send",
      icon: <SendIcon fontSize="small" style={{ border: "0px", color: "#007bff" }} />,
      actionClick: this.sendAllModal.bind(this)
    };
    let assignAction = {
      name: "Assign",
      icon: <CheckOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.desicionModal.bind(this)
    };
    let clearAllAssignmentsAction = {
      name: "Clear All Assignments",
      icon: <StopOutlined fontSize="small" style={{ border: "0px", color: "red" }} />,
      actionClick: this.clearAllAssignmentsModal.bind(this)
    };
    let callBackAction = {
      name: "Call Back",
      icon: <ReplyIcon fontSize="small" color="error" />,
      actionClick: this.callBackActionModal.bind(this)
    };

    console.log("record", record);

    let actionList = [];
    if (Boolean(record.status)) {
      if (record.status === "StockControlSentToRfq") {
        //Assign
        actionList.push(callBackAction);
      } else if (record.status === "Stock_Control_In_Progress") {
        actionList.push(assignAction);
        actionList.push(clearAllAssignmentsAction);
        actionList.push(sendAction);
      } else if (record.status === "RfqSentToVendors") {
      } else if (record.status === "StockControlSentToApproval") {
        actionList.push(callBackAction);
      } else {
        actionList.push(assignAction);
        actionList.push(sendAction);
      }
    } else {
      actionList.push(assignAction);
      actionList.push(sendAction);
    }

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => { };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  generateReportList = async () => {
    let url = "/api/" + this.state.controllerName + "/getReportList";
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });
      showError("There is no created report for selected item");
    }
  };

  sendAllModal = row => {
    this.setState({
      modalSendAll: true,
      id: row.id,
      selectedRow: row
    });
  };

  clearAllAssignmentsModal = row => {
    this.setState({
      modalClearAllAssignments: true,
      id: row.id,
      selectedRow: row
    });
  };

  callBackActionModal = row => {
    this.setState({
      modalCallBackAction: true,
      id: row.id,
      selectedRow: row
    });
  };

  newRequestActionModal = row => {
    this.setState({
      modalNewRequestAction: true,
      id: row.id,
      selectedRow: row
    });
  };

  pendingActionModal = row => {
    this.setState({
      modalPendingAction: true,
      id: row.id,
      selectedRow: row
    });
  };

  assignmentDoneActionModal = row => {
    this.setState({
      modalAssignmentDoneAction: true,
      id: row.id,
      selectedRow: row
    });
  };

  sentActionModal = row => {
    this.setState({
      modalSentAction: true,
      id: row.id,
      selectedRow: row
    });
  };

  showOrHidesendAllModal = () => {
    this.setState({
      modalSendAll: !this.state.modalSendAll
    });
  };

  sendAll = async () => {
    let list = [];
    const { id, selectedRow } = this.state;
    if (Boolean(id) && Boolean(selectedRow)) {
      let result = this.setQuantityToBeProcured(selectedRow);
      if (Boolean(result) && result > 0) {
        list.push({ id: id, quantityToBeProcured: result });
      }

      if (Boolean(list) && list.length > 0) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/sendToRFQ", list);
        if (response.type === "ERROR") {
          error(response);
        } else {
          const { pagination } = this.state;
          this.restartTable({ pagination });
          showSuccess();
          this.setState({
            id: null,
            modalSendAll: false
          });
        }
      } else {
        showWarning("No item found to send.");
      }
    } else {
      showWarning("Not found.");
    }
  };

  sendStockControlApproval = async () => {
    let list = [];
    const { id, selectedRow } = this.state;
    if (Boolean(id) && Boolean(selectedRow)) {
      let result = this.setQuantityToBeProcured(selectedRow);
      if (Boolean(result) && result > 0) {
        list.push({ id: id, quantityToBeProcured: result });
      }

      if (Boolean(list) && list.length > 0) {
        let url = "/api/" + this.state.controllerName + "/sendToStockControlApproval";
        if (this.state.tab === "purchaseRequisition") {
          url = "/api/" + this.state.controllerName + "/sendToStockControlApprovalPurchase";
        }
        var response = await handleRequest("POST", url, list);
        if (response.type === "ERROR") {
          error(response);
        } else {
          const { pagination } = this.state;
          this.restartTable({ pagination });
          showSuccess();
          this.setState({
            id: null,
            modalSendAll: false
          });
        }
      } else {
        showWarning("No item found to send.");
      }
    } else {
      showWarning("Not found.");
    }
  };

  desicionModal = async row => {
    if (Boolean(row.id) && row.id > 0) {
      let url = "/api/" + this.state.controllerName + "/desicion/" + row.id;
      if (this.state.tab === "purchaseRequisition") {
        url = "/api/" + this.state.controllerName + "/desicionRequisition/" + row.id;
      }
      var response = await handleRequest("GET", url);
      if (response.type === "ERROR") {
        error(response);
      }

      let desicionList = Boolean(response.data) ? response.data : null;
      let companyProjectIds = [];
      if (Boolean(desicionList)) {
        if (Boolean(desicionList.reserved) && desicionList.reserved.length > 0) {
          desicionList.reserved.forEach(reserved => {
            if (!companyProjectIds.includes(reserved.companyProjectId)) {
              companyProjectIds.push(reserved.companyProjectId);
            }
          });
        }

        if (Boolean(desicionList.toBeReleased) && desicionList.toBeReleased.length > 0) {
          desicionList.toBeReleased.forEach(toBeReleased => {
            if (!companyProjectIds.includes(toBeReleased.companyProjectId)) {
              companyProjectIds.push(toBeReleased.companyProjectId);
            }
          });
        }
      }

      this.setState(
        {
          totalQuantity: 0,
          balance: 0,
          selectedRow: row,
          modalDesicionShow: true,
          desicionList: desicionList,
          companyProjectIds: companyProjectIds,
          collapsedAvailable: true,
          collapsedReserved: false,
          collapsedToBeReleased: false,
          collapsedRequested: false
        },
        () => {
          this.formRefDesicion.current.resetFields();
          this.onChangeValues();
        }
      );
    } else {
      showError("Not found.");
    }
  };

  onChangeValues = async () => {
    const { companyProjectIds, desicionList, selectedRow } = this.state;
    console.log("companyProjectIds", companyProjectIds);

    let requiredQuantity = selectedRow.quantity;
    console.log("requiredQuantity", requiredQuantity);
    console.log("desicionList", desicionList);

    let reserve = 0;
    if (Boolean(desicionList) && Boolean(desicionList.reserved)) {
      desicionList.reserved.forEach(r => {
        if (Boolean(r.quantity) && r.quantity > 0) {
          reserve += r.quantity;
        }
      });
    }
    console.log("reserve", reserve);

    let toBeRelease = 0;
    if (Boolean(desicionList) && Boolean(desicionList.toBeReleased)) {
      desicionList.toBeReleased.forEach(t => {
        if (Boolean(t.quantity) && t.quantity > 0) {
          toBeRelease += t.quantity;
        }
      });
    }
    console.log("toBeRelease", toBeRelease);
    console.log("balance", requiredQuantity - (reserve + toBeRelease));

    this.setState({
      // totalQuantity: this.state.selectedRow.quantity - balance,
      balance: requiredQuantity - (reserve + toBeRelease),
      collapsedAvailable: true,
      collapsedReserved: reserve > 0 ? true : false,
      collapsedToBeReleased: toBeRelease > 0 ? true : false
    });
  };

  reservedQuantityModal = async row => {
    console.log("row", row);
    console.log("selectedRow", this.state.selectedRow);
    const { selectedRow } = this.state;

    let reservedQuantityList = [];
    if (Boolean(selectedRow.id) && selectedRow.id > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/reserved/" + selectedRow.id + "/" + row.companyProjectId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(Boolean(response.data)) && Boolean(response.data.reserved) && response.data.reserved.length > 0) {
          reservedQuantityList = response.data.reserved;
        }
      }
    }
    console.log("reservedQuantityList", reservedQuantityList);

    this.setState(
      {
        modalReservedQuantityShow: true,
        reservedQuantityList: reservedQuantityList
      },
      () => {
        this.setColumnsReserved();
      }
    );
  };

  avaliableQuantityModal = async () => {
    console.log("selectedRow", this.state.selectedRow);
    const { selectedRow } = this.state;

    let avaliableQuantityList = [];
    if (Boolean(selectedRow.id) && selectedRow.id > 0 && Boolean(selectedRow.companyProjectId) && selectedRow.companyProjectId > 0) {
      var response = await handleRequest(
        "GET",
        "/api/" + this.state.controllerName + "/avaliable/" + selectedRow.id + "/" + selectedRow.companyProjectId
      );
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(Boolean(response.data)) && Boolean(response.data.avaliable) && response.data.avaliable.length > 0) {
          avaliableQuantityList = response.data.avaliable;
        }
      }
    }
    console.log("avaliableQuantityList", avaliableQuantityList);

    this.setState(
      {
        modalAvaliableQuantityShow: true,
        avaliableQuantityList: avaliableQuantityList
      },
      () => {
        this.setColumnsAvaliable();
      }
    );
  };

  toBeReleasedQuantityModal = async row => {
    console.log("row", row);
    console.log("selectedRow", this.state.selectedRow);
    const { selectedRow } = this.state;

    let toBeReleasedQuantityList = [];
    if (Boolean(selectedRow.id) && selectedRow.id > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/toBeReleased/" + selectedRow.id + "/" + row.companyProjectId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(Boolean(response.data)) && Boolean(response.data.toBeReleased) && response.data.toBeReleased.length > 0) {
          toBeReleasedQuantityList = response.data.toBeReleased;
        }
      }
    }
    console.log("toBeReleasedQuantityList", toBeReleasedQuantityList);

    this.setState(
      {
        modalToBeReleasedQuantityShow: true,
        toBeReleasedQuantityList: toBeReleasedQuantityList
      },
      () => {
        this.setColumnsToBeReleased();
      }
    );
  };

  setColumnsToBeReleased() {
    const { selectedRow, toBeReleasedQuantityList } = this.state;

    let columnsToBeReleased = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="StockControl.Stock" defaultMessage="Stock" />,
        key: "stockName",
        render: record => {
          return record.stockName;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="StockControl.InStock" defaultMessage="In Stock" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        },
        width: 100
      }
    ];

    if (Boolean(selectedRow) && Boolean(selectedRow.allDimensions)) {
      let dimensionList = selectedRow.allDimensions.split(", ");

      if (Boolean(toBeReleasedQuantityList) && toBeReleasedQuantityList.length > 0) {
        toBeReleasedQuantityList.forEach(element => {
          if (Boolean(element.allDimensions)) {
            let dimensionRowList = element.allDimensions.split(", ");

            if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
              dimensionRowList.forEach(element => {
                if (!dimensionList.includes(element)) {
                  dimensionList.push(element);
                }
              });
            }
          }
        });
      }

      if (Boolean(dimensionList) && dimensionList.length > 0) {
        dimensionList.forEach(element => {
          let dimension = element.split(":");
          if (Boolean(dimension) && dimension.length > 1) {
            let obj = {
              // key: row.key,
              title: dimension[0],
              dataIndex: dimension[0]
              // [dimension[0]]: dimension[1],
            };

            let addColumn = columnsToBeReleased.find(p => p.title === obj.title);
            if (!Boolean(addColumn)) {
              columnsToBeReleased.push(obj);
            }

            if (Boolean(toBeReleasedQuantityList) && toBeReleasedQuantityList.length > 0) {
              toBeReleasedQuantityList.forEach((element, index) => {
                if (Boolean(element.allDimensions) && element.allDimensions.includes(dimension[0])) {
                  let dimensionRowList = element.allDimensions.split(", ");
                  if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
                    console.log("dimensionRowList", dimensionRowList);
                    dimensionRowList.forEach(subRow => {
                      console.log("subRow", subRow);

                      let subDimension = subRow.split(":");
                      if (Boolean(subDimension) && subDimension.length > 1 && dimension[0] === subDimension[0]) {
                        element.key = index + 1;
                        element[dimension[0]] = subDimension[1];
                        return;
                      }
                    });
                  }
                } else {
                  element.key = index + 1;
                  element[dimension[0]] = "";
                }
              });
            }
          }
        });
      }
    }

    let toBeReleased = {
      title: <FormattedMessage id="StockControl.ToBeReleased" defaultMessage="To be Released" />,
      key: "toBeReleasedQuantity",
      render: record => {
        return record.toBeReleasedQuantity;
      },
      width: 100,
      editable: true,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: "toBeReleasedQuantity",
        title: <FormattedMessage id="StockControl.ToBeReleased" defaultMessage="To be Released" />,
        handleSave: this.handleToBeReleasedQuantityList
      })
    };

    columnsToBeReleased.push(toBeReleased);

    this.setState(
      {
        columnsToBeReleased: columnsToBeReleased
      },
      () => {
        console.log("toBeReleasedQuantityList", toBeReleasedQuantityList);
        console.log("columnsToBeReleased", this.state.columnsToBeReleased);
      }
    );
  }

  setColumnsReserved() {
    const { selectedRow, reservedQuantityList } = this.state;

    let columnsReserved = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="StockControl.Stock" defaultMessage="Stock" />,
        key: "stockName",
        render: record => {
          return record.stockName;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="StockControl.InStock" defaultMessage="In Stock" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        },
        width: 100
      }
    ];

    if (Boolean(selectedRow) && Boolean(selectedRow.allDimensions)) {
      let dimensionList = selectedRow.allDimensions.split(", ");
      console.log("selectedRow", dimensionList);

      if (Boolean(reservedQuantityList) && reservedQuantityList.length > 0) {
        reservedQuantityList.forEach(element => {
          if (Boolean(element.allDimensions)) {
            let dimensionRowList = element.allDimensions.split(", ");

            if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
              dimensionRowList.forEach(element => {
                if (!dimensionList.includes(element)) {
                  dimensionList.push(element);
                }
              });
            }
          }
        });
      }

      if (Boolean(dimensionList) && dimensionList.length > 0) {
        dimensionList.forEach(element => {
          let dimension = element.split(":");
          if (Boolean(dimension) && dimension.length > 1) {
            let obj = {
              // key: row.key,
              title: dimension[0],
              dataIndex: dimension[0]
              // [dimension[0]]: dimension[1],
            };

            let addColumn = columnsReserved.find(p => p.title === obj.title);
            if (!Boolean(addColumn)) {
              columnsReserved.push(obj);
            }

            if (Boolean(reservedQuantityList) && reservedQuantityList.length > 0) {
              reservedQuantityList.forEach((element, index) => {
                if (Boolean(element.allDimensions) && element.allDimensions.includes(dimension[0])) {
                  let dimensionRowList = element.allDimensions.split(", ");
                  if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
                    console.log("dimensionRowList", dimensionRowList);
                    dimensionRowList.forEach(subRow => {
                      console.log("subRow", subRow);

                      let subDimension = subRow.split(":");
                      if (Boolean(subDimension) && subDimension.length > 1 && dimension[0] === subDimension[0]) {
                        element.key = index + 1;
                        element[dimension[0]] = subDimension[1];
                        return;
                      }
                    });
                  }
                } else {
                  element.key = index + 1;
                  element[dimension[0]] = "";
                }
              });
            }
          }
        });
      }
    }

    let reserved = {
      title: <FormattedMessage id="StockControl.Reserved" defaultMessage="Reserved" />,
      key: "reservedQuantity",
      render: record => {
        return record.reservedQuantity;
      },
      width: 100,
      editable: true,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: "reservedQuantity",
        title: <FormattedMessage id="StockControl.Reserved" defaultMessage="Reserved" />,
        handleSave: this.handleReservedQuantityList
      })
    };

    columnsReserved.push(reserved);

    this.setState(
      {
        columnsReserved: columnsReserved
      },
      () => {
        console.log("reservedQuantityList", reservedQuantityList);
        console.log("columnsReserved", this.state.columnsReserved);
      }
    );
  }

  handleReservedQuantityList = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.reservedQuantityList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.reservedQuantity === value.reservedQuantity) return;
        item.reservedQuantity = value.reservedQuantity;

        console.log("item", item);
        this.setState(
          {
            reservedQuantityList: newData
          },
          () => {
            this.setColumnsReserved();
            console.log("reserved", this.state.reservedQuantityList);
          }
        );
      }
    }
  };

  handleToBeReleasedQuantityList = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.toBeReleasedQuantityList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.toBeReleasedQuantity === value.toBeReleasedQuantity) return;
        item.toBeReleasedQuantity = value.toBeReleasedQuantity;
        console.log("item", item);

        this.setState(
          {
            toBeReleasedQuantityList: newData
          },
          () => {
            this.setColumnsToBeReleased();
            console.log("toBeReleased", this.state.toBeReleasedQuantityList);
          }
        );
      }
    }
  };

  saveReservedQuantity = async () => {
    const { reservedQuantityList, selectedRow } = this.state;
    console.log("reservedQuantityList", reservedQuantityList);
    console.log("selectedRow", selectedRow);

    if (Boolean(reservedQuantityList) && reservedQuantityList.length > 0) {
      const newItem = {
        projectTakeOffQuantityId: this.state.selectedRow.id,
        companyProjectId: this.state.selectedRow.companyProjectId,
        reserved: reservedQuantityList
      };
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveReserved", newItem);

      if (response.type === "ERROR") {
        error(response);
        this.setState({ loadingButtonSubmit: false });
      } else {
        this.cancel();
        this.desicionModal(selectedRow);
        showSuccess();
        this.setState({
          modalReservedQuantityShow: false,
          loadingButtonSubmit: false
        });
      }
    } else {
      showError("Not found.");
      this.setState({ loadingButtonSubmit: false });
    }
  };

  saveToBeReleasedQuantity = async () => {
    const { toBeReleasedQuantityList, selectedRow } = this.state;
    console.log("toBeReleasedQuantityList", toBeReleasedQuantityList);
    console.log("selectedRow", selectedRow);

    if (Boolean(toBeReleasedQuantityList) && toBeReleasedQuantityList.length > 0) {
      toBeReleasedQuantityList.forEach(element => {
        if (Boolean(element.quantity) && element.quantity > 0) {
          element.toBeReleasedQuantity = element.quantity;
        }
      });
      const newItem = {
        projectTakeOffQuantityId: this.state.selectedRow.id,
        companyProjectId: this.state.selectedRow.companyProjectId,
        toBeReleased: toBeReleasedQuantityList
      };
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveToBeReleased", newItem);

      if (response.type === "ERROR") {
        error(response);
        this.setState({ loadingButtonSubmit: false });
      } else {
        this.cancel();
        await this.desicionModal(selectedRow);
        showSuccess();
        this.setState({
          modalToBeReleasedQuantityShow: false,
          loadingButtonSubmit: false,
          modalDesicionShow: false
        });
      }
    } else {
      showError("Not found.");
      this.setState({ loadingButtonSubmit: false });
    }
  };

  setColumnsAvaliable() {
    const { selectedRow, avaliableQuantityList } = this.state;

    let columnsAvaliable = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="StockControl.Stock" defaultMessage="Stock" />,
        key: "stockName",
        render: record => {
          return record.stockName;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="StockControl.InStock" defaultMessage="In Stock" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        },
        width: 100
      },
      {
        title: <FormattedMessage id="StockControl.Reserve" defaultMessage="Reserved" />,
        key: "reserve",
        render: record => {
          return record.reserve;
        },
        width: 100
      }
    ];

    if (Boolean(selectedRow) && Boolean(selectedRow.allDimensions)) {
      let dimensionList = selectedRow.allDimensions.split(", ");
      console.log("selectedRow", dimensionList);

      if (Boolean(avaliableQuantityList) && avaliableQuantityList.length > 0) {
        avaliableQuantityList.forEach(element => {
          if (Boolean(element.allDimensions)) {
            let dimensionRowList = element.allDimensions.split(", ");

            if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
              dimensionRowList.forEach(element => {
                if (!dimensionList.includes(element)) {
                  dimensionList.push(element);
                }
              });
            }
          }
        });
      }

      if (Boolean(dimensionList) && dimensionList.length > 0) {
        dimensionList.forEach(element => {
          let dimension = element.split(":");
          if (Boolean(dimension) && dimension.length > 1) {
            let obj = {
              // key: row.key,
              title: dimension[0],
              dataIndex: dimension[0]
              //[dimension[0]]: dimension[1],
            };

            let addColumn = columnsAvaliable.find(p => p.title === obj.title);
            if (!Boolean(addColumn)) {
              columnsAvaliable.push(obj);
            }

            if (Boolean(avaliableQuantityList) && avaliableQuantityList.length > 0) {
              avaliableQuantityList.forEach((element, index) => {
                if (Boolean(element.allDimensions) && element.allDimensions.includes(dimension[0])) {
                  let dimensionRowList = element.allDimensions.split(", ");
                  if (Boolean(dimensionRowList) && dimensionRowList.length > 1) {
                    console.log("dimensionRowList", dimensionRowList);
                    dimensionRowList.forEach(subRow => {
                      console.log("subRow", subRow);

                      let subDimension = subRow.split(":");
                      if (Boolean(subDimension) && subDimension.length > 1 && dimension[0] === subDimension[0]) {
                        element.key = index + 1;
                        element[dimension[0]] = subDimension[1];
                        return;
                      }
                    });
                  }
                } else {
                  element.key = index + 1;
                  element[dimension[0]] = "";
                }
              });
            }
          }
        });
      }
    }

    let toBeReleased = {
      title: <FormattedMessage id="StockControl.ToBeReleased" defaultMessage="To Be Released" />,
      key: "avaliableQuantity",
      render: record => {
        return record.avaliableQuantity;
      },
      width: 100,
      editable: true,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: "avaliableQuantity",
        title: <FormattedMessage id="StockControl.ToBeReleased" defaultMessage="To Be Released" />,
        handleSave: this.handleAvaliableQuantityList
      })
    };

    columnsAvaliable.push(toBeReleased);

    this.setState(
      {
        columnsAvaliable: columnsAvaliable
      },
      () => {
        console.log("avaliableQuantityList", avaliableQuantityList);
        console.log("columnsAvaliable", this.state.columnsAvaliable);
      }
    );
  }

  handleAvaliableQuantityList = async (row, value) => {
    console.log("row", row);
    console.log("value", value);
    if (Boolean(value)) {
      const newData = [...this.state.avaliableQuantityList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.avaliableQuantity === value.avaliableQuantity) return;

        // let result = this.setQuantityToBeProcured(this.state.selectedRow);
        let result = this.state.balance;
        console.log("selectedRow", this.state.selectedRow);
        console.log("result", result);
        if (Boolean(result) && result >= value.avaliableQuantity) {
          item.avaliableQuantity = value.avaliableQuantity;

          this.setState(
            {
              avaliableQuantityList: newData
            },
            () => {
              this.setColumnsAvaliable();
              console.log("avaliable", this.state.avaliableQuantityList);
            }
          );
        } else {
          showError("Available quantity can be max " + result);
        }
      }
    }
  };

  saveAvaliableQuantity = async () => {
    const { avaliableQuantityList, selectedRow } = this.state;
    console.log("avaliableQuantityList", avaliableQuantityList);
    console.log("selectedRow", selectedRow);

    if (Boolean(avaliableQuantityList) && avaliableQuantityList.length > 0) {
      const newItem = {
        projectTakeOffQuantityId: this.state.selectedRow.id,
        companyProjectId: this.state.selectedRow.companyProjectId,
        avaliable: avaliableQuantityList
      };

      console.log("newItem", newItem);

      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAvaliable", newItem);

      if (response.type === "ERROR") {
        error(response);
        this.setState({ loadingButtonSubmit: false });
      } else {
        const { pagination } = this.state;
        this.restartTable({ pagination });

        let availableStock = selectedRow.availableStock;
        avaliableQuantityList.forEach(element => {
          if (Boolean(element.avaliableQuantity) && element.avaliableQuantity > 0) {
            availableStock = availableStock - element.avaliableQuantity;
          }
        });
        selectedRow.availableStock = availableStock;

        //this.cancel();
        this.desicionModal(selectedRow);
        showSuccess();
        this.setState({
          modalAvaliableQuantityShow: false,
          loadingButtonSubmit: false
        });
      }
    } else {
      showError("Not found.");
      this.setState({ loadingButtonSubmit: false });
    }
  };

  setQuantityToBeProcured(record) {
    let quantity = 0;
    if (Boolean(record.quantity)) {
      quantity = record.quantity;
    }

    let reservedQuantity = 0;
    if (Boolean(record.reservedQuantity)) {
      reservedQuantity = record.reservedQuantity;
    }

    let toBeReleasedQuantity = 0;
    if (Boolean(record.toBeReleasedQuantity)) {
      toBeReleasedQuantity = record.toBeReleasedQuantity;
    }

    let requested = 0;
    if (Boolean(record.requested)) {
      requested = record.requested;
    }

    let result = quantity - (reservedQuantity + toBeReleasedQuantity + requested);
    if (result > 0) {
      return result;
    }
    return 0;
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  sendSelectedStockControlApproval = async () => {
    const { selectedRowKeys, tableList } = this.state;
    console.log("selectedRowKeys", selectedRowKeys);
    if (Boolean(selectedRowKeys) && selectedRowKeys.length > 0) {
      let list = [];
      selectedRowKeys.forEach(key => {
        let row = tableList.find(p => p.id === key);
        if (Boolean(row)) {
          let result = this.setQuantityToBeProcured(row);
          if (Boolean(result) && result > 0) {
            list.push({ id: key, quantityToBeProcured: result });
          }
        }
      });
      if (Boolean(list) && list.length > 0) {
        let url = "/api/" + this.state.controllerName + "/sendToStockControlApproval";
        if (this.state.tab === "purchaseRequisition") {
          url = "/api/" + this.state.controllerName + "/sendToStockControlApprovalPurchase";
        }

        var response = await handleRequest("POST", url, list);
        if (response.type === "ERROR") {
          error(response);
        } else {
          const { pagination } = this.state;
          this.restartTable({ pagination });
          showSuccess();
          this.setState({
            modalSendSelectShow: false
          });
        }
        this.setState({
          selectedRowKeys: []
        });
      } else {
        showWarning("No item found to send.");
      }
    } else {
      showWarning("Please select item.");
    }
  };

  sendSelected = async () => {
    const { selectedRowKeys, tableList } = this.state;
    console.log("selectedRowKeys", selectedRowKeys);
    if (Boolean(selectedRowKeys) && selectedRowKeys.length > 0) {
      let list = [];
      selectedRowKeys.forEach(key => {
        let row = tableList.find(p => p.id === key);
        if (Boolean(row)) {
          let result = this.setQuantityToBeProcured(row);
          if (Boolean(result) && result > 0) {
            list.push({ id: key, quantityToBeProcured: result });
          }
        }
      });
      if (Boolean(list) && list.length > 0) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/sendToRFQ", list);
        if (response.type === "ERROR") {
          error(response);
        } else {
          const { pagination } = this.state;
          this.restartTable({ pagination });
          showSuccess();
          this.setState({
            modalSendSelectShow: false
          });
        }
        this.setState({
          selectedRowKeys: []
        });
      } else {
        showWarning("No item found to send.");
      }
    } else {
      showWarning("Please select item.");
    }
  };

  clearAndCallBackAllAssignment = async status => {
    const { selectedRow } = this.state;
    if (
      Boolean(selectedRow) &&
      Boolean(selectedRow.id) &&
      selectedRow.id > 0 &&
      Boolean(selectedRow.companyProjectId) &&
      selectedRow.companyProjectId > 0 &&
      Boolean(status)
    ) {
      var response = await handleRequest(
        "GET",
        "/api/" + this.state.controllerName + "/clearAndCallBackAllAssignment/" + selectedRow.id + "/" + selectedRow.companyProjectId + "/" + status
      );
      if (response.type === "ERROR") {
        error(response);
      } else {
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.setState({
          modalClearAllAssignments: false,
          modalCallBackAction: false
        });
      }
    } else {
      showWarning("Project take off or company project not found.");
    }
  };

  sendSelectModal = () => {
    this.setState({
      modalSendSelectShow: true
    });
  };

  toggleCollapse = collapse => {
    this.setState(prevState => ({
      ["collapsed" + collapse]: !prevState["collapsed" + collapse]
    }));
  };

  getStatusColor = status => {
    if (Boolean(status)) {
      if (status === "Sent" || status === "RfqInPRogress") {
        return "processing";
      } else if (status === "Clear_All_Assignments" || status === "Call_Back") {
        return "warning";
      } else if (status === "StockControlSentToRfq") {
        return "success";
      } else if (status === "Stock_Control_In_Progress") {
        return "error";
      }
    }
    return "default";
  };

  getModalContent = () => {
    // İçeriği burada oluşturun veya başka bir fonksiyona çağırın
    return <Table rowKey={"index"} bordered dataSource={this.state.tableList} loading={this.state.loading} pagination={false} />;
  };

  render() {
    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };
    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.printButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    };
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideSelectAll: true,
      renderCell: (checked, record, index, originNode) => {
        return originNode;
      }
    };

    const columns = [
      {
        key: "key",
        render: this.action,
        width: 100
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        },
        onCell: (record, rowIndex) => ({
          style: {
            color:
              record.status === "StockControlSentToRfq" || record.status === "RfqSentToVendors" || record.status === "StockControlSentToApproval"
                ? "#d3d3d3"
                : null
          }
        })
      },
      {
        title: <FormattedMessage id="StockControl.OfferDate" defaultMessage="Date" />,
        key: "lastUpdated",
        width: 100,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        ...getColumnFilter({
          inputName: "offerDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        onCell: (record, rowIndex) => ({
          style: {
            color:
              record.status === "StockControlSentToRfq" || record.status === "RfqSentToVendors" || record.status === "StockControlSentToApproval"
                ? "#d3d3d3"
                : null
          }
        })
      },
      {
        title: <FormattedMessage id="StockControl.Project" defaultMessage="Project" />,
        key: "companyProjectId",
        width: 100,
        render: row => {
          return (
            <Tooltip
              color={"white"}
              title={
                <div className="w-100" style={{ color: "black" }}>
                  {row.companyProjectName}
                </div>
              }
            >
              {Boolean(row.companyProjectName) && row.companyProjectName.length > 10
                ? row.companyProjectName.substring(0, 10) + "..."
                : row.companyProjectName}
            </Tooltip>
          );
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Company Project"
              id="companyProjectId"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  companyProjectId: value,
                  companyProjectIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["companyProjectIdSelectItems"]) &&
                this.state["companyProjectIdSelectItems"].length > 0 &&
                this.state["companyProjectIdSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),
        sorter: true,
        onCell: (record, rowIndex) => ({
          style: {
            color:
              record.status === "StockControlSentToRfq" || record.status === "RfqSentToVendors" || record.status === "StockControlSentToApproval"
                ? "#d3d3d3"
                : null
          }
        })
      },
      {
        title: <FormattedMessage id="StockControl.Status" defaultMessage="Status" />,
        key: "status",
        width: 200,
        render: record => {
          if (record.status === "Sent") {
            return (
              <Tag color={this.getStatusColor(record.status)}>
                <FormattedMessage id="StockControl.Waiting" defaultMessage="Waiting" />
              </Tag>
            );
          } else if (record.status === "StockControlSentToRfq") {
            return (
              <Tag color={this.getStatusColor(record.status)}>
                <FormattedMessage id="StockControl.Sent" defaultMessage="Sent" />
              </Tag>
            );
          } else if (record.status === "StockControlSentToApproval") {
            return (
              <Tag color={this.getStatusColor(record.status)}>
                <FormattedMessage id="StockControl.SentApproval" defaultMessage="Sent Approval" />
              </Tag>
            );
          } else {
            return (
              <Tag color={this.getStatusColor(record.status)}>
                <FormattedMessage id="StockControl.NewRequest" defaultMessage="New Request" />
              </Tag>
            );
          }

          // return Boolean(record.status) ? <Tag color={this.getStatusColor(record.status)}>{record.status.replaceAll("_", " ")}</Tag> : "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              style={{ flex: "auto" }}
              onChange={async e => {
                const { pagination } = this.state;
                console.log("e.target.value", e);
                let status = null;
                if (e.target.value === "send") {
                  status = "StockControlSentToRfq";
                } else if (e.target.value === "assign") {
                  status = "Stock_Control_In_Progress";
                }
                let _pagination = {
                  ...pagination,
                  current: 1,
                  status: status,
                  statusColor: Boolean(status) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            >
              <Radio.Button value="send">
                <FormattedMessage id="StockControl.Send" defaultMessage="Send" />
              </Radio.Button>
              <Radio.Button value="assign">
                <FormattedMessage id="StockControl.Assign" defaultMessage="Assign" />
              </Radio.Button>
              <Radio.Button value="clearAllAssignments">
                <FormattedMessage id="StockControl.ClearAllAssignments" defaultMessage="Clear All Assignments" />
              </Radio.Button>
              <Radio.Button value="callBack">
                <FormattedMessage id="StockControl.CallBack" defaultMessage="Call Back" />
              </Radio.Button>
            </Radio.Group>
          </div>
        ),
        sorter: true,
        onCell: (record, rowIndex) => ({
          style: {
            color:
              record.status === "StockControlSentToRfq" || record.status === "RfqSentToVendors" || record.status === "StockControlSentToApproval"
                ? "#d3d3d3"
                : null
          }
        })
      },
      {
        title: <FormattedMessage id="StockControl.item" defaultMessage="Item" />,
        key: "id",
        width: 100,
        render: row => {
          return (
            <Tooltip
              color={"white"}
              title={
                <div className="w-100" style={{ color: "black" }}>
                  {row.itemDescriptionRename}
                </div>
              }
            >
              {Boolean(row.itemDescriptionRename) && row.itemDescriptionRename.length > 10
                ? row.itemDescriptionRename.substring(0, 10) + "..."
                : row.itemDescriptionRename}
            </Tooltip>
          );
        },
        ...getColumnFilter({
          inputName: "id",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        onCell: (record, rowIndex) => ({
          style: {
            color:
              record.status === "StockControlSentToRfq" || record.status === "RfqSentToVendors" || record.status === "StockControlSentToApproval"
                ? "#d3d3d3"
                : null
          }
        })
      },
      {
        title: <FormattedMessage id="StockControl.dimension" defaultMessage="Dimension" />,
        key: "allDimensions",
        width: 100,
        render: row => {
          return (
            <Tooltip
              color={"white"}
              title={
                <div className="w-100" style={{ color: "black" }}>
                  {row.allDimensions}
                </div>
              }
            >
              {Boolean(row.allDimensions) && row.allDimensions.length > 10 ? row.allDimensions.substring(0, 10) + "..." : row.allDimensions}
            </Tooltip>
          );
        },
        ...getColumnFilter({
          inputName: "allDimensions",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        onCell: (record, rowIndex) => ({
          style: {
            color:
              record.status === "StockControlSentToRfq" || record.status === "RfqSentToVendors" || record.status === "StockControlSentToApproval"
                ? "#d3d3d3"
                : null
          }
        })
      },
      {
        title: <FormattedMessage id="StockControl.quantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        render: record => {
          if (Boolean(record.quantity)) {
            return record.quantity.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
          }
        },
        ...getColumnFilter({
          inputName: "quantity",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        onCell: (record, rowIndex) => ({
          style: {
            color:
              record.status === "StockControlSentToRfq" || record.status === "RfqSentToVendors" || record.status === "StockControlSentToApproval"
                ? "#d3d3d3"
                : null
          }
        })
      },
      {
        title: <FormattedMessage id="StockControl.inStock" defaultMessage="In Stock" />,
        key: "inStock",
        width: 100,
        render: record => {
          return record.inStock;
        },
        ...getColumnFilter({
          inputName: "inStock",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        onCell: (record, rowIndex) => ({
          style: {
            color:
              record.status === "StockControlSentToRfq" || record.status === "RfqSentToVendors" || record.status === "StockControlSentToApproval"
                ? "#d3d3d3"
                : null
          }
        })
      },
      {
        title: <FormattedMessage id="StockControl.Reserved" defaultMessage="Reserved" />,
        key: "reservedQuantity",
        width: 100,
        render: record => {
          return record.reservedQuantity;
        },
        ...getColumnFilter({
          inputName: "reservedQuantity",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        onCell: (record, rowIndex) => ({
          style: {
            color:
              record.status === "StockControlSentToRfq" || record.status === "RfqSentToVendors" || record.status === "StockControlSentToApproval"
                ? "#d3d3d3"
                : null
          }
        })
      },
      {
        title: <FormattedMessage id="StockControl.ToBeReleased" defaultMessage="To Be Released" />,
        key: "toBeReleasedQuantity",
        width: 100,
        render: record => {
          return record.toBeReleasedQuantity;
        },
        ...getColumnFilter({
          inputName: "toBeReleasedQuantity",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        onCell: (record, rowIndex) => ({
          style: {
            color:
              record.status === "StockControlSentToRfq" || record.status === "RfqSentToVendors" || record.status === "StockControlSentToApproval"
                ? "#d3d3d3"
                : null
          }
        })
      },
      {
        title: <FormattedMessage id="StockControl.requested" defaultMessage="Requested" />,
        key: "requested",
        width: 100,
        render: record => {
          return record.requested;
        },
        ...getColumnFilter({
          inputName: "requested",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        onCell: (record, rowIndex) => ({
          style: {
            color:
              record.status === "StockControlSentToRfq" || record.status === "RfqSentToVendors" || record.status === "StockControlSentToApproval"
                ? "#d3d3d3"
                : null
          }
        })
      },
      {
        title: <FormattedMessage id="StockControl.availableStock" defaultMessage="Available Stock" />,
        key: "availableStock",
        width: 100,
        render: record => {
          return record.availableStock;
        },
        ...getColumnFilter({
          inputName: "availableStock",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        onCell: (record, rowIndex) => ({
          style: {
            color:
              record.status === "StockControlSentToRfq" || record.status === "RfqSentToVendors" || record.status === "StockControlSentToApproval"
                ? "#d3d3d3"
                : null
          }
        })
      },
      {
        title: <FormattedMessage id="StockControl.QuantityToBeProcured" defaultMessage="Quantity To Be Procured" />,
        key: "key",
        width: 100,
        render: record => {
          let result = this.setQuantityToBeProcured(record);

          if (result > 0) {
            return result.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
          }
          return 0;
        },
        onCell: (record, rowIndex) => ({
          style: {
            color:
              record.status === "StockControlSentToRfq" || record.status === "RfqSentToVendors" || record.status === "StockControlSentToApproval"
                ? "#d3d3d3"
                : null
          }
        })
      },
      {
        title: <FormattedMessage id="StockControl.remarks" defaultMessage="Remarks" />,
        key: "remarks",
        width: 100,
        render: record => {
          return record.remarks;
        },
        ...getColumnFilter({
          inputName: "remarks",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        onCell: (record, rowIndex) => ({
          style: {
            color:
              record.status === "StockControlSentToRfq" || record.status === "RfqSentToVendors" || record.status === "StockControlSentToApproval"
                ? "#d3d3d3"
                : null
          }
        })
      },
      {
        title: <FormattedMessage id="StockControl.updates" defaultMessage="Updates" />,
        key: "updates",
        width: 100,
        render: record => {
          return record.updates;
        },
        ...getColumnFilter({
          inputName: "updates",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true,
        onCell: (record, rowIndex) => ({
          style: {
            color:
              record.status === "StockControlSentToRfq" || record.status === "RfqSentToVendors" || record.status === "StockControlSentToApproval"
                ? "#d3d3d3"
                : null
          }
        })
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];

      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));


    const tabTitleComponent = (title, key) => (
      <div className={"card-title-component"}>
        <img src={key === "projectTakeOff" ? PlurkSvg : Vimeo} alt="Icon" style={{ width: "20px", height: "20px", marginRight: "10px" }} color={key === this.state.tab ? "#469CF0" : "#A2A5B5"} />
        <span style={{ color: key === this.state.tab ? "#469CF0" : "#A2A5B5" }}>
          {title}
        </span>
      </div>
    );

    const tabList = [
      {
        key: "projectTakeOff",
        tab: tabTitleComponent(
          "Project Take Off",
          "projectTakeOff"
        ),
      },
      {
        key: "purchaseRequisition",
        tab: tabTitleComponent("Purchase Requisition", "purchaseRequisition"),
      },
    ];

    return (
      <>
        <div className="col-lg-12">
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-body">
              <div className="row m-0">
                <div className="col-lg-4">
                  <Carousel autoplay>
                    <div>
                      <div className="col bg-light-warning px-6 py-4 rounded-xl mb-3 mr-7 justify-content-center">
                        <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2"></span>
                        <a href="#" className="text-warning font-weight-bold font-size-h6">
                          New Request: {Boolean(this.state.newRequestsSelectItems) ? this.state.newRequestsSelectItems.newRequests : 0}
                          <divs id></divs>
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="col bg-light-primary px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2"></span>
                        <a href="#" className="text-primary font-weight-bold font-size-h6 mt-2">
                          Pending Requests: {Boolean(this.state.newRequestsSelectItems) ? this.state.newRequestsSelectItems.pendingRequests : 0}
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="col bg-light-danger px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2"></span>
                        <a href="#" className="text-danger font-weight-bold font-size-h6 mt-2">
                          Done: {Boolean(this.state.newRequestsSelectItems) ? this.state.newRequestsSelectItems.doneRequests : 0}
                        </a>
                      </div>
                    </div>
                  </Carousel>
                </div>

                <div className="col-lg-4">
                  <Carousel autoplay>
                    <div>
                      <div className="col bg-light-success px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2"></span>
                        <a href="#" className="text-success font-weight-bold font-size-h6 mt-2">
                          Pound
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="col bg-light-warning px-6 py-4 rounded-xl mr-7 mb-3 justify-content-center">
                        <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2"></span>
                        <a href="#" className="text-warning font-weight-bold font-size-h6">
                          Bitcoin
                        </a>
                      </div>
                    </div>

                    <div>
                      <div className="col bg-light-primary px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2"></span>
                        <a href="#" className="text-primary font-weight-bold font-size-h6 mt-2">
                          Dollar
                        </a>
                      </div>
                    </div>

                    <div>
                      <div className="col bg-light-danger px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2"></span>
                        <a href="#" className="text-danger font-weight-bold font-size-h6 mt-2">
                          Euro
                        </a>
                      </div>
                    </div>
                  </Carousel>
                </div>

                <div className="col-lg-4">
                  <Carousel autoplay>
                    <div>
                      <div className="col bg-light-danger px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2"></span>
                        <a href="#" className="text-danger font-weight-bold font-size-h6 mt-2">
                          Chart-Bar
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="col bg-light-success px-6 py-4 rounded-xl mb-3">
                        <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2"></span>
                        <a href="#" className="text-success font-weight-bold font-size-h6 mt-2">
                          Chart-Line
                        </a>
                      </div>
                    </div>

                    <div>
                      <div className="col bg-light-warning px-6 py-4 rounded-xl mr-7 mb-3 justify-content-center">
                        <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2"></span>
                        <a href="#" className="text-warning font-weight-bold font-size-h6">
                          Shuffle
                        </a>
                      </div>
                    </div>

                    <div>
                      <div className="col bg-light-primary px-6 py-4 rounded-xl mb-3 mr-7">
                        <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2"></span>
                        <a href="#" className="text-primary font-weight-bold font-size-h6 mt-2">
                          Chart-Pie
                        </a>
                      </div>
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Badge.Ribbon
          text={<FormattedMessage id="StockControl.pageTitle" defaultMessage="Stock Control" />}
          placement="start"
          color="volcano"
          style={{ fontSize: 12 }}
        >
          <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
              <br /> <br />
            </Form>

            {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
              <div className="alert alert-custom alert-light">
                <div className="alert-icon">
                  <FilterOutlined />
                </div>
                <div className="alert-text">
                  {this.state.searchCriteriaModels.map((val, i) => {
                    return (
                      <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                        <span className="mr-2">{val.label}</span>
                        <strong className="mr-2">{val.type}</strong>
                        <span className="mr-2">{val.operationValue}</span>
                        <span className="mr-2">{val.showValue}</span>
                        <span>
                          <Tooltip title="Remove">
                            <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                          </Tooltip>
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="alert-close">
                  <Tooltip title="Remove All">
                    <CloseCircleOutlined
                      style={{ color: "crimson" }}
                      onClick={() =>
                        this.setState({ searchCriteriaModels: [] }, () => {
                          this.filterChangeItem([]);
                        })
                      }
                    />
                  </Tooltip>{" "}
                </div>{" "}
              </div>
            )}

            <Row gutter={[16, 16]}>
              <Col md={11}>
                <Card
                  bodyStyle={{ padding: 0 }}
                  tabList={tabList}
                  onTabChange={value => {
                    const { pagination } = this.state;
                    let _pagination = {
                      ...pagination,
                      current: 1,
                    };
                    this.setState({
                      tab: value,
                      selectedRowKeys: [],
                      pagination: _pagination
                    }, () => {
                      const { pagination } = this.state;
                      this.restartTable({ pagination });
                    });
                  }}
                />
              </Col>
              <Col md={11}>
                <Space>
                  <Button
                    variant="primary"
                    onClick={this.sendSelectModal.bind(this)}
                    style={{ marginTop: "10px" }}
                    disabled={Boolean(this.state.selectedRowKeys) && this.state.selectedRowKeys.length > 0 ? false : true}
                    loading={this.state.loading}
                  >
                    <FormattedMessage id="StockControl.SendSelected" defaultMessage="Send Selected" />
                  </Button>
                  <span style={{ marginLeft: 8 }}>
                    {Boolean(this.state.selectedRowKeys) && this.state.selectedRowKeys.length > 0 ? `Selected ${selectedRowKeys.length} items` : ""}
                  </span>
                </Space>
              </Col>
              <Col md={1}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <Tooltip title={<FormattedMessage id="StockControl.Settings" defaultMessage="Settings" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.setState({
                          drawerSettingsShow: true
                        });
                      }}
                      icon={<SettingsIcon color="error" fontSize="large" />}
                    ></AntButton>
                  </Tooltip>
                )}
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={this.generateReportList}
                  icon={<PictureAsPdf style={{ color: "red" }} fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>
            <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
              <Table
                rowKey={"id"}
                bordered
                size="small"
                components={{
                  header: {
                    cell: ResizableTitle
                  }
                }}
                rowSelection={rowSelection}
                scroll={{ x: 900, y: 300, scrollToFirstRowOnChange: true }}
                columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
                dataSource={this.state.tableList}
                loading={this.state.loading}
                onChange={this.handleTableChange}
                pagination={this.state.pagination}
              />
            </div>
          </div>

          <DraggableModal
            title={<FormattedMessage id="StockControl.Assignment" defaultMessage="Assignment" />}
            closable="false"
            open={this.state.modalDesicionShow}
            onOk={() => this.sendAll()}
            onCancel={() => this.setState({ modalDesicionShow: false })}
            content={
              <Row gutter={[16, 16]}>
                <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}></Col>
                <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
                  <Form initialValues={{ remember: false }} onFinish={this.save} ref={this.formRefDesicion}>
                    {Boolean(this.state.desicionList) && (
                      <>
                        <div className="card card-custom" style={{ minHeight: "100%" }}>
                          <div className="arrow-container">
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <img src={KickStarter} alt="Plurk Icon" style={{ width: "20px", height: "20px", marginRight: "10px" }} />
                              {/* <TrademarkOutlined style={{ fontSize: "20px", marginRight: "10px" }} /> */}
                              <span style={{ fontSize: "15px" }}>
                                <FormattedMessage id="StockControl.RequiredQuantity" defaultMessage="Required Quantity" />
                              </span>
                            </div>
                            <Space>
                              <InputNumber className="w-100" value={this.state.selectedRow.quantity} readOnly />
                            </Space>
                          </div>
                        </div>

                        <br />

                        <div className="card card-custom" style={{ minHeight: "100%" }}>
                          <div className="arrow-container">
                            <div style={{ display: "flex", alignItems: "center" }}>
                              {/* <CalculatorOutlined style={{ fontSize: "20px", marginRight: "10px" }} /> */}
                              <img src={PlurkSvg} alt="Plurk Icon" style={{ width: "20px", height: "20px", marginRight: "10px" }} />
                              <span style={{ fontSize: "15px" }}>
                                <FormattedMessage id="StockControl.Balance" defaultMessage="Balance" />
                              </span>
                            </div>
                            <Space>
                              <InputNumber className="w-100" readOnly value={this.state.balance} />
                            </Space>
                          </div>
                        </div>

                        <br />

                        <div className="card card-custom" style={{ minHeight: "100%" }}>
                          <div className="arrow-container">
                            <div style={{ display: "flex", alignItems: "center" }}>
                              {/* <DatabaseOutlined style={{ fontSize: "20px", marginRight: "10px" }} /> */}
                              <img src={Vimeo} alt="Plurk Icon" style={{ width: "20px", height: "20px", marginRight: "10px" }} />
                              <span style={{ fontSize: "15px" }}>
                                <FormattedMessage id="StockControl.Available" defaultMessage="Available" />
                              </span>
                            </div>
                            <Space>
                              <IconButton onClick={() => this.toggleCollapse("Available")}>
                                {this.state.collapsedAvailable ? <ArrowDownOutlined /> : <ArrowRightOutlined />}
                              </IconButton>
                            </Space>
                          </div>

                          {this.state.collapsedAvailable ? (
                            <Row>
                              <Col span={6}></Col>
                              <Col span={4}>
                                <InputNumber
                                  className="w-100"
                                  value={this.state.selectedRow.availableStock}
                                  readOnly
                                  addonAfter={<SettingOutlined onClick={() => this.avaliableQuantityModal()} />}
                                />
                              </Col>
                              <Col span={1}></Col>
                            </Row>
                          ) : (
                            ""
                          )}
                        </div>
                        <br />

                        <div className="card card-custom" style={{ minHeight: "100%" }}>
                          <div className="arrow-container">
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <StopOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                              <span style={{ fontSize: "15px" }}>
                                <FormattedMessage id="StockControl.Reserved" defaultMessage="Reserved" />
                              </span>
                            </div>
                            <Space>
                              <IconButton onClick={() => this.toggleCollapse("Reserved")}>
                                {this.state.collapsedReserved ? <ArrowDownOutlined /> : <ArrowRightOutlined />}
                              </IconButton>
                            </Space>
                          </div>
                          {this.state.collapsedReserved
                            ? Boolean(this.state.desicionList.reserved) &&
                            this.state.desicionList.reserved.length > 0 &&
                            this.state.desicionList.reserved.map(i => (
                              <Row key={i.project}>
                                <Col span={6}>{i.project}</Col>
                                <Col span={4}>
                                  <InputNumber
                                    className="w-100"
                                    value={i.quantity}
                                    readOnly
                                    addonAfter={<SettingOutlined onClick={() => this.reservedQuantityModal(i)} />}
                                  />
                                </Col>
                                <Col span={1}></Col>
                              </Row>
                            ))
                            : ""}
                        </div>

                        <br />

                        <div className="card card-custom" style={{ minHeight: "100%" }}>
                          <div className="arrow-container">
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <ExportOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                              <span style={{ fontSize: "15px" }}>
                                <FormattedMessage id="StockControl.ToBeReleased" defaultMessage="To Be Released" />
                              </span>
                            </div>
                            <Space>
                              <IconButton onClick={() => this.toggleCollapse("ToBeReleased")}>
                                {this.state.collapsedToBeReleased ? <ArrowDownOutlined /> : <ArrowRightOutlined />}
                              </IconButton>
                            </Space>
                          </div>
                          {this.state.collapsedToBeReleased
                            ? Boolean(this.state.desicionList.toBeReleased) &&
                            this.state.desicionList.toBeReleased.length > 0 &&
                            this.state.desicionList.toBeReleased.map(i => (
                              <Row key={i.project}>
                                <Col span={6}>{i.project}</Col>
                                <Col span={4}>
                                  <InputNumber
                                    className="w-100"
                                    value={i.quantity}
                                    readOnly
                                    addonAfter={<SettingOutlined onClick={() => this.toBeReleasedQuantityModal(i)} />}
                                  />
                                </Col>
                                <Col span={1}></Col>
                              </Row>
                            ))
                            : ""}
                        </div>

                        <br />
                        <div className="card card-custom" style={{ minHeight: "100%" }}>
                          <div className="arrow-container">
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <FileDoneOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
                              <span style={{ fontSize: "15px" }}>
                                <FormattedMessage id="StockControl.Requested" defaultMessage="Requested" />
                              </span>
                            </div>
                            <Space>
                              <IconButton onClick={() => this.toggleCollapse("Requested")}>
                                {this.state.collapsedRequested ? <ArrowDownOutlined /> : <ArrowRightOutlined />}
                              </IconButton>
                            </Space>
                          </div>
                          {this.state.collapsedRequested
                            ? Boolean(this.state.desicionList.requested) &&
                            this.state.desicionList.requested.length > 0 &&
                            this.state.desicionList.requested.map(i => <Row></Row>)
                            : ""}
                        </div>

                        <br />
                      </>
                    )}
                  </Form>
                </Col>
              </Row>
            }
            width={800}
            centered={true}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalDesicionShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                </Button>
              </Space>
            ]}
          />

          <DraggableModal
            title={<FormattedMessage id="StockControl.ReservedQuantity" defaultMessage="Reserved Quantity" />}
            width={800}
            centered={true}
            closable={false}
            open={this.state.modalReservedQuantityShow}
            onOk={() => this.sendAll()}
            onCancel={() => this.setState({ modalReservedQuantityShow: false })}
            content={
              <div className="card card-custom" style={{ minHeight: "100%" }}>
                <Table
                  bordered
                  size="small"
                  components={components}
                  rowClassName={() => "editable-row"}
                  columns={this.state.columnsReserved}
                  dataSource={this.state.reservedQuantityList}
                  loading={this.state.loading}
                  pagination={false}
                />
              </div>
            }
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalReservedQuantityShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                </Button>

                <Spin spinning={this.state.loadingButtonSubmit}>
                  <Button
                    style={{ width: "100%" }}
                    variant="success"
                    onClick={() => {
                      this.setState(
                        {
                          loadingButtonSubmit: true
                        },
                        () => {
                          this.saveReservedQuantity();
                        }
                      );
                    }}
                  >
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Spin>
              </Space>
            ]}
          />

          <DraggableModal
            title={
              <span>
                <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  <FormattedMessage id="StockControl.SendToStockControlApproval" defaultMessage="Send To Stock Control Approval" />
                </span>
              </span>
            }
            open={this.state.modalSendAll}
            onOk={() => this.sendStockControlApproval()}
            onCancel={() => this.setState({ modalSendAll: false })}
            content={
              <FormattedMessage
                id="GeneralMessage.SelectedProjectItemWillSendedToStockControlAreYouSure"
                defaultMessage="Selected Project Item will sended to Approval. Are you sure?"
              />
            }
          />

          <DraggableModal
            title={<FormattedMessage id="StockControl.ToBeReleasedQuantity" defaultMessage="To Be Released Quantity" />}
            closable="false"
            open={this.state.modalToBeReleasedQuantityShow}
            onCancel={() => {
              this.setState({ modalToBeReleasedQuantityShow: false });
            }}
            width={800}
            centered
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalToBeReleasedQuantityShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                </Button>
                <Spin spinning={this.state.loadingButtonSubmit}>
                  <Button
                    style={{ width: "100%" }}
                    variant="success"
                    onClick={() => {
                      this.setState(
                        {
                          loadingButtonSubmit: true
                        },
                        () => {
                          this.saveToBeReleasedQuantity();
                        }
                      );
                    }}
                  >
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Spin>
              </Space>
            ]}
            content={
              <div className="card card-custom" style={{ minHeight: "100%" }}>
                <Table
                  bordered
                  size="small"
                  components={components}
                  rowClassName={() => "editable-row"}
                  columns={this.state.columnsToBeReleased}
                  dataSource={this.state.toBeReleasedQuantityList}
                  loading={this.state.loading}
                  pagination={false}
                />
              </div>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="StockControl.AvailableQuantity" defaultMessage="Available Quantity" />}
            closable="false"
            open={this.state.modalAvaliableQuantityShow}
            onCancel={() => {
              this.setState({ modalAvaliableQuantityShow: false });
            }}
            width={800}
            centered
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalAvaliableQuantityShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                </Button>

                <Spin spinning={this.state.loadingButtonSubmit}>
                  <Button
                    style={{ width: "100%" }}
                    variant="success"
                    onClick={() => {
                      this.setState(
                        {
                          loadingButtonSubmit: true
                        },
                        () => {
                          this.saveAvaliableQuantity();
                        }
                      );
                    }}
                  >
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Spin>
              </Space>
            ]}
            content={
              <div className="card card-custom" style={{ minHeight: "100%" }}>
                <Table
                  bordered
                  size="small"
                  components={components}
                  rowClassName={() => "editable-row"}
                  columns={this.state.columnsAvaliable}
                  dataSource={this.state.avaliableQuantityList}
                  loading={this.state.loading}
                  pagination={false}
                />
              </div>
            }
          />

          <DraggableModal
            title={
              <span>
                <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  <FormattedMessage id="StockControl.SendToStockControlApproval" defaultMessage="Send To Stock Control Approval" />
                </span>
              </span>
            }
            centered
            open={this.state.modalSendSelectShow}
            onOk={() => this.sendSelectedStockControlApproval()}
            onCancel={() => this.setState({ modalSendSelectShow: false })}
            okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<FormattedMessage id="GeneralMessageDoYouWantToProceedThisRequest" defaultMessage="Do you want to proceed this request?" />}
          />

          <DraggableModal
            title={
              <span>
                <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  <FormattedMessage id="StockControl.ClearAllAssignments" defaultMessage="Clear All Assignments" />
                </span>
              </span>
            }
            centered
            open={this.state.modalClearAllAssignments}
            onOk={() => this.clearAndCallBackAllAssignment("Clear_All_Assignments")}
            onCancel={() => this.setState({ modalClearAllAssignments: false })}
            okText={<FormattedMessage id="GeneralButtonClear" defaultMessage="Clear" />}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<FormattedMessage id="GeneralMessageDoYouWantToProceedThisRequest" defaultMessage="Do you want to proceed this request?" />}
          />

          <DraggableModal
            title={<FormattedMessage id="GeneralPDFReportViewer" defaultMessage="PDF Report Viewer" />}
            open={this.state.modalPdfViewer}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalPdfViewer: false })}
            width={1200}
            centered
            content={
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                <div style={{ marginTop: "2rem" }}>
                  <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                </div>
              </Worker>
            }
          ></DraggableModal>

          <DraggableModal
            title={
              <span>
                <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  <FormattedMessage id="StockControl.CallBack" defaultMessage="Call Back" />
                </span>
              </span>
            }
            centered
            open={this.state.modalCallBackAction}
            onOk={() => this.clearAndCallBackAllAssignment("Call_Back")}
            onCancel={() => this.setState({ modalCallBackAction: false })}
            okText={<FormattedMessage id="GeneralButtonCallBack" defaultMessage="Call Back" />}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<FormattedMessage id="GeneralMessageDoYouWantToProceedThisRequest" defaultMessage="Do you want to proceed this request?" />}
          />

          <DraggableModal
            title={
              <span>
                <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  <FormattedMessage id="StockControl.NewRequest" defaultMessage="New Request" />
                </span>
              </span>
            }
            centered
            open={this.state.modalNewRequestAction}
            onOk={() => this.clearAndCallBackAllAssignment("Call_Back")}
            onCancel={() => this.setState({ modalNewRequestAction: false })}
            okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<FormattedMessage id="GeneralMessageDoYouWantToProceedThisRequest" defaultMessage="Do you want to proceed this request?" />}
          />

          <DraggableModal
            title={
              <span>
                <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  <FormattedMessage id="StockControl.Pending" defaultMessage="Pending" />
                </span>
              </span>
            }
            centered
            open={this.state.modalPendingAction}
            onOk={() => this.clearAndCallBackAllAssignment("Call_Back")}
            onCancel={() => this.setState({ modalPendingAction: false })}
            okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<FormattedMessage id="GeneralMessageDoYouWantToProceedThisRequest" defaultMessage="Do you want to proceed this request?" />}
          />

          <DraggableModal
            title={
              <span>
                <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  <FormattedMessage id="StockControl.AssignmentDone" defaultMessage="Assignment Done" />
                </span>
              </span>
            }
            centered
            open={this.state.modalAssignmentDoneAction}
            onOk={() => this.clearAndCallBackAllAssignment("Call_Back")}
            onCancel={() => this.setState({ modalAssignmentDoneAction: false })}
            okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<FormattedMessage id="GeneralMessageDoYouWantToProceedThisRequest" defaultMessage="Do you want to proceed this request?" />}
          />

          <DraggableModal
            title={
              <span>
                <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  <FormattedMessage id="StockControl.Sent" defaultMessage="Sent" />
                </span>
              </span>
            }
            centered
            open={this.state.modalSentAction}
            onOk={() => this.clearAndCallBackAllAssignment("Call_Back")}
            onCancel={() => this.setState({ modalSentAction: false })}
            okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<FormattedMessage id="GeneralMessageDoYouWantToProceedThisRequest" defaultMessage="Do you want to proceed this request?" />}
          />

          <Drawer
            title={<FormattedMessage id="StockControl.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerSettingsShow: false });
            }}
            open={this.state.drawerSettingsShow}
          >
            <PageSettings pageName={"Stock_Control"} />
          </Drawer>
        </Badge.Ribbon>
      </>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(StockControl);
