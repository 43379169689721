import { ExportOutlined, FilterOutlined, ImportOutlined, UploadOutlined } from "@ant-design/icons";
import { EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import SyncIcon from "@material-ui/icons/Sync";
import {
  Button as AntButton,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal as AntModal,
  Row,
  Select,
  Table as AntTable,
  Upload
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { inputTypeSelectItems } from "../../Components/helpers/ComponentProperties";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import CountriesAndCitiesJSON from "./countries_states_cities.json";

const { RangePicker } = DatePicker;
const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.data.id,
      lineage: node.data.lineage,
      title: node.data.menuTitle
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class PageForm extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      inputTypeSelectItems: inputTypeSelectItems,
      dynamicMenuCascader: [],
      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      folderSelectItems: [],
      entitySelectItems: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      excelData: [],
      importFormAsJsonModal: false
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.getDynamicMenu();
    this.getFolders();
    this.getEntities();
  };
  getDynamicMenu = async () => {
    const responseTree = await handleRequest("GET", "/api/menus/tree");

    if (responseTree.data.length !== 0) {
      generateList(responseTree.data.treeTable);
      this.setState({
        dynamicMenuCascader: responseTree.data.cascader
      });
    }
  };
  getFolders = async () => {
    const responseFolder = await handleRequest("GET", "/ApplicationSettings/getDomainFolders");
    if (Boolean(responseFolder.data) && responseFolder.data.length > 0) {
      this.setState({
        folderSelectItems: responseFolder.data
      });
    }
  };
  getEntities = async folder => {
    if (!Boolean(folder)) {
      let response = await handleRequest("GET", "/api/pageForm/getEntitiesFromLocal");
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          entitySelectItems: response.data
        });
      }
    } else {
      let response = await handleRequest("GET", "/api/pageForm/getEntitiesFromFolder/" + folder);
      console.log(response);
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          entitySelectItems: response.data
        });
      }
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    let newItem = {
      ...row
    };
    var menu = dataList.find(p => p.key === newItem.menuId);
    let menuId = [];
    if (Boolean(menu)) {
      menuId = menu.lineage;
    }
    if (Boolean(newItem.pageFormInputList)) {
      delete newItem.pageFormInputList;
    }
    this.setState(
      {
        folder: newItem.folderName,
        formName: newItem.formName
      },
      () => {
        this.getEntityFields(newItem.entityName, () => {
          this.formRef.current.setFieldsValue({
            ...newItem,
            menuId: menuId
          });
          this.setState({
            hideUpdate: false,
            hideSave: true,
            hideInputs: false,
            entity: newItem.entityName,
            id: newItem.id
          });
        });
      }
    );
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/pageForm/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/pageForm/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      menuId: Boolean(values.menuId) && values.menuId.length > 0 ? values.menuId[values.menuId.length - 1] : null
    };

    if (newItem.id === null) {
      newItem.pageFormInputList.forEach(element => {
        if (Boolean(element.inputProps)) {
          element.inputProps.forEach(element2 => {
            delete element2.element;
          });
          element.inputProps = JSON.stringify(element.inputProps);
        }
      });
      var response = await handleRequest("POST", "/api/pageForm", newItem);
    } else {
      delete newItem.pageFormInputList;
      response = await handleRequest("PUT", "/api/pageForm/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            },
            {
              name: "Export",
              icon: <ExportOutlined fontSize="small" color="primary" />,
              actionClick: this.exportFormAsJson.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };
  getEntityFields = async (entity, callback) => {
    let { folder } = this.state;
    let url = "/api/pageForm/bymodeldto/" + entity;
    if (Boolean(folder)) {
      url = "/api/pageForm/bymodeldtoandfolder/" + entity + "/" + folder;
    }
    const response = await handleRequest("GET", url);
    if (Boolean(response.data) && response.data.length > 0) {
      const data = response.data;
      data.forEach((element, index) => {
        element.rowIndex = index + 1;
        if (Boolean(element.inputProps)) {
          element.inputProps = JSON.parse(element.inputProps);
        }
        if (!Boolean(element.inputType)) {
          element.inputType = "TEXT";
          element.inputProps = this.state.inputTypeSelectItems.find(i => i.key === "TEXT").inputProps;
        }
      });

      this.setState(
        {
          dynamicFormList: data
        },
        () => {
          callback(data);
        }
      );
    }
  };
  onChangeFolder = async value => {
    let folder = value;
    if (!Boolean(folder)) {
      let response = await handleRequest("GET", "/api/pageForm/getEntitiesFromLocal");
      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          entitySelectItems: response.data
        });
      }
    } else {
      let response = await handleRequest("GET", "/api/pageForm/getEntitiesFromFolder/" + folder);

      if (Boolean(response.data) && response.data.length > 0) {
        this.setState({
          entitySelectItems: response.data
        });
      }
    }
    this.setState({
      folder: folder
    });
  };

  syncCities = async () => {
    let json = CountriesAndCitiesJSON;
    let json2 = json.map(element => {
      let c = { iso2: element.iso2, states: [] };
      element.states.forEach(element2 => {
        let s = { name: element2.name, state_code: element2.state_code };
        c.states.push(s);
      });
      return c;
    });
    var response = await handleRequest("POST", "/api/countries/syncCities", json2);

    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
    }
  };

  createForm = async () => {
    const { formName } = this.state;
    if (Boolean(formName)) {
      await handleRequest("GET", "/api/pageForm/createFormFile/" + formName);

      showSuccess();
    } else showError();
  };

  createInlineForm = async () => {
    const { formName } = this.state;
    if (Boolean(formName)) {
      await handleRequest("GET", "/api/pageForm/createInlineFormFile/" + formName);

      showSuccess();
    } else showError();
  };
  exportFormAsJson = row => {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(row));
    var downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", row.formName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };
  showImportFormAsJsonModal = () => {
    this.setState({
      importFormAsJsonModal: true
    });
  };
  importFormAsJson = async data => {
    let json = JSON.parse(data);
    let response = await handleRequest("POST", "/api/pageForm/importJson", json);

    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const formListStyle = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      style: {
        marginBottom: 0
      }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="PageForm.formName" defaultMessage="FormName" />,
        key: "formName",
        render: record => {
          return record.id + "," + record.formName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.formName) ? this.state.pagination.formName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  formName: e.target.value,
                  formNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.formNameColor) ? this.state.pagination.formNameColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PageForm.title" defaultMessage="Title" />,
        key: "title",
        render: record => {
          return record.title;
        },
        className: this.state.hide_title ? "d-none" : "",
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.title) ? this.state.pagination.title : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  title: e.target.value,
                  titleColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.titleColor) ? this.state.pagination.titleColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PageForm.menuId" defaultMessage="MenuId" />,
        key: "menuId",
        render: record => {
          return record.menuId;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.menuId) ? this.state.pagination.menuId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  menuId: e.target.value,
                  menuIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.menuIdColor) ? this.state.pagination.menuIdColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PageForm.showTable" defaultMessage="ShowTable" />,
        key: "showTable",
        render: record => {
          return record.showTable;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.showTable) ? this.state.pagination.showTable : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  showTable: e.target.value,
                  showTableColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.showTableColor) ? this.state.pagination.showTableColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="PageForm.tableLoadUrl" defaultMessage="TableLoadUrl" />,
        key: "tableLoadUrl",
        render: record => {
          return record.tableLoadUrl;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.tableLoadUrl) ? this.state.pagination.tableLoadUrl : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  tableLoadUrl: e.target.value,
                  tableLoadUrlColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.tableLoadUrlColor) ? this.state.pagination.tableLoadUrlColor : "#bfbfbf" }} />
        ),

        sorter: true
      },

      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    return (
      <>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={17}></Col>
              <Col md={3}>
                <AntButton style={{ border: "0px" }} onClick={this.syncCities} icon={<SyncIcon color="primary" />}>
                  Sync Cities
                </AntButton>
              </Col>
              <Col md={3}>
                <AntButton style={{ border: "0px" }} onClick={this.showImportFormAsJsonModal} icon={<ImportOutlined color="primary" />}>
                  Import Form
                </AntButton>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              <Form.Item
                {...layout}
                label="Module"
                name="folderName"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  placeholder="Module Name"
                  style={{ width: "100%" }}
                  value={this.state.folder}
                  onChange={this.onChangeFolder}
                >
                  {this.state.folderSelectItems.map(i => (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                label="Table"
                name="entityName"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  placeholder="Entity Name"
                  style={{ width: "100%" }}
                  value={this.state.entity}
                  onChange={value => {
                    this.setState({
                      entity: value
                    });
                    this.getEntityFields(value, data => {
                      this.formRef.current.setFieldsValue({ pageFormInputList: data });
                    });
                  }}
                >
                  {this.state.entitySelectItems.map(i => (
                    <Option key={i} value={i}>
                      {i}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageForm.menuId" defaultMessage="MenuId" />}
                name="menuId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Cascader style={{ width: "100%" }} options={this.state.dynamicMenuCascader} />
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageForm.formName" defaultMessage="FormName" />}
                name="formName"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="FormName" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageForm.title" defaultMessage="Title" />}
                name="title"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="Title" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageForm.showTable" defaultMessage="ShowTable" />}
                name="showTable"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox>
                  <FormattedMessage id="PageForm.showTable" defaultMessage="ShowTable" />{" "}
                </Checkbox>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PageForm.tableLoadUrl" defaultMessage="TableLoadUrl" />}
                name="tableLoadUrl"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="TableLoadUrl" style={{ width: "100%" }} />
              </Form.Item>
              <Form.List name="pageFormInputList">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>Show In Form</th>
                            <th>Order</th>
                            <th>Input Name</th>
                            <th>Label</th>
                            <th>Is Required ?</th>
                            <th>Input Type</th>
                            <th>Whatis Description</th>
                            <th>Input Props</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            if (!Boolean(field) || !Boolean(this.state.dynamicFormList)) return null;
                            let f = this.state.dynamicFormList[field.name];
                            if (!f) return null;
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle valuePropName="checked" name={[field.name, "showOnForm"]}>
                                    <Checkbox></Checkbox>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "rowIndex"]}>
                                    <InputNumber min={0} max={this.state.dynamicFormList.length} />
                                  </Form.Item>
                                </td>
                                <td>{f.inputName}</td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "label"]}>
                                    <Input />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "required"]} valuePropName="checked">
                                    <Checkbox></Checkbox>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item
                                    noStyle
                                    name={[field.name, "inputType"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                      }
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      allowClear
                                      placeholder="Input type"
                                      style={{ width: "90%", minWidth: "180px" }}
                                      onChange={value => {
                                        let list = this.state.dynamicFormList;
                                        let obj = list[field.key];
                                        let props = this.state.inputTypeSelectItems.find(i => i.key === value);
                                        let pageFormInputList = this.formRef.current.getFieldValue("pageFormInputList");
                                        if (!Boolean(props.inputProps)) {
                                          obj.inputProps = null;
                                          pageFormInputList[field.key].inputProps = null;
                                          this.setState(
                                            {
                                              dynamicFormList: list
                                            },
                                            () => {
                                              this.formRef.current.setFieldsValue({
                                                pageFormInputList: pageFormInputList
                                              });
                                            }
                                          );

                                          return;
                                        }
                                        obj.inputProps = props.inputProps;
                                        pageFormInputList[field.key].inputProps = props.inputProps;
                                        this.setState(
                                          {
                                            dynamicFormList: list
                                          },
                                          () => {
                                            this.formRef.current.setFieldsValue({
                                              pageFormInputList: pageFormInputList
                                            });
                                          }
                                        );
                                      }}
                                      rules={[
                                        {
                                          required: true,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      {this.state.inputTypeSelectItems.map(i => (
                                        <Option key={i.key} value={i.key}>
                                          {i.key}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "whatIsDescription"]}>
                                    <Input />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Button
                                    type="button"
                                    variant="outline-primary"
                                    onClick={() => {
                                      this.setState({
                                        ["inputPropsModal" + field.key]: true
                                      });
                                    }}
                                  >
                                    Show Props
                                  </Button>
                                  <AntModal
                                    title="Input Props"
                                    visible={this.state["inputPropsModal" + field.key]}
                                    onCancel={() => {
                                      this.setState({
                                        ["inputPropsModal" + field.key]: false
                                      });
                                    }}
                                    cancelButtonProps={{
                                      style: {
                                        display: "none"
                                      }
                                    }}
                                    onOk={() => {
                                      this.setState({
                                        ["inputPropsModal" + field.key]: false
                                      });
                                    }}
                                    maskClosable={false}
                                    width={600}
                                  >
                                    <div className="ant-form ant-form-horizontal">
                                      <Form.List name={[field.name, "inputProps"]}>
                                        {(fields2, { add, remove }) => {
                                          let obj = this.state.dynamicFormList[field.key].inputProps;
                                          return (
                                            <>
                                              {fields2.map(field2 => {
                                                if (!Boolean(field2) || !Boolean(obj)) return null;
                                                let f = obj[field2.name];
                                                return (
                                                  <Form.Item
                                                    {...formListStyle}
                                                    label={f.label}
                                                    key={field2.key}
                                                    name={[field2.name, f.key]}
                                                    rules={[
                                                      {
                                                        required: false,
                                                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                                      }
                                                    ]}
                                                  >
                                                    {f.element}
                                                  </Form.Item>
                                                );
                                              })}
                                            </>
                                          );
                                        }}
                                      </Form.List>
                                    </div>
                                  </AntModal>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </>
                  );
                }}
              </Form.List>

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <>
                    <Form.Item {...layout} label={"Create Form File"}>
                      <Button onClick={() => this.createForm()} variant="success">
                        Create Form File
                      </Button>
                    </Form.Item>
                    <Form.Item {...layout} label={"Create Inline Form File"}>
                      <Button onClick={() => this.createInlineForm()} variant="success">
                        Create Inline Form File
                      </Button>
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                          <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                          <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                        </Button>
                      </Col>
                      <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    </Row>
                  </>
                }
              </div>
            </div>
          </Form>

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <AntTable
              bordered
              rowKey={record => record.id}
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <AntModal
            title="Import Json Page Form"
            visible={this.state.importFormAsJsonModal}
            onCancel={() => {
              this.setState({ importFormAsJsonModal: false });
            }}
            cancelButtonProps={{ style: { display: "none" } }}
            onOk={() => {
              this.setState({ importFormAsJsonModal: false });
            }}
            maskClosable={false}
          >
            <div className="ant-form ant-form-horizontal">
              <Form.Item {...layout} label="Json">
                <Upload
                  accept=".json"
                  showUploadList={false}
                  beforeUpload={file => {
                    const reader = new FileReader();
                    reader.onload = e => {
                      this.importFormAsJson(e.target.result);
                    };
                    reader.readAsText(file);

                    // Prevent upload
                    return false;
                  }}
                >
                  <AntButton icon={<UploadOutlined />} style={{ width: "100%", marginTop: "0.2rem" }}>
                    <span>Upload</span>
                  </AntButton>
                </Upload>
              </Form.Item>
            </div>
          </AntModal>
        </div>
      </>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageForm);
