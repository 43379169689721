import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function TextToParaDrawer({ textToParaLogItemId }) {
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  const [selectedTab, setSelectedTab] = React.useState("overall");
  useEffect(() => {
    return () => { };
  }, [user]);

  return (
    <>
      {user && (
        <div className="d-flex flex-row">


          {/* sol kart */}
          <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
            <div className="card card-custom card-stretch">
              {/* begin::Body */}
              <div className="card-body pt-4">
                {/* begin::Toolbar */}
                <div className="d-flex justify-content-end"></div>
                {/* end::Toolbar */}
                {/* begin::Nav */}
                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="TextToParaDrawer.Overall" defaultMessage="Overall" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "overall" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("overall");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="TextToParaDrawer.Overall" defaultMessage="Overall" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="TextToParaDrawer.Assigment" defaultMessage="Document" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "document" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("document");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Article.svg")}></SVG>{" "}
                            </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="TextToParaDrawer.Assigment" defaultMessage="Document" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="TextToParaDrawer.Personnel" defaultMessage="Personnel" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "personnel" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("personnel");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="TextToParaDrawer.Personnel" defaultMessage="Personnel" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="TextToParaDrawer.Links" defaultMessage="Links" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "links" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("links");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="TextToParaDrawer.Links" defaultMessage="Links" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="TextToParaDrawer.Schedule" defaultMessage="Schedule" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "schedule" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("schedule");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}></SVG>{" "}
                            </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="TextToParaDrawer.Schedule" defaultMessage="Schedule" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>   <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="TextToParaDrawer.Assign" defaultMessage="Assign" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "assign" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("assign");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="TextToParaDrawer.Assign" defaultMessage="Assign" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>


                </div>
                {/* end::Nav */}
              </div>
              {/* end::Body */}
            </div>
          </div>



          {/* sağ kart */}
          <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-700px" id="kt_profile_aside">
            {selectedTab === "overall" && (
              <div className="card card-custom card-stretch">
                <div className="form">



                </div>
              </div>
            )}

            {selectedTab === "document" && (
              <div className="card card-custom card-stretch">
                <div className="form">



                </div>
              </div>
            )}

            {selectedTab === "personnel" && (
              <div className="card card-custom card-stretch">
                <div className="form">



                </div>
              </div>
            )}

            {selectedTab === "links" && (
              <div className="card card-custom card-stretch">
                <div className="form">



                </div>
              </div>
            )}



            {selectedTab === "schedule" && (
              <div className="card card-custom card-stretch">
                <div className="form">



                </div>
              </div>
            )}



            {selectedTab === "assign" && (
              <div className="card card-custom card-stretch">
                <div className="form">



                </div>
              </div>
            )}

          </div>
        </div>
      )}
    </>
  );
}
