import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Tooltip, Drawer, Space, Radio, Cascader, Card, Image } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined, FilterList, Settings } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import store from "../../../redux/store";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import DraggableModal from "../../Components/DraggableModal";
import { Select } from "antd";
import { Checkbox } from "antd";
import { InputNumber } from "antd";
import { DatePicker } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, ThunderboltOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class PurchaseRequisitionNewCreate extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "PurchaseRequisitionNewCreate",
      controllerName: "purchaseRequisitionNewCreate",
      id: null,
      loading: false,
      loadingSearch: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      itemIdSelectItems: [],
      codeSelectItems: [],
      unitSelectItems: [],
      purchasingCriteriaSelectItems: [],
      purchasingCriteriaUnitSelectItems: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      itemGroupIdSelectItems: null,
      itemDescriptionIdSelectItems: null,
      nodes: []
    };
  }

  formRef = React.createRef();
  formRefItem = React.createRef();
  formRefProposedSupplier = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  }


  fillComboboxes = async () => {
    var responseitemGroupId = await handleRequest("GET", "/api/" + this.state.controllerName + "/category");
    if (responseitemGroupId.type === "ERROR") {
      error(responseitemGroupId);
    } else {
      this.setState({
        itemGroupIdSelectItems: Boolean(responseitemGroupId.data) ? responseitemGroupId.data : []
      });
    }

    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      this.setState({
        nodes: responseTree.data
      });
    }
  };

  onChangeItemGroupId = async value => {
    if (Boolean(value)) {
      var responseitemDescriptionId = await handleRequest("GET", "/api/projectTakeOff/itemDescriptionIdSelectItems/" + value);
      if (responseitemDescriptionId.type === "ERROR") {
        error(responseitemDescriptionId);
      } else {
        this.setState({
          itemDescriptionIdSelectItems: Boolean(responseitemDescriptionId.data) ? responseitemDescriptionId.data : []
        });
      }
    } else {
      this.setState({
        itemDescriptionIdSelectItems: []
      });
    }
  };

  onChangeItemGroupIdforNameCode = async () => {
    const { itemGroupId, materialTypeId, itemDescriptionId } = this.state;
    let obj = {
      materialTypeId: Boolean(materialTypeId) && materialTypeId.length > 0 ? materialTypeId[materialTypeId.length - 1] : null,
      categoryId: Boolean(itemGroupId) ? itemGroupId : null,
      descriptionId: Boolean(itemDescriptionId) ? itemDescriptionId : null
    };

    var responseCodeSelectItems = await handleRequest("POST", "/api/" + this.state.controllerName + "/nameCode", obj);
    if (responseCodeSelectItems.type === "ERROR") {
      error(responseCodeSelectItems);
    } else {
      this.setState({
        itemIdSelectItems: Boolean(responseCodeSelectItems.data) ? responseCodeSelectItems.data : []
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = async (row) => {
    document.getElementById("kt_scrolltop").click();

    if (Boolean(row.itemName)) {
      this.searchItem(row.itemName);
    }

    if (Boolean(row.itemId)) {
      this.fillCodeSelectItems(row.itemId);

      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getStockCard/" + row.itemId);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(row.purchasingCriteriaId)) {
          this.fillPurchasingCriteriaUnits(row.purchasingCriteriaId, true);
        }
        let stockCard = Boolean(response.data) ? response.data : [];
        this.setState(
          {
            itemId: row.itemId,
            selectedStockCard: stockCard,
          },
          async () => {
            await this.fillPurchasingCriterias(true);
          }
        );

        if (Boolean(stockCard.itemDimension) && stockCard.itemDimension.length > 0) {
          stockCard.itemDimension.forEach(element => {
            this.formRef.current.setFieldsValue({
              [element.itemDefinition]: element.codeId,
              [element.itemDefinition + "Unit"]: element.unitId
            });
          });
        }
        if (Boolean(stockCard.itemCodeCriterias) && stockCard.itemCodeCriterias.length > 0) {
          stockCard.itemCodeCriterias.forEach(element => {
            this.formRef.current.setFieldsValue({
              [element.itemDefinition]: element.codeId
            });
          });
        }
      }
    }

    let dimensionValues = [];
    if (Boolean(row.dimensionValues) && row.dimensionValues.length > 0) {
      row.dimensionValues.forEach(element => {
        dimensionValues.push({
          id: element.id,
          key: element.id,
          definition: element.itemDimension.definition,
          value: element.value,
          itemDimensionId: element.itemDimensionId,
          unitId: element.unitId,
          units: element.itemDimension.unitType.units
        });
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      proposedReceivingDate: Boolean(row.proposedReceivingDate) ? moment(row.proposedReceivingDate) : null,
      dimensionValues: dimensionValues,
    });

    console.log("dimensionValues", dimensionValues);

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      dimensionValues: dimensionValues,
      id: row.id
    });

    setTimeout(() => {
      const { purchasingCriteriaSelectItems } = this.state;
      const selectedPurchasingCriteria = purchasingCriteriaSelectItems.find(i => i.key === row.purchasingCriteriaId);
      this.setState({
        selectedPurchasingCriteria
      });
    }, 1500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    const { dimensionValues } = this.state;
    if (Boolean(dimensionValues)) {
      newItem.dimensionValues = dimensionValues;
    } else {
      newItem.dimensionValues = [];
    }

    console.log("SAVE:", newItem);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  saveItem = async values => {
    console.log("Values", values);
    this.setState({
      showItemReachSearchModal: false
    }, () => {
      this.formRef.current.setFieldsValue({
        itemId: values.code
      });
    });
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  searchItem = async (item) => {
    console.log("item", item);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemSearch/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          itemIdSelectItems: [],
          loadingSearch: false,
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            itemIdSelectItems: response.data,
            loadingSearch: false,
          });
        } else {
          this.setState({
            itemIdSelectItems: [],
            loadingSearch: false,
          });
        }
      }
    } else {
      this.setState({
        loadingSearch: false,
      });
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  sendSelectModal = () => {
    this.setState({
      modalSendSelectShow: true
    });
  };

  sendToRequest = async () => {
    const { selectedRowKeys, tableList } = this.state;
    console.log("selectedRowKeys", selectedRowKeys);
    if (Boolean(selectedRowKeys) && selectedRowKeys.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/sendToRequest", selectedRowKeys);
      if (response.type === "ERROR") {
        error(response);
      } else {
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.setState({
          modalSendSelectShow: false
        });
        this.props.close();
      }
      this.setState({
        selectedRowKeys: []
      });
    } else {
      showWarning("Please select item.");
    }
  };

  fillCodeSelectItems = async (itemId) => {
    if (Boolean(itemId) && itemId > 0) {
      let responsecode = await handleRequest("GET", "/api/" + this.state.controllerName + "/stockControlCode/" + itemId);
      if (responsecode.type === "ERROR") {
        error(responsecode);
      } else {
        this.setState({
          codeSelectItems: Boolean(responsecode.data) ? responsecode.data : []
        }, () => {
          this.fillItemDescription(itemId);
        });
      }
    } else {
      this.setState({
        codeSelectItems: []
      }, () => {
        this.fillItemDescription(itemId);
      });
    }
  }

  fillProposedSupplierId = async (itemId) => {
    if (Boolean(itemId) && itemId > 0) {
      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillProposedSupplierId/" + itemId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          proposedSupplierIdSelectItems: Boolean(response.data) ? response.data : []
        });
      }
    } else {
      this.setState({
        proposedSupplierIdSelectItems: []
      });
    }
  }

  fillItemDescription = async (itemId) => {
    if (Boolean(itemId) && itemId > 0) {
      let responseItemDescription = await handleRequest("GET", "/api/" + this.state.controllerName + "/eldenItem/" + itemId);

      if (responseItemDescription.type === "ERROR") {
        error(responseItemDescription);
      } else {
        let itemDescription = Boolean(responseItemDescription.data) ? responseItemDescription.data : [];
        this.setState({
          itemDescription: itemDescription
        }, () => {
          this.fillDynamicDetail(itemId);
        });
      }
    } else {
      this.setState({
        dimensionValues: null,
        itemDescription: null
      });
      this.formRef.current.setFieldsValue({
        dimensionValues: null
      });
    }
  }

  fillDynamicDetail = async (value) => {
    this.setState({ itemId: value }, () => {
      const { itemDescription } = this.state;
      if (Boolean(itemDescription.itemDimension) && itemDescription.itemDimension.length > 0) {
        itemDescription.itemDimension.forEach(element => {
          this.fillCode("StockCard" + element.itemDefinition);
          if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
            this.formRef.current.setFieldsValue({
              [element.itemDefinition]: null
            });
          }
        });
      }
      if (Boolean(itemDescription.itemCodeCriterias) && itemDescription.itemCodeCriterias.length > 0) {
        itemDescription.itemCodeCriterias.forEach(element => {
          this.fillCode("StockCard" + element.itemDefinition);
          if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
            this.formRef.current.setFieldsValue({
              [element.itemDefinition]: null
            });
          }
        });
      }

      const { codeSelectItems } = this.state;
      console.log("value", value);
      console.log("codeSelectItems", codeSelectItems);
      let unitName = "mm";
      if (Boolean(value)) {
        let fObj = codeSelectItems.find(i => i.id === value);
        if (Boolean(fObj)) {
          if (Boolean(fObj.itemCodeCriterias) && fObj.itemCodeCriterias.length > 0) {
            fObj.itemCodeCriterias.forEach(element => {
              this.formRef.current.setFieldsValue({
                [element.itemDefinition]: element.codeId
              });
            });
          }

          if (Boolean(fObj.itemDimension) && fObj.itemDimension.length > 0) {
            fObj.itemDimension.forEach(element => {
              this.formRef.current.setFieldsValue({
                [element.itemDefinition]: element.codeId,
                [element.itemDefinition + "Unit"]: element.unitId
              });
            });
          }
        }

        if (Boolean(this.state.dimensionValues) && this.state.dimensionValues.length > 0) {
          this.state.dimensionValues.forEach(element => {
            if (element.definition === "Length" || element.definition === "Width") {
              if (Boolean(element) && Boolean(element.unitId) && Boolean(element.units) && element.units.length > 0) {
                let unit = element.units.find(p => p.id === element.unitId);
                if (Boolean(unit)) {
                  unitName = unit.abbreviation;
                }
              }
            }
          });
        }
      }
    });
  }

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillPurchasingCriterias = async edit => {
    const { itemId } = this.state;
    if (Boolean(itemId) && itemId > 0) {
      let responsePurchaseCriteria = await handleRequest("GET", "/api/" + this.state.controllerName + "/purchasingcriterias/" + itemId);
      if (responsePurchaseCriteria.type === "ERROR") {
        error(responsePurchaseCriteria);
      } else {
        let purchasingCriteriaSelectItems = [];
        let purchasingCriteriaId = null;
        if (Boolean(responsePurchaseCriteria.data) && responsePurchaseCriteria.data.length > 0) {
          purchasingCriteriaSelectItems = responsePurchaseCriteria.data;
          purchasingCriteriaId = responsePurchaseCriteria.data[0].key;
        }
        this.setState({
          purchasingCriteriaSelectItems: purchasingCriteriaSelectItems
        });

        if (!Boolean(edit) && Boolean(this.formRef) && Boolean(this.formRef.current)) {
          this.formRef.current.setFieldsValue({
            purchasingCriteriaId: purchasingCriteriaId
          });

          if (Boolean(purchasingCriteriaId)) {
            this.fillPurchasingCriteriaUnits(purchasingCriteriaId);
          }
        }
      }
    } else {
      this.setState({
        purchasingCriteriaSelectItems: []
      });
      this.formRef.current.setFieldsValue({
        purchasingCriteriaId: null
      });
      this.fillPurchasingCriteriaUnits(null);
    }
  };

  fillPurchasingCriteriaUnits = async (criteriaId, edit) => {
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.setFieldsValue({
        purchasingCriteriaUnitId: null
      });
    }
    if (Boolean(criteriaId)) {
      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getUnitType/" + criteriaId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        let purchasingCriteriaUnitSelectItems = [];
        let purchasingCriteriaUnitId = null;
        if (Boolean(response.data) && response.data.length > 0) {
          purchasingCriteriaUnitSelectItems = response.data;
          purchasingCriteriaUnitId = response.data[0].id;
        }
        this.setState({
          purchasingCriteriaUnitSelectItems: purchasingCriteriaUnitSelectItems
        });

        if (!Boolean(edit) && Boolean(this.formRef) && Boolean(this.formRef.current)) {
          this.formRef.current.setFieldsValue({
            purchasingCriteriaUnitId: purchasingCriteriaUnitId
          });
        }
      }
    } else {
      this.setState({
        purchasingCriteriaUnitSelectItems: []
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layoutLeft = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };

    const layoutRight = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 }
    };


    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideSelectAll: true,
      renderCell: (checked, record, index, originNode) => {
        return originNode;
      }
    };

    const columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="PurchaseRequisitionNewCreate.itemId" defaultMessage="Item" />,
        key: "itemId",
        render: record => {
          return record.itemName;
        },
        ...getColumnFilter({
          inputName: "itemName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PurchaseRequisitionNewCreate.allDimensions" defaultMessage="Dimensions" />,
        key: "allDimensions",
        ellipsis: true,
        render: record => {
          return <Tooltip title={record.allDimensions}>
            {record.allDimensions}
          </Tooltip>;
        },
      },
      {
        title: <FormattedMessage id="PurchaseRequisitionNewCreate.quantity" defaultMessage="Quantity" />,
        key: "quantity",
        render: record => {
          let unitName = "";
          let quantity = "";
          if (Boolean(record.quantity)) {
            quantity = record.quantity;
            if (Boolean(record.unitName)) {
              unitName = record.unitName;
            }
          }
          return quantity + " " + unitName;
        },
        ...getColumnFilter({
          inputName: "quantity",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PurchaseRequisitionNewCreate.Sketch" defaultMessage="Sketch" />,
        key: "sketchUrl",
        render: record => {
          if (Boolean(record.sketchUrl)) {
            return <Image width={50} src={record.sketchUrl}></Image>
          }
        },
      },
      {
        title: <FormattedMessage id="PurchaseRequisitionNewCreate.ProposedReceivingDate" defaultMessage="Proposed Receiving Date" />,
        key: "proposedReceivingDate",
        render: record => {
          return Boolean(record.proposedReceivingDate) ? moment(record.proposedReceivingDate).format("DD-MM-YYYY HH:mm") : "";
        },
        ...getColumnFilter({
          inputName: "proposedReceivingDate",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PurchaseRequisitionNewCreate.status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          return record.status;
        },
        ...getColumnFilter({
          inputName: "status",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="PurchaseRequisitionNewCreate.LastUpdate" defaultMessage="Last Update" />,
        key: "lastUpdated",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        ...getColumnFilter({
          inputName: "lastUpdated",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="PurchaseRequisitionNewCreate" hideElement={true}>
            <ExcelSheet name="PurchaseRequisitionNewCreate" data={this.state.tableList}>
              <ExcelColumn label="Item" value="itemName" />
              <ExcelColumn label="Dimensions" value="allDimensions" />
              <ExcelColumn label="Quantity" value="quantity" />
              <ExcelColumn label="Unit" value="unit" />
              <ExcelColumn label="Sketch" value="sketch" />
              <ExcelColumn label="Purpose Of Use" value="purposeOfUseName" />
              <ExcelColumn label="Location" value="location" />
              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="Received Quantity" value="receivedQuantity" />
              <ExcelColumn label="Last Update" value="lastUpdated" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={20}></Col> <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
            <Col md={1}>
              {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                <Tooltip title={<FormattedMessage id="PurchaseRequisitionNewCreate.Settings" defaultMessage="Settings" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      this.setState({ drawerSettingsShow: true });
                    }}
                    icon={<Settings color="error" fontSize="large" />}
                  ></AntButton>
                </Tooltip>
              )}
            </Col>
          </Row>
          <div hidden={this.state.hideInputs}>
            <Row gutter={16} style={{ marginBottom: "16px" }}>
              <Col span={15}>
                <Card
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <Form.Item
                    {...layoutLeft}
                    label={<FormattedMessage id="PurchaseRequisitionNewCreate.itemId" defaultMessage="Item" />}
                  >
                    <Space.Compact style={{ width: "100%" }}>
                      <Form.Item
                        name="itemId"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        style={{ width: "90%" }}
                      >
                        <Select
                          allowClear
                          showSearch
                          onSearch={this.searchItem}
                          style={{ marginBottom: 0, width: "100%" }}
                          loading={this.state.loadingSearch}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }

                          onChange={(value) => {
                            this.setState({
                              itemId: value
                            }, () => {
                              this.fillPurchasingCriterias(false);
                              this.fillCodeSelectItems(value);
                              this.fillProposedSupplierId(value);
                            });
                          }}
                        >
                          {Boolean(this.state["itemIdSelectItems"]) &&
                            this.state["itemIdSelectItems"].length > 0 &&
                            this.state["itemIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <AntButton
                        type="default"
                        style={{
                          color: "black",
                          background: "white",
                          width: "10%"
                        }}
                        onClick={() => {
                          this.setState({ showItemReachSearchModal: true });
                        }}
                        icon={<FilterList />}
                      ></AntButton>
                    </Space.Compact>
                  </Form.Item>

                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="PurchaseRequisitionNewCreate.PurposeofUse" defaultMessage="Purpose of Use" />}
                      name="purposeOfUseId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear style={{ width: "100%" }} mode="multiple" optionFilterProp="children">
                        {Boolean(this.state["purposeOfUseIdSelectItems"]) &&
                          this.state["purposeOfUseIdSelectItems"].length > 0 &&
                          this.state["purposeOfUseIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  }

                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="PurchaseRequisitionNewCreate.ProposedSupplier" defaultMessage="Proposed Supplier" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Space.Compact style={{ width: "100%" }}>
                        <Form.Item
                          name="proposedSupplierId"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          style={{ width: "90%" }}
                        >
                          <Select allowClear style={{ width: "100%" }} mode="multiple" optionFilterProp="children">
                            {Boolean(this.state["proposedSupplierIdSelectItems"]) &&
                              this.state["proposedSupplierIdSelectItems"].length > 0 &&
                              this.state["proposedSupplierIdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>

                        <AntButton
                          type="default"
                          style={{
                            color: "black",
                            background: "white",
                            width: "10%"
                          }}
                          onClick={() => {
                            this.setState({ showProposedSupplierStatisticsModal: false });
                          }}
                          icon={<ThunderboltOutlined />}
                        ></AntButton>
                      </Space.Compact>
                    </Form.Item>
                  }

                  {Boolean(this.state.dimensionValues) &&
                    this.state.dimensionValues.length > 0 &&
                    this.state.dimensionValues.map((dimension, index) => {
                      return (
                        <Form.Item key={index} {...layoutLeft} label={dimension.definition}>
                          <Row>
                            <Col span={11}>
                              <InputNumber
                                parser={value => value.replace(",", ".")}
                                formatter={value => value.replace(".", ",")}
                                placeholder={dimension.definition}
                                style={{ width: "100%" }}
                                min={0}
                                value={dimension.value}
                                onChange={value => {
                                  let list = this.state.dimensionValues;
                                  list[index].value = value;
                                  let unitName = "mm";
                                  if (Boolean(dimension) && Boolean(dimension.unitId) && Boolean(dimension.units) && dimension.units.length > 0) {
                                    let unit = dimension.units.find(p => p.id === dimension.unitId);
                                    if (Boolean(unit)) {
                                      unitName = unit.abbreviation;
                                    }
                                  }
                                  this.setState(
                                    {
                                      dimensionValues: list
                                    },
                                    () => {
                                      if (dimension.definition === "Length" || dimension.definition === "Width") {
                                        console.log("selectedStockCard", this.state.selectedStockCard);
                                        let row = {
                                          code: Boolean(this.state.selectedStockCard) ? this.state.selectedStockCard.code : "",
                                          unit: unitName,
                                          dimensionValues:
                                            Boolean(this.state.dimensionValues) && this.state.dimensionValues.length > 0
                                              ? this.state.dimensionValues
                                              : [],
                                          unitSelectItems:
                                            Boolean(this.state.unitSelectItems) && this.state.unitSelectItems.length > 0
                                              ? this.state.unitSelectItems
                                              : []
                                        };
                                      }
                                    }
                                  );
                                }}
                              />
                            </Col>
                            <Col span={2}></Col>
                            <Col span={11}>
                              <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{ width: "100%" }}
                                placeholder="Unit"
                                value={dimension.unitId}
                                onChange={value => {
                                  console.log("VVV", value);
                                  console.log(dimension);
                                  let list = this.state.dimensionValues;
                                  list[index].unitId = value;
                                  let unitName = "mm";
                                  if (Boolean(value) && Boolean(dimension) && Boolean(dimension.units) && dimension.units.length > 0) {
                                    let unit = dimension.units.find(p => p.id === value);
                                    if (Boolean(unit)) {
                                      unitName = unit.abbreviation;
                                    }
                                  }
                                  this.setState(
                                    {
                                      dimensionValues: list
                                    },
                                    () => {
                                      if (dimension.definition === "Length" || dimension.definition === "Width") {
                                        console.log("selectedStockCard", this.state.selectedStockCard);
                                        let row = {
                                          code: Boolean(this.state.selectedStockCard) ? this.state.selectedStockCard.code : "",
                                          unit: unitName,
                                          dimensionValues:
                                            Boolean(this.state.dimensionValues) && this.state.dimensionValues.length > 0
                                              ? this.state.dimensionValues
                                              : [],
                                          unitSelectItems:
                                            Boolean(this.state.unitSelectItems) && this.state.unitSelectItems.length > 0
                                              ? this.state.unitSelectItems
                                              : []
                                        };
                                      }
                                    }
                                  );
                                }}
                              >
                                {dimension.units &&
                                  dimension.units.map(i => (
                                    <Option key={i.id} value={i.id}>
                                      {i.description}
                                    </Option>
                                  ))}
                              </Select>
                            </Col>
                          </Row>
                        </Form.Item>
                      );
                    })
                  }

                  <Form.Item
                    {...layoutLeft}
                    label={
                      <FormattedMessage
                        id="PurchaseRequisitionNewCreate.quantity"
                        defaultMessage="Quantity"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      />
                    }
                  >
                    <Row>
                      <Col span={8}>
                        <Form.Item
                          name="quantity"
                          rules={[
                            { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                          ]}
                        >
                          <InputNumber
                            parser={value => value.replace(",", ".")}
                            formatter={value => value.replace(".", ",")}
                            className="w-100"
                            placeholder="Quantity"
                            min={0}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1}></Col>
                      <Col span={7}>
                        <Form.Item
                          name={"purchasingCriteriaId"}
                          rules={[
                            { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                          ]}
                        >
                          <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            style={{ width: "100%" }}
                            onChange={value => {
                              this.fillPurchasingCriteriaUnits(value);
                            }}
                          >
                            {this.state.purchasingCriteriaSelectItems &&
                              this.state.purchasingCriteriaSelectItems.map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1}></Col>
                      <Col span={7}>
                        <Form.Item
                          name={"purchasingCriteriaUnitId"}
                          rules={[
                            { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                          ]}
                        >
                          <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                            {Boolean(this.state.purchasingCriteriaUnitSelectItems) &&
                              this.state.purchasingCriteriaUnitSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>
                                  {i.abbreviation}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>

                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="PurchaseRequisitionNewCreate.proposedReceivingDate" defaultMessage="Proposed Receiving Date" />}
                      name="proposedReceivingDate"
                      rules={[
                        {
                          required: false,
                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                        }
                      ]}
                    >
                      <DatePicker style={{ width: "100%" }} showTime format="DD-MM-YYYY HH:mm" />
                    </Form.Item>
                  }

                  {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="PurchaseRequisitionNewCreate.RequestType" defaultMessage="Request Type" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            name="isNormal"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            valuePropName="checked"
                          >
                            <Checkbox onChange={e => {
                              this.formRef.current.setFieldsValue({
                                isEmergency: !e.target.checked
                              });
                            }}>{<FormattedMessage id="PurchaseRequisitionNewCreate.Normal" defaultMessage="Normal" />}</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="isEmergency"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            valuePropName="checked"
                          >
                            <Checkbox onChange={e => {
                              this.formRef.current.setFieldsValue({
                                isNormal: !e.target.checked
                              });
                            }}>{<FormattedMessage id="PurchaseRequisitionNewCreate.Emergency" defaultMessage="Emergency" />}</Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  }
                </Card>
              </Col>

              <Col span={9}>
                <Card
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    height: "100%"
                  }}
                >

                  {Boolean(this.state.itemDescription) &&
                    Boolean(this.state.itemDescription.itemCodeCriterias) &&
                    this.state.itemDescription.itemCodeCriterias.length > 0 &&
                    this.state.itemDescription.itemCodeCriterias.map(element => (
                      <Form.Item
                        {...layoutRight}
                        label={element.itemDefinition}
                        name={element.itemDefinition}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select disabled showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                          {Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"]) &&
                            this.state["StockCard" + element.itemDefinition + "SelectItems"].map(i => (
                              <Option key={i.id} value={i.id}>
                                {i.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    ))}
                  {Boolean(this.state.itemDescription) &&
                    Boolean(this.state.itemDescription.itemDimension) &&
                    this.state.itemDescription.itemDimension.length > 0 &&
                    this.state.itemDescription.itemDimension.map(element => (
                      <Form.Item
                        {...layoutRight}
                        label={element.itemDefinition}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Row>
                          <Col span={11}>
                            <Form.Item
                              name={element.itemDefinition}
                              rules={[
                                { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                              ]}
                            >
                              <Select disabled showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                {Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"]) &&
                                  this.state["StockCard" + element.itemDefinition + "SelectItems"].map(i => (
                                    <Option key={i.id} value={i.id}>
                                      {i.name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={2}></Col>
                          <Col span={11}>
                            <Form.Item
                              name={element.itemDefinition + "Unit"}
                              rules={[
                                { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                              ]}
                            >
                              <Select disabled showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                {element.units &&
                                  element.units.map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    ))}
                </Card>
              </Col>

            </Row>


            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <Row gutter={[16, 16]}>
          <Col md={24}>
            <Space>
              <Button
                variant="primary"
                onClick={this.sendSelectModal.bind(this)}
                style={{ marginTop: "10px" }}
                disabled={Boolean(this.state.selectedRowKeys) && this.state.selectedRowKeys.length > 0 ? false : true}
                loading={this.state.loading}
              >
                <FormattedMessage id="PurchaseRequisitionNewCreate.SendSelected" defaultMessage="Send Selected" />
              </Button>
              <span style={{ marginLeft: 8 }}>
                {Boolean(this.state.selectedRowKeys) && this.state.selectedRowKeys.length > 0 ? `Selected ${selectedRowKeys.length} items` : ""}
              </span>
            </Space>
          </Col>
        </Row>


        {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
          <div className="alert alert-custom alert-light">
            <div className="alert-icon">
              <FilterOutlined />
            </div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (
                  <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                    <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                    <span>
                      <Tooltip title="Remove">
                        <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined
                  style={{ color: "crimson" }}
                  onClick={() =>
                    this.setState({ searchCriteriaModels: [] }, () => {
                      this.filterChangeItem([]);
                    })
                  }
                />
              </Tooltip>
            </div>
          </div>
        )}
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            rowKey={record => record.id}
            rowSelection={rowSelection}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Drawer
          title={<FormattedMessage id="PurchaseRequisitionNewCreate.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"PurchaseRequisitionNewCreate"} />
        </Drawer>

        <DraggableModal
          title={<FormattedMessage id="PurchaseRequisitionNewCreate.Item" defaultMessage="Item" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showItemReachSearchModal}
          onOk={() => this.formRefItem.current.submit()}
          onCancel={() => {
            this.setState({ showItemReachSearchModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveItem} onFinishFailed={onFinishFailed} ref={this.formRefItem}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ProjectTakeOff.type" defaultMessage="Type" />}
                    name="type"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Radio.Group>
                      <Radio value={"EQUIPMENT"}>{<FormattedMessage id="ProjectTakeOff.Equipment" defaultMessage="Equipment" />}</Radio>
                      <Radio value={"MATERIAL"}>{<FormattedMessage id="ProjectTakeOff.Material" defaultMessage="Material" />}</Radio>
                      <Radio value={"SPAREPART"}>{<FormattedMessage id="ProjectTakeOff.SparePart" defaultMessage="Spare Part" />}</Radio>
                    </Radio.Group>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="PurchaseRequisitionNewCreate.itemGroupId" defaultMessage="Item Group" />}
                    name="itemGroupId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                      onChange={value => {
                        this.onChangeItemGroupId(value);
                        this.setState({ itemGroupId: value }, () => {
                          this.onChangeItemGroupIdforNameCode();
                        });
                      }}
                    >
                      {Boolean(this.state["itemGroupIdSelectItems"]) &&
                        this.state["itemGroupIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="PurchaseRequisitionNewCreate.itemDescriptionId" defaultMessage="Item Description" />}
                    name="itemDescriptionId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select
                      showSearch
                      onChange={value => {
                        this.setState({ itemDescriptionId: value }, () => {
                          this.onChangeItemGroupIdforNameCode();
                        });
                      }}
                      allowClear
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                    >
                      {Boolean(this.state["itemDescriptionIdSelectItems"]) &&
                        this.state["itemDescriptionIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="PurchaseRequisitionNewCreate.materialTypeId" defaultMessage="Material Type" />}
                    name="materialTypeId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Cascader
                      onChange={value => {
                        this.setState({ materialTypeId: value }, () => {
                          this.onChangeItemGroupIdforNameCode();
                        });
                      }}
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      style={{ width: "100%" }}
                      options={this.state.nodes}
                    />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="PurchaseRequisitionNewCreate.NameCode" defaultMessage="Name/Code" />}
                    name="code"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                      {Boolean(this.state["itemIdSelectItems"]) &&
                        this.state["itemIdSelectItems"].length > 0 &&
                        this.state["itemIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }
              </Form>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="PurchaseRequisitionNewCreate.ProposedSupplier" defaultMessage="Proposed Supplier" />}
          centered
          width={1200}
          okButtonProps={{ hidden: true }}
          maskClosable={false}
          open={this.state.showProposedSupplierStatisticsModal}
          onCancel={() => {
            this.setState({ showProposedSupplierStatisticsModal: false });
          }}
          content={
            <Form
              initialValues={{ remember: false }}
              onFinish={this.saveProposedSupplier}
              onFinishFailed={onFinishFailed}
              ref={this.formRefProposedSupplier}
            >
              <Form.Item
                name="isVendor"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>
            </Form>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="PurchaseRequisitionNewCreate.CodeCriterias" defaultMessage="Code Criterias" />}
          centered
          width={1200}
          okButtonProps={{ hidden: true }}
          maskClosable={false}
          open={this.state.showCodeCriteriasModal}
          onCancel={() => {
            this.setState({ showCodeCriteriasModal: false });
          }}
          content={123}
        />

        <DraggableModal
          title={
            <span>
              <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="PurchaseRequisitionNewCreate.SendToRequest" defaultMessage="Send To Request" />
              </span>
            </span>
          }
          centered
          open={this.state.modalSendSelectShow}
          onOk={() => this.sendToRequest()}
          onCancel={() => this.setState({ modalSendSelectShow: false })}
          okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={<FormattedMessage id="GeneralMessageDoYouWantToProceedThisRequest" defaultMessage="Do you want to proceed this request?" />}
        />
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PurchaseRequisitionNewCreate);