import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../modules/Auth/_redux/authRedux";
import EditIcon from '@material-ui/icons/EditTwoTone';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PageviewIcon from '@material-ui/icons/Pageview';
import { Button, Modal } from 'react-bootstrap';
import { FilterOutlined } from '@ant-design/icons';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { handleRequest } from '../../ApiConnector';
import { API_BASE_ROOT } from '../../ApiConnector';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import CustomMaterialMenu from '../CustomMenu/CustomMaterialMenu';
import SettingsIcon from '@material-ui/icons/Settings'; 

import CreateIcon from '@material-ui/icons/AddCircle';
import ExportIcon from '@material-ui/icons/CloudDownloadRounded';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { Form, message, Table, Input, Select, Switch, Col, Row, DatePicker, Button as AntButton, InputNumber, Divider } from 'antd';
import { ExcelReader } from '../excel/ExcelReader';
import moment from 'moment';
import { Upload } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import ShareIcon from '@material-ui/icons/Share';
import { FaBullseye, FaLastfmSquare, FaLessThanEqual } from 'react-icons/fa';
import { string } from 'prop-types';
import { instanceOf } from 'prop-types';
import { TransmittalResponse } from './TransmittalResponse';
import { boolean } from 'yup';
import { LogDetail } from './LogDetail';
import { error,showError,showSuccess } from '../../MessageHelper';

const { Option } = Select;


export class TransmittalOfficial extends Component {

    constructor() {
        super();
        this.state = {

            modalDeleteShow: false,
            exportList: [],
            exportClick: false,
            showCodeModal: false,
            showSettingsModal: false,
            officialType:null,

            hideUpdate: true,
            hideSave: false,
            hiddenInputs: true,
            filePaths: [],
            fileList: [],
            showRevisedListModal: false,
            showLinkedListModal: false,
            revisedFileList: [],
            modalPdfViewer: false,
            pdfFile: "",
            codeName: "",
            drawingTypeSelectItems: [],
            designationSelectItems: [],
            departmentSelectItems: [],
            documentTypeSelectItems: [],
            actionTypeSelectItems: [],
            clientSelectItems: [],
            userSelectItems: [],
            locationSelectItems: [],

            materialEquipmentSelectItems: [],
            id: null,
            modalResponseShow: false,
            selectedRow: null,
            revising: false,

            disciplineId: null,
            departmentId: null,
            drawingTypeId: null,
            drawingSearch: null,
            linkDrawingList: [],
            linkedDrawingIds: [],
            dateOfSupersede: null,
            modalSupersedeAction: false,
            index: null,
            logDetailId: null,
            logFileName: null,
            logFilePath: null,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['2', '10', '25', '50', '100', '1000', '10000', '100000', '1000000'],
            },
            loading: false,
            sortField: "",
            sortOrder: "",
        };

        
    }

    formRef = React.createRef();

    componentDidMount = async () => {

        this.fillDesignations(); 
        this.initialize();
        

    };

    componentDidUpdate = (prevProps) => {

        let officialType = Boolean(this.props) && Boolean(this.props.officialType) ? this.props.officialType : null; 
        let prevOfficialType = this.state.officialType;  //Boolean(prevProps) && Boolean(prevProps.logId) ? prevProps.logId : null;

        console.log("officialType",officialType);

        if (prevOfficialType !== officialType) {
            this.initialize();
        }
    }



    initialize = async () => {

        let officialType = Boolean(this.props) && Boolean(this.props.officialType) ? this.props.officialType : null;        
       
      
        if (Boolean(officialType)) {
            this.setState({
                officialType: officialType          

            });          
        }

        const { pagination } = this.state;
        this.restartTable({ pagination });

    }

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hiddenInputs: true,
        });
        this.resetInputs();
    };

    resetInputs = () => {

        this.formRef.current.resetFields();

        this.setState({
            filePaths: [],
            fileList: [],

            id: null,
            logDetailId: null,
            revising: false,
            logFileName: null,
            logFilePath: null,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['2', '10', '25', '50', '100', '1000', '10000', '100000', '1000000'],
            },
        })
    }

    edit = (row) => {

      

        this.formRef.current.setFieldsValue({
            ...row,
            effectiveDate: moment(row.effectiveDate),
        });

        this.setState({
            hideUpdate: false,
            hideSave: true,
            hiddenInputs: false,
            id: row.id     
        });
    };

  

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }



    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        }
        var response = await handleRequest("DELETE", "/api/transmittalOfficials/" + deletedItem.id);

        if (response.type === "ERROR") {
            error(response);
        }
        else {
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.setState({
                id: null,
                modalDeleteShow: false
            });
        }
    }






    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters, });
    }

    restartTable = async (params = {}) => {

        this.setState({ loading: true });
        const newObj = this.createWhere(params);

        let url = "/api/transmittalOfficials/paging";
        const response = await handleRequest("POST", url, newObj);
        if (response.type === "ERROR") {
            error(response);
            this.setState({
                loading: false,
                tableList: [],
                pagination: {
                    ...params.pagination,
                    total: 0,
                },
                sortField: "",
                sortOrder: "",
            });
        }
        else {
            if (response.data.length !== 0) {
                let list = response.data.data;
                list.forEach((element, index) => {
                    element.sn = index + 1;
                });
                this.setState({
                    loading: false,
                    tableList: list,
                    pagination: {
                        ...params.pagination,
                        total: response.data.totalRecords,
                    },
                    sortField: params.sortField,
                    sortOrder: params.sortOrder,
                });
            } else {
                this.setState({
                    loading: false,
                    tableList: [],
                    pagination: {
                        ...params.pagination,
                        total: 0,
                    },
                    sortField: "",
                    sortOrder: "",
                });
            }
        }
    };

    onClickCreateNew = async () => {
        this.resetInputs();
        if (this.state.hiddenInputs) {
            this.setState({
                hiddenInputs: false,
                hiddenSave: false,
                hiddenUpdate: true,
            });
        }
        else {
            this.setState({
                hiddenInputs: true,
                hiddenSave: true,
                hiddenUpdate: false,
            });
        }
    }

    onClickSettings = async () => {

        this.setState({
            showSettingsModal: true
        });

    }

    save = async (values) => {

        const newItem = {
            ...values,
            id: this.state.id,
            officialType:this.state.officialType,
            effectiveDate: values.effectiveDate.toDate(),
        }

        if (newItem.id === null) {
            var response = await handleRequest("POST", "/api/transmittalOfficials", newItem);
        }
        else {

            response = await handleRequest("PUT", "/api/transmittalOfficials/" + newItem.id, newItem);
        }

        if (response.type === "ERROR") {
            error(response);
        }
        else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.cancel();
        }
    };

    createWhere(params) {
        var current = this.state.pagination.current;
        var pageSize = this.state.pagination.pageSize;
        var  sortField = "", sortOrder = "";
        if (Boolean(params) && Boolean(params.pagination)) {
            current = params.pagination.current;
            pageSize = params.pagination.pageSize;

            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }

        }
        const newObj = {
            ...params.pagination,
            officialType: Boolean(this.props) && Boolean(this.props.officialType) ? this.props.officialType : null ,
            firstResult: current - 1, 
            maxResults: pageSize,
            sortField: sortField,
            sortOrder: sortOrder
        };
        return newObj;
    }


    searchUserMail = async (email) => {
        if (Boolean(email) && email.length > 0) {
            var response = await handleRequest("GET", "/api/users/mails/" + email);
            if (response.type === "ERROR") {
                error(response);
            }
            else {
                this.setState({
                    userSelectItems: Boolean(response.data) ? response.data : []
                });
            }
        }
        else this.setState({
            userSelectItems: []
        });
    }

    addDesignation = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "designation"
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillDesignations();
            }
        } else {
            showError("Please enter designation");
        }
    };

    fillDesignations = async () => {
        const response = await handleRequest("GET", "/api/codes/type/designation");
        if (Boolean(response.data)) {
            this.setState({
                designationSelectItems: response.data
            });
        }
    }


    actionTemplate(row) {

        return <React.Fragment>
            <CustomMaterialMenu row={row} onDeleteRow={this.deleteModal.bind(this)}  onEditRow={this.edit.bind(this)} />
        </React.Fragment>;

    }



    handleExportExcel = async () => {

        this.setState({

            exportClick: true,
        });

    }

    ExcelDateToJSDate = (serial) => {

        if ((serial + "").includes(".")) {

            return moment(serial, "DD.MM.YYYY").toDate()
        }
        else {

            var hours = Math.floor((serial % 1) * 24);
            var minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
            return new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
        }

    }

    handleUploadExcel = async (excelJson) => {
        try {
            var list = [];
            if (excelJson === null)
                showError("Select an excel file");
            else {
                excelJson.forEach(element => {


                    const obj = {
                        date: this.ExcelDateToJSDate(element["Date"]),
                        disciplineStr: element["Discipline"],
                        drawingTypeStr: element["Drawing Type"],
                        drawingNo: element["Drawing No"],
                        shtNo: element["Sheet no"],
                        revNo: element["Rev no"],
                        index: element["Index"],
                        locationStr: element["Location"],
                        materialEquipmentStr: element["Material/Equipment"],
                        description: element["Description"],
                        status: element["Status"],
                        remarks: element["Remarks"]
                    }
                    list.push(obj);
                });
                if (Boolean(list) && list.length > 0) {
                    var response = await handleRequest("POST", "/api/transmittalOfficials/import", list);
                    if (response.type === "ERROR") {
                        showError("No records to be added");
                    }
                    else {
                        this.resetInputs();
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                        showSuccess();
                        this.cancel();
                    }
                } else {
                    showError("No records to be added");
                }
            }
        } catch (error) {
            showError("");
        }
    }


    searchUserMail = async (email) => {
        if (Boolean(email) && email.length > 0) {
            var response = await handleRequest("GET", "/api/users/mails/" + email);
            if (response.type === "ERROR") {
                error(response);
            }
            else {
                this.setState({
                    userSelectItems: Boolean(response.data) ? response.data : []
                });
            }
        }
        else this.setState({
            userSelectItems: []
        });
    }

    getUser = async (id) => {
        var list = [];
        if (Boolean(id)) {
            var response = await handleRequest("GET", "/api/users/" + id);
            if (response.type === "ERROR") {
                error(response);
            }
            else {
                list.push(response.data);
                this.setState({
                    userSelectItems: list
                });
            }
        }
        else this.setState({
            userSelectItems: []
        });
    }

    openCodeModal = (type) => {

        this.setState({
            showCodeModal: true,
            codeType: type,

        })

    }

    addCode = async () => {

        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: this.state.codeType
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: null,
                    showCodeModal: false
                });
                this.fillCode();
            }
        } else {
            showError("Please type value");
        }

    }

    fillCode = async () => {
        const { codeType } = this.state;

        const response = await handleRequest("GET", "/api/codes/type/" + codeType);
        if (Boolean(response.data)) {
            this.setState({
                [codeType + "SelectItems"]: response.data
            });
        }
    }


    render() {
        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout2 = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
        };

        const renderToolbar = (toolbarSlot) => {
            return (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexGrow: 1,
                            flexShrink: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.previousPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.nextPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomOutButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomPopover}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomInButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.downloadButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.moreActionsPopover}
                        </div>
                    </div>
                </div>
            );
        };

        const props = {
            action: `${API_BASE_ROOT}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        const layoutView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
        ) => {
            return defaultLayout(
                isSidebarOpened,
                container,
                main,
                toolbar(renderToolbar),
                sidebar,
            );
        };

      
        const columns = [
            {
                title: 'S/N', key: 'id', render: (value, row, index) => {
                    return index + 1 + (this.state.pagination.pageSize * (this.state.pagination.current - 1));
                }
            },

           
            {
                title: 'Designation', key: 'designationId', render: (record) => {
                    if (Boolean(record.designation)) {
                        return record.designation.name
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Designation"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    designationId: value,
                                    designationIdColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })

                                this.restartTable({ pagination : _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }

                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.designationSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                            ))}
                        </Select>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.designationIdColor) ? this.state.pagination.designationIdColor : "#bfbfbf" }} />,
                sorter: true,
            },

            
           

            {
                title: 'Name', key: 'name', render: (record) => {
                    return record.name
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Document Code"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    name: e.target.value,
                                    nameColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ pagination:  _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.nameColor) ? this.state.pagination.nameColor : "#bfbfbf" }} />,
                sorter: true,
            },

            {
                title: 'Contact No', key: 'contactNo', render: (record) => {
                    return record.contactNo
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Description"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    contactNo: e.target.value,
                                    contactNoColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ pagination : _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.contactNoColor) ? this.state.pagination.contactNoColor : "#bfbfbf" }} />,
                sorter: true,
            },
            {
                title: 'Email', key: 'email', render: (record) => {
                    return record.email
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Description"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    email: e.target.value,
                                    emailColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ pagination : _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.emailColor) ? this.state.pagination.emailColor : "#bfbfbf" }} />,
                sorter: true,
            },
            {
                title: 'Effective Date', key: 'effectiveDate', render: (record) => {
                    if (Boolean(record.effectiveDate)) {
                        return moment(record.effectiveDate).format("DD-MM-YYYY"); 
                    }
                },
                // filterDropdown: () => (
                //     <div style={{ padding: 8 }}>
                //         <Select
                //             showSearch
                //             optionFilterProp="children"
                //             style={{ width: "100%" }} placeholder="By"
                //             onChange={(value) => {
                //                 const { pagination } = this.state;
                //                 let _pagination = {
                //                     ...pagination,
                //                     byUserId: value,
                //                     byUserIdColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                //                 };
                //                 this.setState({

                //                     pagination: _pagination
                //                 })

                //                 this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                //             }
                //             }

                //         >
                //             <Option key={null} value={null}>Select</Option>
                //             {this.state.userSelectItems.map(i => (
                //                 <Option value={i.id}>{i.email}</Option>
                //             ))}
                //         </Select>

                //     </div>),
                // filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.byUserIdColor) ? this.state.pagination.byUserIdColor : "#bfbfbf" }} />,
                sorter: true,
            },

            

            {
                title: 'Actions', key: 'name', render: (record) => {
                    return this.actionTemplate(record);
                }
            },


        ];

       
        return (
            <div hidden={this.state.hideInputs} className="card card-custom" style={{ padding: '2rem' }}>

                <Form
                    initialValues={{ remember: false }}
                    onFinish={this.save}
                    onFinishFailed={onFinishFailed}
                    ref={this.formRef}>

                    <Row>
                        <Col md={1}>
                           
                        </Col>
                        <Col xs={20}></Col>

                        <Col md={1}>
                            {/* <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader> */}
                        </Col>
                        <Col xs={2}>
                            <AntButton hidden={!this.state.hiddenInputs} style={{ border: "0px" }} onClick={() => this.onClickCreateNew()} icon={<CreateIcon color="secondary" fontSize="large" />}></AntButton>
                            <AntButton hidden={this.state.hiddenInputs} style={{ border: "0px" }} onClick={() => this.onClickCreateNew()} icon={<RemoveCircle color="error" fontSize="large" />}></AntButton>
                        </Col>
                    </Row>

                    <div hidden={this.state.hiddenInputs}>


                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Name" />}
                                name="name"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type name" /> }]} >

                                <Input placeholder="Name" />

                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="Designation" defaultMessage="Designation" />}
                                name="designationId"
                                rules={[{ required: true, message: <FormattedMessage id="designationId" defaultMessage="This field is required" /> }]} >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }} placeholder="Designation" id="DefinitionsType"
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>

                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={() => this.openCodeModal("designation")}>
                                                    <PlusOutlined /> Add New
                                                </a>
                                            </div>
                                        </div>
                                    )}

                                >
                                    <Option key={null} value={null}>Select</Option>
                                    {this.state.designationSelectItems.map(i => (
                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Contact No" />}
                                name="contactNo"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type contact no" /> }]} >

                                <Input placeholder="Contact No" />

                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Email" />}
                                name="email"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type email" /> }]} >

                                <Input placeholder="Email" />

                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Effective Date" />}
                                name="effectiveDate"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please select date" /> }]} >

                                <DatePicker style={{ width: '100%' }} />

                            </Form.Item>
                        }


                        <div hidden={this.state.hideSave}>
                            {
                                <Row gutter={8}>
                                    <Col span={8}></Col>

                                    <Col span={8}>
                                        {
                                            <Button id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                        }
                                    </Col>
                                </Row>

                            }
                        </div>

                        <div hidden={this.state.hideUpdate}>
                            {
                                <Row gutter={8}>
                                    <Col span={8}></Col>

                                    <Col span={4}>
                                        <Button id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                                    </Col>
                                    <Col span={4}>
                                        <Button id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                                    </Col>

                                </Row>
                            }
                        </div>
                    </div>
                </Form>

                <div style={{ marginTop: '2rem', marginBottom: '1rem' }} className="content-section implementation">
                    <Table
                        bordered
                        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                        columns={columns}
                        dataSource={this.state.tableList}
                        loading={this.state.loading} onChange={this.handleTableChange}
                        pagination={this.state.pagination}
                    />
                </div>

                <Modal
                    show={this.state.showCodeModal}
                    onHide={() => {
                        this.setState({
                            showCodeModal: false
                        })
                    }}

                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> Add item to dropdown </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Input placeholder="Type Value" id="modalCodeName" value={this.state.codeName} onChange={(e) => this.setState({ codeName: e.target.value })} />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showCodeModal: false,
                                codeName: null
                            })
                        }}>Close</Button>

                        <Button variant="primary" onClick={this.addCode} > Save </Button>

                    </Modal.Footer>
                </Modal>

             <Modal
                    show={this.state.modalDeleteShow}
                    onHide={this.showOrHideDeleteModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</Button>
                        <Button variant="danger" onClick={this.delete} >Delete</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalResponseShow}
                    onHide={() => {
                        this.setState({
                            modalResponseShow: false
                        })
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="xl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"> Transmittal Response</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TransmittalResponse transmittalLogId={Boolean(this.state.selectedRow) ? this.state.selectedRow.id : null} ></TransmittalResponse>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="warning" onClick={() => {
                            this.setState({
                                modalResponseShow: false
                            });
                            const { pagination } = this.state;
                            this.restartTable({ pagination });
                        }} >Close</Button>

                    </Modal.Footer>
                </Modal>

                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            PDF  Viewer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                            <div style={{ height: "900px" }}>
                                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                            </div>
                        </Worker>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalPdfViewer}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {


    return {
        // auth: state.auth,
        logout: state.logout,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TransmittalOfficial)
// export default connect(null, auth.actions)(Domain);
