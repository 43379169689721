import React, { useEffect } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

function PresentationPage5(props) {
    const [companyName, setCompanyName] = React.useState(Boolean(props.companyName) ? props.companyName : null);

    useEffect(() => {
        //setLoadUrl(props.apiUrl);

        // const getData = async () => {
        //   await fillData(props.apiUrl);
        // };
        // getData().catch(console.error);
    }, []);

    return (
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-md-12">
                            <span className="headerText">Business Plan 2025</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div style={{
                                "font-family": "Public Sans Thin",
                                "font-size": "60px",
                                "margin-top": '100px'
                            }}>Our
                            </div>
                            <div style={{
                                "position": "relative",
                                "font-family": "Public Sans Thin",
                                "font-size": "60px",
                            }}>Business
                            </div>
                            <div style={{
                                "position": "relative",
                                "font-family": "Public Sans",
                                "font-size": "60px",
                                "font-weight": "bolder"
                            }}>Model
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <img src={toAbsoluteUrl("/media/presentation/middle1.png")} width="400px" height="100%" />
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-12">
                            <span className="headerText" style={{ "float": "right" }}>{companyName}</span>
                            <br />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style={{
                            "font-family": "Public Sans",
                            "font-size": "18px",
                        }}>
                            We sell affordable and sustainable workwear basics.
                            <br />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style={{
                            "font-family": "Public Sans",
                            "font-size": "15px",
                        }}>
                            Apply page animations and transitions to emphasize ideas and make them even more memorable. Find the magic and fun in presenting, too, by pressing C for confetti, D for a drumroll, and O for bubbles.

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PresentationPage5;
