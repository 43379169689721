import React, { useEffect, useState } from 'react';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import '../planning/components/Gantt/GanttOld.js';
import { handleRequest } from "../../ApiConnector.js";
import { error } from "../../MessageHelper.js";
import '../planning/components/Gantt/Gantt.css';
import { Modal as AntModal } from "antd";
import { FormattedMessage } from "react-intl";
import MaintenancePlanningGanttDetail from "./MaintenancePlanningGanttDetail";

const MaintenancePlanningGantt = () => {
    const [tasks, setTasks] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [modalAverageShow, setModalAverageShow] = useState(false);
    const [data, setData] = useState(null);
    const [random, setRandom] = useState(null);

    useEffect(() => {
        fetchTasks();
    }, []);

    const fetchTasks = async () => {
        var response = await handleRequest("GET", "/api/maintenancePlanning/getGanttChart/" + page);
        if (response.type === "ERROR") {
            error(response);
        } else {
            let data = [];
            let totalPages = 0;
            if (Boolean(response.data) && Boolean(response.data.data) && response.data.data.length > 0 && Boolean(response.data.data[0].data)) {
                data = response.data.data[0].data;
                totalPages = response.data.totalRecords;
            }

            // var data = [
            //     { id: 1, text: "Ana Görev 1", start_date: "2023-10-15", duration: 5 },
            //     { id: 2, text: "Alt Görev 1", start_date: "2023-10-16", duration: 3, parent: 1 },
            //     { id: 3, text: "Alt Görev 2", start_date: "2023-10-16", duration: 2, parent: 1 },
            //     // Diğer görevler buraya eklenebilir
            // ];

            setTasks(data)
            initializeGantt(data);
            setTotalPages(totalPages);
        }
    };

    // Görev üzerine tıklandığında modalı açacak fonksiyon
    function openTaskModal(taskId) {
        var modal = document.getElementById("taskModal");
        var closeModal = document.getElementById("closeModal");

        // Görev üzerine tıklandığında modalı aç
        if (modal && closeModal) {
            modal.style.display = "block";

            // Modal'ı kapatma işlemi
            closeModal.onclick = function () {
                modal.style.display = "none";
            };

            // Modal dışına tıklama işlemi
            window.onclick = function (event) {
                if (event.target == modal) {
                    modal.style.display = "none";
                }
            };
        }
    }

    // Gantt'ta görev üzerine tıklandığında modalı aç
    // gantt.attachEvent("onTaskClick", function (id, e) {
    //     openTaskModal(id); // Görev ID'sini kullanarak modalı aç
    // });

    // Görev üzerine tıklandığında alt görevleri açma veya kapatma
    // gantt.attachEvent("onTaskClick", function (id, e) {
    //     var task = gantt.getTask(id);

    //     if (e.target && e.target.classList.contains("gantt_tree_icon")) {
    //         if (task.$open) {
    //             gantt.close(id);
    //         } else if (task.$level) {
    //             gantt.open(id);
    //         }
    //     }
    // });

    gantt.attachEvent("onContextMenu", function (taskId, e) {
        if (taskId) {
            // Sağ tıklandığında belirli bir görevin detaylarını göster
            showTaskDetails(taskId);
        }
        return false; // Tarayıcıya sağ tıklama menüsünü engellemek için false döndürün
    });

    function showTaskDetails(taskId) {
        var task = gantt.getTask(taskId);
        // alert("Görev Adı: " + task.text + "\nBaşlangıç Tarihi: " + task.start_date + "\nBitiş Tarihi: " + task.end_date);

        console.log("task", task);
        if (Boolean(task) && Boolean(task.idList) && task.idList.length > 0) {
            let apartmentId = null;
            let apartmentSectionId = null;
            let apartmentRoomId = null;
            let roomQuantityId = null;
            let houseTypeId = null;
            let roomTypeId = null;
            let apartmentFloorId = null;
            let apartmentHouseId = null;
            let structureId = null;
            let subStructureId = null;
            let roomUnitId = null;
            let roomQuantityItemId = null;
            task.idList.forEach(element => {
                if (element.value === "Apartment") {
                    apartmentId = element.key;
                } else if (element.value === "ApartmentSection") {
                    apartmentSectionId = element.key;
                } else if (element.value === "ApartmentRoom") {
                    apartmentRoomId = element.key;
                } else if (element.value === "RoomQuantity") {
                    roomQuantityId = element.key;
                } else if (element.value === "ApartmentFloor") {
                    apartmentFloorId = element.key;
                } else if (element.value === "ApartmentHouse") {
                    apartmentHouseId = element.key;
                } else if (element.value === "HouseType") {
                    houseTypeId = element.key;
                } else if (element.value === "RoomType") {
                    roomTypeId = element.key;
                } else if (element.value === "RoomUnit") {
                    roomUnitId = element.key;
                } else if (element.value === "Structure") {
                    structureId = element.key;
                } else if (element.value === "SubStructure") {
                    subStructureId = element.key;
                } else if (element.value === "RoomQuantityItem") {
                    roomQuantityItemId = element.key;
                }
            });
            let data = {
                apartmentId: apartmentId,
                apartmentSectionId: apartmentSectionId,
                apartmentRoomId: apartmentRoomId,
                roomQuantityId: roomQuantityId,
                houseTypeId: houseTypeId,
                roomTypeId: roomTypeId,
                apartmentFloorId: apartmentFloorId,
                apartmentHouseId: apartmentHouseId,
                structureId: structureId,
                subStructureId: subStructureId,
                roomUnitId: roomUnitId,
                roomQuantityItemId: roomQuantityItemId,
            }
            setData(data);
            setRandom(Math.random());
            setTimeout(() => {
                setModalAverageShow(true);
            }, 1000);
        }
    }

    const initializeGantt = (data) => {
        gantt.config.branch_loading = true;

        gantt.init('gantt_maintenance_planning');
        gantt.parse({ data });
        gantt.attachEvent('onAfterTaskUpdate', handleTaskUpdate);
        gantt.attachEvent('onAfterTaskAdd', handleTaskAdd);
        gantt.attachEvent('onAfterTaskDelete', handleTaskDelete);

        // Yeni görev ekleme işlemini engelle
        gantt.attachEvent('onBeforeLightbox', handleBeforeLightbox);

        // Eşleştirme işlemini engellemek için olay dinleyicilerini ekle
        gantt.attachEvent('onAfterLinkAdd', handleAfterLinkAdd);
        gantt.attachEvent('onAfterLinkDelete', handleAfterLinkDelete);

        // Task'ların sürükleme işlemini engellemek için olay dinleyicisini ekle
        gantt.attachEvent('onBeforeTaskDrag', handleBeforeTaskDrag);

        gantt.attachEvent('onBeforeTaskDisplay', handleBeforeTaskDisplay);
    };

    const handleBeforeTaskDrag = (id, mode, event) => {
        // Task'ların sürükleme işlemini iptal et
        return false;
    };

    const handleBeforeTaskDisplay = (id, mode, event) => {
        var task = gantt.getTask(id);
        console.log("gantt", gantt);
        console.log("task", task);
        let taskNode = gantt.getTaskNode(id);
        if (taskNode !== null) {
            if (Boolean(task.idList) && task.idList.length > 0) {
                taskNode.style.backgroundColor = 'green';
            } else {
                taskNode.style.backgroundColor = 'lightblue';
            }
        }
        return true;
    };

    const handleAfterLinkAdd = (id, link) => {
        // Eşleştirme işlemini iptal et
        gantt.deleteLink(id);
    };

    const handleAfterLinkDelete = (id, link) => {
        // Eşleştirme işlemini yeniden ekle
        // Burada uygun bir işlem belirleyebilirsiniz
    };

    const handleBeforeLightbox = (id) => {
        return false;

        // if (gantt.getState().lightbox !== null) {
        //   // Eğer bir düzenleme işlemi açıksa yeni görev ekleme işlemini engelle
        //   return false;
        // }

        // // Kullanıcıya onay mesajı göstererek yeni görev ekleme işlemini engellemek için
        // const confirmResult = window.confirm('Yeni görev eklemek istediğinize emin misiniz?');
        // if (!confirmResult) {
        //   return false;
        // }

        // // Yeni görev ekleme işlemleri
        // // ...
        // return true;
    };

    const handleTaskUpdate = (id, task) => {
        // Güncellenen görevi sunucuya gönder
        fetch(`/api/tasks/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(task),
        })
            .then((response) => response.json())
            .then((data) => {
                // Sunucudan gelen cevabı işle
                // Örneğin, sunucu tarafında yapılan değişiklikler varsa güncelle
                fetchTasks();
            })
            .catch((error) => {
                console.error('Görev güncelleme hatası:', error);
            });
    };

    const handleTaskAdd = (id, task) => {
        // Yeni görevi sunucuya gönder
        fetch('/api/tasks', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(task),
        })
            .then((response) => response.json())
            .then((data) => {
                // Sunucudan gelen cevabı işle
                // Örneğin, sunucu tarafında yapılan değişiklikler varsa güncelle
                fetchTasks();
            })
            .catch((error) => {
                console.error('Görev ekleme hatası:', error);
            });
    };

    const handleTaskDelete = (id) => {
        // Silinen görevi sunucuya gönder
        fetch(`/api/tasks/${id}`, {
            method: 'DELETE',
        })
            .then((response) => response.json())
            .then((data) => {
                // Sunucudan gelen cevabı işle
                // Örneğin, sunucu tarafında yapılan değişiklikler varsa güncelle
                fetchTasks();
            })
            .catch((error) => {
                console.error('Görev silme hatası:', error);
            });
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
            fetchTasks();
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
            fetchTasks();
        }
    };

    const modalDetailHide = () => {
        setModalAverageShow(false);
    };

    return <div>
        {Boolean(tasks) &&
            <>
                <div id="gantt_maintenance_planning" style={{ width: '100%', height: '600px' }} />
                {/* <div>
                    <button onClick={handlePrevPage} disabled={page === 1}>
                        Önceki Sayfa
                    </button>
                    <span>{`Sayfa ${page} / ${totalPages}`}</span>
                    <button onClick={handleNextPage} disabled={page === totalPages}>
                        Sonraki Sayfa
                    </button>
                </div> */}

                <AntModal
                    title={<FormattedMessage id="MaintenancePlanning.Detail" defaultMessage="Detail" />}
                    closable="false"
                    open={modalAverageShow}
                    okButtonProps={{ hidden: true }}
                    cancelButtonProps={{ hidden: true }}
                    onCancel={() => {
                        setModalAverageShow(false);
                    }}
                    width={1000}
                    centered
                >
                    <MaintenancePlanningGanttDetail random={random} data={data} modalDetailHide={modalDetailHide} />
                </AntModal>
            </>
        }
    </div>;
};

export default MaintenancePlanningGantt;