import { FilterOutlined, GiftOutlined, MinusCircleOutlined, PlusOutlined, SaveOutlined, UploadOutlined, ZoomInOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { ArrowRightOutlined } from "@ant-design/icons";
import {
  Button as AntButton,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Table,
  Tag,
  Typography,
  Upload,
  Badge, Drawer, Tooltip
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import store from "../../../redux/store";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import { GetDynamicFormItemsToHideForNonDynamicPage } from "../../Components/ElementUtils";
import PaymentMethodComponent, {
  GetFormValuesFromPaymentList,
  GetPaymentListFromFormValues,
  GetPaymentStateObject
} from "../../Components/PaymentMethodComponent";
import { error, showError, showInfo, showSuccess, showWarning } from "../../MessageHelper";
import InvoiceMenu from "../CustomMenu/InvoiceMenu";
import { ExcelReader } from "../excel/ExcelReader";
import { ProductList } from "./ProductList";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";
import SettingsIcon from "@material-ui/icons/Settings";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import DraggableModal from "../../Components/DraggableModal";

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title } = Typography;

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class Invoice extends Component {
  constructor() {
    super();
    this.state = {
      formName: "InvoiceLog",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      showProductListModal: false,
      invoiceProductList: [],
      invoiceReportList: [],
      showInvoiceDetailModal: false,
      showSalableProductListModal: false,
      selectedProducts: [],
      discountRate: null,
      discountValue: null,
      discountRateVat: null,
      discountValueVat: null,
      hasVat: true,
      vatValue: 15,
      grandTotal: 0,
      modalPdfViewer: false,
      isFreeProduct: false,
      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      customerIdSelectItems: [],
      pdfFile: "",
      user: store.getState().auth.user,
      showReceivePaymentModal: false,
      showReturnInvoiceModal: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      selectedSalableProductKeys: [],
      salableProductlist: [],
      saveLoading: false,
      refreshProductListWhenChange: false,
      receiveTypeItems: [
        { value: "CASH", label: "Cash" },
        { value: "TRANSFER", label: "Transfer" }
      ],
      receiveTypeAddNewItems: [
        { value: "NOTE", label: "Note" },
        { value: "LC", label: "LC" },
        { value: "CHEQUES", label: "Cheques" },
        { value: "DBS", label: "DBS" }
      ],
      addNewIndex: 0,
      maxAmount: 0,
    };
  }

  formRef = React.createRef();
  formAddCustomerRef = React.createRef();
  formReceivePayment = React.createRef();
  formProceedWith = React.createRef();

  paymentTypeItems = [
    { value: "CASH", label: "Cash" },
    { value: "TRANSFER", label: "Transfer" },
    { value: "CREDIT", label: "Credit" }
  ];

  componentDidMount = async () => {
    const { pagination, formName } = this.state;
    let data = await GetDynamicFormItemsToHideForNonDynamicPage(formName);
    this.setState({
      ...data
    });

    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsecustomerId = await handleRequest("GET", "/api/invoice/customerIdSelectItems");
    if (responsecustomerId.type === "ERROR") {
      error(responsecustomerId);
    } else {
      this.setState({
        customerIdSelectItems: Boolean(responsecustomerId.data) ? responsecustomerId.data : []
      });
    }

    var responseSalesman = await handleRequest("GET", "/api/invoice/salesman");
    if (responseSalesman.type === "ERROR") {
      error(responseSalesman);
    } else {
      if (this.state.user.role === "SALESMAN") {
        if (Boolean(responseSalesman.data) && responseSalesman.data.length > 0) {
          this.setState({
            salesmanSelectItems: responseSalesman.data.filter(p => p.key === this.state.user.id),
          });
        } else {
          this.setState({
            salesmanSelectItems: []
          });
        }
      } else {
        this.setState({
          salesmanSelectItems: Boolean(responseSalesman.data) ? responseSalesman.data : []
        });
      }
    }
  };

  fillSalesmanProducts = async () => {
    if (this.state.user.role === "SALESMAN") {
      let response = await handleRequest("GET", "/api/invoice/waitList/" + this.state.user.id);
      if (response.type === "ERROR") {
        error(response);
      } else if (response.data.length > 0) {
        let products = response.data.map(element => {
          return {
            id: element.inventoryId,
            materialName: element.inventory,
            arabicName: element.arabicName,
            quantity: element.quantity,
            relatedReleaseId: element.id
          };
        });
        this.setState({
          salableProductlist: products
        });
      } else {
        showWarning("No products available for you to sale");
        this.productMessages = [
          {
            variant: "warning",
            text: "No products available for you to sale"
          }
        ];
        this.setState({
          selectedProducts: []
        });
      }
    }
  };
  calculateTotal = () => {
    const { hasVat, vatValue, selectedProducts } = this.state;
    let total = 0;
    let grandTotal = 0;

    if (Boolean(selectedProducts) && selectedProducts.length > 0) {
      selectedProducts.forEach(element => {
        if (Boolean(element.price)) {
          grandTotal = grandTotal + element.price;
        }
      });
      grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;

      if (grandTotal > 0) {
        if (hasVat && Boolean(vatValue)) {
          total = (grandTotal * 100) / (100 + vatValue);
          total = Math.round((total + Number.EPSILON) * 100) / 100;
        } else if (!hasVat) {
          this.setState({ vatValue: null });
          total = grandTotal;
          total = Math.round((total + Number.EPSILON) * 100) / 100;
        }

        this.setState({
          grandTotal: grandTotal,
          total: total,
          totalVat: Math.round((grandTotal - total + Number.EPSILON) * 100) / 100,
          discountRate: null,
          discountValue: null,
          discountRateVat: null,
          discountValueVat: null
        });

        this.formRef.current.setFieldsValue({
          grandTotal: grandTotal,
          total: total,
          totalVat: Math.round((grandTotal - total + Number.EPSILON) * 100) / 100,
          discountRate: null,
          discountValue: null,
          discountRateVat: null,
          discountValueVat: null,
          totalDiscount: null,
          payment1: { amount: grandTotal }
        });
      }
    } else {
      this.setState({
        grandTotal: 0,
        total: 0,
        totalVat: 0,
        discountRate: null,
        discountValue: null,
        discountRateVat: null,
        discountValueVat: null
      });
      this.formRef.current.setFieldsValue({
        grandTotal: 0,
        total: 0,
        totalVat: 0,
        discountRate: null,
        discountValue: null,
        discountRateVat: null,
        discountValueVat: null,
        totalDiscount: null,
        payment1: { amount: 0 }
      });
    }
  };

  calculateDiscountVat = () => {
    const { hasVat, vatValue, selectedProducts, discountValue, discountValueVat, discountRateVat } = this.state;
    let total = 0;
    let grandTotal = 0;
    let tempGrandTotal = 0;
    let tempDiscountValueVat = 0;
    let tempDiscountRateVat = 0;

    if (Boolean(selectedProducts) && selectedProducts.length > 0) {
      selectedProducts.forEach(element => {
        if (Boolean(element.price)) {
          grandTotal = grandTotal + element.price;
          tempGrandTotal = tempGrandTotal + element.price;
        }
      });

      grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
      tempGrandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
    }

    if (grandTotal > 0) {
      if (hasVat && Boolean(vatValue)) {
        total = (grandTotal * 100) / (100 + vatValue);
        total = Math.round((total + Number.EPSILON) * 100) / 100;
      } else if (!hasVat) {
        this.setState({ vatValue: null });
        total = grandTotal;
        total = Math.round((total + Number.EPSILON) * 100) / 100;
      }

      if (Boolean(discountValue)) {
        total = total - discountValue;
        total = Math.round((total + Number.EPSILON) * 100) / 100;
        grandTotal = hasVat && Boolean(vatValue) ? total + (total * vatValue) / 100 : total;
        grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
      }

      if (Boolean(discountValueVat)) {
        tempDiscountValueVat = discountValueVat;
        tempDiscountRateVat = (discountValueVat * 100) / grandTotal;
        grandTotal = grandTotal - tempDiscountValueVat;
        grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
        total = hasVat && Boolean(vatValue) ? (grandTotal * 100) / (100 + vatValue) : grandTotal;
        total = Math.round((total + Number.EPSILON) * 100) / 100;
      } else if (Boolean(discountRateVat)) {
        tempDiscountRateVat = discountRateVat;
        tempDiscountValueVat = (grandTotal * discountRateVat) / 100;
        grandTotal = grandTotal - tempDiscountValueVat;
        grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
        total = hasVat && Boolean(vatValue) ? (grandTotal * 100) / (100 + vatValue) : grandTotal;
        total = Math.round((total + Number.EPSILON) * 100) / 100;
      }

      this.setState({
        grandTotal: grandTotal,
        total: total,
        totalVat: Math.round((grandTotal - total + Number.EPSILON) * 100) / 100,
        discountValueVat: Math.round((tempDiscountValueVat + Number.EPSILON) * 100) / 100,
        discountRateVat: Math.round((tempDiscountRateVat + Number.EPSILON) * 1000) / 1000
      });

      this.formRef.current.setFieldsValue({
        grandTotal: grandTotal,
        total: total,
        totalDiscount: Math.round((grandTotal - tempGrandTotal + Number.EPSILON) * 100) / 100,
        totalVat: Math.round((grandTotal - total + Number.EPSILON) * 100) / 100,
        discountValueVat: Math.round((tempDiscountValueVat + Number.EPSILON) * 100) / 100,
        discountRateVat: Math.round((tempDiscountRateVat + Number.EPSILON) * 1000) / 1000,
        payment1: { amount: grandTotal }
      });
    }
  };

  calculateDiscount = () => {
    const { hasVat, vatValue, selectedProducts, discountRate, discountValue } = this.state;
    let total = 0;
    let grandTotal = 0;
    let tempGrandTotal = 0;
    let tempDiscountValue = 0;
    let tempDiscountRate = 0;

    if (Boolean(selectedProducts) && selectedProducts.length > 0) {
      selectedProducts.forEach(element => {
        if (Boolean(element.price)) {
          grandTotal = grandTotal + element.price;
          tempGrandTotal = tempGrandTotal + element.price;
        }
      });

      grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
      tempGrandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;

      if (grandTotal > 0) {
        if (hasVat && Boolean(vatValue)) {
          total = (grandTotal * 100) / (100 + vatValue);
          total = Math.round((total + Number.EPSILON) * 100) / 100;
        } else if (!hasVat) {
          this.setState({ vatValue: null });
          total = grandTotal;
          total = Math.round((total + Number.EPSILON) * 100) / 100;
        }

        if (Boolean(discountValue)) {
          tempDiscountValue = discountValue;
          tempDiscountRate = (discountValue * 100) / total;
          total = total - tempDiscountValue;
          total = Math.round((total + Number.EPSILON) * 100) / 100;
          grandTotal = hasVat && Boolean(vatValue) ? total + (total * vatValue) / 100 : total;
          grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
        } else if (Boolean(discountRate)) {
          tempDiscountRate = discountRate;
          tempDiscountValue = (total * discountRate) / 100;
          total = total - tempDiscountValue;
          total = Math.round((total + Number.EPSILON) * 100) / 100;
          grandTotal = hasVat && Boolean(vatValue) ? total + (total * vatValue) / 100 : total;
          grandTotal = Math.round((grandTotal + Number.EPSILON) * 100) / 100;
        }

        this.setState({
          grandTotal: grandTotal,
          total: total,
          totalVat: Math.round((grandTotal - total + Number.EPSILON) * 100) / 100,
          discountValue: Math.round((tempDiscountValue + Number.EPSILON) * 100) / 100,
          discountRate: Math.round((tempDiscountRate + Number.EPSILON) * 1000) / 1000,
          discountValueVat: null,
          discountRateVat: null
        });

        this.formRef.current.setFieldsValue({
          grandTotal: grandTotal,
          total: total,
          totalVat: Math.round((grandTotal - total + Number.EPSILON) * 100) / 100,
          discountValue: Math.round((tempDiscountValue + Number.EPSILON) * 100) / 100,
          discountRate: Math.round((tempDiscountRate + Number.EPSILON) * 1000) / 1000,
          totalDiscount: Math.round((grandTotal - tempGrandTotal + Number.EPSILON) * 100) / 100,

          discountValueVat: null,
          discountRateVat: null,
          payment1: { amount: grandTotal }
        });
      }
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  showCustomerModal = () => {
    console.log("showCustomerModal");
    this.setState({
      showAddCustomerModal: true
    });
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.formRef.current.setFieldsValue({
      hasVat: true
    });

    this.setState({
      id: null,
      discountRate: null,
      hasVat: true,
      vatValue: 15,
      selectedProducts: [],
      totalVat: null,
      selectedInvoice: null,
      showReceivePaymentModal: false,
      showReturnInvoiceModal: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,

        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: [],
      grandTotal: 0
    });
  };

  receivePaymentModal = row => {
    if (Boolean(row.invoiceProductList)) {
      row.invoiceProductList.forEach(element => {
        if (Boolean(element.product)) element.name = element.product.materialName;
      });

      this.setState({
        selectedProducts: row.invoiceProductList.map(element => {
          return { ...element, quantity_available: element.quantity, oldUnitPrice: element.unitPrice };
        })
      });
    }
    let paymentObj = GetFormValuesFromPaymentList(row.invoicePaymentList);

    let paymentStateObj = GetPaymentStateObject(paymentObj);

    let typeSelectItems = [];
    if (Boolean(row.invoicePaymentList) && row.invoicePaymentList.length > 0) {
      row.invoicePaymentList.forEach(element => {
        if (Boolean(element.paymentMethodType) && (element.paymentMethodType === "CREDIT" || element.paymentMethodType === "CHEQUES")) {
          typeSelectItems.push({
            "key": element.paymentMethodType,
            "value": element.paymentMethodType + " / " + element.amount,
            "amount": element.amount,
          });
        }
      });
    }

    this.setState(
      {
        showReceivePaymentModal: true,
        typeSelectItems: typeSelectItems,
        selectedInvoice: row,
        id: row.id,
        discountRate: row.discountRate,
        discountValue: row.discountValue,
        discountRateVat: row.discountRateVat,
        discountValueVat: row.discountValueVat,
        vatValue: row.vatValue,
        grandTotal: row.grandTotal,
        paymentStateObj: paymentStateObj,
        receiveTypeItems: [
          { value: "CASH", label: "Cash" },
          { value: "TRANSFER", label: "Transfer" }
        ],
        addNewIndex: 0,
        maxAmount: 0,
      },
      () => {
        this.formReceivePayment.current.resetFields();
      }
    );
  };


  returnInvoiceModal = row => {
    if (Boolean(row.invoiceProductList)) {
      row.invoiceProductList.forEach(element => {
        if (Boolean(element.product)) element.name = element.product.materialName;
      });

      this.setState({
        selectedProducts: row.invoiceProductList.map(element => {
          return { ...element, quantity_available: element.quantity, oldUnitPrice: element.unitPrice };
        })
      });
    }
    let paymentObj = GetFormValuesFromPaymentList(row.invoicePaymentList);

    let paymentStateObj = GetPaymentStateObject(paymentObj);

    this.setState(
      {
        showReturnInvoiceModal: true,
        originalIssuedInvoiceVisible: true,
        customersReturnInvoiceVisible: true,
        selectedInvoice: row,
        id: row.id,
        discountRate: row.discountRate,
        discountValue: row.discountValue,
        discountRateVat: row.discountRateVat,
        discountValueVat: row.discountValueVat,
        vatValue: row.vatValue,
        grandTotal: row.grandTotal,
        paymentStateObj: paymentStateObj
      },
      () => {
        setTimeout(() => {
          this.formProceedWith.current.setFieldsValue({
            ...paymentObj
          });
        }, 1500);
      }
    );
  };



  edit = row => {
    document.getElementById("kt_scrolltop").click();

    if (Boolean(row.invoiceProductList)) {
      row.invoiceProductList.forEach(element => {
        if (Boolean(element.product)) element.name = element.product.materialName;
      });

      this.setState({
        selectedProducts: row.invoiceProductList.map(element => {
          return { ...element, quantity_available: element.quantity, oldUnitPrice: element.unitPrice };
        })
      });
    }
    let paymentObj = GetFormValuesFromPaymentList(row.invoicePaymentList);

    let paymentStateObj = GetPaymentStateObject(paymentObj);
    this.formRef.current.setFieldsValue({
      ...row,
      ...paymentObj,
    });

    this.setState(
      {
        hideUpdate: false,
        hideSave: true,
        hideInputs: false,
        id: row.id,
        discountRate: row.discountRate,
        discountValue: row.discountValue,
        discountRateVat: row.discountRateVat,
        discountValueVat: row.discountValueVat,
        vatValue: row.vatValue,
        grandTotal: row.grandTotal,
        paymentStateObj: paymentStateObj
      },
      () => {
        //this.calculateDiscountVat();
      }
    );
  };

  deleteModal = row => {
    console.log("deleteModal", row);
    this.setState({
      modalDeleteShow: true,
      id: row.id,
      deleteReason: row.remarks
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow,
      deleteReason: ""
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    let obj = {
      remarks: this.state.deleteReason
    };
    console.log("reason", obj);
    var response = await handleRequest("POST", "/api/invoice/delete/" + deletedItem.id, obj);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        deleteReason: "",
        modalDeleteShow: false
      });
    }
  };
  initPage = async params => {
    console.log("AAA", this.props);
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();

    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      if (Boolean(this.props.selectedPO)) {
        this.setState({
          invoice: this.props.selectedPO.invoice,
          delivery: this.props.selectedPO.delivery,
          requiredDocuments: this.props.selectedPO.requiredDocuments
        });

        let invoiceFileUrl = [];
        let fileUrlInvoice = Boolean(this.props.selectedPO.invoiceFileUrl) ? this.props.selectedPO.invoiceFileUrl.split(",") : [];
        if (Boolean(fileUrlInvoice) && fileUrlInvoice.length > 0) {
          fileUrlInvoice.forEach((element, index) => {
            let obj = {
              uid: index,
              name: element.split("download/")[1],
              url: element,
              thumbUrl: element
            };
            invoiceFileUrl.push(obj);
          });
        }

        let deliveryFileUrl = [];
        let fileUrlDelivery = Boolean(this.props.selectedPO.deliveryFileUrl) ? this.props.selectedPO.deliveryFileUrl.split(",") : [];
        if (Boolean(fileUrlDelivery) && fileUrlDelivery.length > 0) {
          fileUrlDelivery.forEach((element, index) => {
            let obj = {
              uid: index,
              name: element.split("download/")[1],
              url: element,
              thumbUrl: element
            };
            deliveryFileUrl.push(obj);
          });
        }

        if (
          Boolean(this.props.selectedPO) &&
          Boolean(this.props.selectedPO.requiredDocuments) &&
          this.props.selectedPO.requiredDocuments.length > 0
        ) {
          this.props.selectedPO.requiredDocuments.forEach((element, index) => {
            var fileUrl = [];
            if (Boolean(element.fileUrl) && element.fileUrl.length > 0) {
              let obj = {
                uid: index,
                name: element.fileUrl.split("download/")[1],
                url: element.fileUrl,
                thumbUrl: element.fileUrl
              };
              fileUrl.push(obj);
            }
            element.fileUrl = fileUrl;
          });
        }

        this.formRef.current.setFieldsValue({
          ...this.props.selectedPO,
          invoiceFileUrl: invoiceFileUrl,
          deliveryFileUrl: deliveryFileUrl,
          invoiceDate: Boolean(this.props.selectedPO.invoiceDate) ? moment(this.props.selectedPO.invoiceDate) : null,
          deliveryDate: Boolean(this.props.selectedPO.deliveryDate) ? moment(this.props.selectedPO.deliveryDate) : null
        });
      } else {
        this.formRef.current.setFieldsValue({
          quantity: Boolean(this.props.selectedPO) ? this.props.selectedPO.quantity : null,
          unitPrice: Boolean(this.props.selectedPO) ? this.props.selectedPO.unitPrice : null,
          oldUnitPrice: Boolean(this.state.unitPrice) ? this.state.unitPrice : null
        });
      }
    }
  };

  addSelectedProducts = async selectedRows => {
    const { selectedProducts, isFreeProduct } = this.state;
    let data = selectedRows;
    let invoiceProductList = [...selectedProducts];
    data.forEach(element => {
      let obj = {};
      obj.id = null;
      obj.productId = element.id;
      obj.name = Boolean(element.producDetail) ? element.producDetail.name : element.materialName;
      obj.quantity = element.quantity;
      obj.quantity_available = element.quantity;
      obj.unitPrice = Boolean(element.productDetail) ? element.productDetail.sellingPrice : element.sellingPrice;
      obj.oldUnitPrice = obj.unitPrice;
      obj.price = obj.quantity * obj.unitPrice;
      if (Boolean(isFreeProduct)) {
        obj.unitPrice = 0;
        obj.price = 0;
        obj.quantity = 1;
      }
      invoiceProductList.push(obj);
    });
    showSuccess();
    this.setState(
      {
        selectedProducts: invoiceProductList,
        showProductListModal: false,
        discountRate: null,
        discountValue: null,
        discountRateVat: null,
        discountValueVat: null
      },
      () => {
        this.calculateTotal();
      }
    );
  };

  addSelectedSalesmanProducts = async () => {
    const { selectedProducts, selectedSalableProductKeys, salableProductlist, isFreeProduct } = this.state;

    let data = salableProductlist.filter(item => selectedSalableProductKeys.includes(item.relatedReleaseId));
    var response = await handleRequest(
      "POST",
      "/api/invoice/selectedproducts",
      data.map(item => item.id)
    );
    var response2 = await handleRequest("POST", "/api/invoice/soldProductsByRelease", selectedSalableProductKeys);

    if (response.type === "ERROR" || response2.type === "ERROR") {
      error(response);
    } else {
      let products = response.data;
      // let sellings = response2.data;
      let invoiceProductList = [...selectedProducts];
      data.forEach(element => {
        let obj = {};
        let p = products.filter(item => item.id === element.id)[0];
        // let i = sellings.filter(
        //   (item) => item.relatedReleaseId === element.relatedReleaseId
        // )[0];
        // element.quantity = Boolean(i)
        //   ? element.quantity - i.quantity
        //   : element.quantity;
        if (Boolean(p)) {
          obj.id = null;
          obj.productId = element.id;
          obj.name = Boolean(p.producDetail) ? p.producDetail.name : p.materialName;
          obj.quantity = element.quantity;
          obj.quantity_available = element.quantity;
          obj.unitPrice = Boolean(p.productDetail) ? p.productDetail.sellingPrice : p.sellingPrice;
          obj.oldUnitPrice = obj.unitPrice;
          obj.price = obj.quantity * obj.unitPrice;
          obj.relatedReleaseId = element.relatedReleaseId;
          if (Boolean(isFreeProduct)) {
            obj.unitPrice = 0;
            obj.price = 0;
            obj.quantity = 1;
          }
          invoiceProductList.push(obj);
        }
      });
      showSuccess();
      this.setState(
        {
          selectedProducts: invoiceProductList,
          showSalableProductListModal: false,
          discountRate: null,
          discountValue: null,
          discountRateVat: null,
          discountValueVat: null
        },
        () => {
          this.calculateTotal();
        }
      );
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/invoice/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = Boolean(response.data.data) ? response.data.data : response.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  saveCustomer = async values => {
    let newItem = {
      customer: true,
      ...values
    };

    var response = await handleRequest("POST", "/api/vendor", newItem);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.fillComboboxes();
      this.setState({
        showAddCustomerModal: false
      });
      showSuccess();
    }
  };

  save = async values => {
    this.setState({ saveLoading: true });
    var chequeLog = [];
    if (Boolean(this.state.chequeLog)) {
      chequeLog.push(this.state.chequeLog);
    }
    const newItem = {
      ...values,
      id: this.state.id,
      invoiceProductList: this.state.selectedProducts,
      hasVat: this.state.hasVat,
      vatValue: this.state.vatValue,
      chequeLog: chequeLog
    };

    newItem.invoicePaymentList = GetPaymentListFromFormValues(newItem);
    console.log("newItem", newItem);

    if (Boolean(newItem.invoiceProductList) && newItem.invoiceProductList.length > 0) {
      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/invoice", newItem);
      } else {
        response = await handleRequest("PUT", "/api/invoice/" + newItem.id, newItem);
      }

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else showError("Please add at least one product to invoice");
    this.setState({ saveLoading: false, refreshProductListWhenChange: !this.state.refreshProductListWhenChange });
  };

  saveReceivePayment = async values => {
    this.setState({ saveLoading: true });

    const newItem = {
      invoiceId: this.state.id,
      ...values,
    };

    if (newItem.paymentMethodType === newItem.receiveFor) {
      showInfo("The payment method must be chosen differently.");
    } else {
      var response = await handleRequest("PUT", "/api/invoice/receivePayment", newItem);

      if (response.type === "ERROR") {
        error(response);
        this.setState({ saveLoading: false });
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.setState({ saveLoading: false });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    let tempPaymentList = null;
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
      if (Boolean(params.pagination.invoicePaymentList)) {
        tempPaymentList = params.pagination.invoicePaymentList.map(item => {
          return { paymentMethodType: item };
        });
      }
    }
    const newObj = {
      ...params.pagination,
      invoicePaymentList: tempPaymentList,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/invoice/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = row => {
    let actions = {
      row: row,
      onReportRow: this.showThermalReport.bind(this)
    };
    if (row.active && this.state.user.role !== "SALESMAN") {
      actions.onEditRow = this.edit.bind(this);
    }
    if (Boolean(this.state.user) && (this.state.user.role === "COMPANY_ADMIN" || this.state.user.role === "ADMIN") && row.active) {
      actions.onDeleteRow = this.deleteModal.bind(this);
    }
    if (row.active && this.state.user.role !== "SALESMAN") {
      actions.onReturnInvoiceRow = this.returnInvoiceModal.bind(this);
    }

    if (
      Boolean(row.invoicePaymentList) &&
      row.invoicePaymentList.length > 0 &&
      (row.invoicePaymentList.findIndex(p => p.paymentMethodType === "CREDIT") >= 0 ||
        row.invoicePaymentList.findIndex(p => p.paymentMethodType === "CHEQUES") >= 0)
    ) {
      actions.onReceivePayment = this.receivePaymentModal.bind(this);
    }

    return (
      <React.Fragment>
        <InvoiceMenu {...actions} />
      </React.Fragment>
    );
  };

  getImageGalleryFromUrls = (paths, galleryName) => {
    if (Boolean(paths) && paths.length > 0) {
      let pathList = paths.split(",");
      // for (let i = 0; i < pathList.length; i++) {
      //   images.push(<Image key={i} width={80} src={pathList[i].trim()} />);
      // }

      return (
        <>
          {Boolean(paths) && paths.length > 0 ? (
            <Image
              width={80}
              preview={{
                visible: false
              }}
              src={pathList[0].trim()}
              onClick={() => this.setState({ [galleryName]: true })}
            />
          ) : null}
          {Boolean(pathList) && pathList.length > 0 ? (
            <div style={{ display: "none" }}>
              <Image.PreviewGroup
                preview={{
                  visible: this.state[galleryName],
                  onVisibleChange: vis => this.setState({ [galleryName]: vis })
                }}
              >
                {pathList.map((field, i) => (
                  <Image key={i} src={field} />
                ))}
              </Image.PreviewGroup>
            </div>
          ) : null}
        </>
      );
    }
  };

  add = () => {
    this.setState({
      showProductListModal: true,
      isFreeProduct: false,
      random: Math.random(),
    });
  };

  showInvoiceProducts = row => {
    console.log(row);
    this.setState({
      invoiceProductList: row.invoiceProductList,
      showInvoiceDetailModal: true
    });
  };

  remove = index => {
    let list = this.state.selectedProducts;

    list.splice(index, 1);

    this.setState(
      {
        selectedProducts: list
      },
      this.calculateTotal
    );
  };

  showReport = record => {
    console.log(record);
    if (Boolean(record.invoicePath))
      try {
        const fileRealUrl = record.invoicePath;
        this.setState({
          pdfFile: fileRealUrl,
          modalPdfViewer: true
        });
      } catch (error) {
        alert("File cant found.");
      }
    else showError("Invoice path could not find !");
  };

  generateReport65 = async row => {
    let url = "/api/invoice/thermalReport65/" + row.id;

    this.showThermalReportFromUrl(url);
  };

  generateReport75 = async row => {
    let url = "/api/invoice/thermalReport75/" + row.id;

    this.showThermalReportFromUrl(url);
  };

  generateReport85 = async row => {
    let url = "/api/invoice/thermalReport85/" + row.id;

    this.showThermalReportFromUrl(url);
  };

  showThermalReportFromUrl = async url => {
    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });

      showError("There is no created report for selected item");
    }
  };

  showThermalReport = record => {
    if (Boolean(record.thermalInvoicePath))
      try {
        const fileRealUrl = record.thermalInvoicePath;

        this.setState({
          pdfFile: fileRealUrl,
          modalPdfViewer: true
        });
      } catch (error) {
        alert("File cant found.");
      }
    else showError("Invoice path could not find !");
  };

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer
    });
  };

  onSalableProductSelectChange = (selectedRowKeys, selectedRows) => {
    let selectedRowKeysState = this.state.selectedSalableProductKeys;
    selectedRowKeysState = [...selectedRowKeys];

    this.setState({ selectedSalableProductKeys: selectedRowKeysState });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          allowClear
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
        />
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  saveChequeLogCustomer = chequeLog => {
    this.setState({
      chequeLog: chequeLog
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 8,
          offset: 0
        },
        sm: {
          span: 8,
          offset: 8
        }
      }
    };
    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout3 = {
      labelCol: { span: 0 },
      wrapperCol: { span: 23 }
    };

    const columnsInvoiceProducts = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },

      {
        title: <FormattedMessage id="Invoice.productName" defaultMessage="Product Name" />,
        key: "productName",
        width: 100,
        render: record => {
          if (Boolean(record.product)) return record.product.materialName;
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.materialPhotoPaths" defaultMessage="Product Photos" />,
        key: "materialPhotoPaths",
        width: 100,
        render: record => {
          return this.getImageGalleryFromUrls(record.product.materialPhotoPaths, "galleryInvoicePhotos" + record.id);
        }
      },
      {
        title: <FormattedMessage id="Invoice.quantity" defaultMessage="Quantity" />,
        key: "invoiceNumber",
        width: 100,
        render: record => {
          return record.quantity;
        }
      },
      {
        title: <FormattedMessage id="Invoice.unitPrice" defaultMessage="List Unit Price" />,
        key: "invoiceNumber",
        width: 100,
        render: record => {
          return record.unitPrice;
        }
      },
      {
        title: <FormattedMessage id="Invoice.price" defaultMessage="List Price" />,
        key: "invoiceNumber",
        width: 100,
        render: record => {
          return record.price;
        }
      },
      {
        title: <FormattedMessage id="Invoice.salesUnitPrice" defaultMessage="Sales Unit Price" />,
        key: "salesUnitPrice",
        width: 100,
        render: record => {
          return record.salesUnitPrice;
        }
      },
      {
        title: <FormattedMessage id="Invoice.salesPrice" defaultMessage="Sales Price" />,
        key: "salesPrice",
        width: 100,
        render: record => {
          return record.salesPrice;
        }
      }
    ];

    const columns = [
      {
        key: "key",
        width: 100,
        title: <FormattedMessage id="General.TitleActions" defaultMessage="Actions" />,
        render: this.action
      },

      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        width: 100,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="Invoice.invoiceNo" defaultMessage="Invoice Number" />,
        key: "invoiceNo",
        width: 100,
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline"
                }}
                onClick={() => this.showReport(record)}
              >
                {record.invoiceNo}
              </div>
            </React.Fragment>
          );
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.invoiceNo) ? this.state.pagination.invoiceNo : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  invoiceNo: e.target.value,
                  invoiceNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.invoiceNoColor) ? this.state.pagination.invoiceNoColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="General.LastUpdate" defaultMessage="Last Update" />,
        key: "lastUpdated",
        width: 100,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={async (dates, dateStrings) => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  lastUpdateDate: dates,
                  lastUpdatedColor: Boolean(dates) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.lastUpdatedColor) ? this.state.pagination.lastUpdatedColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Invoice.salesmanId" defaultMessage="Salesman" />,
        key: "salesmanId",
        width: 100,
        render: record => {

          return record.salesmanName;
        },

        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Salesman"
              id="DefinitionsType"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  salesmanId: value,
                  salesmanIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              {Boolean(this.state["salesmanSelectItems"]) && this.state["salesmanSelectItems"].length > 0 &&
                this.state["salesmanSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          </div>
        ),

      },
      {
        title: <FormattedMessage id="Invoice.invoiceProductList" defaultMessage="Products" />,
        key: "invoiceNumber",
        width: 100,
        render: record => {
          return (
            <AntButton
              style={{ border: "0px", alignContent: "center" }}
              type="text"
              onClick={() => this.showInvoiceProducts(record)}
              icon={<ZoomInOutlined style={{ fontSize: "20px", color: "#0b5fcc" }} />}
            />
          );
        }
      },
      {
        title: <FormattedMessage id="Invoice.customerId" defaultMessage="Customer" />,
        key: "customerId",
        width: 100,
        render: record => {
          if (Boolean(record.vendor))
            if (Boolean(record.vendor.arabicName) && Boolean(record.vendor.name) && !this.state.hide_customerArabicName)
              return record.vendor.arabicName + " / " + record.vendor.name;
            else if (Boolean(record.vendor.arabicName) && !this.state.hide_customerArabicName) return record.vendor.arabicName;
            else if (Boolean(record.vendor.name)) return record.vendor.name;
            else return "";
        },
        filterDropdown: () => (
          <div style={{ padding: 8, width: "400px" }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Customer"
              id="DefinitionsType"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  customerId: value,
                  customerIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.customerIdSelectItems.map(i => (
                <Option key={i.key} value={i.key}>
                  {Boolean(i.value) ? i.value : i.description}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.customerIdColor) ? this.state.pagination.customerIdColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Invoice.discountValue" defaultMessage="Discount" />,
        key: "discountValue",
        width: 100,
        render: record => {
          return record.totalDiscount;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.totalDiscount) ? this.state.pagination.totalDiscount : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  current: 1,
                  totalDiscount: e.target.value,
                  totalDiscountColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.totalDiscountColor) ? this.state.pagination.totalDiscountColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Invoice.grandTotal" defaultMessage="Total" />,
        key: "grandTotal",
        width: 100,
        render: record => {
          return record.grandTotal;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.grandTotal) ? this.state.pagination.grandTotal : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  current: 1,
                  grandTotal: e.target.value,
                  grandTotalColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.grandTotalColor) ? this.state.pagination.grandTotalColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Invoice.invoicePaymentList" defaultMessage="Payment" />,
        key: "invoicePaymentList",
        width: 100,
        className: this.state.hide_invoicePaymentList ? "d-none" : "",
        render: record => {
          let a = record.invoicePaymentList.map(item => {
            return (
              <>
                <Tag key={item.id} color={item.paymentMethodType === "CASH" ? "green" : item.paymentMethodType === "TRANSFER" ? "blue" : "red"}>
                  {item.paymentMethodType} : {item.amount}
                </Tag>
              </>
            );
          });

          return a;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={Boolean(this.state.pagination.invoicePaymentList) ? this.state.pagination.invoicePaymentList : null}
              onChange={async list => {
                console.log(list);

                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  current: 1,
                  invoicePaymentList: list,
                  invoicePaymentListColor: Boolean(list) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            >
              {this.paymentTypeItems.map(item => {
                return (
                  <Row key={item.value}>
                    <Checkbox value={item.value}>
                      <Tag color={item.value === "CASH" ? "green" : item.value === "TRANSFER" ? "blue" : "red"}>{item.label}</Tag>
                    </Checkbox>
                  </Row>
                );
              })}
            </Checkbox.Group>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.invoicePaymentListColor) ? this.state.pagination.invoicePaymentListColor : "#bfbfbf"
            }}
          />
        )
      },
      {
        title: <FormattedMessage id="Invoice.remarks" defaultMessage="Remarks" />,
        key: "remarks",
        width: 100,
        render: record => {
          return record.remarks;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.remarks) ? this.state.pagination.remarks : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  current: 1,
                  remarks: e.target.value,
                  remarksColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.remarksColor) ? this.state.pagination.remarksColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Invoice.active" defaultMessage="Status" />,
        key: "active",
        width: 100,
        render: record => {
          return record.active ? (
            <p className="text-success">
              <FormattedMessage id="Invoice.active" defaultMessage="Active" />
            </p>
          ) : (
            <p className="text-danger">
              <FormattedMessage id="Invoice.deleted" defaultMessage="Deleted" />
            </p>
          );
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              style={{ flex: "auto" }}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  current: 1,
                  active: e.target.value === "true" ? true : e.target.value === "false" ? false : undefined,
                  activeColor: Boolean(e.target.value === "true") ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            >
              <Radio.Button>
                <FormattedMessage id="Invoice.all" defaultMessage="All" />
              </Radio.Button>
              <Radio.Button value="true">
                <FormattedMessage id="Invoice.active" defaultMessage="Active" />
              </Radio.Button>
              <Radio.Button value="false">
                <FormattedMessage id="Invoice.deleted" defaultMessage="Deleted" />
              </Radio.Button>
            </Radio.Group>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.remarksColor) ? this.state.pagination.remarksColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Invoice.DontShowDiscountOnInvoice" defaultMessage="Don't show discount on invoice" />,
        key: "dontShowDiscountOnInvoice",
        width: 100,
        ellipsis: true,
        align: "center",
        render: record => {
          return record.dontShowDiscountOnInvoice ? (
            <p className="text-success">
              <FormattedMessage id="Invoice.Yes" defaultMessage="Yes" />
            </p>
          ) : (
            <p className="text-danger">
              <FormattedMessage id="Invoice.No" defaultMessage="No" />
            </p>
          );
        },
      }
    ];

    const columnsSalableProducts = [
      {
        title: <FormattedMessage id="Invoice.sn" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="Invoice.productArabicName" defaultMessage="Product Name" />,
        key: "arabicName",
        width: 100,
        dataIndex: "arabicName",
        render: (value, row, index) => {
          return value;
        }
      },
      {
        title: <FormattedMessage id="Invoice.productName" defaultMessage="Product Name" />,
        key: "materialName",
        width: 100,
        dataIndex: "materialName",
        render: (value, row, index) => {
          return value;
        }
      },

      {
        title: <FormattedMessage id="Invoice.productQuantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        dataIndex: "quantity"
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };

      const newColumnsSalableProducts = [...columnsSalableProducts];
      newColumnsSalableProducts[index] = {
        ...newColumnsSalableProducts[index],
        width: size.width
      };

      const newColumnsInvoiceProducts = [...columnsInvoiceProducts];
      newColumnsInvoiceProducts[index] = {
        ...newColumnsInvoiceProducts[index],
        width: size.width
      };

      this.setState({
        columns: newColumns,
        columnsSalableProducts: newColumnsSalableProducts,
        columnsInvoiceProducts: newColumnsInvoiceProducts
      });
    };

    const mergeColumnsInvoiceProducts =
      Boolean(this.state.columnsInvoiceProducts) && this.state.columnsInvoiceProducts.length > 0
        ? this.state.columnsInvoiceProducts.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columnsInvoiceProducts) &&
        columnsInvoiceProducts.length > 0 &&
        columnsInvoiceProducts.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const mergeColumnsSalableProducts =
      Boolean(this.state.columnsSalableProducts) && this.state.columnsSalableProducts.length > 0
        ? this.state.columnsSalableProducts.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columnsSalableProducts) &&
        columnsSalableProducts.length > 0 &&
        columnsSalableProducts.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    const uploadProps = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };
    const { selectedSalableProductKeys } = this.state;
    const rowSalableProductSelection = {
      selectedSalableProductKeys,
      onChange: this.onSalableProductSelectChange
    };
    return (
      <Badge.Ribbon
        text={<FormattedMessage id={"invoice.pageTitle"} defaultMessage={"Invoice"} />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form
            initialValues={{
              remember: false,
              vatValue: 15,
              payment1: {
                paymentMethodType: "CASH"
              }
            }}
            onFinish={this.save}
            onFinishFailed={onFinishFailed}
            ref={this.formRef}
          >
            <Row gutter={[16, 16]}>
              <Col md={20}></Col>
              <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
              <Col md={1}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <Tooltip title={<FormattedMessage id="RequestForQuotation.Settings" defaultMessage="Settings" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.setState({
                          drawerSettingsShow: true
                        });
                      }}
                      icon={<SettingsIcon color="error" fontSize="large" />}
                    ></AntButton>
                  </Tooltip>
                )}
              </Col>
            </Row>

            <div hidden={this.state.hideInputs}>
              {Boolean(this.state.user) && (
                <Title justify="center" level={4}>
                  {this.state.user.name + " " + this.state.user.surname}
                </Title>
              )}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Invoice.customerName" defaultMessage="Customer" />}
                  name="customerId"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    placeholder="Customer"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8
                          }}
                        >
                          <AntButton type="link" icon={<PlusOutlined />} onClick={this.showCustomerModal}>
                            <FormattedMessage id="Invoice.addNewCustomer" defaultMessage=" Add New Customer" />
                          </AntButton>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["customerIdSelectItems"]) &&
                      this.state["customerIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {Boolean(i.value) ? i.value : i.description}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  hidden={this.state.hide_customerArabicName}
                  label={<FormattedMessage id="Invoice.customerArabicName" defaultMessage="عميل" />}
                  name="customerId"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    placeholder="عميل"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8
                          }}
                        >
                          <AntButton type="link" icon={<PlusOutlined />} onClick={this.showCustomerModal}>
                            <FormattedMessage id="Invoice.إضافة عميل جديد" defaultMessage=" إضافة عميل جديد" />
                          </AntButton>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["customerIdSelectItems"]) &&
                      this.state["customerIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.description}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {this.state.user.role !== "SALESMAN" && (
                <>
                  <Form.Item {...layout} label={<FormattedMessage id="Invoice.addProductToInvoice" defaultMessage="Add Products" />}>
                    <AntButton onClick={this.add}>
                      <FormattedMessage id="Invoice.addProductToInvoice" defaultMessage="Add Product to Invoice" />
                    </AntButton>
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    hidden={this.state.hide_freeProcductList}
                    tooltip={
                      <FormattedMessage
                        id="Invoice.addFreeProductToInvoiceWhatIs"
                        defaultMessage="These are the products that will be given free of charge, as a giveaway or with a campaign. It appears on the invoice as zero price."
                      />
                    }
                    label={<FormattedMessage id="Invoice.addFreeProductToInvoice" defaultMessage="Add Free Products" />}
                  >
                    <AntButton
                      type="dashed"
                      icon={<GiftOutlined />}
                      onClick={() => {
                        this.setState({
                          showProductListModal: true,
                          isFreeProduct: true,
                          random: Math.random(),
                        });
                      }}
                    >
                      <FormattedMessage id="Invoice.addFreeProductToInvoice" defaultMessage="Add Free Product to Invoice" />
                    </AntButton>
                  </Form.Item>
                </>
              )}
              {this.state.user.role === "SALESMAN" && (
                <>
                  <Form.Item {...layout} label={<FormattedMessage id="Invoice.addProductToInvoice" defaultMessage="Add Products" />}>
                    <AntButton
                      onClick={() => {
                        this.setState({
                          showSalableProductListModal: true,
                          isFreeProduct: false
                        }, () => {
                          this.fillSalesmanProducts();
                        });
                      }}
                    >
                      <FormattedMessage id="Invoice.addProductToInvoice" defaultMessage="Add Product to Invoice" />
                    </AntButton>
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    tooltip={
                      <FormattedMessage
                        id="Invoice.addFreeProductToInvoiceWhatIs"
                        defaultMessage="These are the products that will be given free of charge, as a giveaway or with a campaign. It appears on the invoice as zero price."
                      />
                    }
                    label={<FormattedMessage id="Invoice.addFreeProductToInvoice" defaultMessage="Add Free Products" />}
                  >
                    <AntButton
                      type="dashed"
                      icon={<GiftOutlined />}
                      onClick={() => {
                        this.setState({
                          showSalableProductListModal: true,
                          isFreeProduct: true
                        }, () => {
                          this.fillSalesmanProducts();
                        });
                      }}
                    >
                      <FormattedMessage id="Invoice.addFreeProductToInvoice" defaultMessage="Add Free Product to Invoice" />
                    </AntButton>
                  </Form.Item>
                </>
              )}
              {this.state.selectedProducts.map((product, index) => {
                var color = product.price === 0 ? "warning" : "";

                var usedQuantity = this.state.selectedProducts
                  .filter((p, i) => i !== index && p.productId === product.productId)
                  .map(p => p.quantity)
                  .reduce((a, b) => a + b, 0);
                var maxQuantity = product.quantity_available - (Boolean(usedQuantity) ? usedQuantity : 0);

                return (
                  <Form.Item key={index} {...layout2} label={product.name} validateStatus={color}>
                    {
                      <Form.Item
                        {...layout3}
                        extra="Quantity"
                        style={{
                          display: "inline-block",
                          width: "calc(30%)",
                          marginBottom: "5px"
                        }}
                      >
                        <InputNumber
                          placeholder="Quantity"
                          style={{ width: "100%" }}
                          min={0}
                          max={maxQuantity}
                          value={product.quantity}
                          onChange={value => {
                            let list = this.state.selectedProducts;

                            list[index].quantity = value;
                            list[index].price =
                              list[index].unitPrice !== undefined || list[index].unitPrice !== "" ? list[index].unitPrice * value : null;
                            this.setState(
                              {
                                selectedProducts: list
                              },
                              this.calculateTotal
                            );
                          }}
                        />
                      </Form.Item>
                    }

                    {
                      <Form.Item
                        {...layout3}
                        extra="Unit Price"
                        style={{
                          display: "inline-block",
                          width: "calc(30%)",
                          marginBottom: "5px"
                        }}
                      >

                        {Boolean(product.oldUnitPrice) && product.oldUnitPrice > 0 && product.unitPrice !== product.oldUnitPrice ? (
                          <Badge showZero count={product.oldUnitPrice}>
                            <InputNumber
                              readOnly={this.state.user.email === "first1gulf@gmail.com" ? false : true}
                              placeholder="Unit Price"
                              style={{ width: "100%" }}
                              min={0}
                              value={product.unitPrice}
                              onChange={value => {
                                let list = this.state.selectedProducts;

                                list[index].unitPrice = value;
                                list[index].price = Boolean(list[index].quantity) ? list[index].quantity * value : null;
                                this.setState(
                                  {
                                    selectedProducts: list,
                                  },
                                  this.calculateTotal
                                );
                              }}
                            />
                          </Badge>
                        ) : (
                          <InputNumber
                            readOnly={this.state.user.email === "first1gulf@gmail.com" ? false : true}
                            placeholder="Unit Price"
                            style={{ width: "100%" }}
                            min={0}
                            value={product.unitPrice}
                            onChange={value => {
                              let list = this.state.selectedProducts;

                              list[index].unitPrice = value;
                              list[index].price = Boolean(list[index].quantity) ? list[index].quantity * value : null;
                              this.setState(
                                {
                                  selectedProducts: list,
                                },
                                this.calculateTotal
                              );
                            }}
                          />
                        )}
                      </Form.Item>
                    }

                    {
                      <Form.Item
                        extra="Price"
                        style={{
                          display: "inline-block",
                          width: "calc(30%)",
                          marginBottom: "5px"
                        }}
                      >
                        <InputNumber readOnly placeholder="Price" style={{ width: "100%" }} min={0} value={product.price} />
                      </Form.Item>
                    }

                    <MinusCircleOutlined
                      style={{
                        // marginLeft: "10px",
                        display: "inline-block",
                        width: "calc(9%)",
                        fontSize: "20px",
                        color: "#f64e60",
                        verticalAlign: "0px"
                      }}
                      onClick={() => this.remove(index)}
                    />
                  </Form.Item>
                );
              })}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Invoice.SubTotal" defaultMessage="Sub Total" />}
                  name="total"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} placeholder="Total" min={0} disabled={true} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Invoice.discountOnSubtotal" defaultMessage="Discount on Subtotal" />}
                  style={{ marginBottom: "5px" }}
                  hidden={this.state.hide_discountRate && this.state.hide_discountValue}
                >
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item extra="Discount Rate" name="discountRate" hidden={this.state.hide_discountRate}>
                        <InputNumber
                          formatter={value => `${value}%`}
                          parser={value => value.replace("%", "")}
                          style={{
                            width: "100%"
                          }}
                          min={0}
                          onChange={value => {
                            this.setState(
                              {
                                discountRate: value,
                                discountValue: null
                              },
                              this.calculateDiscount
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item extra="Discount" name="discountValue" hidden={this.state.hide_discountValue}>
                        <InputNumber
                          style={{
                            width: "100%"
                          }}
                          onChange={value => {
                            this.setState(
                              {
                                discountValue: value,
                                discountRate: null
                              },
                              this.calculateDiscount
                            );
                          }}
                          min={0}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              }
              {
                <Form.Item {...layout} label={<FormattedMessage id="Invoice.totalVat" defaultMessage="Vat (%15)" />}>
                  {this.state.totalVat}
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Invoice.discountOnGrandTotal" defaultMessage="Discount on Grandtotal" />}
                  hidden={this.state.hide_discountRateVat && this.state.hide_discountValueVat}
                >
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item extra="Discount Rate" name="discountRateVat" hidden={this.state.hide_discountRateVat}>
                        <InputNumber
                          formatter={value => `${value}%`}
                          parser={value => value.replace("%", "")}
                          style={{
                            width: "100%"
                          }}
                          min={0}
                          onChange={value => {
                            this.setState(
                              {
                                discountRateVat: value,
                                discountValueVat: null
                              },
                              this.calculateDiscountVat
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item extra="Discount" name="discountValueVat" hidden={this.state.hide_discountValueVat}>
                        <InputNumber
                          style={{
                            width: "100%"
                          }}
                          onChange={value => {
                            this.setState(
                              {
                                discountValueVat: value,
                                discountRateVat: null
                              },
                              this.calculateDiscountVat
                            );
                          }}
                          min={0}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  hidden={true}
                  label={<FormattedMessage id="Invoice.hasVat" defaultMessage="Has Vat" />}
                  name="hasVat"
                  valuePropName="checked"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Checkbox
                    checked={this.state.hasVat}
                    onChange={e => {
                      this.setState({ hasVat: e.target.checked }, this.calculateTotal);
                    }}
                  ></Checkbox>
                </Form.Item>
              }{" "}
              {this.state.hasVat && (
                <Form.Item
                  {...layout}
                  hidden={true}
                  label={<FormattedMessage id="Invoice.vatValue" defaultMessage="Vat" />}
                  name="vatValue"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <InputNumber
                    // defaultValue={15}
                    placeholder="Vat"
                    formatter={value => `${value}%`}
                    parser={value => value.replace("%", "")}
                    min={0}
                    onChange={value => {
                      this.setState({ vatValue: value }, this.calculateTotal);
                    }}
                  />
                </Form.Item>
              )}{" "}
              {
                <Form.Item {...layout} label={<FormattedMessage id="Invoice.TotalDiscount" defaultMessage="Total Discount" />} name="totalDiscount">
                  <InputNumber style={{ width: "100%", color: "red" }} disabled={true} placeholder="Total Discount" min={0} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Invoice.DontShowDiscountOnInvoice" defaultMessage="Don't show discount on invoice" />}
                  name="dontShowDiscountOnInvoice"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="checked"
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Invoice.grandTotal" defaultMessage="GrandTotal" />}
                  name="grandTotal"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} disabled={true} placeholder="GrandTotal" min={0} />
                </Form.Item>
              }{" "}
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Invoice.remarks" defaultMessage="Remarks" />}
                  name="remarks"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <TextArea placeholder="Remarks" style={{ width: "100%" }} />
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Invoice.salesman" defaultMessage="Salesman" />}
                  style={{ marginBottom: 0 }}
                  name="salesmanId"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Select allowClear showSearch optionFilterProp="children" style={{ width: "100%" }}>
                    {Boolean(this.state["salesmanSelectItems"]) && this.state["salesmanSelectItems"].length > 0 &&
                      this.state["salesmanSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              <br />

              {!this.state.hide_invoicePaymentList && (
                <PaymentMethodComponent
                  saveChequeLogCustomer={this.saveChequeLogCustomer}
                  invoiceId={this.state.id}
                  chequeOwnerCustomerId={
                    Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(this.formRef.current.getFieldValue("customerId"))
                      ? this.formRef.current.getFieldValue("customerId")
                      : -1
                  }
                  grandTotal={this.state.grandTotal}
                  paymentStateObj={this.state.paymentStateObj}
                  formRef={this.formRef.current}
                  user={this.state.user}
                />
              )}
              <div hidden={this.state.hideSave}>
                {
                  <Form.Item {...tailFormItemLayout}>
                    <AntButton
                      id="SaveButton"
                      htmlType="submit"
                      type="primary"
                      style={{ width: "100%" }}
                      className="btn btn-success"
                      icon={<SaveOutlined style={{ verticalAlign: "1px", marginRight: "5px" }} />}
                      loading={this.state.saveLoading}
                    >
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </AntButton>
                  </Form.Item>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <AntButton id="CancelButton" type="primary" style={{ width: "100%" }} className="btn btn-default" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </AntButton>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <AntButton
                        id="UpdateButton"
                        htmlType="submit"
                        type="primary"
                        style={{ width: "100%" }}
                        className="btn btn-warning"
                        loading={this.state.saveLoading}
                      >
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </AntButton>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              rowKey="id"
              rowClassName={(record, index) => (record.active ? "" : "text-danger")}
              scroll={{ x: 1500, scrollToFirstRowOnChange: true }}
              components={{
                header: {
                  cell: ResizableTitle
                }
              }}
              columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
              <p>
                <TextArea placeholder="Delete Reason" value={this.state.deleteReason} onChange={e => this.setState({ deleteReason: e.target.value })} />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <DraggableModal
            title="Product List"
            closable={true}
            maskClosable={false}
            centered
            visible={this.state.showProductListModal}
            onCancel={() => {
              this.setState({
                showProductListModal: false
              });
            }}
            okButtonProps={{ style: { display: "none" } }}
            width={1000}
            content={
              <ProductList addSelectedProducts={this.addSelectedProducts.bind(this)}
                random={this.state.random}
                refreshWhenChange={this.state.refreshProductListWhenChange} />
            }
          />

          <Modal
            show={this.state.showInvoiceDetailModal}
            onHide={() => {
              this.setState({
                showInvoiceDetailModal: false
              });
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="Invoice.invoiceProductList " defaultMessage="Invoice Product List " />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table
                bordered
                rowKey="id"
                scroll={{ x: 1000, scrollToFirstRowOnChange: true }}
                components={{
                  header: {
                    cell: ResizableTitle
                  }
                }}
                columns={Boolean(mergeColumnsInvoiceProducts) && mergeColumnsInvoiceProducts.length > 0 ? mergeColumnsInvoiceProducts : columns}
                dataSource={this.state.invoiceProductList}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showInvoiceDetailModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.showAddCustomerModal}
            onHide={() => {
              this.setState({
                showAddCustomerModal: false
              });
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="Invoice.addNewCustomer" defaultMessage=" Add New Customer" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form initialValues={{ remember: false }} onFinish={this.saveCustomer} onFinishFailed={onFinishFailed} ref={this.formAddCustomerRef}>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Vendor.name" defaultMessage="Name" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Vendor.arabicName" defaultMessage="Arabic Name" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="arabicName"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Vendor.vatNumber" defaultMessage="Vat Number" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="vatNumber"
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Vendor.CR" defaultMessage="CR" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="cr"
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Vendor.phone" defaultMessage="Phone" />}
                  style={{
                    marginBottom: 0
                  }}
                  name="phone"
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>

                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button
                      id="CancelButton"
                      style={{ width: "100%" }}
                      variant="secondary"
                      onClick={() => {
                        this.formAddCustomerRef.current.resetFields();
                        this.setState({
                          showAddCustomerModal: false
                        });
                      }}
                    >
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showAddCustomerModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            </Modal.Footer>
          </Modal>

          <DraggableModal
            title={<FormattedMessage id="Invoice.receivePayment" defaultMessage=" Receive Payment" />}
            keyboard={false}
            maskClosable={false}
            open={this.state.showReceivePaymentModal}
            onCancel={() => {
              this.setState({ showReceivePaymentModal: false });
            }}
            onOk={() => {
              this.formReceivePayment.current.submit();
            }}
            okButtonProps={{ hidden: false }}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            width={800}
            centered
            content={
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveReceivePayment}
                onFinishFailed={onFinishFailed}
                ref={this.formReceivePayment}
              >
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Invoice.OutstandingPayment" defaultMessage="Outstanding Payment" />}
                    name="receiveFor"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Select allowClear
                      showSearch
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      onChange={value => {
                        let maxAmount = 0;
                        const { typeSelectItems } = this.state;
                        if (Boolean(value) && Boolean(typeSelectItems) && typeSelectItems.length > 0) {
                          let type = typeSelectItems.find(p => p.key === value);
                          if (Boolean(type)) {
                            maxAmount = type.amount;
                          }
                        }
                        this.setState({
                          maxAmount: maxAmount
                        });
                        this.formReceivePayment.current.setFieldsValue({
                          amount: null,
                        });
                      }}
                    >
                      {Boolean(this.state["typeSelectItems"]) && this.state["typeSelectItems"].length > 0 &&
                        this.state["typeSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                }

                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Invoice.amount" defaultMessage="Amount" />}
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <InputNumber min={1} max={this.state.maxAmount}></InputNumber>
                  </Form.Item>
                }

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Invoice.paymentMethod" defaultMessage="Payment Method" />}
                  style={{ marginBottom: "5px" }}
                >
                  <Row gutter={8}>
                    <Col span={20}>
                      <Form.Item
                        name="paymentMethodType"
                        rules={[
                          {
                            required: true,
                            message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                          }
                        ]}
                      >
                        <Radio.Group
                          value={this.state.paymentMethodType}
                          options={this.state.receiveTypeItems}
                          onChange={e => {
                            this.setState({
                              paymentMethodType: e.target.value
                            });
                          }}
                          optionType="button"
                          buttonStyle="solid"
                        ></Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <AntButton
                        onClick={() => {
                          const { receiveTypeItems, receiveTypeAddNewItems, addNewIndex } = this.state;
                          if (receiveTypeAddNewItems.length > addNewIndex) {
                            receiveTypeItems.push(receiveTypeAddNewItems[addNewIndex]);

                            this.setState({
                              receiveTypeItems: receiveTypeItems,
                              addNewIndex: addNewIndex + 1
                            });
                          }
                        }}
                        style={{ border: "0px", alignContent: "center" }}
                        type="primary"
                        shape="circle"
                        icon={<ArrowRightOutlined style={{ fontSize: "20px", color: "#0b5fcc" }} />}
                      ></AntButton>
                    </Col>
                  </Row>
                </Form.Item>

                <Col span={24} style={{ marginBottom: 8 }} hidden={this.state.paymentMethodType !== "TRANSFER"}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Invoice.TransferBankName" defaultMessage="Bank" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="transferBankTypeId"
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Bank Name"
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8
                            }}
                          >
                            <Input
                              value={this.state.codeName}
                              style={{ flex: "auto" }}
                              onChange={e => {
                                this.setState({
                                  codeName: e.target.value
                                });
                              }}
                            />
                            <Button type="link" icon={<PlusOutlined />} onClick={this.addTransferBankIdType}>
                              <FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" />
                            </Button>
                          </div>
                        </div>
                      )}
                    >
                      {Boolean(this.state["transferBankTypeIdSelectItems"]) &&
                        this.state["transferBankTypeIdSelectItems"].map(i => (
                          <Option key={i.id} value={i.id}>
                            {i.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Invoice.TransferIbanNo" defaultMessage="Account/IBAN No" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="transferIbanNo"
                  >
                    <Input style={{ width: "100%" }} placeholder="Account/IBAN No" />
                  </Form.Item>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Invoice.TransferDate" defaultMessage="Date" />}
                    style={{
                      marginBottom: 0
                    }}
                    name={"transferDate"}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item {...layout} label={<FormattedMessage id="Invoice.TransferPhoto" defaultMessage="Photo" />} name="transferPhotoPath">
                    <Upload {...uploadProps} showUploadList={true} maxCount={1}>
                      <Button icon={<UploadOutlined />} style={{ width: "100%", marginTop: "0.2rem" }}>
                        <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Form>
            }
          />


          <Modal
            size="xl"
            show={this.state.modalPdfViewer}
            onHide={this.showOrHideModalPdfViewer}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralPDFReportViewer" defaultMessage="PDF Report Viewer" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                <div style={{ height: "auto", maxHeight: "74vh", overflow: "auto" }}>
                  <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                </div>
              </Worker>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideModalPdfViewer}>
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            </Modal.Footer>
          </Modal>

          <DraggableModal
            title={<FormattedMessage id="Invoice.salableProductList" defaultMessage="Salable Product List" />}
            closable={true}
            maskClosable={false}
            centered
            open={this.state.showSalableProductListModal}
            onCancel={() => {
              this.setState({
                showSalableProductListModal: false
              });
            }}
            onOk={this.addSelectedSalesmanProducts}
            width={1000}
            content={
              <Table
                bordered
                size="small"
                rowKey="relatedReleaseId"
                rowSelection={rowSalableProductSelection}
                scroll={{ x: 1000, scrollToFirstRowOnChange: true }}
                components={{
                  header: {
                    cell: ResizableTitle
                  }
                }}
                columns={Boolean(mergeColumnsSalableProducts) && mergeColumnsSalableProducts.length > 0 ? mergeColumnsSalableProducts : columns}
                dataSource={this.state.salableProductlist}
              />
            }
          />

          <Modal
            show={this.state.showReturnInvoiceModal}
            onHide={() => {
              this.setState({
                showReturnInvoiceModal: false
              });
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="Invoice.ProceedWith" defaultMessage="Proceed with" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveReceivePayment}
                onFinishFailed={onFinishFailed}
                ref={this.formProceedWith}
              >

                {this.state.originalIssuedInvoiceVisible &&
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Invoice.OriginalIssuedInvoice" defaultMessage="Original Issued Invoice" />}
                    name="originalIssuedInvoice"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox
                      onChange={e => {
                        let customersReturnInvoiceVisible = true;
                        if (e.target.checked) {
                          customersReturnInvoiceVisible = false;
                          this.formRef.current.setFieldsValue({

                          });
                        }
                        this.setState({
                          productVisible: e.target.checked,
                          totalReturnPriceVisible: e.target.checked,
                          invoiceBalanceVisible: e.target.checked,
                          titleVisible: e.target.checked,

                          customersReturnInvoiceVisible: customersReturnInvoiceVisible,

                        });

                      }} />
                  </Form.Item>
                }



                {this.state.customersReturnInvoiceVisible &&
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Invoice.CustomersReturnInvoice" defaultMessage="Customer's return invoice" />}
                    name="customersReturnInvoice"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox
                      onChange={e => {
                        let originalIssuedInvoiceVisible = true;
                        if (e.target.checked) {
                          originalIssuedInvoiceVisible = false;
                          this.formRef.current.setFieldsValue({

                          });
                        }
                        this.setState({
                          productVisible: e.target.checked,
                          totalReturnPriceVisible: e.target.checked,
                          invoiceBalanceVisible: e.target.checked,
                          uploadVisible: e.target.checked,
                          titleVisible: e.target.checked,
                          originalIssuedInvoiceVisible: originalIssuedInvoiceVisible,

                        });

                      }} />
                  </Form.Item>
                }



                {this.state.titleVisible &&
                  <span style={{ color: "#1677ff", fontWeight: "bold" }}><FormattedMessage id="RequestForQuotation.ourExpectedPrice" defaultMessage="Our expected price" /> </span>
                }




                {this.state.productVisible &&
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Invoice.Product" defaultMessage="Product" />}
                    name="product"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }

                {this.state.totalReturnPriceVisible &&
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Invoice.TotalReturnPrice" defaultMessage="Total Return Price" />}
                    name="totalReturnPrice"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }

                {this.state.invoiceBalanceVisible &&
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Invoice.InvoiceBalance" defaultMessage="Invoice Balance" />}
                    name="invoiceBalanceVisible"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }


                {this.state.uploadVisible &&
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Invoice.upload" defaultMessage="Upload" />}
                    name="upload"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }


              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showReturnInvoiceModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
              <AntButton
                id="SaveButton"
                htmlType="submit"
                type="primary"
                className="btn btn-success"
                //icon={<SaveOutlined style={{ verticalAlign: "1px", marginRight: "5px" }} />}
                loading={this.state.saveLoading}
              >
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </AntButton>
            </Modal.Footer>
          </Modal>

          <Drawer title={<FormattedMessage id="Invoice.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
            width={1200} closable={false} onClose={() => {
              this.setState({ drawerSettingsShow: false });
            }} open={this.state.drawerSettingsShow}>
            <PageSettings pageName={"Invoice_Log"} />
          </Drawer>
        </div>
      </Badge.Ribbon >
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);