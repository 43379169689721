import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Divider, Form, Input, InputNumber, Radio, Row, Select, Upload, Modal as AntModal } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { API_BASE_ROOT, handleRequest } from "../ApiConnector";
import { error } from "../MessageHelper";
import { ChequeLogCustomer } from "../pages/finance/ChequeLogCustomer";
const { Option } = Select;

const getPaymentForPost = p => {
  if (Boolean(p.transferPhotoPath) && Boolean(p.transferPhotoPath.fileList) && p.transferPhotoPath.fileList.length > 0) {
    return {
      ...p,
      transferPhotoPath: p.transferPhotoPath.fileList[0].response.url
    };
  }
  return p;
};

const getPaymentForForm = p => {
  if (!Boolean(p)) return null;
  let payment1 = { ...p };
  payment1.paymentMethodType = Boolean(payment1.paymentMethodType) ? payment1.paymentMethodType : "CASH";
  payment1.transferDate = Boolean(payment1.transferDate) ? moment(payment1.transferDate) : null;
  payment1.creditDueDate = Boolean(payment1.creditDueDate) ? moment(payment1.creditDueDate) : null;
  if (Boolean(payment1.transferPhotoPath))
    payment1.transferPhotoPath = {
      fileList: [
        {
          uid: "rc-upload-1640075476751-5",
          name: payment1.transferPhotoPath.substring(payment1.transferPhotoPath.lastIndexOf("/") + 1),
          response: {
            url: payment1.transferPhotoPath
          },
          status: "done",
          url: payment1.transferPhotoPath,
          thumbUrl: payment1.transferPhotoPath
        }
      ]
    };

  return payment1;
};

export const GetPaymentListFromFormValues = formValues => {
  let paymentList = [];
  paymentList.push(getPaymentForPost(formValues.payment1));
  formValues.payment1 = undefined;
  if (Boolean(formValues.payment2) && Boolean(formValues.payment2.amount) && formValues.payment2.amount > 0) {
    paymentList.push(getPaymentForPost(formValues.payment2));
    formValues.payment2 = undefined;
  }
  if (Boolean(formValues.payment3) && Boolean(formValues.payment3.amount) && formValues.payment3.amount > 0) {
    paymentList.push(getPaymentForPost(formValues.payment3));
    formValues.payment3 = undefined;
  }
  return paymentList;
};
export const GetFormValuesFromPaymentList = paymentList => {
  let obj = {
    payment1: null,
    payment2: null,
    payment3: null
  };
  let payment1 = getPaymentForForm(paymentList[0]);
  obj.payment1 = payment1;
  if (Boolean(paymentList[1])) {
    obj.payment2 = getPaymentForForm(paymentList[1]);
  }
  if (Boolean(paymentList[2])) {
    obj.payment3 = getPaymentForForm(paymentList[2]);
  }
  return obj;
};
export const GetPaymentStateObject = paymentObj => {
  let paymentStateObj = {
    ...paymentObj,
    paymentMethodType2: Boolean(paymentObj.payment2) ? paymentObj.payment2.paymentMethodType : null
  };
  if (Boolean(paymentObj.payment1)) {
    paymentStateObj.paymentMethodType = paymentObj.payment1.paymentMethodType ?? "CASH";
    paymentStateObj.payment1Amount = paymentObj.payment1.amount;
  }
  if (Boolean(paymentObj.payment2)) {
    paymentStateObj.paymentMethodType2 = paymentObj.payment2.paymentMethodType ?? null;
    paymentStateObj.payment2Amount = paymentObj.payment2.amount;
  }
  if (Boolean(paymentObj.payment3)) {
    paymentStateObj.paymentMethodType3 = paymentObj.payment3.paymentMethodType ?? null;
    paymentStateObj.payment3Amount = paymentObj.payment3.amount;
  }
  return paymentStateObj;
};
export default class PaymentMethodComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      transferBankTypeIdSelectItems: [],
      paymentMethodType: "CASH",
      payment1Amount: 0,
      payment2Amount: 0,
      paymentTypeItems2: [
        { value: "TRANSFER", label: "Transfer" },
        { value: "CREDIT", label: "Credit" },
      ],
      paymentTypeItems3: [{ value: "CREDIT", label: "Credit" }]
    };
  }
  componentDidMount() {
    this.fillInvoiceBank();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.grandTotal > 0 && prevProps.grandTotal !== this.props.grandTotal) {
      this.setState(
        {
          payment1: { amount: this.props.grandTotal }
        },
        () => {
          this.handlePayment1Change(this.props.grandTotal);
        }
      );
    }
    if (prevProps.paymentStateObj !== this.props.paymentStateObj) {
      this.setState({
        ...this.props.paymentStateObj
      });
    }
  }
  paymentTypeItems = [
    { value: "CASH", label: "Cash" },
    { value: "TRANSFER", label: "Transfer" },
    { value: "CREDIT", label: "Credit" },
    { value: "CHEQUES", label: "Cheques" },
  ];
  handlePayment1Change = value => {
    let obj = {
      payment2Amount: this.props.grandTotal - value,
      payment1Amount: value
    };
    if (this.props.grandTotal > obj.payment1Amount) {
      this.props.formRef.setFieldsValue({
        payment2: {
          amount: obj.payment2Amount,
          paymentMethodType: Boolean(this.state.paymentTypeItems2[0]) ? this.state.paymentTypeItems2[0].value : null
        }
      });
      obj.paymentMethodType2 = Boolean(this.state.paymentTypeItems2[0]) ? this.state.paymentTypeItems2[0].value : null;
    } else {
      this.props.formRef.setFieldsValue({
        payment2: {
          amount: 0,
          paymentMethodType: null
        }
      });
      obj.payment2Amount = 0;
    }
    if (this.props.grandTotal > obj.payment1Amount && this.state.paymentMethodType === "CREDIT") {
      obj.paymentMethodType = "CASH";
      this.props.formRef.setFieldsValue({
        payment1: { paymentMethodType: "CASH" },
        payment2: null
      });
    }
    this.setState({ ...obj });
  };

  handlePaymentType1Change = async selected => {
    let paymentTypeItems2 = this.paymentTypeItems.filter(function (x) {
      return x.value !== selected;
    });
    let obj = {
      paymentTypeItems2: paymentTypeItems2,
      paymentMethodType: selected,
      paymentMethodType2: selected === "CREDIT" ? null : paymentTypeItems2[0].value
    };
    var showChequeLogCustomerModal = false;
    if (selected === "CHEQUES") {
      showChequeLogCustomerModal = true;
    } else if (selected === "CREDIT") {
      obj.payment1 = { amount: this.props.grandTotal };
      obj.payment1Amount = this.props.grandTotal;
      // obj.hidePayment2 = true;
      this.props.formRef.setFieldsValue({
        payment1: { amount: this.props.grandTotal },
        payment2: null
      });
    } else {
      this.props.formRef.setFieldsValue({
        payment2: {
          paymentMethodType: paymentTypeItems2[0].value
        }
      });
    }
    this.setState({
      ...obj,
      showChequeLogCustomerModal: showChequeLogCustomerModal
    });
  };

  addTransferBankIdType = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "invoiceTransferBank"
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: ""
        });
        this.fillInvoiceBank();
      }
    } else {
      this.showError("Please enter value");
    }
  };
  fillInvoiceBank = async () => {
    var response = await handleRequest("GET", "/api/codes/type/invoiceTransferBank");
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({ transferBankTypeIdSelectItems: Boolean(response.data) ? response.data : [] });
    }
  };

  saveChequeLogCustomer = (chequeLog) => {
    this.setState({
      showChequeLogCustomerModal: false,
    });
    this.props.saveChequeLogCustomer(chequeLog);
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const layoutPaymentInfo = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };
    const uploadProps = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };
    return (
      <div>
        <Form.Item
          {...layout}
          hidden={!Boolean(this.props.grandTotal)}
          label={<FormattedMessage id="Invoice.paymentMethodType" defaultMessage="Payment Method" />}
        >
          <Row gutter={8} style={{ marginBottom: 8 }}>
            <Col span={4}>
              <Form.Item name={["payment1", "amount"]}>
                <InputNumber style={{ width: "100%" }} min={0} onChange={this.handlePayment1Change} />
              </Form.Item>
            </Col>
            <Col span={20}>
              <Form.Item
                name={["payment1", "paymentMethodType"]}
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Radio.Group
                  value={this.state.paymentMethodType}
                  options={Boolean(this.props.user) && this.props.user.role === "SALESMAN" ? this.paymentTypeItems.filter(p => p.value !== "CREDIT") : this.paymentTypeItems}
                  onChange={e => {
                    this.handlePaymentType1Change(e.target.value);
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginBottom: 8 }} hidden={this.state.paymentMethodType !== "TRANSFER"}>
              <Form.Item
                {...layoutPaymentInfo}
                label={<FormattedMessage id="Invoice.TransferBankName" defaultMessage="Bank" />}
                style={{
                  marginBottom: 0
                }}
                name={["payment1", "transferBankTypeId"]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Bank Name"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8
                        }}
                      >
                        <Input
                          value={this.state.codeName}
                          style={{ flex: "auto" }}
                          onChange={e => {
                            this.setState({
                              codeName: e.target.value
                            });
                          }}
                        />
                        <Button type="link" icon={<PlusOutlined />} onClick={this.addTransferBankIdType}>
                          Add
                        </Button>
                      </div>
                    </div>
                  )}
                >
                  {Boolean(this.state["transferBankTypeIdSelectItems"]) &&
                    this.state["transferBankTypeIdSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              <Form.Item
                {...layoutPaymentInfo}
                label={<FormattedMessage id="Invoice.TransferIbanNo" defaultMessage="Account/IBAN No" />}
                style={{
                  marginBottom: 0
                }}
                name={["payment1", "transferIbanNo"]}
              >
                <Input style={{ width: "100%" }} placeholder="Account/IBAN No" />
              </Form.Item>
              <Form.Item
                {...layoutPaymentInfo}
                label={<FormattedMessage id="Invoice.TransferDate" defaultMessage="Date" />}
                style={{
                  marginBottom: 0
                }}
                name={["payment1", "transferDate"]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                {...layoutPaymentInfo}
                label={<FormattedMessage id="Invoice.TransferPhoto" defaultMessage="Photo" />}
                name={["payment1", "transferPhotoPath"]}
              >
                <Upload {...uploadProps} showUploadList={true} maxCount={1}>
                  <Button icon={<UploadOutlined />} style={{ width: "100%", marginTop: "0.2rem" }}>
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginBottom: 8 }} hidden={this.state.paymentMethodType !== "CREDIT"}>
              <Form.Item
                {...layoutPaymentInfo}
                label={<FormattedMessage id="Invoice.creditDueDate" defaultMessage="Due Date" />}
                style={{
                  marginBottom: 0
                }}
                name={["payment1", "creditDueDate"]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={8}
            hidden={!Boolean(this.state.payment1Amount) || this.props.grandTotal <= this.state.payment1Amount}
            style={{ marginBottom: 8 }}
          >
            <Col span={6}>
              <Form.Item name={["payment2", "amount"]}>
                <InputNumber
                  style={{ width: "100%" }}
                  onChange={value => {
                    this.setState({
                      payment2Amount: value,
                      payment3Amount: this.props.grandTotal - this.state.payment1Amount - value,
                      paymentMethodType3: Boolean(this.state.paymentTypeItems3[0]) ? this.state.paymentTypeItems3[0].value : null
                    });
                    this.props.formRef.setFieldsValue({
                      payment3: {
                        amount: this.props.grandTotal - this.state.payment1Amount - value,
                        paymentMethodType: Boolean(this.state.paymentTypeItems3[0]) ? this.state.paymentTypeItems3[0].value : null
                      }
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item name={["payment2", "paymentMethodType"]}>
                <Radio.Group
                  value={this.state.paymentMethodType2}
                  onChange={e => {
                    let stateObj = {
                      paymentMethodType2: e.target.value,
                      paymentTypeItems3: Boolean(this.state.paymentTypeItems2)
                        ? this.state.paymentTypeItems2.filter(function (x) {
                          return x.value !== e.target.value;
                        })
                        : []
                    };
                    let formObj = {
                      payment2: {
                        amount: this.state.payment2Amount
                      }
                    };
                    if (this.props.grandTotal > this.state.payment2Amount + this.state.payment1Amount) {
                      let payment3 = this.props.grandTotal - this.state.payment2Amount - this.state.payment1Amount;
                      stateObj["payment3Amount"] = payment3;
                      stateObj["paymentMethodType3"] = Boolean(stateObj.paymentTypeItems3[0]) ? stateObj.paymentTypeItems3[0].value : null;
                      formObj["payment3"] = {
                        amount: payment3,
                        paymentMethodType: stateObj.paymentTypeItems3[0].value
                      };
                    }
                    this.props.formRef.setFieldsValue({
                      ...formObj
                    });

                    this.setState({
                      ...stateObj
                    });
                  }}
                  options={this.state.paymentTypeItems2}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginBottom: 8 }} hidden={this.state.paymentMethodType2 !== "TRANSFER"}>
              <Form.Item
                {...layoutPaymentInfo}
                label={<FormattedMessage id="Invoice.TransferBankName" defaultMessage="Bank" />}
                style={{
                  marginBottom: 0
                }}
                name={["payment2", "transferBankTypeId"]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Bank Name"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8
                        }}
                      >
                        <Input
                          value={this.state.codeName}
                          style={{ flex: "auto" }}
                          onChange={e => {
                            this.setState({
                              codeName: e.target.value
                            });
                          }}
                        />
                        <Button type="link" icon={<PlusOutlined />} onClick={this.addTransferBankIdType}>
                          Add
                        </Button>
                      </div>
                    </div>
                  )}
                >
                  {Boolean(this.state["transferBankTypeIdSelectItems"]) &&
                    this.state["transferBankTypeIdSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              <Form.Item
                {...layoutPaymentInfo}
                label={<FormattedMessage id="Invoice.TransferIbanNo" defaultMessage="Account/IBAN No" />}
                style={{
                  marginBottom: 0
                }}
                name={["payment2", "transferIbanNo"]}
              >
                <Input style={{ width: "100%" }} placeholder="Account/IBAN No" />
              </Form.Item>
              <Form.Item
                {...layoutPaymentInfo}
                label={<FormattedMessage id="Invoice.TransferDate" defaultMessage="Date" />}
                style={{
                  marginBottom: 0
                }}
                name={["payment2", "transferDate"]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                {...layoutPaymentInfo}
                label={<FormattedMessage id="Invoice.TransferPhoto" defaultMessage="Photo" />}
                name={["payment2", "transferPhotoPath"]}
              >
                <Upload {...uploadProps} showUploadList={true} maxCount={1}>
                  <Button icon={<UploadOutlined />} style={{ width: "100%", marginTop: "0.2rem" }}>
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginBottom: 8 }} hidden={this.state.paymentMethodType2 !== "CREDIT"}>
              <Form.Item
                {...layoutPaymentInfo}
                label={<FormattedMessage id="Invoice.creditDueDate" defaultMessage="Due Date" />}
                style={{
                  marginBottom: 0
                }}
                name={["payment2", "creditDueDate"]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={8}
            hidden={
              !Boolean(this.state.payment1Amount) ||
              !Boolean(this.state.payment2Amount) ||
              this.props.grandTotal <= this.state.payment1Amount + this.state.payment2Amount
            }
            style={{ marginBottom: 8 }}
          >
            <Col span={6}>
              <Form.Item name={["payment3", "amount"]}>
                <InputNumber
                  style={{ width: "100%" }}
                  onChange={value => {
                    this.setState({
                      payment3Amount: value
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item name={["payment3", "paymentMethodType"]}>
                <Radio.Group
                  onChange={e => {
                    if (e.target.value === "CREDIT") {
                      this.props.formRef.setFieldsValue({
                        payment3: {
                          amount: this.state.payment3Amount
                        }
                      });
                    }
                    this.setState({
                      paymentMethodType3: e.target.value
                    });
                  }}
                  options={this.state.paymentTypeItems3}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginBottom: 8 }} hidden={this.state.paymentMethodType3 !== "TRANSFER"}>
              <Form.Item
                {...layoutPaymentInfo}
                label={<FormattedMessage id="Invoice.TransferBankName" defaultMessage="Bank" />}
                style={{
                  marginBottom: 0
                }}
                name={["payment3", "transferBankTypeId"]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Bank Name"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8
                        }}
                      >
                        <Input
                          value={this.state.codeName}
                          style={{ flex: "auto" }}
                          onChange={e => {
                            this.setState({
                              codeName: e.target.value
                            });
                          }}
                        />
                        <Button type="link" icon={<PlusOutlined />} onClick={this.addTransferBankIdType}>
                          <PlusOutlined /> Add
                        </Button>
                      </div>
                    </div>
                  )}
                >
                  {Boolean(this.state["transferBankTypeIdSelectItems"]) &&
                    this.state["transferBankTypeIdSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              <Form.Item
                {...layoutPaymentInfo}
                label={<FormattedMessage id="Invoice.TransferIbanNo" defaultMessage="Account/IBAN No" />}
                style={{
                  marginBottom: 0
                }}
                name={["payment3", "transferIbanNo"]}
              >
                <Input style={{ width: "100%" }} placeholder="Account/IBAN No" />
              </Form.Item>
              <Form.Item
                {...layoutPaymentInfo}
                label={<FormattedMessage id="Invoice.TransferDate" defaultMessage="Date" />}
                style={{
                  marginBottom: 0
                }}
                name={["payment3", "transferDate"]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                {...layoutPaymentInfo}
                label={<FormattedMessage id="Invoice.TransferPhoto" defaultMessage="Photo" />}
                name={["payment3", "transferPhotoPath"]}
              >
                <Upload {...uploadProps} showUploadList={true} maxCount={1}>
                  <Button icon={<UploadOutlined />} style={{ width: "100%", marginTop: "0.2rem" }}>
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginBottom: 8 }} hidden={this.state.paymentMethodType3 !== "CREDIT"}>
              <Form.Item
                {...layoutPaymentInfo}
                label={<FormattedMessage id="Invoice.creditDueDate" defaultMessage="Due Date" />}
                style={{
                  marginBottom: 0
                }}
                name={["payment3", "creditDueDate"]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <AntModal
          centered
          width={1500}
          title="Cheques from Customer"
          visible={this.state.showChequeLogCustomerModal}
          onCancel={() => {
            this.setState({ showChequeLogCustomerModal: false });
          }}
          footer={[
            <Button variant="secondary" onClick={() => {
              this.setState({
                showChequeLogCustomerModal: false
              });
            }}>Close</Button>
          ]}
        >
          <ChequeLogCustomer random={Math.random()}
            chequeOwnerCustomerId={this.props.chequeOwnerCustomerId}
            invoiceId={this.props.invoiceId}
            saveChequeLogCustomer={this.saveChequeLogCustomer}
          />
        </AntModal>
      </div>
    );
  }
}
