import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Badge, Tooltip, Divider } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../../redux/store";
import { Input } from "antd";
import { Select } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

export class EldenEquipmentLegal extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "EldenEquipmentLegal",
      controllerName: "eldenEquipmentInsurance",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/listAll";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.formRef.current.setFieldsValue({
    //     settings: [],
    //   });
    // } else {
    //   let settings = [];
    //   if (Boolean(response.data) && response.data.length > 0) {
    //     response.data.forEach(element => {
    //       let options = [];
    //       if (Boolean(element.options) && element.options.length > 0) {
    //         element.options.forEach(opt => {
    //           options.push(opt.codeId);
    //         });
    //       }
    //       element.options = options;

    //       let columns = [];
    //       if (Boolean(element.columns) && element.columns.length > 0) {
    //         element.columns.forEach(opt => {
    //           columns.push(opt.codeId);
    //         });
    //       }
    //       element.columns = columns;

    //       settings.push(element);
    //     });
    //   }

    //   this.formRef.current.setFieldsValue({
    //     settings: settings,
    //   });
    // }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      eldenEquipmentTreeId: this.props.eldenEquipmentTreeId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      fileList: []
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    if (Boolean(values) && Boolean(values.settings) && values.settings.length > 0) {
      values.settings.forEach(row => {

        console.log("AAA", row);
        var options = [];
        if (Boolean(row.options) && row.options.length > 0) {
          row.options.forEach(element => {
            options.push({
              ["codeId"]: element
            });
          });
        }

        var columns = [];
        if (Boolean(row.columns) && row.columns.length > 0) {
          row.columns.forEach(element => {
            columns.push({
              ["codeId"]: element
            });
          });
        }

        var _imageOptions = [];
        if (Boolean(this.state.fileList))
          this.state.fileList.forEach(element => {
            let url = null;
            if (Boolean(element.url)) {
              url = element.url;
            } else if (Boolean(element.response) && Boolean(element.response.url)) {
              url = element.response.url;
            }
            if (Boolean(url)) {
              _imageOptions.push({
                ["imageUrl"]: url
              });
            }
          });
        row.options = options;
        row.columns = columns;
        row.imageOptions = _imageOptions;
      });
    }
    console.log("values", values);

    if (Boolean(this.props.eldenEquipmentTreeId) && this.props.eldenEquipmentTreeId > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll/" + this.props.eldenEquipmentTreeId, values.settings);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.props.nextTab();
      }
    } else {
      showWarning("Equipment Log not found.");
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleFileChange = ({ fileList }) => this.setState({ fileList });

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const settings = Boolean(this.formRef)
      && Boolean(this.formRef.current)
      && Boolean(this.formRef.current.getFieldValue("settings"))
      && Array.isArray(this.formRef.current.getFieldValue("settings"))
      && this.formRef.current.getFieldValue("settings").length > 0
      ? this.formRef.current.getFieldValue("settings")
      : []
      ;
    const isSettingsArray = Boolean(settings) && Array.isArray(settings) && settings.length > 0;

    const layout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 6 }
    };


    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EldenEquipmentLegal.insuranceCompanyId" defaultMessage="Insurance Company" />,
        key: "insuranceCompanyId",
        render: record => {
          return record.documentTypeName;
        }, ...getColumnFilter({
          inputName: "documentTypeId", inputType: "SELECT_ADDABLE",
          selectItems: Boolean(this.state["EldenEquipmentLegaldocumentTypeIdSelectItems"]) && this.state["EldenEquipmentLegaldocumentTypeIdSelectItems"].length > 0 ? this.state["EldenEquipmentLegaldocumentTypeIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentLegal.agencyId" defaultMessage="Agency" />,
        key: "agencyId",
        render: record => {
          return record.formatName;
        }, ...getColumnFilter({
          inputName: "formatId", inputType: "SELECT_ADDABLE",
          selectItems: Boolean(this.state["EldenEquipmentLegalformatIdSelectItems"]) && this.state["EldenEquipmentLegalformatIdSelectItems"].length > 0 ? this.state["EldenEquipmentLegalformatIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentLegal.issueDateId" defaultMessage="Issue Date" />,
        key: "issueDateId",
        render: record => {
          return record.preparationName;
        }, ...getColumnFilter({
          inputName: "preparationId", inputType: "SELECT",
          selectItems: Boolean(this.state["preparationIdSelectItems"]) && this.state["preparationIdSelectItems"].length > 0 ? this.state["preparationIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentLegal.policyNumberId" defaultMessage="Policy Number" />,
        key: "policyNumberId",
        render: record => {
          return record.reviewName;
        }, ...getColumnFilter({
          inputName: "reviewId", inputType: "SELECT",
          selectItems: Boolean(this.state["reviewIdSelectItems"]) && this.state["reviewIdSelectItems"].length > 0 ? this.state["reviewIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentLegal.coveragePeriodId" defaultMessage="Coverage Period" />,
        key: "coveragePeriodId",
        render: record => {
          return record.approvalName;
        }, ...getColumnFilter({
          inputName: "approvalId", inputType: "SELECT",
          selectItems: Boolean(this.state["approvalIdSelectItems"]) && this.state["approvalIdSelectItems"].length > 0 ? this.state["approvalIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },

      {
        title: <FormattedMessage id="EldenEquipmentLegal.coverageId" defaultMessage="Coverage" />,
        key: "coverageId",
        render: record => {
          return record.approvalName;
        }, ...getColumnFilter({
          inputName: "approvalId", inputType: "SELECT",
          selectItems: Boolean(this.state["approvalIdSelectItems"]) && this.state["approvalIdSelectItems"].length > 0 ? this.state["approvalIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentLegal.emergencyContactId" defaultMessage="Emergency Contact" />,
        key: "emergencyContactId",
        render: record => {
          return record.approvalName;
        }, ...getColumnFilter({
          inputName: "approvalId", inputType: "SELECT",
          selectItems: Boolean(this.state["approvalIdSelectItems"]) && this.state["approvalIdSelectItems"].length > 0 ? this.state["approvalIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentLegal.paidAmountId" defaultMessage="Paid Amount" />,
        key: "paidAmountId",
        render: record => {
          return record.approvalName;
        }, ...getColumnFilter({
          inputName: "approvalId", inputType: "SELECT",
          selectItems: Boolean(this.state["approvalIdSelectItems"]) && this.state["approvalIdSelectItems"].length > 0 ? this.state["approvalIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentLegal.remainingDatesId" defaultMessage="Remaining Dates" />,
        key: "remainingDatesId",
        render: record => {
          return record.approvalName;
        }, ...getColumnFilter({
          inputName: "approvalId", inputType: "SELECT",
          selectItems: Boolean(this.state["approvalIdSelectItems"]) && this.state["approvalIdSelectItems"].length > 0 ? this.state["approvalIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
    ];
    return (
      <Badge.Ribbon text={<FormattedMessage id="EldenEquipmentLegal.pageTitle" defaultMessage="Legal" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenEquipmentLegal.documentTypeId" defaultMessage="Document Type" />}
                  name="documentTypeId"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }} optionFilterProp="children"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("EldenEquipmentLegaldocumentTypeId")}
                          >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["EldenEquipmentLegaldocumentTypeIdSelectItems"]) &&
                      this.state["EldenEquipmentLegaldocumentTypeIdSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenEquipmentLegal.formatId" defaultMessage="Format" />}
                  name="formatId"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }} optionFilterProp="children"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("EldenEquipmentLegalformatId")}
                          >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["EldenEquipmentLegalformatIdSelectItems"]) &&
                      this.state["EldenEquipmentLegalformatIdSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenEquipmentLegal.preparationId" defaultMessage="Preparation" />}
                name="preparationId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                >
                  {Boolean(this.state["preparationIdSelectItems"]) && this.state["preparationIdSelectItems"].length > 0 &&
                    this.state["preparationIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenEquipmentLegal.reviewId" defaultMessage="Review" />}
                name="reviewId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                >
                  {Boolean(this.state["reviewIdSelectItems"]) && this.state["reviewIdSelectItems"].length > 0 &&
                    this.state["reviewIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenEquipmentLegal.approvalId" defaultMessage="Approval" />}
                name="approvalId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                >
                  {Boolean(this.state["approvalIdSelectItems"]) && this.state["approvalIdSelectItems"].length > 0 &&
                    this.state["approvalIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              <div hidden={this.state.hideSave}>
                {<Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {<Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>}
              </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (<div className="alert alert-custom alert-light">                 <div className="alert-icon">                   <FilterOutlined />                 </div>                 <div className="alert-text">                   {this.state.searchCriteriaModels.map((val, i) => { return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">                         <span className="mr-2">{val.label}</span>                         <strong className="mr-2">{val.type}</strong>                         <span className="mr-2">{val.operationValue}</span>                         <span className="mr-2">{val.showValue}</span>                         <span>                           <Tooltip title="Remove">                             <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />                           </Tooltip>                         </span>                       </div>); })}                 </div>                 <div className="alert-close">                   <Tooltip title="Remove All">                     <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />                   </Tooltip>                 </div>               </div>)}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              rowKey="id"
              bordered scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns} dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}

            />
          </div>
          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>           <Modal.Header closeButton>             <Modal.Title id="contained-modal-title-vcenter">               <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />             </Modal.Title>           </Modal.Header>           <Modal.Body>             <p>               <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />             </p>           </Modal.Body>           <Modal.Footer>             <Button variant="secondary" onClick={this.showOrHideDeleteModal}>               <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />             </Button>             <Button variant="danger" onClick={this.delete}>               <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />             </Button>           </Modal.Footer>         </Modal>


        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EldenEquipmentLegal); 