import React from "react";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider/Divider";
import Typography from "@material-ui/core/Typography/Typography";
import CallMadeIcon from "@material-ui/icons/CallMade";
import MenuIcon from "@material-ui/icons/Menu";
import CallReceivedIcon from "@material-ui/icons/CallReceived";
export default ({
  row,
  onDeleteRow,
  onEditRow,
  onReleaseRow,
  onReceiveRow,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteRow = () => {
    if (onDeleteRow) {
      onDeleteRow(row);
      setAnchorEl(null);
    }
  };

  const editRow = () => {
    if (onEditRow) {
      onEditRow(row);
      setAnchorEl(null);
    }
  };

  const releaseRow = () => {
    if (onReleaseRow) {
      onReleaseRow(row);
      setAnchorEl(null);
    }
  };

  const receiveRow = () => {
    if (onReceiveRow) {
      onReceiveRow(row);
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu" // flaticon-layer
        aria-haspopup="true"
        onClick={handleClick}
      >
        {/* <i className={`kt-menu__link-icon  flaticon-layer`} /> */}
        <MenuIcon color="secondary" />
      </IconButton>
      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={editRow}>
          <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">Edit</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={deleteRow}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <Typography variant="inherit">Delete</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={releaseRow}>
          <ListItemIcon>
            <CallMadeIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">Release</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={receiveRow}>
          <ListItemIcon>
            <CallReceivedIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">Receive</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};
