import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Radio } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../../excel/ExcelReader";
import { Input } from "antd";
import { InputNumber } from "antd";
import { Select } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const { Option } = Select;

export class MechanicalAndPhysicalTestChild extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "MechanicalAndPhysicalTestChild",
      controllerName: "mechanicalPhysicalTestDetail",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };
  fillComboboxes = async () => {
    var responsetypeOfTestId = await handleRequest("GET", "/api/" + this.state.controllerName + "/typeOfTestId");
    if (responsetypeOfTestId.type === "ERROR") {
      error(responsetypeOfTestId);
    }
    this.setState({
      typeOfTestIdSelectItems: Boolean(responsetypeOfTestId.data) ? responsetypeOfTestId.data : []
    });

    var responsegradeId = await handleRequest("GET", "/api/" + this.state.controllerName + "/gradeId");
    if (responsegradeId.type === "ERROR") {
      error(responsegradeId);
    }
    this.setState({
      gradeIdSelectItems: Boolean(responsegradeId.data) ? responsegradeId.data : []
    });

    var responsegreaterorless01Id = await handleRequest("GET", "/api/" + this.state.controllerName + "/greaterOrEqual");
    if (responsegreaterorless01Id.type === "ERROR") {
      error(responsegreaterorless01Id);
    }
    this.setState({
      greaterorless01IdSelectItems: Boolean(responsegreaterorless01Id.data) ? responsegreaterorless01Id.data : [],
      greaterorless02IdSelectItems: Boolean(responsegreaterorless01Id.data) ? responsegreaterorless01Id.data : []

    });

    var responseminContentPercentIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/percentNumber");
    if (responseminContentPercentIdSelectItems.type === "ERROR") {
      error(responseminContentPercentIdSelectItems);
    }
    this.setState({
      minContentPercentIdSelectItems: Boolean(responseminContentPercentIdSelectItems.data) ? responseminContentPercentIdSelectItems.data : [],
      maxContentPercentIdSelectItems: Boolean(responseminContentPercentIdSelectItems.data) ? responseminContentPercentIdSelectItems.data : []
    });

    if (Boolean(this.props.selectedMechanicalAndPhysicalTestChild) && Boolean(this.props.selectedMechanicalAndPhysicalTestChild.productId) && this.props.selectedMechanicalAndPhysicalTestChild.productId > 0) {
      var responseDimension = await handleRequest("GET", "/api/" + this.state.controllerName + "/dimension/" + this.props.selectedMechanicalAndPhysicalTestChild.productId);
      if (responseDimension.type === "ERROR") {
        error(responseDimension);
      }
      this.setState({
        dimensionSelectItems: Boolean(responseDimension.data) ? responseDimension.data : []
      });
    } else {
      this.setState({
        dimensionSelectItems: [],
      });
    }

    // var responseunitGroupId = await handleRequest("GET", "/api/" + this.state.controllerName + "/unitGroupId");
    // if (responseunitGroupId.type === "ERROR") {
    //   error(responseunitGroupId);
    // }
    // this.setState({
    //   unitGroupIdSelectItems: Boolean(responseunitGroupId.data) ? responseunitGroupId.data : []
    // });
    // // var responsepropertyClassId = await handleRequest("GET", "/api/" + this.state.controllerName + "/propertyClassId");
    // //   if (responsepropertyClassId.type === "ERROR") {
    // //  error(responsepropertyClassId);
    // //   }
    // //   this.setState({
    // //  propertyClassIdSelectItems: Boolean(responsepropertyClassId.data) ? responsepropertyClassId.data : []
    // //   });
    // var responsethicknessFirstType = await handleRequest("GET", "/api/" + this.state.controllerName + "/thicknessFirstType");
    // if (responsethicknessFirstType.type === "ERROR") {
    //   error(responsethicknessFirstType);
    // }
    // this.setState({
    //   thicknessFirstTypeSelectItems: Boolean(responsethicknessFirstType.data) ? responsethicknessFirstType.data : []
    // });
    // var responsethicknessFirstType = await handleRequest("GET", "/api/" + this.state.controllerName + "/thicknessFirstType");
    // if (responsethicknessFirstType.type === "ERROR") {
    //   error(responsethicknessFirstType);
    // }
    // this.setState({
    //   thicknessFirstTypeSelectItems: Boolean(responsethicknessFirstType.data) ? responsethicknessFirstType.data : []
    // });
  };
  fillUnit = async (unitGroupId) => {
    if (Boolean(unitGroupId) && unitGroupId > 0) {
      var responseunitId = await handleRequest("GET", "/api/" + this.state.controllerName + "/unitId/" + unitGroupId);
      if (responseunitId.type === "ERROR") {
        error(responseunitId);
      }
      this.setState({
        unitIdSelectItems: Boolean(responseunitId.data) ? responseunitId.data : []
      });
    } else {
      this.setState({
        unitIdSelectItems: []
      });
    }
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      mechanicalPhysicalTestId: this.props.selectedMechanicalAndPhysicalTestChild.id,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };



    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);



    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    if (Boolean(this.props.selectedMechanicalAndPhysicalTestChild.id) && this.props.selectedMechanicalAndPhysicalTestChild.id > 0) {
      var response = await handleRequest(
        "POST",
        "/api/" + this.state.controllerName + "/saveAllList/" + this.props.selectedMechanicalAndPhysicalTestChild.id,
        values.mechanicalandPhysicalTestDetailList
      );

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showWarning("Mechanical And Physical Test not found.");
    }
  };

  // save = async values => {
  //   const newItem = {
  //     ...values,
  //     id: this.state.id,
  //   };

  //   if (newItem.id === null) {
  //     var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
  //   } else {
  //     response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
  //   }

  //   if (response.type === "ERROR") {
  //     error(response);
  //   } else {
  //     this.cancel();
  //     const { pagination } = this.state;
  //     this.restartTable({ pagination });
  //     showSuccess();
  //   }
  // };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };
    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="MechanicalAndPhysicalTestChild.typeOfTestId" defaultMessage="test" />,
        key: "typeOfTestId",
        render: record => {
          return record.testName;
        }, ...getColumnFilter({
          inputName: "testName", inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="MechanicalAndPhysicalTestChild.grade" defaultMessage="Grade" />,
        key: "grade",
        render: record => {
          return record.gradeName;
        }, ...getColumnFilter({
          inputName: "grade", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="MechanicalAndPhysicalTestChild.propertyClassId" defaultMessage="Property Class" />,
        key: "propertyClassId",
        render: record => {
          return record.propertyClassName;
        }, sorter: true
      },
    ];
    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="MechanicalAndPhysicalTestChild" hideElement={true}>             <ExcelSheet name="MechanicalAndPhysicalTestChild" data={this.state.tableList}>               <ExcelColumn label="test" value="testName" />
      <ExcelColumn label="Grade" value="grade" />
      <ExcelColumn label="Property Class" value="propertyClassName" />
    </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} 
    icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)} */}
    </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="MechanicalAndPhysicalTestChild.pageTitle" defaultMessage="Mechanical and Physical Test Child" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>             <Col md={21}></Col>             <Col md={1}>{excelExport}</Col>             <Col md={1}>               <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>             </Col>             <Col md={1}>               <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />}               ></AntButton>               <AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />}               ></AntButton>             </Col>           </Row>
            <div hidden={this.state.hideInputs}>
              <br />{" "}
              <h4>
                <FormattedMessage id={"MechanicalAndPhysicalTestChild.MechanicalAndPhysicalTest"} defaultMessage={"Mechanical And Physical Test"} />
              </h4>
              {
                <Form.List name="mechanicalandPhysicalTestDetailList">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered responsive="xl">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"mechanicalandPhysicalTestDetail.elementId"} defaultMessage={"Test"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"mechanicalandPhysicalTestDetail.gradeId"} defaultMessage={"Grades"} />
                              </th>
                              <th colSpan="5" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"mechanicalandPhysicalTestDetail.materialQuantity"} defaultMessage={"Criteria"} />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"mechanicalandPhysicalTestDetail.minContent"} defaultMessage={"Min Content"} />
                              </th>
                              <th colSpan="2" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"mechanicalandPhysicalTestDetail.maxContent"} defaultMessage={"Max Content"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "typeOfTestId"]}>
                                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                        {Boolean(this.state["typeOfTestIdSelectItems"]) &&
                                          this.state["typeOfTestIdSelectItems"].length > 0 &&
                                          this.state["typeOfTestIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}{" "}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "gradeId"]}>
                                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                        {Boolean(this.state["gradeIdSelectItems"]) &&
                                          this.state["gradeIdSelectItems"].length > 0 &&
                                          this.state["gradeIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}{" "}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "productFormDimensionId"]}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "5px",
                                        marginLeft: "5px"
                                      }}
                                      rules={[
                                        { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        style={{ width: "100px" }}
                                      >
                                        {Boolean(this.state["dimensionSelectItems"]) && this.state["dimensionSelectItems"].length > 0 &&
                                          this.state["dimensionSelectItems"].map(i => (
                                            <Option key={i.id} value={i.id}>
                                              {i.definition}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "criteria01"]}>
                                      <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "greaterorless01Id"]}>
                                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                        {Boolean(this.state["greaterorless01IdSelectItems"]) &&
                                          this.state["greaterorless01IdSelectItems"].length > 0 &&
                                          this.state["greaterorless01IdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}{" "}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "criteria02"]}>
                                      <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "greaterorless02Id"]}>
                                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                        {Boolean(this.state["greaterorless02IdSelectItems"]) &&
                                          this.state["greaterorless02IdSelectItems"].length > 0 &&
                                          this.state["greaterorless02IdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}{" "}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "minContent"]}>
                                      <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "minContentPercentNumberId"]}>
                                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                        {Boolean(this.state["minContentPercentIdSelectItems"]) &&
                                          this.state["minContentPercentIdSelectItems"].length > 0 &&
                                          this.state["minContentPercentIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}{" "}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "maxContent"]}>
                                      <InputNumber min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "maxContentPercentNumberId"]}>
                                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                                        {Boolean(this.state["maxContentPercentIdSelectItems"]) &&
                                          this.state["maxContentPercentIdSelectItems"].length > 0 &&
                                          this.state["maxContentPercentIdSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}{" "}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <AntButton
                                      style={{ border: "0px" }}
                                      onClick={() => {
                                        this.setState({ andOrModal: true });
                                      }}
                                      icon={<CreateIcon color="secondary" fontSize="large" />}
                                    ></AntButton>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"mechanicalandPhysicalTestDetail.AddNewMaterial"} defaultMessage={"Add New"} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              }
              <Modal
                title={
                  <Badge.Ribbon
                    text={<FormattedMessage id="ChemicalCompositionDetail.andOrModal" defaultMessage="Number of Floors" />}
                    placement="start"
                    color="volcano"
                    style={{ fontSize: 12 }}
                  ></Badge.Ribbon>
                }
                keyboard={false}
                maskClosable={false}
                open={this.state.andOrModal}
                onCancel={() => {
                  this.setState({ andOrModal: false });
                }}
                okButtonProps={{ hidden: false }}
                okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
                cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
                onOk={() => {
                  this.formRefNumberOfFloors.current.submit();
                  this.setState({
                    andOrModal: false
                  });
                  this.fillCode("ChemicalCompositionDetail.TypeOfHouses");
                }}
                width={800}
                centered
              >
                <Form initialValues={{ remember: false }} onFinish={this.saveandOrModal} ref={this.formRefAndOrModal}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="ChemicalCompositionDetail.Type" defaultMessage="Type" />}
                    name="allSameVariableType"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Radio.Group
                      onChange={e => {
                        this.setState({
                          allSameVariableType: e.target.value
                        });
                      }}
                    >
                      <Radio key={"AllSame"} value={"AllSame"}>
                        {<FormattedMessage id="ChemicalCompositionDetail.AllSame" defaultMessage="And" />}
                      </Radio>
                      <Radio key={"Variable"} value={"Variable"}>
                        {<FormattedMessage id="ChemicalCompositionDetail.Variable" defaultMessage="Or" />}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Form>
              </Modal>
              <div hidden={this.state.hideSave}>
                {" "}
                {
                  <Row gutter={[16, 16]}>
                    {" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      {" "}
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        {" "}
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                  </Row>
                }{" "}
              </div>
              <div hidden={this.state.hideUpdate}>
                {" "}
                {
                  <Row gutter={[16, 16]}>
                    {" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      {" "}
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        {" "}
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      {" "}
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        {" "}
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                  </Row>
                }{" "}
              </div>
            </div>



            {/* <div hidden={this.state.hideInputs}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="MechanicalAndPhysicalTestChild.typeOfTestId" defaultMessage="test" />}
                name="typeOfTestId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                >
                  {Boolean(this.state["typeOfTestIdSelectItems"]) && this.state["typeOfTestIdSelectItems"].length > 0 &&
                    this.state["typeOfTestIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>

              {
                <Form.Item
                  {...layout2}
                  label={<FormattedMessage id="MechanicalAndPhysicalTestChild.Unit" defaultMessage="Unit" />}
                >
                  <Row gutter={8}>
                    <Col span={6}>
                      <Form.Item
                        name="unitGroupId"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        extra={<FormattedMessage id="MechanicalAndPhysicalTestChild.UnitGroup" defaultMessage="   " />}
                      >
                        <Select allowClear
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          onChange={value => {
                            this.fillUnit(value);
                          }}
                        >
                          {Boolean(this.state["unitGroupIdSelectItems"]) && this.state["unitGroupIdSelectItems"].length > 0 &&
                            this.state["unitGroupIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}{" "}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="unitId"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        extra={<FormattedMessage id="MechanicalAndPhysicalTestChild.Unit" defaultMessage="Unit" />}
                      >
                        <Select allowClear
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                        >
                          {Boolean(this.state["unitIdSelectItems"]) && this.state["unitIdSelectItems"].length > 0 &&
                            this.state["unitIdSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}{" "}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              }
              <Form.Item
                {...layout}
                label={<FormattedMessage id="MechanicalAndPhysicalTestChild.propertyClassId" defaultMessage="Property Class" />}
                name="propertyClassId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                >
                  {Boolean(this.state["propertyClassIdSelectItems"]) && this.state["propertyClassIdSelectItems"].length > 0 &&
                    this.state["propertyClassIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              {
                <Form.Item
                  {...layout}
                  label={
                    <FormattedMessage
                      id="ItemBreakdownQuantity.thickness"
                      defaultMessage="Thickness"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    />
                  }
                >
                  <Row>
                    <Col span={5}>
                      <Form.Item
                        name="thicknessFirst"
                        extra={<FormattedMessage id="MechanicalAndPhysicalTestChild.thicknessFirst" defaultMessage="    " />}
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} className="w-100" placeholder="    " min={0} />
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={4}>
                      <Form.Item
                        name="thicknessFirstType"
                        extra={<FormattedMessage id="MechanicalAndPhysicalTestChild.thicknessFirstType" defaultMessage="   " />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select allowClear
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                        >
                          {Boolean(this.state["thicknessFirstTypeSelectItems"]) && this.state["thicknessFirstTypeSelectItems"].length > 0 &&
                            this.state["thicknessFirstTypeSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}{" "}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    to
                    <Col span={2}></Col>
                    <Col span={5}>
                      <Form.Item
                        name="thicknessSecond"
                        extra={<FormattedMessage id="MechanicalAndPhysicalTestChild.thicknessSecond" defaultMessage="   " />}
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} className="w-100" placeholder="    " min={0} />
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={4}>
                      <Form.Item
                        name="thicknessSecondType"
                        extra={<FormattedMessage id="MechanicalAndPhysicalTestChild.thicknessSecondType" defaultMessage="   " />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select allowClear
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                        >
                          {Boolean(this.state["thicknessSecondTypeSelectItems"]) && this.state["thicknessSecondTypeSelectItems"].length > 0 &&
                            this.state["thicknessSecondTypeSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}{" "}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                  </Row>
                </Form.Item>
              }


              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="MechanicalAndPhysicalTestChild.nom" defaultMessage="Nom" />}
                  name="nom"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="MechanicalAndPhysicalTestChild.min" defaultMessage="Min" />}
                  name="min"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              <div hidden={this.state.hideSave}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>                     <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">                       <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
              <div hidden={this.state.hideUpdate}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>                       <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />                     </Button>                   </Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">                       <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
            </div> */}
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (<div className="alert alert-custom alert-light">                 <div className="alert-icon">                   <FilterOutlined />                 </div>                 <div className="alert-text">                   {this.state.searchCriteriaModels.map((val, i) => { return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">                         <span className="mr-2">{val.label}</span>                         <strong className="mr-2">{val.type}</strong>                         <span className="mr-2">{val.operationValue}</span>                         <span className="mr-2">{val.showValue}</span>                         <span>                           <Tooltip title="Remove">                             <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />                           </Tooltip>                         </span>                       </div>); })}                 </div>                 <div className="alert-close">                   <Tooltip title="Remove All">                     <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />                   </Tooltip>                 </div>               </div>)}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">           <Table bordered scroll={{ x: 900, scrollToFirstRowOnChange: true }} columns={columns} dataSource={this.state.tableList} loading={this.state.loading} onChange={this.handleTableChange} pagination={this.state.pagination} />         </div>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(MechanicalAndPhysicalTestChild);
