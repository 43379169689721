import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Checkbox } from "antd";
import { DatePicker } from "antd";
import { Select, Cascader } from "antd";
import { Input } from "antd";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class BankTransactionsWithdrawal extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BankTransactionsWithdrawal",
      controllerName: "bankTransactionsWithdrawal",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,

      dateVisible: false,
      invoiceNoVisible: false,
      bankAccountVisible: false,
      cashVisible: false,
      toVisible: false,
      releasedByVisible: false,
      amountVisible: false,
      amountIdVisible: false,
      reasonForTransactionVisible: false,
      toSelectVisible: false,

      tableList: [],


      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
  };
  fillComboboxes = async () => {
    var responseinvoiceId = await handleRequest("GET", "/api/" + this.state.controllerName + "/invoiceId");
    if (responseinvoiceId.type === "ERROR") {
      error(responseinvoiceId);
    }
    this.setState({
      invoiceIdSelectItems: Boolean(responseinvoiceId.data) ? responseinvoiceId.data : []
    });
    var responsebankAccountId = await handleRequest("GET", "/api/" + this.state.controllerName + "/bankAccountId");
    if (responsebankAccountId.type === "ERROR") {
      error(responsebankAccountId);
    }
    this.setState({
      bankAccountIdSelectItems: Boolean(responsebankAccountId.data) ? responsebankAccountId.data : []
    });
    var responsecashId = await handleRequest("GET", "/api/" + this.state.controllerName + "/cashId");
    if (responsecashId.type === "ERROR") {
      error(responsecashId);
    }
    this.setState({
      cashIdSelectItems: Boolean(responsecashId.data) ? responsecashId.data : []
    });
    var responsereleasedById = await handleRequest("GET", "/api/" + this.state.controllerName + "/releasedById");
    if (responsereleasedById.type === "ERROR") {
      error(responsereleasedById);
    }
    this.setState({
      releasedByIdSelectItems: Boolean(responsereleasedById.data) ? responsereleasedById.data : []
    });
    var responsetoSelectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/toSelectId");
    if (responsetoSelectId.type === "ERROR") {
      error(responsetoSelectId);
    }
    this.setState({
      toSelectIdSelectItems: Boolean(responsetoSelectId.data) ? responsetoSelectId.data : []
    });
    var responseamountId = await handleRequest("GET", "/api/" + this.state.controllerName + "/amountId");
    if (responseamountId.type === "ERROR") {
      error(responseamountId);
    }
    this.setState({
      amountIdSelectItems: Boolean(responseamountId.data) ? responseamountId.data : []
    });


  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  save = async values => {
    if (Boolean(this.props.cashId) && this.props.cashId > 0) {
      const newItem = {
        ...values,
        id: this.state.id,
        cashId: this.props.cashId,
      };
      
      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
      }

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showWarning("Cash not found.");
    }
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };



    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);



    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  getAuthorized = async (cashLogId) => {
    if (Boolean(cashLogId) && cashLogId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/authorized/" + cashLogId);
      if (response.type === "ERROR") {
        error(response);
      }
      this.setState({
        releasedByIdSelectItems: Boolean(response.data) ? response.data : []
      });
    } else {
      this.setState({
        releasedByIdSelectItems: []
      });
    }
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    return (
      <Badge.Ribbon
        text={<FormattedMessage id="cashOut.pageTitle" defaultMessage="Cash Out" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div
          className="card card-custom"
          style={{
            padding: "2rem",
            minHeight: "100%"
          }}
        >
          <Form
            initialValues={{ remember: false }}
            onFinish={this.save}
            onFinishFailed={onFinishFailed}
            ref={this.formRef}>
            <Row
              gutter={[16, 16]}
            >
              <Col md={21}></Col>
              <Col md={1}>
                <ExcelReader
                  onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large"
                  />
                  }               >
                </AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}               >
                </AntButton>             </Col>           </Row>


            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CashOut.invoice" defaultMessage="Invoice" />}
                name="invoice"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox
                  onChange={e => {
                    this.setState({
                      dateVisible: e.target.checked,
                      invoiceNoVisible: e.target.checked,
                      releasedByVisible: e.target.checked,
                      amountVisible: e.target.checked,
                      amountIdVisible: e.target.checked,
                      toVisible: e.target.checked,
                      bankAccountVisible: false,
                      toSelectVisible: false,
                      reasonForTransactionVisible: false,

                    });
                    if (e.target.checked) {
                      this.formRef.current.setFieldsValue({
                        advancePayment: null,
                        pettyCash: null,
                        transfer: null,
                        cash: null,
                      });
                    }
                  }} />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CashOut.advancePayment" defaultMessage="Advance Payment" />}
                name="advancePayment"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox
                  onChange={e => {
                    this.setState({
                      dateVisible: e.target.checked,
                      releasedByVisible: e.target.checked,
                      amountVisible: e.target.checked,
                      amountIdVisible: e.target.checked,
                      reasonForTransactionVisible: e.target.checked,
                      toVisible: e.target.checked,
                      invoiceNoVisible: false,
                      bankAccountVisible: false,
                      toSelectVisible: false,
                      cashVisible: false,
                      toVisible: false,
                      bankVisible: false
                    });
                    if (e.target.checked) {
                      this.formRef.current.setFieldsValue({
                        invoice: null,
                        pettyCash: null,
                        transfer: null,
                        cash: null,
                      });
                    }
                  }} />

              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CashOut.pettyCash" defaultMessage="Petty Cash" />}
                name="pettyCash"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox
                  onChange={e => {
                    this.setState({
                      dateVisible: e.target.checked,
                      releasedByVisible: e.target.checked,
                      amountVisible: e.target.checked,
                      amountIdVisible: e.target.checked,
                      reasonForTransactionVisible: e.target.checked,

                      toSelectVisible: e.target.checked,

                      invoiceNoVisible: false,
                      bankAccountVisible: false,
                      cashVisible: false,
                      bankVisible: false

                    });
                    if (e.target.checked) {
                      this.formRef.current.setFieldsValue({
                        invoice: null,
                        advancePayment: null,
                        transfer: null,
                        cash: null,
                      });
                    }
                  }} />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CashOut.transfer" defaultMessage="Transfer" />}
                name="transfer"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox
                  onChange={e => {
                    this.setState({
                      cashCBVisible: e.target.checked,
                      bankVisible: e.target.checked,
                      dateVisible: e.target.checked,
                      toVisible: e.target.checked,
                      releasedByVisible: e.target.checked,
                      amountVisible: e.target.checked,
                      amountIdVisible: e.target.checked,
                      toVisible: e.target.checked,
                      reasonForTransactionVisible: e.target.checked,
                      toSelectVisible: false,
                      invoiceNoVisible: false,

                    });
                    if (e.target.checked) {
                      this.formRef.current.setFieldsValue({
                        invoice: null,
                        advancePayment: null,
                        pettyCash: null,

                      });
                    }
                  }} />
              </Form.Item>
            }
            {this.state.cashCBVisible &&
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CashOut.cash" defaultMessage="Cash" />}
                name="cash"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox
                  onChange={e => {
                    this.setState({
                      cashVisible: e.target.checked,
                      toVisible: e.target.checked,
                      invoiceNoVisible: false,
                      toSelectVisible: false,
                      bankAccountVisible: false,

                    });
                    if (e.target.checked) {
                      this.formRef.current.setFieldsValue({
                        invoice: null,
                        advancePayment: null,
                        pettyCash: null,
                        bank: null,

                      });
                    }
                  }} />
              </Form.Item>
            }
            {this.state.bankVisible &&
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CashOut.bank" defaultMessage="Bank" />}
                name="bank"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox
                  onChange={e => {
                    this.setState({
                      bankAccountVisible: e.target.checked,
                      toVisible: e.target.checked,
                      toSelectVisible: false,
                      invoiceNoVisible: false,
                      cashVisible: false
                    });
                    if (e.target.checked) {
                      this.formRef.current.setFieldsValue({
                        invoice: null,
                        advancePayment: null,
                        pettyCash: null,
                        cash: null
                      });
                    }

                  }} />
              </Form.Item>
            }
            {this.state.dateVisible &&
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CashOut.date" defaultMessage="Date" />}
                name="date"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
              </Form.Item>
            }
            {this.state.invoiceNoVisible &&
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CashOut.invoiceId" defaultMessage="Invoice No" />}
                name="invoiceId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                >
                  {Boolean(this.state["invoiceIdSelectItems"]) && this.state["invoiceIdSelectItems"].length > 0 &&
                    this.state["invoiceIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }
            {this.state.bankAccountVisible &&
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CashOut.bankAccountId" defaultMessage="Bank Account" />}
                name="bankAccountId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"

                >
                  {Boolean(this.state["bankAccountIdSelectItems"]) && this.state["bankAccountIdSelectItems"].length > 0 &&
                    this.state["bankAccountIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>

              </Form.Item>

            }
            {this.state.cashVisible &&
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CashOut.cashId" defaultMessage="Cash" />}
                name="cashId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  onChange={value => {
                    this.getAuthorized(value);
                  }}
                >
                  {Boolean(this.state["cashIdSelectItems"]) && this.state["cashIdSelectItems"].length > 0 &&
                    this.state["cashIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }
            {this.state.toVisible &&
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CashOut.to" defaultMessage="to" />}
                name="to"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            }
            {this.state.toSelectVisible &&
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CashOut.toSelectId" defaultMessage="To" />}
                name="toSelectId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  onChange={value => {
                    this.getAuthorized(value);
                  }}
                >
                  {Boolean(this.state["toSelectIdSelectItems"]) && this.state["toSelectIdSelectItems"].length > 0 &&
                    this.state["toSelectIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }
            {this.state.releasedByVisible &&
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CashOut.releasedById" defaultMessage="Released by" />}
                name="releasedById"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                >
                  {Boolean(this.state["releasedByIdSelectItems"]) && this.state["releasedByIdSelectItems"].length > 0 &&
                    this.state["releasedByIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }
            {this.state.amountVisible &&
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CashOut.amount" defaultMessage="Amount" />}>
                <Row gutter={8}>
                  <Col span={8}>
                    <Form.Item name="amount">
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      label={<FormattedMessage id="CashOut.amountId" defaultMessage="Currency" />}
                      name="amountId"

                    >
                      <Select allowClear
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                      >
                        {Boolean(this.state["amountIdSelectItems"]) && this.state["amountIdSelectItems"].length > 0 &&
                          this.state["amountIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}{" "}
                      </Select>

                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            }{this.state.reasonForTransactionVisible &&
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CashOut.reasons" defaultMessage="Reason for Transaction" />}
                name="reasons"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            }
            <div hidden={this.state.hideSave}>

              {<Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="SaveButton"
                    type="submit"
                    style={{ width: "100%" }}
                    variant="success">
                    <FormattedMessage id="GeneralButtonSave"
                      defaultMessage="Save" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>}
            </div>
            <div hidden={this.state.hideUpdate}>
              {<Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="CancelButton" style={{ width: "100%" }}
                    variant="secondary"
                    onClick={this.cancel}>
                    <FormattedMessage
                      id="GeneralButtonCancel"
                      defaultMessage="Cancel" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="UpdateButton"
                    style={{ width: "100%" }}
                    variant="warning" type="submit">
                    <FormattedMessage id="GeneralButtonUpdate"
                      defaultMessage="Update" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>}
            </div>


            <div hidden={this.state.hideInputs}>
              <div hidden={this.state.hideSave}>
                {<Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton"
                      type="submit"
                      style={{ width: "100%" }}
                      variant="success">
                      <FormattedMessage
                        id="GeneralButtonSave"
                        defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>}
              </div>
              <div hidden={this.state.hideUpdate}>
                {<Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button
                      id="CancelButton"
                      style={{ width: "100%" }}
                      variant="secondary"
                      onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel"
                        defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }}
                      variant="warning"
                      type="submit">
                      <FormattedMessage id="GeneralButtonUpdate"
                        defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>}
              </div>
            </div>
          </Form>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BankTransactionsWithdrawal);
