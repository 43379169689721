import { EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Button as AntButton, Cascader, Col, InputNumber, Form, Input, Row, Select, Collapse } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { ExcelReader } from "../excel/ExcelReader";
import { Button, Modal, Col as ColBoot, Row as RowBoot, Form as FormBoot } from "react-bootstrap";

const { Option } = Select;
const { Panel } = Collapse;

const dataList = [];
const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];
        if (Boolean(node.data)) {
            var lineage = [];
            if (Boolean(node.data.lineage) && node.data.lineage.length > 0) {
                node.data.lineage.forEach(element => {
                    if (!lineage.includes(element)) {
                        lineage.push(element);
                    }
                });
            }

            dataList.push({
                key: node.data.id,
                lineage: lineage,
                title: node.data.menuTitle,
                page: node.data.page
            });
        }
        if (node.children) {
            generateList(node.children);
        }
    }
};

export class ProcurementProgress extends Component {
    constructor() {
        super();
        this.state = {
            formName: "ProcurementProgress",
            controllerName: "procurementProgress",
            modalDeleteShow: false,
            exportList: [],
            exportClick: false,
            hideUpdate: true,
            hideSave: false,
            hideInputs: true,
            lastUpdateDate: [],
            dynamicMenuCascader: [],
            pageActionSelectItems: [],
            itemClassIdSelectItems: [],
            id: null,
            tableList: [],
            excelData: [],
            createWhereParams: null,
            refreshForm: false,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
                total: 0
            },
            loading: false,
            sortField: "",
            sortOrder: "",
        };
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });

        this.fillComboboxes();
    };

    componentDidUpdate = async nextProps => { };

    fillComboboxes = async () => {
        this.getDynamicMenu();
        this.getItemClassess();
    };

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/api/menus/tree");
        if (responseTree.data.length !== 0) {
            generateList(responseTree.data.treeTable);
            this.setState({
                dynamicMenuCascader: responseTree.data.cascader
            });
        }
    };

    getItemClassess = async () => {
        const response = await handleRequest("GET", "/api/codes/type/StockCardItemClassId");
        if (Boolean(response.data)) {
            this.setState({
                itemClassIdSelectItems: response.data
            });
        }
    };

    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hideInputs: true
        });
        this.resetInputs();
    };

    resetInputs = () => {
        this.formRef.current.resetFields();
        this.setState({
            id: null,
        });
    };

    edit = row => {
        document.getElementById("kt_scrolltop").click();
        var menu = dataList.find(p => p.key == row.menuId);
        let menuId = [];
        if (Boolean(menu)) {
            menuId = menu.lineage;
            this.handleMenuIdChange(menuId);
        }
        this.formRef.current.setFieldsValue({
            ...row,
            menuId: menuId,
        });
        this.setState({
            hideUpdate: false,
            hideSave: true,
            hideInputs: false,
            id: row.id,
        });
    };

    deleteModal = row => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    };

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        };
        var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

        if (response.type === "ERROR") {
            error(response);
        } else {
            this.restartTable();
            showSuccess();
            this.setState({
                id: null,
                modalDeleteShow: false
            });
        }
    };

    restartTable = async (params = {}) => {
        this.setState({ loading: true });
        let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getAllByCompany");
        if (response.type === "ERROR") {
            error(response);
            this.setState({
                loading: false,
                tableList: []
            });
        } else {
            this.setState({
                loading: false,
                tableList: response.data
            });
        }
    };

    createWhere(params) {
        if (Boolean(params._pagination)) params.pagination = params._pagination;

        var lastUpdateDateStart = null,
            lastUpdateDateEnd = null,
            searchCriteriaModels = [],
            sortField = "",
            sortOrder = "";
        if (Boolean(params) && Boolean(params.pagination)) {
            if (Boolean(params.pagination.lastUpdateDate)) {
                lastUpdateDateStart = params.pagination.lastUpdateDate[0];
                lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
            }
            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }
        }
        const newObj = {
            ...params.pagination,
            id: null,
            lastUpdateDateStart: lastUpdateDateStart,
            lastUpdateDateEnd: lastUpdateDateEnd,
            firstResult: params.pagination.current - 1,
            maxResults: params.pagination.pageSize,
            sortField: sortField,
            sortOrder: sortOrder,
            searchCriteriaModels: searchCriteriaModels,
        };
        return newObj;
    }

    onClickCreateNew = () => {
        this.resetInputs();
        if (this.state.hideInputs) {
            this.setState({
                hideInputs: false,
                hideSave: false,
                hideUpdate: true
            });
        } else {
            this.setState({
                hideInputs: true,
                hideSave: true,
                hideUpdate: false
            });
        }
    };

    save = async values => {
        let newItem = {
            ...values,
            id: this.state.id,
        };
        newItem.menuId = Boolean(values.menuId) && values.menuId.length > 0 ? values.menuId[values.menuId.length - 1] : null;

        if (!Boolean(newItem.id)) {
            var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
        } else {
            response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
        }

        if (response.type === "ERROR") {
            error(response);
        } else {
            this.cancel();
            this.restartTable();
            showSuccess();
        }
    };

    saveScoreAndWeight = async () => {
        var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/saveScoreAndWeight", this.state.tableList);
        if (response.type === "ERROR") {
            error(response);
        } else {
            this.cancel();
            this.restartTable();
            showSuccess();
        }
    };

    handleExportExcel = async () => {
        this.setState({
            exportClick: true
        });
    };

    handleUploadExcel = async excelJson => {
        try {
            var list = [];
            if (excelJson === null) showError("Select an excel file");
            else {
                excelJson.forEach(element => {
                    const obj = {};
                    list.push(obj);
                });
                if (Boolean(list) && list.length > 0) {
                    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
                    if (response.type === "ERROR") {
                        showError("No records to be added");
                    } else {
                        this.resetInputs();
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                        showSuccess();
                    }
                } else {
                    showError("No records to be added");
                }
            }
        } catch (error) {
            showError("");
        }
    };

    action = record => {
        return (
            <React.Fragment>
                <DynamicActionMenu
                    row={record}
                    actionList={[
                        {
                            name: "Edit",
                            icon: <EditOutlined fontSize="small" color="primary" />,
                            actionClick: this.edit.bind(this)
                        },
                        {
                            name: "Delete",
                            icon: <DeleteIcon fontSize="small" color="error" />,
                            actionClick: this.deleteModal.bind(this)
                        }
                    ]}
                />
            </React.Fragment>
        );
    };

    handleMenuIdChange = async value => {
        let menuId = Boolean(value) && value.length > 0 ? value[value.length - 1] : null;
        let pageActions = [];
        if (Boolean(menuId)) {
            let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/pageActionsByMenu/" + menuId);
            if (response.type === "ERROR") {
                error(response);
            } else {
                pageActions = response.data;
            }
        }
        this.setState({
            pageActionSelectItems: pageActions
        });
    };

    renderElement = (values) => {
        return Boolean(values) && values.length > 0 && values.map((item, index) => (
            <Panel
                header={item.title}
                key={index}
                extra={
                    <>
                        <InputNumber
                            min={0}
                            value={item.score}
                            placeholder="Score"
                            onChange={value => {
                                item.score = value;
                                this.calcWeight();
                            }}
                        ></InputNumber>

                        <InputNumber disabled min={0} value={item.weight} placeholder="Weight"></InputNumber>
                    </>
                }
            >
                {Boolean(item.children) && item.children.length > 0 &&
                    <>
                        <RowBoot style={{ marginTop: '2rem' }}>
                            <ColBoot xs={1}>
                                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                    {"Action"}
                                </FormBoot.Label>
                            </ColBoot>
                            <ColBoot xs={5}>
                                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                    {"Step"}
                                </FormBoot.Label>
                            </ColBoot>
                            <ColBoot xs={2}>
                                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                    {"Score"}
                                </FormBoot.Label>
                            </ColBoot>
                            <ColBoot xs={2}>
                                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                    {"Weight"}
                                </FormBoot.Label>
                            </ColBoot>
                        </RowBoot>

                        {
                            item.children.map((item, index) => (
                                <RowBoot style={{ marginBottom: '1rem' }}>
                                    <ColBoot xs={1}>
                                        {this.action(item)}
                                    </ColBoot>
                                    <ColBoot xs={5}>
                                        <FormBoot.Label style={{ color: "black", marginTop: "0.5rem" }}>
                                            {item.step}
                                        </FormBoot.Label>
                                    </ColBoot>
                                    <ColBoot xs={2}>
                                        <InputNumber
                                            min={0}
                                            value={item.score}
                                            placeholder="Score"
                                            onChange={value => {
                                                item.score = value;
                                                this.calcWeight();
                                            }}
                                        ></InputNumber>
                                    </ColBoot>
                                    <ColBoot xs={2}>
                                        <InputNumber disabled min={0} value={item.weight} placeholder="Weight"></InputNumber>
                                    </ColBoot>
                                </RowBoot>
                            ))
                        }
                    </>
                }
            </Panel>
        ))
    }

    calcWeight = () => {
        var activePanelKey = this.state.activePanelKey;
        var values = this.state.tableList;
        if (Boolean(values) && values.length > 0) {
            var totalParentScore = 0;
            values.forEach((element, index) => {
                if (Boolean(element.score) && element.score > 0) {
                    totalParentScore += element.score;
                }
            });

            values.forEach((element, index) => {
                if (Boolean(element.score) && element.score > 0 && totalParentScore > 0) {
                    element.weight = Number((element.score / totalParentScore) * 100).toFixed(2);
                }
                if (index == activePanelKey) {
                    var totalChildScore = 0;
                    if (Boolean(element.children) && element.children.length > 0) {
                        element.children.forEach((child, index) => {
                            if (Boolean(child.score) && child.score > 0) {
                                totalChildScore += child.score;
                            }
                        });
                    }

                    values.forEach((element) => {
                        if (Boolean(element.children) && element.children.length > 0) {
                            element.children.forEach((child, index) => {
                                if (Boolean(child.score) && child.score > 0 && totalChildScore > 0) {
                                    child.weight = Number((child.score / totalChildScore) * 100).toFixed(2);
                                }
                            });
                        }
                    });
                }
            });
        }

        this.setState({
            tableList: this.state.tableList
        });
    };

    setOpenPanels = key => {
        this.setState({
            activePanelKey: key
        });
    };

    render() {
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 },
            style: {
                marginBottom: 0
            }
        };

        const excelExport = (
            <>
                {/* {this.state.exportClick
                    ? this.setState({
                        exportClick: false
                    })
                    : ""}
                {this.state.exportClick ? (
                    <ExcelFile filename="Products" hideElement={true}>
                        <ExcelSheet name="Products" data={this.state.tableList}>
                            <ExcelColumn label="S/N" value="index" />
                        </ExcelSheet>
                    </ExcelFile>
                ) : (
                    <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
                )} */}
            </>
        );

        return (
            <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
                <Row gutter={[16, 16]}>
                    <Col md={21}>
                        <span className="h3">
                            <FormattedMessage
                                id={this.state.controllerName + ".pageTitle"}
                                defaultMessage={"Procurement Progress"}
                            />
                        </span>
                    </Col>
                    <Col md={1}>{excelExport}</Col>
                    <Col md={1}>
                        <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
                    </Col>
                    <Col md={1}>
                        <AntButton
                            style={{ border: "0px" }}
                            hidden={!this.state.hideInputs}
                            onClick={this.onClickCreateNew}
                            icon={<CreateIcon color="secondary" fontSize="large" />}
                        ></AntButton>
                        <AntButton
                            style={{ border: "0px" }}
                            hidden={this.state.hideInputs}
                            onClick={this.onClickCreateNew}
                            icon={<RemoveCircle color="error" fontSize="large" />}
                        ></AntButton>
                    </Col>
                </Row>
                <div hidden={this.state.hideInputs}>
                    <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
                        <Form.Item
                            {...layout}
                            label={<FormattedMessage id="ProcurementProgress.itemClassId" defaultMessage="Item Class" />}
                            name="itemClassId"
                            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                            <Select allowClear showSearch style={{ width: "100%" }} placeholder="Item Class">
                                {this.state["itemClassIdSelectItems"].map(i => (
                                    <Option key={i.id} value={i.id}>
                                        {i.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            {...layout}
                            label={<FormattedMessage id="ProcurementProgress.step" defaultMessage="Step" />}
                            name="step"
                            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            {...layout}
                            label={<FormattedMessage id="ProcurementProgress.menuId" defaultMessage="Menu" />}
                            name="menuId"
                            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                            <Cascader allowClear showSearch style={{ width: "100%" }} options={this.state.dynamicMenuCascader} onChange={this.handleMenuIdChange} />
                        </Form.Item>

                        <Form.Item
                            {...layout}
                            label={<FormattedMessage id="ProcurementProgress.pageActionId" defaultMessage="Page Action" />}
                            name="pageActionId"
                            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                            <Select allowClear showSearch style={{ width: "100%" }} placeholder="Page Action">
                                {this.state["pageActionSelectItems"].map(i => (
                                    <Option key={i.id} value={i.id}>
                                        {i.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <div hidden={this.state.hideSave}>
                            {
                                <Row gutter={[16, 16]}>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                        <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                                            <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                                        </Button>
                                    </Col>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                </Row>
                            }
                        </div>
                        <div hidden={this.state.hideUpdate}>
                            {
                                <Row gutter={[16, 16]}>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                                        <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                                        <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                                        </Button>
                                    </Col>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                </Row>
                            }
                        </div>
                    </Form>
                </div>
                <br />
                {Boolean(this.state.tableList) && this.state.tableList.length > 0 ?
                    <>
                        <Collapse activeKey={this.state.activePanelKey} onChange={this.setOpenPanels} accordion>
                            {this.renderElement(this.state.tableList)}
                        </Collapse>
                        <br />
                        {
                            <Row gutter={[16, 16]}>
                                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                    <Button id="SaveAllButton" onClick={this.saveScoreAndWeight} style={{ width: "100%" }} variant="success">
                                        <FormattedMessage id="GeneralButtonSaveAll" defaultMessage="Save All" />
                                    </Button>
                                </Col>
                                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                            </Row>
                        }
                    </>
                    :
                    ""
                }

                <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={this.delete}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProcurementProgress);
