
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../ApiConnector';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";

import { Table, Input, Select, message, Divider, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import EditIcon from '@material-ui/icons/EditTwoTone';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import CreateIcon from '@material-ui/icons/AddCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { ExcelReader } from '../excel/ExcelReader';

import ExportIcon from '@material-ui/icons/CloudDownloadRounded';
import { error,showError,showSuccess } from '../../MessageHelper';

const { Option } = Select;

export class CodeDefine extends Component {

    constructor() {
        super();
        this.state = {


            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePath: '',
            fileList: [],
            name: '',
            shortName:null,
            type: '',
            typeSelectItems: [],
            manualType: null,


            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,
            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '25', '50', '100'],
            },
            loading: false,
            sortField: "",
            sortOrder: "",
            excelData: [],
            exportClick: false,
            excelList: [],

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // showSuccess = showSuccess.bind(this);
        // showError = showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {

        const { pagination } = this.state;
        this.restartTable({ pagination });
        this.setComboBoxes();


    }


    save = async () => {

        //[SAVE_OBJECT]
        let obj = {
            id: this.state.id,
            name: this.state.name,
            shortName:this.state.shortName,
            type: this.state.type
        };//SAVE_OBJECT kodlandığında kaldırılacak

        if (obj.id === null)
         var response =  await handleRequest("POST", "/api/codes", obj);
        else
           response = await handleRequest("PUT", "/api/codes/" + obj.id, obj);

            if (response.type === "ERROR") {
                error(response);
            }
            else {
                this.resetInputs();
                const { pagination } = this.state;
                this.restartTable({ pagination });
                showSuccess();
               
            }
       
    }

    edit = (row) => {

        this.formRef.current.setFieldsValue({

            name: row.name,
            shortName:row.shortName,
            type: row.type
        });

        this.setState({
            id: row.id,
            name: row.name,
            shortName:row.shortName,
            type: row.type,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("DELETE", "/api/codes/" + obj.id);

        const { pagination } = this.state
        this.restartTable({ pagination });
        showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    onchangeName = async (event) => {
        if (Boolean(event) && Boolean(event.target) && Boolean(event.target.value)) {
            await this.setState({
                nameColor: '#1890ff',
                pagination: {
                    ...this.state.pagination,
                    name: event.target.value,
                    current: 1,
                },
            })
        } else {
            await this.setState({
                nameColor: '#bfbfbf',
                pagination: {
                    ...this.state.pagination,
                    name: "",
                    current: 1,
                },
            })
        }

        const { pagination } = this.state;
        this.restartTable({ pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    };
    onchangeType = async (event) => {
        if (Boolean(event) && Boolean(event.target) && Boolean(event.target.value)) {
            await this.setState({
                typeColor: '#1890ff',
                pagination: {
                    ...this.state.pagination,
                    type: event.target.value,
                    current: 1,
                },
            })
        } else {
            await this.setState({
                typeColor: '#bfbfbf',
                pagination: {
                    ...this.state.pagination,
                    type: "",
                    current: 1,
                },
            })
        }

        const { pagination } = this.state;
        this.restartTable({ pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    };

    restartTable = async (params = {}) => {


        this.setState({ loading: true });
        const newObj = this.createWhere(params);
        // let url = "/api/codes/paging";
        // const response = await handleRequest("POST", url, newObj);
        const response = await handleRequest("POST", "/api/codes/paging", newObj);
        if (response.type === "ERROR") {
            error(response);
            this.setState({
                loading: false,
                tableList: [],
                pagination: {
                    ...params.pagination,
                    total: 0,
                },
                sortField: "",
                sortOrder: "",
            });
        }
        else {
            if (response.data.length !== 0) {
                this.setState({
                    loading: false,
                    tableList: response.data.data,
                    excelList: response.data.excelJson,
                    pagination: {
                        ...params.pagination,
                        total: response.data.totalRecords,
                    },
                    sortField: params.sortField,
                    sortOrder: params.sortOrder,
                });
            } else {
                this.setState({
                    loading: false,
                    tableList: [],
                    pagination: {
                        ...params.pagination,
                        total: 0,
                    },
                    sortField: "",
                    sortOrder: "",
                });
            }
        }

        /*         if (Boolean(this.state.type) && this.state.type.length > 0)
                    response = await handleRequest("GET", "/api/codes/type/" + this.state.type);
                else
                    response = await handleRequest("GET", "/api/codes/company");
        
                if (response.data.length !== 0) {
                    const list = response.data;
                    list.forEach(element => {
                        element.key = element.id
                    });
                    this.setState({
                        tableList: list,
                    });
                }
        
                if (response.data.length === 0) {
                    this.setState({
                        tableList: [],
                    });
                } */
    }
    createWhere(params) {
        var current = this.state.pagination.current;
        var pageSize = this.state.pagination.pageSize;
        var name = "", type = "", sortField = "", sortOrder = "";;
        if (Boolean(params) && Boolean(params.pagination)) {
            current = params.pagination.current;
            pageSize = params.pagination.pageSize;
            if (Boolean(params.pagination.name)) {
                name = params.pagination.name;
            }
            if (Boolean(params.pagination.type)) {
                type = params.pagination.type;
            }
            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }

        }
        const newObj = {
            id: null,
            name: name,
            type: type,
            firstResult: current - 1, //(current - 1) * pageSize,
            maxResults: pageSize,
            sortField: sortField,
            sortOrder: sortOrder,
        };
        return newObj;
    }

    setComboBoxes = async () => {
        const responseCmb = await handleRequest("GET", "/api/codes");
        var typeList = [];

        if (responseCmb.data.length !== 0) {
            const list = responseCmb.data;


            list.forEach(element => {
                if (!typeList.includes(element.type))
                    typeList.push(element.type);
            });
            this.setState({
                typeSelectItems: typeList,
            });
        }

        if (responseCmb.data.length === 0) {
            this.setState({
                typeSelectItems: [],
            });
        }
    }

    addItem = () => {

        const { typeSelectItems, manualType } = this.state;

        if (manualType !== null && manualType.length > 0)
            this.setState({
                typeSelectItems: [...typeSelectItems, manualType],
                manualType: null,
            });
    };

    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            id: null,
            name: null,
            shortName:null,
            type: null,

        });

        this.setState({
            id: null,
            name: null,
            shortName:null,
            type: null,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
    }

    cancel = () => {
        this.resetInputs();
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({
            sortField: sorter.columnKey,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };

    handleExportExcel = async () => {


        const { pagination } = this.state;
        const newObj = this.createWhere({ pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });

        let url = "/api/codes/export";
        var response = await handleRequest("POST", url, newObj);

        if (Boolean(response.data)) {
            this.setState({ excelData: response.data, exportClick: true })
        } else {
            message.error("File can not found.");
        }
    }
    handleUploadExcel = async (excelJson) => {
        try {
            var list = [];
            if (excelJson === null)
                showError("Select an excel file");
            else {
                excelJson.forEach(element => {
                    const obj = {
                        type: element["Type"],
                        name: element["Name"],
                        shortName : element["Short Name"]
                    }
                    list.push(obj);
                });

                if (Boolean(list) && list.length > 0) {

                    var response = await handleRequest("POST", "/api/codes/import", list);

                    if (response.type === "ERROR") {
                        error(response);
                    }
                    else {
                        this.resetInputs();
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                        showSuccess();
                        this.cancel();
                    }
                } else {
                    showError("No records to be added");
                }
            }
        } catch (error) {
            error();
        }
    }
    render() {
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };


        const columns = [
            {
                title: 'S/N', key: 'name', render: (value, row, index) => {
                    return index + 1 + (this.state.pagination.pageSize * (this.state.pagination.current - 1));
                },
                width: "5%",
            },
            {
                title: 'Type / Group', key: 'type', render: (record) => {
                    return record.type;
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input style={{ flex: 'auto' }} value={Boolean(this.state.pagination.type) ? this.state.pagination.type : ""} placeholder="Type"
                            onChange={this.onchangeType}
                        />
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: this.state.typeColor }} />,
                sorter: true,
            },
            {
                title: 'Name', key: 'name', render: (record) => {
                    return record.name;
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input style={{ flex: 'auto' }} value={Boolean(this.state.pagination.name) ? this.state.pagination.name : ""} placeholder="Name"
                            onChange={this.onchangeName}
                        />
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: this.state.nameColor }} />,
                sorter: true,
            },
            {
                title: 'Short Name', key: 'shortName', render: (record) => {
                    return record.shortName;
                },
               
            },
            {
                title: 'Update', key: 'key', render: (record) => {
                    return <EditIcon fontSize="default" color="primary" backgroundColor="black" onClick={() => this.edit(record)} />
                },
                width: "7%",
            },
            {
                title: 'Delete', key: 'key', render: (record) => {
                    return <DeleteIcon fontSize="default" color="error" onClick={() => this.deleteModal(record)} />
                },
                width: "7%",
            },


        ];

        const excelExport = <>
            {/* {this.state.exportClick ?
                this.setState({
                    exportClick: false
                }) : ""}
            {this.state.exportClick ?
                <ExcelFile filename="Type/Group Excel List" hideElement={true} >
                    <ExcelSheet data={this.state.excelData} name="Type">
                        <ExcelColumn label="Type" value="type" />
                        <ExcelColumn label="Name" value="name" />
                        <ExcelColumn label="Short Name" value="shortName" />
                    </ExcelSheet>
                </ExcelFile> :
                <AntButton onClick={this.handleExportExcel} title="Export to Excel" style={{ border: "0px" }} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
            } */}
        </>

        return <div className="card card-custom" style={{ padding: '2rem', minHeight: '100%' }} >

            <div>

                <Row>
                    <ColAnt xs={18}></ColAnt>

                    <ColAnt xs={2}>
                        {<ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>}
                    </ColAnt>
                    <ColAnt xs={2}>
                        {excelExport}
                    </ColAnt>
                    <ColAnt xs={2}>

                        {this.state.hideInputs &&
                            <AntButton title="Create New" style={{ border: "0px" }} icon={<CreateIcon hidden={!this.state.hideInputs} color="secondary" onClick={this.createNew} />}></AntButton>

                        }

                        {!this.state.hideInputs &&
                            <AntButton title="Hide Form" style={{ border: "0px" }} icon={<RemoveCircle hidden={this.state.hideInputs} color="error" onClick={this.stop} />}></AntButton>
                        }

                    </ColAnt>
                </Row>
            </div>

            <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                <FormAnt
                    initialValues={{ remember: false }}
                    onFinish={this.save}
                    ref={this.formRef}
                >

                    <FormAnt.Item
                        {...layout}
                        label={<FormattedMessage id="DefinitionsType" defaultMessage="Type" />}
                        style={{
                            marginBottom: 0,
                        }}
                        name="type"
                        rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type Type" /> }]} >
                        <RowAnt gutter={[8, 8]}>
                            <ColAnt span={12}>
                                <Select showSearch optionFilterProp="children" style={{ width: "100%" }} placeholder="Type" id="DefinitionsType" value={this.state.type} onChange={(value) => {
                                    this.formRef.current.setFieldsValue({
                                        type: value
                                    });
                                    this.setState({ type: value })
                                }}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input style={{ flex: 'auto' }} value={this.state.manualType} onChange={(e) => {
                                                    this.setState({
                                                        manualType: e.target.value,
                                                        dynamicInputs: "DefinitionsType"
                                                    });
                                                }} />
                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={this.addItem}   >
                                                    <PlusOutlined /> Add item
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    <Option key={null} value={null}>Select</Option>
                                    {this.state.typeSelectItems.map(i => (
                                        <Option key={i} value={i}>{i}</Option>
                                    ))}
                                </Select>
                            </ColAnt>
                        </RowAnt>
                    </FormAnt.Item>


                    <FormAnt.Item
                        {...layout}
                        label={<FormattedMessage id="DefinitionsDescription" defaultMessage="Description" />}
                        style={{
                            marginBottom: 0,
                        }}
                        name="name"
                        rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type Description" /> }]} >

                        <RowAnt gutter={[8, 8]}>
                            <ColAnt span={12}>
                                <Input placeholder="Description" style={{ width: "100%" }} id="DefinitionsDescription" value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value, dynamicInputs: "DefinitionsDescription" }) }} />
                            </ColAnt>
                        </RowAnt>

                    </FormAnt.Item>

                    <FormAnt.Item
                        {...layout}
                        label={<FormattedMessage id="DefinitionsDescription" defaultMessage="Description" />}
                        style={{
                            marginBottom: 0,
                        }}
                        name="shortName"
                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type Description" /> }]} >

                        <RowAnt gutter={[8, 8]}>
                            <ColAnt span={12}>
                                <Input placeholder="Short Name" style={{ width: "100%" }} id="shortName" value={this.state.shortName} onChange={(e) => { this.setState({ shortName: e.target.value }) }} />
                            </ColAnt>
                        </RowAnt>
                    </FormAnt.Item>

                    <div hidden={this.state.hideSave}>
                        {
                            <RowAnt gutter={8}>
                                <ColAnt span={8}></ColAnt>

                                <ColAnt span={8}>
                                    {
                                        <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                            variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                    }
                                </ColAnt>
                            </RowAnt>

                        }
                    </div>



                    <div hidden={this.state.hideUpdate}>
                        {
                            <Row>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                    {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                    <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                </Col>
                                <Col sm="3">
                                    {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                    <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Row>
                        }
                    </div>
                </FormAnt>
            </div>

            <Table

                columns={columns}
                style={{ marginTop: '3rem' }}
                size={`small`}
                dataSource={this.state.tableList}
                pagination={this.state.pagination}
                loading={this.state.loading}
                bordered
                scroll={{ x: 800, scrollToFirstRowOnChange: true }}
                onChange={this.handleTableChange} >

                {/* 
                        <Column title="S/N" key = "name" render={(value, row, index) => {

                            return  index + 1 + (this.state.pagination.pageSize * (this.state.pagination.current - 1));

                        }} />

                        <Column title="Type / Group" key = "name" dataIndex="type" sorter/>
                        <Column title="Description" dataIndex="name" sorter />
                        <Column  title = "Update"  render = {(record) => {
                                return <EditIcon fontSize="default" color="primary" backgroundColor="black" onClick={() => this.edit(record)} />
                            }}                       
                        />
                        <Column  title= 'Delete' render ={ (record) => {
                                return <DeleteIcon fontSize="default" color="error" onClick={() => this.deleteModal(record)} />
                            }}                        
                        /> */}
            </Table>





            <Modal
                show={this.state.modalDeleteShow}
                onHide={this.showOrHideDeleteModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure?</p>
                </Modal.Body>
                <Modal.Footer>
                    <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                    <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                </Modal.Footer>
            </Modal>

            <Modal
                show={this.state.modalMessage}
                onHide={this.showOrHideModalMessage}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <Row >
                            <Col sm="12">
                                {this.state.messageTip === "Success" &&
                                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                {this.state.messageTip === "Warning" &&
                                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                {this.state.messageTip === "Error" &&
                                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row >
                        <Col sm="12">
                            {this.state.message}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                </Modal.Footer>
            </Modal>

        </div>

    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(CodeDefine)




