import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { Form, Col, Row, Button as AntButton, Drawer, Input, DatePicker, Upload } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import { Select } from "antd";
import { API_BASE_ROOT, handleRequest } from "../../../ApiConnector";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;

const dataListService = [];
const generateListService = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataListService.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateListService(node.children);
    }
  }
};

const dataListMaterialType = [];
const generateListMaterialType = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataListMaterialType.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateListMaterialType(node.children);
    }
  }
};

export class PresentationCreate extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "PresentationCreate",
      controllerName: "presentation",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: false,
      tableList: [],
      type: null,
      categoryId: -1,
      descriptionId: -1,
      materialTypeId: -1,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      countrySelectItems: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();

    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      if (Boolean(this.props.selectedRow)) {
        let design = this.props.selectedRow;
        let uploadedFiles = [];
        let photoPath = Boolean(design) && Boolean(design.uploadPath) ? design.uploadPath.split(",") : [];
        if (Boolean(photoPath) && photoPath.length > 0) {
          photoPath.forEach((item, index) => {
            let obj = { uid: index, name: item.split("download/")[1], url: item, thumbUrl: item };
            uploadedFiles.push(obj);
          });
        }
        this.formRef.current.setFieldsValue({
          ...design,
          uploadPath: uploadedFiles,
          date: Boolean(design) && Boolean(design.date) ? moment(design.date) : null,
        });
      } else {
        this.formRef.current.resetFields();
      }
    }
  };

  fillComboboxes = async () => {
    var responseType = await handleRequest("GET", "/api/" + this.state.controllerName + "/type");
    if (responseType.type === "ERROR") {
      error(responseType);
    }
    this.setState({
      typeSelectItems: Boolean(responseType.data) ? responseType.data : [],
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = async (row) => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  projectDocumentsModal = row => {
    this.setState({
      modalProjectDocumentsShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.props.id,
    };

    let pathList = [];
    if (Boolean(newItem.uploadPath))
      newItem.uploadPath.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) pathList.push(item.response.url);
        else if (Boolean(item.url)) pathList.push(item.url);
      });
    newItem.uploadPath = pathList.toString();

    console.log("newItem", newItem);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.props.close();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };

    const propsUpload = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="PresentationCreate" hideElement={true}>
            <ExcelSheet name="PresentationCreate" data={this.state.tableList}>
              <ExcelColumn label="PresentationCreate" value="serviceStockCardLog" />
              <ExcelColumn label="Rev No" value="revNo" />
              <ExcelColumn label="Date" value="date" />
              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="Client" value="clientName" />
              <ExcelColumn label="Contractor" value="contractorName" />
              <ExcelColumn label="Project Description" value="projectDescription" />
              <ExcelColumn label="Client Project No" value="clientProjectNo" />
              <ExcelColumn label="Project Scope" value="projectScope" />
              <ExcelColumn label="Project Details" value="projectDetails" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>

          <div hidden={false}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PresentationCreate.Name" defaultMessage="Name" />}
                name="name"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PresentationCreate.Date" defaultMessage="Date" />}
                name="date"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PresentationCreate.Type" defaultMessage="Type" />}
                name="type"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["typeSelectItems"]) &&
                    this.state["typeSelectItems"].length > 0 &&
                    this.state["typeSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="PresentationCreate.Upload" defaultMessage={"Upload"} />}
                name="uploadPath"
                rules={[{ required: true, message: "Missing Load" }]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload {...propsUpload} showUploadList={true} maxCount={1}>
                  <AntButton icon={<UploadOutlined />} style={{ width: "100%", marginTop: "0.2rem" }}>
                    <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                  </AntButton>
                </Upload>
              </Form.Item>
            }

            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={4} lg={4} xl={4} xxl={4}></Col>
                  <Col xs={24} sm={24} md={20} lg={20} xl={20} xxl={20}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Drawer
          title={<FormattedMessage id="PresentationCreate.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"PresentationCreate"} />
        </Drawer>

      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PresentationCreate);