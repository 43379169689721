import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import EquipmentCalibrationNew from "./EquipmentCalibrationNew";
import EquipmentInsurance from "./EquipmentInsurance";
import EquipmentVehicleLicence from "./EquipmentVehicleLicence";
import EquipmentMaintenance from "./EquipmentMaintenance";
import EquipmentPart from "./EquipmentPart";

export function EquipmentLogMainDrawer({ equipmentLogId }) {
  console.log("equipmentLogId", equipmentLogId);
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  const [selectedTab, setSelectedTab] = React.useState("overall");
  useEffect(() => {
    return () => { };
  }, [user]);

  return (
    <>
      {user && (
        <div className="d-flex flex-row">
          <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
            <div className="card card-custom card-stretch">
              {/* begin::Body */}
              <div className="card-body pt-4">
                {/* begin::Toolbar */}
                <div className="d-flex justify-content-end"></div>
                {/* end::Toolbar */}
                {/* begin::Nav */}
                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogMainDrawer.Overall" defaultMessage="Overall" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "overall" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("overall");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text">
                          <FormattedMessage id="EquipmentLogMainDrawer.Overall" defaultMessage="Overall" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogMainDrawer.Assigment" defaultMessage="Assigment" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "assigment" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("assigment");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="EquipmentLogMainDrawer.Assigment" defaultMessage="Assigment" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>                  
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogMainDrawer.SourceOfPower" defaultMessage="Source of Power" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "sourceOfPower" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("sourceOfPower");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="EquipmentLogMainDrawer.sourceOfPower" defaultMessage="Source of Power" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogMainDrawer.PurposeOfUse" defaultMessage="Purpose Of Use" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "purposeOfUse" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("purposeOfUse");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="EquipmentLogMainDrawer.purposeOfUse" defaultMessage="Purpose Of Use" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>                  
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogMainDrawer.Attachments" defaultMessage="Attachments" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "attachments" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("attachments");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="EquipmentLogMainDrawer.attachments" defaultMessage="Attachments" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogMainDrawer.Attachments" defaultMessage="Performance Criterias" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "performanceCriterias" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("performanceCriterias");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="EquipmentLogMainDrawer.performanceCriterias" defaultMessage="Performance Criterias" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                  
                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogMainDrawer.SpareParts" defaultMessage="Spare Parts" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "spareParts" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("spareParts");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="EquipmentLogMainDrawer.spareParts" defaultMessage="Spare Parts" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogMainDrawer.PartList" defaultMessage="Part List" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "partList" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("partList");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Article.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="EquipmentLogMainDrawer.PartList" defaultMessage="Part List" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogMainDrawer.Licence" defaultMessage="Licence" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "licence" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("licence");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="EquipmentLogMainDrawer.Licence" defaultMessage="Licence" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogMainDrawer.Insurance" defaultMessage="Insurance" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "insurance" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("insurance");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="EquipmentLogMainDrawer.Insurance" defaultMessage="Insurance" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogMainDrawer.PeriodicControls" defaultMessage="Periodic Controls" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "periodicControls" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("periodicControls");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="EquipmentLogMainDrawer.PeriodicControls" defaultMessage="Periodic Controls" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogMainDrawer.Calibration" defaultMessage="Calibration" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "calibration" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("calibration");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="EquipmentLogMainDrawer.Calibration" defaultMessage="Calibration" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogMainDrawer.Maintenance" defaultMessage="Maintenance" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "maintenance" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("maintenance");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Article.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="EquipmentLogMainDrawer.Maintenance" defaultMessage="Maintenance" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>

                  <div className="navi-item mb-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          <FormattedMessage id="EquipmentLogMainDrawer.Statistics" defaultMessage="Statistics" />
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className={`navi-link py-4 ${selectedTab === "statistics" ? "active-link" : ""}`}
                        onClick={() => {
                          setSelectedTab("statistics");
                        }}
                      >
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>{" "}
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">
                          <FormattedMessage id="EquipmentLogMainDrawer.Statistics" defaultMessage="Statistics" />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                </div>
                {/* end::Nav */}
              </div>
              {/* end::Body */}
            </div>
          </div>
          <div className="flex-row-fluid ml-lg-8">
            {selectedTab === "overall" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">

                  </div>
                </div>
              </div>
            )}

            {selectedTab === "assigment" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">

                  </div>
                </div>
              </div>
            )}

{selectedTab === "sourceOfPower" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    
                  </div>
                </div>
              </div>
            )}

{selectedTab === "purposeOfUse" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    
                  </div>
                </div>
              </div>
            )}

{selectedTab === "attachments" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    
                  </div>
                </div>
              </div>
            )}

{selectedTab === "performanceCriterias" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    
                  </div>
                </div>
              </div>
            )}


{selectedTab === "sparePart" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "partList" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    {
                      <EquipmentPart parentId={equipmentLogId} parentName={""}
                        nextTab={() => setSelectedTab("licence")}
                      />
                    }
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "licence" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    {
                      <EquipmentVehicleLicence parentId={equipmentLogId} parentName={""}
                        nextTab={() => setSelectedTab("insurance")}
                      />
                    }
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "insurance" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    {
                      <EquipmentInsurance parentId={equipmentLogId} parentName={""}
                        nextTab={() => setSelectedTab("calibration")}
                      />
                    }
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "calibration" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    {
                      <EquipmentCalibrationNew parentId={equipmentLogId} parentName={""}
                        nextTab={() => setSelectedTab("maintenance")}
                      />
                    }
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "maintenance" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    {
                      <EquipmentMaintenance parentId={equipmentLogId} parentName={""}
                        nextTab={() => setSelectedTab("statistics")}
                      />
                    }
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "statistics" && (
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">


                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
