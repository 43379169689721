import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { downloadMultipleFile } from "../helper/UploadDownloadHelper";
import {
  Form,
  Table,
  Input,
  Select,
  InputNumber,
  Checkbox,
  Radio,
  Divider,
  DatePicker,
  Cascader,
  TimePicker,
  Switch,
  Button as AntButton,
  Col,
  Row,
  Modal as AntModal
} from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import { PlusOutlined, CloseOutlined, CheckOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { DownloadOutlined } from "@ant-design/icons";
import { FcSettings } from "react-icons/fc";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class Stock extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "stock",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      countryIdSelectItems: null,
      markerPosition: { lat: 26.2844367, lng: 50.1888217 },

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseCompanyProjectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/companyProjectId");
    if (responseCompanyProjectId.type === "ERROR") {
      error(responseCompanyProjectId);
    } else {
      this.setState({
        companyProjectIdSelectItems: Boolean(responseCompanyProjectId.data) ? responseCompanyProjectId.data : []
      });
    }

    var responsecountryId = await handleRequest("GET", "/api/countries/selectItems");
    if (responsecountryId.type === "ERROR") {
      error(responsecountryId);
    } else {
      this.setState({
        countryIdSelectItems: Boolean(responsecountryId.data) ? responsecountryId.data : []
      });
    }

    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree");

    if (responseTree.data.length !== 0) {
      console.log(responseTree.data);

      generateList(responseTree.data);
      this.setState({
        nodes: responseTree.data
      });
    }

    this.fillCode("Stock_StockType");
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      markerPosition: { lat: 26.2844367, lng: 50.1888217 },

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];

    let fileUrl = Boolean(row.fileUrl) ? row.fileUrl.split(",") : [];
    if (Boolean(fileUrl) && fileUrl.length > 0) {
      fileUrl.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileList.push(obj);
      });
    }

    var parent = dataList.find(p => p.key === row.parentId);
    let parentId = [];

    if (Boolean(parent)) {
      parentId = parent.lineage;
    }

    this.formRef.current.setFieldsValue({
      ...row,
      parentId: parentId,
      fileUrl: fileList
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/stock/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/stock/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    var latitude = Boolean(this.state.markerPosition) ? this.state.markerPosition.lat : "";
    var longitude = Boolean(this.state.markerPosition) ? this.state.markerPosition.lng : "";

    let fileUrl = [];
    if (Boolean(values.fileUrl)) {
      values.fileUrl.forEach(item => {
        if (Boolean(item.response) && Boolean(item.response.url)) fileUrl.push(item.response.url);
        else if (Boolean(item.url)) fileUrl.push(item.url);
      });
    }

    const newItem = {
      ...values,
      id: this.state.id,
      latitude: latitude,
      longitude: longitude,
      fileUrl: fileUrl.toString(),
      parentId: Boolean(values.parentId) ? values.parentId[values.parentId.length - 1] : null
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/stock", newItem);
    } else {
      response = await handleRequest("PUT", "/api/stock/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      this.fillComboboxes();
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/stock/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  onMarkerPositionChanged = e => {
    this.setState({
      markerPosition: { lat: e.latLng.lat(), lng: e.latLng.lng() }
    });
  };

  saveStockShelf = stockShelf => {
    this.setState({
      showMultipleDimensionModal: false,
      stockShelf: stockShelf
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const mapContainerStyle = {
      height: "300px",
      width: "100%"
    };

    const listUploadProps = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }

      return e && e.fileList;
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="Stock.Branch" defaultMessage="Branch" />,
        key: "companyProjectId",
        render: record => {
          return record.companyProjectName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.companyProjectId) ? this.state.pagination.companyProjectId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  companyProjectId: e.target.value,
                  companyProjectIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.companyProjectIdColor) ? this.state.pagination.companyProjectIdColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Stock.parentStockName" defaultMessage="Parent Stock" />,
        key: "parentStockName",
        render: record => {
          return record.parentStockName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.parentStockName) ? this.state.pagination.parentStockName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  parentStockName: e.target.value,
                  parentStockNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.parentStockNameColor) ? this.state.pagination.parentStockNameColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Stock.stockName" defaultMessage="Stock Name" />,
        key: "stockName",
        render: record => {
          return record.stockName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.stockName) ? this.state.pagination.stockName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  stockName: e.target.value,
                  stockNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.stockNameColor) ? this.state.pagination.stockNameColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Stock.description" defaultMessage="Description" />,
        key: "description",
        render: record => {
          return record.description;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.description) ? this.state.pagination.description : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  description: e.target.value,
                  descriptionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.descriptionColor) ? this.state.pagination.descriptionColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Stock.stockTypeName" defaultMessage="Stock Type" />,
        key: "stockTypeName",
        render: record => {
          return record.stockTypeName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.stockTypeName) ? this.state.pagination.stockTypeName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  stockTypeName: e.target.value,
                  stockTypeNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.stockTypeNameColor) ? this.state.pagination.stockTypeNameColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Stock.countryName" defaultMessage="Country" />,
        key: "countryName",
        render: record => {
          return record.countryName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.countryName) ? this.state.pagination.countryName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  countryName: e.target.value,
                  countryNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.countryNameColor) ? this.state.pagination.countryNameColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Stock.city" defaultMessage="City" />,
        key: "city",
        render: record => {
          return record.city;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.city) ? this.state.pagination.city : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  city: e.target.value,
                  cityColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.cityColor) ? this.state.pagination.cityColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="Stock.address" defaultMessage="Address" />,
        key: "address",
        render: record => {
          return record.address;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.address) ? this.state.pagination.address : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  address: e.target.value,
                  addressColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.addressColor) ? this.state.pagination.addressColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="Stock.remarks" defaultMessage="Remarks" />,
        key: "remarks",
        render: record => {
          return record.remarks;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.remarks) ? this.state.pagination.remarks : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  remarks: e.target.value,
                  remarksColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.remarksColor) ? this.state.pagination.remarksColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Stock.fileUrl" defaultMessage="File" />,
        key: "fileUrl",
        render: record => {
          if (Boolean(record.fileUrl))
            return (
              <AntButton
                style={{
                  marginLeft: "2px",
                  marginTop: "-2px",
                  alignContent: "center"
                }}
                onClick={() => downloadMultipleFile(record.fileUrl)}
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
              ></AntButton>
            );
        }
      },
      {
        title: "Last Update",
        key: "lastUpdated",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Stock.Branch" defaultMessage="Branch" />}
                name="companyProjectId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["companyProjectIdSelectItems"]) &&
                    this.state["companyProjectIdSelectItems"].length > 0 &&
                    this.state["companyProjectIdSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.projectFullName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Stock.parentId" defaultMessage="Parent Stock" />}
                tooltip={<FormattedMessage id="Stock.parentId.w" defaultMessage="Parent stock of defined stock." />}
                name="parentId"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Cascader changeOnSelect style={{ width: "100%" }} options={this.state.nodes} />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Stock.stockName" defaultMessage="Stock Name" />}
                name="stockName"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input placeholder="Stock Name" style={{ width: "100%" }} />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Stock.description" defaultMessage="Description" />}
                name="description"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input placeholder="Description" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Stock.stockTypeId" defaultMessage="Stock Type" />}
                name="stockTypeId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Stock Type"
                  optionFilterProp="children"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={e => {
                            this.setState({
                              codeName: e.target.value
                            });
                          }}
                        />
                        <AntButton type="link" icon={<PlusOutlined />} onClick={() => this.addCode("Stock_StockType")}>
                          Add New
                        </AntButton>
                      </div>
                    </div>
                  )}
                >
                  {Boolean(this.state["Stock_StockTypeSelectItems"]) &&
                    this.state["Stock_StockTypeSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="CashOut.invoice" defaultMessage="Google Maps" />}
                name="googleMaps"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox
                  onChange={e => {
                    this.setState({
                      googleMapsVisible: e.target.checked
                    });
                  }}
                />
              </Form.Item>
            }
            {this.state.googleMapsVisible && (
              <>
                <LoadScript id="script-loader" googleMapsApiKey="AIzaSyBdXzd0sXo2ZjL-GaYs4RtKRUM10KCauIc">
                  <GoogleMap id="marker-example" mapContainerStyle={mapContainerStyle} zoom={8} center={this.state.markerPosition}>
                    <Marker position={this.state.markerPosition} draggable={true} onDragEnd={this.onMarkerPositionChanged} />
                  </GoogleMap>
                </LoadScript>
                <br />
              </>
            )}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Stock.countryId" defaultMessage="Country" />}
                name="countryId"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select showSearch optionFilterProp="children" style={{ width: "100%" }} placeholder="Country">
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["countryIdSelectItems"]) &&
                    this.state["countryIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Stock.city" defaultMessage="City" />}
                name="city"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input placeholder="City" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Stock.address" defaultMessage="Address" />}
                name="address"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input placeholder="Address" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Stock.remarks" defaultMessage="Remarks" />}
                name="remarks"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input placeholder="Remarks" style={{ width: "100%" }} />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProcurementRequirements.fileUrl" defaultMessage="File" />}
                name={"fileUrl"}
                rules={[{ required: false, message: "Missing Load" }]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload {...listUploadProps} name="file" maxCount="1">
                  <AntButton icon={<UploadOutlined />}> Upload </AntButton>
                </Upload>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Stock.IdentifyTheShelfs" defaultMessage="Identify the shelfs" />}
                name="IdentifyTheShelfs"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox
                  onChange={e => {
                    this.setState({
                      showDimensionModal: e.target.checked
                    });
                  }}
                ></Checkbox>
              </Form.Item>
            }
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            rowKey="id"
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <AntModal
          centered
          width={1300}
          title="Stock Shelfs"
          visible={this.state.showDimensionModal}
          onCancel={() => {
            this.setState({ showDimensionModal: false });
          }}
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  showDimensionModal: false
                });
              }}
            >
              Close
            </Button>
          ]}
        >
          <Form initialValues={{ remember: false }} onFinish={this.saveMaterial} onFinishFailed={onFinishFailed} ref={this.formRefCreateMaterial}>
            {
              <Form.List name="chemicalCompositionDetailList">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered responsive="xl">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ChemicalCompositionDetail.gradeId"} defaultMessage={"Shelf No"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ChemicalCompositionDetail.elementId"} defaultMessage={"Shelf Description"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ChemicalCompositionDetail.materialQuantity"} defaultMessage={"Stock Items"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ChemicalCompositionDetail.minContent"} defaultMessage={"Coordinates"} />
                            </th>
                            <th colSpan="1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "shelfNo"]}>
                                    <InputNumber
                                      noStyle
                                      // style={{ width: "15%" }}
                                      min={0}
                                      parser={value => value.replace(",", ".")}
                                      formatter={value => value.replace(".", ",")}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "shelfDescription"]}>
                                    <Input style={{ width: "100%" }} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "stockItemsId"]}
                                    style={{
                                      display: "inline-block",
                                      marginBottom: "5px",
                                      marginLeft: "5px"
                                    }}
                                    rules={[
                                      {
                                        required: false,
                                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                      }
                                    ]}
                                  >
                                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                      {Boolean(this.state["stockItemsIdSelectItems"]) &&
                                        this.state["stockItemsIdSelectItems"].length > 0 &&
                                        this.state["stockItemsIdSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "thickness1"]}>
                                    <AntButton
                                      style={{ border: "0px" }}
                                      onClick={() => this.setState({ showStocksTreeStockCoordinatesModal: true })}
                                      icon={<FcSettings />}
                                    ></AntButton>
                                  </Form.Item>
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}

                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"ChemicalCompositionDetail.AddNewMaterial"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            }
          </Form>

          <AntModal
            title={<FormattedMessage id="StocksTree.Coordinates" defaultMessage="Coordinates" />}
            keyboard={false}
            maskClosable={false}
            open={this.state.showStocksTreeStockCoordinatesModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showStocksTreeStockCoordinatesModal: false })}
            onOk={() => this.formRefStocksTreeStockCoordinates.current.submit()}
            width={1300}
            centered
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveStocksTreeStockCoordinates} red={this.formRefStocksTreeStockCoordinates}>
              <br></br>
              <br></br>
              <br />{" "}
              <h4>
                <FormattedMessage id={"StocksTree.Coordinates"} defaultMessage={"  "} />
              </h4>
              <Form.List name="coordinates">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            {/* POINT */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.Point"} defaultMessage={"Point"} />
                            </th>
                            {/* N */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.N"} defaultMessage={"N"} />
                            </th>
                            {/* E */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.E"} defaultMessage={"E"} />
                            </th>
                            {/* EL */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.EL"} defaultMessage={"El"} />
                            </th>
                            {/* CONNECTION TYPE */}
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"StocksTree.ConnectionType"} defaultMessage={"Connection Type"} />
                            </th>
                            <th colSpan="1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                {/* POINT QUANTITY */}
                                <td>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="pointQuantity"
                                    rules={[
                                      { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={1}
                                      max={this.state.availableQuantity}
                                    />
                                  </Form.Item>
                                </td>
                                {/* N QUANTITY */}
                                <td>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="nQuantity"
                                    rules={[
                                      { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={1}
                                      max={this.state.availableQuantity}
                                    />
                                  </Form.Item>
                                </td>
                                {/* E QUANTITY */}
                                <td>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="eQuantity"
                                    rules={[
                                      { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={1}
                                      max={this.state.availableQuantity}
                                    />
                                  </Form.Item>
                                </td>
                                {/* EL QUANTITY */}
                                <td>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="elQuantity"
                                    rules={[
                                      { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber
                                      style={{
                                        width: "100%"
                                      }}
                                      min={1}
                                      max={this.state.availableQuantity}
                                    />
                                  </Form.Item>
                                </td>
                                {/* CONNECTION TYPE */}
                                <td>
                                  <Form.Item name="connectionTypeId">
                                    <Select
                                      allowClear
                                      showSearch
                                      style={{ width: "100%" }}
                                      placeholder=""
                                      onChange={value => {
                                        this.setState({ answerType: value });
                                      }}
                                    >
                                      {Boolean(this.state["connectionTypeIdSelectItems"]) &&
                                        this.state["connectionTypeIdSelectItems"].length > 0 &&
                                        this.state["connectionTypeIdSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                {/* MINUSCIRCLEOUTLINED */}
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}

                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"StocksTree.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          </AntModal>
        </AntModal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Stock);
