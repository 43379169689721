import React, { useEffect } from "react";

function PresentationPage1(props) {
    const [companyName, setCompanyName] = React.useState(Boolean(props.companyName) ? props.companyName : null);

    useEffect(() => {
        //setLoadUrl(props.apiUrl);
        //setCompanyName()
        //console.log("PresentationPage1 : " + JSON.stringify(user))
        //const getData = async () => {
        //  await handleRequest("POST", url, newObj);
        //};
        //getData().catch(console.error);
    }, []);

    return (
        <div>
            <span className="headerText">{companyName}</span>
            <hr />
            <div>
                <div style={{ 'float': 'left' }}>
                    <div style={{
                        "font-family": "Public Sans Thin",
                        "font-size": "60px",
                        "margin-top": '100px'
                    }}>Business
                    </div>
                    <div style={{
                        "position": "relative",
                        "font-family": "Public Sans Thin",
                        "font-size": "60px",
                    }}>Plan
                    </div>
                    <div style={{
                        "position": "relative",
                        "font-family": "Public Sans",
                        "font-size": "60px",
                        "font-weight": "bolder"
                    }}>2025
                    </div>
                    <div style={{
                        "position": "relative",
                        "font-family": "Public Sans",
                        "font-weight": "bolder"
                    }}>
                    </div>
                </div>
            </div>
            <div className="row">
                <div style={{ "font-family": "Public Sans", "font-size": "28px", "margin-top": "400px", 'float': 'left' }}>
                    Where we want to take our company
                </div>
            </div>

        </div>
    );
}

export default PresentationPage1;
