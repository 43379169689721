import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, showWarning, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import moment from "moment";
import { Form, Table, Col, Row, Button as AntButton, Badge, Input, InputNumber, DatePicker, Checkbox, Spin } from "antd";
import { Tooltip, Skeleton, Steps } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Select } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { PlusOutlined, MinusCircleOutlined, ExclamationCircleOutlined, } from "@ant-design/icons";
import RfqReplyPaymentTermsBaseOn from "./RfqReplyPaymentTermsBaseOn";
import "./style.css";
import DraggableModal from "../../Components/DraggableModal";

const { Option } = Select;
const { Step } = Steps;

export class RfqReplyPaymentTerms extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "RfqReplyPaymentTerms",
      controllerName: "rfqReplyPaymentTerms",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      stepList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      saveLoading: false,
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsebasedOn = await handleRequest("GET", "/api/" + this.state.controllerName + "/basedOn");
    if (responsebasedOn.type === "ERROR") {
      error(responsebasedOn);
    }
    this.setState({
      basedOnSelectItems: Boolean(responsebasedOn.data) ? responsebasedOn.data : []
    });
    var responsedocumentationSteps = await handleRequest("GET", "/api/" + this.state.controllerName + "/documentationSteps");
    if (responsedocumentationSteps.type === "ERROR") {
      error(responsedocumentationSteps);
    }
    this.setState({
      documentationStepsSelectItems: Boolean(responsedocumentationSteps.data) ? responsedocumentationSteps.data : []
    });
    var responsemanufacturingSteps = await handleRequest("GET", "/api/" + this.state.controllerName + "/manufacturingSteps");
    if (responsemanufacturingSteps.type === "ERROR") {
      error(responsemanufacturingSteps);
    }
    this.setState({
      manufacturingStepsSelectItems: Boolean(responsemanufacturingSteps.data) ? responsemanufacturingSteps.data : []
    });

    var responseBankAccounts = await handleRequest("GET", "/api/" + this.state.controllerName + "/bankAccounts");
    if (responseBankAccounts.type === "ERROR") {
      error(responseBankAccounts);
    } else {
      this.setState({
        bankAccountsSelectItems: Boolean(responseBankAccounts.data) ? responseBankAccounts.data : []
      });
    }

    var responseCashLog = await handleRequest("GET", "/api/" + this.state.controllerName + "/cashLogs");
    if (responseCashLog.type === "ERROR") {
      error(responseCashLog);
    } else {
      this.setState({
        cashLogSelectItems: Boolean(responseCashLog.data) ? responseCashLog.data : []
      });
    }

    var responsePeriod = await handleRequest("GET", "/api/" + this.state.controllerName + "/periodTypes");
    if (responsePeriod.type === "ERROR") {
      error(responsePeriod);
    } else {
      this.setState({
        periodSelectItems: Boolean(responsePeriod.data) ? responsePeriod.data : []
      });
    }

    var responsepaymentType = await handleRequest("GET", "/api/" + this.state.controllerName + "/paymentTypes");
    if (responsepaymentType.type === "ERROR") {
      error(responsepaymentType);
    } else {
      this.setState({
        paymentTypeSelectItems: Boolean(responsepaymentType.data) ? responsepaymentType.data : []
      });
    }
    var responsepaymentMode = await handleRequest("GET", "/api/" + this.state.controllerName + "/paymentModes");
    if (responsepaymentMode.type === "ERROR") {
      error(responsepaymentMode);
    } else {
      this.setState({
        paymentModeSelectItems: Boolean(responsepaymentMode.data) ? responsepaymentMode.data : []
      });
    }
    var responsescheduleType = await handleRequest("GET", "/api/" + this.state.controllerName + "/scheduleTypes");
    if (responsescheduleType.type === "ERROR") {
      error(responsescheduleType);
    } else {
      this.setState({
        scheduleTypeSelectItems: Boolean(responsescheduleType.data) ? responsescheduleType.data : []
      });
    }
    var responsescheduleAmountType = await handleRequest("GET", "/api/" + this.state.controllerName + "/scheduleAmountTypes");
    if (responsescheduleAmountType.type === "ERROR") {
      error(responsescheduleAmountType);
    } else {
      this.setState({
        scheduleAmountTypeSelectItems: Boolean(responsescheduleAmountType.data) ? responsescheduleAmountType.data : []
      });
    }
    var responsepaymentTermsPeriodType = await handleRequest("GET", "/api/" + this.state.controllerName + "/paymentTermsPeriodType");
    if (responsepaymentTermsPeriodType.type === "ERROR") {
      error(responsepaymentTermsPeriodType);
    }
    this.setState({
      paymentTermsPeriodTypeSelectItems: Boolean(responsepaymentTermsPeriodType.data) ? responsepaymentTermsPeriodType.data : []
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      }, () => {
        this.calcTotalBalanceAmount();
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        }, () => {
          this.calcTotalBalanceAmount();
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        }, () => {
          this.calcTotalBalanceAmount();
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      rfqVendorExpectedPriceId: this.props.selectedOurExpectedPrice.rfqVendorExpectedPriceId,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let scheduleList = [];
    if (Boolean(row.scheduleList)) {
      scheduleList = row.scheduleList.map((item, index) => {
        return {
          ...item,
          key: index,
          id: null,
          date: Boolean(item.date) ? moment(item.date) : null
        };
      });
    }
    this.formRef.current.setFieldsValue({
      ...row,
      paymentDate: Boolean(row.paymentDate) ? moment(row.paymentDate) : null,
      firstDateForPeriod: Boolean(row.firstDateForPeriod) ? moment(row.firstDateForPeriod) : null,
      scheduleList: scheduleList
    });

    let basedOn = null;
    if (Boolean(this.state["basedOnSelectItems"]) && this.state["basedOnSelectItems"].length > 0 && Boolean(row.basedOnId)) {
      let basedOnItem = this.state["basedOnSelectItems"].find(p => p.key === row.basedOnId);
      if (Boolean(basedOnItem)) {
        basedOn = basedOnItem.value;
      }
    }

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.index,
      scheduleType: row.scheduleType,
      scheduleAmountType: row.scheduleAmountType,
      amount: row.amount,
      fixedAmount: row.fixedAmount,
      period: row.period,
      firstDateForPeriod: Boolean(row.firstDateForPeriod) ? moment(row.firstDateForPeriod) : null,
      paymentMode: row.paymentMode,
      paymentType: row.paymentType,
      basedOn: basedOn,
      paymentTerms: row.paymentTerms,
    });
  };

  delete = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const tableList = this.state.tableList.filter((item) => item.index !== this.state.id);
      this.setState({
        id: null,
        modalDeleteShow: false,
        tableList: tableList,
      }, () => {
        showSuccess();
        this.calcTotalBalanceAmount();
      });
    } else {
      showError();
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.index
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.calcTotalBalanceAmount();
    this.setState({
      id: null,
      scheduleType: null,
      scheduleAmountType: null,
      amount: null,
      fixedAmount: null,
      period: null,
      firstDateForPeriod: null,
      paymentMode: null,
      paymentType: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    this.setState({ saveLoading: true });
    console.log("values", values);
    if (this.state.paymentType === "MULTIPLE") {
      if (Boolean(values.scheduleList) && values.scheduleList.length > 0) {
        let totalAmount = 0;
        values.scheduleList.forEach(item => {
          totalAmount += item.amount;
        });

        if (totalAmount !== values.amount) {
          showError("The total amount  must be equal to the amount in the schedule list");
          this.setState({ saveLoading: false });
          return;
        }
      } else {
        showError("Please add schedule list");
        this.setState({ saveLoading: false });
        return;
      }
    } else values.scheduleList = [{ amount: values.amount, date: values.paymentDate }];

    if (Boolean(this.state.id) && this.state.id > 0) {
      const itemIndex = this.state.tableList.findIndex(p => p.index === this.state.id);
      const updatedItem = {
        ...this.state.tableList[itemIndex],
        ...values
      };
      this.setState({
        tableList: [
          ...this.state.tableList.slice(0, itemIndex),
          updatedItem,
          ...this.state.tableList.slice(itemIndex + 1)
        ]
      }, () => {
        showSuccess();
        this.cancel();
        this.calcTotalBalanceAmount();
        this.setState({ saveLoading: false });
      });
    } else {
      let index = 1;
      if (Boolean(this.state.tableList) && this.state.tableList.length > 0 &&
        Boolean(this.state.tableList[this.state.tableList.length - 1]) && this.state.tableList[this.state.tableList.length - 1] > 0) {
        index = this.state.tableList[this.state.tableList.length - 1] + 1;
      }

      const newItems = this.state.tableList.slice();
      values.index = index;
      newItems.unshift(values);
      this.setState({
        tableList: newItems
      }, () => {
        showSuccess();
        this.cancel();
        this.calcTotalBalanceAmount();
        this.setState({ saveLoading: false });
      });
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };
  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };


  setPaymentTerms = () => {
    console.log("balance", this.state.balance);
    if (this.state.balance > 0) {
      showError("You must enter the full payment schedule");
    } else {
      this.props.setPaymentTerms(this.state.tableList);
    }
  };

  cancelPaymentTerms = () => {
    if (Boolean(this.state.tableList) && this.state.tableList.length > 0) {
      if (this.state.balance > 0) {
        this.setState({
          modalCancelInformationShow: true,
        });
      } else {
        this.props.setPaymentTerms(this.state.tableList);
      }
    } else {
      this.props.setPaymentTerms([]);
    }
  };

  cancelPaymentTermsOK = () => {
    this.setState({
      modalCancelInformationShow: false,
    });
    this.props.setPaymentTerms([]);
  };

  createElementWithPeriodAndAmount = (period, firstDateForPeriod, index, fixedAmount) => {
    let element = { key: index, date: null, amount: fixedAmount };

    if (Boolean(period) && Boolean(firstDateForPeriod)) {
      let firstDate = firstDateForPeriod.toDate();
      switch (period) {
        case "DAILY":
          element.date = moment(firstDate).add(index, "days");

          break;
        case "WEEKLY":
          element.date = moment(firstDate).add(index, "weeks");
          break;
        case "MONTHLY":
          element.date = moment(firstDate).add(index, "months");
          break;
        case "YEARLY":
          element.date = moment(firstDate).add(index, "years");
          break;

        default:
          break;
      }
    }

    return element;
  };

  calculateScheduleList = async () => {
    const { scheduleType, scheduleAmountType, amount, fixedAmount, period, firstDateForPeriod } = this.state;

    if (!Boolean(scheduleType)) {
      showWarning("Please select schedule type");
      return;
    }

    if (!Boolean(scheduleAmountType)) {
      showWarning("Please select schedule amount type");
      return;
    }

    if (scheduleAmountType === "FIXED") {
      if (!Boolean(amount)) {
        showWarning("Please enter amount");
        return;
      }

      if (!Boolean(fixedAmount)) {
        showWarning("Please enter fixed amount");
        return;
      }
    }

    if (scheduleAmountType === "VARIABLE") {
      if (!Boolean(amount)) {
        showWarning("Please enter amount");
        return;
      }
    }

    if (scheduleType === "PERIODIC") {
      if (!Boolean(period)) {
        showWarning("Please select period");
        return;
      }

      if (!Boolean(firstDateForPeriod)) {
        showWarning("Please select first date for period");
        return;
      }
    }

    switch (scheduleType) {
      case "VARIABLEDATES":
        if (Boolean(scheduleAmountType) && scheduleAmountType === "FIXED") {
          if (Boolean(amount) && Boolean(fixedAmount)) {
            let k = amount % fixedAmount;
            let scheduleListCount = Math.ceil(amount / fixedAmount);
            let scheduleList = [];
            if (k > 0) scheduleListCount = scheduleListCount - 1;
            for (let index = 0; index < scheduleListCount; index++) {
              const element = { key: index, date: null, amount: fixedAmount };
              scheduleList.push(element);
            }

            if (k > 0) {
              const element = { key: scheduleListCount, date: null, amount: k };
              scheduleList.push(element);
            }

            this.formRef.current.setFieldsValue({
              scheduleList: scheduleList
            });
          }
          this.setState({
            showScheduleButtons: false
          });
        }

        if (Boolean(scheduleAmountType) && scheduleAmountType === "VARIABLE") {
          this.setState({
            showScheduleButtons: true
          });
        }

        break;

      case "PERIODIC":
        if (Boolean(scheduleAmountType) && scheduleAmountType === "FIXED") {
          if (Boolean(amount) && Boolean(fixedAmount)) {
            let k = amount % fixedAmount;
            let scheduleListCount = Math.ceil(amount / fixedAmount);
            let scheduleList = [];
            if (k > 0) scheduleListCount = scheduleListCount - 1;
            for (let index = 0; index < scheduleListCount; index++) {
              const element = this.createElementWithPeriodAndAmount(period, firstDateForPeriod, index, fixedAmount);
              scheduleList.push(element);
            }

            if (k > 0) {
              const element = this.createElementWithPeriodAndAmount(period, firstDateForPeriod, scheduleListCount, k);
              scheduleList.push(element);
            }
            this.formRef.current.setFieldsValue({
              scheduleList: scheduleList
            });
          }
          this.setState({
            showScheduleButtons: false
          });
        }

        if (Boolean(scheduleAmountType) && scheduleAmountType === "VARIABLE") {
          this.setState({
            showScheduleButtons: true
          });
        }

        break;

      default:
        break;
    }
  };

  addNewSchedule = async () => {
    const { scheduleType, scheduleAmountType, amount, fixedAmount, period, firstDateForPeriod } = this.state;

    if (!Boolean(scheduleType)) {
      showWarning("Please select schedule type");
      return;
    }

    if (!Boolean(scheduleAmountType)) {
      showWarning("Please select schedule amount type");
      return;
    }

    if (scheduleAmountType === "FIXED") {
      if (!Boolean(amount)) {
        showWarning("Please enter amount");
        return;
      }

      if (!Boolean(fixedAmount)) {
        showWarning("Please enter fixed amount");
        return;
      }
    }

    if (scheduleAmountType === "VARIABLE") {
      if (!Boolean(amount)) {
        showWarning("Please enter amount");
        return;
      }
    }

    if (scheduleType === "PERIODIC") {
      if (!Boolean(period)) {
        showWarning("Please select period");
        return;
      }

      if (!Boolean(firstDateForPeriod)) {
        showWarning("Please select first date for period");
        return;
      }
    }
    let scheduleList = Boolean(this.formRef.current.getFieldValue("scheduleList")) ? this.formRef.current.getFieldValue("scheduleList") : [];

    if (scheduleType === "VARIABLEDATES") {
      const element = { key: scheduleList.length, date: null, amount: null };
      scheduleList.push(element);

      this.formRef.current.setFieldsValue({
        scheduleList: scheduleList
      });
    }

    if (scheduleType === "PERIODIC") {
      const element = this.createElementWithPeriodAndAmount(
        period,
        firstDateForPeriod,
        Boolean(scheduleList) && scheduleList.length > 0 ? scheduleList.length : 0,
        null
      );
      scheduleList.push(element);

      this.formRef.current.setFieldsValue({
        scheduleList: scheduleList
      });
    }
  };

  onBaseClick = () => {
    this.setState({
      modalBaseOnShow: true,
    });
  };

  closeRfqReplyPaymentTermsBaseOn = () => {
    this.setState({
      modalBaseOnShow: false,
    });
    this.fillComboboxes();
  };

  calcTotalBalanceAmount() {
    let stepList = [];
    const { tableList } = this.state;
    console.log("XXX", tableList);
    var total = this.props.selectedOurExpectedPrice.quantity * this.props.selectedOurExpectedPrice.unitPrice;
    var scheduled = 0;
    var balance = 0;
    var lastAddition = 0;
    if (Boolean(tableList) && tableList.length > 0) {
      lastAddition = Boolean(tableList[0].amount) ? tableList[0].amount : 0;
      tableList.forEach(element => {
        element.total = total;
        if (Boolean(element.amount)) {
          scheduled += element.amount;
          element.balance = total - element.amount;
        }
        if (Boolean(element.scheduleList) && element.scheduleList.length > 0) {
          element.scheduleList.forEach(schedule => {
            schedule.description = element.description;
            schedule.date = new Date(schedule.date);
            stepList.push(schedule);
          });
        }

      });
    }

    console.log("total", total);
    console.log("scheduled", scheduled);
    balance = total - scheduled;
    console.log("balance", balance);

    let totalValue = total.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ');
    let balanceValue = balance.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ');

    this.formRef.current.setFieldsValue({
      total: totalValue,
      balance: balanceValue,
      scheduled: scheduled.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' '),
      lastAddition: lastAddition.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' '),
    });

    this.sortByDate(stepList);

    this.setState({
      total: Number(totalValue.replace(/\s/g, '')),
      balance: Number(balanceValue.replace(/\s/g, '')),
    });
  }

  sortByDate = (stepList) => {
    const newItems = stepList.slice();
    newItems.sort((a, b) => {
      return a.date.getTime() - b.date.getTime();
    });
    this.setState({
      stepList: newItems
    });
  }

  getRfqReplyPaymentDate = async () => {
    console.log("props", this.props);
    let paymentDate = null;
    if (Boolean(this.props) && Boolean(this.props.requestForQuotationId) &&
      Boolean(this.props.requestForQuotationId) && this.props.requestForQuotationId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/rfqReplyPaymentDate/" + this.props.requestForQuotationId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        paymentDate = Boolean(response.data) && Boolean(response.data.paymentDate) ? moment(response.data.paymentDate) : null

        if (!Boolean(paymentDate) && Boolean(this.state.paymentDate)) {
          paymentDate = moment(this.state.paymentDate);
        }

        this.setState({
          disabledPaymentDate: Boolean(paymentDate) ? true : false,
        });
      }
    }

    this.formRef.current.setFieldsValue({
      paymentDate: paymentDate,
      scheduleType: null,
      scheduleAmountType: null,
      period: null,
      firstDateForPeriod: null,
      scheduleList: null
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layoutSchedule = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      style: {
        marginBottom: 0
      }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 8,
          offset: 0
        },
        sm: {
          span: 8,
          offset: 8
        }
      }
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="RfqReplyPaymentTerms.PaymentNo" defaultMessage="Payment No" />,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="RfqReplyPaymentTerms.PaymentDescription" defaultMessage="Payment Description" />,
        key: "description",
        render: record => {
          return record.description;
        }, ...getColumnFilter({
          inputName: "description", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="RfqReplyPaymentTerms.PaymentDate" defaultMessage="Payment Date" />,
        key: "paymentDate",
        render: record => {
          if (Boolean(record.paymentCalcDate)) {
            return moment(record.paymentCalcDate).format("DD-MM-YYYY");
          } else {
            let paymentCalcDate = moment(record.paymentDate);
            if (record.paymentTermsPeriodType && record.paymentTermsValue > 0) {
              if (record.paymentTermsPeriodType === 'Day') {
                paymentCalcDate = paymentCalcDate.add(record.paymentTermsValue, 'days');
              } else if (record.paymentTermsPeriodType === 'Month') {
                paymentCalcDate = paymentCalcDate.add(record.paymentTermsValue, 'months');
              } else if (record.paymentTermsPeriodType === 'Week') {
                paymentCalcDate = paymentCalcDate.add(record.paymentTermsValue, 'weeks');
              } else if (record.paymentTermsPeriodType === 'Year') {
                paymentCalcDate = paymentCalcDate.add(record.paymentTermsValue, 'years');
              }
            }
            if (Boolean(paymentCalcDate)) {
              return paymentCalcDate.format("DD-MM-YYYY");
            }
          }
        }, ...getColumnFilter({
          inputName: "paymentDate", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="RfqReplyPaymentTerms.PaymentTerms" defaultMessage="Payment Terms" />,
        key: "paymentTerms",
        render: record => {
          if (Boolean(record.paymentTerms)) {
            if (record.paymentTerms === "Po_Sign_Date") {
              return "PO Sign Date";
            } else if (record.paymentTerms === "Po_Sign_Date_Plus") {
              return "PO Sign Date +";
            } else if (record.paymentTerms === "Submittal") {
              return "Submittal";
            } else if (record.paymentTerms === "Approval") {
              return "Approval";
            } else if (record.paymentTerms === "Quality_Control") {
              return "Quality Control";
            } else if (record.paymentTerms === "Quality_Control_Plus") {
              return "Quality Control +";
            }
          }
          return record.paymentTerms;
        }, ...getColumnFilter({
          inputName: "paymentTerms", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="RfqReplyPaymentTerms.PaymentAmount" defaultMessage="Payment Amount" />,
        key: "amount",
        render: record => {
          return Boolean(record.amount) ? record.amount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ') : "";
        }, ...getColumnFilter({
          inputName: "amount", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: "%",
        key: "amountPercent",
        render: record => {
          return record.amountPercent;
        }, ...getColumnFilter({
          inputName: "amountPercent", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="RfqReplyPaymentTerms.Total" defaultMessage="Total" />,
        key: "total",
        render: record => {
          return Boolean(record.total) ? record.total.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ') : "";
        },
      },
      {
        title: <FormattedMessage id="RfqReplyPaymentTerms.Balance" defaultMessage="Balance" />,
        key: "balance",
        render: record => {
          return Boolean(record.balance) ? record.balance.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ') : "";
        },
      },
    ];

    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
      {this.state.exportClick ? (<ExcelFile filename="RfqReplyPaymentTerms" hideElement={true}>
        <ExcelSheet name="RfqReplyPaymentTerms" data={this.state.tableList}>
          <ExcelColumn label="Based on" value="basedOn" />
        </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} 
        icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)}   */}
    </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="rfqReplyPaymentTerms.pageTitle" defaultMessage="Payment Terms" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={21}></Col>
              <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />}  ></AntButton>
                <AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />} ></AntButton>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col md={12}>
                <div hidden={this.state.hideInputs}>
                  <Row>
                    <Col span={4}>
                      <Form.Item
                        name="total"
                        extra={<FormattedMessage id="ProjectTakeOffQuantity.Total" defaultMessage="Total" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber className="w-100" readOnly />
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={4}>
                      <Form.Item
                        name="scheduled"
                        extra={<FormattedMessage id="ProjectTakeOffQuantity.Scheduled" defaultMessage="Scheduled" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber className="w-100" readOnly />
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={4}>
                      <Form.Item
                        name="balance"
                        extra={<FormattedMessage id="ProjectTakeOffQuantity.Balance" defaultMessage="Balance" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber className="w-100" readOnly />
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={4}>
                      <Form.Item
                        name="lastAddition"
                        extra={<FormattedMessage id="ProjectTakeOffQuantity.LastAddition" defaultMessage="Last Addition" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber className="w-100" readOnly />
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={4}>
                      <Button style={{ width: "100%" }} variant="warning" onClick={this.onBaseClick}>
                        <FormattedMessage id="RfqReplyPaymentTerms.Base" defaultMessage="Base" />
                      </Button>
                    </Col>
                  </Row>

                  {this.state.balance > 0 &&
                    <>
                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="RfqReplyPaymentTerms.amount" defaultMessage="Payment Amount" />}
                        name="amount"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber
                          parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")}
                          min={0}
                          max={this.state.balance}
                          onChange={value => {
                            this.setState({
                              amount: value,
                            }, () => {
                              const result = (parseFloat(value) / parseFloat(this.state.total)) * 100;
                              if (Boolean(result)) {
                                this.formRef.current.setFieldsValue({
                                  amountPercent: parseFloat(result.toFixed(2)),
                                });
                              } else {
                                this.formRef.current.setFieldsValue({
                                  amountPercent: null,
                                });
                              }
                            });
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="RfqReplyPaymentTerms.amountPercent" defaultMessage="Amount %" />}
                        name="amountPercent"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber
                          parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")}
                          min={0} max={100}
                          onChange={value => {
                            this.setState({
                              discountPercent: value,
                            }, () => {
                              const result = (parseFloat(value) * parseFloat(this.state.total)) / 100;
                              if (Boolean(result)) {
                                this.formRef.current.setFieldsValue({
                                  amount: parseFloat(result.toFixed(2)),
                                });
                              } else {
                                this.formRef.current.setFieldsValue({
                                  amount: null,
                                });
                              }
                            });
                          }}></InputNumber>
                      </Form.Item>

                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="RfqReplyPaymentTerms.description" defaultMessage="Payment Description" />}
                        name="description"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input id="RfqReplyPaymentTermsDescription"></Input>
                      </Form.Item>

                      <Form.Item
                        {...layout}
                        label={<FormattedMessage id="RfqReplyPaymentTerms.BasisofPayment" defaultMessage="Basis of Payment" />}
                        name="basedOnId"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Select allowClear showSearch
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          onChange={value => {
                            let basedOn = null;
                            if (Boolean(this.state["basedOnSelectItems"]) && this.state["basedOnSelectItems"].length > 0 && Boolean(value)) {
                              let basedOnItem = this.state["basedOnSelectItems"].find(p => p.key === value);
                              if (Boolean(basedOnItem)) {
                                basedOn = basedOnItem.value;
                              }
                            }
                            this.setState({
                              basedOn: basedOn
                            });

                            this.formRef.current.setFieldsValue({
                              documentationSteps: null,
                              manufacturingSteps: null,
                              quantityRated: null,
                              previousPaymentExcluded: null,
                            });
                          }}
                        >
                          {Boolean(this.state["basedOnSelectItems"]) && this.state["basedOnSelectItems"].length > 0 &&
                            this.state["basedOnSelectItems"].map(i => (
                              <Option key={i.key} value={i.key}>
                                {i.value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      {Boolean(this.state.basedOn) &&
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="RfqReplyPaymentTerms.paymentTerms" defaultMessage="Payment Terms" />}
                          name="paymentTerms"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select allowClear showSearch
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            onChange={value =>
                              this.setState({
                                paymentTerms: value
                              })
                            }
                          >
                            {this.state.basedOn === "Payment" &&
                              <>
                                <Option key={"Po_Sign_Date"} value={"Po_Sign_Date"}>{"PO Sign Date"}</Option>
                                <Option key={"Po_Sign_Date_Plus"} value={"Po_Sign_Date_Plus"}>{"PO Sign Date +"}</Option>
                              </>
                            }
                            {this.state.basedOn === "Documentation Steps" &&
                              <>
                                <Option key={"Submittal"} value={"Submittal"}>{"Submittal"}</Option>
                                <Option key={"Approval"} value={"Approval"}>{"Approval"}</Option>
                              </>
                            }
                            {this.state.basedOn === "Manufacturing Steps" &&
                              <>
                                <Option key={"Quality_Control"} value={"Quality_Control"}>{"Quality Control"}  </Option>
                                <Option key={"Quality_Control_Plus"} value={"Quality_Control_Plus"}>{"Quality Control +"}</Option>
                              </>
                            }
                            {this.state.basedOn === "Receiving Quantity" &&
                              <>
                                <Option key={"Quality_Control"} value={"Quality_Control"}>{"Quality Control"}  </Option>
                                <Option key={"Quality_Control_Plus"} value={"Quality_Control_Plus"}>{"Quality Control +"}</Option>
                              </>
                            }
                          </Select>
                        </Form.Item>}
                      {Boolean(this.state.paymentTerms) && (this.state.paymentTerms === "Po_Sign_Date_Plus" || this.state.paymentTerms === "Quality_Control_Plus") &&
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="RfqReplyPaymentTerms.paymentTermsPeriod" defaultMessage="Period" />}
                          style={{ marginBottom: "5px" }}
                        >
                          <Row gutter={8}>
                            <Col span={10}>
                              <Form.Item
                                name="paymentTermsValue"
                                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                              >
                                <InputNumber parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")}></InputNumber>
                              </Form.Item>
                            </Col>
                            <Col span={2}>

                            </Col>
                            <Col span={10}>
                              <Form.Item
                                name="paymentTermsPeriodType"
                                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                              >
                                <Select allowClear showSearch
                                  style={{ width: "100%" }}
                                  optionFilterProp="children"
                                >
                                  {Boolean(this.state["paymentTermsPeriodTypeSelectItems"]) && this.state["paymentTermsPeriodTypeSelectItems"].length > 0 &&
                                    this.state["paymentTermsPeriodTypeSelectItems"].map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                      }


                      {Boolean(this.state.basedOn) && this.state.basedOn === "Documentation Steps" &&
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="RfqReplyPaymentTerms.documentationSteps" defaultMessage="Documentation Steps" />}
                          name="documentationSteps"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select allowClear showSearch
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                          >
                            {Boolean(this.state["documentationStepsSelectItems"]) && this.state["documentationStepsSelectItems"].length > 0 &&
                              this.state["documentationStepsSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>}
                      {Boolean(this.state.basedOn) && this.state.basedOn === "Manufacturing Steps" &&
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="RfqReplyPaymentTerms.manufacturingSteps" defaultMessage="Manufacturing Steps" />}
                          name="manufacturingSteps"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select allowClear showSearch
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                          >
                            {Boolean(this.state["manufacturingStepsSelectItems"]) && this.state["manufacturingStepsSelectItems"].length > 0 &&
                              this.state["manufacturingStepsSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      }

                      {Boolean(this.state.basedOn) && (this.state.basedOn === "Manufacturing Steps" || this.state.basedOn === "Receiving Quantity") &&
                        <>
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="PageFormInput.QuantityRated" defaultMessage="Quantity Rated" />}
                            name="quantityRated"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            valuePropName="checked"
                          >
                            <Checkbox></Checkbox>
                          </Form.Item>

                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="PageFormInput.PreviousPaymentExcluded" defaultMessage="Previous Payment Excluded" />}
                            name="previousPaymentExcluded"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            valuePropName="checked"
                          >
                            <Checkbox></Checkbox>
                          </Form.Item>
                        </>
                      }

                      {
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="RfqReplyPaymentTerms.paymentType" defaultMessage="Payment Type" />}
                          name="paymentType"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            onChange={value => {
                              this.setState({
                                paymentType: value,
                                disabledPaymentDate: false
                              }, () => {
                                let paymentDate = null;
                                if (Boolean(this.state.tableList) && this.state.tableList.length > 0) {
                                  paymentDate = moment(this.state.tableList[0].paymentDate);
                                }
                                if (Boolean(paymentDate)) {
                                  this.setState({
                                    disabledPaymentDate: true
                                  });
                                  this.formRef.current.setFieldsValue({
                                    paymentDate: paymentDate,
                                    scheduleType: null,
                                    scheduleAmountType: null,
                                    period: null,
                                    firstDateForPeriod: null,
                                    scheduleList: null
                                  });
                                } else {
                                  if (Boolean(this.state.paymentType) && this.state.paymentType === "ONETIME") {
                                    this.getRfqReplyPaymentDate();
                                  }
                                }
                              });
                            }}
                          >
                            {Boolean(this.state["paymentTypeSelectItems"]) && this.state["paymentTypeSelectItems"].length > 0 &&
                              this.state["paymentTypeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      }

                      {Boolean(this.state.paymentType) && this.state.paymentType === "ONETIME" && (
                        <Form.Item
                          {...layout}
                          label={Boolean(this.state.basedOn) && this.state.basedOn === "Payment" ? "Estimated PO Date" : "Estimated Delivery Date"}
                          name="paymentDate"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <DatePicker format="DD-MM-YYYY" disabled={this.state.disabledPaymentDate}
                            onChange={async value => {
                              this.setState({
                                paymentDate: Boolean(value) ? value : null,
                              });
                            }} />
                        </Form.Item>
                      )}

                      {Boolean(this.state.paymentType) && this.state.paymentType === "MULTIPLE" && (
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="RfqReplyPaymentTerms.scheduleType" defaultMessage="Schedule Type" />}
                          name="scheduleType"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            onChange={value => {
                              this.setState({
                                scheduleType: value,
                                period: null,
                                firstDateForPeriod: null
                              });
                              this.formRef.current.setFieldsValue({
                                period: null,
                                firstDateForPeriod: null,
                                scheduleList: null
                              });
                            }}
                          >
                            {Boolean(this.state["scheduleTypeSelectItems"]) && this.state["scheduleTypeSelectItems"].length > 0 &&
                              this.state["scheduleTypeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      )}
                      {Boolean(this.state.paymentType) && this.state.paymentType === "MULTIPLE" && (
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="RfqReplyPaymentTerms.scheduleAmountType" defaultMessage="Schedule Amount Type" />}
                          name="scheduleAmountType"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            onChange={value => {
                              this.setState({
                                scheduleAmountType: value,
                                fixedAmount: null
                              });

                              this.formRef.current.setFieldsValue({
                                fixedAmount: null,
                                scheduleList: null
                              });
                            }}
                          >
                            {Boolean(this.state["scheduleAmountTypeSelectItems"]) && this.state["scheduleAmountTypeSelectItems"].length > 0 &&
                              this.state["scheduleAmountTypeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      )}
                      {Boolean(this.state.paymentType) &&
                        this.state.paymentType === "MULTIPLE" &&
                        Boolean(this.state.scheduleAmountType) &&
                        this.state.scheduleAmountType === "FIXED" && (
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="RfqReplyPaymentTerms.fixedAmount" defaultMessage="Fixed Amount" />}
                            name="fixedAmount"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <InputNumber
                              parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")}
                              min={0}
                              onChange={value => {
                                this.setState({
                                  fixedAmount: value
                                });

                                this.formRef.current.setFieldsValue({
                                  scheduleList: null
                                });
                              }}
                            />
                          </Form.Item>
                        )}
                      {Boolean(this.state.paymentType) &&
                        this.state.paymentType === "MULTIPLE" &&
                        Boolean(this.state.scheduleType) &&
                        this.state.scheduleType === "PERIODIC" && (
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="RfqReplyPaymentTerms.period" defaultMessage="Period" />}
                            name="period"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <Select
                              allowClear
                              style={{ width: "100%" }}
                              onChange={value => {
                                this.setState({
                                  period: value
                                });
                                this.formRef.current.setFieldsValue({
                                  scheduleList: null
                                });
                              }}
                            >
                              {Boolean(this.state["periodSelectItems"]) && this.state["periodSelectItems"].length > 0 &&
                                this.state["periodSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        )}
                      {Boolean(this.state.paymentType) &&
                        this.state.paymentType === "MULTIPLE" &&
                        Boolean(this.state.scheduleType) &&
                        this.state.scheduleType === "PERIODIC" && (
                          <Form.Item
                            {...layout}
                            label={<FormattedMessage id="RfqReplyPaymentTerms.firstDateForPeriod" defaultMessage="First Date For Period" />}
                            name="firstDateForPeriod"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          >
                            <DatePicker
                              onChange={date => {
                                this.setState({
                                  firstDateForPeriod: date
                                });
                                this.formRef.current.setFieldsValue({
                                  scheduleList: null
                                });
                              }}
                              format="DD-MM-YYYY"
                            />
                          </Form.Item>
                        )}
                      {Boolean(this.state.paymentType) && this.state.paymentType === "MULTIPLE" && (
                        <Form.List name="scheduleList">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map((field, index) => (
                                <Form.Item {...layoutSchedule} label={<FormattedMessage id="RfqReplyPaymentTerms.Payment" defaultMessage={index + 1 + ". Payment"} />}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "date"]}
                                    extra={<FormattedMessage id="RfqReplyPaymentTerms.schedule.date" defaultMessage="Date" />}
                                    style={{
                                      display: "inline-block",
                                      width: "calc(30%)",
                                      marginBottom: "5px"
                                    }}
                                    rules={[
                                      { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <DatePicker format="DD-MM-YYYY" />
                                  </Form.Item>

                                  <Form.Item
                                    {...field}
                                    name={[field.name, "amount"]}
                                    extra={<FormattedMessage id="RfqReplyPaymentTerms.schedule.amount" defaultMessage="Amount" />}
                                    style={{
                                      display: "inline-block",
                                      width: "calc(30%)",
                                      marginBottom: "5px"
                                    }}
                                    rules={[
                                      { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                    ]}
                                  >
                                    <InputNumber parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                                  </Form.Item>

                                  <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} />
                                </Form.Item>
                              ))}
                              <Form.Item {...tailFormItemLayout}>
                                {Boolean(this.state.scheduleAmountType) && this.state.scheduleAmountType === "VARIABLE" && (
                                  <AntButton type="dashed" onClick={() => this.addNewSchedule()} style={{ width: "100%" }} icon={<PlusOutlined />}>
                                    <FormattedMessage id="RfqReplyPaymentTerms.AddnewSchedule" defaultMessage=" Add new schedule" />
                                  </AntButton>
                                )}

                                {Boolean(this.state.scheduleAmountType) && this.state.scheduleAmountType === "FIXED" && (
                                  <AntButton type="dashed" onClick={() => this.calculateScheduleList()} style={{ width: "100%" }} icon={<PlusOutlined />}>
                                    <FormattedMessage id="RfqReplyPaymentTerms.CalculateSchedule" defaultMessage=" Calculate schedule" />
                                  </AntButton>
                                )}
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      )}

                      {
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="RfqReplyPaymentTerms.modeOfPayment" defaultMessage="Mode Of Payment" />}
                          name="paymentMode"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select allowClear style={{ width: "100%" }}
                            onChange={value => {
                              this.setState({
                                paymentMode: value
                              });
                              this.formRef.current.setFieldsValue({
                                bankAccountsLogId: null,
                                cashLogId: null
                              });
                            }}>
                            {Boolean(this.state["paymentModeSelectItems"]) && this.state["paymentModeSelectItems"].length > 0 &&
                              this.state["paymentModeSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      }

                      {Boolean(this.state.paymentMode) && this.state.paymentMode === "BANK" && (
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="RfqReplyPaymentTerms.bankAccountsLogId" defaultMessage="Bank Account" />}
                          name="bankAccountsLogId"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select allowClear style={{ width: "100%" }} placeholder="Bank Account">
                            {Boolean(this.state["bankAccountsSelectItems"]) && this.state["bankAccountsSelectItems"].length > 0 &&
                              this.state["bankAccountsSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      )}
                      {Boolean(this.state.paymentMode) && this.state.paymentMode === "CASH" && (
                        <Form.Item
                          {...layout}
                          label={<FormattedMessage id="RfqReplyPaymentTerms.cashLogId" defaultMessage="Cash Log" />}
                          name="cashLogId"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select allowClear style={{ width: "100%" }} placeholder="Cash Log">
                            {Boolean(this.state["cashLogSelectItems"]) && this.state["cashLogSelectItems"].length > 0 &&
                              this.state["cashLogSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      )}

                      <div hidden={this.state.hideSave}>
                        {<Row gutter={[16, 16]}>
                          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                            <Spin spinning={this.state.saveLoading}>
                              <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                              </Button>
                            </Spin>
                          </Col>
                          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                        </Row>
                        }
                      </div>
                      <div hidden={this.state.hideUpdate}>
                        {<Row gutter={[16, 16]}>
                          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                            <Spin spinning={this.state.saveLoading}>
                              <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                                <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                              </Button>
                            </Spin>
                          </Col>
                          <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                        </Row>}
                      </div>
                    </>}
                </div>
              </Col>
              <Col md={12}>
                <div hidden={this.state.hideInputs} className="divSbtHeight">
                  <div className="card card-custom" style={{ padding: "0.1rem", minHeight: "100%" }}>
                    <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 ml-5 align-content-center">
                      <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                      <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                      <Skeleton loading={this.state.loading} avatar active title paragraph={{ rows: 1 }} />
                      <Steps direction="vertical" className="pl-5-custom anticon-custom">
                        {Boolean(this.state.stepList) &&
                          this.state.stepList.length > 0 &&
                          this.state.stepList.map((item, index) => {
                            return (
                              <Step
                                description={item.description + " - " + item.amount}
                                status="finish"
                                title={(Boolean(item.date)) ? moment(item.date).format("DD-MM-YYYY") : ""}
                              />
                            );
                          })}
                      </Steps>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (<div className="alert alert-custom alert-light">                 <div className="alert-icon">                   <FilterOutlined />                 </div>                 <div className="alert-text">                   {this.state.searchCriteriaModels.map((val, i) => { return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">                         <span className="mr-2">{val.label}</span>                         <strong className="mr-2">{val.type}</strong>                         <span className="mr-2">{val.operationValue}</span>                         <span className="mr-2">{val.showValue}</span>                         <span>                           <Tooltip title="Remove">                             <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />                           </Tooltip>                         </span>                       </div>); })}                 </div>                 <div className="alert-close">                   <Tooltip title="Remove All">                     <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />                   </Tooltip>                 </div>               </div>)}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              rowKey="index"
              bordered scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns} dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          {<Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={18} lg={18} xl={18} xxl={18}></Col>
            <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
              <Button id="CloseButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancelPaymentTerms}>
                <FormattedMessage id="GeneralButtonCancelAndClose" defaultMessage="Cancel And Close" />
              </Button>
            </Col>
            <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
              <Button id="OKButton" style={{ width: "100%" }} variant="success" onClick={this.setPaymentTerms}>
                <FormattedMessage id="GeneralButtonSaveAndClose" defaultMessage="Save And Close" />
              </Button>
            </Col>
          </Row>}
          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>           <Modal.Header closeButton>             <Modal.Title id="contained-modal-title-vcenter">               <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />             </Modal.Title>           </Modal.Header>           <Modal.Body>             <p>               <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />             </p>           </Modal.Body>           <Modal.Footer>             <Button variant="secondary" onClick={this.showOrHideDeleteModal}>               <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />             </Button>             <Button variant="danger" onClick={this.delete}>               <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />             </Button>           </Modal.Footer>         </Modal>

          <DraggableModal
            open={this.state.modalBaseOnShow}
            onCancel={() => {
              this.setState({ modalBaseOnShow: false });
            }}
            width={800}
            centered
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalBaseOnShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}

            content={
              <RfqReplyPaymentTermsBaseOn random={Math.random()} closeRfqReplyPaymentTermsBaseOn={this.closeRfqReplyPaymentTermsBaseOn}></RfqReplyPaymentTermsBaseOn>
            }
          >

          </DraggableModal>

          <DraggableModal
            title={
              <span>
                <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  <FormattedMessage id="RfqReplyPaymentTerms.DoYouWantToContinue " defaultMessage="Do you want to continue ?" />
                </span>
              </span>
            }
            centered
            open={this.state.modalCancelInformationShow}
            onOk={() => this.cancelPaymentTermsOK()}
            onCancel={() => this.setState({ modalCancelInformationShow: false })}
            okText={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />}
            cancelText={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
            content={
              <FormattedMessage id="RfqReplyPaymentTerms.MessageCancelInformation" defaultMessage="The payments you have entered will be cancelled." />
            }
          >

          </DraggableModal>
        </div>
      </Badge.Ribbon >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(RfqReplyPaymentTerms);
