import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Select } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Modal as AntModal } from "antd";
import { ActivityPlanningTableChild } from "./ActivityPlanningTableChild";

const { Option } = Select;

export class ActivityPlanning extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ActivityPlanning",
      controllerName: "activityPlanning",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };
  fillComboboxes = async () => {
    var responsedisciplineId = await handleRequest("GET", "/api/" + this.state.controllerName + "/disciplineId");
    if (responsedisciplineId.type === "ERROR") {
      error(responsedisciplineId);
    }
    this.setState({
      disciplineIdSelectItems: Boolean(responsedisciplineId.data) ? responsedisciplineId.data : []
    });
    var responsephaseId = await handleRequest("GET", "/api/" + this.state.controllerName + "/phaseId");
    if (responsephaseId.type === "ERROR") {
      error(responsephaseId);
    }
    this.setState({
      phaseIdSelectItems: Boolean(responsephaseId.data) ? responsephaseId.data : []
    });
    var responseactivityId = await handleRequest("GET", "/api/" + this.state.controllerName + "/activityId");
    if (responseactivityId.type === "ERROR") {
      error(responseactivityId);
    }
    this.setState({
      activityIdSelectItems: Boolean(responseactivityId.data) ? responseactivityId.data : []
    });
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let ActivityPlanningTableChildAction = {
      name: "ActivityPlanningTableChild",
      icon: <EditOutlined fontSize="small" color="secondary" />,
      actionClick: this.ActivityPlanningTableChildModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    actionList.push(ActivityPlanningTableChildAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  ActivityPlanningTableChildModal = record => {
    this.setState({
      showActivityPlanningTableChildModal: true,
      selectedActivityPlanningTableChild: record
    });
  };
  ActivityPlanningTableChildModal = record => {
    this.setState({
      showActivityPlanningTableChildModal: true,
      selectedActivityPlanningTableChild: record
    });
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ActivityPlanning.disciplineId" defaultMessage="discipline" />,
        key: "disciplineId",
        render: record => {
          return record.disciplineName;
        }, ...getColumnFilter({
          inputName: "disciplineName", inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ActivityPlanning.phaseId" defaultMessage="phase" />,
        key: "phaseId",
        render: record => {
          return record.phaseName;
        }, ...getColumnFilter({
          inputName: "phaseName", inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ActivityPlanning.activityId" defaultMessage="activity" />,
        key: "activityId",
        render: record => {
          return record.activityName;
        }, ...getColumnFilter({
          inputName: "activityName", inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
    ];
    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="ActivityPlanning" hideElement={true}>             <ExcelSheet name="ActivityPlanning" data={this.state.tableList}>               <ExcelColumn label="discipline" value="disciplineName" />
      <ExcelColumn label="phase" value="phaseName" />
      <ExcelColumn label="activity" value="activityName" />
    </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} 
    icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)}    */}
    </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="activityPlanning.pageTitle" defaultMessage="Activity Planning" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>             <Col md={21}></Col>             <Col md={1}>{excelExport}</Col>             <Col md={1}>               <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>             </Col>             <Col md={1}>               <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />}               ></AntButton>               <AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />}               ></AntButton>             </Col>           </Row>
            <div hidden={this.state.hideInputs}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ActivityPlanning.disciplineId" defaultMessage="Discipline" />}
                name="disciplineId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                >
                  {Boolean(this.state["disciplineIdSelectItems"]) && this.state["disciplineIdSelectItems"].length > 0 &&
                    this.state["disciplineIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ActivityPlanning.phaseId" defaultMessage="Phase" />}
                name="phaseId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                >
                  {Boolean(this.state["phaseIdSelectItems"]) && this.state["phaseIdSelectItems"].length > 0 &&
                    this.state["phaseIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ActivityPlanning.activityId" defaultMessage="Activity" />}
                name="activityId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                >
                  {Boolean(this.state["activityIdSelectItems"]) && this.state["activityIdSelectItems"].length > 0 &&
                    this.state["activityIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              <div hidden={this.state.hideSave}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>                     <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">                       <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
              <div hidden={this.state.hideUpdate}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>                       <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />                     </Button>                   </Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">                       <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (<div className="alert alert-custom alert-light">                 <div className="alert-icon">                   <FilterOutlined />                 </div>                 <div className="alert-text">                   {this.state.searchCriteriaModels.map((val, i) => { return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">                         <span className="mr-2">{val.label}</span>                         <strong className="mr-2">{val.type}</strong>                         <span className="mr-2">{val.operationValue}</span>                         <span className="mr-2">{val.showValue}</span>                         <span>                           <Tooltip title="Remove">                             <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />                           </Tooltip>                         </span>                       </div>); })}                 </div>                 <div className="alert-close">                   <Tooltip title="Remove All">                     <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />                   </Tooltip>                 </div>               </div>)}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              rowKey="id"
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
              expandable={{
                expandedRowRender: record => Boolean(record) &&
                  <ActivityPlanningTableChild selectedActivityPlanningTableChild={record} random={Math.random()}></ActivityPlanningTableChild>,
                rowExpandable: () => {
                  return true;
                }
              }} />
          </div>
          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>           <Modal.Header closeButton>             <Modal.Title id="contained-modal-title-vcenter">               <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />             </Modal.Title>           </Modal.Header>           <Modal.Body>             <p>               <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />             </p>           </Modal.Body>           <Modal.Footer>             <Button variant="secondary" onClick={this.showOrHideDeleteModal}>               <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />             </Button>             <Button variant="danger" onClick={this.delete}>               <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />             </Button>           </Modal.Footer>         </Modal>
          <AntModal
            centered
            width={1500}
            title={<FormattedMessage id="ActivityPlanning.ActivityPlanningTableChild" defaultMessage="ActivityPlanningTableChild" />}
            open={this.state.showActivityPlanningTableChildModal}
            onCancel={() => {
              this.setState({ showActivityPlanningTableChildModal: false });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showActivityPlanningTableChildModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
          >
            <ActivityPlanningTableChild selectedActivityPlanningTableChild={this.state.selectedActivityPlanningTableChild} random={Math.random()}></ActivityPlanningTableChild>
          </AntModal>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ActivityPlanning);
