import React from "react";

import "./Item.css";

import {
  TextFields,
  Mail,
  Phone,
  VpnKey,
  TextFormat,
  CheckBox,
  RadioButtonChecked,
  DateRange,
  AccessTime,
  Event,
  Timer,
  CalendarToday,
  CheckCircle,
  Menu,
  EventNote,
  Description,
  Today,
  ArrowDropDown,
  PlaylistAdd,
  LooksOne
} from "@material-ui/icons";

const Item = ({ id, dragOverlay }) => {
  const style = {
    cursor: dragOverlay ? "grabbing" : "grab",
    width: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  };

  const iconByValue = value => {
    switch (value) {
      case "Text":
        return <TextFields />;
      case "Select":
        return <ArrowDropDown />;
      case "Select Addable":
        return <PlaylistAdd />;
      case "Mail":
        return <Mail />;
      case "Phone":
        return <Phone />;
      case "Password":
        return <VpnKey />;
      case "Number":
        return <LooksOne />;
      case "Text Area":
        return <Description />;
      case "Text Editor":
        return <TextFormat />;
      case "Checkbox":
        return <CheckBox />;
      case "Radio":
        return <RadioButtonChecked />;
      case "Date Picker":
        return <Today />;
      case "Datepicker With Time":
        return <Event />;
      case "Time Picker":
        return <AccessTime />;
      case "Month Picker":
        return <EventNote />;
      case "Year Picker":
        return <CalendarToday />;
      case "Range Picker":
        return <DateRange />;
      case "Range Picker With Time":
        return <Timer />;
      case "Button":
        return <CheckCircle />;
      case "Drawer Button":
        return <Menu />;
      default:
        return null;
    }
  };

  return (
    <div style={style} className="item">
      {id} {iconByValue(id)}
    </div>
  );
};

export default Item;
