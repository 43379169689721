import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { PageHeader, Collapse } from "antd";
import { handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import { VendorBankInfo } from "./VendorBankInfo";
import { VendorSector } from "./VendorSector";
import { VendorDocument } from "./VendorDocument";
import { VendorFinancialPosition } from "./VendorFinancialPosition";
import { VendorContact } from "./VendorContact";
import { VendorClientRegistration } from "./VendorClientRegistration";
import { VendorTaxInfo } from "./VendorTaxInfo";
import { VendorReference } from "./VendorReference";
import { VendorRemark } from "./VendorRemark";
import { VendorProduct } from "./VendorProduct";
import { VendorLogEvaluation } from "./VendorLogEvaluation";

const { Panel } = Collapse;

export class VendorDetail extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      activePanelKey: "1",

      id: null,
      currentVendorName: "",
      tableList: [],
      filePath: "",
      fileListLogo: [],
      fileListInHouse: [],
      fileListEmployee: [],
      fileListOrganizational: [],
      nodes: [], // for cascader.

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    this.setState({
      id: this.props.selectedVendor.id,
      currentVendorName: this.props.selectedVendor.name
    });
  };

  fillComboboxes = async () => {};

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  getVendorId = () => {
    return this.state.id;
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileListLogo: [],
      fileListEmployee: [],
      fileListInHouse: [],
      fileListOrganizational: []
    });
  };

  setSelectedFiles = field => {
    let fileList = [];
    if (Boolean(field) && field.length > 0) {
      let files = field.split(",");
      for (let i = 0; i < files.length; i++) {
        fileList.push({
          uid: i,
          name: files[i],
          url: files[i],
          thumbUrl: files[i]
        });
      }
      return fileList;
    }
    return [];
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileListLogo = [];
    let fileListEmployee = [];
    let fileListInHouse = [];
    let fileListOrganizational = [];

    fileListLogo = this.setSelectedFiles(row.logoPath);
    fileListEmployee = this.setSelectedFiles(row.employeeListPath);
    fileListInHouse = this.setSelectedFiles(row.inHouseResourcesPath);
    fileListOrganizational = this.setSelectedFiles(row.organizationalChartPath);

    this.formRef.current.setFieldsValue({
      ...row
    });
    console.log(row);
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      currentVendorName: row.name,
      fileListLogo: fileListLogo,
      fileListEmployee: fileListEmployee,
      fileListInHouse: fileListInHouse,
      fileListOrganizational: fileListOrganizational
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/vendor/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  handleTabChange = key => {
    //console.log(key);
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/vendor/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  getSelectedFiles = field => {
    if (Boolean(field) && Boolean(field.fileList) && field.fileList.length > 0) {
      let files = "";
      files = field.fileList[0].response.url;
      for (let i = 1; i < field.fileList.length; i++) {
        if (Boolean(field.fileList[i].response)) {
          files += "," + field.fileList[i].response.url;
        }
      }
      return files;
    }
    return field;
  };
  save = async values => {
    const newItem = { ...values, id: this.state.id };

    newItem.logoPath = this.getSelectedFiles(newItem.logoPath);

    newItem.employeeListPath = this.getSelectedFiles(newItem.employeeListPath);

    newItem.inHouseResourcesPath = this.getSelectedFiles(newItem.inHouseResourcesPath);

    newItem.organizationalChartPath = this.getSelectedFiles(newItem.organizationalChartPath);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/vendor", newItem);
    } else {
      response = await handleRequest("PUT", "/api/vendor/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            active: Boolean("1"),
            companyId: element["companyId"],
            projectId: element["projectId"],
            name: element["name"],
            arabicName: element["arabic_name"],
            customer: Boolean("1")
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/vendor/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  setOpenPanels = key => {
    this.setState({
      activePanelKey: key
    });
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <PageHeader className="site-page-header" />
        <Collapse activeKey={this.state.activePanelKey} onChange={this.setOpenPanels} accordion>
          <Panel header="Product" key="11">
            {Boolean(this.state.id) && <VendorProduct vendorId={this.state.id} vendorName={this.state.currentVendorName} />}
          </Panel>
          <Panel header="Sector Of Company" key="2">
            {Boolean(this.state.id) && <VendorSector vendorId={this.state.id} vendorName={this.state.currentVendorName} />}
          </Panel>
          <Panel header="Contacts" key="3">
            {Boolean(this.state.id) && <VendorContact vendorId={this.state.id} vendorName={this.state.currentVendorName} />}
          </Panel>
          <Panel header="Documents" key="4">
            {Boolean(this.state.id) && <VendorDocument vendorId={this.state.id} vendorName={this.state.currentVendorName} />}
          </Panel>
          <Panel header="Client Registration" key="5">
            {Boolean(this.state.id) && <VendorClientRegistration vendorId={this.state.id} vendorName={this.state.currentVendorName} />}
          </Panel>
          <Panel header="Financial Position" key="6">
            {Boolean(this.state.id) && <VendorFinancialPosition vendorId={this.state.id} vendorName={this.state.currentVendorName} />}
          </Panel>
          <Panel header="Bankers Info" key="7">
            {Boolean(this.state.id) && <VendorBankInfo vendorId={this.state.id} vendorName={this.state.currentVendorName} />}
          </Panel>
          <Panel header="Tax Info" key="8">
            {Boolean(this.state.id) && <VendorTaxInfo vendorId={this.state.id} vendorName={this.state.currentVendorName} />}
          </Panel>
          <Panel header="Evaluation" key="12">
            {Boolean(this.state.id) && <VendorLogEvaluation vendorId={this.state.id} vendorName={this.state.currentVendorName} />}
          </Panel>
          <Panel header="References" key="9">
            {Boolean(this.state.id) && <VendorReference vendorId={this.state.id} vendorName={this.state.currentVendorName} />}
          </Panel>
          <Panel header="Remarks" key="10">
            {Boolean(this.state.id) && <VendorRemark vendorId={this.state.id} vendorName={this.state.currentVendorName} />}
          </Panel>
        </Collapse>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VendorDetail);
