import "antd/dist/antd.css";
import React, { Component, useState } from "react";
import OrgChart from "../Components/react-org-chart";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import store from "../../redux/store";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { handleRequest } from "../ApiConnector";
import { DashboardCharts } from "../Components/dashboard";
import { error, showError } from "../MessageHelper";
import Stepper from "../Components/Stepper";
import CashFlowLineChart from "./widgets/CashFlowLineChart";
import ProjectsVectorMap from "./widgets/ProjectsVectorMap";
import QualityControlResults from "./widgets/QualityControlResults";
import QualityControlResultsDetail from "./widgets/QualityControlResultsDetail";
import { Carousel, Button as AntButton, Col, Row} from "antd";
import { FileImageOutlined, FilePdfOutlined, FullscreenOutlined, ExportOutlined } from "@ant-design/icons";
import QualityControlResultsChart from "./widgets/QualityControlResultsChart";
import ProcurementProgressWidget from "./widgets/ProcurementProgressWidget";
import SalesReport from "./store/SalesReport";
import SalesmanSalesType from "./widgets/SalesmanSalesType";
import CustomerPaymentTypes from "./widgets/CustomerPaymentTypes";
import SalesmanDiscountRates from "./widgets/SalesmanDiscountRates";
import CustomerDiscountAndProfitRates from "./widgets/CustomerDiscountAndProfitRates";
import ProductDiscountAndProfitsRates from "./widgets/ProductDiscountAndProfitsRates";
import SalesmanCreditStatus from "./widgets/SalesmanCreditStatus";
import CustomerCreditStatus from "./widgets/CustomerCreditStatus";
import SalesRates from "./widgets/SalesRates";
import StockCosts from "./widgets/StockCosts";
import DeliveryTime from "./widgets/DeliveryTime";
import PaymentType from "./widgets/PaymentType";
import { Button, Tooltip, Modal } from "react-bootstrap";
import Design from "./widgets/Design";
import Procurement from "./widgets/Procurement";
import Manufacturing from "./widgets/Manufacturing";
import QualityControl from "./widgets/QualityControl";
import SiteConstruction from "./widgets/SiteConstruction";
import Budget from "./widgets/Budget";
import Vendors from "./widgets/Vendors";
import TenderingProject from "./widgets/tendering/TenderingProject";
import TenderingOnGoingProject from "./widgets/tendering/TenderingOnGoingProject";
import TenderingSubmittedProject from "./widgets/tendering/TenderingSubmittedProject";
import TenderingProgress from "./widgets/tendering/TenderingProgress";
import avatarPersonnel from "../assets/img/avatar-personnel.svg";
import { unset } from "lodash";
import DraggableModal from "../Components/DraggableModal";

const downloadImageId = "download-image";
const downloadPdfId = "download-pdf";
const zoomExtentId = "zoom-extend";

const modalTitle = "Modal Title";
const modalBody = null;

export class DashboardPage extends Component {

  constructor() {
    super();

    this.state = {
      user: store.getState().auth.user,
      chartList: [],
      statusList: [],
      companyVisible: true,
      procurementVisible: false,
      salesVisible: false,
      financeVisible: false,
      qualityVisible: false,
      stockVisible: false,
      projectsVisible: false,
      tenderingVisible: false,

      showModal: false
    };
  }

  tree = {
    id: 0,
    person: {
      id: 0,
      avatar: avatarPersonnel,
      name: "Company Name",
      title: "",
      totalReports: 3
    },
    hasChild: true,
    hasParent: false,
    children: []
  };


  componentDidMount = async () => {
    let url = "/api/userDashboardChart/all/";
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      showError(response.message);
    } else {
      this.setState({ chartList: response.data });
    }
    this.rfqStatus();
    this.fillComboboxes();

    let chartd = await this.getChartData(0);
    this.tree.person.totalReports = chartd.length;
    this.tree.children.push(...chartd);
    this.setState({
      chartData: this.tree
    });
  };
  buttonStyle = {
    marginRight: "143px",
    padding: "10px",
    backgroundColor: "#3498db",
    color: "#ffffff",
    cursor: "pointer"
  };

  fillComboboxes = async () => {
    var responseCurrentProject = await handleRequest("GET", "/api/projects/getCurrentProject");
    if (responseCurrentProject.type === "ERROR") {
      error(responseCurrentProject);
    } else {
      this.setState({
        currentProject: Boolean(responseCurrentProject.data) ? responseCurrentProject.data : []
      });
    }
  };

  rfqStatus = async row => {
    // let url = "/api/requestForQuotation/getStatus/39";
    // const response = await handleRequest("GET", url);
    // if (response.type === "ERROR") {
    // } else if (Boolean(response.data) && response.data.length > 0) {
    //   this.setState({
    //     statusList: response.data
    //   });
    // }
  };
  handleVisibility(flag) {
    if (flag) {
      flag = false;
      return flag;
    } else {
      flag = true;
      return flag;
    }
  }


  getModalParameters = (title, component) => {
    this.setState({
      showModal: true
    })
    this.modalTitle = title;
    this.modalBody = component;
  }

  showOrHideModal = () => {
    this.setState({
      showModal: !this.state.showModal
    })
  }

  handleOnChangeConfig = config => {
    this.setState({ config: config });
  };
  handleDownload = () => {
    this.setState({ downloadingChart: false });
  };
  handleLoadConfig = () => {
    const { config } = this.state;
    return config;
  };
  getChartData = async departmentId => {
    let url = "/api/personnelLog/organizationalChart/" + departmentId;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      showError(response.message);
      return [];
    } else {
      return response.data;
    }
  };
  getChild = async id => {
    let chartd = await this.getChartData(id);
    return chartd;
  };

  render() {
    const contentStyle = {
      height: "160px",
      color: "#fff",
      lineHeight: "160px",
      textAlign: "center",
      background: "#364d79"
    };

    return (
      <div className="row" style={{ padding: "2rem", minHeight: "100%" }}>
        <>
          <div className="col-lg-12">
            <div className="card card-custom card-stretch gutter-b">
              <div className="card-body">
                <div className="row m-0">
                  <div className="col-lg-4">
                    <Carousel autoplay>
                      <div>
                        <div className="col bg-light-warning px-6 py-6 rounded-xl mr-7 mb-7 justify-content-center">
                          <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}></SVG>
                          </span>
                          <a href="#" className="text-warning font-weight-bold font-size-h6">
                            Weekly Sales
                          </a>
                        </div>
                      </div>
                      <div>
                        <div className="col bg-light-primary px-6 py-6 rounded-xl mb-7 mr-7">
                          <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")}></SVG>
                          </span>
                          <a href="#" className="text-primary font-weight-bold font-size-h6 mt-2">
                            New Users
                          </a>
                        </div>
                      </div>
                      <div>
                        <div className="col bg-light-danger px-6 py-6 rounded-xl mb-7 mr-7">
                          <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}></SVG>
                          </span>
                          <a href="#" className="text-danger font-weight-bold font-size-h6 mt-2">
                            Item Orders
                          </a>
                        </div>
                      </div>
                      <div>
                        <div className="col bg-light-success px-6 py-6 rounded-xl mb-7">
                          <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Urgent-mail.svg")}></SVG>
                          </span>
                          <a href="#" className="text-success font-weight-bold font-size-h6 mt-2">
                            Bug Reports
                          </a>
                        </div>
                      </div>
                    </Carousel>
                  </div>

                  <div className="col-lg-4">
                    <Carousel autoplay>
                      <div>
                        <div className="col bg-light-success px-6 py-6 rounded-xl mb-7">
                          <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Pound.svg")}></SVG>
                          </span>
                          <a href="#" className="text-success font-weight-bold font-size-h6 mt-2">
                            Pound
                          </a>
                        </div>
                      </div>
                      <div>
                        <div className="col bg-light-warning px-6 py-6 rounded-xl mr-7 mb-7 justify-content-center">
                          <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bitcoin.svg")}></SVG>
                          </span>
                          <a href="#" className="text-warning font-weight-bold font-size-h6">
                            Bitcoin
                          </a>
                        </div>
                      </div>

                      <div>
                        <div className="col bg-light-primary px-6 py-6 rounded-xl mb-7 mr-7">
                          <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}></SVG>
                          </span>
                          <a href="#" className="text-primary font-weight-bold font-size-h6 mt-2">
                            Dollar
                          </a>
                        </div>
                      </div>

                      <div>
                        <div className="col bg-light-danger px-6 py-6 rounded-xl mb-7 mr-7">
                          <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Euro.svg")}></SVG>
                          </span>
                          <a href="#" className="text-danger font-weight-bold font-size-h6 mt-2">
                            Euro
                          </a>
                        </div>
                      </div>
                    </Carousel>
                  </div>

                  <div className="col-lg-4">
                    <Carousel autoplay>
                      <div>
                        <div className="col bg-light-danger px-6 py-6 rounded-xl mb-7 mr-7">
                          <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-bar1.svg")}></SVG>
                          </span>
                          <a href="#" className="text-danger font-weight-bold font-size-h6 mt-2">
                            Chart-Bar
                          </a>
                        </div>
                      </div>
                      <div>
                        <div className="col bg-light-success px-6 py-6 rounded-xl mb-7">
                          <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line2.svg")}></SVG>
                          </span>
                          <a href="#" className="text-success font-weight-bold font-size-h6 mt-2">
                            Chart-Line
                          </a>
                        </div>
                      </div>

                      <div>
                        <div className="col bg-light-warning px-6 py-6 rounded-xl mr-7 mb-7 justify-content-center">
                          <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Shuffle.svg ")}></SVG>
                          </span>
                          <a href="#" className="text-warning font-weight-bold font-size-h6">
                            Shuffle
                          </a>
                        </div>
                      </div>

                      <div>
                        <div className="col bg-light-primary px-6 py-6 rounded-xl mb-7 mr-7">
                          <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")}></SVG>
                          </span>
                          <a href="#" className="text-primary font-weight-bold font-size-h6 mt-2">
                            Chart-Pie
                          </a>
                        </div>
                      </div>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="button-row"
            style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", padding: "2rem", minHeight: "100%" }}
          >
            <Button
              title={"Company"}
              style={{ width: "10%" }}
              onClick={() => {
                this.setState({
                  companyVisible: !this.state.companyVisible,
                  salesVisible: false,
                  procurementVisible: false,
                  financeVisible: false,
                  qualityVisible: false,
                  stockVisible: false,
                  projectsVisible: false,
                  tenderingVisible: false
                });
              }}
            >
              Company
            </Button>

            <Button
              title={"Projects"}
              style={{ width: "10%" }}
              onClick={() => {
                this.setState({
                  projectsVisible: !this.state.projectsVisible,
                  salesVisible: false,
                  procurementVisible: false,
                  financeVisible: false,
                  qualityVisible: false,
                  stockVisible: false,
                  companyVisible: false,
                  tenderingVisible: false
                });
              }}
            >
              Projects
            </Button>
            <Button
              title={"Procurement"}
              style={{ width: "10%" }}
              onClick={() => {
                this.setState({
                  procurementVisible: !this.state.procurementVisible,
                  companyVisible: false,
                  salesVisible: false,
                  financeVisible: false,
                  qualityVisible: false,
                  stockVisible: false,
                  projectsVisible: false,
                  tenderingVisible: false
                });
              }}
            >
              Procurement
            </Button>
            <Button
              title={"Sales"}
              style={{ width: "10%" }}
              onClick={() => {
                this.setState({
                  salesVisible: !this.state.salesVisible,
                  companyVisible: false,
                  procurementVisible: false,
                  financeVisible: false,
                  qualityVisible: false,
                  stockVisible: false,
                  projectsVisible: false,
                  tenderingVisible: false
                });
              }}
            >
              Sales
            </Button>
            <Button
              title={"Finance"}
              style={{ width: "10%" }}
              onClick={() => {
                this.setState({
                  financeVisible: !this.state.financeVisible,
                  companyVisible: false,
                  procurementVisible: false,
                  salesVisible: false,
                  qualityVisible: false,
                  stockVisible: false,
                  projectsVisible: false,
                  tenderingVisible: false
                });
              }}
            >
              Finance
            </Button>
            <Button
              title={"Quality"}
              style={{ width: "10%" }}
              onClick={() => {
                this.setState({
                  qualityVisible: !this.state.qualityVisible,
                  companyVisible: false,
                  procurementVisible: false,
                  financeVisible: false,
                  salesVisible: false,
                  stockVisible: false,
                  projectsVisible: false,
                  tenderingVisible: false
                });
              }}
            >
              Quality
            </Button>
            <Button
              title={"Stocks "}
              style={{ width: "10%" }}
              onClick={() => {
                this.setState({
                  stockVisible: !this.state.stockVisible,
                  companyVisible: false,
                  procurementVisible: false,
                  financeVisible: false,
                  salesVisible: false,
                  qualityVisible: false,
                  projectsVisible: false,
                  tenderingVisible: false
                });
              }}
            >
              Stocks
            </Button>
            <Button
              title={"Tendering "}
              style={{ width: "10%" }}
              onClick={() => {
                this.setState({
                  tenderingVisible: !this.state.tenderingVisible,
                  stockVisible: false,
                  companyVisible: false,
                  procurementVisible: false,
                  financeVisible: false,
                  salesVisible: false,
                  qualityVisible: false,
                  projectsVisible: false
                });
              }}
            >
              Tendering
            </Button>
          </div>

          {this.state.companyVisible && (
            <>
              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    {/* <div className="col-lg-3 mb-3"> */}
                    <h5>
                      {this.state.currentProject}
                      {/* <FormattedMessage id="DashboardPage.ProjectsVectorMap" defaultMessage="Projects Vector Map12" /> */}
                    </h5>
                    {/* </div> */}
                    <div id="map1" style={{ width: "100%", height: "400px" }}>
                      <ProjectsVectorMap />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <Row gutter={[8, 8]}>
                      <Col span={16} style={{ display: "flex", alignItems: "end" }}>
                        <h5>
                          <FormattedMessage id="DashboardPage.OrganizationalChart" defaultMessage="Organizational Chart" />
                        </h5>
                      </Col>
                      <Col span={8} style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                        <AntButton style={{ border: "0px" }} id={zoomExtentId} icon={<FullscreenOutlined style={{ fontSize: "23px", color: "#08c" }} />}>
                          {/* Fit to screen */}
                        </AntButton>
                        <AntButton style={{ border: "0px" }} id={downloadImageId} icon={<FileImageOutlined style={{ fontSize: "23px", color: "#08c" }} />}>
                          {/* Save As Image */}
                        </AntButton>
                        <AntButton style={{ border: "0px" }} id={downloadPdfId} icon={<FilePdfOutlined style={{ fontSize: "23px", color: "#08c" }} />}>
                          {/* Save As PDF */}
                        </AntButton>
                      </Col>
                    </Row>

                    <div style={{ width: "100%", height: "400px" }}>
                      {this.state.chartData ? (
                        <>
                          <OrgChart
                            tree={this.tree}
                            downloadImageId={downloadImageId}
                            downloadPdfId={downloadPdfId}
                            zoomExtentId={zoomExtentId}
                            onConfigChange={config => {
                              this.handleOnChangeConfig(config);
                            }}
                            loadConfig={d => {
                              let configuration = this.handleLoadConfig(d);
                              if (configuration) {
                                return configuration;
                              }
                            }}
                            downlowdedOrgChart={d => {
                              this.handleDownload();
                            }}
                            loadImage={d => {
                              return Promise.resolve(avatarPersonnel);
                            }}
                            // loadParent={d => {
                            //   const parentData = this.getParent(d);
                            //   return parentData;
                            // }}
                            loadChildren={d => {
                              const childrenData = this.getChild(d.departmentId);
                              return childrenData;
                            }}
                            style={{ height: "350px" }}
                          />
                        </>
                      ) : null}
                    </div>

                  </div>
                </div>
              </div>
            </>

          )}

          {this.state.projectsVisible && (
            <div className="col-lg-6">
              <div className="card card-custom card-stretch gutter-b">
                <div className="card-body">
                  <h5>
                    <FormattedMessage id="DashboardPage.ProjectSummary" defaultMessage="Project Summary" />
                  </h5>
                </div>
              </div>
            </div>
          )}

          {this.state.projectsVisible && (
            <>
              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.Design" defaultMessage="Design" />
                    </h5>
                    <Design />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.Procurement" defaultMessage="Procurement" />
                    </h5>
                    <Procurement />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.Manufacturing" defaultMessage="Manufacturing" />
                    </h5>
                    <Manufacturing />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.QualityControl" defaultMessage="Quality Control" />
                    </h5>
                    <QualityControl />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.SiteConstruction" defaultMessage="Site Construction" />
                    </h5>
                    <SiteConstruction />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.Budget" defaultMessage="Budget" />
                    </h5>
                    <Budget />
                  </div>
                </div>
              </div>
            </>
          )}

          {this.state.financeVisible && (
            <div className="col-lg-6">
              <div className="card card-custom card-stretch gutter-b">
                <div className="card-body">
                  <div className="col-lg-3 mb-3">
                    <h5>
                      <FormattedMessage id="DashboardPage.CashFlow" defaultMessage="Cash Flow" />
                    </h5>
                  </div>
                  <CashFlowLineChart />
                  {/* {React.createElement(CashFlowLineChart)} */}
                </div>
              </div>
            </div>
          )}

          {this.state.procurementVisible && (
            <>
              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.ProcurementProgress" defaultMessage="Procurement Progress" />
                    </h5>
                    <ProcurementProgressWidget />
                    {/* {React.createElement(CashFlowLineChart)} */}
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.DeliveryTime" defaultMessage="Delivery Time" />
                    </h5>
                    <DeliveryTime />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.Vendors" defaultMessage="Vendors" />
                    </h5>
                    <Vendors />
                  </div>
                </div>
              </div>

              {React.createElement(Stepper, {
                key: 0,
                sList: this.state.statusList
              })}
            </>
          )}

          {this.state.salesVisible && (
            <>
              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <Row>
                      <Col span={16}>
                        <h5>
                          <FormattedMessage id="DashboardPage.SalesRates" defaultMessage="Sales Rates" />
                        </h5>
                      </Col>
                      <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                        <ExportOutlined style={{ fontSize: "150%" }} onClick={() => this.getModalParameters("Sales Rates", <SalesRates />)} />
                      </Col>
                    </Row>
                    <SalesRates />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <Row>
                      <Col span={16}>
                        <h5>
                          <FormattedMessage id="DashboardPage.SalesmanSalesType" defaultMessage="Salesman Sales Type" />
                        </h5>
                      </Col>
                      <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                        <ExportOutlined style={{ fontSize: "150%" }} onClick={() => this.getModalParameters("Salesman Sales Type", <SalesmanSalesType />)} />
                      </Col>
                    </Row>
                    <SalesmanSalesType />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <Row>
                      <Col span={16}>
                        <h5>
                          <FormattedMessage id="DashboardPage.CustomerPaymentTypes" defaultMessage=" Customer Payment Types" />
                        </h5>
                      </Col>
                      <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                        <ExportOutlined style={{ fontSize: "150%" }} onClick={() => this.getModalParameters("Customer Payment Types", <CustomerPaymentTypes />)} />
                      </Col>
                    </Row>
                    <CustomerPaymentTypes />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <Row>
                      <Col span={16}>
                        <h5>
                          <FormattedMessage id="DashboardPage.SalesmanDiscountRates" defaultMessage="Salesman Discount Rates" />
                        </h5>
                      </Col>
                      <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                        <ExportOutlined style={{ fontSize: "150%" }} onClick={() => this.getModalParameters("Salesman Discount Rates", <SalesmanDiscountRates />)}/>
                      </Col>
                    </Row>
                    <SalesmanDiscountRates />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <Row>
                      <Col span={16}>
                        <h5>
                        <FormattedMessage id="DashboardPage.PaymentType" defaultMessage="Payment Type" />
                        </h5>
                      </Col>
                      <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                        <ExportOutlined style={{ fontSize: "150%" }} onClick={() => this.getModalParameters("Payment Type", <PaymentType />)}/>
                      </Col>
                    </Row>
                    <PaymentType />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <Row>
                      <Col span={16}>
                        <h5>
                        <FormattedMessage id="DashboardPage.CustomerDiscountAndProfitRates" defaultMessage="Customer Discount And Profit Rates" />
                        </h5>
                      </Col>
                      <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                        <ExportOutlined style={{ fontSize: "150%" }} onClick={() => this.getModalParameters("Customer Discount And Profit Rates", <CustomerDiscountAndProfitRates />)}/>
                      </Col>
                    </Row>
                    <CustomerDiscountAndProfitRates />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <Row>
                      <Col span={16}>
                        <h5>
                        <FormattedMessage id="DashboardPage.ProductDiscountAndProfitsRates" defaultMessage="Product Discount And Profit Rates" />
                        </h5>
                      </Col>
                      <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                        <ExportOutlined style={{ fontSize: "150%" }} onClick={() => this.getModalParameters("Product Discount And Profit Rates", <ProductDiscountAndProfitsRates />)}/>
                      </Col>
                    </Row>
                    <ProductDiscountAndProfitsRates />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <Row>
                      <Col span={16}>
                        <h5>
                        <FormattedMessage id="DashboardPage.SalesmanCreditStatus" defaultMessage="Salesman Credit Status" />
                        </h5>
                      </Col>
                      <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                        <ExportOutlined style={{ fontSize: "150%" }} onClick={() => this.getModalParameters("Salesman Credit Status", <SalesmanCreditStatus />)}/>
                      </Col>
                    </Row>
                    <SalesmanCreditStatus />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <Row>
                      <Col span={16}>
                        <h5>
                        <FormattedMessage id="DashboardPage.CustomerCreditStatus" defaultMessage="Customer Credits Status" />
                        </h5>
                      </Col>
                      <Col span={8} style={{ display: "flex", justifyContent: "end" }}>
                        <ExportOutlined style={{ fontSize: "150%" }} onClick={() => this.getModalParameters("Customer Credits Status", <CustomerCreditStatus />)}/>
                      </Col>
                    </Row>
                    <CustomerCreditStatus />
                  </div>
                </div>
              </div>
            </>
          )}

          {this.state.qualityVisible && (
            <>
              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.QualityControlResultsDetail" defaultMessage="Kalite Kontrol Sonuçları" />
                    </h5>
                    <QualityControlResultsDetail />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.QualityControlResults" defaultMessage="Kalite Kontrol Sonuçları" />
                    </h5>
                    <QualityControlResults />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.QualityControlResults" defaultMessage="Kalite Kontrol Sonuçları" />
                    </h5>
                    <QualityControlResultsChart />
                  </div>
                </div>
              </div>
            </>
          )}

          {this.state.stockVisible && (
            <>
              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.StockCost" defaultMessage="Stock Cost" />
                    </h5>
                    <StockCosts />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.StockingItems" defaultMessage="Stocking Items" />
                    </h5>
                    <StockCosts />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.InventoryTurnover" defaultMessage="Inventory Turnover" />
                    </h5>
                    <StockCosts />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.ProjectsSurpluses" defaultMessage="Projects' Surpluses" />
                    </h5>
                    <StockCosts />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.StockingAreaEfficiencies" defaultMessage="Stocking Area Efficiencies" />
                    </h5>
                    <StockCosts />
                  </div>
                </div>
              </div>
            </>
          )}

          {this.state.tenderingVisible && (
            <>
              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <TenderingProgress random={Math.random()} />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.OngoingProjects" defaultMessage="Ongoing Projects" />
                    </h5>
                    <TenderingOnGoingProject random={Math.random()} />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.Projects" defaultMessage="Projects" />
                    </h5>
                    <TenderingProject random={Math.random()} />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-custom card-stretch gutter-b">
                  <div className="card-body">
                    <h5>
                      <FormattedMessage id="DashboardPage.SubmittedProjects" defaultMessage="Submitted Projects" />
                    </h5>
                    <TenderingSubmittedProject random={Math.random()} />
                  </div>
                </div>
              </div>

            </>
          )}

          {this.state.chartList.map((chart, index) => {
            if (Boolean(chart.dashboardChart) && DashboardCharts[chart.dashboardChart.componentName])
              return React.createElement(DashboardCharts[chart.dashboardChart.componentName], {
                key: index,
                apiUrl: chart.dashboardChart.loadUrl,
                title: chart.dashboardChart.title,
                detailHeader: false
              });
            return null;
          })}
        </>

        <DraggableModal
        title={this.modalTitle}
        closable={true}
        onCancel={() => {
          this.setState({
            showModal: false
          });
        }}
        okButtonProps={{ hidden: false }}
        cancelButtonProps={{ hidden: false }}
        open={this.state.showModal}
        width={"55svw"}
        content={
          <div style={{height: "55svh"}}>
            {this.modalBody}
          </div>
        }
        footer={[]}
      />

      </div>


    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
