import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Row, Col, Button as AntButton, Checkbox, Tooltip, Radio, Space, Image } from "antd";
import { EyeTwoTone, LinkOutlined, EyeInvisibleTwoTone } from '@ant-design/icons';
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import DraggableModal from "../../../Components/DraggableModal";
import PresentationPreviewMI from "./PresentationPreviewMI";
import PresentationDesignMI from "./PresentationDesignMI";
import PresentationPreviewCW from "./PresentationPreviewCW";
import PresentationDesignCW from "./PresentationDesignCW";

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class PresentationDesignDrawer extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "PresentationDesignDrawer",
      controllerName: "presentationDesign",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      materialList: [],
      usageList: [],
      inputDetails: [],
      selectedValue: null,
      pageAutoDesign: null,
      options: [],
      checkedValues: [],
      initialCheckedValues: [],
      preview: true,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();
  formRefDetail = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {

  };

  restartTable = async (params = {}) => {
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getPresentation");
    if (response.type === "ERROR") {
      error(response);
    } else {
      let initialCheckedValues = [];
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          if (Boolean(element.uploadPath)) {
            initialCheckedValues.push(element.uploadPath);
          }
        });
      }
      this.setState({
        initialCheckedValues: initialCheckedValues,
        checkedValues: initialCheckedValues
      });
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }

    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const { parentId } = this.props;
    let { inputDetails } = this.state;

    let formName = values.formName;

    if (!(Boolean(formName) && formName.length > 1)) {
      showWarning("Form name not found.");
      return;
    }

    const newItem = {
      ...values,
      id: this.state.id,
      parentId: parentId,
      description: formName,
    };

    let controllerName = formName.charAt(0).toLowerCase() + formName.slice(1) + 's';
    let forms = [];
    if (Boolean(inputDetails) && inputDetails.length > 0) {
      forms.push({
        formName: formName,
        ribbonName: values.ribbonName,
        controllerName: controllerName,
        tableInputs: inputDetails,
      });
    }
    newItem.forms = forms;
    console.log("newItem", newItem);
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAllPageAutoDesign", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      if (Boolean(this.props) && Boolean(this.props.close)) {
        this.props.close();
      }
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          modalAddNewDimensionShow: false,
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const onFinishFailedDetail = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout1 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };

    const handleChange = async (checked) => {
      console.log('Selected values: ', checked);

      const { initialCheckedValues } = this.state;
      let checkedValues = [];
      if (Boolean(initialCheckedValues) && initialCheckedValues.length > 0) {
        initialCheckedValues.forEach(element => {
          checkedValues.push(element);
        });
      }

      if (Boolean(checked) && checked.length > 0) {
        checked.forEach(element => {
          checkedValues.push(element);
        });
      }

      this.setState({
        checkedValues: checkedValues
      });
    };

    const handleChangeRadio = async (e) => {
      console.log('Selected values: ', e);
      console.log('options: ', this.state.options);
      let newObj = {
        uploadPath: e.target.value,
      }
      let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/getPresentationType", newObj);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          type: Boolean(response.data) ? response.data : null,
          selectedValue: e.target.value,
          random: Math.random(),
        });
      }
    };

    const { selectedValue, random, checkedValues } = this.state;

    return (
      <div
        className="card card-custom"
        style={{ padding: "2rem", minHeight: "100%" }}>
        <Form
          initialValues={{ remember: false }}
          onFinish={this.save}
          onFinishFailed={onFinishFailed}
          ref={this.formRef}>

          <Row gutter={[16, 16]}>
            <Col md={8}></Col>
            <Col md={1}>
              <Tooltip title="Select Template">
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() => {
                    this.setState({ eldenStockCardModal: true }, async () => {
                      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getEldenPresentation");
                      if (response.type === "ERROR") {
                        error(response);
                      } else {
                        this.setState({
                          options: Boolean(response.data) ? response.data : []
                        });
                      }
                    });
                  }}
                  icon={<LinkOutlined color="secondary" fontSize="large" />}
                ></AntButton>
              </Tooltip>
            </Col>
            <Col md={1}>
              {Boolean(selectedValue) &&
                <Tooltip title={this.state.preview ? "Data" : "Preview"}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      this.setState({
                        preview: !this.state.preview
                      });
                    }}
                    icon={
                      this.state.preview ?
                        <EyeTwoTone color="secondary" fontSize="large" />
                        :
                        <EyeInvisibleTwoTone color="secondary" fontSize="large" />
                    }
                  ></AntButton>
                </Tooltip>
              }
            </Col>
          </Row>

          <div className="d-flex flex-row">
            <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
              <div className="card card-custom card-stretch">
                <div className="card-body pt-4">
                  <div className="d-flex justify-content-end"></div>
                  <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                    <br />
                    <Radio.Group onChange={handleChangeRadio}>
                      {Boolean(checkedValues) && checkedValues.length > 0 && checkedValues.map(i => (
                        <>
                          <Radio key={i} value={i}>
                            <div style={{ textAlign: 'center' }}>
                              <Image
                                src={i}
                                alt={i}
                                style={{ width: '200px', height: '200px' }}
                                preview={false}
                              />
                            </div>
                          </Radio>
                        </>
                      ))}
                    </Radio.Group>
                  </div>
                </div>
              </div>
            </div>

            {Boolean(selectedValue) &&
              <div className="flex-row-fluid ml-lg-8">
                <div className="card card-custom card-stretch" >
                  <div className="form">
                    <div className="card-body">
                      <div className="d-flex flex-row">

                        {!this.state.preview &&
                          <div className="flex-row-fluid ml-lg-8">
                            <div className="card card-custom card-stretch" >
                              <div className="form">
                                <div className="card-body">
                                  {this.state.type === "Mix_It" &&
                                    <PresentationDesignMI uploadPath={selectedValue} presentationId={this.props.presentationId} random={random} />}

                                  {this.state.type === "Clean_Window" &&
                                    <PresentationDesignCW uploadPath={selectedValue} presentationId={this.props.presentationId} random={random} />}
                                </div>
                              </div>
                            </div>
                          </div>
                        }

                        {this.state.preview &&
                          <div className="flex-row-fluid ml-lg-8">
                            <div className="card card-custom card-stretch" >
                              <div className="form">
                                <div className="card-body">
                                  {this.state.type === "Mix_It" &&
                                    <PresentationPreviewMI uploadPath={selectedValue} presentationId={this.props.presentationId} random={random} />}

                                  {this.state.type === "Clean_Window" &&
                                    <PresentationPreviewCW uploadPath={selectedValue} presentationId={this.props.presentationId} random={random} />}
                                </div>
                              </div>
                            </div>
                          </div>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </Form>

        <DraggableModal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={500}
          okButtonProps={{ hidden: true }}
          open={this.state.modalDeleteShow}
          onCancel={() => {
            this.setState({ modalDeleteShow: false });
          }}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDeleteShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Space>
          ]}
          content={
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="PresentationDesignDrawer.Type" defaultMessage="Type" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.eldenStockCardModal}
          onCancel={() => {
            this.setState({
              eldenStockCardModal: false,
              checkedValues: this.state.initialCheckedValues
            });
          }}
          onOk={() => {
            this.setState({ eldenStockCardModal: false });
          }}
          content={
            <Form
              initialValues={{ remember: false }}
              onFinishFailed={onFinishFailed}
              ref={this.formRefDetail}>
              <div>
                <br /> <br />
                <Form.Item name="type">
                  <Checkbox.Group onChange={handleChange}>
                    {this.state.options && this.state.options.length > 0 &&
                      this.state.options.map(i => (
                        <div key={i.uploadPath} style={{ textAlign: 'center' }}>
                          <Checkbox value={i.uploadPath}>
                            <Image
                              src={i.uploadPath}
                              alt={i.name}
                              style={{ width: '200px', height: '200px' }}
                              preview={false}
                            />
                          </Checkbox>
                        </div>
                      ))}
                  </Checkbox.Group>
                </Form.Item>
              </div>
            </Form>
          }
        />
      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PresentationDesignDrawer);