import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Button as AntButton, Drawer, Card, Space, Image, Cascader, Spin, Tooltip, Checkbox, Avatar } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { Add, EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import DraggableModal from "../../../Components/DraggableModal";
import { InputNumber } from "antd";
import { Select } from "antd";
import { Input } from "antd";
import { DragOutlined } from "@ant-design/icons";

const { Option } = Select;

export class BillOfQuantityAddPrice extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "BillOfQuantityAddPrice",
      controllerName: "billOfQuantityAddPrice",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      latesPurchases: [],
      purchasingcriteriaSelectItems: [],
      companyName: "Stock Card",
      selectedRow: null,
      page: 0,
      quotationDay: -1,
      purchaseDay: -1,
      loadingPurchases: false,
      loadingQuotation: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      }
    };
  }

  formRef = React.createRef();
  formRefNewSupplier = React.createRef();
  formRefNewManufacturer = React.createRef();
  formRefNewBrand = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    this.setState({
      page: 0,
    }, () => {
      this.fillSelectedRow(this.props.serialNumber);
    });
    this.fillComboboxes();
    this.resetInputs();
  };

  fillSelectedRow = async (serialNumber) => {
    this.formRef.current.resetFields();
    this.setState({ loading: true });
    if (!Boolean(serialNumber)) {
      serialNumber = -1;
    }
    let url = "/api/" + this.state.controllerName + "/getSelectedRow/" + this.props.tenderingLogId + "/" + this.state.page + "/" + serialNumber;
    if (Boolean(this.props) && this.props.missingItems) {
      url = "/api/" + this.state.controllerName + "/getSelectedRowMissing/" + this.props.tenderingLogId + "/" + this.state.page + "/" + serialNumber;
    }

    var response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    }
    let data = Boolean(response.data) ? response.data : null;
    console.log("data", data);
    if (Boolean(data)) {
      this.formRef.current.setFieldsValue({
        ...data,
        proposedVendorId: data.supplierId
      });

      let itemIdSelectItems = [];
      if (Boolean(data.items) && data.items.length > 0) {
        data.items.forEach(element => {
          if (Boolean(element.id) && element.id > 0) {
            itemIdSelectItems.push({ "id": element.id, "itemName": element.itemName });
          }
        });
      }

      this.setState({
        selectedRow: data,
        itemIdSelectItems: itemIdSelectItems,
        loading: false,
        serviceStockCardItemId: data.itemId,
      }, () => {
        this.fillSupplier();
        this.fillBrand();
        this.getPurchaseCriteria();
        this.fillLatesPurchases();
        this.fillLatesQuotations();
      });

    } else {
      showWarning("There are no items left in the list.");
      this.props.onCancel();
    }
  }

  fillComboboxes = async () => {
    this.fillManufacturer();
    var responsemanufacturerId = await handleRequest("GET", "/api/" + this.state.controllerName + "/manufacturer");
    if (responsemanufacturerId.type === "ERROR") {
      error(responsemanufacturerId);
    }
    this.setState({
      manufacturerIdSelectItems: Boolean(responsemanufacturerId.data) ? responsemanufacturerId.data : []
    });

    var responsecurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responsecurrency.type === "ERROR") {
      error(responsecurrency);
    } else {
      this.setState({
        currencySelectItems: Boolean(responsecurrency.data) ? responsecurrency.data : []
      });
    }

    var responseCompanyName = await handleRequest("GET", "/api/" + this.state.controllerName + "/getCompanyName");
    if (responseCompanyName.type === "ERROR") {
      error(responseCompanyName);
    } else {
      this.setState({
        companyName: Boolean(responseCompanyName.data) ? "" + responseCompanyName.data.companyShortName + " Stock Card" : "Stock Card"
      });
    }

    let missing = Boolean(this.props) && this.props.missingItems ? true : false;
    var responseSerialNumber = await handleRequest("GET", "/api/" + this.state.controllerName + "/serialNumber/" + this.props.tenderingLogId + "/" + missing);
    if (responseSerialNumber.type === "ERROR") {
      error(responseSerialNumber);
    } else {
      this.setState({
        serialNumberSelectItems: Boolean(responseSerialNumber.data) ? responseSerialNumber.data : []
      });
    }
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      unitId: Boolean(values.unitId) && values.unitId.length > 0 ? values.unitId[values.unitId.length - 1] : null,
      billOfQuantityId: this.state.selectedRow.id,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      this.setState({
        page: this.state.page + 1,
        latesPurchases: null,
        latesQuotations: null
      }, () => {
        this.formRef.current.setFieldsValue({
          latesPurchases: null,
          latesQuotations: null,
        });
        this.fillSelectedRow();
        if (Boolean(this.props) && Boolean(this.props.getMissingItemsCount)) {
          this.props.getMissingItemsCount();
        }
      });
    }
  };

  next = async () => {
    this.setState({
      page: this.state.page + 1,
      latesPurchases: null,
      latesQuotations: null
    }, () => {
      this.formRef.current.setFieldsValue({
        latesPurchases: null,
        latesQuotations: null,
      });
      this.fillSelectedRow();
    });
  };

  back = async () => {
    if (this.state.page === 0) {
      showWarning("You are on the first page");
      return;
    }
    this.setState({
      page: this.state.page - 1,
      latesPurchases: null,
      latesQuotations: null
    }, () => {
      this.formRef.current.setFieldsValue({
        latesPurchases: null,
        latesQuotations: null,
      });
      this.fillSelectedRow();
    });
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleFormNewSupplierSubmit = async values => {
    const newItem = {
      ...values,
      supplier: true,
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createVendor", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showNewSupplierModal: false
      });
      showSuccess();
    }
  };

  handleFormNewManufacturerSubmit = async values => {
    const newItem = {
      ...values,
      manufacturer: true,
    };
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/createVendor", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showNewManufacturerModal: false
      });
      showSuccess();
      this.fillManufacturer();
    }
  };

  fillSupplier = async () => {
    let { serviceStockCardItemId } = this.state;
    if (!Boolean(serviceStockCardItemId)) {
      serviceStockCardItemId = -1;
    }
    var responsesupplierId = await handleRequest("GET", "/api/" + this.state.controllerName + "/supplier/" + serviceStockCardItemId);
    if (responsesupplierId.type === "ERROR") {
      error(responsesupplierId);
    }
    this.setState({
      supplierIdSelectItems: Boolean(responsesupplierId.data) ? responsesupplierId.data : []
    });
  }

  fillManufacturer = async () => {
    var responsemanufacturerId = await handleRequest("GET", "/api/" + this.state.controllerName + "/manufacturer");
    if (responsemanufacturerId.type === "ERROR") {
      error(responsemanufacturerId);
    }
    this.setState({
      manufacturerIdSelectItems: Boolean(responsemanufacturerId.data) ? responsemanufacturerId.data : []
    });
  }

  fillBrand = async () => {
    let { proposedVendorId, serviceStockCardItemId } = this.state;
    if (!Boolean(serviceStockCardItemId)) {
      serviceStockCardItemId = -1;
    }

    if (Boolean(proposedVendorId)) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/brand/" + proposedVendorId + "/" + serviceStockCardItemId);
      if (response.type === "ERROR") {
        error(response);
      }
      this.setState({
        brandIdSelectItems: Boolean(response.data) ? response.data : []
      });
    }
  }

  getPurchaseCriteria = async () => {
    let { serviceStockCardItemId } = this.state;
    if (!Boolean(serviceStockCardItemId)) {
      serviceStockCardItemId = -1;
    }
    let responsePurchaseCriteria = await handleRequest("GET", "/api/" + this.state.controllerName + "/purchasingcriterias/" + serviceStockCardItemId);
    if (responsePurchaseCriteria.type === "ERROR") {
      error(responsePurchaseCriteria);
    } else {
      this.setState({
        purchasingcriteriaSelectItems: Boolean(responsePurchaseCriteria.data) && responsePurchaseCriteria.data.length > 0 ? responsePurchaseCriteria.data : []
      });
    }
  };

  fillLatesPurchases = async () => {
    this.setState({ loadingPurchases: true });
    let { serviceStockCardItemId, purchaseDay } = this.state;

    if (!Boolean(serviceStockCardItemId)) {
      serviceStockCardItemId = -1;
    }
    if (!Boolean(purchaseDay)) {
      purchaseDay = -1;
    }
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillLatesPurchases/" + serviceStockCardItemId + "/" + purchaseDay);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loadingPurchases: false
      });
    } else {
      let latesPurchases = Boolean(response.data) && response.data.length > 0 ? response.data : [];
      this.setState({
        latesPurchases: latesPurchases,
        loadingPurchases: false
      });
      this.formRef.current.setFieldsValue({
        latesPurchases: latesPurchases,
      });
    }
  };

  fillLatesQuotations = async () => {
    this.setState({ loadingQuotation: true });
    let { serviceStockCardItemId, quotationDay } = this.state;
    if (!Boolean(serviceStockCardItemId)) {
      serviceStockCardItemId = -1;
    }
    if (!Boolean(quotationDay)) {
      quotationDay = -1;
    }

    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillLatesQuotations/" + serviceStockCardItemId + "/" + quotationDay);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loadingQuotation: false
      });
    } else {
      let latesQuotations = Boolean(response.data) && response.data.length > 0 ? response.data : [];
      this.setState({
        latesQuotations: latesQuotations,
        loadingQuotation: false
      });
      this.formRef.current.setFieldsValue({
        latesQuotations: latesQuotations,
      });
    }
  };

  getLaborship = async (checked) => {
    const { serviceStockCardItemId } = this.state;
    if (Boolean(serviceStockCardItemId) && serviceStockCardItemId > 0) {
      const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getLaborship/" + serviceStockCardItemId);
      if (Boolean(response.data) && response.data > 0) {
        let unitPrice = this.formRef.current.getFieldValue("unitPrice");
        if (checked) {
          unitPrice += response.data;
        } else {
          unitPrice -= response.data;
        }
        this.formRef.current.setFieldsValue({
          unitPrice: unitPrice,
        });
        showSuccess();
      } else {
        showWarning("Laborship not found.");
      }
    } else {
      showWarning("Please select item.");
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layoutLeft = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };

    const layoutRight = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 }
    };
    const { selectedRow } = this.state;
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Spin spinning={this.state.loading}>
            {Boolean(selectedRow) && <>
              <Card
                title={
                  <FormattedMessage
                    id="BillOfQuantity.ClientIdentifications"
                    defaultMessage="Client Identifications"
                  />
                }
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                  height: "100%",
                  fontSize: '11px'
                }}
              >
                <Row gutter={[16, 16]}>
                  <Col span={20}>
                    <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
                      <Col span={6}>
                        <Form.Item
                          name="disciplineName"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} readOnly placeholder="Discipline" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="serviceName"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} readOnly placeholder="Service" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="compound"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} readOnly placeholder="Compound" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="componentName"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} readOnly placeholder="Component" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
                      <Col span={6}>
                        <Form.Item
                          name="serialNumber"
                          {...layoutRight}
                          label={<FormattedMessage id="EldenCompounds.ExcelLineNo" defaultMessage="Excel Line No" />}
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}
                            onChange={(value) => {
                              this.setState({
                                latesPurchases: null,
                                latesQuotations: null
                              }, () => {
                                this.formRef.current.setFieldsValue({
                                  latesPurchases: null,
                                  latesQuotations: null,
                                });
                                this.fillSelectedRow(value);
                              });
                            }}>
                            {Boolean(this.state["serialNumberSelectItems"]) &&
                              this.state["serialNumberSelectItems"].length > 0 &&
                              this.state["serialNumberSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={18}>
                        <Form.Item
                          name="clientItemDescription"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Input style={{ width: "100%" }} readOnly placeholder="Item Description" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={4}>
                    <Avatar shape="square" size={128} src={Boolean(selectedRow) ? selectedRow.imageUrl : null} />
                  </Col>
                </Row>
              </Card>


              <br /><br />

              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <Col span={8}></Col>

                <Col span={8}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.IncludeLaborship" defaultMessage="Include Laborship" />}
                    name="includeLaborship"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox
                      onChange={(e) => {
                        this.getLaborship(e.target.checked);
                      }}
                    ></Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantity.IncludeIsolation" defaultMessage="Include Isolation" />}
                    name="includeIsolation"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox></Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginBottom: "16px" }}>
                {/* Sol Card */}
                <Col span={8}>
                  <h5>
                    {this.state.companyName}
                  </h5>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="BillOfQuantityAddPrice.Item" defaultMessage="Item" />}
                      name="itemId"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Select allowClear showSearch style={{ width: "100%" }} optionFilterProp="children"
                        onChange={value => {
                          let serviceStockCardItemId = null;
                          if (Boolean(value) && value > 0) {
                            let item = this.state["itemIdSelectItems"].find(p => p.id === value);
                            if (Boolean(item)) {
                              serviceStockCardItemId = item.id;
                            }
                          }
                          this.setState({
                            serviceStockCardItemId: serviceStockCardItemId,
                          }, () => {
                            this.fillSupplier();
                            this.fillBrand();
                            this.getPurchaseCriteria();
                            this.fillLatesPurchases();
                            this.fillLatesQuotations();
                          });
                          if (Boolean(value) && value > 0) {
                            let item = this.state["itemIdSelectItems"].find(p => p.id === value);
                            if (Boolean(item)) {
                              this.formRef.current.setFieldsValue({
                                clientCode: item.clientCode,
                                description: item.description,
                                referenceCode: item.referenceCode,
                              });
                            } else {
                              this.formRef.current.setFieldsValue({
                                clientCode: "",
                                description: "",
                                referenceCode: "",
                              });
                            }
                          } else {
                            this.formRef.current.setFieldsValue({
                              clientCode: "",
                              description: "",
                              referenceCode: "",
                            });
                          }
                        }}>
                        {Boolean(this.state["itemIdSelectItems"]) &&
                          this.state["itemIdSelectItems"].length > 0 &&
                          this.state["itemIdSelectItems"].map(i => (
                            <Option key={i.id} value={i.id}>
                              {i.itemName}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="BillOfQuantityAddPrice.Supplier" defaultMessage="Supplier" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <Form.Item
                            name="proposedVendorId"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            style={{ width: "90%" }}
                          >
                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children"
                              onChange={value => {
                                this.setState({
                                  proposedVendorId: value,
                                }, () => {
                                  this.fillBrand();
                                });
                              }}>
                              {Boolean(this.state["supplierIdSelectItems"]) &&
                                this.state["supplierIdSelectItems"].length > 0 &&
                                this.state["supplierIdSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>

                          <AntButton
                            type="default"
                            style={{
                              color: "black",
                              background: "white",
                              width: "10%"
                            }}
                            onClick={() => {
                              this.setState({ showNewSupplierModal: true });
                            }}
                            icon={<Add />}
                          ></AntButton>
                        </Space.Compact>
                      </Form.Item>
                    }

                    {/* {
                    <Form.Item
                      {...layoutLeft}
                      label={<FormattedMessage id="BillOfQuantityAddPrice.manufacturerId" defaultMessage="Manufacturer" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <Space.Compact style={{ width: "100%" }}>
                        <Form.Item
                          name="manufacturerId"
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          style={{ width: "90%" }}
                        >
                          <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                            {Boolean(this.state["manufacturerIdSelectItems"]) &&
                              this.state["manufacturerIdSelectItems"].length > 0 &&
                              this.state["manufacturerIdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>

                        <AntButton
                          type="default"
                          style={{
                            color: "black",
                            background: "white",
                            width: "10%"
                          }}
                          onClick={() => {
                            this.setState({ showNewManufacturerModal: true });
                          }}
                          icon={<Add />}
                        ></AntButton>
                      </Space.Compact>
                    </Form.Item>
                   } */}

                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="BillOfQuantityAddPrice.Brand" defaultMessage="Brand" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <Form.Item
                            name="brandId"
                            rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            style={{ width: "90%" }}
                          >
                            <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                              {Boolean(this.state["brandIdSelectItems"]) &&
                                this.state["brandIdSelectItems"].length > 0 &&
                                this.state["brandIdSelectItems"].map(i => (
                                  <Option key={i.key} value={i.key}>
                                    {i.value}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>

                          {/* <AntButton
                          type="default"
                          style={{
                            color: "black",
                            background: "white",
                            width: "10%"
                          }}
                          onClick={() => {
                            this.setState({ showNewBrandModal: true });
                          }}
                          icon={<Add />}
                        ></AntButton> */}
                        </Space.Compact>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="BillOfQuantityAddPrice.Quantity" defaultMessage="Quantity" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Row gutter={8}>
                          <Col span={12}>
                            <Form.Item
                              name="quantity"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <InputNumber style={{ width: '100%' }} min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="unitId"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <Cascader showSearch allowClear optionFilterProp="children" style={{ width: '100%' }} options={this.state.purchasingcriteriaSelectItems} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="BillOfQuantityAddPrice.UnitPrice" defaultMessage="Unit Price" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Row gutter={8}>
                          <Col span={12}>
                            <Form.Item
                              name="unitPrice"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <InputNumber style={{ width: '100%' }} min={0} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="currency"
                              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                            >
                              <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                {Boolean(this.state.currencySelectItems) &&
                                  this.state.currencySelectItems.length > 0 &&
                                  this.state.currencySelectItems.map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    }
                  </Card>
                </Col>

                <Col span={8}>
                  <h5>
                    <FormattedMessage id="BillOfQuantityAddPrice.LatestPurchases" defaultMessage="Latest Purchases" />
                  </h5>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Form.Item
                      {...layoutRight}
                      name="latesPurchasesDay"
                      label={<FormattedMessage id="BillOfQuantityAddPrice.Last" defaultMessage="Last" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber
                        onChange={value => {
                          this.setState({
                            purchaseDay: value
                          }, () => {
                            this.fillLatesPurchases();
                          });
                        }}
                        addonAfter="day" min={0} style={{ width: '100%' }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                    </Form.Item>

                    <Spin spinning={this.state.loadingPurchases}>
                      <Form.List name="latesPurchases">
                        {(fields, { add, remove }) => {
                          const handleMoveClick = (fieldName) => {
                            const latesPurchases = this.formRef.current.getFieldValue("latesPurchases");
                            let row = latesPurchases[fieldName];
                            if (Boolean(row) && Boolean(row.unitPrice) && row.unitPrice > 0) {
                              this.formRef.current.setFieldsValue({
                                unitPrice: row.unitPrice,
                              });
                            } else {
                              this.formRef.current.setFieldsValue({
                                unitPrice: 0,
                              });
                            }
                          };
                          return (
                            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                              <TableBoot bordered size="sm">
                                <thead>
                                  <tr>
                                    <th colSpan="1" style={{ textAlign: "center" }}></th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddPrice.UnitPrice"} defaultMessage={"Unit Price"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddPrice.Date"} defaultMessage={"Date"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddPrice.Vendor"} defaultMessage={"Vendor"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddPrice.Move"} defaultMessage={"Move"} />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item name={[field.name, "selectedValue"]} valuePropName="checked">
                                            <Checkbox onClick={(e) => {
                                              const latesPurchases = this.formRef.current.getFieldValue("latesPurchases");
                                              let row = latesPurchases[field.name];
                                              row.selectedValue = e.target.checked;

                                              this.setState({
                                                latesPurchases: latesPurchases,
                                              });
                                              this.formRef.current.setFieldsValue({
                                                latesPurchases: latesPurchases,
                                              });

                                              let total = 0;
                                              let count = 0;
                                              latesPurchases.forEach(element => {
                                                if (element.selectedValue) {
                                                  total += parseFloat(element.unitPrice);
                                                  count++;
                                                }
                                              });

                                              console.log("latesPurchases", latesPurchases);
                                              if (Boolean(total) && total > 0 && Boolean(count) && count > 0) {
                                                this.formRef.current.setFieldsValue({
                                                  latesPurchasesAverage: parseFloat((total / count).toFixed(2)),
                                                });
                                              } else {
                                                this.formRef.current.setFieldsValue({
                                                  latesPurchasesAverage: 0,
                                                });
                                              }
                                            }} />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "unitPrice"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "date"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "vendorName"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <DragOutlined onClick={() => {
                                            handleMoveClick(field.name);
                                          }} />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </TableBoot>
                            </div>
                          );
                        }}
                      </Form.List>
                    </Spin>


                    {Boolean(this.state.latesPurchases) && this.state.latesPurchases.length > 1 &&
                      <Form.Item
                        {...layoutRight}
                        name="latesPurchasesAverage"
                        label={<FormattedMessage id="BillOfQuantityAddPrice.Average" defaultMessage="Average" />}
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        style={{ marginTop: '30px' }}
                      >
                        <InputNumber readOnly addonAfter={
                          <Tooltip title="Move">
                            <DragOutlined onClick={() => {
                              const latesPurchasesAverage = this.formRef.current.getFieldValue("latesPurchasesAverage");
                              if (Boolean(latesPurchasesAverage) && latesPurchasesAverage > 0) {
                                this.formRef.current.setFieldsValue({
                                  unitPrice: latesPurchasesAverage,
                                });
                              } else {
                                this.formRef.current.setFieldsValue({
                                  unitPrice: 0,
                                });
                              }
                            }} />
                          </Tooltip>
                        } placeholder="unit price"
                          min={0} style={{ width: '100%' }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                      </Form.Item>}
                  </Card>
                </Col>

                <Col span={8}>
                  <h5>
                    <FormattedMessage id="BillOfQuantityAddPrice.LatestQuotations" defaultMessage="Latest Quotations" />
                  </h5>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Form.Item
                      {...layoutRight}
                      name="latesQuotationsDay"
                      label={<FormattedMessage id="BillOfQuantityAddPrice.Last" defaultMessage="Last" />}
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                      <InputNumber
                        onChange={value => {
                          this.setState({
                            quotationDay: value
                          }, () => {
                            this.fillLatesQuotations();
                          });
                        }}
                        addonAfter="day" min={0} style={{ width: '100%' }} parser={value => value.replace(",", ".")} formatter={value => value.replace(".", ",")} />
                    </Form.Item>

                    <Spin spinning={this.state.loadingQuotation}>
                      <Form.List name="latesQuotations">
                        {(fields, { add, remove }) => {
                          const handleMoveClick = (fieldName) => {
                            const latesQuotations = this.formRef.current.getFieldValue("latesQuotations");
                            let row = latesQuotations[fieldName];
                            if (Boolean(row) && Boolean(row.unitPrice) && row.unitPrice > 0) {
                              this.formRef.current.setFieldsValue({
                                unitPrice: row.unitPrice,
                              });
                            } else {
                              this.formRef.current.setFieldsValue({
                                unitPrice: 0,
                              });
                            }
                          };

                          return (
                            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                              <TableBoot bordered size="sm">
                                <thead>
                                  <tr>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddPrice.UnitPrice"} defaultMessage={"Unit Price"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddPrice.Date"} defaultMessage={"Date"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddPrice.Project"} defaultMessage={"Project"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddPrice.Deviation"} defaultMessage={"Deviation"} />
                                    </th>
                                    <th colSpan="1" style={{ textAlign: "center" }}>
                                      <FormattedMessage id={"BillOfQuantityAddPrice.Move"} defaultMessage={"Move"} />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map(field => {
                                    return (
                                      <tr key={field.key}>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "unitPrice"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "date"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "projectName"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item noStyle name={[field.name, "deviation"]}>
                                            <Input readOnly />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <DragOutlined onClick={() => {
                                            handleMoveClick(field.name);
                                          }} />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </TableBoot>
                            </div>
                          );
                        }}
                      </Form.List>
                    </Spin>
                  </Card>
                </Col>
              </Row>
            </>}
          </Spin>
          <br />
          <br />
          <div hidden={this.state.hideSave}>
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={15} lg={15} xl={15} xxl={15}></Col>
                <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
                  <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.back}>
                    <FormattedMessage id="GeneralButtonBack" defaultMessage="Back" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
                  <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.next}>
                    <FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
                  <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Col>
              </Row>
            }
          </div>
          {/*  <div hidden={this.state.hideUpdate}>
            {
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            }
          </div> */}
        </Form>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Drawer
          title={<FormattedMessage id="BillOfQuantityAddPrice.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"BillOfQuantityAddPrice"} />
        </Drawer>

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantityAddPrice.NewSupplier" defaultMessage="New Supplier" />}
          centered
          width={600}
          open={this.state.showNewSupplierModal}
          onOk={() => this.formRefNewSupplier.current.submit()}
          onCancel={() => {
            this.setState({ showNewSupplierModal: false });
          }}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Form id="FormVendor" initialValues={{ remember: false }} onFinish={this.handleFormNewSupplierSubmit} ref={this.formRefNewSupplier}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Vendor.name" defaultMessage="Name" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="VendorContact.address" defaultMessage="Address" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="VendorContact.emails" defaultMessage="Email" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="emails"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </div>
          }
        ></DraggableModal>


        <DraggableModal
          title={<FormattedMessage id="BillOfQuantityAddPrice.NewManufacturer" defaultMessage="New Manufacturer" />}
          centered
          width={600}
          maskClosable={false}
          open={this.state.showNewManufacturerModal}
          onOk={() => this.formRefNewManufacturer.current.submit()}
          onCancel={() => {
            this.setState({ showNewManufacturerModal: false });
          }}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Form id="FormVendor" initialValues={{ remember: false }} onFinish={this.handleFormNewManufacturerSubmit} ref={this.formRefNewManufacturer}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="Vendor.name" defaultMessage="Name" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="VendorContact.address" defaultMessage="Address" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="VendorContact.emails" defaultMessage="Email" />}
                    style={{
                      marginBottom: 0
                    }}
                    name="emails"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                      }
                    ]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </div>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="BillOfQuantityAddPrice.NewBrand" defaultMessage="New Brand" />}
          centered
          width={600}
          maskClosable={false}
          open={this.state.showNewBrandModal}
          onOk={() => this.formRefNewBrand.current.submit()}
          onCancel={() => {
            this.setState({ showNewBrandModal: false });
          }}
          content={
            <>
              <Form initialValues={{ remember: false }} onFinish={this.saveNewBrand} onFinishFailed={onFinishFailed} ref={this.formRefNewBrand}>
                {
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="BillOfQuantityAddPrice.NewSupplier" defaultMessage="New Brand" />}
                    name="newBrand"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                }
              </Form>
            </>
          }
        />
      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BillOfQuantityAddPrice);