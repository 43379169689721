import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Input, Tree, Button as AntButton, Form, Modal as AntModal, Select, Badge, Space, Divider, InputNumber, Row, Col, Radio } from "antd";
import { Checkbox } from "antd";
import { FcFolder, FcEditImage, FcFile, FcSettings } from "react-icons/fc";
import { GrInherit } from "react-icons/gr";
import { Menu, Item, Submenu, useContextMenu } from "react-contexify";
import { FaFileAudio, FaFileUpload, FaPlus, FaRegFile } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../ApiConnector";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { Button, Table as TableBoot } from "react-bootstrap";
import { PlusOutlined, MinusCircleOutlined, LinkOutlined } from "@ant-design/icons";
import { FcTreeStructure, FcBiotech, FcMediumPriority, FcLibrary } from "react-icons/fc";

const Menu_Action = "Menu_Action";
const Menu_Senario = "Menu_Senario";

const { Option } = Select;
//const { TextArea } = Input;

const { show } = useContextMenu();

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

var dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    dataList.push({ key: node.key, title: node.title });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class CompanySettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "CompanySettings",
      controllerName: "companySettingsSenario",
      id: null,
      projectName: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false,
      refreshTree: true,

      informVisible: true,
      reviewVisible: true,
      approvalVisible: true
    };
  }

  inputRef = React.createRef(null);

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  fillComboboxes = async () => {
    // var responseinformationTypeIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/informationTypeId");
    // if (responseinformationTypeIdSelectItems.type === "ERROR") {
    //   error(responseinformationTypeIdSelectItems);
    // }
    // this.setState({
    //   informationTypeIdSelectItems: Boolean(responseinformationTypeIdSelectItems.data) ? responseinformationTypeIdSelectItems.data : []
    // });

    // var responsereviewTypeId = await handleRequest("GET", "/api/" + this.state.controllerName + "/reviewTypeId");
    // if (responsereviewTypeId.type === "ERROR") {
    //   error(responsereviewTypeId);
    // }
    // this.setState({
    //   reviewTypeIdSelectItems: Boolean(responsereviewTypeId.data) ? responsereviewTypeId.data : []
    // });

    // var responseapprovalTypeId = await handleRequest("GET", "/api/" + this.state.controllerName + "/approvalTypeId");
    // if (responseapprovalTypeId.type === "ERROR") {
    //   error(responseapprovalTypeId);
    // }
    // this.setState({
    //   approvalTypeIdSelectItems: Boolean(responseapprovalTypeId.data) ? responseapprovalTypeId.data : []
    // });

    var responsedesignationId = await handleRequest("GET", "/api/" + this.state.controllerName + "/designation");
    if (responsedesignationId.type === "ERROR") {
      error(responsedesignationId);
    }
    this.setState({
      designationIdSelectItems: Boolean(responsedesignationId.data) ? responsedesignationId.data : []
    });

    var responsepersonIdSelectItems = await handleRequest("GET", "/api/" + this.state.controllerName + "/person");
    if (responsepersonIdSelectItems.type === "ERROR") {
      error(responsepersonIdSelectItems);
    }
    this.setState({
      personIdSelectItems: Boolean(responsepersonIdSelectItems.data) ? responsepersonIdSelectItems.data : []
    });

  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "ProjectName"
    };

    // SUBITEMS
    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      if (treenode.type !== "Senario") {
        if (treenode.type === "Page") {
          response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsAction/" + obj.id);
        } else if (treenode.type === "Action") {
          response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsSenario/" + obj.id);
        } else {
          response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitems/" + obj.id);
        }

        if (response.type === "ERROR") {
          error(response);
        } else {
          if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
            const data = response.data;

            data.forEach(element => {
              element.icon =
                Boolean(element.type) && element.type === "Stock" ? (
                  <FcFile />
                ) : element.type === "Action" ? (
                  <FaRegFile />
                ) : element.type === "Senario" ? (
                  <FaFileAudio />
                ) : (
                  <FcFolder />
                );
              element.title = element.value;
              element.id = element.key;
              element.key = element.key + "-" + element.type + "." + element.companyProjectId;
              // element.key = element.id + "." + element.type + "." + element.companyProjectId;
            });

            var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
            this.setState({
              nodes: res,
              refreshTree: true
            });
          } else {
            this.setState({
              refreshTree: true
            });
          }
        }
      }
    }
  };

  formRefSenario = React.createRef();

  componentDidMount = async () => {
    this.fillTree();
    this.fillComboboxes();
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "CompanyProject";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  onRightClick = (event, node) => {
    let parentNode = this.getParentNode(node);
    this.setState(
      {
        selectedNodeKey: node.key,
        selectedNode: node,
        parentNode: parentNode
      },
      this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
    );
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    // eslint-disable-next-line default-case
    switch (event.currentTarget.id) {
      case "create_senario":
        this.setState({
          showActionModal: true,
          parentId: selectedNode.id,
          pageActionId: selectedNode.id,
          type: "Action",
          senarioType: null,
        }, () => {
          if (Boolean(this.formRefSenario) && Boolean(this.formRefSenario.current)) {
            this.formRefSenario.current.resetFields();
          }
        });
        break;

      case "edit_senario":
        this.setState({
          showActionModal: true,
          id: selectedNode.id,
          type: selectedNode.type,
          pageActionId: Boolean(parentNode) ? parentNode.id : null,
        }, () => {
          this.editSenario(selectedNode.id);
        });
        break;

      case "deleteSenario":
        this.setState({
          showDeleteSenarioModal: true,
          id: selectedNode.id,
          type: selectedNode.type
        });
        break;
    }
  };

  editSenario = async (id) => {
    if (Boolean(id) && id > 0) {
      var responseUnits = await handleRequest("GET", "/api/" + this.state.controllerName + "/" + id);
      if (responseUnits.type === "ERROR") {
        error(responseUnits);
      } else {
        let row = Boolean(responseUnits.data) ? responseUnits.data : [];
        if (Boolean(row)) {

          var personList = [];
          if (Boolean(row.personList) && row.personList.length > 0) {
            row.personList.forEach(element => {
              personList.push(element.personId);
            });
          }

          var designationList = [];
          if (Boolean(row.designationList) && row.designationList.length > 0) {
            row.designationList.forEach(element => {
              designationList.push(element.designationId);
            });
          }

          this.formRefSenario.current.setFieldsValue({
            ...row,
            personList: personList,
            designationList: designationList,
          });

          this.setState({
            senarioType: row.senarioType,
          });
        } else {
          this.formRefSenario.current.resetFields();
        }
      }
    } else {
      this.formRefSenario.current.resetFields();
    }
  };

  displayMenu = (e, type) => {
    console.log("TTT", type);
    if (Boolean(type)) {
      if (type === "Action") {
        show(e, { id: Menu_Action });
      } else if (type === "Senario") {
        show(e, { id: Menu_Senario });
      }
    }
  };

  // DELETE   DELETE    DELETE

  deleteSenario = async () => {
    if (Boolean(this.state.id) && this.state.id > 0) {
      const deletedItem = {
        id: this.state.id,
        type: this.state.type
      };

      var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/deleteSenario/" + deletedItem.id);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();

        showSuccess();
        this.setState({
          id: null,
          showDeleteSenarioModal: false
        });
      }
    } else {
      showError("Senario not found.");
    }
  };

  // SAVE UPDATE   SAVE UPDATE  SAVE UPDATE   SAVE UPDATE   SAVE UPDATE

  // SAVE SENARIO
  saveSenario = async values => {
    if (Boolean(this.state.pageActionId) && this.state.pageActionId > 0) {
      const { process, selectedNode } = this.state;
      const newItem = {
        ...values,
        id: this.state.id,
        pageActionId: this.state.pageActionId,
      };

      let personList = [];
      if (newItem.personList && newItem.personList.length > 0) {
        newItem.personList.forEach(element => {
          personList.push({ personId: element });
        });
      }
      newItem.personList = personList;

      let designationList = [];
      if (newItem.designationList && newItem.designationList.length > 0) {
        newItem.designationList.forEach(element => {
          designationList.push({ designationId: element });
        });
      }
      newItem.designationList = designationList;

      if (newItem.id === null) {
        newItem.childId = selectedNode.key;
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveSenario", newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateSenario/" + newItem.id, newItem);
      }
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (newItem.id === null) {
          if (newItem.parentId === null) this.fillTree();
          else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
          else this.expandSelectedNode();
        } else {
          if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
          else this.fillTree();
        }

        showSuccess();
        this.resetInputs();
        this.setState({
          id: null,
          showActionModal: false,
          pageActionId: null
        });
      }
    } else {
      showWarning("Page Action not found.");
    }
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      projectName: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false
    });
  };

  expandSelectedNode = () => {
    let key = this.state.selectedNodeKey;
    let node = this.state.selectedNode;
    if (Boolean(key)) {
      let expandedKeys = Boolean(this.state.expandedKeys) ? this.state.expandedKeys : [];
      if (Boolean(expandedKeys.find(p => p === key))) {
        this.loadData(node, false);
      } else {
        this.setState({
          expandedKeys: [...expandedKeys, key]
        });
        this.loadData(node, false);
      }
    }
  };

  itemTemplate(item) {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <h5 className="mb-2">{item.title}</h5>
        </div>
      </div>
    );
  }

  onDragEnter = info => { };

  onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...this.state.nodes];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    this.setState({
      nodes: data
    });
  };

  closeFormModal = () => {
    this.setState({
      showFormModal: false
    });
  };

  closeTableModal = () => {
    this.setState({
      showTableModal: false
    });
  };

  closeInputFormatModal = () => {
    this.setState({
      showInputFormatModal: false
    });
  };

  closeTableFormatModal = () => {
    this.setState({
      showTableFormatModal: false
    });
  };

  okProjectTreeTemplate = async () => {
    const { targetKeys, companyProjectId } = this.state;
    if (Boolean(companyProjectId) && companyProjectId > 0) {
      if (targetKeys && targetKeys.length > 0) {
        targetKeys.sort((a, b) => {
          return a - b;
        });

        var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/eldenTransfer/" + targetKeys + "/" + companyProjectId);
        if (response.type === "ERROR") {
          error(response);
        } else {
          if (Boolean(this.state.parentNode)) {
            this.loadData(this.state.parentNode, false);
          } else {
            this.fillTree();
          }
          showSuccess();
          this.setState({ modalEldenProjectTree: false });
        }
      } else {
        showError("Not found.");
      }
    } else {
      showError("Company-Project Not found.");
    }
  };

  onTargetKeysCheck = async targetKeys => {
    console.log("targetKeys", targetKeys);
    this.setState({
      targetKeys: targetKeys
    });
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Company Settings"} />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <br /> <br />
          {this.state.refreshTree && (
            <Tree
              style={{ marginTop: "10px", lineHeight: "30px" }}
              className="draggable-tree"
              showLine={{ showLeafIcon: false }}
              showIcon
              checkStrictly={true}
              loadedKeys={this.state.loadedKeys}
              onLoad={this.onLoadData}
              loadData={this.loadData}
              onRightClick={({ event, node }) => this.onRightClick(event, node)}
              onExpand={this.onExpand}
              expandedKeys={this.state.expandedKeys}
              autoExpandParent={false}
              treeData={this.state.nodes}
              blockNode={true}
              draggable
              halfCheckedKeys={this.state.halfCheckedKeys}
              onDragEnter={this.onDragEnter}
              onDrop={this.onDrop}
            />
          )}
          {/* MENU */}
          <Menu id={Menu_Action}>
            <Item id="create_senario" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="CompanySettings.AddNewStock" defaultMessage="Create Senario" />
                </span>
              </Space>
            </Item>
          </Menu>
          <Menu id={Menu_Senario}>
            <Item id="edit_senario" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="CompanySettings.edit" defaultMessage="Edit" />
                </span>
              </Space>
            </Item>
            <Item id="onHold" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>
                  <FormattedMessage id="CompanySettings.OnHold" defaultMessage="On Hold" />
                </span>
              </Space>
            </Item>
            <Item id="deleteSenario" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>
                  <FormattedMessage id="CompanySettings.Delete" defaultMessage="Delete" />
                </span>
              </Space>
            </Item>
          </Menu>
          {/* TABLE MODAL */}
          {/* ACTION MODAL */}
          <AntModal
            title={<FormattedMessage id="CompanySettings.Senario" defaultMessage="Senario" />}
            open={this.state.showActionModal}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ showActionModal: false })}
            onOk={() => this.formRefSenario.current.submit()}
            width={1300}
            centered
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveSenario} onFinishFailed={onFinishFailed} ref={this.formRefSenario}>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="StockCard.Type" defaultMessage="Type" />}
                name="senarioType"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Radio.Group
                  onChange={e => {
                    this.setState({
                      senarioType: e.target.value
                    });
                  }}
                >
                  <Radio value={"Inform"}><FormattedMessage id="CompanySettings.Inform" defaultMessage="Inform" /></Radio>
                  <Radio value={"Review"}><FormattedMessage id="CompanySettings.Review" defaultMessage="Review" /></Radio>
                  <Radio value={"Approval"}><FormattedMessage id="CompanySettings.Approval" defaultMessage="Approval" /></Radio>
                </Radio.Group>
              </Form.Item>

              {this.state.senarioType && (
                <>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="CompanySettings.Designation" defaultMessage="Designation" />}
                    name="designationList"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children" mode="multiple">
                      {Boolean(this.state["designationIdSelectItems"]) &&
                        this.state["designationIdSelectItems"].length > 0 &&
                        this.state["designationIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="CompanySettings.Person" defaultMessage="Person" />}
                    name="personList"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "100%" }} showSearch optionFilterProp="children" mode="multiple">
                      {Boolean(this.state["personIdSelectItems"]) &&
                        this.state["personIdSelectItems"].length > 0 &&
                        this.state["personIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="CompanySettings.InformationType" defaultMessage="Information Type" />}
                    name="informationType"
                    rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                      <Option key={"OnNotification"} value={"OnNotification"}>
                        {"On notification"}
                      </Option>
                      <Option key={"ToDirectToRelatedPage"} value={"ToDirectToRelatedPage"}>
                        {"To direct to related page"}
                      </Option>
                      {this.state.senarioType === "Approval" ?
                        <Option key={"ToGoToThePage"} value={"ToGoToThePage"}>
                          {"To go to the approval"}
                        </Option>
                        :
                        this.state.senarioType === "Review" ?
                          <Option key={"ToGoToThePage"} value={"ToGoToThePage"}>
                            {"To go to the review page"}
                          </Option>
                          :
                          <Option key={"ToGoToThePage"} value={"ToGoToThePage"}>
                            {"To go to the next page"}
                          </Option>
                      }
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="CompanySettings.Showthesummary" defaultMessage="Show the summary" />}
                    name="showTheSummary"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                </>
              )}
            </Form>
          </AntModal>

          {/* DeleteModal */}
          {/* DELETE SENARIO */}
          <AntModal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.showDeleteSenarioModal}
            onCancel={() => {
              this.setState({ showDeleteSenarioModal: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ showDeleteSenarioModal: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.deleteSenario}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </AntModal>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySettings);
