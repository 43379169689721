import { FilterOutlined } from "@ant-design/icons";
import { Cascader, DatePicker, Image, Input, PageHeader, Space, Table } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";
import ActionMenu from "../CustomMenu/ActionMenu";

const { RangePicker } = DatePicker;
const categoryList = [];
const itemList = [];
let timerId;
const debounceFunction = (func, delay) => {
  // Cancels the setTimeout method execution
  clearTimeout(timerId);
  // Executes the func after delay time.
  timerId = setTimeout(func, delay);
};
const generateCategoryList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    categoryList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateCategoryList(node.children);
    }
  }
};

const generateItemList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    itemList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateItemList(node.children);
    }
  }
};

export class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      disableProductNotExist: props.disableProductNotExist,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      inventorySelectItems: [],
      selectedRowKeys: [],
      categoryNodes: [], // for cascader.

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    this.fillComboboxes();
    const { pagination } = this.state;
    this.restartTable({ pagination });
  }

  fillComboboxes = async params => {
    const responseTree = await handleRequest("GET", "/api/itemCategory/tree");

    if (responseTree.data.length !== 0) {
      console.log(responseTree.data);

      generateCategoryList(responseTree.data);
      this.setState({
        categoryNodes: responseTree.data
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  setSelectedFiles = field => {
    let fileList = [];
    if (Boolean(field) && field.length > 0) {
      let files = field.split(",");
      for (let i = 0; i < files.length; i++) {
        fileList.push({
          uid: i,
          name: files[i],
          url: files[i],
          thumbUrl: files[i]
        });
      }
      return fileList;
    }
    return [];
  };
  
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      fileList: fileList
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/inventory/product/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/inventory/product/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
          element.key = element.id;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  getSelectedFiles = field => {
    if (Boolean(field) && Boolean(field.fileList) && field.fileList.length > 0) {
      let files = "";
      files = field.fileList[0].response.url;
      for (let i = 1; i < field.fileList.length; i++) {
        if (Boolean(field.fileList[i].response)) {
          files += "," + field.fileList[i].response.url;
        }
      }
      return files;
    }
    return field;
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/inventory/product", newItem);
    } else {
      response = await handleRequest("PUT", "/api/inventory/product/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
      </React.Fragment>
    );
  };

  getImageGalleryFromUrls = (paths, galleryName) => {
    if (Boolean(paths) && paths.length > 0) {
      let pathList = paths.split(",");
      // for (let i = 0; i < pathList.length; i++) {
      //   images.push(<Image key={i} width={80} src={pathList[i].trim()} />);
      // }

      return (
        <>
          {Boolean(paths) && paths.length > 0 ? (
            <Image
              width={80}
              preview={{
                visible: false
              }}
              src={pathList[0].trim()}
              onClick={() => this.setState({ [galleryName]: true })}
            />
          ) : null}
          {Boolean(pathList) && pathList.length > 0 ? (
            <div style={{ display: "none" }}>
              <Image.PreviewGroup
                preview={{
                  visible: this.state[galleryName],
                  onVisibleChange: vis => this.setState({ [galleryName]: vis })
                }}
              >
                {pathList.map((field, i) => (
                  <Image key={i} src={field} />
                ))}
              </Image.PreviewGroup>
            </div>
          ) : null}
        </>
      );
    }
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    let selectedRowKeysState = this.state.selectedRowKeys;
    selectedRowKeysState = [...selectedRowKeys];

    this.setState({ selectedRowKeys: selectedRowKeysState });
  };

  addSelectedProducts = () => {
    // const { selectedRowKeys } = this.state.selectedRowKeys;
    const { selectedRowKeys, tableList } = this.state;
    let data = tableList.filter(item => selectedRowKeys.includes(item.id));
    this.props.addSelectedProducts(data);
  };

  render() {
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: this.onSelectChange,
      getCheckboxProps: record => {
        if (this.state.disableProductNotExist !== undefined && !this.state.disableProductNotExist) {
          return;
        }
        if (record.quantity <= 0) {
          return {
            disabled: !Boolean(record.quantity) // Column configuration not to be checked
          };
        }
      }
    };

    const columns = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="InventoryProduct.category" defaultMessage="Category" />,
        key: "item.itemCategoryId",
        render: record => {
          return record.item.itemCategory.name;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Cascader
              changeOnSelect
              style={{ flex: "auto" }}
              options={this.state.categoryNodes}
              onChange={async (value, selectedOptions) => {
                const { pagination } = this.state;
                let val = Boolean(value) && value.length > 0 ? value[value.length - 1] : null;
                let _pagination = {
                  ...pagination,
                  categoryColor: Boolean(val) ? "#1890ff" : "#bfbfbf",
                  item: null
                };

                if (Boolean(val)) {
                  _pagination.item = { itemCategoryId: val };
                }
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.categoryColor) ? this.state.pagination.categoryColor : "#bfbfbf"
            }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryProduct.materialCode" defaultMessage="Code" />,
        key: "materialCode",
        render: record => {
          return Boolean(record.productDetail) ? record.productDetail.productCode : record.materialCode;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.materialCode) ? this.state.pagination.materialCode : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  materialCode: e.target.value,
                  materialCodeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf",
                  productDetail: null
                };
                if (Boolean(e.target.value)) {
                  _pagination.productDetail = { productCode: e.target.value };
                }
                this.setState({
                  pagination: _pagination
                });
                debounceFunction(() => {
                  this.restartTable({
                    _pagination,
                    sortField: this.state.sortField,
                    sortOrder: this.state.sortOrder
                  });
                }, 400);
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.materialCodeColor) ? this.state.pagination.materialCodeColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryProduct.name" defaultMessage="Name" />,
        key: "materialName",
        render: record => {
          return Boolean(record.productDetail) ? record.productDetail.name : record.materialName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.name) ? this.state.pagination.name : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  materialName: e.target.value,
                  nameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf",
                  productDetail: null
                };
                if (Boolean(e.target.value)) {
                  _pagination.productDetail = { name: e.target.value };
                }
                this.setState({
                  pagination: _pagination
                });

                debounceFunction(() => {
                  this.restartTable({
                    _pagination,
                    sortField: this.state.sortField,
                    sortOrder: this.state.sortOrder
                  });
                }, 400);
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.nameColor) ? this.state.pagination.nameColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryProduct.arabicName" defaultMessage="Arabic Name" />,
        key: "arabicName",
        render: record => {
          return Boolean(record.productDetail) ? record.productDetail.arabicName : record.arabicMaterialName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.arabicName) ? this.state.pagination.arabicName : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  arabicMaterialName: e.target.value,
                  arabicNameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf",
                  productDetail: null
                };
                if (Boolean(e.target.value)) {
                  _pagination.productDetail = { arabicName: e.target.value };
                }
                this.setState({
                  pagination: _pagination
                });

                debounceFunction(() => {
                  this.restartTable({
                    _pagination,
                    sortField: this.state.sortField,
                    sortOrder: this.state.sortOrder
                  });
                }, 400);
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.arabicNameColor) ? this.state.pagination.arabicNameColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryProduct.materialPhotoPaths" defaultMessage="Product Photos" />,
        key: "materialPhotoPaths",
        render: record => {
          return this.getImageGalleryFromUrls(record.materialPhotoPaths, "gallery" + record.id);
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.description" defaultMessage="Description" />,
        key: "remarks",
        render: record => {
          return Boolean(record.productDetail) ? record.productDetail.description : record.remarks;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.remarks) ? this.state.pagination.remarks : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  remarks: e.target.value,
                  remarksColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf",
                  productDetail: null
                };
                if (Boolean(e.target.value)) {
                  _pagination.productDetail = { description: e.target.value };
                }
                this.setState({
                  pagination: _pagination
                });

                debounceFunction(() => {
                  this.restartTable({
                    _pagination,
                    sortField: this.state.sortField,
                    sortOrder: this.state.sortOrder
                  });
                }, 400);
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.remarksColor) ? this.state.pagination.remarksColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="InventoryProduct.unit" defaultMessage="Unit" />,
        key: "unit",
        render: record => {
          return record.unit;
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.quantity" defaultMessage="Quantity" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.reservedQuantity" defaultMessage="Reserved Quantity" />,
        key: "reservedQuantity",
        render: record => {
          return record.reservedQuantity;
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.buyingPrice" defaultMessage="Buying Price" />,
        key: "buyingPrice",
        render: record => {
          return Boolean(record.productDetail) ? record.productDetail.buyingPrice : record.buyingPrice;
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.sellingPrice" defaultMessage="Selling Price" />,
        key: "sellingPrice",
        render: record => {
          return Boolean(record.productDetail) ? record.productDetail.sellingPrice : record.sellingPrice;
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.discount" defaultMessage="Discount" />,
        key: "discount",
        render: record => {
          return record.discount;
        }
      },
      {
        title: <FormattedMessage id="InventoryProduct.active" defaultMessage="Status" />,
        key: "active",
        render: record => {
          return record.active;
        }
      },
      {
        title: "Last Update",
        key: "lastUpdated",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <PageHeader className="site-page-header" title={"Product List"} />

        <div style={{ marginBottom: 16 }}>
          <Space>
            <Button variant="success" onClick={this.addSelectedProducts} disabled={!hasSelected}>
              Add Selected Products
            </Button>
            <span style={{ marginLeft: 8 }}>{hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}</span>
          </Space>
        </div>
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            size="small"
            rowKey="id"
            rowSelection={rowSelection}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
