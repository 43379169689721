import React from "react";
import CascaderComponent from "./CascaderComponent";
import CheckboxComponent from "./CheckboxComponent";
import DatePickerComponent from "./DatePickerComponent";
import DatetimePickerComponent from "./DatetimePickerComponent";
import FileUploadComponent from "./FileUploadComponent";
import FileUploadImageComponent from "./FileUploadImageComponent";
import FormListComponent from "./FormListComponent";
import HiddenComponent from "./HiddenComponent";
import MailComponent from "./MailComponent";
import MonthPickerComponent from "./MonthPickerComponent";
import NumberComponent from "./NumberComponent";
import PasswordComponent from "./PasswordComponent";
import PhoneComponent from "./PhoneComponent";
import RadioComponent from "./RadioComponent";
import RangePickerComponent from "./RangePickerComponent";
import RangePickerTimeComponent from "./RangePickerTimeComponent";
import SelectAddComponent from "./SelectAddComponent";
import SelectComponent from "./SelectComponent";
import SelectMultiComponent from "./SelectMultiComponent";
import SelectMultipleAddComponent from "./SelectMultipleAddComponent";
import TextAreaComponent from "./TextAreaComponent";
import TextComponent from "./TextComponent";
import TextEditorComponent from "./TextEditorComponent";
import TimePickerComponent from "./TimePickerComponent";
import TreeSelectComponent from "./TreeSelectComponent";
import YearPickerComponent from "./YearPickerComponent";

const Components = {
  TEXT: TextComponent,
  MAIL: MailComponent,
  PHONE: PhoneComponent,
  PASSWORD: PasswordComponent,
  NUMBER: NumberComponent,
  TEXTAREA: TextAreaComponent,
  TEXT_EDITOR: TextEditorComponent,
  SELECT: SelectComponent,
  SELECT_MULTIPLE: SelectMultiComponent,
  SELECT_ADDABLE: SelectAddComponent,
  SELECT_MULTIPLE_ADDABLE: SelectMultipleAddComponent,
  CHECKBOX: CheckboxComponent,
  RADIO: RadioComponent,
  DATEPICKER: DatePickerComponent,
  DATEPICKER_WITH_TIME: DatetimePickerComponent,
  TIMEPICKER: TimePickerComponent,
  MONTHPICKER: MonthPickerComponent,
  YEARPICKER: YearPickerComponent,
  RANGEPICKER: RangePickerComponent,
  RANGEPICKER_WITH_TIME: RangePickerTimeComponent,
  CASCADER: CascaderComponent,
  FILEUPLOAD: FileUploadComponent,
  FILEUPLOAD_IMAGE: FileUploadImageComponent,
  TREE_SELECT: TreeSelectComponent,
  FORM_LIST: FormListComponent,
  HIDDEN: HiddenComponent
};
const ElementComponent = props => {
  const { field, refresh } = props;
  //   switch (field.field_type) {
  //     case "text":
  //       return <Input field={field} />;
  //     case "select":
  //       return <Select field={field} />;
  //     case "checkbox":
  //       return <Checkbox field={field} />;
  //     default:
  //       return null;
  //   }

  if (typeof Components[field.inputType] !== "undefined") {
    return React.createElement(Components[field.inputType], { refresh: refresh, ...props });
  }
  return React.createElement(() => <div>The component {field.inputType} has not been created yet.</div>, { ...props });
};

export default ElementComponent;
