/* eslint-disable */

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import MenuIcon from "@material-ui/icons/Menu";
import PostAddOutlined from "@material-ui/icons/PostAddOutlined";
import { ReplyOutlined } from "@material-ui/icons";

export default ({ row, onDeleteRow, onEditRow, onReviseRow, onReportRow, onAddResult, onChecklistRow, size, editBgColor, deleteBgColor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteRow = () => {
    if (onDeleteRow) {
      onDeleteRow(row);
      setAnchorEl(null);
    }
  };

  const editRow = () => {
    if (onEditRow) {
      onEditRow(row);
      setAnchorEl(null);
    }
  };

  const reviseRow = () => {
    if (onReviseRow) {
      onReviseRow(row);
      setAnchorEl(null);
    }
  };

  const showReport = () => {
    if (onReportRow) {
      onReportRow(row);
      setAnchorEl(null);
    }
  };

  const checklist = () => {
    if (onChecklistRow) {
      onChecklistRow(row);
      setAnchorEl(null);
    }
  };

  const addResult = () => {
    if (onAddResult) {
      onAddResult(row);
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu" // flaticon-layer
        aria-haspopup="true"
        onClick={handleClick}
        size={size}
      >
        {/* <i className={`kt-menu__link-icon  flaticon-layer`} /> */}
        <MenuIcon color="primary" />
        {/* <MoreVertIcon /> */}
      </IconButton>
      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={editRow} style={{ backgroundColor: "#" + editBgColor }}>
          <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">Edit</Typography>
        </MenuItem>

        <Divider />
        <MenuItem onClick={reviseRow} style={{ backgroundColor: "#" + editBgColor }}>
          <ListItemIcon>
            <AccountTreeIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">Revise</Typography>
        </MenuItem>

        <Divider />

        <MenuItem onClick={deleteRow} style={{ backgroundColor: "#" + deleteBgColor }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <Typography variant="inherit">Delete</Typography>
        </MenuItem>

        {!(Boolean(row.status) && row.status === "Closed") && <Divider /> && (
          <MenuItem onClick={addResult} style={{ backgroundColor: "#" + editBgColor }}>
            <ListItemIcon>
              <ReplyOutlined fontSize="small" style={{ border: "0px", color: "#007bff" }} />
            </ListItemIcon>
            <Typography variant="inherit">Add Result</Typography>
          </MenuItem>
        )}

        <Divider />

        <MenuItem onClick={showReport} style={{ backgroundColor: "#" + editBgColor }}>
          <ListItemIcon>
            <PostAddOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />
          </ListItemIcon>
          <Typography variant="inherit">Report</Typography>
        </MenuItem>
        {/* <Divider />

        <MenuItem onClick={checklist} style={{ backgroundColor: "#" + editBgColor }}>
          <ListItemIcon>
            <PostAddOutlined fontSize="small" color="info" />
          </ListItemIcon>
          <Typography variant="inherit">Cheklist</Typography>
        </MenuItem> */}
      </Menu>
    </div>
  );
};
