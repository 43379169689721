import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { API_BASE_ROOT, handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Button as AntButton, Drawer, Image, InputNumber, Radio } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import { Checkbox } from "antd";
import { Input } from "antd";
import { getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { DownloadOutlined } from "@ant-design/icons";
import DeclineIcon from "@material-ui/icons/CloseOutlined";
import CheckIcon from "@material-ui/icons/CheckCircleOutlineRounded";

export class EldenEquipmentLogCode extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "EldenEquipmentLogCode",
      controllerName: "eldenEquipmentLogCode",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
  };

  restartTable = async (params = {}) => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getCode/" + this.props.eldenEquipmentTreeId);
    if (response.type === "ERROR") {
      error(response);
    }
    this.formRef.current.setFieldsValue({
      ...response.data
    });
    this.setState({
      ascendingWithNumberLetter: Boolean(response.data) ? response.data.ascendingWithNumberLetter : null,
      groupedVisible: Boolean(response.data) ? response.data.grouped : false,
      categoryVisible: Boolean(response.data) ? response.data.category : false,
      typeVisible: Boolean(response.data) ? response.data.type : false,
      equipmentVisible: Boolean(response.data) ? response.data.equipment : false,
      manufacturerVisible: Boolean(response.data) ? response.data.manufacturer : false,
      brandVisible: Boolean(response.data) ? response.data.brand : false,
    });

    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      fileList: []
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      eldenEquipmentTreeId: this.props.eldenEquipmentTreeId
    };

    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveCode", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
      if (Boolean(this.props) && Boolean(this.props.close)) {
        this.props.close();
      }
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  showReport = async rowData => {
    try {
      if (rowData.filePath !== null && rowData.filePath !== undefined && rowData.filePath !== "") {
        const fileRealUrl = rowData.filePath;
        window.location.href = fileRealUrl;
      } else {
        this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
      }
    } catch (error) {
      this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EldenEquipmentLogCode.equipmentGroup" defaultMessage="Equipment Group" />,
        key: "equipmentGroup",
        render: record => {
          return record.equipmentGroup;
        },
        ...getColumnFilter({
          inputName: "equipmentGroup",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentLogCode.UsageType" defaultMessage="Usage Type" />,
        key: "id",
        render: record => {
          return record.isUsageTypeFixed ? "Fixed" : record.isUsageTypeMoveable ? "Moveable" : "";
        },
      },
      {
        title: <FormattedMessage id="EldenEquipmentLogCode.Maintenance" defaultMessage="Maintenance" />,
        key: "isMaintenanceYes",
        render: record => {
          return record.isMaintenanceYes ? <CheckIcon fontSize="large" color="primary" /> : <DeclineIcon fontSize="large" color="error" />;
        },
        ...getColumnFilter({
          inputName: "isMaintenanceYes",
          inputType: "CHECKBOX",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentLogCode.Photo" defaultMessage="Photo" />,
        key: "uploadPath",
        render: record => {
          return (
            record.uploadPath.endsWith(".png") ||
            record.uploadPath.endsWith(".jpg") ||
            record.uploadPath.endsWith(".tiff") ||
            record.uploadPath.endsWith(".jpeg") ||
            record.uploadPath.endsWith(".PNG") ||
            record.uploadPath.endsWith(".JPG") ||
            record.uploadPath.endsWith(".TIFF") ||
            record.uploadPath.endsWith(".JPEG")
          ) ?
            <Image width={80} src={record.uploadPath} />
            :
            <AntButton
              style={{
                marginLeft: "2px",
                marginTop: "-2px",
                alignContent: "center"
              }}
              onClick={() => this.showReport(record)}
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
            ></AntButton>;
        },
      }
    ];
    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="EldenEquipmentLogCode" hideElement={true}>
            <ExcelSheet name="EldenEquipmentLogCode" data={this.state.tableList}>
              <ExcelColumn label="Equipment Group" value="equipmentGroup" />
              <ExcelColumn label="Usage Type" value="usageType" />
              <ExcelColumn label="Maintenance" value="maintenance" />
              <ExcelColumn label="Photo" value="photo" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    const normFile = e => {
      console.log("Upload event:", e);

      if (Array.isArray(e)) {
        return e;
      }

      return e && e.fileList;
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <div hidden={false}>
            <Form.Item
              {...layout}
              label={<FormattedMessage id="EldenEquipmentLogCode.Group" defaultMessage="Group" />}
              style={{ marginBottom: "5px" }}
            >
              <Row gutter={8}>
                <Col span={4}>
                  <Form.Item name="grouped"
                    valuePropName="checked">
                    <Checkbox onClick={(e) => {
                      this.setState({
                        groupedVisible: e.target.checked
                      });
                    }} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                </Col>
                <Col span={16}>
                  {this.state.groupedVisible &&
                    <Form.Item name="groupedText">
                      <Input
                        style={{
                          width: "100%"
                        }}
                      />
                    </Form.Item>}
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="EldenEquipmentLogCode.Category" defaultMessage="Category" />}
              style={{ marginBottom: "5px" }}
            >
              <Row gutter={8}>
                <Col span={4}>
                  <Form.Item name="category"
                    valuePropName="checked">
                    <Checkbox onClick={(e) => {
                      this.setState({
                        categoryVisible: e.target.checked
                      });
                    }} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                </Col>
                <Col span={16}>
                  {this.state.categoryVisible &&
                    <Form.Item name="categoryText">
                      <Input
                        style={{
                          width: "100%"
                        }}
                      />
                    </Form.Item>}
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="EldenEquipmentLogCode.Type" defaultMessage="Type" />}
              style={{ marginBottom: "5px" }}
            >
              <Row gutter={8}>
                <Col span={4}>
                  <Form.Item name="type"
                    valuePropName="checked">
                    <Checkbox onClick={(e) => {
                      this.setState({
                        typeVisible: e.target.checked
                      });
                    }} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                </Col>
                <Col span={16}>
                  {this.state.typeVisible &&
                    <Form.Item name="typeText">
                      <Input
                        style={{
                          width: "100%"
                        }}
                      />
                    </Form.Item>}
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="EldenEquipmentLogCode.Equipment" defaultMessage="Equipment" />}
              style={{ marginBottom: "5px" }}
            >
              <Row gutter={8}>
                <Col span={4}>
                  <Form.Item name="equipment"
                    valuePropName="checked">
                    <Checkbox onClick={(e) => {
                      this.setState({
                        equipmentVisible: e.target.checked
                      });
                    }} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                </Col>
                <Col span={16}>
                  {this.state.equipmentVisible &&
                    <Form.Item name="equipmentText">
                      <Input
                        style={{
                          width: "100%"
                        }}
                      />
                    </Form.Item>}
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="EldenEquipmentLogCode.Manufacturer" defaultMessage="Manufacturer" />}
              style={{ marginBottom: "5px" }}
            >
              <Row gutter={8}>
                <Col span={4}>
                  <Form.Item name="manufacturer"
                    valuePropName="checked">
                    <Checkbox onClick={(e) => {
                      this.setState({
                        manufacturerVisible: e.target.checked
                      });
                    }} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                </Col>
                <Col span={16}>
                  {this.state.manufacturerVisible &&
                    <Form.Item name="manufacturerText">
                      <Input
                        style={{
                          width: "100%"
                        }}
                      />
                    </Form.Item>}
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="EldenEquipmentLogCode.Brand" defaultMessage="Brand" />}
              style={{ marginBottom: "5px" }}
            >
              <Row gutter={8}>
                <Col span={4}>
                  <Form.Item name="brand"
                    valuePropName="checked">
                    <Checkbox onClick={(e) => {
                      this.setState({
                        brandVisible: e.target.checked
                      });
                    }} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                </Col>
                <Col span={16}>
                  {this.state.brandVisible &&
                    <Form.Item name="brandText">
                      <Input
                        style={{
                          width: "100%"
                        }}
                      />
                    </Form.Item>}
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              {...layout}
              label={<FormattedMessage id="EldenEquipmentLogCode.NumberOrLetter" defaultMessage="Number or Letter" />}
              name="ascendingWithNumberLetter"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Radio.Group
                onChange={e => {
                  this.setState({
                    ascendingWithNumberLetter: e.target.value
                  });
                  this.formRef.current.setFieldsValue({
                    startingWithNumber: "",
                    startingWithLetter: "",
                  });
                }}
              >
                <Radio key={"Ascending_With_Number"} value={"Ascending_With_Number"}>
                  {<FormattedMessage id="ChemicalCompositionDetail.AscendingWithNumber" defaultMessage="Ascending With Number" />}
                </Radio>
                <Radio key={"Ascending_With_Letter"} value={"Ascending_With_Letter"}>
                  {<FormattedMessage id="ChemicalCompositionDetail.AscendingWithLetter" defaultMessage="Ascending With Letter" />}
                </Radio>
              </Radio.Group>
            </Form.Item>

            {Boolean(this.state.ascendingWithNumberLetter) && this.state.ascendingWithNumberLetter === "Ascending_With_Number" &&
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenEquipmentLogCode.StartingWithNumber" defaultMessage="Starting with number" />}
                name="startingWithNumber"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>}

            {Boolean(this.state.ascendingWithNumberLetter) && this.state.ascendingWithNumberLetter === "Ascending_With_Letter" &&
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenEquipmentLogCode.StartingWithLetter" defaultMessage="Starting with letter" />}
                name="startingWithLetter"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>}

            <Form.Item
              {...layout}
              label={<FormattedMessage id="EldenEquipmentLogCode.Code" defaultMessage="Code" />}
              name="code"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input readOnly style={{ width: "100%" }} />
            </Form.Item>

            <div hidden={false}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Drawer
          title={<FormattedMessage id="EldenEquipmentLogCode.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"EldenEquipmentLogCode"} />
        </Drawer>
      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EldenEquipmentLogCode); 