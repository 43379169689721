import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Tooltip, Space, Drawer, Cascader, InputNumber, Input, Card, Radio } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined, Add, FilterList, Settings } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../../excel/ExcelReader";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import { Select } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, PlusOutlined, MinusCircleOutlined, ZoomInOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import DraggableModal from "../../../Components/DraggableModal";

const { Option } = Select;

const dataListMaterialType = [];
const generateListMaterialType = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataListMaterialType.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateListMaterialType(node.children);
    }
  }
};

const layoutLeft = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 }
};

const dataListPurchaseCriteria = [];
const generateListPurchaseCriteria = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataListPurchaseCriteria.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateListPurchaseCriteria(node.children);
    }
  }
};

export class EldenEquipmentPeriodicControls extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "EldenEquipmentPeriodicControls",
      controllerName: "biddingLogQuotationIsolation",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      type: null,
      categoryId: -1,
      descriptionId: -1,
      materialTypeId: -1,
      purchasingcriteriaSelectItems: [],
      costRatios: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      countrySelectItems: []
    };
  }

  formRef = React.createRef();
  formRefItem = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsedisciplineId = await handleRequest("GET", "/api/" + this.state.controllerName + "/discipline");
    if (responsedisciplineId.type === "ERROR") {
      error(responsedisciplineId);
    }

    var responsecategoryId = await handleRequest("GET", "/api/" + this.state.controllerName + "/category");
    if (responsecategoryId.type === "ERROR") {
      error(responsecategoryId);
    }

    var responsecurrency = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responsecurrency.type === "ERROR") {
      error(responsecurrency);
    }

    var responselaborshipId = await handleRequest("GET", "/api/" + this.state.controllerName + "/laborshipId");
    if (responselaborshipId.type === "ERROR") {
      error(responselaborshipId);
    }

    var responseitemGroupId = await handleRequest("GET", "/api/" + this.state.controllerName + "/category");
    if (responseitemGroupId.type === "ERROR") {
      error(responseitemGroupId);
    }

    this.setState({
      disciplineIdSelectItems: Boolean(responsedisciplineId.data) ? responsedisciplineId.data : [],
      categoryIdSelectItems: Boolean(responsecategoryId.data) ? responsecategoryId.data : [],
      currencySelectItems: Boolean(responsecurrency.data) ? responsecurrency.data : [],
      laborshipIdSelectItems: Boolean(responselaborshipId.data) ? responselaborshipId.data : [],
      itemGroupIdSelectItems: Boolean(responseitemGroupId.data) ? responseitemGroupId.data : [],
    });
  };

  fillMaterialType = async () => {
    const { categoryId, descriptionId } = this.state;
    if (Boolean(categoryId) && categoryId > 0 && Boolean(descriptionId) && descriptionId > 0) {
      const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree/" + categoryId + "/" + descriptionId);
      if (responseTree.data.length !== 0) {
        generateListMaterialType(responseTree.data);
        this.setState({
          nodes: responseTree.data
        });
      }
    } else {
      this.setState({
        nodes: []
      });
    }
  };

  getPurchaseCriteria = async value => {
    if (Boolean(value)) {
      let responsePurchaseCriteria = await handleRequest("GET", "/api/" + this.state.controllerName + "/purchasingcriterias/" + value);
      if (responsePurchaseCriteria.type === "ERROR") {
      } else {
        generateListPurchaseCriteria(responsePurchaseCriteria.data);
        this.setState({
          purchasingcriteriaSelectItems: Boolean(responsePurchaseCriteria.data) && responsePurchaseCriteria.data.length > 0 ? responsePurchaseCriteria.data : []
        });
      }
    } else {
      this.setState({
        purchasingcriteriaSelectItems: []
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = async (row) => {
    document.getElementById("kt_scrolltop").click();

    this.fillService(row.disciplineId);
    this.fillComponent(row.compound);

    let itemIdFormListSelectItems = [];
    if (Boolean(row.costRatios) && row.costRatios.length > 0) {
      row.costRatios.forEach(element => {
        if (Boolean(element.itemId) && element.itemId > 0) {
          let isKeyExists = itemIdFormListSelectItems.some(item => item.key === element.itemId);
          if (!isKeyExists) {
            itemIdFormListSelectItems.push({ key: element.itemId, value: element.itemName });
          }
        }
      });
    }

    this.setState({
      type: row.type,
      categoryId: row.categoryId,
      descriptionId: row.descriptionId,
      materialTypeId: row.materialTypeId,
      serviceId: row.serviceId,
      itemIdFormListSelectItems: itemIdFormListSelectItems,
    }, () => {
      this.getStockCardItem();
      this.fillMaterialType();
      this.fillCompounds();
    });

    await this.onChangeItemGroupId(row.categoryId);

    var materialType = dataListMaterialType.find(p => p.key === row.materialTypeId);
    let materialTypeId = [];
    if (Boolean(materialType)) {
      materialTypeId = materialType.lineage;
    }

    var unit = dataListPurchaseCriteria.find(p => p.key === row.unitId);
    let unitId = [];
    if (Boolean(unit)) {
      unitId = unit.lineage;
    }

    var itemIds = [];
    if (Boolean(row.items) && row.items.length > 0) {
      row.items.forEach(element => {
        itemIds.push(element.stockCardId);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      materialTypeId: materialTypeId,
      unitId: unitId,
      itemIds: itemIds,
      effectiveDate: Boolean(row.effectiveDate) ? moment(row.effectiveDate) : null,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  projectDocumentsModal = row => {
    this.setState({
      modalProjectDocumentsShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    newItem.materialTypeId =
      Boolean(values.materialTypeId) && values.materialTypeId.length > 0 ? values.materialTypeId[values.materialTypeId.length - 1] : null;

    newItem.unitId =
      Boolean(values.unitId) && values.unitId.length > 0 ? values.unitId[values.unitId.length - 1] : null;

    let items = [];
    if (newItem.itemIds && newItem.itemIds.length > 0)
      newItem.itemIds.forEach(element => {
        items.push({ stockCardId: element });
      });
    newItem.items = items;

    if (Boolean(newItem.costRatios) && newItem.costRatios.length > 0) {
      newItem.costRatios.forEach(element => {
        element.unitId =
          Boolean(element.unitId) && element.unitId.length > 0 ? element.unitId[element.unitId.length - 1] : null;
      });
    }

    console.log("newItem", newItem);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  onChangeItemGroupId = async value => {
    if (Boolean(value)) {
      var responseitemDescriptionId = await handleRequest("GET", "/api/" + this.state.controllerName + "/description/" + value);
      if (responseitemDescriptionId.type === "ERROR") {
        error(responseitemDescriptionId);
        this.setState({
          descriptionIdSelectItems: [],
          descriptionId: null
        });
        this.formRef.current.setFieldsValue({
          descriptionId: null
        });
      } else {
        this.setState({
          descriptionIdSelectItems: Boolean(responseitemDescriptionId.data) ? responseitemDescriptionId.data : [],
          descriptionId: null
        });
        this.formRef.current.setFieldsValue({
          descriptionId: null
        });
      }
    } else {
      this.setState({
        descriptionIdSelectItems: [],
        descriptionId: null
      });
      this.formRef.current.setFieldsValue({
        descriptionId: null
      });
    }
  };

  onChangeItemGroupIdforNameCode = async () => {
    const { type, itemGroupId, materialTypeId, descriptionId } = this.state;
    let obj = {
      type: Boolean(type) ? type : null,
      descriptionId: Boolean(descriptionId) ? descriptionId : null,
      categoryId: Boolean(itemGroupId) ? itemGroupId : null,
      materialTypeId: Boolean(materialTypeId) && materialTypeId.length > 0 ? materialTypeId[materialTypeId.length - 1] : null,
    };

    var responseCodeSelectItems = await handleRequest("POST", "/api/" + this.state.controllerName + "/nameCode", obj);
    if (responseCodeSelectItems.type === "ERROR") {
      error(responseCodeSelectItems);
    } else {
      this.setState({
        itemIdFormListSelectItems: Boolean(responseCodeSelectItems.data) ? responseCodeSelectItems.data : []
      });
    }
  };

  fillService = async value => {
    if (Boolean(value)) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/service/" + value);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          serviceIdSelectItems: [],
        });
      } else {
        this.setState({
          serviceIdSelectItems: Boolean(response.data) ? response.data : [],
        });
      }
    } else {
      this.setState({
        serviceIdSelectItems: [],
      });
    }
  };

  fillCompounds = async () => {
    const { serviceId } = this.state;
    if (Boolean(serviceId)) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/compound/" + serviceId);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          compoundIdSelectItems: [],
        });
      } else {
        this.setState({
          compoundIdSelectItems: Boolean(response.data) ? response.data : [],
        });
      }
    } else {
      this.setState({
        compoundIdSelectItems: [],
      });
    }
  };

  fillComponent = async (value) => {
    if (Boolean(value)) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/component/" + value);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          componentSelectItems: [],
        });
      } else {
        this.setState({
          componentSelectItems: Boolean(response.data) ? response.data : [],
        });
      }
    } else {
      this.setState({
        componentSelectItems: [],
      });
    }
  };

  getStockCardItem = async () => {
    const { type, categoryId, descriptionId, materialTypeId } = this.state;
    var newObj = {
      type: type,
      categoryId: categoryId,
      descriptionSelectId: descriptionId,
      materialTypeId: materialTypeId,
    };
    let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/getStockCardItem", newObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        itemIdSelectItems: Boolean(response.data) ? response.data : [],
      });
    }
  };

  searchItem = async item => {
    console.log("item", item);
    this.setState({ loadingSearch: true });
    if (Boolean(item) && item.length > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/itemSearch/" + item);
      console.log("response", response.data);
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          itemIdFormListSelectItems: [],
          loadingSearch: false
        });
      } else {
        if (Boolean(response.data)) {
          this.setState({
            itemIdFormListSelectItems: response.data,
            loadingSearch: false
          });
        } else {
          this.setState({
            itemIdFormListSelectItems: [],
            loadingSearch: false
          });
        }
      }
    } else {
      this.setState({
        itemIdFormListSelectItems: [],
        loadingSearch: false
      });
    }
  };

  saveItem = async values => {
    console.log("Values", values);
    this.setState(
      {
        showItemReachSearchModal: false
      },
      () => {
        const { currentFieldKey } = this.state;
        console.log("currentFieldKey", currentFieldKey);

        let costRatios = this.formRef.current.getFieldValue("costRatios");
        let product = costRatios[currentFieldKey];
        product.itemId = values.code;

        this.formRef.current.setFieldsValue({
          "costRatios": costRatios
        });

        this.setState({
          showProductTreeCompanyModal: false,
        });
      }
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };

    const columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EldenEquipmentPeriodicControls.ControlDescription" defaultMessage="ControlDescription" />,
        key: "controlDescription",
        render: record => {
          return record.disciplineName;
        },
        ...getColumnFilter({
          inputName: "controlDescription",
          inputType: "Inbox",
          selectItems: Boolean(this.state["controlDescriptionIdSelectItems"]) ? this.state["controlDescriptionIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentPeriodicControls.Scope" defaultMessage="Scope" />,
        key: "scopeId",
        render: record => {
          return record.serviceName;
        },
        ...getColumnFilter({
          inputName: "scopeId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["scopeIdSelectItems"]) ? this.state["scopeIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentPeriodicControls.Period" defaultMessage="Period" />,
        key: "period",
        render: record => {
          return record.compound;
        },
        ...getColumnFilter({
          inputName: "period",
          inputType: "SELECT",
          selectItems: Boolean(this.state["periodSelectItems"]) ? this.state["periodSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentPeriodicControls.Output" defaultMessage="Output" />,
        key: "outputId",
        render: record => {
          return record.componentName;
        },
        ...getColumnFilter({
          inputName: "outputId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["outputIdSelectItems"]) ? this.state["outputIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentPeriodicControls.Requirement" defaultMessage="Requirement" />,
        key: "requirementId",
        render: record => {
          return record.categoryName;
        },
        ...getColumnFilter({
          inputName: "requirementId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["requirementIdSelectItems"]) ? this.state["requirementIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentPeriodicControls.Authority" defaultMessage="Authority" />,
        key: "authorityId",
        render: record => {
          return record.descriptionName;
        },
        ...getColumnFilter({
          inputName: "authorityId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["authorityIdSelectItems"]) ? this.state["authorityIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="EldenEquipmentPeriodicControls" hideElement={true}>
            <ExcelSheet name="EldenEquipmentPeriodicControls" data={this.state.tableList}>
              <ExcelColumn label="EldenEquipmentPeriodicControls" value="EldenEquipmentPeriodicControls" />
              <ExcelColumn label="Rev No" value="revNo" />
              <ExcelColumn label="Date" value="date" />
              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="Client" value="clientName" />
              <ExcelColumn label="Contractor" value="contractorName" />
              <ExcelColumn label="Project Description" value="projectDescription" />
              <ExcelColumn label="Client Project No" value="clientProjectNo" />
              <ExcelColumn label="Project Scope" value="projectScope" />
              <ExcelColumn label="Project Details" value="projectDetails" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="EldenEquipmentPeriodicControls.pageTitle" defaultMessage="Periodic Controls" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={20}></Col> <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
              <Col md={1}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <Tooltip title={<FormattedMessage id="EldenEquipmentPeriodicControls.Settings" defaultMessage="Settings" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.setState({ drawerSettingsShow: true });
                      }}
                      icon={<Settings color="error" fontSize="large" />}
                    ></AntButton>
                  </Tooltip>
                )}
              </Col>
            </Row>
            <br></br>

            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenEquipmentCerticates.controlDescription" defaultMessage="Control Description" />}
                  name="controlDescription"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layoutLeft}
                  label={<FormattedMessage id="EldenEquipmentCerticates.Scope" defaultMessage="Scope" />}
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Space.Compact style={{ width: "100%" }}>
                    <Form.Item
                      name="controlScopeId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      style={{ width: "90%" }}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children"
                        onChange={value => {
                          this.setState({
                            controlScopeId: value,
                          }, () => {
                            this.fillBrand();
                          });
                        }}>
                        {Boolean(this.state["controlScopeIdSelectItems"]) &&
                          this.state["controlScopeIdSelectItems"].length > 0 &&
                          this.state["controlScopeIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>

                    <AntButton
                      type="default"
                      style={{
                        color: "black",
                        background: "white",
                        width: "10%"
                      }}
                      onClick={() => {
                        this.setState({ showNewControlScopeModal: true });
                      }}
                      icon={<Add />}
                    ></AntButton>
                  </Space.Compact>
                </Form.Item>
              }
              {/*
              {
                <Form.Item
                  {...layoutLeft}
                  label={<FormattedMessage id="EldenEquipmentCerticates.Period" defaultMessage="Period" />}
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Space.Compact style={{ width: "100%" }}>
                    <Form.Item
                      name="standardId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      style={{ width: "90%" }}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children"
                        onChange={value => {
                          this.setState({
                            standardId: value,
                          }, () => {
                            this.fillBrand();
                          });
                        }}>
                        {Boolean(this.state["standardIdSelectItems"]) &&
                          this.state["standardIdSelectItems"].length > 0 &&
                          this.state["standardIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>

                    <AntButton
                      type="default"
                      style={{
                        color: "black",
                        background: "white",
                        width: "10%"
                      }}
                      onClick={() => {
                        this.setState({ showNewStandardModal: true });
                      }}
                      icon={<Add />}
                    ></AntButton>
                  </Space.Compact>
                </Form.Item>
              }
                */}
              {
                <Form.Item
                  {...layoutLeft}
                  label={<FormattedMessage id="EldenEquipmentCerticates.Output" defaultMessage="Output" />}
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Space.Compact style={{ width: "100%" }}>
                    <Form.Item
                      name="outputId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      style={{ width: "90%" }}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children"
                        onChange={value => {
                          this.setState({
                            outputId: value,
                          }, () => {
                            this.fillBrand();
                          });
                        }}>
                        {Boolean(this.state["outputIdSelectItems"]) &&
                          this.state["outputIdSelectItems"].length > 0 &&
                          this.state["outputIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>

                    <AntButton
                      type="default"
                      style={{
                        color: "black",
                        background: "white",
                        width: "10%"
                      }}
                      onClick={() => {
                        this.setState({ showNewOutputModal: true });
                      }}
                      icon={<Add />}
                    ></AntButton>
                  </Space.Compact>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layoutLeft}
                  label={<FormattedMessage id="EldenEquipmentCerticates.Requirement" defaultMessage="Requirement" />}
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Space.Compact style={{ width: "100%" }}>
                    <Form.Item
                      name="requirementId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      style={{ width: "90%" }}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children"
                        onChange={value => {
                          this.setState({
                            requirementId: value,
                          }, () => {
                            this.fillBrand();
                          });
                        }}>
                        {Boolean(this.state["requirementIdSelectItems"]) &&
                          this.state["requirementIdSelectItems"].length > 0 &&
                          this.state["requirementIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>

                    <AntButton
                      type="default"
                      style={{
                        color: "black",
                        background: "white",
                        width: "10%"
                      }}
                      onClick={() => {
                        this.setState({ showNewRequirementModal: true });
                      }}
                      icon={<Add />}
                    ></AntButton>
                  </Space.Compact>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layoutLeft}
                  label={<FormattedMessage id="EldenEquipmentCerticates.Authority" defaultMessage="Authority" />}
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Space.Compact style={{ width: "100%" }}>
                    <Form.Item
                      name="authorityId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      style={{ width: "90%" }}
                    >
                      <Select allowClear style={{ width: "100%" }} optionFilterProp="children"
                        onChange={value => {
                          this.setState({
                            authorityId: value,
                          }, () => {
                            this.fillBrand();
                          });
                        }}>
                        {Boolean(this.state["authorityIdSelectItems"]) &&
                          this.state["authorityIdSelectItems"].length > 0 &&
                          this.state["authorityIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>

                    <AntButton
                      type="default"
                      style={{
                        color: "black",
                        background: "white",
                        width: "10%"
                      }}
                      onClick={() => {
                        this.setState({ showNewAuthorityModal: true });
                      }}
                      icon={<Add />}
                    ></AntButton>
                  </Space.Compact>
                </Form.Item>
              }
            </div>
            <br></br>
          </Form>
          {
            Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
              <div className="alert alert-custom alert-light">
                <div className="alert-icon">
                  <FilterOutlined />
                </div>
                <div className="alert-text">
                  {this.state.searchCriteriaModels.map((val, i) => {
                    return (
                      <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                        <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                        <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                        <span>
                          <Tooltip title="Remove">
                            <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                          </Tooltip>
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="alert-close">
                  <Tooltip title="Remove All">
                    <CloseCircleOutlined
                      style={{ color: "crimson" }}
                      onClick={() =>
                        this.setState({ searchCriteriaModels: [] }, () => {
                          this.filterChangeItem([]);
                        })
                      }
                    />
                  </Tooltip>
                </div>
              </div>
            )
          }

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <Drawer
            title={<FormattedMessage id="EldenEquipmentPeriodicControls.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerSettingsShow: false });
            }}
            open={this.state.drawerSettingsShow}
          >
            <PageSettings pageName={"EldenEquipmentPeriodicControls"} />
          </Drawer>

        </div >
      </Badge.Ribbon >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EldenEquipmentPeriodicControls);