import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CreateIcon from '@material-ui/icons/Create';
import InputIcon from '@material-ui/icons/Input';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from "react-intl";
import SortIcon from '@material-ui/icons/Sort';

export default ({ row, onDeleteRow, onEditRow, onInputsRow, onActionRow, onOrderRow,onSyncMenu, size, editBgColor, deleteBgColor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteRow = () => {
    if (onDeleteRow) {
      onDeleteRow(row);
      setAnchorEl(null);
    }
  };

  const editRow = () => {
    if (onEditRow) {
      onEditRow(row);
      setAnchorEl(null);
    }
  };

  const inputsRow = () => {
    if (onInputsRow) {
      onInputsRow(row);
      setAnchorEl(null);
    }
  };

 const actionRow = () => {
    if (onActionRow) {
      onActionRow(row);
      setAnchorEl(null);
    }
  };

  const orderRow = () => {
    if (onOrderRow) {
      onOrderRow(row);
      setAnchorEl(null);
    }
  };

  // const syncMenu = () => {
  //   if (onSyncMenu) { 
  //     onSyncMenu(row);
  //     setAnchorEl(null);
  //   }
  // };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu" // flaticon-layer
        aria-haspopup="true"
        onClick={handleClick}
        size={size}        
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

        <MenuItem onClick={editRow} style={{ backgroundColor: '#' + editBgColor }}>
          <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">
          <FormattedMessage id="CustomMaterialMenuEdit" defaultMessage="Edit"/>
          </Typography>
        </MenuItem>

        <Divider /> 

        <MenuItem onClick={deleteRow} style={{ backgroundColor: '#' + deleteBgColor }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <Typography variant="inherit">
          <FormattedMessage id="CustomMaterialMenuDelete" defaultMessage="Delete"/>
          </Typography>
        </MenuItem>

        <Divider /> 

        <MenuItem onClick={inputsRow} style={{ backgroundColor: '#' + deleteBgColor }}>
          <ListItemIcon>
            <InputIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">
          <FormattedMessage id="CustomMaterialMenuInputs" defaultMessage="Inputs"/>
          </Typography>
        </MenuItem>

        <Divider /> 

        <MenuItem onClick={actionRow} style={{ backgroundColor: '#' + deleteBgColor }}>
          <ListItemIcon>
            <CreateIcon fontSize="small" color="error" />
          </ListItemIcon>
          <Typography variant="inherit">
          <FormattedMessage id="CustomMaterialMenuAction" defaultMessage="Actions"/>
          </Typography>
        </MenuItem>

        <Divider /> 

        <MenuItem onClick={orderRow} style={{ backgroundColor: '#' + deleteBgColor }}>
          <ListItemIcon>
            <SortIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">
          <FormattedMessage id="CustomMaterialMenuOrder" defaultMessage="Order"/>
          </Typography>
        </MenuItem>
        <Divider /> 
        {/* <MenuItem onClick={syncMenu} style={{ backgroundColor: '#' + deleteBgColor }}>
          <ListItemIcon>
            <SyncIcon fontSize="small" color="secondary" /> 
          </ListItemIcon> 
          <Typography variant="inherit">
          <FormattedMessage id="CustomMaterialMenuSync" defaultMessage="Sync"/> 
          </Typography>
        </MenuItem> */}

      </Menu>
    </div>
  );
};