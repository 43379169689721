import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../modules/Auth/_redux/authRedux";
import EditIcon from "@material-ui/icons/EditTwoTone";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import PageviewIcon from "@material-ui/icons/Pageview";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { handleRequest } from "../../ApiConnector";
import { API_BASE_ROOT } from "../../ApiConnector";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import {
  Form,
  message,
  Table,
  Input,
  Select,
  Switch,
  Col,
  Row,
  DatePicker,
  Button as AntButton,
  InputNumber,
} from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { DownloadOutlined } from "@ant-design/icons";
import ShareIcon from "@material-ui/icons/Share";
import { error, showError, showSuccess } from "../../MessageHelper";

const { Option } = Select;

export class File extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      hideUpdate: true,
      hideSave: false,
      hiddenInputs: true,
      filePath: "",
      fileList: [],
      showRevisedListModal: false,
      revisedFileList: [],
      modalPdfViewer: false,
      pdfFile: "",
      selectedMailAddress: [],
      userSelectItems: [],
      id: null,
      folderId: null,
      fileName: null,
      revNo: 1,
      code: null,
      folderSelectItems: [],
      revising: false,

      showShareModal: false,
      shareFileId: null,
      shareEmail: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["10", "25", "50", "100"],
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });

    this.fillComboboxes();
  };

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hiddenInputs: true,
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.setFieldsValue({
      filePath: "",
      id: null,
      folderId: null,
      fileName: null,
      revNo: 1,
      code: null,
    });

    this.setState({
      filePath: "",
      fileList: [],

      id: null,
      folderId: null,
      fileName: null,
      revising: false,
      revNo: 1,
      code: null,
      selectedMailAddress: [],
      folderNameColor: "#bfbfbf",
      fileNameColor: "#bfbfbf",
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["10", "25", "50", "100"],
      },
    });
  };

  error = (response) => {
    try {
      if (Boolean(response.response) && response.response.status === 403)
        window.location.href = "/logout";
      else
        showError(
          Boolean(response.response) && Boolean(response.response.data)
            ? response.response.data.message
            : ""
        );
    } catch (error) {}
  };

  edit = (row) => {
    let fileList = [];

    if (Boolean(row.path) && row.path.length > 0)
      fileList = [
        {
          uid: 1,
          name: row.fileName,
          size: row.size,
          url: row.path,
          thumbUrl: row.path,
        },
      ];

    this.formRef.current.setFieldsValue({
      folderId: row.folderId,
      fileName: row.fileName,
      revNo: row.revNo,
      code: row.code,
      path: row.path,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hiddenInputs: false,

      revNo: row.revNo + 1,
      code: row.code,
      id: row.id,
      folderId: row.folderId,
      fileName: row.fileName,
      filePath: row.path,
      fileList: fileList,
    });
  };

  revise = (row) => {
    let fileList = [];

    if (Boolean(row.path) && row.path.length > 0)
      fileList = [
        {
          uid: 1,
          name: row.fileName,
          url: row.path,
          thumbUrl: row.path,
        },
      ];

    this.formRef.current.setFieldsValue({
      folderId: row.folderId,
      fileName: row.fileName,
      revNo: row.revNo + 1,
      code: row.code,
      path: row.path,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hiddenInputs: false,

      id: row.id,
      folderId: row.folderId,
      fileName: row.fileName,
      filePath: row.path,
      fileList: fileList,
      revising: true,
      revNo: row.revNo + 1,
      code: row.code,
    });
  };

  showRevisedList = async (row) => {
    let url = "/api/revised/files/" + row.id;

    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        revisedFileList: response.data,
        showRevisedListModal: true,
      });
    } else {
      this.setState({
        revisedFileList: [],
        showRevisedListModal: false,
      });

      showError("There is no revised files for selected item");
    }
  };

  openShareModal = (row) => {
    this.setState({
      shareFileId: row.id,
      showShareModal: true,
    });
  };

  deleteModal = (row) => {
    this.setState({
      modalDeleteShow: true,
      id: row.id,
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow,
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id,
    };
    var response = await handleRequest(
      "DELETE",
      "/api/files/" + deletedItem.id
    );

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false,
      });
    }
  };

  share = async () => {
    const { shareFileId, selectedMailAddress } = this.state;

    const shareObject = {
      fileIds: [],
      emails: [],
    };

    shareObject.fileIds.push(shareFileId);
    if (Boolean(selectedMailAddress) && selectedMailAddress.length > 0) {
      selectedMailAddress.forEach((element) => {
        shareObject.emails.push(element);
      });

      var response = await handleRequest(
        "POST",
        "/api/files/share",
        shareObject
      );

      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess("File sended successfully");
        this.setState({
          showShareModal: false,
          shareFileId: null,
          shareEmail: null,
        });
      }
    } else showError("Please type least one email address");
  };

  onchangeFolderName = async (event) => {
    if (
      Boolean(event) &&
      Boolean(event.target) &&
      Boolean(event.target.value)
    ) {
      await this.setState({
        folderNameColor: "#1890ff",
        folderName: event.target.value,
        pagination: {
          ...this.state.pagination,
          folderName: event.target.value,
        },
      });
    } else {
      await this.setState({
        folderNameColor: "#bfbfbf",
        folderName: "",
        pagination: {
          ...this.state.pagination,
          folderName: "",
        },
      });
    }

    const { pagination } = this.state;
    this.restartTable({
      pagination,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
  };

  onchangeFileName = async (event) => {
    if (
      Boolean(event) &&
      Boolean(event.target) &&
      Boolean(event.target.value)
    ) {
      await this.setState({
        fileNameColor: "#1890ff",
        fileName: event.target.value,
        pagination: {
          ...this.state.pagination,
          fileName: event.target.value,
        },
      });
    } else {
      await this.setState({
        fileNameColor: "#bfbfbf",
        fileName: "",
        pagination: {
          ...this.state.pagination,
          fileName: "",
        },
      });
    }

    const { pagination } = this.state;
    this.restartTable({
      pagination,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);

    let url = "/api/files/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0,
        },
        sortField: "",
        sortOrder: "",
      });
    } else {
      if (response.data.length !== 0) {
        this.setState({
          loading: false,
          tableList: response.data.data,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords,
          },
          sortField: params.sortField,
          sortOrder: params.sortOrder,
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0,
          },
          sortField: "",
          sortOrder: "",
        });
      }
    }
  };

  fillComboboxes = async () => {
    let url = "/api/folders/authorized";

    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else
      this.setState({
        folderSelectItems: Boolean(response.data) ? response.data : [],
      });
  };

  onClickCreateNew = async () => {
    this.resetInputs();
    if (this.state.hiddenInputs) {
      this.setState({
        hiddenInputs: false,
        hiddenSave: false,
        hiddenUpdate: true,
      });
    } else {
      this.setState({
        hiddenInputs: true,
        hiddenSave: true,
        hiddenUpdate: false,
      });
    }
  };

  save = async () => {
    var fileName =
      Boolean(this.state.fileList) && this.state.fileList.length > 0
        ? Boolean(this.state.fileList[0].response)
          ? this.state.fileList[0].response.name
          : this.state.fileList[0].name
        : "";
    var size =
      Boolean(this.state.fileList) && this.state.fileList.length > 0
        ? Boolean(this.state.fileList[0].response)
          ? this.state.fileList[0].response.size / 1024
          : this.state.fileList[0].size
        : "";

    const newItem = {
      id: this.state.id,
      folderId: this.state.folderId,
      fileName: this.state.fileName,
      size: size,
      type: "FILE",
      path: this.state.filePath,
      code: this.state.code,
      revNo: this.state.revNo,
      extension: fileName.substring(
        fileName.lastIndexOf(".") + 1,
        fileName.length
      ),
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/files", newItem);
    } else {
      if (this.state.revising === true)
        response = await handleRequest(
          "PUT",
          "/api/files/revise/" + newItem.id,
          newItem
        );
      else
        response = await handleRequest(
          "PUT",
          "/api/files/" + newItem.id,
          newItem
        );
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.resetInputs();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.cancel();
    }
  };

  createWhere(params) {
    var current = this.state.pagination.current;
    var pageSize = this.state.pagination.pageSize;
    var folderName = "",
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      current = params.pagination.current;
      pageSize = params.pagination.pageSize;
      if (Boolean(params.pagination.folderName)) {
        folderName = params.pagination.folderName;
      }

      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      id: 0,
      type: "FILE",
      folderName: folderName,
      firstResult: current - 1, //(current - 1) * pageSize,
      maxResults: pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
    };
    return newObj;
  }

  /*  showSuccess(msg) {
        if (Boolean(msg) && msg.length > 0)
            message.success(msg);
        else
            message.success('Success');
    };

    showError(msg) {
        if (Boolean(msg) && msg.length > 0)
            message.error(msg);
        else
            message.error('An error has occured');
    }; */

  handleExportExcel = async () => {
    const { pagination } = this.state;
    const newObj = this.createWhere({
      pagination,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });

    let url = "/api/files/export";
    var response = await handleRequest("POST", url, newObj);

    if (Boolean(response.data) && Boolean(response.data.url)) {
      window.location.href = response.data.url;
    } else {
      message.error("File cant found.");
    }
  };

  handleUploadExcel = async (excelJson) => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach((element) => {
          const obj = {
            folderName: element["Folder Name"],
          };
          list.push(obj);
        });

        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/files/import", list);

          if (response.type === "ERROR") {
            error(response);
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.cancel();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      error();
    }
  };

  downloadFile = async (url) => {
    window.location.href = url;
  };

  showReport = async (url) => {
    try {
      const fileRealUrl = url;

      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true,
      });
    } catch (error) {
      alert("File cant found.");
    }
  };

  searchUserMail = async (email) => {
    if (Boolean(email) && email.length > 0) {
      var response = await handleRequest("GET", "/api/users/mails/" + email);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          userSelectItems: Boolean(response.data) ? response.data : [],
        });
      }
    } else
      this.setState({
        userSelectItems: [],
      });
  };

  render() {
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center",
            }}
          >
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList],
    };

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar
      );
    };

    const revisedColumns = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return (
            index +
            1 +
            this.state.pagination.pageSize * (this.state.pagination.current - 1)
          );
        },
      },

      {
        title: "Folder",
        key: "folderName",
        render: (record) => {
          if (Boolean(record.folder)) {
            return record.folder.folderName;
          }
        },

        sorter: true,
      },

      {
        title: "File Name",
        key: "fileName",

        render: (value, row, index) => {
          if (
            Boolean(row) &&
            Boolean(row.fileName) &&
            Boolean(row.path) &&
            row.path.includes(".pdf")
          ) {
            return (
              <React.Fragment>
                <div
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                  onClick={(e) => this.showReport(row.path)}
                >
                  {row.fileName}
                </div>
              </React.Fragment>
            );
          } else {
            return row.fileName;
          }
        },

        sorter: true,
      },
      {
        title: "Code",
        key: "code",
        render: (record) => {
          return record.code;
        },

        sorter: true,
      },
      {
        title: "Rev No",
        key: "revNo",
        render: (record) => {
          return record.revNo;
        },

        sorter: true,
      },

      {
        title: "File",
        key: "path",
        render: (record) => {
          if (Boolean(record.path))
            return (
              <AntButton
                style={{
                  marginLeft: "2px",
                  marginTop: "-2px",
                  alignContent: "center",
                }}
                onClick={() => this.downloadFile(record.path)}
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
              ></AntButton>
            );
        },
      },
    ];

    const columns = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return (
            index +
            1 +
            this.state.pagination.pageSize * (this.state.pagination.current - 1)
          );
        },
      },

      {
        title: "Folder",
        key: "folderName",
        render: (record) => {
          if (Boolean(record.folder)) {
            return record.folder.folderName;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ width: 200 }}
              value={this.state.folderName}
              placeholder="Folder Name"
              onChange={this.onchangeFolderName}
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined style={{ color: this.state.folderNameColor }} />
        ),
        sorter: true,
      },

      {
        title: "File Name",
        key: "fileName",
        render: (value, row, index) => {
          if (
            Boolean(row) &&
            Boolean(row.fileName) &&
            Boolean(row.path) &&
            row.path.includes(".pdf")
          ) {
            return (
              <React.Fragment>
                <div
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                  onClick={(e) => this.showReport(row.path)}
                >
                  {row.fileName}
                </div>
              </React.Fragment>
            );
          } else {
            return row.fileName;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ width: 200 }}
              value={this.state.fileName}
              placeholder="File Name"
              onChange={this.onchangeFileName}
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <FilterOutlined style={{ color: this.state.folderNameColor }} />
        ),
        sorter: true,
      },
      {
        title: "Code",
        key: "code",
        render: (record) => {
          return record.code;
        },

        sorter: true,
      },
      {
        title: "Rev No",
        key: "revNo",
        render: (record) => {
          return record.revNo;
        },

        sorter: true,
      },

      {
        title: "File",
        key: "path",
        render: (record) => {
          if (Boolean(record.path))
            return (
              <AntButton
                style={{
                  marginLeft: "2px",
                  marginTop: "-2px",
                  alignContent: "center",
                }}
                onClick={() => this.downloadFile(record.path)}
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
              ></AntButton>
            );
        },
      },

      {
        title: "Update",
        key: "key",
        render: (record) => {
          return (
            <EditIcon
              fontSize="default"
              color="primary"
              backgroundColor="black"
              onClick={() => this.edit(record)}
            />
          );
        },
      },
      {
        title: "Revise",
        key: "key",
        render: (record) => {
          return (
            <AccountTreeIcon
              fontSize="default"
              color="primary"
              backgroundColor="black"
              onClick={() => this.revise(record)}
            />
          );
        },
      },
      {
        title: "Revised Versions",
        key: "key",
        render: (record) => {
          return (
            <PageviewIcon
              fontSize="default"
              color="primary"
              backgroundColor="black"
              onClick={() => this.showRevisedList(record)}
            />
          );
        },
      },
      {
        title: "Share",
        key: "key",
        render: (record) => {
          return (
            <ShareIcon
              fontSize="default"
              color="info"
              backgroundColor="black"
              onClick={() => this.openShareModal(record)}
            />
          );
        },
      },
      {
        title: "Delete",
        key: "key",
        render: (record) => {
          return (
            <DeleteIcon
              fontSize="default"
              color="error"
              onClick={() => this.deleteModal(record)}
            />
          );
        },
      },
    ];

    return (
      <div
        hidden={this.state.hideInputs}
        className="card card-custom"
        style={{ padding: "2rem" }}
      >
        <Form
          initialValues={{ remember: false }}
          onFinish={this.save}
          onFinishFailed={onFinishFailed}
          ref={this.formRef}
        >
          <Row>
            <Col xs={18}></Col>
            <Col xs={2}>
              {/* <ExportIcon fontSize="large" color="action" onClick={() => this.handleExportExcel()} /> */}
            </Col>
            <Col xs={2}>
              {/* <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader> */}
            </Col>
            <Col xs={2}>
              <CreateIcon
                hidden={!this.state.hiddenInputs}
                fontSize="large"
                color="secondary"
                onClick={() => this.onClickCreateNew()}
              />
              <RemoveCircle
                hidden={this.state.hiddenInputs}
                fontSize="large"
                color="error"
                onClick={() => this.onClickCreateNew()}
              />
            </Col>
          </Row>

          <div hidden={this.state.hiddenInputs}>
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="todo" defaultMessage="Folder" />}
                name="folderId"
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select folder"
                  id="selectedCompanyIds"
                  value={this.state.folderId}
                  onChange={(value) => {
                    this.setState({ folderId: value });
                  }}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {this.state.folderSelectItems.map((i) => (
                    <Option value={i.id}>{i.folderName}</Option>
                  ))}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={
                  <FormattedMessage id="todo" defaultMessage="File Name" />
                }
                name="fileName"
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="Please type folder name"
                      />
                    ),
                  },
                ]}
              >
                <Input
                  placeholder="Name"
                  style={{ width: "100%" }}
                  id="todo"
                  value={this.state.fileName}
                  onChange={(e) => {
                    this.setState({ fileName: e.target.value });
                  }}
                />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="todo" defaultMessage="Code" />}
                name="code"
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="Please type code"
                      />
                    ),
                  },
                ]}
              >
                <Input
                  placeholder="Code"
                  style={{ width: "100%" }}
                  id="todo"
                  value={this.state.code}
                  onChange={(e) => {
                    this.setState({ code: e.target.value });
                  }}
                />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="todo" defaultMessage="Rev. No." />}
                name="revNo"
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="Please type folder name"
                      />
                    ),
                  },
                ]}
              >
                <InputNumber
                  placeholder="Rev. No."
                  style={{ width: "100%" }}
                  id="todo"
                  min={1}
                  value={this.state.revNo}
                  onChange={(value) => {
                    this.setState({ revNo: value });
                  }}
                />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="todo" defaultMessage="upload" />}
                name="path"
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="todo"
                        defaultMessage="Please upload file"
                      />
                    ),
                  },
                ]}
              >
                <Upload
                  {...props}
                  id="path"
                  showUploadList={true}
                  fileList={this.state.fileList}
                  onChange={({ fileList }) => {
                    if (fileList.length === 1) {
                      var filePath = "";
                      if (Boolean(fileList[0].response)) {
                        filePath = fileList[0].response.url;
                        showSuccess("file uploaded successfully");
                        // fileName: info.file.name,
                      }
                      this.setState({
                        fileList: fileList,
                        filePath: filePath,
                      });
                    }
                  }}
                  beforeUpload={(fileList) => {
                    if (this.state.fileList.length > 0) {
                      showError(
                        "You have been already choose a file! For change firstly remove the uploaded file."
                      );
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState({
                      fileList: [],
                      filePath: "",
                    });
                  }}
                >
                  <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Upload
                  </AntButton>
                </Upload>
              </Form.Item>
            }

            <div hidden={this.state.hideSave}>
              {
                <Row gutter={8}>
                  <Col span={8}></Col>

                  <Col span={8}>
                    {
                      <Button
                        id="OrganizationalLevelSaveButton"
                        type="submit"
                        style={{ width: "100%" }}
                        variant="success"
                      >
                        <FormattedMessage
                          id="GeneralButtonSave"
                          defaultMessage="Save"
                        />
                      </Button>
                    }
                  </Col>
                </Row>
              }
            </div>

            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={8}>
                  <Col span={8}></Col>

                  <Col span={4}>
                    <Button
                      id="OrganizationalLevelCancelButton"
                      style={{ width: "100%" }}
                      variant="secondary"
                      onClick={this.cancel}
                    >
                      <FormattedMessage
                        id="GeneralButtonCancel"
                        defaultMessage="Cancel"
                      />
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button
                      id="OrganizationalLevelUpdateButton"
                      style={{ width: "100%" }}
                      variant="warning"
                      type="submit"
                    >
                      <FormattedMessage
                        id="GeneralButtonUpdate"
                        defaultMessage="Update"
                      />
                    </Button>
                  </Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div
          style={{ marginTop: "2rem", marginBottom: "1rem" }}
          className="content-section implementation"
        >
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal
          show={this.state.modalDeleteShow}
          onHide={this.showOrHideDeleteModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Chosen record will be deleted !
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showRevisedListModal}
          onHide={() => {
            this.setState({
              showRevisedListModal: false,
            });
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Revised Files
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table
              bordered
              scroll={{ x: 400, scrollToFirstRowOnChange: true }}
              columns={revisedColumns}
              dataSource={this.state.revisedFileList}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  showRevisedListModal: false,
                });
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showShareModal}
          onHide={() => {
            this.setState({
              showShareModal: false,
            });
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {" "}
              Share File
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Input placeholder="Email" id="todo" value={this.state.shareEmail} onChange={(e) => this.setState({ shareEmail: e.target.value })} /> */}
            <Select
              showSearch
              placeholder="Search with email"
              style={{ width: "100%" }}
              mode="tags"
              onSearch={this.searchUserMail}
              id="selectedCompanyIds"
              value={this.state.selectedMailAddress}
              onChange={(value) => {
                this.setState({ selectedMailAddress: value });
              }}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.userSelectItems.map((i) => (
                <Option value={i.email}>{i.email}</Option>
              ))}
            </Select>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  showShareModal: false,
                });
              }}
            >
              Close
            </Button>

            <Button variant="info" onClick={this.share}>
              {" "}
              Share{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfViewer}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("YYY", state);

  return {
    // auth: state.auth,
    logout: state.logout,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(File);
// export default connect(null, auth.actions)(Domain);
