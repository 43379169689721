import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import { Table, DatePicker, Space, PageHeader, Image, Input, Form, Row, Col, Cascader, Select, Button as AntButton } from "antd";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import { Pie, measureTextWidth } from "@ant-design/plots";
import { Line } from "@ant-design/charts";
import { Column } from "@ant-design/charts";
import PieChart from "../../Components/dashboard/PieChart";
import { SearchOutlined } from "@ant-design/icons";
const { Option } = Select;

export class ProductStatistics extends Component {
  constructor({ disableProductNotExist }) {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      disableProductNotExist: disableProductNotExist,
      tableList: [],
      lineChart: [],
      pieChart: [],
      period: "daily",
      startDate: null,
      endDate: null,
      customerIds: null,
      productIds: null,
      salesmanIds: null,
      customerSelectItems: [],
      productSelectItems: [],
      salesmanSelectItems: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  componentDidMount = async () => {
    this.restartTable();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseCustomer = await handleRequest("GET", "/api/invoiceProduct/customerIdSelectItems");
    if (responseCustomer.type === "ERROR") {
      error(responseCustomer);
    } else {
      this.setState({
        customerSelectItems: Boolean(responseCustomer.data) ? responseCustomer.data : []
      });
    }
    var responseProduct = await handleRequest("GET", "/api/invoiceProduct/top50product");
    if (responseProduct.type === "ERROR") {
      error(responseProduct);
    } else {
      this.setState({
        productSelectItems: Boolean(responseProduct.data) ? responseProduct.data : []
      });
    }

    var responseSalesman = await handleRequest("GET", "/api/invoiceProduct/salesman");
    if (responseSalesman.type === "ERROR") {
      error(responseProduct);
    } else {
      this.setState({
        salesmanSelectItems: Boolean(responseSalesman.data) ? responseSalesman.data : []
      });
    }
  };

  handleSearchListChange = (paginationSearchList, filters, sorter) => {
    this.setState({
      paginationSearchList: paginationSearchList
    });
  };

  restartTable = async () => {
    const { period, startDate, endDate, customerIds, productIds, salesmanIds } = this.state;

    let obj = {
      startDate: startDate
        ? startDate
        : moment()
            .subtract(1, "M")
            .toDate(),
      endDate: endDate ? endDate : moment().toDate(),
      customerIds: customerIds,
      productIds: productIds,
      salesmanIds: salesmanIds
    };
    console.log(obj);
    const response = await handleRequest("POST", "/api/invoiceProduct/" + period, obj);

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false
      });
    } else {
      this.setState({
        tableList: Boolean(response.data) ? response.data.table : [],
        lineChart: Boolean(response.data) ? response.data.lineChartProduct : [],
        pieChart: Boolean(response.data) ? response.data.pieChartProduct : [],
        loading: false
      });
    }
  };

  search = async values => {
    console.log(values);
    this.setState(
      {
        ...values,
        period: Boolean(values.period) ? values.period : "daily",
        startDate: Boolean(values.startDate) ? values.startDate.toDate() : null,
        endDate: Boolean(values.endDate) ? values.endDate.toDate() : null,
        loading: true
      },
      this.restartTable
    );
  };

  searchProduct = async materialName => {
    let obj = { product: materialName };
    if (Boolean(materialName) && materialName.length > 0) var response = await handleRequest("POST", "/api/invoiceProduct/productsearch", obj);
    else response = await handleRequest("GET", "/api/invoiceProduct/top50product");

    if (Boolean(response.data)) {
      this.setState({
        productSelectItems: response.data
      });
    } else
      this.setState({
        productSelectItems: []
      });
  };

  renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    var barConfig = {
      data: this.state.lineChart,
      isGroup: true,
      xField: "dateStr",
      yField: "quantity",
      seriesField: "product",
      legend: { position: "top-left" },
      label: {
        position: "middle",
        layout: [{ type: "interval-adjust-position" }, { type: "interval-hide-overlap" }, { type: "adjust-color" }]
      }
    };

    const lineConfig = {
      data: this.state.lineChart,
      isGroup: true,
      xField: "dateStr",
      yField: "quantity",
      seriesField: "product"
    };

    var pieConfig = {
      appendPadding: 10,
      data: this.state.pieChart,
      angleField: "quantity",
      colorField: "product",
      radius: 1,
      innerRadius: 0.6,
      label: {
        type: "inner",
        offset: "-50%",
        content: '{value}',
        style: {
          textAlign: "center",
          fontSize: 14
        }
      },
      interactions: [
        {
          type: 'element-selected',
        },
        {
          type: 'element-active',
        },
        {
          type: 'pie-statistic-active',
        },
      ],
      statistic: {
        title: false,
        content: {
          offsetY: 4,
          style: {
            fontSize: '32px',
          },
          customHtml: (container, view, datum, data) => {

            const { width } = container.getBoundingClientRect();
            const text = datum ? `${datum.quantity}` : `${data.reduce((r, d) => r + d.quantity, 0)}`;
            return this.renderStatistic(width, text, {
              fontSize: 32,
            });
          },
        }
      }
    };

    const columns = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: "Date",
        key: "date",
        render: (value, row, index) => {
          if (row.dateStr) return row.dateStr;
        }
      },
      {
        title: "Customer",
        key: "customer",
        render: (value, row, index) => {
          return row.customer;
        }
      },
      {
        title: "Product",
        key: "product",
        render: (value, row, index) => {
          return row.product;
        }
      },
      {
        title: "Quantity",
        key: "quantity",
        render: (value, row, index) => {
          return row.quantity;
        }
      },
      {
        title: "Price",
        key: "price",
        render: (value, row, index) => {
          return row.price;
        }
      }
    ];
    return (
      <div>
        <Form initialValues={{ remember: false }} onFinish={this.search} onFinishFailed={onFinishFailed} ref={this.formRef}>
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductStatistics.Period" defaultMessage="Period" />}
              initialValue={"daily"}
              style={{
                marginBottom: 0
              }}
              name="period"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <Select placeholder="Period" allowClear>
                <Option key={"daily"} value={"daily"}>
                  <FormattedMessage id="period.day" defaultMessage="Day" />
                </Option>
                <Option key={"monthly"} value={"monthly"}>
                  <FormattedMessage id="period.month" defaultMessage="Month" />
                </Option>
                <Option key={"yearly"} value={"yearly"}>
                  <FormattedMessage id="period.year" defaultMessage="Year" />
                </Option>
              </Select>
            </Form.Item>
          }{" "}
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductStatistics.startdate" defaultMessage="Start Date" />}
              style={{
                marginBottom: 0
              }}
              name="startDate"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductStatistics.endDate" defaultMessage="End Date" />}
              style={{
                marginBottom: 0
              }}
              name="endDate"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductStatistics.Customer" defaultMessage="Customer" />}
              style={{
                marginBottom: 0
              }}
              name="customerIds"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <Select showSearch optionFilterProp="children" placeholder="Customer" mode="multiple" allowClear>
                <Option key={null} value={null}>
                  Select
                </Option>
                {Boolean(this.state["customerSelectItems"]) &&
                  this.state["customerSelectItems"].map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}{" "}
              </Select>
            </Form.Item>
          }{" "}
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductStatistics.product" defaultMessage="Product" />}
              name="productIds"
              style={{
                marginBottom: 0
              }}
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <Select showSearch optionFilterProp="children" mode="multiple" onSearch={this.searchProduct} style={{ marginBottom: 0, width: "100%" }}>
                <Option key={null} value={null}>
                  Select
                </Option>
                {Boolean(this.state["productSelectItems"]) &&
                  this.state["productSelectItems"].map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}{" "}
              </Select>
            </Form.Item>
          }
          {
            <Form.Item
              {...layout}
              label={<FormattedMessage id="ProductStatistics.salesman" defaultMessage="Salesman" />}
              style={{ marginBottom: 0 }}
              name="salesmanIds"
              rules={[
                {
                  required: false,
                  message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                }
              ]}
            >
              <Select showSearch optionFilterProp="children" mode="multiple" style={{ width: "100%" }}>
                <Option key={null} value={null}>
                  Select
                </Option>
                {Boolean(this.state["salesmanSelectItems"]) &&
                  this.state["salesmanSelectItems"].map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}{" "}
              </Select>
            </Form.Item>
          }
          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <AntButton type="dashed" htmlType="submit" style={{ width: "100%" }} icon={<SearchOutlined />} size="large">
                <FormattedMessage id="GeneralButtonSearch" defaultMessage="Search" />
              </AntButton>
            </Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
          </Row>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
          />
        </div>

        <Row gutter={[16, 16]}>
          <Col xs={24} xxl={24}>
            <Line {...lineConfig} />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={48} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <div style={{ width: "100%", height: this.state.height1 }}>
              <Column {...barConfig} />
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {/*<Pie {...pieConfig} />*/}
            <PieChart apiUrl="/api/invoiceProduct/monthly" detailHeader={false} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductStatistics);
