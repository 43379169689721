import { error, showError, showSuccess } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";

export async function downloadMultipleFile(url) {
  let pathList = url.split(",");
  console.log(pathList);
  if (Boolean(pathList) && pathList.length > 1) {
    var response = await handleRequest("POST", "/api/transmittalLogs/download", pathList);

    if (response.type === "ERROR") {
      error(response);
    } else {
      try {
        const link = document.createElement("a");
        link.href = response.data;
        link.download = "Files.zip";
        link.click();
      } catch (error) {
        console.log(error);
      }
    }
  } else if (Boolean(pathList) && pathList.length === 1) window.location.href = pathList[0];
}

export function getFilesFromPath(path) {
  let pathList = Boolean(path) ? path.split(",") : [];
  let fileList = [];
  if (Boolean(pathList) && pathList.length > 0) {
    pathList.forEach((item, index) => {
      let obj = {
        uid: index,
        name: item.split("download/")[1],
        url: item,
        thumbUrl: item
      };

      fileList.push(obj);
    });
  }
  return fileList;
}

export function getPathFromFiles(fileList) {
  let pathList = [];
  if (Boolean(fileList) && fileList.length > 0) {
    fileList.forEach((item, index) => {
      if (Boolean(item.response) && Boolean(item.response.url)) pathList.push(item.response.url);
      else if (Boolean(item.url)) pathList.push(item.url);
    });
  }
  return pathList.join(",");
}
