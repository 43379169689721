import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Col as ColBoot, Row as RowBoot, Form as FormBoot } from "react-bootstrap";
import { FilterOutlined, SearchOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { handleRequest } from "../../ApiConnector";
import { API_BASE_ROOT } from "../../ApiConnector";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import CustomInspectionLogMenu from "../CustomMenu/CustomInspectionLogMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import {
  Form,
  Switch,
  Table,
  Input,
  Select,
  Col,
  Row,
  DatePicker,
  Button as AntButton,
  InputNumber,
  Divider,
  Radio,
  TimePicker,
  Space,
  Checkbox,
  Modal as AntModal,
  Spin,
  Image
} from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { Upload } from "antd";
import { UploadOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { DownloadOutlined } from "@ant-design/icons";
import PageviewIcon from "@material-ui/icons/Pageview";
import { error, showError, showSuccess, showWarning, showWarningBasic } from "../../MessageHelper";
import { getFilesFromPath, getPathFromFiles } from "../helper/UploadDownloadHelper";
import store from "../../../redux/store";

const { TextArea } = Input;

const { Option } = Select;
const { getJsDateFromExcel } = require("excel-date-to-js");

function JSDateToExcelDate(inDate) {
  var returnDateTime = 25569.0 + (inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000) / (1000 * 60 * 60 * 24);
  return returnDateTime.toString().substr(0, 20);
}

const worker = {
  issueDate: moment()
};
export class InspectionLog extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      showResultModal: false,
      showResultAnswerModal: false,
      modalStatusOpen: false,
      resultUploadWarning: false,
      user: store.getState().auth.user,
      saveLoading: false,
      selectAll: false,
      radioResult: "PASS",

      hideUpdate: true,
      hideSave: false,
      hiddenInputs: true,
      filePaths: [],
      fileList: [],
      fileListChecklist: [],
      filePathsChecklist: [],
      fileListPhoto: [],
      filePathsPhoto: [],
      fileListTestReport: [],
      filePathsTestReport: [],
      selectedRowKeys: [],
      issueDate: moment(),

      bulkFileList: [],
      showRevisedListModal: false,
      revisedFileList: [],
      modalPdfViewer: false,
      pdfFile: "",
      codeName: "",
      codeName2: "",
      disciplineSelectItems: [],
      activitySelectItems: [],
      clientQCSelectItems: [],
      clientPMTSelectItems: [],
      constructionRepSelectItems: [],
      qciSelectItems: [],
      supervisorSelectItems: [],
      locationSelectItems: [],
      id: null,
      selectedRow: null,
      revising: false,
      index: null,
      result: null,
      status: "Open",
      checklistFilePath: null,
      revisionNo: 0,
      workPermitRequired: true,
      tableAddResultList: [],
      filePath: "",
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"]
      },
      paginationAddResult: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"]
      },
      loading: false,
      loadingAddResult: false,
      sortField: "",
      sortOrder: "",
      modalReportShow: false,
      itemIndex: 0,
      previousButtonProps: "none",
      loadingChecklistSingle: false,
      loadingChecklistMultiple: false,

      period: "day",
      lastUpdateDateStart: null,
      lastUpdateDateEnd: null,
      startDate: null,
      endDate: null,
      selectedMonths: null,
      selectedYears: null,
      monthsSelectItems: [],
      yearsSelectItems: []
    };
  }

  formRef = React.createRef();
  formChecklist = React.createRef();
  formAddResultList = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });

    this.fillDisciplines();
    this.fillActivities();
    this.fillConstructionRepresentative();
    this.fillClientPMT();
    this.fillClientQC();
    this.fillLocations();
    this.fillQci();
    this.fillSupervisor();

    this.formRef.current.setFieldsValue({
      issueDate: moment()
    });
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hiddenInputs: true
    });
    this.resetInputs();
  };

  addResult = async () => {
    const obj = {
      id: this.state.id,
      result: this.state.result,
      checklistFilePath: this.state.filePathsChecklist.join(),
      photoFilePath: this.state.filePathsPhoto.join()
    };

    let response = await handleRequest("PUT", "/api/inspectionLog/addResult", obj);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.resetInputs();
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    if (Boolean(this.formChecklist) && Boolean(this.formChecklist.current)) {
      this.formChecklist.current.setFieldsValue({
        result: null,
        photoPath: null,
        attachmentPath: null
      });
    }

    this.setState({
      filePath: "",
      filePaths: [],
      fileList: [],
      bulkFileList: [],
      id: null,
      revising: false,
      result: null,
      status: "Open",
      checklistFilePath: null,
      showResultModal: false,
      showResultAnswerModal: false,
      revisionNo: 0,
      workPermitRequired: true,
      checklist: null,
      selectAll: false,
      tableAddResultList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,

        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"]
      },
      paginationAddResult: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,

        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"]
      }
    });
  };

  edit = row => {
    let fileList = [];
    let fileListPhoto = [];
    let fileListChecklist = [];
    let fileListTestReport = [];
    let clientPmtIds = [];
    let clientPmtIdsArray = Boolean(row.clientPmtIds) ? row.clientPmtIds.split(",") : [];
    let pathList = Boolean(row.drawingFilePath) ? row.drawingFilePath.split(",") : [];
    if (Boolean(pathList) && pathList.length > 0) {
      pathList.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileList.push(obj);
      });
    }

    if (Boolean(clientPmtIdsArray))
      clientPmtIdsArray.forEach(element => {
        clientPmtIds.push(Number(element));
      });

    let pathListPhoto = Boolean(row.photoFilePath) ? row.photoFilePath.split(",") : [];
    if (Boolean(pathListPhoto) && pathListPhoto.length > 0) {
      pathListPhoto.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileListPhoto.push(obj);
      });
    }

    let pathListchecklist = Boolean(row.checklistFilePath) ? row.checklistFilePath.split(",") : [];
    if (Boolean(pathListchecklist) && pathListchecklist.length > 0) {
      pathListchecklist.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileListChecklist.push(obj);
      });
    }

    let pathListTestReport = Boolean(row.testReportPath) ? row.testReportPath.split(",") : [];
    if (Boolean(pathListTestReport) && pathListTestReport.length > 0) {
      pathListTestReport.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileListTestReport.push(obj);
      });
    }

    let drawingIdsStringArray = Boolean(row.drawingIds) ? row.drawingIds.split(",") : [];
    let drawingIds = [];

    drawingIdsStringArray.forEach(element => {
      drawingIds.push(parseInt(element));
    });

    let drawingIdsSelectItems = [];

    if (Boolean(row.drawingList) && row.drawingList.length > 0)
      row.drawingList.forEach(d => {
        drawingIdsSelectItems.push(d);
      });

    this.formRef.current.setFieldsValue({
      ...row,
      issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null,
      inspectionDate: Boolean(row.inspectionDate) ? moment(row.inspectionDate) : null,
      inspectionTime: Boolean(row.inspectionTime) ? moment(row.inspectionTime) : null,
      status: row.status,
      result: row.result,
      workPermitRequired: Boolean(row.workPermitRequired),
      clientPmtIds: clientPmtIds,
      drawingIds: drawingIds
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hiddenInputs: false,

      id: row.id,
      revisionNo: row.revisionNo,
      status: row.status,
      result: row.result,
      fileList: fileList,
      fileListPhoto: fileListPhoto,
      fileListChecklist: fileListChecklist,
      checklistFilePath: row.checklistFilePath,
      photoFilePath: row.photoFilePath,
      filePaths: Boolean(row.drawingFilePath) ? row.drawingFilePath.split(",") : [],
      filePathsPhoto: Boolean(row.photoFilePath) ? row.photoFilePath.split(",") : [],
      filePathsChecklist: Boolean(row.checklistFilePath) ? row.checklistFilePath.split(",") : [],
      fileListTestReport: fileListTestReport,
      filePathsTestReport: pathListTestReport,
      workPermitRequired: Boolean(row.workPermitRequired),
      drawingIdsSelectItems: drawingIdsSelectItems
    });
  };

  revise = row => {
    let fileList = [];

    let pathList = Boolean(row.drawingFilePath) ? row.drawingFilePath.split(",") : [];
    if (Boolean(pathList) && pathList.length > 0) {
      pathList.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileList.push(obj);
      });
    }

    this.formRef.current.setFieldsValue({
      ...row,
      revisionNo: row.revisionNo + 1,
      issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null,
      inspectionDate: Boolean(row.inspectionDate) ? moment(row.inspectionDate) : null,
      inspectionTime: Boolean(row.inspectionTime) ? moment(row.inspectionTime) : null,
      workPermitRequired: Boolean(row.workPermitRequired)
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hiddenInputs: false,
      id: row.id,
      revisionNo: row.revisionNo + 1,
      fileList: fileList,
      filePaths: Boolean(row.drawingFilePath) ? row.drawingFilePath.split(",") : [],
      revising: true,
      workPermitRequired: Boolean(row.workPermitRequired)
    });
  };

  showRevisedList = async row => {
    let url = "/api/revisedInspectionLog/inspectionlog/" + row.id;

    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        revisedFileList: response.data,
        showRevisedListModal: true
      });
    } else {
      this.setState({
        revisedFileList: [],
        showRevisedListModal: false
      });

      showError("There is no revised list for selected item");
    }
  };

  generateReport = async row => {
    let url = "/api/inspectionLog/report/" + row.id;

    // this.setState({
    //     modalPdfViewer: true
    // });

    const response = await handleRequest("GET", url);

    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });

      showError("There is no created report for selected item");
    }
  };

  openShareModal = row => {
    this.setState({
      shareFileId: row.id,
      showShareModal: true
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideReportModal = () => {
    this.setState({
      modalReportShow: !this.state.modalReportShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/inspectionLog/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  handleTableChangeAddResult = (pagination, filters, sorter) => {
    this.setState({
      paginationAddResult: pagination,
    });
  };

  restartTable = async (params = {}) => {
    const prd = Boolean(params.period) ? params.period : "";
    this.setState({ loading: true });
    let newObj = "";
    let url = "";

    if (prd === "day") {
      newObj = params;
      url = "/api/inspectionLog/pagingfiltered";
    } else {
      newObj = this.createWhere(params);
      url = "/api/inspectionLog/paging";
    }
    const response = await handleRequest("POST", url, newObj);

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        sortField: "",
        sortOrder: ""
      });
    } else {
      let list = Boolean(response.data.data) ? response.data.data : response.data;
      if (list !== 0) {
        // let list = (Boolean(response.data.data)) ? response.data.data : response.data;
        list.forEach((element, index) => {
          element.sn = index + 1;
          element.key = element.id;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  search = async values => {
    const { pagination } = this.state;
    var current = this.state.pagination.current;
    var pageSize = this.state.pagination.pageSize;
    var sortField = this.state.pagination.sortField;
    var sortOrder = this.state.pagination.sortOrder;

    let obj = {};
    if (values.period === "day") {
      obj = {
        ...pagination,
        firstResult: current - 1,
        maxResults: pageSize,
        sortField: sortField,
        sortOrder: sortOrder,
        period: values.period,
        lastUpdateDateStart: Boolean(values.startDate) ? values.startDate.toDate() : null,
        lastUpdateDateEnd: Boolean(values.endDate) ? values.endDate.toDate() : null
      };
    } else if (values.period === "month") {
      obj = {
        ...pagination,
        firstResult: current - 1,
        maxResults: pageSize,
        sortField: sortField,
        sortOrder: sortOrder,
        period: values.period,
        selectedMonths: values.selectedMonths,
        selectedYears: [values.selectedYears]
      };
    } else if (values.period === "year") {
      obj = {
        ...pagination,
        firstResult: current - 1,
        sortField: sortField,
        sortOrder: sortOrder,
        maxResults: pageSize,
        period: values.period,
        selectedYears: values.selectedYears
      };
    }

    this.setState(
      {
        period: Boolean(values.period) ? values.period : "day",
        loading: true
      },
      () => {
        this.restartTable(obj);
        // this.generateReport(obj);
      }
    );
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;
    var current = this.state.pagination.current;
    var pageSize = this.state.pagination.pageSize;
    var sortField = "",
      sortOrder = "";

    if (Boolean(params) && Boolean(params.pagination)) {
      current = params.pagination.current;
      pageSize = params.pagination.pageSize;

      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      firstResult: current - 1, // (current - 1) * pageSize,
      maxResults: pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  createWhereAddResult(params) {
    if (Boolean(params._pagination)) params.paginationAddResult = params._pagination;
    var current = this.state.paginationAddResult.current;
    var pageSize = this.state.paginationAddResult.pageSize;
    var sortField = "",
      sortOrder = "";

    if (Boolean(params) && Boolean(params.paginationAddResult)) {
      current = params.paginationAddResult.current;
      pageSize = params.paginationAddResult.pageSize;

      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.paginationAddResult,
      firstResult: current - 1, // (current - 1) * pageSize,
      maxResults: pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  onClickCreateNew = async () => {
    this.resetInputs();
    if (this.state.hiddenInputs) {
      this.setState({
        hiddenInputs: false,
        hiddenSave: false,
        hiddenUpdate: true
      });
    } else {
      this.setState({
        hiddenInputs: true,
        hiddenSave: true,
        hiddenUpdate: false
      });
    }
  };

  save = async values => {
    this.setState({ saveLoading: true });

    const { filePaths, filePathsChecklist, filePathsPhoto, filePathsTestReport } = this.state;
    const newItem = {
      ...values,
      id: this.state.id,
      issueDate: values.issueDate.toDate(),
      inspectionDate: values.inspectionDate.toDate(),
      inspectionTime: values.inspectionTime.toDate(),
      drawingFilePath: Boolean(filePaths) ? filePaths.toString() : "",
      testReportPath: Boolean(filePathsTestReport) ? filePathsTestReport.toString() : "",
      clientPmtIds: Boolean(values.clientPmtIds) ? values.clientPmtIds.toString() : null,
      checklistFilePath: Boolean(filePathsChecklist) ? filePathsChecklist.toString() : "",
      photoFilePath: Boolean(filePathsPhoto) ? filePathsPhoto.toString() : "",
      status: this.state.status,
      result: Boolean(this.state.status) && this.state.status === "Open" ? null : this.state.result,
      revisionNo: this.state.revisionNo,
      workPermitRequired: this.state.workPermitRequired,
      drawingIds: Boolean(values.drawingIds) ? values.drawingIds.toString() : "",
      rfiNo: values.rfi
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/inspectionLog", newItem);
    } else {
      if (this.state.revising === true) response = await handleRequest("PUT", "/api/inspectionLog/revise/" + newItem.id, newItem);
      else response = await handleRequest("PUT", "/api/inspectionLog/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.resetInputs();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.cancel();
    }
    this.setState({ saveLoading: false });
  };

  downloadFile = async url => {
    window.location.href = url;
  };

  downloadMultipleFile = async url => {
    let pathList = url.split(",");
    if (Boolean(pathList) && pathList.length > 1) {
      var response = await handleRequest("POST", "/api/transmittalLogs/download", pathList);

      if (response.type === "ERROR") {
        error(response);
      } else {
        try {
          const link = document.createElement("a");
          link.href = response.data;
          link.download = "Fİles.zip";
          link.click();
        } catch (error) {
        }
      }
    } else if (Boolean(pathList) && pathList.length === 1) this.downloadFile(pathList[0]);
  };

  showReport = async url => {
    try {
      const fileRealUrl = url;

      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true
      });
    } catch (error) {
      alert("File cant found.");
    }
  };

  searchUserMail = async email => {
    if (Boolean(email) && email.length > 0) {
      var response = await handleRequest("GET", "/api/users/mails/" + email);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          userSelectItems: Boolean(response.data) ? response.data : []
        });
      }
    } else
      this.setState({
        userSelectItems: []
      });
  };

  addItem = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "discipline"
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: ""
        });
        this.fillDisciplines();
      }
    } else {
      showError("Please enter discipline");
    }
  };

  fillDisciplines = async () => {
    const response = await handleRequest("GET", "/api/inspectionLog/disciplines");
    if (Boolean(response.data)) {
      this.setState({
        disciplineSelectItems: response.data
      });
    }
  };

  fillDrawingFiles = async value => {
    if (Boolean(value)) {
      const response = await handleRequest("GET", "/api/itpActivity/" + value);
      if (Boolean(response.data) && Boolean(response.data.drawing)) {
        let row = response.data.drawing;

        let fileList = [];

        let pathList = Boolean(row.fileUrl) ? row.fileUrl.split(",") : [];
        if (Boolean(pathList) && pathList.length > 0) {
          pathList.forEach((element, index) => {
            let obj = {
              uid: index + "",
              name: element.split("download/")[1],
              url: element,
              thumbUrl: element
            };

            fileList.push(obj);
          });
        }

        this.setState({
          fileList: fileList,
          filePaths: Boolean(row.fileUrl) ? row.fileUrl.split(",") : []
        });
      }
    } else {
    }
  };

  onChangeSwitch = checked => {
    this.setState({ workPermitRequired: checked });
  };

  actionTemplate(row) {
    return (
      <React.Fragment>
        <CustomInspectionLogMenu
          row={row}
          onDeleteRow={this.deleteModal.bind(this)}
          onReviseRow={this.revise.bind(this)}
          onEditRow={this.edit.bind(this)}
          onAddResult={this.modalProcessType.bind(this)}
          onReportRow={this.generateReport.bind(this)}
        />
      </React.Fragment>
    );
  }

  handleExportExcel = async () => {
    this.setState({ exportClick: true });
  };

  ExcelDateToJSDate = serial => {
    if (Boolean(serial) && serial.length > 0)
      if ((serial + "").includes(".")) {
        return moment(serial, "DD.MM.YYYY").toDate();
      } else {
        var utc_days = Math.floor(serial - 25569);
        var utc_value = utc_days * 86400;
        var date_info = new Date(utc_value * 1000);

        var fractional_day = serial - Math.floor(serial) + 0.0000001;

        var total_seconds = Math.floor(86400 * fractional_day);

        var seconds = total_seconds % 60;

        total_seconds -= seconds;

        var hours = Math.floor(total_seconds / (60 * 60));
        var minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
      }
    else return null;
  };

  syncTestReports = async excelJson => {
    var list = [];
    if (excelJson === null) showError("Select an excel file");
    else {
      excelJson.forEach(element => {
        let obj = {
          key: Boolean(element["RFI no"])
            ? element["RFI no"].toString().includes("#")
              ? element["RFI no"].toString().split("#")[1]
              : element["RFI no"].toString()
            : null,
          value: element["Test Report No"]
        };
        if (Boolean(obj.key) && Boolean(obj.value)) list.push(obj);
      });
      if (Boolean(list) && list.length > 0) {
        var response = await handleRequest("POST", "/api/inspectionLog/sync", list);
        if (response.type === "ERROR") {
          showError("No records to be added");
        } else {
          this.resetInputs();
          const { pagination } = this.state;
          this.restartTable({ pagination });
          showSuccess();
          this.cancel();
        }
      } else {
        showError("No records to be added");
      }
    }
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            rfiNo: element["RFI No"],
            rfi: element["RFI No"],
            logType: element["Type"],
            epmNo: element["EPM no"],
            disciplineName: element["Discipline"],
            revisionNo: element["Rev"],
            activity: element["Activity"],
            worksInspected: element["Works To Be Inspected"],
            issueDate: Boolean(element["Issue Date"]) ? getJsDateFromExcel(element["Issue Date"] + "") : null,
            inspectionDate: Boolean(element["Inspection Date"]) ? getJsDateFromExcel(element["Inspection Date"] + "") : null,
            inspectionTime:
              Boolean(element["Insp. Time"]) && element["Insp. Time"].length > 0 ? moment(element["Insp. Time"], "HH:mma").toDate() : null,
            qciName: element["QCI"],
            clientPmtName: element["PMT"],
            clientQcName: element["PID Insp"],
            constructionRepName: element["Construction Representative"],
            supervisorName: element["QC Supervisor"],
            itpActivity: {
              satipNo: element["ITP No"],
              itemNo: Boolean(element["Item no"]) > 0 ? element["Item no"].toString() : null,
              qualityProcedure: Boolean(element["Checklist"]) && element["Checklist"].length > 0 ? element["Checklist"] : ""
            },
            inspectionLevelClient: element["Ins.Level Client"],
            inspectionLevelMcc: element["Ins.Level MCC"],
            status: Boolean(element["Status"]) ? (element["Status"] === "Closed" ? "Closed" : "Open") : "",
            result: Boolean(element["Result"])
              ? element["Result"] === "Accepted" || element["Result"] === "Approved"
                ? "Approved"
                : element["Result"]
              : "",
            drawingNo: element["Drawing no"]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/inspectionLog/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.cancel();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("Catch");
    }
  };

  fillActivities = async () => {
    const response = await handleRequest("GET", "/api/itpActivity");
    if (Boolean(response.data)) {
      this.setState({
        activitySelectItems: response.data
      });
    }
  };

  addCodelocationId = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "location"
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: ""
        });
        this.fillLocations();
      }
    } else {
      showError("Please enter discipline");
    }
  };

  fillLocations = async () => {
    const response = await handleRequest("GET", "/api/codes/type/location");
    if (Boolean(response.data)) {
      this.setState({
        locationSelectItems: response.data
      });
    }
  };

  addConstructionRepresentative = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "constructionRep"
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: ""
        });
        this.fillConstructionRepresentative();
      }
    } else {
      showError("Please enter discipline");
    }
  };

  fillConstructionRepresentative = async () => {
    const response = await handleRequest("GET", "/api/codes/type/constructionRep");
    if (Boolean(response.data)) {
      this.setState({
        constructionRepSelectItems: response.data
      });
    }
  };

  addQci = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "qci"
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: ""
        });
        this.fillQci();
      }
    } else {
      showError("Please enter discipline");
    }
  };

  fillQci = async () => {
    const response = await handleRequest("GET", "/api/codes/type/qci");
    if (Boolean(response.data)) {
      this.setState({
        qciSelectItems: response.data
      });
    }
  };

  addSupervisor = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "supervisor"
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: ""
        });
        this.fillSupervisor();
      }
    } else {
      showError("Please enter supervisor");
    }
  };

  fillSupervisor = async () => {
    const response = await handleRequest("GET", "/api/codes/type/supervisor");
    if (Boolean(response.data)) {
      this.setState({
        supervisorSelectItems: response.data
      });
    }
  };

  addClientPMT = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "clientPMT"
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: ""
        });
        this.fillClientPMT();
      }
    } else {
      showError("Please enter discipline");
    }
  };

  fillClientPMT = async () => {
    const response = await handleRequest("GET", "/api/codes/type/clientPMT");
    if (Boolean(response.data)) {
      this.setState({
        clientPMTSelectItems: response.data
      });
    }
  };

  addClientQC = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: "clientQC"
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: ""
        });
        this.fillClientQC();
      }
    } else {
      showError("Please enter discipline");
    }
  };

  fillClientQC = async () => {
    const response = await handleRequest("GET", "/api/codes/type/clientQC");
    if (Boolean(response.data)) {
      this.setState({
        clientQCSelectItems: response.data
      });
    }
  };

  refreshLinkDrawingList = async drawingSearch => {
    let obj = { drawingNo: drawingSearch };

    const response = await handleRequest("POST", "/api/drawings/search", obj);
    if (Boolean(response.data)) {
      this.setState({
        drawingIdsSelectItems: response.data
      });
    } else
      this.setState({
        drawingIdsSelectItems: []
      });
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  sendMailSelectedInspectionReports = async () => {
    this.setState({ loading: true });
    var response = await handleRequest("GET", "/api/inspectionLog/createreportandsendemail/" + this.state.selectedRowKeys);
    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess("When the report is completed, it will be sent via e-mail.");
    }
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false
      });
    }, 1000);
  };

  sendMailSelectedInspectionReportsAsMir = async () => {
    this.setState({ loading: true });
    var response = await handleRequest("GET", "/api/inspectionLog/createmirreportandsendemail/" + this.state.selectedRowKeys);
    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess("When the report is completed, it will be sent via e-mail.");
    }
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false
      });
    }, 1000);
  };

  disabledDate = current => {
    // Can not select days before today and today
    return current && current < this.state.issueDate;
  };

  onChangeDiscipline = async value => {
    const { supervisorSelectItems, clientPMTSelectItems, clientQCSelectItems } = this.state;

    if (Boolean(value)) {
      var response = await handleRequest("GET", "/api/inspectionLog/last/" + value);
      if (response.type === "ERROR") {
      } else {
        let lastInspection = response.data;

        if (
          Boolean(lastInspection) &&
          Boolean(supervisorSelectItems) &&
          supervisorSelectItems.findIndex(x => x.id === lastInspection.supervisorId) > -1
        ) {
          this.formRef.current.setFieldsValue({
            supervisorId: lastInspection.supervisorId
          });
        }

        if (
          Boolean(lastInspection) &&
          Boolean(clientPMTSelectItems) &&
          clientPMTSelectItems.findIndex(x => Boolean(lastInspection.clientPmtIds) && lastInspection.clientPmtIds.includes(x.id + "")) > -1
        ) {
          this.formRef.current.setFieldsValue({
            clientPmtIds: lastInspection.clientPmtIds.split(",").map(x => {
              return Number(x);
            })
          });
        }

        if (Boolean(lastInspection) && Boolean(clientQCSelectItems) && clientQCSelectItems.findIndex(x => x.id === lastInspection.clientQcId) > -1) {
          this.formRef.current.setFieldsValue({
            clientQcId: lastInspection.clientQcId
          });
        }
      }
    }
  };

  generateFilteredReport = async obj => {
    let url = "/api/inspectionTestReport/report";

    const response = await handleRequest("POST", url, obj);

    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });

      showError("There is no created report for selected item");
    }
  };

  saveSingleResultList = async () => {
    this.formChecklist.current.submit();
  };

  handleFormSingleListSubmit = async values => {
    const itemIndex = this.state.itemIndex;
    this.setState({
      itemIndex: itemIndex + 1,
      previousButtonProps: ""
    });

    var newItem = {
      ...values,
      itemNo: this.state.checklist.itemNo,
      saicId: this.state.checklist.saicId,
      inspectionLogId: this.state.selectedChecklistRow.id
    }

    this.setInspectionChecklistResultObj(values, this.state.checklist, newItem);

    var response = await handleRequest("POST", "/api/inspectionChecklistResult/save", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.singleOpenCheckListModel(this.state.selectedChecklistRow);
    }
  };

  singleChecklistModel = async () => {
    var row = this.state.selectedChecklistRowData;
    this.setState({
      itemIndex: 0,
      previousButtonProps: "none",
    }, () => {
      this.singleOpenCheckListModel(row)
    });
  };

  singleOpenCheckListModel = async row => {
    this.setState({ selectedChecklistRow: row, loadingChecklistSingle: true });
    if (Boolean(row.itpActivity) && Boolean(row.itpActivity.checklistItemIds) && row.itpActivity.checklistItemIds.length > 0) {
      var checklistItemIds = row.itpActivity.checklistItemIds.split(",");
      if (Boolean(checklistItemIds) && checklistItemIds.length > 0) {
        if (checklistItemIds.length > this.state.itemIndex) {
          var checklistItemId = checklistItemIds[this.state.itemIndex];
          let response = await handleRequest(
            "GET",
            "/api/saiclog/getChecklistItem/" + row.itpActivity.qualityProcedure + "/" + checklistItemId + "/" + row.itpActivityId + "/" + row.id
          );
          if (response.type === "ERROR") {
            error(response);
            this.setState({
              loadingChecklistSingle: false,
            });
          } else {
            this.setState({
              checklist: response.data,
              showChecklistModal: true,
              loadingChecklistSingle: false,
              modalProcessTypeShow: false,
              fileList: this.getFileList(response.data),
            });
            this.formChecklist.current.setFieldsValue({
              ...response.data,
            });
          }
          this.setState({
            nextButtonProps: ""
          });
        } else {
          this.setState({
            loadingChecklistSingle: false,
            nextButtonProps: "none"
          });
        }
      } else {
        this.setState({
          loadingChecklistSingle: false,
        });
      }
    } else {
      if (Boolean(row.itpActivity)) {
        showWarning("There is no checklist items for selected record");
      } else {
        showWarning("There is no ITP Activity for selected record");
      }
      this.setState({
        loadingChecklistSingle: false,
      });
    }
  };

  saveMultipleResultList = async () => {
    this.formAddResultList.current.submit();
  };

  handleFormMultipleListSubmit = async values => {
    var newItem = {
      ...values,
      itemNo: this.state.selectedAddResultAnswer.itemNo,
      saicId: this.state.selectedAddResultAnswer.saicId,
      inspectionLogId: this.state.selectedChecklistRowData.id
    }

    this.setInspectionChecklistResultObj(values, this.state.selectedAddResultAnswer, newItem);

    var response = await handleRequest("POST", "/api/inspectionChecklistResult/save", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.multipleChecklistModel();
      this.setState({
        showResultAnswerModal: false,
        selectedRowKeys: [],
      });
    }
  };

  setInspectionChecklistResultObj = (values, data, newItem) => {
    if (Boolean(data.answerType)) {
      var options = [];
      if (Boolean(values.options) && values.options.length > 0) {
        values.options.forEach(element => {
          if (Boolean(data.questionnary) && Boolean(data.questionnary.options) &&
            data.questionnary.options.length > 0) {
            var obj = data.questionnary.options.find(p => p.codeId === element);
            if (Boolean(obj)) {
              options.push({
                ["codeId"]: element,
                ["codeName"]: obj.codeName,
                ["value"]: true,
              });
            }
          }
        });
        newItem.options = options;
      } else if (Boolean(values.options)) {
        if (Boolean(data.questionnary) && Boolean(data.questionnary.options) &&
          data.questionnary.options.length > 0) {
          var obj = data.questionnary.options.find(p => p.codeId === values.options);
          if (Boolean(obj)) {
            options.push({
              ["codeId"]: values.options,
              ["codeName"]: obj.codeName,
              ["value"]: true,
            });
          }
        }
        newItem.options = options;
      }

      var imageOptions = [];
      if (Boolean(values.imageOptions)) {
        imageOptions.push({
          ["imageUrl"]: values.imageOptions,
          ["value"]: true,
        });
        newItem.imageOptions = imageOptions;
      }
    }

    newItem.filePath = Boolean(this.state.fileList) && this.state.fileList.length > 0 ?
      this.state.fileList[0].url : ""
  }

  modalProcessType = async (row) => {
    this.setState({
      modalProcessTypeShow: true,
      selectedChecklistRowData: row,
    });
  }

  multipleChecklistModel = async () => {
    var row = this.state.selectedChecklistRowData;
    if (Boolean(row.itpActivity)) {
      this.setState({ loadingChecklistMultiple: true, loadingAddResult: true });
      let response = await handleRequest(
        "GET",
        "/api/inspectionLog/getAddResultItem/" + row.itpActivity.qualityProcedure + "/" + row.itpActivityId + "/" + row.id
      );
      if (response.type === "ERROR") {
        error(response);
        this.setState({
          loadingChecklistMultiple: false,
          loadingAddResult: false,
        });
      } else {
        if (Boolean(response.data) && response.data.length > 0) {
          response.data.forEach((element, index) => {
            element.id = index + 1;
          });
          this.setState({
            tableAddResultList: response.data,
            showResultModal: true,
            modalProcessTypeShow: false,
            loadingChecklistMultiple: false,
            loadingAddResult: false,
            selectedRowKeys: [],
          });
        } else {
          this.setState({
            tableAddResultList: [],
            showResultModal: true,
            modalProcessTypeShow: false,
            loadingChecklistMultiple: false,
            loadingAddResult: false,
            selectedRowKeys: [],
          });
        }
      }
    } else {
      if (Boolean(row.itpActivity)) {
        showWarning("There is no checklist items for selected record");
      } else {
        showWarning("There is no ITP Activity for selected record");
      }
    }
  };

  fillInspectionLogChecklist = async (checklist, id) => {
    const response = await handleRequest("GET", "/api/inspectionChecklistResult/checklist/" + id);
    if (response.type === "ERROR") {
      // error(response);
    } else {
      let data = response.data;
      if (Boolean(data) && data.length > 0)
        checklist.forEach(element => {
          let fObj = data.find(p => p.saicId === element.saicId);
          if (Boolean(fObj)) {
            element.id = fObj.id;
            element.result = Boolean(fObj.result) ? fObj.result : element.result;
            element.photoPath = getFilesFromPath(fObj.photoPath);
            element.attachmentPath = getFilesFromPath(fObj.attachmentPath);
          }
        });
    }
  };

  getChecklistDescription = index => {
    if (Boolean(this.state.checklist) && Boolean(this.state.checklist[index])) {
      return this.state.checklist[index].acceptanceCriteria;
    }
  };

  getChecklistItemNo = index => {
    if (Boolean(this.state.checklist) && Boolean(this.state.checklist[index])) {
      return this.state.checklist[index].itemNo + ".";
    }
  };

  onSelectChangeAddResult = selectedRowKeys => {
    if (Boolean(selectedRowKeys) && selectedRowKeys.length === 1) {
      var row = this.state.tableAddResultList.find(p => p.id === selectedRowKeys[0]);
      if (Boolean(row)) {
        this.setState({
          showResultAnswerModal: true,
          selectedAddResultAnswer: row,
          fileList: this.getFileList(row),
        }, () => {
          if (Boolean(this.formAddResultList) && Boolean(this.formAddResultList.current)) {
            this.formAddResultList.current.setFieldsValue({
              ...row,
            });
          }
        });
      }
    }
    this.setState({ selectedRowKeys });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search and click enter`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
      </div>
    ),
    filterIcon: filtered => <FilterOutlined style={{ color: filtered ? '#1890ff' : '#bfbfbf' }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  getFileList(row) {
    var fileList = [];
    let filePath = Boolean(row.filePath) ? row.filePath.split(",") : [];
    if (Boolean(filePath) && filePath.length > 0) {
      filePath.forEach((element, index) => {
        let obj = {
          uid: index,
          name: element.split("download/")[1],
          url: element,
          thumbUrl: element
        };

        fileList.push(obj);
      });
    }
    return fileList;
  }

  checklistAnswerType(data, layout, props) {
    return <>
      {Boolean(data) &&
        <>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <span
                className="mr-3"
                style={{
                  fontWeight: "bold"
                }}
              >
                {data.itemNo + ". "}
              </span>
              <div className="d-inline">
                {data.question}
              </div>
            </Col>
          </Row>
          <br />

          {Boolean(data.answerType) && data.answerType === 'Matrix_Multiple'
            && <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
              <ColBoot></ColBoot>
              {Boolean(data.questionnary) && data.questionnary.columns.length > 0
                && data.questionnary.columns.map((col, j) => (
                  <>
                    <ColBoot>
                      {col.codeName}
                    </ColBoot>
                  </>
                ))}
            </FormBoot.Group>}

          {Boolean(data.answerType) && (data.answerType === 'Matrix_Multiple') &&
            Boolean(data.questionnary) && Boolean(data.questionnary.matrixValues)
            && data.questionnary.matrixValues.length > 0 && data.questionnary.matrixValues.map((row, k) => (
              <>
                <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <ColBoot > {row.title} </ColBoot>
                  {Boolean(data.questionnary.columns) && data.questionnary.columns.length > 0
                    && data.questionnary.columns.map((col, j) => (
                      <ColBoot>
                        <Select
                          style={{ width: '100%' }}
                          placeholder="Options"
                        >
                          {Boolean(data.questionnary.options) &&
                            data.questionnary.options.length > 0 &&
                            data.questionnary.options.map((opt, j) => (
                              Boolean(opt.codeId) &&
                              <Option key={opt.codeId} value={opt.codeId}>
                                {opt.codeName}
                              </Option>
                            ))}
                        </Select>
                      </ColBoot>
                    ))}
                </FormBoot.Group>
              </>
            ))}

          {Boolean(data.answerType) && data.answerType === 'Matrix_Single'
            && <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
              <ColBoot></ColBoot>
              {Boolean(data.questionnary) && data.questionnary.columns.length > 0 &&
                data.questionnary.columns.map((col, j) => (
                  <>
                    <ColBoot>
                      {col.codeName}
                    </ColBoot>
                  </>
                ))}
            </FormBoot.Group>}

          {Boolean(data.answerType) && (data.answerType === 'Matrix_Single') &&
            Boolean(data.questionnary) && Boolean(data.questionnary.columns) &&
            data.questionnary.columns.length > 0 &&
            Boolean(data.questionnary.matrixValues) && data.questionnary.matrixValues.length > 0
            && data.questionnary.matrixValues.map((row, k) => (
              <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                <ColBoot>
                  {row.title}
                </ColBoot>
                {Boolean(data.questionnary.columns) &&
                  data.questionnary.columns.length > 0 &&
                  data.questionnary.columns.map((opt, j) => (
                    Boolean(opt.codeId) &&
                    <ColBoot>
                      <Radio></Radio>
                      {/* <Radio value={opt.value} checked={opt.value} defaultChecked={opt.value}
                        onChange={(e) => {
                          { console.log("KKK", e) }
                          opt.value = e.target.checked;
                          row.value = e.target.checked;
                          row.codeId = opt.codeId;
                          var otherObj = data.questionnary.columns.filter(p => p.codeId !== opt.codeId);
                          { console.log("XXX", otherObj) }
                          { console.log("YYY", opt) }
                          { console.log("ZZZ", row) }
                          if (Boolean(otherObj) && otherObj.length > 0) {
                            otherObj.forEach(element => {
                              element.value = false;
                            });
                          }
                          this.setState({
                            [data]: data,
                          });
                        }} ></Radio> */}
                    </ColBoot>
                  ))}
              </FormBoot.Group>
            ))}

          {Boolean(data.answerType) && (data.answerType === 'Radiogroup') &&
            <Form.Item
              {...layout}
              label={<FormattedMessage id="InspectionLog.options" defaultMessage="Options" />}
              name="options"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Radio.Group>
                {Boolean(data.questionnary) && Boolean(data.questionnary.options) &&
                  data.questionnary.options.length > 0 &&
                  data.questionnary.options.map((opt, j) => (
                    Boolean(opt.codeId) &&
                    <Radio value={opt.codeId}>
                      {opt.codeName}
                    </Radio>
                  ))}
              </Radio.Group>
            </Form.Item>}

          {Boolean(data.answerType) && (data.answerType === 'Checkbox' || data.answerType === 'Dropdown') &&
            <Form.Item
              {...layout}
              label={<FormattedMessage id="InspectionLog.options" defaultMessage="Options" />}
              name="options"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Options"
              >
                {Boolean(data.questionnary) && Boolean(data.questionnary.options) &&
                  data.questionnary.options.length > 0 &&
                  data.questionnary.options.map((opt, j) => (
                    Boolean(opt.codeId) &&
                    <Option key={opt.codeId} value={opt.codeId}>
                      {opt.codeName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>}

          {Boolean(data.answerType) && data.answerType === 'Rating' &&
            <Form.Item
              {...layout}
              label={<FormattedMessage id="InspectionLog.rate" defaultMessage="Rate" />}
              name="rate"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <InputNumber />
            </Form.Item>}

          {Boolean(data.answerType) && data.answerType === 'Imagepicker' &&
            <Form.Item
              {...layout}
              label={<FormattedMessage id="InspectionLog.imageOptions" defaultMessage="Options" />}
              name="imageOptions"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Radio.Group>
                {Boolean(data.questionnary) && Boolean(data.questionnary.imageOptions) &&
                  data.questionnary.imageOptions.length > 0 &&
                  data.questionnary.imageOptions.map((opt, j) => (
                    <Radio value={opt.imageUrl}>
                      <img alt="option" style={{ width: '120px', height: '120px' }} src={opt.imageUrl} />
                    </Radio>
                  ))}
              </Radio.Group>
            </Form.Item>}

          {Boolean(data.answerType) && data.answerType === 'File' &&
            <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
              <ColBoot xs="4">
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="InspectionLog.filePath" defaultMessage="File Path" />}
                  name="filePath"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Upload
                    {...props}
                    showUploadList={true}
                    fileList={this.state.fileList}
                    onChange={({ fileList }) => {
                      if (fileList.length == 1) {
                        if (Boolean(fileList[0].response)) {
                          fileList[0].url = fileList[0].response.url;
                          showSuccess("file uploaded successfully");
                        }
                        this.setState({
                          fileList: fileList
                        });
                      }
                    }}
                    beforeUpload={fileList => {
                      if (this.state.fileList.length > 0) {
                        showError("You have been already choose a file! For change firstly remove the uploaded file.");
                        return false;
                      }
                      return true;
                    }}
                    onRemove={fileList => {
                      this.setState({
                        fileList: []
                      });
                    }}
                  >
                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> Click to Upload
                    </AntButton>
                  </Upload>
                </Form.Item>
              </ColBoot>
            </FormBoot.Group>}

          {Boolean(data.answerType) && data.answerType === 'Boolean' &&
            Boolean(data.questionnary) && Boolean(data.questionnary.id) &&
            <Form.Item
              {...layout}
              label={<FormattedMessage id="InspectionLog.resultBool" defaultMessage="Select" />}
              name="resultBool"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Switch checkedChildren={data.questionnary.labelTrue}
                unCheckedChildren={data.questionnary.labelFalse}
                valuePropName="checked" />
            </Form.Item>}

          {Boolean(data.answerType) && data.answerType === 'Scoring' &&
            <Form.Item
              {...layout}
              label={<FormattedMessage id="InspectionLog.score" defaultMessage="Score" />}
              name="score"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <InputNumber placeholder="Score" />
            </Form.Item>}

          {Boolean(data.answerType) && data.answerType === 'Comment' &&
            <Form.Item
              {...layout}
              label={<FormattedMessage id="InspectionLog.comment" defaultMessage="Comment" />}
              name="comment"
              rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <TextArea placeholder="Comment" style={{ width: "100%" }} rows={3} />
            </Form.Item>}
        </>}
    </>;
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 8,
          offset: 0
        },
        sm: {
          span: 8,
          offset: 8
        }
      }
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const layout = {
      labelCol: { span: 0 },
      wrapperCol: { span: 16 }
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const propsBulk = {
      action: `${API_BASE_ROOT}/File/upload/inspectiontest`,
      listType: "picture",
      defaultFileList: [...this.state.bulkFileList]
    };

    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const { loading, selectedRowKeys } = this.state;
    const hasSelected = selectedRowKeys.length > 0;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const rowSelectionAddResult = {
      selectedRowKeys,
      onChange: this.onSelectChangeAddResult
    };

    const columns = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        },
        width: 50,
        ellipsis: true
      },
      {
        title: "Actions",
        key: "actions",
        render: record => {
          return this.actionTemplate(record);
        },
        width: 70,
        ellipsis: true
      },
      {
        title: "RFI",
        key: "rfi",
        render: record => {
          if (record.rfi === -1) return record.rfiNo;
          else return record.rfi;
        },

        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.rfiNo) ? this.state.pagination.rfiNo : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  rfiNo: e.target.value,
                  rfiNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.rfiNoColor) ? this.state.pagination.rfiNoColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 100,
        ellipsis: true
      },

      {
        title: "Rev. No",
        key: "revNo",
        render: record => {
          return record.revisionNo;
        },

        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.revisionNo) ? this.state.pagination.revisionNo : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  revisionNo: e.target.value,
                  revisionNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.revisionNoColor) ? this.state.pagination.revisionNoColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 100,
        ellipsis: true
      },

      // {
      //   title: "Type",
      //   key: "logType",
      //   render: record => {
      //     return record.logType;
      //   },
      //   filterDropdown: () => (
      //     <div style={{ padding: 8 }}>
      //       <Radio.Group
      //         id="InspectionLog.logtype"
      //         value={Boolean(this.state.pagination.logType) ? this.state.pagination.logType : null}
      //         onChange={async e => {
      //           const { pagination } = this.state;
      //           let _pagination = {
      //             ...pagination,
      //             logType: e.target.value,
      //             logTypeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
      //           };
      //           this.setState({
      //             pagination: _pagination
      //           });

      //           this.restartTable({
      //             _pagination,
      //             sortField: this.state.sortField,
      //             sortOrder: this.state.sortOrder
      //           });
      //         }}
      //       >
      //         <Radio value={"Construction Inspection"}>Construction Inspection</Radio>
      //         <Radio value={"Item Receiving"}>Item Receiving</Radio>
      //       </Radio.Group>
      //     </div>
      //   ),
      //   filterIcon: filtered => (
      //     <FilterOutlined
      //       style={{
      //         color: Boolean(this.state.pagination.logTypeColor) ? this.state.pagination.logTypeColor : "#bfbfbf"
      //       }}
      //     />
      //   ),
      //   sorter: true,
      //   width: 200,
      //   ellipsis: true
      // },

      {
        title: "Epm No",
        key: "epmNo",
        render: record => {
          return record.epmNo;
        },

        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.epmNo) ? this.state.pagination.epmNo : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  epmNo: e.target.value,
                  epmNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.epmNoColor) ? this.state.pagination.epmNoColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 200,
        ellipsis: true
      },

      {
        title: "Discipline",
        key: "disciplineId",
        render: record => {
          if (Boolean(record.discipline)) {
            return record.discipline.name;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Dsicipline"
              id="DefinitionsType"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  disciplineId: value,
                  disciplineColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.disciplineSelectItems.map(i => (
                <Option key={i.key} value={i.key}>
                  {i.value}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.disciplineColor) ? this.state.pagination.disciplineColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 150,
        ellipsis: true
      },

      {
        title: "Inspection Date",
        key: "inspectionDate",
        render: record => {
          if (Boolean(record.inspectionDate)) {
            return moment(record.inspectionDate).format("YYYY-MM-DD");
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <DatePicker
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.inspectionDate) ? this.state.pagination.inspectionDate : ""}
              placeholder="Date"
              onChange={async date => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  inspectionDate: date,
                  inspectionDateColor: Boolean(date) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.inspectionDateColor) ? this.state.pagination.inspectionDateColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 150,
        ellipsis: true
      },

      {
        title: "Inspection Time",
        key: "inspectionTime",
        render: record => {
          if (Boolean(record.inspectionTime)) {
            return moment(record.inspectionTime).format("HH:mm");
          }
        },

        width: 150,
        ellipsis: true
      },

      {
        title: "Activity",
        key: "itpActivityId",
        render: record => {
          if (Boolean(record.itpActivity)) {
            return record.itpActivity.activity;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Activity"
              id="DefinitionsType"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  itpActivityId: value,
                  itpActivityIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.activitySelectItems.map(i => (
                <Option key={i.id} value={i.id}>
                  {i.activity}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.itpActivityIdColor) ? this.state.pagination.itpActivityIdColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 150,
        ellipsis: true
      },
      {
        title: "Works To Be Inspected",
        key: "worksInspected",
        render: record => {
          return record.worksInspected;
        },

        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.worksInspected) ? this.state.pagination.worksInspected : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  worksInspected: e.target.value,
                  worksInspectedColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.worksInspectedColor) ? this.state.pagination.worksInspectedColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 200,
        ellipsis: true
      },
      {
        title: "Issue Date",
        key: "issueDate",
        render: record => {
          if (Boolean(record.issueDate)) {
            return moment(record.issueDate).format("YYYY-MM-DD");
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <DatePicker
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.issueDate) ? this.state.pagination.issueDate : ""}
              placeholder="Date"
              onChange={async date => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  issueDate: date,
                  issueDateColor: Boolean(date) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.issueDateColor) ? this.state.pagination.issueDateColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 150,
        ellipsis: true
      },

      {
        title: "Location",
        key: "locationId",
        render: record => {
          if (Boolean(record.location)) {
            return record.location.name;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Location"
              id="locationId"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  locationId: value,
                  locationIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.locationSelectItems.map(i => (
                <Option key={i.id} value={i.id}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.locationIdColor) ? this.state.pagination.locationIdColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 150,
        ellipsis: true
      },

      {
        title: "QCI",
        key: "qciId",
        render: record => {
          if (Boolean(record.qci)) {
            return record.qci.name;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="QCI"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  qciId: value,
                  qciIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
              dropdownRender={menu => <div>{menu}</div>}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.qciSelectItems.map(i => (
                <Option key={i.id} value={i.id}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.qciIdColor) ? this.state.pagination.qciIdColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 150,
        ellipsis: true
      },

      {
        title: "Client Pmt",
        key: "clientPmtId",
        render: record => {
          if (Boolean(record.clientPmtNames) && record.clientPmtNames.length > 0) return record.clientPmtNames.toString();
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Client PMT"
              id="clientPmtId"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  clientPmtId: value,
                  clientPmtIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.clientPMTSelectItems.map(i => (
                <Option key={i.id} value={i.id}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.clientPmtIdColor) ? this.state.pagination.clientPmtIdColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 150,
        ellipsis: true
      },
      {
        title: "Client QC",
        key: "clientQcId",
        render: record => {
          if (Boolean(record.clientQc)) {
            return record.clientQc.name;
          }
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Client QC"
              id="clientQcId"
              onChange={value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  clientQcId: value,
                  clientQcIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            >
              <Option key={null} value={null}>
                Select
              </Option>
              {this.state.clientQCSelectItems.map(i => (
                <Option key={i.id} value={i.id}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.clientQcIdColor) ? this.state.pagination.clientQcIdColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 150,
        ellipsis: true
      },
      {
        title: "Remarks",
        key: "remarks",
        render: record => {
          return record.remarks;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              showSearch
              optionFilterProp="children"
              style={{ width: 200 }}
              placeholder="Rev. No"
              onChange={e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  remarks: e.target.value,
                  remarksColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });
                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            ></Input>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.remarksColor) ? this.state.pagination.remarksColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: 120,
        ellipsis: true
      },

      {
        title: "ITP",
        key: "itpLogId",
        render: record => {
          if (Boolean(record.itpActivity)) {
            return record.itpActivity.satipNo;
          }
        },
        sorter: true,
        width: 150,
        ellipsis: true
      },

      {
        title: "Checklist",
        key: "checklistId",
        render: record => {
          if (Boolean(record.itpActivity)) {
            return record.itpActivity.qualityProcedure;
          }
        },
        sorter: true,
        width: 150,
        ellipsis: true
      },

      {
        title: "Client Inspection Level",
        key: "clientInspectionLevelId",
        render: record => {
          if (record.clientInspectionLevel) {
            return record.clientInspectionLevel;
          }
        },
        sorter: true,
        width: 150,
        ellipsis: true
      },

      {
        title: "Company Inspection Level",
        key: "companyInspectionLevelId",
        render: record => {
          if (record.companyInspectionLevel) {
            return record.companyInspectionLevel;
          }
        },
        sorter: true,
        width: 150,
        ellipsis: true
      },

      {
        title: "Drawing Reference",
        key: "drawingId",
        render: record => {
          let drawings = [];
          if (Boolean(record.drawingList)) {
            drawings = record.drawingList.map(i => i.drawingNo + "-" + i.shtNo + "-" + i.revNo);
          }
          return drawings.toString();
        },
        sorter: true,
        width: 150,
        ellipsis: true
      },

      {
        title: "Drawing File",
        key: "drawingFilePath",
        render: record => {
          if (Boolean(record.drawingFilePath))
            return (
              <AntButton
                style={{
                  marginLeft: "2px",
                  marginTop: "-2px",
                  alignContent: "center"
                }}
                onClick={() => this.downloadMultipleFile(record.drawingFilePath)}
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
              ></AntButton>
            );
        },
        width: 120,
        ellipsis: true
      },

      {
        title: "Checklist File",
        key: "checklistFilePath",
        render: record => {
          if (Boolean(record.checklistFilePath))
            return (
              <AntButton
                style={{
                  marginLeft: "2px",
                  marginTop: "-2px",
                  alignContent: "center"
                }}
                onClick={() => this.downloadMultipleFile(record.checklistFilePath)}
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
              ></AntButton>
            );
        },
        width: 120,
        ellipsis: true
      },

      {
        title: "Status",
        key: "status",
        render: record => {
          return record.status;
        },
        width: 100,
        ellipsis: true
      },

      {
        title: "Result",
        key: "result",
        render: record => {
          return record.result;
        },
        width: 100,
        ellipsis: true
      },

      {
        title: "Revised Versions",
        key: "revisedVersions",
        render: record => {
          return <PageviewIcon fontSize="default" color={"primary"} backgroundColor="black" onClick={() => this.showRevisedList(record)} />;
        },
        width: 100,
        ellipsis: true
      }
    ];

    const columnsAddResult = [
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.paginationAddResult.pageSize * (this.state.paginationAddResult.current - 1);
        }
      },
      {
        title: "Kontrol Liste No",
        key: "qualityProcedure",
        render: record => {
          return record.qualityProcedure;
        },
        ...this.getColumnSearchProps('qualityProcedure'),
        sorter: (a, b) => Boolean(a.qualityProcedure) && Boolean(b.qualityProcedure) && a.qualityProcedure.localeCompare(b.qualityProcedure),
      },
      {
        title: "Madde No",
        key: "itemNo",
        render: record => {
          return record.itemNo;
        },
        ...this.getColumnSearchProps('itemNo'),
        sorter: (a, b) => Boolean(a.itemNo) && Boolean(b.itemNo) && a.itemNo.localeCompare(b.itemNo),
      },
      {
        title: "Kriter",
        key: "question",
        render: record => {
          return record.question;
        },
        ...this.getColumnSearchProps('question'),
        sorter: (a, b) => Boolean(a.question) && Boolean(b.question) && a.question.localeCompare(b.question),
      },
      {
        title: "Kabul Koşulu",
        key: "",
        render: record => {
          return "";
        },
      },
      {
        title: "Referans",
        key: "reference",
        render: record => {
          return record.reference;
        },
        ...this.getColumnSearchProps('reference'),
        sorter: (a, b) => Boolean(a.reference) && Boolean(b.reference) && a.reference.localeCompare(b.reference),
      },
      {
        title: "Kontrol Değeri",
        key: "answer",
        render: record => {
          if (Boolean(record.answer) && Boolean(record.answerType) &&
            record.answerType === "Imagepicker") {
            return <Image width={80} src={record.answer}></Image>
          } else if (Boolean(record.filePath)) {
            return <AntButton
              style={{
                marginLeft: "2px",
                marginTop: "-2px",
                alignContent: "center"
              }}
              onClick={() => this.downloadFile(record.filePath)}
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
            ></AntButton>
          }
          return record.answer;
        },
      },
      {
        title: "Sonuc",
        key: "",
        render: record => {
          return "";
        },
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Inspection Log" hideElement={true}>
            <ExcelSheet name="Inspection Log" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="sn" />
              <ExcelColumn label="RFI No" value="rfiNo" />
              <ExcelColumn label="Rev" value="revNo" />
              <ExcelColumn label="EPM no" value="epmNo" />
              <ExcelColumn label="Discipline" value={col => (Boolean(col.discipline) ? col.discipline.name : "")} />

              <ExcelColumn label="Activity" value={col => (Boolean(col.itpActivity) ? col.itpActivity.activity : "")} />
              <ExcelColumn label="Works To Be Inspected" value="worksInspected" />
              <ExcelColumn label="Issue Date" value={col => (Boolean(col.issueDate) ? moment(col.issueDate).format("DD.MM.YYYY") : null)} />
              <ExcelColumn
                label="Inspection Date"
                value={col => (Boolean(col.inspectionDate) ? moment(col.inspectionDate).format("DD.MM.YYYY") : null)}
              />
              <ExcelColumn label="Insp. Time" value={col => (Boolean(col.inspectionTime) ? moment(col.inspectionTime).format("HH:mm") : null)} />
              <ExcelColumn label="QCI" value={col => (Boolean(col.qci) ? col.qci.name : "")} />
              <ExcelColumn
                label="PMT"
                value={col => (Boolean(col.clientPmtNames) && col.clientPmtNames.length > 0 ? col.clientPmtNames.toString() : "")}
              />
              <ExcelColumn label="Client QC" value={col => (Boolean(col.clientQc) ? col.clientQc.name : "")} />
              <ExcelColumn label="Location" value={col => (Boolean(col.location) ? col.location.name : "")} />
              <ExcelColumn label="ITP No" value={col => (Boolean(col.itpActivity) ? col.itpActivity.satipNo : "")} />
              <ExcelColumn label="Checklist" value={col => (Boolean(col.itpActivity) ? col.itpActivity.qualityProcedure : "")} />
              <ExcelColumn label="Ins.Level Client" value={col => (Boolean(col.clientInspectionLevel) ? col.clientInspectionLevel : "")} />
              <ExcelColumn label="Ins.Level MCC" value={col => (Boolean(col.companyInspectionLevel) ? col.companyInspectionLevel : "")} />
              <ExcelColumn
                label="Drawing No"
                value={col => {
                  let drawings = [];
                  if (Boolean(col.drawingList)) {
                    drawings = col.drawingList.map(i => i.drawingNo + "-" + i.shtNo + "-" + i.revNo);
                  }
                  return drawings.toString();
                }}
              />

              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="Result" value="result" />

              <ExcelColumn label="Remarks" value="remarks" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="secondary" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row>
            <Col xs={6}>
              {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                <Upload
                  {...propsBulk}
                  showUploadList={true}
                  fileList={this.state.bulkFileList}
                  onChange={({ fileList }) => {
                    if (fileList.length == 1) {
                      var imageUrl = "";
                      if (Boolean(fileList[0].response)) {
                        imageUrl = fileList[0].response.url;
                      }
                      this.setState({
                        bulkFileList: fileList,
                        imageUrl: imageUrl
                      });
                    }
                  }}
                  beforeUpload={fileList => {
                    const isZip = fileList.type === "application/x-zip-compressed";
                    if (this.state.bulkFileList.length > 0) {
                      error("You have been already choose a file! For change firstly remove the uploaded file.");
                      this.setState({
                        bulkFileList: [],
                        imageUrl: ""
                      });
                      return false;
                    }
                    if (!isZip) {
                      error("You can only upload zip file!");
                      this.setState({
                        bulkFileList: [],
                        imageUrl: ""
                      });
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState({
                      bulkFileList: [],
                      imageUrl: ""
                    });
                  }}
                >
                  <AntButton style={{ width: "100%" }}>
                    <UploadOutlined /> Bulk Test Reports As Zip{" "}
                  </AntButton>
                </Upload>
              )}
            </Col>
            <Col xs={4}>
              {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                <ExcelReader excelConfig={"Sync With Excel"} onCreateJson={this.syncTestReports.bind(this)}></ExcelReader>
              )}
            </Col>
            <Col xs={8}></Col>
            <Col xs={2}>{Boolean(this.state.user) && this.state.user.role === "ADMIN" && excelExport}</Col>
            <Col xs={2}>
              {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              )}
            </Col>
            <Col xs={2}>
              <AntButton
                hidden={!this.state.hiddenInputs}
                style={{ border: "0px" }}
                onClick={() => this.onClickCreateNew()}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                hidden={this.state.hiddenInputs}
                style={{ border: "0px" }}
                onClick={() => this.onClickCreateNew()}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hiddenInputs}>
            {/* {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="InspectionLog.logtype" defaultMessage="Construction Inspection / Item Receiving" />}
                name="logType"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="todo" defaultMessage="Please type select " />
                  }
                ]}
              >
                <Radio.Group id="InspectionLog.logtype">
                  <Radio value={"Construction Inspection"}>Construction Inspection</Radio>
                  <Radio value={"Item Receiving"}>Item Receiving</Radio>
                </Radio.Group>
              </Form.Item>
            } */}

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="rfiNo" defaultMessage="RFI No" />}
                name="rfi"
                hidden={true}
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="Please type RFI No" />
                  }
                ]}
              >
                <Input placeholder="RFI" />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="empno" defaultMessage="EPM No" />}
                name="epmNo"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="Please type EPM No" />
                  }
                ]}
              >
                <Input placeholder="EPM No" />
              </Form.Item>
            }
            {/* (this.state.result === "Approved" || this.state.result === "Rejected")  */}
            {Boolean(this.state.result) && (
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="status" defaultMessage="Status" />}
                name="status"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="Please type status" />
                  }
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Ins.Level Client"
                  id="InspectionLog.InsLevelClient"
                  value={this.state.status}
                  onChange={value => {
                    if (Boolean(value) && value === "Open") this.setState({ modalStatusOpen: true });
                  }}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  <Option key={"Open"} value={"Open"}>
                    Open
                  </Option>
                  <Option key={"Closed"} value={"Closed"}>
                    Closed
                  </Option>
                </Select>
              </Form.Item>
            )}

            {Boolean(this.state.result) && (
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="result" defaultMessage="Result" />}
                name="result"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="Please type status" />
                  }
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Ins.Level Client"
                  id="InspectionLog.InsLevelClient"
                  value={this.state.result}
                  onChange={value => {
                    this.setState({ result: value });
                  }}
                >
                  <Option key={""} value={""}>
                    Select
                  </Option>
                  <Option key={"Approved"} value={"Approved"}>
                    Approved
                  </Option>
                  <Option key={"Rejected"} value={"Rejected"}>
                    Rejected
                  </Option>
                </Select>
              </Form.Item>
            )}

            {Boolean(this.state.result) && (
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="todo" defaultMessage="Checklist File(s)" />}
                name="fileListChecklist"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="Please upload file" />
                  }
                ]}
              >
                <Upload
                  {...props}
                  id="path"
                  showUploadList={true}
                  multiple
                  fileList={this.state.fileListChecklist}
                  onChange={({ fileList }) => {
                    var filePaths = [];
                    const stateFileList = this.state.fileList;
                    if (fileList.length >= 1) {
                      for (let index = 0; index < fileList.length; index++) {
                        if (Boolean(fileList[index].response)) {
                          filePaths[index] = fileList[index].response.url;
                          fileList[index].url = fileList[index].response.url;

                          if (filePaths.length === fileList.length && fileList.length > stateFileList.length)
                            showSuccess("Files uploaded successfully");
                        } else if (Boolean(fileList[index].url)) {
                          filePaths[index] = fileList[index].url;
                        }
                      }
                    }

                    this.setState({
                      fileListChecklist: fileList,
                      filePathsChecklist: filePaths
                    });
                  }}
                >
                  <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Upload Checklist
                  </AntButton>
                </Upload>
              </Form.Item>
            )}

            {Boolean(this.state.result) && (
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="todo" defaultMessage="Photos" />}
                name="fileListPhoto"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="Please upload file" />
                  }
                ]}
              >
                <Upload
                  {...props}
                  id="path"
                  showUploadList={true}
                  accept=".png,.PNG,.jpeg,.JPEG,.jpg,.JPG"
                  multiple
                  fileList={this.state.fileListPhoto}
                  onChange={({ fileList }) => {
                    var filePaths = [];
                    const stateFileList = this.state.fileList;
                    if (fileList.length >= 1) {
                      for (let index = 0; index < fileList.length; index++) {
                        if (Boolean(fileList[index].response)) {
                          filePaths[index] = fileList[index].response.url;
                          fileList[index].url = fileList[index].response.url;

                          if (filePaths.length === fileList.length && fileList.length > stateFileList.length)
                            showSuccess("Files uploaded successfully");
                        } else if (Boolean(fileList[index].url)) {
                          filePaths[index] = fileList[index].url;
                        }
                      }
                    }

                    this.setState({
                      fileListPhoto: fileList,
                      filePathsPhoto: filePaths
                    });
                  }}
                >
                  <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Upload Photo
                  </AntButton>
                </Upload>
              </Form.Item>
            )}

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="todo" defaultMessage="Discipline" />}
                name="disciplineId"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                  }
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  placeholder="Dsicipline"
                  id="DefinitionsType"
                  onChange={value => {
                    this.onChangeDiscipline(value);
                  }}
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={e => {
                            this.setState({
                              codeName: e.target.value
                            });
                          }}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer"
                          }}
                          onClick={this.addItem}
                        >
                          <PlusOutlined /> Add Discipline
                        </a>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {this.state.disciplineSelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="InspectionLog.wti" defaultMessage="Works to be inspected" />}
                name="worksInspected"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="todo" defaultMessage="Please type inspected" />
                  }
                ]}
              >
                <Input placeholder="Works to be inspected" />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="il.activty" defaultMessage="Activity" />}
                name="itpActivityId"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                  }
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  placeholder="Checklist"
                  id="DefinitionsType"
                  onChange={value => {
                    this.fillDrawingFiles(value);
                  }}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {this.state.activitySelectItems.map(i => (
                    <Option key={i.id} value={i.id}>
                      {i.activity}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="qci" defaultMessage="QCI" />}
                name="qciId"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                  }
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="QCI"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer"
                          }}
                          onClick={this.addQci}
                        >
                          <PlusOutlined /> Add QCI
                        </a>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {this.state.qciSelectItems.map(i => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="issueDate" defaultMessage="Issue Date" />}
                name="issueDate"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="todo" defaultMessage="Please select date" />
                  }
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={value => {
                    this.setState({
                      issueDate: value
                    });
                  }}
                />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="inspectionDate" defaultMessage="Inspection Date" />}
                name="inspectionDate"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="todo" defaultMessage="Please select date" />
                  }
                ]}
              >
                <DatePicker style={{ width: "100%" }} disabledDate={this.disabledDate} />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="inspectionTime" defaultMessage="Inspection Time" />}
                name="inspectionTime"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="todo" defaultMessage="Please select time" />
                  }
                ]}
              >
                <TimePicker id="InspectionLog.inspectiontime" />
              </Form.Item>
            }

            {/* {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="revisionNo" defaultMessage="Revision No" />}
                                name="revisionNo"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type code" /> }]} >

                                <InputNumber min={0} placeholder="Revision No" />

                            </Form.Item>
                        } */}

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="location" defaultMessage="Location" />}
                name="locationId"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                  }
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Location"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer"
                          }}
                          onClick={this.addCodelocationId}
                        >
                          <PlusOutlined /> Add Location
                        </a>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {this.state.locationSelectItems.map(i => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="constructionRep" defaultMessage="Construction Rep." />}
                name="constructionRepId"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                  }
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Construction Representative"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer"
                          }}
                          onClick={this.addConstructionRepresentative}
                        >
                          <PlusOutlined /> Add Construction Rep.
                        </a>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {this.state.constructionRepSelectItems.map(i => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="supervisor" defaultMessage="Supervisor" />}
                name="supervisorId"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                  }
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Supervisor"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer"
                          }}
                          onClick={this.addSupervisor}
                        >
                          <PlusOutlined /> Add Supervisor
                        </a>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {this.state.supervisorSelectItems.map(i => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="clientPMT" defaultMessage="Client PMT" />}
                name="clientPmtIds"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                  }
                ]}
              >
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Client PMT"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer"
                          }}
                          onClick={this.addClientPMT}
                        >
                          <PlusOutlined /> Add Client PMT
                        </a>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {this.state.clientPMTSelectItems.map(i => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="clientQC" defaultMessage="Client QC" />}
                name="clientQcId"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="This field is required" />
                  }
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Client QC"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer"
                          }}
                          onClick={this.addClientQC}
                        >
                          <PlusOutlined /> Add Client QC
                        </a>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {this.state.clientQCSelectItems.map(i => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="InspectionLog.remarks" defaultMessage="Remarks" />}
                name="remarks"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="Please type remarks" />
                  }
                ]}
              >
                <Input placeholder="Remarks" />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="ItpActivity.drawingIds" defaultMessage="Drawing(s)" />}
                name="drawingIds"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  mode="multiple"
                  onSearch={this.refreshLinkDrawingList}
                  style={{ width: "100%" }}
                  placeholder="Drawing(s)"
                // onChange={(value) => {
                //     this.refreshLinkDrawingList(value);
                // }
                // }
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["drawingIdsSelectItems"]) &&
                    this.state["drawingIdsSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.drawingNo + "-" + i.shtNo + "-" + i.revNo}{" "}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="todo" defaultMessage="Drawing File" />}
                name="drawingFilePath"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="Please upload file" />
                  }
                ]}
              >
                <Upload
                  {...props}
                  id="path"
                  showUploadList={true}
                  multiple
                  fileList={this.state.fileList}
                  onChange={({ fileList }) => {
                    var filePaths = [];
                    const stateFileList = this.state.fileList;
                    if (fileList.length >= 1) {
                      for (let index = 0; index < fileList.length; index++) {
                        if (Boolean(fileList[index].response)) {
                          filePaths[index] = fileList[index].response.url;
                          fileList[index].url = fileList[index].response.url;

                          if (filePaths.length === fileList.length && fileList.length > stateFileList.length)
                            showSuccess("Files uploaded successfully");
                        } else if (Boolean(fileList[index].url)) {
                          filePaths[index] = fileList[index].url;
                        }
                      }
                    }

                    this.setState({
                      fileList: fileList,
                      filePaths: filePaths
                    });
                  }}
                >
                  <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Upload
                  </AntButton>
                </Upload>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="InspectionLog.TestReports" defaultMessage="Test Reports" />}
                name="testReportPath"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="Please upload file" />
                  }
                ]}
              >
                <Upload
                  {...props}
                  id="path"
                  showUploadList={true}
                  multiple
                  fileList={this.state.fileListTestReport}
                  onChange={({ fileList }) => {
                    var filePaths = [];
                    const stateFileList = this.state.fileList;
                    if (fileList.length >= 1) {
                      for (let index = 0; index < fileList.length; index++) {
                        if (Boolean(fileList[index].response)) {
                          filePaths[index] = fileList[index].response.url;
                          fileList[index].url = fileList[index].response.url;

                          if (filePaths.length === fileList.length && fileList.length > stateFileList.length)
                            showSuccess("Files uploaded successfully");
                        } else if (Boolean(fileList[index].url)) {
                          filePaths[index] = fileList[index].url;
                        }
                      }
                    }

                    this.setState({
                      fileListTestReport: fileList,
                      filePathsTestReport: filePaths
                    });
                  }}
                >
                  <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Upload
                  </AntButton>
                </Upload>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="InspectionLog.workPermitRequired" defaultMessage="Work Permit Required" />}
                name="workPermitRequired"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="todo" defaultMessage="Please type workPermitRequired" />
                  }
                ]}
              >
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked
                  onChange={this.onChangeSwitch}
                  checked={this.state.workPermitRequired}
                />
              </Form.Item>
            }

            <div hidden={this.state.hideSave}>
              {
                <Form.Item {...tailFormItemLayout}>
                  <AntButton
                    id="SaveButton"
                    htmlType="submit"
                    type="primary"
                    style={{ width: "100%" }}
                    className="btn btn-success"
                    icon={<SaveOutlined style={{ verticalAlign: "1px", marginRight: "5px" }} />}
                    loading={this.state.saveLoading}
                  >
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </AntButton>
                </Form.Item>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <AntButton id="CancelButton" type="primary" style={{ width: "100%" }} className="btn btn-default" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </AntButton>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <AntButton
                      id="UpdateButton"
                      htmlType="submit"
                      type="primary"
                      style={{ width: "100%" }}
                      className="btn btn-warning"
                      loading={this.state.saveLoading}
                    >
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </AntButton>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <div style={{ marginBottom: 16 }}>
            <Space>
              <Button variant="success" onClick={this.sendMailSelectedInspectionReports} disabled={!hasSelected} loading={loading}>
                Get Inspection Reports as Email
              </Button>

              <span style={{ marginLeft: 8 }}>{hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}</span>
              <Button variant="success" onClick={this.sendMailSelectedInspectionReportsAsMir} disabled={!hasSelected} loading={loading}>
                Send RFI Log
              </Button>
              {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                <Button variant="success" onClick={this.showOrHideReportModal} loading={loading}>
                  Get Report
                </Button>
              )}
            </Space>
          </div>
          <Table
            size="small"
            rowSelection={rowSelection}
            bordered
            scroll={{ y: 500, x: 900 }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalReportShow} onHide={this.showOrHideReportModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Inspection Log Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              initialValues={{ startDate: moment().subtract(1, "M"), endDate: moment(), remember: false }}
              onFinish={this.search}
              onFinishFailed={onFinishFailed}
              ref={this.formRef}
            >
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="InspectionTestReportLogReport.Period" defaultMessage="Period" />}
                initialValue={"day"}
                name="period"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select
                  placeholder="Period"
                  allowClear
                  onChange={value => {
                    this.setState({ period: value });
                    this.formRef.current.setFieldsValue({
                      startDate: null,
                      endDate: null,
                      selectedMonths: undefined,
                      selectedYears: undefined
                    });
                  }}
                >
                  <Option key={"day"} value={"day"}>
                    <FormattedMessage id="InspectionTestReportLogReport.periodDay" defaultMessage="Day" />
                  </Option>
                  <Option key={"month"} value={"month"}>
                    <FormattedMessage id="InspectionTestReportLogReport.periodMonth" defaultMessage="Month" />
                  </Option>
                  <Option key={"year"} value={"year"}>
                    <FormattedMessage id="InspectionTestReportLogReport.periodYear" defaultMessage="Year" />
                  </Option>
                </Select>
              </Form.Item>

              <Form.Item
                {...layout2}
                hidden={this.state.period !== "day"}
                label={<FormattedMessage id="InspectionTestReportLogReport.startdate" defaultMessage="Start Date" />}
                name="startDate"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                {...layout2}
                hidden={this.state.period !== "day"}
                label={<FormattedMessage id="InspectionTestReportLogReport.endDate" defaultMessage="End Date" />}
                name="endDate"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item {...tailFormItemLayout} hidden={this.state.period === "day"}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      {...layout2}
                      label={<FormattedMessage id="InspectionTestReportLogReport.Years" defaultMessage="Years" />}
                      name="selectedYears"
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Years"
                        mode={this.state.period === "year" ? "multiple" : ""}
                        allowClear
                        style={{ width: "100%" }}
                      >
                        {this.state["yearsSelectItems"].map(i => (
                          <Option key={i} value={i}>
                            {i}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12} hidden={this.state.period === "year"}>
                    <Form.Item
                      {...layout2}
                      label={<FormattedMessage id="InspectionTestReportLogReport.Months" defaultMessage="Months" />}
                      name="selectedMonths"
                    >
                      <Select showSearch optionFilterProp="children" placeholder="Months" mode="multiple" allowClear style={{ width: "100%" }}>
                        {this.state["monthsSelectItems"].map(i => (
                          <Option key={i.id} value={i.id}>
                            {i.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <AntButton type="dashed" htmlType="submit" style={{ width: "100%" }} icon={<SearchOutlined />} size="large">
                    <FormattedMessage id="GeneralButtonSearch" defaultMessage="Search" />
                  </AntButton>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideReportModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <AntModal
          centered
          width={1400}
          title="Checklist Result"
          visible={this.state.showChecklistModal}
          onCancel={() => {
            this.setState({
              showChecklistModal: false
            });
          }}
          previousButtonProps={{ style: { display: "none" } }}
          nextButtonProps={{ style: { display: "none" } }}
          footer={[
            <Space>
              <Button style={{ display: this.state.previousButtonProps }} variant="secondary" onClick=
                {() => {
                  const itemIndex = this.state.itemIndex;
                  if (itemIndex > 0) {
                    this.setState({
                      itemIndex: itemIndex - 1,
                      previousButtonProps: "",
                      nextButtonProps: ""
                    }, () => {
                      this.singleOpenCheckListModel(this.state.selectedChecklistRow)
                    });
                  } else {
                    this.setState({
                      previousButtonProps: "none"
                    });
                  }
                }}>
                Previous
              </Button>
              <Button style={{ display: this.state.nextButtonProps }} variant="success" onClick={this.saveSingleResultList}>
                Next
              </Button>
            </Space>
          ]}
        >
          <Spin spinning={this.state.loadingChecklistSingle}>
            <Form id="FormChecklist" initialValues={{ remember: false }} onFinish={this.handleFormSingleListSubmit} ref={this.formChecklist}>
              {this.checklistAnswerType(this.state.checklist, layout, props)}
            </Form>
          </Spin>
        </AntModal>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalStatusOpen}
          onHide={() => {
            this.setState({
              modalStatusOpen: false
            });
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Are you sure ? </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure to change status as open ? When you select yes, all your added files will be deleted ! ?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                this.setState({
                  modalStatusOpen: false,
                  fileListChecklist: [],
                  filePathsChecklist: [],
                  fileListPhoto: [],
                  filePathsPhoto: [],
                  result: "",
                  status: "Open"
                });
              }}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  modalStatusOpen: false
                });
              }}
            >
              {" "}
              No
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showRevisedListModal}
          onHide={() => {
            this.setState({
              showRevisedListModal: false
            });
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Revised Drawings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table
              bordered
              scroll={{ x: 400, scrollToFirstRowOnChange: true }}
              columns={columns.filter(p => p.key !== "actions" && p.key !== "revisedVersions")}
              dataSource={this.state.revisedFileList}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  showRevisedListModal: false
                });
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <AntModal
          title="Answer"
          visible={this.state.showResultAnswerModal}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ showResultAnswerModal: false, selectedRowKeys: [], })}
          width={1200}
          centered
          footer={[
            <Space>
              <Button variant="secondary" onClick=
                {() => {
                  this.setState({
                    showResultAnswerModal: false,
                    selectedRowKeys: [],
                  });
                }}>
                Cancel
              </Button>
              <Button variant="success" onClick={this.saveMultipleResultList}>
                Save
              </Button>
            </Space>
          ]}
        >
          <Form id="FormAddResultList" initialValues={{ remember: false }} onFinish={this.handleFormMultipleListSubmit} ref={this.formAddResultList}>
            {this.checklistAnswerType(this.state.selectedAddResultAnswer, layout, props)}
          </Form>
        </AntModal>

        <AntModal
          title="Checklist Result"
          visible={this.state.showResultModal}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ showResultModal: false })}
          width={1400}
          centered
        >
          <Table
            bordered
            rowKey={record => record.id}
            rowSelection={{
              type: "radio",
              ...rowSelectionAddResult,
            }}
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columnsAddResult}
            dataSource={this.state.tableAddResultList}
            loading={this.state.loadingAddResult}
            onChange={this.handleTableChangeAddResult}
            pagination={this.state.paginationAddResult}
          />
        </AntModal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfViewer}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <AntModal
          title=""
          centered
          visible={this.state.modalProcessTypeShow}
          onCancel={() => {
            this.setState({ modalProcessTypeShow: false })
          }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
        >
          <FormattedMessage id="InspectionLogItem.SelectEncodingMethodOfChecklist" defaultMessage="Please select encoding method of checklist items ?" />
          <br />
          <br />
          <br />
          <Row gutter={[16, 16]}>
            <Col md={6}></Col>
            <Col md={6}>
              <Spin spinning={this.state.loadingChecklistSingle}>
                <Button id="OneByOneButton" onClick={this.singleChecklistModel} style={{ width: "100%" }} variant="dark">
                  <FormattedMessage id="GeneralButtonOneByOne" defaultMessage="One by one" />
                </Button>
              </Spin>
            </Col>
            <Col md={6}>
              <Spin spinning={this.state.loadingChecklistMultiple}>
                <Button id="MultipleButton" onClick={this.multipleChecklistModel} style={{ width: "100%" }} variant="dark">
                  <FormattedMessage id="GeneralButtonMultiple" defaultMessage="Multiple" />
                </Button>
              </Spin>
            </Col>
            <Col md={6}></Col>
          </Row>
        </AntModal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InspectionLog);