import "antd/dist/antd.css";
import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { showError, error, showWarning, showInfo } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { FcFolder, FcFile } from "react-icons/fc";
import { FcTreeStructure, FcEmptyBattery, FcLibrary } from "react-icons/fc";
import { FaFileAudio, FaRegFile } from "react-icons/fa";
import {
  Form,
  Button as AntButton,
  Badge,
  Radio,
  Select,
  Space,
  InputNumber,
  Cascader,
  Checkbox,
  Transfer,
  Typography,
  Breadcrumb,
  Tree,
  Tooltip
} from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Input } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import DraggableModal from "../../../Components/DraggableModal";
import { useContextMenu } from "react-contexify";
import { OrderList } from "primereact/orderlist";

const Menu_Room_Quantity = "Menu_Room_Quantity";
const { show } = useContextMenu();

const { Option } = Select;
const { Text } = Typography;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node)) {
      var lineage = [];
      if (Boolean(node.lineage) && node.lineage.length > 0) {
        node.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.value,
        lineage: node.lineage,
        title: node.value
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}


export class ProjectsTreeCompanyLayout extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ProjectsTreeCompanyLayout",
      controllerName: "apartment",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      houseRoomList: [],
      loadingButtonSubmit: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      innerHouseTypeIds: [],
      houseTypeName: "",
      houseTypeIds: [],
      templateList: [],
      orderTemplateList: [],
      targetKeys: [],
      currentRow: 0
    };
  }

  formRef = React.createRef();
  formRefApartmentLayout = React.createRef();
  formRefExtensions = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    fillFilterOperations();
  };

  fillComboboxes = async () => {
    this.fillCode("ProjectsTreeCompanyLayout.ContainType");
    this.getRoomByHouseType();

    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/neighborhood");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      this.setState({
        neighboorhoodSelectItems: responseTree.data
      });
    }

    this.fillTree();
  };


  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true,
        houseTypeSelectItems: []
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        let houseTypeSelectItems = [];
        response.data.forEach(element => {
          element.id = element.key;
          element.key = Math.random();
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "ProjectName";
          }

          let houseType = houseTypeSelectItems.find(p => p.houseTypeId === element.id);
          if (!Boolean(houseType)) {
            houseTypeSelectItems.push({ "houseTypeName": element.title, "houseTypeId": element.id, });
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true,
          houseTypeSelectItems: houseTypeSelectItems,
        });
      } else {
        this.setState({
          refreshTree: true,
          houseTypeSelectItems: []
        });
      }
    }
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : "ProjectName",
      houseTypeId: Boolean(treenode.houseTypeId) ? treenode.houseTypeId : 0,
    };

    var response = null;
    if (Boolean(treenode.key) && obj.type !== "Unit") {
      response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitemsLayout/" + obj.id + "/" + obj.type + "/" + obj.houseTypeId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
          const data = response.data;
          data.forEach(element => {
            if (element.projectName === "Apartment") {
              element.type = "ApartmentRoot";
            }
            element.icon =
              Boolean(element.type) && element.type === "ProjectName" ? (
                <FcFile />
              ) : element.type === "Project" ? (
                <FaRegFile />
              ) : element.type === "ProjectChild" ? (
                <FaFileAudio />
              ) : element.type === "ApartmentRoot" ? (
                <FcLibrary />
              ) : element.type === "Unit" && Boolean(element.id) ? (
                < FcTreeStructure />
              ) : element.type === "Unit" ? (
                <FcEmptyBattery />
              ) : (
                <FcFolder />
              );
            element.title = element.projectName;
            element.key = Math.random();
          });

          var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
          this.setState({
            nodes: res,
            refreshTree: true
          });
        }
      }
    }
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onRightClick = (event, node) => {
    let parentNode = this.getParentNode(node);
    this.setState(
      {
        selectedNodeKey: node.key,
        selectedNode: node,
        parentNode: parentNode
      },
      this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
    );
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  displayMenu = (e, type) => {
    if (type === "Unit") {
      show(e, { id: Menu_Room_Quantity });
    }
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    switch (event.currentTarget.id) {
      case "create_room_quantity":
        this.setState({
          roomQuantityModal: true,
          treeVisibleModal: false,
          roomTypeId: selectedNode.roomTypeId,
          houseTypeId: selectedNode.houseTypeId,
          roomQuantityId: selectedNode.id,
          roomUnit: selectedNode.title,
          random: Math.random(),
        });
        break;
      default:
    }
  };

  getRoomByHouseType = async () => {
    let houseTypeIds = [];
    let houseTypeName = "";
    var responseHouseType = await handleRequest("GET", "/api/" + this.state.controllerName + "/getHouseType");
    if (responseHouseType.type === "ERROR") {
      error(responseHouseType);
    }
    if (Boolean(responseHouseType.data) && responseHouseType.data.length > 0) {
      responseHouseType.data.forEach(element => {
        if (Boolean(element.houseTypeId) && element.houseTypeId > 0 && !houseTypeIds.includes(element.houseTypeId)) {
          if (houseTypeName === "") {
            houseTypeName = element.houseTypeName;
          }
          houseTypeIds.push(element.houseTypeId);
        }
      });
    }

    if (Boolean(houseTypeIds) && houseTypeIds.length > 0) {
      let houseTypeId = houseTypeIds[0];
      this.fillApartmentLayout(houseTypeId);
      var responseDivision = await handleRequest("GET", "/api/" + this.state.controllerName + "/getRoomByHouseType/" + houseTypeId);
      if (responseDivision.type === "ERROR") {
        error(responseDivision);
      }
      this.setState({
        divisionSelectItems: Boolean(responseDivision.data) ? responseDivision.data : [],
        houseTypeSelectItems: Boolean(responseHouseType.data) ? responseHouseType.data : [],
        houseTypeIds: houseTypeIds,
        houseTypeName: houseTypeName,
        houseTypeId: houseTypeId
      });
    } else {
      showWarning("House type not found.");
      this.setState({
        divisionSelectItems: [],
        houseTypeSelectItems: [],
        houseTypeIds: [],
        houseTypeName: houseTypeName,
        houseTypeId: null,
        tableVisibleModal: true
      });
    }
  };

  fillApartmentLayout = async houseTypeId => {
    if (Boolean(houseTypeId) && houseTypeId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillApartmentLayout/" + houseTypeId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState(
          {
            tableVisibleModal: true
          },
          () => {
            let apartmentPeriferyWalls = [];
            if (Boolean(response.data) && Boolean(response.data.apartmentPeriferyWalls) && response.data.apartmentPeriferyWalls.length > 0) {
              response.data.apartmentPeriferyWalls.forEach(element => {
                if (Boolean(element.wallJunction)) {
                  element.junctionCheck = true;
                }
              });

              apartmentPeriferyWalls = response.data.apartmentPeriferyWalls;
            }
            let apartmentInnerWalls = [];
            if (Boolean(response.data) && Boolean(response.data.apartmentInnerWalls) && response.data.apartmentInnerWalls.length > 0) {
              response.data.apartmentInnerWalls.forEach(element => {
                if (Boolean(element.wallJunction)) {
                  element.junctionCheck = true;
                }
              });

              apartmentInnerWalls = response.data.apartmentInnerWalls;
            }

            if (Boolean(this.formRefApartmentLayout) && Boolean(this.formRefApartmentLayout.current)) {
              this.formRefApartmentLayout.current.setFieldsValue({
                apartmentPeriferyWalls: apartmentPeriferyWalls,
                apartmentInnerWalls: apartmentInnerWalls
              });
            }
          }
        );
      }
    } else {
      if (Boolean(this.formRefApartmentLayout) && Boolean(this.formRefApartmentLayout.current)) {
        this.formRefApartmentLayout.current.resetFields();
      }
    }
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  addCodeExtension = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          modalAddNewExtensionShow: false
        });
        this.getPeriferyWallExtension(this.state.apartmentPeriferyWallId, this.state.fieldKey);
      }
    } else {
      showError("Please type value");
    }
  };

  addCodeContainType = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          modalAddNewContainShow: false
        });

        if (this.state.innerPeriferyContain === "Inner") {
          this.getInnerWallContain(this.state.apartmentInnerWallId, this.state.fieldKey);
        } else if (this.state.innerPeriferyContain === "Perifery") {
          this.getPeriferyWallContain(this.state.apartmentPeriferyWallId, this.state.fieldKey);
        }
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillApartmentTypes = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
        //  currentApartmentTypeSelectItems: response.data,
      });
    }
  };

  saveApartmentLayoutPerifery = async values => {
    console.log("values", values);
    const newItem = {
      ...values,
      houseTypeId: this.state.houseTypeId
    };

    if (Boolean(newItem.apartmentPeriferyWalls) && newItem.apartmentPeriferyWalls.length > 0) {
      newItem.apartmentPeriferyWalls.forEach(element => {
        if (Boolean(element.neighborhood) && element.neighborhood.length === 1) {
          let neighborhood = element.neighborhood[0];
          console.log("neighborhood", neighborhood);
          if (neighborhood === -1) {
            element.neighboorhoodOutside = true;
          } else {
            element.neighboorhoodHouseTypeId = neighborhood;
          }
        } else if (Boolean(element.neighborhood) && element.neighborhood.length === 2) {
          console.log("element.neighborhood", element.neighborhood);
          let neighboorhoodHouseTypeId = element.neighborhood[0];
          let neighboorhoodRoomTypeId = element.neighborhood[1];

          element.neighboorhoodHouseTypeId = neighboorhoodHouseTypeId;
          element.neighboorhoodRoomTypeId = neighboorhoodRoomTypeId;
        }
      });
    }

    console.log("newItem", newItem);

    if (Boolean(newItem.apartmentPeriferyWalls) && newItem.apartmentPeriferyWalls.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveApartmentLayoutPerifery", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({ modalPeriferyWallsShow: false })
        this.restartNewHouseType();
      }
    } else {
      showInfo("No data entry");
    }
  };

  saveApartmentLayoutInner = async values => {
    console.log("values", values);
    const newItem = {
      ...values,
      houseTypeId: this.state.houseTypeId
    };

    if (Boolean(newItem.apartmentInnerWalls) && newItem.apartmentInnerWalls.length > 0) {
      newItem.apartmentInnerWalls.forEach(element => {
        if (Boolean(element.neighborhood) && element.neighborhood.length === 1) {
          let neighborhood = element.neighborhood[0];
          console.log("neighborhood", neighborhood);
          if (neighborhood === -1) {
            element.neighboorhoodOutside = true;
          } else {
            element.neighboorhoodHouseTypeId = neighborhood;
          }
        } else if (Boolean(element.neighborhood) && element.neighborhood.length === 2) {
          console.log("element.neighborhood", element.neighborhood);
          let neighboorhoodHouseTypeId = element.neighborhood[0];
          let neighboorhoodRoomTypeId = element.neighborhood[1];

          element.neighboorhoodHouseTypeId = neighboorhoodHouseTypeId;
          element.neighboorhoodRoomTypeId = neighboorhoodRoomTypeId;
        }
      });
    }

    console.log("newItem", newItem);

    if (Boolean(newItem.apartmentInnerWalls) && newItem.apartmentInnerWalls.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveApartmentLayoutInner", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({ modalInnerWallsShow: false })
        this.restartNewHouseType();
      }
    } else {
      showInfo("No data entry");
    }
  };

  restartNewHouseType = async () => {
    const { currentRow, houseTypeSelectItems } = this.state;

    if (Boolean(houseTypeSelectItems) && houseTypeSelectItems.length > 0 && houseTypeSelectItems.length > currentRow) {
      if (Boolean(this.formRefApartmentLayout) && Boolean(this.formRefApartmentLayout.current)) {
        this.formRefApartmentLayout.current.resetFields();
      }
      let row = houseTypeSelectItems[currentRow];

      let houseTypeId = row.houseTypeId;
      this.fillApartmentLayout(houseTypeId);
      var responseDivision = await handleRequest("GET", "/api/" + this.state.controllerName + "/getRoomByHouseType/" + houseTypeId);
      if (responseDivision.type === "ERROR") {
        error(responseDivision);
      } else {
        this.setState({
          divisionSelectItems: Boolean(responseDivision.data) ? responseDivision.data : [],
          houseTypeId: houseTypeId,
          houseTypeName: row.houseTypeName
        });
      }
    } else {
      if (Boolean(this.formRefApartmentLayout) && Boolean(this.formRefApartmentLayout.current)) {
        this.formRefApartmentLayout.current.resetFields();
      }
    }
  };

  getPeriferyWallContainSort = async (apartmentPeriferyWallId, fieldKey) => {
    var orderTemplateList = [];
    var targetKeys = [];
    if (Boolean(apartmentPeriferyWallId) && apartmentPeriferyWallId > 0) {
      const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getPeriferyWallContainSort/" + apartmentPeriferyWallId);
      if (Boolean(response.data) && Boolean(response.data.list) && response.data.list.length > 0) {
        response.data.list.forEach((element, index) => {
          element.index = index + 1;
          element.key = element.structure;
        });
        orderTemplateList = response.data.list;
        targetKeys = response.data.targetKeys;
      }
    }
    this.setState({
      modalPeriferyWallContainSort: true,
      orderTemplateList: orderTemplateList,
      targetKeys: targetKeys,
      fieldKey: fieldKey,
      apartmentPeriferyWallId: apartmentPeriferyWallId
    });
  };

  getPeriferyWallContain = async (apartmentPeriferyWallId, fieldKey) => {
    var templateList = [];
    var targetKeys = [];
    if (Boolean(apartmentPeriferyWallId) && apartmentPeriferyWallId > 0) {
      const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getPeriferyWallContain/" + apartmentPeriferyWallId);
      if (Boolean(response.data) && Boolean(response.data.list) && response.data.list.length > 0) {
        templateList = response.data.list;
        targetKeys = response.data.targetKeys;
      }
    }
    this.setState({
      modalPeriferyWallContain: true,
      templateList: templateList,
      targetKeys: targetKeys,
      fieldKey: fieldKey,
      apartmentPeriferyWallId: apartmentPeriferyWallId
    });
  };

  getInnerWallContain = async (apartmentInnerWallId, fieldKey) => {
    const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getInnerWallContain/" + apartmentInnerWallId);
    var templateList = [];
    var targetKeys = [];
    if (Boolean(response.data) && Boolean(response.data.list) && response.data.list.length > 0) {
      response.data.list.forEach(element => {
        element.key = element.structure;
      });
      templateList = response.data.list;
      targetKeys = response.data.targetKeys;
    }
    this.setState({
      modalInnerWallContain: true,
      templateList: templateList,
      targetKeys: targetKeys,
      fieldKey: fieldKey,
      apartmentInnerWallId: apartmentInnerWallId
    });
  };

  getPeriferyWallExtension = async (apartmentPeriferyWallId, fieldKey) => {
    const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getPeriferyWallExtension/" + apartmentPeriferyWallId);
    var extensions = [];
    if (Boolean(response.data) && Boolean(response.data.list) && response.data.list.length > 0) {
      response.data.list.forEach(element => {
        element.key = element.extensionId;
      });
      extensions = response.data.list;
    }
    this.setState(
      {
        modalExtensionShow: true,
        fieldKey: fieldKey,
        apartmentPeriferyWallId: apartmentPeriferyWallId
      },
      () => {
        this.formRefExtensions.current.setFieldsValue({
          extensions: extensions
        });
      }
    );
  };

  onChangeTransferPerifery = (newTargetKeys, direction, moveKeys) => {
    console.log(newTargetKeys, direction, moveKeys);
    const { templateList } = this.state;
    console.log("templateList", templateList);
    let targetKeys = [];
    newTargetKeys.forEach(key => {
      let template = templateList.find(p => p.groupNo + " " + p.structure === key);
      console.log("template", template);
      if (Boolean(template)) {
        targetKeys.push(template.groupNo + " " + template.structure);
      }
    });
    console.log("targetKeys", targetKeys);
    this.setState({
      targetKeys: targetKeys
    });
  };

  onChangeTransfer = (newTargetKeys, direction, moveKeys) => {
    console.log(newTargetKeys, direction, moveKeys);
    const { templateList } = this.state;
    console.log("templateList", templateList);
    let targetKeys = [];
    newTargetKeys.forEach(key => {
      let template = templateList.find(p => p.structure === key);
      console.log("template", template);
      if (Boolean(template)) {
        targetKeys.push(template.structure);
      }
    });
    console.log("targetKeys", targetKeys);
    this.setState({
      targetKeys: targetKeys
    });
  };

  okPeriferyWallContain = async () => {
    const { fieldKey, targetKeys, templateList } = this.state;
    let apartmentPeriferyWalls = this.formRefApartmentLayout.current.getFieldValue("apartmentPeriferyWalls");

    let apartmentPeriferyWall = { ...apartmentPeriferyWalls[fieldKey] };

    let contains = [];
    if (targetKeys && targetKeys.length > 0) {
      targetKeys.forEach(key => {
        let template = templateList.find(p => p.groupNo + " " + p.structure === key);
        if (Boolean(template)) {
          contains.push(template);
        }
      });
    }

    if (Boolean(apartmentPeriferyWall) && Boolean(apartmentPeriferyWall.contains)) {
      apartmentPeriferyWall.contains = contains;
    } else {
      apartmentPeriferyWall = {
        ...apartmentPeriferyWall,
        contains: contains
      };
    }

    apartmentPeriferyWalls[fieldKey] = apartmentPeriferyWall;

    this.formRefApartmentLayout.current.setFieldsValue({
      apartmentPeriferyWalls: apartmentPeriferyWalls
    });
    this.setState({
      modalPeriferyWallContain: false
    });
  };

  okPeriferyWallContainSort = async () => {
    const { apartmentPeriferyWallId } = this.state;
    if (Boolean(apartmentPeriferyWallId) && apartmentPeriferyWallId > 0) {
      const list = this.state.orderTemplateList;
      list.forEach((element, i) => {
        element.orderNo = i;
      });
      var response = await handleRequest("POST",
        "/api/" + this.state.controllerName + "/saveApartmentPeriferyContain/" + apartmentPeriferyWallId, list
      );
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          modalPeriferyWallContainSort: false
        });
      }
    } else {
      showWarning("Apartment Perifery Wall not found.");
    }
  };

  okInnerWallContain = async () => {
    const { fieldKey, targetKeys, templateList } = this.state;
    let apartmentInnerWalls = this.formRefApartmentLayout.current.getFieldValue("apartmentInnerWalls");

    let apartmentInnerWall = { ...apartmentInnerWalls[fieldKey] };

    let contains = [];
    if (targetKeys && targetKeys.length > 0) {
      targetKeys.forEach(key => {
        let template = templateList.find(p => p.structure === key);
        if (Boolean(template)) {
          contains.push(template);
        }
      });
    }

    if (Boolean(apartmentInnerWall) && Boolean(apartmentInnerWall.contains)) {
      apartmentInnerWall.contains = contains;
    } else {
      apartmentInnerWall = {
        ...apartmentInnerWall,
        contains: contains
      };
    }

    apartmentInnerWalls[fieldKey] = apartmentInnerWall;

    this.formRefApartmentLayout.current.setFieldsValue({
      apartmentInnerWalls: apartmentInnerWalls
    });
    this.setState({
      modalInnerWallContain: false
    });
  };

  okJuctionDirection = async () => {
    const { fieldKey, junction } = this.state;
    let apartmentPeriferyWalls = this.formRefApartmentLayout.current.getFieldValue("apartmentPeriferyWalls");
    let apartmentPeriferyWall = { ...apartmentPeriferyWalls[fieldKey] };

    apartmentPeriferyWall.junction = junction;

    this.formRefApartmentLayout.current.setFieldsValue({
      apartmentPeriferyWalls: apartmentPeriferyWalls
    });
    this.setState({
      modalJuctionDirectionShow: false
    });
  };

  addNewPeriferyButtonClick = async direction => {
    console.log("direction", direction);
    let apartmentPeriferyWalls = this.formRefApartmentLayout.current.getFieldValue("apartmentPeriferyWalls");

    if (Boolean(apartmentPeriferyWalls) && apartmentPeriferyWalls.length > 0) {
      let lastRow = apartmentPeriferyWalls[apartmentPeriferyWalls.length - 1];
      console.log("lastRow", lastRow);

      if (direction === "To_Right") {
        apartmentPeriferyWalls.push({
          direction: direction,
          width: Boolean(lastRow.width) ? lastRow.width : null,
          neighborhood: [-1],
          roomTypeId: Boolean(lastRow.roomTypeId) ? lastRow.roomTypeId : null
        });
      } else {
        apartmentPeriferyWalls.push({
          direction: direction,
          width: Boolean(lastRow.width) ? lastRow.width : null,
          neighborhood: [-1]
        });
      }

      this.formRefApartmentLayout.current.setFieldsValue({
        apartmentPeriferyWalls: apartmentPeriferyWalls
      });
      this.setState({
        modalAddNewPeriferyShow: false
      });
    } else {
      showWarning("Not found.");
    }
  };

  saveExtensions = async values => {
    console.log("values", values);
    if (Boolean(values) && Boolean(values.extensions) && values.extensions.length > 0) {
      let list = values.extensions.filter(p => p.extensionCheck === true);
      if (Boolean(list) && list.length > 0) {
        const { fieldKey } = this.state;
        let apartmentPeriferyWalls = this.formRefApartmentLayout.current.getFieldValue("apartmentPeriferyWalls");
        let apartmentPeriferyWall = { ...apartmentPeriferyWalls[fieldKey] };

        apartmentPeriferyWall.extensions = list;

        apartmentPeriferyWalls[fieldKey] = apartmentPeriferyWall;

        this.formRefApartmentLayout.current.setFieldsValue({
          apartmentPeriferyWalls: apartmentPeriferyWalls
        });
        this.setState({
          modalExtensionShow: false
        });
      } else {
        showWarning("Not found.");
      }
    } else {
      showWarning("Not found.");
    }
  };

  getApartmentLayoutPerifery = async () => {
    const { houseTypeId } = this.state;
    if (Boolean(houseTypeId) && houseTypeId > 0) {
      const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getApartmentLayoutPerifery/" + houseTypeId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        let apartmentPeriferyWalls = [];
        if (Boolean(response.data) && response.data.length > 0) {
          apartmentPeriferyWalls = response.data;
        }
        if (Boolean(this.formRefApartmentLayout) && Boolean(this.formRefApartmentLayout.current)) {
          this.formRefApartmentLayout.current.setFieldsValue({
            apartmentPeriferyWalls: apartmentPeriferyWalls,
          });
        }
      }
    }
  };

  getApartmentLayoutInner = async () => {
    const { houseTypeId } = this.state;
    if (Boolean(houseTypeId) && houseTypeId > 0) {
      const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getApartmentLayoutInner/" + houseTypeId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        let apartmentInnerWalls = [];
        if (Boolean(response.data) && response.data.length > 0) {
          apartmentInnerWalls = response.data;
        }
        if (Boolean(this.formRefApartmentLayout) && Boolean(this.formRefApartmentLayout.current)) {
          this.formRefApartmentLayout.current.setFieldsValue({
            apartmentInnerWalls: apartmentInnerWalls,
          });
        }
      }
    }
  };

  handleTemplateCopyClick = (item) => {
    console.log("item", item);
    this.setState((prevState) => {
      const maxIndex = prevState.orderTemplateList.reduce((max, template) => {
        return template.index > max ? template.index : max;
      }, -1);
      const newIndex = maxIndex + 1;
      return {
        orderTemplateList: [...prevState.orderTemplateList, { ...item, index: newIndex }],
      };
    });
  };

  render() {
    const template = (item) => {
      return (
        <Space>
          <div className="p-clearfix">
            <div style={{ fontSize: "12px", margin: "5px 5px 0 0" }}>{item.structure}</div>
          </div>
          <AntButton onClick={() => this.handleTemplateCopyClick(item)} icon={<PlusOutlined />} />
        </Space>
      );
    }

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <div className="card card-custom card-stretch">
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <Breadcrumb separator=">">
                <Breadcrumb.Item className={this.state.tableVisibleModal ? "customBreadcrumbItem" : ""}>
                  <a
                    href="#"
                    onClick={() => {
                      this.setState(
                        {
                          tableVisibleModal: true,
                          currentRow: 0
                        },
                        () => {
                          this.restartNewHouseType();
                        }
                      );
                    }}
                  >
                    <Space>
                      <SVG style={{ width: '20px', height: '20px' }} src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>
                      <FormattedMessage id="ProjectsTreeCompanyApartment.Layout" defaultMessage="Layout" />
                    </Space>
                  </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {this.state.tableVisibleModal && Boolean(this.state.houseTypeSelectItems) && this.state.houseTypeSelectItems.length > 0 && (
                    <Breadcrumb separator=">">
                      {this.state.houseTypeSelectItems.map((val, i) => {
                        return (
                          <Breadcrumb.Item className={val.houseTypeName === this.state.houseTypeName ? "customBreadcrumbItem" : ""}>
                            <a
                              href="#"
                              onClick={() => {
                                this.setState(
                                  {
                                    currentRow: i
                                  },
                                  () => {
                                    this.restartNewHouseType();
                                  }
                                );
                              }}
                            >
                              {val.houseTypeName}
                            </a>
                          </Breadcrumb.Item>
                        );
                      })}
                    </Breadcrumb>
                  )}

                  <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                      <a
                        href="#"
                        className={this.state.sectionsOfApartmentsModal ? "customBreadcrumbItem" : ""}
                        onClick={() => {
                          this.setState(
                            {
                              modalPeriferyWallsShow: true,
                              modalInnerWallsShow: false
                            },
                            () => {
                              this.getApartmentLayoutPerifery();
                            }
                          );
                        }}
                      >
                        <FormattedMessage id="ProjectsTreeCompanyLayout.PriferyWalls" defaultMessage="Perifery Walls" />
                      </a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <a
                        href="#"
                        className={this.state.sectionsOfApartmentsModal ? "customBreadcrumbItem" : ""}
                        onClick={() => {
                          this.setState(
                            {
                              modalInnerWallsShow: true,
                              modalPeriferyWallsShow: false
                            },
                            () => {
                              this.getApartmentLayoutInner();
                            }
                          );
                        }}
                      >
                        <FormattedMessage id="ProjectsTreeCompanyLayout.InnerWalls" defaultMessage="Inner Walls" />
                      </a>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </Breadcrumb.Item>
              </Breadcrumb >
            </div>
            <div className="card-toolbar"></div>
          </div>

          <br />
          <h3 className="card-label font-weight-bolder text-dark">
            <FormattedMessage id="ProjectsTreeCompanyLayout.Layout" defaultMessage="Layout" />
          </h3>
        </div>

        {
          this.state.refreshTree && (
            <Tree
              style={{ marginTop: "10px", lineHeight: "30px" }}
              className="draggable-tree"
              showLine={{ showLeafIcon: false }}
              showIcon
              checkStrictly={true}
              loadedKeys={this.state.loadedKeys}
              onLoad={this.onLoadData}
              loadData={this.loadData}
              onRightClick={({ event, node }) => this.onRightClick(event, node)}
              onExpand={this.onExpand}
              expandedKeys={this.state.expandedKeys}
              autoExpandParent={false}
              treeData={this.state.nodes}
              blockNode={true}
              checkedKeys={this.state.checkedKeys}
              halfCheckedKeys={this.state.halfCheckedKeys}
            />
          )
        }

        {/* <Menu id={Menu_Room_Quantity}>
          <Item id="create_room_quantity" onClick={this.handleItemClick}>
            <Space>
              <FcEditImage />
              <span>
                <FormattedMessage id="ProjectsTreeCompany.RoomQuantity" defaultMessage="Room Quantity" />
              </span>
            </Space>
          </Item>
        </Menu> */}

        <DraggableModal
          centered
          width={750}
          open={this.state.modalPeriferyWallContainSort}
          onCancel={() => {
            this.setState({ modalPeriferyWallContainSort: false });
          }}
          onOk={this.okPeriferyWallContainSort}
          content={
            <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%" }}>
              <OrderList
                value={this.state.orderTemplateList}
                responsive={true}
                itemTemplate={template}
                onChange={e => this.setState({ orderTemplateList: e.value })}
              ></OrderList>
            </div>
          }
        />

        <DraggableModal
          centered
          width={1000}
          closable={false}
          open={this.state.modalPeriferyWallContain}
          onCancel={() => {
            this.setState({ modalPeriferyWallContain: false });
          }}
          onOk={this.okPeriferyWallContain}
          content={
            <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%" }}>
              <Transfer
                showSearch
                listStyle={{
                  width: 500,
                  height: 500
                }}
                style={{ marginTop: "2rem", minHeight: "100%" }}
                dataSource={this.state.templateList}
                targetKeys={this.state.targetKeys}
                onChange={this.onChangeTransferPerifery}
                render={item => `${Boolean(item.groupNo) ? item.groupNo : ""} ${item.structure}`}
                oneWay={true}
                pagination
              />
            </div>
          }
        />

        <DraggableModal
          centered
          width={1000}
          closable={false}
          open={this.state.modalInnerWallContain}
          onCancel={() => {
            this.setState({ modalInnerWallContain: false });
          }}
          onOk={this.okInnerWallContain}
          content={
            <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%" }}>
              <Transfer
                showSearch
                listStyle={{
                  width: 500,
                  height: 500
                }}
                style={{ marginTop: "2rem", minHeight: "100%" }}
                dataSource={this.state.templateList}
                targetKeys={this.state.targetKeys}
                onChange={this.onChangeTransfer}
                render={item => `${item.structure}`}
                oneWay={true}
                pagination
              />
            </div>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="ProjectsTreeCompanySection.Sections" defaultMessage="Sections" />}
          centered
          open={this.state.modalJuctionYesShow}
          onCancel={() => this.setState({ modalJuctionYesShow: false })}
          onOk={() => this.setState({ modalJuctionYesShow: false })}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveHouseTypes} ref={this.formRefHouseTypes}>
              <Form.List name="junctionYes">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanySection.Sections"} defaultMessage={"Junction No"} />
                            </th>
                            <th colSpan="1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "wallJunction"]}>
                                    <Input style={{ width: "100px" }} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <a
                                    href="#"
                                    className={`navi-link py-4`}
                                    onClick={() => {
                                      this.setState({ modalJuctionDirectionShow: true });
                                    }}
                                  >
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>
                                  </a>
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"ProjectsTreeCompanySection.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="ProjectsTreeCompanySection.PeriferyWalls" defaultMessage="Perifery Walls" />}
          centered
          width={1200}
          open={this.state.modalPeriferyWallsShow}
          onCancel={() => this.setState({ modalPeriferyWallsShow: false })}
          onOk={() => {
            this.formRefApartmentLayout.current.submit();
          }}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveApartmentLayoutPerifery} ref={this.formRefApartmentLayout}>
              <h3 className="card-label font-weight-bolder text-dark">{`Perifery Walls of ${this.state.houseTypeName}`}</h3>
              <Form.List name="apartmentPeriferyWalls">
                {(fields, { add, remove }) => {
                  const handleSortClick = fieldKey => {
                    const apartmentPeriferyWalls = this.formRefApartmentLayout.current.getFieldValue("apartmentPeriferyWalls");
                    let apartmentPeriferyWall = { ...apartmentPeriferyWalls[fieldKey] };

                    let apartmentPeriferyWallId = -1;
                    if (Boolean(apartmentPeriferyWall) && Boolean(apartmentPeriferyWall.apartmentPeriferyWallId)) {
                      apartmentPeriferyWallId = apartmentPeriferyWall.apartmentPeriferyWallId;
                    }
                    this.getPeriferyWallContainSort(apartmentPeriferyWallId, fieldKey);
                  };

                  const handleContainClick = fieldKey => {
                    const apartmentPeriferyWalls = this.formRefApartmentLayout.current.getFieldValue("apartmentPeriferyWalls");
                    let apartmentPeriferyWall = { ...apartmentPeriferyWalls[fieldKey] };

                    let apartmentPeriferyWallId = -1;
                    if (Boolean(apartmentPeriferyWall) && Boolean(apartmentPeriferyWall.apartmentPeriferyWallId)) {
                      apartmentPeriferyWallId = apartmentPeriferyWall.apartmentPeriferyWallId;
                    }
                    this.getPeriferyWallContain(apartmentPeriferyWallId, fieldKey);
                  };

                  const handleExtensionClick = fieldKey => {
                    const apartmentPeriferyWalls = this.formRefApartmentLayout.current.getFieldValue("apartmentPeriferyWalls");
                    let apartmentPeriferyWall = { ...apartmentPeriferyWalls[fieldKey] };

                    let apartmentPeriferyWallId = -1;
                    if (Boolean(apartmentPeriferyWall) && Boolean(apartmentPeriferyWall.apartmentPeriferyWallId)) {
                      apartmentPeriferyWallId = apartmentPeriferyWall.apartmentPeriferyWallId;
                    }
                    this.getPeriferyWallExtension(apartmentPeriferyWallId, fieldKey);
                  };

                  const handleCopyClick = fieldKey => {
                    let newIndex = parseInt(fieldKey, 10) + 1;
                    const apartmentPeriferyWalls = this.formRefApartmentLayout.current.getFieldValue("apartmentPeriferyWalls");
                    let apartmentPeriferyWall = {
                      wall: apartmentPeriferyWalls[fieldKey].wall,
                      roomTypeId: apartmentPeriferyWalls[fieldKey].roomTypeId,
                      width: apartmentPeriferyWalls[fieldKey].width,
                      neighborhood: [-1]
                    };
                    apartmentPeriferyWalls.splice(newIndex, 0, apartmentPeriferyWall);

                    console.log("apartmentPeriferyWalls", apartmentPeriferyWalls);

                    this.formRefApartmentLayout.current.setFieldsValue({
                      apartmentPeriferyWalls: apartmentPeriferyWalls
                    });
                  };

                  const handleAddNewClick = () => {
                    const apartmentPeriferyWalls = this.formRefApartmentLayout.current.getFieldValue("apartmentPeriferyWalls");
                    if (Boolean(apartmentPeriferyWalls) && apartmentPeriferyWalls.length > 0) {
                      this.setState({
                        modalAddNewPeriferyShow: true
                      });
                    } else {
                      let newObj = [{ neighborhood: [-1] }];
                      console.log("newObj", newObj);
                      this.formRefApartmentLayout.current.setFieldsValue({
                        apartmentPeriferyWalls: newObj
                      });
                    }
                  };

                  return (
                    <div style={{ overflowX: "auto" }}>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Wall"} defaultMessage={"Wall"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Division"} defaultMessage={"Division"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Neighborhood"} defaultMessage={"Neighborhood"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Width"} defaultMessage={"Width"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Length"} defaultMessage={"Length"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Height"} defaultMessage={"Height"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Contains"} defaultMessage={"Contains"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Junction"} defaultMessage={"Junction"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Extension"} defaultMessage={"Extension"} />
                            </th>
                            <th colSpan="1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <Form.Item hidden name={[field.name, "apartmentPeriferyWallId"]}></Form.Item>
                                <Form.Item hidden name={[field.name, "contains"]}></Form.Item>
                                <Form.Item hidden name={[field.name, "extensions"]}></Form.Item>
                                <td>
                                  <Form.Item noStyle name={[field.name, "wall"]}>
                                    <Input style={{ width: "100px" }} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "roomTypeId"]}>
                                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                      {Boolean(this.state["divisionSelectItems"]) &&
                                        this.state["divisionSelectItems"].length > 0 &&
                                        this.state["divisionSelectItems"].map(i => (
                                          <Option key={i.roomTypeId} value={i.roomTypeId}>
                                            {i.roomTypeName}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "neighborhood"]}>
                                    <Cascader
                                      showSearch
                                      allowClear
                                      optionFilterProp="children"
                                      style={{ width: "100px" }}
                                      options={this.state.neighboorhoodSelectItems}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "width"]}>
                                    <InputNumber
                                      style={{ width: "100px" }}
                                      parser={value => value.replace(",", ".")}
                                      formatter={value => value.replace(".", ",")}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "length"]}>
                                    <InputNumber
                                      style={{ width: "100px" }}
                                      parser={value => value.replace(",", ".")}
                                      formatter={value => value.replace(".", ",")}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "height"]}>
                                    <InputNumber
                                      style={{ width: "100px" }}
                                      parser={value => value.replace(",", ".")}
                                      formatter={value => value.replace(".", ",")}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Space>
                                    <Tooltip title="Contains">
                                      <a
                                        href="#"
                                        className={`navi-link py-4`}
                                        onClick={() => {
                                          handleContainClick(field.name);
                                        }}
                                      >
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>
                                      </a>
                                    </Tooltip>

                                    <Tooltip title="Sort">
                                      <a
                                        href="#"
                                        className={`navi-link py-4`}
                                        onClick={() => {
                                          handleSortClick(field.name);
                                        }}
                                      >
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sort1.svg")}></SVG>
                                      </a>
                                    </Tooltip>
                                  </Space>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "junctionCheck"]} valuePropName="checked">
                                    <Checkbox
                                      onChange={e => {
                                        if (e.target.checked) {
                                          let apartmentPeriferyWalls = this.formRefApartmentLayout.current.getFieldValue("apartmentPeriferyWalls");

                                          this.setState({
                                            fieldKey: field.name,
                                            modalJuctionYesShow: true,
                                            junction: apartmentPeriferyWalls.junction
                                          });
                                        } else {
                                          let apartmentPeriferyWalls = this.formRefApartmentLayout.current.getFieldValue("apartmentPeriferyWalls");
                                          let apartmentPeriferyWall = { ...apartmentPeriferyWalls[field.name] };

                                          apartmentPeriferyWall.junction = null;

                                          this.formRefApartmentLayout.current.setFieldsValue({
                                            apartmentPeriferyWalls: apartmentPeriferyWalls
                                          });
                                        }
                                      }}
                                    >
                                      <FormattedMessage id={"ProjectsTreeCompanyLayout.Yes"} defaultMessage={"Yes"} />
                                    </Checkbox>
                                  </Form.Item>

                                  {/* <Form.Item noStyle name={[field.name, "wallJunction"]}>
                                    <Input style={{ width: "100px" }} />
                                  </Form.Item> */}
                                </td>
                                <td>
                                  <a
                                    href="#"
                                    className={`navi-link py-4`}
                                    onClick={() => {
                                      handleExtensionClick(field.name);
                                    }}
                                  >
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>
                                  </a>
                                </td>
                                <td>
                                  <Space>
                                    <PlusOutlined onClick={() => handleCopyClick(field.name)} />
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </Space>
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => handleAddNewClick()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"Apartment.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </div>
                  );
                }}
              </Form.List>
            </Form>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="ProjectsTreeCompanySection.InnerWalls" defaultMessage="Inner Walls" />}
          centered
          width={1200}
          open={this.state.modalInnerWallsShow}
          onCancel={() => this.setState({ modalInnerWallsShow: false })}
          onOk={() => {
            this.formRefApartmentLayout.current.submit();
          }}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveApartmentLayoutInner} ref={this.formRefApartmentLayout}>
              <h3 className="card-label font-weight-bolder text-dark">{`Inner Walls of ${this.state.houseTypeName}`}</h3>
              <Form.List name="apartmentInnerWalls">
                {(fields, { add, remove }) => {
                  const handleContainClick = fieldKey => {
                    const apartmentInnerWalls = this.formRefApartmentLayout.current.getFieldValue("apartmentInnerWalls");
                    let apartmentInner = { ...apartmentInnerWalls[fieldKey] };

                    let apartmentInnerWallId = -1;
                    if (Boolean(apartmentInner) && Boolean(apartmentInner.apartmentInnerWallId)) {
                      apartmentInnerWallId = apartmentInner.apartmentInnerWallId;
                    }
                    this.getInnerWallContain(apartmentInnerWallId, fieldKey);
                  };

                  const handleCopyClick = fieldKey => {
                    let apartmentPeriferyWalls = this.formRefApartmentLayout.current.getFieldValue("apartmentPeriferyWalls");

                    // let newIndex = parseInt(fieldKey, 10) + 1;
                    // const sectionHouses = this.formRefApartmentLayout.current.getFieldValue("sectionHouses");
                    // let apartmentSection = { ...sectionHouses[fieldKey] };;
                    // apartmentSection.houseTypeId = null;
                    // apartmentSection.numberOfHouse = null;

                    // sectionHouses.splice(newIndex, 0, apartmentSection);

                    // console.log("sectionHouses", sectionHouses);

                    // this.formRefApartmentLayout.current.setFieldsValue({
                    //   sectionHouses: sectionHouses
                    // });

                    add();
                  };

                  const addNewInnerButtonClick = () => {
                    let apartmentPeriferyWalls = this.formRefApartmentLayout.current.getFieldValue("apartmentPeriferyWalls");
                    let relatedJunctionSelectItems = [];
                    if (Boolean(apartmentPeriferyWalls) && apartmentPeriferyWalls.length > 0) {
                      apartmentPeriferyWalls.forEach(element => {
                        if (Boolean(element) && Boolean(element.wallJunction)) {
                          let relatedJunction = relatedJunctionSelectItems.find(p => p.key === element.wallJunction);
                          if (!Boolean(relatedJunction)) {
                            relatedJunctionSelectItems.push({ key: element.wallJunction, value: element.wallJunction });
                          }
                        }
                      });
                    }

                    let apartmentInnerWalls = this.formRefApartmentLayout.current.getFieldValue("apartmentInnerWalls");
                    if (Boolean(apartmentInnerWalls) && apartmentInnerWalls.length > 0) {
                      apartmentInnerWalls.forEach(element => {
                        if (Boolean(element) && Boolean(element.wallJunction)) {
                          let relatedJunction = relatedJunctionSelectItems.find(p => p.key === element.wallJunction);
                          if (!Boolean(relatedJunction)) {
                            relatedJunctionSelectItems.push({ key: element.wallJunction, value: element.wallJunction });
                          }
                        }
                      });
                    }

                    this.setState({
                      relatedJunctionSelectItems: relatedJunctionSelectItems
                    });
                    add();
                  };

                  return (
                    <div style={{ overflowX: "auto" }}>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Juction"} defaultMessage={"Juction"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.JointedWall"} defaultMessage={"Jointed Wall"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Neighborhood"} defaultMessage={"Neighborhood"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Neighborhood"} defaultMessage={"Neighborhood"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Width"} defaultMessage={"Width"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Length"} defaultMessage={"Length"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Height"} defaultMessage={"Height"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Contains"} defaultMessage={"Contains"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyLayout.Juction"} defaultMessage={"Juction"} />
                            </th>
                            <th colSpan="1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <Form.Item hidden name={[field.name, "apartmentInnerWallId"]}></Form.Item>
                                <Form.Item hidden name={[field.name, "contains"]}></Form.Item>
                                <Form.Item hidden name={[field.name, "extensions"]}></Form.Item>
                                <td>
                                  <Form.Item noStyle name={[field.name, "relatedJunction"]}>
                                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "100px" }}>
                                      {Boolean(this.state["relatedJunctionSelectItems"]) &&
                                        this.state["relatedJunctionSelectItems"].length > 0 &&
                                        this.state["relatedJunctionSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "jointedWall"]}>
                                    <Input style={{ width: "100px" }} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "neighboorhood1"]}>
                                    <Cascader
                                      showSearch
                                      allowClear
                                      optionFilterProp="children"
                                      style={{ width: "100px" }}
                                      options={this.state.neighboorhoodSelectItems}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "neighboorhood2"]}>
                                    <Cascader
                                      showSearch
                                      allowClear
                                      optionFilterProp="children"
                                      style={{ width: "100px" }}
                                      options={this.state.neighboorhoodSelectItems}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "width"]}>
                                    <InputNumber
                                      style={{ width: "100px" }}
                                      parser={value => value.replace(",", ".")}
                                      formatter={value => value.replace(".", ",")}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "length"]}>
                                    <InputNumber
                                      style={{ width: "100px" }}
                                      parser={value => value.replace(",", ".")}
                                      formatter={value => value.replace(".", ",")}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "height"]}>
                                    <InputNumber
                                      style={{ width: "100px" }}
                                      parser={value => value.replace(",", ".")}
                                      formatter={value => value.replace(".", ",")}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <a
                                    href="#"
                                    className={`navi-link py-4`}
                                    onClick={() => {
                                      handleContainClick(field.name);
                                    }}
                                  >
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>
                                  </a>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "junctionCheck"]} valuePropName="checked">
                                    <Checkbox>
                                      <FormattedMessage id={"ProjectsTreeCompanyLayout.Yes"} defaultMessage={"Yes"} />
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item noStyle name={[field.name, "wallJunction"]}>
                                    <Input />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Space>
                                    <PlusOutlined onClick={() => handleCopyClick(field.name)} />
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </Space>
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => addNewInnerButtonClick()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"Apartment.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </div>
                  );
                }}
              </Form.List>
            </Form>
          }
        />

        <DraggableModal
          centered
          width={400}
          closable={false}
          maskClosable={false}
          open={this.state.modalJuctionDirectionShow}
          onCancel={() => {
            this.setState({ modalJuctionDirectionShow: false });
          }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          content={
            <Radio.Group
              onChange={e => {
                this.setState(
                  {
                    junction: e.target.value
                  },
                  () => {
                    this.okJuctionDirection();
                  }
                );
              }}
              value={this.state.junction}
            >
              <Radio value={"Up"}>{"Up"}</Radio>
              <Radio value={"Down"}>{"Down"}</Radio>
              <Radio value={"Right"}>{"Right"}</Radio>
              <Radio value={"Left"}>{"Left"}</Radio>
            </Radio.Group>
          }
        />

        <DraggableModal
          centered
          width={400}
          closable={false}
          maskClosable={false}
          open={this.state.modalAddNewPeriferyShow}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ modalAddNewPeriferyShow: false });
          }}
          content={
            <>
              <Button
                onClick={() => {
                  this.addNewPeriferyButtonClick("In_Same_Direction");
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                <FormattedMessage id="ProjectsTreeCompanyLayout.InSameDirection" defaultMessage="In Same Direction" />
              </Button>
              <br /> <br />
              <Button
                onClick={() => {
                  this.addNewPeriferyButtonClick("To_Right");
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                <FormattedMessage id="ProjectsTreeCompanyLayout.ToRight" defaultMessage="To Right" />
              </Button>
              <br /> <br />
              <Button
                onClick={() => {
                  this.addNewPeriferyButtonClick("To_Left");
                }}
                style={{ width: "100%" }}
                variant="secondary"
              >
                <FormattedMessage id="ProjectsTreeCompanyLayout.ToLeft" defaultMessage="To left" />
              </Button>
            </>
          }
        />

        <DraggableModal
          centered
          width={600}
          closable={false}
          maskClosable={false}
          open={this.state.modalExtensionShow}
          onCancel={() => {
            this.setState({ modalExtensionShow: false });
          }}
          onOk={() => {
            this.formRefExtensions.current.submit();
          }}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveExtensions} ref={this.formRefExtensions}>
              <Form.List name="extensions">
                {(fields, { add, remove }) => {
                  const addNewExtensionButtonClick = () => {
                    this.setState({
                      modalAddNewExtensionShow: true,
                      codeName: null
                    });
                  };

                  return (
                    <TableBoot bordered size="sm">
                      <tbody>
                        {fields.map(field => {
                          return (
                            <tr key={field.key}>
                              <Form.Item hidden name={[field.name, "extensionId"]}></Form.Item>
                              <td>
                                <Form.Item noStyle name={[field.name, "extensionCheck"]} valuePropName="checked">
                                  <Checkbox />
                                </Form.Item>
                              </td>
                              <td>
                                <Text type="secondary">
                                  {Boolean(this.formRefExtensions) &&
                                    this.formRefExtensions.current &&
                                    this.formRefExtensions.current.getFieldValue("extensions") &&
                                    this.formRefExtensions.current.getFieldValue("extensions").length > 0 &&
                                    this.formRefExtensions.current.getFieldValue("extensions")[field.name].extensionName}
                                </Text>
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colSpan={11}>
                            <AntButton type="dashed" onClick={() => addNewExtensionButtonClick()} block icon={<PlusOutlined />}>
                              <FormattedMessage id={"Apartment.AddNew"} defaultMessage={"Add New"} />
                            </AntButton>
                          </td>
                        </tr>
                      </tbody>
                    </TableBoot>
                  );
                }}
              </Form.List>
            </Form>
          }
        />

        <DraggableModal
          centered
          width={400}
          closable={false}
          open={this.state.modalAddNewExtensionShow}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ modalAddNewExtensionShow: false });
          }}
          content={
            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
              <Space>
                <Input
                  style={{ flex: "auto" }}
                  value={this.state.codeName}
                  onChange={event => {
                    this.setState({
                      codeName: event.target.value
                    });
                  }}
                />
                <Button onClick={() => this.addCodeExtension("ProjectsTreeCompanyLayout.Extension")} variant="primary">
                  <FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" />
                </Button>
              </Space>
            </div>
          }
        />

        <DraggableModal
          centered
          width={400}
          closable={false}
          open={this.state.modalAddNewContainShow}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ modalAddNewContainShow: false });
          }}
          content={
            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
              <Space>
                <Input
                  style={{ flex: "auto" }}
                  value={this.state.codeName}
                  onChange={event => {
                    this.setState({
                      codeName: event.target.value
                    });
                  }}
                />
                <Button onClick={() => this.addCodeContainType("ProjectsTreeCompanyLayout.ContainType")} variant="primary">
                  <FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" />
                </Button>
              </Space>
            </div>
          }
        />
      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTreeCompanyLayout);