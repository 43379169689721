import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Select, Input, Button as AntButton, Modal, Space, Checkbox } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import { Table as TableBoot } from "react-bootstrap";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import SettingsIcon from "@material-ui/icons/Settings";

const { Option } = Select;

export class Workflow extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "Workflow",
      controllerName: "pageSettings",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      houseRoomList: [],
      loadingButtonSubmit: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();
  formRefPageSettingsWorkflow = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    fillFilterOperations();

    await this.fillCompanyWorkFlows();
  };

  fillComboboxes = async () => {
    var responseAction = await handleRequest("GET", "/api/" + this.state.controllerName + "/actions/" + this.props.pageName);
    if (responseAction.type === "ERROR") {
      error(responseAction);
    } else {
      this.setState({
        actionsSelectItems: Boolean(responseAction.data) ? responseAction.data : [],
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: "",
        tableVisibleModal: true,
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder,
          tableVisibleModal: true,
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: "",
          tableVisibleModal: true,
        });
      }
    }
  };

  async fillCompanyWorkFlows() {
    var responseWorkflow = await handleRequest("GET", "/api/" + this.state.controllerName + "/companyWorkflows/" + this.props.pageName);
    if (responseWorkflow.type === "ERROR") {
      error(responseWorkflow);
    } else {
      if (Boolean(responseWorkflow.data)) {
        let data = responseWorkflow.data;
        console.log("data", data);
        if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
          this.formRef.current.setFieldsValue({
            workflows: data,
          });
        }
      } else {
        if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
          this.formRef.current.resetFields();
        }
      }
    }
  }

  async fillWorkFlows() {
    var responseWorkflow = await handleRequest("GET", "/api/" + this.state.controllerName + "/workflows/" + this.props.pageName);
    if (responseWorkflow.type === "ERROR") {
      error(responseWorkflow);
    } else {
      if (Boolean(responseWorkflow.data)) {
        let data = responseWorkflow.data;
        console.log("data", data);
        if (Boolean(this.formRefPageSettingsWorkflow) && Boolean(this.formRefPageSettingsWorkflow.current)) {
          console.log("bbb");
          this.formRefPageSettingsWorkflow.current.setFieldsValue({
            workflows: data,
          });
        }
      } else {
        console.log("cccc");
        if (Boolean(this.formRefPageSettingsWorkflow) && Boolean(this.formRefPageSettingsWorkflow.current)) {
          console.log("ddd");
          this.formRefPageSettingsWorkflow.current.resetFields();
        }
      }
    }
  }

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  resetInputs = () => {

  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  save = async values => {
    console.log("values", values);
    let list = [];
    if (Boolean(values) && Boolean(values.workflows) && values.workflows.length > 0) {
      values.workflows.forEach(element => {
        element.pageName = this.props.pageName;
        if (element.value) {
          list.push(element);
        }
      });
    }
    console.log("list", list);

    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveCompanyWorkflows/" + this.props.pageName, list);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      await this.fillCompanyWorkFlows();
      showSuccess();
      this.props.nextTab();
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  onCheckTo = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    this.setState({
      checkedKeysTo: checkedKeys,
      halfCheckedKeysTo: e.halfCheckedKeys,
      checkedNodesTo: e.checkedNodes
    });
  };

  onCheckInfo = async (checkedKeys, e) => {
    this.setState({
      checkedKeysInfo: checkedKeys,
      halfCheckedKeysInfo: e.halfCheckedKeys,
      checkedNodesInfo: e.checkedNodes
    });
  };

  savePageSettingsWorkflows = async values => {
    if (Boolean(values) && Boolean(values.workflows) && values.workflows.length > 0) {
      values.workflows.forEach(element => {
        element.pageName = this.props.pageName;
      });
    }

    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveWorkflows/" + this.props.pageName, values.workflows);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({ modalPageSettingsWorkflowShow: false });
      this.cancel();
      await this.fillCompanyWorkFlows();
      showSuccess();
    }
  };

  render() {
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} ref={this.formRef}>

          <Row gutter={[16, 16]}>
            <Col md={22}></Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                onClick={() => {
                  this.setState({
                    modalPageSettingsWorkflowShow: true
                  }, async () => {
                    await this.fillWorkFlows();
                  });
                }}
                icon={<SettingsIcon color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>
          <br />
          <Form.List name="workflows">
            {(fields, { add, remove }) => {
              return (
                <>
                  <TableBoot bordered size="sm">
                    <thead>
                      <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="RoomQuantity.Step" defaultMessage="Step" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="RoomQuantity.Action" defaultMessage="Action" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="RoomQuantity.PreRequisite" defaultMessage="Pre-requisite" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map(field => {
                        return (
                          <tr key={field.key}>
                            <td>
                              <Form.Item
                                noStyle name={[field.name, "value"]}
                                valuePropName="checked"
                              >
                                <Checkbox></Checkbox>
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item
                                noStyle name={[field.name, "stepName"]}>
                                <Input readOnly />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item
                                noStyle name={[field.name, "pageActionName"]}>
                                <Input readOnly />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item
                                noStyle name={[field.name, "preRequisiteName"]}>
                                <Input readOnly />
                              </Form.Item>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </TableBoot>
                </>
              );
            }}
          </Form.List>

          <br />
          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={19} lg={19} xl={19} xxl={19}></Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Button type="submit" style={{ width: "100%" }} variant="success">
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </Button>
            </Col>
          </Row>
        </Form>

        <Modal
          title={<FormattedMessage id="Workflow.Workflow" defaultMessage="Workflow" />}
          closable="false"
          open={this.state.modalPageSettingsWorkflowShow}
          onCancel={() => {
            this.setState({ modalPageSettingsWorkflowShow: false });
          }}
          width={1200}
          centered
          okButtonProps={{ hidden: false }}
          onOk={() => {
            this.formRefPageSettingsWorkflow.current.submit();
          }}
        >
          <Form initialValues={{ remember: false }} onFinish={this.savePageSettingsWorkflows} ref={this.formRefPageSettingsWorkflow}>
            <Form.List name="workflows">
              {(fields, { add, remove }) => {
                const handleAddClick = () => {
                  add();
                };

                const handleCopyClick = (fieldKey) => {
                  let newIndex = parseInt(fieldKey, 10) + 1;
                  const workflows = this.formRefPageSettingsWorkflow.current.getFieldValue("workflows");
                  let workflow = { ...workflows[fieldKey] };;

                  workflows.splice(newIndex, 0, workflow);

                  console.log("workflows", workflows);

                  this.formRefPageSettingsWorkflow.current.setFieldsValue({
                    workflows: workflows
                  });
                };

                return (
                  <>
                    <TableBoot bordered size="sm">
                      <thead>
                        <tr>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id="RoomQuantity.Step" defaultMessage="Step" />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id="RoomQuantity.Action" defaultMessage="Action" />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                            <FormattedMessage id="RoomQuantity.PreRequisite" defaultMessage="Pre-requisite" />
                          </th>
                          <th colSpan="1" style={{ textAlign: "center" }}>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map(field => {
                          return (
                            <tr key={field.key}>
                              <td>
                                <Form.Item
                                  noStyle name={[field.name, "stepName"]}>
                                  <Input />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  noStyle name={[field.name, "pageActionId"]}>
                                  <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    style={{ width: "300px" }}
                                  >
                                    {Boolean(this.state["actionsSelectItems"]) && this.state["actionsSelectItems"].length > 0 &&
                                      this.state["actionsSelectItems"].map(i => (
                                        <Option key={i.id} value={i.id}>
                                          {i.name}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  noStyle name={[field.name, "preRequisiteId"]}>
                                  <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    style={{ width: "300px" }}
                                  >
                                    {Boolean(this.state["actionsSelectItems"]) && this.state["actionsSelectItems"].length > 0 &&
                                      this.state["actionsSelectItems"].map(i => (
                                        <Option key={i.id} value={i.id}>
                                          {i.name}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </td>
                              <td>
                                <Space>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  <PlusOutlined onClick={() => handleCopyClick(field.name)} />
                                </Space>
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colSpan={11}>
                            <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                              <FormattedMessage id={"Workflow.AddNew"} defaultMessage={"Add New"} />
                            </AntButton>
                          </td>
                        </tr>
                      </tbody>
                    </TableBoot>
                  </>
                );
              }}
            </Form.List>
          </Form>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Workflow);