import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { Button, Modal } from 'react-bootstrap';
import { FilterOutlined } from '@ant-design/icons';
import { handleRequest } from '../../ApiConnector';
import { API_BASE_ROOT } from '../../ApiConnector';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import CustomEditReviseDeleteMenu from '../CustomMenu/CustomEditReviseDeleteMenu';
import CreateIcon from '@material-ui/icons/AddCircle';
import ExportIcon from '@material-ui/icons/CloudDownloadRounded';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { Form, message, Table, Input, Select, Col, Row, DatePicker, Button as AntButton, InputNumber, Divider } from 'antd';
import { ExcelReader } from '../excel/ExcelReader';
import moment from 'moment';
import { Upload } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import PageviewIcon from '@material-ui/icons/Pageview';
import { error,showError,showSuccess } from '../../MessageHelper';

const { Option } = Select;

function JSDateToExcelDate(inDate) {
    var returnDateTime = 25569.0 + ((inDate.getTime() - (inDate.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));
    return returnDateTime.toString().substr(0, 20);
}

export class Checklist extends Component {

    constructor() {
        super();
        this.state = {

            modalDeleteShow: false,
            exportList: [],
            exportClick: false,

            hideUpdate: true,
            hideSave: false,
            hiddenInputs: true,
            filePaths: [],
            fileList: [],
            bulkFileList: [],
            showRevisedListModal: false,
            revisedFileList: [],
            modalPdfViewer: false,
            pdfFile: "",
            codeName: "",
            disciplineSelectItems: [],
            id: null,
            selectedRow: null,
            revising: false,
            index: null,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['2', '10', '25', '50', '100', '1000', '10000', '100000', '1000000'],
            },
            loading: false,
            sortField: "",
            sortOrder: "",
        };

        
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });

        this.fillDisciplines();
    };

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hiddenInputs: true,
        });
        this.resetInputs();
    };

    resetInputs = () => {

        this.formRef.current.resetFields();

        this.setState({
            filePath: '',
            filePaths: [],
            fileList: [],
            bulkFileList: [],
            id: null,
            revising: false,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['2', '10', '25', '50', '100', '1000', '10000', '100000', '1000000'],
            },
        })
    }

    

    edit = (row) => {

        let fileList = [];

        let pathList = Boolean(row.filePath) ? row.filePath.split(",") : [];
        if (Boolean(pathList) && pathList.length > 0) {

            pathList.forEach((element, index) => {
                let obj = {
                    uid: index,
                    name: element.split("download/")[1],
                    url: element,
                    thumbUrl: element,
                };

                fileList.push(obj);
            });
        }


        this.formRef.current.setFieldsValue({
            ...row,
            issueDate: moment(row.issueDate),


        });


        this.setState({
            hideUpdate: false,
            hideSave: true,
            hiddenInputs: false,

            id: row.id,
            fileList: fileList,
            filePaths: Boolean(row.filePath) ? row.filePath.split(",") : [],

        });
    };

    revise = (row) => {

        let fileList = [];

        let pathList = Boolean(row.filePath) ? row.filePath.split(",") : [];
        if (Boolean(pathList) && pathList.length > 0) {

            pathList.forEach((element, index) => {
                let obj = {
                    uid: index,
                    name: element.split("download/")[1],
                    url: element,
                    thumbUrl: element,
                };

                fileList.push(obj);
            });
        }



        this.formRef.current.setFieldsValue({
            ...row,
            revNo: row.revNo + 1,
            issueDate: moment(row.issueDate),
        });

        this.setState({
            hideUpdate: false,
            hideSave: true,
            hiddenInputs: false,

            id: row.id,
            fileList: fileList,
            filePaths: Boolean(row.filePath) ? row.filePath.split(",") : [],
            revising: true
        });
    };

    showRevisedList = async (row) => {

        let url = "/api/revisedchecklist/checklist/" + row.id;

        const response = await handleRequest("GET", url);

        if (response.type === "ERROR") {
            error(response);
        }
        else
            if (Boolean(response.data) && response.data.length > 0) {
                this.setState({
                    revisedFileList: response.data,
                    showRevisedListModal: true
                });
            } else {

                this.setState({
                    revisedFileList: [],
                    showRevisedListModal: false
                });

                this.showError("There is no revised list for selected item");
            }
    }



    openShareModal = (row) => {

        this.setState({
            shareFileId: row.id,
            showShareModal: true
        });

    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }



    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        }
        var response = await handleRequest("DELETE", "/api/checklist/" + deletedItem.id);

        if (response.type === "ERROR") {
            error(response);
        }
        else {
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.setState({
                id: null,
                modalDeleteShow: false
            });
        }
    }






    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters, });
    }

    restartTable = async (params = {}) => {

        this.setState({ loading: true });
        const newObj = this.createWhere(params);

        let url = "/api/checklist/paging";
        const response = await handleRequest("POST", url, newObj);
        if (response.type === "ERROR") {
            error(response);
            this.setState({
                loading: false,
                tableList: [],
                pagination: {
                    ...params.pagination,
                    total: 0,
                },
                sortField: "",
                sortOrder: "",
            });
        }
        else {
            if (response.data.length !== 0) {
                let list = response.data.data;
                list.forEach((element, index) => {
                    element.sn = index + 1;
                });
                this.setState({
                    loading: false,
                    tableList: list,
                    pagination: {
                        ...params.pagination,
                        total: response.data.totalRecords,
                    },
                    sortField: params.sortField,
                    sortOrder: params.sortOrder,
                });
            } else {
                this.setState({
                    loading: false,
                    tableList: [],
                    pagination: {
                        ...params.pagination,
                        total: 0,
                    },
                    sortField: "",
                    sortOrder: "",
                });
            }
        }
    };

    onClickCreateNew = async () => {
        this.resetInputs();
        if (this.state.hiddenInputs) {
            this.setState({
                hiddenInputs: false,
                hiddenSave: false,
                hiddenUpdate: true,
            });
        }
        else {
            this.setState({
                hiddenInputs: true,
                hiddenSave: true,
                hiddenUpdate: false,
            });
        }
    }

    save = async (values) => {

        const { filePaths } = this.state;

        const newItem = {
            ...values,
            id: this.state.id,
            issueDate: values.issueDate.toDate(),
            filePath: Boolean(filePaths) ? filePaths.toString() : "",

        }


        if (newItem.id === null) {
            var response = await handleRequest("POST", "/api/checklist", newItem);
        }
        else {
            if (this.state.revising === true)
                response = await handleRequest("PUT", "/api/checklist/revise/" + newItem.id, newItem);
            else
                response = await handleRequest("PUT", "/api/checklist/" + newItem.id, newItem);
        }

        if (response.type === "ERROR") {
            error(response);
        }
        else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.cancel();
        }
    };

    createWhere(params) {
        if (Boolean(params._pagination))
            params.pagination = params._pagination;
        var current = this.state.pagination.current;
        var pageSize = this.state.pagination.pageSize;
        var sortField = "", sortOrder = "";


        if (Boolean(params) && Boolean(params.pagination)) {
            current = params.pagination.current;
            pageSize = params.pagination.pageSize;


            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }

        }
        const newObj = {
            ...params.pagination,
            firstResult: current - 1, // (current - 1) * pageSize,
            maxResults: pageSize,
            sortField: sortField,
            sortOrder: sortOrder,
        };
        return newObj;
    }


    downloadFile = async (url) => {
        window.location.href = url;
    }

    downloadMultipleFile = async (url) => {

        let pathList = url.split(",");
        console.log(pathList);
        if (Boolean(pathList) && pathList.length > 1) {
            var response = await handleRequest("POST", "/api/transmittalLogs/download", pathList);

            if (response.type === "ERROR") {
                error(response);
            }
            else {

                try {
                    const link = document.createElement('a')
                    link.href = response.data;
                    link.download = "Fİles.zip";
                    link.click();

                } catch (error) {
                    console.log(error);

                }

            }

        }
        else if (Boolean(pathList) && pathList.length === 1)
            this.downloadFile(pathList[0]);

    }

    showReport = async (url) => {
        try {
            const fileRealUrl = url;

            this.setState({
                pdfFile: fileRealUrl,
                modalPdfViewer: true,
            });

        } catch (error) {
            alert("File cant found.");
        }
    }

    searchUserMail = async (email) => {
        if (Boolean(email) && email.length > 0) {
            var response = await handleRequest("GET", "/api/users/mails/" + email);
            if (response.type === "ERROR") {
                error(response);
            }
            else {
                this.setState({
                    userSelectItems: Boolean(response.data) ? response.data : []
                });
            }
        }
        else this.setState({
            userSelectItems: []
        });
    }

    addItem = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "discipline"
            }

            var response = await handleRequest("POST", "/api/codes", newItem);
            if (response.type === "ERROR") {
                error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillDisciplines();
            }
        } else {
            this.showError("Please enter discipline");
        }
    };

    fillDisciplines = async () => {
        const response = await handleRequest("GET", "/api/codes/type/discipline");
        if (Boolean(response.data)) {
            this.setState({
                disciplineSelectItems: response.data
            });
        }
    }





    actionTemplate(row) {

        return <React.Fragment>
            <CustomEditReviseDeleteMenu row={row} onDeleteRow={this.deleteModal.bind(this)} onReviseRow={this.revise.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;

    }



    handleExportExcel = async () => {

        this.setState({

            exportClick: true,
        });

    }

    ExcelDateToJSDate = (serial) => {

        if ((serial + "").includes(".")) {

            return moment(serial, "DD.MM.YYYY").toDate()
        }
        else {

            var hours = Math.floor((serial % 1) * 24);
            var minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
            return new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
        }

    }

    handleUploadExcel = async (excelJson) => {
        try {
            var list = [];
            if (excelJson === null)
                this.showError("Select an excel file");
            else {
                excelJson.forEach(element => {


                    const obj = {
                        issueDate: this.ExcelDateToJSDate(element["Date"]),
                        disciplineStr: element["Discipline"],
                        revisionNo: element["Revision no"],
                        checklistCode: element["Code"],
                        checklistDescription: element["Description"]
                    }
                    list.push(obj);
                });
                if (Boolean(list) && list.length > 0) {
                    var response = await handleRequest("POST", "/api/checklist/import", list);
                    if (response.type === "ERROR") {
                        this.showError("No records to be added");
                    }
                    else {
                        this.resetInputs();
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                        showSuccess();
                        this.cancel();
                    }
                } else {
                    this.showError("No records to be added");
                }
            }
        } catch (error) {
            this.showError("");
        }
    }


    sync = async () => {

        let response = await handleRequest("GET", "/api/checklist/sync");


        if (response.type === "ERROR") {
            error(response);
        }
        else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.cancel();
        }


    };

    render() {
        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout2 = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
        };

        const renderToolbar = (toolbarSlot) => {
            return (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexGrow: 1,
                            flexShrink: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.previousPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.nextPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomOutButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomPopover}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomInButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.downloadButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.moreActionsPopover}
                        </div>
                    </div>
                </div>
            );
        };

        const props = {
            action: `${API_BASE_ROOT}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        const layoutView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
        ) => {
            return defaultLayout(
                isSidebarOpened,
                container,
                main,
                toolbar(renderToolbar),
                sidebar,
            );
        };



        const columns = [
            {
                title: 'S/N', key: 'id', render: (value, row, index) => {
                    return index + 1 + (this.state.pagination.pageSize * (this.state.pagination.current - 1));
                },
                width: 80,
                ellipsis: true,
            },


            {
                title: 'Discipline', key: 'disciplineId', render: (record) => {
                    if (Boolean(record.discipline)) {
                        return record.discipline.name
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: "100%" }} placeholder="Discipline" id="DefinitionsType"
                            onChange={(value) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    disciplineId: value,
                                    disciplineColor: Boolean(value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                });
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }}
                        >
                            <Option key={null} value={null}>Select</Option>
                            {this.state.disciplineSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                            ))}
                        </Select>
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.disciplineColor) ? this.state.pagination.disciplineColor : "#bfbfbf" }} />,
                sorter: true,
                width: 150,
                ellipsis: true,
            },

            {
                title: 'Checklist Description', key: 'checklistDescription', render: (record) => {
                    return record.checklistDescription
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: 200 }} placeholder="Checklist Description"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    checklistDescription: e.target.value,
                                    checklistDescriptionColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.checklistDescriptionColor) ? this.state.pagination.checklistDescriptionColor : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },

            {
                title: 'Checklist Code', key: 'checklistCode', render: (record) => {
                    // return record.checklistCode

                    return Boolean(record.filePath) && record.filePath.length > 0 ? (
                        <React.Fragment>
                            <div style={{
                                cursor: "pointer",
                                color: "blue",
                                textDecoration: "underline",
                            }}
                                onClick={(e) => this.downloadMultipleFile(record.filePath)}>
                                {record.checklistCode}
                            </div>
                        </React.Fragment>
                    ) : record.checklistCode;
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: 200 }} placeholder="Checklist Code"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    checklistCode: e.target.value,
                                    checklistCodeColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({
                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.checklistCodeColor) ? this.state.pagination.checklistCodeColor : "#bfbfbf" }} />,
                sorter: true,
                width: 200,
                ellipsis: true,
            },

            {
                title: 'Revision No', key: 'revNo', render: (record) => {
                    return record.revisionNo
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <Input
                            showSearch
                            optionFilterProp="children"
                            style={{ width: 150 }} placeholder="Rev. No"
                            onChange={(e) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    revisionNo: e.target.value,
                                    revisionNoColor: Boolean(e.target.value) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })
                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }
                            }
                        >

                        </Input>

                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.revisionNoColor) ? this.state.pagination.revisionNoColor : "#bfbfbf" }} />,
                sorter: true,
                width: 120,
                ellipsis: true,
            },

            {
                title: 'Issue Date', key: 'issueDate', render: (record) => {
                    if (Boolean(record.issueDate)) {
                        return moment(record.issueDate).format("YYYY-MM-DD");
                    }
                },
                filterDropdown: () => (
                    <div style={{ padding: 8 }}>
                        <DatePicker style={{ flex: 'auto' }} value={Boolean(this.state.pagination.issueDate) ? this.state.pagination.issueDate : ""} placeholder="Date"
                            onChange={async (date) => {
                                const { pagination } = this.state;
                                let _pagination = {
                                    ...pagination,
                                    issueDate: date,
                                    issueDateColor: Boolean(date) ? '#1890ff' : "#bfbfbf",
                                };
                                this.setState({

                                    pagination: _pagination
                                })

                                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                            }}
                        />
                    </div>),
                filterIcon: filtered => <FilterOutlined style={{ color: Boolean(this.state.pagination.issueDateColor) ? this.state.pagination.issueDateColor : "#bfbfbf" }} />,
                sorter: true,
                width: 150,
                ellipsis: true,

            },

            // {
            //     title: 'File', key: 'filePath', render: (record) => {

            //         if (Boolean(record.filePath))
            //             return <AntButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadMultipleFile(record.filePath)} type="primary" shape="round" icon={<DownloadOutlined />} >

            //             </AntButton>

            //     },
            //     width: 120,
            //     ellipsis: true,

            // },

            {
                title: 'Revised Versions', key: 'revisedVersions', render: (record) => {
                    return <PageviewIcon fontSize="default" color={"primary"} backgroundColor="black" onClick={() => this.showRevisedList(record)} />
                },
                width: 100,
                ellipsis: true,
            },

            {
                title: 'Actions', key: 'actions', render: (record) => {
                    return this.actionTemplate(record);
                },
                width: 100,
                ellipsis: true,
            },


        ];

        const excelExport = <>
            {/* {this.state.exportClick ?
                this.setState({
                    exportClick: false
                }) : ""}
            {this.state.exportClick ?
                <ExcelFile filename="Checklist" hideElement={true}>
                    <ExcelSheet name="Checklist" data={this.state.tableList}>
                        <ExcelColumn label="S/N" value="sn" />
                        <ExcelColumn label="Discipline" value={(col) => Boolean(col.discipline) ? col.discipline.name : ""} />
                        <ExcelColumn label="Checklist Description" value="checklistDescription" />
                        <ExcelColumn label="Checklist Code" value="checklistCode" />
                        <ExcelColumn label="Revision no" value="revisionNo" />
                        <ExcelColumn label="Date" value={(col) => Boolean(col.issueDate) ? moment(col.issueDate).format("DD.MM.YYYY") : null} />
                    </ExcelSheet>
                </ExcelFile> :
                <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="secondary" fontSize="large" />}></AntButton>
            } */}
        </>

        return (
            <div hidden={this.state.hideInputs} className="card card-custom" style={{ padding: '2rem', minHeight: '100%' }}>

                <Form
                    initialValues={{ remember: false }}
                    onFinish={this.save}
                    onFinishFailed={onFinishFailed}
                    ref={this.formRef}>

                    <Row>
                        <Col xs={18}>
                        </Col>
                        <Col xs={2}>
                            {excelExport}
                        </Col>
                        <Col xs={2}>
                            <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
                        </Col>
                        <Col xs={2}>
                            <AntButton hidden={!this.state.hiddenInputs} style={{ border: "0px" }} onClick={() => this.onClickCreateNew()} icon={<CreateIcon color="secondary" fontSize="large" />}></AntButton>
                            <AntButton hidden={this.state.hiddenInputs} style={{ border: "0px" }} onClick={() => this.onClickCreateNew()} icon={<RemoveCircle color="error" fontSize="large" />}></AntButton>
                        </Col>
                    </Row>

                    <div hidden={this.state.hiddenInputs}>
                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="Discipline" />}
                                name="disciplineId"
                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="This field is required" /> }]} >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }} placeholder="Dsicipline" id="DefinitionsType"
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(e) => {

                                                    this.setState({
                                                        codeName: e.target.value
                                                    });

                                                }} />
                                                <a
                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={this.addItem}
                                                >
                                                    <PlusOutlined /> Add Discipline
                                                </a>
                                            </div>
                                        </div>
                                    )}

                                >
                                    <Option key={null} value={null}>Select</Option>
                                    {this.state.disciplineSelectItems.map(i => (
                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="checklistDescription" defaultMessage="Checklist Description" />}
                                name="checklistDescription"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type description" /> }]} >

                                <Input placeholder="Checklist Description" />

                            </Form.Item>
                        }
                        {<Form.Item
                            {...layout2}
                            label={<FormattedMessage id="checklistCode" defaultMessage="Checklist Code" />}
                            name="checklistCode"
                            rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type description" /> }]} >

                            <Input placeholder="Checklist Code" />

                        </Form.Item>
                        }
                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="revisionNo" defaultMessage="Revision No" />}
                                name="revisionNo"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please type code" /> }]} >

                                <InputNumber min={0} placeholder="Revision No" />

                            </Form.Item>
                        }

                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="issueDate" defaultMessage="Issue Date" />}
                                name="issueDate"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please select date" /> }]} >
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                        }




                        {
                            <Form.Item
                                {...layout2}
                                label={<FormattedMessage id="todo" defaultMessage="File" />}
                                name="filePath"
                                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please upload file" /> }]}
                            >
                                <Upload {...props} id="path"
                                    showUploadList={true}
                                    multiple
                                    fileList={this.state.fileList}
                                    onChange={({ fileList }) => {
                                        var filePaths = [];
                                        const stateFileList = this.state.fileList;
                                        if (fileList.length >= 1) {
                                            for (let index = 0; index < fileList.length; index++) {

                                                if (Boolean(fileList[index].response)) {
                                                    filePaths[index] = fileList[index].response.url;
                                                    fileList[index].url = fileList[index].response.url;

                                                    if (filePaths.length === fileList.length && fileList.length > stateFileList.length)
                                                        showSuccess("Files uploaded successfully");

                                                } else
                                                    if (Boolean(fileList[index].url)) {
                                                        filePaths[index] = fileList[index].url;

                                                    }
                                            }
                                        }
                                        this.setState({
                                            fileList: fileList,
                                            filePaths: filePaths,
                                        });
                                    }}
                                    beforeUpload={(fileList) => {

                                        return true;
                                    }}
                                    onRemove={({ fileList }) => {


                                    }
                                    }>
                                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                        <UploadOutlined /> Upload
                                    </AntButton>
                                </Upload>
                            </Form.Item>
                        }
                        

                        <div hidden={this.state.hideSave}>
                            {
                                <Row gutter={8}>
                                    <Col span={8}></Col>

                                    <Col span={8}>
                                        {
                                            <Button id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                        }
                                    </Col>
                                </Row>

                            }
                        </div>

                        <div hidden={this.state.hideUpdate}>
                            {
                                <Row gutter={8}>
                                    <Col span={8}></Col>

                                    <Col span={4}>
                                        <Button id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                                    </Col>
                                    <Col span={4}>
                                        <Button id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                                    </Col>

                                </Row>
                            }
                        </div>
                    </div>
                </Form>

                <div style={{ marginTop: '2rem', marginBottom: '1rem' }} className="content-section implementation">
                    <Table
                        size="small"
                        bordered
                        scroll={{ y: 500, x: 900 }}
                        columns={columns}
                        dataSource={this.state.tableList}
                        loading={this.state.loading} onChange={this.handleTableChange}
                        pagination={this.state.pagination}
                    />
                </div>

                <Modal
                    show={this.state.modalDeleteShow}
                    onHide={this.showOrHideDeleteModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</Button>
                        <Button variant="danger" onClick={this.delete} >Delete</Button>
                    </Modal.Footer>
                </Modal>



                <Modal
                    show={this.state.showRevisedListModal}
                    onHide={() => {
                        this.setState({
                            showRevisedListModal: false
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Revised Drawings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table
                            bordered
                            scroll={{ x: 400, scrollToFirstRowOnChange: true }}
                            columns={columns.filter(p => p.key !== "actions" && p.key !== "revisedVersions")}
                            dataSource={this.state.revisedFileList} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({
                                showRevisedListModal: false
                            })
                        }}>Close</Button>

                    </Modal.Footer>
                </Modal>


                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            PDF Report Viewer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                            <div style={{ height: "900px" }}>
                                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                            </div>
                        </Worker>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalPdfViewer}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    console.log("YYY", state);

    return {
        // auth: state.auth,
        logout: state.logout,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Checklist)
// export default connect(null, auth.actions)(Domain);
