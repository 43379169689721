import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { Form, Table, Input, Select, InputNumber, Checkbox, Divider, Tooltip, DatePicker, Button as AntButton, Col, Row, Modal as AntModal } from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import ActionMenu from "../CustomMenu/ActionMenu";
import CreateIcon from "@material-ui/icons/AddCircle";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import IncomeLog from "./IncomeLog";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";

const { RangePicker } = DatePicker;
const { Option } = Select;

export class IncomePlan extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      showScheduleButtons: true,
      showReceiveModal: false,

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      incomeGroupIdSelectItems: null,
      reasonForIncomeIdSelectItems: null,
      incomeTypeSelectItems: null,
      paymentModeSelectItems: null,
      scheduleTypeSelectItems: null,
      scheduleAmountTypeSelectItems: null,
      deptorSelectItems: null,
      periodSelectItems: null,
      bankAccountsSelectItems: [],
      cashLogSelectItems: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      excelData: [],
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    fillFilterOperations();
  };

  fillComboboxes = async () => {
    var responseincomeGroupId = await handleRequest("GET", "/api/codes/type/incomeGroupId");
    if (responseincomeGroupId.type === "ERROR") {
      error(responseincomeGroupId);
    } else {
      this.setState({
        incomeGroupIdSelectItems: Boolean(responseincomeGroupId.data) ? responseincomeGroupId.data : []
      });
    }
    var responsereasonForIncomeId = await handleRequest("GET", "/api/codes/type/reasonForIncomeId");
    if (responsereasonForIncomeId.type === "ERROR") {
      error(responsereasonForIncomeId);
    } else {
      this.setState({
        reasonForIncomeIdSelectItems: Boolean(responsereasonForIncomeId.data) ? responsereasonForIncomeId.data : []
      });
    }
    var responseincomeType = await handleRequest("GET", "/api/incomePlan/incomeTypes");
    if (responseincomeType.type === "ERROR") {
      error(responseincomeType);
    } else {
      this.setState({
        incomeTypeSelectItems: Boolean(responseincomeType.data) ? responseincomeType.data : []
      });
    }
    var responsepaymentMode = await handleRequest("GET", "/api/incomePlan/paymentModes");
    if (responsepaymentMode.type === "ERROR") {
      error(responsepaymentMode);
    } else {
      this.setState({
        paymentModeSelectItems: Boolean(responsepaymentMode.data) ? responsepaymentMode.data : []
      });
    }
    var responsescheduleType = await handleRequest("GET", "/api/incomePlan/scheduleTypes");
    if (responsescheduleType.type === "ERROR") {
      error(responsescheduleType);
    } else {
      this.setState({
        scheduleTypeSelectItems: Boolean(responsescheduleType.data) ? responsescheduleType.data : []
      });
    }
    var responsescheduleAmountType = await handleRequest("GET", "/api/incomePlan/scheduleAmountTypes");
    if (responsescheduleAmountType.type === "ERROR") {
      error(responsescheduleAmountType);
    } else {
      this.setState({
        scheduleAmountTypeSelectItems: Boolean(responsescheduleAmountType.data) ? responsescheduleAmountType.data : []
      });
    }

    var responsePeriod = await handleRequest("GET", "/api/incomePlan/periodTypes");
    if (responsePeriod.type === "ERROR") {
      error(responsePeriod);
    } else {
      this.setState({
        periodSelectItems: Boolean(responsePeriod.data) ? responsePeriod.data : []
      });
    }

    var responseDeptor = await handleRequest("GET", "/api/incomePlan/deptors");
    if (responseDeptor.type === "ERROR") {
      error(responseDeptor);
    } else {
      this.setState({
        deptorSelectItems: Boolean(responseDeptor.data) ? responseDeptor.data : []
      });
    }

    var responseBankAccounts = await handleRequest("GET", "/api/incomePlan/bankAccounts");
    if (responseBankAccounts.type === "ERROR") {
      error(responseBankAccounts);
    } else {
      this.setState({
        bankAccountsSelectItems: Boolean(responseBankAccounts.data) ? responseBankAccounts.data : []
      });
    }

    var responseCashLog = await handleRequest("GET", "/api/incomePlan/cashLogs");
    if (responseCashLog.type === "ERROR") {
      error(responseCashLog);
    } else {
      this.setState({
        cashLogSelectItems: Boolean(responseCashLog.data) ? responseCashLog.data : []
      });
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let scheduleList = [];
    if (Boolean(row.scheduleList)) {
      scheduleList = row.scheduleList.map((item, index) => {
        return {
          ...item,
          key: index,
          id: null,
          date: item.date ? moment(item.date) : null
        };
      });
    }

    console.log("scheduleList", scheduleList);

    this.formRef.current.setFieldsValue({
      ...row,
      receivingDate: Boolean(row.receivingDate) ? moment(row.receivingDate) : null,
      firstDateForPeriod: Boolean(row.firstDateForPeriod) ? moment(row.firstDateForPeriod) : null,
      scheduleList: scheduleList
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      incomeType: row.incomeType,
      scheduleType: row.scheduleType,
      scheduleAmountType: row.scheduleAmountType,
      amount: row.amount,
      fixedAmount: row.fixedAmount,
      period: row.period,
      firstDateForPeriod: Boolean(row.firstDateForPeriod) ? moment(row.firstDateForPeriod) : null,
      paymentMode: row.paymentMode
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/incomePlan/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/incomePlan/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = { ...values, id: this.state.id };

    if (this.state.incomeType === "MULTIPLE") {
      if (Boolean(newItem.scheduleList) && newItem.scheduleList.length > 0) {
        let totalAmount = 0;
        newItem.scheduleList.forEach(item => {
          totalAmount += item.amount;
        });

        if (totalAmount !== newItem.amount) {
          showError("The total amount  must be equal to the amount in the schedule list");
          return;
        }
      } else {
        showError("Please add schedule list");
        return;
      }
    } else newItem.scheduleList = [{ amount: newItem.amount, date: newItem.receivingDate }];

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/incomePlan", newItem);
    } else {
      response = await handleRequest("PUT", "/api/incomePlan/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/incomePlan/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  receiveModal = row => {
    this.setState({
      showReceiveModal: true,
      selectedIncomePlan: row
    });
  };

  action = record => {
    return (
      <React.Fragment>
        <ActionMenu
          row={record}
          onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.edit.bind(this)}
          onReceive={this.receiveModal.bind(this)}
        />
      </React.Fragment>
    );
  };

  createElementWithPeriodAndAmount = (period, firstDateForPeriod, index, fixedAmount) => {
    let element = { key: index, date: null, amount: fixedAmount };

    if (Boolean(period) && Boolean(firstDateForPeriod)) {
      let firstDate = firstDateForPeriod.toDate();
      switch (period) {
        case "DAILY":
          element.date = moment(firstDate).add(index, "days");

          break;
        case "WEEKLY":
          element.date = moment(firstDate).add(index, "weeks");
          break;
        case "MONTHLY":
          element.date = moment(firstDate).add(index, "months");
          break;
        case "YEARLY":
          element.date = moment(firstDate).add(index, "years");
          break;

        default:
          break;
      }
    }

    return element;
  };

  calculateScheduleList = async () => {
    const { scheduleType, scheduleAmountType, amount, fixedAmount, period, firstDateForPeriod } = this.state;

    if (!Boolean(scheduleType)) {
      showWarning("Please select schedule type");
      return;
    }

    if (!Boolean(scheduleAmountType)) {
      showWarning("Please select schedule amount type");
      return;
    }

    if (scheduleAmountType === "FIXED") {
      if (!Boolean(amount)) {
        showWarning("Please enter amount");
        return;
      }

      if (!Boolean(fixedAmount)) {
        showWarning("Please enter fixed amount");
        return;
      }
    }

    if (scheduleAmountType === "VARIABLE") {
      if (!Boolean(amount)) {
        showWarning("Please enter amount");
        return;
      }
    }

    if (scheduleType === "PERIODIC") {
      if (!Boolean(period)) {
        showWarning("Please select period");
        return;
      }

      if (!Boolean(firstDateForPeriod)) {
        showWarning("Please select first date for period");
        return;
      }
    }

    switch (scheduleType) {
      case "VARIABLEDATES":
        if (Boolean(scheduleAmountType) && scheduleAmountType === "FIXED") {
          if (Boolean(amount) && Boolean(fixedAmount)) {
            let k = amount % fixedAmount;
            let schduleListCount = Math.ceil(amount / fixedAmount);
            let schduleList = [];
            if (k > 0) schduleListCount = schduleListCount - 1;
            for (let index = 0; index < schduleListCount; index++) {
              const element = { key: index, date: null, amount: fixedAmount };
              schduleList.push(element);
            }

            if (k > 0) {
              const element = { key: schduleListCount, date: null, amount: k };
              schduleList.push(element);
            }

            this.formRef.current.setFieldsValue({
              scheduleList: schduleList
            });
          }
          this.setState({
            showScheduleButtons: false
          });
        }

        if (Boolean(scheduleAmountType) && scheduleAmountType === "VARIABLE") {
          this.setState({
            showScheduleButtons: true
          });
        }

        break;

      case "PERIODIC":
        if (Boolean(scheduleAmountType) && scheduleAmountType === "FIXED") {
          if (Boolean(amount) && Boolean(fixedAmount)) {
            let k = amount % fixedAmount;
            let schduleListCount = Math.ceil(amount / fixedAmount);
            let schduleList = [];
            if (k > 0) schduleListCount = schduleListCount - 1;
            for (let index = 0; index < schduleListCount; index++) {
              const element = this.createElementWithPeriodAndAmount(period, firstDateForPeriod, index, fixedAmount);
              schduleList.push(element);
            }

            if (k > 0) {
              const element = this.createElementWithPeriodAndAmount(period, firstDateForPeriod, schduleListCount, k);
              schduleList.push(element);
            }

            this.formRef.current.setFieldsValue({
              scheduleList: schduleList
            });
          }
          this.setState({
            showScheduleButtons: false
          });
        }

        if (Boolean(scheduleAmountType) && scheduleAmountType === "VARIABLE") {
          this.setState({
            showScheduleButtons: true
          });
        }

        break;

      default:
        break;
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  addNewSchedule = async () => {
    const { scheduleType, scheduleAmountType, amount, fixedAmount, period, firstDateForPeriod } = this.state;

    if (!Boolean(scheduleType)) {
      showWarning("Please select schedule type");
      return;
    }

    if (!Boolean(scheduleAmountType)) {
      showWarning("Please select schedule amount type");
      return;
    }

    if (scheduleAmountType === "FIXED") {
      if (!Boolean(amount)) {
        showWarning("Please enter amount");
        return;
      }

      if (!Boolean(fixedAmount)) {
        showWarning("Please enter fixed amount");
        return;
      }
    }

    if (scheduleAmountType === "VARIABLE") {
      if (!Boolean(amount)) {
        showWarning("Please enter amount");
        return;
      }
    }

    if (scheduleType === "PERIODIC") {
      if (!Boolean(period)) {
        showWarning("Please select period");
        return;
      }

      if (!Boolean(firstDateForPeriod)) {
        showWarning("Please select first date for period");
        return;
      }
    }
    let schduleList = Boolean(this.formRef.current.getFieldValue("scheduleList")) ? this.formRef.current.getFieldValue("scheduleList") : [];

    if (scheduleType === "VARIABLEDATES") {
      const element = { key: schduleList.length, date: null, amount: null };
      schduleList.push(element);

      this.formRef.current.setFieldsValue({
        scheduleList: schduleList
      });
    }

    if (scheduleType === "PERIODIC") {
      const element = this.createElementWithPeriodAndAmount(
        period,
        firstDateForPeriod,
        Boolean(schduleList) && schduleList.length > 0 ? schduleList.length : 0,
        null
      );
      schduleList.push(element);

      this.formRef.current.setFieldsValue({
        scheduleList: schduleList
      });
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action
      },

      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },

      {
        title: <FormattedMessage id="IncomePlan.incomeGroupId" defaultMessage="Income Group" />,
        key: "incomeGroupId",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.incomeGroupName}>{record.incomeGroupName}</Tooltip>;
        },
        ...getColumnFilter({
          inputName: "incomeGroupId",
          inputType: "SELECT_ADDABLE",
          selectItems: Boolean(this.state["incomeGroupIdSelectItems"]) ? this.state["incomeGroupIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="IncomePlan.incomeDescription" defaultMessage="Income Description" />,
        key: "incomeDescription",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={ record.incomeDescription}>{ record.incomeDescription}</Tooltip>;
        },
        ...getColumnFilter({
          inputName: "incomeDescription",
          inputType: "TEXT",
          selectItems: Boolean(this.state["incomeDescriptionSelectItems"]) ? this.state["incomeDescriptionSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },
      {
        title: <FormattedMessage id="IncomePlan.reasonForIncomeId" defaultMessage="Reason For Income" />,
        key: "reasonForIncomeId",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.reasonForIncomeName}>{record.reasonForIncomeName}</Tooltip>;
        },
        ...getColumnFilter({
          inputName: "reasonForIncomeId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["reasonForIncomeIdSelectItems"]) ? this.state["reasonForIncomeIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }),

        sorter: true
      },
      {
        title: <FormattedMessage id="IncomePlan.deptorId" defaultMessage="Deptor" />,
        key: "deptorId",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.deptorName}>{record.deptorName}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.deptorId) ? this.state.pagination.deptorId : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  deptorId: e.target.value,
                  deptorIdColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.deptorIdColor) ? this.state.pagination.deptorIdColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="IncomePlan.incomeType" defaultMessage="Income Type" />,
        key: "incomeType",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.incomeType}>{record.incomeType}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.incomeType) ? this.state.pagination.incomeType : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  incomeType: e.target.value,
                  incomeTypeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.incomeTypeColor) ? this.state.pagination.incomeTypeColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="IncomePlan.receivingDate" defaultMessage="Receiving Date" />,
        key: "receivingDate",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.receivingDate}>{moment(record.receivingDate).format("DD/MM/YYYY")}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.receivingDate) ? this.state.pagination.receivingDate : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  receivingDate: e.target.value,
                  receivingDateColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.receivingDateColor) ? this.state.pagination.receivingDateColor : "#bfbfbf" }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="IncomePlan.amount" defaultMessage="Total Amount" />,
        key: "amount",
        ellipsis: true,
        width: 150,
        render: record => {
          if (Boolean(record.amount)) {
            let amount = record.amount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ');
            if (record.currency) {
              return amount + " " + record.currency;
            }
            return amount;
          }
          return <Tooltip title={record.amount}>{record.amount}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.amount) ? this.state.pagination.amount : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  amount: e.target.value,
                  amountColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.amountColor) ? this.state.pagination.amountColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="IncomePlan.includeVat" defaultMessage="IncludeVat" />,
        key: "includeVat",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.includeVat}>{record.includeVat}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.includeVat) ? this.state.pagination.includeVat : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  includeVat: e.target.value,
                  includeVatColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.includeVatColor) ? this.state.pagination.includeVatColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="IncomePlan.paymentMode" defaultMessage="Mode Of Income" />,
        key: "paymentMode",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.paymentMode}>{record.paymentMode}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.paymentMode) ? this.state.pagination.paymentMode : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  paymentMode: e.target.value,
                  paymentModeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.paymentModeColor) ? this.state.pagination.paymentModeColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="IncomePlan.scheduleType" defaultMessage="Schedule Type" />,
        key: "scheduleType",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.scheduleType}>{record.scheduleType}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.scheduleType) ? this.state.pagination.scheduleType : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  scheduleType: e.target.value,
                  scheduleTypeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.scheduleTypeColor) ? this.state.pagination.scheduleTypeColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="IncomePlan.scheduleAmountType" defaultMessage="Schedule Amount Type" />,
        key: "scheduleAmountType",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.scheduleAmountType}>{record.scheduleAmountType}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.scheduleAmountType) ? this.state.pagination.scheduleAmountType : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  scheduleAmountType: e.target.value,
                  scheduleAmountTypeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.scheduleAmountTypeColor) ? this.state.pagination.scheduleAmountTypeColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="IncomePlan.period" defaultMessage="Period" />,
        key: "period",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.period}>{record.period}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.period) ? this.state.pagination.period : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  period: e.target.value,
                  periodColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.periodColor) ? this.state.pagination.periodColor : "#bfbfbf" }} />
        ),

        sorter: true
      },
      {
        title: <FormattedMessage id="IncomePlan.firstDateForPeriod" defaultMessage="First Date For Period" />,
        key: "firstDateForPeriod",
        ellipsis: true,
        width: 150,
        render: record => {
          if (Boolean(record.firstDateForPeriod)) return <Tooltip title={record.firstDateForPeriod}>{moment(record.firstDateForPeriod).format("DD/MM/YYYY")}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.firstDateForPeriod) ? this.state.pagination.firstDateForPeriod : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  firstDateForPeriod: e.target.value,
                  firstDateForPeriodColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.firstDateForPeriodColor) ? this.state.pagination.firstDateForPeriodColor : "#bfbfbf" }}
          />
        ),

        sorter: true
      },

      {
        title: "Last Update",
        key: "lastUpdateDate",
        ellipsis: true,
        width: 150,
        render: record => {
          return <Tooltip title={record.lastUpdated}>{moment(record.lastUpdated).format("DD/MM/YYYY")}</Tooltip>;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
              exportClick: false
            })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 8,
          offset: 0
        },
        sm: {
          span: 8,
          offset: 8
        }
      }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}></Col>
            <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="IncomePlan.incomeGroupId" defaultMessage="Income Group" />}
                name="incomeGroupId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Income Group"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <a
                          style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                          onClick={() => this.addCode("incomeGroupId")}
                        >
                          <PlusOutlined /> Add item
                        </a>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["incomeGroupIdSelectItems"]) &&
                    this.state["incomeGroupIdSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="IncomePlan.incomeDescription" defaultMessage="Income Description" />}
                name="incomeDescription"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input placeholder="Income Description" style={{ width: "100%" }} />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="IncomePlan.reasonForIncomeId" defaultMessage="Reason For IncomeId" />}
                name="reasonForIncomeId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Reason For IncomeId"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <a
                          style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                          onClick={() => this.addCode("reasonForIncomeId")}
                        >
                          <PlusOutlined /> Add item
                        </a>
                      </div>
                    </div>
                  )}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["reasonForIncomeIdSelectItems"]) &&
                    this.state["reasonForIncomeIdSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="IncomePlan.deptorId" defaultMessage="Deptor" />}
                name="deptorId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select style={{ width: "100%" }} placeholder="Deptor">
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["deptorSelectItems"]) &&
                    this.state["deptorSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="IncomePlan.incomeType" defaultMessage="Income Type" />}
                name="incomeType"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Income Type"
                  onChange={value => {
                    this.setState({
                      incomeType: value
                    });
                    this.formRef.current.setFieldsValue({
                      receivingDate: null,
                      scheduleType: null,
                      scheduleAmountType: null,
                      period: null,
                      firstDateForPeriod: null,
                      scheduleList: null
                    });
                  }}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["incomeTypeSelectItems"]) &&
                    this.state["incomeTypeSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {Boolean(this.state.incomeType) && this.state.incomeType === "ONETIME" && (
              <Form.Item
                {...layout}
                label={<FormattedMessage id="IncomePlan.receivingDate" defaultMessage="Receiving Date" />}
                name="receivingDate"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <DatePicker format="DD-MM-YYYY" />
              </Form.Item>
            )}{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="IncomePlan.amount" defaultMessage="Total Amount" />}
                name="amount"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber
                  placeholder="Amount"
                  min={0}
                  onChange={value => {
                    this.setState({
                      amount: value
                    });
                  }}
                />
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="IncomePlan.includeVat" defaultMessage="IncludeVat" />}
                name="includeVat"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Checkbox>
                  {" "}
                  <FormattedMessage id="IncomePlan.includeVat" defaultMessage="IncludeVat" />{" "}
                </Checkbox>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="IncomePlan.paymentMode" defaultMessage="Mode Of Income" />}
                name="paymentMode"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Mode Of Income"
                  onChange={value => {
                    this.setState({
                      paymentMode: value
                    });
                    this.formRef.current.setFieldsValue({
                      bankAccountsLogId: null
                    });
                  }}
                >
                  {Boolean(this.state["paymentModeSelectItems"]) &&
                    this.state["paymentModeSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {Boolean(this.state.paymentMode) && this.state.paymentMode === "BANK" && (
              <Form.Item
                {...layout}
                label={<FormattedMessage id="IncomePlan.bankAccountsLogId" defaultMessage="Bank Account" />}
                name="bankAccountsLogId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} placeholder="Bank Account">
                  {this.state.bankAccountsSelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}{" "}
                </Select>
              </Form.Item>
            )}{" "}
            {Boolean(this.state.paymentMode) && this.state.paymentMode === "CASH" && (
              <Form.Item
                {...layout}
                label={<FormattedMessage id="IncomePlan.cashLogId" defaultMessage="Cash Log" />}
                name="cashLogId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} placeholder="Cash Log">
                  {this.state.cashLogSelectItems.map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}{" "}
                </Select>
              </Form.Item>
            )}{" "}
            {Boolean(this.state.incomeType) && this.state.incomeType === "MULTIPLE" && (
              <Form.Item
                {...layout}
                label={<FormattedMessage id="IncomePlan.scheduleType" defaultMessage="Schedule Type" />}
                name="scheduleType"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Schedule Type"
                  onChange={value => {
                    this.setState({
                      scheduleType: value,
                      period: null,
                      firstDateForPeriod: null
                    });
                    this.formRef.current.setFieldsValue({
                      period: null,
                      firstDateForPeriod: null,
                      scheduleList: null
                    });
                  }}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["scheduleTypeSelectItems"]) &&
                    this.state["scheduleTypeSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            )}{" "}
            {Boolean(this.state.incomeType) && this.state.incomeType === "MULTIPLE" && (
              <Form.Item
                {...layout}
                label={<FormattedMessage id="IncomePlan.scheduleAmountType" defaultMessage="Schedule Amount Type" />}
                name="scheduleAmountType"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Schedule Amount Type"
                  onChange={value => {
                    this.setState({
                      scheduleAmountType: value,
                      fixedAmount: null
                    });

                    this.formRef.current.setFieldsValue({
                      fixedAmount: null,
                      scheduleList: null
                    });
                  }}
                >
                  <Option key={null} value={null}>
                    Select
                  </Option>
                  {Boolean(this.state["scheduleAmountTypeSelectItems"]) &&
                    this.state["scheduleAmountTypeSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            )}{" "}
            {Boolean(this.state.incomeType) &&
              this.state.incomeType === "MULTIPLE" &&
              Boolean(this.state.scheduleAmountType) &&
              this.state.scheduleAmountType === "FIXED" && (
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="IncomePlan.fixedAmount" defaultMessage="Fixed Amount" />}
                  name="fixedAmount"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <InputNumber
                    placeholder="Fixed amount"
                    min={0}
                    onChange={value => {
                      this.setState({
                        fixedAmount: value
                      });

                      this.formRef.current.setFieldsValue({
                        scheduleList: null
                      });
                    }}
                  />
                </Form.Item>
              )}{" "}
            {Boolean(this.state.incomeType) &&
              this.state.incomeType === "MULTIPLE" &&
              Boolean(this.state.scheduleType) &&
              this.state.scheduleType === "PERIODIC" && (
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="IncomePlan.period" defaultMessage="Period" />}
                  name="period"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Period"
                    onChange={value => {
                      this.setState({
                        period: value
                      });
                      this.formRef.current.setFieldsValue({
                        scheduleList: null
                      });
                    }}
                  >
                    <Option key={null} value={null}>
                      Select
                    </Option>
                    {Boolean(this.state["periodSelectItems"]) &&
                      this.state["periodSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              )}{" "}
            {Boolean(this.state.incomeType) &&
              this.state.incomeType === "MULTIPLE" &&
              Boolean(this.state.scheduleType) &&
              this.state.scheduleType === "PERIODIC" && (
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="IncomePlan.firstDateForPeriod" defaultMessage="First Date For Period" />}
                  name="firstDateForPeriod"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <DatePicker
                    onChange={date => {
                      this.setState({
                        firstDateForPeriod: date
                      });
                      this.formRef.current.setFieldsValue({
                        scheduleList: null
                      });
                    }}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              )}
            {Boolean(this.state.incomeType) && this.state.incomeType === "MULTIPLE" && (
              <Form.List name="scheduleList">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item {...layout} label={<FormattedMessage id="PlanService.Coordinates" defaultMessage={index + 1 + ". Payment"} />}>
                        <Form.Item
                          {...field}
                          name={[field.name, "date"]}
                          extra={<FormattedMessage id="ImcomePlan.schedule.date" defaultMessage="Date" />}
                          style={{
                            display: "inline-block",
                            width: "calc(30%)",
                            marginBottom: "5px"
                          }}
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <DatePicker format="DD-MM-YYYY" placeholder="Date" />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "amount"]}
                          extra={<FormattedMessage id="ImcomePlan.schedule.amount" defaultMessage="Amount" />}
                          style={{
                            display: "inline-block",
                            width: "calc(30%)",
                            marginBottom: "5px"
                          }}
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <InputNumber placeholder="Amount" />
                        </Form.Item>

                        <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} />
                      </Form.Item>
                    ))}
                    <Form.Item {...tailFormItemLayout}>
                      {Boolean(this.state.scheduleAmountType) && this.state.scheduleAmountType === "VARIABLE" && (
                        <AntButton type="dashed" onClick={() => this.addNewSchedule()} style={{ width: "60%" }} icon={<PlusOutlined />}>
                          Add new schedule
                        </AntButton>
                      )}

                      {Boolean(this.state.scheduleAmountType) && this.state.scheduleAmountType === "FIXED" && (
                        <AntButton type="dashed" onClick={() => this.calculateScheduleList()} style={{ width: "60%" }} icon={<PlusOutlined />}>
                          Calculate schedule
                        </AntButton>
                      )}
                    </Form.Item>
                  </>
                )}
              </Form.List>
            )}
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {this.state.showReceiveModal && (
          <AntModal
            centered
            width={1500}
            title="Item Breakdown"
            visible={this.state.showReceiveModal}
            onCancel={() => {
              this.setState({ showReceiveModal: false });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showReceiveModal: false
                  });
                }}
              >
                Close
              </Button>
            ]}
          >
            {this.state.showReceiveModal && (
              <IncomeLog incomePlanId={this.state.selectedIncomePlan.id} totalAmount={this.state.selectedIncomePlan.amount}></IncomeLog>
            )}
          </AntModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(IncomePlan);
