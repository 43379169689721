import { EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { PlusOutlined } from "@ant-design/icons";
import EditIcon from "@material-ui/icons/EditTwoTone";
import {
  Button as AntButton,
  Badge,
  Col,
  Form,
  Input,
  Row,
  Select,
  Divider,
  Collapse,
  Space,
} from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest, API_BASE_ROOT } from "../../../ApiConnector";
import { error, showError, showSuccess } from "../../../MessageHelper";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { Button, Modal } from "react-bootstrap";
import TaxGroupDetail from "./TaxGroupDetail";

const { Option } = Select;
const { Panel } = Collapse;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node.data)) {
      var lineage = [];
      if (Boolean(node.data.lineage) && node.data.lineage.length > 0) {
        node.data.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.data.id,
        lineage: lineage,
        title: node.data.menuTitle,
        page: node.data.page
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class TaxGroup extends Component {
  constructor() {
    super();
    this.state = {
      formName: "TaxGroup",
      controllerName: "taxGroup",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      tableList: [],
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });

    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.fillCode("TaxGroupParent");
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      fileList: [],
    });
  };

  edit = row => {
    console.log("AAA", row);
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      searchCriteriaModels = [],
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
      searchCriteriaModels: searchCriteriaModels
    };
    return newObj;
  }

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    let newItem = {
      ...values,
      id: this.state.id,
    };

    if (!Boolean(newItem.id)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  renderElement = values => {
    return (
      Boolean(values) &&
      values.length > 0 &&
      values.map((item, index) => (
        <Panel header={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{item.parentName + "-" + item.taxGroup}</span>
            <Space>
              <EditIcon fontSize="default" color="primary" onClick={() => { this.edit(item) }} />
              <DeleteIcon fontSize="default" color="error" backgroundColor="black" onClick={() => { this.deleteModal(item) }} />
            </Space>
          </ div>
        }
          key={index}>
          <TaxGroupDetail random={Math.random()} taxGroupId={item.id} />
        </Panel>
      ))
    );
  };

  setOpenPanels = key => {
    this.setState({
      activePanelKey: key
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/" + this.state.controllerName + "/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  getRatingList = max => {
    const numbers = [];
    for (let index = 1; index <= max; index++) {
      numbers.push(index);
    }
    return numbers;
  };

  handleFileChange = ({ fileList }) => this.setState({ fileList });

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    return (
      <Badge.Ribbon text="Tax Group" placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Row gutter={[16, 16]}>
            <Col md={23}>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>
          <div hidden={this.state.hideInputs}>
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="TaxGroup.TaxParent" defaultMessage="Tax Parent" />}
                  name="parentId"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("TaxGroupParent")}
                          >
                            <PlusOutlined /> <FormattedMessage id="TaxGroup.AddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["TaxGroupParentSelectItems"]) &&
                      this.state["TaxGroupParentSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              <Form.Item
                {...layout}
                label={<FormattedMessage id="TaxGroup.TaxGroup" defaultMessage="Tax Group" />}
                name="taxGroup"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input />
              </Form.Item>

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </Form>
          </div>
          <br />

          {Boolean(this.state.tableList) && this.state.tableList.length > 0 ? (
            <>
              <Collapse activeKey={this.state.activePanelKey} onChange={this.setOpenPanels} accordion>
                {this.renderElement(this.state.tableList)}
              </Collapse>
            </>
          ) : (
            ""
          )}

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TaxGroup);