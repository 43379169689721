import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import RevisionIcon from "@material-ui/icons/ViewList";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { Form, Table, Input, Select, Divider, DatePicker, Button as AntButton, Col, Row, Modal as AntModal } from "antd";
import { ExcelReader } from "../excel/ExcelReader";
import moment from "moment";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import CreateIcon from "@material-ui/icons/AddCircle";
import ItpActivityQuestionnary from "./ItpActivityQuestionnary";

const { RangePicker } = DatePicker;
const { Option } = Select;

export class ItpActivity extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "itpActivity",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],

      id: null,
      tableList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      revNoSelectItems: null,
      discipline1SelectItems: null,
      satipNoSelectItems: null,
      satipDescriptionSelectItems: null,
      itemNoSelectItems: null,
      projectPhaseActivityCodeSelectItems: null,
      taskActivitySelectItems: null,
      qualityProcedureSelectItems: null,
      checklistItemIdsSelectItems: null,
      testRequirementIdsSelectItems: null,
      locationIdSelectItems: null,
      drawingIdsSelectItems: null,
      satipLogList: [],

      answerTypeSelectItems: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    const { pagination } = this.state;
    this.restartTable({ pagination });
  };

  fillComboboxes = async () => {
    var responsediscipline1 = await handleRequest("GET", "/api/satiplog/discipline1SelectItems");
    if (responsediscipline1.type === "ERROR") {
      error(responsediscipline1);
    } else {
      this.setState({
        discipline1SelectItems: Boolean(responsediscipline1.data) ? responsediscipline1.data : []
      });
    }

    var responsetestRequirementIds = await handleRequest("GET", "/api/codes/type/testRequirementIds");
    if (responsetestRequirementIds.type === "ERROR") {
      error(responsetestRequirementIds);
    } else {
      this.setState({
        testRequirementIdsSelectItems: Boolean(responsetestRequirementIds.data) ? responsetestRequirementIds.data : []
      });
    }
  };

  onChangeChecklist = async saicNo => {
    if (Boolean(saicNo)) {
      var responsesatipNo = await handleRequest("GET", "/api/saiclog/checklistItemIdsSelectItems/" + saicNo);
      if (responsesatipNo.type === "ERROR") {
        error(responsesatipNo);
      } else {
        this.setState({
          checklistItemIdsSelectItems: Boolean(responsesatipNo.data) ? responsesatipNo.data : []
        });
      }
    } else {
      this.setState({
        checklistItemIdsSelectItems: []
      });
    }

    this.formRef.current.setFieldsValue({
      checklistItemIds: []
    });

    this.setState({
      checklistItemIds: []
    });
  };

  onchangeDiscipline1 = async discipline1 => {
    if (Boolean(discipline1)) {
      var responsesatipNo = await handleRequest("GET", "/api/satiplog/satipNoSelectItems/" + discipline1);
      if (responsesatipNo.type === "ERROR") {
        error(responsesatipNo);
      } else {
        this.setState({
          satipNoSelectItems: Boolean(responsesatipNo.data) ? responsesatipNo.data : []
        });
      }
    } else {
      this.setState({
        satipNoSelectItems: [],
        revNoSelectItems: [],
        satipDescriptionSelectItems: [],
        itemNoSelectItems: [],
        qualityProcedureSelectItems: [],
        taskActivitySelectItems: [],
        projectPhaseActivityCodeSelectItems: []
      });
    }

    this.formRef.current.setFieldsValue({
      satipNo: null,
      revNo: null,
      satipDescription: null,
      itemNo: null,
      qualityProcedure: null,
      taskActivity: null,
      projectPhaseActivityCode: null
    });

    this.setState({
      satipNo: null,
      revNo: null,
      satipDescription: null,
      itemNo: null,
      qualityProcedure: null,
      taskActivity: null,
      projectPhaseActivityCode: null
    });
  };

  onchangeSatipNo = async satipNo => {
    const { discipline1 } = this.state;
    if (Boolean(discipline1) && Boolean(satipNo)) {
      var responserevNo = await handleRequest("GET", "/api/satiplog/revNoSelectItems/" + discipline1 + "/" + satipNo);
      if (responserevNo.type === "ERROR") {
        error(responserevNo);
      } else {
        this.setState({
          revNoSelectItems: Boolean(responserevNo.data) ? responserevNo.data : []
        });
      }

      var responsesatipDescription = await handleRequest("GET", "/api/satiplog/satipDescriptionSelectItems/" + discipline1 + "/" + satipNo);
      if (responsesatipDescription.type === "ERROR") {
        error(responsesatipDescription);
      } else {
        this.setState({
          satipDescriptionSelectItems: Boolean(responsesatipDescription.data) ? responsesatipDescription.data : []
        });

        if (Boolean(responsesatipDescription.data) && responsesatipDescription.data.length > 0) {
          this.formRef.current.setFieldsValue({
            satipDescription: responsesatipDescription.data[0].key
          });

          this.setState({
            satipDescription: responsesatipDescription.data[0].key
          });
        }
      }

      var responseitemNo = await handleRequest("GET", "/api/satiplog/itemNoSelectItems/" + discipline1 + "/" + satipNo);
      if (responseitemNo.type === "ERROR") {
        error(responseitemNo);
      } else {
        this.setState({
          itemNoSelectItems: Boolean(responseitemNo.data) ? responseitemNo.data : []
        });
      }
    } else {
      this.setState({
        revNoSelectItems: [],
        satipDescriptionSelectItems: [],
        itemNoSelectItems: [],
        qualityProcedureSelectItems: [],
        taskActivitySelectItems: [],
        projectPhaseActivityCodeSelectItems: []
      });
    }

    this.formRef.current.setFieldsValue({
      revNo: null,
      satipDescription: null,
      itemNo: null,
      qualityProcedure: null,
      taskActivity: null,
      projectPhaseActivityCode: null
    });

    this.setState({
      revNo: null,
      satipDescription: null,
      itemNo: null,
      qualityProcedure: null,
      taskActivity: null,
      projectPhaseActivityCode: null
    });
  };

  onChangeRevNo = async revNo => {
    const { discipline1, satipNo } = this.state;
    if (Boolean(discipline1) && Boolean(satipNo)) {
      var responsesatipDescription = await handleRequest(
        "GET",
        "/api/satiplog/satipDescriptionSelectItems/" + discipline1 + "/" + satipNo + (Boolean(revNo) ? "/" + revNo : "")
      );
      if (responsesatipDescription.type === "ERROR") {
        error(responsesatipDescription);
      } else {
        this.setState({
          satipDescriptionSelectItems: Boolean(responsesatipDescription.data) ? responsesatipDescription.data : []
        });

        if (Boolean(responsesatipDescription.data) && responsesatipDescription.data.length > 0) {
          this.formRef.current.setFieldsValue({
            satipDescription: responsesatipDescription.data[0].key
          });

          this.setState({
            satipDescription: responsesatipDescription.data[0].key
          });
        }
      }

      var responseitemNo = await handleRequest(
        "GET",
        "/api/satiplog/itemNoSelectItems/" + discipline1 + "/" + satipNo + (Boolean(revNo) ? "/" + revNo : "")
      );
      if (responseitemNo.type === "ERROR") {
        error(responseitemNo);
      } else {
        this.setState({
          itemNoSelectItems: Boolean(responseitemNo.data) ? responseitemNo.data : []
        });
      }
    } else {
      console.log("revNo", revNo);
      this.setState({
        satipDescriptionSelectItems: [],
        itemNoSelectItems: [],
        qualityProcedureSelectItems: [],
        taskActivitySelectItems: [],
        projectPhaseActivityCodeSelectItems: []
      });
    }

    this.formRef.current.setFieldsValue({
      itemNo: null,
      qualityProcedure: null,
      taskActivity: null,
      projectPhaseActivityCode: null
    });

    this.setState({
      itemNo: null,
      qualityProcedure: null,
      taskActivity: null,
      projectPhaseActivityCode: null
    });
  };

  onChangeItemNo = async itemNo => {
    const { discipline1, satipNo, revNo } = this.state;
    if (Boolean(discipline1) && Boolean(satipNo) && Boolean(itemNo)) {
      var responseprojectPhaseActivityCode = await handleRequest(
        "GET",
        "/api/satiplog/projectPhaseActivityCodeSelectItems/" + discipline1 + "/" + satipNo + (Boolean(revNo) ? "/" + revNo + "/" : "/") + itemNo
      );
      if (responseprojectPhaseActivityCode.type === "ERROR") {
        error(responseprojectPhaseActivityCode);
      } else {
        this.setState({
          projectPhaseActivityCodeSelectItems: Boolean(responseprojectPhaseActivityCode.data) ? responseprojectPhaseActivityCode.data : []
        });
      }
      var responsetaskActivity = await handleRequest(
        "GET",
        "/api/satiplog/taskActivitySelectItems/" + discipline1 + "/" + satipNo + (Boolean(revNo) ? "/" + revNo + "/" : "/") + itemNo
      );
      if (responsetaskActivity.type === "ERROR") {
        error(responsetaskActivity);
      } else {
        this.setState({
          taskActivitySelectItems: Boolean(responsetaskActivity.data) ? responsetaskActivity.data : []
        });

        if (Boolean(responsetaskActivity.data) && responsetaskActivity.data.length > 0) {
          this.formRef.current.setFieldsValue({
            projectPhaseActivityCode: responsetaskActivity.data[0].key
          });

          this.setState({
            projectPhaseActivityCode: responsetaskActivity.data[0].key
          });
        }
      }
      var responsequalityProcedure = await handleRequest(
        "GET",
        "/api/satiplog/qualityProcedureSelectItems/" + discipline1 + "/" + satipNo + (Boolean(revNo) ? "/" + revNo + "/" : "/") + itemNo
      );
      if (responsequalityProcedure.type === "ERROR") {
        error(responsequalityProcedure);
      } else {
        this.setState({
          qualityProcedureSelectItems: Boolean(responsequalityProcedure.data) ? responsequalityProcedure.data : []
        });
      }
    } else {
      this.setState({
        qualityProcedureSelectItems: [],
        taskActivitySelectItems: [],
        projectPhaseActivityCodeSelectItems: []
      });
    }

    this.formRef.current.setFieldsValue({
      qualityProcedure: null,
      taskActivity: null
    });

    this.setState({
      qualityProcedure: null,
      taskActivity: null
    });
  };

  refreshLinkDrawingList = async drawingSearch => {
    let obj = { drawingNo: drawingSearch, shtNo: drawingSearch };

    const response = await handleRequest("POST", "/api/drawings/search", obj);
    if (Boolean(response.data)) {
      this.setState({
        drawingIdsSelectItems: response.data
      });
    } else
      this.setState({
        drawingIdsSelectItems: []
      });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();

    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = [];

    let testRequirementIdsFlag = Boolean(row.testRequirementIds) ? row.testRequirementIds.split(",") : [];
    let testRequirementIds = [];

    testRequirementIdsFlag.forEach(element => {
      testRequirementIds.push(parseInt(element));
    });

    let drawingIdsStringArray = Boolean(row.drawingIds) ? row.drawingIds.split(",") : [];
    let drawingIds = [];

    drawingIdsStringArray.forEach(element => {
      drawingIds.push(parseInt(element));
    });

    let drawingIdsSelectItems = [];

    if (Boolean(row.drawingList) && row.drawingList.length > 0)
      row.drawingList.forEach(d => {
        drawingIdsSelectItems.push(d);
      });

    this.formRef.current.setFieldsValue({
      ...row,
      testRequirementIds: testRequirementIds,
      checklistItemIds: Boolean(row.checklistItemIds) ? row.checklistItemIds.split(",") : [],
      drawingIds: drawingIds
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      drawingIdsSelectItems: drawingIdsSelectItems
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/itpActivity/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      pagination,
      filters
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/itpActivity/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        let drawingIdsSelectItems = [];

        list.forEach((element, index) => {
          element.key = element.id;
          element.sn = index + 1;
          if (Boolean(element.drawingList) && element.drawingList.length > 0)
            element.drawingList.forEach(d => {
              drawingIdsSelectItems.push(d);
            });
        });

        this.setState({
          loading: false,
          tableList: list,
          drawingIdsSelectItems: drawingIdsSelectItems,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    console.log(values);

    const newItem = {
      ...values,
      id: this.state.id,
      testRequirementIds: Boolean(values.testRequirementIds) ? values.testRequirementIds.toString() : "",
      checklistItemIds: Boolean(values.checklistItemIds) ? values.checklistItemIds.toString() : "",
      drawingIds: Boolean(values.drawingIds) ? values.drawingIds.toString() : ""
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/itpActivity", newItem);
    } else {
      response = await handleRequest("PUT", "/api/itpActivity/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.resetInputs();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  ExcelDateToJSDate = serial => {
    if ((serial + "").includes(".")) {
      return moment(serial, "DD.MM.YYYY").toDate();
    } else {
      var hours = Math.floor((serial % 1) * 24);
      var minutes = Math.floor(((serial % 1) * 24 - hours) * 60);
      return new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
    }
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            rfiNo: element["RFI No"],
            logType: element["Type"],
            epmNo: element["EPM no"],
            disciplineName: element["Discipline"],
            revisionNo: element["Rev"],
            worksInspected: element["Works To Be Inspected"],
            issueDate: this.ExcelDateToJSDate(element["Issue Date"])
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/itpActivity/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            },
            {
              name: "Answer Type",
              icon: <RevisionIcon fontSize="small" color="primary" />,
              actionClick: this.onClickAnswerType.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  searchItpWithDiscipline = discipline1 => {
    const { filterObject } = this.state;

    this.setState(
      {
        filterObject: { ...filterObject, discipline1 }
      },
      this.searchItp
    );
  };

  searchUserMail = async () => {
    const { filterObject } = this.state;

    if (Boolean(filterObject)) {
      var response = await handleRequest("POST", "/api/satiplog/search", filterObject);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          satipLogList: Boolean(response.data) ? response.data : []
        });
      }
    } else
      this.setState({
        satipLogList: []
      });
  };

  onClickAnswerType = record => {
    this.setState({
      modalAnswerTypeShow: true,
      selectedItpActivityRow: record
    });
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        title: "Actions",
        render: this.action,
        width: "%5"
      },
      {
        title: "S/N",
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        },
        width: "%5"
      },
      {
        title: <FormattedMessage id="ItpActivity.revNo" defaultMessage="Rev. No" />,
        key: "revNo",
        render: record => {
          return record.revNo;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.revNo) ? this.state.pagination.revNo : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  revNo: e.target.value,
                  revNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.revNoColor) ? this.state.pagination.revNoColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: "%5"
      },
      {
        title: <FormattedMessage id="ItpActivity.discipline1" defaultMessage="Discipline" />,
        key: "discipline1",
        render: record => {
          return record.discipline1;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.discipline1) ? this.state.pagination.discipline1 : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  discipline1: e.target.value,
                  discipline1Color: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.discipline1Color) ? this.state.pagination.discipline1Color : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: "%5"
      },
      {
        title: <FormattedMessage id="ItpActivity.satipNo" defaultMessage="ITP Code" />,
        key: "satipNo",
        render: record => {
          return record.satipNo;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.satipNo) ? this.state.pagination.satipNo : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  satipNo: e.target.value,
                  satipNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.satipNoColor) ? this.state.pagination.satipNoColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: "%10"
      },
      {
        title: <FormattedMessage id="ItpActivity.satipDescription" defaultMessage="ITP Description" />,
        key: "satipDescription",
        render: record => {
          return record.satipDescription;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.satipDescription) ? this.state.pagination.satipDescription : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  satipDescription: e.target.value,
                  satipDescriptionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.satipDescriptionColor) ? this.state.pagination.satipDescriptionColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        ellipsis: true,
        width: "%15"
      },
      {
        title: <FormattedMessage id="ItpActivity.itemNo" defaultMessage="Activity No" />,
        key: "itemNo",
        render: record => {
          return record.itemNo;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.itemNo) ? this.state.pagination.itemNo : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  itemNo: e.target.value,
                  itemNoColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.itemNoColor) ? this.state.pagination.itemNoColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: "%5"
      },
      {
        title: <FormattedMessage id="ItpActivity.projectPhaseActivityCode" defaultMessage="Activity Phase" />,
        key: "projectPhaseActivityCode",
        render: record => {
          return record.projectPhaseActivityCode;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.projectPhaseActivityCode) ? this.state.pagination.projectPhaseActivityCode : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  projectPhaseActivityCode: e.target.value,
                  projectPhaseActivityCodeColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.projectPhaseActivityCodeColor) ? this.state.pagination.projectPhaseActivityCodeColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        ellipsis: true,
        width: "%10"
      },
      {
        title: <FormattedMessage id="ItpActivity.taskActivity" defaultMessage="Description of Activity" />,
        key: "activity",
        render: record => {
          return record.activity;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.taskActivity) ? this.state.pagination.taskActivity : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  activity: e.target.value,
                  activityColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.activityColor) ? this.state.pagination.activityColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        ellipsis: true,
        width: "%15"
      },
      {
        title: <FormattedMessage id="ItpActivity.qualityProcedure" defaultMessage="Checklist" />,
        key: "qualityProcedure",
        render: record => {
          return record.qualityProcedure;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.qualityProcedure) ? this.state.pagination.qualityProcedure : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  qualityProcedure: e.target.value,
                  qualityProcedureColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.qualityProcedureColor) ? this.state.pagination.qualityProcedureColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        width: "%5"
      },
      {
        title: <FormattedMessage id="ItpActivity.checklistItemIds" defaultMessage="Checklist Items" />,
        key: "checklistItemIds",
        render: record => {
          return record.checklistItemIds;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.checklistItemIds) ? this.state.pagination.checklistItemIds : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  checklistItemIds: e.target.value,
                  checklistItemIdsColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.checklistItemIdsColor) ? this.state.pagination.checklistItemIdsColor : "#bfbfbf"
            }}
          />
        ),
        sorter: true,
        ellipsis: true,
        width: "%10"
      },
      {
        title: <FormattedMessage id="ItpActivity.testRequirementIds" defaultMessage="Test RequirementIds" />,
        key: "testRequirementIds",
        render: record => {
          let result = [];
          const { testRequirementIdsSelectItems } = this.state;
          if (Boolean(record.testRequirementIds) && Boolean(testRequirementIdsSelectItems)) {
            let ids = record.testRequirementIds.split(",");
            ids.forEach(element => {
              let fObj = testRequirementIdsSelectItems.find(p => p.id == element);
              if (Boolean(fObj)) result.push(fObj.name);
            });
          }
          return result.toString();
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.testRequirementIds) ? this.state.pagination.testRequirementIds : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  testRequirementIds: e.target.value,
                  testRequirementIdsColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({
                  _pagination,
                  sortField: this.state.sortField,
                  sortOrder: this.state.sortOrder
                });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: Boolean(this.state.pagination.testRequirementIdsColor) ? this.state.pagination.testRequirementIdsColor : "#bfbfbf"
            }}
          />
        ),
        sorter: false,
        ellipsis: true,
        width: "%5"
      },
      {
        title: "Last Update",
        key: "lastUpdateDate",
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={Boolean(this.state.pagination.lastUpdateDate) ? this.state.pagination.lastUpdateDate : null}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: "HH:mm" }}
              onChange={this.onChangeLastUpdateDate}
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.lastUpdatedColor }} />,
        sorter: true,
        width: "%5"
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    const props = {
      action: `${API_BASE_ROOT}/api/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}>
              <span className="h3">
                <FormattedMessage
                  id={this.state.controllerName + ".pageTitle"}
                  defaultMessage={"ITP Activity"}
                />
              </span>
            </Col>
            <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ItpActivity.discipline1" defaultMessage="Discipline" />}
                name="discipline1"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onSearch={this.searchItpWithDiscipline}
                  onChange={value => {
                    this.setState({
                      discipline1: value
                    });
                    this.onchangeDiscipline1(value);
                  }}
                  style={{ width: "100%" }}
                  placeholder="Discipline"
                >
                  {Boolean(this.state["discipline1SelectItems"]) &&
                    this.state["discipline1SelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ItpActivity.satipNo" defaultMessage="ITP Code" />}
                name="satipNo"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onSearch={this.searchItp}
                  onChange={value => {
                    this.setState({
                      satipNo: value
                    });
                    this.onchangeSatipNo(value);
                  }}
                  style={{ width: "100%" }}
                  placeholder="ITP Code"
                >
                  {Boolean(this.state["satipNoSelectItems"]) &&
                    this.state["satipNoSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ItpActivity.revNo" defaultMessage="Rev. No" />}
                name="revNo"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onChange={value => {
                    this.setState({
                      revNo: value
                    });
                    this.onChangeRevNo(value);
                  }}
                  style={{ width: "100%" }}
                  placeholder="Rev. No"
                >
                  {Boolean(this.state["revNoSelectItems"]) &&
                    this.state["revNoSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ItpActivity.satipDescription" defaultMessage="ITP Description" />}
                name="satipDescription"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select allowClear showSearch optionFilterProp="children" style={{ width: "100%" }} placeholder="ITP Description">
                  {Boolean(this.state["satipDescriptionSelectItems"]) &&
                    this.state["satipDescriptionSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ItpActivity.itemNo" defaultMessage="Activity No" />}
                name="itemNo"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onSearch={this.searchItp}
                  onChange={value => {
                    this.setState({
                      itemNo: value
                    });
                    this.onChangeItemNo(value);
                  }}
                  style={{ width: "100%" }}
                  placeholder="Activity No"
                >
                  {Boolean(this.state["itemNoSelectItems"]) &&
                    this.state["itemNoSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ItpActivity.projectPhaseActivityCode" defaultMessage="Activity Phase" />}
                name="projectPhaseActivityCode"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select allowClear showSearch optionFilterProp="children" onSearch={this.searchItp} style={{ width: "100%" }} placeholder="Activity Phase">
                  {Boolean(this.state["taskActivitySelectItems"]) &&
                    this.state["taskActivitySelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ItpActivity.taskActivity" defaultMessage="Description of Activity" />}
                name="activity"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input placeholder="Description of Activity"></Input>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ItpActivity.qualityProcedure" defaultMessage="Checklist" />}
                name="qualityProcedure"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onSearch={this.searchItp}
                  onChange={value => {
                    this.onChangeChecklist(value);
                  }}
                  style={{ width: "100%" }}
                  placeholder="Checklist"
                >
                  {Boolean(this.state["qualityProcedureSelectItems"]) &&
                    this.state["qualityProcedureSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ItpActivity.checklistItemIds" defaultMessage="Checklist Items" />}
                tooltip={<FormattedMessage id="ItpActivity.checklistItemIds.w" defaultMessage="Items of selected checklist." />}
                name="checklistItemIds"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  mode="multiple"
                  onSearch={this.searchItp}
                  style={{ width: "100%" }}
                  placeholder="Checklist Items"
                >
                  {Boolean(this.state["checklistItemIdsSelectItems"]) &&
                    this.state["checklistItemIdsSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ItpActivity.testRequirementIds" defaultMessage="Test RequirementIds" />}
                tooltip={<FormattedMessage id="ItpActivity.testRequirementIds.w" defaultMessage="you can select multiple items." />}
                name="testRequirementIds"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Test RequirementIds"
                  mode="multiple"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={this.state.codeName}
                          onChange={event => {
                            this.setState({
                              codeName: event.target.value
                            });
                          }}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer"
                          }}
                          onClick={() => this.addCode("testRequirementIds")}
                        >
                          <PlusOutlined /> Add item
                        </a>
                      </div>
                    </div>
                  )}
                >
                  {Boolean(this.state["testRequirementIdsSelectItems"]) &&
                    this.state["testRequirementIdsSelectItems"].map(i => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }{" "}
            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            size="small"
            bordered
            scroll={{ y: 500, x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
            expandable={{
              expandedRowRender: record => (Boolean(record) && Boolean(record.id)) &&
                <ItpActivityQuestionnary random={Math.random()} itpActivityId={record.id}
                  qualityProcedure={record.qualityProcedure} />,
              rowExpandable: () => {
                return true;
              }
            }}
          />
        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <AntModal
          centered
          width={1200}
          title=""
          visible={this.state.modalAnswerTypeShow}
          onCancel={() => {
            this.setState({ modalAnswerTypeShow: false })
          }}
          cancelText="Close"
          okButtonProps={{ style: { display: "none" } }}
        >
          {Boolean(this.state.selectedItpActivityRow) &&
            <ItpActivityQuestionnary random={Math.random()} itpActivityId={this.state.selectedItpActivityRow.id}
              qualityProcedure={this.state.selectedItpActivityRow.qualityProcedure} />}
        </AntModal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ItpActivity);
