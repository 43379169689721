import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Badge } from "antd";

export class React3D extends Component {
  constructor() {
    super();
    this.state = {
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: []
    };
  }

  formRef = React.createRef();

  render() {
    return (
      <Badge.Ribbon text="React3D" placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          {" "}
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(React3D);
