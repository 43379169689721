import "antd/dist/antd.css";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Row, Col, Button as AntButton, Input, Card, Space, Spin } from "antd";
import {
  ClearOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import DraggableModal from "../../../Components/DraggableModal";
import { TextToParaDrawer } from "./TextToParaDrawer";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const { TextArea } = Input;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class TextToParaLogItem extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "TextToParaLogItem",
      controllerName: "textToParaLogItem",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      textToPara: null,
      renderedValue: "",
      selectedItem: { title: "" },

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.fillItems();
  };

  fillItems = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/fillItems/" + this.props.textToParaLogId);
    if (response.type === "ERROR") {
      error(response);
    }

    let selectedItem = { title: "" };
    let itemSelectItems = Boolean(response.data) ? response.data : [];
    if (Boolean(itemSelectItems) && itemSelectItems.length > 0) {
      selectedItem = itemSelectItems[0];

      this.formRef.current.setFieldsValue({
        ...selectedItem
      });
    }

    this.setState({
      itemSelectItems: itemSelectItems,
      selectedItem: selectedItem,
    });
  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }

    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
      this.fillItems();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    let { textToParaLogId } = this.props;

    const newItem = {
      ...values,
      id: this.state.id,
      textToParaLogId: textToParaLogId,
    };
    console.log("newItem", newItem);
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      this.fillItems();
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          modalAddNewDimensionShow: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout1 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };

    const { selectedTab, itemSelectItems, selectedText, selectedItem } = this.state;

    const handleTextSelection = () => {
      const textArea = document.getElementById("text-area");
      const start = textArea.selectionStart;
      const end = textArea.selectionEnd;
      const selected = textArea.value.substring(start, end);

      // const beforeSelection = textArea.value.substring(0, start);
      // const afterSelection = textArea.value.substring(end);
      // const highlightedValue = `${beforeSelection}[${selected} - Seçilen metin]${afterSelection}`;

      this.setState({
        // renderedValue: highlightedValue,
        selectedText: selected,
      });
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          {/* üst 3 card başlangıç */}
          <Spin spinning={this.state.loading}>
            <Row gutter={24}>
              {/* Sol Card */}
              <Col span={9}>
                <Card
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <Row gutter={[24, 24]}>
                    <Col md={21}>
                      <Form.Item
                        name="selection"
                        rules={[
                          {
                            required: false,
                            message: (
                              <FormattedMessage
                                id="GENERAL.REQUIRED"
                                defaultMessage="This field is required."
                              />
                            ),
                          },
                        ]}
                      >
                        <TextArea
                          id="text-area"
                          rows={4}
                          placeholder="Metni buraya yazın"
                          style={{ width: "100%" }}
                          onMouseUp={handleTextSelection}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={2}>
                      <AntButton type="default" onClick={() => {
                        if (!Boolean(selectedText)) {
                          return;
                        }
                        let oldValue = this.formRef.current.getFieldValue("selectedText");
                        this.formRef.current.setFieldsValue({
                          selectedText: Boolean(oldValue) ? (oldValue + " " + selectedText) : selectedText,
                        });
                      }}
                        style={{ borderRadius: "", color: "#000000", borderColor: "", background: "#dcfade" }}
                        icon={<ArrowRightOutlined />}></AntButton>
                    </Col>

                    <Col md={1}></Col>
                  </Row>
                </Card>
              </Col>

              <Col span={13}>
                <Card
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Row gutter={[24, 24]}>
                    <Col md={16}>
                      <Form.Item
                        name="selectedText"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <TextArea
                          rows={4}
                          placeholder="Selected Text"
                          style={{ width: "100%", height: "200%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}></Col>
                  </Row>

                  <Row gutter={[24, 24]}>
                    <Col md={5}>
                      <Form.Item
                        name="itemNo"
                        placeholder="Item No"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input
                          placeholder="Item No"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="title"
                        placeholder="Title"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input
                          placeholder="Title"
                          style={{ width: "100%" }}
                          onChange={e => {
                            this.setState({
                              ribbonName: e.target.value
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={7}>
                      <Form.Item
                        name="keyword"
                        placeholder="Keywords"
                        rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input
                          placeholder="Keywords"
                          style={{ width: "100%" }}
                          onChange={e => {
                            this.setState({
                              ribbonName: e.target.value
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6}></Col>
                  </Row>
                </Card>
              </Col>

              <Col span={1.5}>
                <Card
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Row gutter={[16, 16]}>
                    <AntButton
                      type="default"
                      icon={<PlusCircleOutlined />}
                      style={{ borderRadius: "", color: "#000000", borderColor: "", background: "#b6fcfa" }}
                      onClick={() => {
                        this.setState({
                          id: null,
                        }, () => {
                          this.formRef.current.submit();
                        });
                      }}
                    ></AntButton>
                  </Row>
                  <br></br>
                  {Boolean(selectedItem) && Boolean(selectedItem.id) && selectedItem.id > 0 &&
                    <Row gutter={[16, 16]}>
                      <AntButton type="default" icon={<EditOutlined />} style={{ color: "#000000", background: "#cdf7a6" }}
                        onClick={() => this.formRef.current.submit()}
                      ></AntButton>
                    </Row>
                  }
                  <br></br>
                  <Row gutter={[16, 16]}>
                    <AntButton type="default" icon={<ClearOutlined />} style={{ color: "#000000", background: "#f7f7b2" }}
                      onClick={() => {
                        this.formRef.current.setFieldsValue({
                          selectedText: "",
                          itemNo: "",
                          title: "",
                          keyword: "",
                        });
                      }}
                    ></AntButton>
                  </Row>
                  <br></br>
                  {Boolean(selectedItem) && Boolean(selectedItem.id) && selectedItem.id > 0 &&
                    <Row gutter={[16, 16]}>
                      <AntButton type="default" icon={<DeleteOutlined />} style={{ color: "#000000", background: "#fa9696" }}
                        onClick={() => this.deleteModal(selectedItem)}
                      ></AntButton>
                    </Row>
                  }
                </Card>
              </Col>
            </Row>
          </Spin>
          {/* üst 3 card bitiş */}
          <br></br>
          {/* alt sol card başlangıç */}
          <div className="d-flex flex-row">
            <div className="flex-row-auto offcanvas-mobile w-150px w-xxl-250px" id="kt_profile_aside">
              <div className="card card-custom card-stretch">
                {/* begin::Body */}
                <div className="card-body pt-4">
                  {/* begin::Toolbar */}
                  <div className="d-flex justify-content-end"></div>
                  {/* end::Toolbar */}
                  {/* begin::Nav */}
                  <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                    {Boolean(itemSelectItems) &&
                      itemSelectItems.length > 0 &&
                      itemSelectItems.map(item => (
                        <div className="navi-item mb-2">
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip>
                                {item.itemNo + "-" + item.title}
                              </Tooltip>
                            }
                          >
                            <a
                              href="#"
                              className={`navi-link py-4 ${selectedItem.title === item.title ? "active-link" : ""}`}
                              onClick={() => {
                                this.setState({
                                  selectedItem: item
                                });
                                this.edit(item)
                              }}
                            >
                              <span className="navi-icon mr-2">
                                <span className="svg-icon">
                                  <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>
                                </span>
                              </span>
                              <span className="navi-text">
                                {item.itemNo + "-" + item.title}
                              </span>
                            </a>
                          </OverlayTrigger>
                        </div>
                      ))}
                  </div>
                  {/* end::Nav */}
                </div>
                {/* end::Body */}
              </div>
            </div>
            {/* alt sağ card başlangıç */}
            <div className="flex-row-fluid ml-lg-8">
              <div className="card card-custom card-stretch">
                <div className="form">
                  <div className="card-body">
                    <div className="d-flex flex-row">
                      {Boolean(selectedItem) && Boolean(selectedItem.id) && selectedItem.id > 0 &&
                        <TextToParaDrawer textToParaLogItemId={selectedItem.id}> </TextToParaDrawer>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* alt sağ card bitiş */}
          </div>
          {/* alt sol card bitiş */}

          {selectedTab === "Table" && (
            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Button id="SaveAllButton" type="submit" style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSaveAll" defaultMessage="Save All" />
                </Button>
              </Col>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            </Row>
          )}
        </Form>

        <DraggableModal
          title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
          centered
          width={500}
          okButtonProps={{ hidden: true }}
          open={this.state.modalDeleteShow}
          onCancel={() => {
            this.setState({ modalDeleteShow: false });
          }}
          footer={[
            <Space>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalDeleteShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Space>
          ]}
          content={
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          }
        />
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TextToParaLogItem);