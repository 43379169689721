import React, { useEffect } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

function PresentationPage2(props) {
    const [companyName, setCompanyName] = React.useState(Boolean(props.companyName) ? props.companyName : null);

    useEffect(() => {
        //setLoadUrl(props.apiUrl);

        // const getData = async () => {
        //   await fillData(props.apiUrl);
        // };
        // getData().catch(console.error);
    }, []);

    return (
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-7">
                    <div class="row">
                        <div class="col-md-12">
                            <span className="headerText">Business Plan 2025</span>
                            <hr />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div style={{
                                "font-family": "Public Sans",
                                "font-size": "60px",
                                "margin-top": '100px',
                                "font-weight": "bolder"
                            }}>{companyName}
                            </div>
                            <div style={{
                                "position": "relative",
                                "font-family": "Public Sans",
                                "font-size": "28px",
                            }}>We are a fashion retail shop specializing in stylish workwear basics.

                            </div>
                            <div style={{
                                "position": "relative",
                                "font-family": "Public Sans Thin",
                                "font-size": "22px",
                            }}>Present with ease and wow any audience with Canva Presentations. Choose from over a thousand professionally-made templates to fit any objective or topic. Make it your own by customizing it with text and photos. 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <img src={toAbsoluteUrl("/media/presentation/right_side1.png")} width="400px" style={{"padding": "0px", marginTop: "0px"}} />
                </div>
                <div class="col-md-1 sunumSayfaModalSagBar">
                    
                </div>
            </div>
        </div>
    );
}

export default PresentationPage2;
