
import { Button as AntButton, Col, Form, Row, DatePicker, Select,Typography,  Table } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import moment from "moment";
import { FormattedMessage} from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";

const { Option } = Select;
const { Text } = Typography;

export class RawMaterialMonitoring extends Component {
    constructor() {
        super();
        this.state = {
            formName: "RawMaterialMonitoring",
            controllerName: "rawMaterialMonitoring",
            period: "DAILY",
            startDate: moment()
                .subtract(1, "M")
                .toDate(),
            endDate: moment().toDate(),
            loading: false,
            columns: [],
            projectIdSelectItems:null
        };
    }

    formRef = React.createRef();


    fillComboboxes = async () => {

        var responseprojectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/projectId");
        if (responseprojectId.type === "ERROR") {
            error(responseprojectId);
        }
        this.setState({
            projectIdSelectItems: Boolean(responseprojectId.data) ? responseprojectId.data : []
        });

       // var responseproductId = await handleRequest("GET", "/api/" + this.state.controllerName + "/productId");
       // if (responseproductId.type === "ERROR") {
       //     error(responseproductId);
       // }
       // this.setState({
       //     productIdSelectItems: Boolean(responseproductId.data) ? responseproductId.data : []
       // });
//
    };

    componentDidMount = async () => {
        this.restartTable();
        this.fillComboboxes();
    };

    search = async values => {
        console.log(values);
        if (Boolean(values.startDate) && Boolean(values.endDate) && values.startDate > values.endDate) {
            showError("Start date cannot be greater than end date");
            return;
        }

        this.setState(
            {
                period: Boolean(values.period) ? values.period : "MONTHLY",
                startDate: Boolean(values.startDate) ? values.startDate.toDate() : null,
                endDate: Boolean(values.endDate) ? values.endDate.toDate() : null,
                loading: true
            },
            this.restartTable
        );
    };

    restartTable = async () => {
        const { period, startDate, endDate } = this.state;
    
        const search = {
          period,
          startDate: startDate
            ? startDate
            : moment()
                .subtract(1, "M")
                .toDate(),
          endDate: endDate ? endDate : moment().toDate()
        };
        var response = await handleRequest("POST", "/api/cashflow", search);
        if (response.type === "ERROR") {
          error(response);
        } else {
          let data = Boolean(response.data) ? response.data : null;
          let tableList = [];
          let tableListActualized = [];
          let columns = [
            {
              title: "",
              key: "name",
              render: (value, row, index) => {
                return (
                  <React.Fragment>
                    <div
                      style={{
                        color: "black",
                        font: "bold",
                        fontSize: "16px"
                      }}
                    >
                      {row.name}
                    </div>
                  </React.Fragment>
                );
              },
              width: 200,
              fixed: "left"
            }
          ];
    
          if (Boolean(data)) {
            let localColumns = this.generateColumns();
    
            let incomeList = this.generateList2(data.incomes, localColumns, "Planning Total", data.incomesActualized);
            let outgoingList = this.generateList2(data.outgoings, localColumns, "Raw Material Total", data.outgoingsActualized);
         

    
            let incomeList2 = this.generateList(data.incomes, localColumns, "Incomes");
            let outgoingList2 = this.generateList(data.outgoings, localColumns, "Outgoings");
            let totalRows = this.generateTotalRows(incomeList2, outgoingList2, localColumns, "(P)");
    
            let incomeListActualized = this.generateList(data.incomesActualized, localColumns, "Incomes");
            let outgoingListActualized = this.generateList(data.outgoingsActualized, localColumns, "Outgoings");
            let totalRowsActualized = this.generateTotalRows(incomeListActualized, outgoingListActualized, localColumns, "(A)");
    
            tableList.push(...incomeList);
            tableList.push(...outgoingList);            
            tableList.push(...totalRows);
            tableList.push(...totalRowsActualized);
    
            tableListActualized.push(...incomeListActualized);
            tableListActualized.push(...outgoingListActualized);
            tableListActualized.push(...totalRowsActualized);
    
            columns.push(...localColumns);
          }
          this.setState({ columns, tableList, tableListActualized, loading: false });
        }
      };
    

    generateColumns = () => {
        const { period, startDate, endDate } = this.state;
        let columns = [];
        if (Boolean(startDate) && Boolean(endDate))
            switch (period) {
                case "DAILY":
                    let start = startDate;
                    let end = endDate;
                    let days = moment(end).diff(moment(start), "days");
                    if (days >= 0) {
                        for (let i = 0; i <= days; i++) {
                            let date = moment(start)
                                .add(i, "days")
                                .toDate();
                            columns.push({
                                title: moment(date).format("DD-MM-YYYY"),
                                key: moment(date).format("DD-MM-YYYY"),
                                render: (value, row, index) => {
                                    return row[moment(date).format("DD-MM-YYYY")];
                                },
                                width: 200
                            });
                        }
                    }
                    break;
                case "WEEKLY":
                    let startWeek = startDate;
                    let endWeek = endDate;
                    let weeks = moment(endWeek).diff(moment(startWeek), "weeks");
                    if (weeks >= 0) {
                        for (let i = 0; i <= weeks; i++) {
                            let date = moment(startWeek)
                                .add(i, "weeks")
                                .toDate();
                            let weekIndex = moment(date).weekYear();
                            let year = moment(date).year();
                            columns.push({
                                title: weekIndex + "-" + year,
                                key: weekIndex + "-" + year,
                                render: (value, row, index) => {
                                    return row[weekIndex + "-" + year];
                                },
                                width: 200
                            });
                        }
                    }

                    break;
                case "MONTHLY":
                    let startMonth = startDate;
                    let endMonth = endDate;
                    let months = moment(endMonth).diff(moment(startMonth), "months");
                    if (months >= 0) {
                        for (let i = 0; i <= months; i++) {
                            let date = moment(startMonth)
                                .add(i, "months")
                                .toDate();
                            let monthIndex = moment(date).month() + 1;
                            let year = moment(date).year();
                            columns.push({
                                title: moment(date).format("MMM-YYYY"),
                                key: monthIndex + "-" + year, //moment(date).format("MMM-YYYY"),
                                render: (value, row, index) => {
                                    return row[monthIndex + "-" + year];
                                },
                                width: 200
                            });
                        }
                    }

                    break;
                case "YEARLY":
                    let startYear = startDate;
                    let endYear = endDate;
                    let years = moment(endYear).diff(moment(startYear), "years");
                    if (years >= 0) {
                        for (let i = 0; i <= years; i++) {
                            let date = moment(startYear)
                                .add(i, "years")
                                .toDate();
                            let year = moment(date).year() + "";
                            columns.push({
                                title: year,
                                key: year,
                                render: (value, row, index) => {
                                    return row[year];
                                },
                                width: 200
                            });
                        }
                    }

                    break;

                default:
                    break;
            }

        return columns;
    };

    generateList2 = (list, columns, type, listActualized) => {
        let tableList = [];
        let firstRow = { key: type, name: type };
        if (Boolean(list)) {
            list.forEach(item => {
                if (tableList.findIndex(x => x.name === item.name) === -1) {
                    let row = {
                        key: item.name,
                        name: item.name
                    };
                    columns.forEach(column => {
                        let f = list.find(x => x.name === item.name && x.dateStr === column.key);
                        let fAct = listActualized.find(x => x.name === item.name && x.dateStr === column.key);
                        if (Boolean(f)) {
                            firstRow[column.key + "planned"] = Boolean(firstRow[column.key + "planned"]) ? firstRow[column.key + "planned"] + f.amount : f.amount;
                            if (Boolean(fAct)) {
                                row[column.key] = f.amount + " / " + fAct.amount;
                                firstRow[column.key + "actual"] = Boolean(firstRow[column.key + "actual"])
                                    ? firstRow[column.key + "actual"] + fAct.amount
                                    : fAct.amount;
                            } else {
                                row[column.key] = f.amount + " / " + 0;
                                firstRow[column.key + "actual"] = Boolean(firstRow[column.key + "actual"]) ? firstRow[column.key + "actual"] + 0 : 0;
                            }
                            firstRow[column.key] = firstRow[column.key + "planned"] + " / " + firstRow[column.key + "actual"];
                        } else {
                            firstRow[column.key + "planned"] = Boolean(firstRow[column.key + "planned"]) ? firstRow[column.key + "planned"] + 0 : 0;
                            firstRow[column.key + "actual"] = Boolean(firstRow[column.key + "actual"]) ? firstRow[column.key + "actual"] + 0 : 0;
                            firstRow[column.key] = firstRow[column.key + "planned"] + " / " + firstRow[column.key + "actual"];
                        }
                    });
                    tableList.push(row);
                }
            });
        }
        firstRow.children = tableList;
        return [firstRow];
    };

    generateList = (list, columns, type) => {
        let tableList = [];
        let firstRow = { key: type, name: type };
        if (Boolean(list)) {
            list.forEach(item => {
                if (tableList.findIndex(x => x.name === item.name) === -1) {
                    let row = {
                        key: item.name,
                        name: item.name
                    };
                    columns.forEach(column => {
                        let f = list.find(x => x.name === item.name && x.dateStr === column.key);
                        if (Boolean(f)) {
                            row[column.key] = f.amount;
                            firstRow[column.key] = Boolean(firstRow[column.key]) ? firstRow[column.key] + f.amount : f.amount;
                        } else firstRow[column.key] = Boolean(firstRow[column.key]) ? firstRow[column.key] + 0 : 0;
                    });
                    tableList.push(row);
                }
            });
        }
        firstRow.children = tableList;
        return [firstRow];
    };

    generateTotalRows = (incomeList, outgoingList, columns, name) => {
        let totalRows = [];
        let D = 0;
        let firstRow = { key: "In stock" + name, name: "In stock " + name };
        let secondRow = { key: "Planned Purchasing" + name, name: "Planned Purchasing " + name };
        let thirdRow = { key: "Planned Manufacturing" + name, name: "Planned Manufacturing " + name };

        columns.forEach(column => {
            let income = Boolean(incomeList[0][column.key]) ? incomeList[0][column.key] : 0;
            let outgoing = Boolean(outgoingList[0][column.key]) ? outgoingList[0][column.key] : 0;
            //if (Boolean(income) && Boolean(outgoing)) {
            let C = income - outgoing;
            firstRow[column.key] = C;
            secondRow[column.key] = C + D;
            D = D + C;
            // }
        });
        totalRows.push(firstRow);
        totalRows.push(secondRow);
        totalRows.push(thirdRow);
        console.log(name, totalRows);
        return totalRows;
    };

    render() {
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
        };

        return (
            <div>
                <Form initialValues={{ remember: false }} onFinish={this.search} onFinishFailed={onFinishFailed} ref={this.formRef}>

                    <Form.Item
                        {...layout}
                        label={<FormattedMessage id="RawMaterialMonitoring.projectId" defaultMessage="Project" />}
                        name="projectId"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                        <Select allowClear
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                        >
                            {Boolean(this.state["projectIdSelectItems"]) && this.state["projectIdSelectItems"].length > 0 &&
                                this.state["projectIdSelectItems"].map(i => (
                                    <Option key={i.key} value={i.key}>
                                        {i.value}
                                    </Option>
                                ))}{" "}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        {...layout}
                        label={<FormattedMessage id="RawMaterialMonitoring.productId" defaultMessage="Product" />}
                        name="productId"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    >
                        <Select allowClear
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                        >
                            {Boolean(this.state["productIdSelectItems"]) && this.state["productIdSelectItems"].length > 0 &&
                                this.state["productIdSelectItems"].map(i => (
                                    <Option key={i.key} value={i.key}>
                                        {i.value}
                                    </Option>
                                ))}{" "}
                        </Select>
                    </Form.Item>
                </Form>
                <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                    <Table
                        size="small"
                        bordered
                        scroll={{ y: 600, x: 900 }}
                        // scroll={{ x: 900 }}
                        columns={this.state.columns}
                        dataSource={this.state.tableList}
                        loading={this.state.loading}
                        summary={pageData => {
                            let priceSubTotal = 0;
                            pageData.forEach(({ price }) => {
                              priceSubTotal += price;
                            });
                            var priceTotal = priceSubTotal + ((priceSubTotal * this.state.priceVat) / 100);
                            return (
                              <>
                                <Table.Summary.Row>
                             
                                  <Table.Summary.Cell>Balance</Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <Text type="danger">{0}</Text>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                                
                                
                              </>
                            );
                          }}
                    />
                </div>
                {/* <Row>
          <Col span={8}>
            <h2>
              {" "}
              <div className="float-left font-weight-bold mb-4 mr-2">
                <FormattedMessage id="IncomeLog.acutalized" defaultMessage="ACTUALIZED" />
              </div>
            </h2>
          </Col>
        </Row>
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            size="small"
            bordered
            scroll={{ y: 600, x: 900 }}
            // scroll={{ x: 900 }}
            columns={this.state.columns}
            dataSource={this.state.tableListActualized}
            loading={this.state.loading}
          />
        </div> */}
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(RawMaterialMonitoring);
