import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Tooltip, Drawer, Collapse, Space } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined, Settings } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../../excel/ExcelReader";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import DraggableModal from "../../../Components/DraggableModal";
import { Select } from "antd";
import { Input } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { EldenMethodOfStatementStepsDrawer } from "./EldenMethodOfStatementStepsDrawer";
import { EldenCompoundsDrawer } from "./EldenCompoundsDrawer";

const { Option } = Select;
const { Panel } = Collapse;

export class EldenCompounds extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "EldenCompounds",
      controllerName: "eldenCompounds",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      disciplineItems: [],
      serviceItems: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      columns: [
        { key: "key", render: this.action },
        {
          title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
          key: "id",
          render: (value, row, index) => {
            return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
          }
        },
        {
          title: <FormattedMessage id="EldenCompounds.disciplineId" defaultMessage="Discipline" />,
          key: "disciplineId",
          render: record => {
            return record.disciplineName;
          },
        },
        {
          title: <FormattedMessage id="EldenCompounds.Service" defaultMessage="Service" />,
          key: "serviceId",
          render: record => {
            return record.serviceName;
          },
        },
        {
          title: <FormattedMessage id="EldenCompounds.SubService" defaultMessage="Sub Service" />,
          key: "subServiceId",
          render: record => {
            return record.subServiceName;
          },
        },
        {
          title: <FormattedMessage id="EldenCompounds.compound" defaultMessage="Compound" />,
          key: "compound",
          render: record => {
            return record.compound;
          },
        },
      ]
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillDisciplineItems();
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillDisciplineItems = async () => {
    var responseGroupByDiscipline = await handleRequest("GET", "/api/" + this.state.controllerName + "/groupByDiscipline");
    if (responseGroupByDiscipline.type === "ERROR") {
      error(responseGroupByDiscipline);
    }
    this.setState({
      disciplineItems: Boolean(responseGroupByDiscipline.data) ? responseGroupByDiscipline.data : []
    });
  }

  fillComboboxes = async () => {
    var responsedisciplineId = await handleRequest("GET", "/api/" + this.state.controllerName + "/discipline");
    if (responsedisciplineId.type === "ERROR") {
      error(responsedisciplineId);
    }
    this.setState({
      disciplineIdSelectItems: Boolean(responsedisciplineId.data) ? responsedisciplineId.data : []
    });
    var responseserviceId = await handleRequest("GET", "/api/" + this.state.controllerName + "/service");
    if (responseserviceId.type === "ERROR") {
      error(responseserviceId);
    }
    this.setState({
      serviceIdSelectItems: Boolean(responseserviceId.data) ? responseserviceId.data : []
    });
    var responsegeometricalShapeId = await handleRequest("GET", "/api/" + this.state.controllerName + "/geometricalShape");
    if (responsegeometricalShapeId.type === "ERROR") {
      error(responsegeometricalShapeId);
    }
    this.setState({
      geometricalShapeIdSelectItems: Boolean(responsegeometricalShapeId.data) ? responsegeometricalShapeId.data : []
    });
    var responseComponentId = await handleRequest("GET", "/api/" + this.state.controllerName + "/component");
    if (responseComponentId.type === "ERROR") {
      error(responseComponentId);
    }
    this.setState({
      componentIdSelectItems: Boolean(responseComponentId.data) ? responseComponentId.data : []
    });
    var responseMaterialId = await handleRequest("GET", "/api/" + this.state.controllerName + "/material");
    if (responseMaterialId.type === "ERROR") {
      error(responseMaterialId);
    }
    this.setState({
      materialIdSelectItems: Boolean(responseMaterialId.data) ? responseMaterialId.data : []
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      disciplineId: this.state.disciplineId,
      serviceId: this.state.serviceId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    if (Boolean(row.settingsList) && row.settingsList.length > 0) {
      row.settingsList.forEach(element => {
        if (Boolean(element.materialIds) && element.materialIds.length > 0) {
          let materialIds = [];
          element.materialIds.forEach(material => {
            materialIds.push(material.materialId);
          });
          element.materialIds = materialIds;
        }
      });
    }

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.settingsList && newItem.settingsList.length > 0) {
      newItem.settingsList.forEach(settingsList => {
        let materials = [];
        if (Boolean(settingsList.materialIds) && settingsList.materialIds.length > 0) {
          settingsList.materialIds.forEach(materialId => {
            materials.push({ materialId: materialId });
          });
        }
        settingsList.materialIds = materials;
      });
    }

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.fillDisciplineItems();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  setOpenPanels = key => {
    this.fillGroupByService(key);
    this.setState({
      activePanelKey: key,
      activePanelKeyChild: -1,
      tableList: [],
    });
  };

  setOpenPanelsChild = key => {
    this.setState({
      activePanelKeyChild: key,
      disciplineId: this.state.activePanelKey,
      serviceId: key,
    }, () => {
      const { pagination } = this.state;
      this.restartTable({ pagination });
    });
  };

  fillGroupByService = async (disciplineId) => {
    if (Boolean(disciplineId)) {
      var responseService = await handleRequest("GET", "/api/" + this.state.controllerName + "/groupByService/" + disciplineId);
      if (responseService.type === "ERROR") {
        error(responseService);
      }
      this.setState({
        serviceItems: Boolean(responseService.data) ? responseService.data : []
      });
    } else {
      this.setState({
        serviceItems: []
      });
    }
  }

  renderElement = values => {
    return (
      Boolean(values) &&
      values.length > 0 &&
      values.map((item, index) => (
        <Panel header={item.value} key={item.key}>
          <>
            <Collapse activeKey={this.state.activePanelKeyChild} onChange={this.setOpenPanelsChild} accordion>
              {Boolean(this.state.serviceItems) &&
                this.state.serviceItems.length > 0 &&
                this.state.serviceItems.map((service, index) => (
                  <Panel header={service.value} key={service.key}>
                    {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
                      <div className="alert alert-custom alert-light">
                        <div className="alert-icon">
                          <FilterOutlined />
                        </div>
                        <div className="alert-text">
                          {this.state.searchCriteriaModels.map((val, i) => {
                            return (
                              <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                                <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                                <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                                <span>
                                  <Tooltip title="Remove">
                                    <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                                  </Tooltip>
                                </span>
                              </div>
                            );
                          })}
                        </div>
                        <div className="alert-close">
                          <Tooltip title="Remove All">
                            <CloseCircleOutlined
                              style={{ color: "crimson" }}
                              onClick={() =>
                                this.setState({ searchCriteriaModels: [] }, () => {
                                  this.filterChangeItem([]);
                                })
                              }
                            />
                          </Tooltip>
                        </div>
                      </div>
                    )}
                    <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                      <Table
                        bordered
                        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                        columns={this.state.columns}
                        dataSource={this.state.tableList}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                        pagination={this.state.pagination}
                      />
                    </div>
                  </Panel>
                ))}


            </Collapse>
            <br />
          </>
        </Panel>
      ))
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EldenCompounds.disciplineId" defaultMessage="Discipline" />,
        key: "disciplineId",
        render: record => {
          return record.disciplineName;
        },
        ...getColumnFilter({
          inputName: "disciplineName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenCompounds.Service" defaultMessage="Service" />,
        key: "serviceId",
        render: record => {
          return record.serviceName;
        },
        ...getColumnFilter({
          inputName: "serviceId",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenCompounds.SubService" defaultMessage="Sub Service" />,
        key: "subServiceId",
        render: record => {
          return record.subServiceName;
        },
        ...getColumnFilter({
          inputName: "subServiceId",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EldenCompounds.compound" defaultMessage="Compound" />,
        key: "compound",
        render: record => {
          return record.compound;
        },
        ...getColumnFilter({
          inputName: "compound",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="EldenCompounds" hideElement={true}>
            <ExcelSheet name="EldenCompounds" data={this.state.tableList}>
              <ExcelColumn label="Discipline" value="disciplineName" />
              <ExcelColumn label="Service" value="serviceName" />
              <ExcelColumn label="Compound" value="compound" />
              <ExcelColumn label="Component" value="componentName" />
              <ExcelColumn label="Material" value="materialName" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    return (
      <Badge.Ribbon
        text={<FormattedMessage id="eldenCompounds.pageTitle" defaultMessage="Elden Compounds" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={19}></Col>
              <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
              <Col md={1}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <Tooltip title={<FormattedMessage id="EldenCompounds.Settings" defaultMessage="Settings" />}>
                    <AntButton
                      style={{ border: "0px" }}
                      onClick={() => {
                        this.setState({ drawerSettingsShow: true });
                      }}
                      icon={<Settings color="error" fontSize="large" />}
                    ></AntButton>
                  </Tooltip>
                )}
              </Col>
              <Col md={1}>
                <Tooltip title={<FormattedMessage id="EldenCompounds.CompoundsSettings" defaultMessage="Compounds Settings" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      this.setState({ drawerCompoundsSettingsShow: true });
                    }}
                    icon={<Settings fontSize="large" />}
                  ></AntButton>
                </Tooltip>
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenCompounds.disciplineId" defaultMessage="Discipline" />}
                  name="disciplineId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["disciplineIdSelectItems"]) &&
                      this.state["disciplineIdSelectItems"].length > 0 &&
                      this.state["disciplineIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenCompounds.Service" defaultMessage="Service" />}
                  name="serviceId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["serviceIdSelectItems"]) &&
                      this.state["serviceIdSelectItems"].length > 0 &&
                      this.state["serviceIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenCompounds.SubService" defaultMessage="Sub Service" />}
                  name="subServiceId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["serviceIdSelectItems"]) &&
                      this.state["serviceIdSelectItems"].length > 0 &&
                      this.state["serviceIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenCompounds.compound" defaultMessage="Compound" />}
                  name="compound"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EldenCompounds.geometricalShapeId" defaultMessage="Geometrical Shape" />}
                  name="geometricalShapeId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["geometricalShapeIdSelectItems"]) &&
                      this.state["geometricalShapeIdSelectItems"].length > 0 &&
                      this.state["geometricalShapeIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>

          <br />
          {Boolean(this.state.disciplineItems) && this.state.disciplineItems.length > 0 ? (
            <>
              <Collapse activeKey={this.state.activePanelKey} onChange={this.setOpenPanels} accordion>
                {this.renderElement(this.state.disciplineItems)}
              </Collapse>
            </>
          ) : (
            ""
          )}

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <Drawer
            title={<FormattedMessage id="EldenCompounds.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerSettingsShow: false });
            }}
            open={this.state.drawerSettingsShow}
          >
            <PageSettings pageName={"EldenCompounds"} />
          </Drawer>

          <Drawer
            title={<FormattedMessage id="EldenCompounds.CompoundsSettings" defaultMessage="Compounds Settings" />}
            width={1200}
            closable={false}
            onClose={() => {
              this.setState({ drawerCompoundsSettingsShow: false });
            }}
            open={this.state.drawerCompoundsSettingsShow}
          >
            <EldenCompoundsDrawer />
          </Drawer>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EldenCompounds);