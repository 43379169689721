import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Tooltip, Drawer, Space, DatePicker, Tag, Radio } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined, MoreHoriz, Settings } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../../excel/ExcelReader";
import store from "../../../../redux/store";
import { PageSettings } from "../../administrator/pageSettings/PageSettings";
import DraggableModal from "../../../Components/DraggableModal";
import { InputNumber } from "antd";
import { LineChartOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { Checkbox } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Histogram from "./Histogram";

const { Option } = Select;

export class Manpower extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "Manpower",
      controllerName: "manpower",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      showWorkingPeriodWeekModalTitle: "",

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefWorkingPeriod = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responsedepartmentId = await handleRequest("GET", "/api/" + this.state.controllerName + "/department");
    if (responsedepartmentId.type === "ERROR") {
      error(responsedepartmentId);
    }
    this.setState({
      departmentIdSelectItems: Boolean(responsedepartmentId.data) ? responsedepartmentId.data : []
    });
    var responsedesignationId = await handleRequest("GET", "/api/" + this.state.controllerName + "/designation");
    if (responsedesignationId.type === "ERROR") {
      error(responsedesignationId);
    }
    this.setState({
      designationIdSelectItems: Boolean(responsedesignationId.data) ? responsedesignationId.data : []
    });
    var responseroleDescriptionId = await handleRequest("GET", "/api/" + this.state.controllerName + "/roleDescription");
    if (responseroleDescriptionId.type === "ERROR") {
      error(responseroleDescriptionId);
    }
    this.setState({
      roleDescriptionIdSelectItems: Boolean(responseroleDescriptionId.data) ? responseroleDescriptionId.data : []
    });
    var responseproposedPersonId = await handleRequest("GET", "/api/" + this.state.controllerName + "/proposedPerson");
    if (responseproposedPersonId.type === "ERROR") {
      error(responseproposedPersonId);
    }
    this.setState({
      proposedPersonIdSelectItems: Boolean(responseproposedPersonId.data) ? responseproposedPersonId.data : []
    });

    var responseType = await handleRequest("GET", "/api/" + this.state.controllerName + "/type");
    if (responseType.type === "ERROR") {
      error(responseType);
    }
    this.setState({
      typeSelectItems: Boolean(responseType.data) ? responseType.data : []
    });
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      tenderingLogId: this.props.tenderingLogId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      showWorkingPeriodWeekModalTitle: "",

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    if (Boolean(this.props.tenderingLogId) && this.props.tenderingLogId > 0) {
      const { showWorkingPeriodWeekModalTitle } = this.state;
      let workingPeriod = this.state.formRefWorkingPeriod;

      console.log("workingPeriodList", workingPeriod);
      if (showWorkingPeriodWeekModalTitle === "Working Period Week" ||
        showWorkingPeriodWeekModalTitle === "Working Period Month") {
        if (Boolean(workingPeriod) && Boolean(workingPeriod.workingPeriodList) && workingPeriod.workingPeriodList.length > 0) {
          workingPeriod.workingPeriodList = workingPeriod.workingPeriodList.filter(p => p.numberOfChecked);
        }

        if (Boolean(workingPeriod) && Boolean(workingPeriod.workingPeriodList) && workingPeriod.workingPeriodList.length > 0 && Boolean(showWorkingPeriodWeekModalTitle)) {
          if (showWorkingPeriodWeekModalTitle === "Working Period Week") {
            workingPeriod.workingPeriodList.forEach(element => {
              element.periodType = "Week";
            });
          } else if (showWorkingPeriodWeekModalTitle === "Working Period Month") {
            workingPeriod.workingPeriodList.forEach(element => {
              element.periodType = "Month";
            });
          }
        }
      }

      const newItem = {
        ...values,
        ...workingPeriod,
        id: this.state.id,
        tenderingLogId: this.props.tenderingLogId,
      };

      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
      }

      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showWarning("Bidding Log not found.");
    }
  };

  saveWorkingPeriod = async values => {
    console.log("Values", values);
    this.setState({ formRefWorkingPeriod: values, showWorkingPeriodDateModal: false });
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  fillWorkingPeriod = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getWorkingPeriod/" + this.props.tenderingLogId);
    if (response.type === "ERROR") {
      error(response);
    } else {
      let type = Boolean(response.data) && Boolean(response.data.type) ? response.data.type : null
      let count = Boolean(response.data) && Boolean(response.data.count) ? response.data.count : 0
      if (Boolean(type)) {
        if (type === "Date") {
          this.setState({ showWorkingPeriodDateModal: true });
        } else if (type === "Week") {
          this.setState({
            showWorkingPeriodWeekModal: true,
            showWorkingPeriodWeekModalTitle: "Working Period Week"
          }, () => {
            let workingPeriodList = [];
            for (let index = 1; index < count + 1; index++) {
              workingPeriodList.push({ "numberOf": index });
            }
            this.formRefWorkingPeriod.current.setFieldsValue({
              workingPeriodList: workingPeriodList
            });
          });
        } else if (type === "Month") {
          this.setState({
            showWorkingPeriodWeekModal: true,
            showWorkingPeriodWeekModalTitle: "Working Period Month"
          }, () => {
            let workingPeriodList = [];
            for (let index = 1; index < count + 1; index++) {
              workingPeriodList.push({ "numberOf": index });
            }

            this.formRefWorkingPeriod.current.setFieldsValue({
              workingPeriodList: workingPeriodList
            });
          });
        } else {
          showWarning("Period type not found.");
        }
      } else {
        showWarning("Period type not found.");
      }
    }
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="Manpower.departmentId" defaultMessage="Department" />,
        key: "departmentId",
        render: record => {
          return record.departmentName;
        },
        ...getColumnFilter({
          inputName: "departmentId",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Manpower.designationId" defaultMessage="Designation" />,
        key: "designationId",
        render: record => {
          return record.designationName;
        },
        ...getColumnFilter({
          inputName: "designationId",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Manpower.roleDescriptionId" defaultMessage="Role Description" />,
        key: "roleDescriptionId",
        render: record => {
          return record.roleDescriptionName;
        },
        ...getColumnFilter({
          inputName: "roleDescriptionId",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Manpower.NumberOfPerson" defaultMessage="Number of Person" />,
        key: "numberOfPerson",
        render: record => {
          return record.numberOfPerson;
        },
        ...getColumnFilter({
          inputName: "numberOfPerson",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Manpower.Type" defaultMessage="Type" />,
        key: "type",
        render: record => {
          return record.type;
        },
        ...getColumnFilter({
          inputName: "type",
          inputType: "SELECT",
          selectItems: Boolean(this.state["typeSelectItems"]) ? this.state["typeSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Manpower.WorkingPeriod" defaultMessage="Working Period" />,
        key: "id",
        render: record => {
          return record.workingPeriodName;
        },
      },
      {
        title: <FormattedMessage id="Manpower.proposedPersonId" defaultMessage="Proposed Person" />,
        key: "proposedPersonId",
        render: record => {
          return record.proposedPersonName;
        },
        ...getColumnFilter({
          inputName: "proposedPersonId",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Manpower.Salary" defaultMessage="Salary" />,
        key: "id",
        render: record => {
          let list = ["Forecasted", "Official"];
          let a = list.map(item => {
            return (
              <Tag color={item === "Forecasted" ? "green" : "blue"}>
                {item === "Forecasted" ? "Forecasted: " + record.forecastedSalary : "Official: " + record.officialSalary}
              </Tag>
            );
          });
          return a;
        },
      }
    ];
    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Manpower" hideElement={true}>
            <ExcelSheet name="Manpower" data={this.state.tableList}>
              <ExcelColumn label="Department" value="departmentName" />
              <ExcelColumn label="Designation" value="designationName" />
              <ExcelColumn label="Role Description" value="roleDescriptionName" />
              <ExcelColumn label="Number" value="number" />
              <ExcelColumn label="Working Period" value="workingPeriod" />
              <ExcelColumn label="Proposed Person" value="proposedPersonName" />
              <ExcelColumn label="Salary" value="salary" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={19}></Col>
            <Col md={1}>
              <Tooltip title={<FormattedMessage id="Manpower.Histogram" defaultMessage="Histogram" />}>
                <AntButton
                  style={{ border: "0px" }}
                  onClick={() =>
                    this.setState({
                      modalHistogramShow: true
                    })
                  }
                  icon={<LineChartOutlined style={{ border: "0px", color: "#007bff" }} />}
                ></AntButton>
              </Tooltip>
            </Col>
            <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
            <Col md={1}>
              {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                <Tooltip title={<FormattedMessage id="Manpower.Settings" defaultMessage="Settings" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      this.setState({ drawerSettingsShow: true });
                    }}
                    icon={<Settings color="error" fontSize="large" />}
                  ></AntButton>
                </Tooltip>
              )}
            </Col>
          </Row>
          <div hidden={this.state.hideInputs}>
            <Form.Item
              {...layout}
              label={<FormattedMessage id="Manpower.departmentId" defaultMessage="Department" />}
              name="departmentId"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                {Boolean(this.state["departmentIdSelectItems"]) &&
                  this.state["departmentIdSelectItems"].length > 0 &&
                  this.state["departmentIdSelectItems"].map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              {...layout}
              label={<FormattedMessage id="Manpower.designationId" defaultMessage="Designation" />}
              name="designationId"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                {Boolean(this.state["designationIdSelectItems"]) &&
                  this.state["designationIdSelectItems"].length > 0 &&
                  this.state["designationIdSelectItems"].map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              {...layout}
              label={<FormattedMessage id="Manpower.roleDescriptionId" defaultMessage="Role Description" />}
              name="roleDescriptionId"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                {Boolean(this.state["roleDescriptionIdSelectItems"]) &&
                  this.state["roleDescriptionIdSelectItems"].length > 0 &&
                  this.state["roleDescriptionIdSelectItems"].map(i => (
                    <Option key={i.key} value={i.key}>
                      {i.value}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Manpower.numberOfPerson" defaultMessage="Number Of Person" />}
                name="numberOfPerson"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            }
            {
              <Row gutter={8}>
                <Col span={8}>
                </Col>
                <Col span={10}>
                  <Form.Item
                    {...layout}
                    name="type"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  >
                    <Radio.Group
                      onChange={e => {
                        this.setState({
                          type: e.target.value
                        });
                      }}
                    >
                      {Boolean(this.state["typeSelectItems"]) &&
                        this.state["typeSelectItems"].length > 0 &&
                        this.state["typeSelectItems"].map(i => (
                          <Radio key={i.key} value={i.key}>{i.key}</Radio>
                        ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Manpower.WorkingPeriod" defaultMessage="Working Period" />}
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <AntButton
                  type="default"
                  style={{
                    color: "black",
                    background: "white"
                  }}
                  onClick={() => {
                    this.fillWorkingPeriod();
                  }}
                >
                  <FormattedMessage id="Manpower.WorkingPeriod" defaultMessage="Working Period" />
                </AntButton>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Manpower.proposedPersonId" defaultMessage="Proposed Person" />}
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name="proposedPersonId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    style={{ width: "90%" }}
                  >
                    <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                      {Boolean(this.state["proposedPersonIdSelectItems"]) &&
                        this.state["proposedPersonIdSelectItems"].length > 0 &&
                        this.state["proposedPersonIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <AntButton
                    type="default"
                    style={{
                      color: "black",
                      background: "white"
                    }}
                    icon={<MoreHoriz />}
                  ></AntButton>
                </Space.Compact>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Manpower.ForecastedSalary" defaultMessage="Forecasted Salary" />}
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Space.Compact style={{ width: "100%" }}>
                  
                <Form.Item
                    name="currentSalary"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox> {<FormattedMessage id="Manpower.CurrentSalary" defaultMessage="Current Salary" />}</Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="forecasted"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox> {<FormattedMessage id="Manpower.Forecasted" defaultMessage="Forecasted" />}</Checkbox>
                  </Form.Item>
                  
                  <Form.Item
                    name="officialSalary"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    style={{ width: "40%", marginRight: "10px" }}
                  >
                    <InputNumber style={{ width: "100%" }} placeholder="official" />
                  </Form.Item>

                  <Form.Item
                    name="forecastedSalary"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    style={{ width: "40%", marginRight: "10px" }}
                  >
                    <InputNumber style={{ width: "100%" }} placeholder="forecasted" />
                  </Form.Item>

                  <Form.Item
                    name="isUseCurrentSalary"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox> {<FormattedMessage id="Manpower.UseCurrentSalary" defaultMessage="Use Current Salary" />}</Checkbox>
                  </Form.Item>
                </Space.Compact>
              </Form.Item>
            }

            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>
        {
          Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )
        }
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Drawer
          title={<FormattedMessage id="Manpower.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"Manpower"} />
        </Drawer>

        <DraggableModal
          title={this.state.showWorkingPeriodWeekModalTitle}
          centered
          width={400}
          maskClosable={false}
          open={this.state.showWorkingPeriodWeekModal}
          onOk={async () => {
            await this.formRefWorkingPeriod.current.submit();
            this.setState({
              showWorkingPeriodSaveModal: true,
              showWorkingPeriodWeekModal: false
            });
          }}
          onCancel={() => {
            this.setState({ showWorkingPeriodWeekModal: false });
          }}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          content={
            <>
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveWorkingPeriod}
                onFinishFailed={onFinishFailed}
                ref={this.formRefWorkingPeriod}
              >
                <Form.List name="workingPeriodList">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <Form.Item hidden name={[field.name, "numberOf"]}>
                                    <InputNumber value={field.name + 1}></InputNumber>
                                  </Form.Item>
                                  <td>
                                    <Form.Item noStyle
                                      name={[field.name, "numberOfChecked"]}
                                      valuePropName="checked"
                                    >
                                      <Checkbox> {field.name + 1} </Checkbox>
                                    </Form.Item>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              </Form>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="Manpower.WorkingPeriod" defaultMessage="Working Period" />}
          centered
          width={600}
          maskClosable={false}
          open={this.state.showWorkingPeriodDateModal}
          onOk={async () => {
            await this.formRefWorkingPeriod.current.submit();
            this.setState({
              showWorkingPeriodSaveModal: true,
              showWorkingPeriodDateModal: false
            });
          }}
          onCancel={() => {
            this.setState({ showWorkingPeriodDateModal: false });
          }}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          content={
            <>
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveWorkingPeriod}
                onFinishFailed={onFinishFailed}
                ref={this.formRefWorkingPeriod}
              >
                <Form.List name="workingPeriodList">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"Manpower.StartDate"} defaultMessage={"Start Date"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"Manpower.EndDate"} defaultMessage={"End Date"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "startDate"]}>
                                      <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item noStyle name={[field.name, "endDate"]}>
                                      <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={6}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              </Form>
            </>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="Manpower.WorkingPeriod" defaultMessage="Working Period" />}
          centered
          width={400}
          maskClosable={false}
          open={this.state.showWorkingPeriodSaveModal}
          onOk={() => {
            this.setState({ showWorkingPeriodSaveModal: false });
          }}
          onCancel={() => {
            this.setState({ showWorkingPeriodSaveModal: false });
          }}
          okText={<FormattedMessage id="Manpower.Yes" defaultMessage="Yes" />}
          cancelText={<FormattedMessage id="Manpower.No" defaultMessage="No" />}
          content={
            <FormattedMessage
              id="Manpower.Willworkingperiodbesameforallremainingmanpower?"
              defaultMessage="Will working period be same for all remaining manpower?"
            />
          }
        />

        <DraggableModal
          title={<FormattedMessage id="Manpower.Histogram" defaultMessage="Histogram" />}
          centered
          width={1200}
          maskClosable={false}
          open={this.state.modalHistogramShow}
          okButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ modalHistogramShow: false });
          }}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={
            <Histogram random={Math.random()} tenderingLogId={this.props.tenderingLogId} />
          }
        />
      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Manpower);
