import { Form } from "antd";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PrepareFormItemProps from "./ElementUtils";

const TextEditorComponent = props => {
  const { field, layout } = props;
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link", "code"],
      ["clean"]
    ]
  };

  const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "code"];
  const onChange = (html, delta, source, editor) => {
    field.value = html;
    props.handleChange(field);
    return;
  };
  return (
    <>
      <Form.Item {...layout} {...PrepareFormItemProps(field)} initialValue="" style={{ marginBottom: "5px" }} wrapperCol={{ span: 12 }}>
        <ReactQuill theme="snow" modules={modules} formats={formats} placeholder={field.label} />
      </Form.Item>
    </>
  );
};

export default TextEditorComponent;
