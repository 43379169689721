import { EditOutlined } from "@material-ui/icons";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import DataTableComponent2 from "../../Components/DataTableComponent2";
import { GetDynamicFormItems } from "../../Components/ElementUtils";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";

export class RoleDescriptionListModal extends Component {
  constructor() {
    super();
    this.state = {
      formName: "RoleDescriptionCompany",
      controllerName: "roleDescriptionCompany",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      tableList: [],
      restartTable: false,
      pageFormData: null,
      excelData: [],
      createWhereParams: null,
      refreshForm: false,
      showRoleDescriptionTemplateModal: false
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    let data = await GetDynamicFormItems(this.state.formName);
    this.setState({
      pageFormData: data
    });

    this.fillComboboxes(data.pageFormInputCompanyList);
  };
  componentDidUpdate = async nextProps => {};
  fillComboboxes = async inputList => {};

  setData = data => {
    this.setState({
      tableList: data
    });
  };
  restartTable = async () => {
    this.setState({ restartTable: !this.state.restartTable });
  };

  selectRow = async row => {
    if (this.props.fillData) {
      this.props.fillData(row);
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Select",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.selectRow.bind(this)
            }
          ]}
        />
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
            <DataTableComponent2
              key="dataTable"
              field={this.state.pageFormData.pageForm}
              inputs={this.state.pageFormData.pageFormInputCompanyList}
              actions={this.action}
              restartTable={this.state.restartTable}
              setData={this.setData.bind(this)}
            />
          ) : null}
        </div>{" "}
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RoleDescriptionListModal);
