import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { EditOutlined, ReplyOutlined, ScoreOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined } from "@ant-design/icons";
import { showError, showSuccess, error } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import {
  Form,
  Table,
  Button as AntButton,
  Image,
  Tag,
  Tooltip,
  Modal as AntModal,
  Badge
} from "antd";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import { CloseCircleOutlined } from "@ant-design/icons";
import SelectFromImageList from "../visual/SelectFromImageList";
import Viewer, { defaultLayout, Worker } from "@phuocng/react-pdf-viewer";
import { StockCardNo } from "./StockCardNo";
import { StockCardPurchaseCriteria } from "./StockCardPurchaseCriteria";
import StockCardRenameCriteria from "./StockCardRenameCriteria";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";
import StockCardSetting from "./StockCardSetting";
import DraggableModal from "../../Components/DraggableModal";
import StockCardRenameCriteria2 from "./StockCardRenameCriteria2";

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};
const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export class StockCardSelectable extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "stockCard",
      modalDeleteShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      modalPdfViewer: false,
      pdfFile: "",
      lastUpdateDate: [],
      showImageViewerModal: false,
      descriptionIdSelectItems: [],
      id: null,
      tableList: [],
      nodes: [], // for cascader.
      unitTypes: [],
      visible: false,
      open: false,
      disabled: true,
      bounds: { left: 0, top: 0, bottom: 0, right: 0 },

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      disabledLinkedDrawings: false,
      linkedDrawingIds: [],
      linkDrawingList: [],
      searchCriteriaModels: [],
      spinLoading: false,
      tableListSettings: []
    };
  }

  formRef = React.createRef();
  formRefstockCardSettingModal = React.createRef();

  fillPurchasingCriteriaUnits = async criteriaId => {
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.setFieldsValue({
        purchasingCriteriaUnitId: null
      });
    }
    if (Boolean(criteriaId)) {
      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getUnitType/" + criteriaId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          purchasingCriteriaUnitSelectItems: Boolean(response.data) ? response.data : []
        });
      }
    } else {
      this.setState({
        purchasingCriteriaUnitSelectItems: []
      });
    }
  };

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
    this.refreshLinkDrawingList();
    fillFilterOperations();
  };

  fillComboboxes = async () => {
    this.fillCode("StockCardCoatingId");
    this.fillCode("StockCardCuttingId");
    this.fillCode("StockCardMillingId");
    this.fillCode("StockCardDrillingId");
    this.fillCode("StockCardItemClassId");
    this.fillCode("purchasingCriteriaId");

    var responseUnits = await handleRequest("GET", "/api/" + this.state.controllerName + "/unitTypes");
    if (responseUnits.type === "ERROR") {
      error(responseUnits);
    } else {
      this.setState({
        unitTypes: Boolean(responseUnits.data) ? responseUnits.data : []
      });
    }
    var responseStandardId = await handleRequest("GET", "/api/" + this.state.controllerName + "/standardId");
    if (responseStandardId.type === "ERROR") {
      error(responseStandardId);
    } else {
      this.setState({
        StockCardStandardIdSelectItems: Boolean(responseStandardId.data) ? responseStandardId.data : []
      });
    }

    var responsecategoryId = await handleRequest("GET", "/api/" + this.state.controllerName + "/category");
    if (responsecategoryId.type === "ERROR") {
      error(responsecategoryId);
    } else {
      this.setState({
        categoryIdSelectItems: Boolean(responsecategoryId.data) ? responsecategoryId.data : []
      });
    }

    var responceDrawing = await handleRequest("GET", "/api/" + this.state.controllerName + "/drawings");
    if (responceDrawing.type === "ERROR") {
      error(responceDrawing);
    } else {
      this.setState({
        drawingIdSelectItems: Boolean(responceDrawing.data) ? responceDrawing.data : []
      });
    }

    var responsedescriptionSelectId = await handleRequest("GET", "/api/" + this.state.controllerName + "/descriptionSelect");
    if (responsedescriptionSelectId.type === "ERROR") {
      error(responsedescriptionSelectId);
    } else {
      this.setState({
        descriptionSelectIdSelectItems: Boolean(responsedescriptionSelectId.data) ? responsedescriptionSelectId.data : []
      });
    }
    var responsetabledescriptionSelect = await handleRequest("GET", "/api/" + this.state.controllerName + "/tableDescription");
    if (responsetabledescriptionSelect.type === "ERROR") {
      error(responsetabledescriptionSelect);
    } else {
      this.setState({
        tabledescriptionSelectItems: Boolean(responsetabledescriptionSelect.data) ? responsetabledescriptionSelect.data : []
      });
    }
    console.log(this.state.tabledescriptionSelectItems);

    var responseweightUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/weightUnitId");
    if (responseweightUnit.type === "ERROR") {
      error(responseweightUnit);
    }
    this.setState({
      weightUnitIdSelectItems: Boolean(responseweightUnit.data) ? responseweightUnit.data : []
    });

    var responsefilterItem = await handleRequest("GET", "/api/" + this.state.controllerName + "/filterItem");
    if (responsefilterItem.type === "ERROR") {
      error(responsefilterItem);
    }
    this.setState({
      filterItemSelectItems: Boolean(responsefilterItem.data) ? responsefilterItem.data : []
    });

    const responseTree = await handleRequest("GET", "/api/" + this.state.controllerName + "/tree");
    if (responseTree.data.length !== 0) {
      generateList(responseTree.data);
      this.setState({
        nodes: responseTree.data
      });
    }

    //this.fillCodeDescription();
  };

  fillCodeDescription = async () => {
    var newObj = {
      itemGroupId: null
    };
    var responsedescriptionId = await handleRequest("POST", "/api/" + this.state.controllerName + "/description", newObj);
    if (responsedescriptionId.type === "ERROR") {
      error(responsedescriptionId);
    } else {
      this.setState({
        descriptionIdSelectItems: Boolean(responsedescriptionId.data) ? responsedescriptionId.data : []
      });
    }
  };

  setItemDescriptionId = async value => {
    this.formRef.current.setFieldsValue({
      descriptionId: value.key,
      descriptionSelectId: value.key
    });

    this.handleEldenItemIdChange();

    this.formRef.current.setFieldsValue({
      descriptionId: value.key,
      descriptionSelectId: value.key
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  fillGrade = async standardId => {
    if (Boolean(standardId) && standardId > 0) {
      var responsegradeId = await handleRequest("GET", "/api/" + this.state.controllerName + "/gradeId/" + standardId);
      if (responsegradeId.type === "ERROR") {
        error(responsegradeId);
      }
      this.setState({
        gradeIdSelectItems: Boolean(responsegradeId.data) ? responsegradeId.data : []
      });
    } else {
      this.setState({
        gradeIdSelectItems: []
      });
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedEldenItem: null,
      descriptionType: "",
      disabledLinkedDrawings: false,
      categoryId: null,
      materialTypeId: null,
      descriptionId: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  edit = async (typeRevise, row) => {
    let linkedDrawingIds = [];
    document.getElementById("kt_scrolltop").click();

    if (Boolean(row.categoryId) && row.categoryId > 0) {
      await this.onChangeItemGroupId(row.categoryId);
    }

    var materialType = dataList.find(p => p.key === row.materialTypeId);
    let materialTypeId = [];
    if (Boolean(materialType)) {
      materialTypeId = materialType.lineage;
    }

    let descriptionId = row.descriptionId;
    if (Boolean(row.descriptionSelectId)) descriptionId = row.descriptionSelectId;

    this.formRef.current.setFieldsValue({
      ...row,
      descriptionId: descriptionId,
      materialTypeId: materialTypeId
    });

    if (Boolean(row.linkedDrawingList))
      row.linkedDrawingList.forEach(element => {
        linkedDrawingIds.push(element.linkedDrawingId);
      });

    await this.handleEldenItemIdChange();
    await this.editDimensionsAndCodeCriteria(row);

    this.formRef.current.setFieldsValue({
      ...row,
      descriptionId: descriptionId,
      materialTypeId: materialTypeId
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      descriptionType: Boolean(row.descriptionId) ? "ADD" : "SELECT",
      linkedDrawingIds: linkedDrawingIds,
      descriptionId: descriptionId,
      categoryId: row.categoryId,
      materialTypeId: Boolean(materialTypeId) && materialTypeId.length > 0 ? materialTypeId[materialTypeId.length - 1] : null
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  active = async row => {
    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/setActive/" + row.id, row);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    var itemDimension = [];
    if (
      Boolean(this.state.selectedEldenItem) &&
      Boolean(this.state.selectedEldenItem.itemDimension) &&
      this.state.selectedEldenItem.itemDimension.length > 0
    ) {
      this.state.selectedEldenItem.itemDimension.forEach(element => {
        let itemDefinition = this.formRef.current.getFieldValue([element.itemDefinition]);
        let unitId = this.formRef.current.getFieldValue([element.itemDefinition + "unitId"]);
        itemDimension.push({
          itemDefinition: element.itemDefinition,
          codeId: itemDefinition,
          unitId: unitId
        });
      });
    }
    newItem.itemDimension = itemDimension;

    var itemCodeCriterias = [];
    if (
      Boolean(this.state.selectedEldenItem) &&
      Boolean(this.state.selectedEldenItem.itemCodeCriterias) &&
      this.state.selectedEldenItem.itemCodeCriterias.length > 0
    ) {
      this.state.selectedEldenItem.itemCodeCriterias.forEach(element => {
        let itemDefinition = this.formRef.current.getFieldValue([element.itemDefinition]);

        itemCodeCriterias.push({
          itemDefinition: element.itemDefinition,
          codeId: itemDefinition
        });
      });
    }
    newItem.itemCodeCriterias = itemCodeCriterias;

    if (Boolean(this.state.selectedEldenItem) && Boolean(this.state.selectedEldenItem.sketchPath)) {
      newItem.sketchPath = this.state.selectedEldenItem.sketchPath;
    }
    newItem.materialTypeId =
      Boolean(values.materialTypeId) && values.materialTypeId.length > 0 ? values.materialTypeId[values.materialTypeId.length - 1] : null;

    if (this.state.descriptionType === "ADD") {
      newItem.descriptionId = values.descriptionId;
      newItem.descriptionSelectId = null;
    } else {
      newItem.descriptionId = null;
      newItem.descriptionSelectId = values.descriptionId;
    }

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      var message = Boolean(response.response) && Boolean(response.response.data) ? response.response.data.message : "";
      if (message === "Stock Card No you must determine") {
        this.setState({
          random: Math.random(),
          modalStockCardNoShow: true
        });
      }
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDastockCardart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDastockCardart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      status: "Active",
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDastockCardart: lastUpdateDastockCardart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          if (Array.isArray(element))
            element.forEach(element2 => {
              let obj = { columns: element2 };
              list.push(obj);
            });
          else {
            let obj = { columns: element };
            list.push(obj);
          }
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/importDynamicaly", list);
          if (response.type === "ERROR") {
            error(response);
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          error("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  handleUploadExcelStockCardAndInventory = async excelJson => {
    try {
      this.setState({ spinLoading: true });
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            ...element
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/importStockCardAndInventory", list);
          if (response.type === "ERROR") {
            error(response);
            this.setState({ spinLoading: false });
          } else {
            let message = response.data;
            if (Boolean(message)) {
              this.setState({ spinLoading: false });
              showError(message);
            } else {
              this.resetInputs();
              const { pagination } = this.state;
              this.restartTable({ pagination });
              showSuccess();
              this.setState({ spinLoading: false });
            }
          }
        } else {
          showError("No records to be added");
          this.setState({ spinLoading: false });
        }
      }
    } catch (error) {
      showError("");
      this.setState({ spinLoading: false });
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  addCodeDescription = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCodeDescription(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  action = record => {
    let editAction = {
      //   name: "Edit",
      name: <FormattedMessage id="StockCard.Edit" defaultMessage="Edit" />,
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this, false)
    };
    let properties = {
      //   name: "Properties",
      name: <FormattedMessage id="StockCard.Properties" defaultMessage="Properties" />,
      icon: <ReplyOutlined fontSize="small" color="action" />
      // actionClick: this.showReplyModal.bind(this)
    };
    let manufacturerInfo = {
      //     name: "Manufacturer info",
      name: <FormattedMessage id="StockCard.Manufacturerinfo" defaultMessage="Manufacturer info" />,
      icon: <ScoreOutlined fontSize="small" color="action" />
      // actionClick: this.showScoringModal.bind(this)
    };
    let active = {
      //    name: "Activate",
      name: <FormattedMessage id="StockCard.Activate" defaultMessage="Activate" />,
      icon: <ReplyOutlined fontSize="small" color="action" />,
      actionClick: this.active.bind(this)
    };

    let deleteAction = {
      //     name: "Delete",
      name: <FormattedMessage id="StockCard.Delete" defaultMessage="Delete" />,
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    if (record.status === "Inactive") {
      actionList.push(active);
    } else {
      actionList.push(editAction);
      actionList.push(properties);
      actionList.push(manufacturerInfo);
      actionList.push(deleteAction);
    }

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  onChangeItemGroupId = async value => {
    if (Boolean(value)) {
      var responseitemDescriptionId = await handleRequest("GET", "/api/stockCard/itemDescriptionIdSelectItems/" + value);
      if (responseitemDescriptionId.type === "ERROR") {
        error(responseitemDescriptionId);
        this.setState({
          descriptionIdSelectItems: [],
          descriptionId: null
        });
        this.formRef.current.setFieldsValue({
          descriptionId: null
        });
      } else {
        this.setState({
          descriptionIdSelectItems: Boolean(responseitemDescriptionId.data) ? responseitemDescriptionId.data : [],
          selectedEldenItem: null,
          descriptionId: null
        });
        this.formRef.current.setFieldsValue({
          descriptionId: null
        });
      }
    } else {
      this.setState({
        descriptionIdSelectItems: [],
        descriptionId: null
      });
      this.formRef.current.setFieldsValue({
        descriptionId: null
      });
    }
  };

  handleEldenItemIdChange = async () => {
    this.formRef.current.setFieldsValue({
      coatingId: null,
      surfaceFinishingId: null,
      standardId: null
    });

    var newObj = {
      materialCategoryId: this.formRef.current.getFieldValue("categoryId"),
      id: this.formRef.current.getFieldValue("descriptionId")
    };
    let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/eldenItem", newObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        selectedEldenItem: response.data,
        descriptionType: Boolean(response.data.descriptionId) ? "ADD" : "SELECT"
      });
      if (Boolean(response.data) && Boolean(response.data.itemDimension) && response.data.itemDimension.length > 0) {
        response.data.itemDimension.forEach(element => {
          this.fillCode("StockCard" + element.itemDefinition);
          this.formRef.current.setFieldsValue({
            [element.itemDefinition]: null
          });
        });
      }
      if (Boolean(response.data) && Boolean(response.data.itemCodeCriterias) && response.data.itemCodeCriterias.length > 0) {
        response.data.itemCodeCriterias.forEach(element => {
          this.fillCode("StockCard" + element.itemDefinition);
          this.formRef.current.setFieldsValue({
            [element.itemDefinition]: null
          });
        });
      }
    }

    // this.handleCodeCriteriaChange();
  };

  editDimensionsAndCodeCriteria = async row => {
    if (Boolean(row.itemDimension) && row.itemDimension.length > 0) {
      row.itemDimension.forEach(element => {
        this.formRef.current.setFieldsValue({
          [element.itemDefinition]: element.codeId,
          [element.itemDefinition + "unitId"]: element.unitId
        });
      });
    }
    if (Boolean(row.itemCodeCriterias) && row.itemCodeCriterias.length > 0) {
      row.itemCodeCriterias.forEach(element => {
        this.formRef.current.setFieldsValue({
          [element.itemDefinition]: element.codeId
        });
      });
    }
  };

  handleCodeCriteriaChange = async () => {
    let materialTypeIds = this.formRef.current.getFieldValue("materialTypeId");
    let materialTypeId = Boolean(materialTypeIds) && materialTypeIds.length > 0 ? materialTypeIds[materialTypeIds.length - 1] : null;

    let standardId = this.formRef.current.getFieldValue("standardId");
    let cuttingId = this.formRef.current.getFieldValue("cuttingId");
    let millingId = this.formRef.current.getFieldValue("millingId");
    let dirllingId = this.formRef.current.getFieldValue("drillingId");
    let coatingId = this.formRef.current.getFieldValue("coatingId");

    let obj = {
      materialTypeId: materialTypeId,
      categoryId: this.formRef.current.getFieldValue("categoryId"),
      descriptionId: this.formRef.current.getFieldValue("descriptionId"),
      standardId: Boolean(standardId) ? standardId : null,
      cuttingId: Boolean(cuttingId) ? cuttingId : null,
      millingId: Boolean(millingId) ? millingId : null,
      dirllingId: Boolean(dirllingId) ? dirllingId : null,
      coatingId: Boolean(coatingId) ? coatingId : null
    };

    var itemDimension = [];
    if (
      Boolean(this.state.selectedEldenItem) &&
      Boolean(this.state.selectedEldenItem.itemDimension) &&
      this.state.selectedEldenItem.itemDimension.length > 0
    ) {
      this.state.selectedEldenItem.itemDimension.forEach(element => {
        let itemDefinition = this.formRef.current.getFieldValue([element.itemDefinition]);
        itemDimension.push({
          itemDefinition: element.itemDefinition,
          codeId: itemDefinition
        });
      });
    }
    obj.itemDimension = itemDimension;

    var itemCodeCriterias = [];
    if (
      Boolean(this.state.selectedEldenItem) &&
      Boolean(this.state.selectedEldenItem.itemCodeCriterias) &&
      this.state.selectedEldenItem.itemCodeCriterias.length > 0
    ) {
      this.state.selectedEldenItem.itemCodeCriterias.forEach(element => {
        let itemDefinition = this.formRef.current.getFieldValue([element.itemDefinition]);

        itemCodeCriterias.push({
          itemDefinition: element.itemDefinition,
          codeId: itemDefinition
        });
      });
    }
    obj.itemCodeCriterias = itemCodeCriterias;

    let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/controlCode", obj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      let list = response.data;
      if (Boolean(list) && list.length > 0) {
        obj.list = list;
        let response2 = await handleRequest("POST", "/api/" + this.state.controllerName + "/controlCodeInList", obj);
        if (response.type === "ERROR") {
          error(response2);
        } else {
          let row = response2.data;

          if (Boolean(row)) {
            var materialType = dataList.find(p => p.key === row.materialTypeId);
            let materialTypeId = [];
            if (Boolean(materialType)) {
              materialTypeId = materialType.lineage;
            }

            let descriptionId = row.descriptionId;
            if (Boolean(row.descriptionSelectId)) descriptionId = row.descriptionSelectId;

            this.formRef.current.setFieldsValue({
              ...row,
              descriptionId: descriptionId,
              materialTypeId: materialTypeId
            });

            await this.handleEldenItemIdChange();
            await this.editDimensionsAndCodeCriteria(row);

            this.formRef.current.setFieldsValue({
              ...row,
              descriptionId: descriptionId,
              materialTypeId: materialTypeId
            });

            this.setState({
              hideUpdate: false,
              hideSave: true,
              hideInputs: false,
              id: row.id,
              descriptionType: Boolean(row.descriptionId) ? "ADD" : "SELECT"
            });
          } else {
            this.formRef.current.setFieldsValue({
              code: null
            });
            this.setState({
              hideUpdate: true,
              hideSave: false,
              id: null
            });
          }
        }
      } else {
        this.formRef.current.setFieldsValue({
          code: null
        });
        this.setState({
          hideUpdate: true,
          hideSave: false,
          id: null
        });
      }
    }
  };

  isAllAttributesFilled = obj => {
    const { selectedEldenItem } = this.state;

    if (!Boolean(selectedEldenItem)) return false;

    if (!Boolean(obj.materialTypeId)) return false;
    if (!Boolean(obj.categoryId)) return false;
    if (!Boolean(obj.descriptionId)) return false;
    if (Boolean(selectedEldenItem.processingCutting) && obj.cuttingId === null) return false;
    if (Boolean(selectedEldenItem.processingMilling) && obj.millingId === null) return false;
    if (Boolean(selectedEldenItem.processingDrilling) && obj.dirllingId === null) return false;
    if (Boolean(selectedEldenItem.processingCoating) && obj.coatingId === null) return false;
    if (Boolean(selectedEldenItem.standard) && obj.standardId === null) return false;
    if (Boolean(selectedEldenItem.itemDimension) && selectedEldenItem.itemDimension.length > 0 && obj.itemDimension === null) return false;
    if (Boolean(selectedEldenItem.itemCodeCriterias) && selectedEldenItem.itemCodeCriterias.length > 0 && obj.itemCodeCriterias === null)
      return false;

    if (
      (Boolean(selectedEldenItem.itemDimension) &&
        selectedEldenItem.itemDimension.length > 0 &&
        obj.itemDimension.length !== selectedEldenItem.itemDimension.length) ||
      (Boolean(selectedEldenItem.itemCodeCriterias) &&
        selectedEldenItem.itemCodeCriterias.length > 0 &&
        obj.itemCodeCriterias.length !== selectedEldenItem.itemCodeCriterias.length)
    )
      return false;

    return true;
  };

  onClickImageViewer = () => {
    this.setState({
      showImageViewerModal: true
    });
  };

  getImageGalleryFromUrls = (paths, key) => {
    const galleryName = "Gallery" + key;
    if (Boolean(paths) && paths.length > 0) {
      let pathList = paths.split(",");
      return (
        <>
          {Boolean(paths) && paths.length > 0 ? (
            <Button
              style={{
                color: "black",
                backgroundColor: "transparent", // Set the background color to transparent
                border: "2px navy-blue",
                width: "100%", // Set the button width to 100% of the cell width
                fontSize: "12px", // Adjust the font size as needed
                textAlign: "center"
              }}
              title="preview"
              width={200}
              preview={{
                visible: false
              }}
              src={pathList[0].trim()}
              onClick={() => this.setState({ [galleryName]: true })}
            >
              <FormattedMessage id="StockCard.Preview" defaultMessage="Preview" />
            </Button>
          ) : null}
          {Boolean(pathList) && pathList.length > 0 ? (
            <div style={{ display: "none" }}>
              <Image.PreviewGroup
                preview={{
                  visible: this.state[galleryName],
                  onVisibleChange: vis => this.setState({ [galleryName]: vis })
                }}
              >
                {pathList.map((field, i) => (
                  <Image key={i} src={field} />
                ))}
              </Image.PreviewGroup>
            </div>
          ) : null}
        </>
      );
    }
  };

  refreshLinkDrawingList = async drawingSearch => {
    const { disciplineId, drawingTypeId } = this.state;

    let obj = {
      disciplineId: disciplineId,
      drawingTypeId: drawingTypeId,
      drawingNo: drawingSearch,
      shtNo: drawingSearch
    };

    const response = await handleRequest("POST", "/api/drawings/search", obj);
    if (Boolean(response.data)) {
      this.setState({
        linkDrawingList: response.data
      });
    } else
      this.setState({
        linkDrawingList: []
      });
  };

  generateReport = async () => {
    let url = "/api/" + this.state.controllerName + "/getReport";
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        pdfFile: response.data,
        modalPdfViewer: true
      });
    } else {
      this.setState({
        pdfFile: null,
        modalPdfViewer: false
      });
      showError("There is no created report for selected item");
    }
  };

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showReport = async url => {
    try {
      const fileRealUrl = url;

      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true
      });
    } catch (error) {
      alert("File cant found.");
    }
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  onStockCardNoClick = () => {
    this.setState({
      random: Math.random(),
      modalStockCardNoShow: true
    });
  };

  onRenameClick = () => {
    this.setState({
      random: Math.random(),
      modalRenameShow: true
    });
  };

  onRenameClick2 = () => {
    this.setState({
      random: Math.random(),
      modalRenameShow2: true
    });
  };

  onPurchaseCriteriaClick = () => {
    this.setState({
      random: Math.random(),
      modalPurchaseCriteriaShow: true
    });
  };

  saveStockCardPurchaseCriteria = () => {
    this.setState({
      modalPurchaseCriteriaShow: false
    });
  };

  saveStockCardRenameCriteria = () => {
    this.setState({
      modalRenameShow: false
    });
  };

  getRenameCriteria = async () => {
    const newItem = {
      categoryId: this.state.categoryId,
      materialTypeId: this.state.materialTypeId
    };
    if (this.state.descriptionType === "ADD") {
      newItem.descriptionId = this.state.descriptionId;
      newItem.descriptionSelectId = null;
    } else {
      newItem.descriptionId = null;
      newItem.descriptionSelectId = this.state.descriptionId;
    }
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/getRenameCriteria", newItem);
    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(this.formRef) && Boolean(this.formRef.current) && Boolean(response.data)) {
        console.log("ZZZ", response.data);
        this.formRef.current.setFieldsValue({
          renameText: response.data
        });
      } else {
        this.formRef.current.setFieldsValue({
          renameText: ""
        });
      }
    }
  };
  onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = this.formRef?.current?.getBoundingClientRect();
    this.setState({
      bounds: {
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y)
      }
    });
  };

  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys: selectedRowKeys,
    });
    if (Boolean(this.props.onSelectChange)) {
      this.props.onSelectChange(selectedRowKeys);
    }
  };

  render() {
    const layoutView = (isSidebarOpened, container, main, toolbar, sidebar) => {
      return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
    };

    const renderToolbar = toolbarSlot => {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: "center"
            }}
          >
            <div style={{ padding: "0 2px" }}>{toolbarSlot.previousPageButton}</div>
            <div style={{ padding: "0 2px" }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.nextPageButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomOutButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomPopover}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.zoomInButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.printButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.downloadButton}</div>
            <div style={{ padding: "0 2px" }}>{toolbarSlot.moreActionsPopover}</div>
          </div>
        </div>
      );
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const columns = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="StockCard.ItemCategory" defaultMessage="Item Category" />,
        key: "categoryId",
        width: 150,
        render: record => {
          return record.categoryName;
        },
        editable: true,
        ...getColumnFilter({
          inputName: "categoryId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["filterItemSelectItems"]) ? this.state["filterItemSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="StockCard.ItemType" defaultMessage="Item Type" />,
        key: "materialTypeId",
        width: 150,
        ellipsis: true,
        render: record => {
          return record.materialTypeName;
        },
        ...getColumnFilter({
          inputName: "materialTypeId",
          inputType: "CASCADER",
          selectItems: Boolean(this.state["nodes"]) ? this.state["nodes"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="StockCard.ItemDescription" defaultMessage="Item Description" />,
        key: "descriptionId",
        width: 150,
        render: record => {
          return record.descriptionName;
        },
        ...getColumnFilter({
          inputName: "descriptionSelectId",
          inputType: "SELECT",
          selectItems: Boolean(this.state["tabledescriptionSelectItems"]) ? this.state["tabledescriptionSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="StockCard.Rename" defaultMessage="Rename" />,
        key: "renameText",
        width: 150,
        ellipsis: true,
        render: record => {
          return <Tooltip title={record.renameText}>{record.renameText}</Tooltip>;
        },
        ...getColumnFilter({
          inputName: "renameText",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="StockCard.Abbreviation" defaultMessage="Abbreviation" />,
        key: "abbreviation",
        width: 150,
        render: record => {
          return record.abbreviation;
        },
        ...getColumnFilter({
          inputName: "abbreviation",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="StockCard.ItemCode" defaultMessage="Item Code" />,
        key: "code",
        width: 100,
        render: record => {
          return record.code;
        },
        ...getColumnFilter({
          inputName: "code",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="StockCard.Sketch" defaultMessage="Sketch" />,
        key: "sketchPath",
        width: 150,
        render: record => {
          return this.getImageGalleryFromUrls(record.sketchPath, record.id);
        }
      },

      {
        title: <FormattedMessage id="StockCard.Dimension" defaultMessage="Dimension" />,
        key: "dimensionName",
        width: 150,
        ellipsis: true,
        render: record => {
          return <Tooltip title={record.dimensionName}>{record.dimensionName}</Tooltip>;
        }
      },
      {
        title: <FormattedMessage id="StockCard.CodeCriterias" defaultMessage="Code Criterias" />,
        key: "codeCriterias",
        width: 150,
        render: record => {
          return (
            <Button type="primary" onClick={() => this.setState({ modalCodeCriterias: true })}>
              <FormattedMessage id="StockCard.Preview" defaultMessage="Preview" />
            </Button>
          );
        }
      },
      // {
      //   title: <FormattedMessage id="StockCard.Coating" defaultMessage="Coating" />,
      //   key: "coatingId",
      //   width: 150,
      //   render: record => {
      //     return record.coatingName;
      //   },
      //   ...getColumnFilter({
      //     inputName: "coatingId",
      //     inputType: "SELECT_ADDABLE",
      //     selectItems: Boolean(this.state["StockCardCoatingIdSelectItems"]) ? this.state["StockCardCoatingIdSelectItems"] : [],
      //     filterChangeItem: this.filterChangeItem,
      //     searchCriteriaModels: this.state.searchCriteriaModels
      //   })
      // },
      // {
      //   title: <FormattedMessage id="StockCard.SurfaceFinishing" defaultMessage="Surface Finishing" />,
      //   key: "surfaceFinishingName",
      //   width: 150,
      //   render: record => {
      //     return record.surfaceFinishingName;
      //   }
      // },
      // {
      //   title: <FormattedMessage id="StockCard.Standard" defaultMessage="Standard" />,
      //   key: "standardId",
      //   width: 150,
      //   render: record => {
      //     return record.standardName;
      //   },
      //   ...getColumnFilter({
      //     inputName: "standardId",
      //     inputType: "SELECT",
      //     selectItems: Boolean(this.state["StockCardStandardIdSelectItems"]) ? this.state["StockCardStandardIdSelectItems"] : [],
      //     filterChangeItem: this.filterChangeItem,
      //     searchCriteriaModels: this.state.searchCriteriaModels
      //   })
      // },
      // {
      //   title: <FormattedMessage id="StockCard.Grade" defaultMessage="Grade" />,
      //   key: "gradeId",
      //   width: 150,
      //   render: record => {
      //     return record.gradeName;
      //   },
      //   ...getColumnFilter({
      //     inputName: "gradeId",
      //     inputType: "SELECT",
      //     selectItems: Boolean(this.state["StockCardGradeIdSelectItems"]) ? this.state["StockCardGradeIdSelectItems"] : [],
      //     filterChangeItem: this.filterChangeItem,
      //     searchCriteriaModels: this.state.searchCriteriaModels
      //   })
      // },
      // {
      //   title: <FormattedMessage id="StockCard.Properties" defaultMessage="Properties" />,
      //   key: "properties",
      //   width: 150,
      //   render: record => {
      //     return "";
      //   }
      // },
      // {
      //   title: <FormattedMessage id="StockCard.Vendor" defaultMessage="Vendor" />,
      //   key: "vendor",
      //   width: 150,
      //   render: record => {
      //     return "";
      //   }
      // },
      {
        title: <FormattedMessage id="StockCard.IssueDate" defaultMessage="Issue Date" />,
        key: "issueDate",
        width: 150,
        render: record => {
          return Boolean(record.issueDate) ? moment(record.issueDate).format("DD-MM-YYYY HH:mm") : "";
        }
      },
      {
        title: <FormattedMessage id="StockCard.Status" defaultMessage="Status" />,
        key: "status",
        width: 150,
        render: record => {
          return Boolean(record.status) && <Tag color={record.status === "Active" ? "green" : "red"}>{record.status}</Tag>;
        }
      },
      {
        title: <FormattedMessage id="StockCard.LastUpdate" defaultMessage="Last Update" />,
        key: "lastUpdated",
        width: 150,
        render: record => {
          return moment(record.lastUpdated).format("DD-MM-YYYY HH:mm");
        },
        ...getColumnFilter({
          inputName: "lastUpdated",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];

      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
        </Form>

        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span>
                      <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span>
                      <span className="mr-2">{val.showValue}</span>
                      <span>
                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}

          <Table
            rowSelection={rowSelection}
            rowKey={record => record.id}
            scroll={{ x: 900, y: 400, scrollToFirstRowOnChange: true }}
            columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            components={{
              header: {
                cell: ResizableTitle
              }
            }}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>
        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfViewer}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <AntModal
          width={1000}
          title={"Select an Image"}
          visible={this.state.showImageViewerModal}
          // okButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ showImageViewerModal: false });
            // this.resetInputs();
          }}
          onOk={() => {
            this.setState({ showImageViewerModal: false });
          }}
        >
          {Boolean(this.state.descriptionIdSelectItems) && (
            <SelectFromImageList data={this.state.descriptionIdSelectItems} setSelected={this.setItemDescriptionId}></SelectFromImageList>
          )}
        </AntModal>

        <AntModal
          open={this.state.modalStockCardNoShow}
          onCancel={() => {
            this.setState({ modalStockCardNoShow: false });
          }}
          width={1500}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalStockCardNoShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
        >
          <Badge.Ribbon
            text={<FormattedMessage id="StockCardNo.purchaseOrderNo" defaultMessage="Stock Card No" />}
            placement="start"
            color="purple"
            style={{ fontSize: 12 }}
          >
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <StockCardNo
                random={this.state.random}
                categoryId={this.state.categoryId}
                descriptionId={this.state.descriptionId}
                materialTypeId={this.state.materialTypeId}
                descriptionType={this.state.descriptionType}
              />
            </div>
          </Badge.Ribbon>
        </AntModal>

        <AntModal
          open={this.state.modalRenameShow}
          onCancel={() => {
            this.setState({ modalRenameShow: false });
          }}
          title={
            <Badge.Ribbon
              text={<FormattedMessage id="StockCardNo.RenameSettings" defaultMessage="Rename Settings" />}
              placement="start"
              color="purple"
              style={{ fontSize: 12 }}
            >
              {" "}
            </Badge.Ribbon>
          }
          width={1000}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalRenameShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
        >
          <div className="card card-custom" style={{ minHeight: "100%" }}>
            <StockCardRenameCriteria
              random={this.state.random}
              descriptionId={this.state.descriptionId}
              descriptionType={this.state.descriptionType}
              categoryId={this.state.categoryId}
              saveStockCardRenameCriteria={this.saveStockCardRenameCriteria}
            />
          </div>
        </AntModal>

        <AntModal
          open={this.state.modalRenameShow}
          onCancel={() => {
            this.setState({ modalRenameShow: false });
          }}
          title={
            <Badge.Ribbon
              text={<FormattedMessage id="StockCardNo.RenameSettings" defaultMessage="Rename Settings" />}
              placement="start"
              color="purple"
              style={{ fontSize: 12 }}
            >
              {" "}
            </Badge.Ribbon>
          }
          width={1000}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalRenameShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
        >
          <div className="card card-custom" style={{ minHeight: "100%" }}>
            <StockCardRenameCriteria
              random={this.state.random}
              descriptionId={this.state.descriptionId}
              descriptionType={this.state.descriptionType}
              categoryId={this.state.categoryId}
              saveStockCardRenameCriteria={this.saveStockCardRenameCriteria}
            />
          </div>
        </AntModal>

        <AntModal
          open={this.state.modalRenameShow2}
          onCancel={() => {
            this.setState({ modalRenameShow2: false });
          }}
          title={
            <Badge.Ribbon
              text={<FormattedMessage id="StockCardNo.RenameSettings" defaultMessage="Rename Settings" />}
              placement="start"
              color="purple"
              style={{ fontSize: 12 }}
            >
              {" "}
            </Badge.Ribbon>
          }
          width={1000}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalRenameShow2: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
        >
          <div className="card card-custom" style={{ minHeight: "100%" }}>
            <StockCardRenameCriteria2
              random={this.state.random}
              descriptionId={this.state.descriptionId}
              descriptionType={this.state.descriptionType}
              categoryId={this.state.categoryId}
              saveStockCardRenameCriteria={this.saveStockCardRenameCriteria}
            />
          </div>
        </AntModal>

        <DraggableModal
          open={this.state.modalPurchaseCriteriaShow}
          onCancel={() => {
            this.setState({ modalPurchaseCriteriaShow: false });
          }}
          width={800}
          centered
          footer={[
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modalPurchaseCriteriaShow: false });
              }}
            >
              <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
            </Button>
          ]}
          content={
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <StockCardPurchaseCriteria
                random={this.state.random}
                categoryId={this.state.categoryId}
                descriptionId={this.state.descriptionId}
                descriptionType={this.state.descriptionType}
                saveStockCardPurchaseCriteria={this.saveStockCardPurchaseCriteria}
              />
            </div>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="StockCard.CodeCriterias" defaultMessage="Code Criterias" />}
          centered
          width={1000}
          open={this.state.modalCodeCriterias}
          // onOk={() => this.projectStatusSave}
          onCancel={() => this.setState({ modalCodeCriterias: false })}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          content={123}
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StockCardSelectable);