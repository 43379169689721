import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Select } from "antd";
import { Modal as AntModal } from "antd";
import { Input } from "antd";
import { InputNumber } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, MinusCircleOutlined, PlusOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const { Option } = Select;

export class ActivityPlanningTableChild extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ActivityPlanningTableChild",
      controllerName: "activityPlanningDetail",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      modalPersonnelList: [],
      modalEquipmentList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };
  fillComboboxes = async () => {
    var responseunitGroupId = await handleRequest("GET", "/api/" + this.state.controllerName + "/unitGroupId");
    if (responseunitGroupId.type === "ERROR") {
      error(responseunitGroupId);
    }
    this.setState({
      unitGroupIdSelectItems: Boolean(responseunitGroupId.data) ? responseunitGroupId.data : []
    });
    var responseactivityPeriod = await handleRequest("GET", "/api/" + this.state.controllerName + "/activityPeriod");
    if (responseactivityPeriod.type === "ERROR") {
      error(responseactivityPeriod);
    }
    this.setState({
      activityPeriodSelectItems: Boolean(responseactivityPeriod.data) ? responseactivityPeriod.data : []
    });
    var responsematerialId = await handleRequest("GET", "/api/" + this.state.controllerName + "/materialId");
    if (responsematerialId.type === "ERROR") {
      error(responsematerialId);
    }
    this.setState({
      materialIdSelectItems: Boolean(responsematerialId.data) ? responsematerialId.data : []
    });
    var responsequantityUnitId = await handleRequest("GET", "/api/" + this.state.controllerName + "/quantityUnitId");
    if (responsequantityUnitId.type === "ERROR") {
      error(responsequantityUnitId);
    }
    this.setState({
      quantityUnitIdSelectItems: Boolean(responsequantityUnitId.data) ? responsequantityUnitId.data : []
    });
    var responsedesignationId = await handleRequest("GET", "/api/" + this.state.controllerName + "/designationId");
    if (responsedesignationId.type === "ERROR") {
      error(responsedesignationId);
    }
    this.setState({
      designationIdSelectItems: Boolean(responsedesignationId.data) ? responsedesignationId.data : []
    });
    var responseequipmentId = await handleRequest("GET", "/api/" + this.state.controllerName + "/equipmentId");
    if (responseequipmentId.type === "ERROR") {
      error(responseequipmentId);
    }
    this.setState({
      equipmentIdSelectItems: Boolean(responseequipmentId.data) ? responseequipmentId.data : []
    });

    // var responseqtyUnitId = await handleRequest("GET", "/api/" + this.state.controllerName + "/qtyUnitId");
    // if (responseqtyUnitId.type === "ERROR") {
    //   error(responseqtyUnitId);
    // }
    // this.setState({
    //   qtyUnitIdSelectItems: Boolean(responseqtyUnitId.data) ? responseqtyUnitId.data : []
    // });
  };

  fillUnit = async (unitGroupId) => {
    if (Boolean(unitGroupId) && unitGroupId > 0) {
      var responseunitId = await handleRequest("GET", "/api/" + this.state.controllerName + "/unitId/" + unitGroupId);
      if (responseunitId.type === "ERROR") {
        error(responseunitId);
      }
      this.setState({
        unitIdSelectItems: Boolean(responseunitId.data) ? responseunitId.data : []
      });
    } else {
      this.setState({
        unitIdSelectItems: []
      });
    }
  };


  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      activityPlanningId: this.props.selectedActivityPlanningTableChild.id,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };



    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);



    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      activityPlanningId: this.props.selectedActivityPlanningTableChild.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  personnelModal = async row => {
    this.setState({
      modalPersonnelShow: true,
      modalPersonnelList: row.activityPlanningPersonnel
    });
  };
  equipmentModal = async row => {
    this.setState({
      modalEquipmentShow: true,
      modalEquipmentList: row.activityPlanningEquipment
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };


    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.unit" defaultMessage="unit" />,
        key: "unit",
        render: record => {
          return record.activityUnit;
        }, ...getColumnFilter({
          inputName: "unit", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.Period" defaultMessage="Period" />,
        key: "activityPeriod",
        render: record => {
          if (Boolean(record.activityPeriod) && Boolean(record.activityPeriodType)) {
            return record.activityPeriod + " " + record.activityPeriodType;
          }
        }, ...getColumnFilter({
          inputName: "activityPeriod", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.Material" defaultMessage="Material" />,
        key: "material",
        render: record => {
          return record.material;
        }, ...getColumnFilter({
          inputName: "material", inputType: "BUTTON",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.personnel" defaultMessage="personnel" />,
        key: "id",
        render: record => {
          return (
            <AntButton
              onClick={() => this.personnelModal(record)}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ZoomInOutlined fontSize="large" />}
            ></AntButton>
          );
        }
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.equipment" defaultMessage="equipment" />,
        key: "equipment",
        render: record => {
          return (
            <AntButton
              onClick={() => this.equipmentModal(record)}
              style={{ border: "0px", color: "#1BC5BD" }}
              icon={<ZoomInOutlined fontSize="large" />}
            ></AntButton>
          );
        }, ...getColumnFilter({
          inputName: "equipment", inputType: "BUTTON",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
    ];

    const columnsPersonnelList = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.Designation" defaultMessage="Designation" />,
        key: "id",
        render: record => {
          return record.designationName;
        },
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.Responsibility" defaultMessage="Responsibility" />,
        key: "id",
        render: record => {
          return record.responsibility;
        },
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.NumberOfPersonnel" defaultMessage="Number Of Personnel" />,
        key: "id",
        render: record => {
          return record.numberOfPersonnel;
        },
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.WorkingTime" defaultMessage="Working Time" />,
        key: "id",
        render: record => {
          return record.workingTime;
        },
      },
    ];

    const columnsEquipmentList = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.equipmentId" defaultMessage="Equipment" />,
        key: "id",
        render: record => {
          return record.equipmentName;
        },
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.quantity" defaultMessage="Quantity" />,
        key: "id",
        render: record => {
          return record.quantity;
        },
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.quantityUnit" defaultMessage="Quantity Unit" />,
        key: "id",
        render: record => {
          return record.quantityUnitName;
        },
      },
      {
        title: <FormattedMessage id="ActivityPlanningTableChild.WorkingTime" defaultMessage="Working Time" />,
        key: "id",
        render: record => {
          return record.workingTime;
        },
      },
    ];

    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="ActivityPlanningTableChild" hideElement={true}>             <ExcelSheet name="ActivityPlanningTableChild" data={this.state.tableList}>               <ExcelColumn label="unit" value="unit" />
        <ExcelColumn label="period" value="period" />
        <ExcelColumn label="material" value="material" />
        <ExcelColumn label="personnel" value="personnel" />
        <ExcelColumn label="equipment" value="equipment" />
      </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel}
        icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)} */}
    </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="activityPlanningChild.pageTitle" defaultMessage="Detail of Activity" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>             <Col md={21}></Col>             <Col md={1}>{excelExport}</Col>             <Col md={1}>               <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>             </Col>             <Col md={1}>               <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />}               ></AntButton>               <AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />}               ></AntButton>             </Col>           </Row>
            <div hidden={this.state.hideInputs}>

              <Form.Item
                {...layout2}
                label={<FormattedMessage id="ActivityPlanningTableChild.activityUnit" defaultMessage="Activity Unit" />}
              >
                <Row gutter={8}>
                  <Col span={2}>
                    <Form.Item
                      name="activityUnit"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      extra={<FormattedMessage id="ActivityPlanningTableChild.activityUnit" defaultMessage="Activity Unit" />}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="unitGroupId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      extra={<FormattedMessage id="ActivityPlanningTableChild.UnitGroup" defaultMessage="Unit Group" />}
                    >
                      <Select allowClear
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        onChange={value => {
                          this.fillUnit(value);
                        }}
                      >
                        {Boolean(this.state["unitGroupIdSelectItems"]) && this.state["unitGroupIdSelectItems"].length > 0 &&
                          this.state["unitGroupIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="unitId"
                      rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      extra={<FormattedMessage id="ActivityPlanningTableChild.Unit" defaultMessage="Unit" />}
                    >
                      <Select allowClear
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                      >
                        {Boolean(this.state["unitIdSelectItems"]) && this.state["unitIdSelectItems"].length > 0 &&
                          this.state["unitIdSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}{" "}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>





              <Form.Item
                {...layout}
                label={<FormattedMessage id="ActivityPlanningTableChild.activityPeriod" defaultMessage="Activity Period" />}
                name="activityPeriodType"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                >
                  {Boolean(this.state["activityPeriodSelectItems"]) && this.state["activityPeriodSelectItems"].length > 0 &&
                    this.state["activityPeriodSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ActivityPlanningTableChild.period" defaultMessage="Period" />}
                  name="activityPeriod"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }


              <Form.List name="activityPlanningMaterial">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        {...layout2}
                        key={field.key}
                        label={"Material"}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, "materialId"]}
                          extra={<FormattedMessage id="ActivityPlanningTableChild.Material" defaultMessage="Material" />}
                          style={{
                            display: "inline-block",
                            width: "calc(20%)",
                            marginBottom: "5px",
                            marginLeft: "5px"
                          }}
                          rules={[
                            { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                          ]}
                        >
                          <Select allowClear
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                          >
                            {Boolean(this.state["materialIdSelectItems"]) && this.state["materialIdSelectItems"].length > 0 &&
                              this.state["materialIdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}{" "}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "quantity"]}
                          extra={<FormattedMessage id="ActivityPlanningTableChild.Quantity" defaultMessage="Quantity" />}
                          style={{
                            display: "inline-block",
                            width: "calc(20%)",
                            marginBottom: "5px",
                            marginLeft: "5px"
                          }}
                          rules={[
                            { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                          ]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "quantityUnitId"]}
                          extra={<FormattedMessage id="ActivityPlanningTableChild.quantityUnitId" defaultMessage="Quantity Unit" />}
                          style={{
                            display: "inline-block",
                            width: "calc(20%)",
                            marginBottom: "5px",
                            marginLeft: "5px"
                          }}
                          rules={[
                            { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                          ]}
                        >
                          <Select allowClear
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                          >
                            {Boolean(this.state["quantityUnitIdSelectItems"]) && this.state["quantityUnitIdSelectItems"].length > 0 &&
                              this.state["quantityUnitIdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}{" "}
                          </Select>
                        </Form.Item>

                        <Form.Item {...field} hidden name={[field.name, "id"]}>
                          <InputNumber></InputNumber>
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                      </Form.Item>
                    ))}

                    <Form.Item>
                      <Row gutter={[16, 16]}>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}></Col>
                        <Col md={4}></Col>
                        <Col md={6}>
                          <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New Material" />
                          </AntButton>
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Form.List name="activityPlanningPersonnel">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        {...layout2}
                        key={field.key}
                        label={"Personnel"}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, "designationId"]}
                          extra={<FormattedMessage id="ActivityPlanningTableChild.Designation" defaultMessage="Designation" />}
                          style={{
                            display: "inline-block",
                            width: "calc(20%)",
                            marginBottom: "5px",
                            marginLeft: "5px"
                          }}
                          rules={[
                            { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                          ]}
                        >
                          <Select allowClear
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                          >
                            {Boolean(this.state["designationIdSelectItems"]) && this.state["designationIdSelectItems"].length > 0 &&
                              this.state["designationIdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}{" "}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "responsibility"]}
                          extra={<FormattedMessage id="ActivityPlanningTableChild.Responsibility" defaultMessage="Responsibility" />}
                          style={{
                            display: "inline-block",
                            width: "calc(20%)",
                            marginBottom: "5px",
                            marginLeft: "5px"
                          }}
                          rules={[
                            { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                          ]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "numberOfPersonnel"]}
                          extra={<FormattedMessage id="ActivityPlanningTableChild.NumberOfPersonnel" defaultMessage="Number Of Personnel" />}
                          style={{
                            display: "inline-block",
                            width: "calc(20%)",
                            marginBottom: "5px",
                            marginLeft: "5px"
                          }}
                          rules={[
                            { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                          ]}
                        >
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "workingTime"]}
                          extra={<FormattedMessage id="ActivityPlanningTableChild.WorkingTime" defaultMessage="Working Time" />}
                          style={{
                            display: "inline-block",
                            width: "calc(20%)",
                            marginBottom: "5px",
                            marginLeft: "5px"
                          }}
                          rules={[
                            { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                          ]}
                        >
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item {...field} hidden name={[field.name, "id"]}>
                          <InputNumber></InputNumber>
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                      </Form.Item>
                    ))}

                    <Form.Item>
                      <Row gutter={[16, 16]}>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}></Col>
                        <Col md={4}></Col>
                        <Col md={6}>
                          <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New Personnel" />
                          </AntButton>
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                )}
              </Form.List>


              <Form.List name="activityPlanningEquipment">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        {...layout2}
                        key={field.key}
                        label={"Equipment"}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, "equipmentId"]}
                          extra={<FormattedMessage id="ActivityPlanningTableChild.Equipment" defaultMessage="Equipment" />}
                          style={{
                            display: "inline-block",
                            width: "calc(20%)",
                            marginBottom: "5px",
                            marginLeft: "5px"
                          }}
                          rules={[
                            { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                          ]}
                        >
                          <Select allowClear
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                          >
                            {Boolean(this.state["equipmentIdSelectItems"]) && this.state["equipmentIdSelectItems"].length > 0 &&
                              this.state["equipmentIdSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}{" "}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "quantity"]}
                          extra={<FormattedMessage id="ActivityPlanningTableChild.Quantity" defaultMessage="Quantity" />}
                          style={{
                            display: "inline-block",
                            width: "calc(20%)",
                            marginBottom: "5px",
                            marginLeft: "5px"
                          }}
                          rules={[
                            { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                          ]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "qtyyunit"]}
                          extra={<FormattedMessage id="ActivityPlanningTableChild.qtyyUnit" defaultMessage="Quantity Unit" />}
                          style={{
                            display: "inline-block",
                            width: "calc(20%)",
                            marginBottom: "5px",
                            marginLeft: "5px"
                          }}
                          rules={[
                            { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                          ]}
                        >
                          <Select allowClear
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                          >
                            {Boolean(this.state["qtyyunitSelectItems"]) && this.state["qtyyunitSelectItems"].length > 0 &&
                              this.state["qtyyunitSelectItems"].map(i => (
                                <Option key={i.key} value={i.key}>
                                  {i.value}
                                </Option>
                              ))}{" "}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "workingTime"]}
                          extra={<FormattedMessage id="ActivityPlanningTableChild.WorkingTime" defaultMessage="Working Time" />}
                          style={{
                            display: "inline-block",
                            width: "calc(20%)",
                            marginBottom: "5px",
                            marginLeft: "5px"
                          }}
                          rules={[
                            { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> },
                          ]}
                        >
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item {...field} hidden name={[field.name, "id"]}>
                          <InputNumber></InputNumber>
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                      </Form.Item>
                    ))}

                    <Form.Item>
                      <Row gutter={[16, 16]}>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}></Col>
                        <Col md={4}></Col>
                        <Col md={6}>
                          <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New Equipment" />
                          </AntButton>
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                )}
              </Form.List>


              <div hidden={this.state.hideSave}>
                {<Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {<Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }}
                      variant="secondary"
                      onClick={this.cancel}
                    >
                      <FormattedMessage
                        id="GeneralButtonCancel"
                        defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }}
                      variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>}
              </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 &&
            (<div className="alert alert-custom alert-light">                 <div className="alert-icon">
              <FilterOutlined />
            </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i}
                      className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">
                        {val.label}
                      </span>
                      <strong className="mr-2">
                        {val.type}
                      </strong>
                      <span className="mr-2">
                        {val.operationValue}
                      </span>
                      <span className="mr-2">
                        {val.showValue}
                      </span>
                      <span>
                        <Tooltip title="Remove">

                          <CloseCircleOutlined style={{ color: "crimson" }}
                            onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>);
                }
                )
                }
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() => this.setState({
                      searchCriteriaModels: []
                    }, () => {
                      this.filterChangeItem([]);
                    })} />
                </Tooltip>
              </div>
            </div>)}

          <div style={{
            marginTop: "2rem",
            marginBottom: "1rem"
          }} className="content-section implementation"
          >
            <Table
              bordered scroll={{
                x: 900, scrollToFirstRowOnChange: true
              }} columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination} />
          </div>

          <Modal show={this.state.modalDeleteShow}
            onHide={this.showOrHideDeleteModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered>           <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage
                  id="GeneralMessageChosenRecordWillBeDeleted"
                  defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>           </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage
                  id="GeneralMessageAreYouSure"
                  defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary"
                onClick={this.showOrHideDeleteModal}>
                <FormattedMessage
                  id="GeneralButtonCancel"
                  defaultMessage="Cancel" />
              </Button>
              <Button variant="danger"
                onClick={this.delete}>
                <FormattedMessage
                  id="GeneralButtonDelete"
                  defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <AntModal
            title={<FormattedMessage id="ActivityPlanningTableChild.PersonnelList" defaultMessage="Personnel List" />}
            closable="false"
            open={this.state.modalPersonnelShow}
            onCancel={() => {
              this.setState({ modalPersonnelShow: false });
            }}
            width={1000}
            centered
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalPersonnelShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
          >
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Table
                bordered
                size="small"
                columns={columnsPersonnelList}
                dataSource={this.state.modalPersonnelList}
                loading={this.state.loading}
                pagination={false}
              />
            </div>
          </AntModal>
          <AntModal
            title={<FormattedMessage id="ActivityPlanningTableChild.EquipmentList" defaultMessage="Equipment List" />}
            closable="false"
            open={this.state.modalEquipmentShow}
            onCancel={() => {
              this.setState({ modalEquipmentShow: false });
            }}
            width={1000}
            centered
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalEquipmentShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
          >
            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Table
                bordered
                size="small"
                columns={columnsEquipmentList}
                dataSource={this.state.modalEquipmentList}
                loading={this.state.loading}
                pagination={false}
              />
            </div>
          </AntModal>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ActivityPlanningTableChild);
