import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { FilterOutlined, LoadingOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined, NoteAddOutlined, ShoppingCartOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import RevisionIcon from "@material-ui/icons/ViewList";
import {
  Form,
  Table,
  Input,
  Select,
  Timeline,
  InputNumber,
  DatePicker,
  Button as AntButton,
  Col,
  Row,
  Progress,
  Space,
  Tag,
  Steps,
  Tooltip,
  Spin,
  Card
} from "antd";
import moment from "moment";
import { handleRequest } from "../../ApiConnector";
import CreateIcon from "@material-ui/icons/AddCircle";

import { Badge } from "antd";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import { evaluate, round } from "mathjs";
import "../../assets/style/resizeTable.css";
import { Resizable } from "react-resizable";
import DraggableModal from "../../Components/DraggableModal";
import { UserOutlined, PlusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Step } = Steps;
const { TextArea } = Input;

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

function createOperator(operator) {
  switch (operator) {
    case "add":
      return "+";
    case "subtract":
      return "-";
    case "multiply":
      return "*";
    case "divide":
      return "/";
    case "sqrt":
      return "sqrt";
    case "pow":
      return "^";
    case "open_parenthesis":
      return "(";
    case "close_parenthesis":
      return ")";
    default:
      return "";
  }
}

//#region editibleCell
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate"
      ? form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? moment(record[dataIndex], "DD-MM-YYYY") : ""
      })
      : form.setFieldsValue({
        [dataIndex]: Boolean(record[dataIndex]) ? record[dataIndex] : ""
      });
  };

  const toggleSave = async () => {
    try {
      if (dataIndex === "planedDeliveryDate") {
        const values = await form.validateFields();
        values.planedDeliveryDate = Boolean(values.planedDeliveryDate) ? moment(values.planedDeliveryDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else if (dataIndex === "paymentDate") {
        const values = await form.validateFields();
        values.paymentDate = Boolean(values.paymentDate) ? moment(values.paymentDate).format("DD-MM-YYYY") : null;
        toggleEdit();
        handleSave({ ...record }, { ...values });
      } else {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record }, { ...values });
      }
    } catch (errInfo) { }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <>
        {dataIndex === "deliveryPlace" ? (
          <>
            <Form.Item
              label={<FormattedMessage id="POVendorReply.town" defaultMessage="Town" />}
              name="town"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Form.Item
              label={<FormattedMessage id="POVendorReply.city" defaultMessage="City" />}
              name="city"
              rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
            >
              <Input ref={inputRef} />
            </Form.Item>

            <Button id="SaveButton" onClick={toggleSave} style={{ width: "100%" }} variant="success">
              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
            </Button>
          </>
        ) : (
          <Form.Item
            style={{
              margin: 0
            }}
            name={dataIndex}
          >
            {dataIndex === "planedDeliveryDate" || dataIndex === "paymentDate" ? (
              <DatePicker ref={inputRef} onBlur={toggleSave} format={"DD-MM-YYYY"} />
            ) : dataIndex === "unit" ? (
              <Select ref={inputRef} onChange={toggleSave} onBlur={toggleSave} showSearch optionFilterProp="children">
                {Boolean(record.unitSelectItems) &&
                  record.unitSelectItems.length > 0 &&
                  record.unitSelectItems.map(i => (
                    <Option key={i.id} value={i.id}>
                      {i.abbreviation}
                    </Option>
                  ))}
              </Select>
            ) : (
              <InputNumber
                parser={value => value.replace(",", ".")}
                formatter={value => value.replace(".", ",")}
                min={0}
                ref={inputRef}
                onPressEnter={toggleSave}
                onBlur={toggleSave}
              />
            )}
          </Form.Item>
        )}
      </>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
//#endregion

export class ProjectTakeOffQuantity extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "projectTakeOffQuantity",
      tableName: "ProjectTakeOffQuantity",
      modalDeleteShow: false,
      modalSendSelectShow: false,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      selectedItemBreakdown: null,
      itemGroupId: null,
      showModalProcurementProgress: false,
      id: null,
      selectedStockCard: null,
      tableList: [],
      tableListEquivalent: [],
      modalHistoryShow: false,
      historyList: [],
      filePath: "",
      fileList: [],
      nodes: [], // for cascader.
      codeSelectItems: null,
      disciplineIdSelectItems: null,
      locationIdSelectItems: null,
      inventoryLogs: "0",
      unitSelectItems: [],
      purchasingCriteriaSelectItems: [],
      purchasingCriteriaUnitSelectItems: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      paginationEquivalent: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",

      excelData: [],
      searchCriteriaModels: [],
      saveLoading: false
    };
  }

  formRef = React.createRef();
  formRefNote = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    let responseUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/getUnitType");
    if (responseUnit.type === "ERROR") {
      error(responseUnit);
    }
    this.setState(
      {
        selectedItemBreakdown: Boolean(this.props) && Boolean(this.props.selectedItemBreakdown) ? this.props.selectedItemBreakdown : null,
        unitSelectItems: Boolean(responseUnit.data) && responseUnit.data.length > 0 ? responseUnit.data : []
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
        this.fillComboboxes();
        fillFilterOperations();
      }
    );
  };

  fillPurchasingCriterias = async edit => {
    const { selectedStockCard } = this.state;
    let responsePurchaseCriteria = await handleRequest("GET", "/api/" + this.state.controllerName + "/purchasingcriterias/" + selectedStockCard.id);
    if (responsePurchaseCriteria.type === "ERROR") {
      error(responsePurchaseCriteria);
    } else {
      let purchasingCriteriaSelectItems = [];
      let purchasingCriteriaId = null;
      if (Boolean(responsePurchaseCriteria.data) && responsePurchaseCriteria.data.length > 0) {
        purchasingCriteriaSelectItems = responsePurchaseCriteria.data;
        purchasingCriteriaId = responsePurchaseCriteria.data[0].key;
      }
      this.setState({
        purchasingCriteriaSelectItems: purchasingCriteriaSelectItems
      });

      if (!Boolean(edit) && Boolean(this.formRef) && Boolean(this.formRef.current)) {
        this.formRef.current.setFieldsValue({
          purchasingCriteriaId: purchasingCriteriaId
        });

        if (Boolean(purchasingCriteriaId)) {
          this.fillPurchasingCriteriaUnits(purchasingCriteriaId);
        }
      }
    }
  };

  fillPurchasingCriteriaUnits = async (criteriaId, edit) => {
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.setFieldsValue({
        purchasingCriteriaUnitId: null
      });
    }
    if (Boolean(criteriaId)) {
      let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getUnitType/" + criteriaId);
      if (response.type === "ERROR") {
        error(response);
      } else {
        let purchasingCriteriaUnitSelectItems = [];
        let purchasingCriteriaUnitId = null;
        if (Boolean(response.data) && response.data.length > 0) {
          purchasingCriteriaUnitSelectItems = response.data;
          purchasingCriteriaUnitId = response.data[0].id;
        }
        this.setState({
          purchasingCriteriaUnitSelectItems: purchasingCriteriaUnitSelectItems
        });

        if (!Boolean(edit) && Boolean(this.formRef) && Boolean(this.formRef.current)) {
          this.formRef.current.setFieldsValue({
            purchasingCriteriaUnitId: purchasingCriteriaUnitId
          });
        }
      }
    } else {
      this.setState({
        purchasingCriteriaUnitSelectItems: []
      });
    }
  };

  fillComboboxes = async () => {
    const { selectedItemBreakdown } = this.state;
    let obj = {
      materialTypeId: Boolean(selectedItemBreakdown) ? selectedItemBreakdown.materialTypeId : null,
      categoryId: Boolean(selectedItemBreakdown) ? selectedItemBreakdown.itemGroupId : null,
      descriptionId: Boolean(selectedItemBreakdown) ? selectedItemBreakdown.itemDescriptionId : null,
      projectTakeOffListId: Boolean(selectedItemBreakdown) ? selectedItemBreakdown.id : null
    };

    let responsecode = await handleRequest("POST", "/api/stockCard/controlCode", obj);
    if (responsecode.type === "ERROR") {
      error(responsecode);
    } else {
      this.setState({
        codeSelectItems: Boolean(responsecode.data) ? responsecode.data : []
      });
    }

    let responseItemDescription = await handleRequest("GET", "/api/eldenItem/" + obj.descriptionId);

    if (responseItemDescription.type === "ERROR") {
      error(responseItemDescription);
    } else {
      let itemDescription = Boolean(responseItemDescription.data) ? responseItemDescription.data : [];
      this.setState({
        itemDescription: itemDescription
      });

      if (Boolean(itemDescription.itemDimension) && itemDescription.itemDimension.length > 0) {
        itemDescription.itemDimension.forEach(element => {
          this.fillCode("StockCard" + element.itemDefinition);
          if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
            this.formRef.current.setFieldsValue({
              [element.itemDefinition]: null
            });
          }
        });
      }
      if (Boolean(itemDescription.itemCodeCriterias) && itemDescription.itemCodeCriterias.length > 0) {
        itemDescription.itemCodeCriterias.forEach(element => {
          this.fillCode("StockCard" + element.itemDefinition);
          if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
            this.formRef.current.setFieldsValue({
              [element.itemDefinition]: null
            });
          }
        });
      }
      let residueOfDimensions = [];
      if (Boolean(itemDescription.dimensionList) && itemDescription.dimensionList.length > 0) {
        itemDescription.dimensionList.forEach(element => {
          if (Boolean(itemDescription.itemDimension) && itemDescription.itemDimension.length > 0) {
            let f = itemDescription.itemDimension.find(x => x.itemDefinition === element.definition);
            if (!Boolean(f)) {
              residueOfDimensions.push({
                key: element.id,
                definition: element.definition,
                value: null,
                itemDimensionId: element.id,
                unitId: null,
                units: Boolean(element.unitType) ? element.unitType.units : []
              });
            }
          } else {
            residueOfDimensions.push({
              key: element.id,
              definition: element.definition,
              value: null,
              itemDimensionId: element.id,
              unitId: null,
              units: Boolean(element.unitType) ? element.unitType.units : []
            });
          }
        });
      }
      this.setState({
        residueOfDimensions: residueOfDimensions
      });
      if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
        this.formRef.current.setFieldsValue({
          residueOfDimensions: residueOfDimensions
        });
      }
    }

    var responsedisciplineId = await handleRequest("GET", "/api/codes/type/disciplineId");
    if (responsedisciplineId.type === "ERROR") {
      error(responsedisciplineId);
    } else {
      this.setState({
        disciplineIdSelectItems: Boolean(responsedisciplineId.data) ? responsedisciplineId.data : []
      });
    }
    var responselocationId = await handleRequest("GET", "/api/codes/type/locationId");
    if (responselocationId.type === "ERROR") {
      error(responselocationId);
    } else {
      this.setState({
        locationIdSelectItems: Boolean(responselocationId.data) ? responselocationId.data : []
      });
    }

    var responsepersonnelLogId = await handleRequest("GET", "/api/" + this.state.controllerName + "/toPersonnelLogId");
    if (responsepersonnelLogId.type === "ERROR") {
      error(responsepersonnelLogId);
    } else {
      this.setState({
        toPersonnelLogIdSelectItems: Boolean(responsepersonnelLogId.data) ? responsepersonnelLogId.data : []
      });
    }
  };

  getDeliveredQuantities = async (quantity, projectTakeOffListId) => {
    const responseInventoryLog = await handleRequest("GET", "/api/inventoryLog/getInventoryLogs/" + projectTakeOffListId);
    if (responseInventoryLog.type === "ERROR") {
      error(responseInventoryLog);
    } else {
      this.setState({
        inventoryLogs: responseInventoryLog.data
      });

      return (
        <Progress type="circle" percent={quantity} success={{ percent: responseInventoryLog.data }} format={percent => `${percent}`} width={85} />
      );
    }
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      selectedStockCard: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      paginationEquivalent: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
        showQuickJumper: true
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = async row => {
    document.getElementById("kt_scrolltop").click();

    if (Boolean(row.stockCardId)) {
      let response = await handleRequest("GET", "/api/stockCard/" + row.stockCardId);

      if (response.type === "ERROR") {
        error(response);
      } else {
        if (Boolean(row.purchasingCriteriaId)) {
          this.fillPurchasingCriteriaUnits(row.purchasingCriteriaId, true);
        }
        let stockCard = Boolean(response.data) ? response.data : [];
        this.setState(
          {
            selectedStockCard: stockCard
          },
          async () => {
            await this.fillPurchasingCriterias(true);
          }
        );

        if (Boolean(stockCard.itemDimension) && stockCard.itemDimension.length > 0) {
          stockCard.itemDimension.forEach(element => {
            this.formRef.current.setFieldsValue({
              [element.itemDefinition]: element.codeId,
              [element.itemDefinition + "Unit"]: element.unitId
            });
          });
        }
        if (Boolean(stockCard.itemCodeCriterias) && stockCard.itemCodeCriterias.length > 0) {
          stockCard.itemCodeCriterias.forEach(element => {
            this.formRef.current.setFieldsValue({
              [element.itemDefinition]: element.codeId
            });
          });
        }
      }
    }

    let residueOfDimensions = [];

    if (Boolean(row.residueOfDimensions) && row.residueOfDimensions.length > 0) {
      row.residueOfDimensions.forEach(element => {
        residueOfDimensions.push({
          id: element.id,
          key: element.id,
          definition: element.itemDimension.definition,
          value: element.value,
          itemDimensionId: element.itemDimensionId,
          unitId: element.unitId,
          units: element.itemDimension.unitType.units
        });
      });
    }

    //let weight = this.calculateWeight(row);
    //console.log("WWW", weight);

    this.formRef.current.setFieldsValue({
      ...row,
      deliveryDate: Boolean(row.deliveryDate) ? moment(row.deliveryDate) : null
      //  weight: weight
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      residueOfDimensions: residueOfDimensions,
      id: row.id
    });

    setTimeout(() => {
      const { purchasingCriteriaSelectItems } = this.state;
      const selectedPurchasingCriteria = purchasingCriteriaSelectItems.find(i => i.key === row.purchasingCriteriaId);
      this.setState({
        selectedPurchasingCriteria
      });
    }, 1500);
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  sendSelectModal = row => {
    this.setState({
      modalSendSelectShow: true,
      id: row.id
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/projectTakeOffQuantity/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  handleTableChangeEquivalent = (paginationEquivalent, filters, sorter) => {
    this.restartTableEquivalent({ sortField: sorter.columnKey, sortOrder: sorter.order, paginationEquivalent, filters });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/projectTakeOffQuantity/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
          element.key = element.id;
          element.unitSelectItems = Boolean(this.state.unitSelectItems) && this.state.unitSelectItems.length > 0 ? this.state.unitSelectItems : [];
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  restartTableEquivalent = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhereEquivalent(params);
    let url = "/api/projectTakeOffQuantity/pagingEquivalent";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableListEquivalent: [],
        paginationEquivalent: {
          ...params.paginationEquivalent,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        this.setState({
          loading: false,
          tableListEquivalent: list,
          paginationEquivalent: {
            ...params.paginationEquivalent,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableListEquivalent: [],
          paginationEquivalent: {
            ...params.paginationEquivalent,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    this.setState({ saveLoading: true });
    if (Boolean(this.props.companyId) && Boolean(this.props.companyProjectId)) {
      const newItem = { ...values, id: this.state.id };
      const { selectedItemBreakdown, residueOfDimensions } = this.state;
      newItem.projectTakeOffId = Boolean(selectedItemBreakdown) ? selectedItemBreakdown.id : null;
      newItem.stockCardId = Boolean(this.state.selectedStockCard) ? this.state.selectedStockCard.id : null;

      if (Boolean(residueOfDimensions)) {
        newItem.residueOfDimensions = residueOfDimensions;
      } else {
        newItem.residueOfDimensions = [];
      }

      newItem.companyId = this.props.companyId;
      newItem.projectId = this.props.projectId;
      newItem.companyProjectId = this.props.companyProjectId;

      if (newItem.id === null) {
        var response = await handleRequest("POST", "/api/projectTakeOffQuantity", newItem);
      } else {
        response = await handleRequest("PUT", "/api/projectTakeOffQuantity/" + newItem.id, newItem);
      }

      if (response.type === "ERROR") {
        error(response);
        this.setState({ saveLoading: false });
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
        this.setState({ saveLoading: false });
      }
    } else {
      showError("Company/Project not found.");
      this.setState({ saveLoading: false });
    }
  };

  createWhere(params) {
    console.log(params);
    const { selectedItemBreakdown } = this.state;
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      companyId: this.props.companyId,
      projectId: this.props.projectId,
      companyProjectId: this.props.companyProjectId,
      projectTakeOffId: Boolean(selectedItemBreakdown) ? selectedItemBreakdown.id : null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  createWhereEquivalent(params) {
    console.log(params);
    if (Boolean(params._paginationEquivalent)) params.paginationEquivalent = params._paginationEquivalent;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.paginationEquivalent)) {
      if (Boolean(params.paginationEquivalent.lastUpdateDate)) {
        lastUpdateDateStart = params.paginationEquivalent.lastUpdateDate[0];
        lastUpdateDateEnd = params.paginationEquivalent.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.paginationEquivalent,
      id: null,
      companyId: this.props.companyId,
      projectId: this.props.projectId,
      companyProjectId: this.props.companyProjectId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.paginationEquivalent.current - 1,
      maxResults: params.paginationEquivalent.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/projectTakeOffQuantityimport", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let addEquivalentAction = {
      name: "Add Equivalent",
      icon: <RevisionIcon fontSize="small" color="secondary" />,
      actionClick: this.addEquivalent.bind(this)
    };

    let addNoteAction = {
      name: "Note",
      icon: <NoteAddOutlined fontSize="small" color="primary" />,
      actionClick: this.addNoteAction.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);
    if (!record.equivalent) {
      actionList.push(addEquivalentAction);
    }
    actionList.push(addNoteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  addEquivalent = record => {
    const { paginationEquivalent } = this.state;
    let _paginationEquivalent = {
      ...paginationEquivalent,
      projectTakeOffQuantityId: record.id
    };
    this.setState(
      {
        paginationEquivalent: _paginationEquivalent,
        showAddEquivalentModal: true
      },
      () => {
        this.restartTableEquivalent({ _paginationEquivalent, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
      }
    );
  };

  addNoteAction = async record => {
    var response = await handleRequest("GET", "/api/entityNote/getNote/" + record.id + "/" + this.state.tableName);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState(
        {
          id: record.id,
          showAddNoteModal: true
        },
        () => {
          this.formRefNote.current.setFieldsValue({
            toPersonnelLogId: response.data.toPersonnelLogId,
            toMessage: response.data.toMessage
          });
        }
      );
    }
  };

  saveNote = async values => {
    let newObj = {
      ...values,
      tableId: this.state.id,
      tableName: this.state.tableName
    };
    var response = await handleRequest("POST", "/api/entityNote/saveNote", newObj);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        showAddNoteModal: false
      });
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  sendSelected = async () => {
    const { selectedRowKeys, selectedItemBreakdown } = this.state;
    var response = await handleRequest("POST", "/api/projectTakeOff/sendSelectedList/" + selectedItemBreakdown.id, selectedRowKeys);
    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        modalSendSelectShow: false
      });
    }
    this.setState({
      selectedRowKeys: []
    });
  };

  procurementProgress = async row => {
    var response = await handleRequest("GET", "/api/projectTakeOffQuantity/procurementProgress/" + row.id);
    if (response.type === "ERROR") {
      error(response);
    } else {
      let data = response.data;
      if (Boolean(data.details) && data.details.length > 0) {
        let index = data.details.findIndex(p => p.status === "Not Started");
        if (index !== -1) data.details[index].status = "In Progress";
      }
      this.setState({
        showModalProcurementProgress: true,
        procurementProgress: data
      });
    }
  };

  openRichSearch = () => {
    this.setState({
      richSearchModal: true,
      tempCodeSelectItems: this.state.codeSelectItems
    });
  };

  openLastPurchaseModal = async () => {
    const { selectedStockCard } = this.state;
    if (!Boolean(selectedStockCard)) {
      showWarning("Please select a stock card code");
      return;
    }

    var response = await handleRequest("GET", "/api/stockCard/lastPurchaseList/" + selectedStockCard.code + "/" + 5);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState({
        tableListLastPurchase: response.data,
        showLastPurchaseModal: true
      });
    }
  };

  calculateWeightTableColumns = row => {
    const { itemDescription } = this.state;
    if (Boolean(itemDescription) && Boolean(itemDescription.features) && itemDescription.features.length > 0) {
      let weightFeature = itemDescription.features[0];
      if (weightFeature.itemFeatureFormula && weightFeature.itemFeatureFormula.length > 0) {
        let formulaText = "";
        weightFeature.itemFeatureFormula.forEach((element, index) => {
          let constant = Boolean(element.constant)
            ? element.constant
            : Boolean(element.dimensionAbbreviation)
              ? this.getDimensionValue(element.dimension, element.dimensionId, row)
              : Boolean(element.numberConstant)
                ? element.numberConstant
                : "";
          let operator = Boolean(element.operator) ? createOperator(element.operator) : "";
          formulaText += constant + operator;
        });
        console.log("formulaText", formulaText);
        if (!formulaText.includes("DIMENSION_NOT_FOUND")) {
          let evaluatedValue = evaluate(formulaText);
          let result = round(evaluatedValue, 2);
          console.log("result", result);
          if (row) {
            let weight = 0;
            if (Boolean(result)) {
              if (Boolean(row.unit) && Boolean(row.unitSelectItems) && row.unitSelectItems.length > 0) {
                let unit = row.unitSelectItems.find(p => p.abbreviation === row.unit);
                if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                  weight = parseFloat(((result * row.quantity) / unit.coefficent).toFixed(2)).toFixed(2);
                  console.log("weight111", weight);
                } else {
                  weight = (result * row.quantity).toFixed(2);
                  console.log("weight222", weight);
                }
              } else {
                weight = (result * row.quantity).toFixed(2);
                console.log("weight333", weight);
              }
            }
            return weight;
          }
          return result;
        }
      }
    }
    return "";
  };

  calculateWeight = row => {
    const { itemDescription, selectedStockCard } = this.state;
    console.log("itemDescription", itemDescription);

    if (Boolean(selectedStockCard) && Boolean(selectedStockCard.weight)) {
      console.log("selectedStockCard", selectedStockCard);

      this.formRef.current.setFieldsValue({
        weight: selectedStockCard.weight
      });
      return selectedStockCard.weight;
    }

    if (Boolean(itemDescription) && Boolean(itemDescription.features) && itemDescription.features.length > 0) {
      let weightFeature = itemDescription.features[0];
      if (weightFeature.itemFeatureFormula && weightFeature.itemFeatureFormula.length > 0) {
        let formulaText = "";
        weightFeature.itemFeatureFormula.forEach((element, index) => {
          let constant = Boolean(element.constant)
            ? element.constant
            : Boolean(element.dimensionAbbreviation)
              ? this.getDimensionValue(element.dimension, element.dimensionId, row)
              : Boolean(element.numberConstant)
                ? element.numberConstant
                : "";
          let operator = Boolean(element.operator) ? createOperator(element.operator) : "";
          formulaText += constant + operator;
        });
        console.log("formulaText", formulaText);
        if (!formulaText.includes("DIMENSION_NOT_FOUND")) {
          let result = round(evaluate(formulaText), 2);
          console.log("result", result);
          let weight = result;
          if (row) {
            console.log("row", row);
            if (Boolean(result)) {
              if (Boolean(row.unit) && Boolean(row.unitSelectItems) && row.unitSelectItems.length > 0) {
                let unit = row.unitSelectItems.find(p => p.abbreviation === row.unit);
                let unitRow = [];
                if (
                  Boolean(row.residueOfDimensions) &&
                  row.residueOfDimensions.length > 0 &&
                  row.residueOfDimensions[0].units &&
                  row.residueOfDimensions[0].units.length > 0
                ) {
                  unitRow = row.residueOfDimensions[0].units.find(p => p.abbreviation === row.unit);
                }
                if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                  weight = parseFloat((result / unit.coefficent).toFixed(2)).toFixed(2);
                  console.log("weight111", weight);
                } else if (Boolean(unitRow) && Boolean(unitRow.coefficent) && unitRow.coefficent > 0) {
                  weight = parseFloat((result / unitRow.coefficent).toFixed(2)).toFixed(2);
                  console.log("weight222", weight);
                } else {
                  weight = result.toFixed(2);
                  console.log("weight333", weight);
                }
              } else {
                weight = result.toFixed(2);
                console.log("weight444", weight);
              }
            }
          }
          this.formRef.current.setFieldsValue({
            weight: weight
          });
          return weight;
        }
      }
    }
    console.log("weight3:0");
    this.formRef.current.setFieldsValue({
      weight: ""
    });
    return 0;
  };

  getDimensionValue = (dimension, dimensionId, row) => {
    const { residueOfDimensions, selectedStockCard } = this.state;

    let dimensionValue = "DIMENSION_NOT_FOUND";
    let stockCard = { ...selectedStockCard };
    let rdo = residueOfDimensions;
    if (row) {
      stockCard = this.state.codeSelectItems.find(i => i.code === row.code);
      rdo = row.residueOfDimensions;
    }

    if (Boolean(rdo) && rdo.length > 0) {
      rdo.forEach(element => {
        if (element.itemDimensionId === dimensionId) {
          if (Boolean(element.value)) {
            dimensionValue = element.value;
          }
        }
      });
    }
    if (dimensionValue === "DIMENSION_NOT_FOUND" && stockCard && stockCard.itemDimension && stockCard.itemDimension.length > 0) {
      stockCard.itemDimension.forEach(element => {
        if (element.itemDefinition === dimension) {
          let code = Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"])
            ? this.state["StockCard" + element.itemDefinition + "SelectItems"].find(x => x.id === element.codeId)
            : null;
          dimensionValue = Boolean(code) ? code.name : "DIMENSION_NOT_FOUND";
        }
      });
    }

    return dimensionValue;
  };

  filterOnCriterias = async () => {
    const { codeSelectItems, itemDescription } = this.state;

    let temp = [...codeSelectItems];

    if (Boolean(itemDescription) && Boolean(itemDescription.itemDimension) && itemDescription.itemDimension.length > 0) {
      itemDescription.itemDimension.forEach(element => {
        if (Boolean(element.codeId)) temp = temp.filter(p => p.itemDimension.findIndex(u => u.codeId === element.codeId) >= 0);
      });
    }

    if (Boolean(itemDescription) && Boolean(itemDescription.itemCodeCriterias) && itemDescription.itemCodeCriterias.length > 0) {
      itemDescription.itemCodeCriterias.forEach(element => {
        if (Boolean(element.codeId)) temp = temp.filter(p => p.itemCodeCriterias.findIndex(u => u.codeId === element.codeId) >= 0);
      });
    }

    this.setState({
      tempCodeSelectItems: temp
    });

    if (Boolean(temp) && temp.length === 1) {
      this.setState({
        selectedStockCard: temp[0]
      });
    } else
      this.setState({
        selectedStockCard: null
      });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  handleUnit = async (row, value) => {
    console.log("row", row);
    console.log(value);
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.key === item.key);
      if (Boolean(value) && Boolean(item)) {
        if (item.unit === value.unit) return;
        const unit = row.unitSelectItems.find(p => p.id === value.unit);
        if (Boolean(unit)) {
          item.unit = unit.abbreviation;
        }
        this.setState({
          tableList: newData
        });
      }
    }
  };

  onSelectChangeEquivalent = selectedRowKeysEquivalent => {
    this.setState({
      selectedRowKeysEquivalent,
      modalEquivalentShow: true
    });
  };

  confirmEquivalent = async () => {
    const { selectedRowKeysEquivalent } = this.state;
    console.log("XXX", selectedRowKeysEquivalent);
    if (Boolean(selectedRowKeysEquivalent) && selectedRowKeysEquivalent.length > 0) {
      let obj = {
        id: selectedRowKeysEquivalent[0]
      };

      let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/addEquivalentItem", obj);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.setState({
          selectedRowKeysEquivalent: null,
          modalEquivalentShow: false,
          showAddEquivalentModal: false
        });
      }
    } else {
      showError("Not found.");
    }
  };
  rfqHistory = async row => {
    let url = "/api/" + this.state.controllerName + "/getHistory/" + row.id;
    const response = await handleRequest("GET", url);
    if (response.type === "ERROR") {
      error(response);
    } else if (Boolean(response.data) && response.data.length > 0) {
      this.setState({
        historyList: response.data,
        modalHistoryShow: true
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      },
      header: {
        cell: ResizableTitle
      }
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layoutLeft = {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 }
    };

    const layoutRight = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 }
    };

    const { selectedRowKeys, selectedRowKeysEquivalent } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideSelectAll: true,
      renderCell: (checked, record, index, originNode) => {
        if (!Boolean(record.status)) return originNode;
        else if (record.status === "NotSent") return originNode;
        else return null;
      }
    };

    const rowSelectionEquivalent = {
      selectedRowKeys: selectedRowKeysEquivalent,
      onChange: this.onSelectChangeEquivalent
    };

    const columns = [
      {
        key: "key",
        responsive: ["sm"],
        fixed: "left",
        width: 100,
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 100,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.equivalent === true ? "#FF0000" : null
          }
        })
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.Status" defaultMessage="Status" />,
        key: "status",
        width: 100,
        render: (value, row, index) => {
          if (!Boolean(row.status)) return <Tag color="red">Not Sent</Tag>;
          else if (row.status === "NotSent") return <Tag color="red">{"Not Sent"}</Tag>;
          else return <Tag color="green">{"Sent"}</Tag>;
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.equivalent === true ? "#FF0000" : null
          }
        })
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.ItemDescription" defaultMessage="Item Description" />,
        key: "id",
        ellipsis: true,
        width: 100,
        render: record => {
          return (
            <React.Fragment>
              <Tooltip title={record.itemDescriptionRename}>
                <div
                  style={{
                    cursor: "pointer"
                  }}
                  onClick={e => this.procurementProgress(record)}
                >
                  {record.itemDescriptionRename}
                </div>
              </Tooltip>
            </React.Fragment>
          );
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.equivalent === true ? "#FF0000" : null
          }
        }),
        ...getColumnFilter({
          inputName: "code",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.Dimensions" defaultMessage="Dimensions" />,
        key: "idimensions",
        ellipsis: true,
        width: 100,
        render: (value, row, index) => {
          return <Tooltip title={row.allDimensions}>{row.allDimensions}</Tooltip>;
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.equivalent === true ? "#FF0000" : null
          }
        })
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.quantity" defaultMessage="Quantity" />,
        key: "quantity",
        width: 100,
        render: record => {
          return record.quantity ? record.quantity + " ad" : record.quantity;
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.equivalent === true ? "#FF0000" : null
          }
        }),
        ...getColumnFilter({
          inputName: "quantity",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="ProjectTakeOffQuantity.weight" defaultMessage="Weight" />,
        key: "weight",
        width: 100,
        render: record => {
          if (Boolean(record.weight) && Boolean(record.quantity)) {
            console.log("record", record);
            if (Boolean(record.unit) && Boolean(this.state.unitSelectItems) && this.state.unitSelectItems.length > 0) {
              let unit = this.state.unitSelectItems.find(p => p.abbreviation === record.unit);
              if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
                console.log("weight1111", unit.coefficent);
                console.log(record.quantity * record.weight);
                console.log((record.quantity * record.weight) / unit.coefficent);
                return ((record.quantity * record.weight) / unit.coefficent).toFixed(2);
              } else {
                console.log("weight2222", record.weight * record.quantity);
                return (record.weight * record.quantity).toFixed(2);
              }
            } else {
              console.log("weight3333", record.weight * record.quantity);
              return (record.weight * record.quantity).toFixed(2);
            }
          } else {
            return this.calculateWeightTableColumns(record);
          }
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.equivalent === true ? "#FF0000" : null
          }
        })
      },
      {
        title: <FormattedMessage id="ProjectTakeOffQuantity.unit" defaultMessage="Unit" />,
        key: "unit",
        width: 100,
        render: record => {
          return Boolean(record.unit) ? record.unit : "";
        },
        editable: true,
        onCell: record => ({
          style: {
            color: record.equivalent === true ? "#FF0000" : null
          },
          record,
          editable: true,
          dataIndex: "unit",
          title: <FormattedMessage id="ProjectTakeOffQuantity.unit" defaultMessage="Unit" />,
          handleSave: this.handleUnit
        })
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.disciplineId" defaultMessage="Discipline" />,
        key: "disciplineId",
        width: 100,
        render: record => {
          if (Boolean(record.discipline)) {
            return record.discipline.name;
          }
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.equivalent === true ? "#FF0000" : null
          }
        }),
        ...getColumnFilter({
          inputName: "disciplineId",
          inputType: "SELECT_ADDABLE",
          selectItems: Boolean(this.state["disciplineIdSelectItems"]) ? this.state["disciplineIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.locationId" defaultMessage="Location" />,
        key: "locationId",
        width: 100,
        render: record => {
          if (Boolean(record.location)) {
            return record.location.name;
          }
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.equivalent === true ? "#FF0000" : null
          }
        }),
        ...getColumnFilter({
          inputName: "locationId",
          inputType: "SELECT_ADDABLE",
          selectItems: Boolean(this.state["locationIdSelectItems"]) ? this.state["locationIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.deliveryDate" defaultMessage="Delivery Date" />,
        key: "deliveryDate",
        width: 100,
        render: record => {
          if (Boolean(record.deliveryDate)) {
            return moment(record.deliveryDate).format("DD-MM-YYYY");
          }
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.equivalent === true ? "#FF0000" : null
          }
        }),
        ...getColumnFilter({
          inputName: "deliveryDate",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.DeliveredAmount" defaultMessage="Delivered Amount" />,
        key: "deliveryAmount",
        width: 100,
        render: record => {
          return (
            <Progress
              type="circle"
              percent={record.deliveryAmount}
              success={{ percent: record.deliveryAmount }}
              format={() => (Boolean(record.deliveryAmount) ? record.deliveryAmount : 0)}
              width={85}
            />
          );
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.equivalent === true ? "#FF0000" : null
          }
        })
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.remarks" defaultMessage="Remarks" />,
        key: "remarks",
        width: 100,
        render: record => {
          return record.remarks;
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.equivalent === true ? "#FF0000" : null
          }
        }),
        ...getColumnFilter({
          inputName: "remarks",
          inputType: "SELECT_ADDABLE",
          selectItems: Boolean(this.state["remarksSelectItems"]) ? this.state["remarksSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.LastUpdate" defaultMessage="Last Update" />,
        key: "lastUpdateDate",
        width: 100,
        render: record => {
          return (
            <React.Fragment>
              <div
                style={{
                  cursor: "pointer",
                  color: record.status === "Deleted" ? "#d3d3d3" : "blue",
                  textDecoration: "underline"
                }}
                onClick={e => this.rfqHistory(record)}
              >
                {moment(record.lastUpdated).format("DD-MM-YYYY HH:mm")};
              </div>
            </React.Fragment>
          );
        },
        onCell: (record, rowIndex) => ({
          style: {
            color: record.equivalent === true ? "#FF0000" : null
          }
        }),
        ...getColumnFilter({
          inputName: "lastUpdated",
          inputType: "DATEPICKER_WITH_TIME",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        })
      }
    ];

    const columnsEquivalent = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.paginationEquivalent.pageSize * (this.state.paginationEquivalent.current - 1);
        }
      },
      {
        title: <FormattedMessage id="StockCardEquivalentDetail.itemDescriptionId" defaultMessage="Equivalent Items" />,
        key: "itemDescriptionId",
        render: record => {
          return record.equivalentName;
        }
      }
    ];

    const handleResize = index => (_, { size }) => {
      const newColumns = [...columns];

      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      this.setState({
        columns: newColumns
      });
    };

    const mergeColumns =
      Boolean(this.state.columns) && this.state.columns.length > 0
        ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
        : Boolean(columns) &&
        columns.length > 0 &&
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }));

    const columnsLastPurchase = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.vendor" defaultMessage="Vendor" />,
        key: "vendor",
        render: record => {
          return record.vendor;
        }
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.quantity" defaultMessage="Quantity" />,
        key: "quantity",
        render: record => {
          return record.quantity;
        }
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.unitPrice" defaultMessage="Unit Price" />,
        key: "unitPrice",
        render: record => {
          return record.unitPrice;
        }
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.unitPrice" defaultMessage="Planned Delivery Date" />,
        key: "planedDeliveryDate",
        render: record => {
          if (record.planedDeliveryDate) {
            return moment(record.planedDeliveryDate).format("DD-MM-YYYY");
          }
        }
      },
      {
        title: <FormattedMessage id="ItemBreakdownQuantity.unitPrice" defaultMessage="Delivery Date" />,
        key: "deliveryDate",
        render: record => {
          if (record.deliveryDate) {
            return moment(record.deliveryDate).format("DD-MM-YYYY");
          }
        }
      }
    ];

    const excelExport = (
      <>
        {/* {this.state.exportClick
          ? this.setState({
            exportClick: false
          })
          : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Products" hideElement={true}>
            <ExcelSheet name="Products" data={this.state.tableList}>
              <ExcelColumn label="S/N" value="index" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );

    return (
      <Badge.Ribbon
        text={Boolean(this.state.selectedItemBreakdown) ? this.state.selectedItemBreakdown.itemGroup.name : ""}
        placement="start"
        color="blue"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={22}></Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
              <Col md={1}></Col>
            </Row>
            <br />
            <div hidden={this.state.hideInputs}>
              <Row gutter={16} style={{ marginBottom: "16px" }}>
                {/* Sol Card */}
                <Col span={15}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="ItemBreakdownQuantity.codeName" defaultMessage="Name/Code" />}
                        name="code"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input.Group compact>
                          <Select
                            showSearch
                            allowClear
                            style={{ width: '75%' }}
                            optionFilterProp="children"
                            onChange={value => {
                              const { codeSelectItems } = this.state;
                              console.log("value", value);
                              console.log("codeSelectItems", codeSelectItems);
                              let unitName = "mm";
                              if (Boolean(value)) {
                                let fObj = codeSelectItems.find(i => i.code === value);
                                if (Boolean(fObj)) {
                                  if (Boolean(fObj.itemCodeCriterias) && fObj.itemCodeCriterias.length > 0) {
                                    fObj.itemCodeCriterias.forEach(element => {
                                      this.formRef.current.setFieldsValue({
                                        [element.itemDefinition]: element.codeId
                                      });
                                    });
                                  }

                                  if (Boolean(fObj.itemDimension) && fObj.itemDimension.length > 0) {
                                    fObj.itemDimension.forEach(element => {
                                      this.formRef.current.setFieldsValue({
                                        [element.itemDefinition]: element.codeId,
                                        [element.itemDefinition + "Unit"]: element.unitId
                                      });
                                    });
                                  }
                                }

                                if (Boolean(this.state.residueOfDimensions) && this.state.residueOfDimensions.length > 0) {
                                  this.state.residueOfDimensions.forEach(element => {
                                    if (element.definition === "Length" || element.definition === "Width") {
                                      if (Boolean(element) && Boolean(element.unitId) && Boolean(element.units) && element.units.length > 0) {
                                        let unit = element.units.find(p => p.id === element.unitId);
                                        if (Boolean(unit)) {
                                          unitName = unit.abbreviation;
                                        }
                                      }
                                    }
                                  });
                                }

                                this.setState(
                                  {
                                    selectedStockCard: fObj
                                  },
                                  () => {
                                    this.fillPurchasingCriterias();
                                    let row = {
                                      code: value,
                                      unit: unitName,
                                      residueOfDimensions:
                                        Boolean(this.state.residueOfDimensions) && this.state.residueOfDimensions.length > 0
                                          ? this.state.residueOfDimensions
                                          : [],
                                      unitSelectItems:
                                        Boolean(this.state.unitSelectItems) && this.state.unitSelectItems.length > 0 ? this.state.unitSelectItems : []
                                    };
                                    this.calculateWeight(row);
                                  }
                                );
                              } else {
                                this.formRef.current.resetFields();
                                this.setState({
                                  selectedStockCard: null
                                });
                              }
                            }}
                          >
                            {Boolean(this.state["codeSelectItems"]) &&
                              this.state["codeSelectItems"].map(i => (
                                <Option key={i.code} value={i.code}>
                                  {i.renameText}
                                </Option>
                              ))}
                          </Select>
                          <Tooltip title={<FormattedMessage id="ProjectTakeOffQuantity.RichSearch" defaultMessage="Rich Search" />}>
                            <AntButton
                              style={{ width: '10%', marginLeft: '3%' }}
                              onClick={this.openRichSearch}
                              icon={<FindInPageIcon color="secondary" fontSize="large" />}
                            ></AntButton>
                          </Tooltip>

                          <Tooltip title={<FormattedMessage id="ProjectTakeOffQuantity.LastPurchases" defaultMessage="Last Purchases" />}>
                            <AntButton
                              style={{ width: '10%', marginLeft: '2%' }}
                              onClick={this.openLastPurchaseModal}
                              icon={<ShoppingCartOutlined />}
                            >
                            </AntButton>
                          </Tooltip>
                        </Input.Group>
                      </Form.Item>
                    }
                    {Boolean(this.state.residueOfDimensions) &&
                      this.state.residueOfDimensions.length > 0 &&
                      this.state.residueOfDimensions.map((dimension, index) => {
                        return (
                          <Form.Item key={index} {...layoutLeft} label={dimension.definition}>
                            <Row>
                              <Col span={11}>
                                <InputNumber
                                  parser={value => value.replace(",", ".")}
                                  formatter={value => value.replace(".", ",")}
                                  placeholder={dimension.definition}
                                  style={{ width: "100%" }}
                                  min={0}
                                  value={dimension.value}
                                  onChange={value => {
                                    let list = this.state.residueOfDimensions;
                                    list[index].value = value;
                                    let unitName = "mm";
                                    if (Boolean(dimension) && Boolean(dimension.unitId) && Boolean(dimension.units) && dimension.units.length > 0) {
                                      let unit = dimension.units.find(p => p.id === dimension.unitId);
                                      if (Boolean(unit)) {
                                        unitName = unit.abbreviation;
                                      }
                                    }
                                    this.setState(
                                      {
                                        residueOfDimensions: list
                                      },
                                      () => {
                                        if (dimension.definition === "Length" || dimension.definition === "Width") {
                                          console.log("selectedStockCard", this.state.selectedStockCard);
                                          let row = {
                                            code: Boolean(this.state.selectedStockCard) ? this.state.selectedStockCard.code : "",
                                            unit: unitName,
                                            residueOfDimensions:
                                              Boolean(this.state.residueOfDimensions) && this.state.residueOfDimensions.length > 0
                                                ? this.state.residueOfDimensions
                                                : [],
                                            unitSelectItems:
                                              Boolean(this.state.unitSelectItems) && this.state.unitSelectItems.length > 0
                                                ? this.state.unitSelectItems
                                                : []
                                          };
                                          this.calculateWeight(row);
                                        }
                                      }
                                    );
                                  }}
                                />
                              </Col>
                              <Col span={2}></Col>
                              <Col span={11}>
                                <Select
                                  showSearch
                                  allowClear
                                  optionFilterProp="children"
                                  style={{ width: "100%" }}
                                  placeholder="Unit"
                                  value={dimension.unitId}
                                  onChange={value => {
                                    console.log("VVV", value);
                                    console.log(dimension);
                                    let list = this.state.residueOfDimensions;
                                    list[index].unitId = value;
                                    let unitName = "mm";
                                    if (Boolean(value) && Boolean(dimension) && Boolean(dimension.units) && dimension.units.length > 0) {
                                      let unit = dimension.units.find(p => p.id === value);
                                      if (Boolean(unit)) {
                                        unitName = unit.abbreviation;
                                      }
                                    }
                                    this.setState(
                                      {
                                        residueOfDimensions: list
                                      },
                                      () => {
                                        if (dimension.definition === "Length" || dimension.definition === "Width") {
                                          console.log("selectedStockCard", this.state.selectedStockCard);
                                          let row = {
                                            code: Boolean(this.state.selectedStockCard) ? this.state.selectedStockCard.code : "",
                                            unit: unitName,
                                            residueOfDimensions:
                                              Boolean(this.state.residueOfDimensions) && this.state.residueOfDimensions.length > 0
                                                ? this.state.residueOfDimensions
                                                : [],
                                            unitSelectItems:
                                              Boolean(this.state.unitSelectItems) && this.state.unitSelectItems.length > 0
                                                ? this.state.unitSelectItems
                                                : []
                                          };
                                          this.calculateWeight(row);
                                        }
                                      }
                                    );
                                  }}
                                >
                                  {dimension.units &&
                                    dimension.units.map(i => (
                                      <Option key={i.id} value={i.id}>
                                        {i.description}
                                      </Option>
                                    ))}
                                </Select>
                              </Col>
                            </Row>
                          </Form.Item>
                        );
                      })}
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={
                          <FormattedMessage
                            id="ItemBreakdownQuantity.quantity"
                            defaultMessage="Quantity"
                            rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                          />
                        }
                      >
                        <Row>
                          <Col span={8}>
                            <Form.Item
                              name="quantity"
                              rules={[
                                { required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                              ]}
                            >
                              <InputNumber
                                parser={value => value.replace(",", ".")}
                                formatter={value => value.replace(".", ",")}
                                className="w-100"
                                placeholder="Quantity"
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={1}></Col>
                          <Col span={7}>
                            <Form.Item
                              name={"purchasingCriteriaId"}
                              rules={[
                                { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                              ]}
                            >
                              <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{ width: "100%" }}
                                onChange={value => {
                                  this.fillPurchasingCriteriaUnits(value);
                                }}
                              >
                                {this.state.purchasingCriteriaSelectItems &&
                                  this.state.purchasingCriteriaSelectItems.map(i => (
                                    <Option key={i.key} value={i.key}>
                                      {i.value}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={1}></Col>
                          <Col span={7}>
                            <Form.Item
                              name={"purchasingCriteriaUnitId"}
                              rules={[
                                { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                              ]}
                            >
                              <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                {Boolean(this.state.purchasingCriteriaUnitSelectItems) &&
                                  this.state.purchasingCriteriaUnitSelectItems.map(i => (
                                    <Option key={i.id} value={i.id}>
                                      {i.abbreviation}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layoutLeft}
                        label={<FormattedMessage id="ItemBreakdownQuantity.DeliveryDate" defaultMessage="Delivery Date" />}
                        name="deliveryDate"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <DatePicker format="DD-MM-YYYY" />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layoutLeft}
                        hidden={true}
                        label={<FormattedMessage id="ItemBreakdownQuantity.Status" defaultMessage="Delivered Amount" />}
                        name="status"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <Input />
                      </Form.Item>
                    }
                    {
                      <Form.Item
                        {...layoutLeft}
                        name="remarks"
                        label={<FormattedMessage id="ProjectTakeOffQuantity.Remarks" defaultMessage="Remarks" />}
                        style={{ marginBottom: "5px" }}
                      >
                        <Input.Group compact>
                          <Input style={{ width: '75%' }} />
                          <AntButton
                            style={{ width: '10%', marginLeft: '3%' }}
                            icon={<UserOutlined />}
                            onClick={() => {
                              this.setState({
                                modalUserShow: true
                              });
                            }}
                          ></AntButton>
                          <AntButton
                            // onClick={() => this.addNewRow(record)}
                            style={{ width: '10%', marginLeft: '2%' }}
                            icon={<PlusCircleOutlined fontSize="large" />}
                          ></AntButton>
                        </Input.Group>
                      </Form.Item>
                    }
                  </Card>
                </Col>

                {/* Sağ Card */}
                <Col span={9}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    {Boolean(this.state.itemDescription) &&
                      Boolean(this.state.itemDescription.itemCodeCriterias) &&
                      this.state.itemDescription.itemCodeCriterias.length > 0 &&
                      this.state.itemDescription.itemCodeCriterias.map(element => (
                        <Form.Item
                          {...layoutRight}
                          label={element.itemDefinition}
                          name={element.itemDefinition}
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Select disabled showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                            {Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"]) &&
                              this.state["StockCard" + element.itemDefinition + "SelectItems"].map(i => (
                                <Option key={i.id} value={i.id}>
                                  {i.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      ))}
                    {Boolean(this.state.itemDescription) &&
                      Boolean(this.state.itemDescription.itemDimension) &&
                      this.state.itemDescription.itemDimension.length > 0 &&
                      this.state.itemDescription.itemDimension.map(element => (
                        <Form.Item
                          {...layoutRight}
                          label={element.itemDefinition}
                          rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                        >
                          <Row>
                            <Col span={11}>
                              <Form.Item
                                name={element.itemDefinition}
                                rules={[
                                  { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                              >
                                <Select disabled showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                  {Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"]) &&
                                    this.state["StockCard" + element.itemDefinition + "SelectItems"].map(i => (
                                      <Option key={i.id} value={i.id}>
                                        {i.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={11}>
                              <Form.Item
                                name={element.itemDefinition + "Unit"}
                                rules={[
                                  { required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }
                                ]}
                              >
                                <Select disabled showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                  {element.units &&
                                    element.units.map(i => (
                                      <Option key={i.key} value={i.key}>
                                        {i.value}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                      ))}

                    {
                      <Form.Item
                        {...layoutRight}
                        label={<FormattedMessage id="ItemBreakdownQuantity.UnitWeight" defaultMessage="Unit Weight" />}
                        name="weight"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber disabled className="w-100" min={0} addonAfter={<FormattedMessage id="ProjectTakeOffQuantity.gr" defaultMessage="gr" />} />
                      </Form.Item>
                    }
                  </Card>
                </Col>
              </Row>

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Spin spinning={this.state.saveLoading}>
                        <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                          <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                        </Button>
                      </Spin>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Spin spinning={this.state.saveLoading}>
                        <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                          <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                        </Button>
                      </Spin>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
          <Row gutter={[16, 16]}>
            <Col md={24}>
              <Space>
                <Button
                  variant="primary"
                  onClick={this.sendSelectModal.bind(this)}
                  style={{ marginTop: "10px" }}
                  disabled={Boolean(this.state.selectedRowKeys) && this.state.selectedRowKeys.length > 0 ? false : true}
                  loading={this.state.loading}
                >
                  Send Selected
                </Button>
                <span style={{ marginLeft: 8 }}>
                  {Boolean(this.state.selectedRowKeys) && this.state.selectedRowKeys.length > 0 ? `Selected ${selectedRowKeys.length} items` : ""}
                </span>
              </Space>
            </Col>
          </Row>
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
              <div className="alert alert-custom alert-light">
                <div className="alert-icon">
                  <FilterOutlined />
                </div>
                <div className="alert-text">
                  {this.state.searchCriteriaModels.map((val, i) => {
                    return (
                      <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                        <span className="mr-2">{val.label}</span>
                        <strong className="mr-2">{val.type}</strong>
                        <span className="mr-2">{val.operationValue}</span>
                        <span className="mr-2">{val.showValue}</span>
                        <span>
                          <Tooltip title="Remove">
                            <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                          </Tooltip>
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="alert-close">
                  <Tooltip title="Remove All">
                    <CloseCircleOutlined
                      style={{ color: "crimson" }}
                      onClick={() =>
                        this.setState({ searchCriteriaModels: [] }, () => {
                          this.filterChangeItem([]);
                        })
                      }
                    />
                  </Tooltip>
                </div>
              </div>
            )}

            <Table
              components={components}
              bordered
              size="small"
              rowSelection={rowSelection}
              scroll={{ x: 1200, y: 900, scrollToFirstRowOnChange: true }}
              columns={Boolean(mergeColumns) && mergeColumns.length > 0 ? mergeColumns : columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <DraggableModal
            centered
            width={700}
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            visible={this.state.modalDeleteShow}
            onCancel={() => {
              this.setState({ modalDeleteShow: false });
            }}
            footer={[
              <Button
                style={{ marginRight: "10px" }}
                variant="secondary"
                onClick={() => {
                  this.setState({
                    modalDeleteShow: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonNo" defaultMessage="Cancel" />
              </Button>,
              <Button style={{ marginRight: "3px" }} variant="primary" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonYes" defaultMessage="Delete" />
              </Button>
            ]}
            content={
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            }
          ></DraggableModal>

          <DraggableModal
            centered
            width={700}
            title={
              <>
                <FormattedMessage id="GeneralMessageChosenItemIsBelongToProject" defaultMessage="The choosen item is belong to project " />
                {Boolean(this.props.main) ? this.props.main.companyProject : ""}
              </>
            }
            visible={this.state.modalSendSelectShow}
            onCancel={() => {
              this.setState({ modalSendSelectShow: false });
            }}
            footer={[
              <Button
                style={{ marginRight: "10px" }}
                variant="secondary"
                onClick={() => {
                  this.setState({
                    modalSendSelectShow: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonNo" defaultMessage="No" />
              </Button>,
              <Button style={{ marginRight: "3px" }} variant="primary" onClick={this.sendSelected}>
                <FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />
              </Button>
            ]}
            content={
              <p>
                <FormattedMessage id="GeneralMessageDoYouWantToProceedThisRequest" defaultMessage="Do you want to proceed this request?" />
              </p>
            }
          ></DraggableModal>

          {this.state.showLastPurchaseModal && (
            <DraggableModal
              centered
              width={1500}
              title="Last Purchases"
              visible={this.state.showLastPurchaseModal}
              onCancel={() => {
                this.setState({ tableListLastPurchase: [], showLastPurchaseModal: false });
              }}
              footer={[
                <Button
                  style={{ marginRight: "3px" }}
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      tableListLastPurchase: [],
                      showLastPurchaseModal: false
                    });
                  }}
                >
                  Close
                </Button>
              ]}
              content={
                <Row>
                  <Col md={2}></Col>
                  <Col md={20}>
                    <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                      <Table
                        bordered
                        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                        columns={columnsLastPurchase}
                        dataSource={this.state.tableListLastPurchase}
                        loading={this.state.loading}
                      />
                    </div>
                  </Col>
                  <Col md={2}></Col>
                </Row>
              }
            ></DraggableModal>
          )}

          {this.state.richSearchModal && (
            <DraggableModal
              centered
              width={800}
              title={<FormattedMessage id="ProjectTakeOffQuantity.RichSearch" defaultMessage="Rich Search" />}
              open={this.state.richSearchModal}
              onCancel={() => {
                this.setState({ richSearchModal: false });
              }}
              footer={[
                <Button
                  style={{ marginRight: "3px" }}
                  variant="secondary"
                  onClick={() => {
                    this.setState({
                      richSearchModal: false,
                      selectedStockCard: null
                    });
                  }}
                >
                  Close
                </Button>,
                <Button
                  variant="primary"
                  onClick={() => {
                    let fObj = this.state.selectedStockCard;
                    this.setState({
                      richSearchModal: false
                    });

                    if (Boolean(fObj)) {
                      this.formRef.current.setFieldsValue({
                        code: fObj.code
                      });

                      if (Boolean(fObj.itemCodeCriterias) && fObj.itemCodeCriterias.length > 0) {
                        fObj.itemCodeCriterias.forEach(element => {
                          this.formRef.current.setFieldsValue({
                            [element.itemDefinition]: element.codeId
                          });
                        });
                      }

                      let unitName = "mm";
                      if (Boolean(fObj.itemDimension) && fObj.itemDimension.length > 0) {
                        fObj.itemDimension.forEach(element => {
                          this.formRef.current.setFieldsValue({
                            [element.itemDefinition]: element.codeId,
                            [element.itemDefinition + "Unit"]: element.unitId
                          });
                        });
                      }

                      if (Boolean(this.state.residueOfDimensions) && this.state.residueOfDimensions.length > 0) {
                        this.state.residueOfDimensions.forEach(element => {
                          if (element.definition === "Length" || element.definition === "Width") {
                            if (Boolean(element) && Boolean(element.unitId) && Boolean(element.units) && element.units.length > 0) {
                              let unit = element.units.find(p => p.id === element.unitId);
                              if (Boolean(unit)) {
                                unitName = unit.abbreviation;
                              }
                            }
                          }
                        });
                      }

                      this.fillPurchasingCriterias();
                      let row = {
                        code: fObj.code,
                        unit: unitName,
                        residueOfDimensions:
                          Boolean(this.state.residueOfDimensions) && this.state.residueOfDimensions.length > 0 ? this.state.residueOfDimensions : [],
                        unitSelectItems:
                          Boolean(this.state.unitSelectItems) && this.state.unitSelectItems.length > 0 ? this.state.unitSelectItems : []
                      };
                      this.calculateWeight(row);
                    }
                  }}
                >
                  Ok
                </Button>
              ]}
              content={
                <>
                  {Boolean(this.state.itemDescription) &&
                    Boolean(this.state.itemDescription.itemCodeCriterias) &&
                    this.state.itemDescription.itemCodeCriterias.length > 0 &&
                    this.state.itemDescription.itemCodeCriterias.map((element, index) => (
                      <Row>
                        <Col md={4}></Col>
                        <Col md={4}>{element.itemDefinition}</Col>
                        <Col md={8}>
                          <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            style={{ width: "100%" }}
                            onChange={value => {
                              element.codeId = value;
                              this.filterOnCriterias();
                            }}
                          >
                            {Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"]) &&
                              this.state["StockCard" + element.itemDefinition + "SelectItems"].map(i => (
                                <Option key={i.id} value={i.id}>
                                  {i.name}
                                </Option>
                              ))}
                          </Select>
                        </Col>
                        <Col md={8}></Col>
                      </Row>
                    ))}
                  {Boolean(this.state.itemDescription) &&
                    Boolean(this.state.itemDescription.itemDimension) &&
                    this.state.itemDescription.itemDimension.length > 0 &&
                    this.state.itemDescription.itemDimension.map(element => (
                      <Row>
                        <Col md={4}></Col>
                        <Col md={4}>{element.itemDefinition}</Col>
                        <Col md={8}>
                          <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            style={{ width: "100%" }}
                            onChange={value => {
                              element.codeId = value;
                              this.filterOnCriterias();
                            }}
                          >
                            {Boolean(this.state["StockCard" + element.itemDefinition + "SelectItems"]) &&
                              this.state["StockCard" + element.itemDefinition + "SelectItems"].map(i => (
                                <Option key={i.id} value={i.id}>
                                  {i.name}
                                </Option>
                              ))}
                          </Select>
                        </Col>
                        <Col md={8}></Col>
                      </Row>
                    ))}
                  <Row>
                    <Col md={8}></Col>
                    <Col md={8}>
                      <Select
                        style={{ width: "100%" }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        placeholder="Code"
                        value={Boolean(this.state.selectedStockCard) ? this.state.selectedStockCard.code : null}
                        onChange={value => {
                          const { codeSelectItems } = this.state;
                          if (Boolean(value)) {
                            let fObj = codeSelectItems.find(i => i.code === value);

                            this.setState({
                              selectedStockCard: fObj
                            });
                          } else {
                            this.setState({
                              selectedStockCard: null
                            });
                          }
                        }}
                      >
                        <Option key={null} value={null}>
                          Select
                        </Option>
                        {Boolean(this.state["tempCodeSelectItems"]) &&
                          this.state["tempCodeSelectItems"].map(i => (
                            <Option key={i.code} value={i.code}>
                              {i.renameText != null ? i.renameText + " / " + i.code : i.code}
                            </Option>
                          ))}
                      </Select>
                    </Col>
                    <Col md={8}></Col>
                  </Row>
                </>
              }
            ></DraggableModal>
          )}
          <DraggableModal
            visible={this.state.showModalProcurementProgress}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ showModalProcurementProgress: false })}
            width={700}
            centered
            content={
              <Badge.Ribbon text="Procurement Progress" placement="start" color="green" style={{ fontSize: 12 }}>
                <div className="card card-custom" style={{ padding: "0.1rem", minHeight: "100%" }}>
                  <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 mt-10 align-content-center">
                    <Steps direction="vertical" className="pl-5-custom anticon-custom">
                      {Boolean(this.state.procurementProgress) &&
                        Boolean(this.state.procurementProgress.details) &&
                        this.state.procurementProgress.details.length > 0 &&
                        this.state.procurementProgress.details.map((item, index) => {
                          return (
                            <Step
                              title={item.procurementProgressStepName}
                              status={
                                item.status === "Completed"
                                  ? "finish"
                                  : item.status === "In Progress"
                                    ? "process"
                                    : item.status === "Not Started"
                                      ? "wait"
                                      : "wait"
                              }
                              description={"( " + item.weight + " % )"}
                              icon={item.status === "In Progress" && <LoadingOutlined />}
                            />
                          );
                        })}
                    </Steps>
                  </div>
                  {!Boolean(this.state.procurementProgress) && (
                    <div className="card-body pt-4 custom-timeline pr-5 pl-5 mr-5 mt-10 align-content-center">
                      <h5>There is no result! Please check your Procurement Progress Settings or Product's Class Item on Stock Card page.</h5>
                    </div>
                  )}
                </div>
              </Badge.Ribbon>
            }
          ></DraggableModal>

          <DraggableModal
            centered
            width={1000}
            title={<FormattedMessage id="ProjectTakeOffQuantity.EquivalentItems" defaultMessage="Equivalent Items" />}
            open={this.state.showAddEquivalentModal}
            onCancel={() => {
              this.setState({ showAddEquivalentModal: false });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showAddEquivalentModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
            content={
              <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                <Table
                  rowKey="id"
                  rowSelection={{
                    type: "radio",
                    ...rowSelectionEquivalent
                  }}
                  bordered
                  scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                  columns={columnsEquivalent}
                  dataSource={this.state.tableListEquivalent}
                  loading={this.state.loading}
                  onChange={this.handleTableChangeEquivalent}
                  pagination={this.state.paginationEquivalent}
                />
              </div>
            }
          ></DraggableModal>
          <DraggableModal
            open={this.state.modalHistoryShow}
            okButtonProps={{ hidden: true }}
            onCancel={() => this.setState({ modalHistoryShow: false })}
            width={1000}
            centered
            scroll={{ x: 400, y: 400, scrollToFirstRowOnChange: true }}
            content={
              <Badge.Ribbon
                text={<FormattedMessage id="ProjectTakeOffQuantity.History" defaultMessage="History" />}
                placement="start"
                color="purple"
                style={{ fontSize: 12 }}
              >
                <div className="card card-custom" style={{ minHeight: "100%", padding: "2rem" }}>
                  <Timeline mode="alternate">
                    {Boolean(this.state.historyList) &&
                      this.state.historyList.length > 0 &&
                      this.state.historyList.map((item, index) => {
                        return (
                          <Timeline.Item>
                            {item.remoteUserName} <br />
                            {Boolean(item.lastUpdated) ? moment(item.lastUpdated).format("DD-MM-YYYY HH:mm") : ""}
                          </Timeline.Item>
                        );
                      })}
                  </Timeline>
                </div>
              </Badge.Ribbon>
            }
          ></DraggableModal>

          <DraggableModal
            title={
              <span>
                <ExclamationCircleOutlined style={{ color: "#b6db0f" }} />
                <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                  <FormattedMessage id="ProjectTakeOffQuantity.AreYouSure" defaultMessage="Are You Sure ?" />
                </span>
              </span>
            }
            centered
            open={this.state.modalEquivalentShow}
            onOk={() => this.confirmEquivalent()}
            onCancel={() => this.setState({ modalEquivalentShow: false, selectedRowKeysEquivalent: null })}
            okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
            cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
            content={<FormattedMessage id="ProjectTakeOffQuantity.DoYouReallyWantToAdd" defaultMessage="Do you really want to add ?" />}
          ></DraggableModal>

          <DraggableModal
            title={<FormattedMessage id="ProjectTakeOffQuantity.Note" defaultMessage="Note" />}
            keyboard={false}
            maskClosable={false}
            open={this.state.showAddNoteModal}
            onCancel={() => {
              this.setState({ showAddNoteModal: false });
            }}
            okButtonProps={{ hidden: false }}
            onOk={() => this.formRefNote.current.submit()}
            width={800}
            centered
            content={
              <Form initialValues={{ remember: false }} onFinish={this.saveNote} ref={this.formRefNote}>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProjectTakeOffQuantity.Message" defaultMessage="Message" />}
                  name="toMessage"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <TextArea style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ProjectTakeOffQuantity.Personnel" defaultMessage="Personnel" />}
                  name="toPersonnelLogId"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                    {Boolean(this.state["toPersonnelLogIdSelectItems"]) &&
                      this.state["toPersonnelLogIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Form>
            }
          />

          <DraggableModal
            title={<FormattedMessage id="EldenInbox.User" defaultMessage="User" />}
            open={this.state.modalUserShow}
            okButtonProps={{ hidden: false }}
            onCancel={() => this.setState({ modalUserShow: false, userIds: [] })}
            onOk={() => this.setState({ modalUserShow: false })}
            width={800}
            centered
            content={
              <Form.Item
                {...layout}
                label={<FormattedMessage id="EldenInbox.User" defaultMessage="User" />}
                name="users"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  allowClear
                  mode="multiple"
                  onChange={value => {
                    this.setState({
                      userIds: value
                    });
                  }}
                >
                  {Boolean(this.state["userSelectItems"]) &&
                    this.state["userSelectItems"].length > 0 &&
                    this.state["userSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTakeOffQuantity);
