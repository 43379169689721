import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest, API_BASE_ROOT } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Upload } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Select } from "antd";
import { DatePicker } from "antd";
import { Input } from "antd";
import { InputNumber } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;

export class JobOfferEducation extends Component {
  constructor({ jobOfferId, personnelName }) {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "JobOfferEducation",
      controllerName: "jobOfferEducation",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      jobOfferId: jobOfferId,
      personnelName: personnelName,
      createWhereParams: [
        {
          key: "jobOfferId",
          value: jobOfferId
        }
      ],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };
  fillComboboxes = async () => {
    var responsecountryId = await handleRequest("GET", "/api/" + this.state.controllerName + "/countries");
    if (responsecountryId.type === "ERROR") {
      error(responsecountryId);
    }
    this.setState({
      countryIdSelectItems: Boolean(responsecountryId.data) ? responsecountryId.data : []
    });
    this.fillCode("JobOfferEducationgraduationTypeId");
    this.fillCode("JobOfferEducationdegreeTypeId");
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
      graduationDate: moment(row.graduationDate),
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };



    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);



    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      jobOfferId: this.state.jobOfferId,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };
  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="JobOfferEducation.graduationTypeId" defaultMessage="Graduation Type" />,
        key: "graduationTypeId",
        render: record => {
          return record.graduationTypeName;
        }, ...getColumnFilter({
          inputName: "graduationTypeName", inputType: "SELECT_ADDABLE",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="JobOfferEducation.degreeTypeId" defaultMessage="Degree Type" />,
        key: "degreeTypeId",
        render: record => {
          return record.degreeTypeName;
        }, ...getColumnFilter({
          inputName: "degreeTypeName", inputType: "SELECT_ADDABLE",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="JobOfferEducation.nameOfSchool" defaultMessage="Name Of School" />,
        key: "nameOfSchool",
        render: record => {
          return record.nameOfSchool;
        }, ...getColumnFilter({
          inputName: "nameOfSchool", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="JobOfferEducation.countryId" defaultMessage="Country" />,
        key: "countryId",
        render: record => {
          return record.countryName;
        }, ...getColumnFilter({
          inputName: "countryName", inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="JobOfferEducation.graduationDate" defaultMessage="Graduation Date" />,
        key: "graduationDate",
        render: record => {
          return record.graduationDate;
        }, ...getColumnFilter({
          inputName: "graduationDate", inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="JobOfferEducation.graduationScore" defaultMessage="Graduation Score" />,
        key: "graduationScore",
        render: record => {
          return record.graduationScore;
        }, ...getColumnFilter({
          inputName: "graduationScore", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="JobOfferEducation.graduationScoreOutOf" defaultMessage="Graduation Score Out" />,
        key: "graduationScoreOutOf",
        render: record => {
          return record.graduationScoreOutOf;
        }, ...getColumnFilter({
          inputName: "graduationScoreOutOf", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="JobOfferEducation.graduationCertificatePath" defaultMessage="Graduation Certificate" />,
        key: "graduationCertificatePath",
        render: record => {
          return record.graduationCertificatePath;
        }, ...getColumnFilter({
          inputName: "graduationCertificatePath", inputType: "FILEUPLOAD",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
    ];

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const excelExport = (<>   
          {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="JobOfferEducation" hideElement={true}>             <ExcelSheet name="JobOfferEducation" data={this.state.tableList}>               <ExcelColumn label="Graduation Type" value="graduationTypeName" />
      <ExcelColumn label="Degree Type" value="degreeTypeName" />
      <ExcelColumn label="Name Of School" value="nameOfSchool" />
      <ExcelColumn label="Country" value="countryName" />
      <ExcelColumn label="Graduation Date" value="graduationDate" />
      <ExcelColumn label="Graduation Score" value="graduationScore" />
      <ExcelColumn label="Graduation Score Out" value="graduationScoreOutOf" />
      <ExcelColumn label="Graduation Certificate" value="graduationCertificatePath" />
    </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel}
     icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)}      */}
       </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="jobOfferEducation.pageTitle" defaultMessage="Job Offer Education" />} placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>             <Col md={21}></Col>             <Col md={1}>{excelExport}</Col>             <Col md={1}>               <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>             </Col>             <Col md={1}>               <AntButton style={{ border: "0px" }} hidden={!this.state.hideInputs} onClick={this.onClickCreateNew} icon={<CreateIcon color="secondary" fontSize="large" />}               ></AntButton>               <AntButton style={{ border: "0px" }} hidden={this.state.hideInputs} onClick={this.onClickCreateNew} icon={<RemoveCircle color="error" fontSize="large" />}               ></AntButton>             </Col>           </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="JobOfferEducation.graduationTypeId" defaultMessage="Graduation Type" />}
                  name="graduationTypeId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }} optionFilterProp="children"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("JobOfferEducationgraduationTypeId")}
                          >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["JobOfferEducationgraduationTypeIdSelectItems"]) &&
                      this.state["JobOfferEducationgraduationTypeIdSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="JobOfferEducation.degreeTypeId" defaultMessage="Degree Type" />}
                  name="degreeTypeId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }} optionFilterProp="children"
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("JobOfferEducationdegreeTypeId")}
                          >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add item" />
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["JobOfferEducationdegreeTypeIdSelectItems"]) &&
                      this.state["JobOfferEducationdegreeTypeIdSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="JobOfferEducation.nameOfSchool" defaultMessage="Name O fSchool" />}
                  name="nameOfSchool"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="JobOfferEducation.countryId" defaultMessage="Country" />}
                  name="countryId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                  >
                    {Boolean(this.state["countryIdSelectItems"]) && this.state["countryIdSelectItems"].length > 0 &&
                      this.state["countryIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="JobOfferEducation.graduationDate" defaultMessage="Graduation Date" />}
                  name="graduationDate"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={
                    <FormattedMessage
                      id="JobOfferEducation.GraduationScore"
                      defaultMessage="Graduation Score"
                      rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    />
                  }
                >
                  <Row>
                    <Col span={11}>
                      <Form.Item
                        name="graduationScore"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber
                          style={{ width: "100px" }}
                          parser={value => value.replace(",", ".")}
                          formatter={value => value.replace(".", ",")}
                          className="w-100"
                          placeholder="    "
                          min={0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>
                      <Form.Item
                        name="graduationScoreOutOf"
                        rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                      >
                        <InputNumber
                          style={{ width: "100px" }}
                          parser={value => value.replace(",", ".")}
                          formatter={value => value.replace(".", ",")}
                          className="w-100"
                          placeholder="    "
                          min={0}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="JobOfferEducation.GraduationCertificate" defaultMessage="Graduation Certificate" />}
                  name="graduationCertificatePath"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Upload
                    {...props}
                    showUploadList={true}
                    fileList={this.state.fileList}
                    onChange={({ fileList }) => {
                      if (fileList.length === 1) {
                        if (Boolean(fileList[0].response)) {
                          fileList[0].url = fileList[0].response.url;
                          showSuccess("file uploaded successfully");
                        }
                        this.setState({
                          fileList: fileList
                        });
                      }
                    }}
                    beforeUpload={fileList => {
                      if (this.state.fileList.length > 0) {
                        showError("You have been already choose a file! For change firstly remove the uploaded file.");
                        return false;
                      }
                      return true;
                    }}
                    onRemove={fileList => {
                      this.setState({
                        fileList: []
                      });
                    }}
                  >
                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> Upload
                    </AntButton>
                  </Upload>
                </Form.Item>
              }

              <div hidden={this.state.hideSave}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>                     <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">                       <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
              <div hidden={this.state.hideUpdate}>               {<Row gutter={[16, 16]}>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>                       <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />                     </Button>                   </Col>                   <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>                     <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">                       <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />                     </Button>                   </Col>                   <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>                 </Row>}             </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (<div className="alert alert-custom alert-light">                 <div className="alert-icon">                   <FilterOutlined />                 </div>                 <div className="alert-text">                   {this.state.searchCriteriaModels.map((val, i) => { return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">                         <span className="mr-2">{val.label}</span>                         <strong className="mr-2">{val.type}</strong>                         <span className="mr-2">{val.operationValue}</span>                         <span className="mr-2">{val.showValue}</span>                         <span>                           <Tooltip title="Remove">                             <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />                           </Tooltip>                         </span>                       </div>); })}                 </div>                 <div className="alert-close">                   <Tooltip title="Remove All">                     <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />                   </Tooltip>                 </div>               </div>)}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">           <Table bordered scroll={{ x: 900, scrollToFirstRowOnChange: true }} columns={columns} dataSource={this.state.tableList} loading={this.state.loading} onChange={this.handleTableChange} pagination={this.state.pagination} />         </div>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(JobOfferEducation);
