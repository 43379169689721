import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { showError, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Button as AntButton, Col, Row, Select, Space, InputNumber, Tooltip } from "antd";
import { Input, Breadcrumb } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, MinusCircleOutlined, PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import "./style.css";
import DraggableModal from "../../../Components/DraggableModal";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const { Option } = Select;

export class ProjectsTreeCompanyApartment extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "ProjectsTreeCompanyApartment",
      controllerName: "apartment",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      houseRoomList: [],
      loadingButtonSubmit: false,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRefTotalNumberOfApartments = React.createRef();
  formRefApartmentTypes = React.createRef();
  formRefApartmentsAndTypes = React.createRef();
  formRefSectionAndApartments = React.createRef();
  formRefNumberOfFloors = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    fillFilterOperations();
    const { pagination } = this.state;
    this.restartTable({ pagination });
  };

  fillComboboxes = async () => {
    this.fillApartmentTypes("Apartment.ApartmentType");
    this.fillCode("Apartment.TypeOfHouses");
  };

  fillApartmentAndTypes = async () => {
    this.fillApartmentTypes("Apartment.ApartmentType");
    var responseApartmentId = await handleRequest("GET", "/api/" + this.state.controllerName + "/apartmentId");
    if (responseApartmentId.type === "ERROR") {
      error(responseApartmentId);
    }

    this.setState({
      apartmentIdSelectItems: Boolean(responseApartmentId.data) ? responseApartmentId.data : [],
      currentApartmentIdSelectItems: Boolean(responseApartmentId.data) ? responseApartmentId.data : []
    });
  };

  fillApartmentsWithTypeNotNull = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/apartmentsWithTypeNotNull");
    if (response.type === "ERROR") {
      error(response);
    }

    this.setState({
      apartmentsWithTypeNotNullList: Boolean(response.data) ? response.data : []
    });
  };

  fillApartmentsWithSectionNotNull = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/apartmentsWithSectionNotNull");
    if (response.type === "ERROR") {
      error(response);
    }

    this.setState({
      apartmentsWithSectionNotNull: Boolean(response.data) ? response.data : []
    });
  };

  fillNumberOfFloorsNotNull = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/numberOfFloorsNotNull");
    if (response.type === "ERROR") {
      error(response);
    }

    this.setState({
      numberOfFloorsNotNull: Boolean(response.data) ? response.data : []
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: "",
        tableVisibleModal: true
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder,
          tableVisibleModal: true
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: "",
          tableVisibleModal: true
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  resetInputs = () => { };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickTotalNumberOfApartment = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getNumberOfAllFloors");
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.setState(
        {
          totalApartmentsModal: true,
          tableVisibleModal: false
        },
        () => {
          if (Boolean(this.formRefTotalNumberOfApartments) && Boolean(this.formRefTotalNumberOfApartments.current)) {
            this.formRefTotalNumberOfApartments.current.setFieldsValue({
              totalNumberOfApartments: response.data
            });
          }
        }
      );
    }
  };

  saveTotalNumberOfApartments = async values => {
    if (Boolean(values) && Boolean(values.totalNumberOfApartments) && values.totalNumberOfApartments > 0) {
      var response = await handleRequest(
        "POST",
        "/api/" + this.state.controllerName + "/saveTotalNumberOfApartments/" + values.totalNumberOfApartments
      );
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.fillTypesOfApartmentsModal();
      }
    } else {
      showWarning("Not found.");
    }
  };

  fillTypesOfApartmentsModal() {
    this.cancel();
    this.fillApartmentAndTypes();
    this.setState(
      {
        totalApartmentsModal: false,
        typesOfApartmentsModal: true
      },
      () => {
        if (Boolean(this.formRefApartmentTypes) && Boolean(this.formRefApartmentTypes.current)) {
          this.formRefApartmentTypes.current.resetFields();
          let apartmentTypes = [];
          if (this.state["Apartment.ApartmentTypeSelectItems"] && this.state["Apartment.ApartmentTypeSelectItems"].length > 0) {
            this.state["Apartment.ApartmentTypeSelectItems"].forEach(element => {
              apartmentTypes.push({ apartmentTypeName: element.name });
            });
          }
          this.formRefApartmentTypes.current.setFieldsValue({
            apartmentTypes: apartmentTypes
          });
        }
      }
    );
  }

  saveApartmentTypes = async values => {
    if (Boolean(values.apartmentTypes) && values.apartmentTypes.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveApartmentTypes", values.apartmentTypes);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.fillApartmentsAndTypesModal();
      }
    } else {
      showWarning("Please enter at least one apartment type.");
      this.setState({
        loadingButtonSubmit: false
      });
    }
  };

  fillApartmentsAndTypesModal() {
    this.fillApartmentAndTypes();
    this.fillApartmentTypes("Apartment.ApartmentType");
    this.setState(
      {
        typesOfApartmentsModal: false,
        apartmentsAndTypesModal: true
      },
      async () => {
        if (Boolean(this.formRefApartmentsAndTypes) && Boolean(this.formRefApartmentsAndTypes.current)) {
          await this.fillApartmentsWithTypeNotNull();

          const { apartmentsWithTypeNotNullList } = this.state;
          let apartmentsAndTypes = [];
          console.log("data", apartmentsWithTypeNotNullList);
          if (Boolean(apartmentsWithTypeNotNullList) && apartmentsWithTypeNotNullList.length > 0) {
            apartmentsWithTypeNotNullList.forEach(element => {
              apartmentsAndTypes.push({ id: element.id, apartmentTypeId: element.apartmentTypeId });
            });
          }
          this.formRefApartmentsAndTypes.current.setFieldsValue({
            apartmentsAndTypes: apartmentsAndTypes
          });
          this.calcApartmentsForApartmentsAndTypes();
        }

        this.setState({
          loadingButtonSubmit: false
        });
      }
    );
  }

  calcApartmentsForApartmentsAndTypes = () => {
    const apartmentsAndTypes = this.formRefApartmentsAndTypes.current.getFieldValue("apartmentsAndTypes");
    console.log("apartmentsAndTypes", apartmentsAndTypes);
    const { apartmentIdSelectItems } = this.state;
    console.log("apartmentIdSelectItems", apartmentIdSelectItems);
    let selectedApartments = [];
    if (Boolean(apartmentIdSelectItems) && apartmentIdSelectItems.length > 0) {
      apartmentIdSelectItems.forEach(element => {
        if (apartmentsAndTypes != null && apartmentsAndTypes.length > 0) {
          let row = apartmentsAndTypes.find(p => Boolean(p) && Boolean(p.id) && p.id === element.key);
          if (!Boolean(row)) {
            selectedApartments.push({ key: element.key, value: element.value });
          }
        } else {
          selectedApartments.push({ key: element.key, value: element.value });
        }
      });
    }
    this.setState({ currentApartmentIdSelectItems: selectedApartments });
  };

  calcApartmentsSectionsApartmentsAndTypes = () => {
    const apartmentSections = this.formRefSectionAndApartments.current.getFieldValue("apartmentSections");
    console.log("apartmentSections", apartmentSections);
    let apartmentIdSelectItems = this.state["Apartment.ApartmentTypeSelectItems"];
    console.log("apartmentIdSelectItems", apartmentIdSelectItems);
    let selectedApartmentTypes = [];
    if (Boolean(apartmentIdSelectItems) && apartmentIdSelectItems.length > 0) {
      apartmentIdSelectItems.forEach(element => {
        if (apartmentSections != null && apartmentSections.length > 0) {
          let row = apartmentSections.find(p => Boolean(p) && Boolean(p.apartmentTypeId) && p.apartmentTypeId === element.id);
          if (!Boolean(row)) {
            selectedApartmentTypes.push({ id: element.id, name: element.name });
          }
        } else {
          selectedApartmentTypes.push({ id: element.id, name: element.name });
        }
      });
    }
    console.log("currentApartmentTypeSelectItems", selectedApartmentTypes);
    this.setState({ currentApartmentTypeSelectItems: selectedApartmentTypes });
  };

  saveApartmentsAndTypes = async values => {
    if (Boolean(values.apartmentsAndTypes) && values.apartmentsAndTypes.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveApartmentsAndTypes", values.apartmentsAndTypes);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.fillSectionsOfApartmentsModal();
      }
    }
  };

  fillSectionsOfApartmentsModal() {
    this.cancel();
    this.setState(
      {
        sectionsOfApartmentsModal: true
      },
      async () => {
        if (Boolean(this.formRefSectionAndApartments) && Boolean(this.formRefSectionAndApartments.current)) {
          await this.fillApartmentsWithSectionNotNull();
          const { apartmentsWithSectionNotNull, apartmentIdSelectItems } = this.state;
          let apartmentSections = [];
          let currentApartmentTypeSelectItems = [];
          console.log("data", apartmentsWithSectionNotNull);
          if (Boolean(apartmentsWithSectionNotNull) && apartmentsWithSectionNotNull.length > 0) {
            apartmentsWithSectionNotNull.forEach(element => {
              apartmentSections.push({ apartmentTypeId: element.apartmentTypeId, section: element.section });

              let hasType = currentApartmentTypeSelectItems.find(p => p.id === element.apartmentTypeId);
              if (!Boolean(hasType) && Boolean(element.apartmentTypeId) && Boolean(element.apartmentTypeName)) {
                currentApartmentTypeSelectItems.push({ id: element.apartmentTypeId, name: element.apartmentTypeName });
              }
            });
          }
          this.formRefSectionAndApartments.current.setFieldsValue({
            apartmentSections: apartmentSections
          });

          let selectedApartments = [];
          if (Boolean(apartmentIdSelectItems) && apartmentIdSelectItems.length > 0) {
            apartmentIdSelectItems.forEach(element => {
              selectedApartments.push({ key: element.key, value: element.value });
            });
          }
          console.log("currentApartmentTypeSelectItems", currentApartmentTypeSelectItems);

          this.setState({
            currentApartmentIdSelectItems: selectedApartments,
            currentApartmentTypeSelectItems: currentApartmentTypeSelectItems,
            loadingButtonSubmit: false,
            apartmentsAndTypesModal: false
          });
        }
      }
    );
  }

  saveSectionAndApartments = async values => {
    if (Boolean(values.apartmentSections) && values.apartmentSections.length > 0) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveSectionAndApartments", values.apartmentSections);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        this.setState(
          {
            sectionsOfApartmentsModal: false,
            numberOfFloorsModal: true
          },
          async () => {
            if (Boolean(this.formRefNumberOfFloors) && Boolean(this.formRefNumberOfFloors.current)) {
              await this.fillNumberOfFloorsNotNull();

              const { numberOfFloorsNotNull } = this.state;
              console.log("data", numberOfFloorsNotNull);
              if (Boolean(numberOfFloorsNotNull)) {
                let allSameVariableType = numberOfFloorsNotNull.allSameVariableType;
                let floor = numberOfFloorsNotNull.floor;
                let numberOfFloors = numberOfFloorsNotNull.numberOfFloors;
                this.formRefNumberOfFloors.current.setFieldsValue({
                  allSameVariableType: allSameVariableType,
                  floor: floor,
                  numberOfFloors: numberOfFloors
                });

                this.setState({
                  allSameVariableType: allSameVariableType
                });
              } else {
                this.formRefNumberOfFloors.current.resetFields();
              }
            }
            this.setState({
              loadingButtonSubmit: false
            });
          }
        );
      }
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillApartmentTypes = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
        //  currentApartmentTypeSelectItems: response.data,
      });
    }
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        title: <FormattedMessage id="Apartment.ApartmentNo" defaultMessage="Apartment no" />,
        key: "id",
        render: record => {
          return record.name.match(/\d+/g);
        }
      },
      {
        title: <FormattedMessage id="Apartment.ApartmentName" defaultMessage="Apartment name" />,
        key: "name",
        render: record => {
          return record.name;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.name) ? this.state.pagination.name : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  name: e.target.value,
                  nameColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.nameColor) ? this.state.pagination.nameColor : "#bfbfbf" }} />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Apartment.ApartmentType" defaultMessage="Apartment Type" />,
        key: "apartmentTypeId",
        render: record => {
          return record.apartmentTypeName;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: "300px" }}
              value={Boolean(this.state.pagination.apartmentTypeId) ? this.state.pagination.apartmentTypeId : null}
              onChange={async value => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  apartmentTypeId: value,
                  apartmentTypeIdColor: Boolean(value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            >
              {Boolean(this.state["Apartment.ApartmentTypeSelectItems"]) &&
                this.state["Apartment.ApartmentTypeSelectItems"].length > 0 &&
                this.state["Apartment.ApartmentTypeSelectItems"].map(i => (
                  <Option key={i.id} value={i.id}>
                    {i.name}
                  </Option>
                ))}
            </Select>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{ color: Boolean(this.state.pagination.apartmentTypeIdColor) ? this.state.pagination.apartmentTypeIdColor : "#bfbfbf" }}
          />
        ),
        sorter: true
      },
      {
        title: <FormattedMessage id="Apartment.Sections" defaultMessage="Sections" />,
        key: "section",
        render: record => {
          return record.section;
        },
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: "auto" }}
              value={Boolean(this.state.pagination.section) ? this.state.pagination.section : null}
              onChange={async e => {
                const { pagination } = this.state;
                let _pagination = {
                  ...pagination,
                  section: e.target.value,
                  sectionColor: Boolean(e.target.value) ? "#1890ff" : "#bfbfbf"
                };
                this.setState({
                  pagination: _pagination
                });

                this.restartTable({ _pagination, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
              }}
            />
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined style={{ color: Boolean(this.state.pagination.sectionColor) ? this.state.pagination.sectionColor : "#bfbfbf" }} />
        ),
        sorter: true
      }
    ];

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <div className="card card-custom card-stretch">
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">

              <Breadcrumb separator=">">
                <Breadcrumb.Item className={this.state.tableVisibleModal ? "customBreadcrumbItem" : ""}>
                  <a
                    href="#"
                    onClick={() => {
                      this.setState(
                        {
                          tableVisibleModal: true,

                          totalApartmentsModal: false,
                          typesOfApartmentsModal: false,
                          apartmentsAndTypesModal: false,
                          sectionsOfApartmentsModal: false
                        },
                        () => {
                          const { pagination } = this.state;
                          this.restartTable({ pagination });
                        }
                      );
                    }}
                  >
                    <Space>
                      <SVG style={{ width: '20px', height: '20px' }} src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>
                      <FormattedMessage id="ProjectsTreeCompanyApartment.Apartment" defaultMessage="Apartment" />
                    </Space>
                  </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a
                    href="#"
                    className={this.state.totalApartmentsModal ? "customBreadcrumbItem" : ""}
                    onClick={() => {
                      this.setState(
                        {
                          totalApartmentsModal: true,

                          tableVisibleModal: false,
                          typesOfApartmentsModal: false,
                          apartmentsAndTypesModal: false,
                          sectionsOfApartmentsModal: false
                        },
                        () => {
                          this.onClickTotalNumberOfApartment();
                        }
                      );
                    }}
                  >
                    <FormattedMessage id="ProjectsTreeCompanyApartment.Numbers" defaultMessage="Numbers" />
                  </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a
                    href="#"
                    className={this.state.typesOfApartmentsModal ? "customBreadcrumbItem" : ""}
                    onClick={() => {
                      this.setState(
                        {
                          typesOfApartmentsModal: true,

                          tableVisibleModal: false,
                          totalApartmentsModal: false,
                          apartmentsAndTypesModal: false,
                          sectionsOfApartmentsModal: false
                        },
                        () => {
                          this.fillTypesOfApartmentsModal();
                        }
                      );
                    }}
                  >
                    <FormattedMessage id="ProjectsTreeCompanyApartment.Types" defaultMessage="Types" />
                  </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a
                    href="#"
                    className={this.state.apartmentsAndTypesModal ? "customBreadcrumbItem" : ""}
                    onClick={() => {
                      this.setState(
                        {
                          apartmentsAndTypesModal: true,

                          tableVisibleModal: false,
                          totalApartmentsModal: false,
                          typesOfApartmentsModal: false,
                          sectionsOfApartmentsModal: false
                        },
                        () => {
                          this.fillApartmentsAndTypesModal();
                        }
                      );
                    }}
                  >
                    <FormattedMessage id="ProjectsTreeCompanyApartment.ApartmentsTypes" defaultMessage="Apartments-Types" />
                  </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a
                    href="#"
                    className={this.state.sectionsOfApartmentsModal ? "customBreadcrumbItem" : ""}
                    onClick={() => {
                      this.setState(
                        {
                          sectionsOfApartmentsModal: true,

                          tableVisibleModal: false,
                          totalApartmentsModal: false,
                          typesOfApartmentsModal: false,
                          apartmentsAndTypesModal: false
                        },
                        () => {
                          this.fillSectionsOfApartmentsModal();
                        }
                      );
                    }}
                  >
                    <FormattedMessage id="ProjectsTreeCompanyApartment.Sections" defaultMessage="Sections" />
                  </a>
                </Breadcrumb.Item>
              </Breadcrumb>

            </div>
            <div className="card-toolbar"></div>
          </div>

          <br />
          <h3 className="card-label font-weight-bolder text-dark">
            <FormattedMessage id="ProjectsTreeCompanyApartment.Apartment" defaultMessage="Apartment" />
          </h3>
        </div>

        <>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                      <span className="mr-2">{val.operationValue}</span>
                      <span className="mr-2">{val.showValue}</span>
                      <span>

                        <Tooltip title="Remove">
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                        </Tooltip>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              size="small"
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>

          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={19} lg={19} xl={19} xxl={19}></Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Button
                onClick={() => {
                  this.props.nextTab();
                }}
                style={{ width: "100%" }}
                variant="primary"
              >
                <FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />
              </Button>
            </Col>
          </Row>
        </>

        <DraggableModal
          title={<FormattedMessage id="ProjectsTreeCompanyApartment.Numbers" defaultMessage="Numbers" />}
          centered
          open={this.state.totalApartmentsModal}
          onCancel={() => this.setState({ totalApartmentsModal: false })}
          onOk={() => {
            this.formRefTotalNumberOfApartments.current.submit();
          }}
          okText={<FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          cancelButtonProps={{ hidden: true }}
          width={750}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveTotalNumberOfApartments} ref={this.formRefTotalNumberOfApartments}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="ProjectsTreeCompanyApartment.NumberOfApartments" defaultMessage="Number Of Apartments" />}
                name="totalNumberOfApartments"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber
                  value={Boolean(this.state.currentNumberOfApartments) ? this.state.currentNumberOfApartments : 1}
                  min={1}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Form>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="ProjectsTreeCompanyApartment.Types" defaultMessage="Types" />}
          centered
          open={this.state.typesOfApartmentsModal}
          onCancel={() => this.setState({ typesOfApartmentsModal: false })}
          onOk={() => {
            this.formRefApartmentTypes.current.submit();
          }}
          okText={<FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          cancelButtonProps={{ hidden: true }}
          width={750}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveApartmentTypes} ref={this.formRefApartmentTypes}>
              <Form.List name="apartmentTypes">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyApartment.ApartmentType"} defaultMessage={"Apartment Type"} />
                            </th>
                            <th colSpan="1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "apartmentTypeName"]}>
                                    <Input />
                                  </Form.Item>
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"ProjectsTreeCompanyApartment.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="ProjectsTreeCompanyApartment.ApartmentsTypes" defaultMessage="Apartments-Types" />}
          centered
          open={this.state.apartmentsAndTypesModal}
          onCancel={() => this.setState({ apartmentsAndTypesModal: false })}
          onOk={() => {
            this.setState(
              {
                loadingButtonSubmit: true
              },
              () => {
                this.formRefApartmentsAndTypes.current.submit();
              }
            );
          }}
          okText={<FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          cancelButtonProps={{ hidden: true }}
          width={750}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveApartmentsAndTypes} ref={this.formRefApartmentsAndTypes}>
              <Form.List name="apartmentsAndTypes">
                {(fields, { add, remove }) => {
                  const handleAddClick = () => {
                    this.calcApartmentsForApartmentsAndTypes();
                    add();
                  };

                  const handleRemoveClick = fieldKey => {
                    remove(fieldKey);
                    this.calcApartmentsForApartmentsAndTypes();
                  };

                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyApartment.Apartment"} defaultMessage={"Apartment"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyApartment.ApartmentType"} defaultMessage={"Apartment Type"} />
                            </th>
                            <th colSpan="1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "id"]}>
                                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                      {Boolean(this.state["currentApartmentIdSelectItems"]) &&
                                        this.state["currentApartmentIdSelectItems"].length > 0 &&
                                        this.state["currentApartmentIdSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "apartmentTypeId"]}>
                                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                      {Boolean(this.state["Apartment.ApartmentTypeSelectItems"]) &&
                                        this.state["Apartment.ApartmentTypeSelectItems"].length > 0 &&
                                        this.state["Apartment.ApartmentTypeSelectItems"].map(i => (
                                          <Option key={i.id} value={i.id}>
                                            {i.name}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => handleRemoveClick(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"ProjectsTreeCompanyApartment.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          }
        />

        <DraggableModal
          title={<FormattedMessage id="ProjectsTreeCompanyApartment.Sections" defaultMessage="Sections" />}
          centered
          open={this.state.sectionsOfApartmentsModal}
          onCancel={() => this.setState({ sectionsOfApartmentsModal: false })}
          onOk={() => {
            let exception = false;
            const apartmentSections = this.formRefSectionAndApartments.current.getFieldValue("apartmentSections");
            console.log("apartmentSections", apartmentSections);
            const uniqueData = [];
            if (Boolean(apartmentSections) && apartmentSections.length > 0) {
              apartmentSections.forEach(item => {
                const { apartmentTypeId, section } = item;
                if (!uniqueData.some(uniqueItem => uniqueItem.apartmentTypeId === apartmentTypeId && uniqueItem.section === section)) {
                  uniqueData.push(item);
                } else {
                  let apartmentType = this.state["Apartment.ApartmentTypeSelectItems"].find(p => p.id === apartmentTypeId);
                  showWarning(
                    "Apartment and Section must be unique. (Apartment Type: " + apartmentType.name + ", Section: " + section + ")"
                  );
                  exception = true;
                  return;
                }
              });
            }

            if (!exception) {
              this.formRefSectionAndApartments.current.submit();
            }
          }}
          okText={<FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
          cancelButtonProps={{ hidden: true }}
          width={750}
          content={
            <Form initialValues={{ remember: false }} onFinish={this.saveSectionAndApartments} ref={this.formRefSectionAndApartments}>
              <Form.List name="apartmentSections">
                {(fields, { add, remove }) => {
                  const handleAddClick = () => {
                    // this.calcApartmentsSectionsApartmentsAndTypes();
                    add();
                  };

                  const handleRemoveClick = fieldKey => {
                    remove(fieldKey);
                    // this.calcApartmentsSectionsApartmentsAndTypes();
                  };

                  const handleCopyClick = fieldKey => {
                    let newIndex = parseInt(fieldKey, 10) + 1;
                    const apartmentSections = this.formRefSectionAndApartments.current.getFieldValue("apartmentSections");
                    let apartmentSection = { ...apartmentSections[fieldKey] };
                    apartmentSection.section = "";

                    apartmentSections.splice(newIndex, 0, apartmentSection);

                    console.log("apartmentSections", apartmentSections);

                    this.formRefSectionAndApartments.current.setFieldsValue({
                      apartmentSections: apartmentSections
                    });

                    // this.calcApartmentsSectionsApartmentsAndTypes();
                  };

                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyApartment.ApartmentType"} defaultMessage={"Apartment Type"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"ProjectsTreeCompanyApartment.Sections"} defaultMessage={"Sections"} />
                            </th>
                            <th colSpan="1"></th>
                            <th colSpan="1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  <Form.Item noStyle name={[field.name, "apartmentTypeId"]}>
                                    <Select showSearch allowClear optionFilterProp="children" style={{ width: "100%" }}>
                                      {Boolean(this.state["currentApartmentTypeSelectItems"]) &&
                                        this.state["currentApartmentTypeSelectItems"].length > 0 &&
                                        this.state["currentApartmentTypeSelectItems"].map(i => (
                                          <Option key={i.id} value={i.id}>
                                            {i.name}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "section"]}>
                                    <Input style={{ width: "100%" }} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <PlusOutlined onClick={() => handleCopyClick(field.name)} />
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => handleRemoveClick(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"ProjectsTreeCompanyApartment.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          }
        />
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTreeCompanyApartment);
