import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { API_BASE_ROOT, handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Tooltip, Space, Upload, Image } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined, Add } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import DraggableModal from "../../Components/DraggableModal";
import { Select } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import { Input } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { PrepareFileUploadEdit, PrepareFileUploadSave } from "../../Components/ElementUtils";
import BreakdownType from "./BreakdownType";
import BreakdownReason from "./BreakdownReason";
import BreakdownSolution from "./BreakdownSolution";

const { Option } = Select;

export class Breakdown extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "Breakdown",
      controllerName: "breakdown",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.fillBreakdownReason();
    this.fillBreakdownTypeAll();
    this.fillProposedSolutionId();

    var responseEquipment = await handleRequest("GET", "/api/" + this.state.controllerName + "/equipmentLogId");
    if (responseEquipment.type === "ERROR") {
      error(responseEquipment);
    }

    this.setState({
      equipmentLogIdSelectItems: Boolean(responseEquipment.data) ? responseEquipment.data : [],
    });
  };

  fillBreakdownTypeId = async (equipmentLogId) => {
    if (Boolean(equipmentLogId) && equipmentLogId > 0) {
      var responseBreakdownTypeId = await handleRequest("GET", "/api/" + this.state.controllerName + "/breakdownTypeId/" + equipmentLogId);
      if (responseBreakdownTypeId.type === "ERROR") {
        error(responseBreakdownTypeId);
      }
      this.setState({
        breakdownTypeIdSelectItems: Boolean(responseBreakdownTypeId.data) ? responseBreakdownTypeId.data : []
      });
    } else {
      this.setState({
        breakdownTypeIdSelectItems: []
      });
    }
  };

  fillBreakdownReason = async () => {
    var responseReasonOfBreakdownId = await handleRequest("GET", "/api/" + this.state.controllerName + "/reasonOfBreakdownId");
    if (responseReasonOfBreakdownId.type === "ERROR") {
      error(responseReasonOfBreakdownId);
    }
    this.setState({
      reasonOfBreakdownIdSelectItems: Boolean(responseReasonOfBreakdownId.data) ? responseReasonOfBreakdownId.data : [],
    });
  };

  fillBreakdownTypeAll = async () => {
    var responseBreakdownTypeAll = await handleRequest("GET", "/api/" + this.state.controllerName + "/breakdownTypeAll");
    if (responseBreakdownTypeAll.type === "ERROR") {
      error(responseBreakdownTypeAll);
    }
    this.setState({
      breakdownTypeAllSelectItems: Boolean(responseBreakdownTypeAll.data) ? responseBreakdownTypeAll.data : []
    });
  };

  fillProposedSolutionId = async () => {
    var responseProposedSolutionId = await handleRequest("GET", "/api/" + this.state.controllerName + "/proposedSolutionId");
    if (responseProposedSolutionId.type === "ERROR") {
      error(responseProposedSolutionId);
    }
    this.setState({
      proposedSolutionIdSelectItems: Boolean(responseProposedSolutionId.data) ? responseProposedSolutionId.data : [],
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    let fileList = PrepareFileUploadEdit(row.photoUrl);
    this.fillBreakdownTypeId(row.equipmentLogId);

    this.formRef.current.setFieldsValue({
      ...row,
      photoUrl: fileList,
      date: moment(row.date)
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    newItem.photoUrl = PrepareFileUploadSave(newItem.photoUrl);

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  showReport = async rowData => {
    try {
      if (rowData.photoUrl !== null && rowData.photoUrl !== undefined && rowData.photoUrl !== "") {
        const fileRealUrl = rowData.photoUrl;
        window.location.href = fileRealUrl;
      } else {
        this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
      }
    } catch (error) {
      this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const normFile = e => {
      if (Array.isArray(e)) {
        return e;
      }

      return e && e.fileList;
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture"
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="Breakdown.Equipment" defaultMessage="Equipment" />,
        key: "equipmentLogId",
        render: record => {
          return record.equipmentLogName;
        }, ...getColumnFilter({
          inputName: "equipmentLogId", inputType: "TEXT",
          selectItems: Boolean(this.state["equipmentLogIdSelectItems"]) ? this.state["equipmentLogIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="Breakdown.BreakdownType" defaultMessage="Breakdown Type" />,
        key: "breakdownTypeId",
        render: record => {
          return record.breakdownTypeName;
        }, ...getColumnFilter({
          inputName: "breakdownTypeId", inputType: "TEXT",
          selectItems: Boolean(this.state["breakdownTypeAllSelectItems"]) ? this.state["breakdownTypeAllSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="Breakdown.ReasonOfBreakdown" defaultMessage="Reason Of Breakdown" />,
        key: "reasonOfBreakdownId",
        render: record => {
          return record.reasonOfBreakdownName;
        }, ...getColumnFilter({
          inputName: "reasonOfBreakdownId", inputType: "TEXT",
          selectItems: Boolean(this.state["reasonOfBreakdownIdSelectItems"]) ? this.state["reasonOfBreakdownIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="Breakdown.ProposedSolution" defaultMessage="Proposed Solution" />,
        key: "proposedSolutionId",
        render: record => {
          return record.proposedSolutionName;
        }, ...getColumnFilter({
          inputName: "proposedSolutionId", inputType: "TEXT",
          selectItems: Boolean(this.state["proposedSolutionIdSelectItems"]) ? this.state["proposedSolutionIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="DocumentLog.Date" defaultMessage="Date" />,
        key: "date",
        render: record => {
          return Boolean(record.date) ? moment(record.date).format("DD-MM-YYYY HH:mm:ss") : "";
        },
        ...getColumnFilter({
          inputName: "date",
          inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Breakdown.kmPerHour" defaultMessage="Km/hour" />,
        key: "kmPerHour",
        render: record => {
          return record.kmPerHour;
        }, ...getColumnFilter({
          inputName: "kmPerHour", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="EldenEquipmentGroup.Photo" defaultMessage="Photo" />,
        key: "photoUrl",
        render: record => {
          if (!Boolean(record.photoUrl)) {
            return "";
          }
          return (
            record.photoUrl.endsWith(".png") ||
            record.photoUrl.endsWith(".jpg") ||
            record.photoUrl.endsWith(".tiff") ||
            record.photoUrl.endsWith(".jpeg") ||
            record.photoUrl.endsWith(".PNG") ||
            record.photoUrl.endsWith(".JPG") ||
            record.photoUrl.endsWith(".TIFF") ||
            record.photoUrl.endsWith(".JPEG")
          ) ?
            <Image width={80} src={record.photoUrl} />
            :
            <AntButton
              style={{
                marginLeft: "2px",
                marginTop: "-2px",
                alignContent: "center"
              }}
              onClick={() => this.showReport(record)}
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
            ></AntButton>;
        },
      }
    ];

    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
      {this.state.exportClick ? (<ExcelFile filename="Breakdown" hideElement={true}>
        <ExcelSheet name="Breakdown" data={this.state.tableList}>
          <ExcelColumn label="Equipment" value="equipmentLogName" />
          <ExcelColumn label="Breakdown Type" value="breakdownTypeName" />
          <ExcelColumn label="Reason Of Breakdown" value="reasonOfBreakdownName" />
          <ExcelColumn label="Proposed Solution" value="proposedSolutionName" />
          <ExcelColumn label="Date" value="date" />
          <ExcelColumn label="Km/hour" value="kmPerHour" />
          <ExcelColumn label="Photo" value="photo" />
        </ExcelSheet>
      </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}>

      </AntButton>)} */}
    </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="Breakdown.pageTitle" defaultMessage="Breakdown" />} placement="start" color="purple" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={21}></Col>
              <Col md={1}>{excelExport}</Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary"
                    fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error"
                    fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Breakdown.Equipment" defaultMessage="Equipment" />}
                name="equipmentLogId"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  onChange={(value) => {
                    this.setState({
                      equipmentLogId: value
                    });
                    this.fillBreakdownTypeId(value);
                  }}
                >
                  {Boolean(this.state["equipmentLogIdSelectItems"]) && this.state["equipmentLogIdSelectItems"].length > 0 &&
                    this.state["equipmentLogIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="Breakdown.BreakdownType" defaultMessage="Breakdown Type" />}
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name="breakdownTypeId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    style={{ width: "90%" }}
                  >
                    <Select allowClear
                      showSearch
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                    >
                      {Boolean(this.state["breakdownTypeIdSelectItems"]) && this.state["breakdownTypeIdSelectItems"].length > 0 &&
                        this.state["breakdownTypeIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  <AntButton
                    type="default"
                    style={{
                      color: "black",
                      background: "white",
                      width: "10%",
                      marginRight: "10px"
                    }}
                    onClick={() => {
                      this.setState({
                        showBreakdownTypeModal: true,
                        random: Math.random(),
                      });
                    }}
                    icon={<Add />}
                  ></AntButton>
                </Space.Compact>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="Breakdown.ReasonOfBreakdown" defaultMessage="Reason Of Breakdown" />}
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name="reasonOfBreakdownId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    style={{ width: "90%" }}
                  >
                    <Select allowClear
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                    >
                      {Boolean(this.state["reasonOfBreakdownIdSelectItems"]) && this.state["reasonOfBreakdownIdSelectItems"].length > 0 &&
                        this.state["reasonOfBreakdownIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>
                  <AntButton
                    type="default"
                    style={{
                      color: "black",
                      background: "white",
                      width: "10%",
                      marginRight: "10px"
                    }}
                    onClick={() => {
                      this.setState({
                        showBreakdownReasonModal: true,
                        random: Math.random(),
                      });
                    }}
                    icon={<Add />}
                  ></AntButton>
                </Space.Compact>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="Breakdown.ProposedSolution" defaultMessage="Proposed Solution" />}
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name="proposedSolutionId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    style={{ width: "90%" }}
                  >
                    <Select allowClear
                      showSearch
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                    >
                      {Boolean(this.state["proposedSolutionIdSelectItems"]) && this.state["proposedSolutionIdSelectItems"].length > 0 &&
                        this.state["proposedSolutionIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}{" "}
                    </Select>
                  </Form.Item>

                  <AntButton
                    type="default"
                    style={{
                      color: "black",
                      background: "white",
                      width: "10%",
                      marginRight: "10px"
                    }}
                    onClick={() => {
                      this.setState({ showBreakdownSolutionsModal: true });
                    }}
                    icon={<Add />}
                  ></AntButton>
                </Space.Compact>
              </Form.Item>


              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Breakdown.date" defaultMessage="Date" />}
                  name="date"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY HH:mm:ss" showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Breakdown.kmPerHour" defaultMessage="Km/hour" />}
                  name="kmPerHour"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="DocumentLog.Photo" defaultMessage="Photo" />}
                  name="photoUrl"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload {...props}>
                    <AntButton icon={<UploadOutlined />}>
                      <FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                    </AntButton>
                  </Upload>
                </Form.Item>
              }

              <div hidden={this.state.hideSave}>
                {<Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>}
              </div>
              <div hidden={this.state.hideUpdate}>
                {<Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>}
              </div>

            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (<div className="alert alert-custom alert-light">
            <div className="alert-icon">
              <FilterOutlined />
            </div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                  <span className="mr-2">{val.label}</span>
                  <strong className="mr-2">{val.type}</strong>
                  <span className="mr-2">{val.operationValue}</span>
                  <span className="mr-2">{val.showValue}</span>
                  <span>                           <Tooltip title="Remove">
                    <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                  </Tooltip>
                  </span>
                </div>);
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined
                  style={{ color: "crimson" }}
                  onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />
              </Tooltip>
            </div>
          </div>)}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />
          </div>
          <Modal
            show={this.state.modalDeleteShow}
            onHide={this.showOrHideDeleteModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <DraggableModal
            centered
            width={1200}
            title={<FormattedMessage id="Breakdown.BreakdownType" defaultMessage="Breakdown Type" />}
            open={this.state.showBreakdownTypeModal}
            onCancel={() => {
              this.fillBreakdownTypeId(this.state.equipmentLogId);
              this.fillBreakdownTypeAll();
              this.setState({
                showBreakdownTypeModal: false
              });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.fillBreakdownTypeId(this.state.equipmentLogId);
                  this.fillBreakdownTypeAll();
                  this.setState({
                    showBreakdownTypeModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
            ]}
            content={
              <BreakdownType random={this.state.random} />
            }
          />

          <DraggableModal
            centered
            width={1200}
            title={<FormattedMessage id="Breakdown.BreakdownReason" defaultMessage="Breakdown Reason" />}
            open={this.state.showBreakdownReasonModal}
            onCancel={() => {
              this.fillBreakdownReason();
              this.setState({
                showBreakdownReasonModal: false
              });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.fillBreakdownReason();
                  this.setState({
                    showBreakdownReasonModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
            ]}
            content={
              <BreakdownReason random={this.state.random} />
            }
          />

          <DraggableModal
            centered
            width={1200}
            title={<FormattedMessage id="Breakdown.BreakdownSolutions" defaultMessage="Breakdown Solutions" />}
            open={this.state.showBreakdownSolutionsModal}
            onCancel={() => {
              this.fillProposedSolutionId();
              this.setState({
                showBreakdownSolutionsModal: false
              });
            }}
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.fillProposedSolutionId();
                  this.setState({
                    showBreakdownSolutionsModal: false
                  });
                }}
              >
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
            ]}
            content={
              <BreakdownSolution random={this.state.random} />
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Breakdown);