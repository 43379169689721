import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, InputNumber, Button as AntButton, Badge } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Checkbox } from "antd";
import { DatePicker } from "antd";
import { Select, Tooltip, Cascader } from "antd";
import { Input } from "antd";
import moment from "moment";
import { EyeTwoTone, EyeInvisibleTwoTone, MinusCircleOutlined } from "@ant-design/icons";
import { number } from "mathjs";
const { Option } = Select;

export class StockCostSetting extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "StockCostSetting",
      controllerName: "stockCostSetting",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: false,
      bankTransferVisible: false,
      invoiceVisible: true,
      returnPaymentVisible: true,
      dateVisible: false,
      fromVisible: false,
      bankAccountVisible: false,
      invoiceNoVisible: false,
      receivedByVisible: false,
      amountVisible: false,
      amountIdVisible: false,
      reasonForTransactionVisible: false,
      unitIdSelectItems: [],
      allUnitSelectItems: [],

      tableList: [],

      stockCostSetting: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      }
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };
  fillComboboxes = async () => {
    var responseunitId = await handleRequest("GET", "/api/" + this.state.controllerName + "/unitId");
    if (responseunitId.type === "ERROR") {
      error(responseunitId);
    }
    this.setState({
      unitIdSelectItems: Boolean(responseunitId.data) ? responseunitId.data : []
    });

    var responseAllUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/allUnit");
    if (responseAllUnit.type === "ERROR") {
      error(responseAllUnit);
    }
    this.setState({
      allUnitSelectItems: Boolean(responseAllUnit.data) ? responseAllUnit.data : []
    });
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/pagingSettings";
    //let url = "/api/" + this.state.controllerName + "/paging";
    console.log("okan1");
    const response = await handleRequest("POST", url, newObj);
    console.log(response);
    if (response.type === "ERROR") {
      error(response);
      console.log("okan2");
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        console.log("okan3");
        let list = response.data.data;
        list.forEach((element, index) => {
          element.sn = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
        console.log(this.state.tableList);
      } else {
        console.log("okan4");
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };
    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  getAuthorized = async cashLogId => {
    if (Boolean(cashLogId) && cashLogId > 0) {
      var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/authorized/" + cashLogId);
      if (response.type === "ERROR") {
        error(response);
      }
      this.setState({
        releasedByIdSelectItems: Boolean(response.data) ? response.data : []
      });
    } else {
      this.setState({
        releasedByIdSelectItems: []
      });
    }
  };
  handlePriceListQuantityOurExpectedPrice = async (row, value) => {
    if (Boolean(value)) {
      const newData = [...this.state.tableList];
      const item = newData.find(item => row.productId === item.productId);
      if (Boolean(value) && Boolean(item)) {
        if (item.price === value.price) return;

        item.price = value.price;
        this.setState({
          tableList: newData
        });
      }
    }
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout2 = {
      labelCol: { span: 15 },
      wrapperCol: { span: 16 }
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      {
        title: <FormattedMessage id="StockCostSetting.Category" defaultMessage="Category" />,
        key: "categoryName",
        render: record => {
          return record.categoryName;
        },
        align: "center"
      },
      {
        title: <FormattedMessage id="StockCostSetting.Price" defaultMessage="Price" />,
        key: "price",
        width: 100,
        render: record => {
          return <InputNumber value={record.price} onChange={value => this.handlePriceChange(record, value)} style={{ width: "100%" }} />;
        },

        align: "center"
      },
      {
        title: <FormattedMessage id="StockCostSetting.Unit" defaultMessage="Unit" />,
        key: "unitId",
        render: record => {
          return (
            <Select
              allowClear
              style={{ width: "100%" }}
              optionFilterProp="children"
              onChange={value => {
                this.setState({
                  unitId: value
                });
              }}
            >
              {Boolean(this.state["unitIdSelectItems"]) &&
                this.state["unitIdSelectItems"].length > 0 &&
                this.state["unitIdSelectItems"].map(i => (
                  <Option key={i.key} value={i.key}>
                    {i.value}
                  </Option>
                ))}{" "}
            </Select>
          );
        },
        align: "center"
      },
      // {
      //   title: <FormattedMessage id="RequestForQuotation.Quantity" defaultMessage="Quantity" />,
      //   key: "quantity",
      //   width: 100,
      //   render: record => {
      //     let weight = "";
      //     if (record.unit && Boolean(record.weight) && Boolean(record.quantity)) {
      //       if (Boolean(this.state.allUnitSelectItems) && this.state.allUnitSelectItems.length > 0) {
      //         let unit = this.state.allUnitSelectItems.find(p => p.abbreviation === record.unit);
      //         if (Boolean(unit.abbreviation) && unit.abbreviation === "ad") {
      //           weight = record.quantity;
      //         } else {
      //           if (unit.unitTypeName === "Length") {
      //             if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
      //               weight = (record.length * record.quantity) / unit.coefficent;
      //             } else {
      //               weight = record.length * record.quantity;
      //             }
      //           } else {
      //             if (Boolean(unit) && Boolean(unit.coefficent) && unit.coefficent > 0) {
      //               weight = (record.quantity * record.weight) / unit.coefficent;
      //             } else {
      //               weight = record.weight * record.quantity;
      //             }
      //           }
      //         }
      //       }
      //     }
      //     //let quantity = record.quantity;
      //     let quantity = weight.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, " ");
      //     if (!Boolean(quantity)) {
      //       quantity = record.quantity;
      //     }

      //     return Boolean(record.oldQuantity) && record.oldQuantity > 0 ? (
      //       <Badge showZero offset={[25, -15]} overflowCount={record.oldQuantity + 1} count={record.oldQuantity}>
      //         {quantity}
      //       </Badge>
      //     ) : (
      //       quantity
      //     );
      //   },
      //   editable: true,
      //   onCell: record => ({
      //     record,
      //     editable: true,
      //     dataIndex: "quantity",
      //     title: <FormattedMessage id="RequestForQuotation.quantity" defaultMessage="Quantity" />,
      //     handleSave: this.handlePriceListQuantityOurExpectedPrice
      //   })
      // },
      {
        title: <FormattedMessage id="StockCostSetting.Currency" defaultMessage="Currency" />,
        key: "currency",
        render: record => {
          return (
            <Select defaultValue={record.unitId} style={{ width: 120 }} defaultActiveFirstOption allowClear className="w-50">
              <Option key={"dashboard"} value={"dashboard"}>
                <FormattedMessage id="StockCostSetting.Euro" defaultMessage="EURO" />
              </Option>
              <Option key={"circle"} value={"circle"}>
                <FormattedMessage id="StockCostSetting.Dolar" defaultMessage="DOLAR" />
              </Option>
              <Option key={"table"} value={"table"}>
                <FormattedMessage id="StockCostSetting.Sterlin" defaultMessage="Sterlin" />
              </Option>
              <Option key={"pie"} value={"pie"}>
                <FormattedMessage id="StockCoStockCostSettingsts.SaudiArabianRiyal" defaultMessage="Saudi Arabian Riyal" />
              </Option>
            </Select>
          );
        },
        align: "center"
      }
    ];
    const columnsBottom = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        width: 50,
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="StockCostSetting.EffectiveDate" defaultMessage="Effective Date" />,
        key: "effectiveDate",
        render: record => {
          return record.effectiveDate;
        },
        align: "center"
      },
      {
        title: <FormattedMessage id="StockCostSetting.Settings" defaultMessage="Settings" />,
        key: "settings",
        render: record => {
          return record.settings;
        },
        align: "center"
      }
    ];

    return (
      <Badge.Ribbon
        text={<FormattedMessage id="StockCostSetting.pageTitle" defaultMessage="Settings" />}
        placement="start"
        color="Navy Blue"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={21}></Col>
            </Row>
            <br></br>
            <Row>
              <Form.Item
                {...layout2}
                label={<FormattedMessage id="StocCostSetting.effectiveDate" defaultMessage="Effective Date" />}
                name="effectiveDate"
                rules={[{ required: true, message: <FormattedMessage id="todo" defaultMessage="Please select date" /> }]}
              >
                <DatePicker style={{ width: "150%" }} />
              </Form.Item>
            </Row>
            <div hidden={this.state.hideInputs}>
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                      <Row gutter={[16, 16]}>
                        <Col lg={24}>
                          <Table
                            scroll={{ y: 900, scrollToFirstRowOnChange: true }}
                            bordered
                            columns={columns}
                            dataSource={this.state.tableList}
                            onChange={this.handleTableChange}
                            pagination={this.state.pagination}
                          />
                        </Col>
                      </Row>
                    </div>

                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                      <Row gutter={[16, 16]}>
                        <Col lg={24}>
                          <Table
                            scroll={{ y: 900, scrollToFirstRowOnChange: true }}
                            bordered
                            columns={columnsBottom}
                            dataSource={this.state.tableList}
                            onChange={this.handleTableChange}
                            pagination={this.state.pagination}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Row>
                }
              </div>

              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </div>
          </Form>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(StockCostSetting);
