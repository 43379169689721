import { Input, InputNumber, Select } from "antd";
import React from "react";
const { Option } = Select;

const inputPropElements = {
  TEXT: <Input style={{ width: "100%" }} />,
  NUMBER: <InputNumber style={{ width: "100%" }} />,
  SELECTYESNO: (
    <Select style={{ width: "100%" }}>
      <Option></Option>
      <Option value="No">No</Option>
      <Option value="YES">Yes</Option>
    </Select>
  )
};
const inputPropsList = {
  SELECT: [
    { key: "loadUrl", label: "Load Url", element: inputPropElements.TEXT },
    { key: "propKey", label: "Key Prop", element: inputPropElements.TEXT },
    { key: "propValue", label: "Value Prop", element: inputPropElements.TEXT },
    { key: "parentName", label: "Parent name", element: inputPropElements.TEXT },
    { key: "children", label: "Has Children", element: inputPropElements.SELECTYESNO }
  ]
};
const generalInputProps = [
  { key: "tableColumnValue", label: "Table Row Value", element: inputPropElements.TEXT },
  { key: "tableColumnHeader", label: "Table Header", element: inputPropElements.TEXT },
  { key: "tableColumnHeaderLanguage", label: "Table Header Language Key", element: inputPropElements.TEXT },
  {
    key: "visibilityParentName",
    label: "Visibility Parent Name",
    element: inputPropElements.TEXT
  },
  {
    key: "showWhenParentValue",
    label: "Show When Parent Value",
    element: inputPropElements.TEXT
  },
  {
    key: "hideWhenParentValue",
    label: "Hide When Parent Value",
    element: inputPropElements.TEXT
  }
];

const inputTypeSelectItems = [
  { key: "TEXT", value: "", inputProps: [...generalInputProps] },
  { key: "MAIL", value: "", inputProps: [...generalInputProps] },
  { key: "PHONE", value: "", inputProps: [{ key: "country", label: "Country", element: inputPropElements.TEXT }, ...generalInputProps] },
  { key: "PASSWORD", value: "", inputProps: [...generalInputProps] },
  {
    key: "NUMBER",
    value: "",
    inputProps: [
      { key: "max", label: "Max", element: inputPropElements.TEXT },
      { key: "min", label: "Min", element: inputPropElements.TEXT },
      ...generalInputProps
    ]
  },
  { key: "TEXTAREA", value: "" },
  { key: "TEXT_EDITOR", value: "", inputProps: [...generalInputProps] },
  {
    key: "SELECT",
    value: "",
    inputProps: [...inputPropsList.SELECT, ...generalInputProps]
  },
  {
    key: "SELECT_ADDABLE",
    value: "",
    inputProps: [...inputPropsList.SELECT, { key: "addType", label: "Code Type", element: inputPropElements.TEXT }, ...generalInputProps]
  },
  {
    key: "SELECT_MULTIPLE",
    value: "",
    inputProps: [...inputPropsList.SELECT, ...generalInputProps]
  },
  {
    key: "SELECT_MULTIPLE_ADDABLE",
    value: "",
    inputProps: [...inputPropsList.SELECT, { key: "addType", label: "Code Type", element: inputPropElements.TEXT }, ...generalInputProps]
  },
  {
    key: "CHECKBOX",
    value: "",
    inputProps: [
      { key: "trueColor", label: "True Color", element: inputPropElements.TEXT },
      { key: "falseColor", label: "False Color", element: inputPropElements.TEXT },
      { key: "trueText", label: "True Text", element: inputPropElements.TEXT },
      { key: "falseText", label: "False Text", element: inputPropElements.TEXT },
      { key: "columnElement", label: "Row Element", element: inputPropElements.TEXT },
      {
        key: "checkType",
        label: "Check Type",
        element: (
          <Select style={{ width: "100%" }}>
            <Option></Option>
            <Option value="checkbox">Checkbox</Option>
            <Option value="switch">Switch</Option>
          </Select>
        )
      },
      { key: "labelRight", label: "Label Right", element: inputPropElements.SELECTYESNO },
      { key: "children", label: "Has Children", element: inputPropElements.SELECTYESNO },
      ...generalInputProps
    ]
  },
  {
    key: "RADIO",
    value: "",
    inputProps: [
      { key: "loadUrl", label: "Load Url", element: inputPropElements.TEXT },
      { key: "propKey", label: "Key Prop", element: inputPropElements.TEXT },
      { key: "propValue", label: "Value Prop", element: inputPropElements.TEXT },
      {
        key: "radioType",
        label: "Radio Type",
        element: (
          <Select style={{ width: "100%" }}>
            <Option></Option>
            <Option value="radio">Radio</Option>
            <Option value="button">Button</Option>
          </Select>
        )
      },
      { key: "children", label: "Has Children", element: inputPropElements.SELECTYESNO },
      ...generalInputProps
    ]
  },
  { key: "DATEPICKER", value: "", inputProps: [...generalInputProps] },
  { key: "DATEPICKER_WITH_TIME", value: "", inputProps: [...generalInputProps] },
  { key: "TIMEPICKER", value: "", inputProps: [...generalInputProps] },
  { key: "MONTHPICKER", value: "", inputProps: [...generalInputProps] },
  { key: "YEARPICKER", value: "", inputProps: [...generalInputProps] },
  { key: "RANGEPICKER", value: "", inputProps: [...generalInputProps] },
  { key: "RANGEPICKER_WITH_TIME", value: "", inputProps: [...generalInputProps] },
  { key: "CASCADER", value: "", inputProps: [{ key: "loadUrl", label: "Load Url", element: inputPropElements.TEXT }, ...generalInputProps] },
  {
    key: "FILEUPLOAD",
    value: "",
    inputProps: [{ key: "max", label: "Max File", element: inputPropElements.NUMBER }, ...generalInputProps]
  },
  {
    key: "FILEUPLOAD_IMAGE",
    value: "",
    inputProps: [{ key: "max", label: "Max File", element: inputPropElements.NUMBER }, ...generalInputProps]
  },
  { key: "TREE_SELECT", value: "", inputProps: [...generalInputProps] },
  {
    key: "FORM_LIST",
    value: "",
    inputProps: [
      { key: "formName", label: "Form Name", element: inputPropElements.TEXT },
      {
        key: "visibilityParentName",
        label: "Visibility Parent Name",
        element: inputPropElements.TEXT
      },
      {
        key: "showWhenParentValue",
        label: "Show When Parent Value",
        element: inputPropElements.TEXT
      },
      {
        key: "hideWhenParentValue",
        label: "Hide When Parent Value",
        element: inputPropElements.TEXT
      }
    ]
  },
  { key: "HIDDEN", value: undefined, inputProps: [] }
];

export { inputTypeSelectItems, generalInputProps, inputPropsList, inputPropElements };
