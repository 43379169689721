/* eslint-disable */

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import MenuIcon from "@material-ui/icons/Menu";
import PostAddOutlined from "@material-ui/icons/PostAddOutlined";
import RevisionIcon from "@material-ui/icons/ViewList";

export default ({
  row,
  onDeleteRow,
  onEditRow,
  onReceivePayment,
  onReportRow,
  onReturnInvoiceRow,
  onReport65,
  onReport75,
  onReport85,
  size,
  editBgColor,
  deleteBgColor,
  returnInvoiceBgColor
  
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteRow = () => {
    if (onDeleteRow) {
      onDeleteRow(row);
      setAnchorEl(null);
    }
  };

  const editRow = () => {
    if (onEditRow) {
      onEditRow(row);
      setAnchorEl(null);
    }
  };

  const returnInvoiceRow = () => {
    if (onReturnInvoiceRow) {
      onReturnInvoiceRow(row);
      setAnchorEl(null);
    }
  };

  const receivePayment = () => {
    if (onReceivePayment) {
      onReceivePayment(row);
      setAnchorEl(null);
    }
  };

  const showReport = () => {
    if (onReportRow) {
      onReportRow(row);
      setAnchorEl(null);
    }
  };

  const showReport65 = () => {
    if (onReport65) {
      onReport65(row);
      setAnchorEl(null);
    }
  };

  const showReport75 = () => {
    if (onReport75) {
      onReport75(row);
      setAnchorEl(null);
    }
  };

  const showReport85 = () => {
    if (onReport85) {
      onReport85(row);
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu" // flaticon-layer
        aria-haspopup="true"
        onClick={handleClick}
        size={size}
      >
        {/* <i className={`kt-menu__link-icon  flaticon-layer`} /> */}
        <MenuIcon color="primary" />
        {/* <MoreVertIcon /> */}
      </IconButton>
      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Boolean(onEditRow) && (
          <MenuItem onClick={editRow} style={{ backgroundColor: "#" + editBgColor }}>
            <ListItemIcon>
              <EditIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <Typography variant="inherit">Edit</Typography>
          </MenuItem>
        )}
        {Boolean(onEditRow) && <Divider />}

        {Boolean(onReturnInvoiceRow) && (
          <MenuItem onClick={returnInvoiceRow} style={{ backgroundColor: "#" + returnInvoiceBgColor }}>
            <ListItemIcon>
              <EditIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <Typography variant="inherit">Return Invoice</Typography>
          </MenuItem>
        )}
        {Boolean(onReturnInvoiceRow) && <Divider />}

        {Boolean(onDeleteRow) && (
          <MenuItem onClick={deleteRow} style={{ backgroundColor: "#" + deleteBgColor }}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" color="error" />
            </ListItemIcon>
            <Typography variant="inherit">Delete</Typography>
          </MenuItem>
        )}
        {Boolean(onDeleteRow) && <Divider />}

        <MenuItem onClick={showReport} style={{ backgroundColor: "#" + editBgColor }}>
          <ListItemIcon>
            <PostAddOutlined fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">Print</Typography>
        </MenuItem>

        {Boolean(onReceivePayment) && <Divider />}

        {Boolean(onReceivePayment) && (
          <MenuItem onClick={receivePayment} style={{ backgroundColor: "#" + editBgColor }}>
            <ListItemIcon>
              <RevisionIcon fontSize="small" color="secondary" />
            </ListItemIcon>
            <Typography variant="inherit">Receive Payment</Typography>
          </MenuItem>
        )}

        {/* <Divider />

        <MenuItem
          onClick={showReport65}
          style={{ backgroundColor: "#" + editBgColor }}
        >
          <ListItemIcon>
            <PostAddOutlined fontSize="small" color="info" />
          </ListItemIcon>
          <Typography variant="inherit">Print1</Typography>
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={showReport75}
          style={{ backgroundColor: "#" + editBgColor }}
        >
          <ListItemIcon>
            <PostAddOutlined fontSize="small" color="info" />
          </ListItemIcon>
          <Typography variant="inherit">Print2</Typography>
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={showReport85}
          style={{ backgroundColor: "#" + editBgColor }}
        >
          <ListItemIcon>
            <PostAddOutlined fontSize="small" color="info" />
          </ListItemIcon>
          <Typography variant="inherit">Print3</Typography>
        </MenuItem> */}
      </Menu>
    </div>
  );
};
