import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Button as AntButton, Drawer, Row, Col, Tooltip } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "../../../redux/store";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import { InputNumber } from "antd";
import { Input } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import EldenCompoundsSettingsProductTreeSelectableCompany from "../elden/equipment/EldenCompoundsSettingsProductTreeSelectableCompany";
import DraggableModal from "../../Components/DraggableModal";
import EldenCompoundsSettingsProductTreeSelectable from "../elden/equipment/EldenCompoundsSettingsProductTreeSelectable";

export class TenderingLogAddProduct extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "TenderingLogAddProduct",
      controllerName: "tenderingLogAddProduct",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      countrySelectItems: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseAddProduct = await handleRequest("GET", "/api/" + this.state.controllerName + "/getAddProduct/" + this.props.tenderingLogId);
    if (responseAddProduct.type === "ERROR") {
      error(responseAddProduct);
    }
    this.formRef.current.setFieldsValue({
      products: Boolean(responseAddProduct.data) ? responseAddProduct.data : [],
    });
  };

  restartTable = async (params = {}) => {
    // this.setState({ loading: true });
    // const newObj = this.createWhere(params);
    // let url = "/api/" + this.state.controllerName + "/paging";
    // const response = await handleRequest("POST", url, newObj);
    // if (response.type === "ERROR") {
    //   error(response);
    //   this.setState({
    //     loading: false,
    //     tableList: [],
    //     pagination: {
    //       ...params.pagination,
    //       total: 0
    //     },
    //     totalRecords: 0,
    //     sortField: "",
    //     sortOrder: ""
    //   });
    // } else {
    //   if (response.data.length !== 0) {
    //     let list = response.data.data;
    //     list.forEach((element, index) => {
    //       element.index = index + 1;
    //     });
    //     this.setState({
    //       loading: false,
    //       tableList: list,
    //       pagination: {
    //         ...params.pagination,
    //         total: response.data.totalRecords
    //       },
    //       totalRecords: response.data.totalRecords,
    //       sortField: params.sortField,
    //       sortOrder: params.sortOrder
    //     });
    //   } else {
    //     this.setState({
    //       loading: false,
    //       tableList: [],
    //       pagination: {
    //         ...params.pagination,
    //         total: 0
    //       },
    //       totalRecords: 0,
    //       sortField: "",
    //       sortOrder: ""
    //     });
    //   }
    // }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
      issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null,
      biddingClosingDate: Boolean(row.biddingClosingDate) ? moment(row.biddingClosingDate) : null,
      tenderingDate: Boolean(row.tenderingDate) ? moment(row.tenderingDate) : null,
      projectStartDate: Boolean(row.projectStartDate) ? moment(row.projectStartDate) : null,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      checkedKeys: { checked: row.projectsTreeIds }
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    console.log("values", values);
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll/" + this.props.tenderingLogId, values.products);
    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.props.close();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  onTargetKeysCheck = async targetKeys => {
    console.log("targetKeys", targetKeys);
    this.setState({
      targetKeys: targetKeys
    });
  };

  okProjectTreeTemplateCompany = async () => {
    const { currentFieldKey, targetKeys } = this.state;
    console.log("currentFieldKey", currentFieldKey);
    console.log("targetKeys", targetKeys);

    let products = this.formRef.current.getFieldValue("products");
    let product = products[currentFieldKey];
    product.productTreeId = Boolean(targetKeys) && targetKeys.length > 0 ? targetKeys[0] : null;
    console.log("product", product);
    console.log("products", products);

    this.formRef.current.setFieldsValue({
      "products": products
    });

    this.setState({
      showProductTreeCompanyModal: false,
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Form.List name="products">
            {(fields, { add, remove }) => {
              return (
                <>
                  <TableBoot bordered size="sm">
                    <thead>
                      <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"TenderingLogAddProduct.NameOfProduct"} defaultMessage={"Name of Product"} />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"TenderingLogAddProduct.Quantity"} defaultMessage={"Quantity"} />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id={"TenderingLogAddProduct.Quantity"} defaultMessage={"Select Product"} />
                        </th>
                        <th colSpan="1">
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map(field => {
                        return (
                          <tr key={field.key}>
                            <td>
                              <Form.Item noStyle name={[field.name, "name"]}>
                                <Input style={{ width: "100%" }} />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle name={[field.name, "quantity"]}>
                                <InputNumber min={0} style={{ width: "100%" }} />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item noStyle hidden name={[field.name, "productTreeId"]}>
                                <Input style={{ width: "100%" }} />
                              </Form.Item>

                              <Tooltip title={
                                Boolean(this.formRef) && Boolean(this.formRef.current) &&
                                  Boolean(this.formRef.current.getFieldValue("products")) &&
                                  Boolean(this.formRef.current.getFieldValue("products")[field.name]) &&
                                  Boolean(this.formRef.current.getFieldValue("products")[field.name].productTreeName)
                                  ?
                                  this.formRef.current.getFieldValue("products")[field.name].productTreeName
                                  : ""}
                              >
                                <AntButton type="dashed"
                                  onClick={() => {
                                    this.setState({
                                      currentFieldKey: field.name,
                                      showProductTreeCompanyModal: true,
                                      random: Math.random()
                                    });
                                  }}
                                  block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"TenderingLogAddProduct.AddNew"} defaultMessage={"Select Product"} />
                                </AntButton>
                              </Tooltip>
                            </td>
                            <td>
                              <MinusCircleOutlined onClick={() => remove(field.name)} />
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={11}>
                          <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            <FormattedMessage id={"TenderingLogAddProduct.AddNew"} defaultMessage={"Add New"} />
                          </AntButton>
                        </td>
                      </tr>
                    </tbody>
                  </TableBoot>
                </>
              );
            }}
          </Form.List>

          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </Button>
            </Col>
            <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
          </Row>
        </Form>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Drawer
          title={<FormattedMessage id="TenderingLogAddProduct.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"TenderingLogAddProduct"} />
        </Drawer>

        <DraggableModal
          title={<FormattedMessage id="ProductLog.ProductTree" defaultMessage="Product Tree" />}
          centered
          width={800}
          maskClosable={false}
          open={this.state.showProductTreeCompanyModal}
          onCancel={() => {
            this.setState({ showProductTreeCompanyModal: false });
          }}
          onOk={() => {
            this.okProjectTreeTemplateCompany();
          }}
          content={
            <div className="card card-custom" style={{ padding: "1rem", minHeight: "100%" }}>
              <EldenCompoundsSettingsProductTreeSelectable
                onTargetKeysCheck={this.onTargetKeysCheck} singleCheck={true}
              />
            </div>
          }
        />

      </div >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TenderingLogAddProduct);