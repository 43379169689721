import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Tooltip, Drawer, Space, DatePicker } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined, MoreHoriz, Settings } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import store from "../../../redux/store";
import { PageSettings } from "../administrator/pageSettings/PageSettings";
import DraggableModal from "../../Components/DraggableModal";
import { Checkbox } from "antd";
import { InputNumber } from "antd";
import { Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

export class Equipment extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "Equipment",
      controllerName: "equipment",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();
  formRefWorkingPeriod = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var responseequipmentId = await handleRequest("GET", "/api/" + this.state.controllerName + "/equipment");
    if (responseequipmentId.type === "ERROR") {
      error(responseequipmentId);
    }
    this.setState({
      equipmentIdSelectItems: Boolean(responseequipmentId.data) ? responseequipmentId.data : []
    });
    var responseproposedEquipmentId = await handleRequest("GET", "/api/" + this.state.controllerName + "/proposedEquipment");
    if (responseproposedEquipmentId.type === "ERROR") {
      error(responseproposedEquipmentId);
    }
    this.setState({
      proposedEquipmentIdSelectItems: Boolean(responseproposedEquipmentId.data) ? responseproposedEquipmentId.data : []
    });
    var responseForecastedCostId = await handleRequest("GET", "/api/" + this.state.controllerName + "/forecastedCost");
    if (responseForecastedCostId.type === "ERROR") {
      error(responseForecastedCostId);
    }
    this.setState({
      forecastedCostIdSelectItems: Boolean(responseForecastedCostId.data) ? responseForecastedCostId.data : []
    });
    var responseCurrencyId = await handleRequest("GET", "/api/" + this.state.controllerName + "/currency");
    if (responseCurrencyId.type === "ERROR") {
      error(responseCurrencyId);
    }
    this.setState({
      currencyIdSelectItems: Boolean(responseCurrencyId.data) ? responseCurrencyId.data : []
    });
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      tenderingLogId: this.props.tenderingLogId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const workingPeriod = this.state.formRefWorkingPeriod;
    const newItem = {
      ...values,
      ...workingPeriod,
      id: this.state.id,
      tenderingLogId: this.props.tenderingLogId,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  saveWorkingPeriod = async values => {
    console.log("Values", values);
    this.setState({ formRefWorkingPeriod: values, showWorkingPeriodModal: false });
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    const columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="Equipment.equipmentId" defaultMessage="Equipment" />,
        key: "equipmentId",
        render: record => {
          return record.equipmentName;
        },
        ...getColumnFilter({
          inputName: "equipmentName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Equipment.number" defaultMessage="Number" />,
        key: "number",
        render: record => {
          return record.number;
        },
        ...getColumnFilter({
          inputName: "number",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Equipment.workingPeriod" defaultMessage="Working Period" />,
        key: "workingPeriod",
        render: record => {
          return record.workingPeriod;
        },
        ...getColumnFilter({
          inputName: "workingPeriod",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Equipment.proposedEquipmentId" defaultMessage="Proposed Equipment" />,
        key: "proposedEquipmentId",
        render: record => {
          return record.proposedEquipmentName;
        },
        ...getColumnFilter({
          inputName: "proposedEquipmentName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Equipment.unitCost" defaultMessage="Unit Cost" />,
        key: "unitCost",
        render: record => {
          return record.unitCost;
        },
        ...getColumnFilter({
          inputName: "unitCost",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="Equipment.totalCost" defaultMessage="Total Cost" />,
        key: "totalCost",
        render: record => {
          return record.totalCost;
        },
        ...getColumnFilter({
          inputName: "totalCost",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      }
    ];
    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="Equipment" hideElement={true}>
            <ExcelSheet name="Equipment" data={this.state.tableList}>
              <ExcelColumn label="Equipment" value="equipmentName" />
              <ExcelColumn label="Number" value="number" />
              <ExcelColumn label="Working Period" value="workingPeriod" />
              <ExcelColumn label="Proposed Equipment" value="proposedEquipmentName" />
              <ExcelColumn label="Unit Cost" value="unitCost" />
              <ExcelColumn label="Total Cost" value="totalCost" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={20}></Col> <Col md={1}>{excelExport}</Col>
            <Col md={1}>
              <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
            <Col md={1}>
              {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                <Tooltip title={<FormattedMessage id="Equipment.Settings" defaultMessage="Settings" />}>
                  <AntButton
                    style={{ border: "0px" }}
                    onClick={() => {
                      this.setState({ drawerSettingsShow: true });
                    }}
                    icon={<Settings color="error" fontSize="large" />}
                  ></AntButton>
                </Tooltip>
              )}
            </Col>
          </Row>
          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Equipment.equipmentId" defaultMessage="Equipment" />}
                name="equipmentId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                  {Boolean(this.state["equipmentIdSelectItems"]) &&
                    this.state["equipmentIdSelectItems"].length > 0 &&
                    this.state["equipmentIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Equipment.numberToBeUsed" defaultMessage="Number to be Used" />}
                name="numberToBeUsed"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Manpower.WorkingPeriod" defaultMessage="Working Period" />}
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <AntButton
                  type="default"
                  style={{
                    color: "black",
                    background: "white"
                  }}
                  onClick={() => {
                    this.setState({ showWorkingPeriodModal: true });
                  }}
                >
                  <FormattedMessage id="Manpower.WorkingPeriod" defaultMessage="Working Period" />
                </AntButton>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Equipment.proposedEquipmentId" defaultMessage="Proposed Equipment" />}
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name="proposedEquipmentId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    style={{ width: "90%" }}
                  >
                    <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                      {Boolean(this.state["proposedEquipmentIdSelectItems"]) &&
                        this.state["proposedEquipmentIdSelectItems"].length > 0 &&
                        this.state["proposedEquipmentIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <AntButton
                    type="default"
                    style={{
                      color: "black",
                      background: "white"
                    }}
                    icon={<MoreHoriz />}
                  ></AntButton>
                </Space.Compact>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Equipment.isRental" defaultMessage="Rental" />}
                name="isRental"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="Equipment.forecastedCost" defaultMessage="Forecasted Cost" />}
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name="forecastedCost"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    style={{ width: "40%" }}
                  >
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    name="forecastedCostId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    style={{ width: "30%", marginRight: "10px" }}
                  >
                    <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                      {Boolean(this.state["forecastedCostIdSelectItems"]) &&
                        this.state["forecastedCostIdSelectItems"].length > 0 &&
                        this.state["forecastedCostIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="currencyId"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    style={{ width: "30%", marginRight: "10px" }}
                  >
                    <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                      {Boolean(this.state["currencyIdSelectItems"]) &&
                        this.state["currencyIdSelectItems"].length > 0 &&
                        this.state["currencyIdSelectItems"].map(i => (
                          <Option key={i.key} value={i.key}>
                            {i.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...layout}
                    name="isCurrentCost"
                    rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                    valuePropName="checked"
                  >
                    <Checkbox> {<FormattedMessage id="Equipment.CurrentCost" defaultMessage="Current Cost" />} </Checkbox>
                  </Form.Item>
                </Space.Compact>
              </Form.Item>
            }

            <div hidden={this.state.hideSave}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                      <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {
                <Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                      <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>
              }
            </div>
          </div>
        </Form>
        {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
          <div className="alert alert-custom alert-light">
            <div className="alert-icon">
              <FilterOutlined />
            </div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (
                  <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>
                    <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>
                    <span>
                      <Tooltip title="Remove">
                        <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined
                  style={{ color: "crimson" }}
                  onClick={() =>
                    this.setState({ searchCriteriaModels: [] }, () => {
                      this.filterChangeItem([]);
                    })
                  }
                />
              </Tooltip>
            </div>
          </div>
        )}
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <Table
            bordered
            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination}
          />
        </div>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Drawer
          title={<FormattedMessage id="Equipment.PageSettingsAuthorization" defaultMessage="Page Settings-Authorization" />}
          width={1200}
          closable={false}
          onClose={() => {
            this.setState({ drawerSettingsShow: false });
          }}
          open={this.state.drawerSettingsShow}
        >
          <PageSettings pageName={"Equipment"} />
        </Drawer>

        <DraggableModal
          title={<FormattedMessage id="Manpower.WorkingPeriod" defaultMessage="Working Period" />}
          centered
          width={600}
          maskClosable={false}
          open={this.state.showWorkingPeriodModal}
          onOk={async () => {
            await this.formRefWorkingPeriod.current.submit();
            this.setState({
              showWorkingPeriodModal: false
            });
          }}
          onCancel={() => {
            this.setState({ showWorkingPeriodModal: false });
          }}
          okText={<FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />}
          content={
            <>
              <Form
                initialValues={{ remember: false }}
                onFinish={this.saveWorkingPeriod}
                onFinishFailed={onFinishFailed}
                ref={this.formRefWorkingPeriod}
              >
                <Form.List name="workingPeriodList">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"Manpower.StartDate"} defaultMessage={"Start Date"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"Manpower.EndDate"} defaultMessage={"End Date"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Form.Item noStyle name={[field.name, "startDate"]}>
                                      <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item noStyle name={[field.name, "endDate"]}>
                                      <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={6}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              </Form>
            </>
          }
        />
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Equipment);