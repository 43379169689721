import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Select } from "antd";
import { InputNumber } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Tooltip, DatePicker } from "antd";
import moment from "moment";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class MaintenanceLogExpanded extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "MaintenanceLogExpanded",
      controllerName: "maintenanceLog",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    console.log("props", this.props);
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  }

  fillComboboxes = async () => {
    var responsePeriodOfMaintenanceType = await handleRequest("GET", "/api/" + this.state.controllerName + "/periodOfMaintenanceType");
    if (responsePeriodOfMaintenanceType.type === "ERROR") {
      error(responsePeriodOfMaintenanceType);
    }

    var responseMaintenanceLocation = await handleRequest("GET", "/api/" + this.state.controllerName + "/maintenanceLocation");
    if (responseMaintenanceLocation.type === "ERROR") {
      error(responseMaintenanceLocation);
    }

    var responseMaintenanceControlPlanDetailId = await handleRequest("GET", "/api/" + this.state.controllerName + "/maintenanceControlPlanDetailId");
    if (responseMaintenanceControlPlanDetailId.type === "ERROR") {
      error(responseMaintenanceControlPlanDetailId);
    }

    this.setState({
      periodOfMaintenanceTypeSelectItems: Boolean(responsePeriodOfMaintenanceType.data) ? responsePeriodOfMaintenanceType.data : [],
      maintenanceControlPlanDetailIdSelectItems: Boolean(responseMaintenanceControlPlanDetailId.data) ? responseMaintenanceControlPlanDetailId.data : [],
      maintenanceLocationSelectItems: Boolean(responseMaintenanceLocation.data) ? responseMaintenanceLocation.data : [],
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      equipmentLogId: this.props.equipmentLogId,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    })

    this.formRef.current.setFieldsValue({
      ...row,
      startDate: Boolean(row.startDate) ? moment(row.startDate) : null,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
      equipmentLogId: this.props.equipmentLogId,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="MaintenanceLogExpanded.MaintenanceControlPlan" defaultMessage="Maintenance Control Plan" />,
        key: "maintenanceControlPlanDetailId",
        render: record => {
          return record.maintenanceControlPlanDetailName;
        }, ...getColumnFilter({
          inputName: "maintenanceControlPlanDetailId", inputType: "SELECT",
          selectItems: Boolean(this.state["maintenanceControlPlanDetailIdSelectItems"]) && this.state["maintenanceControlPlanDetailIdSelectItems"].length > 0 ? this.state["maintenanceControlPlanDetailIdSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="MaintenanceLogExpanded.PeriodOfMaintenance" defaultMessage="Period Of Maintenance" />,
        key: "periodOfMaintenance",
        render: record => {
          let list = [];
          if (Boolean(record.periodOfMaintenance)) {
            list.push(record.periodOfMaintenance);
          }
          if (Boolean(record.periodOfMaintenanceTypeName)) {
            list.push(record.periodOfMaintenanceTypeName);
          }
          return list.join(" ");
        }, ...getColumnFilter({
          inputName: "periodOfMaintenance", inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="MaintenanceLogExpanded.StartDate" defaultMessage="Start Date" />,
        key: "startDate",
        render: record => {
          return Boolean(record.startDate) ? moment(record.startDate).format("DD-MM-YYYY") : "";
        }, ...getColumnFilter({
          inputName: "startDate", inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="MaintenanceLogExpanded.MaintenanceLocation" defaultMessage="Maintenance Location" />,
        key: "maintenanceLocation",
        render: record => {
          return record.maintenanceLocationName;
        }, ...getColumnFilter({
          inputName: "maintenanceLocation", inputType: "SELECT",
          selectItems: Boolean(this.state["maintenanceLocationSelectItems"]) && this.state["maintenanceLocationSelectItems"].length > 0 ? this.state["maintenanceLocationSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
    ];

    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="MaintenanceLogExpanded" hideElement={true}>             <ExcelSheet name="MaintenanceLogExpanded" data={this.state.tableList}>               <ExcelColumn label="unit" value="unit" />
      <ExcelColumn label="period" value="period" />
      <ExcelColumn label="material" value="material" />
      <ExcelColumn label="personnel" value="personnel" />
      <ExcelColumn label="equipment" value="equipment" />
    </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} 
    icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)}      */}
    </>);
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col md={21}>
            </Col>
            <Col md={1}>{excelExport}
            </Col>
            <Col md={1}>
              <ExcelReader
                onCreateJson={this.handleUploadExcel.bind(this)}>
              </ExcelReader>
            </Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary"
                  fontSize="large" />}
              ></AntButton>
              <AntButton style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error"
                  fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>

          <div hidden={this.state.hideInputs}>
            {
              <Form.Item
                {...layout}
                name="maintenanceControlPlanDetailId"
                label={<FormattedMessage id="MaintenanceLogExpanded.MaintenanceControlPlan" defaultMessage="Maintenance Control Plan" />}
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                >
                  {Boolean(this.state["maintenanceControlPlanDetailIdSelectItems"]) && this.state["maintenanceControlPlanDetailIdSelectItems"].length > 0 &&
                    this.state["maintenanceControlPlanDetailIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="MaintenanceLogExpanded.PeriodOfMaintenance" defaultMessage="Period Of Maintenance" />}
                style={{ marginBottom: "5px" }}
              >
                <Row gutter={8}>
                  <Col span={10}>
                    <Form.Item name="periodOfMaintenance">
                      <InputNumber
                        style={{
                          width: "100%"
                        }}
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                  </Col>
                  <Col span={10}>
                    <Form.Item name="periodOfMaintenanceType">
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        style={{ width: "100%" }}
                      >
                        {Boolean(this.state["periodOfMaintenanceTypeSelectItems"]) && this.state["periodOfMaintenanceTypeSelectItems"].length > 0 &&
                          this.state["periodOfMaintenanceTypeSelectItems"].map(i => (
                            <Option key={i.key} value={i.key}>
                              {i.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="MaintenanceLogExpanded.StartDate" defaultMessage="Start Date" />}
                name="startDate"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                name="maintenanceLocation"
                label={<FormattedMessage id="MaintenanceLogExpanded.MaintenanceLocation" defaultMessage="Maintenance Location" />}
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                >
                  {Boolean(this.state["maintenanceLocationSelectItems"]) && this.state["maintenanceLocationSelectItems"].length > 0 &&
                    this.state["maintenanceLocationSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            }

            <div hidden={this.state.hideSave}>
              {<Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>
              }
            </div>
            <div hidden={this.state.hideUpdate}>
              {<Row gutter={[16, 16]}>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="CancelButton" style={{ width: "100%" }}
                    variant="secondary"
                    onClick={this.cancel}
                  >
                    <FormattedMessage
                      id="GeneralButtonCancel"
                      defaultMessage="Cancel" />
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <Button id="UpdateButton" style={{ width: "100%" }}
                    variant="warning" type="submit">
                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                  </Button>
                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              </Row>}
            </div>

          </div>

        </Form>
        {
          Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 &&
          (<div className="alert alert-custom alert-light">                 <div className="alert-icon">
            <FilterOutlined />
          </div>
            <div className="alert-text">
              {this.state.searchCriteriaModels.map((val, i) => {
                return (
                  <div key={i}
                    className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">
                      {val.label}
                    </span>
                    <strong className="mr-2">
                      {val.type}
                    </strong>
                    <span className="mr-2">
                      {val.operationValue}
                    </span>
                    <span className="mr-2">
                      {val.showValue}
                    </span>
                    <span>
                      <Tooltip title="Remove">

                        <CloseCircleOutlined style={{ color: "crimson" }}
                          onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>);
              })}
            </div>
            <div className="alert-close">
              <Tooltip title="Remove All">
                <CloseCircleOutlined
                  style={{ color: "crimson" }}
                  onClick={() => this.setState({
                    searchCriteriaModels: []
                  }, () => {
                    this.filterChangeItem([]);
                  })} />
              </Tooltip>
            </div>
          </div>)
        }

        <div style={{
          marginTop: "2rem",
          marginBottom: "1rem"
        }} className="content-section implementation"
        >
          <Table
            bordered scroll={{
              x: 900, scrollToFirstRowOnChange: true
            }} columns={columns}
            dataSource={this.state.tableList}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            pagination={this.state.pagination} />
        </div>

        <Modal show={this.state.modalDeleteShow}
          onHide={this.showOrHideDeleteModal}
          aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>             <Button variant="danger" onClick={this.delete}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceLogExpanded);