import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Badge, Row, Col, Radio } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, } from "@ant-design/icons";
import { Tooltip } from "antd";
import BudgetCategory from "./BudgetCategory";
import DraggableModal from "../../../Components/DraggableModal";
import store from "../../../../redux/store";

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (Boolean(node)) {
      var lineage = [];
      if (Boolean(node.lineage) && node.lineage.length > 0) {
        node.lineage.forEach(element => {
          if (!lineage.includes(element)) {
            lineage.push(element);
          }
        });
      }

      dataList.push({
        key: node.value,
        lineage: node.lineage,
        title: node.value
      });
    }
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class Budget extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "Budget",
      controllerName: "budget",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      user: store.getState().auth.user,
      tableList: [],
      categoryList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
      columns: [
        {
          title: "",
          key: "name",
          render: (value, row, index) => {
            return (
              <React.Fragment>
                <div
                  style={{
                    color: "black",
                    font: "bold",
                    fontSize: "16px"
                  }}
                >
                  {row.name}
                </div>
              </React.Fragment>
            );
          },
          width: 200,
          fixed: "left"
        }
      ],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getCategoryList");
    if (response.type === "ERROR") {
      error(response);
    }
    let columns = [
      {
        title: "",
        key: "name",
        render: (value, row, index) => {
          return (
            <React.Fragment>
              <div
                style={{
                  color: "black",
                  font: "bold",
                  fontSize: "16px"
                }}
              >
                {row.name}
              </div>
            </React.Fragment>
          );
        },
        width: 200,
        fixed: "left"
      }
    ];
    const currentYear = new Date().getFullYear();
    const last10Years = Array.from({ length: 10 }, (_, i) => (currentYear - i).toString());
    last10Years.forEach((year) => {
      columns.push({
        title: year,
        key: year,
        render: (value, row) => row[year],
        width: 200
      });
    });
    this.setState({
      categoryList: Boolean(response.data) ? response.data : [],
      columns: columns,
    });
    this.formRef.current.setFieldsValue({
      type: "Year"
    });
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords,
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCategory = () => {
    this.setState({
      random: Math.random(),
      showCategoryModal: true,
    });
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    return (
      <Badge.Ribbon text={<FormattedMessage id="Budget.pageTitle" defaultMessage="Budget" />} placement="start" color="purple" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <br /><br />
            <Row gutter={[16, 16]}>
              <Col md={21}>
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Budget.Type" defaultMessage="Type" />}
                  name="type"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Radio.Group
                    onChange={e => {
                      let columns = [
                        {
                          title: "",
                          key: "name",
                          render: (value, row, index) => {
                            return (
                              <React.Fragment>
                                <div
                                  style={{
                                    color: "black",
                                    font: "bold",
                                    fontSize: "16px"
                                  }}
                                >
                                  {row.name}
                                </div>
                              </React.Fragment>
                            );
                          },
                          width: 200,
                          fixed: "left"
                        }
                      ];

                      if (e.target.value === "Year") {
                        const currentYear = new Date().getFullYear();
                        const last10Years = Array.from({ length: 10 }, (_, i) => (currentYear - i).toString());

                        last10Years.forEach((year) => {
                          columns.push({
                            title: year,
                            key: year,
                            render: (value, row) => row[year],
                            width: 200
                          });
                        });
                      } else if (e.target.value === "Month") {
                        const months = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
                          "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
                        ];
                        months.forEach((month) => {
                          columns.push({
                            title: month,
                            key: month,
                            render: (value, row) => row[month],
                            width: 200
                          });
                        });
                      } else if (e.target.value === "Week") {
                        for (let week = 1; week <= 52; week++) {
                          columns.push({
                            title: `Week ${week}`,
                            key: `week_${week}`,
                            render: (value, row) => row[`week_${week}`],
                            width: 100
                          });
                        }
                      }
                      this.setState({
                        type: e.target.value,
                        columns: columns,
                      });
                    }}
                  >
                    <Radio value={"Year"}>
                      <FormattedMessage id="Budget.Year" defaultMessage="Year" />
                    </Radio>
                    <Radio value={"Month"}>
                      <FormattedMessage id="Budget.Month" defaultMessage="Month" />
                    </Radio>
                    <Radio value={"Week"}>
                      <FormattedMessage id="Budget.Week" defaultMessage="Week" />
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={3}>
                {Boolean(this.state.user) && this.state.user.role === "ADMIN" && (
                  <Button style={{ width: "100%" }} variant="primary" onClick={this.addCategory}>
                    <FormattedMessage id="Budget.AddCategory" defaultMessage="Add Category" />
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">{val.label}</span>
                    <strong className="mr-2">{val.type}</strong>
                    <span className="mr-2">{val.operationValue}</span>
                    <span className="mr-2">{val.showValue}</span>
                    <span>
                      <Tooltip title="Remove">
                        <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>);
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined style={{ color: "crimson" }}
                    onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />
                </Tooltip>
              </div>
            </div>)}

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={this.state.columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={false}
            />
          </div>
          <br />
          {/* {this.state.categoryList.map(i => (
            <>
              <Row gutter={[16, 16]}>
                <Col md={24}>
                  <Button
                    style={{ width: "100%", textAlign: "left" }}
                    variant="secondary"
                  >
                    {i.mainTypeName + " / " + i.subTypeName}
                  </Button>
                </Col>
              </Row>
              <br /><br />
            </>
          ))} */}

          <Modal
            show={this.state.modalDeleteShow}
            onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>             <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
            </Modal.Title>           </Modal.Header>           <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>           </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>             <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

          <DraggableModal
            title={<FormattedMessage id="Budget.Budget" defaultMessage="Budget" />}
            centered
            width={800}
            maskClosable={false}
            open={this.state.showCategoryModal}
            okButtonProps={{ hidden: true }}
            onCancel={() => {
              this.setState({ showCategoryModal: false });
            }}
            content={
              <BudgetCategory random={this.state.random}
                close={() => {
                  this.setState({ showCategoryModal: false });
                  const { pagination } = this.state;
                  this.restartTable({ pagination });
                }}
              />
            }
          />
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Budget);