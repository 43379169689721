import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Tree, Form, Badge, Row, Col } from "antd";
import { FcFolder, FcAddressBook, FcAreaChart } from "react-icons/fc";
import { useContextMenu } from "react-contexify";
import { FcTreeStructure, FcLibrary } from "react-icons/fc";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../../ApiConnector";
import { showError, showSuccess, error } from "../../../MessageHelper";

const { show } = useContextMenu();

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

const dataListMaterialType = [];
const generateListMaterialType = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataListMaterialType.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateListMaterialType(node.children);
    }
  }
};

export class EldenEquipmentHardware extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "EldenEquipmentHardware",
      controllerName: "eldenEquipmentHardware",
      id: null,
      description: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      materialTypeIds: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      refreshTree: true
    };
  }

  inputRef = React.createRef(null);
  formRef = React.createRef();

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = {
      id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1,
      type: Boolean(treenode.type) ? treenode.type : ""
    };

    var response = null;
    console.log("treenode", treenode);
    if (Boolean(treenode.key)) {
      response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitems/" + obj.id);
    }
    if (Boolean(response) && response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach((element, index) => {
        element.icon =
          Boolean(element.type) && element.type === "Add_Equipment_Tree" ? (
            <FcLibrary />
          ) : element.type === "Group" ? (
            <FcTreeStructure />
          ) : element.type === "Category" ? (
            <FcTreeStructure />
          ) : element.type === "Type" ? (
            <FcAreaChart />
          ) : element.type === "Equipment" ? (
            <FcAddressBook />
          ) : element.type === "Manufacturer" ? (
            <FcAddressBook />
          ) : (
            <FcFolder />
          );

        if (Boolean(element.type)) {
          if (element.type === "Group") {
            element.title = element.name;
          } else if (element.type === "Category") {
            element.title = element.name;
          } else if (element.type === "Type") {
            element.title = element.name;
          } else if (element.type === "Equipment") {
            element.title = element.name;
          } else if (element.type === "Manufacturer") {
            element.title = element.name;
          } else
            element.title = element.name;
        }
        element.key = element.id;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      this.setState({
        nodes: res,
        refreshTree: true
      }, async () => {
        var responseHardware = await handleRequest("GET", "/api/" + this.state.controllerName + "/hardware/" + this.props.eldenEquipmentTreeId);
        if (responseHardware.type === "ERROR") {
          error(responseHardware);
        }
        let checkedKeys = [];
        if (Boolean(responseHardware.data) && responseHardware.data.length > 0) {
          responseHardware.data.forEach(element => {
            checkedKeys.push(element.eldenEquipmentTreeId);
          });
        }
        console.log("checkedKeys", checkedKeys);
        this.setState({
          checkedKeys: checkedKeys
        });
      });
    } else {
      this.setState({
        refreshTree: true,
      });
    }
  };

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = prevProps => {
    let random = this.props.random;
    if (random !== prevProps.random) {
      this.initialize();
    }
  };

  initialize = async () => {
    this.fillTree();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {

  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.disableCheckbox = true;
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "Add_Equipment_Tree";
          }
          element.title = element.name;
          element.key = element.id;
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  save = async () => {
    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/addSelectedKeys/" + this.props.eldenEquipmentTreeId, this.state.checkedKeys);
    if (response.type === "ERROR") {
      error(response);
    } else {
      showSuccess();
      this.props.nextTab();
    }
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      description: "",
      code: "",
      showCreateModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null
    });
  };

  onCheck = async (checkedKeys, e) => {
    console.log("checkedKeys", checkedKeys);
    this.setState({
      checkedKeys: checkedKeys,
      checkedNodes: e.checkedNodes
    });
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Equipment Tree "} />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <br />
            <br />

            {this.state.refreshTree && (
              <Tree
                style={{ marginTop: "10px", lineHeight: "30px" }}
                className="draggable-tree"
                checkable
                defaultExpandAll
                showLine={true}
                onCheck={this.onCheck}
                checkedKeys={this.state.checkedKeys}
                treeData={this.state.nodes}
                loadedKeys={this.state.loadedKeys}
                onLoad={this.onLoadData}
                loadData={this.loadData}
                onExpand={this.onExpand}
                expandedKeys={this.state.expandedKeys}
                autoExpandParent={false}
                blockNode={true}
              />
            )}

            <Row gutter={[16, 16]}>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Col>
              <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
            </Row>
          </Form>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EldenEquipmentHardware);