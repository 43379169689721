import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { Badge, Col, Form, Row, Table } from "antd";
import { handleRequest } from "../../../ApiConnector";
import { Assignment } from "./Assignment";
import DraggableModal from "../../../Components/DraggableModal";
import AssignmentSingle from "./AssignmentSingle";
import AssignmentMultiple from "./AssignmentMultiple";

export class AssignmentPersonnel extends Component {
    constructor() {
        super();
        this.state = {
            controllerName: "assignment",
            modalDeleteShow: false,
            exportList: [],
            exportClick: false,
            hideUpdate: true,
            hideSave: false,
            hideInputs: true,
            lastUpdateDate: [],

            id: null,
            tableList: [],
            personnelsSelectItems: [],
            departmentItems: [],
            designationItems: [],
            searchCriteriaModels: [],

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
                total: 0
            },
            loading: false,
            sortField: "",
            sortOrder: "",

            excelData: []
        };
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });

        this.fillComboboxes();
    };

    fillComboboxes = async () => {
    };

    cancel = () => {
        this.setState({
            hideUpdate: true,
            hideSave: false,
            hideInputs: true
        });
        this.resetInputs();
    };

    resetInputs = () => {
        this.setState({
            id: null,

            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
                total: this.state.totalRecords
            },
            loading: false,
            sortField: "",
            sortOrder: "",
        });
    };

    edit = row => {
        document.getElementById("kt_scrolltop").click();
        this.formRef.current.setFieldsValue({
            ...row,
        });
        this.setState({
            hideUpdate: false,
            hideSave: true,
            hideInputs: false,
            id: row.id
        });
    };

    deleteModal = row => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    };

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    delete = async () => {
        const deletedItem = {
            id: this.state.id
        };
        var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

        if (response.type === "ERROR") {
            error(response);
        } else {
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
            this.setState({
                id: null,
                modalDeleteShow: false
            });
        }
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({
            sortField: sorter.columnKey,
            sortOrder: sorter.order,
            pagination,
            filters
        });
    };

    restartTable = async (params = {}) => {
        this.setState({ loading: true });
        const newObj = this.createWhere(params);
        let url = "/api/" + this.state.controllerName + "/pagingPersonnel";
        const response = await handleRequest("POST", url, newObj);
        if (response.type === "ERROR") {
            error(response);
            this.setState({
                loading: false,
                tableList: [],
                pagination: {
                    ...params.pagination,
                    total: 0
                },
                totalRecords: 0,
                sortField: "",
                sortOrder: ""
            });
        } else {
            if (response.data.length !== 0) {
                let list = response.data.data;
                list.forEach((element, index) => {
                    element.index = index + 1;
                    element.key = index + 1;
                });
                this.setState({
                    loading: false,
                    tableList: list,
                    pagination: {
                        ...params.pagination,
                        total: response.data.totalRecords
                    },
                    totalRecords: response.data.totalRecords,
                    sortField: params.sortField,
                    sortOrder: params.sortOrder
                });
            } else {
                this.setState({
                    loading: false,
                    tableList: [],
                    pagination: {
                        ...params.pagination,
                        total: 0
                    },
                    totalRecords: 0,
                    sortField: "",
                    sortOrder: ""
                });
            }
        }
    };

    onClickCreateNew = () => {
        this.resetInputs();
        if (this.state.hideInputs) {
            this.setState({
                hideInputs: false,
                hideSave: false,
                hideUpdate: true
            });
        } else {
            this.setState({
                hideInputs: true,
                hideSave: true,
                hideUpdate: false
            });
        }
    };

    save = async values => {
        const newItem = {
            ...values,
            id: this.state.id,
            parentId: Boolean(values.parentId) ? values.parentId[values.parentId.length - 1] : null
        };

        if (newItem.id === null) {
            var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
        } else {
            response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
        }

        if (response.type === "ERROR") {
            error(response);
        } else {
            this.cancel();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
        }
    };

    createWhere(params) {
        if (Boolean(params._pagination)) params.pagination = params._pagination;

        var lastUpdateDateStart = null,
            lastUpdateDateEnd = null,
            searchCriteriaModels = [],
            sortField = "",
            sortOrder = "";
        if (Boolean(params) && Boolean(params.pagination)) {
            if (Boolean(params.pagination.lastUpdateDate)) {
                lastUpdateDateStart = params.pagination.lastUpdateDate[0];
                lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
            }
            if (Boolean(params.sortField)) {
                sortField = params.sortField;
            }
            if (Boolean(params.sortOrder)) {
                sortOrder = params.sortOrder;
            }
        }
        const newObj = {
            ...params.pagination,
            id: null,
            lastUpdateDateStart: lastUpdateDateStart,
            lastUpdateDateEnd: lastUpdateDateEnd,
            firstResult: params.pagination.current - 1,
            maxResults: params.pagination.pageSize,
            sortField: sortField,
            sortOrder: sortOrder,
            searchCriteriaModels: searchCriteriaModels,
        };
        return newObj;
    }

    handleExportExcel = async () => {
        this.setState({
            exportClick: true
        });
    };

    handleUploadExcel = async excelJson => {
        try {
            var list = [];
            if (excelJson === null) showError("Select an excel file");
            else {
                excelJson.forEach(element => {
                    const obj = {};
                    list.push(obj);
                });
                if (Boolean(list) && list.length > 0) {
                    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
                    if (response.type === "ERROR") {
                        showError("No records to be added");
                    } else {
                        this.resetInputs();
                        const { pagination } = this.state;
                        this.restartTable({ pagination });
                        showSuccess();
                    }
                } else {
                    showError("No records to be added");
                }
            }
        } catch (error) {
            showError("");
        }
    };

    sendAssignModal = () => {
        this.setState({
            modalAssignShow: true
        });
    };

    addAssignButtonClick = async type => {
        console.log("type", type);
        if (type === "Single") {
            this.setState({
                modalAssignmentSingleShow: true,
                modalAssignShow: false
            });
        } else if (type === "Multiple") {
            this.setState({
                modalAssignmentMultipleShow: true,
                modalAssignShow: false
            });
        }
    }

    render() {
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };

        const columns = [
            {
                title: "S/N",
                key: "id",
                width: "10%",
                render: (value, row, index) => {
                    return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
                }
            },
            {
                title: <FormattedMessage id="AssignmentPersonnel.identificationNo" defaultMessage="ID" />,
                key: "identificationNo",
                render: record => {
                    return record.identificationNo;
                },
                sorter: false,
                width: "20%",
            },
            {
                title: <FormattedMessage id="AssignmentPersonnel.personnelName" defaultMessage="Name" />,
                key: "personnelName",
                render: record => {
                    var personnelName = record.name + " ";
                    personnelName += Boolean(record.midName) ? record.midName + " " : " ";
                    personnelName += record.lastName;
                    return personnelName;
                },
                sorter: false,
                width: "70%",
            }
        ];

        return (
            <Badge.Ribbon
                text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage="Assignment" />}
                placement="start"
                color="volcano"
                style={{ fontSize: 12 }}
            >
                <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
                    <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
                        <Row gutter={[16, 16]}>
                            <Col md={21}>
                            </Col>
                            <Col md={3}>
                                <Button
                                    variant="primary"
                                    onClick={this.sendAssignModal.bind(this)}
                                    style={{ width: "100%", marginTop: "10px" }}
                                    loading={this.state.loading}
                                >
                                    <FormattedMessage id="AssignmentPersonnel.Assign" defaultMessage="Assign" />
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                    <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
                        <Table
                            rowKey="id"
                            bordered
                            scroll={{ x: 900, scrollToFirstRowOnChange: true }}
                            columns={columns}
                            dataSource={this.state.tableList}
                            loading={this.state.loading}
                            onChange={this.handleTableChange}
                            pagination={this.state.pagination}
                            expandable={{
                                expandedRowRender: (record) => (
                                    <Assignment
                                        random={Math.random()}
                                        personnelLogId={record.id}
                                    ></Assignment>
                                ),
                                rowExpandable: () => {
                                    return true;
                                },
                            }}
                        />
                    </div>

                    <DraggableModal
                        title={<FormattedMessage id="AssignmentPersonnel.SingleOrMultiple" defaultMessage="Single or Multiple" />}
                        centered
                        width={400}
                        maskClosable={false}
                        open={this.state.modalAssignShow}
                        okButtonProps={{ hidden: true }}
                        cancelButtonProps={{ hidden: true }}
                        onCancel={() => {
                            this.setState({ modalAssignShow: false });
                        }}
                        content={
                            <>
                                <Button
                                    onClick={() => {
                                        this.addAssignButtonClick("Single");
                                    }}
                                    style={{ width: "100%" }}
                                    variant="secondary"
                                >
                                    <FormattedMessage id="AssignmentPersonnel.Single" defaultMessage="Single" />
                                </Button>
                                <br /> <br />
                                <Button
                                    onClick={() => {
                                        this.addAssignButtonClick("Multiple");
                                    }}
                                    style={{ width: "100%" }}
                                    variant="secondary"
                                >
                                    <FormattedMessage id="AssignmentPersonnel.Multiple" defaultMessage="Multiple" />
                                </Button>
                            </>
                        }
                    />

                    <DraggableModal
                        title={<FormattedMessage id="AssignmentPersonnel.SingleAssignment" defaultMessage="Single Assignment" />}
                        open={this.state.modalAssignmentSingleShow}
                        okButtonProps={{ hidden: true }}
                        onCancel={() => this.setState({ modalAssignmentSingleShow: false })}
                        width={1200}
                        centered
                        content={
                            <AssignmentSingle random={Math.random()} close={() => this.setState({ modalAssignmentSingleShow: false })} />
                        }
                    />

                    <DraggableModal
                        title={<FormattedMessage id="AssignmentPersonnel.MultipleAssignment" defaultMessage="Multiple Assignment" />}
                        open={this.state.modalAssignmentMultipleShow}
                        okButtonProps={{ hidden: true }}
                        onCancel={() => this.setState({ modalAssignmentMultipleShow: false })}
                        width={1600}
                        centered
                        content={
                            <AssignmentMultiple random={Math.random()} close={() => this.setState({ modalAssignmentMultipleShow: false })} />
                        }
                    />

                    <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={this.delete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </Badge.Ribbon>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentPersonnel);