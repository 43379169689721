import {
  BankOutlined,
  CommentOutlined,
  ContactsOutlined,
  ExperimentOutlined,
  FileDoneOutlined,
  LikeOutlined,
  MessageOutlined,
  PhoneOutlined,
  ReadOutlined,
  TeamOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Modal as AntModal, Steps, Table, Form, Select, Badge, Space, Divider, InputNumber, Col, Button as AntButton, Input, DatePicker } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import DataTableComponent2 from "../../Components/DataTableComponent2";
import { GetDynamicFormItems } from "../../Components/ElementUtils";
import FormTopRightButtonsComponent from "../../Components/FormTopRightButtonsComponent";
import PersonnelLogSaveFormJson from "../../Components/PersonnelLogSaveForm";
import { error, showError, showSuccess, showWarning } from "../../MessageHelper";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import ActionMenu from "../CustomMenu/ActionMenu";
import { JobOfferBankAccount } from "./JobOfferBankAccount";
import { JobOfferComment } from "./JobOfferComment";
import { JobOfferContact } from "./JobOfferContact";
import { JobOfferContract } from "./JobOfferContract";
import { JobOfferEducation } from "./JobOfferEducation";
import { JobOfferExperience } from "./JobOfferExperience";
import { JobOfferHobby } from "./JobOfferHobby";
import { JobOfferLanguage } from "./JobOfferLanguage";
import { JobOfferPromptForm } from "./JobOfferPromptForm";
import JobOfferPersonalInformation from "./JobOfferPersonalInformation";
import { JobOfferReference } from "./JobOfferReference";
import { JobOfferTraining } from "./JobOfferTraining";
import moment from "moment";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { PlusOutlined } from "@ant-design/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";

const { Step } = Steps;
const { Option } = Select;

export class JobOffer extends Component {
  constructor() {
    super();
    this.state = {
      formName: "JobOffer",
      controllerName: "jobOffer",
      modalDeleteShow: false,
      dataTable: PersonnelLogSaveFormJson.dataTable,
      inputs: PersonnelLogSaveFormJson.inputs,
      exportList: [],
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      isPromptModalVisible: false,
      isPersonnelSaveModalVisible: false,
      id: null,
      currentRow: null,
      currentStep: 0,
      tableList: [],
      restartTable: false,
      pageFormData: null,
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],

    };
  }

  formRefReply = React.createRef();
  formRefWithrawnTheOffer = React.createRef();
  formRefSend = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    if (Boolean(this.props) && Boolean(this.props.row)) {
      this.edit(this.props.row);
    }
    this.restartTable({ pagination });
    this.fillComboboxes();
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  fillComboboxes = async () => {
    var responseAcceptedRequestsChangeRejectedType = await handleRequest("GET", "/api/" + this.state.controllerName + "/acceptedRequestsChangeRejectedType");
    if (responseAcceptedRequestsChangeRejectedType.type === "ERROR") {
      error(responseAcceptedRequestsChangeRejectedType);
    }
    this.setState({
      responseIdSelectItems: Boolean(responseAcceptedRequestsChangeRejectedType.data) ? responseAcceptedRequestsChangeRejectedType.data : []
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onPersonnelSave = values => {
    this.setState({
      id: values.id,
      currentPersonnelName: values.name,
      isPersonnelSaveModalVisible: false
    });
    this.restartTable();
  };

  onPersonnelEdit = values => {
    this.setState({
      id: values.id,
      currentPersonnelName: values.name,
      isPersonnelSaveModalVisible: false
    });
  };

  onPersonnelPromptSave = values => {
    this.setState({
      isPersonnelSaveModalVisible: false
    });
    this.restartTable();
  };

  resetInputs = () => {
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRefReply.current.resetFields();
    }
    this.setState({
      id: null,
      currentRow: null,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
      filePath: "",
      fileList: []
    });
  };

  edit = row => {
    document.getElementById("kt_scrolltop").click();
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
      currentPersonnelName: row.name + " " + (Boolean(row.midName) ? row.midName : "") + " " + row.lastName,
      currentRow: row,
      isPrompt: false
    });
  };

  showReplyModal = row => {
    this.setState({
      showReplyModal: true,
      id: row.id
    });
  };

  showWithdrawnTheOfferModal = row => {
    this.setState({
      showWithdrawnTheOfferModal: true,
      id: row.id
    });
  };

  showSendModal = row => {
    this.setState({
      showSendModal: true,
      id: row.id
    });
  };

  setData = data => {
    this.setState({
      tableList: data
    });
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/personnelLog/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  // restartTable = async (params = {}) => {
  //   this.setState({ restartTable: !this.state.restartTable });
  // };

  onClickShowPrompt = () => {
    this.setState({
      isPromptModalVisible: true
    });
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        isPromptModalVisible: false
      });
    } else {
      this.setState({
        id: null,
        currentRow: null,
        hideInputs: true,
        isPromptModalVisible: false,
        currentStep: 0
      });
    }
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            //name: element["Name"],
            //shortName: element["Short Name"],
            // [IMPORT_OBJECT]

            companyId: "14",
            projectId: "13",
            name: element["Ad"],
            midName: element["Ad2"],
            lastName: element["Soyad"],
            phoneNumber: element["Telefon"],
            email: element["Mail Adresi"]
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/personnelLog/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            this.restartTable();
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };
  handleChangeAlarmType = e => {
    this.setState({
      alarmTypeId: e
    });
  };

  saveReply = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };
    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateReply/" + newItem.id, newItem);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        showReplyModal: false
      });
    }
  };

  saveWithdrawnTheOffer = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };
    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateWithdrawnTheOffer/" + newItem.id, newItem);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        showWithdrawnTheOfferModal: false
      });
    }
  };

  saveSend = async values => {
    const newItem = {
      ...values,
      id: this.state.id,
    };
    var response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateSend/" + newItem.id, newItem);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        showSendModal: false
      });
    }
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let sendAction = {
      name: "Send",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.showSendModal.bind(this)
    };
    let revisionAction = {
      name: "Revision",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let replyAction = {
      name: "Reply",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.showReplyModal.bind(this)
    };
    let cancelAction = {
      name: "Cancel",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let withdrawnTheOfferAction = {
      name: "Withdrawn The Offer",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.showWithdrawnTheOfferModal.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };
    let createContractAction = {
      name: "Create Contract",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };


    let actionList = [];
    if (Boolean(record.status)) {
      if (record.status === "Send") {
        actionList.push(revisionAction);
        actionList.push(replyAction);
        actionList.push(withdrawnTheOfferAction);
      } else if (record.status === "Revision") {
        actionList.push(editAction);
        actionList.push(sendAction);
        actionList.push(withdrawnTheOfferAction);
      } else if (record.status === "Accepted") {
        actionList.push(createContractAction);
        actionList.push(withdrawnTheOfferAction);
      } else if (record.status === "RequestsChange") {
        actionList.push(revisionAction);
        actionList.push(withdrawnTheOfferAction);
      } else if (record.status === "WithdrawnTheOffer") {

      }
      else {
        actionList.push(editAction);
        actionList.push(sendAction);
        actionList.push(deleteAction);
        actionList.push(cancelAction);
        actionList.push(replyAction);
      }
    } else {
      actionList.push(editAction);
      actionList.push(sendAction);
      actionList.push(deleteAction);
      actionList.push(cancelAction);
      actionList.push(replyAction);
    }
    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  closePersonnelSaveModal = () => {
    this.setState({
      isPersonnelSaveModalVisible: false
    });
  };
  onStepsChange = current => {
    this.setState({ currentStep: current });
  };

  render() {
    const { currentStep } = this.state;

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const next = () => {
      if (steps[currentStep + 1].disabled) {
        showWarning("Please fill all the required fields and save!");
        return;
      }
      this.setState({
        currentStep: currentStep + 1
      });
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="JobOffer.PhoneNumber" defaultMessage="Offer No" />,
        key: "offerNo",
        render: record => {
          return record.offerNo;
        },
        ...getColumnFilter({
          inputName: "offerNo",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="JobOffer.RevNo" defaultMessage="Rev. No" />,
        key: "revNo",
        render: record => {
          return record.revNo;
        },
        ...getColumnFilter({
          inputName: "revNo",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="JobOffer.Status" defaultMessage="Status" />,
        key: "status",
        render: record => {
          return record.status;
        },
        ...getColumnFilter({
          inputName: "status",
          inputType: "SELECT",
          selectItems: Boolean(this.state["personnelTypeSelectItems"]) && this.state["personnelTypeSelectItems"].length > 0 ? this.state["personnelTypeSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="JobOffer.RequestNo" defaultMessage="Request No" />,
        key: "requestNo",
        render: record => {
          return record.requestNo;
        },
        ...getColumnFilter({
          inputName: "requestNo",
          inputType: "NUMBER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="JobOffer.Department" defaultMessage="Department" />,
        key: "department",
        render: record => {
          return record.department;
        },
        ...getColumnFilter({
          inputName: "department",
          inputType: "SELECT",
          selectItems: Boolean(this.state["personnelTypeSelectItems"]) && this.state["personnelTypeSelectItems"].length > 0 ? this.state["personnelTypeSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="JobOffer.Designation" defaultMessage="Designation" />,
        key: "designation",
        render: record => {
          return record.designation;
        },
        ...getColumnFilter({
          inputName: "designation",
          inputType: "SELECT",
          selectItems: Boolean(this.state["personnelTypeSelectItems"]) && this.state["personnelTypeSelectItems"].length > 0 ? this.state["personnelTypeSelectItems"] : [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="JobOffer.Adress" defaultMessage="Name" />,
        key: "name",
        render: record => {
          return record.name;
        },
        ...getColumnFilter({
          inputName: "name",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="JobOffer.OfferDate" defaultMessage="Offer Date" />,
        key: "offerDate",
        render: record => {
          return record.offerDate;
        }, ...getColumnFilter({
          inputName: "offerDate", inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="JobOffer.ReplyDate" defaultMessage="Reply Date" />,
        key: "replyDate",
        render: record => {
          return record.replyDate;
        }, ...getColumnFilter({
          inputName: "replyDate", inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="JobOffer.Reply" defaultMessage="Reply" />,
        key: "reply",
        render: record => {
          return record.reply;
        },
        ...getColumnFilter({
          inputName: "reply",
          inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="JobOffer.ForcastedStartDate" defaultMessage="Forcasted Start Date" />,
        key: "forcastedStartDate",
        render: record => {
          return record.forcastedStartDate;
        }, ...getColumnFilter({
          inputName: "forcastedStartDate", inputType: "DATEPICKER",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
    ];

    const prev = () => {
      this.setState({
        currentStep: currentStep - 1
      });
    };
    let iconStyle = {
      minWidth: "50px",
      fontSize: "50px"
    };
    const steps = [
      {
        title: "Personal Information",
        description: "0/15",
        content: (
          <JobOfferPersonalInformation
            row={this.state.currentRow}
            isPrompt={false}
            onSave={this.onPersonnelSave.bind(this)}
            onEdit={this.onPersonnelEdit.bind(this)}
            onCancel={this.onClickCreateNew.bind(this)}
          />
        ),
        icon: <UserOutlined style={{ ...iconStyle }} />
      },
      {
        title: "Contacts",
        description: "0 Records. 0/15",
        content: <JobOfferContact jobOfferId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <PhoneOutlined style={{ ...iconStyle }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "Tranings",
        description: "0 Records. 0/15",
        content: <JobOfferTraining jobOfferId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <TeamOutlined style={{ ...iconStyle }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "Contract",
        description: "0 Records. 0/15",
        content: <JobOfferContract jobOfferId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <FileDoneOutlined style={{ fontSize: "50px" }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "Experiences",
        description: "0 Records. 0/15",
        content: <JobOfferExperience jobOfferId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <ExperimentOutlined style={{ fontSize: "50px" }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "Education",
        description: "0 Records. 0/15",
        content: <JobOfferEducation jobOfferId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <ReadOutlined style={{ fontSize: "50px" }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "Language Skills",
        description: "0 Records. 0/15",
        content: <JobOfferLanguage jobOfferId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <MessageOutlined style={{ fontSize: "50px" }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "Bank Accounts",
        description: "0 Records. 0/15",
        content: <JobOfferBankAccount jobOfferId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <BankOutlined style={{ fontSize: "50px" }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "Hobbies",
        description: "0 Records. 0/15",
        content: <JobOfferHobby jobOfferId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <LikeOutlined style={{ fontSize: "50px" }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "References",
        description: "0 Records. 0/15",
        content: <JobOfferReference jobOfferId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <ContactsOutlined style={{ fontSize: "50px" }} />,
        disabled: !Boolean(this.state.id)
      },
      {
        title: "Comments",
        description: "0 Records. 0/15",
        content: <JobOfferComment jobOfferId={this.state.id} personnelName={this.state.currentPersonnelName} />,
        icon: <CommentOutlined style={{ fontSize: "50px" }} />,
        disabled: !Boolean(this.state.id)
      }
    ];
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <FormTopRightButtonsComponent
          title="Job Offer"
          inputs={this.state.inputs}
          tableList={this.state.dataTable}
          handleUploadExcel={this.handleUploadExcel.bind(this)}
          hideInputs={this.state.hideInputs}
          onClickCreateNew={this.onClickCreateNew.bind(this)}
          onClickCancel={this.onClickCreateNew.bind(this)}
        />
        <div hidden={this.state.hideInputs}>
          <PerfectScrollbar style={{ minHeight: "90px" }}>
            <Steps type="navigation" current={currentStep} onChange={this.onStepsChange}>
              {steps.map(item => (
                <Step
                  style={{ minWidth: "230px" }}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  description={item.description}
                  disabled={item.disabled}
                />
              ))}
            </Steps>
          </PerfectScrollbar>
          <div className="steps-action">
            {currentStep < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button type="primary" onClick={() => showSuccess("Processing complete!")}>
                Done
              </Button>
            )}
            {currentStep > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </div>
          <div className="steps-content">{steps[currentStep].content}</div>
        </div>

        <div hidden={!this.state.hideInputs} style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            {" "}
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />{" "}
          </div>

        </div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* <AntModal
          title="Job Offer Select"
          visible={this.state.isPromptModalVisible}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ isPromptModalVisible: false })}
        >
          <Button
            onClick={() => {
              this.setState({ isPromptModalVisible: false, isPersonnelSaveModalVisible: true });
            }}
            style={{ width: "100%", marginBottom: "2rem" }}
            variant="success"
          >
            <FormattedMessage id="JobOffer.PromtRecordMessage" defaultMessage="Prompt Record" />
          </Button>
          <Button onClick={this.onClickCreateNew} style={{ width: "100%", marginBottom: "2rem" }} variant="warning">
            <FormattedMessage id="JobOffer.NormalRecordMessage" defaultMessage="Normal Record" />
          </Button>
        </AntModal> */}

        <AntModal
          title="Personnel Record"
          visible={this.state.isPersonnelSaveModalVisible}
          okButtonProps={{ hidden: true }}
          onCancel={() => this.setState({ isPersonnelSaveModalVisible: false })}
          width={800}
        >
          <JobOfferPromptForm row={null} closeModal={this.closePersonnelSaveModal.bind(this)} onSave={this.onPersonnelPromptSave.bind(this)} />
        </AntModal>

        <AntModal
          title={<FormattedMessage id="JobOffer.Reply" defaultMessage="Reply" />}
          open={this.state.showReplyModal}
          okButtonProps={{ hidden: false }}
          onCancel={() => this.setState({ showReplyModal: false })}
          onOk={() => this.formRefReply.current.submit()}
          width={1300}
          centered
        >
          <Form initialValues={{ remember: false }} onFinish={this.saveReply} onFinishFailed={onFinishFailed} ref={this.formRefReply}>

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="JobOffer.Response" defaultMessage="Response" />}
                name="responseId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Select allowClear
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                >
                  {Boolean(this.state["responseIdSelectItems"]) && this.state["responseIdSelectItems"].length > 0 &&
                    this.state["responseIdSelectItems"].map(i => (
                      <Option key={i.key} value={i.key}>
                        {i.value}
                      </Option>
                    ))}{" "}
                </Select>
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="JobOffer.ForecastedStartDate" defaultMessage="Forecasted Start Date" />}
                name="forecastedStartDate"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
              </Form.Item>
            }
            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="JobOffer.Requests" defaultMessage="Requests" />}
                name="requests"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            }

            {
              <Form.Item
                {...layout}
                label={<FormattedMessage id="JobOffer.Justifications" defaultMessage="Justifications" />}
                name="justifications"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                  }
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            }

          </Form>
        </AntModal>

        <AntModal
          title={<FormattedMessage id="JobOffer.WithdrawnTheOffer" defaultMessage="Withdrawn The Offer" />}
          open={this.state.showWithdrawnTheOfferModal}
          okButtonProps={{ hidden: false }}
          onCancel={() => this.setState({ showWithdrawnTheOfferModal: false })}
          onOk={() => this.formRefWithrawnTheOffer.current.submit()}
          width={1300}
          centered
        >
          <Form initialValues={{ remember: false }} onFinish={this.saveWithdrawnTheOffer} onFinishFailed={onFinishFailed} ref={this.formRefReply}>

            {
              <FormattedMessage id="JobOffer.ProcessCompleted" defaultMessage="Process completed" />
            }

          </Form>
        </AntModal>

        <AntModal
          title={
            <span>
              <span style={{ verticalAlign: "-0.2em" }} className="ml-2">
                <FormattedMessage id="JobOffer.Send" defaultMessage="Send" />
              </span>
            </span>
          }
          centered
          open={this.state.showSendModal}
          onOk={() => this.formRefSend.current.submit()}
          onCancel={() => this.setState({ showSendModal: false })}
          okText={<FormattedMessage id="GeneralButtonConfirm" defaultMessage="Confirm" />}
          cancelText={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />}
        >
          <Form initialValues={{ remember: false }} onFinish={this.saveSend} onFinishFailed={onFinishFailed} ref={this.formRefSend}>
          <FormattedMessage id="RequestForQuotation.Doyoureallywanttosend" defaultMessage="Do you really want to send ?" />
          </Form>
        </AntModal>

      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(JobOffer);
