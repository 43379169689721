import { EditOutlined } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { PlusOutlined, DeleteFilled, DeleteOutlined, EditFilled } from "@ant-design/icons";
import { Button as AntButton, Space, Card, Badge, Col, Form, Input, Row, Select, Divider, Collapse } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest, API_BASE_ROOT } from "../../ApiConnector";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { Button, Modal, Col as ColBoot, Row as RowBoot, Form as FormBoot } from "react-bootstrap";
import FunctionsIcon from '@material-ui/icons/Functions';

const { Option } = Select;
const { Panel } = Collapse;

export class VendorPerformance extends Component {
  constructor() {
    super();
    this.state = {
      controllerName: "vendorPerformance",
      modalDeleteShow: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      id: null,
      tableList: [],
      loading: false
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.restartTable();
    this.fillComboboxes();
  };

  componentDidUpdate = async nextProps => {};

  fillComboboxes = async () => {
    this.fillCode("VendorPerformanceTitle");

  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null
    });
  };

  edit = row => {
    console.log("AAA", row);
    document.getElementById("kt_scrolltop").click();
    this.formRef.current.setFieldsValue({
      ...row
    });
    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    let response = await handleRequest("GET", "/api/" + this.state.controllerName + "/getAllByCompany");
    console.log("XXX", response.data);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: []
      });
    } else {
      this.setState({
        loading: false,
        tableList: response.data
      });
    }
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    console.log("AAA", values);
    let newItem = {
      ...values,
      id: this.state.id
    };

    if (!Boolean(newItem.id)) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      this.restartTable();
      showSuccess();
    }
  };

  renderElement = values => {
    return (
      Boolean(values) &&
      values.length > 0 &&
      values.map((item, index) => (
        <Panel header={item.title} key={index}>
          {Boolean(item.children) && item.children.length > 0 && (
            <>
              {Boolean(item.children) &&
                item.children.length > 0 &&
                item.children.map((item, index) => (
                  <>
                    {/* <div style={{ backgroundColor: "white", border: "1px solid #1ab394" }}> */}
                    {/* <FormBoot.Group style={{ marginBottom: "1rem" }} as={Row}>
                        <div style={{ float: "left" }}>
                          <FormBoot.Label style={{ color: "black" }} column sm="10">
                            {item.question}
                          </FormBoot.Label>
                        </div>

                        <div style={{ float: "right" }}>
                          <ColBoot sm="2">
                            <AntButton type="dashed" onClick={() => this.edit(item)} shape="circle" variant="primary" icon={<EditOutlined />} />
                            <AntButton type="dashed" onClick={() => this.deleteModal(item)} shape="circle" danger icon={<CloseOutlined />} />
                          </ColBoot>
                        </div>
                      </FormBoot.Group> */}

                    <Card
                      type="inner"
                      size="small"
                      extra={
                        <Space>
                          <AntButton size="small" type="primary" onClick={() => this.setFormula(item)} shape="circle" icon={<FunctionsIcon />} />
                          <AntButton size="small" type="primary" onClick={() => this.edit(item)} shape="circle" icon={<EditFilled />} />
                          <AntButton
                            size="small"
                            type="danger"
                            danger
                            onClick={() => this.deleteModal(item)}
                            shape="circle"
                            icon={<DeleteFilled />}
                          />
                        </Space>
                      }
                    >
                      {item.question}
                    </Card>

                    {/* </div> */}
                    <br />
                  </>
                ))}
            </>
          )}
        </Panel>
      ))
    );
  };

  setFormula = item => {
    console.log(item)
  };

  setOpenPanels = key => {
    this.setState({
      activePanelKey: key
    });
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    return (
      <Badge.Ribbon text="Vendor Performance" placement="start" color="volcano" style={{ fontSize: 12 }}>
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Row gutter={[16, 16]}>
            <Col md={23}></Col>
            <Col md={1}>
              <AntButton
                style={{ border: "0px" }}
                hidden={!this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<CreateIcon color="secondary" fontSize="large" />}
              ></AntButton>
              <AntButton
                style={{ border: "0px" }}
                hidden={this.state.hideInputs}
                onClick={this.onClickCreateNew}
                icon={<RemoveCircle color="error" fontSize="large" />}
              ></AntButton>
            </Col>
          </Row>
          <div hidden={this.state.hideInputs}>
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="VendorPerformance.titleId" defaultMessage="Title" />}
                  name="titleId"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("VendorPerformanceTitle")}
                          >
                            <PlusOutlined /> Add item
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state["VendorPerformanceTitleSelectItems"]) &&
                      this.state["VendorPerformanceTitleSelectItems"].map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }

              <Form.Item
                {...layout}
                label={<FormattedMessage id="VendorPerformance.question" defaultMessage="Question" />}
                name="question"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
                <Input />
              </Form.Item>

              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </Form>
          </div>
          <br />
          {Boolean(this.state.tableList) && this.state.tableList.length > 0 ? (
            <>
              <Collapse activeKey={this.state.activePanelKey} onChange={this.setOpenPanels} accordion>
                {this.renderElement(this.state.tableList)}
              </Collapse>
            </>
          ) : (
            ""
          )}

          <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.delete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VendorPerformance);
