import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Col, Row, Select, Input, Button as AntButton, Tree, Modal } from "antd";
import { fillFilterOperations } from "../../../Components/DataTableFilterComponent";
import { Table as TableBoot } from "react-bootstrap";
import { PlusOutlined, MinusCircleOutlined, CheckOutlined } from "@ant-design/icons";

const { Option } = Select;

export class Approval extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "Approval",
      controllerName: "pageSettings",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      houseRoomList: [],
      loadingButtonSubmit: false,
      nodes: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.fillComboboxes();
    fillFilterOperations();

    var responseApproval = await handleRequest("GET", "/api/" + this.state.controllerName + "/pageApprovals/" + this.props.pageName);
    if (responseApproval.type === "ERROR") {
      error(responseApproval);
    } else {
      if (Boolean(responseApproval.data)) {
        let data = responseApproval.data;
        console.log("data", data);
        if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
          this.formRef.current.setFieldsValue({
            reviews: data.reviews,
            approvals: data.approvals,
          });
        }
      } else {
        if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
          this.formRef.current.resetFields();
        }
      }
    }
  };

  fillComboboxes = async () => {
    var responseAction = await handleRequest("GET", "/api/" + this.state.controllerName + "/actions/" + this.props.pageName);
    if (responseAction.type === "ERROR") {
      error(responseAction);
    } else {
      this.setState({
        actionsSelectItems: Boolean(responseAction.data) ? responseAction.data : [],
      });
    }

    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(response.data))
        this.setState({
          nodes: Boolean(response.data) ? response.data : []
        });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: "",
        tableVisibleModal: true,
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder,
          tableVisibleModal: true,
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: "",
          tableVisibleModal: true,
        });
      }
    }
  };

  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  resetInputs = () => {

  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  save = async values => {
    console.log("values", values);
    let reviews = [];
    if (Boolean(values) && Boolean(values.reviews) && values.reviews.length > 0) {
      values.reviews.forEach(element => {
        element.pageName = this.props.pageName;

        let notificationTos = [];
        if (Boolean(element.notificationTos) && element.notificationTos.length > 0) {
          element.notificationTos.forEach(to => {
            if (Boolean(to.id) && to.id > 0) {
              notificationTos.push({ id: to.id, userId: to.userId, departmentId: to.departmentId });
            } else {
              let type = to.split("~")[0];
              let value = to.split("~")[1];
              if (Boolean(type) && Boolean(value)) {
                if (type === "CODE") {
                  notificationTos.push({ userId: value });
                } else if (type === "CATEGORY") {
                  notificationTos.push({ departmentId: value });
                }
              }
            }
          });
        }
        element.notificationTos = notificationTos;

        let notificationInfos = [];
        if (Boolean(element.notificationInfos) && element.notificationInfos.length > 0) {
          element.notificationInfos.forEach(info => {
            if (Boolean(info.id) && info.id > 0) {
              notificationInfos.push({ id: info.id, userId: info.userId, departmentId: info.departmentId });
            } else {
              let type = info.split("~")[0];
              let value = info.split("~")[1];
              if (Boolean(type) && Boolean(value)) {
                if (type === "CODE") {
                  notificationInfos.push({ userId: value });
                } else if (type === "CATEGORY") {
                  notificationInfos.push({ departmentId: value });
                }
              }
            }
          });
        }
        element.notificationInfos = notificationInfos;

        reviews.push(element);
      });
    }

    let approvals = [];
    if (Boolean(values) && Boolean(values.approvals) && values.approvals.length > 0) {
      values.approvals.forEach(element => {
        element.pageName = this.props.pageName;

        let notificationTos = [];
        if (Boolean(element.notificationTos) && element.notificationTos.length > 0) {
          element.notificationTos.forEach(to => {
            if (Boolean(to.id) && to.id > 0) {
              notificationTos.push({ id: to.id, userId: to.userId, departmentId: to.departmentId });
            } else {
              let type = to.split("~")[0];
              let value = to.split("~")[1];
              if (Boolean(type) && Boolean(value)) {
                if (type === "CODE") {
                  notificationTos.push({ userId: value });
                } else if (type === "CATEGORY") {
                  notificationTos.push({ departmentId: value });
                }
              }
            }
          });
        }
        element.notificationTos = notificationTos;

        let notificationInfos = [];
        if (Boolean(element.notificationInfos) && element.notificationInfos.length > 0) {
          element.notificationInfos.forEach(info => {
            if (Boolean(info.id) && info.id > 0) {
              notificationInfos.push({ id: info.id, userId: info.userId, departmentId: info.departmentId });
            } else {
              let type = info.split("~")[0];
              let value = info.split("~")[1];
              if (Boolean(type) && Boolean(value)) {
                if (type === "CODE") {
                  notificationInfos.push({ userId: value });
                } else if (type === "CATEGORY") {
                  notificationInfos.push({ departmentId: value });
                }
              }
            }
          });
        }
        element.notificationInfos = notificationInfos;

        approvals.push(element);
      });
    }

    let newObj = {
      reviews: reviews,
      approvals: approvals,
    }

    console.log("newObj", newObj);

    var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveApprovals", newObj);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.props.nextTab();
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  onCheckTo = async (checkedKeys, e) => {
    this.setState({
      checkedKeysTo: checkedKeys,
      halfCheckedKeysTo: e.halfCheckedKeys,
      checkedNodesTo: e.checkedNodes
    });
  };

  onCheckInfo = async (checkedKeys, e) => {
    this.setState({
      checkedKeysInfo: checkedKeys,
      halfCheckedKeysInfo: e.halfCheckedKeys,
      checkedNodesInfo: e.checkedNodes
    });
  };

  render() {
    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Form initialValues={{ remember: false }} onFinish={this.save} ref={this.formRef}>
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark"><FormattedMessage id="PageSettings.Review" defaultMessage="Review" /></h3>
            </div>
          </div>
          <Form.List name="reviews">
            {(fields, { add, remove }) => {

              const handleAddClick = () => {
                let reviews = this.formRef.current.getFieldValue("reviews");

                if (Boolean(reviews) && reviews.length > 0) {
                  reviews.push({
                    "notificationTos": [],
                    "notificationInfos": [],
                  });
                } else {
                  reviews = [{
                    "notificationTos": [],
                    "notificationInfos": [],
                  }];
                }
                this.formRef.current.setFieldsValue({
                  reviews: reviews,
                });
              };

              const onChanceToSelectted = async (fieldKey) => {
                let reviews = this.formRef.current.getFieldValue("reviews");
                let row = reviews[fieldKey];
                console.log("row", row);

                if (Boolean(row) && Boolean(row.id) && row.id > 0) {
                  var res = await handleRequest("GET", "/api/" + this.state.controllerName + "/notificationTos/" + row.id);
                  if (res.type === "ERROR") {
                    error(res);
                  } else {
                    let list = Boolean(res.data) ? res.data : [];
                    let keyList = [];
                    list.forEach(element => {
                      if (Boolean(element.userId) && element.userId > 0) {
                        keyList.push("CODE~" + element.userId);
                      } else if (Boolean(element.departmentId) && element.departmentId > 0) {
                        keyList.push("CATEGORY~" + element.departmentId);
                      }
                    });
                    console.log(keyList);
                    this.setState({
                      checkedKeysTo: keyList,
                      halfCheckedKeysTo: null,
                      checkedNodesTo: null,

                      fieldKey: fieldKey,
                      modalReviewToDepartmentShow: true,
                    });
                  }
                } else {
                  this.setState({
                    checkedKeysTo: null,
                    halfCheckedKeysTo: null,
                    checkedNodesTo: null,

                    fieldKey: fieldKey,
                    modalReviewToDepartmentShow: true,
                  });
                }
              };

              const onChanceInfoSelectted = async (fieldKey) => {
                let reviews = this.formRef.current.getFieldValue("reviews");
                let row = reviews[fieldKey];
                console.log("row", row);

                if (Boolean(row) && Boolean(row.id) && row.id > 0) {
                  var res = await handleRequest("GET", "/api/" + this.state.controllerName + "/notificationInfos/" + row.id);
                  if (res.type === "ERROR") {
                    error(res);
                  } else {
                    let list = Boolean(res.data) ? res.data : [];
                    let keyList = [];
                    list.forEach(element => {
                      if (Boolean(element.userId) && element.userId > 0) {
                        keyList.push("CODE~" + element.userId);
                      } else if (Boolean(element.departmentId) && element.departmentId > 0) {
                        keyList.push("CATEGORY~" + element.departmentId);
                      }
                    });
                    console.log(keyList);
                    this.setState({
                      checkedKeysInfo: keyList,
                      halfCheckedKeysInfo: null,
                      checkedNodesInfo: null,

                      fieldKey: fieldKey,
                      modalReviewInfoDepartmentShow: true,
                    });
                  }
                } else {
                  this.setState({
                    checkedKeysInfo: null,
                    halfCheckedKeysInfo: null,
                    checkedNodesInfo: null,

                    fieldKey: fieldKey,
                    modalReviewInfoDepartmentShow: true,
                  });
                }
              };
              return (
                <>
                  <TableBoot bordered size="sm">
                    <thead>
                      <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="RoomQuantity.Action" defaultMessage="Action" />
                        </th>
                        <th colSpan="2" style={{ textAlign: "center" }}>
                          <FormattedMessage id="RoomQuantity.To" defaultMessage="To" />
                        </th>
                        <th colSpan="2" style={{ textAlign: "center" }}>
                          <FormattedMessage id="RoomQuantity.Info" defaultMessage="Info" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map(field => {
                        return (
                          <tr key={field.key}>
                            <Form.Item hidden name={[field.name, "notificationTos"]}></Form.Item>
                            <Form.Item hidden name={[field.name, "notificationInfos"]}></Form.Item>
                            <td>
                              <Form.Item
                                noStyle name={[field.name, "pageActionId"]}>
                                <Select
                                  showSearch
                                  allowClear
                                  optionFilterProp="children"
                                  style={{ width: "300px" }}
                                >
                                  {Boolean(this.state["actionsSelectItems"]) && this.state["actionsSelectItems"].length > 0 &&
                                    this.state["actionsSelectItems"].map(i => (
                                      <Option key={i.id} value={i.id}>
                                        {i.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>
                            <td>
                              <AntButton
                                type="default" style={{
                                  color: "white", background: "#1BC5BD", borderColor: "#1BC5BD"
                                }}
                                onClick={() => onChanceToSelectted(field.name)}
                                icon={<CheckOutlined />}
                              ></AntButton>
                            </td>
                            <td>
                              <Form.Item
                                noStyle name={[field.name, "toMessage"]}>
                                <Input placeholder="Message"></Input>
                              </Form.Item>
                            </td>
                            <td>
                              <AntButton
                                type="default" style={{
                                  color: "white", background: "#1BC5BD", borderColor: "#1BC5BD"
                                }}
                                onClick={() => onChanceInfoSelectted(field.name)}
                                icon={<CheckOutlined />}
                              ></AntButton>
                            </td>
                            <td>
                              <Form.Item
                                noStyle name={[field.name, "infoMessage"]}>
                                <Input placeholder="Message"></Input>
                              </Form.Item>
                            </td>
                            <td>
                              <MinusCircleOutlined onClick={() => remove(field.name)} />
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={11}>
                          <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                            <FormattedMessage id={"Approval.AddNew"} defaultMessage={"Add New"} />
                          </AntButton>
                        </td>
                      </tr>
                    </tbody>
                  </TableBoot>
                </>
              );
            }}
          </Form.List>
          <br />
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark"><FormattedMessage id="PageSettings.Approval" defaultMessage="Approval" /></h3>
            </div>
          </div>
          <Form.List name="approvals">
            {(fields, { add, remove }) => {

              const handleAddClick = () => {
                let approvals = this.formRef.current.getFieldValue("approvals");

                if (Boolean(approvals) && approvals.length > 0) {
                  approvals.push({
                    "notificationTos": [],
                    "notificationInfos": [],
                  });
                } else {
                  approvals = [{
                    "notificationTos": [],
                    "notificationInfos": [],
                  }];
                }
                this.formRef.current.setFieldsValue({
                  approvals: approvals,
                });
              };

              const onChanceToSelectted = async (fieldKey) => {
                let approvals = this.formRef.current.getFieldValue("approvals");
                let row = approvals[fieldKey];
                console.log("row", row);

                if (Boolean(row) && Boolean(row.id) && row.id > 0) {
                  var res = await handleRequest("GET", "/api/" + this.state.controllerName + "/notificationTos/" + row.id);
                  if (res.type === "ERROR") {
                    error(res);
                  } else {
                    let list = Boolean(res.data) ? res.data : [];
                    let keyList = [];
                    list.forEach(element => {
                      if (Boolean(element.userId) && element.userId > 0) {
                        keyList.push("CODE~" + element.userId);
                      } else if (Boolean(element.departmentId) && element.departmentId > 0) {
                        keyList.push("CATEGORY~" + element.departmentId);
                      }
                    });
                    console.log(keyList);
                    this.setState({
                      checkedKeysTo: keyList,
                      halfCheckedKeysTo: null,
                      checkedNodesTo: null,

                      fieldKey: fieldKey,
                      modalToDepartmentShow: true,
                    });
                  }
                } else {
                  this.setState({
                    checkedKeysTo: null,
                    halfCheckedKeysTo: null,
                    checkedNodesTo: null,

                    fieldKey: fieldKey,
                    modalToDepartmentShow: true,
                  });
                }
              };

              const onChanceInfoSelectted = async (fieldKey) => {
                let approvals = this.formRef.current.getFieldValue("approvals");
                let row = approvals[fieldKey];
                console.log("row", row);

                if (Boolean(row) && Boolean(row.id) && row.id > 0) {
                  var res = await handleRequest("GET", "/api/" + this.state.controllerName + "/notificationInfos/" + row.id);
                  if (res.type === "ERROR") {
                    error(res);
                  } else {
                    let list = Boolean(res.data) ? res.data : [];
                    let keyList = [];
                    list.forEach(element => {
                      if (Boolean(element.userId) && element.userId > 0) {
                        keyList.push("CODE~" + element.userId);
                      } else if (Boolean(element.departmentId) && element.departmentId > 0) {
                        keyList.push("CATEGORY~" + element.departmentId);
                      }
                    });
                    console.log(keyList);
                    this.setState({
                      checkedKeysInfo: keyList,
                      halfCheckedKeysInfo: null,
                      checkedNodesInfo: null,

                      fieldKey: fieldKey,
                      modalInfoDepartmentShow: true,
                    });
                  }
                } else {
                  this.setState({
                    checkedKeysInfo: null,
                    halfCheckedKeysInfo: null,
                    checkedNodesInfo: null,

                    fieldKey: fieldKey,
                    modalInfoDepartmentShow: true,
                  });
                }
              };
              return (
                <>
                  <TableBoot bordered size="sm">
                    <thead>
                      <tr>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                          <FormattedMessage id="RoomQuantity.Action" defaultMessage="Action" />
                        </th>
                        <th colSpan="2" style={{ textAlign: "center" }}>
                          <FormattedMessage id="RoomQuantity.To" defaultMessage="To" />
                        </th>
                        <th colSpan="2" style={{ textAlign: "center" }}>
                          <FormattedMessage id="RoomQuantity.Info" defaultMessage="Info" />
                        </th>
                        <th colSpan="1" style={{ textAlign: "center" }}>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map(field => {
                        return (
                          <tr key={field.key}>
                            <Form.Item hidden name={[field.name, "notificationTos"]}></Form.Item>
                            <Form.Item hidden name={[field.name, "notificationInfos"]}></Form.Item>
                            <td>
                              <Form.Item
                                noStyle name={[field.name, "pageActionId"]}>
                                <Select
                                  showSearch
                                  allowClear
                                  optionFilterProp="children"
                                  style={{ width: "300px" }}
                                >
                                  {Boolean(this.state["actionsSelectItems"]) && this.state["actionsSelectItems"].length > 0 &&
                                    this.state["actionsSelectItems"].map(i => (
                                      <Option key={i.id} value={i.id}>
                                        {i.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </td>
                            <td>
                              <AntButton
                                type="default" style={{
                                  color: "white", background: "#1BC5BD", borderColor: "#1BC5BD"
                                }}
                                onClick={() => onChanceToSelectted(field.name)}
                                icon={<CheckOutlined />}
                              ></AntButton>
                            </td>
                            <td>
                              <Form.Item
                                noStyle name={[field.name, "toMessage"]}>
                                <Input placeholder="Message"></Input>
                              </Form.Item>
                            </td>
                            <td>
                              <AntButton
                                type="default" style={{
                                  color: "white", background: "#1BC5BD", borderColor: "#1BC5BD"
                                }}
                                onClick={() => onChanceInfoSelectted(field.name)}
                                icon={<CheckOutlined />}
                              ></AntButton>
                            </td>
                            <td>
                              <Form.Item
                                noStyle name={[field.name, "infoMessage"]}>
                                <Input placeholder="Message"></Input>
                              </Form.Item>
                            </td>
                            <td>
                              <MinusCircleOutlined onClick={() => remove(field.name)} />
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={11}>
                          <AntButton type="dashed" onClick={handleAddClick} block icon={<PlusOutlined />}>
                            <FormattedMessage id={"Approval.AddNew"} defaultMessage={"Add New"} />
                          </AntButton>
                        </td>
                      </tr>
                    </tbody>
                  </TableBoot>
                </>
              );
            }}
          </Form.List>
          <br />
          <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={19} lg={19} xl={19} xxl={19}></Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Button type="submit" style={{ width: "100%" }} variant="success">
                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
              </Button>
            </Col>
          </Row>
        </Form>

        <Modal
          title={<FormattedMessage id="Approval.To" defaultMessage="To" />}
          closable="false"
          open={this.state.modalToDepartmentShow}
          onCancel={() => {
            this.setState({ modalToDepartmentShow: false });
          }}
          width={800}
          centered
          okButtonProps={{ hidden: false }}
          onOk={() => {
            const { checkedKeysTo, fieldKey } = this.state;
            console.log("checkedKeysTo", checkedKeysTo);
            console.log("fieldKey", fieldKey);

            if (Boolean(checkedKeysTo) && checkedKeysTo.length > 0) {
              const approvals = this.formRef.current.getFieldValue("approvals");
              console.log("approvals", approvals);

              const row = approvals[fieldKey];
              console.log("row", row);

              row.notificationTos = checkedKeysTo;

              console.log("approvals", approvals);

              this.formRef.current.setFieldsValue({
                approvals: approvals,
              });
              this.setState({ modalToDepartmentShow: false });
            } else {
              showWarning("Please select item.");
            }
          }}
        >
          <Tree
            blockNode
            checkable
            showLine={true}
            onCheck={this.onCheckTo}
            checkedKeys={this.state.checkedKeysTo}
            halfCheckedKeys={this.state.halfCheckedKeysTo}
            treeData={this.state.nodes}
          />
        </Modal>

        <Modal
          title={<FormattedMessage id="Approval.Info" defaultMessage="Info" />}
          closable="false"
          open={this.state.modalInfoDepartmentShow}
          onCancel={() => {
            this.setState({ modalInfoDepartmentShow: false });
          }}
          width={800}
          centered
          okButtonProps={{ hidden: false }}
          onOk={() => {
            const { checkedKeysInfo, fieldKey } = this.state;

            if (Boolean(checkedKeysInfo) && checkedKeysInfo.length > 0) {
              const approvals = this.formRef.current.getFieldValue("approvals");

              console.log("approvals", approvals);

              const row = approvals[fieldKey];
              console.log("row", row);

              row.notificationInfos = checkedKeysInfo;

              console.log("approvals", approvals);

              this.formRef.current.setFieldsValue({
                approvals: approvals,
              });
              this.setState({ modalInfoDepartmentShow: false });
            } else {
              showWarning("Please select item.");
            }
          }}
        >
          <Tree
            blockNode
            checkable
            showLine={true}
            onCheck={this.onCheckInfo}
            checkedKeys={this.state.checkedKeysInfo}
            halfCheckedKeys={this.state.halfCheckedKeysInfo}
            treeData={this.state.nodes}
          />
        </Modal>

        <Modal
          title={<FormattedMessage id="Approval.To" defaultMessage="To" />}
          closable="false"
          open={this.state.modalReviewToDepartmentShow}
          onCancel={() => {
            this.setState({ modalReviewToDepartmentShow: false });
          }}
          width={800}
          centered
          okButtonProps={{ hidden: false }}
          onOk={() => {
            const { checkedKeysTo, fieldKey } = this.state;
            console.log("checkedKeysTo", checkedKeysTo);
            console.log("fieldKey", fieldKey);

            if (Boolean(checkedKeysTo) && checkedKeysTo.length > 0) {
              const reviews = this.formRef.current.getFieldValue("reviews");
              console.log("reviews", reviews);

              const row = reviews[fieldKey];
              console.log("row", row);

              row.notificationTos = checkedKeysTo;

              console.log("reviews", reviews);

              this.formRef.current.setFieldsValue({
                reviews: reviews,
              });
              this.setState({ modalReviewToDepartmentShow: false });
            } else {
              showWarning("Please select item.");
            }
          }}
        >
          <Tree
            blockNode
            checkable
            showLine={true}
            onCheck={this.onCheckTo}
            checkedKeys={this.state.checkedKeysTo}
            halfCheckedKeys={this.state.halfCheckedKeysTo}
            treeData={this.state.nodes}
          />
        </Modal>

        <Modal
          title={<FormattedMessage id="Approval.Info" defaultMessage="Info" />}
          closable="false"
          open={this.state.modalReviewInfoDepartmentShow}
          onCancel={() => {
            this.setState({ modalReviewInfoDepartmentShow: false });
          }}
          width={800}
          centered
          okButtonProps={{ hidden: false }}
          onOk={() => {
            const { checkedKeysInfo, fieldKey } = this.state;
            console.log("checkedKeysInfo", checkedKeysInfo);
            console.log("fieldKey", fieldKey);

            if (Boolean(checkedKeysInfo) && checkedKeysInfo.length > 0) {
              const reviews = this.formRef.current.getFieldValue("reviews");

              const row = reviews[fieldKey];
              console.log("row", row);

              row.notificationInfos = checkedKeysInfo;

              console.log("reviews", reviews);

              this.formRef.current.setFieldsValue({
                reviews: reviews,
              });
              this.setState({ modalReviewInfoDepartmentShow: false });
            } else {
              showWarning("Please select item.");
            }
          }}
        >
          <Tree
            blockNode
            checkable
            showLine={true}
            onCheck={this.onCheckInfo}
            checkedKeys={this.state.checkedKeysInfo}
            halfCheckedKeys={this.state.halfCheckedKeysInfo}
            treeData={this.state.nodes}
          />
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Approval);