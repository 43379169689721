import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { Button, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error } from "../../../MessageHelper";
import { handleRequest } from "../../../ApiConnector";
import { Form, Table, Col, Row, Button as AntButton, Badge, Divider, Checkbox } from "antd";
import DynamicActionMenu from "../../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../../excel/ExcelReader";
import { Select } from "antd";
import { Input } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../../Components/DataTableFilterComponent";
import { UploadOutlined, FilterOutlined, MinusCircleOutlined, CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Upload } from "antd";
import { API_BASE_ROOT } from "../../../ApiConnector";
import { FcSearch } from "react-icons/fc";

const { Option } = Select;

export class EquipmentIdentification extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "EquipmentIdentification",
      controllerName: "equipmentIdentification",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      fileList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: []
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };
  fillComboboxes = async () => {
    this.fillCode("Equipment");

    var responseatomicNumberId = await handleRequest("GET", "/api/" + this.state.controllerName + "/atomicNumberId");
    if (responseatomicNumberId.type === "ERROR") {
      error(responseatomicNumberId);
    }
    this.setState({
      atomicNumberIdSelectItems: Boolean(responseatomicNumberId.data) ? responseatomicNumberId.data : []
    });
    var responsephysicalPhaseId = await handleRequest("GET", "/api/" + this.state.controllerName + "/physicalPhaseId");
    if (responsephysicalPhaseId.type === "ERROR") {
      error(responsephysicalPhaseId);
    }
    this.setState({
      physicalPhaseIdSelectItems: Boolean(responsephysicalPhaseId.data) ? responsephysicalPhaseId.data : []
    });
  };
  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };
  edit = row => {
    document.getElementById("kt_scrolltop").click();

    this.formRef.current.setFieldsValue({
      ...row
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };
  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };
  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };
  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };
  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };
  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: ""
    });
  };
  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };
  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };
  save = async values => {
    const newItem = {
      ...values,
      id: this.state.id
    };

    if (newItem.id === null) {
      var response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.cancel();
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
    }
  };
  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };
  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {};
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async () => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: this.state.codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          showCodeModal: false
        });
        this.fillCode();
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async () => {
    const { codeType } = this.state;

    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  fillCodeWithType = async type => {
    const response = await handleRequest("GET", "/api/codes/type/" + type);
    if (Boolean(response.data)) {
      this.setState({
        [type + "SelectItems"]: response.data
      });
    }
  };

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const props = {
      action: `${API_BASE_ROOT}/File/upload`,
      listType: "picture",
      defaultFileList: [...this.state.fileList]
    };

    const columns = [
      { key: "key", render: this.action },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />,
        key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="EquipmentIdentification.EquipmentType" defaultMessage="Equipment Type" />,
        key: "equipmentTypeId",
        render: record => {
          return record.equipmentTypeName;
        },
        ...getColumnFilter({
          inputName: "atomicNumberName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EquipmentIdentification.Equipment" defaultMessage="Equipment" />,
        key: "equipmentId",
        render: record => {
          return record.equipmentName;
        },
        ...getColumnFilter({
          inputName: "atomicNumberName",
          inputType: "SELECT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem,
          searchCriteriaModels: this.state.searchCriteriaModels
        }),
        sorter: true
      },
      {
        title: <FormattedMessage id="EquipmentIdentification.Scope" defaultMessage="Scope" />,
        key: "scope",
        render: record => {
          return (
            <AntButton
              style={{ border: "0px" }}
              onClick={() => this.setState({ showScopeModal: true })}
              // type="primary"
              // shape="round"
              icon={<FcSearch />}
            ></AntButton>
          );
        }
      }
    ];
    const excelExport = (
      <>
        {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}
        {this.state.exportClick ? (
          <ExcelFile filename="PeriodicTable" hideElement={true}>
            <ExcelSheet name="PeriodicTable" data={this.state.tableList}>
              <ExcelColumn label="Atomic Number" value="atomicNumberName" />
              <ExcelColumn label="Element" value="element" />
              <ExcelColumn label="Symbol" value="symbol" />
              <ExcelColumn label="Atomic Weight" value="atomicWeight" />
              <ExcelColumn label="Physical Phase" value="physicalPhaseName" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} icon={<ExportIcon color="action" fontSize="large" />}></AntButton>
        )} */}
      </>
    );
    return (
      <Badge.Ribbon
        text={<FormattedMessage id="periodicTable.pageTitle" defaultMessage="Equipment Identification" />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
            <Row gutter={[16, 16]}>
              {" "}
              <Col md={21}></Col> <Col md={1}>{excelExport}</Col>{" "}
              <Col md={1}>
                {" "}
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}></ExcelReader>{" "}
              </Col>{" "}
              <Col md={1}>
                {" "}
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>{" "}
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>{" "}
              </Col>{" "}
            </Row>
            <div hidden={this.state.hideInputs}>
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EquipmentIdentification.EquipmentType" defaultMessage="Equipment Type" />}
                  name="equipmentTypeId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["equipmentTypeIdSelectItems"]) &&
                      this.state["equipmentTypeIdSelectItems"].length > 0 &&
                      this.state["equipmentTypeIdSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>
                      ))}{" "}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="EquipmentIdentification.Equipment" defaultMessage="Equipment" />}
                  name="equipmentId"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select
                    style={{ width: "100%" }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                          <Input
                            style={{ flex: "auto" }}
                            value={this.state.codeName}
                            onChange={event => {
                              this.setState({
                                codeName: event.target.value
                              });
                            }}
                          />
                          <a
                            href
                            style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                            onClick={() => this.addCode("Equipment")}
                          >
                            <PlusOutlined /> Add item
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {Boolean(this.state.EquipmentSelectItems) &&
                      this.state.EquipmentSelectItems.length > 0 &&
                      this.state.EquipmentSelectItems.map(i => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="Photo" defaultMessage="Photo" />}
                  name="path"
                  rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please upload file" /> }]}
                >
                  <Upload {...props}>
                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> Upload
                    </AntButton>
                  </Upload>
                </Form.Item>
              }

              {
                <Form.List name="Criteria">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <TableBoot bordered size="sm">
                          <thead>
                            <tr>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Checkbox"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ExecutionPlan.Sections"} defaultMessage={"Criteria"} />
                              </th>
                              <th colSpan="1" style={{ textAlign: "center" }}>
                                <FormattedMessage id={"ExecutionPlan.ApartmentType"} defaultMessage={"Input Type"} />
                              </th>
                              <th colSpan="1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map(field => {
                              return (
                                <tr key={field.key}>
                                  <td>
                                    <Checkbox></Checkbox>
                                  </td>
                                  <td>
                                    <Form.Item
                                      style={{ marginBottom: "5px" }}
                                      name="criteria"
                                      rules={[
                                        {
                                          required: true,
                                          message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                                        }
                                      ]}
                                    >
                                      <Input
                                        style={{
                                          width: "100%"
                                        }}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item name="labelTrue">
                                      <Select
                                        allowClear
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder=""
                                        onChange={value => {
                                          this.setState({ answerType: value });
                                        }}
                                      >
                                        {Boolean(this.state["endPointDescriptionSelectItems"]) &&
                                          this.state["endPointDescriptionSelectItems"].length > 0 &&
                                          this.state["endPointDescriptionSelectItems"].map(i => (
                                            <Option key={i.key} value={i.key}>
                                              {i.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={11}>
                                <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                  <FormattedMessage id={"ExecutionPlan.AddNewPersonnel"} defaultMessage={"Add New Coordinate "} />
                                </AntButton>
                              </td>
                            </tr>
                          </tbody>
                        </TableBoot>
                      </>
                    );
                  }}
                </Form.List>
              }

              <div hidden={this.state.hideSave}>
                {" "}
                {
                  <Row gutter={[16, 16]}>
                    {" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      {" "}
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        {" "}
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                  </Row>
                }{" "}
              </div>
              <div hidden={this.state.hideUpdate}>
                {" "}
                {
                  <Row gutter={[16, 16]}>
                    {" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      {" "}
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        {" "}
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      {" "}
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        {" "}
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />{" "}
                      </Button>{" "}
                    </Col>{" "}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>{" "}
                  </Row>
                }{" "}
              </div>
            </div>
          </Form>
          {Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 && (
            <div className="alert alert-custom alert-light">
              {" "}
              <div className="alert-icon">
                {" "}
                <FilterOutlined />{" "}
              </div>{" "}
              <div className="alert-text">
                {" "}
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (
                    <div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                      {" "}
                      <span className="mr-2">{val.label}</span> <strong className="mr-2">{val.type}</strong>{" "}
                      <span className="mr-2">{val.operationValue}</span> <span className="mr-2">{val.showValue}</span>{" "}
                      <span>
                        {" "}
                        <Tooltip title="Remove">
                          {" "}
                          <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />{" "}
                        </Tooltip>{" "}
                      </span>{" "}
                    </div>
                  );
                })}{" "}
              </div>{" "}
              <div className="alert-close">
                {" "}
                <Tooltip title="Remove All">
                  {" "}
                  <CloseCircleOutlined
                    style={{ color: "crimson" }}
                    onClick={() =>
                      this.setState({ searchCriteriaModels: [] }, () => {
                        this.filterChangeItem([]);
                      })
                    }
                  />{" "}
                </Tooltip>{" "}
              </div>{" "}
            </div>
          )}
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            {" "}
            <Table
              bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns}
              dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
            />{" "}
          </div>
        </div>
        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          {" "}
          <Modal.Header closeButton>
            {" "}
            <Modal.Title id="contained-modal-title-vcenter">
              {" "}
              <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />{" "}
            </Modal.Title>{" "}
          </Modal.Header>{" "}
          <Modal.Body>
            {" "}
            <p>
              {" "}
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />{" "}
            </p>{" "}
          </Modal.Body>{" "}
          <Modal.Footer>
            {" "}
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              {" "}
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />{" "}
            </Button>{" "}
            <Button variant="danger" onClick={this.delete}>
              {" "}
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />{" "}
            </Button>{" "}
          </Modal.Footer>{" "}
        </Modal>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EquipmentIdentification);
