import "antd/dist/antd.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Table as TableBoot } from "react-bootstrap";
import { showError, showSuccess, error, showWarning } from "../../MessageHelper";
import { handleRequest } from "../../ApiConnector";
import { Form, Table, Col, Row, Modal, Button as AntButton, Badge, Checkbox, Space, Cascader, Switch, InputNumber } from "antd";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/AddCircle";
import ExportIcon from "@material-ui/icons/CloudDownloadRounded";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { ExcelReader } from "../excel/ExcelReader";
import { Input } from "antd";
import { Select } from "antd";
import { fillFilterOperations, getColumnFilter } from "../../Components/DataTableFilterComponent";
import { FilterOutlined, CloseCircleOutlined, MinusCircleOutlined, ZoomInOutlined, PlusCircleOutlined, MessageOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import SendIcon from "@material-ui/icons/Send";
import { ReplyOutlined } from "@material-ui/icons";

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({
      key: node.value,
      lineage: node.lineage,
      title: node.value
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class RoomApplication extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      formName: "RoomApplication",
      controllerName: "roomApplication",
      id: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      exportClick: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      tableList: [],
      materialList: [],
      usageList: [],

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: 0
      },
      searchCriteriaModels: [],
    };
  }

  formRef = React.createRef();
  formRefMoveToNewLine = React.createRef();
  formRefMaterial = React.createRef();
  formRefWorkflow = React.createRef();
  formRefSpecification = React.createRef();
  formRefDimension = React.createRef();

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.restartTable({ pagination });
    fillFilterOperations();
    this.fillComboboxes();
  };

  fillComboboxes = async () => {
    this.fillCode("RoomApplication.RoomApplicationDimension");
    var responDiscipline = await handleRequest("GET", "/api/" + this.state.controllerName + "/discipline");
    if (responDiscipline.type === "ERROR") {
      error(responDiscipline);
    }
    this.setState({
      disciplineSelectItems: Boolean(responDiscipline.data) ? responDiscipline.data : []
    });

    var responseRoomUnit = await handleRequest("GET", "/api/" + this.state.controllerName + "/roomUnit");
    if (responseRoomUnit.type === "ERROR") {
      error(responseRoomUnit);
    } else {
      this.setState({
        roomUnitSelectItems: Boolean(responseRoomUnit.data) ? responseRoomUnit.data : []
      });
    }

    var responsecategoryId = await handleRequest("GET", "/api/" + this.state.controllerName + "/category");
    if (responsecategoryId.type === "ERROR") {
      error(responsecategoryId);
    } else {
      this.setState({
        categoryIdSelectItems: Boolean(responsecategoryId.data) ? responsecategoryId.data : []
      });
    }

    var responseconstructionType = await handleRequest("GET", "/api/" + this.state.controllerName + "/constructionType");
    if (responseconstructionType.type === "ERROR") {
      error(responseconstructionType);
    } else {
      this.setState({
        constructionTypeSelectItems: Boolean(responseconstructionType.data) ? responseconstructionType.data : []
      });
    }

    var responseshape = await handleRequest("GET", "/api/" + this.state.controllerName + "/shape");
    if (responseshape.type === "ERROR") {
      error(responseshape);
    } else {
      this.setState({
        shapeSelectItems: Boolean(responseshape.data) ? responseshape.data : []
      });
    }

    const responseMaterialList = await handleRequest("GET", "/api/" + this.state.controllerName + "/materialList");
    if (responseMaterialList.data.length !== 0) {
      generateList(responseMaterialList.data);
      this.setState({
        materialList: responseMaterialList.data
      });
    }

    const responseUsageList = await handleRequest("GET", "/api/" + this.state.controllerName + "/usageList");
    if (responseUsageList.data.length !== 0) {
      generateList(responseUsageList.data);
      this.setState({
        usageList: responseUsageList.data
      });
    }

    var responsestructure = await handleRequest("GET", "/api/" + this.state.controllerName + "/structure");
    if (responsestructure.type === "ERROR") {
      error(responsestructure);
    } else {
      this.setState({
        structureSelectItems: Boolean(responsestructure.data) ? responsestructure.data : []
      });
    }
  };

  fillCodeDescription = async () => {
    var newObj = {
      itemGroupId: null
    };
    var responsedescriptionId = await handleRequest("POST", "/api/" + this.state.controllerName + "/description", newObj);
    if (responsedescriptionId.type === "ERROR") {
      error(responsedescriptionId);
    } else {
      this.setState({
        descriptionIdSelectItems: Boolean(responsedescriptionId.data) ? responsedescriptionId.data : []
      });
    }
  };

  handleEldenItemIdChange = async () => {
    const { materialCategoryId, materialDescriptionId } = this.state
    if (Boolean(materialCategoryId) && materialCategoryId > 0 && Boolean(materialDescriptionId) && materialDescriptionId > 0) {
      var newObj = {
        materialCategoryId: materialCategoryId,
        id: materialDescriptionId
      };
      let response = await handleRequest("POST", "/api/" + this.state.controllerName + "/eldenItem", newObj);
      if (response.type === "ERROR") {
        error(response);
      } else {
        let data = response.data;
        console.log("data", data);

        let dimensionIdSelectItems = [];
        if (Boolean(data) && Boolean(data.itemDimension) && data.itemDimension.length > 0) {
          data.itemDimension.forEach(element => {
            if (Boolean(element) && Boolean(element.itemDefinition)) {
              dimensionIdSelectItems.push({ "key": element.itemDefinition, "value": element.itemDefinition });
            }
          });
        }
        this.setState({
          dimensionIdSelectItems: dimensionIdSelectItems,
        });
      }
    } else {
      this.setState({
        dimensionIdSelectItems: [],
      });
    }
  };

  onChangeItemGroupId = async value => {
    if (Boolean(value)) {
      var responseitemDescriptionId = await handleRequest("GET", "/api/stockCard/itemDescriptionIdSelectItems/" + value);
      if (responseitemDescriptionId.type === "ERROR") {
        error(responseitemDescriptionId);
        this.setState({
          descriptionIdSelectItems: [],
          descriptionId: null
        });
        this.formRef.current.setFieldsValue({
          descriptionId: null
        });
      } else {
        this.setState({
          descriptionIdSelectItems: Boolean(responseitemDescriptionId.data) ? responseitemDescriptionId.data : [],
          descriptionId: null
        });
        this.formRef.current.setFieldsValue({
          descriptionId: null
        });
      }
    } else {
      this.setState({
        descriptionIdSelectItems: [],
        descriptionId: null
      });
      this.formRef.current.setFieldsValue({
        descriptionId: null
      });
    }
  };

  restartTable = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = this.createWhere(params);
    let url = "/api/" + this.state.controllerName + "/paging";
    const response = await handleRequest("POST", url, newObj);
    if (response.type === "ERROR") {
      error(response);
      this.setState({
        loading: false,
        tableList: [],
        pagination: {
          ...params.pagination,
          total: 0
        },
        totalRecords: 0,
        sortField: "",
        sortOrder: ""
      });
    } else {
      if (response.data.length !== 0) {
        let list = response.data.data;
        list.forEach((element, index) => {
          element.index = index + 1;
        });
        this.setState({
          loading: false,
          tableList: list,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords
          },
          totalRecords: response.data.totalRecords,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        });
      } else {
        this.setState({
          loading: false,
          tableList: [],
          pagination: {
            ...params.pagination,
            total: 0
          },
          totalRecords: 0,
          sortField: "",
          sortOrder: ""
        });
      }
    }
  };
  createWhere(params) {
    console.log(params);
    if (Boolean(params._pagination)) params.pagination = params._pagination;

    var lastUpdateDateStart = null,
      lastUpdateDateEnd = null,
      sortField = "",
      sortOrder = "";
    if (Boolean(params) && Boolean(params.pagination)) {
      if (Boolean(params.pagination.lastUpdateDate)) {
        lastUpdateDateStart = params.pagination.lastUpdateDate[0];
        lastUpdateDateEnd = params.pagination.lastUpdateDate[1];
      }
      if (Boolean(params.sortField)) {
        sortField = params.sortField;
      }
      if (Boolean(params.sortOrder)) {
        sortOrder = params.sortOrder;
      }
    }
    const newObj = {
      ...params.pagination,
      id: null,
      searchCriteriaModels: this.state.searchCriteriaModels,
      lastUpdateDateStart: lastUpdateDateStart,
      lastUpdateDateEnd: lastUpdateDateEnd,
      firstResult: params.pagination.current - 1,
      maxResults: params.pagination.pageSize,
      sortField: sortField,
      sortOrder: sortOrder
    };
    return newObj;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.restartTable({ sortField: sorter.columnKey, sortOrder: sorter.order, pagination, filters });
  };

  edit = row => {
    console.log("row", row);
    document.getElementById("kt_scrolltop").click();

    let roomApplications = [];

    this.prepareMaterial(row);

    row.roomApplicationMaterial = row.roomApplicationMaterials;
    roomApplications.push(row);

    console.log("roomApplications", roomApplications);

    this.formRef.current.setFieldsValue({
      roomApplications: roomApplications,
    });

    this.setState({
      hideUpdate: false,
      hideSave: true,
      hideInputs: false,
      id: row.id,
    });
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      const { pagination } = this.state;
      this.restartTable({ pagination });
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  removeSearchCriteria = index => {
    let searchCriteriaModels = this.state.searchCriteriaModels;
    searchCriteriaModels.splice(index, 1);
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels
      },
      () => {
        this.filterChangeItem(searchCriteriaModels);
      }
    );
  };

  filterChangeItem = searchCriteriaModels => {
    this.setState(
      {
        searchCriteriaModels: searchCriteriaModels,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        const { pagination } = this.state;
        this.restartTable({ pagination });
      }
    );
  };

  action = record => {
    let editAction = {
      name: "Edit",
      icon: <EditOutlined fontSize="small" color="primary" />,
      actionClick: this.edit.bind(this)
    };
    let itemAction = {
      name: "Items",
      icon: <SendIcon fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.materialModal.bind(this)
    };
    let specificationAction = {
      name: "Specification",
      icon: <ReplyOutlined fontSize="small" style={{ border: "0px", color: "#1BC5BD" }} />,
      actionClick: this.specificationModal.bind(this)
    };
    let deleteAction = {
      name: "Delete",
      icon: <DeleteIcon fontSize="small" color="error" />,
      actionClick: this.deleteModal.bind(this)
    };

    let actionList = [];
    actionList.push(editAction);
    actionList.push(itemAction);
    actionList.push(specificationAction);
    actionList.push(deleteAction);

    return (
      <React.Fragment>
        <DynamicActionMenu row={record} actionList={actionList} />
      </React.Fragment>
    );
  };

  itemsModal = row => {
    this.setState({
      modalItemShow: true,
      id: row.id
    });
  };

  workflowModal = row => {
    this.setState({
      modalWorkflowShow: true,
      id: row.id
    });
  };

  specificationModal = row => {
    console.log("row", row);

    this.setState({
      modalSpecificationShow: true,
      id: row.id,
    }, () => {
      this.formRefSpecification.current.setFieldsValue({
        roomApplicationSpecification: row.roomApplicationSpecifications
      });
    });
  };

  resetInputs = () => {
    this.formRef.current.resetFields();
    this.setState({
      id: null,

      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["2", "10", "25", "50", "100", "1000", "10000", "100000", "1000000"],
        total: this.state.totalRecords
      },
      loading: false,
      sortField: "",
      sortOrder: "",
    });
  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  onClickCreateNew = () => {
    this.resetInputs();
    if (this.state.hideInputs) {
      this.setState({
        hideInputs: false,
        hideSave: false,
        hideUpdate: true
      });
    } else {
      this.setState({
        hideInputs: true,
        hideSave: true,
        hideUpdate: false
      });
    }
  };

  save = async values => {
    if (Boolean(values) && Boolean(values.roomApplications) && values.roomApplications.length > 0) {

      if (Boolean(values.roomApplications) && values.roomApplications.length > 0) {
        values.roomApplications.forEach(element => {
          if (Boolean(element.roomApplicationMaterial) && element.roomApplicationMaterial.length > 0) {
            element.roomApplicationMaterial.forEach(material => {
              material.constructionType = material.constructionType ? "Precast" : "In_Situ";
            });
          }
          if (Boolean(element.roomApplicationMaterials) && element.roomApplicationMaterials.length > 0) {
            element.roomApplicationMaterials.forEach(material => {
              material.constructionType = material.constructionType ? "Precast" : "In_Situ";
            });
          }
        });
      }

      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveAll", values.roomApplications);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.cancel();
        const { pagination } = this.state;
        this.restartTable({ pagination });
        showSuccess();
      }
    } else {
      showError("Not found.");
    }
  };

  handleExportExcel = async () => {
    this.setState({
      exportClick: true
    });
  };

  handleUploadExcel = async excelJson => {
    try {
      var list = [];
      if (excelJson === null) showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
          };
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/import", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            const { pagination } = this.state;
            this.restartTable({ pagination });
            showSuccess();
          }
        } else {
          showError("No records to be added");
        }
      }
    } catch (error) {
      showError("");
    }
  };

  addCode = async codeType => {
    const { codeName } = this.state;
    if (Boolean(codeName)) {
      const newItem = {
        name: codeName,
        type: codeType
      };

      var response = await handleRequest("POST", "/api/codes", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        this.setState({
          codeName: null,
          modalAddNewDimensionShow: false,
        });
        this.fillCode(codeType);
      }
    } else {
      showError("Please type value");
    }
  };

  fillCode = async codeType => {
    const response = await handleRequest("GET", "/api/codes/type/" + codeType);
    if (Boolean(response.data)) {
      this.setState({
        [codeType + "SelectItems"]: response.data
      });
    }
  };

  itemModal = async row => {
    this.setState({
      modalItemShow: true,
      modalItemList: row.roomApplicationMaterials,
    });
  };

  workflowEditModal = async (row) => {
    this.setState({
      modalWorkflowShow: true,
    }, () => {
      this.formRefWorkflow.current.setFieldsValue({
        roomApplicationWorkflow: row.roomApplicationWorkflow
      });
    });
  };

  materialModal = async (row) => {
    console.log("row", row);

    this.prepareMaterial(row);

    this.setState({
      modalMaterialShow: true,
      id: row.id,
    }, () => {
      this.formRefMaterial.current.setFieldsValue({
        roomApplicationMaterial: row.roomApplicationMaterials
      });
    });
  };

  dimensionModal = async row => {
    let applicationDimensions = [];
    if (Boolean(row) && Boolean(row.applicationDimensions) && row.applicationDimensions.length > 0) {
      applicationDimensions = row.applicationDimensions;
    }

    this.setState({
      modalWorkflowShow: true,
      modalDimensionList: applicationDimensions,
    });
  };

  saveMoveToNewLine = async values => {
    const roomApplications = this.formRef.current.getFieldValue("roomApplications");
    const { fieldKey } = this.state;
    let ra;
    let newIndex;

    if (fieldKey === "AddNew") {
      ra = { ...roomApplications[roomApplications.length - 1] };
      newIndex = roomApplications.length;
    } else {
      ra = { ...roomApplications[fieldKey] };
      newIndex = parseInt(fieldKey, 10) + 1;
    }

    ra.id = null;
    if (!values.roomUnit) {
      ra.roomUnit = "";
    }
    if (!values.discipline) {
      ra.discipline = "";
    }
    if (!values.shapeId) {
      ra.shapeId = "";
    }
    if (!values.structure) {
      ra.structure = "";
    }
    if (!values.subStructure) {
      ra.subStructure = "";
    }
    if (!values.subStructureType) {
      ra.subStructureType = "";
    }
    if (!values.constructionType) {
      ra.constructionType = "";
    }
    if (!values.roomApplicationMaterial) {
      ra.roomApplicationMaterial = [];
    }

    roomApplications.splice(newIndex, 0, ra);
    this.setState({
      modalMoveToNewLineShow: false,
    });

    this.formRef.current.setFieldsValue({
      roomApplications: roomApplications,
    });
  }

  saveMaterial = async values => {
    console.log("values", values);
    const { id } = this.state;
    if (Boolean(id) && id > 0) {
      if (Boolean(values.roomApplicationMaterial) && values.roomApplicationMaterial.length > 0) {
        values.roomApplicationMaterial.forEach(element => {
          element.applicationUsage = Boolean(element.usage) && element.usage.length > 0 ? element.usage[element.usage.length - 1] : null;
          element.constructionType = element.constructionType ? "Precast" : "In_Situ";
        });
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveRoomApplicationMaterial/" + id, values.roomApplicationMaterial);
        if (response.type === "ERROR") {
          error(response);
        } else {
          this.setState({
            modalMaterialShow: false
          });
          const { pagination } = this.state;
          this.restartTable({ pagination });
          showSuccess();
        }
      } else {
        showWarning("Room Application Material not found.");
      }
    } else {
      showWarning("Room Application not found.");
    }
  }

  renderSubStructureTypeField = (fieldkey) => {
    const roomApplications = this.formRef.current.getFieldValue("roomApplications");
    const isCivilDiscipline = roomApplications &&
      roomApplications.length > 0 &&
      roomApplications[fieldkey] &&
      roomApplications[fieldkey].discipline === "Civil";

    const mainStructure = roomApplications &&
      roomApplications.length > 0 &&
      roomApplications[fieldkey] &&
      roomApplications[fieldkey].mainStructure;

    const renderField = isCivilDiscipline && mainStructure ? (
      <Form.Item noStyle name={[fieldkey, "mainStructure"]} valuePropName="checked">
        <Checkbox onChange={e => {
          this.setState({
            mainStructure: e.target.checked
          })
        }}>
          <FormattedMessage id={"RoomApplication.MainStructure"} defaultMessage={"Main Structure"} />
        </Checkbox>
      </Form.Item>
    ) : (
      <Form.Item noStyle name={[fieldkey, "subStructureType"]}>
        <Input onChange={e => {
          this.setState({
            subStructureType: e.target.value
          })
        }} />
      </Form.Item>
    );

    return renderField;
  };

  renderCoverFinishingField = (fieldkey) => {
    let roomApplications = this.formRef.current.getFieldValue("roomApplications");
    const isCover = roomApplications &&
      roomApplications.length > 0 &&
      roomApplications[fieldkey] &&
      roomApplications[fieldkey].subStructureType === "Cover";

    const mainStructure = roomApplications &&
      roomApplications.length > 0 &&
      roomApplications[fieldkey] &&
      roomApplications[fieldkey].subStructureType === "Main Structure";

    if (mainStructure) {
      return <Form.Item noStyle name={[fieldkey, "coverFinishing"]} valuePropName="checked">
        <Checkbox onChange={e => {
          this.setState({
            coverFinishing: e.target.checked
          });

          if (e.target.checked) {
            let newIndex = parseInt(fieldkey, 10) + 1;
            let roomApplication = { ...roomApplications[fieldkey] };;
            roomApplication.id = "";
            roomApplication.structure = "";
            roomApplication.subStructure = "";
            roomApplication.subStructureType = "Cover";
            roomApplication.mainStructure = false;

            roomApplications.splice(newIndex, 0, roomApplication);

            console.log("fieldkey", fieldkey);
            console.log("roomApplications", roomApplications);

            this.formRef.current.setFieldsValue({
              roomApplications: roomApplications
            });
          }
        }}>
          <FormattedMessage id={"RoomApplication.HasCover"} defaultMessage={"Has Cover"} />
        </Checkbox>
      </Form.Item>
    } else if (isCover) {
      return <Form.Item noStyle name={[fieldkey, "coverFinishing"]} valuePropName="checked">
        <Checkbox onChange={e => {
          this.setState({
            coverFinishing: e.target.checked
          })

          if (e.target.checked) {
            let newIndex = parseInt(fieldkey, 10) + 1;
            let roomApplication = { ...roomApplications[fieldkey] };;
            roomApplication.id = "";
            roomApplication.structure = "";
            roomApplication.subStructure = "";
            roomApplication.subStructureType = "Finishing";

            roomApplications.splice(newIndex, 0, roomApplication);

            this.formRef.current.setFieldsValue({
              roomApplications: roomApplications
            });
          }
        }}>
          <FormattedMessage id={"RoomApplication.HasFinishing"} defaultMessage={"Has Finishing"} />
        </Checkbox>
      </Form.Item>
    }
  };

  prepareMaterial(row) {
    if (Boolean(row.roomApplicationMaterials) && row.roomApplicationMaterials.length > 0) {
      row.roomApplicationMaterials.forEach(element => {
        let materialIds = [];
        if (Boolean(element.materialCategoryId)) {
          materialIds.push(element.materialCategoryId);
        }
        if (Boolean(element.materialDescriptionId)) {
          materialIds.push(element.materialDescriptionId);
        }
        if (Boolean(element.materialGroupId)) {
          materialIds.push(element.materialGroupId);
        }
        if (Boolean(element.materialGroupId)) {
          materialIds.push(element.materialGroupId);
        }
        element.materialIds = materialIds;
        element.constructionType = element.constructionType === "Precast" ? true : false;
      });
    }
  }

  saveSpecification = async values => {
    console.log("values", values);

    const { id } = this.state;
    if (Boolean(id) && id > 0) {
      if (Boolean(values.roomApplicationSpecification) && values.roomApplicationSpecification.length > 0) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveRoomApplicationSpecification/" + id, values.roomApplicationSpecification);
        if (response.type === "ERROR") {
          error(response);
        } else {
          this.setState({
            modalSpecificationShow: false
          });
          const { pagination } = this.state;
          this.restartTable({ pagination });
          showSuccess();
        }
      } else {
        showWarning("Room Application Specification not found.");
      }
    } else {
      showWarning("Room Application not found.");
    }
  }

  saveDimension = async values => {
    console.log("values", values);

    const roomApplicationSpecification = this.formRefSpecification.current.getFieldValue("roomApplicationSpecification");
    console.log("roomApplicationSpecification", roomApplicationSpecification);
    const { fieldKey } = this.state;
    console.log("fieldKey", fieldKey);

    let specificationsDimensions = [];
    if (Boolean(values.length)) {
      specificationsDimensions.push({ "dimension": "Length" });
    }
    if (Boolean(values.width)) {
      specificationsDimensions.push({ "dimension": "Width" });
    }
    if (Boolean(values.height)) {
      specificationsDimensions.push({ "dimension": "Height" });
    }
    if (Boolean(values.diameter)) {
      specificationsDimensions.push({ "dimension": "Diameter" });
    }
    console.log("specificationsDimensions", specificationsDimensions);
    roomApplicationSpecification[fieldKey].specificationsDimensions = specificationsDimensions;
    console.log("roomApplicationSpecification", roomApplicationSpecification);

    this.formRefSpecification.current.setFieldsValue({
      roomApplicationSpecification: roomApplicationSpecification,
    });

    this.setState({
      selectedValueDimensions: values.dimension,
      modalDimensionShow: false,
    });
    showSuccess();
  }

  render() {
    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const columns = [
      {
        key: "key",
        render: this.action
      },
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1 + this.state.pagination.pageSize * (this.state.pagination.current - 1);
        }
      },
      {
        title: <FormattedMessage id="RoomApplication.RoomUnit" defaultMessage="Room Unit" />,
        key: "roomUnit",
        render: record => {
          return record.roomUnit;
        }, ...getColumnFilter({
          inputName: "roomUnit", inputType: "SELECT",
          selectItems: Boolean(this.state["roomUnitSelectItems"]) && this.state["roomUnitSelectItems"].length > 0 ? this.state["roomUnitSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="RoomApplication.Discipline" defaultMessage="Discipline" />,
        key: "discipline",
        render: record => {
          return record.discipline;
        }, ...getColumnFilter({
          inputName: "discipline", inputType: "SELECT",
          selectItems: Boolean(this.state["disciplineSelectItems"]) && this.state["disciplineSelectItems"].length > 0 ? this.state["disciplineSelectItems"] : [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="RoomApplication.Structure" defaultMessage="Structure" />,
        key: "structure",
        render: record => {
          return record.structure;
        }, ...getColumnFilter({
          inputName: "structure", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="RoomApplication.SubStructure" defaultMessage="Sub Structure" />,
        key: "subStructure",
        render: record => {
          return record.subStructure;
        }, ...getColumnFilter({
          inputName: "subStructure", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
      {
        title: <FormattedMessage id="RoomApplication.Structure" defaultMessage="Structure Type" />,
        key: "subStructureType",
        render: record => {
          return record.subStructureType;
        }, ...getColumnFilter({
          inputName: "subStructureType", inputType: "TEXT",
          selectItems: [],
          filterChangeItem: this.filterChangeItem, searchCriteriaModels: this.state.searchCriteriaModels
        }), sorter: true
      },
    ];


    const columnsItemList = [
      {
        title: <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />, key: "id",
        render: (value, row, index) => {
          return index + 1;
        }
      },
      {
        title: <FormattedMessage id="RoomApplication.Material" defaultMessage="Material" />,
        key: "id",
        render: record => {
          let material = [];
          if (Boolean(record.materialCategoryName)) {
            material.push(record.materialCategoryName);
          }
          if (Boolean(record.materialDescriptionName)) {
            material.push(record.materialDescriptionName);
          }
          if (Boolean(record.materialGroupName)) {
            material.push(record.materialGroupName);
          }
          if (Boolean(record.materialTypeName)) {
            material.push(record.materialTypeName);
          }
          return material.join(", ");
        },
      },
      {
        title: <FormattedMessage id="RoomApplication.Usage" defaultMessage="Usage" />,
        key: "id",
        render: record => {
          return record.applicationUsageName;
        },
      },
    ];

    const excelExport = (<>
      {/* {this.state.exportClick ? this.setState({ exportClick: false }) : ""}         {this.state.exportClick ? (<ExcelFile filename="RoomApplication" hideElement={true}>             <ExcelSheet name="RoomApplication" data={this.state.tableList}>               <ExcelColumn label="Discipline" value="Discipline" />
      <ExcelColumn label="Phase" value="Phase" />
      <ExcelColumn label="Activity" value="Activity" />
    </ExcelSheet>           </ExcelFile>) : (<AntButton style={{ border: "0px" }} onClick={this.handleExportExcel} 
    icon={<ExportIcon color="action" fontSize="large" />}></AntButton>)}      */}
    </>);
    return (
      <Badge.Ribbon text={<FormattedMessage id="RoomApplication.pageTitle" defaultMessage="Room Application" />}
        placement="start" color="volcano"
        style={{ fontSize: 12 }}>
        <div
          className="card card-custom"
          style={{ padding: "2rem", minHeight: "100%" }}>
          <Form
            initialValues={{ remember: false }}
            onFinish={this.save}
            onFinishFailed={onFinishFailed}
            ref={this.formRef}>
            <Row gutter={[16, 16]}>
              <Col md={21}>
              </Col>
              <Col md={1}>{excelExport}
              </Col>
              <Col md={1}>
                <ExcelReader onCreateJson={this.handleUploadExcel.bind(this)}>
                </ExcelReader>
              </Col>
              <Col md={1}>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={!this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<CreateIcon color="secondary" fontSize="large" />}
                ></AntButton>
                <AntButton
                  style={{ border: "0px" }}
                  hidden={this.state.hideInputs}
                  onClick={this.onClickCreateNew}
                  icon={<RemoveCircle color="error" fontSize="large" />}
                ></AntButton>
              </Col>
            </Row>
            <div hidden={this.state.hideInputs}>
              <br /> <br />
              <Form.List name="roomApplications">
                {(fields, { add, remove }) => {

                  // const handleAddClick = () => {
                  //   add();
                  // };

                  // const handleRemoveClick = (fieldKey) => {
                  //   remove(fieldKey);
                  // };

                  const handleCopyModalClick = (fieldKey) => {
                    const roomApplications = this.formRef.current.getFieldValue("roomApplications");
                    if (Boolean(roomApplications) && roomApplications.length > 0) {
                      this.setState({
                        fieldKey: fieldKey,
                        modalMoveToNewLineShow: true,
                      }, () => {
                        this.formRefMoveToNewLine.current.resetFields();
                      });
                    } else {
                      add();
                    }
                  };

                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"RoomApplication.RoomApplicationUnit"} defaultMessage={"Room Unit"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"RoomApplication.Discipline"} defaultMessage={"Discipline"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"RoomApplication.StructureType"} defaultMessage={"Structure Type"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"RoomApplication.Structure"} defaultMessage={"Structure"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"RoomApplication.Component"} defaultMessage={"Component"} />
                            </th>
                            <th colSpan="1">
                            </th>
                            <th colSpan="1">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <Form.Item hidden name={[field.name, "id"]}>
                                  <InputNumber />
                                </Form.Item>
                                <Form.Item hidden name={[field.name, "roomApplicationMaterials"]}></Form.Item>

                                <td>
                                  <Form.Item noStyle name={[field.name, "roomUnit"]}>
                                    <Select
                                      showSearch
                                      allowClear
                                      optionFilterProp="children"
                                      style={{ width: "100%" }}
                                    >
                                      {Boolean(this.state["roomUnitSelectItems"]) && this.state["roomUnitSelectItems"].length > 0 &&
                                        this.state["roomUnitSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}{" "}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "discipline"]}>
                                    <Select
                                      showSearch
                                      allowClear
                                      optionFilterProp="children"
                                      style={{ width: "100%" }}
                                      onChange={value => {
                                        if (value === "Civil") {
                                          const roomApplications = this.formRef.current.getFieldValue("roomApplications");
                                          roomApplications[field.name].mainStructure = true;
                                          roomApplications[field.name].subStructureType = "Main Structure";

                                          this.formRef.current.setFieldsValue({
                                            roomApplications: roomApplications,
                                          });
                                        }
                                        this.setState({
                                          discipline: value
                                        });
                                      }}
                                    >
                                      {Boolean(this.state["disciplineSelectItems"]) && this.state["disciplineSelectItems"].length > 0 &&
                                        this.state["disciplineSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}{" "}
                                    </Select>
                                  </Form.Item>
                                </td>

                                <td>
                                  {
                                    this.renderSubStructureTypeField(field.name)
                                  }
                                </td>

                                <td>
                                  <Form.Item noStyle name={[field.name, "structure"]}>
                                    <Input />
                                  </Form.Item>
                                </td>

                                <td>
                                  <Form.Item noStyle name={[field.name, "subStructure"]}>
                                    <Input />
                                  </Form.Item>
                                </td>

                                <td>
                                  {
                                    this.renderCoverFinishingField(field.name)
                                  }
                                </td>

                                <td>
                                  <Space>
                                    <PlusCircleOutlined onClick={() => handleCopyModalClick(field.name)} />
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                  </Space>
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => handleCopyModalClick("AddNew")} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"RoomApplication.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot >
                    </>
                  );
                }}
              </Form.List>

              <div hidden={this.state.hideSave}>
                {<Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Button id="SaveButton"
                      type="submit"
                      style={{ width: "100%" }}
                      variant="success">
                      <FormattedMessage id="GeneralButtonSave"
                        defaultMessage="Save" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>}
              </div>

              <div hidden={this.state.hideUpdate}>
                {<Row gutter={[16, 16]}>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="CancelButton" style={{ width: "100%" }}
                      variant="secondary"
                      onClick={this.cancel}>
                      <FormattedMessage
                        id="GeneralButtonCancel"
                        defaultMessage="Cancel" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Button id="UpdateButton"
                      style={{ width: "100%" }}
                      variant="warning" type="submit">
                      <FormattedMessage id="GeneralButtonUpdate"
                        defaultMessage="Update" />
                    </Button>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                </Row>}
              </div>
            </div>
          </Form>

          {
            Boolean(this.state.searchCriteriaModels) && this.state.searchCriteriaModels.length > 0 &&
            (<div className="alert alert-custom alert-light">
              <div className="alert-icon">
                <FilterOutlined />
              </div>
              <div className="alert-text">
                {this.state.searchCriteriaModels.map((val, i) => {
                  return (<div key={i} className="bg-secondary rounded-sm d-inline mr-2 py-2 px-2">
                    <span className="mr-2">{val.label}</span>
                    <strong className="mr-2">{val.type}</strong>
                    <span className="mr-2">{val.operationValue}</span>
                    <span className="mr-2">{val.showValue}</span>
                    <span>
                      <Tooltip title="Remove">
                        <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.removeSearchCriteria(i)} />
                      </Tooltip>
                    </span>
                  </div>);
                })}
              </div>
              <div className="alert-close">
                <Tooltip title="Remove All">
                  <CloseCircleOutlined style={{ color: "crimson" }} onClick={() => this.setState({ searchCriteriaModels: [] }, () => { this.filterChangeItem([]); })} />
                </Tooltip>
              </div>
            </div>)
          }

          <div style={{ marginTop: "2rem", marginBottom: "1rem" }} className="content-section implementation">
            <Table bordered
              scroll={{ x: 900, scrollToFirstRowOnChange: true }}
              columns={columns} dataSource={this.state.tableList}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              pagination={this.state.pagination} />
          </div>

          <Modal
            title={<FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />}
            centered
            width={500}
            okButtonProps={{ hidden: true }}
            open={this.state.modalDeleteShow}
            onCancel={() => {
              this.setState({ modalDeleteShow: false });
            }}
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalDeleteShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={this.delete}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                </Button>
              </Space>
            ]}
          >
            <p>
              <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
            </p>
          </Modal>

          <Modal
            title={<FormattedMessage id="RoomApplication.Item" defaultMessage="Item" />}
            closable="false"
            open={this.state.modalItemShow}
            onCancel={() => {
              this.setState({ modalItemShow: false });
            }}
            width={1000}
            centered
            footer={[
              <>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalItemShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                </Button>
              </>
            ]}
          >

            <div className="card card-custom" style={{ minHeight: "100%" }}>
              <Table
                bordered
                size="small"
                columns={columnsItemList}
                dataSource={this.state.modalItemList}
                loading={this.state.loading}
                pagination={false}
              />
            </div>
          </Modal>

          <Modal
            title={<FormattedMessage id="RoomApplication.Item" defaultMessage="Item" />}
            closable="false"
            open={this.state.modalMaterialShow}
            onCancel={() => {
              this.setState({ modalMaterialShow: false });
            }}
            width={1200}
            centered
            footer={[
              <>
                <Space>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.setState({ modalMaterialShow: false });
                    }}
                  >
                    <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      this.formRefMaterial.current.submit()
                    }}
                  >
                    <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                  </Button>
                </Space>
              </>
            ]}
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveMaterial} ref={this.formRefMaterial}>
              <Form.List name="roomApplicationMaterial">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"RoomApplication.Material"} defaultMessage={"Material"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"RoomApplication.ConstructionType"} defaultMessage={"Construction Type"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"RoomApplication.Usage"} defaultMessage={"Usage"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"RoomApplication.Workflow"} defaultMessage={"Workflow"} />
                            </th>
                            <th colSpan="1">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map(field => {
                            return (
                              <tr key={field.key}>
                                <Form.Item hidden name={[field.name, "id"]}> </Form.Item>
                                <td>
                                  <Form.Item noStyle name={[field.name, "materialIds"]}>
                                    <Cascader
                                      showSearch
                                      allowClear
                                      optionFilterProp="children"
                                      style={{ width: "100%" }} options={this.state.materialList} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "constructionType"]}
                                    valuePropName="checked"
                                  >
                                    <Switch checkedChildren="Precast" unCheckedChildren="In Situ" />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "usage"]}>
                                    <Cascader
                                      showSearch
                                      allowClear
                                      optionFilterProp="children"
                                      style={{ width: "100%" }} options={this.state.usageList} />
                                  </Form.Item>
                                </td>
                                <td>
                                  <AntButton
                                    onClick={() => this.dimensionModal()}
                                    style={{ border: "0px", color: "#1BC5BD" }}
                                    icon={<ZoomInOutlined fontSize="large" />}
                                  ></AntButton>
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"RoomApplication.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          </Modal>

          <Modal
            title={<FormattedMessage id="RoomApplication.Workflow" defaultMessage="Workflow" />}
            closable="false"
            open={this.state.modalWorkflowShow}
            onCancel={() => {
              this.setState({ modalWorkflowShow: false });
            }}
            width={1200}
            centered
            footer={[
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modalWorkflowShow: false });
                }}
              >
                <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
              </Button>
            ]}
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveWorkflow} ref={this.formRefWorkflow}>
              <Form.List name="roomApplicationWorkflow">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id="General.TitleSN" defaultMessage="S/N" />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"RoomApplication.Material"} defaultMessage={"Step"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"RoomApplication.Activity"} defaultMessage={"Activity"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"RoomApplication.Item"} defaultMessage={"Item"} />
                            </th>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"RoomApplication.Definition"} defaultMessage={"Definition"} />
                            </th>
                            <th colSpan="1">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map((field, index) => {
                            return (
                              <tr key={field.key}>
                                <td>
                                  {index + 1}
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "stepId"]}>
                                    <Select
                                      showSearch
                                      allowClear
                                      optionFilterProp="children"
                                      style={{ width: "100%" }}
                                    >
                                      {Boolean(this.state["stepIdSelectItems"]) && this.state["stepIdSelectItems"].length > 0 &&
                                        this.state["stepIdSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}{" "}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "activityId"]}>
                                    <Select
                                      showSearch
                                      allowClear
                                      optionFilterProp="children"
                                      style={{ width: "100%" }}
                                    >
                                      {Boolean(this.state["activityIdSelectItems"]) && this.state["activityIdSelectItems"].length > 0 &&
                                        this.state["activityIdSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}{" "}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "itemId"]}>
                                    <Select
                                      showSearch
                                      allowClear
                                      optionFilterProp="children"
                                      style={{ width: "100%" }}
                                    >
                                      {Boolean(this.state["itemIdSelectItems"]) && this.state["itemIdSelectItems"].length > 0 &&
                                        this.state["itemIdSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}{" "}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "definition"]}>
                                    <Input />
                                  </Form.Item>
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"RoomApplication.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          </Modal>

          <Modal
            title={<FormattedMessage id="RoomApplication.AddNewDimension" defaultMessage="Add New Dimension" />}
            closable="false"
            open={this.state.modalAddNewDimensionShow}
            onCancel={() => {
              this.setState({ modalAddNewDimensionShow: false });
            }}
            width={600}
            centered
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalAddNewDimensionShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                </Button>
                <Button variant="success" onClick={() => this.addCode("RoomApplication.RoomApplicationDimension")}>
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Space>
            ]}
          >
            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
              <Input
                style={{ flex: "auto" }}
                value={this.state.codeName}
                onChange={event => {
                  this.setState({
                    codeName: event.target.value
                  });
                }}
              />
            </div>
          </Modal>

          <Modal
            title={<FormattedMessage id="RoomApplication.PleaseSelectItemsToBeMoveToNewLine" defaultMessage="Please select item(s) to be move to new line" />}
            closable="false"
            open={this.state.modalMoveToNewLineShow}
            onCancel={() => {
              this.setState({ modalMoveToNewLineShow: false });
            }}
            width={600}
            centered
            footer={[
              <Space>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.setState({ modalMoveToNewLineShow: false });
                  }}
                >
                  <FormattedMessage id="GeneralButtonClose" defaultMessage="Close" />
                </Button>
                <Button variant="success"
                  onClick={() => {
                    this.formRefMoveToNewLine.current.submit();
                  }}
                >
                  <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                </Button>
              </Space>
            ]}
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveMoveToNewLine} ref={this.formRefMoveToNewLine}>
              <Form.Item
                {...layout}
                name="selectAll"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox
                  onChange={value => {
                    this.formRefMoveToNewLine.current.setFieldsValue({
                      roomUnit: value.target.checked,
                      discipline: value.target.checked,
                      structure: value.target.checked,
                      subStructure: value.target.checked,
                      shapeId: value.target.checked,
                      subStructureType: value.target.checked,
                      roomApplicationMaterial: value.target.checked,
                      constructionType: value.target.checked,
                    });
                  }}
                ><FormattedMessage id="RoomApplication.RoomUnitSelectAll" defaultMessage="Select All" /></Checkbox>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="RoomApplication.RoomUnit" defaultMessage="Room unit" />}
                name="roomUnit"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="RoomApplication.Discipline" defaultMessage="Discipline" />}
                name="discipline"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="RoomApplication.Structure" defaultMessage="Structure" />}
                name="structure"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="RoomApplication.SubStructure" defaultMessage="Sub-structure" />}
                name="subStructure"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="RoomApplication.Shape" defaultMessage="Shape" />}
                name="shapeId"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="RoomApplication.SubStructureType" defaultMessage="Sub-structure type" />}
                name="subStructureType"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="RoomApplication.Item" defaultMessage="Item" />}
                name="roomApplicationMaterial"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="RoomApplication.ConstructionType" defaultMessage="Construction Type" />}
                name="constructionType"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>
            </Form>

          </Modal>

          <Modal
            title={<FormattedMessage id="RoomApplication.StructureSpecification" defaultMessage="Structure Specification" />}
            closable="false"
            open={this.state.modalSpecificationShow}
            onCancel={() => {
              this.setState({ modalSpecificationShow: false });
            }}
            onOk={() => {
              this.formRefSpecification.current.submit();
            }}
            okButtonProps={{ hidden: false }}
            width={1200}
            centered
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveSpecification} ref={this.formRefSpecification}>
              <Form.List name="roomApplicationSpecification">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <TableBoot bordered size="sm">
                        <thead>
                          <tr>
                            <th colSpan="1" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"RoomApplication.Structure"} defaultMessage={"Structure"} />
                            </th>
                            <th colSpan="3" style={{ textAlign: "center" }}>
                              <FormattedMessage id={"RoomApplication.Criterias"} defaultMessage={"Criterias"} />
                            </th>
                            <th colSpan="1">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map((field) => {
                            return (
                              <tr key={field.key}>
                                <Form.Item hidden name={[field.name, "specificationsDimensions"]}></Form.Item>
                                <td>
                                  <Form.Item noStyle name={[field.name, "structure"]}>
                                    <Select
                                      showSearch
                                      allowClear
                                      optionFilterProp="children"
                                      style={{ width: "100%" }}
                                    >
                                      {Boolean(this.state["structureSelectItems"]) && this.state["structureSelectItems"].length > 0 &&
                                        this.state["structureSelectItems"].map(i => (
                                          <Option key={i.key} value={i.key}>
                                            {i.value}
                                          </Option>
                                        ))}{" "}
                                    </Select>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "dimension"]} valuePropName="checked">
                                    <Checkbox onChange={e => {
                                      if (e.target.checked) {
                                        this.setState({
                                          modalDimensionShow: true,
                                          fieldKey: field.name,
                                        },
                                          () => {
                                            this.formRefDimension.current.resetFields();
                                          });
                                      }
                                    }}>
                                      <FormattedMessage id={"RoomApplication.Dimension"} defaultMessage={"Dimension"} />
                                    </Checkbox>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "material"]} valuePropName="checked">
                                    <Checkbox>
                                      <FormattedMessage id={"RoomApplication.Material"} defaultMessage={"Material"} />
                                    </Checkbox>
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item noStyle name={[field.name, "technicalSpecification"]} valuePropName="checked">
                                    <Checkbox>
                                      <FormattedMessage id={"RoomApplication.TechnicalSpecification"} defaultMessage={"Technical Specification"} />
                                    </Checkbox>
                                  </Form.Item>
                                </td>
                                <td>
                                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={11}>
                              <AntButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                <FormattedMessage id={"RoomApplication.AddNew"} defaultMessage={"Add New"} />
                              </AntButton>
                            </td>
                          </tr>
                        </tbody>
                      </TableBoot>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          </Modal>

          <Modal
            title={""}
            closable={false}
            maskClosable={false}
            open={this.state.modalDimensionShow}
            onCancel={() => {
              this.setState({ modalDimensionShow: false });
            }}
            onOk={() => {
              this.formRefDimension.current.submit();
            }}
            okButtonProps={{ hidden: false }}
            width={600}
            centered
          >
            <Form initialValues={{ remember: false }} onFinish={this.saveDimension} ref={this.formRefDimension}>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="RoomApplication.Length" defaultMessage="Length" />}
                name="length"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>
              <Form.Item
                {...layout}
                label={<FormattedMessage id="RoomApplication.Width" defaultMessage="Width" />}
                name="width"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="RoomApplication.Height" defaultMessage="Height" />}
                name="height"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>

              <Form.Item
                {...layout}
                label={<FormattedMessage id="RoomApplication.Diameter" defaultMessage="Diameter" />}
                name="diameter"
                rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>
            </Form>
          </Modal>
        </div >
      </Badge.Ribbon >
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(RoomApplication);