import { EditOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Col, Form, Row, Skeleton, Steps, Select, Modal as AntModal, Space, Slider, DatePicker, Checkbox, Input, InputNumber, TimePicker } from "antd";
import "antd/dist/antd.css";
import React, { Component, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { handleRequest } from "../../ApiConnector";
import ElementsProvider from "../../Components/ElementsProvider";
import { GetDynamicFormItems, PrepareRowForEdit, PrepareRowForSave } from "../../Components/ElementUtils";
import { error, showError, showSuccess } from "../../MessageHelper";
import DynamicActionMenu from "../CustomMenu/DynamicActionMenu";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  FileDoneOutlined,
} from "@ant-design/icons";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import moment from "moment";
import { SliderMarks } from 'antd/es/slider';
import { select } from "d3";

const { Step } = Steps;

const { Option } = Select;

function ArrowIcon() {
  return (
    <span role="img" aria-label="checkmark">➜</span>
  );
}

const defaultValues = [{key:"StartOfWork", value:"Start Of Work"  }];
const startOfWorkValues = [  { key: "WorkHours", value: "Work Hours" },{ key: "Break", value: "Break" }];
const workHoursValues = [{ key: "Break", value: "Break" }, { key: "EndOfWork", value: "End Of Work" }];
const breakValues = [{ key: "WorkHours", value: "Work Hours" }, { key: "EndOfWork", value: "End Of Work" }];
const endOfWorkValues = [" "];

export class ShiftsWorkSchedule extends Component {
  constructor() {
    super();
    this.state = {
      formName: "ShiftsWorkSchedule",
      controllerName: "shiftsWorkSchedule",
      modalDeleteShow: false,
      modalCopyScheduleToShow: false,
      modalWorkScheduleShow: false,
      hideUpdate: true,
      hideSave: false,
      hideInputs: true,
      lastUpdateDate: [],
      id: null,
      restartTable: false,
      pageFormData: null,
      refreshForm: false,
      currentStep: 0,
      shiftsSelectedRow: {},
      steps: [],
      workingDayList: [],
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    this.initialize();
  };

  componentDidUpdate = (prevProps) => {
    let random = this.props.random;
    if (random !== prevProps.random)
      this.initialize();
  };

  initialize = async () => {
    var steps = this.getSteps(this.props.shiftsSelectedRow);
    let data = await GetDynamicFormItems(this.state.formName);
    await this.setState({
      pageFormData: data,
      shiftsSelectedRow: this.props.shiftsSelectedRow,
      steps: steps,
      currentStep: 0,
    });
    this.onStepsChange(0)
    this.fillComboboxes();
  };

  fillComboboxes = async () => {

    var responseDescriptionType = await handleRequest("GET", "/api/" + this.state.controllerName + "/descriptionType");
    if (responseDescriptionType.type === "ERROR") {
      error(responseDescriptionType);
    }
    this.setState({
      descriptionTypeSelectItems: Boolean(responseDescriptionType.data) ? responseDescriptionType.data : []
    });

  };

  cancel = () => {
    this.setState({
      hideUpdate: true,
      hideSave: false,
      hideInputs: true
    });
    this.resetInputs();
  };

  resetInputs = () => {
    if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
      this.formRef.current.resetFields();
    }
    this.setState({
      id: null,
      modalWorkScheduleShow: false,
    });
  };

  edit = row => {
    this.setState({
      modalWorkScheduleShow: true,
    }, () => {
      var startTime = null;
      if (Boolean(row.startTime)) {
        startTime = moment(moment(row.startTime).format("HH:mm:ss"), 'HH:mm:ss');
      }
      var endTime = null;
      if (Boolean(row.endTime)) {
        endTime = moment(moment(row.endTime).format("HH:mm:ss"), 'HH:mm:ss');
      }
      let handledValues = {
        ...row,
        startTime: startTime,
        endTime: endTime,
      };
      handledValues = PrepareRowForEdit(handledValues, this.state.pageFormData.pageFormInputCompanyList);
      if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
        this.formRef.current.setFieldsValue({
          ...handledValues
        });
      }
      this.setState({
        hideUpdate: false,
        hideSave: true,
        hideInputs: false,
        id: row.id,
      });
    });
  };

  deleteModal = row => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  };

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id
    };
    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      this.restartTable();
      showSuccess();
      this.setState({
        id: null,
        modalDeleteShow: false
      });
    }
  };

  restartTable = async () => {
    this.onStepsChange(this.state.currentStep)
  };

  onClickCreateNew = () => {
    this.resetInputs();
    this.setState({
      hideInputs: false,
      hideSave: false,
      hideUpdate: true,
      modalWorkScheduleShow: true,
    }, () => {
      let { currentContent } = this.state;
      let endTime = null;
      if (Boolean(currentContent) && currentContent.length > 0) {
        endTime = currentContent[currentContent.length - 1].endTime;
      }

      if (Boolean(endTime)) {
        endTime = moment(moment(endTime).format("HH:mm:ss"), 'HH:mm:ss');
      }
      let handledValues = {
        startTime: endTime,
      };
      handledValues = PrepareRowForEdit(handledValues, this.state.pageFormData.pageFormInputCompanyList);
      if (Boolean(this.formRef) && Boolean(this.formRef.current)) {
        this.formRef.current.setFieldsValue({
          ...handledValues
        });
      }

    });
  };

  save = async values => {
    if (Boolean(this.state.shiftsSelectedRow) && Boolean(this.state.shiftsSelectedRow.id)
      && this.state.shiftsSelectedRow.id > 0) {
      var startTime = null;
      if (Boolean(values.startTime)) {
        startTime = moment(values.startTime);
      }
      var endTime = null;
      if (Boolean(values.endTime)) {
        endTime = moment(values.endTime);
      }

      let newItem = {
        ...values,
        id: this.state.id,
        shiftsId: this.state.shiftsSelectedRow.id,
        workingDays: this.state.steps[this.state.currentStep].title,
        startTime: startTime,
        endTime: endTime,
      };
      newItem = PrepareRowForSave(newItem, this.state.pageFormData.pageFormInputCompanyList);
      if (!Boolean(newItem.id)) {
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "", newItem);
      } else {
        response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/" + newItem.id, newItem);
      }

      if (response.type === "ERROR") {
        showError(response);
      } else {
        this.cancel();
        this.restartTable();
        showSuccess();
      }
    } else {
      showError("Shifts not found");
    }
  };

  action = record => {
    return (
      <React.Fragment>
        <DynamicActionMenu
          row={record}
          actionList={[
            {
              name: "Edit",
              icon: <EditOutlined fontSize="small" color="primary" />,
              actionClick: this.edit.bind(this)
            },
            {
              name: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              actionClick: this.deleteModal.bind(this)
            },
          ]}
        />
      </React.Fragment>
    );
  };

  onStepsChange = async (current) => {
    var step = this.state.steps[current];
    if (Boolean(step) && Boolean(this.state.shiftsSelectedRow) &&
      Boolean(this.state.shiftsSelectedRow.workingDays) &&
      this.state.shiftsSelectedRow.workingDays.length > 0 && Boolean(this.state.shiftsSelectedRow.id)
      && this.state.shiftsSelectedRow.id > 0) {
      var shiftsItemWorkingDays = this.state.shiftsSelectedRow.workingDays.find(p => p.day === step.title);
      if (Boolean(shiftsItemWorkingDays)) {
        var content = [];
        var newObj = {
          workingDays: step.title,
          shiftsId: this.state.shiftsSelectedRow.id
        }
        var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/getWorkingDays", newObj);
        if (response.type === "ERROR") {
          error(response);
        } else {
          content = Boolean(response.data) ? response.data : [];
        }
        shiftsItemWorkingDays.content = content;
        this.setState({
          currentStep: current,
          steps: this.state.steps,
          shiftsSelectedRow: this.state.shiftsSelectedRow,
          currentContent: content,
        }, () => {
          var steps = this.getSteps(this.state.shiftsSelectedRow, step.title);
          this.setState({
            steps: steps,
          });
        });
      } else {
        showError("Working Days not found");
      }
    }
  };

  onClickCopyScheduleTo = row => {
    this.setState({
      modalCopyScheduleToShow: true,
      modalWorkingDayList: [],
    });
  };

  onClickCopyScheduleFrom = row => {
    this.setState({
      modalCopyScheduleFromShow: true,
    });
  };


  getSteps(shiftsSelectedRow, currentWorkDay) {
    const marks: SliderMarks = {
      0: {
        style: {
          color: '#f50',
        },
        label: <strong>00.00</strong>,
      },
      15: '00:15',
      30: '00:30',
      45: '00:45',
      60: '01:00',
      // 60: {
      //   style: {
      //     color: '#f50',
      //   },
      //   label: <strong>24.00</strong>,
      // },
    };
    var steps = [];
    var workingDayList = [];
    if (Boolean(shiftsSelectedRow) && Boolean(shiftsSelectedRow.workingDays) && shiftsSelectedRow.workingDays.length > 0) {
      shiftsSelectedRow.workingDays.forEach(element => {
        if (Boolean(currentWorkDay) && currentWorkDay !== element.day)
          workingDayList.push(element.day);
        steps.push({
          title: element.day, description: "", content: (
            <div className="card card-custom card-stretch gutter-b">
              {Boolean(element.content) && element.content.length > 0 &&
                Boolean(element.content.find(p => p.endOfWork === true)) > 0 ?
                "" :
                <Row gutter={[16, 16]}>
                  {
                    <>
                      <Col md={3}>
                        <Button id="WorkScheduleButton" variant="primary" style={{ width: "100%" }}
                          onClick={this.onClickCreateNew}>
                          <FormattedMessage id="GeneralButtonWorkSchedule" defaultMessage="+ Schedule" />
                        </Button>
                      </Col>
                    </>
                  }
                  {
                    Boolean(element.content) && element.content.length > 0 &&
                    <>
                      <Col md={3}>
                        <Button id="DublicateTheScheduleButton" variant="primary" style={{ width: "100%" }}
                          onClick={this.onClickCopyScheduleTo}>
                          <FormattedMessage id="GeneralButtonDublicateTheSchedule" defaultMessage="Copy to" />
                        </Button>
                      </Col>
                    </>
                  }
                  {
                    Boolean(element.content) && element.content.length > 0 &&
                    <>
                      <Col md={3}>
                        <Button id="DublicateTheScheduleButton" variant="primary" style={{ width: "100%" }}
                          onClick={this.onClickCopyScheduleFrom}>
                          <FormattedMessage id="GeneralButtonDublicateTheSchedule" defaultMessage="Copy from" />
                        </Button>
                      </Col>
                    </>
                  }
                  <Col md={18}>
                  </Col>
                </Row>
              }

              {/* {Boolean(element.content) && element.content.length > 0 &&
                <>
                  <br />
                  <Row gutter={[16, 16]}>
                    <Col md={3}>
                      <Button id="DublicateTheScheduleButton" variant="primary" style={{ width: "100%" }}
                        onClick={this.onClickCopyScheduleTo}>
                        <FormattedMessage id="GeneralButtonDublicateTheSchedule" defaultMessage="Copy to" />
                      </Button>
                    </Col>
                    <Col md={18}>
                    </Col>
                  </Row>
                </>
              } */}

              <>
                <h4>marks & step</h4>
                <Slider marks={marks} min={0} max={60} step={5} defaultValue={15} />

              </>



              <div className="card-body pt-4 custom-timeline"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div className="timeline timeline-5 mt-3">
                  {Boolean(element.content) && element.content.length > 0 && element.content.map((item, index) => {
                    return (
                      <>
                        {/* <div key={index} className="timeline-item align-items-start">
                        <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">{item.descriptionName}</div>
                        <div className="timeline-badge">{index + 1}</div>
                        <div className="timeline-content d-flex">
                          <span className="font-weight-bolder text-dark-75 pl-3 font-size-lg">
                            <h5>
                              {Boolean(item.startTime) ? moment(item.startTime).format("HH:mm:ss") : "-"}
                            </h5>
                            <br />
                            <h5>
                              {Boolean(item.endTime) ? moment(item.endTime).format("HH:mm:ss") : "-"}
                            </h5>
                            <Button size="sm" variant="danger" onClick={() => this.deleteModal(item)}>
                              <DeleteOutlineOutlinedIcon />
                            </Button>
                            <Button size="sm" variant="primary" onClick={() => this.edit(item)}>
                              <EditOutlinedIcon />
                            </Button>
                          </span>
                        </div>
                      </div> */}
                        <Button size="sm" variant="danger" onClick={() => this.deleteModal(item)}>
                          <DeleteOutlineOutlinedIcon />
                        </Button>
                        <Button size="sm" variant="primary" onClick={() => this.edit(item)}>
                          <EditOutlinedIcon />
                        </Button>
                        <div key={index} className="timeline-item align-items-start">
                          <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">{item.descriptionName}</div>
                          <div className="timeline-badge">{index + 1}</div>
                          <div className="timeline-content d-flex">
                            <span className="font-weight-bolder text-dark-75 pl-3 font-size-lg">
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <h5>
                                  {Boolean(item.startTime) ? moment(item.startTime).format("HH:mm:ss") : "-"}
                                </h5>
                                <span style={{ margin: '0 5px', color: 'darkred', fontWeight: 'bold', verticalAlign: '25em' }}> <ArrowIcon /> </span>
                                <h5>
                                  {Boolean(item.endTime) ? moment(item.endTime).format("HH:mm:ss") : "-"}
                                </h5>
                              </div>
                              {/* <Button size="sm" variant="danger" onClick={() => this.deleteModal(item)}>
                              <DeleteOutlineOutlinedIcon />
                            </Button>
                            <Button size="sm" variant="primary" onClick={() => this.edit(item)}>
                              <EditOutlinedIcon />
                            </Button> */}
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div >
          ),
          "icon": <FileDoneOutlined style={{ fontSize: "50px" }} />,
          "disabled": false
        });
      });
    }
    this.setState({
      workingDayList: workingDayList,
    });
    return steps;
  }

  saveDublicateTheScheduleClickSubmit = async () => {
    if (Boolean(this.state.modalWorkingDayList) && this.state.shiftsSelectedRow && this.state.modalWorkingDayList.length > 0
      && Boolean(this.state.steps) && this.state.steps.length > 0) {
      var step = this.state.steps[this.state.currentStep];
      if (Boolean(step) && Boolean(this.state.shiftsSelectedRow) && Boolean(this.state.shiftsSelectedRow.workingDays)
        && this.state.shiftsSelectedRow.workingDays.length > 0) {
        var row = this.state.shiftsSelectedRow.workingDays.find(p => p.day === step.title);
        var list = [];
        if (Boolean(row) && Boolean(row.content) && row.content.length > 0) {
          row.content.forEach(element => {
            this.state.modalWorkingDayList.forEach(day => {
              const obj = {
                "descriptionType": element.descriptionType, "endOfWork": element.endOfWork, "endTime": element.endTime,
                "shiftsId": element.shiftsId, "startTime": element.startTime,
                "workingDays": day,
              };
              list.push(obj);
            });
          });
        }
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveDublicateList", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            this.restartTable();
            showSuccess();
            this.setState({
              modalCopyScheduleToShow: false,
              modalWorkingDayList: null
            });
          }
        } else {
          showError("No records to be added");
        }
      }
    } else {
      showError("Please select work days");
    }
  };


  saveCopyScheduleFromClickSubmit = async () => {
    if (Boolean(this.state.modalWorkingDay) && this.state.shiftsSelectedRow
      && Boolean(this.state.steps) && this.state.steps.length > 0) {
      var step = this.state.steps[this.state.currentStep];
      if (Boolean(step) && Boolean(this.state.shiftsSelectedRow) && Boolean(this.state.shiftsSelectedRow.workingDays)
        && this.state.shiftsSelectedRow.workingDays.length > 0) {
        var row = this.state.shiftsSelectedRow.workingDays.find(p => p.day === this.state.modalWorkingDay);
        var list = [];
        if (Boolean(row) && Boolean(row.content) && row.content.length > 0) {
          row.content.forEach(element => {
            const obj = {
              "descriptionType": element.descriptionType, "endOfWork": element.endOfWork, "endTime": element.endTime,
              "shiftsId": element.shiftsId, "startTime": element.startTime,
              "workingDays": step.title,
            };

            list.push(obj);
          });
        }
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveCopyScheduleFrom", list);
          if (response.type === "ERROR") {
            showError("No records to be added");
          } else {
            this.resetInputs();
            this.restartTable();
            showSuccess();
            this.setState({
              modalCopyScheduleToShow: false,
              modalWorkingDayList: null
            });
          }
        } else {
          showError("No records to be added");
        }
      }
    } else {
      showError("Please select work days");
    }
  };


  currentList() {
    let { currentContent } = this.state;
    let selectedValue = null;
    if (Boolean(currentContent) && currentContent.length > 0) {
      selectedValue = currentContent[currentContent.length - 1].descriptionType;
    }
    console.log("currentContent:", currentContent);
    if (selectedValue === "StartOfWork") return startOfWorkValues;
    else if (selectedValue === "WorkHours") return workHoursValues;
    else if (selectedValue === "Break") return breakValues;
    else if (selectedValue === "EndOfWork") return endOfWorkValues;
    else return defaultValues;
  }

  render() {
    const { currentStep } = this.state;

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      style: {
        marginBottom: 0
      }
    };

    return (
      <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col md={8}>
            <span className="h3">
              {Boolean(this.state.shiftsSelectedRow) && (
                <span style={{ color: 'dodgerblue', fontWeight: 'bold' }}>
                  {this.state.shiftsSelectedRow.descriptionName}
                </span>
              )}
            </span>
          </Col>
          <Col md={16}></Col>
        </Row>
        <br />
        <PerfectScrollbar style={{ minHeight: "90px" }}>
          <Steps type="navigation" current={currentStep} onChange={this.onStepsChange}>
            {this.state.steps.map(item => (
              <Step
                style={{ minWidth: "200px" }}
                key={item.title}
                title={item.title}
                icon={item.icon}
                description={item.description}
                disabled={item.disabled}
              />
            ))}
          </Steps>
        </PerfectScrollbar>
        <div className="steps-content">{Boolean(this.state.steps) && this.state.steps.length > 0
          && this.state.steps[currentStep].content}</div>

        <Modal show={this.state.modalDeleteShow} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <AntModal
          title="Create the Schedule"
          visible={this.state.modalWorkScheduleShow}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => {
            this.setState({ modalWorkScheduleShow: false });
          }}
          width={900}
          centered
        // footer={[
        //   <Button variant="secondary" onClick={() => {
        //     this.setState({ modalWorkScheduleShow: false });
        //   }}>Cancel</Button>
        // ]}
        >
          <br />
          <div hidden={this.state.hideInputs}>
            {/* <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>
              {this.state.pageFormData && this.state.pageFormData.pageFormInputCompanyList ? (
                <ElementsProvider
                  inputs={this.state.pageFormData.pageFormInputCompanyList}
                  layout={layout}
                  form={this.formRef}
                  refresh={this.state.refreshForm}
                />
              ) : null}
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </Form> */}
            <Form initialValues={{ remember: false }} onFinish={this.save} onFinishFailed={onFinishFailed} ref={this.formRef}>

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ShiftsWorkSchedule.TotalWeeklyWork" defaultMessage="Total (weekly) work" />}
                  name="totalWeekly"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]
                  }
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <InputNumber style={{ width: "100%" }} />
                    <span style={{ marginLeft: '5px' }}>hrs</span>
                  </div>
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ShiftsWorkSchedule.TotalWeeklyWork" defaultMessage="Total (daily) work" />}
                  name="totalDaily"
                  rules={[{ required: false, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <InputNumber style={{ width: "100%" }} />
                    <span style={{ marginLeft: '5px' }}>hrs</span>
                  </div>
                </Form.Item>
              }

              {/* {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ShiftsWorkSchedule.Description" defaultMessage="Description" />}
                  name="descriptionType"
                  rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
                >
                  <Select allowClear style={{ width: "100%" }} optionFilterProp="children">
                    {Boolean(this.state["descriptionTypeSelectItems"]) &&
                      this.state["descriptionTypeSelectItems"].length > 0 &&
                      this.state["descriptionTypeSelectItems"].map(i => (
                        <Option key={i.key} value={i.key}>
                          {i.value}
                        </Option>

                      ))}
                  </Select>
                </Form.Item>
              } */}

              {
                <Form.Item
                {...layout}
                label={<FormattedMessage id="ShiftsWorkSchedule.Description" defaultMessage="Description" />}
                name="descriptionType"
                rules={[{ required: true, message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." /> }]}
              >
        <Select allowClear style={{ width: "100%" }} optionFilterProp="children" value={this.state.selectedValue} >
          {Boolean(this.state["descriptionTypeSelectItems"]) &&
            this.state["descriptionTypeSelectItems"].length > 0 &&
            this.currentList().map(i => (
              <Option key={i.key} value={i.key}>
                {i.value}
              </Option>
            ))}
        </Select>
              </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ShiftsWorkSchedule.Explanation" defaultMessage="Explanation" />}
                  name="endOfWork"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />,
                    },
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={e => {
                      if (e.target.checked) {
                      }
                      this.setState({
                        nextButtonVisible: e.target.checked
                      });
                    }}
                  />
                </Form.Item>
              }

              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ShiftsWorkSchedule.StartTime" defaultMessage="Start Time" />}
                  name="startTime"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <TimePicker />
                </Form.Item>
              }
              {
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="ShiftsWorkSchedule.EndTime" defaultMessage="End Time" />}
                  name="endTime"
                  rules={[
                    {
                      required: false,
                      message: <FormattedMessage id="GENERAL.REQUIRED" defaultMessage="This field is required." />
                    }
                  ]}
                >
                  <TimePicker />
                </Form.Item>
              }
              <div hidden={this.state.hideSave}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    {!this.state.nextButtonVisible && (
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Button id="SaveButton" type="submit" style={{ width: "100%" }} variant="success">
                          <FormattedMessage id="GeneralButtonNext" defaultMessage="Next" />
                        </Button>
                      </Col>
                    )}
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
              <div hidden={this.state.hideUpdate}>
                {
                  <Row gutter={[16, 16]}>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="CancelButton" style={{ width: "100%" }} variant="secondary" onClick={this.cancel}>
                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Button id="UpdateButton" style={{ width: "100%" }} variant="warning" type="submit">
                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                      </Button>
                    </Col>
                    <Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
                  </Row>
                }
              </div>
            </Form>
          </div>

        </AntModal>

        <AntModal
          title="Copy Schedule to..."
          open={this.state.modalCopyScheduleToShow}
          onCancel={() => {
            this.setState({ modalCopyScheduleToShow: false });
          }}
          width={600}
          centered
          footer={[
            <Space>
              <Button variant="secondary" onClick={() => {
                this.setState({ modalCopyScheduleToShow: false });
              }}>Cancel</Button>
              <Button variant="success" onClick={this.saveDublicateTheScheduleClickSubmit}>Save</Button>
            </Space>
          ]}
        >
          <br />
          <Select
            allowClear
            showSearch
            style={{ width: "100%" }} placeholder="Select day" id="selectedDay"
            mode="multiple"
            value={this.state.modalWorkingDayList} onChange={(value) => {
              this.setState({ modalWorkingDayList: value })
            }}>
            {this.state.workingDayList.map(i => (
              <Option value={i}>{i}</Option>
            ))}
          </Select>
        </AntModal>

        <AntModal
          title="Copy Schedule from..."
          visible={this.state.modalCopyScheduleFromShow}
          onCancel={() => {
            this.setState({ modalCopyScheduleFromShow: false });
          }}
          width={600}
          centered
          footer={[
            <Space>
              <Button variant="secondary" onClick={() => {
                this.setState({ modalCopyScheduleFromShow: false });
              }}>Cancel</Button>
              <Button variant="success" onClick={this.saveCopyScheduleFromClickSubmit}>Save</Button>
            </Space>
          ]}
        >
          <br />
          <Select
            allowClear
            showSearch
            style={{ width: "100%" }} placeholder="Select day" id="selectedDay"
            value={this.state.modalWorkingDay} onChange={(value) => {
              this.setState({ modalWorkingDay: value })
            }}>
            {this.state.workingDayList.map(i => (
              <Option value={i}>{i}</Option>
            ))}
          </Select>
        </AntModal>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsWorkSchedule);