import React, { Component } from "react";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Input, Tree, Button as AntButton, Form, Col, Row, Modal as AntModal, Space, Badge } from "antd";
import { FcFolder, FcEditImage, FcFile } from "react-icons/fc";
import { GrInherit } from "react-icons/gr";
import { Menu, Item, Submenu, useContextMenu } from "react-contexify";
import { FaFileUpload, FaPlus, FaRegFile } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "react-contexify/dist/ReactContexify.css";
import { handleRequest } from "../../../ApiConnector";
import { error, showError, showSuccess } from "../../../MessageHelper";
import { Button, Modal } from "react-bootstrap";
import { PlusOutlined } from "@ant-design/icons";

const Menu_NACECodes = "Menu_NACECodes";
const Menu_Group = "Menu_Group";
const Menu_NACE = "Menu_NACE";

const { show } = useContextMenu();

function updateTreeData(list, key, children, expandedKeys) {
  return list.map(node => {
    if (node.key === key) {
      return { ...node, children: setChildren(node.children, children, expandedKeys) };
    }

    if (node.children) {
      return { ...node, children: updateTreeData(node.children, key, children, expandedKeys) };
    }

    return node;
  });
}

function setChildren(oldList, newList, keys) {
  let result = [];

  if (Boolean(oldList) && oldList.length > 0 && Boolean(newList) && newList.length > 0) {
    oldList.forEach(node => {
      if (Boolean(node.key) && Boolean(keys) && keys.length > 0 && keys.includes(node.key)) {
        let findInNewIndex = newList.findIndex(p => p.key === node.key);
        if (findInNewIndex >= 0) {
          result.push(node);
          newList.splice(findInNewIndex, 1);
        }
      }
    });

    newList.forEach(element => {
      result.push(element);
    });
  } else result = Boolean(newList) && newList.length > 0 ? newList : [];

  return result;
}

var dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    dataList.push({ key: node.key, title: node.title });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export class NaceCodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "NaceCodes",
      controllerName: "naceCodes",
      id: null,
      description: "",
      code: "",
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      nodes: [],
      loadedKeys: [],
      searchValue: "",
      selectedNodeKey: null,
      selectedNode: null,
      parentNode: null,
      process: null,
      copy: false,
      copiedId: null,
      showGroupModal: false,
      showNaceModal: false,
      showNaceChildModal: false,
      refreshTree: true,
    };
  }

  inputRef = React.createRef(null);

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys: expandedKeys,
      autoExpandParent: false
    });
  };

  onLoadData = async loadedKeys => {
    this.setState({
      loadedKeys: loadedKeys
    });
  };

  loadData = async (treenode, refreshTree) => {
    this.setState({ refreshTree: refreshTree === false ? refreshTree : true });
    const { expandedKeys } = this.state;
    var nodes = this.state.nodes;
    let obj = { id: Boolean(treenode.id) ? treenode.id : Boolean(treenode.key) ? treenode.key : -1, type: Boolean(treenode.type) ? treenode.type : "NACE" };

    console.log("treenode", treenode);
    console.log("obj", obj);
    var response = null;
    if (Boolean(treenode.key)) {
      response = await handleRequest("GET", "/api/" + this.state.controllerName + "/subitems/" + obj.id);
    }
    if (response.type === "ERROR") {
      error(response);
    }

    if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
      const data = response.data;
      console.log("data", data);

      data.forEach(element => {
        element.icon = Boolean(element.type) &&
          (element.type === "NACE") ? <FcFile /> :
          (element.type === "NACEChild" ) ? <FaRegFile /> :
            (element.type === "Group") ? <FaFileUpload /> : <FcFolder />;
        element.title = element.description + "/" + element.code;
        element.key = element.id;
      });

      var res = updateTreeData(nodes, treenode.key, data, expandedKeys);
      console.log("res", res);
      this.setState({
        nodes: res,
        refreshTree: true
      });
    } else {
      this.setState({
        refreshTree: true
      });
    }
  };

  formRef = React.createRef();
  formRefOnClickName = React.createRef();

  componentDidMount = async () => {
    this.fillTree();
  };

  fillTree = async () => {
    this.setState({ refreshTree: false });
    var response = await handleRequest("GET", "/api/" + this.state.controllerName + "/root");

    if (response.type === "ERROR") {
      error(response);
      this.setState({
        refreshTree: true
      });
    } else {
      if (Boolean(response.data) && response.data.length > 0) {
        response.data.forEach(element => {
          element.id = element.key
          element.icon = <FcFolder />;
          if (!Boolean(element.type)) {
            element.type = "NACECodes";
          }
        });

        this.setState({
          nodes: Boolean(response.data) ? response.data : [],
          refreshTree: true,
        });
      } else {
        this.setState({
          refreshTree: true
        });
      }
    }
  };

  onRightClick = (event, node) => {
    let parentNode = this.getParentNode(node);
    this.setState(
      {
        selectedNodeKey: node.key,
        selectedNode: node,
        parentNode: parentNode
      },
      this.displayMenu(event, Boolean(node) && Boolean(node.type) ? node.type : null)
    );
  };

  getParentNode = node => {
    let parentNode = null;
    var list = this.state.nodes;
    var posArray = node.pos.split("-");

    for (let index = 1; index < posArray.length - 1; index++) {
      let element = parseInt(posArray[index]);
      parentNode = list[element];
      list = parentNode.children;
    }

    return parentNode;
  };

  handleItemClick = ({ event, props, triggerEvent, data }) => {
    var key = this.state.selectedNodeKey;
    const { selectedNode, parentNode } = this.state;

    console.log("AAA", event.currentTarget.id);
    console.log(selectedNode);
    console.log(parentNode);
    console.log(key);

    switch (event.currentTarget.id) {
      case "create_group":
        this.setState({
          showCreateGroupModal: true,
          parentId: selectedNode.id,
          description: "",
          type: "Group"
        });
        break;

      case "create_nace":
        this.setState({
          showCreateNaceModal: true,
          parentId: selectedNode.id,
          description: "",
          type: "NACE"
        });
        break;

      case "create_nace_child":
        this.setState({
          showCreateNaceChildModal: true,
          parentId: selectedNode.id,
          description: "",
          type: "NACEChild"
        });
        break;

      case "rename":
        this.setState({
          showCreateModal: true,
          showCreateGroupModal: true,
          showCreateNaceModal: true,
          showCreateNaceChildModal: true,
          description: selectedNode.title,
          code: selectedNode.title,
          id: selectedNode.id,
          parentId: Boolean(parentNode) ? parentNode.id : null,
          type: selectedNode.type,
        });
        break;

      case "delete":
        this.setState({
          showDeleteModal: true,
          id: selectedNode.id,
          type: selectedNode.type,
        });
        break;

    }
  };

  showOrHideDeleteModal = () => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
    if (!this.state.showDeleteModal) {
      this.resetInputs();
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  delete = async () => {
    const deletedItem = {
      id: this.state.id,
      type: this.state.type
    };

    var response = await handleRequest("DELETE", "/api/" + this.state.controllerName + "/" + deletedItem.id);

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
      else this.fillTree();

      showSuccess();
      this.setState({
        id: null,
        showDeleteModal: false
      });
    }
  };

  displayMenu = (e, type) => {
    console.log("TTT", type);
    if (Boolean(type)) {

      if (type === "Group") {
        show(e, { id: Menu_Group });
      }
      else if (type === "NACE") {
        show(e, { id: Menu_NACE });
      }
      else if (type === "NACECodes") {
        show(e, { id: Menu_NACECodes });
      }
    }
  };

  save = async () => {
    const { process, selectedNode } = this.state;
    const newItem = {
      id: this.state.id,
      parentId: this.state.parentId,
      description: this.state.description,
      code: this.state.code,
      type: Boolean(this.state.type) ? this.state.type : "NACECodes"
    };
    if (Boolean(process) && process === "create_parent") {
      newItem.childId = selectedNode.key;
      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/addparent", newItem);
    } else if (newItem.id === null) {
      response = await handleRequest("POST", "/api/" + this.state.controllerName, newItem);
    } else {
      response = await handleRequest("PUT", "/api/" + this.state.controllerName + "/updateType/" + newItem.id + "/" + newItem.type, newItem);
    }

    if (response.type === "ERROR") {
      error(response);
    } else {
      if (newItem.id === null) {
        if (newItem.parentId === null) this.fillTree();
        else if (Boolean(process) && process === "create_parent") this.loadData(this.state.parentNode, false);
        else this.expandSelectedNode();
      } else {
        if (Boolean(this.state.parentNode)) this.loadData(this.state.parentNode, false);
        else this.fillTree();
      }

      showSuccess();
      this.resetInputs();
    }
  };

  saveOnClickName = async values => {
    if (Boolean(this.state.pageAutoDesignId) && this.state.pageAutoDesignId > 0) {
      const newItem = { ...values, id: this.state.id };
      newItem.id = this.state.pageAutoDesignId;
      newItem.autoDesignOnClick = this.state.autoDesignOnClick;
      newItem.type = this.state.type;

      var response = await handleRequest("POST", "/api/" + this.state.controllerName + "/saveOnClickName", newItem);
      if (response.type === "ERROR") {
        error(response);
      } else {
        showSuccess();
        this.setState({ showOnClickNameModal: false });
        if (Boolean(this.state.parentNode))
          this.loadData(this.state.parentNode, false);
        else
          this.fillTree();
      }
    } else {
      showError("Page Auto Design Form not found");
    }
  };

  paste = () => {
    this.formRef.current.submit();
  };

  resetInputs = () => {
    this.setState({
      id: null,
      parentId: null,
      description: "",
      code: "",
      showCreateModal: false,
      showCreateGroupModal: false,
      showCreateNaceModal: false,
      showCreateNaceChildModal: false,
      selectedNode: null,
      selectedNodeKey: null,
      parentNode: null,
      process: null,
      showOrderModal: false,
      showPasteModal: false,
      copy: false,
      copiedId: null,
      showSupplyMethodModal: false,
      showServiceModal: false,
      showLocationModal: false
    });
  };

  expandSelectedNode = () => {
    let key = this.state.selectedNodeKey;
    let node = this.state.selectedNode;
    if (Boolean(key)) {
      let expandedKeys = Boolean(this.state.expandedKeys) ? this.state.expandedKeys : [];
      if (Boolean(expandedKeys.find(p => p === key))) {
        this.loadData(node, false);
      } else {
        this.setState({
          expandedKeys: [...expandedKeys, key]
        });
        this.loadData(node, false);
      }
    }
  };

  itemTemplate(item) {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <h5 className="mb-2">{item.title}</h5>
        </div>
      </div>
    );
  }


  onDragEnter = (info) => {
  };

  onDrop = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...this.state.nodes];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    this.setState({
      nodes: data
    });
  };

  closeGroupModal = () => {
    this.setState({
      showFormModal: false,
    });
  };

  closeNACEModal = () => {
    this.setState({
      showTableModal: false,
    });
  };

  closeNACEChildModal = () => {
    this.setState({
      showInputFormatModal: false,
    });
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };

    const layout2 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      style: { marginBottom: 0 }
    };

    return (
      <Badge.Ribbon
        text={<FormattedMessage id={this.state.controllerName + ".pageTitle"} defaultMessage={"Nace Codes"} />}
        placement="start"
        color="volcano"
        style={{ fontSize: 12 }}
      >
        <div className="card card-custom" style={{ padding: "2rem", minHeight: "100%" }}>
          <br /><br />


          {this.state.refreshTree && <Tree
            style={{ marginTop: "10px", lineHeight: "30px" }}
            className="draggable-tree"
            showLine={{ showLeafIcon: false }}
            showIcon
            checkStrictly={true}
            loadedKeys={this.state.loadedKeys}
            onLoad={this.onLoadData}
            loadData={this.loadData}
            onRightClick={({ event, node }) => this.onRightClick(event, node)}
            onExpand={this.onExpand}
            expandedKeys={this.state.expandedKeys}
            autoExpandParent={false}
            treeData={this.state.nodes}
            blockNode={true}
            // draggable
            onDragEnter={this.onDragEnter}
            onDrop={this.onDrop}
          />}

          <Menu id={Menu_NACECodes}>
            <Item id="create_group" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Create Group </span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_Group}>
            <Item id="create_nace" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Create NACE </span>
              </Space>
            </Item>
            <Item id="rename" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Rename</span>
              </Space>
            </Item>
            <Item id="delete" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>Delete</span>
              </Space>
            </Item>
          </Menu>

          <Menu id={Menu_NACE}>
            <Item id="create_nace_child" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Create Nace Child</span>
              </Space>
            </Item>
            <Item id="rename" onClick={this.handleItemClick}>
              <Space>
                <FcEditImage />
                <span>Rename</span>
              </Space>
            </Item>
            <Item id="delete" onClick={this.handleItemClick}>
              <Space>
                <MdDelete />
                <span>Delete</span>
              </Space>
            </Item>
          </Menu>


          <Modal
            show={this.state.showCreateGroupModal}
            onHide={() => {
              this.setState({
                showCreateGroupModal: false
              });
              this.resetInputs();
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter"> Create Group </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Input
                placeholder="Group Code"
                id="groupCode"
                ref={this.inputRef}
                onPressEnter={this.save}
                value={this.state.code}
                onChange={e => this.setState({ code: e.target.value })}
              />
              <br /><br />
              <Input
                placeholder="Group Description"
                id="description"
                ref={this.inputRef}
                onPressEnter={this.save}
                value={this.state.description}
                onChange={e => this.setState({ description: e.target.value })}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showCreateGroupModal: false
                  });
                  this.resetInputs();
                }}
              >
                Close
              </Button>
              <Button variant="primary" onClick={this.save}>
                {" "}
                Save{" "}
              </Button>
            </Modal.Footer>
          </Modal>



          <Modal
            show={this.state.showCreateNaceModal}
            onHide={() => {
              this.setState({
                showCreateNaceModal: false
              });
              this.resetInputs();
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter"> Create Nace </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Input
                placeholder="Nace Code"
                id="naceCode"
                ref={this.inputRef}
                onPressEnter={this.save}
                value={this.state.code}
                onChange={e => this.setState({ code: e.target.value })}
              />
              <br /><br />
              <Input
                placeholder="Nace Description"
                id="naceDescription"
                ref={this.inputRef}
                onPressEnter={this.save}
                value={this.state.description}
                onChange={e => this.setState({ description: e.target.value })}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showCreateNaceModal: false
                  });
                  this.resetInputs();
                }}
              >
                Close
              </Button>
              <Button variant="primary" onClick={this.save}>
                {" "}
                Save{" "}
              </Button>
            </Modal.Footer>
          </Modal>




          <Modal
            show={this.state.showCreateNaceChildModal}
            onHide={() => {
              this.setState({
                showCreateNaceChildModal: false
              });
              this.resetInputs();
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter"> Create Nace Child </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Input
                placeholder="Nace Code"
                id="naceCode"
                ref={this.inputRef}
                onPressEnter={this.save}
                value={this.state.code}
                onChange={e => this.setState({ code: e.target.value })}
              />
              <br /><br />
              <Input
                placeholder="Nace Description"
                id="naceDescription"
                ref={this.inputRef}
                onPressEnter={this.save}
                value={this.state.description}
                onChange={e => this.setState({ description: e.target.value })}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    showCreateNaceChildModal: false
                  });
                  this.resetInputs();
                }}
              >
                Close
              </Button>
              <Button variant="primary" onClick={this.save}>
                {" "}
                Save{" "}
              </Button>
            </Modal.Footer>
          </Modal>




          <Modal show={this.state.showDeleteModal} onHide={this.showOrHideDeleteModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralMessageChosenRecordWillBeDeleted" defaultMessage="Chosen record will be deleted !" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage id="GeneralMessageAreYouSure" defaultMessage="Are you sure?" />
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideDeleteModal}>
                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="danger" onClick={this.delete}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Badge.Ribbon>
    );
  }
}
const mapStateToProps = state => {
  return {
    logout: state.logout
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NaceCodes);