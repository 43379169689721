import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, createStyles } from '@material-ui/core/styles'

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import MenuIcon from '@material-ui/icons/Menu';
import CreateIcon from '@material-ui/icons/AddCircle';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

export default ({ row, onDeleteRow, onEditRow, onReviseRow, onAddNew, onSupersedeRow, size, editBgColor, deleteBgColor }) => {
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(false)

  function handleClickOpen() {
    setOpen(!open)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteRow = () => {
    if (onDeleteRow) {
      onDeleteRow(row);
      setAnchorEl(null);
    }
  };

  const editRow = () => {
    if (onEditRow) {
      onEditRow(row);
      setAnchorEl(null);
    }
  };

  const reviseRow = () => {
    if (onReviseRow) {
      onReviseRow(row);
      setAnchorEl(null);
    }
  };

  const addNew = (type) => {
    if (onAddNew) {
      onAddNew(row,type); 
      setAnchorEl(null);
    }
  };

  const supersedeRow = () => {
    if (onSupersedeRow) {
      onSupersedeRow(row);
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu" // flaticon-layer
        aria-haspopup="true"
        onClick={handleClick}
        size={size}
      >
        {/* <i className={`kt-menu__link-icon  flaticon-layer`} /> */}
        <MenuIcon color="primary" />
        {/* <MoreVertIcon /> */}
      </IconButton>
      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}

      >

        <MenuItem onClick={editRow} style={{ backgroundColor: '#' + editBgColor }}>
          <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">
            Edit
          </Typography>
        </MenuItem>

        <Divider />
        <MenuItem onClick={reviseRow} style={{ backgroundColor: '#' + editBgColor }}>
          <ListItemIcon>
            <AccountTreeIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">
            Revise
          </Typography>
        </MenuItem>

        <Divider />
        <MenuItem  style={{ backgroundColor: '#' + editBgColor }}>
          <ListItemIcon>
            <CreateIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">
            <ListItem button onClick={handleClickOpen}  >
             
              <ListItemText primary="Add" />
              {open ? <IconExpandLess /> : <IconExpandMore />}
            </ListItem>
            {Boolean(row) && Boolean(row.drawingType.name==="IFC") && <Collapse in={open} timeout="auto" unmountOnExit>
              <Divider />
              <List component="div" disablePadding>
                <ListItem  onClick={()=>addNew("Shop Drawing")} button  >
                  <ListItemText inset primary="Shop Drawing" />
                </ListItem>
                <ListItem  onClick={()=>addNew("Red Line")} button  >
                  <ListItemText inset primary="Red Line" />
                </ListItem>
                <ListItem onClick={()=>addNew("Coordination")} button  >
                  <ListItemText inset primary="Coordination" />
                </ListItem>
                <ListItem onClick={()=>addNew("Coordination")}  button  > 
                  <ListItemText inset primary="As Built" />
                </ListItem>
              </List>
            </Collapse>}
            {Boolean(row) && Boolean(row.drawingType.name==="Shop Drawing") && <Collapse in={open} timeout="auto" unmountOnExit>
              <Divider />
              <List component="div" disablePadding>
              <ListItem button  >
                  <ListItemText inset primary="Red Line" />
                </ListItem>
                <ListItem button  >
                  <ListItemText inset primary="Coordination" />
                </ListItem>
                <ListItem button  >
                  <ListItemText inset primary="As Built" />
                </ListItem>
              </List>
            </Collapse>}
          </Typography>
        </MenuItem>

        <Divider />
        <MenuItem onClick={supersedeRow} style={{ backgroundColor: '#' + editBgColor }}>
          <ListItemIcon>
            <FindReplaceIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">
            Supersede
          </Typography>
        </MenuItem>

        <Divider />

        <MenuItem onClick={deleteRow} style={{ backgroundColor: '#' + deleteBgColor }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <Typography variant="inherit">
            Delete
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};