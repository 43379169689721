import React, { useEffect, useState, useCallback, useRef } from "react";
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    useReactFlow,
} from "react-flow-renderer";
import { nodes as initialNodes } from "../documentation/NodesEdges";
import "../../../custom.scss";

const flowKey = 'example-flow';

const getNodeId = () => `randomnode_${+new Date()}`;

const fitViewOptions = {
    padding: 3,
};

const hide = hidden => nodeOrEdge => {
    nodeOrEdge.hidden = hidden;
    return nodeOrEdge;
};

function PresentationPage3(props) {
    const reactFlowWrapper = useRef(null);
    const connectingNodeId = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const { project } = useReactFlow();
    const [rfInstance, setRfInstance] = useState(null);
    const { setViewport } = useReactFlow();
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        setNodes(nds => nds.map(hide(hidden)));
        setEdges(eds => eds.map(hide(hidden)));
    }, [hidden]);

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);


    const onConnectStart = useCallback((_, { nodeId }) => {
        connectingNodeId.current = nodeId;
    }, []);

    const onConnectStop = useCallback(
        (event) => {
            const targetIsPane = event.target.classList.contains('react-flow__pane');

            if (targetIsPane) {
                // we need to remove the wrapper bounds, in order to get the correct position
                const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
                const id = getNodeId();
                const newNode = {
                    id,
                    // we are removing the half of the node width (75) to center the new node
                    position: project({ x: event.clientX - left - 75, y: event.clientY - top }),
                    data: { label: `Node ${id}` },
                };

                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) =>
                    eds.concat({ id, source: connectingNodeId.current, target: id })
                );
            }
        },
        [project]
    );

    const onSave = useCallback(() => {
        if (rfInstance) {
            const flow = rfInstance.toObject();
            localStorage.setItem(flowKey, JSON.stringify(flow));
        }
    }, [rfInstance]);

    const onRestore = useCallback(() => {
        const restoreFlow = async () => {
            const flow = JSON.parse(localStorage.getItem(flowKey));

            if (flow) {
                const { x = 0, y = 0, zoom = 1 } = flow.viewport;
                setNodes(flow.nodes || []);
                setEdges(flow.edges || []);
                setViewport({ x, y, zoom });
            }
        };

        restoreFlow();
    }, [setNodes, setViewport]);

    const onAdd = useCallback(() => {
        const newNode = {
            id: getNodeId(),
            data: { label: 'Added node' },
            position: {
                x: Math.random() * window.innerWidth - 100,
                y: Math.random() * window.innerHeight,
            },
        };
        setNodes((nds) => nds.concat(newNode));
    }, [setNodes]);

    return (
        <div class="row" style={{ height: '500px' }}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onInit={setRfInstance}
                onConnectStart={onConnectStart}
                onConnectStop={onConnectStop}
                fitView
                fitViewOptions={fitViewOptions}
            >
                <MiniMap />
                <Controls />
                <Background />

                <div style={{ position: 'absolute', left: 10, top: 10, zIndex: 4 }}>
                    <div>
                        <div className="save__controls">
                            <button onClick={onSave}>save</button>
                            <button onClick={onRestore}>restore</button>
                            <button onClick={onAdd}>add node</button>
                        </div>
                        <label htmlFor="ishidden">
                            isHidden
                            <input
                                id="ishidden"
                                type="checkbox"
                                checked={hidden}
                                onChange={event => setHidden(event.target.checked)}
                                className="react-flow__ishidden"
                            />
                        </label>
                    </div>
                </div>
            </ReactFlow>
        </div>
    );
}

export default () => (
    <ReactFlowProvider>
        <PresentationPage3 />
    </ReactFlowProvider>
);